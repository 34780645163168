import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date custom scalar type */
  Date: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type ActivateCompetitionInput = {
  competitionId: Scalars['String'];
};

export type ActivateDynamicSurveyInput = {
  id: Scalars['String'];
};

export type ActivateSeasonCompetitionIntegrationInput = {
  integrationId: Scalars['String'];
};

export type ActivateSeasonInput = {
  id: Scalars['String'];
  sendCoachEmails: Scalars['Boolean'];
  sendOfficialEmails: Scalars['Boolean'];
};

export type ActivateSeasonMediaMembersOrgRecipientInput = {
  orgId: Scalars['String'];
  seasonId: Scalars['String'];
  orgRecipientId: Scalars['String'];
  isActive: Scalars['Boolean'];
};

export type ActivateSeasonTeamKitOrgRecipientInput = {
  orgId: Scalars['String'];
  seasonId: Scalars['String'];
  orgRecipientId: Scalars['String'];
  isActive: Scalars['Boolean'];
};

export type ActivateSeasonTeamKitTeamRecipientInput = {
  teamId: Scalars['String'];
  seasonId: Scalars['String'];
  organizationId: Scalars['String'];
  recipientId: Scalars['String'];
  isActive: Scalars['Boolean'];
};

export type ActivateSurveyInput = {
  id: Scalars['String'];
};

export type ActivateTeamKitForSeasonInput = {
  orgId: Scalars['String'];
  seasonId: Scalars['String'];
  seasonTeamKitId: Scalars['String'];
  sendTeamEmails: Scalars['Boolean'];
};

export type AddAdditionalKeyIncidentToCompetitionSurveyInput = {
  competitionSurveyId: Scalars['String'];
  source: FeedbackSource;
  description: Scalars['String'];
  incidentType: KeyIncidentTypes;
  impactedOutcome: Scalars['Boolean'];
  time?: Maybe<Scalars['String']>;
  sectionId?: Maybe<Scalars['String']>;
  questionId?: Maybe<Scalars['String']>;
  feedbackResponseId?: Maybe<Scalars['String']>;
  periodId?: Maybe<Scalars['String']>;
  teamId?: Maybe<Scalars['String']>;
};

export type AddAdditionalOptionToCompetitionIntegrationInput = {
  integrationId: Scalars['String'];
  name: Scalars['String'];
  isRequired: Scalars['Boolean'];
};

export type AddAdditionalOptionToDynamicSurveyIntegrationInput = {
  integrationId: Scalars['String'];
  name: Scalars['String'];
  value: Scalars['String'];
};

export type AddAdditionalOptionToKeyIncidentVideoIntegrationInput = {
  integrationId: Scalars['String'];
  name: Scalars['String'];
  value: Scalars['String'];
};

export type AddAdditionalSupplementalReportToDynamicCompetitionSurveyInput = {
  competitionSurveyId: Scalars['String'];
  title: Scalars['String'];
  supplementalReportType: SupplementalReportTypes;
  time?: Maybe<Scalars['String']>;
  teamId?: Maybe<Scalars['String']>;
  report?: Maybe<Scalars['String']>;
};

export type AddBroadcastOptionToCompetitionTeamKitInput = {
  competitionTeamKitId: Scalars['String'];
  broadcastOptionId: Scalars['String'];
};

export type AddCoachToTeamInput = {
  userId: Scalars['String'];
  teamId: Scalars['String'];
  type: CoachTypes;
  status: CoachStatuses;
  role?: Maybe<Scalars['String']>;
};

export type AddCommentToKeyIncidentInput = {
  keyIncidentId: Scalars['String'];
  comment: Scalars['String'];
};

export type AddCommentToSupplementalReportInput = {
  supplementalReportId: Scalars['String'];
  comment: Scalars['String'];
};

export type AddCompetitionInfoToCompetitionTeamKitInput = {
  competitionTeamKitId: Scalars['String'];
  value: Scalars['String'];
};

export type AddCompetitionSurveyMultipleResponseInput = {
  competitionSurveyId: Scalars['String'];
  questionId: Scalars['String'];
  response: Scalars['String'];
};

export type AddCompetitionSurveyMultipleResponseWithTokenInput = {
  surveyToken: Scalars['String'];
  questionId: Scalars['String'];
  response: Scalars['String'];
};

export type AddDynamicCompetitionSurveyFileUploadInput = {
  competitionSurveyId: Scalars['String'];
  questionId: Scalars['String'];
  comment?: Maybe<Scalars['String']>;
};

export type AddDynamicCompetitionSurveyFileUploadWithTokenInput = {
  surveyToken: Scalars['String'];
  questionId: Scalars['String'];
  comment?: Maybe<Scalars['String']>;
};

export type AddDynamicCompetitionSurveyMultipleResponseInput = {
  competitionSurveyId: Scalars['String'];
  questionId: Scalars['String'];
  response: Scalars['String'];
};

export type AddDynamicCompetitionSurveyMultipleResponseWithTokenInput = {
  surveyToken: Scalars['String'];
  questionId: Scalars['String'];
  response: Scalars['String'];
};

export type AddDynamicCompetitionSurveySubQuestionMultipleResponseInput = {
  competitionSurveyId: Scalars['String'];
  questionId: Scalars['String'];
  subQuestionId: Scalars['String'];
  response: Scalars['String'];
};

export type AddDynamicCompetitionSurveySubQuestionMultipleResponseWithTokenInput = {
  surveyToken: Scalars['String'];
  questionId: Scalars['String'];
  subQuestionId: Scalars['String'];
  response: Scalars['String'];
};

export type AddDynamicSurveyAssessmentObjectRecipientInput = {
  surveyId: Scalars['String'];
  assessmentObjectId: Scalars['String'];
};

export type AddDynamicSurveyIncidentReportInput = {
  surveyId: Scalars['String'];
  orgMembershipId: Scalars['String'];
};

export type AddDynamicSurveyIncidentReportRecipientInput = {
  surveyId: Scalars['String'];
  orgMembershipId: Scalars['String'];
};

export type AddDynamicSurveyReminderLevelReportRecipientInput = {
  surveyId: Scalars['String'];
  orgMembershipId: Scalars['String'];
  level1Recipient: Scalars['Boolean'];
  level2Recipient: Scalars['Boolean'];
  level3Recipient: Scalars['Boolean'];
};

export type AddDynamicSurveySectionInput = {
  surveyId: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  isDynamic: Scalars['Boolean'];
  allowAdditions: Scalars['Boolean'];
  requireSupplementalReport: Scalars['Boolean'];
  supplementalReportType?: Maybe<SupplementalReportTypes>;
};

export type AddDynamicSurveySectionQuestionInput = {
  sectionId: Scalars['String'];
  assessmentObjectId: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  isRequired: Scalars['Boolean'];
  allowCorrections: Scalars['Boolean'];
  questionType: SurveyQuestionTypes;
};

export type AddDynamicSurveySectionQuestionOptionInput = {
  questionId: Scalars['String'];
  value: Scalars['String'];
};

export type AddDynamicSurveySectionSubQuestionInput = {
  questionId: Scalars['String'];
  name: Scalars['String'];
  questionText: Scalars['String'];
  isRequired: Scalars['Boolean'];
  questionType: SurveyQuestionTypes;
};

export type AddDynamicSurveySectionSubQuestionOptionInput = {
  subQuestionId: Scalars['String'];
  value: Scalars['String'];
};

export type AddDynamicSurveySignatureSectionInput = {
  surveyId: Scalars['String'];
  signatureText: Scalars['String'];
};

export type AddDynamicSurveySummaryRecipientInput = {
  surveyId: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive: Scalars['Boolean'];
};

export type AddDynamicSurveySupplementalReportInput = {
  surveyId: Scalars['String'];
  orgMembershipId: Scalars['String'];
};

export type AddDynamicSurveySupplementalReportRecipientInput = {
  surveyId: Scalars['String'];
  orgMembershipId: Scalars['String'];
};

export type AddExternalLinkToKeyIncidentInput = {
  keyIncidentId: Scalars['String'];
  link: Scalars['String'];
  type: KeyIncidentExternalLinkTypes;
  description?: Maybe<Scalars['String']>;
};

export type AddExternalLinkToKeyIncidentToCompetitionSurveyInput = {
  competitionSurveyId: Scalars['String'];
  keyIncidentId: Scalars['String'];
  link: Scalars['String'];
  type: KeyIncidentExternalLinkTypes;
  description?: Maybe<Scalars['String']>;
};

export type AddExternalLinkToKeyIncidentToCompetitionSurveyWithTokenInput = {
  surveyToken: Scalars['String'];
  keyIncidentId: Scalars['String'];
  link: Scalars['String'];
  type: KeyIncidentExternalLinkTypes;
  description?: Maybe<Scalars['String']>;
};

export type AddFeatureForOrganizationInput = {
  organizationId: Scalars['String'];
  feature: OrgFeatures;
  enabled: Scalars['Boolean'];
};

export type AddKeyIncidentToCompetitionSurveyInput = {
  competitionSurveyId: Scalars['String'];
  description: Scalars['String'];
  incidentType: KeyIncidentTypes;
  impactedOutcome: Scalars['Boolean'];
  time?: Maybe<Scalars['String']>;
  sectionId?: Maybe<Scalars['String']>;
  periodId?: Maybe<Scalars['String']>;
  externalLinks: Array<CreateKeyIncidentExternalLinkForCompetitionSurveyInput>;
};

export type AddKeyIncidentToCompetitionSurveyWithTokenInput = {
  surveyToken: Scalars['String'];
  description: Scalars['String'];
  incidentType: KeyIncidentTypes;
  impactedOutcome: Scalars['Boolean'];
  time?: Maybe<Scalars['String']>;
  sectionId?: Maybe<Scalars['String']>;
  periodId?: Maybe<Scalars['String']>;
  externalLinks: Array<CreateKeyIncidentExternalLinkForCompetitionSurveyInput>;
};

export type AddKeyIncidentToDynamicCompetitionSurveyInput = {
  competitionSurveyId: Scalars['String'];
  description: Scalars['String'];
  incidentType: KeyIncidentTypes;
  impactedOutcome: Scalars['Boolean'];
  time?: Maybe<Scalars['String']>;
  sectionId?: Maybe<Scalars['String']>;
  questionId?: Maybe<Scalars['String']>;
  teamId?: Maybe<Scalars['String']>;
};

export type AddKeyIncidentToDynamicCompetitionSurveyWithTokenInput = {
  surveyToken: Scalars['String'];
  description: Scalars['String'];
  incidentType: KeyIncidentTypes;
  impactedOutcome: Scalars['Boolean'];
  time?: Maybe<Scalars['String']>;
  teamId?: Maybe<Scalars['String']>;
  sectionId?: Maybe<Scalars['String']>;
  questionId?: Maybe<Scalars['String']>;
};

export type AddKeyIncidentVideoIntegrationToSurveyInput = {
  surveyId: Scalars['String'];
  integrationId: Scalars['String'];
};

export type AddLeagueMembershipInput = {
  leagueId: Scalars['String'];
  userId: Scalars['String'];
};

export type AddLeagueTagInput = {
  leagueId: Scalars['String'];
  tag: Scalars['String'];
};

export type AddNewContactToCompetitionTeamKitContactInput = {
  competitionTeamKitContactId: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type AddOfficialAssessmentObjectToSeasonInput = {
  seasonId: Scalars['String'];
  assessmentObjectId: Scalars['String'];
  order: Scalars['Int'];
  isOptional: Scalars['Boolean'];
};

export type AddOfficialToCompetitionInput = {
  competitionId: Scalars['String'];
  officialId: Scalars['String'];
  assessmentObjectId: Scalars['String'];
};

export type AddOfficialToSeasonInput = {
  seasonId: Scalars['String'];
  officialId: Scalars['String'];
  sendEmail: Scalars['Boolean'];
};

export type AddPeriodToSeasonInput = {
  seasonId: Scalars['String'];
  periodId: Scalars['String'];
  order: Scalars['Int'];
};

export type AddSeasonMediaMembersOrgRecipientInput = {
  orgId: Scalars['String'];
  seasonId: Scalars['String'];
  orgMembershipId: Scalars['String'];
  isActive: Scalars['Boolean'];
  isKIRecipient: Scalars['Boolean'];
};

export type AddSeasonTeamKitOrgRecipientInput = {
  orgId: Scalars['String'];
  seasonId: Scalars['String'];
  orgMembershipId: Scalars['String'];
};

export type AddSeasonTeamToSeasonDivisionInput = {
  seasonId: Scalars['String'];
  seasonDivisionId: Scalars['String'];
  seasonTeamId: Scalars['String'];
};

export type AddSupplementalReportToDynamicCompetitionSurveyInput = {
  competitionSurveyId: Scalars['String'];
  title: Scalars['String'];
  supplementalReportType: SupplementalReportTypes;
  time?: Maybe<Scalars['String']>;
  teamId?: Maybe<Scalars['String']>;
  report?: Maybe<Scalars['String']>;
};

export type AddSupplementalReportToDynamicCompetitionSurveyWithTokenInput = {
  surveyToken: Scalars['String'];
  title: Scalars['String'];
  supplementalReportType: SupplementalReportTypes;
  time?: Maybe<Scalars['String']>;
  teamId?: Maybe<Scalars['String']>;
  report?: Maybe<Scalars['String']>;
};

export type AddSurveyAssessmentObjectRecipientInput = {
  surveyId: Scalars['String'];
  assessmentObjectId: Scalars['String'];
};

export type AddSurveyIncidentReportInput = {
  surveyId: Scalars['String'];
  orgMembershipId: Scalars['String'];
};

export type AddSurveyIncidentReportRecipientInput = {
  surveyId: Scalars['String'];
  orgMembershipId: Scalars['String'];
};

export type AddSurveyReminderLevelReportRecipientInput = {
  surveyId: Scalars['String'];
  orgMembershipId: Scalars['String'];
  level1Recipient: Scalars['Boolean'];
  level2Recipient: Scalars['Boolean'];
  level3Recipient: Scalars['Boolean'];
};

export type AddSurveySectionInput = {
  surveyId: Scalars['String'];
  assessmentObjectId: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
};

export type AddSurveySectionQuestionInput = {
  sectionId: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  isRequired: Scalars['Boolean'];
  questionType: SurveyQuestionTypes;
};

export type AddSurveySectionQuestionOptionInput = {
  questionId: Scalars['String'];
  value: Scalars['String'];
};

export type AddSurveyTemplateIncidentReportInput = {
  templateId: Scalars['String'];
};

export type AddSurveyTemplateSectionInput = {
  id: Scalars['String'];
  assessmentObjectId: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
};

export type AddSurveyTemplateSectionQuestionInput = {
  sectionId: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  isRequired: Scalars['Boolean'];
  questionType: SurveyQuestionTypes;
};

export type AddSurveyTemplateSectionQuestionOptionInput = {
  questionId: Scalars['String'];
  value: Scalars['String'];
};

export type AddTeamToSeasonInput = {
  seasonId: Scalars['String'];
  teamId: Scalars['String'];
};

export type AddUserToOrganizationInput = {
  organizationId: Scalars['String'];
  email: Scalars['String'];
  roles: Array<OrganizationUserRoles>;
  permissions: Array<OrganizationUserPermissions>;
  title?: Maybe<Scalars['String']>;
};

export type AddUserToOrganizationResponse = {
  __typename?: 'AddUserToOrganizationResponse';
  user: User;
  emailSent: Scalars['Boolean'];
};

export type AdminManageCompetitionIntegrationRequestSummaries = {
  __typename?: 'AdminManageCompetitionIntegrationRequestSummaries';
  requests: Array<AdminManageCompetitionIntegrationRequestSummary>;
  pagination: OffsetPagination;
};

export type AdminManageCompetitionIntegrationRequestSummary = {
  __typename?: 'AdminManageCompetitionIntegrationRequestSummary';
  id: Scalars['String'];
  seasonId: Scalars['String'];
  orgName: Scalars['String'];
  seasonName: Scalars['String'];
  leagueName: Scalars['String'];
  seasonIntegrationId: Scalars['String'];
  integrationId: Scalars['String'];
  integrationName: Scalars['String'];
  status: CompetitionIntegrationRequestStatuses;
  error?: Maybe<Scalars['String']>;
  totalCount?: Maybe<Scalars['Float']>;
  processedCount?: Maybe<Scalars['Float']>;
  errorCount?: Maybe<Scalars['Float']>;
  successCount?: Maybe<Scalars['Float']>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type AdminUsersResponse = {
  __typename?: 'AdminUsersResponse';
  adminUsers: Array<User>;
  pagination: OffsetPagination;
};

export type AnySurvey = {
  __typename?: 'AnySurvey';
  id: Scalars['String'];
  type: SurveyTypes;
  sport: Sports;
  status: SurveyStatuses;
  name: Scalars['String'];
  description: Scalars['String'];
  audience: SurveyAudiences;
  sendDelayMinutes: Scalars['Int'];
  reminders: SurveyRemindersConfig;
  hasCompetitionVerification: Scalars['Boolean'];
  hasSignatureSection: Scalars['Boolean'];
  hasIncidentReport: Scalars['Boolean'];
  hasKeyIncidentVideoIntegration: Scalars['Boolean'];
  sectionCount: Scalars['Int'];
  orgId: Scalars['String'];
  seasonUuid?: Maybe<Scalars['String']>;
  seasonId: Scalars['String'];
  activatedAt?: Maybe<Scalars['Date']>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type AnySurveys = {
  __typename?: 'AnySurveys';
  surveys: Array<AnySurvey>;
  pagination: OffsetPagination;
};

export type ApproveCompetitionTeamKitInput = {
  competitionTeamKitId: Scalars['String'];
};

export type ApproveSupplementalReportInput = {
  supplementalReportId: Scalars['String'];
};

export type AssessmentObject = {
  __typename?: 'AssessmentObject';
  id: Scalars['String'];
  sport: Sports;
  type: AssessmentObjectTypes;
  classification?: Maybe<AssessmentObjectClassifications>;
  name: Scalars['String'];
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

/** Classification of assessment objects */
export enum AssessmentObjectClassifications {
  HomeTeam = 'HOME_TEAM',
  AwayTeam = 'AWAY_TEAM',
  OpposingTeam = 'OPPOSING_TEAM'
}

/** Types of assessment objects */
export enum AssessmentObjectTypes {
  Official = 'OFFICIAL',
  Team = 'TEAM',
  Organization = 'ORGANIZATION'
}

export type AssessmentObjects = {
  __typename?: 'AssessmentObjects';
  assessmentObjects: Array<AssessmentObject>;
  pagination: OffsetPagination;
};

export type AssessmentObjectsForSeason = {
  __typename?: 'AssessmentObjectsForSeason';
  seasonId: Scalars['String'];
  assessmentObjects: Array<AssessmentObject>;
};

/** The batch file upload row statuses */
export enum BatchFileUploadRowStatuses {
  Processing = 'PROCESSING',
  ActionRequired = 'ACTION_REQUIRED',
  Failed = 'FAILED',
  Created = 'CREATED',
  FoundAndAdded = 'FOUND_AND_ADDED',
  FoundAndUpdated = 'FOUND_AND_UPDATED',
  Deleted = 'DELETED'
}

/** The batch file upload statuses */
export enum BatchFileUploadStatuses {
  Uploading = 'UPLOADING',
  Uploaded = 'UPLOADED',
  UploadFailed = 'UPLOAD_FAILED',
  Processing = 'PROCESSING',
  Failed = 'FAILED',
  Finished = 'FINISHED',
  FinishedWithErrors = 'FINISHED_WITH_ERRORS',
  Deleted = 'DELETED'
}

export type BatchSeasonCoachUpload = {
  __typename?: 'BatchSeasonCoachUpload';
  id: Scalars['String'];
  organizationId: Scalars['String'];
  seasonId: Scalars['String'];
  seasonName: Scalars['String'];
  fileName: Scalars['String'];
  status: BatchFileUploadStatuses;
  error?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type BatchSeasonCoachUploadRow = {
  __typename?: 'BatchSeasonCoachUploadRow';
  id: Scalars['String'];
  uploadId: Scalars['String'];
  rowNumber: Scalars['Float'];
  value: Scalars['String'];
  status: BatchFileUploadRowStatuses;
  errors: Array<BatchSeasonCoachUploadRowError>;
  coachId?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type BatchSeasonCoachUploadRowError = {
  __typename?: 'BatchSeasonCoachUploadRowError';
  id: Scalars['String'];
  columnName?: Maybe<Scalars['String']>;
  error: Scalars['String'];
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type BatchSeasonCoachUploadRowErrors = {
  __typename?: 'BatchSeasonCoachUploadRowErrors';
  rowId: Scalars['String'];
  rowErrors: Array<BatchSeasonCoachUploadRowError>;
  pagination: OffsetPagination;
};

export type BatchSeasonCoachUploadRows = {
  __typename?: 'BatchSeasonCoachUploadRows';
  uploadId: Scalars['String'];
  rows: Array<BatchSeasonCoachUploadRow>;
  pagination: OffsetPagination;
};

export type BatchSeasonCoachUploads = {
  __typename?: 'BatchSeasonCoachUploads';
  seasonId: Scalars['String'];
  uploads: Array<BatchSeasonCoachUpload>;
  pagination: OffsetPagination;
};

export type BatchSeasonCompetitionUpload = {
  __typename?: 'BatchSeasonCompetitionUpload';
  id: Scalars['String'];
  organizationId: Scalars['String'];
  seasonId: Scalars['String'];
  seasonName: Scalars['String'];
  fileName: Scalars['String'];
  status: BatchFileUploadStatuses;
  error?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type BatchSeasonCompetitionUploadRow = {
  __typename?: 'BatchSeasonCompetitionUploadRow';
  id: Scalars['String'];
  uploadId: Scalars['String'];
  rowNumber: Scalars['Float'];
  value: Scalars['String'];
  status: BatchFileUploadRowStatuses;
  errors: Array<BatchSeasonCompetitionUploadRowError>;
  competitionId?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type BatchSeasonCompetitionUploadRowError = {
  __typename?: 'BatchSeasonCompetitionUploadRowError';
  id: Scalars['String'];
  columnName?: Maybe<Scalars['String']>;
  error: Scalars['String'];
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type BatchSeasonCompetitionUploadRowErrors = {
  __typename?: 'BatchSeasonCompetitionUploadRowErrors';
  rowId: Scalars['String'];
  rowErrors: Array<BatchSeasonCompetitionUploadRowError>;
  pagination: OffsetPagination;
};

export type BatchSeasonCompetitionUploadRows = {
  __typename?: 'BatchSeasonCompetitionUploadRows';
  uploadId: Scalars['String'];
  rows: Array<BatchSeasonCompetitionUploadRow>;
  pagination: OffsetPagination;
};

export type BatchSeasonCompetitionUploads = {
  __typename?: 'BatchSeasonCompetitionUploads';
  seasonId: Scalars['String'];
  uploads: Array<BatchSeasonCompetitionUpload>;
  pagination: OffsetPagination;
};

/** The batch season survey response types */
export enum BatchSeasonLegacySurveyTypes {
  Season_2021 = 'SEASON_2021'
}

export type BatchSeasonOfficialUpload = {
  __typename?: 'BatchSeasonOfficialUpload';
  id: Scalars['String'];
  organizationId: Scalars['String'];
  seasonId: Scalars['String'];
  seasonName: Scalars['String'];
  fileName: Scalars['String'];
  status: BatchFileUploadStatuses;
  error?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type BatchSeasonOfficialUploadRow = {
  __typename?: 'BatchSeasonOfficialUploadRow';
  id: Scalars['String'];
  uploadId: Scalars['String'];
  rowNumber: Scalars['Float'];
  value: Scalars['String'];
  status: BatchFileUploadRowStatuses;
  errors: Array<BatchSeasonOfficialUploadRowError>;
  officialId?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type BatchSeasonOfficialUploadRowError = {
  __typename?: 'BatchSeasonOfficialUploadRowError';
  id: Scalars['String'];
  columnName?: Maybe<Scalars['String']>;
  error: Scalars['String'];
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type BatchSeasonOfficialUploadRowErrors = {
  __typename?: 'BatchSeasonOfficialUploadRowErrors';
  rowId: Scalars['String'];
  rowErrors: Array<BatchSeasonOfficialUploadRowError>;
  pagination: OffsetPagination;
};

export type BatchSeasonOfficialUploadRows = {
  __typename?: 'BatchSeasonOfficialUploadRows';
  uploadId: Scalars['String'];
  rows: Array<BatchSeasonOfficialUploadRow>;
  pagination: OffsetPagination;
};

export type BatchSeasonOfficialUploads = {
  __typename?: 'BatchSeasonOfficialUploads';
  seasonId: Scalars['String'];
  uploads: Array<BatchSeasonOfficialUpload>;
  pagination: OffsetPagination;
};

export type BatchSeasonSurveyResponseUpload = {
  __typename?: 'BatchSeasonSurveyResponseUpload';
  id: Scalars['String'];
  organizationId: Scalars['String'];
  seasonId: Scalars['String'];
  seasonName: Scalars['String'];
  surveyId: Scalars['String'];
  surveyName: Scalars['String'];
  fileName: Scalars['String'];
  status: BatchFileUploadStatuses;
  legacyType: BatchSeasonLegacySurveyTypes;
  error?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type BatchSeasonSurveyResponseUploadRow = {
  __typename?: 'BatchSeasonSurveyResponseUploadRow';
  id: Scalars['String'];
  uploadId: Scalars['String'];
  rowNumber: Scalars['Float'];
  value: Scalars['String'];
  status: BatchFileUploadRowStatuses;
  errors: Array<BatchSeasonSurveyResponseUploadRowError>;
  competitionSurveyId?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type BatchSeasonSurveyResponseUploadRowError = {
  __typename?: 'BatchSeasonSurveyResponseUploadRowError';
  id: Scalars['String'];
  columnName?: Maybe<Scalars['String']>;
  error: Scalars['String'];
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type BatchSeasonSurveyResponseUploadRowErrors = {
  __typename?: 'BatchSeasonSurveyResponseUploadRowErrors';
  rowId: Scalars['String'];
  rowErrors: Array<BatchSeasonSurveyResponseUploadRowError>;
  pagination: OffsetPagination;
};

export type BatchSeasonSurveyResponseUploadRows = {
  __typename?: 'BatchSeasonSurveyResponseUploadRows';
  uploadId: Scalars['String'];
  rows: Array<BatchSeasonSurveyResponseUploadRow>;
  pagination: OffsetPagination;
};

export type BatchSeasonSurveyResponseUploads = {
  __typename?: 'BatchSeasonSurveyResponseUploads';
  uploads: Array<BatchSeasonSurveyResponseUpload>;
  pagination: OffsetPagination;
};

export type BatchSeasonTeamUpload = {
  __typename?: 'BatchSeasonTeamUpload';
  id: Scalars['String'];
  organizationId: Scalars['String'];
  seasonId: Scalars['String'];
  seasonName: Scalars['String'];
  fileName: Scalars['String'];
  status: BatchFileUploadStatuses;
  error?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type BatchSeasonTeamUploadRow = {
  __typename?: 'BatchSeasonTeamUploadRow';
  id: Scalars['String'];
  uploadId: Scalars['String'];
  rowNumber: Scalars['Float'];
  value: Scalars['String'];
  status: BatchFileUploadRowStatuses;
  errors: Array<BatchSeasonTeamUploadRowError>;
  teamId?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type BatchSeasonTeamUploadRowError = {
  __typename?: 'BatchSeasonTeamUploadRowError';
  id: Scalars['String'];
  columnName?: Maybe<Scalars['String']>;
  error: Scalars['String'];
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type BatchSeasonTeamUploadRowErrors = {
  __typename?: 'BatchSeasonTeamUploadRowErrors';
  rowId: Scalars['String'];
  rowErrors: Array<BatchSeasonTeamUploadRowError>;
  pagination: OffsetPagination;
};

export type BatchSeasonTeamUploadRows = {
  __typename?: 'BatchSeasonTeamUploadRows';
  uploadId: Scalars['String'];
  rows: Array<BatchSeasonTeamUploadRow>;
  pagination: OffsetPagination;
};

export type BatchSeasonTeamUploads = {
  __typename?: 'BatchSeasonTeamUploads';
  seasonId: Scalars['String'];
  uploads: Array<BatchSeasonTeamUpload>;
  pagination: OffsetPagination;
};

export type BatchUploadCoachesForSeasonInput = {
  seasonId: Scalars['String'];
};

export type BatchUploadCompetitionsForSeasonInput = {
  seasonId: Scalars['String'];
};

export type BatchUploadOfficialsForSeasonInput = {
  seasonId: Scalars['String'];
};

export type BatchUploadSurveyResponsesForSeasonInput = {
  legacyType: BatchSeasonLegacySurveyTypes;
  seasonId: Scalars['String'];
  surveyId: Scalars['String'];
};

export type BatchUploadTeamsForSeasonInput = {
  seasonId: Scalars['String'];
};

export type BeginReviewForKeyIncidentInput = {
  keyIncidentId: Scalars['String'];
};

export type CancelCompetitionInput = {
  competitionId: Scalars['String'];
};

export type CheckConfirmationInput = {
  confirmationToken: Scalars['String'];
};

export type CheckResetPasswordTokenInput = {
  resetPasswordToken: Scalars['String'];
};

export type ClearSelectionForCompetitionTeamKitApparelInput = {
  competitionTeamKitApparelId: Scalars['String'];
};

export type ClearSelectionForCompetitionTeamKitContactInput = {
  competitionTeamKitContactId: Scalars['String'];
};

export type Coach = {
  __typename?: 'Coach';
  id: Scalars['String'];
  teamId: Scalars['String'];
  teamName: Scalars['String'];
  teamAbbreviation?: Maybe<Scalars['String']>;
  sport: Sports;
  teamCity: Scalars['String'];
  userId: Scalars['String'];
  type: CoachTypes;
  status: CoachStatuses;
  role?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  profilePic?: Maybe<Scalars['String']>;
  userStatus: UserStatuses;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

/** The coach statuses */
export enum CoachStatuses {
  Active = 'ACTIVE',
  NotActive = 'NOT_ACTIVE'
}

/** The types of coaches */
export enum CoachTypes {
  HeadCoach = 'HEAD_COACH',
  AssistantCoach = 'ASSISTANT_COACH',
  TechnicalDirector = 'TECHNICAL_DIRECTOR',
  Staff = 'STAFF',
  Other = 'OTHER'
}

export type Competition = {
  __typename?: 'Competition';
  id: Scalars['String'];
  externalId?: Maybe<Scalars['String']>;
  sport: Sports;
  homeTeam: Team;
  homeTeamName: Scalars['String'];
  homeTeamScore?: Maybe<Scalars['Int']>;
  homeTeamTieBreakerScore?: Maybe<Scalars['Int']>;
  awayTeam: Team;
  awayTeamName: Scalars['String'];
  awayTeamScore?: Maybe<Scalars['Int']>;
  awayTeamTieBreakerScore?: Maybe<Scalars['Int']>;
  startTime: Scalars['Date'];
  timeZone: Scalars['String'];
  status: CompetitionStatuses;
  outcome?: Maybe<CompetitionOutcome>;
  verificationStatus: CompetitionVerificationStatuses;
  organization: Organization;
  officials: Array<CompetitionOfficial>;
  location?: Maybe<Scalars['String']>;
  season?: Maybe<Season>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type CompetitionIntegration = {
  __typename?: 'CompetitionIntegration';
  id: Scalars['String'];
  orgId: Scalars['String'];
  orgName: Scalars['String'];
  orgAbbreviation: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  status: CompetitionIntegrationStatuses;
  queueName: Scalars['String'];
  additionalOptions: Array<CompetitionIntegrationAdditionalOption>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type CompetitionIntegrationAdditionalOption = {
  __typename?: 'CompetitionIntegrationAdditionalOption';
  id: Scalars['String'];
  name: Scalars['String'];
  isRequired: Scalars['Boolean'];
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type CompetitionIntegrationAdditionalOptions = {
  __typename?: 'CompetitionIntegrationAdditionalOptions';
  additionalOptions: Array<CompetitionIntegrationAdditionalOption>;
  pagination: OffsetPagination;
};

/** The competition integration request statuses */
export enum CompetitionIntegrationRequestStatuses {
  Sent = 'SENT',
  Processing = 'PROCESSING',
  Processed = 'PROCESSED',
  Error = 'ERROR'
}

export type CompetitionIntegrationRequestSummaries = {
  __typename?: 'CompetitionIntegrationRequestSummaries';
  requests: Array<CompetitionIntegrationRequestSummary>;
  pagination: OffsetPagination;
};

export type CompetitionIntegrationRequestSummary = {
  __typename?: 'CompetitionIntegrationRequestSummary';
  id: Scalars['String'];
  seasonId: Scalars['String'];
  seasonName: Scalars['String'];
  seasonIntegrationId: Scalars['String'];
  integrationId: Scalars['String'];
  integrationName: Scalars['String'];
  status: CompetitionIntegrationRequestStatuses;
  error?: Maybe<Scalars['String']>;
  totalCount?: Maybe<Scalars['Float']>;
  processedCount?: Maybe<Scalars['Float']>;
  errorCount?: Maybe<Scalars['Float']>;
  successCount?: Maybe<Scalars['Float']>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type CompetitionIntegrationResponse = {
  __typename?: 'CompetitionIntegrationResponse';
  id: Scalars['String'];
  requestId: Scalars['String'];
  status: CompetitionIntegrationResponseStatuses;
  index: Scalars['Float'];
  total: Scalars['Float'];
  data?: Maybe<Scalars['String']>;
  errors: Array<CompetitionIntegrationResponseError>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type CompetitionIntegrationResponseError = {
  __typename?: 'CompetitionIntegrationResponseError';
  id: Scalars['String'];
  error: Scalars['String'];
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

/** The competition integration response statuses */
export enum CompetitionIntegrationResponseStatuses {
  Success = 'SUCCESS',
  Error = 'ERROR'
}

export type CompetitionIntegrationResponses = {
  __typename?: 'CompetitionIntegrationResponses';
  responses: Array<CompetitionIntegrationResponse>;
  pagination: OffsetPagination;
};

/** The competition integration statuses */
export enum CompetitionIntegrationStatuses {
  Pending = 'PENDING',
  Active = 'ACTIVE',
  Disabled = 'DISABLED'
}

export type CompetitionIntegrations = {
  __typename?: 'CompetitionIntegrations';
  integrations: Array<CompetitionIntegration>;
  pagination: OffsetPagination;
};

export type CompetitionOfficial = {
  __typename?: 'CompetitionOfficial';
  id: Scalars['String'];
  competitionId: Scalars['String'];
  assessmentObjectId: Scalars['String'];
  official: Official;
  type: Scalars['String'];
  order: Scalars['Float'];
  isOptional: Scalars['Boolean'];
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type CompetitionOfficials = {
  __typename?: 'CompetitionOfficials';
  competitionId: Scalars['String'];
  competitionOfficials: Array<CompetitionOfficial>;
  pagination: OffsetPagination;
};

export type CompetitionOfficialsAvailableForAdditionalKeyIncidents = {
  __typename?: 'CompetitionOfficialsAvailableForAdditionalKeyIncidents';
  competitionOfficialId: Scalars['String'];
  assessmentObjectId: Scalars['String'];
  type: Scalars['String'];
  order: Scalars['Float'];
  official: Official;
  sectionId: Scalars['String'];
  sectionName: Scalars['String'];
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

/** The competition outcome */
export enum CompetitionOutcome {
  Tie = 'TIE',
  HomeTeamWon = 'HOME_TEAM_WON',
  AwayTeamWon = 'AWAY_TEAM_WON'
}

export type CompetitionStat = {
  __typename?: 'CompetitionStat';
  id: Scalars['String'];
  competitionId: Scalars['String'];
  teamId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  displayText?: Maybe<Scalars['String']>;
  valueString?: Maybe<Scalars['String']>;
  valueNumeric?: Maybe<Scalars['Float']>;
  valueDate?: Maybe<Scalars['Date']>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type CompetitionStats = {
  __typename?: 'CompetitionStats';
  competitionId: Scalars['String'];
  stats: Array<CompetitionStat>;
  pagination: OffsetPagination;
};

/** The competition statuses */
export enum CompetitionStatuses {
  Draft = 'DRAFT',
  Active = 'ACTIVE',
  Finished = 'FINISHED',
  Cancelled = 'CANCELLED',
  Postponed = 'POSTPONED',
  Suspended = 'SUSPENDED',
  NotPlayed = 'NOT_PLAYED'
}

export type CompetitionSummaries = {
  __typename?: 'CompetitionSummaries';
  competitionSummaries: Array<CompetitionSummary>;
  pagination: OffsetPagination;
};

export type CompetitionSummary = {
  __typename?: 'CompetitionSummary';
  leagueName: Scalars['String'];
  seasonName: Scalars['String'];
  competitionId: Scalars['String'];
  competitionStartTime: Scalars['Date'];
  competitionTimeZone: Scalars['String'];
  competitionLocation?: Maybe<Scalars['String']>;
  competitionExternalId?: Maybe<Scalars['String']>;
  competitionOutcome?: Maybe<CompetitionOutcome>;
  competitionVerificationStatus: CompetitionVerificationStatuses;
  homeTeamId: Scalars['String'];
  homeTeamName: Scalars['String'];
  homeTeamAbbreviation?: Maybe<Scalars['String']>;
  homeTeamLogo?: Maybe<Scalars['String']>;
  homeTeamScore?: Maybe<Scalars['Int']>;
  homeTeamTieBreakerScore?: Maybe<Scalars['Int']>;
  awayTeamId: Scalars['String'];
  awayTeamName: Scalars['String'];
  awayTeamAbbreviation?: Maybe<Scalars['String']>;
  awayTeamLogo?: Maybe<Scalars['String']>;
  awayTeamScore?: Maybe<Scalars['Int']>;
  awayTeamTieBreakerScore?: Maybe<Scalars['Int']>;
  hasSeasonTeamsAssessment: Scalars['Boolean'];
  hasSeasonOfficialsAssessment: Scalars['Boolean'];
  hasSeasonOfficialsDynamicSurvey: Scalars['Boolean'];
  hasSeasonOfficialsDynamicSurveyAssessment: Scalars['Boolean'];
  hasSeasonTeamKit: Scalars['Boolean'];
  homeTeamAssessmentCreated: Scalars['Boolean'];
  awayTeamAssessmentCreated: Scalars['Boolean'];
  officialsAssessmentCreated: Scalars['Boolean'];
  officialsDynamicSurveyCreated: Scalars['Boolean'];
  teamKitCreated: Scalars['Boolean'];
  homeTeamSubmitted: Scalars['Boolean'];
  awayTeamSubmitted: Scalars['Boolean'];
  officialsAssessmentSubmitted: Scalars['Boolean'];
  officialsDynamicSurveyAssessmentSubmitted: Scalars['Boolean'];
  officialsDynamicSurveySubmitted: Scalars['Boolean'];
  totalKeyIncidents: Scalars['Float'];
  totalOpenNormalKeyIncidents: Scalars['Float'];
  totalOpenKeyIncidentsImpactingGameOutcome: Scalars['Float'];
  totalResolvedKeyIncidents: Scalars['Float'];
  totalSupplementalReportCount?: Maybe<Scalars['Float']>;
  totalOpenSupplementalReportCount?: Maybe<Scalars['Float']>;
  totalApprovedSupplementalReportCount?: Maybe<Scalars['Float']>;
  officialsByHomeTeamAverageCompetitionScore?: Maybe<Scalars['Float']>;
  homeTeamByOfficialsAverageCompetitionScore?: Maybe<Scalars['Float']>;
  officialsByAwayTeamAverageCompetitionScore?: Maybe<Scalars['Float']>;
  awayTeamByOfficialsAverageCompetitionScore?: Maybe<Scalars['Float']>;
  officialDynamicSurveyId?: Maybe<Scalars['String']>;
  officialDynamicSurveyName?: Maybe<Scalars['String']>;
  competitionTeamKitId?: Maybe<Scalars['String']>;
  competitionTeamKitStatus?: Maybe<CompetitionTeamKitStatus>;
};

export type CompetitionSurvey = {
  __typename?: 'CompetitionSurvey';
  id: Scalars['String'];
  competition: Competition;
  teamId?: Maybe<Scalars['String']>;
  teamName?: Maybe<Scalars['String']>;
  surveyId: Scalars['String'];
  orgId: Scalars['String'];
  seasonId?: Maybe<Scalars['String']>;
  recipients: Array<CompetitionSurveyUser>;
  sport: Sports;
  finished: Scalars['Boolean'];
  hasCompetitionVerification: Scalars['Boolean'];
  hasIncidentReport: Scalars['Boolean'];
  allowAdditionalIncidentReports: Scalars['Boolean'];
  allowAdditionalIncidentReportsCutoffDays?: Maybe<Scalars['Int']>;
  info: CompetitionSurveyInfo;
  sections: Array<CompetitionSurveySection>;
  completedDate?: Maybe<Scalars['Date']>;
  startedDate?: Maybe<Scalars['Date']>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type CompetitionSurveyInfo = {
  __typename?: 'CompetitionSurveyInfo';
  name: Scalars['String'];
  description: Scalars['String'];
  audience: SurveyAudiences;
};

export type CompetitionSurveyResponse = {
  __typename?: 'CompetitionSurveyResponse';
  id: Scalars['String'];
  orgInfo: CompetitionSurveyResponseOrgInfo;
  surveyInfo: CompetitionSurveyResponseSurveyInfo;
  competitionInfo: CompetitionSurveyResponseCompetitionInfo;
  userInfo?: Maybe<CompetitionSurveyResponseUserInfo>;
  teamId?: Maybe<Scalars['String']>;
  teamName?: Maybe<Scalars['String']>;
  completedDate: Scalars['Date'];
  sections: Array<CompetitionSurveyResponseSection>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type CompetitionSurveyResponseCompetitionInfo = {
  __typename?: 'CompetitionSurveyResponseCompetitionInfo';
  competitionId: Scalars['String'];
  competitionDate: Scalars['Date'];
  externalId?: Maybe<Scalars['String']>;
  homeTeamId: Scalars['String'];
  homeTeam: Scalars['String'];
  homeTeamAbbreviation?: Maybe<Scalars['String']>;
  homeTeamScore?: Maybe<Scalars['Int']>;
  homeTeamTieBreakerScore?: Maybe<Scalars['Int']>;
  awayTeamId: Scalars['String'];
  awayTeam: Scalars['String'];
  awayTeamAbbreviation?: Maybe<Scalars['String']>;
  awayTeamScore?: Maybe<Scalars['Int']>;
  awayTeamTieBreakerScore?: Maybe<Scalars['Int']>;
  outcome?: Maybe<CompetitionOutcome>;
  verificationStatus: CompetitionVerificationStatuses;
};

export type CompetitionSurveyResponseOrgInfo = {
  __typename?: 'CompetitionSurveyResponseOrgInfo';
  sport: Sports;
  orgId: Scalars['String'];
  orgName: Scalars['String'];
  leagueId?: Maybe<Scalars['String']>;
  leagueName?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  seasonName?: Maybe<Scalars['String']>;
};

export type CompetitionSurveyResponseSection = {
  __typename?: 'CompetitionSurveyResponseSection';
  id: Scalars['String'];
  assessmentObject: AssessmentObject;
  official?: Maybe<Official>;
  coach?: Maybe<Coach>;
  organization?: Maybe<Organization>;
  questions: Array<CompetitionSurveyResponseSectionQuestion>;
  name: Scalars['String'];
  description: Scalars['String'];
  order: Scalars['Int'];
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type CompetitionSurveyResponseSectionQuestion = {
  __typename?: 'CompetitionSurveyResponseSectionQuestion';
  id: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  questionType: SurveyQuestionTypes;
  order: Scalars['Int'];
  selectedOption?: Maybe<Scalars['String']>;
  selectedOptionOrder?: Maybe<Scalars['Int']>;
  responseShortAnswer?: Maybe<Scalars['String']>;
  responseText?: Maybe<Scalars['String']>;
  responseNumber?: Maybe<Scalars['Float']>;
  responses?: Maybe<Array<Scalars['String']>>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type CompetitionSurveyResponseSurveyInfo = {
  __typename?: 'CompetitionSurveyResponseSurveyInfo';
  surveyId: Scalars['String'];
  surveyName: Scalars['String'];
  audience: SurveyAudiences;
};

export type CompetitionSurveyResponseUserInfo = {
  __typename?: 'CompetitionSurveyResponseUserInfo';
  userId: Scalars['String'];
  coachId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  profilePic?: Maybe<Scalars['String']>;
};

export type CompetitionSurveyResponses = {
  __typename?: 'CompetitionSurveyResponses';
  responses: Array<CompetitionSurveyResponse>;
  pagination: OffsetPagination;
};

export type CompetitionSurveySection = {
  __typename?: 'CompetitionSurveySection';
  id: Scalars['String'];
  finished: Scalars['Boolean'];
  assessmentObject: AssessmentObject;
  official?: Maybe<Official>;
  coach?: Maybe<Coach>;
  organization?: Maybe<Organization>;
  questions: Array<CompetitionSurveySectionQuestion>;
  name: Scalars['String'];
  description: Scalars['String'];
  order: Scalars['Int'];
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type CompetitionSurveySectionQuestion = {
  __typename?: 'CompetitionSurveySectionQuestion';
  id: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  isRequired: Scalars['Boolean'];
  canAddToKeyIncidents: Scalars['Boolean'];
  questionType: SurveyQuestionTypes;
  order: Scalars['Int'];
  options: Array<CompetitionSurveySectionQuestionOption>;
  selectedOption?: Maybe<Scalars['String']>;
  selectedOptionOrder?: Maybe<Scalars['Int']>;
  responseShortAnswer?: Maybe<Scalars['String']>;
  responseText?: Maybe<Scalars['String']>;
  responseNumber?: Maybe<Scalars['Float']>;
  responses?: Maybe<Array<CompetitionSurveySectionQuestionMultipleResponse>>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type CompetitionSurveySectionQuestionMultipleResponse = {
  __typename?: 'CompetitionSurveySectionQuestionMultipleResponse';
  id: Scalars['String'];
  value: Scalars['String'];
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type CompetitionSurveySectionQuestionOption = {
  __typename?: 'CompetitionSurveySectionQuestionOption';
  id: Scalars['String'];
  value: Scalars['String'];
  order: Scalars['Int'];
};

export type CompetitionSurveySummaries = {
  __typename?: 'CompetitionSurveySummaries';
  competitionSurveys: Array<CompetitionSurveySummary>;
  pagination: OffsetPagination;
};

export type CompetitionSurveySummary = {
  __typename?: 'CompetitionSurveySummary';
  competitionSurveyId: Scalars['String'];
  surveyName: Scalars['String'];
  finished: Scalars['Boolean'];
  completedDate?: Maybe<Scalars['Date']>;
  createdAt: Scalars['Date'];
  teamId?: Maybe<Scalars['String']>;
  teamName?: Maybe<Scalars['String']>;
  teamLogo?: Maybe<Scalars['String']>;
  competitionId: Scalars['String'];
  startTime: Scalars['Date'];
  homeTeamId: Scalars['String'];
  homeTeamName: Scalars['String'];
  homeTeamLogo?: Maybe<Scalars['String']>;
  awayTeamId: Scalars['String'];
  awayTeamName: Scalars['String'];
  awayTeamLogo?: Maybe<Scalars['String']>;
  tookSurvey?: Maybe<Scalars['String']>;
  tookSurveyUserId?: Maybe<Scalars['String']>;
  averageScore?: Maybe<Scalars['Float']>;
  keyIncidentsCount?: Maybe<Scalars['Float']>;
  isDynamic: Scalars['Boolean'];
  completedPercentage?: Maybe<Scalars['Float']>;
};

export type CompetitionSurveyUser = {
  __typename?: 'CompetitionSurveyUser';
  userId: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  tookSurvey: Scalars['Boolean'];
  profilePic?: Maybe<Scalars['String']>;
};

export type CompetitionTeamKit = {
  __typename?: 'CompetitionTeamKit';
  id: Scalars['String'];
  organizationId: Scalars['String'];
  organizationName: Scalars['String'];
  seasonId: Scalars['String'];
  seasonName: Scalars['String'];
  seasonTeamKitId: Scalars['String'];
  competitionId: Scalars['String'];
  startTime: Scalars['Date'];
  timeZone: Scalars['String'];
  homeTeamId: Scalars['String'];
  homeTeamName: Scalars['String'];
  homeTeamLogo?: Maybe<Scalars['String']>;
  awayTeamId: Scalars['String'];
  awayTeamName: Scalars['String'];
  awayTeamLogo?: Maybe<Scalars['String']>;
  status: CompetitionTeamKitStatus;
  completedDate?: Maybe<Scalars['Date']>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type CompetitionTeamKitApparel = {
  __typename?: 'CompetitionTeamKitApparel';
  id: Scalars['String'];
  competitionTeamKitId: Scalars['String'];
  type: CompetitionTeamKitApparelType;
  status: CompetitionTeamKitApparelStatusType;
  seasonKitRoleId?: Maybe<Scalars['String']>;
  roleType: TeamKitRoleType;
  roleName: Scalars['String'];
  roleOrder: Scalars['Int'];
  seasonKitApparelDefinitionId?: Maybe<Scalars['String']>;
  definitionName: Scalars['String'];
  definitionOrder: Scalars['Int'];
  seasonApparelId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type CompetitionTeamKitApparelApprovalInput = {
  competitionTeamKitApparelId: Scalars['String'];
  approved: Scalars['Boolean'];
};

/** The Competition TeamKit Apparel Status Type */
export enum CompetitionTeamKitApparelStatusType {
  Pending = 'PENDING',
  Approved = 'APPROVED',
  ChangeRequested = 'CHANGE_REQUESTED'
}

/** The Competition TeamKit Apparel Type */
export enum CompetitionTeamKitApparelType {
  Official = 'OFFICIAL',
  HomeTeam = 'HOME_TEAM',
  AwayTeam = 'AWAY_TEAM'
}

export type CompetitionTeamKitApparels = {
  __typename?: 'CompetitionTeamKitApparels';
  apparels: Array<CompetitionTeamKitApparel>;
  pagination: OffsetPagination;
};

export type CompetitionTeamKitBroadcastOption = {
  __typename?: 'CompetitionTeamKitBroadcastOption';
  id: Scalars['String'];
  competitionTeamKitId: Scalars['String'];
  broadcastOptionId: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type CompetitionTeamKitBroadcastOptions = {
  __typename?: 'CompetitionTeamKitBroadcastOptions';
  broadcastOptions: Array<CompetitionTeamKitBroadcastOption>;
  pagination: OffsetPagination;
};

export type CompetitionTeamKitCompetitionInfo = {
  __typename?: 'CompetitionTeamKitCompetitionInfo';
  id: Scalars['String'];
  competitionTeamKitId: Scalars['String'];
  value: Scalars['String'];
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type CompetitionTeamKitCompetitionInfos = {
  __typename?: 'CompetitionTeamKitCompetitionInfos';
  competitionInfos: Array<CompetitionTeamKitCompetitionInfo>;
  pagination: OffsetPagination;
};

export type CompetitionTeamKitContact = {
  __typename?: 'CompetitionTeamKitContact';
  id: Scalars['String'];
  competitionTeamKitId: Scalars['String'];
  type: TeamKitContactType;
  name: Scalars['String'];
  order: Scalars['Int'];
  seasonContactDefinitionId?: Maybe<Scalars['String']>;
  teamContactId?: Maybe<Scalars['String']>;
  organizationContactId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type CompetitionTeamKitContacts = {
  __typename?: 'CompetitionTeamKitContacts';
  contacts: Array<CompetitionTeamKitContact>;
  pagination: OffsetPagination;
};

export type CompetitionTeamKitRecipient = {
  __typename?: 'CompetitionTeamKitRecipient';
  id: Scalars['String'];
  competitionTeamKitId: Scalars['String'];
  type: TeamKitRecipientType;
  userId: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  notificationSent?: Maybe<Scalars['Date']>;
  emailSent?: Maybe<Scalars['Date']>;
  profilePic?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type CompetitionTeamKitRequestFixInput = {
  competitionTeamKitId: Scalars['String'];
  forHomeTeam: Scalars['Boolean'];
};

/** The Competition TeamKit status */
export enum CompetitionTeamKitStatus {
  Pending = 'PENDING',
  LeagueStart = 'LEAGUE_START',
  HomeTeamEdit = 'HOME_TEAM_EDIT',
  AwayTeamEdit = 'AWAY_TEAM_EDIT',
  LeagueEdit = 'LEAGUE_EDIT',
  HomeTeamRevision = 'HOME_TEAM_REVISION',
  AwayTeamRevision = 'AWAY_TEAM_REVISION',
  Finished = 'FINISHED'
}

export type CompetitionTeamKits = {
  __typename?: 'CompetitionTeamKits';
  competitionTeamKits: Array<CompetitionTeamKit>;
  pagination: OffsetPagination;
};

/** The competition verification statuses */
export enum CompetitionVerificationStatuses {
  Pending = 'PENDING',
  Conflict = 'CONFLICT',
  Verified = 'VERIFIED'
}

export type Competitions = {
  __typename?: 'Competitions';
  competitions: Array<Competition>;
  pagination: OffsetPagination;
};

export type CompetitionsAvailableForAdditionalFeedback = {
  __typename?: 'CompetitionsAvailableForAdditionalFeedback';
  organizationId: Scalars['String'];
  organizationName: Scalars['String'];
  leagueId: Scalars['String'];
  leagueName: Scalars['String'];
  seasonId: Scalars['String'];
  seasonName: Scalars['String'];
  competitionId: Scalars['String'];
  competitionExternalId?: Maybe<Scalars['String']>;
  startTime: Scalars['Date'];
  sport: Sports;
  homeTeamId: Scalars['String'];
  homeTeamName: Scalars['String'];
  homeTeamAbbreviation?: Maybe<Scalars['String']>;
  awayTeamId: Scalars['String'];
  awayTeamName: Scalars['String'];
  awayTeamAbbreviation?: Maybe<Scalars['String']>;
  canAddKeyIncidentsToAssessments: Scalars['Boolean'];
  canAddKeyIncidentsToDynamicSurveys: Scalars['Boolean'];
  canAddSupplementalReportsToDynamicSurveys: Scalars['Boolean'];
};

export type CompleteCompetitionSurveyInput = {
  competitionSurveyId: Scalars['String'];
};

export type CompleteCompetitionSurveyWithTokenInput = {
  surveyToken: Scalars['String'];
};

export type CompleteDynamicCompetitionSurveyInput = {
  competitionSurveyId: Scalars['String'];
};

export type CompleteDynamicCompetitionSurveyWithTokenInput = {
  surveyToken: Scalars['String'];
};

export type ConfirmationStatus = {
  __typename?: 'ConfirmationStatus';
  isValid: Scalars['Boolean'];
};

export type ConvertCompetitionSurveySectionFeedbackToKeyIncidentInput = {
  competitionSurveyId: Scalars['String'];
  questionId: Scalars['String'];
  feedbackResponseId: Scalars['String'];
  incidentType: KeyIncidentTypes;
  impactedOutcome: Scalars['Boolean'];
  time?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  periodId?: Maybe<Scalars['String']>;
  externalLinks: Array<CreateKeyIncidentExternalLinkForCompetitionSurveyInput>;
};

export type ConvertCompetitionSurveySectionFeedbackToKeyIncidentWithTokenInput = {
  surveyToken: Scalars['String'];
  questionId: Scalars['String'];
  feedbackResponseId: Scalars['String'];
  incidentType: KeyIncidentTypes;
  impactedOutcome: Scalars['Boolean'];
  time?: Maybe<Scalars['String']>;
  periodId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  externalLinks: Array<CreateKeyIncidentExternalLinkForCompetitionSurveyInput>;
};

export type ConvertDynamicCompetitionSurveyMultipleResponseToKeyIncidentInput = {
  competitionSurveyId: Scalars['String'];
  questionId: Scalars['String'];
  feedbackResponseId: Scalars['String'];
  incidentType: KeyIncidentTypes;
  impactedOutcome: Scalars['Boolean'];
  time?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type ConvertDynamicCompetitionSurveyMultipleResponseToKeyIncidentWithTokenInput = {
  surveyToken: Scalars['String'];
  questionId: Scalars['String'];
  feedbackResponseId: Scalars['String'];
  incidentType: KeyIncidentTypes;
  impactedOutcome: Scalars['Boolean'];
  time?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type ConvertDynamicCompetitionSurveySubMultipleResponseToKeyIncidentInput = {
  competitionSurveyId: Scalars['String'];
  subQuestionId: Scalars['String'];
  feedbackResponseId: Scalars['String'];
  incidentType: KeyIncidentTypes;
  impactedOutcome: Scalars['Boolean'];
  time?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type ConvertDynamicCompetitionSurveySubMultipleResponseToKeyIncidentWithTokenInput = {
  surveyToken: Scalars['String'];
  subQuestionId: Scalars['String'];
  feedbackResponseId: Scalars['String'];
  incidentType: KeyIncidentTypes;
  impactedOutcome: Scalars['Boolean'];
  time?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

/** Supported country codes */
export enum CountryCodes {
  UnitedStates = 'UNITED_STATES',
  Canada = 'CANADA',
  Mexico = 'MEXICO',
  Australia = 'AUSTRALIA',
  UnitedKingdom = 'UNITED_KINGDOM',
  Germany = 'GERMANY',
  Spain = 'SPAIN',
  Italy = 'ITALY',
  France = 'FRANCE',
  Netherlands = 'NETHERLANDS',
  Brazil = 'BRAZIL',
  Croatia = 'CROATIA',
  Colombia = 'COLOMBIA',
  SouthKorea = 'SOUTH_KOREA',
  Argentina = 'ARGENTINA',
  Denmark = 'DENMARK',
  Belgium = 'BELGIUM',
  Portugal = 'PORTUGAL',
  Afghanistan = 'AFGHANISTAN',
  AlandIslands = 'ALAND_ISLANDS',
  Albania = 'ALBANIA',
  Algeria = 'ALGERIA',
  AmericanSamoa = 'AMERICAN_SAMOA',
  Andorra = 'ANDORRA',
  Angola = 'ANGOLA',
  Anguilla = 'ANGUILLA',
  Antarctica = 'ANTARCTICA',
  AntiguaAndBarbuda = 'ANTIGUA_AND_BARBUDA',
  Armenia = 'ARMENIA',
  Aruba = 'ARUBA',
  Austria = 'AUSTRIA',
  Azerbaijan = 'AZERBAIJAN',
  Bahamas = 'BAHAMAS',
  Bahrain = 'BAHRAIN',
  Bangladesh = 'BANGLADESH',
  Barbados = 'BARBADOS',
  Belarus = 'BELARUS',
  Belize = 'BELIZE',
  Benin = 'BENIN',
  Bermuda = 'BERMUDA',
  Bhutan = 'BHUTAN',
  Bolivia = 'BOLIVIA',
  BosniaAndHerzegovina = 'BOSNIA_AND_HERZEGOVINA',
  Botswana = 'BOTSWANA',
  BouvetIsland = 'BOUVET_ISLAND',
  BritishVirginIslands = 'BRITISH_VIRGIN_ISLANDS',
  BritishIndianOceanTerritory = 'BRITISH_INDIAN_OCEAN_TERRITORY',
  BruneiDarussalam = 'BRUNEI_DARUSSALAM',
  Bulgaria = 'BULGARIA',
  BurkinaFaso = 'BURKINA_FASO',
  Burundi = 'BURUNDI',
  Cambodia = 'CAMBODIA',
  Cameroon = 'CAMEROON',
  CapeVerde = 'CAPE_VERDE',
  CaymanIslands = 'CAYMAN_ISLANDS',
  CentralAfricanRepublic = 'CENTRAL_AFRICAN_REPUBLIC',
  Chad = 'CHAD',
  Chile = 'CHILE',
  China = 'CHINA',
  HongKongSarChina = 'HONG_KONG_SAR_CHINA',
  MacaoSarChina = 'MACAO_SAR_CHINA',
  ChristmasIsland = 'CHRISTMAS_ISLAND',
  CocosKeelingIslands = 'COCOS_KEELING_ISLANDS',
  Comoros = 'COMOROS',
  CongoBrazzaville = 'CONGO_BRAZZAVILLE',
  CongoKinshasa = 'CONGO_KINSHASA',
  CookIslands = 'COOK_ISLANDS',
  CostaRica = 'COSTA_RICA',
  CoteDIvoire = 'COTE_D_IVOIRE',
  Cuba = 'CUBA',
  Cyprus = 'CYPRUS',
  CzechRepublic = 'CZECH_REPUBLIC',
  Djibouti = 'DJIBOUTI',
  Dominica = 'DOMINICA',
  DominicanRepublic = 'DOMINICAN_REPUBLIC',
  Ecuador = 'ECUADOR',
  Egypt = 'EGYPT',
  ElSalvador = 'EL_SALVADOR',
  EquatorialGuinea = 'EQUATORIAL_GUINEA',
  Eritrea = 'ERITREA',
  Estonia = 'ESTONIA',
  Ethiopia = 'ETHIOPIA',
  FalklandIslandsMalvinas = 'FALKLAND_ISLANDS_MALVINAS',
  FaroeIslands = 'FAROE_ISLANDS',
  Fiji = 'FIJI',
  Finland = 'FINLAND',
  FrenchGuiana = 'FRENCH_GUIANA',
  FrenchPolynesia = 'FRENCH_POLYNESIA',
  FrenchSouthernTerritories = 'FRENCH_SOUTHERN_TERRITORIES',
  Gabon = 'GABON',
  Gambia = 'GAMBIA',
  Georgia = 'GEORGIA',
  Ghana = 'GHANA',
  Gibraltar = 'GIBRALTAR',
  Greece = 'GREECE',
  Greenland = 'GREENLAND',
  Grenada = 'GRENADA',
  Guadeloupe = 'GUADELOUPE',
  Guam = 'GUAM',
  Guatemala = 'GUATEMALA',
  Guernsey = 'GUERNSEY',
  Guinea = 'GUINEA',
  GuineaBissau = 'GUINEA_BISSAU',
  Guyana = 'GUYANA',
  Haiti = 'HAITI',
  HeardAndMcdonaldIslands = 'HEARD_AND_MCDONALD_ISLANDS',
  HolySeeVaticanCityState = 'HOLY_SEE_VATICAN_CITY_STATE',
  Honduras = 'HONDURAS',
  Hungary = 'HUNGARY',
  Iceland = 'ICELAND',
  India = 'INDIA',
  Indonesia = 'INDONESIA',
  IranIslamicRepublicOf = 'IRAN_ISLAMIC_REPUBLIC_OF',
  Iraq = 'IRAQ',
  Ireland = 'IRELAND',
  IsleOfMan = 'ISLE_OF_MAN',
  Israel = 'ISRAEL',
  Jamaica = 'JAMAICA',
  Japan = 'JAPAN',
  Jersey = 'JERSEY',
  Jordan = 'JORDAN',
  Kazakhstan = 'KAZAKHSTAN',
  Kenya = 'KENYA',
  Kiribati = 'KIRIBATI',
  KoreaNorth = 'KOREA_NORTH',
  KoreaSouth = 'KOREA_SOUTH',
  Kuwait = 'KUWAIT',
  Kyrgyzstan = 'KYRGYZSTAN',
  LaoPdr = 'LAO_PDR',
  Latvia = 'LATVIA',
  Lebanon = 'LEBANON',
  Lesotho = 'LESOTHO',
  Liberia = 'LIBERIA',
  Libya = 'LIBYA',
  Liechtenstein = 'LIECHTENSTEIN',
  Lithuania = 'LITHUANIA',
  Luxembourg = 'LUXEMBOURG',
  MacedoniaRepublicOf = 'MACEDONIA_REPUBLIC_OF',
  Madagascar = 'MADAGASCAR',
  Malawi = 'MALAWI',
  Malaysia = 'MALAYSIA',
  Maldives = 'MALDIVES',
  Mali = 'MALI',
  Malta = 'MALTA',
  MarshallIslands = 'MARSHALL_ISLANDS',
  Martinique = 'MARTINIQUE',
  Mauritania = 'MAURITANIA',
  Mauritius = 'MAURITIUS',
  Mayotte = 'MAYOTTE',
  MicronesiaFederatedStatesOf = 'MICRONESIA_FEDERATED_STATES_OF',
  Moldova = 'MOLDOVA',
  Monaco = 'MONACO',
  Mongolia = 'MONGOLIA',
  Montenegro = 'MONTENEGRO',
  Montserrat = 'MONTSERRAT',
  Morocco = 'MOROCCO',
  Mozambique = 'MOZAMBIQUE',
  Myanmar = 'MYANMAR',
  Namibia = 'NAMIBIA',
  Nauru = 'NAURU',
  Nepal = 'NEPAL',
  NetherlandsAntilles = 'NETHERLANDS_ANTILLES',
  NewCaledonia = 'NEW_CALEDONIA',
  NewZealand = 'NEW_ZEALAND',
  Nicaragua = 'NICARAGUA',
  Niger = 'NIGER',
  Nigeria = 'NIGERIA',
  Niue = 'NIUE',
  NorfolkIsland = 'NORFOLK_ISLAND',
  NorthernMarianaIslands = 'NORTHERN_MARIANA_ISLANDS',
  Norway = 'NORWAY',
  Oman = 'OMAN',
  Pakistan = 'PAKISTAN',
  Palau = 'PALAU',
  PalestinianTerritory = 'PALESTINIAN_TERRITORY',
  Panama = 'PANAMA',
  PapuaNewGuinea = 'PAPUA_NEW_GUINEA',
  Paraguay = 'PARAGUAY',
  Peru = 'PERU',
  Philippines = 'PHILIPPINES',
  Pitcairn = 'PITCAIRN',
  Poland = 'POLAND',
  PuertoRico = 'PUERTO_RICO',
  Qatar = 'QATAR',
  Reunion = 'REUNION',
  Romania = 'ROMANIA',
  RussianFederation = 'RUSSIAN_FEDERATION',
  Rwanda = 'RWANDA',
  SaintBarthelemy = 'SAINT_BARTHELEMY',
  SaintHelena = 'SAINT_HELENA',
  SaintKittsAndNevis = 'SAINT_KITTS_AND_NEVIS',
  SaintLucia = 'SAINT_LUCIA',
  SaintMartinFrenchPart = 'SAINT_MARTIN_FRENCH_PART',
  SaintPierreAndMiquelon = 'SAINT_PIERRE_AND_MIQUELON',
  SaintVincentAndGrenadines = 'SAINT_VINCENT_AND_GRENADINES',
  Samoa = 'SAMOA',
  SanMarino = 'SAN_MARINO',
  SaoTomeAndPrincipe = 'SAO_TOME_AND_PRINCIPE',
  SaudiArabia = 'SAUDI_ARABIA',
  Senegal = 'SENEGAL',
  Serbia = 'SERBIA',
  Seychelles = 'SEYCHELLES',
  SierraLeone = 'SIERRA_LEONE',
  Singapore = 'SINGAPORE',
  Slovakia = 'SLOVAKIA',
  Slovenia = 'SLOVENIA',
  SolomonIslands = 'SOLOMON_ISLANDS',
  Somalia = 'SOMALIA',
  SouthAfrica = 'SOUTH_AFRICA',
  SouthGeorgiaAndTheSouthSandwichIslands = 'SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS',
  SouthSudan = 'SOUTH_SUDAN',
  SriLanka = 'SRI_LANKA',
  Sudan = 'SUDAN',
  Suriname = 'SURINAME',
  SvalbardAndJanMayenIslands = 'SVALBARD_AND_JAN_MAYEN_ISLANDS',
  Swaziland = 'SWAZILAND',
  Sweden = 'SWEDEN',
  Switzerland = 'SWITZERLAND',
  SyrianArabRepublicSyria = 'SYRIAN_ARAB_REPUBLIC_SYRIA',
  TaiwanRepublicOfChina = 'TAIWAN_REPUBLIC_OF_CHINA',
  Tajikistan = 'TAJIKISTAN',
  TanzaniaUnitedRepublicOf = 'TANZANIA_UNITED_REPUBLIC_OF',
  Thailand = 'THAILAND',
  TimorLeste = 'TIMOR_LESTE',
  Togo = 'TOGO',
  Tokelau = 'TOKELAU',
  Tonga = 'TONGA',
  TrinidadAndTobago = 'TRINIDAD_AND_TOBAGO',
  Tunisia = 'TUNISIA',
  Turkey = 'TURKEY',
  Turkmenistan = 'TURKMENISTAN',
  TurksAndCaicosIslands = 'TURKS_AND_CAICOS_ISLANDS',
  Tuvalu = 'TUVALU',
  Uganda = 'UGANDA',
  Ukraine = 'UKRAINE',
  UnitedArabEmirates = 'UNITED_ARAB_EMIRATES',
  UsMinorOutlyingIslands = 'US_MINOR_OUTLYING_ISLANDS',
  Uruguay = 'URUGUAY',
  Uzbekistan = 'UZBEKISTAN',
  Vanuatu = 'VANUATU',
  VenezuelaBolivarianRepublic = 'VENEZUELA_BOLIVARIAN_REPUBLIC',
  VietNam = 'VIET_NAM',
  VirginIslandsUs = 'VIRGIN_ISLANDS_US',
  WallisAndFutunaIslands = 'WALLIS_AND_FUTUNA_ISLANDS',
  WesternSahara = 'WESTERN_SAHARA',
  Yemen = 'YEMEN',
  Zambia = 'ZAMBIA',
  Zimbabwe = 'ZIMBABWE'
}

export type CreateAdminSurveyTemplateInput = {
  sport: Sports;
  name: Scalars['String'];
  description: Scalars['String'];
  audience: SurveyAudiences;
  sendDelayMinutes: Scalars['Int'];
};

export type CreateAssessmentObjectInput = {
  sport: Sports;
  type: AssessmentObjectTypes;
  name: Scalars['String'];
  classification?: Maybe<AssessmentObjectClassifications>;
};

export type CreateCompetitionForSeasonInput = {
  seasonId: Scalars['String'];
  homeTeamId: Scalars['String'];
  awayTeamId: Scalars['String'];
  startTime: Scalars['Date'];
  timeZone: Scalars['String'];
  externalId?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  homeTeamScore?: Maybe<Scalars['Int']>;
  awayTeamScore?: Maybe<Scalars['Int']>;
  homeTeamTieBreakerScore?: Maybe<Scalars['Int']>;
  awayTeamTieBreakerScore?: Maybe<Scalars['Int']>;
  status: CompetitionStatuses;
};

export type CreateCompetitionIntegrationForOrganizationAdditionalOptionInput = {
  name: Scalars['String'];
  isRequired: Scalars['Boolean'];
};

export type CreateCompetitionIntegrationForOrganizationInput = {
  orgId: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  status: CompetitionIntegrationStatuses;
  queueName: Scalars['String'];
  additionalOptions: Array<CreateCompetitionIntegrationForOrganizationAdditionalOptionInput>;
};

export type CreateCompetitionStatForCompetitionInput = {
  competitionId: Scalars['String'];
  teamId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  displayText: Scalars['String'];
  valueString?: Maybe<Scalars['String']>;
  valueNumeric?: Maybe<Scalars['Float']>;
  valueDate?: Maybe<Scalars['Date']>;
};

export type CreateDynamicSeasonSurveyInput = {
  orgId: Scalars['String'];
  seasonId: Scalars['String'];
  integrationId: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  audience: SurveyAudiences;
  recipientAssessmentObjectId?: Maybe<Scalars['String']>;
  sendDelayMinutes: Scalars['Int'];
  reminderType: SurveyReminderTypes;
  dailyReminders?: Maybe<SurveyDailyRemindersInput>;
  levelReminders?: Maybe<SurveyLevelRemindersInput>;
};

export type CreateDynamicSurveyIntegrationForOrganizationInput = {
  orgId: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  status: DynamicSurveyIntegrationStatuses;
  queueName: Scalars['String'];
};

export type CreateKeyIncidentExternalLinkForCompetitionSurveyInput = {
  link: Scalars['String'];
  type: KeyIncidentExternalLinkTypes;
  description?: Maybe<Scalars['String']>;
};

export type CreateKeyIncidentVideoIntegrationForOrganizationInput = {
  orgId: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  status: KeyIncidentVideoIntegrationStatuses;
  queueName: Scalars['String'];
  enableRetry: Scalars['Boolean'];
  maxRetries?: Maybe<Scalars['Int']>;
  retryIntervalMinutes?: Maybe<Scalars['Int']>;
};

export type CreateLeagueInput = {
  orgId: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  abbreviation?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
};

export type CreateMediaOrganizationInput = {
  name: Scalars['String'];
  abbreviation?: Maybe<Scalars['String']>;
  site?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
};

export type CreateNewOfficialForSeasonInput = {
  seasonId: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  externalId?: Maybe<Scalars['String']>;
  sendEmail: Scalars['Boolean'];
};

export type CreateNewOrganizationUserInput = {
  organizationId: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  roles: Array<OrganizationUserRoles>;
  permissions: Array<OrganizationUserPermissions>;
  title?: Maybe<Scalars['String']>;
};

export type CreateNewOrganizationUserResponse = {
  __typename?: 'CreateNewOrganizationUserResponse';
  user: User;
  emailSent: Scalars['Boolean'];
};

export type CreateNewSeasonCoachInput = {
  seasonId: Scalars['String'];
  teamId: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  type: CoachTypes;
  status: CoachStatuses;
  role?: Maybe<Scalars['String']>;
  addToOpenSurveys: Scalars['Boolean'];
};

export type CreateNewTeamForSeasonInput = {
  seasonId: Scalars['String'];
  name: Scalars['String'];
  abbreviation?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  stateAbbreviation?: Maybe<Scalars['String']>;
  countryCode: CountryCodes;
  timeZone: Scalars['String'];
  classification: TeamClassifications;
  gender: TeamGenders;
  parentOrganizationName?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  ageGroup?: Maybe<Scalars['String']>;
};

export type CreateOfficialExternalIdInput = {
  organizationId: Scalars['String'];
  externalId?: Maybe<Scalars['String']>;
};

export type CreateOfficialInput = {
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  externalId?: Maybe<CreateOfficialExternalIdInput>;
  sport: Sports;
};

export type CreateOrganizationContactInput = {
  organizationId: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type CreateOrganizationInput = {
  sport: Sports;
  name: Scalars['String'];
  abbreviation: Scalars['String'];
  site?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
};

export type CreateOrganizationSurveyTemplateInput = {
  orgId: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  audience: SurveyAudiences;
  sendDelayMinutes: Scalars['Int'];
};

export type CreatePeriodInput = {
  sport: Sports;
  name: Scalars['String'];
};

export type CreateSeasonCompetitionIntegrationInput = {
  orgId: Scalars['String'];
  seasonId: Scalars['String'];
  integrationId: Scalars['String'];
};

export type CreateSeasonDivisionAliasInput = {
  seasonDivisionId: Scalars['String'];
  alias: Scalars['String'];
};

export type CreateSeasonDivisionInput = {
  seasonId: Scalars['String'];
  name: Scalars['String'];
};

export type CreateSeasonInput = {
  leagueId: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
};

export type CreateSeasonMediaMembersConfigInput = {
  orgId: Scalars['String'];
  seasonId: Scalars['String'];
  allowIncidentReports: Scalars['Boolean'];
  allowIncidentReportsCutoffDays?: Maybe<Scalars['Int']>;
  allowIncidentReportsDelayMinutes?: Maybe<Scalars['Int']>;
};

export type CreateSeasonSurveyFromTemplateInput = {
  templateId: Scalars['String'];
  orgId: Scalars['String'];
  seasonId: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  sendDelayMinutes: Scalars['Int'];
  reminderType: SurveyReminderTypes;
  dailyReminders?: Maybe<SurveyDailyRemindersInput>;
  levelReminders?: Maybe<SurveyLevelRemindersInput>;
  orgMembershipId?: Maybe<Scalars['String']>;
  recipientAssessmentObjectId?: Maybe<Scalars['String']>;
};

export type CreateSeasonSurveyInput = {
  orgId: Scalars['String'];
  seasonId: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  audience: SurveyAudiences;
  sendDelayMinutes: Scalars['Int'];
  reminderType: SurveyReminderTypes;
  dailyReminders?: Maybe<SurveyDailyRemindersInput>;
  levelReminders?: Maybe<SurveyLevelRemindersInput>;
  recipientAssessmentObjectId?: Maybe<Scalars['String']>;
};

export type CreateSeasonTeamKitApparelDefinitionInput = {
  seasonId: Scalars['String'];
  value: Scalars['String'];
};

export type CreateSeasonTeamKitContactDefinitionInput = {
  seasonId: Scalars['String'];
  type: TeamKitContactType;
  value: Scalars['String'];
};

export type CreateSeasonTeamKitContactOptionInput = {
  seasonId: Scalars['String'];
  contactDefinitionId: Scalars['String'];
  orgContactId?: Maybe<Scalars['String']>;
  teamId?: Maybe<Scalars['String']>;
  teamContactId?: Maybe<Scalars['String']>;
  isDefault: Scalars['Boolean'];
};

export type CreateSeasonTeamKitInput = {
  orgId: Scalars['String'];
  seasonId: Scalars['String'];
  sendPreCompetitionDays: Scalars['Int'];
  sendReminders: Scalars['Boolean'];
  sendReminderDays: Scalars['Int'];
  sendReminderCutoffDays: Scalars['Int'];
};

export type CreateTeamAliasInput = {
  teamId: Scalars['String'];
  alias: Scalars['String'];
};

export type CreateTeamContactForSeasonInput = {
  teamId: Scalars['String'];
  seasonId: Scalars['String'];
  organizationId: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type CreateTeamForSeasonInput = {
  organizationId: Scalars['String'];
  seasonId: Scalars['String'];
  name: Scalars['String'];
  abbreviation?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  stateAbbreviation?: Maybe<Scalars['String']>;
  countryCode: CountryCodes;
  timeZone: Scalars['String'];
  classification: TeamClassifications;
  gender: TeamGenders;
  parentOrganizationName?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  ageGroup?: Maybe<Scalars['String']>;
};

export type CreateTeamKitBroadcastOptionForSeasonInput = {
  seasonId: Scalars['String'];
  teamId?: Maybe<Scalars['String']>;
  value: Scalars['String'];
  isActive: Scalars['Boolean'];
};

export type CreateTeamKitRecipientForSeasonInput = {
  organizationId: Scalars['String'];
  seasonId: Scalars['String'];
  teamId: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  role?: Maybe<Scalars['String']>;
  addToExistingCompetitions: Scalars['Boolean'];
};

export type CreateTeamKitRoleForSeasonInput = {
  seasonId: Scalars['String'];
  value: Scalars['String'];
  type: TeamKitRoleType;
};

export type CreateTeamKitSeasonApparelInput = {
  seasonId: Scalars['String'];
  kitRoleId: Scalars['String'];
  kitApparelId: Scalars['String'];
  teamId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  color: Scalars['String'];
  isActive: Scalars['Boolean'];
};

export type CreateTeamKitSeasonKitInput = {
  seasonId: Scalars['String'];
  kitRoleId: Scalars['String'];
  definitionId: Scalars['String'];
  apparelId: Scalars['String'];
  teamId?: Maybe<Scalars['String']>;
  type: TeamKitType;
};


export type DeactivateCoachInput = {
  coachId: Scalars['String'];
  seasonId?: Maybe<Scalars['String']>;
  deactivateUser: Scalars['Boolean'];
};

export type DeactivateDynamicSurveyInput = {
  id: Scalars['String'];
};

export type DeactivateMediaMembersForSeasonInput = {
  orgId: Scalars['String'];
  seasonId: Scalars['String'];
  seasonMediaMembersConfigId: Scalars['String'];
};

export type DeactivateOfficialInput = {
  id: Scalars['String'];
};

export type DeactivateSeasonInput = {
  id: Scalars['String'];
};

export type DeactivateSurveyInput = {
  id: Scalars['String'];
};

export type DeactivateTeamKitForSeasonInput = {
  orgId: Scalars['String'];
  seasonId: Scalars['String'];
  seasonTeamKitId: Scalars['String'];
};

export type DeleteAssessmentObjectInput = {
  id: Scalars['String'];
};

export type DeleteCompetitionInput = {
  id: Scalars['String'];
};

export type DeleteCompetitionIntegrationInput = {
  integrationId: Scalars['String'];
};

export type DeleteCompetitionStatInput = {
  competitionStatId: Scalars['String'];
};

export type DeleteDynamicSurveyAssessmentObjectRecipientInput = {
  recipientId: Scalars['String'];
};

export type DeleteDynamicSurveyIncidentReportInput = {
  surveyId: Scalars['String'];
};

export type DeleteDynamicSurveyIncidentReportRecipientInput = {
  recipientId: Scalars['String'];
};

export type DeleteDynamicSurveyInput = {
  id: Scalars['String'];
};

export type DeleteDynamicSurveyIntegrationInput = {
  integrationId: Scalars['String'];
};

export type DeleteDynamicSurveyReminderLevelReportRecipientInput = {
  recipientId: Scalars['String'];
};

export type DeleteDynamicSurveySectionInput = {
  sectionId: Scalars['String'];
};

export type DeleteDynamicSurveySectionQuestionInput = {
  questionId: Scalars['String'];
};

export type DeleteDynamicSurveySectionQuestionOptionInput = {
  optionId: Scalars['String'];
};

export type DeleteDynamicSurveySectionSubQuestionInput = {
  subQuestionId: Scalars['String'];
};

export type DeleteDynamicSurveySectionSubQuestionOptionInput = {
  optionId: Scalars['String'];
};

export type DeleteDynamicSurveySignatureSectionInput = {
  surveyId: Scalars['String'];
};

export type DeleteDynamicSurveySummaryRecipientInput = {
  recipientId: Scalars['String'];
};

export type DeleteDynamicSurveySupplementalReportInput = {
  surveyId: Scalars['String'];
};

export type DeleteDynamicSurveySupplementalReportRecipientInput = {
  recipientId: Scalars['String'];
};

export type DeleteKeyIncidentExternalLinkInput = {
  externalLinkId: Scalars['String'];
};

export type DeleteKeyIncidentVideoIntegrationInput = {
  integrationId: Scalars['String'];
};

export type DeleteLeagueInput = {
  id: Scalars['String'];
};

export type DeleteMediaOrganizationInput = {
  id: Scalars['String'];
};

export type DeleteMyAlertInput = {
  id: Scalars['String'];
};

export type DeleteMySelectedAlertsInput = {
  ids: Array<Scalars['String']>;
};

export type DeleteOfficialInput = {
  id: Scalars['String'];
};

export type DeleteOrganizationContactInput = {
  organizationId: Scalars['String'];
  contactId: Scalars['String'];
};

export type DeleteOrganizationInput = {
  id: Scalars['String'];
};

export type DeletePeriodInput = {
  id: Scalars['String'];
};

export type DeleteSeasonCompetitionIntegrationInput = {
  seasonIntegrationId: Scalars['String'];
};

export type DeleteSeasonDivisionAliasInput = {
  id: Scalars['String'];
};

export type DeleteSeasonDivisionInput = {
  id: Scalars['String'];
};

export type DeleteSeasonDivisionTeamInput = {
  seasonId: Scalars['String'];
  seasonDivisionTeamId: Scalars['String'];
};

export type DeleteSeasonInput = {
  id: Scalars['String'];
};

export type DeleteSeasonMediaMembersOrgRecipientInput = {
  orgId: Scalars['String'];
  seasonId: Scalars['String'];
  orgRecipientId: Scalars['String'];
};

export type DeleteSeasonTeamKitApparelDefinitionInput = {
  seasonId: Scalars['String'];
  definitionId: Scalars['String'];
};

export type DeleteSeasonTeamKitContactDefinitionInput = {
  seasonId: Scalars['String'];
  contactDefinitionId: Scalars['String'];
};

export type DeleteSeasonTeamKitOrgRecipientInput = {
  orgId: Scalars['String'];
  seasonId: Scalars['String'];
  orgRecipientId: Scalars['String'];
};

export type DeleteSurveyAssessmentObjectRecipientInput = {
  recipientId: Scalars['String'];
};

export type DeleteSurveyIncidentReportInput = {
  surveyId: Scalars['String'];
};

export type DeleteSurveyIncidentReportRecipientInput = {
  recipientId: Scalars['String'];
};

export type DeleteSurveyInput = {
  id: Scalars['String'];
};

export type DeleteSurveyReminderLevelReportRecipientInput = {
  recipientId: Scalars['String'];
};

export type DeleteSurveySectionInput = {
  sectionId: Scalars['String'];
};

export type DeleteSurveySectionQuestionInput = {
  questionId: Scalars['String'];
};

export type DeleteSurveySectionQuestionOptionInput = {
  optionId: Scalars['String'];
};

export type DeleteSurveyTemplateIncidentReportInput = {
  templateId: Scalars['String'];
};

export type DeleteSurveyTemplateInput = {
  id: Scalars['String'];
};

export type DeleteSurveyTemplateSectionInput = {
  sectionId: Scalars['String'];
};

export type DeleteSurveyTemplateSectionQuestionInput = {
  questionId: Scalars['String'];
};

export type DeleteSurveyTemplateSectionQuestionOptionInput = {
  optionId: Scalars['String'];
};

export type DeleteTeamAliasInput = {
  id: Scalars['String'];
};

export type DeleteTeamContactForSeasonInput = {
  teamId: Scalars['String'];
  seasonId: Scalars['String'];
  organizationId: Scalars['String'];
  contactId: Scalars['String'];
};

export type DeleteTeamInput = {
  id: Scalars['String'];
};

export type DeleteTeamKitBroadcastOptionForSeasonInput = {
  seasonId: Scalars['String'];
  teamId?: Maybe<Scalars['String']>;
  optionId: Scalars['String'];
};

export type DeleteTeamKitLeagueContactOptionForSeasonInput = {
  seasonId: Scalars['String'];
  teamId?: Maybe<Scalars['String']>;
  contactOptionId: Scalars['String'];
};

export type DeleteTeamKitRoleForSeasonInput = {
  seasonId: Scalars['String'];
  kitRoleId: Scalars['String'];
};

export type DeleteTeamKitSeasonApparelInput = {
  seasonId: Scalars['String'];
  kitRoleId: Scalars['String'];
  kitApparelId: Scalars['String'];
  teamId?: Maybe<Scalars['String']>;
  seasonApparelId: Scalars['String'];
};

export type DeleteTeamKitSeasonKitInput = {
  seasonId: Scalars['String'];
  teamId?: Maybe<Scalars['String']>;
  seasonKitId: Scalars['String'];
};

export type DisableSeasonCompetitionIntegrationInput = {
  integrationId: Scalars['String'];
};

export type DynamicCompetitionSurvey = {
  __typename?: 'DynamicCompetitionSurvey';
  id: Scalars['String'];
  competition: Competition;
  teamId?: Maybe<Scalars['String']>;
  teamName?: Maybe<Scalars['String']>;
  surveyId: Scalars['String'];
  orgId: Scalars['String'];
  seasonId?: Maybe<Scalars['String']>;
  recipients: Array<DynamicCompetitionSurveyUser>;
  sport: Sports;
  finished: Scalars['Boolean'];
  hasCompetitionVerification: Scalars['Boolean'];
  hasSupplementalReport: Scalars['Boolean'];
  allowAdditionalSupplementalReports: Scalars['Boolean'];
  allowAdditionalSupplementalReportsCutoffDays?: Maybe<Scalars['Int']>;
  hasIncidentReport: Scalars['Boolean'];
  allowAdditionalIncidentReports: Scalars['Boolean'];
  allowAdditionalIncidentReportsCutoffDays?: Maybe<Scalars['Int']>;
  hasSignatureSection: Scalars['Boolean'];
  signatureText?: Maybe<Scalars['String']>;
  info: DynamicCompetitionSurveyInfo;
  sections: Array<DynamicCompetitionSurveySection>;
  startedDate?: Maybe<Scalars['Date']>;
  completedDate?: Maybe<Scalars['Date']>;
  signature?: Maybe<DynamicCompetitionSurveySignature>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type DynamicCompetitionSurveyInfo = {
  __typename?: 'DynamicCompetitionSurveyInfo';
  name: Scalars['String'];
  description: Scalars['String'];
  audience: SurveyAudiences;
};

export type DynamicCompetitionSurveyIntegration = {
  __typename?: 'DynamicCompetitionSurveyIntegration';
  id: Scalars['String'];
  organizationId: Scalars['String'];
  organizationName: Scalars['String'];
  leagueId: Scalars['String'];
  leagueName: Scalars['String'];
  seasonId: Scalars['String'];
  seasonName: Scalars['String'];
  surveyName: Scalars['String'];
  surveyAudience: SurveyAudiences;
  competitionId: Scalars['String'];
  competitionExternalId?: Maybe<Scalars['String']>;
  competitionStartTime: Scalars['Date'];
  competitionTimeZone: Scalars['String'];
  homeTeamId: Scalars['String'];
  homeTeamName: Scalars['String'];
  awayTeamId: Scalars['String'];
  awayTeamName: Scalars['String'];
  integrationStatus: DynamicCompetitionSurveyIntegrationStatuses;
  integrationSent?: Maybe<Scalars['Date']>;
  integrationProcess?: Maybe<Scalars['Date']>;
  errorCount: Scalars['Int'];
};

export type DynamicCompetitionSurveyIntegrationError = {
  __typename?: 'DynamicCompetitionSurveyIntegrationError';
  id: Scalars['String'];
  survey: Scalars['String'];
  error: Scalars['String'];
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type DynamicCompetitionSurveyIntegrationErrors = {
  __typename?: 'DynamicCompetitionSurveyIntegrationErrors';
  errors: Array<DynamicCompetitionSurveyIntegrationError>;
  total: Scalars['Float'];
};

/** The dynamic survey competition survey integration statuses */
export enum DynamicCompetitionSurveyIntegrationStatuses {
  Pending = 'PENDING',
  WaitingRetry = 'WAITING_RETRY',
  IntegrationSent = 'INTEGRATION_SENT',
  IntegrationError = 'INTEGRATION_ERROR',
  IntegrationProcessing = 'INTEGRATION_PROCESSING',
  IntegrationProcessed = 'INTEGRATION_PROCESSED'
}

export type DynamicCompetitionSurveyIntegrations = {
  __typename?: 'DynamicCompetitionSurveyIntegrations';
  integrations: Array<DynamicCompetitionSurveyIntegration>;
  pagination: OffsetPagination;
};

export type DynamicCompetitionSurveySection = {
  __typename?: 'DynamicCompetitionSurveySection';
  id: Scalars['String'];
  finished: Scalars['Boolean'];
  questions: Array<DynamicCompetitionSurveySectionQuestion>;
  name: Scalars['String'];
  description: Scalars['String'];
  requireSupplementalReport: Scalars['Boolean'];
  supplementalReportType?: Maybe<SupplementalReportTypes>;
  order: Scalars['Int'];
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type DynamicCompetitionSurveySectionQuestion = {
  __typename?: 'DynamicCompetitionSurveySectionQuestion';
  id: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  additionalText1?: Maybe<Scalars['String']>;
  additionalText2?: Maybe<Scalars['String']>;
  additionalText3?: Maybe<Scalars['String']>;
  isRequired: Scalars['Boolean'];
  canAddToKeyIncidents: Scalars['Boolean'];
  assessmentObject: AssessmentObject;
  official?: Maybe<Official>;
  coach?: Maybe<Coach>;
  team?: Maybe<Team>;
  organization?: Maybe<Organization>;
  questionType: SurveyQuestionTypes;
  order: Scalars['Int'];
  options: Array<DynamicCompetitionSurveySectionQuestionOption>;
  selectedOption?: Maybe<Scalars['String']>;
  selectedOptionOrder?: Maybe<Scalars['Int']>;
  responseShortAnswer?: Maybe<Scalars['String']>;
  responseText?: Maybe<Scalars['String']>;
  responseNumber?: Maybe<Scalars['Float']>;
  responseBoolean?: Maybe<Scalars['Boolean']>;
  responses?: Maybe<Array<DynamicCompetitionSurveySectionQuestionMultipleResponse>>;
  fileUploads?: Maybe<Array<DynamicCompetitionSurveySectionQuestionFileUpload>>;
  subQuestions: Array<DynamicCompetitionSurveySectionSubQuestion>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type DynamicCompetitionSurveySectionQuestionFileUpload = {
  __typename?: 'DynamicCompetitionSurveySectionQuestionFileUpload';
  id: Scalars['String'];
  comment?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  fileUpload: Scalars['String'];
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type DynamicCompetitionSurveySectionQuestionMultipleResponse = {
  __typename?: 'DynamicCompetitionSurveySectionQuestionMultipleResponse';
  id: Scalars['String'];
  value: Scalars['String'];
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type DynamicCompetitionSurveySectionQuestionOption = {
  __typename?: 'DynamicCompetitionSurveySectionQuestionOption';
  id: Scalars['String'];
  value: Scalars['String'];
  order: Scalars['Int'];
};

export type DynamicCompetitionSurveySectionSubQuestion = {
  __typename?: 'DynamicCompetitionSurveySectionSubQuestion';
  id: Scalars['String'];
  name: Scalars['String'];
  questionText: Scalars['String'];
  isRequired: Scalars['Boolean'];
  canAddToKeyIncidents: Scalars['Boolean'];
  questionType: SurveyQuestionTypes;
  order: Scalars['Int'];
  options: Array<DynamicCompetitionSurveySectionSubQuestionOption>;
  selectedOption?: Maybe<Scalars['String']>;
  selectedOptionOrder?: Maybe<Scalars['Int']>;
  responseShortAnswer?: Maybe<Scalars['String']>;
  responseText?: Maybe<Scalars['String']>;
  responseNumber?: Maybe<Scalars['Float']>;
  responseBoolean?: Maybe<Scalars['Boolean']>;
  responses?: Maybe<Array<DynamicCompetitionSurveySectionSubQuestionMultipleResponse>>;
  fileUploads?: Maybe<Array<DynamicCompetitionSurveySectionSubQuestionFileUpload>>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type DynamicCompetitionSurveySectionSubQuestionFileUpload = {
  __typename?: 'DynamicCompetitionSurveySectionSubQuestionFileUpload';
  id: Scalars['String'];
  comment?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  fileUpload: Scalars['String'];
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type DynamicCompetitionSurveySectionSubQuestionMultipleResponse = {
  __typename?: 'DynamicCompetitionSurveySectionSubQuestionMultipleResponse';
  id: Scalars['String'];
  value: Scalars['String'];
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type DynamicCompetitionSurveySectionSubQuestionOption = {
  __typename?: 'DynamicCompetitionSurveySectionSubQuestionOption';
  id: Scalars['String'];
  value: Scalars['String'];
  order: Scalars['Int'];
};

export type DynamicCompetitionSurveySignature = {
  __typename?: 'DynamicCompetitionSurveySignature';
  competitionSurveyId: Scalars['String'];
  signedDate: Scalars['Date'];
  userId: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  profilePic?: Maybe<Scalars['String']>;
};

export type DynamicCompetitionSurveySummaries = {
  __typename?: 'DynamicCompetitionSurveySummaries';
  competitionSurveys: Array<DynamicCompetitionSurveySummary>;
  pagination: OffsetPagination;
};

export type DynamicCompetitionSurveySummary = {
  __typename?: 'DynamicCompetitionSurveySummary';
  id: Scalars['String'];
  surveyName: Scalars['String'];
  completedDate?: Maybe<Scalars['Date']>;
  createdAt: Scalars['Date'];
  teamId?: Maybe<Scalars['String']>;
  teamName?: Maybe<Scalars['String']>;
  teamLogo?: Maybe<Scalars['String']>;
  officialId?: Maybe<Scalars['String']>;
  officialType?: Maybe<Scalars['String']>;
  competitionId: Scalars['String'];
  externalId?: Maybe<Scalars['String']>;
  startTime: Scalars['Date'];
  homeTeamScore?: Maybe<Scalars['Int']>;
  homeTeamTieBreakerScore?: Maybe<Scalars['Int']>;
  awayTeamScore?: Maybe<Scalars['Int']>;
  awayTeamTieBreakerScore?: Maybe<Scalars['Int']>;
  homeTeamId: Scalars['String'];
  homeTeamName: Scalars['String'];
  homeTeamAbbreviation?: Maybe<Scalars['String']>;
  homeTeamLogo?: Maybe<Scalars['String']>;
  awayTeamId: Scalars['String'];
  awayTeamName: Scalars['String'];
  awayTeamAbbreviation?: Maybe<Scalars['String']>;
  awayTeamLogo?: Maybe<Scalars['String']>;
  tookSurvey?: Maybe<Scalars['String']>;
  tookSurveyUserId?: Maybe<Scalars['String']>;
  keyIncidentsCount?: Maybe<Scalars['Float']>;
  supplementalReportsCount?: Maybe<Scalars['Float']>;
};

export type DynamicCompetitionSurveyUser = {
  __typename?: 'DynamicCompetitionSurveyUser';
  userId: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  tookSurvey: Scalars['Boolean'];
  profilePic?: Maybe<Scalars['String']>;
};

export type DynamicSurvey = {
  __typename?: 'DynamicSurvey';
  id: Scalars['String'];
  sport: Sports;
  status: SurveyStatuses;
  hasCompetitionVerification: Scalars['Boolean'];
  hasSignatureSection: Scalars['Boolean'];
  signatureText?: Maybe<Scalars['String']>;
  hasIncidentReport: Scalars['Boolean'];
  hasSupplementalReport: Scalars['Boolean'];
  additionsConfig: DynamicSurveyAdditionsConfig;
  recipients: Array<SurveyAssessmentObjectRecipient>;
  keyIncidentRecipients: Array<SurveyKeyIncidentRecipient>;
  supplementalReportRecipients: Array<SurveySupplementalReportRecipient>;
  info: DynamicSurveyInfo;
  reminders: SurveyRemindersConfig;
  integration: DynamicSurveyIntegration;
  sections: Array<DynamicSurveySection>;
  orgId: Scalars['String'];
  seasonId?: Maybe<Scalars['String']>;
  activatedAt?: Maybe<Scalars['Date']>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type DynamicSurveyAdditionsConfig = {
  __typename?: 'DynamicSurveyAdditionsConfig';
  allowAdditionalSupplementalReports: Scalars['Boolean'];
  allowAdditionalSupplementalReportsCutoffDays?: Maybe<Scalars['Int']>;
  allowAdditionalIncidentReports: Scalars['Boolean'];
  allowAdditionalIncidentReportsCutoffDays?: Maybe<Scalars['Int']>;
};

export type DynamicSurveyCompetitionVerification = {
  __typename?: 'DynamicSurveyCompetitionVerification';
  enabled: Scalars['Boolean'];
};

export type DynamicSurveyInfo = {
  __typename?: 'DynamicSurveyInfo';
  name: Scalars['String'];
  description: Scalars['String'];
  audience: SurveyAudiences;
  sendDelayMinutes: Scalars['Int'];
};

export type DynamicSurveyIntegration = {
  __typename?: 'DynamicSurveyIntegration';
  id: Scalars['String'];
  orgId: Scalars['String'];
  orgName: Scalars['String'];
  orgAbbreviation: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  status: DynamicSurveyIntegrationStatuses;
  queueName: Scalars['String'];
  additionalOptions: Array<DynamicSurveyIntegrationAdditionalOption>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type DynamicSurveyIntegrationAdditionalOption = {
  __typename?: 'DynamicSurveyIntegrationAdditionalOption';
  id: Scalars['String'];
  name: Scalars['String'];
  value: Scalars['String'];
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type DynamicSurveyIntegrationAdditionalOptions = {
  __typename?: 'DynamicSurveyIntegrationAdditionalOptions';
  additionalOptions: Array<DynamicSurveyIntegrationAdditionalOption>;
  pagination: OffsetPagination;
};

/** The dynamic survey integration statuses */
export enum DynamicSurveyIntegrationStatuses {
  Pending = 'PENDING',
  Active = 'ACTIVE',
  Disabled = 'DISABLED'
}

export type DynamicSurveyIntegrations = {
  __typename?: 'DynamicSurveyIntegrations';
  integrations: Array<DynamicSurveyIntegration>;
  pagination: OffsetPagination;
};

export type DynamicSurveySection = {
  __typename?: 'DynamicSurveySection';
  id: Scalars['String'];
  questions: Array<DynamicSurveySectionQuestion>;
  name: Scalars['String'];
  description: Scalars['String'];
  order: Scalars['Int'];
  isDynamic: Scalars['Boolean'];
  requireSupplementalReport: Scalars['Boolean'];
  supplementalReportType?: Maybe<SupplementalReportTypes>;
  allowAdditions: Scalars['Boolean'];
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type DynamicSurveySectionQuestion = {
  __typename?: 'DynamicSurveySectionQuestion';
  id: Scalars['String'];
  assessmentObject: AssessmentObject;
  name: Scalars['String'];
  description: Scalars['String'];
  isRequired: Scalars['Boolean'];
  allowCorrections: Scalars['Boolean'];
  canAddToKeyIncidents: Scalars['Boolean'];
  questionType: SurveyQuestionTypes;
  order: Scalars['Int'];
  options: Array<DynamicSurveySectionQuestionOption>;
  subQuestions: Array<DynamicSurveySectionSubQuestion>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type DynamicSurveySectionQuestionOption = {
  __typename?: 'DynamicSurveySectionQuestionOption';
  id: Scalars['String'];
  value: Scalars['String'];
  order: Scalars['Int'];
};

export type DynamicSurveySectionSubQuestion = {
  __typename?: 'DynamicSurveySectionSubQuestion';
  id: Scalars['String'];
  name: Scalars['String'];
  questionText: Scalars['String'];
  isRequired: Scalars['Boolean'];
  canAddToKeyIncidents: Scalars['Boolean'];
  questionType: SurveyQuestionTypes;
  order: Scalars['Int'];
  options: Array<DynamicSurveySectionSubQuestionOption>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type DynamicSurveySectionSubQuestionOption = {
  __typename?: 'DynamicSurveySectionSubQuestionOption';
  id: Scalars['String'];
  value: Scalars['String'];
  order: Scalars['Int'];
};

export type DynamicSurveys = {
  __typename?: 'DynamicSurveys';
  surveys: Array<DynamicSurvey>;
  pagination: OffsetPagination;
};

export type EditCoachInput = {
  coachId: Scalars['String'];
  seasonId?: Maybe<Scalars['String']>;
  type: CoachTypes;
  role?: Maybe<Scalars['String']>;
};

export type EditKeyIncidentForDynamicCompetitionSurveyInput = {
  competitionSurveyId: Scalars['String'];
  keyIncidentId: Scalars['String'];
  description: Scalars['String'];
  incidentType: KeyIncidentTypes;
  impactedOutcome: Scalars['Boolean'];
  time?: Maybe<Scalars['String']>;
};

export type EditKeyIncidentForDynamicCompetitionSurveyWithTokenInput = {
  surveyToken: Scalars['String'];
  keyIncidentId: Scalars['String'];
  description: Scalars['String'];
  incidentType: KeyIncidentTypes;
  impactedOutcome: Scalars['Boolean'];
  time?: Maybe<Scalars['String']>;
};

export type EditKeyIncidentInput = {
  keyIncidentId: Scalars['String'];
  time?: Maybe<Scalars['String']>;
  periodId?: Maybe<Scalars['String']>;
};

export type EditKeyIncidentToCompetitionSurveyInput = {
  competitionSurveyId: Scalars['String'];
  keyIncidentId: Scalars['String'];
  description: Scalars['String'];
  incidentType: KeyIncidentTypes;
  impactedOutcome: Scalars['Boolean'];
  time?: Maybe<Scalars['String']>;
  periodId?: Maybe<Scalars['String']>;
};

export type EditKeyIncidentToCompetitionSurveyWithTokenInput = {
  surveyToken: Scalars['String'];
  keyIncidentId: Scalars['String'];
  description: Scalars['String'];
  incidentType: KeyIncidentTypes;
  impactedOutcome: Scalars['Boolean'];
  time?: Maybe<Scalars['String']>;
  periodId?: Maybe<Scalars['String']>;
};

export type EditSupplementalReportForDynamicCompetitionSurveyInput = {
  competitionSurveyId: Scalars['String'];
  supplementalReportId: Scalars['String'];
  title: Scalars['String'];
  supplementalReportType: SupplementalReportTypes;
  time?: Maybe<Scalars['String']>;
  teamId?: Maybe<Scalars['String']>;
  report?: Maybe<Scalars['String']>;
};

export type EditSupplementalReportForDynamicCompetitionSurveyWithTokenInput = {
  surveyToken: Scalars['String'];
  supplementalReportId: Scalars['String'];
  title: Scalars['String'];
  supplementalReportType: SupplementalReportTypes;
  time?: Maybe<Scalars['String']>;
  teamId?: Maybe<Scalars['String']>;
  report?: Maybe<Scalars['String']>;
};

/** The types of email survey reminder */
export enum EmailSurveyReminderType {
  Daily = 'DAILY',
  Level_1 = 'LEVEL_1',
  Level_2 = 'LEVEL_2',
  Level_3 = 'LEVEL_3'
}

export type FeedbackAvailableToConvertToKeyIncident = {
  __typename?: 'FeedbackAvailableToConvertToKeyIncident';
  surveyId: Scalars['String'];
  sectionId: Scalars['String'];
  assessmentObjectId: Scalars['String'];
  assessmentObjectName: Scalars['String'];
  officialId?: Maybe<Scalars['String']>;
  officialFirstName?: Maybe<Scalars['String']>;
  officialLastName?: Maybe<Scalars['String']>;
  questionId: Scalars['String'];
  questionName: Scalars['String'];
  responseId: Scalars['String'];
  response: Scalars['String'];
};

/** The source for feedback */
export enum FeedbackSource {
  Assessment = 'ASSESSMENT',
  Dynamic = 'DYNAMIC'
}

/** The type of feedback */
export enum FeedbackType {
  KeyIncident = 'KEY_INCIDENT',
  SupplementalReport = 'SUPPLEMENTAL_REPORT'
}

export type FindDynamicCompetitionSurveyIntegrationErrorsByUuid = {
  dynamicCompetitionSurveyId: Scalars['String'];
};

export type FindSeasonMediaMembersConfigForSeasonInput = {
  orgId: Scalars['String'];
  seasonId: Scalars['String'];
};

export type FindSeasonTeamKitForSeasonInput = {
  orgId: Scalars['String'];
  seasonId: Scalars['String'];
};

export type GetAdminUserByIdInput = {
  id: Scalars['String'];
};

export type GetAlertByIdInput = {
  id: Scalars['String'];
};

export type GetBatchSeasonCoachUploadByIdInput = {
  id: Scalars['String'];
};

export type GetBatchSeasonCompetitionUploadByIdInput = {
  id: Scalars['String'];
};

export type GetBatchSeasonOfficialUploadByIdInput = {
  id: Scalars['String'];
};

export type GetBatchSeasonSurveyResponseUploadByIdInput = {
  id: Scalars['String'];
};

export type GetBatchSeasonTeamUploadByIdInput = {
  id: Scalars['String'];
};

export type GetCoachByIdInput = {
  id: Scalars['String'];
};

export type GetCompetitionByIdInput = {
  id: Scalars['String'];
};

export type GetCompetitionIntegrationByIdInput = {
  id: Scalars['String'];
};

export type GetCompetitionSurveyByIdWithTokenInput = {
  surveyToken: Scalars['String'];
};

export type GetCompetitionSurveyInput = {
  competitionSurveyId: Scalars['String'];
};

export type GetCompetitionTeamKitApparelByIdInput = {
  competitionTeamKitApparelId: Scalars['String'];
};

export type GetCompetitionTeamKitContactByIdInput = {
  competitionTeamKitContactId: Scalars['String'];
};

export type GetCompetitionTeamKitInput = {
  competitionTeamKitId: Scalars['String'];
};

export type GetDynamicCompetitionSurveyByIdWithTokenInput = {
  surveyToken: Scalars['String'];
};

export type GetDynamicCompetitionSurveyInput = {
  competitionSurveyId: Scalars['String'];
};

export type GetDynamicSurveyByIdInput = {
  id: Scalars['String'];
};

export type GetDynamicSurveyIntegrationByIdInput = {
  id: Scalars['String'];
};

export type GetKeyIncidentByIdInput = {
  keyIncidentId: Scalars['String'];
};

export type GetKeyIncidentVideoIntegrationByIdInput = {
  id: Scalars['String'];
};

export type GetLeagueByIdInput = {
  id: Scalars['String'];
};

export type GetMediaMembersOrgSetupSummaryForSeasonInput = {
  seasonId: Scalars['String'];
};

export type GetMediaOrganizationByIdInput = {
  id: Scalars['String'];
};

export type GetMyOrganizationContextInput = {
  orgId: Scalars['String'];
  leagueId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
};

export type GetOfficialByIdInput = {
  id: Scalars['String'];
};

export type GetOrgOfficialSummaryByIdInput = {
  officialId: Scalars['String'];
  orgId: Scalars['String'];
  leagueId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
};

export type GetOrganizationByIdInput = {
  id: Scalars['String'];
};

export type GetOrganizationCompetitionMetricsInput = {
  orgId: Scalars['String'];
  leagueId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
};

export type GetOrganizationKeyIncidentMetricsInput = {
  orgId: Scalars['String'];
  leagueId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
};

export type GetOrganizationOfficialCompetitionSurveyMetricsInput = {
  orgId: Scalars['String'];
  leagueId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
};

export type GetOrganizationOfficialDynamicCompetitionSurveyMetricsInput = {
  orgId: Scalars['String'];
  leagueId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
};

export type GetOrganizationSupplementalReportMetricsInput = {
  orgId: Scalars['String'];
  leagueId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
};

export type GetOrganizationTeamCompetitionSurveyMetricsInput = {
  orgId: Scalars['String'];
  leagueId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
};

export type GetOrganizationTopOfficialsInput = {
  orgId: Scalars['String'];
  leagueId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  assessmentObjectId?: Maybe<Scalars['String']>;
  minCompetitionCount?: Maybe<Scalars['Int']>;
  pagination: OffsetPaginationInput;
};

export type GetSeasonByIdInput = {
  id: Scalars['String'];
};

export type GetSeasonCompetitionIntegrationByIdInput = {
  integrationId: Scalars['String'];
};

export type GetSeasonDivisionByIdInput = {
  id: Scalars['String'];
};

export type GetSeasonMediaMembersConfigByIdInput = {
  seasonMediaMembersConfigId: Scalars['String'];
};

export type GetSeasonSetupInfoInput = {
  seasonId: Scalars['String'];
};

export type GetSeasonTeamKitByIdInput = {
  seasonTeamKitId: Scalars['String'];
};

export type GetSupplementalReportByIdInput = {
  supplementalReportId: Scalars['String'];
};

export type GetSurveyByIdInput = {
  id: Scalars['String'];
};

export type GetSurveySummaryRecipientNotificationSettingInput = {
  summaryNotificationSettingsToken: Scalars['String'];
};

export type GetSurveyTemplateByIdInput = {
  id: Scalars['String'];
};

export type GetTeamByIdInput = {
  id: Scalars['String'];
};

export type GetTeamKitOrgSetupSummaryForSeasonInput = {
  seasonId: Scalars['String'];
};

export type GetTeamKitTeamSetupSummaryForSeasonTeamInput = {
  seasonId: Scalars['String'];
  teamId: Scalars['String'];
};

export type GetUserOrganizationMembershipInput = {
  organizationId: Scalars['String'];
  userId: Scalars['String'];
};

export type InviteAdminUserInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type InviteAdminUserResponse = {
  __typename?: 'InviteAdminUserResponse';
  created: Scalars['Boolean'];
  emailSent: Scalars['Boolean'];
};

export type InviteSeasonOfficialUserInput = {
  seasonId: Scalars['String'];
  officialId: Scalars['String'];
};

export type KeyIncident = {
  __typename?: 'KeyIncident';
  id: Scalars['String'];
  submittedBy: KeyIncidentSubmittedByInfo;
  reviewStartedBy?: Maybe<KeyIncidentReviewStartedByInfo>;
  resolvedBy?: Maybe<KeyIncidentResolvedByInfo>;
  competitionSurveyId?: Maybe<Scalars['String']>;
  dynamicCompetitionSurveyId?: Maybe<Scalars['String']>;
  competitionInfo: KeyIncidentCompetitionInfo;
  official?: Maybe<Official>;
  team?: Maybe<Team>;
  assessmentObject?: Maybe<AssessmentObject>;
  organizationId?: Maybe<Scalars['String']>;
  organizationName?: Maybe<Scalars['String']>;
  leagueId?: Maybe<Scalars['String']>;
  leagueName?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  seasonName?: Maybe<Scalars['String']>;
  competitionSurveyFeedbackId?: Maybe<Scalars['String']>;
  dynamicCompetitionSurveyFeedbackId?: Maybe<Scalars['String']>;
  dynamicCompetitionSurveySubFeedbackId?: Maybe<Scalars['String']>;
  type: KeyIncidentTypes;
  status: KeyIncidentStatus;
  description: Scalars['String'];
  period?: Maybe<Period>;
  time?: Maybe<Scalars['String']>;
  impactedOutcome: Scalars['Boolean'];
  resolvedDate?: Maybe<Scalars['Date']>;
  reopenedDate?: Maybe<Scalars['Date']>;
  videoStatus: KeyIncidentVideoStatus;
  videoUrl?: Maybe<Scalars['String']>;
  videoError?: Maybe<Scalars['String']>;
  videoIntegrationSentDate?: Maybe<Scalars['Date']>;
  videoIntegrationProcessedDate?: Maybe<Scalars['Date']>;
  videoIntegrationRetryCount: Scalars['Float'];
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
  reviewStartedAt?: Maybe<Scalars['Date']>;
};

export type KeyIncidentCompetitionInfo = {
  __typename?: 'KeyIncidentCompetitionInfo';
  competitionId: Scalars['String'];
  externalId?: Maybe<Scalars['String']>;
  sport: Sports;
  outcome?: Maybe<CompetitionOutcome>;
  verificationStatus: CompetitionVerificationStatuses;
  homeTeamId: Scalars['String'];
  homeTeamName: Scalars['String'];
  homeTeamLogo?: Maybe<Scalars['String']>;
  homeTeamScore?: Maybe<Scalars['Int']>;
  homeTeamTieBreakerScore?: Maybe<Scalars['Int']>;
  awayTeamId: Scalars['String'];
  awayTeamName: Scalars['String'];
  awayTeamLogo?: Maybe<Scalars['String']>;
  awayTeamScore?: Maybe<Scalars['Int']>;
  awayTeamTieBreakerScore?: Maybe<Scalars['Int']>;
  startTime: Scalars['Date'];
  timeZone: Scalars['String'];
  location?: Maybe<Scalars['String']>;
};

export type KeyIncidentExternalLink = {
  __typename?: 'KeyIncidentExternalLink';
  id: Scalars['String'];
  keyIncidentId: Scalars['String'];
  link: Scalars['String'];
  type: KeyIncidentExternalLinkTypes;
  createdByFirstName: Scalars['String'];
  createdByLastName: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

/** The key incident external link types */
export enum KeyIncidentExternalLinkTypes {
  Video = 'VIDEO',
  Photo = 'PHOTO',
  Audio = 'AUDIO',
  Document = 'DOCUMENT',
  Other = 'OTHER'
}

export type KeyIncidentExternalLinks = {
  __typename?: 'KeyIncidentExternalLinks';
  externalLinks: Array<KeyIncidentExternalLink>;
  pagination: OffsetPagination;
};

export type KeyIncidentMessage = {
  __typename?: 'KeyIncidentMessage';
  id: Scalars['String'];
  keyIncidentId: Scalars['String'];
  author?: Maybe<KeyIncidentMessageAuthorInfo>;
  message: Scalars['String'];
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
  editedAt?: Maybe<Scalars['Date']>;
};

export type KeyIncidentMessageAuthorInfo = {
  __typename?: 'KeyIncidentMessageAuthorInfo';
  id: Scalars['String'];
  userId: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  profilePic?: Maybe<Scalars['String']>;
  authorType: Scalars['String'];
  teamId?: Maybe<Scalars['String']>;
  teamName?: Maybe<Scalars['String']>;
  teamLogo?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  organizationName?: Maybe<Scalars['String']>;
  organizationAbbreviation?: Maybe<Scalars['String']>;
};

export type KeyIncidentMessages = {
  __typename?: 'KeyIncidentMessages';
  messages: Array<KeyIncidentMessage>;
  pagination: OffsetPagination;
};

export type KeyIncidentResolved = {
  __typename?: 'KeyIncidentResolved';
  keyIncident: KeyIncident;
  message: KeyIncidentMessage;
};

export type KeyIncidentResolvedByInfo = {
  __typename?: 'KeyIncidentResolvedByInfo';
  userId: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  profilePic?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  organizationName: Scalars['String'];
  organizationAbbreviation: Scalars['String'];
};

export type KeyIncidentReviewStartedByInfo = {
  __typename?: 'KeyIncidentReviewStartedByInfo';
  userId: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  profilePic?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  organizationName: Scalars['String'];
  organizationAbbreviation: Scalars['String'];
};

/** The key incident statuses */
export enum KeyIncidentStatus {
  Draft = 'DRAFT',
  SentForReview = 'SENT_FOR_REVIEW',
  InReview = 'IN_REVIEW',
  Resolved = 'RESOLVED'
}

export type KeyIncidentSubmittedByInfo = {
  __typename?: 'KeyIncidentSubmittedByInfo';
  userId: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  profilePic?: Maybe<Scalars['String']>;
  teamId?: Maybe<Scalars['String']>;
  teamName?: Maybe<Scalars['String']>;
  teamLogo?: Maybe<Scalars['String']>;
  officialId?: Maybe<Scalars['String']>;
  assessmentObjectId?: Maybe<Scalars['String']>;
  assessmentObjectName?: Maybe<Scalars['String']>;
};

export type KeyIncidentSummaries = {
  __typename?: 'KeyIncidentSummaries';
  keyIncidents: Array<KeyIncidentSummary>;
  pagination: OffsetPagination;
};

export type KeyIncidentSummary = {
  __typename?: 'KeyIncidentSummary';
  id: Scalars['String'];
  organizationId: Scalars['String'];
  organizationName: Scalars['String'];
  leagueId: Scalars['String'];
  leagueName: Scalars['String'];
  seasonId: Scalars['String'];
  seasonName: Scalars['String'];
  competitionId: Scalars['String'];
  competitionExternalId?: Maybe<Scalars['String']>;
  startTime: Scalars['Date'];
  timeZone: Scalars['String'];
  homeTeamId: Scalars['String'];
  homeTeamName: Scalars['String'];
  homeTeamLogo?: Maybe<Scalars['String']>;
  homeTeamScore?: Maybe<Scalars['Int']>;
  homeTeamTieBreakerScore?: Maybe<Scalars['Int']>;
  awayTeamId: Scalars['String'];
  awayTeamScore?: Maybe<Scalars['Int']>;
  awayTeamTieBreakerScore?: Maybe<Scalars['Int']>;
  awayTeamName: Scalars['String'];
  awayTeamLogo?: Maybe<Scalars['String']>;
  type: KeyIncidentTypes;
  status: KeyIncidentStatus;
  impactedOutcome: Scalars['Boolean'];
  description: Scalars['String'];
  time?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
  submittedBy: Scalars['String'];
  submittedByAssessmentObjectName?: Maybe<Scalars['String']>;
  submittedByTeam?: Maybe<Scalars['String']>;
  reviewStartedBy?: Maybe<Scalars['String']>;
  resolvedBy?: Maybe<Scalars['String']>;
  officialName?: Maybe<Scalars['String']>;
  teamName?: Maybe<Scalars['String']>;
  coachName?: Maybe<Scalars['String']>;
  assessmentObjectName?: Maybe<Scalars['String']>;
  resolvedAt?: Maybe<Scalars['Date']>;
  reopenedAt?: Maybe<Scalars['Date']>;
  videoStatus: KeyIncidentVideoStatus;
  videoError?: Maybe<Scalars['String']>;
  reviewStartedAt?: Maybe<Scalars['Date']>;
  messageCount?: Maybe<Scalars['Float']>;
};

/** The key incident types */
export enum KeyIncidentTypes {
  Foul = 'FOUL',
  DisputedCall = 'DISPUTED_CALL',
  MissedCall = 'MISSED_CALL',
  Caution = 'CAUTION',
  Expulsion = 'EXPULSION',
  VideoReview = 'VIDEO_REVIEW',
  Other = 'OTHER'
}

export type KeyIncidentVideoIntegration = {
  __typename?: 'KeyIncidentVideoIntegration';
  id: Scalars['String'];
  orgId: Scalars['String'];
  orgName: Scalars['String'];
  orgAbbreviation: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  status: KeyIncidentVideoIntegrationStatuses;
  queueName: Scalars['String'];
  enableRetry: Scalars['Boolean'];
  maxRetries?: Maybe<Scalars['Float']>;
  retryIntervalMinutes?: Maybe<Scalars['Float']>;
  additionalOptions: Array<KeyIncidentVideoIntegrationAdditionalOption>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type KeyIncidentVideoIntegrationAdditionalOption = {
  __typename?: 'KeyIncidentVideoIntegrationAdditionalOption';
  id: Scalars['String'];
  name: Scalars['String'];
  value: Scalars['String'];
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type KeyIncidentVideoIntegrationAdditionalOptions = {
  __typename?: 'KeyIncidentVideoIntegrationAdditionalOptions';
  additionalOptions: Array<KeyIncidentVideoIntegrationAdditionalOption>;
  pagination: OffsetPagination;
};

/** The key incident video integration statuses */
export enum KeyIncidentVideoIntegrationStatuses {
  Pending = 'PENDING',
  Active = 'ACTIVE',
  Disabled = 'DISABLED'
}

export type KeyIncidentVideoIntegrations = {
  __typename?: 'KeyIncidentVideoIntegrations';
  integrations: Array<KeyIncidentVideoIntegration>;
  pagination: OffsetPagination;
};

/** The key incident video statuses */
export enum KeyIncidentVideoStatus {
  NotEnabled = 'NOT_ENABLED',
  NotAvailable = 'NOT_AVAILABLE',
  Pending = 'PENDING',
  Processing = 'PROCESSING',
  Error = 'ERROR',
  Unavailable = 'UNAVAILABLE',
  Ready = 'READY',
  WaitingRetry = 'WAITING_RETRY'
}

export type KeyIncidents = {
  __typename?: 'KeyIncidents';
  keyIncidents: Array<KeyIncident>;
  pagination: OffsetPagination;
};

export type KeyIncidentsForCompetition = {
  __typename?: 'KeyIncidentsForCompetition';
  keyIncidents: Array<KeyIncident>;
  competitionInfo: KeyIncidentCompetitionInfo;
};

export type League = {
  __typename?: 'League';
  id: Scalars['String'];
  orgId: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  abbreviation?: Maybe<Scalars['String']>;
  status: LeagueStatuses;
  logo?: Maybe<Scalars['String']>;
  tags: Array<LeagueTag>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type LeagueMembershipUsers = {
  __typename?: 'LeagueMembershipUsers';
  leagueId: Scalars['String'];
  leagueMemberships: Array<User>;
  pagination: OffsetPagination;
};

/** The league statuses */
export enum LeagueStatuses {
  Active = 'ACTIVE',
  Disabled = 'DISABLED'
}

export type LeagueTag = {
  __typename?: 'LeagueTag';
  id: Scalars['String'];
  leagueId: Scalars['String'];
  tag: Scalars['String'];
};

export type Leagues = {
  __typename?: 'Leagues';
  leagues: Array<League>;
  pagination: OffsetPagination;
};

export type ListAdditionalOptionsForCompetitionIntegrationInput = {
  integrationId: Scalars['String'];
  pagination: OffsetPaginationInput;
};

export type ListAdditionalOptionsForDynamicSurveyIntegrationInput = {
  integrationId: Scalars['String'];
  pagination: OffsetPaginationInput;
};

export type ListAdditionalOptionsForKeyIncidentVideoIntegrationInput = {
  integrationId: Scalars['String'];
  pagination: OffsetPaginationInput;
};

export type ListAdminSurveyTemplatesInput = {
  sport?: Maybe<Sports>;
  status?: Maybe<SurveyStatuses>;
  name?: Maybe<Scalars['String']>;
  pagination: OffsetPaginationInput;
};

export type ListAdminUsersInput = {
  pagination: OffsetPaginationInput;
};

export type ListAlertsForUserInput = {
  read?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  components?: Maybe<Array<UserAlertComponents>>;
  pagination: OffsetPaginationInput;
};

export type ListAllSurveyInfoForCompetitionForOrgInput = {
  orgId: Scalars['String'];
  competitionId: Scalars['String'];
};

export type ListAllSurveysForOrganizationInput = {
  orgId: Scalars['String'];
  seasonId?: Maybe<Scalars['String']>;
  status?: Maybe<SurveyStatuses>;
  audience?: Maybe<SurveyAudiences>;
  type?: Maybe<SurveyTypes>;
  pagination: OffsetPaginationInput;
};

export type ListAssessmentObjectsForOrganizationInput = {
  organizationId: Scalars['String'];
  leagueId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
};

export type ListAssessmentObjectsInput = {
  sport?: Maybe<Sports>;
  type?: Maybe<AssessmentObjectTypes>;
  classification?: Maybe<AssessmentObjectClassifications>;
  pagination: OffsetPaginationInput;
};

export type ListAvailableAssessmentObjectsForDynamicSurveysRecipientsInput = {
  surveyId: Scalars['String'];
  pagination: OffsetPaginationInput;
};

export type ListAvailableAssessmentObjectsForSeasonInput = {
  seasonId: Scalars['String'];
};

export type ListAvailableAssessmentObjectsForSurveysRecipientsInput = {
  surveyId: Scalars['String'];
  pagination: OffsetPaginationInput;
};

export type ListAvailableBroadcastOptionsForCompetitionTeamKitInput = {
  competitionTeamKitId: Scalars['String'];
  pagination: OffsetPaginationInput;
};

export type ListAvailableCompetitionIntegrationsForSeasonInput = {
  orgId: Scalars['String'];
  seasonId: Scalars['String'];
  nameSearch?: Maybe<Scalars['String']>;
  pagination: OffsetPaginationInput;
};

export type ListAvailableCompetitionOfficialsForAdditionalKeyIncidentsInput = {
  competitionSurveyId: Scalars['String'];
  source: FeedbackSource;
};

export type ListAvailableContactForCompetitionTeamKitContactInput = {
  competitionTeamKitContactId: Scalars['String'];
  pagination: OffsetPaginationInput;
};

export type ListAvailableFeedbackToConvertForAdditionalKeyIncidentsInput = {
  competitionSurveyId: Scalars['String'];
  source: FeedbackSource;
};

export type ListAvailablePeriodsForCompetitionInput = {
  competitionId: Scalars['String'];
  pagination: OffsetPaginationInput;
};

export type ListAvailablePeriodsForCompetitionInputWithToken = {
  surveyToken: Scalars['String'];
  competitionId: Scalars['String'];
  pagination: OffsetPaginationInput;
};

export type ListAvailablePeriodsForSeasonInput = {
  seasonId: Scalars['String'];
};

export type ListAvailableSelectionsForCompetitionTeamKitApparelInput = {
  competitionTeamKitApparelId: Scalars['String'];
  pagination: OffsetPaginationInput;
};

export type ListBatchSeasonCoachUploadsForSeasonInput = {
  seasonId: Scalars['String'];
  fileName?: Maybe<Scalars['String']>;
  status?: Maybe<BatchFileUploadStatuses>;
  pagination: OffsetPaginationInput;
};

export type ListBatchSeasonCompetitionUploadsForSeasonInput = {
  seasonId: Scalars['String'];
  fileName?: Maybe<Scalars['String']>;
  status?: Maybe<BatchFileUploadStatuses>;
  pagination: OffsetPaginationInput;
};

export type ListBatchSeasonOfficialUploadsForSeasonInput = {
  seasonId: Scalars['String'];
  fileName?: Maybe<Scalars['String']>;
  status?: Maybe<BatchFileUploadStatuses>;
  pagination: OffsetPaginationInput;
};

export type ListBatchSeasonSurveyResponseUploadsForSeasonInput = {
  organizationName?: Maybe<Scalars['String']>;
  seasonName?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  status?: Maybe<BatchFileUploadStatuses>;
  legacyType?: Maybe<BatchSeasonLegacySurveyTypes>;
  pagination: OffsetPaginationInput;
};

export type ListBatchSeasonTeamUploadsForSeasonInput = {
  seasonId: Scalars['String'];
  fileName?: Maybe<Scalars['String']>;
  status?: Maybe<BatchFileUploadStatuses>;
  pagination: OffsetPaginationInput;
};

export type ListCoachesForSeasonInput = {
  seasonId: Scalars['String'];
  search?: Maybe<Scalars['String']>;
  team?: Maybe<Scalars['String']>;
  type?: Maybe<CoachTypes>;
  status?: Maybe<CoachStatuses>;
  role?: Maybe<Scalars['String']>;
  userStatus?: Maybe<UserStatuses>;
  pagination: OffsetPaginationInput;
};

export type ListCommentsForKeyIncidentInput = {
  keyIncidentId: Scalars['String'];
  pagination: OffsetPaginationInput;
};

export type ListCommentsForSupplementalReportInput = {
  supplementalReportId: Scalars['String'];
  pagination: OffsetPaginationInput;
};

export type ListCompetitionIntegrationRequestsForSeasonInputInput = {
  seasonId: Scalars['String'];
  dateFrom?: Maybe<Scalars['Date']>;
  dateTo?: Maybe<Scalars['Date']>;
  integrationSearch?: Maybe<Scalars['String']>;
  statuses?: Maybe<Array<CompetitionIntegrationRequestStatuses>>;
  pagination: OffsetPaginationInput;
};

export type ListCompetitionIntegrationRequestsInput = {
  orgName?: Maybe<Scalars['String']>;
  leagueName?: Maybe<Scalars['String']>;
  seasonName?: Maybe<Scalars['String']>;
  fromDate?: Maybe<Scalars['Date']>;
  toDate?: Maybe<Scalars['Date']>;
  integrationSearch?: Maybe<Scalars['String']>;
  statuses?: Maybe<Array<CompetitionIntegrationRequestStatuses>>;
  pagination: OffsetPaginationInput;
};

export type ListCompetitionIntegrationResponsesForRequestInput = {
  requestId: Scalars['String'];
  statuses?: Maybe<Array<CompetitionIntegrationResponseStatuses>>;
  pagination: OffsetPaginationInput;
};

export type ListCompetitionIntegrationsInput = {
  name?: Maybe<Scalars['String']>;
  orgId?: Maybe<Scalars['String']>;
  orgName?: Maybe<Scalars['String']>;
  queueName?: Maybe<Scalars['String']>;
  status?: Maybe<CompetitionIntegrationStatuses>;
  pagination: OffsetPaginationInput;
};

export type ListCompetitionSummariesForOrgInput = {
  orgId: Scalars['String'];
  includeNoResponses: Scalars['Boolean'];
  leagueId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  dateFrom?: Maybe<Scalars['String']>;
  dateTo?: Maybe<Scalars['String']>;
  externalIdSearch?: Maybe<Scalars['String']>;
  teamsSearch?: Maybe<Scalars['String']>;
  leagueSearch?: Maybe<Scalars['String']>;
  seasonSearch?: Maybe<Scalars['String']>;
  pagination: OffsetPaginationInput;
};

export type ListCompetitionSummariesForOrgOfficialInput = {
  officialId: Scalars['String'];
  orgId: Scalars['String'];
  leagueId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  leagueSearch?: Maybe<Scalars['String']>;
  seasonSearch?: Maybe<Scalars['String']>;
  assessmentObjectUuid?: Maybe<Scalars['String']>;
  teamsSearch?: Maybe<Scalars['String']>;
  dateFrom?: Maybe<Scalars['String']>;
  dateTo?: Maybe<Scalars['String']>;
  pagination: OffsetPaginationInput;
};

export type ListCompetitionSurveyResponsesForOrganizationInput = {
  orgId: Scalars['String'];
  leagueId: Scalars['String'];
  seasonId: Scalars['String'];
  surveyId: Scalars['String'];
  pagination: OffsetPaginationInput;
};

export type ListCompetitionTeamKitApparelsInput = {
  competitionTeamKitId: Scalars['String'];
  pagination: OffsetPaginationInput;
};

export type ListCompetitionTeamKitBroadcastOptionsInput = {
  competitionTeamKitId: Scalars['String'];
  pagination: OffsetPaginationInput;
};

export type ListCompetitionTeamKitCompetitionInfosInput = {
  competitionTeamKitId: Scalars['String'];
  pagination: OffsetPaginationInput;
};

export type ListCompetitionTeamKitContactsInput = {
  competitionTeamKitId: Scalars['String'];
  pagination: OffsetPaginationInput;
};

export type ListCompetitionTeamKitRecipientsInput = {
  competitionTeamKitId: Scalars['String'];
};

export type ListCompetitionTeamKitsForOrganizationInput = {
  orgId: Scalars['String'];
  leagueId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  fromDate?: Maybe<Scalars['String']>;
  toDate?: Maybe<Scalars['String']>;
  teamSearch?: Maybe<Scalars['String']>;
  statuses?: Maybe<Array<CompetitionTeamKitStatus>>;
  pagination: OffsetPaginationInput;
};

export type ListCompetitionsForLeagueInput = {
  leagueId: Scalars['String'];
  seasonId?: Maybe<Scalars['String']>;
  dateFrom?: Maybe<Scalars['String']>;
  dateTo?: Maybe<Scalars['String']>;
  externalIdSearch?: Maybe<Scalars['String']>;
  teamsSearch?: Maybe<Scalars['String']>;
  statuses?: Maybe<Array<CompetitionStatuses>>;
  pagination: OffsetPaginationInput;
};

export type ListCompetitionsForSeasonInput = {
  seasonId: Scalars['String'];
  dateFrom?: Maybe<Scalars['String']>;
  dateTo?: Maybe<Scalars['String']>;
  externalIdSearch?: Maybe<Scalars['String']>;
  teamsSearch?: Maybe<Scalars['String']>;
  statuses?: Maybe<Array<CompetitionStatuses>>;
  pagination: OffsetPaginationInput;
};

export type ListContactsForOrganizationInput = {
  organizationId: Scalars['String'];
  search?: Maybe<Scalars['String']>;
  activeOnly?: Maybe<Scalars['Boolean']>;
  pagination: OffsetPaginationInput;
};

export type ListContactsForTeamForSeasonInput = {
  teamId: Scalars['String'];
  seasonId: Scalars['String'];
  organizationId: Scalars['String'];
  search?: Maybe<Scalars['String']>;
  activeOnly?: Maybe<Scalars['Boolean']>;
  pagination: OffsetPaginationInput;
};

export type ListDynamicCompetitionSurveyIntegrationsInput = {
  orgName?: Maybe<Scalars['String']>;
  leagueName?: Maybe<Scalars['String']>;
  seasonName?: Maybe<Scalars['String']>;
  competitionExternalId?: Maybe<Scalars['String']>;
  homeTeamName?: Maybe<Scalars['String']>;
  awayTeamName?: Maybe<Scalars['String']>;
  toDate?: Maybe<Scalars['String']>;
  fromDate?: Maybe<Scalars['String']>;
  status?: Maybe<DynamicCompetitionSurveyIntegrationStatuses>;
  pagination: OffsetPaginationInput;
};

export type ListDynamicCompetitionSurveySummariesForOrganizationInput = {
  orgId: Scalars['String'];
  leagueId: Scalars['String'];
  seasonId: Scalars['String'];
  surveyId: Scalars['String'];
  pagination: OffsetPaginationInput;
};

export type ListDynamicSurveyIntegrationsInput = {
  name?: Maybe<Scalars['String']>;
  orgId?: Maybe<Scalars['String']>;
  orgName?: Maybe<Scalars['String']>;
  queueName?: Maybe<Scalars['String']>;
  status?: Maybe<DynamicSurveyIntegrationStatuses>;
  pagination: OffsetPaginationInput;
};

export type ListDynamicSurveysForOrganizationInput = {
  orgId: Scalars['String'];
  integrationId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<SurveyStatuses>;
  audience?: Maybe<SurveyAudiences>;
  pagination: OffsetPaginationInput;
};

export type ListErrorsForBatchSeasonCoachUploadRowInput = {
  rowId: Scalars['String'];
  pagination: OffsetPaginationInput;
};

export type ListErrorsForBatchSeasonCompetitionUploadRowInput = {
  rowId: Scalars['String'];
  pagination: OffsetPaginationInput;
};

export type ListErrorsForBatchSeasonOfficialUploadRowInput = {
  rowId: Scalars['String'];
  pagination: OffsetPaginationInput;
};

export type ListErrorsForBatchSeasonSurveyResponseUploadRowInput = {
  rowId: Scalars['String'];
  pagination: OffsetPaginationInput;
};

export type ListErrorsForBatchSeasonTeamUploadRowInput = {
  rowId: Scalars['String'];
  pagination: OffsetPaginationInput;
};

export type ListExternalLinksForKeyIncidentForCompetitionSurveyInput = {
  competitionSurveyId: Scalars['String'];
  keyIncidentId: Scalars['String'];
};

export type ListExternalLinksForKeyIncidentForCompetitionSurveyWithTokenInput = {
  surveyToken: Scalars['String'];
  keyIncidentId: Scalars['String'];
};

export type ListExternalLinksForKeyIncidentInput = {
  keyIncidentId: Scalars['String'];
  types?: Maybe<Array<KeyIncidentExternalLinkTypes>>;
  pagination: OffsetPaginationInput;
};

export type ListFeaturesForOrganizationInput = {
  organizationId: Scalars['String'];
  feature?: Maybe<Scalars['String']>;
  pagination: OffsetPaginationInput;
};

export type ListFeedbackForOrgOfficialInput = {
  officialId: Scalars['String'];
  orgId: Scalars['String'];
  leagueId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  submittedByTeamSearch?: Maybe<Scalars['String']>;
  dateFrom?: Maybe<Scalars['String']>;
  dateTo?: Maybe<Scalars['String']>;
  pagination: OffsetPaginationInput;
};

export type ListKeyIncidentSummariesFiltersInput = {
  leagueId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  teamSearch?: Maybe<Scalars['String']>;
  submittedBySearch?: Maybe<Scalars['String']>;
  impactedOutcome?: Maybe<Scalars['Boolean']>;
  competitionDateTo?: Maybe<Scalars['String']>;
  competitionDateFrom?: Maybe<Scalars['String']>;
  lastUpdatedDateFrom?: Maybe<Scalars['String']>;
  lastUpdatedDateTo?: Maybe<Scalars['String']>;
  statuses?: Maybe<Array<KeyIncidentStatus>>;
  videoStatuses?: Maybe<Array<KeyIncidentVideoStatus>>;
};

export type ListKeyIncidentSummariesForOrgInput = {
  orgId: Scalars['String'];
  filters?: Maybe<ListKeyIncidentSummariesFiltersInput>;
  pagination: OffsetPaginationInput;
};

export type ListKeyIncidentSummariesForOrgOfficialFiltersInput = {
  teamSearch?: Maybe<Scalars['String']>;
  submittedBySearch?: Maybe<Scalars['String']>;
  impactedOutcome?: Maybe<Scalars['Boolean']>;
  competitionDateTo?: Maybe<Scalars['String']>;
  competitionDateFrom?: Maybe<Scalars['String']>;
  lastUpdatedDateFrom?: Maybe<Scalars['String']>;
  lastUpdatedDateTo?: Maybe<Scalars['String']>;
  statuses?: Maybe<Array<KeyIncidentStatus>>;
  videoStatuses?: Maybe<Array<KeyIncidentVideoStatus>>;
};

export type ListKeyIncidentSummariesForOrgOfficialInput = {
  officialId: Scalars['String'];
  orgId: Scalars['String'];
  leagueId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  filters?: Maybe<ListKeyIncidentSummariesForOrgOfficialFiltersInput>;
  pagination: OffsetPaginationInput;
};

export type ListKeyIncidentSummariesForUserInput = {
  filters?: Maybe<ListKeyIncidentSummariesFiltersInput>;
  pagination: OffsetPaginationInput;
};

export type ListKeyIncidentVideoIntegrationsInput = {
  name?: Maybe<Scalars['String']>;
  orgId?: Maybe<Scalars['String']>;
  orgName?: Maybe<Scalars['String']>;
  queueName?: Maybe<Scalars['String']>;
  status?: Maybe<KeyIncidentVideoIntegrationStatuses>;
  pagination: OffsetPaginationInput;
};

export type ListKeyIncidentsForCompetitionAndSurveyInput = {
  orgId: Scalars['String'];
  competitionId: Scalars['String'];
  surveyId: Scalars['String'];
};

export type ListKeyIncidentsForCompetitionInput = {
  competitionId: Scalars['String'];
};

export type ListKeyIncidentsForCompetitionSurveyInput = {
  competitionSurveyId: Scalars['String'];
};

export type ListKeyIncidentsForCompetitionSurveyWithTokenInput = {
  surveyToken: Scalars['String'];
};

export type ListKeyIncidentsForDynamicCompetitionSurveyInput = {
  competitionSurveyId: Scalars['String'];
};

export type ListKeyIncidentsForDynamicCompetitionSurveyWithTokenInput = {
  surveyToken: Scalars['String'];
};

export type ListKitRolesForCompetitionTeamKitInput = {
  competitionTeamKitId: Scalars['String'];
  pagination: OffsetPaginationInput;
};

export type ListLeagueMembershipUsersInput = {
  leagueId: Scalars['String'];
  nameOrEmail?: Maybe<Scalars['String']>;
  pagination: OffsetPaginationInput;
};

export type ListLeaguesForOrganizationInput = {
  orgId: Scalars['String'];
  status?: Maybe<LeagueStatuses>;
  name?: Maybe<Scalars['String']>;
  tags: Array<Scalars['String']>;
  pagination: OffsetPaginationInput;
};

export type ListMediaOrganizationsInput = {
  status?: Maybe<MediaOrganizationStatuses>;
  name?: Maybe<Scalars['String']>;
  pagination: OffsetPaginationInput;
};

export type ListMyCompetitionSurveysInput = {
  pagination: OffsetPaginationInput;
};

export type ListMyCompetitionTeamKitsInput = {
  fromDate?: Maybe<Scalars['String']>;
  toDate?: Maybe<Scalars['String']>;
  teamSearch?: Maybe<Scalars['String']>;
  statuses?: Maybe<Array<CompetitionTeamKitStatus>>;
  pagination: OffsetPaginationInput;
};

export type ListMySurveySummaryRecipientNotificationSettingsInput = {
  surveyName?: Maybe<Scalars['String']>;
  pagination: OffsetPaginationInput;
};

export type ListOfficialAssessmentObjectsForSeasonInput = {
  seasonId: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  pagination: OffsetPaginationInput;
};

export type ListOfficialParticipationSummariesForOrgInput = {
  orgId: Scalars['String'];
  leagueId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  descending?: Maybe<Scalars['Boolean']>;
  leagueName?: Maybe<Scalars['String']>;
  seasonName?: Maybe<Scalars['String']>;
  teamNameOrAbbreviation?: Maybe<Scalars['String']>;
  toDate?: Maybe<Scalars['String']>;
  fromDate?: Maybe<Scalars['String']>;
  status?: Maybe<SurveyParticipationStatusesFilter>;
  pagination: OffsetPaginationInput;
};

export type ListOfficialSurveyParticipationForOrgInput = {
  orgId: Scalars['String'];
  competitionId: Scalars['String'];
  status: SurveyParticipationStatuses;
  recipientStatus: SurveyRecipientParticipationStatuses;
  leagueId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  leagueName?: Maybe<Scalars['String']>;
  seasonName?: Maybe<Scalars['String']>;
  surveyName?: Maybe<Scalars['String']>;
  teamNameOrAbbreviation?: Maybe<Scalars['String']>;
  toDate?: Maybe<Scalars['String']>;
  fromDate?: Maybe<Scalars['String']>;
  userStatus?: Maybe<UserStatuses>;
  officialStatus?: Maybe<OfficialStatuses>;
  officialNameOrEmail?: Maybe<Scalars['String']>;
  pagination: OffsetPaginationInput;
};

export type ListOfficialsForCompetitionInput = {
  competitionId: Scalars['String'];
  nameOrEmail?: Maybe<Scalars['String']>;
  status?: Maybe<OfficialStatuses>;
  type?: Maybe<Scalars['String']>;
  pagination: OffsetPaginationInput;
};

export type ListOfficialsForSeasonInput = {
  seasonId: Scalars['String'];
  nameOrEmail?: Maybe<Scalars['String']>;
  status?: Maybe<Array<OfficialStatuses>>;
  userStatus?: Maybe<Array<UserStatuses>>;
  externalId?: Maybe<Scalars['String']>;
  pagination: OffsetPaginationInput;
};

export type ListOfficialsInput = {
  sport: Sports;
  nameOrEmail?: Maybe<Scalars['String']>;
  status?: Maybe<OfficialStatuses>;
  externalId?: Maybe<Scalars['String']>;
  pagination: OffsetPaginationInput;
};

export type ListOptionsForSeasonCompetitionIntegrationInput = {
  seasonIntegrationId: Scalars['String'];
};

export type ListOrgSupplementalReportsForCompetitionInput = {
  competitionId: Scalars['String'];
  filters?: Maybe<SupplementalReportFiltersInput>;
  pagination: OffsetPaginationInput;
};

export type ListOrganizationUsersInput = {
  organizationId: Scalars['String'];
  nameOrEmail?: Maybe<Scalars['String']>;
  pagination: OffsetPaginationInput;
};

export type ListOrganizationsInput = {
  sport?: Maybe<Sports>;
  status?: Maybe<OrganizationStatuses>;
  name?: Maybe<Scalars['String']>;
  includeDeleted?: Maybe<Scalars['Boolean']>;
  pagination: OffsetPaginationInput;
};

export type ListPeriodsForSeasonInput = {
  seasonId: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  pagination: OffsetPaginationInput;
};

export type ListPeriodsInput = {
  sport?: Maybe<Sports>;
  name?: Maybe<Scalars['String']>;
  pagination: OffsetPaginationInput;
};

export type ListReminderLevelRecipientsForDynamicSurveyInput = {
  surveyId: Scalars['String'];
  pagination: OffsetPaginationInput;
};

export type ListReminderLevelRecipientsForSurveyInput = {
  surveyId: Scalars['String'];
  pagination: OffsetPaginationInput;
};

export type ListRowsForBatchSeasonCoachUploadInput = {
  uploadId: Scalars['String'];
  pagination: OffsetPaginationInput;
};

export type ListRowsForBatchSeasonCompetitionUploadInput = {
  uploadId: Scalars['String'];
  pagination: OffsetPaginationInput;
};

export type ListRowsForBatchSeasonOfficialUploadInput = {
  uploadId: Scalars['String'];
  pagination: OffsetPaginationInput;
};

export type ListRowsForBatchSeasonSurveyResponseUploadInput = {
  uploadId: Scalars['String'];
  pagination: OffsetPaginationInput;
};

export type ListRowsForBatchSeasonTeamUploadInput = {
  uploadId: Scalars['String'];
  pagination: OffsetPaginationInput;
};

export type ListSeasonCompetitionIntegrationsForSeasonInput = {
  orgId: Scalars['String'];
  seasonId: Scalars['String'];
  pagination: OffsetPaginationInput;
};

export type ListSeasonDivisionAliasesInput = {
  seasonDivisionId: Scalars['String'];
  alias?: Maybe<Scalars['String']>;
  pagination: OffsetPaginationInput;
};

export type ListSeasonDivisionsForSeasonInput = {
  seasonId: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  pagination: OffsetPaginationInput;
};

export type ListSeasonMediaMembersOrgRecipientsInput = {
  orgId: Scalars['String'];
  seasonId: Scalars['String'];
  pagination: OffsetPaginationInput;
};

export type ListSeasonTeamKitApparelDefinitionsInput = {
  seasonId: Scalars['String'];
  pagination: OffsetPaginationInput;
};

export type ListSeasonTeamKitContactDefinitionsInput = {
  seasonId: Scalars['String'];
  type?: Maybe<TeamKitContactType>;
  pagination: OffsetPaginationInput;
};

export type ListSeasonTeamKitContactOptionsInput = {
  seasonId: Scalars['String'];
  contactDefinitionId: Scalars['String'];
  teamId?: Maybe<Scalars['String']>;
  pagination: OffsetPaginationInput;
};

export type ListSeasonTeamKitOrgRecipientsInput = {
  orgId: Scalars['String'];
  seasonId: Scalars['String'];
  pagination: OffsetPaginationInput;
};

export type ListSeasonTeamsAvailableForSeasonDivisionInput = {
  seasonId: Scalars['String'];
  seasonDivisionId: Scalars['String'];
  search?: Maybe<Scalars['String']>;
  pagination: OffsetPaginationInput;
};

export type ListSeasonTeamsForSeasonDivisionInput = {
  seasonId: Scalars['String'];
  seasonDivisionId?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  countryCodes?: Maybe<Array<CountryCodes>>;
  ageGroup?: Maybe<Scalars['String']>;
  classifications?: Maybe<Array<TeamClassifications>>;
  parentOrganizationName?: Maybe<Scalars['String']>;
  pagination: OffsetPaginationInput;
};

export type ListSeasonsForLeagueInput = {
  leagueId: Scalars['String'];
  status?: Maybe<SeasonStatuses>;
  name?: Maybe<Scalars['String']>;
  pagination: OffsetPaginationInput;
};

export type ListSourcesAvailableForAdditionalFeedbackForCompetitionsForMeInput = {
  competitionId: Scalars['String'];
  source: FeedbackSource;
  type: FeedbackType;
};

export type ListStatsForCompetitionByTeamInput = {
  competitionId: Scalars['String'];
  teamId?: Maybe<Scalars['String']>;
};

export type ListStatsForCompetitionInput = {
  competitionId: Scalars['String'];
  nameOrDisplayText?: Maybe<Scalars['String']>;
  teamName?: Maybe<Scalars['String']>;
  pagination: OffsetPaginationInput;
};

export type ListStatsForDynamicCompetitionSurveyByIdInput = {
  competitionSurveyId: Scalars['String'];
};

export type ListStatsForDynamicCompetitionSurveyByIdWithTokenInput = {
  surveyToken: Scalars['String'];
};

export type ListSummaryRecipientsForDynamicSurveyInput = {
  surveyId: Scalars['String'];
  emailOrName?: Maybe<Scalars['String']>;
  pagination: OffsetPaginationInput;
};

export type ListSupplementalReportsForDynamicCompetitionSurveyInput = {
  competitionSurveyId: Scalars['String'];
};

export type ListSupplementalReportsForDynamicCompetitionSurveyWithTokenInput = {
  surveyToken: Scalars['String'];
};

export type ListSupplementalReportsForOrgInput = {
  orgId: Scalars['String'];
  filters?: Maybe<SupplementalReportFiltersInput>;
  pagination: OffsetPaginationInput;
};

export type ListSupplementalReportsForUserInput = {
  filters?: Maybe<SupplementalReportFiltersInput>;
  pagination: OffsetPaginationInput;
};

export type ListSurveyResponsesForCompetitionAndSurveyInput = {
  orgId: Scalars['String'];
  competitionId: Scalars['String'];
  surveyId: Scalars['String'];
};

export type ListSurveyTemplatesForOrganizationInput = {
  status?: Maybe<SurveyStatuses>;
  orgId: Scalars['String'];
  adminFilter: SurveyTemplateAdminFilters;
  name?: Maybe<Scalars['String']>;
  pagination: OffsetPaginationInput;
};

export type ListSurveysForOrganizationInput = {
  orgId: Scalars['String'];
  seasonId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<SurveyStatuses>;
  audience?: Maybe<SurveyAudiences>;
  pagination: OffsetPaginationInput;
};

export type ListTeamAliasesInput = {
  teamId: Scalars['String'];
  alias?: Maybe<Scalars['String']>;
  pagination: OffsetPaginationInput;
};

export type ListTeamCoachesInput = {
  teamId: Scalars['String'];
  search?: Maybe<Scalars['String']>;
  type?: Maybe<CoachTypes>;
  status?: Maybe<CoachStatuses>;
  role?: Maybe<Scalars['String']>;
  userStatus?: Maybe<UserStatuses>;
  pagination: OffsetPaginationInput;
};

export type ListTeamKitBroadcastOptionsForSeasonInput = {
  seasonId: Scalars['String'];
  teamId?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  pagination: OffsetPaginationInput;
};

export type ListTeamKitRecipientsForSeasonTeamInput = {
  organizationId: Scalars['String'];
  seasonId: Scalars['String'];
  teamId: Scalars['String'];
  search?: Maybe<Scalars['String']>;
  activeOnly?: Maybe<Scalars['Boolean']>;
  pagination: OffsetPaginationInput;
};

export type ListTeamKitRolesForSeasonInput = {
  seasonId: Scalars['String'];
  type?: Maybe<TeamKitRoleType>;
  pagination: OffsetPaginationInput;
};

export type ListTeamKitSeasonApparelsInput = {
  seasonId: Scalars['String'];
  kitRoleId: Scalars['String'];
  kitApparelId: Scalars['String'];
  teamId?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  pagination: OffsetPaginationInput;
};

export type ListTeamKitSeasonKitsInput = {
  seasonId: Scalars['String'];
  kitRoleId: Scalars['String'];
  teamId?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  type?: Maybe<TeamKitType>;
  pagination: OffsetPaginationInput;
};

export type ListTeamKitTeamSetupSummariesForSeasonInput = {
  seasonId: Scalars['String'];
  search?: Maybe<Scalars['String']>;
  pagination: OffsetPaginationInput;
};

export type ListTeamParticipationSummariesForOrgInput = {
  orgId: Scalars['String'];
  leagueId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  descending?: Maybe<Scalars['Boolean']>;
  leagueName?: Maybe<Scalars['String']>;
  seasonName?: Maybe<Scalars['String']>;
  teamNameOrAbbreviation?: Maybe<Scalars['String']>;
  toDate?: Maybe<Scalars['String']>;
  fromDate?: Maybe<Scalars['String']>;
  status?: Maybe<SurveyParticipationStatusesFilter>;
  pagination: OffsetPaginationInput;
};

export type ListTeamSurveyParticipationForOrgInput = {
  orgId: Scalars['String'];
  teamId: Scalars['String'];
  status: SurveyParticipationStatuses;
  recipientStatus: SurveyRecipientParticipationStatuses;
  leagueId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  leagueName?: Maybe<Scalars['String']>;
  seasonName?: Maybe<Scalars['String']>;
  surveyName?: Maybe<Scalars['String']>;
  teamNameOrAbbreviation?: Maybe<Scalars['String']>;
  toDate?: Maybe<Scalars['String']>;
  fromDate?: Maybe<Scalars['String']>;
  userStatus?: Maybe<UserStatuses>;
  coachStatus?: Maybe<CoachStatuses>;
  pagination: OffsetPaginationInput;
};

export type ListTeamsForSeasonInput = {
  seasonId: Scalars['String'];
  search?: Maybe<Scalars['String']>;
  countryCodes?: Maybe<Array<CountryCodes>>;
  ageGroup?: Maybe<Scalars['String']>;
  classifications?: Maybe<Array<TeamClassifications>>;
  parentOrganizationName?: Maybe<Scalars['String']>;
  pagination: OffsetPaginationInput;
};

export type ListTeamsInput = {
  sport: Sports;
  search?: Maybe<Scalars['String']>;
  countryCode?: Maybe<CountryCodes>;
  pagination: OffsetPaginationInput;
};

export type ListUserNotificationSettingsInput = {
  notificationSettingsToken: Scalars['String'];
};

export type ListUserSupplementalReportsForCompetitionInput = {
  competitionId: Scalars['String'];
  filters?: Maybe<SupplementalReportFiltersInput>;
  pagination: OffsetPaginationInput;
};

export type LoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  rememberMe?: Maybe<Scalars['Boolean']>;
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  accessToken: Scalars['String'];
};

export type MarkMySelectedAlertsAsReadInput = {
  ids: Array<Scalars['String']>;
};

/** The Media Members season config status */
export enum MediaMembersSeasonConfigStatus {
  Pending = 'PENDING',
  Active = 'ACTIVE',
  Disabled = 'DISABLED'
}

export type MediaMembersSeasonOrgSetupSummary = {
  __typename?: 'MediaMembersSeasonOrgSetupSummary';
  seasonId: Scalars['String'];
  seasonName: Scalars['String'];
  leagueId: Scalars['String'];
  leagueName: Scalars['String'];
  orgId: Scalars['String'];
  orgName: Scalars['String'];
  orgLogo: Scalars['String'];
  kiEnabled: Scalars['Boolean'];
  kiRecipientsCount: Scalars['Int'];
  mediaMembersCount: Scalars['Int'];
};

export type MediaOrganization = {
  __typename?: 'MediaOrganization';
  id: Scalars['String'];
  name: Scalars['String'];
  abbreviation?: Maybe<Scalars['String']>;
  status: MediaOrganizationStatuses;
  site?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

/** The media organization statuses */
export enum MediaOrganizationStatuses {
  Pending = 'PENDING',
  Active = 'ACTIVE',
  Disabled = 'DISABLED'
}

export type MediaOrganizations = {
  __typename?: 'MediaOrganizations';
  mediaOrganizations: Array<MediaOrganization>;
  pagination: OffsetPagination;
};

export type Mutation = {
  __typename?: 'Mutation';
  createAssessmentObject: AssessmentObject;
  updateAssessmentObject: AssessmentObject;
  deleteAssessmentObject: Scalars['Boolean'];
  createCompetitionIntegrationForOrganization: CompetitionIntegration;
  updateCompetitionIntegration: CompetitionIntegration;
  deleteCompetitionIntegration: Scalars['Boolean'];
  addAdditionalOptionToCompetitionIntegration: CompetitionIntegrationAdditionalOption;
  updateAdditionalOptionForCompetitionIntegration: CompetitionIntegrationAdditionalOption;
  removeAdditionalOptionFromCompetitionIntegration: Scalars['Boolean'];
  createDynamicSurveyIntegrationForOrganization: DynamicSurveyIntegration;
  updateDynamicSurveyIntegration: DynamicSurveyIntegration;
  deleteDynamicSurveyIntegration: Scalars['Boolean'];
  addAdditionalOptionToDynamicSurveyIntegration: DynamicSurveyIntegrationAdditionalOption;
  updateAdditionalOptionForDynamicSurveyIntegration: DynamicSurveyIntegrationAdditionalOption;
  removeAdditionalOptionFromDynamicSurveyIntegration: Scalars['Boolean'];
  createKeyIncidentVideoIntegrationForOrganization: KeyIncidentVideoIntegration;
  updateKeyIncidentVideoIntegration: KeyIncidentVideoIntegration;
  deleteKeyIncidentVideoIntegration: Scalars['Boolean'];
  addAdditionalOptionToKeyIncidentVideoIntegration: KeyIncidentVideoIntegrationAdditionalOption;
  removeAdditionalOptionFromKeyIncidentVideoIntegration: Scalars['Boolean'];
  createMediaOrganization: MediaOrganization;
  updateMediaOrganization: MediaOrganization;
  deleteMediaOrganization: Scalars['Boolean'];
  createOrganization: Organization;
  deleteOrganization: Scalars['Boolean'];
  updateOrganizationStatus: Organization;
  setFeatureForOrganization: OrganizationFeature;
  addFeatureForOrganization: OrganizationFeature;
  createPeriod: Period;
  updatePeriod: Period;
  deletePeriod: Scalars['Boolean'];
  inviteAdminUser: InviteAdminUserResponse;
  deleteMyAlert: Scalars['Boolean'];
  deleteMySelectedAlerts: Scalars['Boolean'];
  deleteAllMyReadAlerts: Scalars['Boolean'];
  setReadForMyAlert: UserAlert;
  markMySelectedAlertsAsRead: Scalars['Boolean'];
  markAllMyAlertsAsRead: Scalars['Boolean'];
  login: LoginResponse;
  resendUserConfirmationEmail: Scalars['Boolean'];
  confirmUser: Scalars['Boolean'];
  updateMyPassword: Scalars['Boolean'];
  requestResetPasswordLink: Scalars['Boolean'];
  resetPassword: Scalars['Boolean'];
  batchUploadCoachesForSeason: BatchSeasonCoachUpload;
  batchUploadCompetitionsForSeason: BatchSeasonCompetitionUpload;
  batchUploadOfficialsForSeason: BatchSeasonOfficialUpload;
  batchUploadSurveyResponsesForSeason: BatchSeasonSurveyResponseUpload;
  batchUploadTeamsForSeason: BatchSeasonTeamUpload;
  addCoachToTeam: Coach;
  removeCoachFromTeam: Scalars['Boolean'];
  deactivateCoach: Scalars['Boolean'];
  reactivateCoach: Scalars['Boolean'];
  editCoach: Coach;
  startCompetitionSurvey: Scalars['Boolean'];
  updateCompetitionSurveyCompetitionScore: Scalars['Boolean'];
  updateCompetitionSurveyMultipleChoiceResponse: CompetitionSurveySectionQuestion;
  updateCompetitionSurveyShortAnswerResponse: CompetitionSurveySectionQuestion;
  updateCompetitionSurveyTextResponse: CompetitionSurveySectionQuestion;
  updateCompetitionSurveyNumberResponse: CompetitionSurveySectionQuestion;
  addCompetitionSurveyMultipleResponse: CompetitionSurveySectionQuestionMultipleResponse;
  removeCompetitionSurveyMultipleResponse: CompetitionSurveySectionQuestion;
  convertCompetitionSurveySectionFeedbackToKeyIncident: KeyIncident;
  addKeyIncidentToCompetitionSurvey: KeyIncident;
  editKeyIncidentToCompetitionSurvey: KeyIncident;
  removeKeyIncidentFromCompetitionSurvey: Scalars['Boolean'];
  removeExternalLinkFromKeyIncidentFromCompetitionSurvey: Scalars['Boolean'];
  addExternalLinkToKeyIncidentToCompetitionSurvey: KeyIncidentExternalLink;
  completeCompetitionSurvey: Scalars['Boolean'];
  startCompetitionSurveyWithToken: Scalars['Boolean'];
  updateCompetitionSurveyCompetitionScoreWithToken: Scalars['Boolean'];
  updateCompetitionSurveyMultipleChoiceResponseWithToken: CompetitionSurveySectionQuestion;
  updateCompetitionSurveyShortAnswerResponseWithToken: CompetitionSurveySectionQuestion;
  updateCompetitionSurveyTextResponseWithToken: CompetitionSurveySectionQuestion;
  updateCompetitionSurveyNumberResponseWithToken: CompetitionSurveySectionQuestion;
  addCompetitionSurveyMultipleResponseWithToken: CompetitionSurveySectionQuestionMultipleResponse;
  removeCompetitionSurveyMultipleResponseWithToken: CompetitionSurveySectionQuestion;
  convertCompetitionSurveySectionFeedbackToKeyIncidentWithToken: KeyIncident;
  addKeyIncidentToCompetitionSurveyWithToken: KeyIncident;
  editKeyIncidentToCompetitionSurveyWithToken: KeyIncident;
  removeKeyIncidentFromCompetitionSurveyWithToken: Scalars['Boolean'];
  removeExternalLinkFromKeyIncidentFromCompetitionSurveyWithToken: Scalars['Boolean'];
  addExternalLinkToKeyIncidentForCompetitionSurveyWithToken: KeyIncidentExternalLink;
  completeCompetitionSurveyWithToken: Scalars['Boolean'];
  sendCompetitionTeamKitToHomeTeamEdit: CompetitionTeamKit;
  sendCompetitionTeamKitToAwayTeamEdit: CompetitionTeamKit;
  sendCompetitionTeamKitToLeagueEdit: CompetitionTeamKit;
  sendCompetitionTeamKitRequestedFixToLeagueEdit: CompetitionTeamKit;
  competitionTeamKitApparelApproval: CompetitionTeamKitApparel;
  approveCompetitionTeamKit: CompetitionTeamKit;
  competitionTeamKitRequestFix: CompetitionTeamKit;
  reopenCompetitionTeamKit: CompetitionTeamKit;
  addCompetitionInfoToCompetitionTeamKit: CompetitionTeamKitCompetitionInfo;
  updateCompetitionTeamKitCompetitionInfo: CompetitionTeamKitCompetitionInfo;
  removeCompetitionInfoFromCompetitionTeamKit: Scalars['Boolean'];
  addBroadcastOptionToCompetitionTeamKit: CompetitionTeamKitBroadcastOption;
  removeBroadcastOptionFromCompetitionTeamKit: Scalars['Boolean'];
  clearSelectionForCompetitionTeamKitApparel: CompetitionTeamKitApparel;
  updateSelectionForCompetitionTeamKitApparel: CompetitionTeamKitApparel;
  clearSelectionForCompetitionTeamKitContact: CompetitionTeamKitContact;
  updateSelectionForCompetitionTeamKitContact: CompetitionTeamKitContact;
  addNewContactToCompetitionTeamKitContact: CompetitionTeamKitContact;
  createCompetitionForSeason: Competition;
  activateCompetition: Competition;
  cancelCompetition: Competition;
  deleteCompetition: Scalars['Boolean'];
  updateCompetition: Competition;
  updateCompetitionScore: Competition;
  addOfficialToCompetition: CompetitionOfficial;
  updateCompetitionOfficial: CompetitionOfficial;
  removeOfficialFromCompetition: Scalars['Boolean'];
  createCompetitionStatForCompetition: CompetitionStat;
  updateCompetitionStatValue: CompetitionStat;
  deleteCompetitionStat: Scalars['Boolean'];
  startDynamicCompetitionSurvey: Scalars['Boolean'];
  updateDynamicCompetitionSurveyCompetitionScore: Scalars['Boolean'];
  updateDynamicCompetitionSurveyMultipleChoiceResponse: DynamicCompetitionSurveySectionQuestion;
  updateDynamicCompetitionSurveyShortAnswerResponse: DynamicCompetitionSurveySectionQuestion;
  updateDynamicCompetitionSurveyTextResponse: DynamicCompetitionSurveySectionQuestion;
  updateDynamicCompetitionSurveyNumberResponse: DynamicCompetitionSurveySectionQuestion;
  updateDynamicCompetitionSurveyBooleanResponse: DynamicCompetitionSurveySectionQuestion;
  addDynamicCompetitionSurveyMultipleResponse: DynamicCompetitionSurveySectionQuestionMultipleResponse;
  removeDynamicCompetitionSurveyMultipleResponse: DynamicCompetitionSurveySectionQuestion;
  addDynamicCompetitionSurveyFileUpload: DynamicCompetitionSurveySectionQuestionFileUpload;
  removeDynamicCompetitionSurveyFileUpload: DynamicCompetitionSurveySectionQuestion;
  refreshDynamicCompetitionSurveyFileUploadLink: Scalars['String'];
  updateDynamicCompetitionSurveyMultiPartQuestion: DynamicCompetitionSurveySectionQuestion;
  updateDynamicCompetitionSurveySubQuestionMultipleChoiceResponse: DynamicCompetitionSurveySectionSubQuestion;
  updateDynamicCompetitionSurveySubQuestionShortAnswerResponse: DynamicCompetitionSurveySectionSubQuestion;
  updateDynamicCompetitionSurveySubQuestionTextResponse: DynamicCompetitionSurveySectionSubQuestion;
  updateDynamicCompetitionSurveySubQuestionNumberResponse: DynamicCompetitionSurveySectionSubQuestion;
  updateDynamicCompetitionSurveySubQuestionBooleanResponse: DynamicCompetitionSurveySectionSubQuestion;
  addDynamicCompetitionSurveySubQuestionMultipleResponse: DynamicCompetitionSurveySectionSubQuestionMultipleResponse;
  removeDynamicCompetitionSurveySubQuestionMultipleResponse: DynamicCompetitionSurveySectionSubQuestion;
  addSupplementalReportToDynamicCompetitionSurvey: SupplementalReport;
  removeSupplementalReportFromDynamicCompetitionSurvey: Scalars['Boolean'];
  editSupplementalReportForDynamicCompetitionSurvey: SupplementalReport;
  convertDynamicCompetitionSurveyMultipleResponseToKeyIncident: KeyIncident;
  convertDynamicCompetitionSurveySubMultipleResponseToKeyIncident: KeyIncident;
  addKeyIncidentToDynamicCompetitionSurvey: KeyIncident;
  editKeyIncidentForDynamicCompetitionSurvey: KeyIncident;
  removeKeyIncidentFromDynamicCompetitionSurvey: Scalars['Boolean'];
  signDynamicCompetitionSurvey: DynamicCompetitionSurveySignature;
  completeDynamicCompetitionSurvey: Scalars['Boolean'];
  startDynamicCompetitionSurveyWithToken: Scalars['Boolean'];
  updateDynamicCompetitionSurveyCompetitionScoreWithToken: Scalars['Boolean'];
  updateDynamicCompetitionSurveyMultipleChoiceResponseWithToken: DynamicCompetitionSurveySectionQuestion;
  updateDynamicCompetitionSurveyShortAnswerResponseWithToken: DynamicCompetitionSurveySectionQuestion;
  updateDynamicCompetitionSurveyTextResponseWithToken: DynamicCompetitionSurveySectionQuestion;
  updateDynamicCompetitionSurveyNumberResponseWithToken: DynamicCompetitionSurveySectionQuestion;
  updateDynamicCompetitionSurveyBooleanResponseWithToken: DynamicCompetitionSurveySectionQuestion;
  addDynamicCompetitionSurveyMultipleResponseWithToken: DynamicCompetitionSurveySectionQuestionMultipleResponse;
  removeDynamicCompetitionSurveyMultipleResponseWithToken: DynamicCompetitionSurveySectionQuestion;
  addDynamicCompetitionSurveyFileUploadWithToken: DynamicCompetitionSurveySectionQuestionFileUpload;
  removeDynamicCompetitionSurveyFileUploadWithToken: DynamicCompetitionSurveySectionQuestion;
  addSupplementalReportToDynamicCompetitionSurveyWithToken: SupplementalReport;
  removeSupplementalReportFromDynamicCompetitionSurveyWithToken: Scalars['Boolean'];
  editSupplementalReportForDynamicCompetitionSurveyWithToken: SupplementalReport;
  updateDynamicCompetitionSurveyMultiPartQuestionWithToken: DynamicCompetitionSurveySectionQuestion;
  updateDynamicCompetitionSurveySubQuestionMultipleChoiceResponseWithToken: DynamicCompetitionSurveySectionSubQuestion;
  updateDynamicCompetitionSurveySubQuestionShortAnswerResponseWithToken: DynamicCompetitionSurveySectionSubQuestion;
  updateDynamicCompetitionSurveySubQuestionTextResponseWithToken: DynamicCompetitionSurveySectionSubQuestion;
  updateDynamicCompetitionSurveySubQuestionNumberResponseWithToken: DynamicCompetitionSurveySectionSubQuestion;
  updateDynamicCompetitionSurveySubQuestionBooleanResponseWithToken: DynamicCompetitionSurveySectionSubQuestion;
  addDynamicCompetitionSurveySubQuestionMultipleResponseWithToken: DynamicCompetitionSurveySectionSubQuestionMultipleResponse;
  removeDynamicCompetitionSurveySubQuestionMultipleResponseWithToken: DynamicCompetitionSurveySectionSubQuestion;
  convertDynamicCompetitionSurveyMultipleResponseToKeyIncidentWithToken: KeyIncident;
  convertDynamicCompetitionSurveySubMultipleResponseToKeyIncidentWithToken: KeyIncident;
  addKeyIncidentToDynamicCompetitionSurveyWithToken: KeyIncident;
  editKeyIncidentForDynamicCompetitionSurveyWithToken: KeyIncident;
  removeKeyIncidentFromDynamicCompetitionSurveyWithToken: Scalars['Boolean'];
  signDynamicCompetitionSurveyWithToken: DynamicCompetitionSurveySignature;
  completeDynamicCompetitionSurveyWithToken: Scalars['Boolean'];
  createSeasonDynamicSurvey: DynamicSurvey;
  deleteDynamicSurvey: Scalars['Boolean'];
  activateDynamicSurvey: Scalars['Boolean'];
  deactivateDynamicSurvey: Scalars['Boolean'];
  updateDynamicSurveyInfo: DynamicSurveyInfo;
  setDynamicSurveyCompetitionVerification: DynamicSurveyCompetitionVerification;
  updateDynamicSurveyReminders: SurveyRemindersConfig;
  addDynamicSurveyReminderLevelReportRecipient: SurveyReminderLevelRecipient;
  updateDynamicSurveyReminderLevelRecipient: SurveyReminderLevelRecipient;
  deleteDynamicSurveyReminderLevelRecipient: Scalars['Boolean'];
  addDynamicSurveySignatureSection: Scalars['Boolean'];
  updateDynamicSurveySignatureSectionText: Scalars['Boolean'];
  deleteDynamicSurveySignatureSection: Scalars['Boolean'];
  addDynamicSurveyAssessmentObjectRecipient: SurveyAssessmentObjectRecipient;
  deleteDynamicSurveyAssessmentObjectRecipient: Scalars['Boolean'];
  addDynamicSurveyIncidentReport: SurveyKeyIncidentRecipient;
  deleteDynamicSurveyIncidentReport: Scalars['Boolean'];
  addDynamicSurveyIncidentReportRecipient: SurveyKeyIncidentRecipient;
  deleteDynamicSurveyIncidentReportRecipient: Scalars['Boolean'];
  setDynamicSurveyAllowAdditionalIncidentReports: DynamicSurveyAdditionsConfig;
  setDynamicSurveyAllowAdditionalIncidentReportsCutoffDays: DynamicSurveyAdditionsConfig;
  addDynamicSurveySupplementalReport: SurveySupplementalReportRecipient;
  deleteDynamicSurveySupplementalReport: Scalars['Boolean'];
  addDynamicSurveySupplementalReportRecipient: SurveySupplementalReportRecipient;
  deleteDynamicSurveySupplementalReportRecipient: Scalars['Boolean'];
  setDynamicSurveyAllowAdditionalSupplementalReports: DynamicSurveyAdditionsConfig;
  setDynamicSurveyAllowAdditionalSupplementalReportsCutoffDays: DynamicSurveyAdditionsConfig;
  addDynamicSurveySummaryRecipient: SurveySummaryRecipient;
  deleteDynamicSurveySummaryRecipient: Scalars['Boolean'];
  setIsActiveForDynamicSurveySummaryRecipient: SurveySummaryRecipient;
  addDynamicSurveySection: DynamicSurveySection;
  updateDynamicSurveySection: DynamicSurveySection;
  updateDynamicSurveySectionSupplementalReport: DynamicSurveySection;
  deleteDynamicSurveySection: Scalars['Boolean'];
  addDynamicSurveySectionQuestion: DynamicSurveySectionQuestion;
  updateDynamicSurveySectionQuestion: DynamicSurveySectionQuestion;
  deleteDynamicSurveySectionQuestion: Scalars['Boolean'];
  setDynamicSurveySectionQuestionCanAddToKeyIncidents: DynamicSurveySectionQuestion;
  addDynamicSurveySectionQuestionOption: DynamicSurveySectionQuestionOption;
  deleteDynamicSurveySectionQuestionOption: Scalars['Boolean'];
  updateDynamicSurveySectionQuestionOption: DynamicSurveySectionQuestionOption;
  addDynamicSurveySectionSubQuestion: DynamicSurveySectionSubQuestion;
  updateDynamicSurveySectionSubQuestion: DynamicSurveySectionSubQuestion;
  deleteDynamicSurveySectionSubQuestion: Scalars['Boolean'];
  setDynamicSurveySectionSubQuestionCanAddToKeyIncidents: DynamicSurveySectionSubQuestion;
  addDynamicSurveySectionSubQuestionOption: DynamicSurveySectionSubQuestionOption;
  deleteDynamicSurveySectionSubQuestionOption: Scalars['Boolean'];
  updateDynamicSurveySectionSubQuestionOption: DynamicSurveySectionSubQuestionOption;
  addAdditionalSupplementalReportToDynamicCompetitionSurvey: SupplementalReport;
  addAdditionalKeyIncidentToCompetitionSurvey: KeyIncident;
  beginReviewForKeyIncident: KeyIncident;
  resolveKeyIncident: KeyIncidentResolved;
  addCommentToKeyIncident: KeyIncidentMessage;
  editKeyIncident: KeyIncident;
  addExternalLinkToKeyIncident: KeyIncidentExternalLink;
  deleteKeyIncidentExternalLink: Scalars['Boolean'];
  createLeague: League;
  deleteLeague: Scalars['Boolean'];
  updateLeague: League;
  addLeagueTag: LeagueTag;
  removeLeagueTag: Scalars['Boolean'];
  addLeagueMembership: Scalars['Boolean'];
  removeLeagueMembership: Scalars['Boolean'];
  createOfficial: Official;
  deactivateOfficial: Official;
  deleteOfficial: Scalars['Boolean'];
  updateOrganization: Organization;
  createNewOrganizationUser: CreateNewOrganizationUserResponse;
  addUserToOrganization: AddUserToOrganizationResponse;
  removeUserFromOrganization: Scalars['Boolean'];
  updateUserOrganizationMembership: OrganizationMembership;
  createOrganizationContact: OrganizationContact;
  updateOrganizationContact: OrganizationContact;
  deleteOrganizationContact: Scalars['Boolean'];
  resendCompetitionSurveyNotificationEmail: Scalars['Boolean'];
  resendDynamicCompetitionSurveyNotificationEmail: Scalars['Boolean'];
  removeCompetitionSurveyParticipation: Scalars['Boolean'];
  resetTookCompetitionSurvey: Scalars['Boolean'];
  reopenCompetitionSurvey: Scalars['Boolean'];
  resetTookDynamicCompetitionSurvey: Scalars['Boolean'];
  reopenDynamicCompetitionSurvey: Scalars['Boolean'];
  updateCompetitionIntegrationEnabledForSeason: Scalars['Boolean'];
  createSeasonCompetitionIntegration: SeasonCompetitionIntegration;
  updateSeasonCompetitionIntegration: SeasonCompetitionIntegration;
  deleteSeasonCompetitionIntegration: Scalars['Boolean'];
  updateSeasonCompetitionIntegrationOption: SeasonCompetitionIntegrationOption;
  activateSeasonCompetitionIntegration: SeasonCompetitionIntegration;
  disableSeasonCompetitionIntegration: SeasonCompetitionIntegration;
  runSeasonCompetitionIntegration: Scalars['Boolean'];
  updateMediaMembersEnabledForSeason: Scalars['Boolean'];
  createSeasonMediaMembersConfig: SeasonMediaMembersConfig;
  updateSeasonMediaMembersConfig: SeasonMediaMembersConfig;
  deactivateMediaMembersConfigForSeason: SeasonMediaMembersConfig;
  addSeasonMediaMembersOrgRecipient: SeasonMediaMembersOrgRecipient;
  deleteSeasonMediaMembersOrgRecipient: Scalars['Boolean'];
  activateSeasonMediaMembersOrgRecipient: SeasonMediaMembersOrgRecipient;
  setIsKIRecipientForSeasonMediaMembersOrgRecipient: SeasonMediaMembersOrgRecipient;
  updateTeamKitEnabledForSeason: Scalars['Boolean'];
  createSeasonTeamKit: SeasonTeamKit;
  updateSeasonTeamKit: SeasonTeamKit;
  activateTeamKitForSeason: SeasonTeamKitActivation;
  deactivateTeamKitForSeason: SeasonTeamKit;
  createSeasonTeamKitRole: SeasonTeamKitRole;
  updateSeasonTeamKitRole: SeasonTeamKitRole;
  deleteSeasonTeamKitRole: Scalars['Boolean'];
  createSeasonTeamKitApparelDefinition: SeasonTeamKitApparelDefinition;
  updateSeasonTeamKitApparelDefinition: SeasonTeamKitApparelDefinition;
  deleteSeasonTeamKitApparelDefinition: Scalars['Boolean'];
  createSeasonTeamKitContactDefinition: SeasonTeamKitContactDefinition;
  updateSeasonTeamKitContactDefinition: SeasonTeamKitContactDefinition;
  deleteSeasonTeamKitContactDefinition: Scalars['Boolean'];
  createSeasonTeamKitContactOption: SeasonTeamKitContactOption;
  setDefaultSeasonTeamKitContactOption: SeasonTeamKitContactOption;
  deleteSeasonTeamKitContactOption: Scalars['Boolean'];
  createTeamKitBroadcastOptionForSeason: SeasonTeamKitBroadcastOption;
  updateTeamKitBroadcastOptionForSeason: SeasonTeamKitBroadcastOption;
  deleteTeamKitBroadcastOptionForSeason: Scalars['Boolean'];
  createTeamKitSeasonApparel: TeamKitSeasonApparel;
  updateTeamKitSeasonApparel: TeamKitSeasonApparel;
  updateTeamKitSeasonApparelImage: TeamKitSeasonApparel;
  deleteTeamKitSeasonApparel: Scalars['Boolean'];
  createTeamKitSeasonKit: TeamKitSeasonKit;
  updateTeamKitSeasonKit: TeamKitSeasonKit;
  deleteTeamKitSeasonKit: Scalars['Boolean'];
  addSeasonTeamKitOrgRecipient: SeasonTeamKitOrgRecipient;
  activateSeasonTeamKitOrgRecipient: SeasonTeamKitOrgRecipient;
  deleteSeasonTeamKitOrgRecipient: Scalars['Boolean'];
  createSeason: Season;
  deleteSeason: Scalars['Boolean'];
  updateSeason: Season;
  activateSeason: Season;
  deactivateSeason: Scalars['Boolean'];
  addTeamToSeason: SeasonTeam;
  createNewTeamForSeason: SeasonTeam;
  removeTeamFromSeason: Scalars['Boolean'];
  createNewSeasonCoach: Coach;
  addOfficialToSeason: SeasonOfficial;
  createNewOfficialForSeason: SeasonOfficialAdded;
  removeOfficialFromSeason: Scalars['Boolean'];
  inviteSeasonOfficialUser: Scalars['Boolean'];
  addOfficialAssessmentObjectToSeason: SeasonOfficialAssessmentObject;
  removeOfficialAssessmentObjectFromSeason: Scalars['Boolean'];
  setIsOptionalForSeasonOfficialAssessmentObject: SeasonOfficialAssessmentObject;
  addPeriodToSeason: SeasonPeriod;
  removeSeasonPeriodFromSeason: Scalars['Boolean'];
  createSeasonDivision: SeasonDivision;
  updateSeasonDivision: SeasonDivision;
  deleteSeasonDivision: Scalars['Boolean'];
  createSeasonDivisionAlias: SeasonDivisionAlias;
  updateSeasonDivisionAlias: SeasonDivisionAlias;
  deleteSeasonDivisionAlias: Scalars['Boolean'];
  addSeasonTeamToSeasonDivision: SeasonDivisionTeam;
  deleteSeasonDivisionTeam: Scalars['Boolean'];
  updateReportForSupplementalReport: SupplementalReport;
  requestChangeForSupplementalReport: SupplementalReport;
  approveSupplementalReport: SupplementalReport;
  addCommentToSupplementalReport: SupplementalReportMessage;
  updateSurveySummaryRecipientNotificationSettingByToken: Scalars['Boolean'];
  updateMySurveySummaryRecipientNotificationSetting: Scalars['Boolean'];
  createAdminSurveyTemplate: SurveyTemplate;
  createOrganizationSurveyTemplate: SurveyTemplate;
  deleteSurveyTemplate: Scalars['Boolean'];
  updateSurveyTemplateInfo: SurveyTemplateInfo;
  addSurveyTemplateIncidentReport: Scalars['Boolean'];
  deleteSurveyTemplateIncidentReport: Scalars['Boolean'];
  addSurveyTemplateSection: SurveyTemplateSection;
  updateSurveyTemplateSection: SurveyTemplateSection;
  deleteSurveyTemplateSection: Scalars['Boolean'];
  addSurveyTemplateSectionQuestion: SurveyTemplateSectionQuestion;
  updateSurveyTemplateSectionQuestion: SurveyTemplateSectionQuestion;
  deleteSurveyTemplateSectionQuestion: Scalars['Boolean'];
  setSurveyTemplateSectionQuestionCanAddToKeyIncidents: SurveyTemplateSectionQuestion;
  addSurveyTemplateSectionQuestionOption: SurveyTemplateSectionQuestionOption;
  deleteSurveyTemplateSectionQuestionOption: Scalars['Boolean'];
  updateSurveyTemplateSectionQuestionOption: SurveyTemplateSectionQuestionOption;
  createSeasonSurvey: Survey;
  createSeasonSurveyFromTemplate: Survey;
  deleteSurvey: Scalars['Boolean'];
  activateSurvey: Scalars['Boolean'];
  deactivateSurvey: Scalars['Boolean'];
  updateSurveyInfo: SurveyInfo;
  setSurveyCompetitionVerification: SurveyCompetitionVerification;
  updateSurveyReminders: SurveyRemindersConfig;
  addSurveyReminderLevelReportRecipient: SurveyReminderLevelRecipient;
  updateSurveyReminderLevelRecipient: SurveyReminderLevelRecipient;
  deleteSurveyReminderLevelRecipient: Scalars['Boolean'];
  addSurveyAssessmentObjectRecipient: SurveyAssessmentObjectRecipient;
  deleteSurveyAssessmentObjectRecipient: Scalars['Boolean'];
  addSurveyIncidentReport: SurveyKeyIncidentRecipient;
  deleteSurveyIncidentReport: Scalars['Boolean'];
  addSurveyIncidentReportRecipient: SurveyKeyIncidentRecipient;
  deleteSurveyIncidentReportRecipient: Scalars['Boolean'];
  addKeyIncidentVideoIntegrationToSurvey: KeyIncidentVideoIntegration;
  removeKeyIncidentVideoIntegrationFromSurvey: Scalars['Boolean'];
  setSurveyAllowAdditionalIncidentReports: SurveyAdditionsConfig;
  setSurveyAllowAdditionalIncidentReportsCutoffDays: SurveyAdditionsConfig;
  addSurveySection: SurveySection;
  updateSurveySection: SurveySection;
  deleteSurveySection: Scalars['Boolean'];
  addSurveySectionQuestion: SurveySectionQuestion;
  updateSurveySectionQuestion: SurveySectionQuestion;
  deleteSurveySectionQuestion: Scalars['Boolean'];
  setSurveySectionQuestionCanAddToKeyIncidents: SurveySectionQuestion;
  addSurveySectionQuestionOption: SurveySectionQuestionOption;
  deleteSurveySectionQuestionOption: Scalars['Boolean'];
  updateSurveySectionQuestionOption: SurveySectionQuestionOption;
  createTeamForSeason: Team;
  deleteTeam: Scalars['Boolean'];
  updateTeamForSeason: Team;
  createTeamAlias: TeamAlias;
  updateTeamAlias: TeamAlias;
  deleteTeamAlias: Scalars['Boolean'];
  createTeamContactForSeason: TeamContact;
  updateTeamContactForSeason: TeamContact;
  deleteTeamContactForSeason: Scalars['Boolean'];
  createTeamKitTeamRecipientForSeason: TeamKitTeamRecipient;
  updateTeamKitTeamRecipientForSeason: TeamKitTeamRecipient;
  activateSeasonTeamKitTeamRecipient: TeamKitTeamRecipient;
  uploadUserProfilePicture: Scalars['Boolean'];
  updateMyProfile: User;
  updateUserNotificationSettings: Scalars['Boolean'];
  updateMyNotificationSettings: Scalars['Boolean'];
  retryDynamicCompetitionIntegrationSurvey: Scalars['Boolean'];
};


export type MutationCreateAssessmentObjectArgs = {
  input: CreateAssessmentObjectInput;
};


export type MutationUpdateAssessmentObjectArgs = {
  input: UpdateAssessmentObjectInput;
};


export type MutationDeleteAssessmentObjectArgs = {
  input: DeleteAssessmentObjectInput;
};


export type MutationCreateCompetitionIntegrationForOrganizationArgs = {
  input: CreateCompetitionIntegrationForOrganizationInput;
};


export type MutationUpdateCompetitionIntegrationArgs = {
  input: UpdateCompetitionIntegrationInput;
};


export type MutationDeleteCompetitionIntegrationArgs = {
  input: DeleteCompetitionIntegrationInput;
};


export type MutationAddAdditionalOptionToCompetitionIntegrationArgs = {
  input: AddAdditionalOptionToCompetitionIntegrationInput;
};


export type MutationUpdateAdditionalOptionForCompetitionIntegrationArgs = {
  input: UpdateAdditionalOptionForCompetitionIntegrationInput;
};


export type MutationRemoveAdditionalOptionFromCompetitionIntegrationArgs = {
  input: RemoveAdditionalOptionFromCompetitionIntegrationInput;
};


export type MutationCreateDynamicSurveyIntegrationForOrganizationArgs = {
  input: CreateDynamicSurveyIntegrationForOrganizationInput;
};


export type MutationUpdateDynamicSurveyIntegrationArgs = {
  input: UpdateDynamicSurveyIntegrationInput;
};


export type MutationDeleteDynamicSurveyIntegrationArgs = {
  input: DeleteDynamicSurveyIntegrationInput;
};


export type MutationAddAdditionalOptionToDynamicSurveyIntegrationArgs = {
  input: AddAdditionalOptionToDynamicSurveyIntegrationInput;
};


export type MutationUpdateAdditionalOptionForDynamicSurveyIntegrationArgs = {
  input: UpdateAdditionalOptionForDynamicSurveyIntegrationInput;
};


export type MutationRemoveAdditionalOptionFromDynamicSurveyIntegrationArgs = {
  input: RemoveAdditionalOptionFromDynamicSurveyIntegrationInput;
};


export type MutationCreateKeyIncidentVideoIntegrationForOrganizationArgs = {
  input: CreateKeyIncidentVideoIntegrationForOrganizationInput;
};


export type MutationUpdateKeyIncidentVideoIntegrationArgs = {
  input: UpdateKeyIncidentVideoIntegrationInput;
};


export type MutationDeleteKeyIncidentVideoIntegrationArgs = {
  input: DeleteKeyIncidentVideoIntegrationInput;
};


export type MutationAddAdditionalOptionToKeyIncidentVideoIntegrationArgs = {
  input: AddAdditionalOptionToKeyIncidentVideoIntegrationInput;
};


export type MutationRemoveAdditionalOptionFromKeyIncidentVideoIntegrationArgs = {
  input: RemoveAdditionalOptionFromKeyIncidentVideoIntegrationInput;
};


export type MutationCreateMediaOrganizationArgs = {
  input: CreateMediaOrganizationInput;
};


export type MutationUpdateMediaOrganizationArgs = {
  input: UpdateMediaOrganizationInput;
};


export type MutationDeleteMediaOrganizationArgs = {
  input: DeleteMediaOrganizationInput;
};


export type MutationCreateOrganizationArgs = {
  input: CreateOrganizationInput;
};


export type MutationDeleteOrganizationArgs = {
  input: DeleteOrganizationInput;
};


export type MutationUpdateOrganizationStatusArgs = {
  input: UpdateOrganizationStatusInput;
};


export type MutationSetFeatureForOrganizationArgs = {
  input: SetFeatureForOrganizationInput;
};


export type MutationAddFeatureForOrganizationArgs = {
  input: AddFeatureForOrganizationInput;
};


export type MutationCreatePeriodArgs = {
  input: CreatePeriodInput;
};


export type MutationUpdatePeriodArgs = {
  input: UpdatePeriodInput;
};


export type MutationDeletePeriodArgs = {
  input: DeletePeriodInput;
};


export type MutationInviteAdminUserArgs = {
  input: InviteAdminUserInput;
};


export type MutationDeleteMyAlertArgs = {
  input: DeleteMyAlertInput;
};


export type MutationDeleteMySelectedAlertsArgs = {
  input: DeleteMySelectedAlertsInput;
};


export type MutationSetReadForMyAlertArgs = {
  input: SetReadForMyAlertInput;
};


export type MutationMarkMySelectedAlertsAsReadArgs = {
  input: MarkMySelectedAlertsAsReadInput;
};


export type MutationLoginArgs = {
  input: LoginInput;
};


export type MutationResendUserConfirmationEmailArgs = {
  input: ResendUserConfirmationEmailInput;
};


export type MutationConfirmUserArgs = {
  input: UserConfirmationInput;
};


export type MutationUpdateMyPasswordArgs = {
  input: SetMyPasswordInput;
};


export type MutationRequestResetPasswordLinkArgs = {
  input: RequestResetPasswordLinkInput;
};


export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};


export type MutationBatchUploadCoachesForSeasonArgs = {
  file: Scalars['Upload'];
  input: BatchUploadCoachesForSeasonInput;
};


export type MutationBatchUploadCompetitionsForSeasonArgs = {
  file: Scalars['Upload'];
  input: BatchUploadCompetitionsForSeasonInput;
};


export type MutationBatchUploadOfficialsForSeasonArgs = {
  file: Scalars['Upload'];
  input: BatchUploadOfficialsForSeasonInput;
};


export type MutationBatchUploadSurveyResponsesForSeasonArgs = {
  file: Scalars['Upload'];
  input: BatchUploadSurveyResponsesForSeasonInput;
};


export type MutationBatchUploadTeamsForSeasonArgs = {
  file: Scalars['Upload'];
  input: BatchUploadTeamsForSeasonInput;
};


export type MutationAddCoachToTeamArgs = {
  input: AddCoachToTeamInput;
};


export type MutationRemoveCoachFromTeamArgs = {
  input: RemoveCoachFromTeamInput;
};


export type MutationDeactivateCoachArgs = {
  input: DeactivateCoachInput;
};


export type MutationReactivateCoachArgs = {
  input: ReactivateCoachInput;
};


export type MutationEditCoachArgs = {
  input: EditCoachInput;
};


export type MutationStartCompetitionSurveyArgs = {
  input: StartCompetitionSurveyInput;
};


export type MutationUpdateCompetitionSurveyCompetitionScoreArgs = {
  input: UpdateCompetitionSurveyCompetitionScoreInput;
};


export type MutationUpdateCompetitionSurveyMultipleChoiceResponseArgs = {
  input: UpdateCompetitionSurveyMultipleChoiceResponseInput;
};


export type MutationUpdateCompetitionSurveyShortAnswerResponseArgs = {
  input: UpdateCompetitionSurveyShortAnswerResponseInput;
};


export type MutationUpdateCompetitionSurveyTextResponseArgs = {
  input: UpdateCompetitionSurveyTextResponseInput;
};


export type MutationUpdateCompetitionSurveyNumberResponseArgs = {
  input: UpdateCompetitionSurveyNumberResponseInput;
};


export type MutationAddCompetitionSurveyMultipleResponseArgs = {
  input: AddCompetitionSurveyMultipleResponseInput;
};


export type MutationRemoveCompetitionSurveyMultipleResponseArgs = {
  input: RemoveCompetitionSurveyMultipleResponseInput;
};


export type MutationConvertCompetitionSurveySectionFeedbackToKeyIncidentArgs = {
  input: ConvertCompetitionSurveySectionFeedbackToKeyIncidentInput;
};


export type MutationAddKeyIncidentToCompetitionSurveyArgs = {
  input: AddKeyIncidentToCompetitionSurveyInput;
};


export type MutationEditKeyIncidentToCompetitionSurveyArgs = {
  input: EditKeyIncidentToCompetitionSurveyInput;
};


export type MutationRemoveKeyIncidentFromCompetitionSurveyArgs = {
  input: RemoveKeyIncidentFromCompetitionSurveyInput;
};


export type MutationRemoveExternalLinkFromKeyIncidentFromCompetitionSurveyArgs = {
  input: RemoveExternalLinkFromKeyIncidentFromCompetitionSurveyInput;
};


export type MutationAddExternalLinkToKeyIncidentToCompetitionSurveyArgs = {
  input: AddExternalLinkToKeyIncidentToCompetitionSurveyInput;
};


export type MutationCompleteCompetitionSurveyArgs = {
  input: CompleteCompetitionSurveyInput;
};


export type MutationStartCompetitionSurveyWithTokenArgs = {
  input: StartCompetitionSurveyWithTokenInput;
};


export type MutationUpdateCompetitionSurveyCompetitionScoreWithTokenArgs = {
  input: UpdateCompetitionSurveyCompetitionScoreWithTokenInput;
};


export type MutationUpdateCompetitionSurveyMultipleChoiceResponseWithTokenArgs = {
  input: UpdateCompetitionSurveyMultipleChoiceResponseWithTokenInput;
};


export type MutationUpdateCompetitionSurveyShortAnswerResponseWithTokenArgs = {
  input: UpdateCompetitionSurveyShortAnswerResponseWithTokenInput;
};


export type MutationUpdateCompetitionSurveyTextResponseWithTokenArgs = {
  input: UpdateCompetitionSurveyTextResponseWithTokenInput;
};


export type MutationUpdateCompetitionSurveyNumberResponseWithTokenArgs = {
  input: UpdateCompetitionSurveyNumberResponseWithTokenInput;
};


export type MutationAddCompetitionSurveyMultipleResponseWithTokenArgs = {
  input: AddCompetitionSurveyMultipleResponseWithTokenInput;
};


export type MutationRemoveCompetitionSurveyMultipleResponseWithTokenArgs = {
  input: RemoveCompetitionSurveyMultipleResponseWithTokenInput;
};


export type MutationConvertCompetitionSurveySectionFeedbackToKeyIncidentWithTokenArgs = {
  input: ConvertCompetitionSurveySectionFeedbackToKeyIncidentWithTokenInput;
};


export type MutationAddKeyIncidentToCompetitionSurveyWithTokenArgs = {
  input: AddKeyIncidentToCompetitionSurveyWithTokenInput;
};


export type MutationEditKeyIncidentToCompetitionSurveyWithTokenArgs = {
  input: EditKeyIncidentToCompetitionSurveyWithTokenInput;
};


export type MutationRemoveKeyIncidentFromCompetitionSurveyWithTokenArgs = {
  input: RemoveKeyIncidentFromCompetitionSurveyWithTokenInput;
};


export type MutationRemoveExternalLinkFromKeyIncidentFromCompetitionSurveyWithTokenArgs = {
  input: RemoveExternalLinkFromKeyIncidentFromCompetitionSurveyWithTokenInput;
};


export type MutationAddExternalLinkToKeyIncidentForCompetitionSurveyWithTokenArgs = {
  input: AddExternalLinkToKeyIncidentToCompetitionSurveyWithTokenInput;
};


export type MutationCompleteCompetitionSurveyWithTokenArgs = {
  input: CompleteCompetitionSurveyWithTokenInput;
};


export type MutationSendCompetitionTeamKitToHomeTeamEditArgs = {
  input: SendCompetitionTeamKitToHomeTeamEditInput;
};


export type MutationSendCompetitionTeamKitToAwayTeamEditArgs = {
  input: SendCompetitionTeamKitToAwayTeamEditInput;
};


export type MutationSendCompetitionTeamKitToLeagueEditArgs = {
  input: SendCompetitionTeamKitToLeagueEditInput;
};


export type MutationSendCompetitionTeamKitRequestedFixToLeagueEditArgs = {
  input: SendCompetitionTeamKitRequestedFixToLeagueEditInput;
};


export type MutationCompetitionTeamKitApparelApprovalArgs = {
  input: CompetitionTeamKitApparelApprovalInput;
};


export type MutationApproveCompetitionTeamKitArgs = {
  input: ApproveCompetitionTeamKitInput;
};


export type MutationCompetitionTeamKitRequestFixArgs = {
  input: CompetitionTeamKitRequestFixInput;
};


export type MutationReopenCompetitionTeamKitArgs = {
  input: ReopenCompetitionTeamKitInput;
};


export type MutationAddCompetitionInfoToCompetitionTeamKitArgs = {
  input: AddCompetitionInfoToCompetitionTeamKitInput;
};


export type MutationUpdateCompetitionTeamKitCompetitionInfoArgs = {
  input: UpdateCompetitionTeamKitCompetitionInfoInput;
};


export type MutationRemoveCompetitionInfoFromCompetitionTeamKitArgs = {
  input: RemoveCompetitionInfoFromCompetitionTeamKitInput;
};


export type MutationAddBroadcastOptionToCompetitionTeamKitArgs = {
  input: AddBroadcastOptionToCompetitionTeamKitInput;
};


export type MutationRemoveBroadcastOptionFromCompetitionTeamKitArgs = {
  input: RemoveBroadcastOptionFromCompetitionTeamKitInput;
};


export type MutationClearSelectionForCompetitionTeamKitApparelArgs = {
  input: ClearSelectionForCompetitionTeamKitApparelInput;
};


export type MutationUpdateSelectionForCompetitionTeamKitApparelArgs = {
  input: UpdateSelectionForCompetitionTeamKitApparelInput;
};


export type MutationClearSelectionForCompetitionTeamKitContactArgs = {
  input: ClearSelectionForCompetitionTeamKitContactInput;
};


export type MutationUpdateSelectionForCompetitionTeamKitContactArgs = {
  input: UpdateSelectionForCompetitionTeamKitContactInput;
};


export type MutationAddNewContactToCompetitionTeamKitContactArgs = {
  input: AddNewContactToCompetitionTeamKitContactInput;
};


export type MutationCreateCompetitionForSeasonArgs = {
  input: CreateCompetitionForSeasonInput;
};


export type MutationActivateCompetitionArgs = {
  input: ActivateCompetitionInput;
};


export type MutationCancelCompetitionArgs = {
  input: CancelCompetitionInput;
};


export type MutationDeleteCompetitionArgs = {
  input: DeleteCompetitionInput;
};


export type MutationUpdateCompetitionArgs = {
  input: UpdateCompetitionInput;
};


export type MutationUpdateCompetitionScoreArgs = {
  input: UpdateCompetitionScoreInput;
};


export type MutationAddOfficialToCompetitionArgs = {
  input: AddOfficialToCompetitionInput;
};


export type MutationUpdateCompetitionOfficialArgs = {
  input: UpdateCompetitionOfficialInput;
};


export type MutationRemoveOfficialFromCompetitionArgs = {
  input: RemoveOfficialFromCompetitionInput;
};


export type MutationCreateCompetitionStatForCompetitionArgs = {
  input: CreateCompetitionStatForCompetitionInput;
};


export type MutationUpdateCompetitionStatValueArgs = {
  input: UpdateCompetitionStatValueInput;
};


export type MutationDeleteCompetitionStatArgs = {
  input: DeleteCompetitionStatInput;
};


export type MutationStartDynamicCompetitionSurveyArgs = {
  input: StartDynamicCompetitionSurveyInput;
};


export type MutationUpdateDynamicCompetitionSurveyCompetitionScoreArgs = {
  input: UpdateDynamicCompetitionSurveyCompetitionScoreInput;
};


export type MutationUpdateDynamicCompetitionSurveyMultipleChoiceResponseArgs = {
  input: UpdateDynamicCompetitionSurveyMultipleChoiceResponseInput;
};


export type MutationUpdateDynamicCompetitionSurveyShortAnswerResponseArgs = {
  input: UpdateDynamicCompetitionSurveyShortAnswerResponseInput;
};


export type MutationUpdateDynamicCompetitionSurveyTextResponseArgs = {
  input: UpdateDynamicCompetitionSurveyTextResponseInput;
};


export type MutationUpdateDynamicCompetitionSurveyNumberResponseArgs = {
  input: UpdateDynamicCompetitionSurveyNumberResponseInput;
};


export type MutationUpdateDynamicCompetitionSurveyBooleanResponseArgs = {
  input: UpdateDynamicCompetitionSurveyBooleanResponseInput;
};


export type MutationAddDynamicCompetitionSurveyMultipleResponseArgs = {
  input: AddDynamicCompetitionSurveyMultipleResponseInput;
};


export type MutationRemoveDynamicCompetitionSurveyMultipleResponseArgs = {
  input: RemoveDynamicCompetitionSurveyMultipleResponseInput;
};


export type MutationAddDynamicCompetitionSurveyFileUploadArgs = {
  file: Scalars['Upload'];
  input: AddDynamicCompetitionSurveyFileUploadInput;
};


export type MutationRemoveDynamicCompetitionSurveyFileUploadArgs = {
  input: RemoveDynamicCompetitionSurveyFileUploadInput;
};


export type MutationRefreshDynamicCompetitionSurveyFileUploadLinkArgs = {
  input: RefreshDynamicCompetitionSurveyFileUploadLinkInput;
};


export type MutationUpdateDynamicCompetitionSurveyMultiPartQuestionArgs = {
  input: UpdateDynamicCompetitionSurveyMultiPartQuestionInput;
};


export type MutationUpdateDynamicCompetitionSurveySubQuestionMultipleChoiceResponseArgs = {
  input: UpdateDynamicCompetitionSurveySubQuestionMultipleChoiceResponseInput;
};


export type MutationUpdateDynamicCompetitionSurveySubQuestionShortAnswerResponseArgs = {
  input: UpdateDynamicCompetitionSurveySubQuestionShortAnswerResponseInput;
};


export type MutationUpdateDynamicCompetitionSurveySubQuestionTextResponseArgs = {
  input: UpdateDynamicCompetitionSurveySubQuestionTextResponseInput;
};


export type MutationUpdateDynamicCompetitionSurveySubQuestionNumberResponseArgs = {
  input: UpdateDynamicCompetitionSurveySubQuestionNumberResponseInput;
};


export type MutationUpdateDynamicCompetitionSurveySubQuestionBooleanResponseArgs = {
  input: UpdateDynamicCompetitionSurveySubQuestionBooleanResponseInput;
};


export type MutationAddDynamicCompetitionSurveySubQuestionMultipleResponseArgs = {
  input: AddDynamicCompetitionSurveySubQuestionMultipleResponseInput;
};


export type MutationRemoveDynamicCompetitionSurveySubQuestionMultipleResponseArgs = {
  input: RemoveDynamicCompetitionSurveySubQuestionMultipleResponseInput;
};


export type MutationAddSupplementalReportToDynamicCompetitionSurveyArgs = {
  input: AddSupplementalReportToDynamicCompetitionSurveyInput;
};


export type MutationRemoveSupplementalReportFromDynamicCompetitionSurveyArgs = {
  input: RemoveSupplementalReportFromDynamicCompetitionSurveyInput;
};


export type MutationEditSupplementalReportForDynamicCompetitionSurveyArgs = {
  input: EditSupplementalReportForDynamicCompetitionSurveyInput;
};


export type MutationConvertDynamicCompetitionSurveyMultipleResponseToKeyIncidentArgs = {
  input: ConvertDynamicCompetitionSurveyMultipleResponseToKeyIncidentInput;
};


export type MutationConvertDynamicCompetitionSurveySubMultipleResponseToKeyIncidentArgs = {
  input: ConvertDynamicCompetitionSurveySubMultipleResponseToKeyIncidentInput;
};


export type MutationAddKeyIncidentToDynamicCompetitionSurveyArgs = {
  input: AddKeyIncidentToDynamicCompetitionSurveyInput;
};


export type MutationEditKeyIncidentForDynamicCompetitionSurveyArgs = {
  input: EditKeyIncidentForDynamicCompetitionSurveyInput;
};


export type MutationRemoveKeyIncidentFromDynamicCompetitionSurveyArgs = {
  input: RemoveKeyIncidentFromDynamicCompetitionSurveyInput;
};


export type MutationSignDynamicCompetitionSurveyArgs = {
  input: SignDynamicCompetitionSurveyInput;
};


export type MutationCompleteDynamicCompetitionSurveyArgs = {
  input: CompleteDynamicCompetitionSurveyInput;
};


export type MutationStartDynamicCompetitionSurveyWithTokenArgs = {
  input: StartDynamicCompetitionSurveyWithTokenInput;
};


export type MutationUpdateDynamicCompetitionSurveyCompetitionScoreWithTokenArgs = {
  input: UpdateDynamicCompetitionSurveyCompetitionScoreWithTokenInput;
};


export type MutationUpdateDynamicCompetitionSurveyMultipleChoiceResponseWithTokenArgs = {
  input: UpdateDynamicCompetitionSurveyMultipleChoiceResponseWithTokenInput;
};


export type MutationUpdateDynamicCompetitionSurveyShortAnswerResponseWithTokenArgs = {
  input: UpdateDynamicCompetitionSurveyShortAnswerResponseWithTokenInput;
};


export type MutationUpdateDynamicCompetitionSurveyTextResponseWithTokenArgs = {
  input: UpdateDynamicCompetitionSurveyTextResponseWithTokenInput;
};


export type MutationUpdateDynamicCompetitionSurveyNumberResponseWithTokenArgs = {
  input: UpdateDynamicCompetitionSurveyNumberResponseWithTokenInput;
};


export type MutationUpdateDynamicCompetitionSurveyBooleanResponseWithTokenArgs = {
  input: UpdateDynamicCompetitionSurveyBooleanResponseWithTokenInput;
};


export type MutationAddDynamicCompetitionSurveyMultipleResponseWithTokenArgs = {
  input: AddDynamicCompetitionSurveyMultipleResponseWithTokenInput;
};


export type MutationRemoveDynamicCompetitionSurveyMultipleResponseWithTokenArgs = {
  input: RemoveDynamicCompetitionSurveyMultipleResponseWithTokenInput;
};


export type MutationAddDynamicCompetitionSurveyFileUploadWithTokenArgs = {
  file: Scalars['Upload'];
  input: AddDynamicCompetitionSurveyFileUploadWithTokenInput;
};


export type MutationRemoveDynamicCompetitionSurveyFileUploadWithTokenArgs = {
  input: RemoveDynamicCompetitionSurveyFileUploadWithTokenInput;
};


export type MutationAddSupplementalReportToDynamicCompetitionSurveyWithTokenArgs = {
  input: AddSupplementalReportToDynamicCompetitionSurveyWithTokenInput;
};


export type MutationRemoveSupplementalReportFromDynamicCompetitionSurveyWithTokenArgs = {
  input: RemoveSupplementalReportFromDynamicCompetitionSurveyWithTokenInput;
};


export type MutationEditSupplementalReportForDynamicCompetitionSurveyWithTokenArgs = {
  input: EditSupplementalReportForDynamicCompetitionSurveyWithTokenInput;
};


export type MutationUpdateDynamicCompetitionSurveyMultiPartQuestionWithTokenArgs = {
  input: UpdateDynamicCompetitionSurveyMultiPartQuestionWithTokenInput;
};


export type MutationUpdateDynamicCompetitionSurveySubQuestionMultipleChoiceResponseWithTokenArgs = {
  input: UpdateDynamicCompetitionSurveySubQuestionMultipleChoiceResponseWithTokenInput;
};


export type MutationUpdateDynamicCompetitionSurveySubQuestionShortAnswerResponseWithTokenArgs = {
  input: UpdateDynamicCompetitionSurveySubQuestionShortAnswerResponseWithTokenInput;
};


export type MutationUpdateDynamicCompetitionSurveySubQuestionTextResponseWithTokenArgs = {
  input: UpdateDynamicCompetitionSurveySubQuestionTextResponseWithTokenInput;
};


export type MutationUpdateDynamicCompetitionSurveySubQuestionNumberResponseWithTokenArgs = {
  input: UpdateDynamicCompetitionSurveySubQuestionNumberResponseWithTokenInput;
};


export type MutationUpdateDynamicCompetitionSurveySubQuestionBooleanResponseWithTokenArgs = {
  input: UpdateDynamicCompetitionSurveySubQuestionBooleanResponseWithTokenInput;
};


export type MutationAddDynamicCompetitionSurveySubQuestionMultipleResponseWithTokenArgs = {
  input: AddDynamicCompetitionSurveySubQuestionMultipleResponseWithTokenInput;
};


export type MutationRemoveDynamicCompetitionSurveySubQuestionMultipleResponseWithTokenArgs = {
  input: RemoveDynamicCompetitionSurveySubQuestionMultipleResponseWithTokenInput;
};


export type MutationConvertDynamicCompetitionSurveyMultipleResponseToKeyIncidentWithTokenArgs = {
  input: ConvertDynamicCompetitionSurveyMultipleResponseToKeyIncidentWithTokenInput;
};


export type MutationConvertDynamicCompetitionSurveySubMultipleResponseToKeyIncidentWithTokenArgs = {
  input: ConvertDynamicCompetitionSurveySubMultipleResponseToKeyIncidentWithTokenInput;
};


export type MutationAddKeyIncidentToDynamicCompetitionSurveyWithTokenArgs = {
  input: AddKeyIncidentToDynamicCompetitionSurveyWithTokenInput;
};


export type MutationEditKeyIncidentForDynamicCompetitionSurveyWithTokenArgs = {
  input: EditKeyIncidentForDynamicCompetitionSurveyWithTokenInput;
};


export type MutationRemoveKeyIncidentFromDynamicCompetitionSurveyWithTokenArgs = {
  input: RemoveKeyIncidentFromCompetitionSurveyWithTokenInput;
};


export type MutationSignDynamicCompetitionSurveyWithTokenArgs = {
  input: SignDynamicCompetitionSurveyWithTokenInput;
};


export type MutationCompleteDynamicCompetitionSurveyWithTokenArgs = {
  input: CompleteDynamicCompetitionSurveyWithTokenInput;
};


export type MutationCreateSeasonDynamicSurveyArgs = {
  input: CreateDynamicSeasonSurveyInput;
};


export type MutationDeleteDynamicSurveyArgs = {
  input: DeleteDynamicSurveyInput;
};


export type MutationActivateDynamicSurveyArgs = {
  input: ActivateDynamicSurveyInput;
};


export type MutationDeactivateDynamicSurveyArgs = {
  input: DeactivateDynamicSurveyInput;
};


export type MutationUpdateDynamicSurveyInfoArgs = {
  input: UpdateDynamicSurveyInfoInput;
};


export type MutationSetDynamicSurveyCompetitionVerificationArgs = {
  input: SetDynamicSurveyCompetitionVerificationInput;
};


export type MutationUpdateDynamicSurveyRemindersArgs = {
  input: UpdateDynamicSurveyRemindersInput;
};


export type MutationAddDynamicSurveyReminderLevelReportRecipientArgs = {
  input: AddDynamicSurveyReminderLevelReportRecipientInput;
};


export type MutationUpdateDynamicSurveyReminderLevelRecipientArgs = {
  input: UpdateDynamicSurveyReminderLevelReportRecipientInput;
};


export type MutationDeleteDynamicSurveyReminderLevelRecipientArgs = {
  input: DeleteDynamicSurveyReminderLevelReportRecipientInput;
};


export type MutationAddDynamicSurveySignatureSectionArgs = {
  input: AddDynamicSurveySignatureSectionInput;
};


export type MutationUpdateDynamicSurveySignatureSectionTextArgs = {
  input: AddDynamicSurveySignatureSectionInput;
};


export type MutationDeleteDynamicSurveySignatureSectionArgs = {
  input: DeleteDynamicSurveySignatureSectionInput;
};


export type MutationAddDynamicSurveyAssessmentObjectRecipientArgs = {
  input: AddDynamicSurveyAssessmentObjectRecipientInput;
};


export type MutationDeleteDynamicSurveyAssessmentObjectRecipientArgs = {
  input: DeleteDynamicSurveyAssessmentObjectRecipientInput;
};


export type MutationAddDynamicSurveyIncidentReportArgs = {
  input: AddDynamicSurveyIncidentReportInput;
};


export type MutationDeleteDynamicSurveyIncidentReportArgs = {
  input: DeleteDynamicSurveyIncidentReportInput;
};


export type MutationAddDynamicSurveyIncidentReportRecipientArgs = {
  input: AddDynamicSurveyIncidentReportRecipientInput;
};


export type MutationDeleteDynamicSurveyIncidentReportRecipientArgs = {
  input: DeleteDynamicSurveyIncidentReportRecipientInput;
};


export type MutationSetDynamicSurveyAllowAdditionalIncidentReportsArgs = {
  input: SetDynamicSurveyAllowAdditionalIncidentReportsInput;
};


export type MutationSetDynamicSurveyAllowAdditionalIncidentReportsCutoffDaysArgs = {
  input: SetDynamicSurveyAllowAdditionalIncidentReportsCutoffDaysInput;
};


export type MutationAddDynamicSurveySupplementalReportArgs = {
  input: AddDynamicSurveySupplementalReportInput;
};


export type MutationDeleteDynamicSurveySupplementalReportArgs = {
  input: DeleteDynamicSurveySupplementalReportInput;
};


export type MutationAddDynamicSurveySupplementalReportRecipientArgs = {
  input: AddDynamicSurveySupplementalReportRecipientInput;
};


export type MutationDeleteDynamicSurveySupplementalReportRecipientArgs = {
  input: DeleteDynamicSurveySupplementalReportRecipientInput;
};


export type MutationSetDynamicSurveyAllowAdditionalSupplementalReportsArgs = {
  input: SetDynamicSurveyAllowAdditionalSupplementalReportsInput;
};


export type MutationSetDynamicSurveyAllowAdditionalSupplementalReportsCutoffDaysArgs = {
  input: SetDynamicSurveyAllowAdditionalSupplementalReportsCutoffDaysInput;
};


export type MutationAddDynamicSurveySummaryRecipientArgs = {
  input: AddDynamicSurveySummaryRecipientInput;
};


export type MutationDeleteDynamicSurveySummaryRecipientArgs = {
  input: DeleteDynamicSurveySummaryRecipientInput;
};


export type MutationSetIsActiveForDynamicSurveySummaryRecipientArgs = {
  input: SetIsActiveForDynamicSurveySummaryRecipientInput;
};


export type MutationAddDynamicSurveySectionArgs = {
  input: AddDynamicSurveySectionInput;
};


export type MutationUpdateDynamicSurveySectionArgs = {
  input: UpdateDynamicSurveySectionInput;
};


export type MutationUpdateDynamicSurveySectionSupplementalReportArgs = {
  input: UpdateDynamicSurveySectionSupplementalReportInput;
};


export type MutationDeleteDynamicSurveySectionArgs = {
  input: DeleteDynamicSurveySectionInput;
};


export type MutationAddDynamicSurveySectionQuestionArgs = {
  input: AddDynamicSurveySectionQuestionInput;
};


export type MutationUpdateDynamicSurveySectionQuestionArgs = {
  input: UpdateDynamicSurveySectionQuestionInput;
};


export type MutationDeleteDynamicSurveySectionQuestionArgs = {
  input: DeleteDynamicSurveySectionQuestionInput;
};


export type MutationSetDynamicSurveySectionQuestionCanAddToKeyIncidentsArgs = {
  input: SetDynamicSurveySectionQuestionCanAddToKeyIncidentsInput;
};


export type MutationAddDynamicSurveySectionQuestionOptionArgs = {
  input: AddDynamicSurveySectionQuestionOptionInput;
};


export type MutationDeleteDynamicSurveySectionQuestionOptionArgs = {
  input: DeleteDynamicSurveySectionQuestionOptionInput;
};


export type MutationUpdateDynamicSurveySectionQuestionOptionArgs = {
  input: UpdateDynamicSurveySectionQuestionOptionInput;
};


export type MutationAddDynamicSurveySectionSubQuestionArgs = {
  input: AddDynamicSurveySectionSubQuestionInput;
};


export type MutationUpdateDynamicSurveySectionSubQuestionArgs = {
  input: UpdateDynamicSurveySectionSubQuestionInput;
};


export type MutationDeleteDynamicSurveySectionSubQuestionArgs = {
  input: DeleteDynamicSurveySectionSubQuestionInput;
};


export type MutationSetDynamicSurveySectionSubQuestionCanAddToKeyIncidentsArgs = {
  input: SetDynamicSurveySectionSubQuestionCanAddToKeyIncidentsInput;
};


export type MutationAddDynamicSurveySectionSubQuestionOptionArgs = {
  input: AddDynamicSurveySectionSubQuestionOptionInput;
};


export type MutationDeleteDynamicSurveySectionSubQuestionOptionArgs = {
  input: DeleteDynamicSurveySectionSubQuestionOptionInput;
};


export type MutationUpdateDynamicSurveySectionSubQuestionOptionArgs = {
  input: UpdateDynamicSurveySectionSubQuestionOptionInput;
};


export type MutationAddAdditionalSupplementalReportToDynamicCompetitionSurveyArgs = {
  input: AddAdditionalSupplementalReportToDynamicCompetitionSurveyInput;
};


export type MutationAddAdditionalKeyIncidentToCompetitionSurveyArgs = {
  input: AddAdditionalKeyIncidentToCompetitionSurveyInput;
};


export type MutationBeginReviewForKeyIncidentArgs = {
  input: BeginReviewForKeyIncidentInput;
};


export type MutationResolveKeyIncidentArgs = {
  input: ResolveKeyIncidentInput;
};


export type MutationAddCommentToKeyIncidentArgs = {
  input: AddCommentToKeyIncidentInput;
};


export type MutationEditKeyIncidentArgs = {
  input: EditKeyIncidentInput;
};


export type MutationAddExternalLinkToKeyIncidentArgs = {
  input: AddExternalLinkToKeyIncidentInput;
};


export type MutationDeleteKeyIncidentExternalLinkArgs = {
  input: DeleteKeyIncidentExternalLinkInput;
};


export type MutationCreateLeagueArgs = {
  input: CreateLeagueInput;
};


export type MutationDeleteLeagueArgs = {
  input: DeleteLeagueInput;
};


export type MutationUpdateLeagueArgs = {
  input: UpdateLeagueInput;
};


export type MutationAddLeagueTagArgs = {
  input: AddLeagueTagInput;
};


export type MutationRemoveLeagueTagArgs = {
  input: RemoveLeagueTagInput;
};


export type MutationAddLeagueMembershipArgs = {
  input: AddLeagueMembershipInput;
};


export type MutationRemoveLeagueMembershipArgs = {
  input: RemoveLeagueMembershipInput;
};


export type MutationCreateOfficialArgs = {
  input: CreateOfficialInput;
};


export type MutationDeactivateOfficialArgs = {
  input: DeactivateOfficialInput;
};


export type MutationDeleteOfficialArgs = {
  input: DeleteOfficialInput;
};


export type MutationUpdateOrganizationArgs = {
  input: UpdateOrganizationInput;
};


export type MutationCreateNewOrganizationUserArgs = {
  input: CreateNewOrganizationUserInput;
};


export type MutationAddUserToOrganizationArgs = {
  input: AddUserToOrganizationInput;
};


export type MutationRemoveUserFromOrganizationArgs = {
  input: RemoveUserFromOrganizationInput;
};


export type MutationUpdateUserOrganizationMembershipArgs = {
  input: UpdateUserOrganizationMembershipInput;
};


export type MutationCreateOrganizationContactArgs = {
  input: CreateOrganizationContactInput;
};


export type MutationUpdateOrganizationContactArgs = {
  input: UpdateOrganizationContactInput;
};


export type MutationDeleteOrganizationContactArgs = {
  input: DeleteOrganizationContactInput;
};


export type MutationResendCompetitionSurveyNotificationEmailArgs = {
  input: ResendCompetitionSurveyNotificationEmailInput;
};


export type MutationResendDynamicCompetitionSurveyNotificationEmailArgs = {
  input: ResendDynamicCompetitionSurveyNotificationEmailInput;
};


export type MutationRemoveCompetitionSurveyParticipationArgs = {
  input: RemoveCompetitionSurveyParticipationInput;
};


export type MutationResetTookCompetitionSurveyArgs = {
  input: ResetTookCompetitionSurveyInput;
};


export type MutationReopenCompetitionSurveyArgs = {
  input: ReopenCompetitionSurveyInput;
};


export type MutationResetTookDynamicCompetitionSurveyArgs = {
  input: ResetTookDynamicCompetitionSurveyInput;
};


export type MutationReopenDynamicCompetitionSurveyArgs = {
  input: ReopenDynamicCompetitionSurveyInput;
};


export type MutationUpdateCompetitionIntegrationEnabledForSeasonArgs = {
  input: UpdateCompetitionIntegrationEnabledForSeasonInput;
};


export type MutationCreateSeasonCompetitionIntegrationArgs = {
  input: CreateSeasonCompetitionIntegrationInput;
};


export type MutationUpdateSeasonCompetitionIntegrationArgs = {
  input: UpdateSeasonCompetitionIntegrationInput;
};


export type MutationDeleteSeasonCompetitionIntegrationArgs = {
  input: DeleteSeasonCompetitionIntegrationInput;
};


export type MutationUpdateSeasonCompetitionIntegrationOptionArgs = {
  input: UpdateSeasonCompetitionIntegrationOptionInput;
};


export type MutationActivateSeasonCompetitionIntegrationArgs = {
  input: ActivateSeasonCompetitionIntegrationInput;
};


export type MutationDisableSeasonCompetitionIntegrationArgs = {
  input: DisableSeasonCompetitionIntegrationInput;
};


export type MutationRunSeasonCompetitionIntegrationArgs = {
  input: RunSeasonCompetitionIntegrationInput;
};


export type MutationUpdateMediaMembersEnabledForSeasonArgs = {
  input: UpdateMediaMembersEnabledForSeasonInput;
};


export type MutationCreateSeasonMediaMembersConfigArgs = {
  input: CreateSeasonMediaMembersConfigInput;
};


export type MutationUpdateSeasonMediaMembersConfigArgs = {
  input: UpdateSeasonMediaMembersConfigInput;
};


export type MutationDeactivateMediaMembersConfigForSeasonArgs = {
  input: DeactivateMediaMembersForSeasonInput;
};


export type MutationAddSeasonMediaMembersOrgRecipientArgs = {
  input: AddSeasonMediaMembersOrgRecipientInput;
};


export type MutationDeleteSeasonMediaMembersOrgRecipientArgs = {
  input: DeleteSeasonMediaMembersOrgRecipientInput;
};


export type MutationActivateSeasonMediaMembersOrgRecipientArgs = {
  input: ActivateSeasonMediaMembersOrgRecipientInput;
};


export type MutationSetIsKiRecipientForSeasonMediaMembersOrgRecipientArgs = {
  input: SetIsKiRecipientForSeasonMediaMembersOrgRecipientInput;
};


export type MutationUpdateTeamKitEnabledForSeasonArgs = {
  input: UpdateTeamKitEnabledForSeasonInput;
};


export type MutationCreateSeasonTeamKitArgs = {
  input: CreateSeasonTeamKitInput;
};


export type MutationUpdateSeasonTeamKitArgs = {
  input: UpdateSeasonTeamKitInput;
};


export type MutationActivateTeamKitForSeasonArgs = {
  input: ActivateTeamKitForSeasonInput;
};


export type MutationDeactivateTeamKitForSeasonArgs = {
  input: DeactivateTeamKitForSeasonInput;
};


export type MutationCreateSeasonTeamKitRoleArgs = {
  input: CreateTeamKitRoleForSeasonInput;
};


export type MutationUpdateSeasonTeamKitRoleArgs = {
  input: UpdateTeamKitRoleForSeasonInput;
};


export type MutationDeleteSeasonTeamKitRoleArgs = {
  input: DeleteTeamKitRoleForSeasonInput;
};


export type MutationCreateSeasonTeamKitApparelDefinitionArgs = {
  input: CreateSeasonTeamKitApparelDefinitionInput;
};


export type MutationUpdateSeasonTeamKitApparelDefinitionArgs = {
  input: UpdateSeasonTeamKitApparelDefinitionInput;
};


export type MutationDeleteSeasonTeamKitApparelDefinitionArgs = {
  input: DeleteSeasonTeamKitApparelDefinitionInput;
};


export type MutationCreateSeasonTeamKitContactDefinitionArgs = {
  input: CreateSeasonTeamKitContactDefinitionInput;
};


export type MutationUpdateSeasonTeamKitContactDefinitionArgs = {
  input: UpdateSeasonTeamKitContactDefinitionInput;
};


export type MutationDeleteSeasonTeamKitContactDefinitionArgs = {
  input: DeleteSeasonTeamKitContactDefinitionInput;
};


export type MutationCreateSeasonTeamKitContactOptionArgs = {
  input: CreateSeasonTeamKitContactOptionInput;
};


export type MutationSetDefaultSeasonTeamKitContactOptionArgs = {
  input: SetDefaultSeasonTeamKitContactOptionInput;
};


export type MutationDeleteSeasonTeamKitContactOptionArgs = {
  input: DeleteTeamKitLeagueContactOptionForSeasonInput;
};


export type MutationCreateTeamKitBroadcastOptionForSeasonArgs = {
  input: CreateTeamKitBroadcastOptionForSeasonInput;
};


export type MutationUpdateTeamKitBroadcastOptionForSeasonArgs = {
  input: UpdateTeamKitBroadcastOptionForSeasonInput;
};


export type MutationDeleteTeamKitBroadcastOptionForSeasonArgs = {
  input: DeleteTeamKitBroadcastOptionForSeasonInput;
};


export type MutationCreateTeamKitSeasonApparelArgs = {
  file: Scalars['Upload'];
  input: CreateTeamKitSeasonApparelInput;
};


export type MutationUpdateTeamKitSeasonApparelArgs = {
  input: UpdateTeamKitSeasonApparelInput;
};


export type MutationUpdateTeamKitSeasonApparelImageArgs = {
  file: Scalars['Upload'];
  input: UpdateTeamKitSeasonApparelImageInput;
};


export type MutationDeleteTeamKitSeasonApparelArgs = {
  input: DeleteTeamKitSeasonApparelInput;
};


export type MutationCreateTeamKitSeasonKitArgs = {
  input: CreateTeamKitSeasonKitInput;
};


export type MutationUpdateTeamKitSeasonKitArgs = {
  input: UpdateTeamKitSeasonKitInput;
};


export type MutationDeleteTeamKitSeasonKitArgs = {
  input: DeleteTeamKitSeasonKitInput;
};


export type MutationAddSeasonTeamKitOrgRecipientArgs = {
  input: AddSeasonTeamKitOrgRecipientInput;
};


export type MutationActivateSeasonTeamKitOrgRecipientArgs = {
  input: ActivateSeasonTeamKitOrgRecipientInput;
};


export type MutationDeleteSeasonTeamKitOrgRecipientArgs = {
  input: DeleteSeasonTeamKitOrgRecipientInput;
};


export type MutationCreateSeasonArgs = {
  input: CreateSeasonInput;
};


export type MutationDeleteSeasonArgs = {
  input: DeleteSeasonInput;
};


export type MutationUpdateSeasonArgs = {
  input: UpdateSeasonInput;
};


export type MutationActivateSeasonArgs = {
  input: ActivateSeasonInput;
};


export type MutationDeactivateSeasonArgs = {
  input: DeactivateSeasonInput;
};


export type MutationAddTeamToSeasonArgs = {
  input: AddTeamToSeasonInput;
};


export type MutationCreateNewTeamForSeasonArgs = {
  input: CreateNewTeamForSeasonInput;
};


export type MutationRemoveTeamFromSeasonArgs = {
  input: RemoveTeamFromSeasonInput;
};


export type MutationCreateNewSeasonCoachArgs = {
  input: CreateNewSeasonCoachInput;
};


export type MutationAddOfficialToSeasonArgs = {
  input: AddOfficialToSeasonInput;
};


export type MutationCreateNewOfficialForSeasonArgs = {
  input: CreateNewOfficialForSeasonInput;
};


export type MutationRemoveOfficialFromSeasonArgs = {
  input: RemoveOfficialFromSeasonInput;
};


export type MutationInviteSeasonOfficialUserArgs = {
  input: InviteSeasonOfficialUserInput;
};


export type MutationAddOfficialAssessmentObjectToSeasonArgs = {
  input: AddOfficialAssessmentObjectToSeasonInput;
};


export type MutationRemoveOfficialAssessmentObjectFromSeasonArgs = {
  input: RemoveOfficialAssessmentObjectFromSeasonInput;
};


export type MutationSetIsOptionalForSeasonOfficialAssessmentObjectArgs = {
  input: SetIsOptionalForSeasonOfficialAssessmentObjectInput;
};


export type MutationAddPeriodToSeasonArgs = {
  input: AddPeriodToSeasonInput;
};


export type MutationRemoveSeasonPeriodFromSeasonArgs = {
  input: RemoveSeasonPeriodFromSeasonInput;
};


export type MutationCreateSeasonDivisionArgs = {
  input: CreateSeasonDivisionInput;
};


export type MutationUpdateSeasonDivisionArgs = {
  input: UpdateSeasonDivisionInput;
};


export type MutationDeleteSeasonDivisionArgs = {
  input: DeleteSeasonDivisionInput;
};


export type MutationCreateSeasonDivisionAliasArgs = {
  input: CreateSeasonDivisionAliasInput;
};


export type MutationUpdateSeasonDivisionAliasArgs = {
  input: UpdateSeasonDivisionAliasInput;
};


export type MutationDeleteSeasonDivisionAliasArgs = {
  input: DeleteSeasonDivisionAliasInput;
};


export type MutationAddSeasonTeamToSeasonDivisionArgs = {
  input: AddSeasonTeamToSeasonDivisionInput;
};


export type MutationDeleteSeasonDivisionTeamArgs = {
  input: DeleteSeasonDivisionTeamInput;
};


export type MutationUpdateReportForSupplementalReportArgs = {
  input: UpdateReportForSupplementalReportInput;
};


export type MutationRequestChangeForSupplementalReportArgs = {
  input: RequestChangeForSupplementalReportInput;
};


export type MutationApproveSupplementalReportArgs = {
  input: ApproveSupplementalReportInput;
};


export type MutationAddCommentToSupplementalReportArgs = {
  input: AddCommentToSupplementalReportInput;
};


export type MutationUpdateSurveySummaryRecipientNotificationSettingByTokenArgs = {
  input: UpdateSurveySummaryRecipientNotificationSettingByTokenInput;
};


export type MutationUpdateMySurveySummaryRecipientNotificationSettingArgs = {
  input: UpdateMySurveySummaryRecipientNotificationSettingInput;
};


export type MutationCreateAdminSurveyTemplateArgs = {
  input: CreateAdminSurveyTemplateInput;
};


export type MutationCreateOrganizationSurveyTemplateArgs = {
  input: CreateOrganizationSurveyTemplateInput;
};


export type MutationDeleteSurveyTemplateArgs = {
  input: DeleteSurveyTemplateInput;
};


export type MutationUpdateSurveyTemplateInfoArgs = {
  input: UpdateSurveyTemplateInfoInput;
};


export type MutationAddSurveyTemplateIncidentReportArgs = {
  input: AddSurveyTemplateIncidentReportInput;
};


export type MutationDeleteSurveyTemplateIncidentReportArgs = {
  input: DeleteSurveyTemplateIncidentReportInput;
};


export type MutationAddSurveyTemplateSectionArgs = {
  input: AddSurveyTemplateSectionInput;
};


export type MutationUpdateSurveyTemplateSectionArgs = {
  input: UpdateSurveyTemplateSectionInput;
};


export type MutationDeleteSurveyTemplateSectionArgs = {
  input: DeleteSurveyTemplateSectionInput;
};


export type MutationAddSurveyTemplateSectionQuestionArgs = {
  input: AddSurveyTemplateSectionQuestionInput;
};


export type MutationUpdateSurveyTemplateSectionQuestionArgs = {
  input: UpdateSurveyTemplateSectionQuestionInput;
};


export type MutationDeleteSurveyTemplateSectionQuestionArgs = {
  input: DeleteSurveyTemplateSectionQuestionInput;
};


export type MutationSetSurveyTemplateSectionQuestionCanAddToKeyIncidentsArgs = {
  input: SetSurveyTemplateSectionQuestionCanAddToKeyIncidentsInput;
};


export type MutationAddSurveyTemplateSectionQuestionOptionArgs = {
  input: AddSurveyTemplateSectionQuestionOptionInput;
};


export type MutationDeleteSurveyTemplateSectionQuestionOptionArgs = {
  input: DeleteSurveyTemplateSectionQuestionOptionInput;
};


export type MutationUpdateSurveyTemplateSectionQuestionOptionArgs = {
  input: UpdateSurveyTemplateSectionQuestionOptionInput;
};


export type MutationCreateSeasonSurveyArgs = {
  input: CreateSeasonSurveyInput;
};


export type MutationCreateSeasonSurveyFromTemplateArgs = {
  input: CreateSeasonSurveyFromTemplateInput;
};


export type MutationDeleteSurveyArgs = {
  input: DeleteSurveyInput;
};


export type MutationActivateSurveyArgs = {
  input: ActivateSurveyInput;
};


export type MutationDeactivateSurveyArgs = {
  input: DeactivateSurveyInput;
};


export type MutationUpdateSurveyInfoArgs = {
  input: UpdateSurveyInfoInput;
};


export type MutationSetSurveyCompetitionVerificationArgs = {
  input: SetSurveyCompetitionVerificationInput;
};


export type MutationUpdateSurveyRemindersArgs = {
  input: UpdateSurveyRemindersInput;
};


export type MutationAddSurveyReminderLevelReportRecipientArgs = {
  input: AddSurveyReminderLevelReportRecipientInput;
};


export type MutationUpdateSurveyReminderLevelRecipientArgs = {
  input: UpdateSurveyReminderLevelReportRecipientInput;
};


export type MutationDeleteSurveyReminderLevelRecipientArgs = {
  input: DeleteSurveyReminderLevelReportRecipientInput;
};


export type MutationAddSurveyAssessmentObjectRecipientArgs = {
  input: AddSurveyAssessmentObjectRecipientInput;
};


export type MutationDeleteSurveyAssessmentObjectRecipientArgs = {
  input: DeleteSurveyAssessmentObjectRecipientInput;
};


export type MutationAddSurveyIncidentReportArgs = {
  input: AddSurveyIncidentReportInput;
};


export type MutationDeleteSurveyIncidentReportArgs = {
  input: DeleteSurveyIncidentReportInput;
};


export type MutationAddSurveyIncidentReportRecipientArgs = {
  input: AddSurveyIncidentReportRecipientInput;
};


export type MutationDeleteSurveyIncidentReportRecipientArgs = {
  input: DeleteSurveyIncidentReportRecipientInput;
};


export type MutationAddKeyIncidentVideoIntegrationToSurveyArgs = {
  input: AddKeyIncidentVideoIntegrationToSurveyInput;
};


export type MutationRemoveKeyIncidentVideoIntegrationFromSurveyArgs = {
  input: RemoveKeyIncidentVideoIntegrationFromSurveyInput;
};


export type MutationSetSurveyAllowAdditionalIncidentReportsArgs = {
  input: SetSurveyAllowAdditionalIncidentReportsInput;
};


export type MutationSetSurveyAllowAdditionalIncidentReportsCutoffDaysArgs = {
  input: SetSurveyAllowAdditionalIncidentReportsCutoffDaysInput;
};


export type MutationAddSurveySectionArgs = {
  input: AddSurveySectionInput;
};


export type MutationUpdateSurveySectionArgs = {
  input: UpdateSurveySectionInput;
};


export type MutationDeleteSurveySectionArgs = {
  input: DeleteSurveySectionInput;
};


export type MutationAddSurveySectionQuestionArgs = {
  input: AddSurveySectionQuestionInput;
};


export type MutationUpdateSurveySectionQuestionArgs = {
  input: UpdateSurveySectionQuestionInput;
};


export type MutationDeleteSurveySectionQuestionArgs = {
  input: DeleteSurveySectionQuestionInput;
};


export type MutationSetSurveySectionQuestionCanAddToKeyIncidentsArgs = {
  input: SetSurveySectionQuestionCanAddToKeyIncidentsInput;
};


export type MutationAddSurveySectionQuestionOptionArgs = {
  input: AddSurveySectionQuestionOptionInput;
};


export type MutationDeleteSurveySectionQuestionOptionArgs = {
  input: DeleteSurveySectionQuestionOptionInput;
};


export type MutationUpdateSurveySectionQuestionOptionArgs = {
  input: UpdateSurveySectionQuestionOptionInput;
};


export type MutationCreateTeamForSeasonArgs = {
  input: CreateTeamForSeasonInput;
};


export type MutationDeleteTeamArgs = {
  input: DeleteTeamInput;
};


export type MutationUpdateTeamForSeasonArgs = {
  input: UpdateTeamForSeasonInput;
};


export type MutationCreateTeamAliasArgs = {
  input: CreateTeamAliasInput;
};


export type MutationUpdateTeamAliasArgs = {
  input: UpdateTeamAliasInput;
};


export type MutationDeleteTeamAliasArgs = {
  input: DeleteTeamAliasInput;
};


export type MutationCreateTeamContactForSeasonArgs = {
  input: CreateTeamContactForSeasonInput;
};


export type MutationUpdateTeamContactForSeasonArgs = {
  input: UpdateTeamContactForSeasonInput;
};


export type MutationDeleteTeamContactForSeasonArgs = {
  input: DeleteTeamContactForSeasonInput;
};


export type MutationCreateTeamKitTeamRecipientForSeasonArgs = {
  input: CreateTeamKitRecipientForSeasonInput;
};


export type MutationUpdateTeamKitTeamRecipientForSeasonArgs = {
  input: UpdateTeamKitTeamRecipientForSeasonInput;
};


export type MutationActivateSeasonTeamKitTeamRecipientArgs = {
  input: ActivateSeasonTeamKitTeamRecipientInput;
};


export type MutationUploadUserProfilePictureArgs = {
  file: Scalars['Upload'];
};


export type MutationUpdateMyProfileArgs = {
  input: UpdateMyProfileInput;
};


export type MutationUpdateUserNotificationSettingsArgs = {
  input: UpdateUserNotificationSettingsInput;
};


export type MutationUpdateMyNotificationSettingsArgs = {
  input: UpdateMyNotificationSettingsInput;
};


export type MutationRetryDynamicCompetitionIntegrationSurveyArgs = {
  input: RerunDynamicCompetitionIntegrationSurveyInput;
};

/** The user notification settings */
export enum NotificationSettings {
  SupplementalReports = 'SUPPLEMENTAL_REPORTS',
  IncidentReports = 'INCIDENT_REPORTS',
  SurveyNotifications = 'SURVEY_NOTIFICATIONS',
  TeamKit = 'TEAM_KIT'
}

export type Official = {
  __typename?: 'Official';
  id: Scalars['String'];
  sport: Sports;
  userId?: Maybe<Scalars['String']>;
  status: OfficialStatuses;
  userStatus?: Maybe<UserStatuses>;
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  externalIds?: Maybe<Array<OfficialExternalId>>;
  profilePic?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type OfficialCompetitionSummaries = {
  __typename?: 'OfficialCompetitionSummaries';
  competitionSummaries: Array<OfficialCompetitionSummary>;
  pagination: OffsetPagination;
};

export type OfficialCompetitionSummary = {
  __typename?: 'OfficialCompetitionSummary';
  officialId: Scalars['String'];
  officialExternalId?: Maybe<Scalars['String']>;
  officialEmail?: Maybe<Scalars['String']>;
  officialFirstName: Scalars['String'];
  officialLastName: Scalars['String'];
  officialProfilePic?: Maybe<Scalars['String']>;
  competitionId: Scalars['String'];
  competitionStartTime: Scalars['Date'];
  competitionLocation?: Maybe<Scalars['String']>;
  homeTeamId: Scalars['String'];
  homeTeamName: Scalars['String'];
  homeTeamLogo?: Maybe<Scalars['String']>;
  homeTeamScore?: Maybe<Scalars['Int']>;
  homeTeamTieBreakerScore?: Maybe<Scalars['Int']>;
  awayTeamId: Scalars['String'];
  awayTeamName: Scalars['String'];
  awayTeamLogo?: Maybe<Scalars['String']>;
  awayTeamScore?: Maybe<Scalars['Int']>;
  awayTeamTieBreakerScore?: Maybe<Scalars['Int']>;
  organizationId: Scalars['String'];
  leagueId?: Maybe<Scalars['String']>;
  leagueName?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  seasonName?: Maybe<Scalars['String']>;
  assessmentObjectId: Scalars['String'];
  assessmentObjectName: Scalars['String'];
  keyIncidentsCount: Scalars['Float'];
  impactedOutcomeCount: Scalars['Float'];
  ratingQuestionsCount: Scalars['Float'];
  totalPoints: Scalars['Float'];
  maxPointsPossible: Scalars['Float'];
  averageCompetitionScore: Scalars['Float'];
};

export type OfficialExternalId = {
  __typename?: 'OfficialExternalId';
  id: Scalars['String'];
  organizationId: Scalars['String'];
  externalId: Scalars['String'];
};

/** The official statuses */
export enum OfficialStatuses {
  Active = 'ACTIVE',
  NotActive = 'NOT_ACTIVE'
}

export type OfficialSummary = {
  __typename?: 'OfficialSummary';
  id: Scalars['String'];
  externalId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  profilePic?: Maybe<Scalars['String']>;
  totalCompetitions: Scalars['Float'];
  totalKeyIncidents: Scalars['Float'];
  totalRatingQuestionsCount: Scalars['Float'];
  totalPoints: Scalars['Float'];
  maxPointsPossible: Scalars['Float'];
  averageCompetitionScore: Scalars['Float'];
};

export type Officials = {
  __typename?: 'Officials';
  officials: Array<Official>;
  pagination: OffsetPagination;
};

export type OffsetPagination = {
  __typename?: 'OffsetPagination';
  total: Scalars['Int'];
  size: Scalars['Int'];
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  page: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export type OffsetPaginationInput = {
  offset: Scalars['Int'];
  limit: Scalars['Int'];
};

/** The organization features */
export enum OrgFeatures {
  Surveys = 'SURVEYS',
  KeyIncidents = 'KEY_INCIDENTS',
  DynamicSurveys = 'DYNAMIC_SURVEYS',
  SupplementalReports = 'SUPPLEMENTAL_REPORTS',
  KeyIncidentVideoIntegration = 'KEY_INCIDENT_VIDEO_INTEGRATION',
  TeamKits = 'TEAM_KITS',
  AutoCompetitionIntegration = 'AUTO_COMPETITION_INTEGRATION',
  MediaMembers = 'MEDIA_MEMBERS',
  CompetitionVerification = 'COMPETITION_VERIFICATION'
}

export type OrgOfficialFeedback = {
  __typename?: 'OrgOfficialFeedback';
  leagueId: Scalars['String'];
  leagueName: Scalars['String'];
  seasonId: Scalars['String'];
  seasonName: Scalars['String'];
  competitionId: Scalars['String'];
  competitionStartTime: Scalars['Date'];
  competitionLocation?: Maybe<Scalars['String']>;
  homeTeamId: Scalars['String'];
  homeTeamName: Scalars['String'];
  homeTeamLogo?: Maybe<Scalars['String']>;
  homeTeamScore?: Maybe<Scalars['Int']>;
  homeTeamTieBreakerScore?: Maybe<Scalars['Int']>;
  awayTeamId: Scalars['String'];
  awayTeamName: Scalars['String'];
  awayTeamLogo?: Maybe<Scalars['String']>;
  awayTeamScore?: Maybe<Scalars['Int']>;
  awayTeamTieBreakerScore?: Maybe<Scalars['Int']>;
  submittedByTeamId: Scalars['String'];
  submittedByTeamName: Scalars['String'];
  submittedByTeamLogo?: Maybe<Scalars['String']>;
  assessmentObjectId: Scalars['String'];
  assessmentObjectName: Scalars['String'];
  feedbackId: Scalars['String'];
  feedback: Scalars['String'];
};

export type OrgOfficialFeedbacks = {
  __typename?: 'OrgOfficialFeedbacks';
  feedback: Array<OrgOfficialFeedback>;
  pagination: OffsetPagination;
};

export type Organization = {
  __typename?: 'Organization';
  id: Scalars['String'];
  name: Scalars['String'];
  abbreviation: Scalars['String'];
  sport: Sports;
  status: OrganizationStatuses;
  site?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type OrganizationCompetitionMetrics = {
  __typename?: 'OrganizationCompetitionMetrics';
  organizationId: Scalars['String'];
  leagueId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  totalCompetitions: Scalars['Float'];
  competitionsThisWeek: Scalars['Float'];
  competitionsThisMonth: Scalars['Float'];
  competitionsThisYear: Scalars['Float'];
};

export type OrganizationCompetitionSurveyMetrics = {
  __typename?: 'OrganizationCompetitionSurveyMetrics';
  organizationId: Scalars['String'];
  leagueId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  totalSurveys: Scalars['Float'];
  completedSurveys: Scalars['Float'];
  incompleteSurveys: Scalars['Float'];
  completionPercentage: Scalars['Float'];
  totalSurveysThisWeek: Scalars['Float'];
  completedSurveysThisWeek: Scalars['Float'];
  incompleteSurveysThisWeek: Scalars['Float'];
  completionPercentageThisWeek: Scalars['Float'];
  totalSurveysThisMonth: Scalars['Float'];
  completedSurveysThisMonth: Scalars['Float'];
  incompleteSurveysThisMonth: Scalars['Float'];
  completionPercentageThisMonth: Scalars['Float'];
  totalSurveysThisYear: Scalars['Float'];
  completedSurveysThisYear: Scalars['Float'];
  incompleteSurveysThisYear: Scalars['Float'];
  completionPercentageThisYear: Scalars['Float'];
};

export type OrganizationContact = {
  __typename?: 'OrganizationContact';
  id: Scalars['String'];
  isActive: Scalars['Boolean'];
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type OrganizationContacts = {
  __typename?: 'OrganizationContacts';
  orgId: Scalars['String'];
  orgName: Scalars['String'];
  contacts: Array<OrganizationContact>;
  pagination: OffsetPagination;
};

export type OrganizationFeature = {
  __typename?: 'OrganizationFeature';
  id: Scalars['Float'];
  feature: OrgFeatures;
  active: Scalars['Boolean'];
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type OrganizationFeatures = {
  __typename?: 'OrganizationFeatures';
  orgId: Scalars['String'];
  orgName: Scalars['String'];
  features: Array<OrganizationFeature>;
  pagination: OffsetPagination;
};

export type OrganizationKeyIncidentMetrics = {
  __typename?: 'OrganizationKeyIncidentMetrics';
  organizationId: Scalars['String'];
  leagueId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  totalKeyIncidents: Scalars['Float'];
  resolved: Scalars['Float'];
  unresolved: Scalars['Float'];
  resolvedPercentage: Scalars['Float'];
  totalKeyIncidentsThisWeek: Scalars['Float'];
  resolvedThisWeek: Scalars['Float'];
  unresolvedThisWeek: Scalars['Float'];
  resolvedPercentageThisWeek: Scalars['Float'];
  totalKeyIncidentsThisMonth: Scalars['Float'];
  resolvedThisMonth: Scalars['Float'];
  unresolvedThisMonth: Scalars['Float'];
  resolvedPercentageThisMonth: Scalars['Float'];
  totalKeyIncidentsThisYear: Scalars['Float'];
  resolvedThisYear: Scalars['Float'];
  unresolvedThisYear: Scalars['Float'];
  resolvedPercentageThisYear: Scalars['Float'];
};

export type OrganizationLeagueMembership = {
  __typename?: 'OrganizationLeagueMembership';
  leagueId: Scalars['String'];
  name: Scalars['String'];
  abbreviation?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
};

export type OrganizationMembership = {
  __typename?: 'OrganizationMembership';
  id: Scalars['String'];
  orgId: Scalars['String'];
  name: Scalars['String'];
  abbreviation: Scalars['String'];
  sport: Sports;
  logo?: Maybe<Scalars['String']>;
  roles: Array<OrganizationUserRoles>;
  permissions: Array<OrganizationUserPermissions>;
  leagueMemberships: Array<OrganizationLeagueMembership>;
  title?: Maybe<Scalars['String']>;
};

export type OrganizationOfficialParticipationSummaries = {
  __typename?: 'OrganizationOfficialParticipationSummaries';
  participationSummaries: Array<OrganizationOfficialParticipationSummary>;
  pagination: OffsetPagination;
};

export type OrganizationOfficialParticipationSummary = {
  __typename?: 'OrganizationOfficialParticipationSummary';
  organizationId: Scalars['String'];
  organizationName: Scalars['String'];
  leagueId: Scalars['String'];
  leagueName: Scalars['String'];
  seasonId: Scalars['String'];
  seasonName: Scalars['String'];
  competitionId: Scalars['String'];
  externalId?: Maybe<Scalars['String']>;
  homeTeamId: Scalars['String'];
  homeTeamName: Scalars['String'];
  homeTeamLogo?: Maybe<Scalars['String']>;
  homeTeamScore?: Maybe<Scalars['Int']>;
  homeTeamTieBreakerScore?: Maybe<Scalars['Int']>;
  awayTeamId: Scalars['String'];
  awayTeamName: Scalars['String'];
  awayTeamLogo?: Maybe<Scalars['String']>;
  awayTeamScore?: Maybe<Scalars['Int']>;
  awayTeamTieBreakerScore?: Maybe<Scalars['Int']>;
  startTime: Scalars['Date'];
  timeZone: Scalars['String'];
  location?: Maybe<Scalars['String']>;
  competitionSurveyCount: Scalars['Int'];
  notStartedCompetitionSurveyCount: Scalars['Int'];
  incompleteCompetitionSurveyCount: Scalars['Int'];
  completedCompetitionSurveyCount: Scalars['Int'];
  completionCompetitionSurveyPercentage: Scalars['Int'];
};

export type OrganizationOfficialSurveyParticipation = {
  __typename?: 'OrganizationOfficialSurveyParticipation';
  organizationId: Scalars['String'];
  organizationName: Scalars['String'];
  leagueId: Scalars['String'];
  leagueName: Scalars['String'];
  seasonId: Scalars['String'];
  seasonName: Scalars['String'];
  competitionId: Scalars['String'];
  externalId?: Maybe<Scalars['String']>;
  startTime: Scalars['Date'];
  timeZone: Scalars['String'];
  location?: Maybe<Scalars['String']>;
  homeTeamId: Scalars['String'];
  homeTeamName: Scalars['String'];
  homeTeamLogo?: Maybe<Scalars['String']>;
  homeTeamScore?: Maybe<Scalars['Int']>;
  homeTeamTieBreakerScore?: Maybe<Scalars['Int']>;
  awayTeamId: Scalars['String'];
  awayTeamName: Scalars['String'];
  awayTeamLogo?: Maybe<Scalars['String']>;
  awayTeamScore?: Maybe<Scalars['Int']>;
  awayTeamTieBreakerScore?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  competitionSurveyId: Scalars['String'];
  isDynamicSurvey: Scalars['Boolean'];
  surveyId: Scalars['String'];
  surveyName: Scalars['String'];
  finished: Scalars['Boolean'];
  completedDate?: Maybe<Scalars['Date']>;
  lastNotificationSent?: Maybe<Scalars['Date']>;
  lastNotificationTypeSent?: Maybe<SurveyNotificationTypes>;
  lastEmailSent?: Maybe<Scalars['Date']>;
  tookSurvey: Scalars['Boolean'];
  userId: Scalars['String'];
  userFirstName: Scalars['String'];
  userLastName: Scalars['String'];
  userStatus: UserStatuses;
  userEmail: Scalars['String'];
  competitionOfficialId: Scalars['String'];
  officialId: Scalars['String'];
  officialStatus: OfficialStatuses;
  assessmentObjectId: Scalars['String'];
  assessmentObjectName: Scalars['String'];
};

export type OrganizationOfficialSurveyParticipations = {
  __typename?: 'OrganizationOfficialSurveyParticipations';
  participations: Array<OrganizationOfficialSurveyParticipation>;
  pagination: OffsetPagination;
};

/** The organization statuses */
export enum OrganizationStatuses {
  Pending = 'PENDING',
  Active = 'ACTIVE',
  Disabled = 'DISABLED'
}

export type OrganizationSupplementalReportMetrics = {
  __typename?: 'OrganizationSupplementalReportMetrics';
  organizationId: Scalars['String'];
  leagueId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  totalSupplementalReports: Scalars['Float'];
  approved: Scalars['Float'];
  unapproved: Scalars['Float'];
  approvedPercentage: Scalars['Float'];
  totalSupplementalReportsThisWeek: Scalars['Float'];
  approvedThisWeek: Scalars['Float'];
  unapprovedThisWeek: Scalars['Float'];
  approvedPercentageThisWeek: Scalars['Float'];
  totalSupplementalReportsThisMonth: Scalars['Float'];
  approvedThisMonth: Scalars['Float'];
  unapprovedThisMonth: Scalars['Float'];
  approvedPercentageThisMonth: Scalars['Float'];
  totalSupplementalReportsThisYear: Scalars['Float'];
  approvedThisYear: Scalars['Float'];
  unapprovedThisYear: Scalars['Float'];
  approvedPercentageThisYear: Scalars['Float'];
};

export type OrganizationTeamParticipationSummaries = {
  __typename?: 'OrganizationTeamParticipationSummaries';
  participationSummaries: Array<OrganizationTeamParticipationSummary>;
  pagination: OffsetPagination;
};

export type OrganizationTeamParticipationSummary = {
  __typename?: 'OrganizationTeamParticipationSummary';
  organizationId: Scalars['String'];
  organizationName: Scalars['String'];
  leagueId: Scalars['String'];
  leagueName: Scalars['String'];
  seasonId: Scalars['String'];
  seasonName: Scalars['String'];
  teamId?: Maybe<Scalars['String']>;
  teamName?: Maybe<Scalars['String']>;
  teamLogo?: Maybe<Scalars['String']>;
  competitionSurveyCount: Scalars['Int'];
  notStartedCompetitionSurveyCount: Scalars['Int'];
  incompleteCompetitionSurveyCount: Scalars['Int'];
  completedCompetitionSurveyCount: Scalars['Int'];
  completionCompetitionSurveyPercentage: Scalars['Int'];
};

export type OrganizationTeamSurveyParticipation = {
  __typename?: 'OrganizationTeamSurveyParticipation';
  organizationId: Scalars['String'];
  organizationName: Scalars['String'];
  leagueId: Scalars['String'];
  leagueName: Scalars['String'];
  seasonId: Scalars['String'];
  seasonName: Scalars['String'];
  teamId: Scalars['String'];
  teamName: Scalars['String'];
  teamLogo: Scalars['String'];
  competitionId: Scalars['String'];
  externalId?: Maybe<Scalars['String']>;
  homeTeamId: Scalars['String'];
  homeTeamName: Scalars['String'];
  homeTeamLogo?: Maybe<Scalars['String']>;
  homeTeamScore?: Maybe<Scalars['Int']>;
  homeTeamTieBreakerScore?: Maybe<Scalars['Int']>;
  awayTeamId: Scalars['String'];
  awayTeamName: Scalars['String'];
  awayTeamLogo?: Maybe<Scalars['String']>;
  awayTeamScore?: Maybe<Scalars['Int']>;
  awayTeamTieBreakerScore?: Maybe<Scalars['Int']>;
  startTime: Scalars['Date'];
  timeZone: Scalars['String'];
  location?: Maybe<Scalars['String']>;
  competitionSurveyId: Scalars['String'];
  surveyId: Scalars['String'];
  surveyName: Scalars['String'];
  finished: Scalars['Boolean'];
  completedDate?: Maybe<Scalars['Date']>;
  lastNotificationSent?: Maybe<Scalars['Date']>;
  lastNotificationTypeSent?: Maybe<SurveyNotificationTypes>;
  lastEmailSent?: Maybe<Scalars['Date']>;
  tookSurvey: Scalars['Boolean'];
  userId: Scalars['String'];
  userFirstName: Scalars['String'];
  userLastName: Scalars['String'];
  userStatus: UserStatuses;
  userEmail: Scalars['String'];
  id: Scalars['Int'];
  coachId: Scalars['String'];
  coachType: Scalars['String'];
  coachRole?: Maybe<Scalars['String']>;
  coachStatus: CoachStatuses;
};

export type OrganizationTeamSurveyParticipations = {
  __typename?: 'OrganizationTeamSurveyParticipations';
  participations: Array<OrganizationTeamSurveyParticipation>;
  pagination: OffsetPagination;
};

export type OrganizationTopOfficial = {
  __typename?: 'OrganizationTopOfficial';
  assessmentObjectId: Scalars['String'];
  assessmentObjectName: Scalars['String'];
  officialId: Scalars['String'];
  officialFirstName: Scalars['String'];
  officialLastName: Scalars['String'];
  officialProfilePic?: Maybe<Scalars['String']>;
  totalCompetitions: Scalars['Float'];
  totalKeyIncidents: Scalars['Float'];
  totalRatingQuestionsCount: Scalars['Float'];
  totalPoints: Scalars['Float'];
  maxPointsPossible: Scalars['Float'];
  averageCompetitionScore: Scalars['Float'];
};

export type OrganizationTopOfficials = {
  __typename?: 'OrganizationTopOfficials';
  officials: Array<OrganizationTopOfficial>;
  pagination: OffsetPagination;
};

/** The organization permissions for users */
export enum OrganizationUserPermissions {
  SurveyManagement = 'SURVEY_MANAGEMENT',
  DynamicSurveyManagement = 'DYNAMIC_SURVEY_MANAGEMENT',
  KiManagement = 'KI_MANAGEMENT',
  SupplementalReportManagement = 'SUPPLEMENTAL_REPORT_MANAGEMENT',
  ParticipationManagement = 'PARTICIPATION_MANAGEMENT',
  TeamManagement = 'TEAM_MANAGEMENT',
  CompetitionManagement = 'COMPETITION_MANAGEMENT',
  OfficialManagement = 'OFFICIAL_MANAGEMENT',
  ViewRawSurveyResponses = 'VIEW_RAW_SURVEY_RESPONSES',
  ViewRawDynamicSurveyResponses = 'VIEW_RAW_DYNAMIC_SURVEY_RESPONSES',
  LeagueManagement = 'LEAGUE_MANAGEMENT',
  TeamKitManagement = 'TEAM_KIT_MANAGEMENT',
  UserManagement = 'USER_MANAGEMENT',
  MediaMembersManagement = 'MEDIA_MEMBERS_MANAGEMENT'
}

/** The organization user roles */
export enum OrganizationUserRoles {
  OrganizationAdmin = 'ORGANIZATION_ADMIN',
  OrganizationUser = 'ORGANIZATION_USER',
  OrganizationAssociate = 'ORGANIZATION_ASSOCIATE'
}

export type OrganizationUsers = {
  __typename?: 'OrganizationUsers';
  users: Array<User>;
  pagination: OffsetPagination;
};

export type Organizations = {
  __typename?: 'Organizations';
  organizations: Array<Organization>;
  pagination: OffsetPagination;
};

export type Period = {
  __typename?: 'Period';
  id: Scalars['String'];
  sport: Sports;
  name: Scalars['String'];
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type Periods = {
  __typename?: 'Periods';
  periods: Array<Period>;
  pagination: OffsetPagination;
};

export type PeriodsForSeason = {
  __typename?: 'PeriodsForSeason';
  seasonId: Scalars['String'];
  periods: Array<Period>;
};

export type Query = {
  __typename?: 'Query';
  getCompetitionIntegrationById: CompetitionIntegration;
  listCompetitionIntegrations: CompetitionIntegrations;
  listAdditionalOptionsForCompetitionIntegration: CompetitionIntegrationAdditionalOptions;
  getDynamicSurveyIntegrationById: DynamicSurveyIntegration;
  listDynamicSurveyIntegrations: DynamicSurveyIntegrations;
  listAdditionalOptionsForDynamicSurveyIntegration: DynamicSurveyIntegrationAdditionalOptions;
  getKeyIncidentVideoIntegrationById: KeyIncidentVideoIntegration;
  listKeyIncidentVideoIntegrations: KeyIncidentVideoIntegrations;
  listAdditionalOptionsForKeyIncidentVideoIntegration: KeyIncidentVideoIntegrationAdditionalOptions;
  listOrganizations: Organizations;
  listAdminUsers: AdminUsersResponse;
  getAdminUserById: User;
  getMyAlertById: UserAlert;
  listMyAlerts: UserAlerts;
  getMyNewAlertsCount: Scalars['Int'];
  listAllSurveysForOrganization: AnySurveys;
  checkConfirmationToken: ConfirmationStatus;
  checkResetPasswordToken: ResetPasswordStatus;
  getBatchSeasonCoachUploadById: BatchSeasonCoachUpload;
  listBatchSeasonCoachUploadsForSeason: BatchSeasonCoachUploads;
  listRowsForBatchSeasonCoachUpload: BatchSeasonCoachUploadRows;
  listErrorsForBatchSeasonCoachUploadRow: BatchSeasonCoachUploadRowErrors;
  getBatchSeasonCompetitionUploadById: BatchSeasonCompetitionUpload;
  listBatchSeasonCompetitionUploadsForSeason: BatchSeasonCompetitionUploads;
  listRowsForBatchSeasonCompetitionUpload: BatchSeasonCompetitionUploadRows;
  listErrorsForBatchSeasonCompetitionUploadRow: BatchSeasonCompetitionUploadRowErrors;
  getBatchSeasonOfficialUploadById: BatchSeasonOfficialUpload;
  listBatchSeasonOfficialUploadsForSeason: BatchSeasonOfficialUploads;
  listRowsForBatchSeasonOfficialUpload: BatchSeasonOfficialUploadRows;
  listErrorsForBatchSeasonOfficialUploadRow: BatchSeasonOfficialUploadRowErrors;
  getBatchSeasonSurveyResponseUploadById: BatchSeasonSurveyResponseUpload;
  listBatchSeasonSurveyResponseUploads: BatchSeasonSurveyResponseUploads;
  listRowsForBatchSeasonSurveyResponseUpload: BatchSeasonSurveyResponseUploadRows;
  listErrorsForBatchSeasonSurveyResponseUploadRow: BatchSeasonSurveyResponseUploadRowErrors;
  getBatchSeasonTeamUploadById: BatchSeasonTeamUpload;
  listBatchSeasonTeamUploadsForSeason: BatchSeasonTeamUploads;
  listRowsForBatchSeasonTeamUpload: BatchSeasonTeamUploadRows;
  listErrorsForBatchSeasonTeamUploadRow: BatchSeasonTeamUploadRowErrors;
  getCoachById: Coach;
  getCompetitionSurveyById: CompetitionSurvey;
  listKeyIncidentsForCompetitionSurvey: KeyIncidents;
  listExternalLinksForKeyIncidentForCompetitionSurvey: KeyIncidentExternalLinks;
  listCompetitionSurveyResponsesForOrganization: CompetitionSurveyResponses;
  listSurveyResponsesForCompetitionAndSurvey: SurveyResponsesForCompetition;
  listMyCompetitionSurveys: CompetitionSurveySummaries;
  getCompetitionSurveyByIdWithToken: CompetitionSurvey;
  listKeyIncidentsForCompetitionSurveyWithToken: KeyIncidents;
  listExternalLinksForKeyIncidentForCompetitionSurveyWithToken: KeyIncidentExternalLinks;
  getCompetitionTeamKitById: CompetitionTeamKit;
  listCompetitionTeamKitRecipients: Array<CompetitionTeamKitRecipient>;
  listCompetitionTeamKitCompetitionInfos: CompetitionTeamKitCompetitionInfos;
  listCompetitionTeamKitBroadcastOptions: CompetitionTeamKitBroadcastOptions;
  listAvailableBroadcastOptionsForCompetitionTeamKit: SeasonTeamKitBroadcastOptions;
  listKitRolesForCompetitionTeamKit: SeasonTeamKitRoles;
  listCompetitionTeamKitApparels: CompetitionTeamKitApparels;
  getCompetitionTeamKitApparelById: CompetitionTeamKitApparel;
  listAvailableSelectionsForCompetitionTeamKitApparel: TeamKitSeasonApparels;
  listCompetitionTeamKitContacts: CompetitionTeamKitContacts;
  listAvailableContactForCompetitionTeamKitContact: SeasonTeamKitContactOptions;
  getCompetitionTeamKitContactById: CompetitionTeamKitContact;
  listCompetitionTeamKitsForOrganization: CompetitionTeamKits;
  listMyCompetitionTeamKits: CompetitionTeamKits;
  getCompetitionById: Competition;
  listCompetitionsForSeason: Competitions;
  listCompetitionsForLeague: Competitions;
  listOfficialsForCompetition: CompetitionOfficials;
  listAvailablePeriodsForCompetition: Periods;
  listAvailablePeriodsForCompetitionWithToken: Periods;
  listCompetitionSummariesForOrg: CompetitionSummaries;
  listStatsForCompetition: CompetitionStats;
  listStatsForCompetitionByTeam: Array<CompetitionStat>;
  listAllSurveyInfoForCompetitionForOrg: Array<SurveyInfoForCompetition>;
  getDynamicCompetitionSurveyById: DynamicCompetitionSurvey;
  listDynamicCompetitionSurveySummariesForOrganization: DynamicCompetitionSurveySummaries;
  listStatsForDynamicCompetitionSurveyById: Array<CompetitionStat>;
  listSupplementalReportsForDynamicCompetitionSurvey: SupplementalReports;
  listKeyIncidentsForDynamicCompetitionSurvey: KeyIncidents;
  getDynamicCompetitionSurveyByIdWithToken: DynamicCompetitionSurvey;
  listStatsForDynamicCompetitionSurveyByIdWithToken: Array<CompetitionStat>;
  listSupplementalReportsForDynamicCompetitionSurveyWithToken: SupplementalReports;
  listKeyIncidentsForDynamicCompetitionSurveyWithToken: KeyIncidents;
  getDynamicSurveyById: DynamicSurvey;
  listDynamicSurveysForOrganization: DynamicSurveys;
  listReminderLevelRecipientsForDynamicSurvey: SurveyReminderLevelRecipients;
  listAvailableAssessmentObjectsForDynamicSurveysRecipients: AssessmentObjects;
  listSummaryRecipientsForDynamicSurvey: SurveySummaryRecipients;
  listAvailableCompetitionsForFeedbackForMe: Array<CompetitionsAvailableForAdditionalFeedback>;
  listSourcesAvailableForAdditionalFeedbackForCompetitionsForMe: Array<SourcesAvailableForAdditionalFeedback>;
  listAvailableCompetitionOfficialsForAdditionalKeyIncidents: Array<CompetitionOfficialsAvailableForAdditionalKeyIncidents>;
  listAvailableFeedbackToConvertForAdditionalKeyIncidents: Array<FeedbackAvailableToConvertToKeyIncident>;
  getKeyIncidentById: KeyIncident;
  listKeyIncidentsForCompetition: KeyIncidentsForCompetition;
  listKeyIncidentsForCompetitionAndSurvey: Array<KeyIncident>;
  listKeyIncidentSummariesForOrg: KeyIncidentSummaries;
  listKeyIncidentSummariesForUser: KeyIncidentSummaries;
  listCommentsForKeyIncident: KeyIncidentMessages;
  listExternalLinksForKeyIncident: KeyIncidentExternalLinks;
  getLeagueById: League;
  listLeaguesForOrganization: Leagues;
  listLeagueMembershipUsers: LeagueMembershipUsers;
  listMediaOrganizations: MediaOrganizations;
  getMediaOrganizationById: MediaOrganization;
  listOrganizationTopOfficialsMetrics: OrganizationTopOfficials;
  getOfficialById: Official;
  listOfficials: Officials;
  getOrgOfficialSummaryById: OfficialSummary;
  listCompetitionSummariesForOrgOfficial: OfficialCompetitionSummaries;
  listFeedbackForOrgOfficial: OrgOfficialFeedbacks;
  listKeyIncidentSummariesForOrgOfficial: KeyIncidentSummaries;
  getOrganizationCompetitionMetrics: OrganizationCompetitionMetrics;
  getOrganizationTeamCompetitionSurveyMetrics: OrganizationCompetitionSurveyMetrics;
  getOrganizationOfficialCompetitionSurveyMetrics: OrganizationCompetitionSurveyMetrics;
  getOrganizationOfficialDynamicCompetitionSurveyMetrics: OrganizationCompetitionSurveyMetrics;
  getOrganizationKeyIncidentMetrics: OrganizationKeyIncidentMetrics;
  getOrganizationSupplementalReportMetrics: OrganizationSupplementalReportMetrics;
  getOrganizationById: Organization;
  getUserOrganizationMembership: OrganizationMembership;
  listOrganizationUsers: OrganizationUsers;
  listOfficialAssessmentObjectsForOrganization: Array<AssessmentObject>;
  listFeaturesForOrganization: OrganizationFeatures;
  listContactsForOrganization: OrganizationContacts;
  listTeamParticipationSummariesForOrg: OrganizationTeamParticipationSummaries;
  listTeamSurveyParticipationForOrg: OrganizationTeamSurveyParticipations;
  listOfficialParticipationSummariesForOrg: OrganizationOfficialParticipationSummaries;
  listOfficialSurveyParticipationForOrg: OrganizationOfficialSurveyParticipations;
  listSeasonCompetitionIntegrationsForSeasonInput?: Maybe<SeasonCompetitionIntegrations>;
  getSeasonCompetitionIntegrationById: SeasonCompetitionIntegration;
  listAvailableCompetitionIntegrationsForSeasonInput: CompetitionIntegrations;
  listOptionsForSeasonCompetitionIntegration: Array<SeasonCompetitionIntegrationOption>;
  listCompetitionIntegrationRequestsForSeason: CompetitionIntegrationRequestSummaries;
  listCompetitionIntegrationResponsesForRequest: CompetitionIntegrationResponses;
  findSeasonMediaMembersConfigForSeason?: Maybe<SeasonMediaMembersConfig>;
  getSeasonMediaMembersConfigById?: Maybe<SeasonMediaMembersConfig>;
  listSeasonMediaMembersOrgRecipients: SeasonMediaMembersOrgRecipients;
  getMediaMembersOrgSetupSummaryForSeason: MediaMembersSeasonOrgSetupSummary;
  findSeasonTeamKitForSeason?: Maybe<SeasonTeamKit>;
  getSeasonTeamKitById?: Maybe<SeasonTeamKit>;
  listSeasonTeamKitRoles: SeasonTeamKitRoles;
  listSeasonTeamKitApparelDefinitions: SeasonTeamKitApparelDefinitions;
  listSeasonTeamKitContactDefinitions: SeasonTeamKitContactDefinitions;
  listSeasonTeamKitContactOptions: SeasonTeamKitContactOptions;
  listTeamKitBroadcastOptionsForSeason: SeasonTeamKitBroadcastOptions;
  listTeamKitSeasonApparels: TeamKitSeasonApparels;
  listTeamKitSeasonKits: TeamKitSeasonKits;
  listSeasonTeamKitOrgRecipients: SeasonTeamKitOrgRecipients;
  getTeamKitOrgSetupSummaryForSeason: TeamKitSeasonOrgSetupSummary;
  getSeasonById: Season;
  listSeasonsForLeague: Seasons;
  getSeasonSetupInfo: SeasonSetupInfo;
  listTeamsForSeason: SeasonTeams;
  listCoachesForSeason: SeasonCoaches;
  listOfficialsForSeason: SeasonOfficials;
  listOfficialAssessmentObjectsForSeason: SeasonOfficialAssessmentObjects;
  listAvailableAssessmentObjectsForSeason: AssessmentObjectsForSeason;
  listSeasonPeriodsForSeason: SeasonPeriods;
  listAvailablePeriodsForSeason: PeriodsForSeason;
  listSeasonDivisionsForSeason: SeasonDivisions;
  getSeasonDivisionById: SeasonDivision;
  listSeasonDivisionAliases: SeasonDivisionAliases;
  listSeasonTeamsForSeasonDivision: SeasonDivisionTeams;
  listSeasonTeamsAvailableForSeasonDivision: SeasonTeams;
  listSports: Array<Sport>;
  listAssessmentObjects: AssessmentObjects;
  listPeriods: Periods;
  getSupplementalReportById: SupplementalReport;
  listOrgSupplementalReportsForCompetition: SupplementalReports;
  listUserSupplementalReportsForCompetition: SupplementalReports;
  listSupplementalReportsForOrg: SupplementalReports;
  listSupplementalReportsForUser: SupplementalReports;
  listCommentsForSupplementalReport: SupplementalReportMessages;
  getSurveySummaryRecipientNotificationSettingByToken: SurveySummaryNotificationSetting;
  listMySurveySummaryRecipientNotificationSettings: SurveySummaryNotificationSettings;
  listAdminSurveyTemplates: SurveyTemplates;
  listSurveyTemplatesForOrganization: SurveyTemplates;
  getSurveyTemplateById: SurveyTemplate;
  getSurveyById: Survey;
  listSurveysForOrganization: Surveys;
  listReminderLevelRecipientsForSurvey: SurveyReminderLevelRecipients;
  listAvailableAssessmentObjectsForSurveysRecipients: AssessmentObjects;
  getTeamById: Team;
  listTeams: Teams;
  listTeamAliases: TeamAliases;
  listTeamCoaches: TeamCoaches;
  listContactsForTeamForSeason: TeamContacts;
  listTeamKitRecipientsForSeasonTeam: TeamKitTeamRecipients;
  listTeamKitTeamSetupSummariesForSeason: TeamKitSeasonTeamSetupSummaries;
  getTeamKitTeamSetupSummaryForSeasonTeam: TeamKitSeasonTeamSetupSummary;
  me: User;
  getMyOrganizationContext: UserOrganizationContext;
  listUserNotificationSettings: UserNotificationSettings;
  myNotificationSettings: UserNotificationSettings;
  listDynamicCompetitionSurveyIntegrations: DynamicCompetitionSurveyIntegrations;
  findDynamicCompetitionSurveyIntegrationErrorsByUuid: DynamicCompetitionSurveyIntegrationErrors;
  listCompetitionIntegrationRequests: AdminManageCompetitionIntegrationRequestSummaries;
};


export type QueryGetCompetitionIntegrationByIdArgs = {
  input: GetCompetitionIntegrationByIdInput;
};


export type QueryListCompetitionIntegrationsArgs = {
  input: ListCompetitionIntegrationsInput;
};


export type QueryListAdditionalOptionsForCompetitionIntegrationArgs = {
  input: ListAdditionalOptionsForCompetitionIntegrationInput;
};


export type QueryGetDynamicSurveyIntegrationByIdArgs = {
  input: GetDynamicSurveyIntegrationByIdInput;
};


export type QueryListDynamicSurveyIntegrationsArgs = {
  input: ListDynamicSurveyIntegrationsInput;
};


export type QueryListAdditionalOptionsForDynamicSurveyIntegrationArgs = {
  input: ListAdditionalOptionsForDynamicSurveyIntegrationInput;
};


export type QueryGetKeyIncidentVideoIntegrationByIdArgs = {
  input: GetKeyIncidentVideoIntegrationByIdInput;
};


export type QueryListKeyIncidentVideoIntegrationsArgs = {
  input: ListKeyIncidentVideoIntegrationsInput;
};


export type QueryListAdditionalOptionsForKeyIncidentVideoIntegrationArgs = {
  input: ListAdditionalOptionsForKeyIncidentVideoIntegrationInput;
};


export type QueryListOrganizationsArgs = {
  input: ListOrganizationsInput;
};


export type QueryListAdminUsersArgs = {
  input: ListAdminUsersInput;
};


export type QueryGetAdminUserByIdArgs = {
  input: GetAdminUserByIdInput;
};


export type QueryGetMyAlertByIdArgs = {
  input: GetAlertByIdInput;
};


export type QueryListMyAlertsArgs = {
  input: ListAlertsForUserInput;
};


export type QueryListAllSurveysForOrganizationArgs = {
  input: ListAllSurveysForOrganizationInput;
};


export type QueryCheckConfirmationTokenArgs = {
  input: CheckConfirmationInput;
};


export type QueryCheckResetPasswordTokenArgs = {
  input: CheckResetPasswordTokenInput;
};


export type QueryGetBatchSeasonCoachUploadByIdArgs = {
  input: GetBatchSeasonCoachUploadByIdInput;
};


export type QueryListBatchSeasonCoachUploadsForSeasonArgs = {
  input: ListBatchSeasonCoachUploadsForSeasonInput;
};


export type QueryListRowsForBatchSeasonCoachUploadArgs = {
  input: ListRowsForBatchSeasonCoachUploadInput;
};


export type QueryListErrorsForBatchSeasonCoachUploadRowArgs = {
  input: ListErrorsForBatchSeasonCoachUploadRowInput;
};


export type QueryGetBatchSeasonCompetitionUploadByIdArgs = {
  input: GetBatchSeasonCompetitionUploadByIdInput;
};


export type QueryListBatchSeasonCompetitionUploadsForSeasonArgs = {
  input: ListBatchSeasonCompetitionUploadsForSeasonInput;
};


export type QueryListRowsForBatchSeasonCompetitionUploadArgs = {
  input: ListRowsForBatchSeasonCompetitionUploadInput;
};


export type QueryListErrorsForBatchSeasonCompetitionUploadRowArgs = {
  input: ListErrorsForBatchSeasonCompetitionUploadRowInput;
};


export type QueryGetBatchSeasonOfficialUploadByIdArgs = {
  input: GetBatchSeasonOfficialUploadByIdInput;
};


export type QueryListBatchSeasonOfficialUploadsForSeasonArgs = {
  input: ListBatchSeasonOfficialUploadsForSeasonInput;
};


export type QueryListRowsForBatchSeasonOfficialUploadArgs = {
  input: ListRowsForBatchSeasonOfficialUploadInput;
};


export type QueryListErrorsForBatchSeasonOfficialUploadRowArgs = {
  input: ListErrorsForBatchSeasonOfficialUploadRowInput;
};


export type QueryGetBatchSeasonSurveyResponseUploadByIdArgs = {
  input: GetBatchSeasonSurveyResponseUploadByIdInput;
};


export type QueryListBatchSeasonSurveyResponseUploadsArgs = {
  input: ListBatchSeasonSurveyResponseUploadsForSeasonInput;
};


export type QueryListRowsForBatchSeasonSurveyResponseUploadArgs = {
  input: ListRowsForBatchSeasonSurveyResponseUploadInput;
};


export type QueryListErrorsForBatchSeasonSurveyResponseUploadRowArgs = {
  input: ListErrorsForBatchSeasonSurveyResponseUploadRowInput;
};


export type QueryGetBatchSeasonTeamUploadByIdArgs = {
  input: GetBatchSeasonTeamUploadByIdInput;
};


export type QueryListBatchSeasonTeamUploadsForSeasonArgs = {
  input: ListBatchSeasonTeamUploadsForSeasonInput;
};


export type QueryListRowsForBatchSeasonTeamUploadArgs = {
  input: ListRowsForBatchSeasonTeamUploadInput;
};


export type QueryListErrorsForBatchSeasonTeamUploadRowArgs = {
  input: ListErrorsForBatchSeasonTeamUploadRowInput;
};


export type QueryGetCoachByIdArgs = {
  input: GetCoachByIdInput;
};


export type QueryGetCompetitionSurveyByIdArgs = {
  input: GetCompetitionSurveyInput;
};


export type QueryListKeyIncidentsForCompetitionSurveyArgs = {
  input: ListKeyIncidentsForCompetitionSurveyInput;
};


export type QueryListExternalLinksForKeyIncidentForCompetitionSurveyArgs = {
  input: ListExternalLinksForKeyIncidentForCompetitionSurveyInput;
};


export type QueryListCompetitionSurveyResponsesForOrganizationArgs = {
  input: ListCompetitionSurveyResponsesForOrganizationInput;
};


export type QueryListSurveyResponsesForCompetitionAndSurveyArgs = {
  input: ListSurveyResponsesForCompetitionAndSurveyInput;
};


export type QueryListMyCompetitionSurveysArgs = {
  input: ListMyCompetitionSurveysInput;
};


export type QueryGetCompetitionSurveyByIdWithTokenArgs = {
  input: GetCompetitionSurveyByIdWithTokenInput;
};


export type QueryListKeyIncidentsForCompetitionSurveyWithTokenArgs = {
  input: ListKeyIncidentsForCompetitionSurveyWithTokenInput;
};


export type QueryListExternalLinksForKeyIncidentForCompetitionSurveyWithTokenArgs = {
  input: ListExternalLinksForKeyIncidentForCompetitionSurveyWithTokenInput;
};


export type QueryGetCompetitionTeamKitByIdArgs = {
  input: GetCompetitionTeamKitInput;
};


export type QueryListCompetitionTeamKitRecipientsArgs = {
  input: ListCompetitionTeamKitRecipientsInput;
};


export type QueryListCompetitionTeamKitCompetitionInfosArgs = {
  input: ListCompetitionTeamKitCompetitionInfosInput;
};


export type QueryListCompetitionTeamKitBroadcastOptionsArgs = {
  input: ListCompetitionTeamKitBroadcastOptionsInput;
};


export type QueryListAvailableBroadcastOptionsForCompetitionTeamKitArgs = {
  input: ListAvailableBroadcastOptionsForCompetitionTeamKitInput;
};


export type QueryListKitRolesForCompetitionTeamKitArgs = {
  input: ListKitRolesForCompetitionTeamKitInput;
};


export type QueryListCompetitionTeamKitApparelsArgs = {
  input: ListCompetitionTeamKitApparelsInput;
};


export type QueryGetCompetitionTeamKitApparelByIdArgs = {
  input: GetCompetitionTeamKitApparelByIdInput;
};


export type QueryListAvailableSelectionsForCompetitionTeamKitApparelArgs = {
  input: ListAvailableSelectionsForCompetitionTeamKitApparelInput;
};


export type QueryListCompetitionTeamKitContactsArgs = {
  input: ListCompetitionTeamKitContactsInput;
};


export type QueryListAvailableContactForCompetitionTeamKitContactArgs = {
  input: ListAvailableContactForCompetitionTeamKitContactInput;
};


export type QueryGetCompetitionTeamKitContactByIdArgs = {
  input: GetCompetitionTeamKitContactByIdInput;
};


export type QueryListCompetitionTeamKitsForOrganizationArgs = {
  input: ListCompetitionTeamKitsForOrganizationInput;
};


export type QueryListMyCompetitionTeamKitsArgs = {
  input: ListMyCompetitionTeamKitsInput;
};


export type QueryGetCompetitionByIdArgs = {
  input: GetCompetitionByIdInput;
};


export type QueryListCompetitionsForSeasonArgs = {
  input: ListCompetitionsForSeasonInput;
};


export type QueryListCompetitionsForLeagueArgs = {
  input: ListCompetitionsForLeagueInput;
};


export type QueryListOfficialsForCompetitionArgs = {
  input: ListOfficialsForCompetitionInput;
};


export type QueryListAvailablePeriodsForCompetitionArgs = {
  input: ListAvailablePeriodsForCompetitionInput;
};


export type QueryListAvailablePeriodsForCompetitionWithTokenArgs = {
  input: ListAvailablePeriodsForCompetitionInputWithToken;
};


export type QueryListCompetitionSummariesForOrgArgs = {
  input: ListCompetitionSummariesForOrgInput;
};


export type QueryListStatsForCompetitionArgs = {
  input: ListStatsForCompetitionInput;
};


export type QueryListStatsForCompetitionByTeamArgs = {
  input: ListStatsForCompetitionByTeamInput;
};


export type QueryListAllSurveyInfoForCompetitionForOrgArgs = {
  input: ListAllSurveyInfoForCompetitionForOrgInput;
};


export type QueryGetDynamicCompetitionSurveyByIdArgs = {
  input: GetDynamicCompetitionSurveyInput;
};


export type QueryListDynamicCompetitionSurveySummariesForOrganizationArgs = {
  input: ListDynamicCompetitionSurveySummariesForOrganizationInput;
};


export type QueryListStatsForDynamicCompetitionSurveyByIdArgs = {
  input: ListStatsForDynamicCompetitionSurveyByIdInput;
};


export type QueryListSupplementalReportsForDynamicCompetitionSurveyArgs = {
  input: ListSupplementalReportsForDynamicCompetitionSurveyInput;
};


export type QueryListKeyIncidentsForDynamicCompetitionSurveyArgs = {
  input: ListKeyIncidentsForDynamicCompetitionSurveyInput;
};


export type QueryGetDynamicCompetitionSurveyByIdWithTokenArgs = {
  input: GetDynamicCompetitionSurveyByIdWithTokenInput;
};


export type QueryListStatsForDynamicCompetitionSurveyByIdWithTokenArgs = {
  input: ListStatsForDynamicCompetitionSurveyByIdWithTokenInput;
};


export type QueryListSupplementalReportsForDynamicCompetitionSurveyWithTokenArgs = {
  input: ListSupplementalReportsForDynamicCompetitionSurveyWithTokenInput;
};


export type QueryListKeyIncidentsForDynamicCompetitionSurveyWithTokenArgs = {
  input: ListKeyIncidentsForDynamicCompetitionSurveyWithTokenInput;
};


export type QueryGetDynamicSurveyByIdArgs = {
  input: GetDynamicSurveyByIdInput;
};


export type QueryListDynamicSurveysForOrganizationArgs = {
  input: ListDynamicSurveysForOrganizationInput;
};


export type QueryListReminderLevelRecipientsForDynamicSurveyArgs = {
  input: ListReminderLevelRecipientsForDynamicSurveyInput;
};


export type QueryListAvailableAssessmentObjectsForDynamicSurveysRecipientsArgs = {
  input: ListAvailableAssessmentObjectsForDynamicSurveysRecipientsInput;
};


export type QueryListSummaryRecipientsForDynamicSurveyArgs = {
  input: ListSummaryRecipientsForDynamicSurveyInput;
};


export type QueryListSourcesAvailableForAdditionalFeedbackForCompetitionsForMeArgs = {
  input: ListSourcesAvailableForAdditionalFeedbackForCompetitionsForMeInput;
};


export type QueryListAvailableCompetitionOfficialsForAdditionalKeyIncidentsArgs = {
  input: ListAvailableCompetitionOfficialsForAdditionalKeyIncidentsInput;
};


export type QueryListAvailableFeedbackToConvertForAdditionalKeyIncidentsArgs = {
  input: ListAvailableFeedbackToConvertForAdditionalKeyIncidentsInput;
};


export type QueryGetKeyIncidentByIdArgs = {
  input: GetKeyIncidentByIdInput;
};


export type QueryListKeyIncidentsForCompetitionArgs = {
  input: ListKeyIncidentsForCompetitionInput;
};


export type QueryListKeyIncidentsForCompetitionAndSurveyArgs = {
  input: ListKeyIncidentsForCompetitionAndSurveyInput;
};


export type QueryListKeyIncidentSummariesForOrgArgs = {
  input: ListKeyIncidentSummariesForOrgInput;
};


export type QueryListKeyIncidentSummariesForUserArgs = {
  input: ListKeyIncidentSummariesForUserInput;
};


export type QueryListCommentsForKeyIncidentArgs = {
  input: ListCommentsForKeyIncidentInput;
};


export type QueryListExternalLinksForKeyIncidentArgs = {
  input: ListExternalLinksForKeyIncidentInput;
};


export type QueryGetLeagueByIdArgs = {
  input: GetLeagueByIdInput;
};


export type QueryListLeaguesForOrganizationArgs = {
  input: ListLeaguesForOrganizationInput;
};


export type QueryListLeagueMembershipUsersArgs = {
  input: ListLeagueMembershipUsersInput;
};


export type QueryListMediaOrganizationsArgs = {
  input: ListMediaOrganizationsInput;
};


export type QueryGetMediaOrganizationByIdArgs = {
  input: GetMediaOrganizationByIdInput;
};


export type QueryListOrganizationTopOfficialsMetricsArgs = {
  input: GetOrganizationTopOfficialsInput;
};


export type QueryGetOfficialByIdArgs = {
  input: GetOfficialByIdInput;
};


export type QueryListOfficialsArgs = {
  input: ListOfficialsInput;
};


export type QueryGetOrgOfficialSummaryByIdArgs = {
  input: GetOrgOfficialSummaryByIdInput;
};


export type QueryListCompetitionSummariesForOrgOfficialArgs = {
  input: ListCompetitionSummariesForOrgOfficialInput;
};


export type QueryListFeedbackForOrgOfficialArgs = {
  input: ListFeedbackForOrgOfficialInput;
};


export type QueryListKeyIncidentSummariesForOrgOfficialArgs = {
  input: ListKeyIncidentSummariesForOrgOfficialInput;
};


export type QueryGetOrganizationCompetitionMetricsArgs = {
  input: GetOrganizationCompetitionMetricsInput;
};


export type QueryGetOrganizationTeamCompetitionSurveyMetricsArgs = {
  input: GetOrganizationTeamCompetitionSurveyMetricsInput;
};


export type QueryGetOrganizationOfficialCompetitionSurveyMetricsArgs = {
  input: GetOrganizationOfficialCompetitionSurveyMetricsInput;
};


export type QueryGetOrganizationOfficialDynamicCompetitionSurveyMetricsArgs = {
  input: GetOrganizationOfficialDynamicCompetitionSurveyMetricsInput;
};


export type QueryGetOrganizationKeyIncidentMetricsArgs = {
  input: GetOrganizationKeyIncidentMetricsInput;
};


export type QueryGetOrganizationSupplementalReportMetricsArgs = {
  input: GetOrganizationSupplementalReportMetricsInput;
};


export type QueryGetOrganizationByIdArgs = {
  input: GetOrganizationByIdInput;
};


export type QueryGetUserOrganizationMembershipArgs = {
  input: GetUserOrganizationMembershipInput;
};


export type QueryListOrganizationUsersArgs = {
  input: ListOrganizationUsersInput;
};


export type QueryListOfficialAssessmentObjectsForOrganizationArgs = {
  input: ListAssessmentObjectsForOrganizationInput;
};


export type QueryListFeaturesForOrganizationArgs = {
  input: ListFeaturesForOrganizationInput;
};


export type QueryListContactsForOrganizationArgs = {
  input: ListContactsForOrganizationInput;
};


export type QueryListTeamParticipationSummariesForOrgArgs = {
  input: ListTeamParticipationSummariesForOrgInput;
};


export type QueryListTeamSurveyParticipationForOrgArgs = {
  input: ListTeamSurveyParticipationForOrgInput;
};


export type QueryListOfficialParticipationSummariesForOrgArgs = {
  input: ListOfficialParticipationSummariesForOrgInput;
};


export type QueryListOfficialSurveyParticipationForOrgArgs = {
  input: ListOfficialSurveyParticipationForOrgInput;
};


export type QueryListSeasonCompetitionIntegrationsForSeasonInputArgs = {
  input: ListSeasonCompetitionIntegrationsForSeasonInput;
};


export type QueryGetSeasonCompetitionIntegrationByIdArgs = {
  input: GetSeasonCompetitionIntegrationByIdInput;
};


export type QueryListAvailableCompetitionIntegrationsForSeasonInputArgs = {
  input: ListAvailableCompetitionIntegrationsForSeasonInput;
};


export type QueryListOptionsForSeasonCompetitionIntegrationArgs = {
  input: ListOptionsForSeasonCompetitionIntegrationInput;
};


export type QueryListCompetitionIntegrationRequestsForSeasonArgs = {
  input: ListCompetitionIntegrationRequestsForSeasonInputInput;
};


export type QueryListCompetitionIntegrationResponsesForRequestArgs = {
  input: ListCompetitionIntegrationResponsesForRequestInput;
};


export type QueryFindSeasonMediaMembersConfigForSeasonArgs = {
  input: FindSeasonMediaMembersConfigForSeasonInput;
};


export type QueryGetSeasonMediaMembersConfigByIdArgs = {
  input: GetSeasonMediaMembersConfigByIdInput;
};


export type QueryListSeasonMediaMembersOrgRecipientsArgs = {
  input: ListSeasonMediaMembersOrgRecipientsInput;
};


export type QueryGetMediaMembersOrgSetupSummaryForSeasonArgs = {
  input: GetMediaMembersOrgSetupSummaryForSeasonInput;
};


export type QueryFindSeasonTeamKitForSeasonArgs = {
  input: FindSeasonTeamKitForSeasonInput;
};


export type QueryGetSeasonTeamKitByIdArgs = {
  input: GetSeasonTeamKitByIdInput;
};


export type QueryListSeasonTeamKitRolesArgs = {
  input: ListTeamKitRolesForSeasonInput;
};


export type QueryListSeasonTeamKitApparelDefinitionsArgs = {
  input: ListSeasonTeamKitApparelDefinitionsInput;
};


export type QueryListSeasonTeamKitContactDefinitionsArgs = {
  input: ListSeasonTeamKitContactDefinitionsInput;
};


export type QueryListSeasonTeamKitContactOptionsArgs = {
  input: ListSeasonTeamKitContactOptionsInput;
};


export type QueryListTeamKitBroadcastOptionsForSeasonArgs = {
  input: ListTeamKitBroadcastOptionsForSeasonInput;
};


export type QueryListTeamKitSeasonApparelsArgs = {
  input: ListTeamKitSeasonApparelsInput;
};


export type QueryListTeamKitSeasonKitsArgs = {
  input: ListTeamKitSeasonKitsInput;
};


export type QueryListSeasonTeamKitOrgRecipientsArgs = {
  input: ListSeasonTeamKitOrgRecipientsInput;
};


export type QueryGetTeamKitOrgSetupSummaryForSeasonArgs = {
  input: GetTeamKitOrgSetupSummaryForSeasonInput;
};


export type QueryGetSeasonByIdArgs = {
  input: GetSeasonByIdInput;
};


export type QueryListSeasonsForLeagueArgs = {
  input: ListSeasonsForLeagueInput;
};


export type QueryGetSeasonSetupInfoArgs = {
  input: GetSeasonSetupInfoInput;
};


export type QueryListTeamsForSeasonArgs = {
  input: ListTeamsForSeasonInput;
};


export type QueryListCoachesForSeasonArgs = {
  input: ListCoachesForSeasonInput;
};


export type QueryListOfficialsForSeasonArgs = {
  input: ListOfficialsForSeasonInput;
};


export type QueryListOfficialAssessmentObjectsForSeasonArgs = {
  input: ListOfficialAssessmentObjectsForSeasonInput;
};


export type QueryListAvailableAssessmentObjectsForSeasonArgs = {
  input: ListAvailableAssessmentObjectsForSeasonInput;
};


export type QueryListSeasonPeriodsForSeasonArgs = {
  input: ListPeriodsForSeasonInput;
};


export type QueryListAvailablePeriodsForSeasonArgs = {
  input: ListAvailablePeriodsForSeasonInput;
};


export type QueryListSeasonDivisionsForSeasonArgs = {
  input: ListSeasonDivisionsForSeasonInput;
};


export type QueryGetSeasonDivisionByIdArgs = {
  input: GetSeasonDivisionByIdInput;
};


export type QueryListSeasonDivisionAliasesArgs = {
  input: ListSeasonDivisionAliasesInput;
};


export type QueryListSeasonTeamsForSeasonDivisionArgs = {
  input: ListSeasonTeamsForSeasonDivisionInput;
};


export type QueryListSeasonTeamsAvailableForSeasonDivisionArgs = {
  input: ListSeasonTeamsAvailableForSeasonDivisionInput;
};


export type QueryListAssessmentObjectsArgs = {
  input: ListAssessmentObjectsInput;
};


export type QueryListPeriodsArgs = {
  input: ListPeriodsInput;
};


export type QueryGetSupplementalReportByIdArgs = {
  input: GetSupplementalReportByIdInput;
};


export type QueryListOrgSupplementalReportsForCompetitionArgs = {
  input: ListOrgSupplementalReportsForCompetitionInput;
};


export type QueryListUserSupplementalReportsForCompetitionArgs = {
  input: ListUserSupplementalReportsForCompetitionInput;
};


export type QueryListSupplementalReportsForOrgArgs = {
  input: ListSupplementalReportsForOrgInput;
};


export type QueryListSupplementalReportsForUserArgs = {
  input: ListSupplementalReportsForUserInput;
};


export type QueryListCommentsForSupplementalReportArgs = {
  input: ListCommentsForSupplementalReportInput;
};


export type QueryGetSurveySummaryRecipientNotificationSettingByTokenArgs = {
  input: GetSurveySummaryRecipientNotificationSettingInput;
};


export type QueryListMySurveySummaryRecipientNotificationSettingsArgs = {
  input: ListMySurveySummaryRecipientNotificationSettingsInput;
};


export type QueryListAdminSurveyTemplatesArgs = {
  input: ListAdminSurveyTemplatesInput;
};


export type QueryListSurveyTemplatesForOrganizationArgs = {
  input: ListSurveyTemplatesForOrganizationInput;
};


export type QueryGetSurveyTemplateByIdArgs = {
  input: GetSurveyTemplateByIdInput;
};


export type QueryGetSurveyByIdArgs = {
  input: GetSurveyByIdInput;
};


export type QueryListSurveysForOrganizationArgs = {
  input: ListSurveysForOrganizationInput;
};


export type QueryListReminderLevelRecipientsForSurveyArgs = {
  input: ListReminderLevelRecipientsForSurveyInput;
};


export type QueryListAvailableAssessmentObjectsForSurveysRecipientsArgs = {
  input: ListAvailableAssessmentObjectsForSurveysRecipientsInput;
};


export type QueryGetTeamByIdArgs = {
  input: GetTeamByIdInput;
};


export type QueryListTeamsArgs = {
  input: ListTeamsInput;
};


export type QueryListTeamAliasesArgs = {
  input: ListTeamAliasesInput;
};


export type QueryListTeamCoachesArgs = {
  input: ListTeamCoachesInput;
};


export type QueryListContactsForTeamForSeasonArgs = {
  input: ListContactsForTeamForSeasonInput;
};


export type QueryListTeamKitRecipientsForSeasonTeamArgs = {
  input: ListTeamKitRecipientsForSeasonTeamInput;
};


export type QueryListTeamKitTeamSetupSummariesForSeasonArgs = {
  input: ListTeamKitTeamSetupSummariesForSeasonInput;
};


export type QueryGetTeamKitTeamSetupSummaryForSeasonTeamArgs = {
  input: GetTeamKitTeamSetupSummaryForSeasonTeamInput;
};


export type QueryGetMyOrganizationContextArgs = {
  input: GetMyOrganizationContextInput;
};


export type QueryListUserNotificationSettingsArgs = {
  input: ListUserNotificationSettingsInput;
};


export type QueryListDynamicCompetitionSurveyIntegrationsArgs = {
  input: ListDynamicCompetitionSurveyIntegrationsInput;
};


export type QueryFindDynamicCompetitionSurveyIntegrationErrorsByUuidArgs = {
  input: FindDynamicCompetitionSurveyIntegrationErrorsByUuid;
};


export type QueryListCompetitionIntegrationRequestsArgs = {
  input: ListCompetitionIntegrationRequestsInput;
};

export type ReactivateCoachInput = {
  coachId: Scalars['String'];
  seasonId?: Maybe<Scalars['String']>;
};

export type RefreshDynamicCompetitionSurveyFileUploadLinkInput = {
  fileUploadId: Scalars['String'];
};

export type RemoveAdditionalOptionFromCompetitionIntegrationInput = {
  integrationId: Scalars['String'];
  additionalOptionId: Scalars['String'];
};

export type RemoveAdditionalOptionFromDynamicSurveyIntegrationInput = {
  integrationId: Scalars['String'];
  additionalOptionId: Scalars['String'];
};

export type RemoveAdditionalOptionFromKeyIncidentVideoIntegrationInput = {
  integrationId: Scalars['String'];
  additionalOptionId: Scalars['String'];
};

export type RemoveBroadcastOptionFromCompetitionTeamKitInput = {
  competitionTeamKitId: Scalars['String'];
  broadcastOptionId: Scalars['String'];
};

export type RemoveCoachFromTeamInput = {
  id: Scalars['String'];
};

export type RemoveCompetitionInfoFromCompetitionTeamKitInput = {
  competitionTeamKitId: Scalars['String'];
  competitionInfoId: Scalars['String'];
};

export type RemoveCompetitionSurveyMultipleResponseInput = {
  competitionSurveyId: Scalars['String'];
  questionId: Scalars['String'];
  responseId: Scalars['String'];
};

export type RemoveCompetitionSurveyMultipleResponseWithTokenInput = {
  surveyToken: Scalars['String'];
  questionId: Scalars['String'];
  responseId: Scalars['String'];
};

export type RemoveCompetitionSurveyParticipationInput = {
  orgId: Scalars['String'];
  competitionSurveyId: Scalars['String'];
  userId: Scalars['String'];
};

export type RemoveDynamicCompetitionSurveyFileUploadInput = {
  competitionSurveyId: Scalars['String'];
  questionId: Scalars['String'];
  fileUploadId: Scalars['String'];
};

export type RemoveDynamicCompetitionSurveyFileUploadWithTokenInput = {
  surveyToken: Scalars['String'];
  questionId: Scalars['String'];
  fileUploadId: Scalars['String'];
};

export type RemoveDynamicCompetitionSurveyMultipleResponseInput = {
  competitionSurveyId: Scalars['String'];
  questionId: Scalars['String'];
  responseId: Scalars['String'];
};

export type RemoveDynamicCompetitionSurveyMultipleResponseWithTokenInput = {
  surveyToken: Scalars['String'];
  questionId: Scalars['String'];
  responseId: Scalars['String'];
};

export type RemoveDynamicCompetitionSurveySubQuestionMultipleResponseInput = {
  competitionSurveyId: Scalars['String'];
  questionId: Scalars['String'];
  subQuestionId: Scalars['String'];
  responseId: Scalars['String'];
};

export type RemoveDynamicCompetitionSurveySubQuestionMultipleResponseWithTokenInput = {
  surveyToken: Scalars['String'];
  questionId: Scalars['String'];
  subQuestionId: Scalars['String'];
  responseId: Scalars['String'];
};

export type RemoveExternalLinkFromKeyIncidentFromCompetitionSurveyInput = {
  competitionSurveyId: Scalars['String'];
  externalLinkId: Scalars['String'];
};

export type RemoveExternalLinkFromKeyIncidentFromCompetitionSurveyWithTokenInput = {
  surveyToken: Scalars['String'];
  externalLinkId: Scalars['String'];
};

export type RemoveKeyIncidentFromCompetitionSurveyInput = {
  competitionSurveyId: Scalars['String'];
  keyIncidentId: Scalars['String'];
};

export type RemoveKeyIncidentFromCompetitionSurveyWithTokenInput = {
  surveyToken: Scalars['String'];
  keyIncidentId: Scalars['String'];
};

export type RemoveKeyIncidentFromDynamicCompetitionSurveyInput = {
  competitionSurveyId: Scalars['String'];
  keyIncidentId: Scalars['String'];
};

export type RemoveKeyIncidentVideoIntegrationFromSurveyInput = {
  surveyId: Scalars['String'];
};

export type RemoveLeagueMembershipInput = {
  leagueId: Scalars['String'];
  userId: Scalars['String'];
};

export type RemoveLeagueTagInput = {
  tagId: Scalars['String'];
};

export type RemoveOfficialAssessmentObjectFromSeasonInput = {
  id: Scalars['String'];
};

export type RemoveOfficialFromCompetitionInput = {
  competitionId: Scalars['String'];
  officialId: Scalars['String'];
};

export type RemoveOfficialFromSeasonInput = {
  seasonId: Scalars['String'];
  officialId: Scalars['String'];
};

export type RemoveSeasonPeriodFromSeasonInput = {
  id: Scalars['String'];
};

export type RemoveSupplementalReportFromDynamicCompetitionSurveyInput = {
  competitionSurveyId: Scalars['String'];
  supplementalReportId: Scalars['String'];
};

export type RemoveSupplementalReportFromDynamicCompetitionSurveyWithTokenInput = {
  surveyToken: Scalars['String'];
  supplementalReportId: Scalars['String'];
};

export type RemoveTeamFromSeasonInput = {
  seasonId: Scalars['String'];
  teamId: Scalars['String'];
};

export type RemoveUserFromOrganizationInput = {
  organizationId: Scalars['String'];
  userId: Scalars['String'];
};

export type ReopenCompetitionSurveyInput = {
  orgId: Scalars['String'];
  competitionSurveyId: Scalars['String'];
};

export type ReopenCompetitionTeamKitInput = {
  competitionTeamKitId: Scalars['String'];
};

export type ReopenDynamicCompetitionSurveyInput = {
  orgId: Scalars['String'];
  dynamicCompetitionSurveyId: Scalars['String'];
};

export type RequestChangeForSupplementalReportInput = {
  supplementalReportId: Scalars['String'];
  message: Scalars['String'];
};

export type RequestResetPasswordLinkInput = {
  email: Scalars['String'];
};

export type RerunDynamicCompetitionIntegrationSurveyInput = {
  dynamicCompetitionSurveyId: Scalars['String'];
};

export type ResendCompetitionSurveyNotificationEmailInput = {
  orgId: Scalars['String'];
  competitionSurveyId: Scalars['String'];
  userId: Scalars['String'];
  reminderType: EmailSurveyReminderType;
};

export type ResendDynamicCompetitionSurveyNotificationEmailInput = {
  orgId: Scalars['String'];
  dynamicCompetitionSurveyId: Scalars['String'];
  userId: Scalars['String'];
  reminderType: EmailSurveyReminderType;
};

export type ResendUserConfirmationEmailInput = {
  email: Scalars['String'];
};

export type ResetPasswordInput = {
  token: Scalars['String'];
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
};

export type ResetPasswordStatus = {
  __typename?: 'ResetPasswordStatus';
  isValid: Scalars['Boolean'];
};

export type ResetTookCompetitionSurveyInput = {
  orgId: Scalars['String'];
  competitionSurveyId: Scalars['String'];
};

export type ResetTookDynamicCompetitionSurveyInput = {
  orgId: Scalars['String'];
  dynamicCompetitionSurveyId: Scalars['String'];
};

export type ResolveKeyIncidentInput = {
  keyIncidentId: Scalars['String'];
  comment: Scalars['String'];
};

export type RunSeasonCompetitionIntegrationInput = {
  seasonIntegrationId: Scalars['String'];
};

export type Season = {
  __typename?: 'Season';
  id: Scalars['String'];
  leagueId: Scalars['String'];
  leagueName: Scalars['String'];
  leagueLogo?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  status: SeasonStatuses;
  teamKitEnabled: Scalars['Boolean'];
  competitionIntegrationEnabled: Scalars['Boolean'];
  mediaMembersEnabled: Scalars['Boolean'];
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type SeasonCoaches = {
  __typename?: 'SeasonCoaches';
  seasonId: Scalars['String'];
  coaches: Array<Coach>;
  pagination: OffsetPagination;
};

export type SeasonCompetitionIntegration = {
  __typename?: 'SeasonCompetitionIntegration';
  id: Scalars['String'];
  seasonId: Scalars['String'];
  seasonName: Scalars['String'];
  integration: CompetitionIntegration;
  status: SeasonCompetitionIntegrationStatuses;
  seasonOptions: Array<SeasonCompetitionIntegrationOption>;
  externalIdOrg?: Maybe<Scalars['String']>;
  lastRan?: Maybe<Scalars['Date']>;
  fromDateMinutes?: Maybe<Scalars['Float']>;
  toDateMinutes?: Maybe<Scalars['Float']>;
};

export type SeasonCompetitionIntegrationOption = {
  __typename?: 'SeasonCompetitionIntegrationOption';
  id: Scalars['String'];
  name: Scalars['String'];
  value?: Maybe<Scalars['String']>;
  isRequired: Scalars['Boolean'];
};

/** The season competition integration statuses */
export enum SeasonCompetitionIntegrationStatuses {
  Pending = 'PENDING',
  MissingRequiredOptions = 'MISSING_REQUIRED_OPTIONS',
  Ready = 'READY',
  Active = 'ACTIVE',
  Disabled = 'DISABLED'
}

export type SeasonCompetitionIntegrations = {
  __typename?: 'SeasonCompetitionIntegrations';
  seasonIntegrations: Array<SeasonCompetitionIntegration>;
  pagination: OffsetPagination;
};

export type SeasonDivision = {
  __typename?: 'SeasonDivision';
  id: Scalars['String'];
  seasonId: Scalars['String'];
  name: Scalars['String'];
  aliases: Array<SeasonDivisionAlias>;
};

export type SeasonDivisionAlias = {
  __typename?: 'SeasonDivisionAlias';
  id: Scalars['String'];
  seasonDivisionId: Scalars['String'];
  alias: Scalars['String'];
};

export type SeasonDivisionAliases = {
  __typename?: 'SeasonDivisionAliases';
  seasonDivisionId: Scalars['String'];
  aliases: Array<SeasonDivisionAlias>;
  pagination: OffsetPagination;
};

export type SeasonDivisionTeam = {
  __typename?: 'SeasonDivisionTeam';
  id: Scalars['String'];
  seasonId: Scalars['String'];
  seasonDivision: SeasonDivision;
  seasonTeam: SeasonTeam;
};

export type SeasonDivisionTeams = {
  __typename?: 'SeasonDivisionTeams';
  seasonId: Scalars['String'];
  seasonDivisionTeams: Array<SeasonDivisionTeam>;
  pagination: OffsetPagination;
};

export type SeasonDivisions = {
  __typename?: 'SeasonDivisions';
  seasonId: Scalars['String'];
  seasonDivisions: Array<SeasonDivision>;
  pagination: OffsetPagination;
};

export type SeasonMediaMembersConfig = {
  __typename?: 'SeasonMediaMembersConfig';
  orgId: Scalars['String'];
  seasonId: Scalars['String'];
  id: Scalars['String'];
  status: MediaMembersSeasonConfigStatus;
  allowIncidentReports: Scalars['Boolean'];
  allowIncidentReportsCutoffDays?: Maybe<Scalars['Int']>;
  allowIncidentReportsDelayMinutes?: Maybe<Scalars['Int']>;
  activatedAt?: Maybe<Scalars['Date']>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type SeasonMediaMembersOrgRecipient = {
  __typename?: 'SeasonMediaMembersOrgRecipient';
  id: Scalars['String'];
  SeasonId: Scalars['String'];
  orgMembershipId: Scalars['String'];
  orgId: Scalars['String'];
  orgName: Scalars['String'];
  orgAbbreviation: Scalars['String'];
  orgLogo?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  isKIRecipient: Scalars['Boolean'];
  title?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  profilePic?: Maybe<Scalars['String']>;
};

export type SeasonMediaMembersOrgRecipients = {
  __typename?: 'SeasonMediaMembersOrgRecipients';
  seasonId: Scalars['String'];
  recipients: Array<SeasonMediaMembersOrgRecipient>;
  pagination: OffsetPagination;
};

export type SeasonOfficial = {
  __typename?: 'SeasonOfficial';
  id: Scalars['String'];
  seasonId: Scalars['String'];
  official: Official;
};

export type SeasonOfficialAdded = {
  __typename?: 'SeasonOfficialAdded';
  seasonOfficial: SeasonOfficial;
  emailSent: Scalars['Boolean'];
  newUser: Scalars['Boolean'];
};

export type SeasonOfficialAssessmentObject = {
  __typename?: 'SeasonOfficialAssessmentObject';
  id: Scalars['String'];
  seasonId: Scalars['String'];
  assessmentObject: AssessmentObject;
  order: Scalars['Int'];
  isOptional: Scalars['Boolean'];
};

export type SeasonOfficialAssessmentObjects = {
  __typename?: 'SeasonOfficialAssessmentObjects';
  seasonId: Scalars['String'];
  assessmentObjects: Array<SeasonOfficialAssessmentObject>;
  pagination: OffsetPagination;
};

export type SeasonOfficials = {
  __typename?: 'SeasonOfficials';
  seasonId: Scalars['String'];
  officials: Array<SeasonOfficial>;
  pagination: OffsetPagination;
};

export type SeasonPeriod = {
  __typename?: 'SeasonPeriod';
  id: Scalars['String'];
  seasonId: Scalars['String'];
  period: Period;
  order: Scalars['Int'];
};

export type SeasonPeriods = {
  __typename?: 'SeasonPeriods';
  seasonId: Scalars['String'];
  periods: Array<SeasonPeriod>;
  pagination: OffsetPagination;
};

export type SeasonSetupInfo = {
  __typename?: 'SeasonSetupInfo';
  seasonId: Scalars['String'];
  leagueId: Scalars['String'];
  leagueName: Scalars['String'];
  name: Scalars['String'];
  status: SeasonStatuses;
  competitionOfficialCount: Scalars['Float'];
  periodCount: Scalars['Float'];
  divisionCount: Scalars['Float'];
  divisionsWithoutTeamsCount: Scalars['Float'];
  teamsWithoutDivisionsCount: Scalars['Float'];
  teamCount: Scalars['Float'];
  coachCount: Scalars['Float'];
  officialCount: Scalars['Float'];
  surveyCount: Scalars['Float'];
  competitionCount: Scalars['Float'];
  leagueMembershipCount: Scalars['Float'];
};

/** The season statuses */
export enum SeasonStatuses {
  Pending = 'PENDING',
  Active = 'ACTIVE',
  NotActive = 'NOT_ACTIVE'
}

export type SeasonTeam = {
  __typename?: 'SeasonTeam';
  id: Scalars['String'];
  seasonId: Scalars['String'];
  team: Team;
};

export type SeasonTeamKit = {
  __typename?: 'SeasonTeamKit';
  orgId: Scalars['String'];
  seasonId: Scalars['String'];
  id: Scalars['String'];
  status: TeamKitSeasonStatus;
  sendPreCompetitionDays: Scalars['Int'];
  sendReminders: Scalars['Boolean'];
  sendReminderDays: Scalars['Int'];
  sendReminderCutoffDays: Scalars['Int'];
  activatedAt?: Maybe<Scalars['Date']>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type SeasonTeamKitActivation = {
  __typename?: 'SeasonTeamKitActivation';
  seasonId: Scalars['String'];
  teamKit: SeasonTeamKit;
  errors?: Maybe<Array<Scalars['String']>>;
};

export type SeasonTeamKitApparelDefinition = {
  __typename?: 'SeasonTeamKitApparelDefinition';
  id: Scalars['String'];
  value: Scalars['String'];
  order: Scalars['Int'];
};

export type SeasonTeamKitApparelDefinitions = {
  __typename?: 'SeasonTeamKitApparelDefinitions';
  seasonId: Scalars['String'];
  definitions: Array<SeasonTeamKitApparelDefinition>;
  pagination: OffsetPagination;
};

export type SeasonTeamKitBroadcastOption = {
  __typename?: 'SeasonTeamKitBroadcastOption';
  id: Scalars['String'];
  seasonId: Scalars['String'];
  teamId?: Maybe<Scalars['String']>;
  teamName?: Maybe<Scalars['String']>;
  value: Scalars['String'];
  isActive: Scalars['Boolean'];
};

export type SeasonTeamKitBroadcastOptions = {
  __typename?: 'SeasonTeamKitBroadcastOptions';
  seasonId: Scalars['String'];
  broadcastOptions: Array<SeasonTeamKitBroadcastOption>;
  pagination: OffsetPagination;
};

export type SeasonTeamKitContactDefinition = {
  __typename?: 'SeasonTeamKitContactDefinition';
  id: Scalars['String'];
  type: TeamKitContactType;
  value: Scalars['String'];
  order: Scalars['Int'];
};

export type SeasonTeamKitContactDefinitions = {
  __typename?: 'SeasonTeamKitContactDefinitions';
  seasonId: Scalars['String'];
  definitions: Array<SeasonTeamKitContactDefinition>;
  pagination: OffsetPagination;
};

export type SeasonTeamKitContactOption = {
  __typename?: 'SeasonTeamKitContactOption';
  id: Scalars['String'];
  seasonId: Scalars['String'];
  definitionId: Scalars['String'];
  orgContactId?: Maybe<Scalars['String']>;
  teamContactId?: Maybe<Scalars['String']>;
  isDefault: Scalars['Boolean'];
  contact: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type SeasonTeamKitContactOptions = {
  __typename?: 'SeasonTeamKitContactOptions';
  seasonId: Scalars['String'];
  options: Array<SeasonTeamKitContactOption>;
  pagination: OffsetPagination;
};

export type SeasonTeamKitOrgRecipient = {
  __typename?: 'SeasonTeamKitOrgRecipient';
  id: Scalars['String'];
  SeasonId: Scalars['String'];
  orgMembershipId: Scalars['String'];
  orgId: Scalars['String'];
  orgName: Scalars['String'];
  orgAbbreviation: Scalars['String'];
  orgLogo?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  title?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  profilePic?: Maybe<Scalars['String']>;
};

export type SeasonTeamKitOrgRecipients = {
  __typename?: 'SeasonTeamKitOrgRecipients';
  seasonId: Scalars['String'];
  recipients: Array<SeasonTeamKitOrgRecipient>;
  pagination: OffsetPagination;
};

export type SeasonTeamKitRole = {
  __typename?: 'SeasonTeamKitRole';
  id: Scalars['String'];
  value: Scalars['String'];
  order: Scalars['Int'];
  type: TeamKitRoleType;
};

export type SeasonTeamKitRoles = {
  __typename?: 'SeasonTeamKitRoles';
  seasonId: Scalars['String'];
  kitRoles: Array<SeasonTeamKitRole>;
  pagination: OffsetPagination;
};

export type SeasonTeams = {
  __typename?: 'SeasonTeams';
  seasonId: Scalars['String'];
  teams: Array<SeasonTeam>;
  pagination: OffsetPagination;
};

export type Seasons = {
  __typename?: 'Seasons';
  seasons: Array<Season>;
  pagination: OffsetPagination;
};

export type SendCompetitionTeamKitRequestedFixToLeagueEditInput = {
  competitionTeamKitId: Scalars['String'];
  forHomeTeam: Scalars['Boolean'];
};

export type SendCompetitionTeamKitToAwayTeamEditInput = {
  competitionTeamKitId: Scalars['String'];
};

export type SendCompetitionTeamKitToHomeTeamEditInput = {
  competitionTeamKitId: Scalars['String'];
};

export type SendCompetitionTeamKitToLeagueEditInput = {
  competitionTeamKitId: Scalars['String'];
};

export type SetDefaultSeasonTeamKitContactOptionInput = {
  seasonId: Scalars['String'];
  contactOptionId: Scalars['String'];
  teamId?: Maybe<Scalars['String']>;
  isDefault: Scalars['Boolean'];
};

export type SetDynamicSurveyAllowAdditionalIncidentReportsCutoffDaysInput = {
  surveyId: Scalars['String'];
  allowAdditionsCutoffDays?: Maybe<Scalars['Int']>;
};

export type SetDynamicSurveyAllowAdditionalIncidentReportsInput = {
  surveyId: Scalars['String'];
  allowAdditions: Scalars['Boolean'];
};

export type SetDynamicSurveyAllowAdditionalSupplementalReportsCutoffDaysInput = {
  surveyId: Scalars['String'];
  allowAdditionsCutoffDays?: Maybe<Scalars['Int']>;
};

export type SetDynamicSurveyAllowAdditionalSupplementalReportsInput = {
  surveyId: Scalars['String'];
  allowAdditions: Scalars['Boolean'];
};

export type SetDynamicSurveyCompetitionVerificationInput = {
  surveyId: Scalars['String'];
  enabled: Scalars['Boolean'];
};

export type SetDynamicSurveySectionQuestionCanAddToKeyIncidentsInput = {
  questionId: Scalars['String'];
  canAddToKeyIncidents: Scalars['Boolean'];
};

export type SetDynamicSurveySectionSubQuestionCanAddToKeyIncidentsInput = {
  subQuestionId: Scalars['String'];
  canAddToKeyIncidents: Scalars['Boolean'];
};

export type SetFeatureForOrganizationInput = {
  organizationId: Scalars['String'];
  feature: OrgFeatures;
  enabled: Scalars['Boolean'];
};

export type SetIsActiveForDynamicSurveySummaryRecipientInput = {
  recipientId: Scalars['String'];
  isActive: Scalars['Boolean'];
};

export type SetIsKiRecipientForSeasonMediaMembersOrgRecipientInput = {
  orgId: Scalars['String'];
  seasonId: Scalars['String'];
  orgRecipientId: Scalars['String'];
  isKIRecipient: Scalars['Boolean'];
};

export type SetIsOptionalForSeasonOfficialAssessmentObjectInput = {
  id: Scalars['String'];
  isOptional: Scalars['Boolean'];
};

export type SetMyPasswordInput = {
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
};

export type SetReadForMyAlertInput = {
  id: Scalars['String'];
  read: Scalars['Boolean'];
};

export type SetSurveyAllowAdditionalIncidentReportsCutoffDaysInput = {
  surveyId: Scalars['String'];
  allowAdditionsCutoffDays?: Maybe<Scalars['Int']>;
};

export type SetSurveyAllowAdditionalIncidentReportsInput = {
  surveyId: Scalars['String'];
  allowAdditions: Scalars['Boolean'];
};

export type SetSurveyCompetitionVerificationInput = {
  surveyId: Scalars['String'];
  enabled: Scalars['Boolean'];
};

export type SetSurveySectionQuestionCanAddToKeyIncidentsInput = {
  questionId: Scalars['String'];
  canAddToKeyIncidents: Scalars['Boolean'];
};

export type SetSurveyTemplateSectionQuestionCanAddToKeyIncidentsInput = {
  questionId: Scalars['String'];
  canAddToKeyIncidents: Scalars['Boolean'];
};

export type SignDynamicCompetitionSurveyInput = {
  competitionSurveyId: Scalars['String'];
};

export type SignDynamicCompetitionSurveyWithTokenInput = {
  surveyToken: Scalars['String'];
};

export type SourcesAvailableForAdditionalFeedback = {
  __typename?: 'SourcesAvailableForAdditionalFeedback';
  organizationId: Scalars['String'];
  organizationName: Scalars['String'];
  leagueId: Scalars['String'];
  leagueName: Scalars['String'];
  seasonId: Scalars['String'];
  seasonName: Scalars['String'];
  competitionId: Scalars['String'];
  competitionExternalId?: Maybe<Scalars['String']>;
  startTime: Scalars['Date'];
  sport: Sports;
  homeTeamId: Scalars['String'];
  homeTeamName: Scalars['String'];
  homeTeamAbbreviation?: Maybe<Scalars['String']>;
  awayTeamId: Scalars['String'];
  awayTeamName: Scalars['String'];
  awayTeamAbbreviation?: Maybe<Scalars['String']>;
  sourceType: FeedbackSource;
  feedbackType: FeedbackType;
  surveyId: Scalars['String'];
  surveyName: Scalars['String'];
};

export type Sport = {
  __typename?: 'Sport';
  sport: Sports;
};

/** Supported Sports */
export enum Sports {
  Soccer = 'SOCCER',
  Hockey = 'HOCKEY',
  Basketball = 'BASKETBALL',
  Baseball = 'BASEBALL',
  Football = 'FOOTBALL',
  Volleyball = 'VOLLEYBALL',
  FieldHockey = 'FIELD_HOCKEY',
  Rugby = 'RUGBY',
  Tennis = 'TENNIS',
  Softball = 'SOFTBALL',
  Lacrosse = 'LACROSSE'
}

export type StartCompetitionSurveyInput = {
  competitionSurveyId: Scalars['String'];
};

export type StartCompetitionSurveyWithTokenInput = {
  surveyToken: Scalars['String'];
};

export type StartDynamicCompetitionSurveyInput = {
  competitionSurveyId: Scalars['String'];
};

export type StartDynamicCompetitionSurveyWithTokenInput = {
  surveyToken: Scalars['String'];
};

export type Subscription = {
  __typename?: 'Subscription';
  newUserAlertsNotification: UserAlertNotification;
};

export type SupplementalReport = {
  __typename?: 'SupplementalReport';
  id: Scalars['String'];
  submittedBy?: Maybe<SupplementalReportSubmittedByInfo>;
  approvedBy?: Maybe<SupplementalReportApprovedByInfo>;
  competitionSurveyId?: Maybe<Scalars['String']>;
  dynamicCompetitionSurveyId?: Maybe<Scalars['String']>;
  competitionInfo: SupplementalReportCompetitionInfo;
  official?: Maybe<Official>;
  team?: Maybe<Team>;
  assessmentObject?: Maybe<AssessmentObject>;
  organizationId?: Maybe<Scalars['String']>;
  organizationName?: Maybe<Scalars['String']>;
  leagueId?: Maybe<Scalars['String']>;
  leagueName?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  seasonName?: Maybe<Scalars['String']>;
  competitionSurveySectionQuestionId?: Maybe<Scalars['String']>;
  competitionSurveyFeedbackId?: Maybe<Scalars['String']>;
  dynamicCompetitionSurveySectionQuestionId?: Maybe<Scalars['String']>;
  dynamicCompetitionSurveyFeedbackId?: Maybe<Scalars['String']>;
  dynamicCompetitionSurveySubFeedbackId?: Maybe<Scalars['String']>;
  type: SupplementalReportTypes;
  status: SupplementalReportStatus;
  title: Scalars['String'];
  report?: Maybe<Scalars['String']>;
  reportVersion?: Maybe<Scalars['Int']>;
  time?: Maybe<Scalars['String']>;
  approvedDate?: Maybe<Scalars['Date']>;
  reopenedDate?: Maybe<Scalars['Date']>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
  submittedAt?: Maybe<Scalars['Date']>;
  reviewStartedAt?: Maybe<Scalars['Date']>;
};

export type SupplementalReportApprovedByInfo = {
  __typename?: 'SupplementalReportApprovedByInfo';
  userId: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  profilePic?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  organizationName: Scalars['String'];
  organizationAbbreviation: Scalars['String'];
};

export type SupplementalReportCompetitionInfo = {
  __typename?: 'SupplementalReportCompetitionInfo';
  competitionId: Scalars['String'];
  externalId?: Maybe<Scalars['String']>;
  sport: Sports;
  homeTeamId: Scalars['String'];
  homeTeamName: Scalars['String'];
  homeTeamLogo?: Maybe<Scalars['String']>;
  homeTeamScore?: Maybe<Scalars['Int']>;
  homeTeamTieBreakerScore?: Maybe<Scalars['Int']>;
  awayTeamId: Scalars['String'];
  awayTeamName: Scalars['String'];
  awayTeamLogo?: Maybe<Scalars['String']>;
  awayTeamScore?: Maybe<Scalars['Int']>;
  awayTeamTieBreakerScore?: Maybe<Scalars['Int']>;
  startTime: Scalars['Date'];
  timeZone: Scalars['String'];
  location?: Maybe<Scalars['String']>;
  outcome?: Maybe<CompetitionOutcome>;
  verificationStatus: CompetitionVerificationStatuses;
};

export type SupplementalReportFiltersInput = {
  leagueId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  teamSearch?: Maybe<Scalars['String']>;
  type?: Maybe<SupplementalReportTypes>;
  status?: Maybe<SupplementalReportStatus>;
  submittedBySearch?: Maybe<Scalars['String']>;
  competitionDate?: Maybe<Scalars['String']>;
};

export type SupplementalReportMessage = {
  __typename?: 'SupplementalReportMessage';
  id: Scalars['String'];
  supplementalReportId: Scalars['String'];
  author?: Maybe<SupplementalReportMessageAuthorInfo>;
  message: Scalars['String'];
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
  editedAt?: Maybe<Scalars['Date']>;
};

export type SupplementalReportMessageAuthorInfo = {
  __typename?: 'SupplementalReportMessageAuthorInfo';
  id: Scalars['String'];
  userId: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  profilePic?: Maybe<Scalars['String']>;
  authorType: Scalars['String'];
  teamId?: Maybe<Scalars['String']>;
  teamName?: Maybe<Scalars['String']>;
  teamLogo?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  organizationName?: Maybe<Scalars['String']>;
  organizationAbbreviation?: Maybe<Scalars['String']>;
  assessmentObjectId?: Maybe<Scalars['String']>;
  assessmentObjectName?: Maybe<Scalars['String']>;
};

export type SupplementalReportMessages = {
  __typename?: 'SupplementalReportMessages';
  messages: Array<SupplementalReportMessage>;
  pagination: OffsetPagination;
};

/** The supplemental report statuses */
export enum SupplementalReportStatus {
  Draft = 'DRAFT',
  ReportNeeded = 'REPORT_NEEDED',
  SentForReview = 'SENT_FOR_REVIEW',
  ChangeRequested = 'CHANGE_REQUESTED',
  Approved = 'APPROVED'
}

export type SupplementalReportSubmittedByInfo = {
  __typename?: 'SupplementalReportSubmittedByInfo';
  userId: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  profilePic?: Maybe<Scalars['String']>;
  teamId?: Maybe<Scalars['String']>;
  teamName?: Maybe<Scalars['String']>;
  teamLogo?: Maybe<Scalars['String']>;
  officialId?: Maybe<Scalars['String']>;
  assessmentObjectId?: Maybe<Scalars['String']>;
  assessmentObjectName?: Maybe<Scalars['String']>;
};

/** The supplemental report types */
export enum SupplementalReportTypes {
  Expulsion = 'EXPULSION',
  Delay = 'DELAY',
  FanInteraction = 'FAN_INTERACTION',
  Other = 'OTHER'
}

export type SupplementalReports = {
  __typename?: 'SupplementalReports';
  supplementalReports: Array<SupplementalReport>;
  pagination: OffsetPagination;
};

export type Survey = {
  __typename?: 'Survey';
  id: Scalars['String'];
  sport: Sports;
  status: SurveyStatuses;
  hasCompetitionVerification: Scalars['Boolean'];
  hasIncidentReport: Scalars['Boolean'];
  keyIncidentRecipients: Array<SurveyKeyIncidentRecipient>;
  recipients: Array<SurveyAssessmentObjectRecipient>;
  additionsConfig: SurveyAdditionsConfig;
  info: SurveyInfo;
  reminders: SurveyRemindersConfig;
  kiVideoIntegration?: Maybe<KeyIncidentVideoIntegration>;
  sections: Array<SurveySection>;
  orgId: Scalars['String'];
  seasonId?: Maybe<Scalars['String']>;
  activatedAt?: Maybe<Scalars['Date']>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type SurveyAdditionsConfig = {
  __typename?: 'SurveyAdditionsConfig';
  allowAdditionalIncidentReports: Scalars['Boolean'];
  allowAdditionalIncidentReportsCutoffDays?: Maybe<Scalars['Int']>;
};

export type SurveyAssessmentObjectRecipient = {
  __typename?: 'SurveyAssessmentObjectRecipient';
  id: Scalars['String'];
  dynamicSurveyId?: Maybe<Scalars['String']>;
  surveyId?: Maybe<Scalars['String']>;
  assessmentObject: AssessmentObject;
};

/** The survey audiences */
export enum SurveyAudiences {
  Team = 'TEAM',
  Official = 'OFFICIAL',
  Organization = 'ORGANIZATION'
}

export type SurveyCompetitionVerification = {
  __typename?: 'SurveyCompetitionVerification';
  enabled: Scalars['Boolean'];
};

export type SurveyDailyRemindersConfig = {
  __typename?: 'SurveyDailyRemindersConfig';
  sendReminders: Scalars['Boolean'];
  sendReminderDays: Scalars['Int'];
  sendReminderCutoffDays: Scalars['Int'];
};

export type SurveyDailyRemindersInput = {
  sendReminders: Scalars['Boolean'];
  sendReminderDays: Scalars['Int'];
  sendReminderCutoffDays: Scalars['Int'];
};

export type SurveyInfo = {
  __typename?: 'SurveyInfo';
  name: Scalars['String'];
  description: Scalars['String'];
  audience: SurveyAudiences;
  sendDelayMinutes: Scalars['Int'];
};

export type SurveyInfoForCompetition = {
  __typename?: 'SurveyInfoForCompetition';
  competitionSurveyId?: Maybe<Scalars['String']>;
  surveyId: Scalars['String'];
  surveyName: Scalars['String'];
  competitionId: Scalars['String'];
  audience: SurveyAudiences;
  isDynamic: Scalars['Boolean'];
};

export type SurveyKeyIncidentRecipient = {
  __typename?: 'SurveyKeyIncidentRecipient';
  id: Scalars['String'];
  orgMembershipId: Scalars['String'];
  orgId: Scalars['String'];
  orgName: Scalars['String'];
  orgAbbreviation: Scalars['String'];
  orgLogo?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  profilePic?: Maybe<Scalars['String']>;
};

export type SurveyLevelRemindersConfig = {
  __typename?: 'SurveyLevelRemindersConfig';
  sendRemindersLevel1: Scalars['Boolean'];
  sendReminderDaysLevel1: Scalars['Int'];
  sendRemindersLevel2: Scalars['Boolean'];
  sendReminderDaysLevel2?: Maybe<Scalars['Int']>;
  sendRemindersLevel3: Scalars['Boolean'];
  sendReminderDaysLevel3?: Maybe<Scalars['Int']>;
};

export type SurveyLevelRemindersInput = {
  sendRemindersLevel1: Scalars['Boolean'];
  sendReminderDaysLevel1: Scalars['Int'];
  sendRemindersLevel2: Scalars['Boolean'];
  sendReminderDaysLevel2?: Maybe<Scalars['Int']>;
  sendRemindersLevel3: Scalars['Boolean'];
  sendReminderDaysLevel3?: Maybe<Scalars['Int']>;
};

/** The types of Survey notification */
export enum SurveyNotificationTypes {
  Initial = 'INITIAL',
  Daily = 'DAILY',
  Level_1 = 'LEVEL_1',
  Level_2 = 'LEVEL_2',
  Level_3 = 'LEVEL_3'
}

/** The survey participation statuses */
export enum SurveyParticipationStatuses {
  All = 'ALL',
  FinishedOnly = 'FINISHED_ONLY',
  IncompleteOnly = 'INCOMPLETE_ONLY'
}

/** The survey participation status filter */
export enum SurveyParticipationStatusesFilter {
  Completed = 'COMPLETED',
  NotStarted = 'NOT_STARTED',
  Incomplete = 'INCOMPLETE'
}

/** The types of questions that can be added to a survey */
export enum SurveyQuestionTypes {
  Rating_5Point = 'RATING_5_POINT',
  YesOrNo = 'YES_OR_NO',
  ShortAnswer = 'SHORT_ANSWER',
  MultipleResponses = 'MULTIPLE_RESPONSES',
  Select = 'SELECT',
  TextInput = 'TEXT_INPUT',
  NumberInput = 'NUMBER_INPUT',
  MultiPart = 'MULTI_PART',
  CheckBox = 'CHECK_BOX',
  FileUpload = 'FILE_UPLOAD',
  MultipleChoice = 'MULTIPLE_CHOICE'
}

/** The survey recipient participation statuses */
export enum SurveyRecipientParticipationStatuses {
  All = 'ALL',
  TookSurveyOnly = 'TOOK_SURVEY_ONLY',
  DidNotTakeSurveyOnly = 'DID_NOT_TAKE_SURVEY_ONLY'
}

export type SurveyReminderLevelRecipient = {
  __typename?: 'SurveyReminderLevelRecipient';
  id: Scalars['String'];
  orgMembershipId: Scalars['String'];
  orgId: Scalars['String'];
  orgName: Scalars['String'];
  orgAbbreviation: Scalars['String'];
  orgLogo?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  profilePic?: Maybe<Scalars['String']>;
  level1Recipient: Scalars['Boolean'];
  level2Recipient: Scalars['Boolean'];
  level3Recipient: Scalars['Boolean'];
};

export type SurveyReminderLevelRecipients = {
  __typename?: 'SurveyReminderLevelRecipients';
  recipients: Array<SurveyReminderLevelRecipient>;
  pagination: OffsetPagination;
};

/** The types of Survey Reminders */
export enum SurveyReminderTypes {
  None = 'NONE',
  Daily = 'DAILY',
  Levels = 'LEVELS'
}

export type SurveyRemindersConfig = {
  __typename?: 'SurveyRemindersConfig';
  type: SurveyReminderTypes;
  dailyReminders?: Maybe<SurveyDailyRemindersConfig>;
  levelReminders?: Maybe<SurveyLevelRemindersConfig>;
};

export type SurveyResponseForCompetition = {
  __typename?: 'SurveyResponseForCompetition';
  id: Scalars['String'];
  submittedBy?: Maybe<CompetitionSurveyResponseUserInfo>;
  teamId?: Maybe<Scalars['String']>;
  teamName?: Maybe<Scalars['String']>;
  completedDate?: Maybe<Scalars['Date']>;
  sections: Array<CompetitionSurveyResponseSection>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type SurveyResponsesForCompetition = {
  __typename?: 'SurveyResponsesForCompetition';
  orgInfo: CompetitionSurveyResponseOrgInfo;
  surveyInfo: CompetitionSurveyResponseSurveyInfo;
  competitionInfo: CompetitionSurveyResponseCompetitionInfo;
  responses: Array<SurveyResponseForCompetition>;
};

export type SurveySection = {
  __typename?: 'SurveySection';
  id: Scalars['String'];
  assessmentObject: AssessmentObject;
  questions: Array<SurveySectionQuestion>;
  name: Scalars['String'];
  description: Scalars['String'];
  order: Scalars['Int'];
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type SurveySectionQuestion = {
  __typename?: 'SurveySectionQuestion';
  id: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  isRequired: Scalars['Boolean'];
  canAddToKeyIncidents: Scalars['Boolean'];
  questionType: SurveyQuestionTypes;
  order: Scalars['Int'];
  options: Array<SurveySectionQuestionOption>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type SurveySectionQuestionOption = {
  __typename?: 'SurveySectionQuestionOption';
  id: Scalars['String'];
  value: Scalars['String'];
  order: Scalars['Int'];
};

/** The survey statuses */
export enum SurveyStatuses {
  Pending = 'PENDING',
  Active = 'ACTIVE',
  Disabled = 'DISABLED'
}

export type SurveySummaryNotificationSetting = {
  __typename?: 'SurveySummaryNotificationSetting';
  id: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  surveyName: Scalars['String'];
  isActive: Scalars['Boolean'];
};

export type SurveySummaryNotificationSettings = {
  __typename?: 'SurveySummaryNotificationSettings';
  settings: Array<SurveySummaryNotificationSetting>;
  pagination: OffsetPagination;
};

export type SurveySummaryRecipient = {
  __typename?: 'SurveySummaryRecipient';
  id: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive: Scalars['Boolean'];
};

export type SurveySummaryRecipients = {
  __typename?: 'SurveySummaryRecipients';
  recipients: Array<SurveySummaryRecipient>;
  pagination: OffsetPagination;
};

export type SurveySupplementalReportRecipient = {
  __typename?: 'SurveySupplementalReportRecipient';
  id: Scalars['String'];
  orgMembershipId: Scalars['String'];
  orgId: Scalars['String'];
  orgName: Scalars['String'];
  orgAbbreviation: Scalars['String'];
  orgLogo?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  profilePic?: Maybe<Scalars['String']>;
};

export type SurveyTemplate = {
  __typename?: 'SurveyTemplate';
  id: Scalars['String'];
  sport: Sports;
  status: SurveyStatuses;
  hasIncidentReport: Scalars['Boolean'];
  templateInfo: SurveyTemplateInfo;
  sections: Array<SurveyTemplateSection>;
  orgId?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

/** Filter for how to include admin survey templates for organizations */
export enum SurveyTemplateAdminFilters {
  NoAdmin = 'NO_ADMIN',
  IncludeAdmin = 'INCLUDE_ADMIN',
  AdminOnly = 'ADMIN_ONLY'
}

export type SurveyTemplateInfo = {
  __typename?: 'SurveyTemplateInfo';
  name: Scalars['String'];
  description: Scalars['String'];
  audience: SurveyAudiences;
  sendDelayMinutes: Scalars['Int'];
};

export type SurveyTemplateSection = {
  __typename?: 'SurveyTemplateSection';
  id: Scalars['String'];
  assessmentObject: AssessmentObject;
  questions: Array<SurveyTemplateSectionQuestion>;
  name: Scalars['String'];
  description: Scalars['String'];
  order: Scalars['Int'];
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type SurveyTemplateSectionQuestion = {
  __typename?: 'SurveyTemplateSectionQuestion';
  id: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  isRequired: Scalars['Boolean'];
  canAddToKeyIncidents: Scalars['Boolean'];
  questionType: SurveyQuestionTypes;
  order: Scalars['Int'];
  options: Array<SurveyTemplateSectionQuestionOption>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type SurveyTemplateSectionQuestionOption = {
  __typename?: 'SurveyTemplateSectionQuestionOption';
  id: Scalars['String'];
  value: Scalars['String'];
  order: Scalars['Int'];
};

export type SurveyTemplates = {
  __typename?: 'SurveyTemplates';
  templates: Array<SurveyTemplate>;
  pagination: OffsetPagination;
};

/** The survey types */
export enum SurveyTypes {
  Assessment = 'ASSESSMENT',
  Dynamic = 'DYNAMIC'
}

export type Surveys = {
  __typename?: 'Surveys';
  surveys: Array<Survey>;
  pagination: OffsetPagination;
};

export type Team = {
  __typename?: 'Team';
  id: Scalars['String'];
  name: Scalars['String'];
  abbreviation?: Maybe<Scalars['String']>;
  sport: Sports;
  city: Scalars['String'];
  stateAbbreviation?: Maybe<Scalars['String']>;
  countryCode: CountryCodes;
  timeZone: Scalars['String'];
  classification: TeamClassifications;
  gender: TeamGenders;
  website?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  parentOrganizationName?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  ageGroup?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type TeamAlias = {
  __typename?: 'TeamAlias';
  id: Scalars['String'];
  teamId: Scalars['String'];
  alias: Scalars['String'];
};

export type TeamAliases = {
  __typename?: 'TeamAliases';
  teamId: Scalars['String'];
  aliases: Array<TeamAlias>;
  pagination: OffsetPagination;
};

/** The team classifications */
export enum TeamClassifications {
  Pro = 'PRO',
  SemiPro = 'SEMI_PRO',
  NonPro = 'NON_PRO',
  Amateur = 'AMATEUR',
  Collegiate = 'COLLEGIATE',
  Youth = 'YOUTH',
  PrePro = 'PRE_PRO',
  Academy = 'ACADEMY'
}

export type TeamCoaches = {
  __typename?: 'TeamCoaches';
  teamId: Scalars['String'];
  coaches: Array<Coach>;
  pagination: OffsetPagination;
};

export type TeamContact = {
  __typename?: 'TeamContact';
  id: Scalars['String'];
  isActive: Scalars['Boolean'];
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type TeamContacts = {
  __typename?: 'TeamContacts';
  teamId: Scalars['String'];
  teamName: Scalars['String'];
  contacts: Array<TeamContact>;
  pagination: OffsetPagination;
};

/** The team genders */
export enum TeamGenders {
  Male = 'MALE',
  Female = 'FEMALE',
  Coed = 'COED'
}

/** The TeamKit Contact Type */
export enum TeamKitContactType {
  League = 'LEAGUE',
  HomeTeam = 'HOME_TEAM',
  AwayTeam = 'AWAY_TEAM'
}

/** The TeamKit Recipient Type */
export enum TeamKitRecipientType {
  League = 'LEAGUE',
  HomeTeam = 'HOME_TEAM',
  AwayTeam = 'AWAY_TEAM'
}

/** The TeamKit Role Type */
export enum TeamKitRoleType {
  Team = 'TEAM',
  Official = 'OFFICIAL'
}

export type TeamKitSeasonApparel = {
  __typename?: 'TeamKitSeasonApparel';
  seasonId: Scalars['String'];
  teamId?: Maybe<Scalars['String']>;
  teamName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  color: Scalars['String'];
  imageUrl?: Maybe<Scalars['String']>;
  kitApparel: SeasonTeamKitApparelDefinition;
  kitRole: SeasonTeamKitRole;
};

export type TeamKitSeasonApparels = {
  __typename?: 'TeamKitSeasonApparels';
  seasonId: Scalars['String'];
  teamId?: Maybe<Scalars['String']>;
  apparels: Array<TeamKitSeasonApparel>;
  pagination: OffsetPagination;
};

export type TeamKitSeasonKit = {
  __typename?: 'TeamKitSeasonKit';
  seasonId: Scalars['String'];
  teamId?: Maybe<Scalars['String']>;
  teamName?: Maybe<Scalars['String']>;
  roleId: Scalars['String'];
  role: Scalars['String'];
  definitionId: Scalars['String'];
  definition: Scalars['String'];
  apparel: TeamKitSeasonApparel;
  id: Scalars['String'];
  type: TeamKitType;
};

export type TeamKitSeasonKits = {
  __typename?: 'TeamKitSeasonKits';
  seasonId: Scalars['String'];
  kits: Array<TeamKitSeasonKit>;
  pagination: OffsetPagination;
};

export type TeamKitSeasonOrgApparelByRoleSetupSummary = {
  __typename?: 'TeamKitSeasonOrgApparelByRoleSetupSummary';
  seasonId: Scalars['String'];
  seasonName: Scalars['String'];
  roleId?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  roleOrder?: Maybe<Scalars['Int']>;
  definitionId?: Maybe<Scalars['String']>;
  definition?: Maybe<Scalars['String']>;
  definitionOrder?: Maybe<Scalars['Int']>;
  apparelCount: Scalars['Int'];
};

export type TeamKitSeasonOrgSetupSummary = {
  __typename?: 'TeamKitSeasonOrgSetupSummary';
  seasonId: Scalars['String'];
  seasonName: Scalars['String'];
  leagueId: Scalars['String'];
  leagueName: Scalars['String'];
  orgId: Scalars['String'];
  orgName: Scalars['String'];
  orgLogo: Scalars['String'];
  orgRecipientsCount: Scalars['Int'];
  orgContactsCount: Scalars['Int'];
  orgContactOptionsCount: Scalars['Int'];
  orgContactOptionsTarget: Scalars['Int'];
  orgApparelSetCount: Scalars['Int'];
  orgTotalApparelSetTarget: Scalars['Int'];
  apparelsForRoles?: Maybe<Array<TeamKitSeasonOrgApparelByRoleSetupSummary>>;
};

/** The TeamKit season status */
export enum TeamKitSeasonStatus {
  Pending = 'PENDING',
  Active = 'ACTIVE',
  Disabled = 'DISABLED'
}

export type TeamKitSeasonTeamApparelByRoleSetupSummary = {
  __typename?: 'TeamKitSeasonTeamApparelByRoleSetupSummary';
  seasonId: Scalars['String'];
  seasonName: Scalars['String'];
  teamId: Scalars['String'];
  teamName: Scalars['String'];
  teamLogo: Scalars['String'];
  roleId?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  roleOrder?: Maybe<Scalars['Int']>;
  definitionId?: Maybe<Scalars['String']>;
  definition?: Maybe<Scalars['String']>;
  definitionOrder?: Maybe<Scalars['Int']>;
  apparelCount: Scalars['Int'];
};

export type TeamKitSeasonTeamSetupSummaries = {
  __typename?: 'TeamKitSeasonTeamSetupSummaries';
  seasonId: Scalars['String'];
  summaries: Array<TeamKitSeasonTeamSetupSummary>;
  pagination: OffsetPagination;
};

export type TeamKitSeasonTeamSetupSummary = {
  __typename?: 'TeamKitSeasonTeamSetupSummary';
  seasonId: Scalars['String'];
  seasonName: Scalars['String'];
  teamId: Scalars['String'];
  teamName: Scalars['String'];
  teamLogo: Scalars['String'];
  teamRecipientsCount: Scalars['Int'];
  teamContactsCount: Scalars['Int'];
  teamHomeContactOptionsCount: Scalars['Int'];
  teamHomeContactOptionsTarget: Scalars['Int'];
  teamAwayContactOptionsCount: Scalars['Int'];
  teamAwayContactOptionsTarget: Scalars['Int'];
  teamBroadcastOptionsCount: Scalars['Int'];
  teamApparelSetCount: Scalars['Int'];
  teamTotalApparelSetTarget: Scalars['Int'];
  apparelsForRoles?: Maybe<Array<TeamKitSeasonTeamApparelByRoleSetupSummary>>;
};

export type TeamKitTeamRecipient = {
  __typename?: 'TeamKitTeamRecipient';
  id: Scalars['String'];
  teamId: Scalars['String'];
  teamName: Scalars['String'];
  teamAbbreviation?: Maybe<Scalars['String']>;
  teamLogo?: Maybe<Scalars['String']>;
  teamCity: Scalars['String'];
  isActive: Scalars['Boolean'];
  role?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  profilePic?: Maybe<Scalars['String']>;
  userStatus: UserStatuses;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type TeamKitTeamRecipients = {
  __typename?: 'TeamKitTeamRecipients';
  teamId: Scalars['String'];
  recipients: Array<TeamKitTeamRecipient>;
  pagination: OffsetPagination;
};

/** The TeamKit Type */
export enum TeamKitType {
  Primary = 'PRIMARY',
  Secondary = 'SECONDARY'
}

export type Teams = {
  __typename?: 'Teams';
  teams: Array<Team>;
  pagination: OffsetPagination;
};

export type UpdateAdditionalOptionForCompetitionIntegrationInput = {
  integrationId: Scalars['String'];
  additionalOptionId: Scalars['String'];
  name: Scalars['String'];
  isRequired: Scalars['Boolean'];
};

export type UpdateAdditionalOptionForDynamicSurveyIntegrationInput = {
  integrationId: Scalars['String'];
  additionalOptionId: Scalars['String'];
  name: Scalars['String'];
  value: Scalars['String'];
};

export type UpdateAssessmentObjectInput = {
  id: Scalars['String'];
  type: AssessmentObjectTypes;
  name: Scalars['String'];
  classification?: Maybe<AssessmentObjectClassifications>;
};

export type UpdateCompetitionInput = {
  competitionId: Scalars['String'];
  seasonId: Scalars['String'];
  homeTeamId: Scalars['String'];
  awayTeamId: Scalars['String'];
  startTime: Scalars['Date'];
  timeZone: Scalars['String'];
  externalId?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  homeTeamScore?: Maybe<Scalars['Int']>;
  awayTeamScore?: Maybe<Scalars['Int']>;
  homeTeamTieBreakerScore?: Maybe<Scalars['Int']>;
  awayTeamTieBreakerScore?: Maybe<Scalars['Int']>;
};

export type UpdateCompetitionIntegrationEnabledForSeasonInput = {
  seasonId: Scalars['String'];
  enabled: Scalars['Boolean'];
};

export type UpdateCompetitionIntegrationInput = {
  id: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  status: CompetitionIntegrationStatuses;
  queueName: Scalars['String'];
};

export type UpdateCompetitionOfficialInput = {
  competitionOfficialId: Scalars['String'];
  officialId: Scalars['String'];
};

export type UpdateCompetitionScoreInput = {
  competitionId: Scalars['String'];
  homeTeamScore?: Maybe<Scalars['Int']>;
  awayTeamScore?: Maybe<Scalars['Int']>;
  homeTeamTieBreakerScore?: Maybe<Scalars['Int']>;
  awayTeamTieBreakerScore?: Maybe<Scalars['Int']>;
};

export type UpdateCompetitionStatValueInput = {
  competitionStatId: Scalars['String'];
  valueString?: Maybe<Scalars['String']>;
  valueNumeric?: Maybe<Scalars['Float']>;
  valueDate?: Maybe<Scalars['Date']>;
};

export type UpdateCompetitionSurveyCompetitionScoreInput = {
  competitionSurveyId: Scalars['String'];
  homeTeamScore?: Maybe<Scalars['Int']>;
  awayTeamScore?: Maybe<Scalars['Int']>;
  homeTeamTieBreakerScore?: Maybe<Scalars['Int']>;
  awayTeamTieBreakerScore?: Maybe<Scalars['Int']>;
};

export type UpdateCompetitionSurveyCompetitionScoreWithTokenInput = {
  surveyToken: Scalars['String'];
  homeTeamScore?: Maybe<Scalars['Int']>;
  awayTeamScore?: Maybe<Scalars['Int']>;
  homeTeamTieBreakerScore?: Maybe<Scalars['Int']>;
  awayTeamTieBreakerScore?: Maybe<Scalars['Int']>;
};

export type UpdateCompetitionSurveyMultipleChoiceResponseInput = {
  competitionSurveyId: Scalars['String'];
  questionId: Scalars['String'];
  selectedOptionId: Scalars['String'];
};

export type UpdateCompetitionSurveyMultipleChoiceResponseWithTokenInput = {
  surveyToken: Scalars['String'];
  questionId: Scalars['String'];
  selectedOptionId: Scalars['String'];
};

export type UpdateCompetitionSurveyNumberResponseInput = {
  competitionSurveyId: Scalars['String'];
  questionId: Scalars['String'];
  response: Scalars['Float'];
};

export type UpdateCompetitionSurveyNumberResponseWithTokenInput = {
  surveyToken: Scalars['String'];
  questionId: Scalars['String'];
  response: Scalars['Float'];
};

export type UpdateCompetitionSurveyShortAnswerResponseInput = {
  competitionSurveyId: Scalars['String'];
  questionId: Scalars['String'];
  response: Scalars['String'];
};

export type UpdateCompetitionSurveyShortAnswerResponseWithTokenInput = {
  surveyToken: Scalars['String'];
  questionId: Scalars['String'];
  response: Scalars['String'];
};

export type UpdateCompetitionSurveyTextResponseInput = {
  competitionSurveyId: Scalars['String'];
  questionId: Scalars['String'];
  response: Scalars['String'];
};

export type UpdateCompetitionSurveyTextResponseWithTokenInput = {
  surveyToken: Scalars['String'];
  questionId: Scalars['String'];
  response: Scalars['String'];
};

export type UpdateCompetitionTeamKitCompetitionInfoInput = {
  competitionTeamKitId: Scalars['String'];
  competitionInfoId: Scalars['String'];
  value: Scalars['String'];
};

export type UpdateDynamicCompetitionSurveyBooleanResponseInput = {
  competitionSurveyId: Scalars['String'];
  questionId: Scalars['String'];
  response: Scalars['Boolean'];
};

export type UpdateDynamicCompetitionSurveyBooleanResponseWithTokenInput = {
  surveyToken: Scalars['String'];
  questionId: Scalars['String'];
  response: Scalars['Boolean'];
};

export type UpdateDynamicCompetitionSurveyCompetitionScoreInput = {
  competitionSurveyId: Scalars['String'];
  homeTeamScore?: Maybe<Scalars['Int']>;
  awayTeamScore?: Maybe<Scalars['Int']>;
  homeTeamTieBreakerScore?: Maybe<Scalars['Int']>;
  awayTeamTieBreakerScore?: Maybe<Scalars['Int']>;
};

export type UpdateDynamicCompetitionSurveyCompetitionScoreWithTokenInput = {
  surveyToken: Scalars['String'];
  homeTeamScore?: Maybe<Scalars['Int']>;
  awayTeamScore?: Maybe<Scalars['Int']>;
  homeTeamTieBreakerScore?: Maybe<Scalars['Int']>;
  awayTeamTieBreakerScore?: Maybe<Scalars['Int']>;
};

export type UpdateDynamicCompetitionSurveyMultiPartQuestionInput = {
  competitionSurveyId: Scalars['String'];
  questionId: Scalars['String'];
  multipleResponses?: Maybe<Array<UpdateDynamicCompetitionSurveyMultiPartQuestionMultipleChoiceResponseInput>>;
  shortAnswerResponses?: Maybe<Array<UpdateDynamicCompetitionSurveyMultiPartQuestionShortAnswerResponseInput>>;
  textResponses?: Maybe<Array<UpdateDynamicCompetitionSurveyMultiPartQuestionTextResponseInput>>;
  numberResponses?: Maybe<Array<UpdateDynamicCompetitionSurveyMultiPartQuestionNumberResponseInput>>;
};

export type UpdateDynamicCompetitionSurveyMultiPartQuestionMultipleChoiceResponseInput = {
  subQuestionId: Scalars['String'];
  selectedOptionId: Scalars['String'];
};

export type UpdateDynamicCompetitionSurveyMultiPartQuestionNumberResponseInput = {
  subQuestionId: Scalars['String'];
  response: Scalars['Float'];
};

export type UpdateDynamicCompetitionSurveyMultiPartQuestionShortAnswerResponseInput = {
  subQuestionId: Scalars['String'];
  response: Scalars['String'];
};

export type UpdateDynamicCompetitionSurveyMultiPartQuestionTextResponseInput = {
  subQuestionId: Scalars['String'];
  response: Scalars['String'];
};

export type UpdateDynamicCompetitionSurveyMultiPartQuestionWithTokenInput = {
  surveyToken: Scalars['String'];
  questionId: Scalars['String'];
  multipleResponses?: Maybe<Array<UpdateDynamicCompetitionSurveyMultiPartQuestionMultipleChoiceResponseInput>>;
  shortAnswerResponses?: Maybe<Array<UpdateDynamicCompetitionSurveyMultiPartQuestionShortAnswerResponseInput>>;
  textResponses?: Maybe<Array<UpdateDynamicCompetitionSurveyMultiPartQuestionTextResponseInput>>;
  numberResponses?: Maybe<Array<UpdateDynamicCompetitionSurveyMultiPartQuestionNumberResponseInput>>;
};

export type UpdateDynamicCompetitionSurveyMultipleChoiceResponseInput = {
  competitionSurveyId: Scalars['String'];
  questionId: Scalars['String'];
  selectedOptionId: Scalars['String'];
};

export type UpdateDynamicCompetitionSurveyMultipleChoiceResponseWithTokenInput = {
  surveyToken: Scalars['String'];
  questionId: Scalars['String'];
  selectedOptionId: Scalars['String'];
};

export type UpdateDynamicCompetitionSurveyNumberResponseInput = {
  competitionSurveyId: Scalars['String'];
  questionId: Scalars['String'];
  response: Scalars['Float'];
};

export type UpdateDynamicCompetitionSurveyNumberResponseWithTokenInput = {
  surveyToken: Scalars['String'];
  questionId: Scalars['String'];
  response: Scalars['Float'];
};

export type UpdateDynamicCompetitionSurveyShortAnswerResponseInput = {
  competitionSurveyId: Scalars['String'];
  questionId: Scalars['String'];
  response: Scalars['String'];
};

export type UpdateDynamicCompetitionSurveyShortAnswerResponseWithTokenInput = {
  surveyToken: Scalars['String'];
  questionId: Scalars['String'];
  response: Scalars['String'];
};

export type UpdateDynamicCompetitionSurveySubQuestionBooleanResponseInput = {
  competitionSurveyId: Scalars['String'];
  questionId: Scalars['String'];
  subQuestionId: Scalars['String'];
  response: Scalars['Boolean'];
};

export type UpdateDynamicCompetitionSurveySubQuestionBooleanResponseWithTokenInput = {
  surveyToken: Scalars['String'];
  questionId: Scalars['String'];
  subQuestionId: Scalars['String'];
  response: Scalars['Boolean'];
};

export type UpdateDynamicCompetitionSurveySubQuestionMultipleChoiceResponseInput = {
  competitionSurveyId: Scalars['String'];
  questionId: Scalars['String'];
  subQuestionId: Scalars['String'];
  selectedOptionId: Scalars['String'];
};

export type UpdateDynamicCompetitionSurveySubQuestionMultipleChoiceResponseWithTokenInput = {
  surveyToken: Scalars['String'];
  questionId: Scalars['String'];
  subQuestionId: Scalars['String'];
  selectedOptionId: Scalars['String'];
};

export type UpdateDynamicCompetitionSurveySubQuestionNumberResponseInput = {
  competitionSurveyId: Scalars['String'];
  questionId: Scalars['String'];
  subQuestionId: Scalars['String'];
  response: Scalars['Float'];
};

export type UpdateDynamicCompetitionSurveySubQuestionNumberResponseWithTokenInput = {
  surveyToken: Scalars['String'];
  questionId: Scalars['String'];
  subQuestionId: Scalars['String'];
  response: Scalars['Float'];
};

export type UpdateDynamicCompetitionSurveySubQuestionShortAnswerResponseInput = {
  competitionSurveyId: Scalars['String'];
  questionId: Scalars['String'];
  subQuestionId: Scalars['String'];
  response: Scalars['String'];
};

export type UpdateDynamicCompetitionSurveySubQuestionShortAnswerResponseWithTokenInput = {
  surveyToken: Scalars['String'];
  questionId: Scalars['String'];
  subQuestionId: Scalars['String'];
  response: Scalars['String'];
};

export type UpdateDynamicCompetitionSurveySubQuestionTextResponseInput = {
  competitionSurveyId: Scalars['String'];
  questionId: Scalars['String'];
  subQuestionId: Scalars['String'];
  response: Scalars['String'];
};

export type UpdateDynamicCompetitionSurveySubQuestionTextResponseWithTokenInput = {
  surveyToken: Scalars['String'];
  questionId: Scalars['String'];
  subQuestionId: Scalars['String'];
  response: Scalars['String'];
};

export type UpdateDynamicCompetitionSurveyTextResponseInput = {
  competitionSurveyId: Scalars['String'];
  questionId: Scalars['String'];
  response: Scalars['String'];
};

export type UpdateDynamicCompetitionSurveyTextResponseWithTokenInput = {
  surveyToken: Scalars['String'];
  questionId: Scalars['String'];
  response: Scalars['String'];
};

export type UpdateDynamicSurveyInfoInput = {
  surveyId: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  audience: SurveyAudiences;
  sendDelayMinutes: Scalars['Int'];
};

export type UpdateDynamicSurveyIntegrationInput = {
  id: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  status: DynamicSurveyIntegrationStatuses;
  queueName: Scalars['String'];
};

export type UpdateDynamicSurveyReminderLevelReportRecipientInput = {
  recipientId: Scalars['String'];
  level1Recipient: Scalars['Boolean'];
  level2Recipient: Scalars['Boolean'];
  level3Recipient: Scalars['Boolean'];
};

export type UpdateDynamicSurveyRemindersInput = {
  surveyId: Scalars['String'];
  type: SurveyReminderTypes;
  dailyReminders?: Maybe<SurveyDailyRemindersInput>;
  levelReminders?: Maybe<SurveyLevelRemindersInput>;
};

export type UpdateDynamicSurveySectionInput = {
  sectionId: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  allowAdditions: Scalars['Boolean'];
  order: Scalars['Int'];
};

export type UpdateDynamicSurveySectionQuestionInput = {
  questionId: Scalars['String'];
  assessmentObjectId: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  isRequired: Scalars['Boolean'];
  allowCorrections: Scalars['Boolean'];
  order: Scalars['Int'];
};

export type UpdateDynamicSurveySectionQuestionOptionInput = {
  optionId: Scalars['String'];
  value: Scalars['String'];
  order: Scalars['Int'];
};

export type UpdateDynamicSurveySectionSubQuestionInput = {
  subQuestionId: Scalars['String'];
  name: Scalars['String'];
  questionText: Scalars['String'];
  isRequired: Scalars['Boolean'];
  order: Scalars['Int'];
};

export type UpdateDynamicSurveySectionSubQuestionOptionInput = {
  optionId: Scalars['String'];
  value: Scalars['String'];
  order: Scalars['Int'];
};

export type UpdateKeyIncidentVideoIntegrationInput = {
  id: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  status: KeyIncidentVideoIntegrationStatuses;
  queueName: Scalars['String'];
  enableRetry: Scalars['Boolean'];
  maxRetries?: Maybe<Scalars['Int']>;
  retryIntervalMinutes?: Maybe<Scalars['Int']>;
};

export type UpdateLeagueInput = {
  id: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  abbreviation?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
};

export type UpdateMediaMembersEnabledForSeasonInput = {
  seasonId: Scalars['String'];
  enabled: Scalars['Boolean'];
};

export type UpdateMediaOrganizationInput = {
  id: Scalars['String'];
  name: Scalars['String'];
  abbreviation?: Maybe<Scalars['String']>;
  site?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
};

export type UpdateMyNotificationSettingsInput = {
  enableSurveyNotifications: Scalars['Boolean'];
  enableIncidentReports: Scalars['Boolean'];
  enableSupplementalReports: Scalars['Boolean'];
  enableTeamKits: Scalars['Boolean'];
};

export type UpdateMyProfileInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type UpdateMySurveySummaryRecipientNotificationSettingInput = {
  recipientId: Scalars['String'];
  isActive: Scalars['Boolean'];
};

export type UpdateOrganizationContactInput = {
  organizationId: Scalars['String'];
  contactId: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive: Scalars['Boolean'];
};

export type UpdateOrganizationInput = {
  id: Scalars['String'];
  name: Scalars['String'];
  abbreviation: Scalars['String'];
  site?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
};

export type UpdateOrganizationStatusInput = {
  organizationId: Scalars['String'];
  organizationStatus: OrganizationStatuses;
};

export type UpdatePeriodInput = {
  id: Scalars['String'];
  name: Scalars['String'];
};

export type UpdateReportForSupplementalReportInput = {
  supplementalReportId: Scalars['String'];
  report: Scalars['String'];
};

export type UpdateSeasonCompetitionIntegrationInput = {
  seasonIntegrationId: Scalars['String'];
  fromDateMinutes?: Maybe<Scalars['Int']>;
  toDateMinutes?: Maybe<Scalars['Int']>;
  externalIdOrg?: Maybe<Scalars['String']>;
};

export type UpdateSeasonCompetitionIntegrationOptionInput = {
  optionId: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export type UpdateSeasonDivisionAliasInput = {
  id: Scalars['String'];
  alias: Scalars['String'];
};

export type UpdateSeasonDivisionInput = {
  id: Scalars['String'];
  name: Scalars['String'];
};

export type UpdateSeasonInput = {
  id: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
};

export type UpdateSeasonMediaMembersConfigInput = {
  orgId: Scalars['String'];
  seasonId: Scalars['String'];
  seasonMediaMembersConfigId: Scalars['String'];
  allowIncidentReports: Scalars['Boolean'];
  allowIncidentReportsCutoffDays?: Maybe<Scalars['Int']>;
  allowIncidentReportsDelayMinutes?: Maybe<Scalars['Int']>;
};

export type UpdateSeasonTeamKitApparelDefinitionInput = {
  seasonId: Scalars['String'];
  definitionId: Scalars['String'];
  value: Scalars['String'];
};

export type UpdateSeasonTeamKitContactDefinitionInput = {
  seasonId: Scalars['String'];
  type: TeamKitContactType;
  contactDefinitionId: Scalars['String'];
  value: Scalars['String'];
};

export type UpdateSeasonTeamKitInput = {
  orgId: Scalars['String'];
  seasonId: Scalars['String'];
  seasonTeamKitId: Scalars['String'];
  sendPreCompetitionDays: Scalars['Int'];
  sendReminders: Scalars['Boolean'];
  sendReminderDays: Scalars['Int'];
  sendReminderCutoffDays: Scalars['Int'];
};

export type UpdateSelectionForCompetitionTeamKitApparelInput = {
  competitionTeamKitApparelId: Scalars['String'];
  selectedApparelId: Scalars['String'];
};

export type UpdateSelectionForCompetitionTeamKitContactInput = {
  competitionTeamKitContactId: Scalars['String'];
  selectedOptionId: Scalars['String'];
};

export type UpdateSurveyInfoInput = {
  surveyId: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  audience: SurveyAudiences;
  sendDelayMinutes: Scalars['Int'];
};

export type UpdateSurveyReminderLevelReportRecipientInput = {
  recipientId: Scalars['String'];
  level1Recipient: Scalars['Boolean'];
  level2Recipient: Scalars['Boolean'];
  level3Recipient: Scalars['Boolean'];
};

export type UpdateSurveyRemindersInput = {
  surveyId: Scalars['String'];
  type: SurveyReminderTypes;
  dailyReminders?: Maybe<SurveyDailyRemindersInput>;
  levelReminders?: Maybe<SurveyLevelRemindersInput>;
};

export type UpdateSurveySectionInput = {
  sectionId: Scalars['String'];
  assessmentObjectId: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  order: Scalars['Int'];
};

export type UpdateSurveySectionQuestionInput = {
  questionId: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  isRequired: Scalars['Boolean'];
  order: Scalars['Int'];
};

export type UpdateSurveySectionQuestionOptionInput = {
  optionId: Scalars['String'];
  value: Scalars['String'];
  order: Scalars['Int'];
};

export type UpdateSurveySummaryRecipientNotificationSettingByTokenInput = {
  summaryNotificationSettingsToken: Scalars['String'];
  isActive: Scalars['Boolean'];
};

export type UpdateSurveyTemplateInfoInput = {
  id: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  audience: SurveyAudiences;
  sendDelayMinutes: Scalars['Int'];
};

export type UpdateSurveyTemplateSectionInput = {
  sectionId: Scalars['String'];
  assessmentObjectId: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  order: Scalars['Int'];
};

export type UpdateSurveyTemplateSectionQuestionInput = {
  questionId: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  isRequired: Scalars['Boolean'];
  order: Scalars['Int'];
};

export type UpdateSurveyTemplateSectionQuestionOptionInput = {
  optionId: Scalars['String'];
  value: Scalars['String'];
  order: Scalars['Int'];
};

export type UpdateTeamAliasInput = {
  id: Scalars['String'];
  alias: Scalars['String'];
};

export type UpdateTeamContactForSeasonInput = {
  teamId: Scalars['String'];
  seasonId: Scalars['String'];
  organizationId: Scalars['String'];
  contactId: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive: Scalars['Boolean'];
};

export type UpdateTeamForSeasonInput = {
  organizationId: Scalars['String'];
  seasonId: Scalars['String'];
  teamId: Scalars['String'];
  name: Scalars['String'];
  abbreviation?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  stateAbbreviation?: Maybe<Scalars['String']>;
  countryCode: CountryCodes;
  timeZone: Scalars['String'];
  classification: TeamClassifications;
  gender: TeamGenders;
  parentOrganizationName?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  ageGroup?: Maybe<Scalars['String']>;
};

export type UpdateTeamKitBroadcastOptionForSeasonInput = {
  seasonId: Scalars['String'];
  teamId?: Maybe<Scalars['String']>;
  optionId: Scalars['String'];
  value: Scalars['String'];
  isActive: Scalars['Boolean'];
};

export type UpdateTeamKitEnabledForSeasonInput = {
  seasonId: Scalars['String'];
  enabled: Scalars['Boolean'];
};

export type UpdateTeamKitRoleForSeasonInput = {
  seasonId: Scalars['String'];
  kitRoleId: Scalars['String'];
  value: Scalars['String'];
  type: TeamKitRoleType;
};

export type UpdateTeamKitSeasonApparelImageInput = {
  seasonId: Scalars['String'];
  kitRoleId: Scalars['String'];
  kitApparelId: Scalars['String'];
  teamId?: Maybe<Scalars['String']>;
  seasonApparelId: Scalars['String'];
};

export type UpdateTeamKitSeasonApparelInput = {
  seasonId: Scalars['String'];
  kitRoleId: Scalars['String'];
  kitApparelId: Scalars['String'];
  teamId?: Maybe<Scalars['String']>;
  seasonApparelId: Scalars['String'];
  name: Scalars['String'];
  color: Scalars['String'];
  isActive: Scalars['Boolean'];
};

export type UpdateTeamKitSeasonKitInput = {
  seasonId: Scalars['String'];
  kitRoleId: Scalars['String'];
  definitionId: Scalars['String'];
  apparelId: Scalars['String'];
  teamId?: Maybe<Scalars['String']>;
  seasonKitId: Scalars['String'];
  type: TeamKitType;
};

export type UpdateTeamKitTeamRecipientForSeasonInput = {
  teamId: Scalars['String'];
  seasonId: Scalars['String'];
  organizationId: Scalars['String'];
  recipientId: Scalars['String'];
  role?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
};

export type UpdateUserNotificationSettingsInput = {
  notificationSettingsToken: Scalars['String'];
  enableSurveyNotifications: Scalars['Boolean'];
  enableIncidentReports: Scalars['Boolean'];
  enableSupplementalReports: Scalars['Boolean'];
  enableTeamKits: Scalars['Boolean'];
};

export type UpdateUserOrganizationMembershipInput = {
  organizationId: Scalars['String'];
  userId: Scalars['String'];
  roles: Array<OrganizationUserRoles>;
  permissions: Array<OrganizationUserPermissions>;
  title?: Maybe<Scalars['String']>;
};


export type User = {
  __typename?: 'User';
  id: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  status: UserStatuses;
  isAdmin: Scalars['Boolean'];
  organizationMemberships: Array<OrganizationMembership>;
  profilePic?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type UserAlert = {
  __typename?: 'UserAlert';
  id: Scalars['String'];
  userId: Scalars['String'];
  title: Scalars['String'];
  message: Scalars['String'];
  route?: Maybe<Scalars['String']>;
  routeText?: Maybe<Scalars['String']>;
  component?: Maybe<UserAlertComponents>;
  read: Scalars['Boolean'];
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

/** The user alert components */
export enum UserAlertComponents {
  Account = 'ACCOUNT',
  Surveys = 'SURVEYS',
  KeyIncidents = 'KEY_INCIDENTS',
  SupplementalReports = 'SUPPLEMENTAL_REPORTS',
  TeamKits = 'TEAM_KITS'
}

export type UserAlertNotification = {
  __typename?: 'UserAlertNotification';
  id: Scalars['String'];
  userId: Scalars['String'];
  title: Scalars['String'];
  message: Scalars['String'];
  route?: Maybe<Scalars['String']>;
  routeText?: Maybe<Scalars['String']>;
  component?: Maybe<UserAlertComponents>;
  date: Scalars['Date'];
};

export type UserAlerts = {
  __typename?: 'UserAlerts';
  alerts: Array<UserAlert>;
  pagination: OffsetPagination;
};

export type UserConfirmationInput = {
  confirmationToken: Scalars['String'];
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
};

export type UserNotificationSetting = {
  __typename?: 'UserNotificationSetting';
  id: Scalars['String'];
  setting: NotificationSettings;
  enabled: Scalars['Boolean'];
};

export type UserNotificationSettings = {
  __typename?: 'UserNotificationSettings';
  userId: Scalars['String'];
  settings: Array<UserNotificationSetting>;
};

export type UserOrganizationContext = {
  __typename?: 'UserOrganizationContext';
  orgFeatures: Array<OrganizationFeature>;
  league?: Maybe<League>;
  season?: Maybe<Season>;
};

/** The user statuses */
export enum UserStatuses {
  Created = 'CREATED',
  Invited = 'INVITED',
  Active = 'ACTIVE',
  Locked = 'LOCKED',
  Disabled = 'DISABLED'
}

export type UpdateDynamicSurveySectionSupplementalReportInput = {
  sectionId: Scalars['String'];
  requireSupplementalReport: Scalars['Boolean'];
  supplementalReportType?: Maybe<SupplementalReportTypes>;
};

export type CreateAssessmentObjectMutationVariables = Exact<{
  sport: Sports;
  type: AssessmentObjectTypes;
  name: Scalars['String'];
  classification?: Maybe<AssessmentObjectClassifications>;
}>;


export type CreateAssessmentObjectMutation = (
  { __typename?: 'Mutation' }
  & { createAssessmentObject: (
    { __typename?: 'AssessmentObject' }
    & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name'>
  ) }
);

export type UpdateAssessmentObjectMutationVariables = Exact<{
  id: Scalars['String'];
  type: AssessmentObjectTypes;
  name: Scalars['String'];
  classification?: Maybe<AssessmentObjectClassifications>;
}>;


export type UpdateAssessmentObjectMutation = (
  { __typename?: 'Mutation' }
  & { updateAssessmentObject: (
    { __typename?: 'AssessmentObject' }
    & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name'>
  ) }
);

export type DeleteAssessmentObjectMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteAssessmentObjectMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteAssessmentObject'>
);

export type ListOrganizationsQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  sport?: Maybe<Sports>;
  status?: Maybe<OrganizationStatuses>;
  name?: Maybe<Scalars['String']>;
  includeDeleted?: Maybe<Scalars['Boolean']>;
}>;


export type ListOrganizationsQuery = (
  { __typename?: 'Query' }
  & { listOrganizations: (
    { __typename?: 'Organizations' }
    & { organizations: Array<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'name' | 'sport' | 'status' | 'abbreviation' | 'site' | 'logo' | 'createdAt' | 'updatedAt'>
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type CreateOrganizationMutationVariables = Exact<{
  sport: Sports;
  name: Scalars['String'];
  abbreviation: Scalars['String'];
  site?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
}>;


export type CreateOrganizationMutation = (
  { __typename?: 'Mutation' }
  & { createOrganization: (
    { __typename?: 'Organization' }
    & Pick<Organization, 'id' | 'name' | 'sport' | 'status' | 'abbreviation' | 'site' | 'logo' | 'createdAt' | 'updatedAt'>
  ) }
);

export type DeleteOrganizationMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteOrganizationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteOrganization'>
);

export type SetFeatureForOrganizationMutationVariables = Exact<{
  organizationId: Scalars['String'];
  feature: OrgFeatures;
  enabled: Scalars['Boolean'];
}>;


export type SetFeatureForOrganizationMutation = (
  { __typename?: 'Mutation' }
  & { setFeatureForOrganization: (
    { __typename?: 'OrganizationFeature' }
    & Pick<OrganizationFeature, 'id' | 'feature' | 'active' | 'createdAt' | 'updatedAt'>
  ) }
);

export type AddFeatureForOrganizationMutationVariables = Exact<{
  organizationId: Scalars['String'];
  feature: OrgFeatures;
  enabled: Scalars['Boolean'];
}>;


export type AddFeatureForOrganizationMutation = (
  { __typename?: 'Mutation' }
  & { addFeatureForOrganization: (
    { __typename?: 'OrganizationFeature' }
    & Pick<OrganizationFeature, 'id' | 'feature' | 'active' | 'createdAt' | 'updatedAt'>
  ) }
);

export type CreatePeriodMutationVariables = Exact<{
  sport: Sports;
  name: Scalars['String'];
}>;


export type CreatePeriodMutation = (
  { __typename?: 'Mutation' }
  & { createPeriod: (
    { __typename?: 'Period' }
    & Pick<Period, 'id' | 'sport' | 'name' | 'createdAt' | 'updatedAt'>
  ) }
);

export type UpdatePeriodMutationVariables = Exact<{
  id: Scalars['String'];
  name: Scalars['String'];
}>;


export type UpdatePeriodMutation = (
  { __typename?: 'Mutation' }
  & { updatePeriod: (
    { __typename?: 'Period' }
    & Pick<Period, 'id' | 'sport' | 'name' | 'createdAt' | 'updatedAt'>
  ) }
);

export type DeletePeriodMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeletePeriodMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletePeriod'>
);

export type ListAdminUsersQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}>;


export type ListAdminUsersQuery = (
  { __typename?: 'Query' }
  & { listAdminUsers: (
    { __typename?: 'AdminUsersResponse' }
    & { adminUsers: Array<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'firstName' | 'lastName' | 'isAdmin' | 'status' | 'createdAt' | 'updatedAt'>
      & { organizationMemberships: Array<(
        { __typename?: 'OrganizationMembership' }
        & Pick<OrganizationMembership, 'id' | 'title' | 'orgId' | 'name' | 'abbreviation' | 'logo' | 'roles' | 'permissions'>
        & { leagueMemberships: Array<(
          { __typename?: 'OrganizationLeagueMembership' }
          & Pick<OrganizationLeagueMembership, 'leagueId' | 'name' | 'abbreviation' | 'logo'>
        )> }
      )> }
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type InviteAdminUserMutationVariables = Exact<{
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
}>;


export type InviteAdminUserMutation = (
  { __typename?: 'Mutation' }
  & { inviteAdminUser: (
    { __typename?: 'InviteAdminUserResponse' }
    & Pick<InviteAdminUserResponse, 'created' | 'emailSent'>
  ) }
);

export type GetAdminUserByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetAdminUserByIdQuery = (
  { __typename?: 'Query' }
  & { getAdminUserById: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email' | 'firstName' | 'lastName' | 'status' | 'isAdmin' | 'profilePic' | 'createdAt' | 'updatedAt'>
    & { organizationMemberships: Array<(
      { __typename?: 'OrganizationMembership' }
      & Pick<OrganizationMembership, 'id' | 'orgId' | 'roles' | 'permissions' | 'title'>
      & { leagueMemberships: Array<(
        { __typename?: 'OrganizationLeagueMembership' }
        & Pick<OrganizationLeagueMembership, 'leagueId' | 'name' | 'abbreviation' | 'logo'>
      )> }
    )> }
  ) }
);

export type GetMyAlertByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetMyAlertByIdQuery = (
  { __typename?: 'Query' }
  & { getMyAlertById: (
    { __typename?: 'UserAlert' }
    & Pick<UserAlert, 'id' | 'userId' | 'title' | 'message' | 'read' | 'route' | 'routeText' | 'component' | 'createdAt' | 'updatedAt'>
  ) }
);

export type ListMyAlertsQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  read?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  components?: Maybe<Array<UserAlertComponents> | UserAlertComponents>;
}>;


export type ListMyAlertsQuery = (
  { __typename?: 'Query' }
  & { listMyAlerts: (
    { __typename?: 'UserAlerts' }
    & { alerts: Array<(
      { __typename?: 'UserAlert' }
      & Pick<UserAlert, 'id' | 'userId' | 'title' | 'message' | 'read' | 'route' | 'routeText' | 'component' | 'createdAt' | 'updatedAt'>
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type DeleteMyAlertMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteMyAlertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteMyAlert'>
);

export type DeleteMySelectedAlertsMutationVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String'];
}>;


export type DeleteMySelectedAlertsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteMySelectedAlerts'>
);

export type DeleteAllMyReadAlertsMutationVariables = Exact<{ [key: string]: never; }>;


export type DeleteAllMyReadAlertsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteAllMyReadAlerts'>
);

export type SetReadForMyAlertMutationVariables = Exact<{
  id: Scalars['String'];
  read: Scalars['Boolean'];
}>;


export type SetReadForMyAlertMutation = (
  { __typename?: 'Mutation' }
  & { setReadForMyAlert: (
    { __typename?: 'UserAlert' }
    & Pick<UserAlert, 'id' | 'userId' | 'title' | 'message' | 'read' | 'route' | 'routeText' | 'component' | 'createdAt' | 'updatedAt'>
  ) }
);

export type MarkMySelectedAlertsAsReadMutationVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String'];
}>;


export type MarkMySelectedAlertsAsReadMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'markMySelectedAlertsAsRead'>
);

export type MarkAllMyAlertsAsReadMutationVariables = Exact<{ [key: string]: never; }>;


export type MarkAllMyAlertsAsReadMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'markAllMyAlertsAsRead'>
);

export type GetMyNewAlertsCountQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyNewAlertsCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getMyNewAlertsCount'>
);

export type NewUserAlertsNotificationSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type NewUserAlertsNotificationSubscription = (
  { __typename?: 'Subscription' }
  & { newUserAlertsNotification: (
    { __typename?: 'UserAlertNotification' }
    & Pick<UserAlertNotification, 'id' | 'userId' | 'title' | 'message' | 'route' | 'routeText' | 'component' | 'date'>
  ) }
);

export type ListAllSurveysForOrganizationQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  orgId: Scalars['String'];
  seasonId?: Maybe<Scalars['String']>;
  status?: Maybe<SurveyStatuses>;
  audience?: Maybe<SurveyAudiences>;
  type?: Maybe<SurveyTypes>;
}>;


export type ListAllSurveysForOrganizationQuery = (
  { __typename?: 'Query' }
  & { listAllSurveysForOrganization: (
    { __typename?: 'AnySurveys' }
    & { surveys: Array<(
      { __typename?: 'AnySurvey' }
      & Pick<AnySurvey, 'id' | 'type' | 'sport' | 'status' | 'orgId' | 'seasonId' | 'hasSignatureSection' | 'hasCompetitionVerification' | 'hasIncidentReport' | 'hasKeyIncidentVideoIntegration' | 'name' | 'description' | 'audience' | 'sendDelayMinutes' | 'sectionCount' | 'activatedAt' | 'createdAt' | 'updatedAt'>
      & { reminders: (
        { __typename?: 'SurveyRemindersConfig' }
        & Pick<SurveyRemindersConfig, 'type'>
        & { dailyReminders?: Maybe<(
          { __typename?: 'SurveyDailyRemindersConfig' }
          & Pick<SurveyDailyRemindersConfig, 'sendReminders' | 'sendReminderDays' | 'sendReminderCutoffDays'>
        )>, levelReminders?: Maybe<(
          { __typename?: 'SurveyLevelRemindersConfig' }
          & Pick<SurveyLevelRemindersConfig, 'sendRemindersLevel1' | 'sendReminderDaysLevel1' | 'sendRemindersLevel2' | 'sendReminderDaysLevel2' | 'sendRemindersLevel3' | 'sendReminderDaysLevel3'>
        )> }
      ) }
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
  rememberMe?: Maybe<Scalars['Boolean']>;
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'LoginResponse' }
    & Pick<LoginResponse, 'accessToken'>
  ) }
);

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'email' | 'isAdmin' | 'status' | 'profilePic' | 'createdAt'>
    & { organizationMemberships: Array<(
      { __typename?: 'OrganizationMembership' }
      & Pick<OrganizationMembership, 'id' | 'orgId' | 'name' | 'abbreviation' | 'logo' | 'sport' | 'roles' | 'permissions' | 'title'>
      & { leagueMemberships: Array<(
        { __typename?: 'OrganizationLeagueMembership' }
        & Pick<OrganizationLeagueMembership, 'leagueId' | 'name' | 'abbreviation' | 'logo'>
      )> }
    )> }
  ) }
);

export type GetMyOrganizationContextQueryVariables = Exact<{
  orgId: Scalars['String'];
  leagueId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
}>;


export type GetMyOrganizationContextQuery = (
  { __typename?: 'Query' }
  & { getMyOrganizationContext: (
    { __typename?: 'UserOrganizationContext' }
    & { orgFeatures: Array<(
      { __typename?: 'OrganizationFeature' }
      & Pick<OrganizationFeature, 'id' | 'feature' | 'active' | 'createdAt' | 'updatedAt'>
    )>, league?: Maybe<(
      { __typename?: 'League' }
      & Pick<League, 'id' | 'orgId' | 'name' | 'description' | 'abbreviation' | 'logo' | 'status' | 'createdAt' | 'updatedAt'>
      & { tags: Array<(
        { __typename?: 'LeagueTag' }
        & Pick<LeagueTag, 'id' | 'leagueId' | 'tag'>
      )> }
    )>, season?: Maybe<(
      { __typename?: 'Season' }
      & Pick<Season, 'id' | 'leagueId' | 'leagueName' | 'leagueLogo' | 'teamKitEnabled' | 'competitionIntegrationEnabled' | 'mediaMembersEnabled' | 'name' | 'description' | 'status' | 'startDate' | 'endDate' | 'createdAt' | 'updatedAt'>
    )> }
  ) }
);

export type CheckUserConfirmationQueryVariables = Exact<{
  token: Scalars['String'];
}>;


export type CheckUserConfirmationQuery = (
  { __typename?: 'Query' }
  & { checkConfirmationToken: (
    { __typename?: 'ConfirmationStatus' }
    & Pick<ConfirmationStatus, 'isValid'>
  ) }
);

export type ResendUserConfirmationEmailMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ResendUserConfirmationEmailMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'resendUserConfirmationEmail'>
);

export type ConfirmUserMutationVariables = Exact<{
  token: Scalars['String'];
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
}>;


export type ConfirmUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'confirmUser'>
);

export type UpdateMyPasswordMutationVariables = Exact<{
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
}>;


export type UpdateMyPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateMyPassword'>
);

export type RequestResetPasswordLinkMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type RequestResetPasswordLinkMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'requestResetPasswordLink'>
);

export type CheckResetPasswordTokenQueryVariables = Exact<{
  token: Scalars['String'];
}>;


export type CheckResetPasswordTokenQuery = (
  { __typename?: 'Query' }
  & { checkResetPasswordToken: (
    { __typename?: 'ResetPasswordStatus' }
    & Pick<ResetPasswordStatus, 'isValid'>
  ) }
);

export type ResetPasswordMutationVariables = Exact<{
  token: Scalars['String'];
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'resetPassword'>
);

export type GetBatchSeasonCoachUploadByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetBatchSeasonCoachUploadByIdQuery = (
  { __typename?: 'Query' }
  & { getBatchSeasonCoachUploadById: (
    { __typename?: 'BatchSeasonCoachUpload' }
    & Pick<BatchSeasonCoachUpload, 'id' | 'organizationId' | 'seasonId' | 'seasonName' | 'fileName' | 'status' | 'error' | 'createdAt' | 'updatedAt'>
  ) }
);

export type ListBatchSeasonCoachUploadsForSeasonQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  seasonId: Scalars['String'];
  fileName?: Maybe<Scalars['String']>;
  status?: Maybe<BatchFileUploadStatuses>;
}>;


export type ListBatchSeasonCoachUploadsForSeasonQuery = (
  { __typename?: 'Query' }
  & { listBatchSeasonCoachUploadsForSeason: (
    { __typename?: 'BatchSeasonCoachUploads' }
    & Pick<BatchSeasonCoachUploads, 'seasonId'>
    & { uploads: Array<(
      { __typename?: 'BatchSeasonCoachUpload' }
      & Pick<BatchSeasonCoachUpload, 'id' | 'organizationId' | 'seasonId' | 'seasonName' | 'fileName' | 'status' | 'error' | 'createdAt' | 'updatedAt'>
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type ListRowsForBatchSeasonCoachUploadQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  uploadId: Scalars['String'];
}>;


export type ListRowsForBatchSeasonCoachUploadQuery = (
  { __typename?: 'Query' }
  & { listRowsForBatchSeasonCoachUpload: (
    { __typename?: 'BatchSeasonCoachUploadRows' }
    & Pick<BatchSeasonCoachUploadRows, 'uploadId'>
    & { rows: Array<(
      { __typename?: 'BatchSeasonCoachUploadRow' }
      & Pick<BatchSeasonCoachUploadRow, 'id' | 'uploadId' | 'rowNumber' | 'value' | 'status' | 'coachId' | 'createdAt' | 'updatedAt'>
      & { errors: Array<(
        { __typename?: 'BatchSeasonCoachUploadRowError' }
        & Pick<BatchSeasonCoachUploadRowError, 'id' | 'columnName' | 'error' | 'createdAt' | 'updatedAt'>
      )> }
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type ListErrorsForBatchSeasonCoachUploadRowQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  rowId: Scalars['String'];
}>;


export type ListErrorsForBatchSeasonCoachUploadRowQuery = (
  { __typename?: 'Query' }
  & { listErrorsForBatchSeasonCoachUploadRow: (
    { __typename?: 'BatchSeasonCoachUploadRowErrors' }
    & Pick<BatchSeasonCoachUploadRowErrors, 'rowId'>
    & { rowErrors: Array<(
      { __typename?: 'BatchSeasonCoachUploadRowError' }
      & Pick<BatchSeasonCoachUploadRowError, 'id' | 'columnName' | 'error' | 'createdAt' | 'updatedAt'>
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type GetBatchSeasonCompetitionUploadByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetBatchSeasonCompetitionUploadByIdQuery = (
  { __typename?: 'Query' }
  & { getBatchSeasonCompetitionUploadById: (
    { __typename?: 'BatchSeasonCompetitionUpload' }
    & Pick<BatchSeasonCompetitionUpload, 'id' | 'organizationId' | 'seasonId' | 'seasonName' | 'fileName' | 'status' | 'error' | 'createdAt' | 'updatedAt'>
  ) }
);

export type ListBatchSeasonCompetitionUploadsForSeasonQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  seasonId: Scalars['String'];
  fileName?: Maybe<Scalars['String']>;
  status?: Maybe<BatchFileUploadStatuses>;
}>;


export type ListBatchSeasonCompetitionUploadsForSeasonQuery = (
  { __typename?: 'Query' }
  & { listBatchSeasonCompetitionUploadsForSeason: (
    { __typename?: 'BatchSeasonCompetitionUploads' }
    & Pick<BatchSeasonCompetitionUploads, 'seasonId'>
    & { uploads: Array<(
      { __typename?: 'BatchSeasonCompetitionUpload' }
      & Pick<BatchSeasonCompetitionUpload, 'id' | 'organizationId' | 'seasonId' | 'seasonName' | 'fileName' | 'status' | 'error' | 'createdAt' | 'updatedAt'>
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type ListRowsForBatchSeasonCompetitionUploadQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  uploadId: Scalars['String'];
}>;


export type ListRowsForBatchSeasonCompetitionUploadQuery = (
  { __typename?: 'Query' }
  & { listRowsForBatchSeasonCompetitionUpload: (
    { __typename?: 'BatchSeasonCompetitionUploadRows' }
    & Pick<BatchSeasonCompetitionUploadRows, 'uploadId'>
    & { rows: Array<(
      { __typename?: 'BatchSeasonCompetitionUploadRow' }
      & Pick<BatchSeasonCompetitionUploadRow, 'id' | 'uploadId' | 'rowNumber' | 'value' | 'status' | 'competitionId' | 'createdAt' | 'updatedAt'>
      & { errors: Array<(
        { __typename?: 'BatchSeasonCompetitionUploadRowError' }
        & Pick<BatchSeasonCompetitionUploadRowError, 'id' | 'columnName' | 'error' | 'createdAt' | 'updatedAt'>
      )> }
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type ListErrorsForBatchSeasonCompetitionUploadRowQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  rowId: Scalars['String'];
}>;


export type ListErrorsForBatchSeasonCompetitionUploadRowQuery = (
  { __typename?: 'Query' }
  & { listErrorsForBatchSeasonCompetitionUploadRow: (
    { __typename?: 'BatchSeasonCompetitionUploadRowErrors' }
    & Pick<BatchSeasonCompetitionUploadRowErrors, 'rowId'>
    & { rowErrors: Array<(
      { __typename?: 'BatchSeasonCompetitionUploadRowError' }
      & Pick<BatchSeasonCompetitionUploadRowError, 'id' | 'columnName' | 'error' | 'createdAt' | 'updatedAt'>
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type GetBatchSeasonOfficialUploadByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetBatchSeasonOfficialUploadByIdQuery = (
  { __typename?: 'Query' }
  & { getBatchSeasonOfficialUploadById: (
    { __typename?: 'BatchSeasonOfficialUpload' }
    & Pick<BatchSeasonOfficialUpload, 'id' | 'organizationId' | 'seasonId' | 'seasonName' | 'fileName' | 'status' | 'error' | 'createdAt' | 'updatedAt'>
  ) }
);

export type ListBatchSeasonOfficialUploadsForSeasonQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  seasonId: Scalars['String'];
  fileName?: Maybe<Scalars['String']>;
  status?: Maybe<BatchFileUploadStatuses>;
}>;


export type ListBatchSeasonOfficialUploadsForSeasonQuery = (
  { __typename?: 'Query' }
  & { listBatchSeasonOfficialUploadsForSeason: (
    { __typename?: 'BatchSeasonOfficialUploads' }
    & Pick<BatchSeasonOfficialUploads, 'seasonId'>
    & { uploads: Array<(
      { __typename?: 'BatchSeasonOfficialUpload' }
      & Pick<BatchSeasonOfficialUpload, 'id' | 'organizationId' | 'seasonId' | 'seasonName' | 'fileName' | 'status' | 'error' | 'createdAt' | 'updatedAt'>
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type ListRowsForBatchSeasonOfficialUploadQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  uploadId: Scalars['String'];
}>;


export type ListRowsForBatchSeasonOfficialUploadQuery = (
  { __typename?: 'Query' }
  & { listRowsForBatchSeasonOfficialUpload: (
    { __typename?: 'BatchSeasonOfficialUploadRows' }
    & Pick<BatchSeasonOfficialUploadRows, 'uploadId'>
    & { rows: Array<(
      { __typename?: 'BatchSeasonOfficialUploadRow' }
      & Pick<BatchSeasonOfficialUploadRow, 'id' | 'uploadId' | 'rowNumber' | 'value' | 'status' | 'officialId' | 'createdAt' | 'updatedAt'>
      & { errors: Array<(
        { __typename?: 'BatchSeasonOfficialUploadRowError' }
        & Pick<BatchSeasonOfficialUploadRowError, 'id' | 'columnName' | 'error' | 'createdAt' | 'updatedAt'>
      )> }
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type ListErrorsForBatchSeasonOfficialUploadRowQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  rowId: Scalars['String'];
}>;


export type ListErrorsForBatchSeasonOfficialUploadRowQuery = (
  { __typename?: 'Query' }
  & { listErrorsForBatchSeasonOfficialUploadRow: (
    { __typename?: 'BatchSeasonOfficialUploadRowErrors' }
    & Pick<BatchSeasonOfficialUploadRowErrors, 'rowId'>
    & { rowErrors: Array<(
      { __typename?: 'BatchSeasonOfficialUploadRowError' }
      & Pick<BatchSeasonOfficialUploadRowError, 'id' | 'columnName' | 'error' | 'createdAt' | 'updatedAt'>
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type GetBatchSeasonSurveyResponseUploadByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetBatchSeasonSurveyResponseUploadByIdQuery = (
  { __typename?: 'Query' }
  & { getBatchSeasonSurveyResponseUploadById: (
    { __typename?: 'BatchSeasonSurveyResponseUpload' }
    & Pick<BatchSeasonSurveyResponseUpload, 'id' | 'organizationId' | 'seasonId' | 'seasonName' | 'surveyId' | 'surveyName' | 'fileName' | 'status' | 'error' | 'createdAt' | 'updatedAt'>
  ) }
);

export type ListBatchSeasonSurveyResponseUploadsQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  organizationName?: Maybe<Scalars['String']>;
  seasonName?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  status?: Maybe<BatchFileUploadStatuses>;
  legacyType?: Maybe<BatchSeasonLegacySurveyTypes>;
}>;


export type ListBatchSeasonSurveyResponseUploadsQuery = (
  { __typename?: 'Query' }
  & { listBatchSeasonSurveyResponseUploads: (
    { __typename?: 'BatchSeasonSurveyResponseUploads' }
    & { uploads: Array<(
      { __typename?: 'BatchSeasonSurveyResponseUpload' }
      & Pick<BatchSeasonSurveyResponseUpload, 'id' | 'organizationId' | 'seasonId' | 'seasonName' | 'surveyId' | 'surveyName' | 'fileName' | 'status' | 'createdAt' | 'updatedAt'>
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type ListRowsForBatchSeasonSurveyResponseUploadQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  uploadId: Scalars['String'];
}>;


export type ListRowsForBatchSeasonSurveyResponseUploadQuery = (
  { __typename?: 'Query' }
  & { listRowsForBatchSeasonSurveyResponseUpload: (
    { __typename?: 'BatchSeasonSurveyResponseUploadRows' }
    & Pick<BatchSeasonSurveyResponseUploadRows, 'uploadId'>
    & { rows: Array<(
      { __typename?: 'BatchSeasonSurveyResponseUploadRow' }
      & Pick<BatchSeasonSurveyResponseUploadRow, 'id' | 'uploadId' | 'rowNumber' | 'value' | 'status' | 'competitionSurveyId' | 'createdAt' | 'updatedAt'>
      & { errors: Array<(
        { __typename?: 'BatchSeasonSurveyResponseUploadRowError' }
        & Pick<BatchSeasonSurveyResponseUploadRowError, 'id' | 'columnName' | 'error' | 'createdAt' | 'updatedAt'>
      )> }
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type ListErrorsForBatchSeasonSurveyResponseUploadRowQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  rowId: Scalars['String'];
}>;


export type ListErrorsForBatchSeasonSurveyResponseUploadRowQuery = (
  { __typename?: 'Query' }
  & { listErrorsForBatchSeasonSurveyResponseUploadRow: (
    { __typename?: 'BatchSeasonSurveyResponseUploadRowErrors' }
    & Pick<BatchSeasonSurveyResponseUploadRowErrors, 'rowId'>
    & { rowErrors: Array<(
      { __typename?: 'BatchSeasonSurveyResponseUploadRowError' }
      & Pick<BatchSeasonSurveyResponseUploadRowError, 'id' | 'columnName' | 'error' | 'createdAt' | 'updatedAt'>
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type GetBatchSeasonTeamUploadByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetBatchSeasonTeamUploadByIdQuery = (
  { __typename?: 'Query' }
  & { getBatchSeasonTeamUploadById: (
    { __typename?: 'BatchSeasonTeamUpload' }
    & Pick<BatchSeasonTeamUpload, 'id' | 'organizationId' | 'seasonId' | 'seasonName' | 'fileName' | 'status' | 'error' | 'createdAt' | 'updatedAt'>
  ) }
);

export type ListBatchSeasonTeamUploadsForSeasonQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  seasonId: Scalars['String'];
  fileName?: Maybe<Scalars['String']>;
  status?: Maybe<BatchFileUploadStatuses>;
}>;


export type ListBatchSeasonTeamUploadsForSeasonQuery = (
  { __typename?: 'Query' }
  & { listBatchSeasonTeamUploadsForSeason: (
    { __typename?: 'BatchSeasonTeamUploads' }
    & Pick<BatchSeasonTeamUploads, 'seasonId'>
    & { uploads: Array<(
      { __typename?: 'BatchSeasonTeamUpload' }
      & Pick<BatchSeasonTeamUpload, 'id' | 'organizationId' | 'seasonId' | 'seasonName' | 'fileName' | 'status' | 'error' | 'createdAt' | 'updatedAt'>
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type ListRowsForBatchSeasonTeamUploadQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  uploadId: Scalars['String'];
}>;


export type ListRowsForBatchSeasonTeamUploadQuery = (
  { __typename?: 'Query' }
  & { listRowsForBatchSeasonTeamUpload: (
    { __typename?: 'BatchSeasonTeamUploadRows' }
    & Pick<BatchSeasonTeamUploadRows, 'uploadId'>
    & { rows: Array<(
      { __typename?: 'BatchSeasonTeamUploadRow' }
      & Pick<BatchSeasonTeamUploadRow, 'id' | 'uploadId' | 'rowNumber' | 'value' | 'status' | 'teamId' | 'createdAt' | 'updatedAt'>
      & { errors: Array<(
        { __typename?: 'BatchSeasonTeamUploadRowError' }
        & Pick<BatchSeasonTeamUploadRowError, 'id' | 'columnName' | 'error' | 'createdAt' | 'updatedAt'>
      )> }
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type ListErrorsForBatchSeasonTeamUploadRowQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  rowId: Scalars['String'];
}>;


export type ListErrorsForBatchSeasonTeamUploadRowQuery = (
  { __typename?: 'Query' }
  & { listErrorsForBatchSeasonTeamUploadRow: (
    { __typename?: 'BatchSeasonTeamUploadRowErrors' }
    & Pick<BatchSeasonTeamUploadRowErrors, 'rowId'>
    & { rowErrors: Array<(
      { __typename?: 'BatchSeasonTeamUploadRowError' }
      & Pick<BatchSeasonTeamUploadRowError, 'id' | 'columnName' | 'error' | 'createdAt' | 'updatedAt'>
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type GetCoachByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetCoachByIdQuery = (
  { __typename?: 'Query' }
  & { getCoachById: (
    { __typename?: 'Coach' }
    & Pick<Coach, 'id' | 'teamId' | 'teamName' | 'teamAbbreviation' | 'sport' | 'teamCity' | 'userId' | 'type' | 'role' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'userStatus' | 'createdAt' | 'updatedAt'>
  ) }
);

export type DeactivateCoachMutationVariables = Exact<{
  coachId: Scalars['String'];
  deactivateUser: Scalars['Boolean'];
  seasonId?: Maybe<Scalars['String']>;
}>;


export type DeactivateCoachMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deactivateCoach'>
);

export type ReactivateCoachMutationVariables = Exact<{
  coachId: Scalars['String'];
  seasonId?: Maybe<Scalars['String']>;
}>;


export type ReactivateCoachMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'reactivateCoach'>
);

export type EditCoachMutationVariables = Exact<{
  coachId: Scalars['String'];
  type: CoachTypes;
  role?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
}>;


export type EditCoachMutation = (
  { __typename?: 'Mutation' }
  & { editCoach: (
    { __typename?: 'Coach' }
    & Pick<Coach, 'id' | 'teamId' | 'teamName' | 'teamAbbreviation' | 'sport' | 'teamCity' | 'userId' | 'type' | 'role' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'userStatus' | 'createdAt' | 'updatedAt'>
  ) }
);

export type CreateCompetitionIntegrationForOrganizationMutationVariables = Exact<{
  orgId: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  status: CompetitionIntegrationStatuses;
  queueName: Scalars['String'];
  additionalOptions: Array<CreateCompetitionIntegrationForOrganizationAdditionalOptionInput> | CreateCompetitionIntegrationForOrganizationAdditionalOptionInput;
}>;


export type CreateCompetitionIntegrationForOrganizationMutation = (
  { __typename?: 'Mutation' }
  & { createCompetitionIntegrationForOrganization: (
    { __typename?: 'CompetitionIntegration' }
    & Pick<CompetitionIntegration, 'id' | 'orgId' | 'orgName' | 'orgAbbreviation' | 'name' | 'description' | 'status' | 'queueName' | 'createdAt' | 'updatedAt'>
    & { additionalOptions: Array<(
      { __typename?: 'CompetitionIntegrationAdditionalOption' }
      & Pick<CompetitionIntegrationAdditionalOption, 'id' | 'name' | 'isRequired'>
    )> }
  ) }
);

export type GetCompetitionIntegrationByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetCompetitionIntegrationByIdQuery = (
  { __typename?: 'Query' }
  & { getCompetitionIntegrationById: (
    { __typename?: 'CompetitionIntegration' }
    & Pick<CompetitionIntegration, 'id' | 'orgId' | 'orgName' | 'orgAbbreviation' | 'name' | 'description' | 'status' | 'queueName' | 'createdAt' | 'updatedAt'>
    & { additionalOptions: Array<(
      { __typename?: 'CompetitionIntegrationAdditionalOption' }
      & Pick<CompetitionIntegrationAdditionalOption, 'id' | 'name' | 'isRequired'>
    )> }
  ) }
);

export type ListCompetitionIntegrationsQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  orgId?: Maybe<Scalars['String']>;
  orgName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  queueName?: Maybe<Scalars['String']>;
  status?: Maybe<CompetitionIntegrationStatuses>;
}>;


export type ListCompetitionIntegrationsQuery = (
  { __typename?: 'Query' }
  & { listCompetitionIntegrations: (
    { __typename?: 'CompetitionIntegrations' }
    & { integrations: Array<(
      { __typename?: 'CompetitionIntegration' }
      & Pick<CompetitionIntegration, 'id' | 'orgId' | 'orgName' | 'orgAbbreviation' | 'name' | 'description' | 'status' | 'queueName' | 'createdAt' | 'updatedAt'>
      & { additionalOptions: Array<(
        { __typename?: 'CompetitionIntegrationAdditionalOption' }
        & Pick<CompetitionIntegrationAdditionalOption, 'id' | 'name' | 'isRequired'>
      )> }
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type UpdateCompetitionIntegrationMutationVariables = Exact<{
  id: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  status: CompetitionIntegrationStatuses;
  queueName: Scalars['String'];
}>;


export type UpdateCompetitionIntegrationMutation = (
  { __typename?: 'Mutation' }
  & { updateCompetitionIntegration: (
    { __typename?: 'CompetitionIntegration' }
    & Pick<CompetitionIntegration, 'id' | 'orgId' | 'orgName' | 'orgAbbreviation' | 'name' | 'description' | 'status' | 'queueName' | 'createdAt' | 'updatedAt'>
    & { additionalOptions: Array<(
      { __typename?: 'CompetitionIntegrationAdditionalOption' }
      & Pick<CompetitionIntegrationAdditionalOption, 'id' | 'name' | 'isRequired'>
    )> }
  ) }
);

export type DeleteCompetitionIntegrationMutationVariables = Exact<{
  integrationId: Scalars['String'];
}>;


export type DeleteCompetitionIntegrationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCompetitionIntegration'>
);

export type ListAdditionalOptionsForCompetitionIntegrationQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  integrationId: Scalars['String'];
}>;


export type ListAdditionalOptionsForCompetitionIntegrationQuery = (
  { __typename?: 'Query' }
  & { listAdditionalOptionsForCompetitionIntegration: (
    { __typename?: 'CompetitionIntegrationAdditionalOptions' }
    & { additionalOptions: Array<(
      { __typename?: 'CompetitionIntegrationAdditionalOption' }
      & Pick<CompetitionIntegrationAdditionalOption, 'id' | 'name' | 'isRequired' | 'createdAt' | 'updatedAt'>
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type AddAdditionalOptionToCompetitionIntegrationMutationVariables = Exact<{
  integrationId: Scalars['String'];
  name: Scalars['String'];
  isRequired: Scalars['Boolean'];
}>;


export type AddAdditionalOptionToCompetitionIntegrationMutation = (
  { __typename?: 'Mutation' }
  & { addAdditionalOptionToCompetitionIntegration: (
    { __typename?: 'CompetitionIntegrationAdditionalOption' }
    & Pick<CompetitionIntegrationAdditionalOption, 'id' | 'name' | 'isRequired' | 'createdAt' | 'updatedAt'>
  ) }
);

export type UpdateAdditionalOptionForCompetitionIntegrationMutationVariables = Exact<{
  integrationId: Scalars['String'];
  additionalOptionId: Scalars['String'];
  name: Scalars['String'];
  isRequired: Scalars['Boolean'];
}>;


export type UpdateAdditionalOptionForCompetitionIntegrationMutation = (
  { __typename?: 'Mutation' }
  & { updateAdditionalOptionForCompetitionIntegration: (
    { __typename?: 'CompetitionIntegrationAdditionalOption' }
    & Pick<CompetitionIntegrationAdditionalOption, 'id' | 'name' | 'isRequired' | 'createdAt' | 'updatedAt'>
  ) }
);

export type RemoveAdditionalOptionFromCompetitionIntegrationMutationVariables = Exact<{
  integrationId: Scalars['String'];
  additionalOptionId: Scalars['String'];
}>;


export type RemoveAdditionalOptionFromCompetitionIntegrationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeAdditionalOptionFromCompetitionIntegration'>
);

export type ListCompetitionIntegrationRequestsForSeasonQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  seasonId: Scalars['String'];
  dateFrom?: Maybe<Scalars['Date']>;
  dateTo?: Maybe<Scalars['Date']>;
  integrationSearch?: Maybe<Scalars['String']>;
  statuses?: Maybe<Array<CompetitionIntegrationRequestStatuses> | CompetitionIntegrationRequestStatuses>;
}>;


export type ListCompetitionIntegrationRequestsForSeasonQuery = (
  { __typename?: 'Query' }
  & { listCompetitionIntegrationRequestsForSeason: (
    { __typename?: 'CompetitionIntegrationRequestSummaries' }
    & { requests: Array<(
      { __typename?: 'CompetitionIntegrationRequestSummary' }
      & Pick<CompetitionIntegrationRequestSummary, 'id' | 'seasonId' | 'seasonName' | 'seasonIntegrationId' | 'integrationId' | 'integrationName' | 'status' | 'error' | 'totalCount' | 'processedCount' | 'errorCount' | 'successCount' | 'createdAt' | 'updatedAt'>
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type ListCompetitionIntegrationResponsesForRequestQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  requestId: Scalars['String'];
  statuses?: Maybe<Array<CompetitionIntegrationResponseStatuses> | CompetitionIntegrationResponseStatuses>;
}>;


export type ListCompetitionIntegrationResponsesForRequestQuery = (
  { __typename?: 'Query' }
  & { listCompetitionIntegrationResponsesForRequest: (
    { __typename?: 'CompetitionIntegrationResponses' }
    & { responses: Array<(
      { __typename?: 'CompetitionIntegrationResponse' }
      & Pick<CompetitionIntegrationResponse, 'id' | 'requestId' | 'index' | 'total' | 'status' | 'data' | 'createdAt' | 'updatedAt'>
      & { errors: Array<(
        { __typename?: 'CompetitionIntegrationResponseError' }
        & Pick<CompetitionIntegrationResponseError, 'id' | 'error' | 'createdAt' | 'updatedAt'>
      )> }
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type ListStatsForCompetitionQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  competitionId: Scalars['String'];
  nameOrDisplayText?: Maybe<Scalars['String']>;
  teamName?: Maybe<Scalars['String']>;
}>;


export type ListStatsForCompetitionQuery = (
  { __typename?: 'Query' }
  & { listStatsForCompetition: (
    { __typename?: 'CompetitionStats' }
    & Pick<CompetitionStats, 'competitionId'>
    & { stats: Array<(
      { __typename?: 'CompetitionStat' }
      & Pick<CompetitionStat, 'id' | 'competitionId' | 'teamId' | 'name' | 'displayText' | 'valueString' | 'valueNumeric' | 'valueDate' | 'createdAt' | 'updatedAt'>
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type ListStatsForCompetitionByTeamQueryVariables = Exact<{
  competitionId: Scalars['String'];
  teamId?: Maybe<Scalars['String']>;
}>;


export type ListStatsForCompetitionByTeamQuery = (
  { __typename?: 'Query' }
  & { listStatsForCompetitionByTeam: Array<(
    { __typename?: 'CompetitionStat' }
    & Pick<CompetitionStat, 'id' | 'competitionId' | 'teamId' | 'name' | 'displayText' | 'valueString' | 'valueNumeric' | 'valueDate' | 'createdAt' | 'updatedAt'>
  )> }
);

export type GetCompetitionSurveyByIdWithTokenQueryVariables = Exact<{
  surveyToken: Scalars['String'];
}>;


export type GetCompetitionSurveyByIdWithTokenQuery = (
  { __typename?: 'Query' }
  & { getCompetitionSurveyByIdWithToken: (
    { __typename?: 'CompetitionSurvey' }
    & Pick<CompetitionSurvey, 'id' | 'surveyId' | 'orgId' | 'seasonId' | 'sport' | 'finished' | 'hasCompetitionVerification' | 'hasIncidentReport' | 'allowAdditionalIncidentReports' | 'allowAdditionalIncidentReportsCutoffDays' | 'createdAt' | 'updatedAt' | 'completedDate'>
    & { recipients: Array<(
      { __typename?: 'CompetitionSurveyUser' }
      & Pick<CompetitionSurveyUser, 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'tookSurvey'>
    )>, competition: (
      { __typename?: 'Competition' }
      & Pick<Competition, 'id' | 'externalId' | 'sport' | 'startTime' | 'timeZone' | 'location' | 'homeTeamScore' | 'homeTeamTieBreakerScore' | 'awayTeamScore' | 'awayTeamTieBreakerScore' | 'createdAt' | 'updatedAt'>
      & { homeTeam: (
        { __typename?: 'Team' }
        & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'classification' | 'gender' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'timeZone' | 'website' | 'logo' | 'createdAt' | 'updatedAt'>
      ), awayTeam: (
        { __typename?: 'Team' }
        & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'classification' | 'gender' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'timeZone' | 'website' | 'logo' | 'createdAt' | 'updatedAt'>
      ), organization: (
        { __typename?: 'Organization' }
        & Pick<Organization, 'id' | 'name' | 'sport' | 'status' | 'abbreviation' | 'site' | 'logo' | 'createdAt' | 'updatedAt'>
      ), season?: Maybe<(
        { __typename?: 'Season' }
        & Pick<Season, 'id' | 'leagueId' | 'leagueName' | 'leagueLogo' | 'name' | 'description' | 'status' | 'startDate' | 'endDate' | 'createdAt' | 'updatedAt'>
      )>, officials: Array<(
        { __typename?: 'CompetitionOfficial' }
        & Pick<CompetitionOfficial, 'id' | 'competitionId' | 'assessmentObjectId' | 'order' | 'type' | 'createdAt' | 'updatedAt'>
        & { official: (
          { __typename?: 'Official' }
          & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    ), info: (
      { __typename?: 'CompetitionSurveyInfo' }
      & Pick<CompetitionSurveyInfo, 'name' | 'description' | 'audience'>
    ), sections: Array<(
      { __typename?: 'CompetitionSurveySection' }
      & Pick<CompetitionSurveySection, 'id' | 'name' | 'description' | 'order' | 'finished' | 'createdAt' | 'updatedAt'>
      & { coach?: Maybe<(
        { __typename?: 'Coach' }
        & Pick<Coach, 'id' | 'teamId' | 'teamName' | 'teamAbbreviation' | 'sport' | 'teamCity' | 'userId' | 'type' | 'role' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
      )>, official?: Maybe<(
        { __typename?: 'Official' }
        & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
        & { externalIds?: Maybe<Array<(
          { __typename?: 'OfficialExternalId' }
          & Pick<OfficialExternalId, 'id' | 'organizationId' | 'externalId'>
        )>> }
      )>, organization?: Maybe<(
        { __typename?: 'Organization' }
        & Pick<Organization, 'id' | 'name' | 'sport' | 'status' | 'abbreviation' | 'site' | 'logo' | 'createdAt' | 'updatedAt'>
      )>, questions: Array<(
        { __typename?: 'CompetitionSurveySectionQuestion' }
        & Pick<CompetitionSurveySectionQuestion, 'id' | 'name' | 'description' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType' | 'selectedOption' | 'selectedOptionOrder' | 'responseShortAnswer' | 'responseText' | 'responseNumber'>
        & { responses?: Maybe<Array<(
          { __typename?: 'CompetitionSurveySectionQuestionMultipleResponse' }
          & Pick<CompetitionSurveySectionQuestionMultipleResponse, 'id' | 'value' | 'createdAt' | 'updatedAt'>
        )>>, options: Array<(
          { __typename?: 'CompetitionSurveySectionQuestionOption' }
          & Pick<CompetitionSurveySectionQuestionOption, 'id' | 'value' | 'order'>
        )> }
      )>, assessmentObject: (
        { __typename?: 'AssessmentObject' }
        & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name'>
      ) }
    )> }
  ) }
);

export type StartCompetitionSurveyWithTokenMutationVariables = Exact<{
  surveyToken: Scalars['String'];
}>;


export type StartCompetitionSurveyWithTokenMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'startCompetitionSurveyWithToken'>
);

export type UpdateCompetitionSurveyCompetitionScoreWithTokenMutationVariables = Exact<{
  surveyToken: Scalars['String'];
  homeTeamScore: Scalars['Int'];
  awayTeamScore: Scalars['Int'];
  homeTeamTieBreakerScore?: Maybe<Scalars['Int']>;
  awayTeamTieBreakerScore?: Maybe<Scalars['Int']>;
}>;


export type UpdateCompetitionSurveyCompetitionScoreWithTokenMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateCompetitionSurveyCompetitionScoreWithToken'>
);

export type UpdateCompetitionSurveyMultipleChoiceResponseWithTokenMutationVariables = Exact<{
  surveyToken: Scalars['String'];
  questionId: Scalars['String'];
  selectedOptionId: Scalars['String'];
}>;


export type UpdateCompetitionSurveyMultipleChoiceResponseWithTokenMutation = (
  { __typename?: 'Mutation' }
  & { updateCompetitionSurveyMultipleChoiceResponseWithToken: (
    { __typename?: 'CompetitionSurveySectionQuestion' }
    & Pick<CompetitionSurveySectionQuestion, 'id' | 'name' | 'description' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType' | 'selectedOption' | 'selectedOptionOrder' | 'responseShortAnswer' | 'responseText' | 'responseNumber'>
    & { responses?: Maybe<Array<(
      { __typename?: 'CompetitionSurveySectionQuestionMultipleResponse' }
      & Pick<CompetitionSurveySectionQuestionMultipleResponse, 'id' | 'value' | 'createdAt' | 'updatedAt'>
    )>>, options: Array<(
      { __typename?: 'CompetitionSurveySectionQuestionOption' }
      & Pick<CompetitionSurveySectionQuestionOption, 'id' | 'value' | 'order'>
    )> }
  ) }
);

export type UpdateCompetitionSurveyShortAnswerResponseWithTokenMutationVariables = Exact<{
  surveyToken: Scalars['String'];
  questionId: Scalars['String'];
  response: Scalars['String'];
}>;


export type UpdateCompetitionSurveyShortAnswerResponseWithTokenMutation = (
  { __typename?: 'Mutation' }
  & { updateCompetitionSurveyShortAnswerResponseWithToken: (
    { __typename?: 'CompetitionSurveySectionQuestion' }
    & Pick<CompetitionSurveySectionQuestion, 'id' | 'name' | 'description' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType' | 'selectedOption' | 'selectedOptionOrder' | 'responseShortAnswer' | 'responseText' | 'responseNumber'>
    & { responses?: Maybe<Array<(
      { __typename?: 'CompetitionSurveySectionQuestionMultipleResponse' }
      & Pick<CompetitionSurveySectionQuestionMultipleResponse, 'id' | 'value' | 'createdAt' | 'updatedAt'>
    )>>, options: Array<(
      { __typename?: 'CompetitionSurveySectionQuestionOption' }
      & Pick<CompetitionSurveySectionQuestionOption, 'id' | 'value' | 'order'>
    )> }
  ) }
);

export type UpdateCompetitionSurveyTextResponseWithTokenMutationVariables = Exact<{
  surveyToken: Scalars['String'];
  questionId: Scalars['String'];
  response: Scalars['String'];
}>;


export type UpdateCompetitionSurveyTextResponseWithTokenMutation = (
  { __typename?: 'Mutation' }
  & { updateCompetitionSurveyTextResponseWithToken: (
    { __typename?: 'CompetitionSurveySectionQuestion' }
    & Pick<CompetitionSurveySectionQuestion, 'id' | 'name' | 'description' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType' | 'selectedOption' | 'selectedOptionOrder' | 'responseShortAnswer' | 'responseText' | 'responseNumber'>
    & { responses?: Maybe<Array<(
      { __typename?: 'CompetitionSurveySectionQuestionMultipleResponse' }
      & Pick<CompetitionSurveySectionQuestionMultipleResponse, 'id' | 'value' | 'createdAt' | 'updatedAt'>
    )>>, options: Array<(
      { __typename?: 'CompetitionSurveySectionQuestionOption' }
      & Pick<CompetitionSurveySectionQuestionOption, 'id' | 'value' | 'order'>
    )> }
  ) }
);

export type UpdateCompetitionSurveyNumberResponseWithTokenMutationVariables = Exact<{
  surveyToken: Scalars['String'];
  questionId: Scalars['String'];
  response: Scalars['Float'];
}>;


export type UpdateCompetitionSurveyNumberResponseWithTokenMutation = (
  { __typename?: 'Mutation' }
  & { updateCompetitionSurveyNumberResponseWithToken: (
    { __typename?: 'CompetitionSurveySectionQuestion' }
    & Pick<CompetitionSurveySectionQuestion, 'id' | 'name' | 'description' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType' | 'selectedOption' | 'selectedOptionOrder' | 'responseShortAnswer' | 'responseText' | 'responseNumber'>
    & { responses?: Maybe<Array<(
      { __typename?: 'CompetitionSurveySectionQuestionMultipleResponse' }
      & Pick<CompetitionSurveySectionQuestionMultipleResponse, 'id' | 'value' | 'createdAt' | 'updatedAt'>
    )>>, options: Array<(
      { __typename?: 'CompetitionSurveySectionQuestionOption' }
      & Pick<CompetitionSurveySectionQuestionOption, 'id' | 'value' | 'order'>
    )> }
  ) }
);

export type ListKeyIncidentsForCompetitionSurveyWithTokenQueryVariables = Exact<{
  surveyToken: Scalars['String'];
}>;


export type ListKeyIncidentsForCompetitionSurveyWithTokenQuery = (
  { __typename?: 'Query' }
  & { listKeyIncidentsForCompetitionSurveyWithToken: (
    { __typename?: 'KeyIncidents' }
    & { keyIncidents: Array<(
      { __typename?: 'KeyIncident' }
      & Pick<KeyIncident, 'id' | 'competitionSurveyId' | 'dynamicCompetitionSurveyId' | 'organizationId' | 'organizationName' | 'leagueId' | 'leagueName' | 'seasonId' | 'seasonName' | 'competitionSurveyFeedbackId' | 'dynamicCompetitionSurveyFeedbackId' | 'dynamicCompetitionSurveySubFeedbackId' | 'type' | 'status' | 'description' | 'time' | 'videoStatus' | 'videoUrl' | 'videoError' | 'impactedOutcome' | 'resolvedDate' | 'reopenedDate' | 'createdAt' | 'updatedAt'>
      & { submittedBy: (
        { __typename?: 'KeyIncidentSubmittedByInfo' }
        & Pick<KeyIncidentSubmittedByInfo, 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'teamId' | 'teamName' | 'teamLogo' | 'officialId' | 'assessmentObjectId' | 'assessmentObjectName'>
      ), competitionInfo: (
        { __typename?: 'KeyIncidentCompetitionInfo' }
        & Pick<KeyIncidentCompetitionInfo, 'competitionId' | 'externalId' | 'sport' | 'homeTeamId' | 'homeTeamName' | 'homeTeamScore' | 'homeTeamTieBreakerScore' | 'awayTeamId' | 'awayTeamName' | 'awayTeamScore' | 'awayTeamTieBreakerScore' | 'startTime' | 'timeZone' | 'location'>
      ), assessmentObject?: Maybe<(
        { __typename?: 'AssessmentObject' }
        & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name' | 'createdAt' | 'updatedAt'>
      )>, official?: Maybe<(
        { __typename?: 'Official' }
        & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
      )>, team?: Maybe<(
        { __typename?: 'Team' }
        & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'classification' | 'gender' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'timeZone' | 'website' | 'logo' | 'createdAt' | 'updatedAt'>
      )>, period?: Maybe<(
        { __typename?: 'Period' }
        & Pick<Period, 'id' | 'sport' | 'name' | 'createdAt' | 'updatedAt'>
      )> }
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type ConvertCompetitionSurveySectionFeedbackToKeyIncidentWithTokenMutationVariables = Exact<{
  surveyToken: Scalars['String'];
  questionId: Scalars['String'];
  feedbackResponseId: Scalars['String'];
  incidentType: KeyIncidentTypes;
  impactedOutcome: Scalars['Boolean'];
  time?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  periodId?: Maybe<Scalars['String']>;
  externalLinks: Array<CreateKeyIncidentExternalLinkForCompetitionSurveyInput> | CreateKeyIncidentExternalLinkForCompetitionSurveyInput;
}>;


export type ConvertCompetitionSurveySectionFeedbackToKeyIncidentWithTokenMutation = (
  { __typename?: 'Mutation' }
  & { convertCompetitionSurveySectionFeedbackToKeyIncidentWithToken: (
    { __typename?: 'KeyIncident' }
    & Pick<KeyIncident, 'id' | 'competitionSurveyId' | 'dynamicCompetitionSurveyId' | 'organizationId' | 'organizationName' | 'leagueId' | 'leagueName' | 'seasonId' | 'seasonName' | 'competitionSurveyFeedbackId' | 'dynamicCompetitionSurveyFeedbackId' | 'dynamicCompetitionSurveySubFeedbackId' | 'type' | 'status' | 'description' | 'time' | 'videoStatus' | 'videoUrl' | 'videoError' | 'impactedOutcome' | 'resolvedDate' | 'reopenedDate' | 'createdAt' | 'updatedAt'>
    & { submittedBy: (
      { __typename?: 'KeyIncidentSubmittedByInfo' }
      & Pick<KeyIncidentSubmittedByInfo, 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'teamId' | 'teamName' | 'teamLogo' | 'officialId' | 'assessmentObjectId' | 'assessmentObjectName'>
    ), competitionInfo: (
      { __typename?: 'KeyIncidentCompetitionInfo' }
      & Pick<KeyIncidentCompetitionInfo, 'competitionId' | 'externalId' | 'sport' | 'homeTeamId' | 'homeTeamName' | 'homeTeamScore' | 'homeTeamTieBreakerScore' | 'awayTeamId' | 'awayTeamName' | 'awayTeamScore' | 'awayTeamTieBreakerScore' | 'startTime' | 'timeZone' | 'location'>
    ), assessmentObject?: Maybe<(
      { __typename?: 'AssessmentObject' }
      & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name' | 'createdAt' | 'updatedAt'>
    )>, official?: Maybe<(
      { __typename?: 'Official' }
      & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
    )>, team?: Maybe<(
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'classification' | 'gender' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'timeZone' | 'website' | 'logo' | 'createdAt' | 'updatedAt'>
    )>, period?: Maybe<(
      { __typename?: 'Period' }
      & Pick<Period, 'id' | 'sport' | 'name' | 'createdAt' | 'updatedAt'>
    )> }
  ) }
);

export type AddKeyIncidentToCompetitionSurveyWithTokenMutationVariables = Exact<{
  surveyToken: Scalars['String'];
  description: Scalars['String'];
  incidentType: KeyIncidentTypes;
  impactedOutcome: Scalars['Boolean'];
  time?: Maybe<Scalars['String']>;
  sectionId?: Maybe<Scalars['String']>;
  periodId?: Maybe<Scalars['String']>;
  externalLinks: Array<CreateKeyIncidentExternalLinkForCompetitionSurveyInput> | CreateKeyIncidentExternalLinkForCompetitionSurveyInput;
}>;


export type AddKeyIncidentToCompetitionSurveyWithTokenMutation = (
  { __typename?: 'Mutation' }
  & { addKeyIncidentToCompetitionSurveyWithToken: (
    { __typename?: 'KeyIncident' }
    & Pick<KeyIncident, 'id' | 'competitionSurveyId' | 'dynamicCompetitionSurveyId' | 'organizationId' | 'organizationName' | 'leagueId' | 'leagueName' | 'seasonId' | 'seasonName' | 'competitionSurveyFeedbackId' | 'dynamicCompetitionSurveyFeedbackId' | 'dynamicCompetitionSurveySubFeedbackId' | 'type' | 'status' | 'description' | 'time' | 'videoStatus' | 'videoUrl' | 'videoError' | 'impactedOutcome' | 'resolvedDate' | 'reopenedDate' | 'createdAt' | 'updatedAt'>
    & { submittedBy: (
      { __typename?: 'KeyIncidentSubmittedByInfo' }
      & Pick<KeyIncidentSubmittedByInfo, 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'teamId' | 'teamName' | 'teamLogo' | 'officialId' | 'assessmentObjectId' | 'assessmentObjectName'>
    ), competitionInfo: (
      { __typename?: 'KeyIncidentCompetitionInfo' }
      & Pick<KeyIncidentCompetitionInfo, 'competitionId' | 'externalId' | 'sport' | 'homeTeamId' | 'homeTeamName' | 'homeTeamScore' | 'homeTeamTieBreakerScore' | 'awayTeamId' | 'awayTeamName' | 'awayTeamScore' | 'awayTeamTieBreakerScore' | 'startTime' | 'timeZone' | 'location'>
    ), assessmentObject?: Maybe<(
      { __typename?: 'AssessmentObject' }
      & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name' | 'createdAt' | 'updatedAt'>
    )>, official?: Maybe<(
      { __typename?: 'Official' }
      & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
    )>, team?: Maybe<(
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'classification' | 'gender' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'timeZone' | 'website' | 'logo' | 'createdAt' | 'updatedAt'>
    )>, period?: Maybe<(
      { __typename?: 'Period' }
      & Pick<Period, 'id' | 'sport' | 'name' | 'createdAt' | 'updatedAt'>
    )> }
  ) }
);

export type RemoveKeyIncidentFromCompetitionSurveyWithTokenMutationVariables = Exact<{
  surveyToken: Scalars['String'];
  keyIncidentId: Scalars['String'];
}>;


export type RemoveKeyIncidentFromCompetitionSurveyWithTokenMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeKeyIncidentFromCompetitionSurveyWithToken'>
);

export type EditKeyIncidentToCompetitionSurveyWithTokenMutationVariables = Exact<{
  surveyToken: Scalars['String'];
  keyIncidentId: Scalars['String'];
  description: Scalars['String'];
  incidentType: KeyIncidentTypes;
  impactedOutcome: Scalars['Boolean'];
  time?: Maybe<Scalars['String']>;
  periodId?: Maybe<Scalars['String']>;
}>;


export type EditKeyIncidentToCompetitionSurveyWithTokenMutation = (
  { __typename?: 'Mutation' }
  & { editKeyIncidentToCompetitionSurveyWithToken: (
    { __typename?: 'KeyIncident' }
    & Pick<KeyIncident, 'id' | 'competitionSurveyId' | 'dynamicCompetitionSurveyId' | 'organizationId' | 'organizationName' | 'leagueId' | 'leagueName' | 'seasonId' | 'seasonName' | 'competitionSurveyFeedbackId' | 'dynamicCompetitionSurveyFeedbackId' | 'dynamicCompetitionSurveySubFeedbackId' | 'type' | 'status' | 'description' | 'time' | 'videoStatus' | 'videoUrl' | 'videoError' | 'impactedOutcome' | 'resolvedDate' | 'reopenedDate' | 'createdAt' | 'updatedAt'>
    & { submittedBy: (
      { __typename?: 'KeyIncidentSubmittedByInfo' }
      & Pick<KeyIncidentSubmittedByInfo, 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'teamId' | 'teamName' | 'teamLogo' | 'officialId' | 'assessmentObjectId' | 'assessmentObjectName'>
    ), competitionInfo: (
      { __typename?: 'KeyIncidentCompetitionInfo' }
      & Pick<KeyIncidentCompetitionInfo, 'competitionId' | 'externalId' | 'sport' | 'homeTeamId' | 'homeTeamName' | 'homeTeamScore' | 'homeTeamTieBreakerScore' | 'awayTeamId' | 'awayTeamName' | 'awayTeamScore' | 'awayTeamTieBreakerScore' | 'startTime' | 'timeZone' | 'location'>
    ), assessmentObject?: Maybe<(
      { __typename?: 'AssessmentObject' }
      & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name' | 'createdAt' | 'updatedAt'>
    )>, official?: Maybe<(
      { __typename?: 'Official' }
      & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
    )>, team?: Maybe<(
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'classification' | 'gender' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'timeZone' | 'website' | 'logo' | 'createdAt' | 'updatedAt'>
    )>, period?: Maybe<(
      { __typename?: 'Period' }
      & Pick<Period, 'id' | 'sport' | 'name' | 'createdAt' | 'updatedAt'>
    )> }
  ) }
);

export type ListExternalLinksForKeyIncidentForCompetitionSurveyWithTokenQueryVariables = Exact<{
  surveyToken: Scalars['String'];
  keyIncidentId: Scalars['String'];
}>;


export type ListExternalLinksForKeyIncidentForCompetitionSurveyWithTokenQuery = (
  { __typename?: 'Query' }
  & { listExternalLinksForKeyIncidentForCompetitionSurveyWithToken: (
    { __typename?: 'KeyIncidentExternalLinks' }
    & { externalLinks: Array<(
      { __typename?: 'KeyIncidentExternalLink' }
      & Pick<KeyIncidentExternalLink, 'id' | 'keyIncidentId' | 'link' | 'type' | 'description' | 'createdByFirstName' | 'createdByLastName' | 'createdAt' | 'updatedAt'>
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type RemoveExternalLinkFromKeyIncidentFromCompetitionSurveyWithTokenMutationVariables = Exact<{
  surveyToken: Scalars['String'];
  externalLinkId: Scalars['String'];
}>;


export type RemoveExternalLinkFromKeyIncidentFromCompetitionSurveyWithTokenMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeExternalLinkFromKeyIncidentFromCompetitionSurveyWithToken'>
);

export type AddExternalLinkToKeyIncidentForCompetitionSurveyWithTokenMutationVariables = Exact<{
  surveyToken: Scalars['String'];
  keyIncidentId: Scalars['String'];
  link: Scalars['String'];
  type: KeyIncidentExternalLinkTypes;
  description?: Maybe<Scalars['String']>;
}>;


export type AddExternalLinkToKeyIncidentForCompetitionSurveyWithTokenMutation = (
  { __typename?: 'Mutation' }
  & { addExternalLinkToKeyIncidentForCompetitionSurveyWithToken: (
    { __typename?: 'KeyIncidentExternalLink' }
    & Pick<KeyIncidentExternalLink, 'id' | 'keyIncidentId' | 'link' | 'type' | 'description' | 'createdByFirstName' | 'createdByLastName' | 'createdAt' | 'updatedAt'>
  ) }
);

export type CompleteCompetitionSurveyWithTokenMutationVariables = Exact<{
  surveyToken: Scalars['String'];
}>;


export type CompleteCompetitionSurveyWithTokenMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'completeCompetitionSurveyWithToken'>
);

export type GetCompetitionSurveyByIdQueryVariables = Exact<{
  competitionSurveyId: Scalars['String'];
}>;


export type GetCompetitionSurveyByIdQuery = (
  { __typename?: 'Query' }
  & { getCompetitionSurveyById: (
    { __typename?: 'CompetitionSurvey' }
    & Pick<CompetitionSurvey, 'id' | 'surveyId' | 'orgId' | 'seasonId' | 'sport' | 'finished' | 'hasCompetitionVerification' | 'hasIncidentReport' | 'allowAdditionalIncidentReports' | 'allowAdditionalIncidentReportsCutoffDays' | 'createdAt' | 'updatedAt' | 'completedDate'>
    & { recipients: Array<(
      { __typename?: 'CompetitionSurveyUser' }
      & Pick<CompetitionSurveyUser, 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'tookSurvey'>
    )>, competition: (
      { __typename?: 'Competition' }
      & Pick<Competition, 'id' | 'externalId' | 'sport' | 'startTime' | 'timeZone' | 'location' | 'homeTeamScore' | 'homeTeamTieBreakerScore' | 'awayTeamScore' | 'awayTeamTieBreakerScore' | 'createdAt' | 'updatedAt'>
      & { homeTeam: (
        { __typename?: 'Team' }
        & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'classification' | 'gender' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'timeZone' | 'website' | 'logo' | 'createdAt' | 'updatedAt'>
      ), awayTeam: (
        { __typename?: 'Team' }
        & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'classification' | 'gender' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'timeZone' | 'website' | 'logo' | 'createdAt' | 'updatedAt'>
      ), organization: (
        { __typename?: 'Organization' }
        & Pick<Organization, 'id' | 'name' | 'sport' | 'status' | 'abbreviation' | 'site' | 'logo' | 'createdAt' | 'updatedAt'>
      ), season?: Maybe<(
        { __typename?: 'Season' }
        & Pick<Season, 'id' | 'leagueId' | 'leagueName' | 'leagueLogo' | 'name' | 'description' | 'status' | 'startDate' | 'endDate' | 'createdAt' | 'updatedAt'>
      )>, officials: Array<(
        { __typename?: 'CompetitionOfficial' }
        & Pick<CompetitionOfficial, 'id' | 'competitionId' | 'assessmentObjectId' | 'order' | 'type' | 'createdAt' | 'updatedAt'>
        & { official: (
          { __typename?: 'Official' }
          & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    ), info: (
      { __typename?: 'CompetitionSurveyInfo' }
      & Pick<CompetitionSurveyInfo, 'name' | 'description' | 'audience'>
    ), sections: Array<(
      { __typename?: 'CompetitionSurveySection' }
      & Pick<CompetitionSurveySection, 'id' | 'name' | 'description' | 'finished' | 'order' | 'createdAt' | 'updatedAt'>
      & { coach?: Maybe<(
        { __typename?: 'Coach' }
        & Pick<Coach, 'id' | 'teamId' | 'teamName' | 'teamAbbreviation' | 'sport' | 'teamCity' | 'userId' | 'type' | 'role' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
      )>, official?: Maybe<(
        { __typename?: 'Official' }
        & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
        & { externalIds?: Maybe<Array<(
          { __typename?: 'OfficialExternalId' }
          & Pick<OfficialExternalId, 'id' | 'organizationId' | 'externalId'>
        )>> }
      )>, organization?: Maybe<(
        { __typename?: 'Organization' }
        & Pick<Organization, 'id' | 'name' | 'sport' | 'status' | 'abbreviation' | 'site' | 'logo' | 'createdAt' | 'updatedAt'>
      )>, questions: Array<(
        { __typename?: 'CompetitionSurveySectionQuestion' }
        & Pick<CompetitionSurveySectionQuestion, 'id' | 'name' | 'description' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType' | 'selectedOption' | 'selectedOptionOrder' | 'responseShortAnswer' | 'responseText' | 'responseNumber'>
        & { responses?: Maybe<Array<(
          { __typename?: 'CompetitionSurveySectionQuestionMultipleResponse' }
          & Pick<CompetitionSurveySectionQuestionMultipleResponse, 'id' | 'value' | 'createdAt' | 'updatedAt'>
        )>>, options: Array<(
          { __typename?: 'CompetitionSurveySectionQuestionOption' }
          & Pick<CompetitionSurveySectionQuestionOption, 'id' | 'value' | 'order'>
        )> }
      )>, assessmentObject: (
        { __typename?: 'AssessmentObject' }
        & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name'>
      ) }
    )> }
  ) }
);

export type StartCompetitionSurveyMutationVariables = Exact<{
  competitionSurveyId: Scalars['String'];
}>;


export type StartCompetitionSurveyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'startCompetitionSurvey'>
);

export type UpdateCompetitionSurveyCompetitionScoreMutationVariables = Exact<{
  competitionSurveyId: Scalars['String'];
  homeTeamScore: Scalars['Int'];
  awayTeamScore: Scalars['Int'];
  homeTeamTieBreakerScore?: Maybe<Scalars['Int']>;
  awayTeamTieBreakerScore?: Maybe<Scalars['Int']>;
}>;


export type UpdateCompetitionSurveyCompetitionScoreMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateCompetitionSurveyCompetitionScore'>
);

export type UpdateCompetitionSurveyMultipleChoiceResponseMutationVariables = Exact<{
  competitionSurveyId: Scalars['String'];
  questionId: Scalars['String'];
  selectedOptionId: Scalars['String'];
}>;


export type UpdateCompetitionSurveyMultipleChoiceResponseMutation = (
  { __typename?: 'Mutation' }
  & { updateCompetitionSurveyMultipleChoiceResponse: (
    { __typename?: 'CompetitionSurveySectionQuestion' }
    & Pick<CompetitionSurveySectionQuestion, 'id' | 'name' | 'description' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType' | 'selectedOption' | 'selectedOptionOrder' | 'responseShortAnswer' | 'responseText' | 'responseNumber'>
    & { responses?: Maybe<Array<(
      { __typename?: 'CompetitionSurveySectionQuestionMultipleResponse' }
      & Pick<CompetitionSurveySectionQuestionMultipleResponse, 'id' | 'value' | 'createdAt' | 'updatedAt'>
    )>>, options: Array<(
      { __typename?: 'CompetitionSurveySectionQuestionOption' }
      & Pick<CompetitionSurveySectionQuestionOption, 'id' | 'value' | 'order'>
    )> }
  ) }
);

export type UpdateCompetitionSurveyShortAnswerResponseMutationVariables = Exact<{
  competitionSurveyId: Scalars['String'];
  questionId: Scalars['String'];
  response: Scalars['String'];
}>;


export type UpdateCompetitionSurveyShortAnswerResponseMutation = (
  { __typename?: 'Mutation' }
  & { updateCompetitionSurveyShortAnswerResponse: (
    { __typename?: 'CompetitionSurveySectionQuestion' }
    & Pick<CompetitionSurveySectionQuestion, 'id' | 'name' | 'description' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType' | 'selectedOption' | 'selectedOptionOrder' | 'responseShortAnswer' | 'responseText' | 'responseNumber'>
    & { responses?: Maybe<Array<(
      { __typename?: 'CompetitionSurveySectionQuestionMultipleResponse' }
      & Pick<CompetitionSurveySectionQuestionMultipleResponse, 'id' | 'value' | 'createdAt' | 'updatedAt'>
    )>>, options: Array<(
      { __typename?: 'CompetitionSurveySectionQuestionOption' }
      & Pick<CompetitionSurveySectionQuestionOption, 'id' | 'value' | 'order'>
    )> }
  ) }
);

export type UpdateCompetitionSurveyTextResponseMutationVariables = Exact<{
  competitionSurveyId: Scalars['String'];
  questionId: Scalars['String'];
  response: Scalars['String'];
}>;


export type UpdateCompetitionSurveyTextResponseMutation = (
  { __typename?: 'Mutation' }
  & { updateCompetitionSurveyTextResponse: (
    { __typename?: 'CompetitionSurveySectionQuestion' }
    & Pick<CompetitionSurveySectionQuestion, 'id' | 'name' | 'description' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType' | 'selectedOption' | 'selectedOptionOrder' | 'responseShortAnswer' | 'responseText' | 'responseNumber'>
    & { responses?: Maybe<Array<(
      { __typename?: 'CompetitionSurveySectionQuestionMultipleResponse' }
      & Pick<CompetitionSurveySectionQuestionMultipleResponse, 'id' | 'value' | 'createdAt' | 'updatedAt'>
    )>>, options: Array<(
      { __typename?: 'CompetitionSurveySectionQuestionOption' }
      & Pick<CompetitionSurveySectionQuestionOption, 'id' | 'value' | 'order'>
    )> }
  ) }
);

export type UpdateCompetitionSurveyNumberResponseMutationVariables = Exact<{
  competitionSurveyId: Scalars['String'];
  questionId: Scalars['String'];
  response: Scalars['Float'];
}>;


export type UpdateCompetitionSurveyNumberResponseMutation = (
  { __typename?: 'Mutation' }
  & { updateCompetitionSurveyNumberResponse: (
    { __typename?: 'CompetitionSurveySectionQuestion' }
    & Pick<CompetitionSurveySectionQuestion, 'id' | 'name' | 'description' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType' | 'selectedOption' | 'selectedOptionOrder' | 'responseShortAnswer' | 'responseText' | 'responseNumber'>
    & { responses?: Maybe<Array<(
      { __typename?: 'CompetitionSurveySectionQuestionMultipleResponse' }
      & Pick<CompetitionSurveySectionQuestionMultipleResponse, 'id' | 'value' | 'createdAt' | 'updatedAt'>
    )>>, options: Array<(
      { __typename?: 'CompetitionSurveySectionQuestionOption' }
      & Pick<CompetitionSurveySectionQuestionOption, 'id' | 'value' | 'order'>
    )> }
  ) }
);

export type AddCompetitionSurveyMultipleResponseWithTokenMutationVariables = Exact<{
  surveyToken: Scalars['String'];
  questionId: Scalars['String'];
  response: Scalars['String'];
}>;


export type AddCompetitionSurveyMultipleResponseWithTokenMutation = (
  { __typename?: 'Mutation' }
  & { addCompetitionSurveyMultipleResponseWithToken: (
    { __typename?: 'CompetitionSurveySectionQuestionMultipleResponse' }
    & Pick<CompetitionSurveySectionQuestionMultipleResponse, 'id' | 'value' | 'createdAt' | 'updatedAt'>
  ) }
);

export type RemoveCompetitionSurveyMultipleResponseWithTokenMutationVariables = Exact<{
  surveyToken: Scalars['String'];
  questionId: Scalars['String'];
  responseId: Scalars['String'];
}>;


export type RemoveCompetitionSurveyMultipleResponseWithTokenMutation = (
  { __typename?: 'Mutation' }
  & { removeCompetitionSurveyMultipleResponseWithToken: (
    { __typename?: 'CompetitionSurveySectionQuestion' }
    & Pick<CompetitionSurveySectionQuestion, 'id' | 'name' | 'description' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType' | 'selectedOption' | 'selectedOptionOrder' | 'responseShortAnswer' | 'responseText' | 'responseNumber'>
    & { responses?: Maybe<Array<(
      { __typename?: 'CompetitionSurveySectionQuestionMultipleResponse' }
      & Pick<CompetitionSurveySectionQuestionMultipleResponse, 'id' | 'value' | 'createdAt' | 'updatedAt'>
    )>>, options: Array<(
      { __typename?: 'CompetitionSurveySectionQuestionOption' }
      & Pick<CompetitionSurveySectionQuestionOption, 'id' | 'value' | 'order'>
    )> }
  ) }
);

export type AddCompetitionSurveyMultipleResponseMutationVariables = Exact<{
  competitionSurveyId: Scalars['String'];
  questionId: Scalars['String'];
  response: Scalars['String'];
}>;


export type AddCompetitionSurveyMultipleResponseMutation = (
  { __typename?: 'Mutation' }
  & { addCompetitionSurveyMultipleResponse: (
    { __typename?: 'CompetitionSurveySectionQuestionMultipleResponse' }
    & Pick<CompetitionSurveySectionQuestionMultipleResponse, 'id' | 'value' | 'createdAt' | 'updatedAt'>
  ) }
);

export type RemoveCompetitionSurveyMultipleResponseMutationVariables = Exact<{
  competitionSurveyId: Scalars['String'];
  questionId: Scalars['String'];
  responseId: Scalars['String'];
}>;


export type RemoveCompetitionSurveyMultipleResponseMutation = (
  { __typename?: 'Mutation' }
  & { removeCompetitionSurveyMultipleResponse: (
    { __typename?: 'CompetitionSurveySectionQuestion' }
    & Pick<CompetitionSurveySectionQuestion, 'id' | 'name' | 'description' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType' | 'selectedOption' | 'selectedOptionOrder' | 'responseShortAnswer' | 'responseText' | 'responseNumber'>
    & { responses?: Maybe<Array<(
      { __typename?: 'CompetitionSurveySectionQuestionMultipleResponse' }
      & Pick<CompetitionSurveySectionQuestionMultipleResponse, 'id' | 'value' | 'createdAt' | 'updatedAt'>
    )>>, options: Array<(
      { __typename?: 'CompetitionSurveySectionQuestionOption' }
      & Pick<CompetitionSurveySectionQuestionOption, 'id' | 'value' | 'order'>
    )> }
  ) }
);

export type ListKeyIncidentsForCompetitionSurveyQueryVariables = Exact<{
  competitionSurveyId: Scalars['String'];
}>;


export type ListKeyIncidentsForCompetitionSurveyQuery = (
  { __typename?: 'Query' }
  & { listKeyIncidentsForCompetitionSurvey: (
    { __typename?: 'KeyIncidents' }
    & { keyIncidents: Array<(
      { __typename?: 'KeyIncident' }
      & Pick<KeyIncident, 'id' | 'competitionSurveyId' | 'organizationId' | 'organizationName' | 'leagueId' | 'leagueName' | 'seasonId' | 'seasonName' | 'competitionSurveyFeedbackId' | 'dynamicCompetitionSurveyFeedbackId' | 'dynamicCompetitionSurveySubFeedbackId' | 'type' | 'status' | 'description' | 'time' | 'videoStatus' | 'videoUrl' | 'videoError' | 'impactedOutcome' | 'resolvedDate' | 'reopenedDate' | 'createdAt' | 'updatedAt'>
      & { submittedBy: (
        { __typename?: 'KeyIncidentSubmittedByInfo' }
        & Pick<KeyIncidentSubmittedByInfo, 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'teamId' | 'teamName' | 'teamLogo' | 'officialId' | 'assessmentObjectId' | 'assessmentObjectName'>
      ), competitionInfo: (
        { __typename?: 'KeyIncidentCompetitionInfo' }
        & Pick<KeyIncidentCompetitionInfo, 'competitionId' | 'externalId' | 'sport' | 'homeTeamId' | 'homeTeamName' | 'homeTeamScore' | 'homeTeamTieBreakerScore' | 'awayTeamId' | 'awayTeamName' | 'awayTeamScore' | 'awayTeamTieBreakerScore' | 'startTime' | 'timeZone' | 'location'>
      ), assessmentObject?: Maybe<(
        { __typename?: 'AssessmentObject' }
        & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name' | 'createdAt' | 'updatedAt'>
      )>, official?: Maybe<(
        { __typename?: 'Official' }
        & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
      )>, team?: Maybe<(
        { __typename?: 'Team' }
        & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'classification' | 'gender' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'timeZone' | 'website' | 'logo' | 'createdAt' | 'updatedAt'>
      )>, period?: Maybe<(
        { __typename?: 'Period' }
        & Pick<Period, 'id' | 'sport' | 'name' | 'createdAt' | 'updatedAt'>
      )> }
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type ConvertCompetitionSurveySectionFeedbackToKeyIncidentMutationVariables = Exact<{
  competitionSurveyId: Scalars['String'];
  questionId: Scalars['String'];
  feedbackResponseId: Scalars['String'];
  incidentType: KeyIncidentTypes;
  impactedOutcome: Scalars['Boolean'];
  time?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  periodId?: Maybe<Scalars['String']>;
  externalLinks: Array<CreateKeyIncidentExternalLinkForCompetitionSurveyInput> | CreateKeyIncidentExternalLinkForCompetitionSurveyInput;
}>;


export type ConvertCompetitionSurveySectionFeedbackToKeyIncidentMutation = (
  { __typename?: 'Mutation' }
  & { convertCompetitionSurveySectionFeedbackToKeyIncident: (
    { __typename?: 'KeyIncident' }
    & Pick<KeyIncident, 'id' | 'competitionSurveyId' | 'organizationId' | 'organizationName' | 'leagueId' | 'leagueName' | 'seasonId' | 'seasonName' | 'competitionSurveyFeedbackId' | 'dynamicCompetitionSurveyFeedbackId' | 'dynamicCompetitionSurveySubFeedbackId' | 'type' | 'status' | 'description' | 'time' | 'videoStatus' | 'videoUrl' | 'videoError' | 'impactedOutcome' | 'resolvedDate' | 'reopenedDate' | 'createdAt' | 'updatedAt'>
    & { submittedBy: (
      { __typename?: 'KeyIncidentSubmittedByInfo' }
      & Pick<KeyIncidentSubmittedByInfo, 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'teamId' | 'teamName' | 'teamLogo' | 'officialId' | 'assessmentObjectId' | 'assessmentObjectName'>
    ), competitionInfo: (
      { __typename?: 'KeyIncidentCompetitionInfo' }
      & Pick<KeyIncidentCompetitionInfo, 'competitionId' | 'externalId' | 'sport' | 'homeTeamId' | 'homeTeamName' | 'homeTeamScore' | 'homeTeamTieBreakerScore' | 'awayTeamId' | 'awayTeamName' | 'awayTeamScore' | 'awayTeamTieBreakerScore' | 'startTime' | 'timeZone' | 'location'>
    ), assessmentObject?: Maybe<(
      { __typename?: 'AssessmentObject' }
      & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name' | 'createdAt' | 'updatedAt'>
    )>, official?: Maybe<(
      { __typename?: 'Official' }
      & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
    )>, team?: Maybe<(
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'classification' | 'gender' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'timeZone' | 'website' | 'logo' | 'createdAt' | 'updatedAt'>
    )>, period?: Maybe<(
      { __typename?: 'Period' }
      & Pick<Period, 'id' | 'sport' | 'name' | 'createdAt' | 'updatedAt'>
    )> }
  ) }
);

export type AddKeyIncidentToCompetitionSurveyMutationVariables = Exact<{
  competitionSurveyId: Scalars['String'];
  description: Scalars['String'];
  incidentType: KeyIncidentTypes;
  impactedOutcome: Scalars['Boolean'];
  time?: Maybe<Scalars['String']>;
  sectionId?: Maybe<Scalars['String']>;
  periodId?: Maybe<Scalars['String']>;
  externalLinks: Array<CreateKeyIncidentExternalLinkForCompetitionSurveyInput> | CreateKeyIncidentExternalLinkForCompetitionSurveyInput;
}>;


export type AddKeyIncidentToCompetitionSurveyMutation = (
  { __typename?: 'Mutation' }
  & { addKeyIncidentToCompetitionSurvey: (
    { __typename?: 'KeyIncident' }
    & Pick<KeyIncident, 'id' | 'competitionSurveyId' | 'organizationId' | 'organizationName' | 'leagueId' | 'leagueName' | 'seasonId' | 'seasonName' | 'competitionSurveyFeedbackId' | 'dynamicCompetitionSurveyFeedbackId' | 'dynamicCompetitionSurveySubFeedbackId' | 'type' | 'status' | 'description' | 'time' | 'videoStatus' | 'videoUrl' | 'videoError' | 'impactedOutcome' | 'resolvedDate' | 'reopenedDate' | 'createdAt' | 'updatedAt'>
    & { submittedBy: (
      { __typename?: 'KeyIncidentSubmittedByInfo' }
      & Pick<KeyIncidentSubmittedByInfo, 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'teamId' | 'teamName' | 'teamLogo' | 'officialId' | 'assessmentObjectId' | 'assessmentObjectName'>
    ), competitionInfo: (
      { __typename?: 'KeyIncidentCompetitionInfo' }
      & Pick<KeyIncidentCompetitionInfo, 'competitionId' | 'externalId' | 'sport' | 'homeTeamId' | 'homeTeamName' | 'homeTeamScore' | 'homeTeamTieBreakerScore' | 'awayTeamId' | 'awayTeamName' | 'awayTeamScore' | 'awayTeamTieBreakerScore' | 'startTime' | 'timeZone' | 'location'>
    ), assessmentObject?: Maybe<(
      { __typename?: 'AssessmentObject' }
      & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name' | 'createdAt' | 'updatedAt'>
    )>, official?: Maybe<(
      { __typename?: 'Official' }
      & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
    )>, team?: Maybe<(
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'classification' | 'gender' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'timeZone' | 'website' | 'logo' | 'createdAt' | 'updatedAt'>
    )>, period?: Maybe<(
      { __typename?: 'Period' }
      & Pick<Period, 'id' | 'sport' | 'name' | 'createdAt' | 'updatedAt'>
    )> }
  ) }
);

export type EditKeyIncidentToCompetitionSurveyMutationVariables = Exact<{
  competitionSurveyId: Scalars['String'];
  keyIncidentId: Scalars['String'];
  description: Scalars['String'];
  incidentType: KeyIncidentTypes;
  impactedOutcome: Scalars['Boolean'];
  time?: Maybe<Scalars['String']>;
  periodId?: Maybe<Scalars['String']>;
}>;


export type EditKeyIncidentToCompetitionSurveyMutation = (
  { __typename?: 'Mutation' }
  & { editKeyIncidentToCompetitionSurvey: (
    { __typename?: 'KeyIncident' }
    & Pick<KeyIncident, 'id' | 'competitionSurveyId' | 'organizationId' | 'organizationName' | 'leagueId' | 'leagueName' | 'seasonId' | 'seasonName' | 'competitionSurveyFeedbackId' | 'dynamicCompetitionSurveyFeedbackId' | 'dynamicCompetitionSurveySubFeedbackId' | 'type' | 'status' | 'description' | 'time' | 'videoStatus' | 'videoUrl' | 'videoError' | 'impactedOutcome' | 'resolvedDate' | 'reopenedDate' | 'createdAt' | 'updatedAt'>
    & { submittedBy: (
      { __typename?: 'KeyIncidentSubmittedByInfo' }
      & Pick<KeyIncidentSubmittedByInfo, 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'teamId' | 'teamName' | 'teamLogo' | 'officialId' | 'assessmentObjectId' | 'assessmentObjectName'>
    ), competitionInfo: (
      { __typename?: 'KeyIncidentCompetitionInfo' }
      & Pick<KeyIncidentCompetitionInfo, 'competitionId' | 'externalId' | 'sport' | 'homeTeamId' | 'homeTeamName' | 'homeTeamScore' | 'homeTeamTieBreakerScore' | 'awayTeamId' | 'awayTeamName' | 'awayTeamScore' | 'awayTeamTieBreakerScore' | 'startTime' | 'timeZone' | 'location'>
    ), assessmentObject?: Maybe<(
      { __typename?: 'AssessmentObject' }
      & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name' | 'createdAt' | 'updatedAt'>
    )>, official?: Maybe<(
      { __typename?: 'Official' }
      & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
    )>, team?: Maybe<(
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'classification' | 'gender' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'timeZone' | 'website' | 'logo' | 'createdAt' | 'updatedAt'>
    )>, period?: Maybe<(
      { __typename?: 'Period' }
      & Pick<Period, 'id' | 'sport' | 'name' | 'createdAt' | 'updatedAt'>
    )> }
  ) }
);

export type RemoveKeyIncidentFromCompetitionSurveyMutationVariables = Exact<{
  competitionSurveyId: Scalars['String'];
  keyIncidentId: Scalars['String'];
}>;


export type RemoveKeyIncidentFromCompetitionSurveyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeKeyIncidentFromCompetitionSurvey'>
);

export type ListExternalLinksForKeyIncidentForCompetitionSurveyQueryVariables = Exact<{
  competitionSurveyId: Scalars['String'];
  keyIncidentId: Scalars['String'];
}>;


export type ListExternalLinksForKeyIncidentForCompetitionSurveyQuery = (
  { __typename?: 'Query' }
  & { listExternalLinksForKeyIncidentForCompetitionSurvey: (
    { __typename?: 'KeyIncidentExternalLinks' }
    & { externalLinks: Array<(
      { __typename?: 'KeyIncidentExternalLink' }
      & Pick<KeyIncidentExternalLink, 'id' | 'keyIncidentId' | 'link' | 'type' | 'description' | 'createdByFirstName' | 'createdByLastName' | 'createdAt' | 'updatedAt'>
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type RemoveExternalLinkFromKeyIncidentFromCompetitionSurveyMutationVariables = Exact<{
  competitionSurveyId: Scalars['String'];
  externalLinkId: Scalars['String'];
}>;


export type RemoveExternalLinkFromKeyIncidentFromCompetitionSurveyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeExternalLinkFromKeyIncidentFromCompetitionSurvey'>
);

export type AddExternalLinkToKeyIncidentToCompetitionSurveyMutationVariables = Exact<{
  competitionSurveyId: Scalars['String'];
  keyIncidentId: Scalars['String'];
  link: Scalars['String'];
  type: KeyIncidentExternalLinkTypes;
  description?: Maybe<Scalars['String']>;
}>;


export type AddExternalLinkToKeyIncidentToCompetitionSurveyMutation = (
  { __typename?: 'Mutation' }
  & { addExternalLinkToKeyIncidentToCompetitionSurvey: (
    { __typename?: 'KeyIncidentExternalLink' }
    & Pick<KeyIncidentExternalLink, 'id' | 'keyIncidentId' | 'link' | 'type' | 'description' | 'createdByFirstName' | 'createdByLastName' | 'createdAt' | 'updatedAt'>
  ) }
);

export type CompleteCompetitionSurveyMutationVariables = Exact<{
  competitionSurveyId: Scalars['String'];
}>;


export type CompleteCompetitionSurveyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'completeCompetitionSurvey'>
);

export type ListCompetitionSurveyResponsesForOrganizationQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  orgId: Scalars['String'];
  leagueId: Scalars['String'];
  seasonId: Scalars['String'];
  surveyId: Scalars['String'];
}>;


export type ListCompetitionSurveyResponsesForOrganizationQuery = (
  { __typename?: 'Query' }
  & { listCompetitionSurveyResponsesForOrganization: (
    { __typename?: 'CompetitionSurveyResponses' }
    & { responses: Array<(
      { __typename?: 'CompetitionSurveyResponse' }
      & Pick<CompetitionSurveyResponse, 'id' | 'completedDate' | 'teamId' | 'teamName' | 'createdAt' | 'updatedAt'>
      & { orgInfo: (
        { __typename?: 'CompetitionSurveyResponseOrgInfo' }
        & Pick<CompetitionSurveyResponseOrgInfo, 'sport' | 'orgId' | 'orgName' | 'leagueId' | 'leagueName' | 'seasonId' | 'seasonName'>
      ), surveyInfo: (
        { __typename?: 'CompetitionSurveyResponseSurveyInfo' }
        & Pick<CompetitionSurveyResponseSurveyInfo, 'surveyId' | 'surveyName' | 'audience'>
      ), competitionInfo: (
        { __typename?: 'CompetitionSurveyResponseCompetitionInfo' }
        & Pick<CompetitionSurveyResponseCompetitionInfo, 'competitionId' | 'competitionDate' | 'homeTeamId' | 'homeTeam' | 'homeTeamScore' | 'homeTeamTieBreakerScore' | 'awayTeamId' | 'awayTeam' | 'awayTeamScore' | 'awayTeamTieBreakerScore'>
      ), userInfo?: Maybe<(
        { __typename?: 'CompetitionSurveyResponseUserInfo' }
        & Pick<CompetitionSurveyResponseUserInfo, 'userId' | 'coachId' | 'email' | 'firstName' | 'lastName' | 'profilePic'>
      )>, sections: Array<(
        { __typename?: 'CompetitionSurveyResponseSection' }
        & Pick<CompetitionSurveyResponseSection, 'id' | 'name' | 'description' | 'order' | 'createdAt' | 'updatedAt'>
        & { assessmentObject: (
          { __typename?: 'AssessmentObject' }
          & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name'>
        ), official?: Maybe<(
          { __typename?: 'Official' }
          & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
          & { externalIds?: Maybe<Array<(
            { __typename?: 'OfficialExternalId' }
            & Pick<OfficialExternalId, 'id' | 'organizationId' | 'externalId'>
          )>> }
        )>, coach?: Maybe<(
          { __typename?: 'Coach' }
          & Pick<Coach, 'id' | 'teamId' | 'teamName' | 'teamAbbreviation' | 'sport' | 'teamCity' | 'userId' | 'type' | 'role' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
        )>, organization?: Maybe<(
          { __typename?: 'Organization' }
          & Pick<Organization, 'id' | 'name' | 'sport' | 'status' | 'abbreviation' | 'site' | 'logo' | 'createdAt' | 'updatedAt'>
        )>, questions: Array<(
          { __typename?: 'CompetitionSurveyResponseSectionQuestion' }
          & Pick<CompetitionSurveyResponseSectionQuestion, 'id' | 'name' | 'description' | 'order' | 'questionType' | 'selectedOption' | 'selectedOptionOrder' | 'responseShortAnswer' | 'responseText' | 'responseNumber' | 'responses'>
        )> }
      )> }
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type ListSurveyResponsesForCompetitionAndSurveyQueryVariables = Exact<{
  orgId: Scalars['String'];
  competitionId: Scalars['String'];
  surveyId: Scalars['String'];
}>;


export type ListSurveyResponsesForCompetitionAndSurveyQuery = (
  { __typename?: 'Query' }
  & { listSurveyResponsesForCompetitionAndSurvey: (
    { __typename?: 'SurveyResponsesForCompetition' }
    & { orgInfo: (
      { __typename?: 'CompetitionSurveyResponseOrgInfo' }
      & Pick<CompetitionSurveyResponseOrgInfo, 'sport' | 'orgId' | 'orgName' | 'leagueId' | 'leagueName' | 'seasonId' | 'seasonName'>
    ), surveyInfo: (
      { __typename?: 'CompetitionSurveyResponseSurveyInfo' }
      & Pick<CompetitionSurveyResponseSurveyInfo, 'surveyId' | 'surveyName' | 'audience'>
    ), competitionInfo: (
      { __typename?: 'CompetitionSurveyResponseCompetitionInfo' }
      & Pick<CompetitionSurveyResponseCompetitionInfo, 'competitionId' | 'competitionDate' | 'externalId' | 'homeTeamId' | 'homeTeam' | 'homeTeamAbbreviation' | 'homeTeamScore' | 'homeTeamTieBreakerScore' | 'awayTeamId' | 'awayTeam' | 'awayTeamAbbreviation' | 'awayTeamScore' | 'awayTeamTieBreakerScore'>
    ), responses: Array<(
      { __typename?: 'SurveyResponseForCompetition' }
      & Pick<SurveyResponseForCompetition, 'id' | 'completedDate' | 'teamId' | 'teamName' | 'createdAt' | 'updatedAt'>
      & { submittedBy?: Maybe<(
        { __typename?: 'CompetitionSurveyResponseUserInfo' }
        & Pick<CompetitionSurveyResponseUserInfo, 'userId' | 'coachId' | 'email' | 'firstName' | 'lastName' | 'profilePic'>
      )>, sections: Array<(
        { __typename?: 'CompetitionSurveyResponseSection' }
        & Pick<CompetitionSurveyResponseSection, 'id' | 'name' | 'description' | 'order' | 'createdAt' | 'updatedAt'>
        & { assessmentObject: (
          { __typename?: 'AssessmentObject' }
          & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name'>
        ), official?: Maybe<(
          { __typename?: 'Official' }
          & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
          & { externalIds?: Maybe<Array<(
            { __typename?: 'OfficialExternalId' }
            & Pick<OfficialExternalId, 'id' | 'organizationId' | 'externalId'>
          )>> }
        )>, coach?: Maybe<(
          { __typename?: 'Coach' }
          & Pick<Coach, 'id' | 'teamId' | 'teamName' | 'teamAbbreviation' | 'sport' | 'teamCity' | 'userId' | 'type' | 'role' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
        )>, organization?: Maybe<(
          { __typename?: 'Organization' }
          & Pick<Organization, 'id' | 'name' | 'sport' | 'status' | 'abbreviation' | 'site' | 'logo' | 'createdAt' | 'updatedAt'>
        )>, questions: Array<(
          { __typename?: 'CompetitionSurveyResponseSectionQuestion' }
          & Pick<CompetitionSurveyResponseSectionQuestion, 'id' | 'name' | 'description' | 'order' | 'questionType' | 'selectedOption' | 'selectedOptionOrder' | 'responseShortAnswer' | 'responseText' | 'responseNumber' | 'responses'>
        )> }
      )> }
    )> }
  ) }
);

export type ListMyCompetitionSurveysQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}>;


export type ListMyCompetitionSurveysQuery = (
  { __typename?: 'Query' }
  & { listMyCompetitionSurveys: (
    { __typename?: 'CompetitionSurveySummaries' }
    & { competitionSurveys: Array<(
      { __typename?: 'CompetitionSurveySummary' }
      & Pick<CompetitionSurveySummary, 'competitionSurveyId' | 'surveyName' | 'finished' | 'completedDate' | 'createdAt' | 'teamId' | 'teamName' | 'teamLogo' | 'competitionId' | 'startTime' | 'homeTeamId' | 'homeTeamName' | 'homeTeamLogo' | 'awayTeamId' | 'awayTeamName' | 'awayTeamLogo' | 'tookSurvey' | 'tookSurveyUserId' | 'averageScore' | 'keyIncidentsCount' | 'isDynamic' | 'completedPercentage'>
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type ListMyCompetitionTeamKitsQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  fromDate?: Maybe<Scalars['String']>;
  toDate?: Maybe<Scalars['String']>;
  teamSearch?: Maybe<Scalars['String']>;
  statuses?: Maybe<Array<CompetitionTeamKitStatus> | CompetitionTeamKitStatus>;
}>;


export type ListMyCompetitionTeamKitsQuery = (
  { __typename?: 'Query' }
  & { listMyCompetitionTeamKits: (
    { __typename?: 'CompetitionTeamKits' }
    & { competitionTeamKits: Array<(
      { __typename?: 'CompetitionTeamKit' }
      & Pick<CompetitionTeamKit, 'id' | 'organizationId' | 'organizationName' | 'seasonId' | 'seasonName' | 'seasonTeamKitId' | 'competitionId' | 'startTime' | 'timeZone' | 'homeTeamId' | 'homeTeamName' | 'homeTeamLogo' | 'awayTeamId' | 'awayTeamName' | 'awayTeamLogo' | 'status' | 'completedDate' | 'createdAt' | 'updatedAt'>
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type ListCompetitionTeamKitsForOrganizationQueryVariables = Exact<{
  orgId: Scalars['String'];
  leagueId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  fromDate?: Maybe<Scalars['String']>;
  toDate?: Maybe<Scalars['String']>;
  teamSearch?: Maybe<Scalars['String']>;
  statuses?: Maybe<Array<CompetitionTeamKitStatus> | CompetitionTeamKitStatus>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}>;


export type ListCompetitionTeamKitsForOrganizationQuery = (
  { __typename?: 'Query' }
  & { listCompetitionTeamKitsForOrganization: (
    { __typename?: 'CompetitionTeamKits' }
    & { competitionTeamKits: Array<(
      { __typename?: 'CompetitionTeamKit' }
      & Pick<CompetitionTeamKit, 'id' | 'organizationId' | 'organizationName' | 'seasonId' | 'seasonName' | 'seasonTeamKitId' | 'competitionId' | 'startTime' | 'timeZone' | 'homeTeamId' | 'homeTeamName' | 'homeTeamLogo' | 'awayTeamId' | 'awayTeamName' | 'awayTeamLogo' | 'status' | 'completedDate' | 'createdAt' | 'updatedAt'>
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type ListAvailableContactForCompetitionTeamKitContactQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  competitionTeamKitContactId: Scalars['String'];
}>;


export type ListAvailableContactForCompetitionTeamKitContactQuery = (
  { __typename?: 'Query' }
  & { listAvailableContactForCompetitionTeamKitContact: (
    { __typename?: 'SeasonTeamKitContactOptions' }
    & Pick<SeasonTeamKitContactOptions, 'seasonId'>
    & { options: Array<(
      { __typename?: 'SeasonTeamKitContactOption' }
      & Pick<SeasonTeamKitContactOption, 'id' | 'seasonId' | 'definitionId' | 'orgContactId' | 'teamContactId' | 'isDefault' | 'contact' | 'email' | 'phone' | 'firstName' | 'lastName'>
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type GetCompetitionTeamKitByIdQueryVariables = Exact<{
  competitionTeamKitId: Scalars['String'];
}>;


export type GetCompetitionTeamKitByIdQuery = (
  { __typename?: 'Query' }
  & { getCompetitionTeamKitById: (
    { __typename?: 'CompetitionTeamKit' }
    & Pick<CompetitionTeamKit, 'id' | 'organizationId' | 'organizationName' | 'seasonId' | 'seasonName' | 'seasonTeamKitId' | 'competitionId' | 'startTime' | 'timeZone' | 'homeTeamId' | 'homeTeamName' | 'homeTeamLogo' | 'awayTeamId' | 'awayTeamName' | 'awayTeamLogo' | 'status' | 'completedDate' | 'createdAt' | 'updatedAt'>
  ) }
);

export type SendCompetitionTeamKitToHomeTeamEditMutationVariables = Exact<{
  competitionTeamKitId: Scalars['String'];
}>;


export type SendCompetitionTeamKitToHomeTeamEditMutation = (
  { __typename?: 'Mutation' }
  & { sendCompetitionTeamKitToHomeTeamEdit: (
    { __typename?: 'CompetitionTeamKit' }
    & Pick<CompetitionTeamKit, 'id' | 'organizationId' | 'organizationName' | 'seasonId' | 'seasonName' | 'seasonTeamKitId' | 'competitionId' | 'startTime' | 'timeZone' | 'homeTeamId' | 'homeTeamName' | 'homeTeamLogo' | 'awayTeamId' | 'awayTeamName' | 'awayTeamLogo' | 'status' | 'completedDate' | 'createdAt' | 'updatedAt'>
  ) }
);

export type SendCompetitionTeamKitToAwayTeamEditMutationVariables = Exact<{
  competitionTeamKitId: Scalars['String'];
}>;


export type SendCompetitionTeamKitToAwayTeamEditMutation = (
  { __typename?: 'Mutation' }
  & { sendCompetitionTeamKitToAwayTeamEdit: (
    { __typename?: 'CompetitionTeamKit' }
    & Pick<CompetitionTeamKit, 'id' | 'organizationId' | 'organizationName' | 'seasonId' | 'seasonName' | 'seasonTeamKitId' | 'competitionId' | 'startTime' | 'timeZone' | 'homeTeamId' | 'homeTeamName' | 'homeTeamLogo' | 'awayTeamId' | 'awayTeamName' | 'awayTeamLogo' | 'status' | 'completedDate' | 'createdAt' | 'updatedAt'>
  ) }
);

export type SendCompetitionTeamKitToLeagueEditMutationVariables = Exact<{
  competitionTeamKitId: Scalars['String'];
}>;


export type SendCompetitionTeamKitToLeagueEditMutation = (
  { __typename?: 'Mutation' }
  & { sendCompetitionTeamKitToLeagueEdit: (
    { __typename?: 'CompetitionTeamKit' }
    & Pick<CompetitionTeamKit, 'id' | 'organizationId' | 'organizationName' | 'seasonId' | 'seasonName' | 'seasonTeamKitId' | 'competitionId' | 'startTime' | 'timeZone' | 'homeTeamId' | 'homeTeamName' | 'homeTeamLogo' | 'awayTeamId' | 'awayTeamName' | 'awayTeamLogo' | 'status' | 'completedDate' | 'createdAt' | 'updatedAt'>
  ) }
);

export type SendCompetitionTeamKitRequestedFixToLeagueEditMutationVariables = Exact<{
  competitionTeamKitId: Scalars['String'];
  forHomeTeam: Scalars['Boolean'];
}>;


export type SendCompetitionTeamKitRequestedFixToLeagueEditMutation = (
  { __typename?: 'Mutation' }
  & { sendCompetitionTeamKitRequestedFixToLeagueEdit: (
    { __typename?: 'CompetitionTeamKit' }
    & Pick<CompetitionTeamKit, 'id' | 'organizationId' | 'organizationName' | 'seasonId' | 'seasonName' | 'seasonTeamKitId' | 'competitionId' | 'startTime' | 'timeZone' | 'homeTeamId' | 'homeTeamName' | 'homeTeamLogo' | 'awayTeamId' | 'awayTeamName' | 'awayTeamLogo' | 'status' | 'completedDate' | 'createdAt' | 'updatedAt'>
  ) }
);

export type CompetitionTeamKitApparelApprovalMutationVariables = Exact<{
  competitionTeamKitApparelId: Scalars['String'];
  approved: Scalars['Boolean'];
}>;


export type CompetitionTeamKitApparelApprovalMutation = (
  { __typename?: 'Mutation' }
  & { competitionTeamKitApparelApproval: (
    { __typename?: 'CompetitionTeamKitApparel' }
    & Pick<CompetitionTeamKitApparel, 'id' | 'competitionTeamKitId' | 'type' | 'status' | 'seasonKitRoleId' | 'roleType' | 'roleName' | 'roleOrder' | 'seasonKitApparelDefinitionId' | 'definitionName' | 'definitionOrder' | 'seasonApparelId' | 'name' | 'color' | 'image' | 'createdAt' | 'updatedAt'>
  ) }
);

export type ApproveCompetitionTeamKitMutationVariables = Exact<{
  competitionTeamKitId: Scalars['String'];
}>;


export type ApproveCompetitionTeamKitMutation = (
  { __typename?: 'Mutation' }
  & { approveCompetitionTeamKit: (
    { __typename?: 'CompetitionTeamKit' }
    & Pick<CompetitionTeamKit, 'id' | 'organizationId' | 'organizationName' | 'seasonId' | 'seasonName' | 'seasonTeamKitId' | 'competitionId' | 'startTime' | 'timeZone' | 'homeTeamId' | 'homeTeamName' | 'homeTeamLogo' | 'awayTeamId' | 'awayTeamName' | 'awayTeamLogo' | 'status' | 'completedDate' | 'createdAt' | 'updatedAt'>
  ) }
);

export type CompetitionTeamKitRequestFixMutationVariables = Exact<{
  competitionTeamKitId: Scalars['String'];
  forHomeTeam: Scalars['Boolean'];
}>;


export type CompetitionTeamKitRequestFixMutation = (
  { __typename?: 'Mutation' }
  & { competitionTeamKitRequestFix: (
    { __typename?: 'CompetitionTeamKit' }
    & Pick<CompetitionTeamKit, 'id' | 'organizationId' | 'organizationName' | 'seasonId' | 'seasonName' | 'seasonTeamKitId' | 'competitionId' | 'startTime' | 'timeZone' | 'homeTeamId' | 'homeTeamName' | 'homeTeamLogo' | 'awayTeamId' | 'awayTeamName' | 'awayTeamLogo' | 'status' | 'completedDate' | 'createdAt' | 'updatedAt'>
  ) }
);

export type ReopenCompetitionTeamKitMutationVariables = Exact<{
  competitionTeamKitId: Scalars['String'];
}>;


export type ReopenCompetitionTeamKitMutation = (
  { __typename?: 'Mutation' }
  & { reopenCompetitionTeamKit: (
    { __typename?: 'CompetitionTeamKit' }
    & Pick<CompetitionTeamKit, 'id' | 'organizationId' | 'organizationName' | 'seasonId' | 'seasonName' | 'seasonTeamKitId' | 'competitionId' | 'startTime' | 'timeZone' | 'homeTeamId' | 'homeTeamName' | 'homeTeamLogo' | 'awayTeamId' | 'awayTeamName' | 'awayTeamLogo' | 'status' | 'completedDate' | 'createdAt' | 'updatedAt'>
  ) }
);

export type ListCompetitionTeamKitRecipientsQueryVariables = Exact<{
  competitionTeamKitId: Scalars['String'];
}>;


export type ListCompetitionTeamKitRecipientsQuery = (
  { __typename?: 'Query' }
  & { listCompetitionTeamKitRecipients: Array<(
    { __typename?: 'CompetitionTeamKitRecipient' }
    & Pick<CompetitionTeamKitRecipient, 'id' | 'competitionTeamKitId' | 'type' | 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'notificationSent' | 'createdAt' | 'updatedAt'>
  )> }
);

export type ListCompetitionTeamKitBroadcastOptionsQueryVariables = Exact<{
  competitionTeamKitId: Scalars['String'];
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}>;


export type ListCompetitionTeamKitBroadcastOptionsQuery = (
  { __typename?: 'Query' }
  & { listCompetitionTeamKitBroadcastOptions: (
    { __typename?: 'CompetitionTeamKitBroadcastOptions' }
    & { broadcastOptions: Array<(
      { __typename?: 'CompetitionTeamKitBroadcastOption' }
      & Pick<CompetitionTeamKitBroadcastOption, 'id' | 'competitionTeamKitId' | 'broadcastOptionId' | 'name' | 'createdAt' | 'updatedAt'>
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type ListAvailableBroadcastOptionsForCompetitionTeamKitQueryVariables = Exact<{
  competitionTeamKitId: Scalars['String'];
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}>;


export type ListAvailableBroadcastOptionsForCompetitionTeamKitQuery = (
  { __typename?: 'Query' }
  & { listAvailableBroadcastOptionsForCompetitionTeamKit: (
    { __typename?: 'SeasonTeamKitBroadcastOptions' }
    & Pick<SeasonTeamKitBroadcastOptions, 'seasonId'>
    & { broadcastOptions: Array<(
      { __typename?: 'SeasonTeamKitBroadcastOption' }
      & Pick<SeasonTeamKitBroadcastOption, 'id' | 'seasonId' | 'teamId' | 'teamName' | 'value' | 'isActive'>
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type AddBroadcastOptionToCompetitionTeamKitMutationVariables = Exact<{
  competitionTeamKitId: Scalars['String'];
  broadcastOptionId: Scalars['String'];
}>;


export type AddBroadcastOptionToCompetitionTeamKitMutation = (
  { __typename?: 'Mutation' }
  & { addBroadcastOptionToCompetitionTeamKit: (
    { __typename?: 'CompetitionTeamKitBroadcastOption' }
    & Pick<CompetitionTeamKitBroadcastOption, 'id' | 'competitionTeamKitId' | 'broadcastOptionId' | 'name' | 'createdAt' | 'updatedAt'>
  ) }
);

export type RemoveBroadcastOptionFromCompetitionTeamKitMutationVariables = Exact<{
  competitionTeamKitId: Scalars['String'];
  broadcastOptionId: Scalars['String'];
}>;


export type RemoveBroadcastOptionFromCompetitionTeamKitMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeBroadcastOptionFromCompetitionTeamKit'>
);

export type ListCompetitionTeamKitContactsQueryVariables = Exact<{
  competitionTeamKitId: Scalars['String'];
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}>;


export type ListCompetitionTeamKitContactsQuery = (
  { __typename?: 'Query' }
  & { listCompetitionTeamKitContacts: (
    { __typename?: 'CompetitionTeamKitContacts' }
    & { contacts: Array<(
      { __typename?: 'CompetitionTeamKitContact' }
      & Pick<CompetitionTeamKitContact, 'id' | 'competitionTeamKitId' | 'type' | 'name' | 'order' | 'seasonContactDefinitionId' | 'teamContactId' | 'organizationContactId' | 'email' | 'phone' | 'firstName' | 'lastName' | 'createdAt' | 'updatedAt'>
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type GetCompetitionTeamKitContactByIdQueryVariables = Exact<{
  competitionTeamKitContactId: Scalars['String'];
}>;


export type GetCompetitionTeamKitContactByIdQuery = (
  { __typename?: 'Query' }
  & { getCompetitionTeamKitContactById: (
    { __typename?: 'CompetitionTeamKitContact' }
    & Pick<CompetitionTeamKitContact, 'id' | 'competitionTeamKitId' | 'type' | 'name' | 'order' | 'seasonContactDefinitionId' | 'teamContactId' | 'organizationContactId' | 'email' | 'phone' | 'firstName' | 'lastName' | 'createdAt' | 'updatedAt'>
  ) }
);

export type ClearSelectionForCompetitionTeamKitContactMutationVariables = Exact<{
  competitionTeamKitContactId: Scalars['String'];
}>;


export type ClearSelectionForCompetitionTeamKitContactMutation = (
  { __typename?: 'Mutation' }
  & { clearSelectionForCompetitionTeamKitContact: (
    { __typename?: 'CompetitionTeamKitContact' }
    & Pick<CompetitionTeamKitContact, 'id' | 'competitionTeamKitId' | 'type' | 'name' | 'order' | 'seasonContactDefinitionId' | 'teamContactId' | 'organizationContactId' | 'email' | 'phone' | 'firstName' | 'lastName' | 'createdAt' | 'updatedAt'>
  ) }
);

export type UpdateSelectionForCompetitionTeamKitContactMutationVariables = Exact<{
  competitionTeamKitContactId: Scalars['String'];
  selectedOptionId: Scalars['String'];
}>;


export type UpdateSelectionForCompetitionTeamKitContactMutation = (
  { __typename?: 'Mutation' }
  & { updateSelectionForCompetitionTeamKitContact: (
    { __typename?: 'CompetitionTeamKitContact' }
    & Pick<CompetitionTeamKitContact, 'id' | 'competitionTeamKitId' | 'type' | 'name' | 'order' | 'seasonContactDefinitionId' | 'teamContactId' | 'organizationContactId' | 'email' | 'phone' | 'firstName' | 'lastName' | 'createdAt' | 'updatedAt'>
  ) }
);

export type AddNewContactToCompetitionTeamKitContactMutationVariables = Exact<{
  competitionTeamKitContactId: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
}>;


export type AddNewContactToCompetitionTeamKitContactMutation = (
  { __typename?: 'Mutation' }
  & { addNewContactToCompetitionTeamKitContact: (
    { __typename?: 'CompetitionTeamKitContact' }
    & Pick<CompetitionTeamKitContact, 'id' | 'competitionTeamKitId' | 'type' | 'name' | 'order' | 'seasonContactDefinitionId' | 'teamContactId' | 'organizationContactId' | 'email' | 'phone' | 'firstName' | 'lastName' | 'createdAt' | 'updatedAt'>
  ) }
);

export type ListKitRolesForCompetitionTeamKitQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  competitionTeamKitId: Scalars['String'];
}>;


export type ListKitRolesForCompetitionTeamKitQuery = (
  { __typename?: 'Query' }
  & { listKitRolesForCompetitionTeamKit: (
    { __typename?: 'SeasonTeamKitRoles' }
    & Pick<SeasonTeamKitRoles, 'seasonId'>
    & { kitRoles: Array<(
      { __typename?: 'SeasonTeamKitRole' }
      & Pick<SeasonTeamKitRole, 'id' | 'value' | 'type' | 'order'>
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type ListCompetitionTeamKitApparelsQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  competitionTeamKitId: Scalars['String'];
}>;


export type ListCompetitionTeamKitApparelsQuery = (
  { __typename?: 'Query' }
  & { listCompetitionTeamKitApparels: (
    { __typename?: 'CompetitionTeamKitApparels' }
    & { apparels: Array<(
      { __typename?: 'CompetitionTeamKitApparel' }
      & Pick<CompetitionTeamKitApparel, 'id' | 'competitionTeamKitId' | 'type' | 'status' | 'seasonKitRoleId' | 'roleType' | 'roleName' | 'roleOrder' | 'seasonKitApparelDefinitionId' | 'definitionName' | 'definitionOrder' | 'seasonApparelId' | 'name' | 'color' | 'image' | 'createdAt' | 'updatedAt'>
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type GetCompetitionTeamKitApparelByIdQueryVariables = Exact<{
  competitionTeamKitApparelId: Scalars['String'];
}>;


export type GetCompetitionTeamKitApparelByIdQuery = (
  { __typename?: 'Query' }
  & { getCompetitionTeamKitApparelById: (
    { __typename?: 'CompetitionTeamKitApparel' }
    & Pick<CompetitionTeamKitApparel, 'id' | 'competitionTeamKitId' | 'type' | 'status' | 'seasonKitRoleId' | 'roleType' | 'roleName' | 'roleOrder' | 'seasonKitApparelDefinitionId' | 'definitionName' | 'definitionOrder' | 'seasonApparelId' | 'name' | 'color' | 'image' | 'createdAt' | 'updatedAt'>
  ) }
);

export type ListAvailableSelectionsForCompetitionTeamKitApparelQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  competitionTeamKitApparelId: Scalars['String'];
}>;


export type ListAvailableSelectionsForCompetitionTeamKitApparelQuery = (
  { __typename?: 'Query' }
  & { listAvailableSelectionsForCompetitionTeamKitApparel: (
    { __typename?: 'TeamKitSeasonApparels' }
    & Pick<TeamKitSeasonApparels, 'seasonId' | 'teamId'>
    & { apparels: Array<(
      { __typename?: 'TeamKitSeasonApparel' }
      & Pick<TeamKitSeasonApparel, 'seasonId' | 'teamId' | 'teamName' | 'id' | 'isActive' | 'name' | 'color' | 'imageUrl'>
      & { kitApparel: (
        { __typename?: 'SeasonTeamKitApparelDefinition' }
        & Pick<SeasonTeamKitApparelDefinition, 'id' | 'value' | 'order'>
      ), kitRole: (
        { __typename?: 'SeasonTeamKitRole' }
        & Pick<SeasonTeamKitRole, 'id' | 'value' | 'type' | 'order'>
      ) }
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type ClearSelectionForCompetitionTeamKitApparelMutationVariables = Exact<{
  competitionTeamKitApparelId: Scalars['String'];
}>;


export type ClearSelectionForCompetitionTeamKitApparelMutation = (
  { __typename?: 'Mutation' }
  & { clearSelectionForCompetitionTeamKitApparel: (
    { __typename?: 'CompetitionTeamKitApparel' }
    & Pick<CompetitionTeamKitApparel, 'id' | 'competitionTeamKitId' | 'type' | 'status' | 'seasonKitRoleId' | 'roleType' | 'roleName' | 'roleOrder' | 'seasonKitApparelDefinitionId' | 'definitionName' | 'definitionOrder' | 'seasonApparelId' | 'name' | 'color' | 'image' | 'createdAt' | 'updatedAt'>
  ) }
);

export type UpdateSelectionForCompetitionTeamKitApparelMutationVariables = Exact<{
  competitionTeamKitApparelId: Scalars['String'];
  selectedApparelId: Scalars['String'];
}>;


export type UpdateSelectionForCompetitionTeamKitApparelMutation = (
  { __typename?: 'Mutation' }
  & { updateSelectionForCompetitionTeamKitApparel: (
    { __typename?: 'CompetitionTeamKitApparel' }
    & Pick<CompetitionTeamKitApparel, 'id' | 'competitionTeamKitId' | 'type' | 'status' | 'seasonKitRoleId' | 'roleType' | 'roleName' | 'roleOrder' | 'seasonKitApparelDefinitionId' | 'definitionName' | 'definitionOrder' | 'seasonApparelId' | 'name' | 'color' | 'image' | 'createdAt' | 'updatedAt'>
  ) }
);

export type ListCompetitionTeamKitCompetitionInfosQueryVariables = Exact<{
  competitionTeamKitId: Scalars['String'];
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}>;


export type ListCompetitionTeamKitCompetitionInfosQuery = (
  { __typename?: 'Query' }
  & { listCompetitionTeamKitCompetitionInfos: (
    { __typename?: 'CompetitionTeamKitCompetitionInfos' }
    & { competitionInfos: Array<(
      { __typename?: 'CompetitionTeamKitCompetitionInfo' }
      & Pick<CompetitionTeamKitCompetitionInfo, 'id' | 'competitionTeamKitId' | 'value' | 'createdAt' | 'updatedAt'>
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type AddCompetitionInfoToCompetitionTeamKitMutationVariables = Exact<{
  competitionTeamKitId: Scalars['String'];
  value: Scalars['String'];
}>;


export type AddCompetitionInfoToCompetitionTeamKitMutation = (
  { __typename?: 'Mutation' }
  & { addCompetitionInfoToCompetitionTeamKit: (
    { __typename?: 'CompetitionTeamKitCompetitionInfo' }
    & Pick<CompetitionTeamKitCompetitionInfo, 'id' | 'competitionTeamKitId' | 'value' | 'createdAt' | 'updatedAt'>
  ) }
);

export type UpdateCompetitionTeamKitCompetitionInfoMutationVariables = Exact<{
  competitionTeamKitId: Scalars['String'];
  competitionInfoId: Scalars['String'];
  value: Scalars['String'];
}>;


export type UpdateCompetitionTeamKitCompetitionInfoMutation = (
  { __typename?: 'Mutation' }
  & { updateCompetitionTeamKitCompetitionInfo: (
    { __typename?: 'CompetitionTeamKitCompetitionInfo' }
    & Pick<CompetitionTeamKitCompetitionInfo, 'id' | 'competitionTeamKitId' | 'value' | 'createdAt' | 'updatedAt'>
  ) }
);

export type RemoveCompetitionInfoFromCompetitionTeamKitMutationVariables = Exact<{
  competitionTeamKitId: Scalars['String'];
  competitionInfoId: Scalars['String'];
}>;


export type RemoveCompetitionInfoFromCompetitionTeamKitMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeCompetitionInfoFromCompetitionTeamKit'>
);

export type CreateCompetitionForSeasonMutationVariables = Exact<{
  seasonId: Scalars['String'];
  homeTeamId: Scalars['String'];
  awayTeamId: Scalars['String'];
  startTime: Scalars['Date'];
  timeZone: Scalars['String'];
  status: CompetitionStatuses;
  externalId?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  homeTeamScore?: Maybe<Scalars['Int']>;
  homeTeamTieBreakerScore?: Maybe<Scalars['Int']>;
  awayTeamScore?: Maybe<Scalars['Int']>;
  awayTeamTieBreakerScore?: Maybe<Scalars['Int']>;
}>;


export type CreateCompetitionForSeasonMutation = (
  { __typename?: 'Mutation' }
  & { createCompetitionForSeason: (
    { __typename?: 'Competition' }
    & Pick<Competition, 'id' | 'externalId' | 'sport' | 'status' | 'startTime' | 'outcome' | 'verificationStatus' | 'timeZone' | 'location' | 'homeTeamScore' | 'homeTeamTieBreakerScore' | 'awayTeamScore' | 'awayTeamTieBreakerScore' | 'createdAt' | 'updatedAt'>
    & { homeTeam: (
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'classification' | 'gender' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'timeZone' | 'website' | 'logo' | 'createdAt' | 'updatedAt'>
    ), awayTeam: (
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'classification' | 'gender' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'timeZone' | 'website' | 'logo' | 'createdAt' | 'updatedAt'>
    ), organization: (
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'name' | 'sport' | 'status' | 'abbreviation' | 'site' | 'logo' | 'createdAt' | 'updatedAt'>
    ), season?: Maybe<(
      { __typename?: 'Season' }
      & Pick<Season, 'id' | 'leagueId' | 'leagueName' | 'leagueLogo' | 'name' | 'description' | 'status' | 'startDate' | 'endDate' | 'createdAt' | 'updatedAt'>
    )>, officials: Array<(
      { __typename?: 'CompetitionOfficial' }
      & Pick<CompetitionOfficial, 'id' | 'competitionId' | 'assessmentObjectId' | 'order' | 'isOptional' | 'type' | 'createdAt' | 'updatedAt'>
      & { official: (
        { __typename?: 'Official' }
        & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
      ) }
    )> }
  ) }
);

export type GetCompetitionByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetCompetitionByIdQuery = (
  { __typename?: 'Query' }
  & { getCompetitionById: (
    { __typename?: 'Competition' }
    & Pick<Competition, 'id' | 'externalId' | 'sport' | 'status' | 'startTime' | 'outcome' | 'verificationStatus' | 'timeZone' | 'location' | 'homeTeamScore' | 'homeTeamTieBreakerScore' | 'awayTeamScore' | 'awayTeamTieBreakerScore' | 'homeTeamName' | 'awayTeamName' | 'createdAt' | 'updatedAt'>
    & { homeTeam: (
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'classification' | 'gender' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'timeZone' | 'website' | 'logo' | 'createdAt' | 'updatedAt'>
    ), awayTeam: (
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'classification' | 'gender' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'timeZone' | 'website' | 'logo' | 'createdAt' | 'updatedAt'>
    ), organization: (
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'name' | 'sport' | 'status' | 'abbreviation' | 'site' | 'logo' | 'createdAt' | 'updatedAt'>
    ), season?: Maybe<(
      { __typename?: 'Season' }
      & Pick<Season, 'id' | 'leagueId' | 'leagueName' | 'leagueLogo' | 'name' | 'description' | 'status' | 'startDate' | 'endDate' | 'createdAt' | 'updatedAt'>
    )>, officials: Array<(
      { __typename?: 'CompetitionOfficial' }
      & Pick<CompetitionOfficial, 'id' | 'competitionId' | 'assessmentObjectId' | 'order' | 'isOptional' | 'type' | 'createdAt' | 'updatedAt'>
      & { official: (
        { __typename?: 'Official' }
        & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
      ) }
    )> }
  ) }
);

export type ListCompetitionsForSeasonQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  seasonId: Scalars['String'];
  dateFrom?: Maybe<Scalars['String']>;
  dateTo?: Maybe<Scalars['String']>;
  teamsSearch?: Maybe<Scalars['String']>;
  externalIdSearch?: Maybe<Scalars['String']>;
  statuses?: Maybe<Array<CompetitionStatuses> | CompetitionStatuses>;
}>;


export type ListCompetitionsForSeasonQuery = (
  { __typename?: 'Query' }
  & { listCompetitionsForSeason: (
    { __typename?: 'Competitions' }
    & { competitions: Array<(
      { __typename?: 'Competition' }
      & Pick<Competition, 'id' | 'externalId' | 'sport' | 'status' | 'startTime' | 'outcome' | 'verificationStatus' | 'timeZone' | 'location' | 'homeTeamScore' | 'homeTeamTieBreakerScore' | 'awayTeamScore' | 'awayTeamTieBreakerScore' | 'createdAt' | 'updatedAt'>
      & { homeTeam: (
        { __typename?: 'Team' }
        & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'classification' | 'gender' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'timeZone' | 'website' | 'logo' | 'createdAt' | 'updatedAt'>
      ), awayTeam: (
        { __typename?: 'Team' }
        & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'classification' | 'gender' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'timeZone' | 'website' | 'logo' | 'createdAt' | 'updatedAt'>
      ), organization: (
        { __typename?: 'Organization' }
        & Pick<Organization, 'id' | 'name' | 'sport' | 'status' | 'abbreviation' | 'site' | 'logo' | 'createdAt' | 'updatedAt'>
      ), season?: Maybe<(
        { __typename?: 'Season' }
        & Pick<Season, 'id' | 'leagueId' | 'leagueName' | 'leagueLogo' | 'name' | 'description' | 'status' | 'startDate' | 'endDate' | 'createdAt' | 'updatedAt'>
      )>, officials: Array<(
        { __typename?: 'CompetitionOfficial' }
        & Pick<CompetitionOfficial, 'id' | 'competitionId' | 'assessmentObjectId' | 'order' | 'isOptional' | 'type' | 'createdAt' | 'updatedAt'>
        & { official: (
          { __typename?: 'Official' }
          & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type ListCompetitionsForLeagueQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  leagueId: Scalars['String'];
  seasonId?: Maybe<Scalars['String']>;
  dateFrom?: Maybe<Scalars['String']>;
  dateTo?: Maybe<Scalars['String']>;
  teamsSearch?: Maybe<Scalars['String']>;
  externalIdSearch?: Maybe<Scalars['String']>;
  statuses?: Maybe<Array<CompetitionStatuses> | CompetitionStatuses>;
}>;


export type ListCompetitionsForLeagueQuery = (
  { __typename?: 'Query' }
  & { listCompetitionsForLeague: (
    { __typename?: 'Competitions' }
    & { competitions: Array<(
      { __typename?: 'Competition' }
      & Pick<Competition, 'id' | 'externalId' | 'sport' | 'status' | 'startTime' | 'outcome' | 'verificationStatus' | 'timeZone' | 'location' | 'homeTeamScore' | 'homeTeamTieBreakerScore' | 'awayTeamScore' | 'awayTeamTieBreakerScore' | 'createdAt' | 'updatedAt'>
      & { homeTeam: (
        { __typename?: 'Team' }
        & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'classification' | 'gender' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'timeZone' | 'website' | 'logo' | 'createdAt' | 'updatedAt'>
      ), awayTeam: (
        { __typename?: 'Team' }
        & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'classification' | 'gender' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'timeZone' | 'website' | 'logo' | 'createdAt' | 'updatedAt'>
      ), organization: (
        { __typename?: 'Organization' }
        & Pick<Organization, 'id' | 'name' | 'sport' | 'status' | 'abbreviation' | 'site' | 'logo' | 'createdAt' | 'updatedAt'>
      ), season?: Maybe<(
        { __typename?: 'Season' }
        & Pick<Season, 'id' | 'leagueId' | 'leagueName' | 'leagueLogo' | 'name' | 'description' | 'status' | 'startDate' | 'endDate' | 'createdAt' | 'updatedAt'>
      )>, officials: Array<(
        { __typename?: 'CompetitionOfficial' }
        & Pick<CompetitionOfficial, 'id' | 'competitionId' | 'assessmentObjectId' | 'order' | 'isOptional' | 'type' | 'createdAt' | 'updatedAt'>
        & { official: (
          { __typename?: 'Official' }
          & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type ActivateCompetitionMutationVariables = Exact<{
  competitionId: Scalars['String'];
}>;


export type ActivateCompetitionMutation = (
  { __typename?: 'Mutation' }
  & { activateCompetition: (
    { __typename?: 'Competition' }
    & Pick<Competition, 'id' | 'externalId' | 'sport' | 'status' | 'startTime' | 'outcome' | 'verificationStatus' | 'timeZone' | 'location' | 'homeTeamScore' | 'homeTeamTieBreakerScore' | 'awayTeamScore' | 'awayTeamTieBreakerScore' | 'createdAt' | 'updatedAt'>
    & { homeTeam: (
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'classification' | 'gender' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'timeZone' | 'website' | 'logo' | 'createdAt' | 'updatedAt'>
    ), awayTeam: (
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'classification' | 'gender' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'timeZone' | 'website' | 'logo' | 'createdAt' | 'updatedAt'>
    ), organization: (
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'name' | 'sport' | 'status' | 'abbreviation' | 'site' | 'logo' | 'createdAt' | 'updatedAt'>
    ), season?: Maybe<(
      { __typename?: 'Season' }
      & Pick<Season, 'id' | 'leagueId' | 'leagueName' | 'leagueLogo' | 'name' | 'description' | 'status' | 'startDate' | 'endDate' | 'createdAt' | 'updatedAt'>
    )>, officials: Array<(
      { __typename?: 'CompetitionOfficial' }
      & Pick<CompetitionOfficial, 'id' | 'competitionId' | 'assessmentObjectId' | 'order' | 'isOptional' | 'type' | 'createdAt' | 'updatedAt'>
      & { official: (
        { __typename?: 'Official' }
        & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
      ) }
    )> }
  ) }
);

export type DeleteCompetitionMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteCompetitionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCompetition'>
);

export type UpdateCompetitionMutationVariables = Exact<{
  competitionId: Scalars['String'];
  seasonId: Scalars['String'];
  homeTeamId: Scalars['String'];
  awayTeamId: Scalars['String'];
  startTime: Scalars['Date'];
  timeZone: Scalars['String'];
  externalId?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  homeTeamScore?: Maybe<Scalars['Int']>;
  homeTeamTieBreakerScore?: Maybe<Scalars['Int']>;
  awayTeamScore?: Maybe<Scalars['Int']>;
  awayTeamTieBreakerScore?: Maybe<Scalars['Int']>;
}>;


export type UpdateCompetitionMutation = (
  { __typename?: 'Mutation' }
  & { updateCompetition: (
    { __typename?: 'Competition' }
    & Pick<Competition, 'id' | 'externalId' | 'sport' | 'status' | 'startTime' | 'outcome' | 'verificationStatus' | 'timeZone' | 'location' | 'homeTeamScore' | 'homeTeamTieBreakerScore' | 'awayTeamScore' | 'awayTeamTieBreakerScore' | 'createdAt' | 'updatedAt'>
    & { homeTeam: (
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'classification' | 'gender' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'timeZone' | 'website' | 'logo' | 'createdAt' | 'updatedAt'>
    ), awayTeam: (
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'classification' | 'gender' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'timeZone' | 'website' | 'logo' | 'createdAt' | 'updatedAt'>
    ), organization: (
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'name' | 'sport' | 'status' | 'abbreviation' | 'site' | 'logo' | 'createdAt' | 'updatedAt'>
    ), season?: Maybe<(
      { __typename?: 'Season' }
      & Pick<Season, 'id' | 'leagueId' | 'leagueName' | 'leagueLogo' | 'name' | 'description' | 'status' | 'startDate' | 'endDate' | 'createdAt' | 'updatedAt'>
    )>, officials: Array<(
      { __typename?: 'CompetitionOfficial' }
      & Pick<CompetitionOfficial, 'id' | 'competitionId' | 'assessmentObjectId' | 'order' | 'isOptional' | 'type' | 'createdAt' | 'updatedAt'>
      & { official: (
        { __typename?: 'Official' }
        & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
      ) }
    )> }
  ) }
);

export type UpdateCompetitionScoreMutationVariables = Exact<{
  competitionId: Scalars['String'];
  homeTeamScore?: Maybe<Scalars['Int']>;
  homeTeamTieBreakerScore?: Maybe<Scalars['Int']>;
  awayTeamScore?: Maybe<Scalars['Int']>;
  awayTeamTieBreakerScore?: Maybe<Scalars['Int']>;
}>;


export type UpdateCompetitionScoreMutation = (
  { __typename?: 'Mutation' }
  & { updateCompetitionScore: (
    { __typename?: 'Competition' }
    & Pick<Competition, 'id' | 'externalId' | 'sport' | 'status' | 'startTime' | 'outcome' | 'verificationStatus' | 'timeZone' | 'location' | 'homeTeamScore' | 'homeTeamTieBreakerScore' | 'awayTeamScore' | 'awayTeamTieBreakerScore' | 'createdAt' | 'updatedAt'>
    & { homeTeam: (
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'classification' | 'gender' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'timeZone' | 'website' | 'logo' | 'createdAt' | 'updatedAt'>
    ), awayTeam: (
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'classification' | 'gender' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'timeZone' | 'website' | 'logo' | 'createdAt' | 'updatedAt'>
    ), organization: (
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'name' | 'sport' | 'status' | 'abbreviation' | 'site' | 'logo' | 'createdAt' | 'updatedAt'>
    ), season?: Maybe<(
      { __typename?: 'Season' }
      & Pick<Season, 'id' | 'leagueId' | 'leagueName' | 'leagueLogo' | 'name' | 'description' | 'status' | 'startDate' | 'endDate' | 'createdAt' | 'updatedAt'>
    )>, officials: Array<(
      { __typename?: 'CompetitionOfficial' }
      & Pick<CompetitionOfficial, 'id' | 'competitionId' | 'assessmentObjectId' | 'order' | 'isOptional' | 'type' | 'createdAt' | 'updatedAt'>
      & { official: (
        { __typename?: 'Official' }
        & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
      ) }
    )> }
  ) }
);

export type AddOfficialToCompetitionMutationVariables = Exact<{
  competitionId: Scalars['String'];
  officialId: Scalars['String'];
  assessmentObjectId: Scalars['String'];
}>;


export type AddOfficialToCompetitionMutation = (
  { __typename?: 'Mutation' }
  & { addOfficialToCompetition: (
    { __typename?: 'CompetitionOfficial' }
    & Pick<CompetitionOfficial, 'id' | 'competitionId' | 'assessmentObjectId' | 'type' | 'order' | 'isOptional' | 'createdAt' | 'updatedAt'>
    & { official: (
      { __typename?: 'Official' }
      & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
    ) }
  ) }
);

export type ListOfficialsForCompetitionQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  competitionId: Scalars['String'];
  nameOrEmail?: Maybe<Scalars['String']>;
  status?: Maybe<OfficialStatuses>;
  type?: Maybe<Scalars['String']>;
}>;


export type ListOfficialsForCompetitionQuery = (
  { __typename?: 'Query' }
  & { listOfficialsForCompetition: (
    { __typename?: 'CompetitionOfficials' }
    & Pick<CompetitionOfficials, 'competitionId'>
    & { competitionOfficials: Array<(
      { __typename?: 'CompetitionOfficial' }
      & Pick<CompetitionOfficial, 'id' | 'competitionId' | 'assessmentObjectId' | 'type' | 'order' | 'isOptional' | 'createdAt' | 'updatedAt'>
      & { official: (
        { __typename?: 'Official' }
        & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
      ) }
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type RemoveOfficialFromCompetitionMutationVariables = Exact<{
  competitionId: Scalars['String'];
  officialId: Scalars['String'];
}>;


export type RemoveOfficialFromCompetitionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeOfficialFromCompetition'>
);

export type UpdateCompetitionOfficialMutationVariables = Exact<{
  competitionOfficialId: Scalars['String'];
  officialId: Scalars['String'];
}>;


export type UpdateCompetitionOfficialMutation = (
  { __typename?: 'Mutation' }
  & { updateCompetitionOfficial: (
    { __typename?: 'CompetitionOfficial' }
    & Pick<CompetitionOfficial, 'id' | 'competitionId' | 'assessmentObjectId' | 'type' | 'order' | 'isOptional' | 'createdAt' | 'updatedAt'>
    & { official: (
      { __typename?: 'Official' }
      & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
    ) }
  ) }
);

export type ListCompetitionSummariesForOrgQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  orgId: Scalars['String'];
  includeNoResponses: Scalars['Boolean'];
  leagueId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  dateFrom?: Maybe<Scalars['String']>;
  dateTo?: Maybe<Scalars['String']>;
  externalIdSearch?: Maybe<Scalars['String']>;
  teamsSearch?: Maybe<Scalars['String']>;
  leagueSearch?: Maybe<Scalars['String']>;
  seasonSearch?: Maybe<Scalars['String']>;
}>;


export type ListCompetitionSummariesForOrgQuery = (
  { __typename?: 'Query' }
  & { listCompetitionSummariesForOrg: (
    { __typename?: 'CompetitionSummaries' }
    & { competitionSummaries: Array<(
      { __typename?: 'CompetitionSummary' }
      & Pick<CompetitionSummary, 'leagueName' | 'seasonName' | 'competitionId' | 'competitionStartTime' | 'competitionTimeZone' | 'competitionLocation' | 'competitionExternalId' | 'competitionOutcome' | 'competitionVerificationStatus' | 'homeTeamId' | 'homeTeamName' | 'homeTeamAbbreviation' | 'homeTeamLogo' | 'homeTeamScore' | 'homeTeamTieBreakerScore' | 'awayTeamId' | 'awayTeamName' | 'awayTeamAbbreviation' | 'awayTeamLogo' | 'awayTeamScore' | 'awayTeamTieBreakerScore' | 'hasSeasonTeamsAssessment' | 'hasSeasonOfficialsAssessment' | 'hasSeasonOfficialsDynamicSurvey' | 'hasSeasonOfficialsDynamicSurveyAssessment' | 'hasSeasonTeamKit' | 'homeTeamAssessmentCreated' | 'awayTeamAssessmentCreated' | 'officialsAssessmentCreated' | 'officialsDynamicSurveyCreated' | 'teamKitCreated' | 'awayTeamSubmitted' | 'homeTeamSubmitted' | 'officialsAssessmentSubmitted' | 'officialsDynamicSurveyAssessmentSubmitted' | 'officialsDynamicSurveySubmitted' | 'totalKeyIncidents' | 'totalOpenNormalKeyIncidents' | 'totalOpenKeyIncidentsImpactingGameOutcome' | 'totalResolvedKeyIncidents' | 'totalSupplementalReportCount' | 'totalOpenSupplementalReportCount' | 'totalApprovedSupplementalReportCount' | 'officialsByHomeTeamAverageCompetitionScore' | 'homeTeamByOfficialsAverageCompetitionScore' | 'officialsByAwayTeamAverageCompetitionScore' | 'awayTeamByOfficialsAverageCompetitionScore' | 'officialDynamicSurveyId' | 'officialDynamicSurveyName' | 'competitionTeamKitId' | 'competitionTeamKitStatus'>
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type ListAllSurveyInfoForCompetitionForOrgQueryVariables = Exact<{
  orgId: Scalars['String'];
  competitionId: Scalars['String'];
}>;


export type ListAllSurveyInfoForCompetitionForOrgQuery = (
  { __typename?: 'Query' }
  & { listAllSurveyInfoForCompetitionForOrg: Array<(
    { __typename?: 'SurveyInfoForCompetition' }
    & Pick<SurveyInfoForCompetition, 'competitionSurveyId' | 'surveyId' | 'surveyName' | 'competitionId' | 'audience' | 'isDynamic'>
  )> }
);

export type ListAvailablePeriodsForCompetitionQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  competitionId: Scalars['String'];
}>;


export type ListAvailablePeriodsForCompetitionQuery = (
  { __typename?: 'Query' }
  & { listAvailablePeriodsForCompetition: (
    { __typename?: 'Periods' }
    & { periods: Array<(
      { __typename?: 'Period' }
      & Pick<Period, 'id' | 'name' | 'sport' | 'createdAt' | 'updatedAt'>
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type ListAvailablePeriodsForCompetitionWithTokenQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  competitionId: Scalars['String'];
  surveyToken: Scalars['String'];
}>;


export type ListAvailablePeriodsForCompetitionWithTokenQuery = (
  { __typename?: 'Query' }
  & { listAvailablePeriodsForCompetitionWithToken: (
    { __typename?: 'Periods' }
    & { periods: Array<(
      { __typename?: 'Period' }
      & Pick<Period, 'id' | 'name' | 'sport' | 'createdAt' | 'updatedAt'>
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type GetDynamicCompetitionSurveyByIdQueryVariables = Exact<{
  competitionSurveyId: Scalars['String'];
}>;


export type GetDynamicCompetitionSurveyByIdQuery = (
  { __typename?: 'Query' }
  & { getDynamicCompetitionSurveyById: (
    { __typename?: 'DynamicCompetitionSurvey' }
    & Pick<DynamicCompetitionSurvey, 'id' | 'surveyId' | 'orgId' | 'seasonId' | 'sport' | 'finished' | 'completedDate' | 'hasSupplementalReport' | 'allowAdditionalSupplementalReports' | 'allowAdditionalSupplementalReportsCutoffDays' | 'hasCompetitionVerification' | 'hasIncidentReport' | 'allowAdditionalIncidentReports' | 'allowAdditionalIncidentReportsCutoffDays' | 'hasSignatureSection' | 'signatureText' | 'createdAt' | 'updatedAt'>
    & { signature?: Maybe<(
      { __typename?: 'DynamicCompetitionSurveySignature' }
      & Pick<DynamicCompetitionSurveySignature, 'competitionSurveyId' | 'signedDate' | 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic'>
    )>, recipients: Array<(
      { __typename?: 'DynamicCompetitionSurveyUser' }
      & Pick<DynamicCompetitionSurveyUser, 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'tookSurvey'>
    )>, competition: (
      { __typename?: 'Competition' }
      & Pick<Competition, 'id' | 'externalId' | 'sport' | 'startTime' | 'timeZone' | 'location' | 'homeTeamScore' | 'homeTeamTieBreakerScore' | 'awayTeamScore' | 'awayTeamTieBreakerScore' | 'createdAt' | 'updatedAt'>
      & { homeTeam: (
        { __typename?: 'Team' }
        & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'classification' | 'gender' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'timeZone' | 'website' | 'logo' | 'createdAt' | 'updatedAt'>
      ), awayTeam: (
        { __typename?: 'Team' }
        & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'classification' | 'gender' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'timeZone' | 'website' | 'logo' | 'createdAt' | 'updatedAt'>
      ), organization: (
        { __typename?: 'Organization' }
        & Pick<Organization, 'id' | 'name' | 'sport' | 'status' | 'abbreviation' | 'site' | 'logo' | 'createdAt' | 'updatedAt'>
      ), season?: Maybe<(
        { __typename?: 'Season' }
        & Pick<Season, 'id' | 'leagueId' | 'leagueName' | 'leagueLogo' | 'name' | 'description' | 'status' | 'startDate' | 'endDate' | 'createdAt' | 'updatedAt'>
      )>, officials: Array<(
        { __typename?: 'CompetitionOfficial' }
        & Pick<CompetitionOfficial, 'id' | 'competitionId' | 'assessmentObjectId' | 'order' | 'type' | 'createdAt' | 'updatedAt'>
        & { official: (
          { __typename?: 'Official' }
          & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    ), info: (
      { __typename?: 'DynamicCompetitionSurveyInfo' }
      & Pick<DynamicCompetitionSurveyInfo, 'name' | 'description' | 'audience'>
    ), sections: Array<(
      { __typename?: 'DynamicCompetitionSurveySection' }
      & Pick<DynamicCompetitionSurveySection, 'id' | 'name' | 'description' | 'finished' | 'order' | 'createdAt' | 'updatedAt' | 'requireSupplementalReport'>
      & { questions: Array<(
        { __typename?: 'DynamicCompetitionSurveySectionQuestion' }
        & Pick<DynamicCompetitionSurveySectionQuestion, 'id' | 'name' | 'description' | 'additionalText1' | 'additionalText2' | 'additionalText3' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType' | 'selectedOption' | 'selectedOptionOrder' | 'responseShortAnswer' | 'responseText' | 'responseNumber' | 'responseBoolean'>
        & { assessmentObject: (
          { __typename?: 'AssessmentObject' }
          & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name'>
        ), coach?: Maybe<(
          { __typename?: 'Coach' }
          & Pick<Coach, 'id' | 'teamId' | 'teamName' | 'teamAbbreviation' | 'sport' | 'teamCity' | 'userId' | 'type' | 'role' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
        )>, team?: Maybe<(
          { __typename?: 'Team' }
          & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'timeZone' | 'classification' | 'gender' | 'website' | 'logo' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'createdAt' | 'updatedAt'>
        )>, official?: Maybe<(
          { __typename?: 'Official' }
          & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
          & { externalIds?: Maybe<Array<(
            { __typename?: 'OfficialExternalId' }
            & Pick<OfficialExternalId, 'id' | 'organizationId' | 'externalId'>
          )>> }
        )>, organization?: Maybe<(
          { __typename?: 'Organization' }
          & Pick<Organization, 'id' | 'name' | 'sport' | 'status' | 'abbreviation' | 'site' | 'logo' | 'createdAt' | 'updatedAt'>
        )>, responses?: Maybe<Array<(
          { __typename?: 'DynamicCompetitionSurveySectionQuestionMultipleResponse' }
          & Pick<DynamicCompetitionSurveySectionQuestionMultipleResponse, 'id' | 'value' | 'createdAt' | 'updatedAt'>
        )>>, fileUploads?: Maybe<Array<(
          { __typename?: 'DynamicCompetitionSurveySectionQuestionFileUpload' }
          & Pick<DynamicCompetitionSurveySectionQuestionFileUpload, 'id' | 'name' | 'comment' | 'fileUpload' | 'createdAt' | 'updatedAt'>
        )>>, options: Array<(
          { __typename?: 'DynamicCompetitionSurveySectionQuestionOption' }
          & Pick<DynamicCompetitionSurveySectionQuestionOption, 'id' | 'value' | 'order'>
        )>, subQuestions: Array<(
          { __typename?: 'DynamicCompetitionSurveySectionSubQuestion' }
          & Pick<DynamicCompetitionSurveySectionSubQuestion, 'id' | 'name' | 'questionText' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType' | 'selectedOption' | 'selectedOptionOrder' | 'responseShortAnswer' | 'responseText' | 'responseNumber' | 'responseBoolean'>
          & { responses?: Maybe<Array<(
            { __typename?: 'DynamicCompetitionSurveySectionSubQuestionMultipleResponse' }
            & Pick<DynamicCompetitionSurveySectionSubQuestionMultipleResponse, 'id' | 'value' | 'createdAt' | 'updatedAt'>
          )>>, fileUploads?: Maybe<Array<(
            { __typename?: 'DynamicCompetitionSurveySectionSubQuestionFileUpload' }
            & Pick<DynamicCompetitionSurveySectionSubQuestionFileUpload, 'id' | 'name' | 'comment' | 'fileUpload' | 'createdAt' | 'updatedAt'>
          )>>, options: Array<(
            { __typename?: 'DynamicCompetitionSurveySectionSubQuestionOption' }
            & Pick<DynamicCompetitionSurveySectionSubQuestionOption, 'id' | 'value' | 'order'>
          )> }
        )> }
      )> }
    )> }
  ) }
);

export type ListStatsForDynamicCompetitionSurveyByIdQueryVariables = Exact<{
  competitionSurveyId: Scalars['String'];
}>;


export type ListStatsForDynamicCompetitionSurveyByIdQuery = (
  { __typename?: 'Query' }
  & { listStatsForDynamicCompetitionSurveyById: Array<(
    { __typename?: 'CompetitionStat' }
    & Pick<CompetitionStat, 'id' | 'competitionId' | 'teamId' | 'name' | 'displayText' | 'valueString' | 'valueNumeric' | 'valueDate' | 'createdAt' | 'updatedAt'>
  )> }
);

export type StartDynamicCompetitionSurveyMutationVariables = Exact<{
  competitionSurveyId: Scalars['String'];
}>;


export type StartDynamicCompetitionSurveyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'startDynamicCompetitionSurvey'>
);

export type UpdateDynamicCompetitionSurveyCompetitionScoreMutationVariables = Exact<{
  competitionSurveyId: Scalars['String'];
  homeTeamScore: Scalars['Int'];
  awayTeamScore: Scalars['Int'];
  homeTeamTieBreakerScore?: Maybe<Scalars['Int']>;
  awayTeamTieBreakerScore?: Maybe<Scalars['Int']>;
}>;


export type UpdateDynamicCompetitionSurveyCompetitionScoreMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateDynamicCompetitionSurveyCompetitionScore'>
);

export type UpdateDynamicCompetitionSurveyMultipleChoiceResponseMutationVariables = Exact<{
  competitionSurveyId: Scalars['String'];
  questionId: Scalars['String'];
  selectedOptionId: Scalars['String'];
}>;


export type UpdateDynamicCompetitionSurveyMultipleChoiceResponseMutation = (
  { __typename?: 'Mutation' }
  & { updateDynamicCompetitionSurveyMultipleChoiceResponse: (
    { __typename?: 'DynamicCompetitionSurveySectionQuestion' }
    & Pick<DynamicCompetitionSurveySectionQuestion, 'id' | 'name' | 'description' | 'additionalText1' | 'additionalText2' | 'additionalText3' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType' | 'selectedOption' | 'selectedOptionOrder' | 'responseShortAnswer' | 'responseText' | 'responseNumber' | 'responseBoolean'>
    & { assessmentObject: (
      { __typename?: 'AssessmentObject' }
      & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name'>
    ), coach?: Maybe<(
      { __typename?: 'Coach' }
      & Pick<Coach, 'id' | 'teamId' | 'teamName' | 'teamAbbreviation' | 'sport' | 'teamCity' | 'userId' | 'type' | 'role' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
    )>, team?: Maybe<(
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'timeZone' | 'classification' | 'gender' | 'website' | 'logo' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'createdAt' | 'updatedAt'>
    )>, official?: Maybe<(
      { __typename?: 'Official' }
      & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
      & { externalIds?: Maybe<Array<(
        { __typename?: 'OfficialExternalId' }
        & Pick<OfficialExternalId, 'id' | 'organizationId' | 'externalId'>
      )>> }
    )>, organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'name' | 'sport' | 'status' | 'abbreviation' | 'site' | 'logo' | 'createdAt' | 'updatedAt'>
    )>, responses?: Maybe<Array<(
      { __typename?: 'DynamicCompetitionSurveySectionQuestionMultipleResponse' }
      & Pick<DynamicCompetitionSurveySectionQuestionMultipleResponse, 'id' | 'value' | 'createdAt' | 'updatedAt'>
    )>>, fileUploads?: Maybe<Array<(
      { __typename?: 'DynamicCompetitionSurveySectionQuestionFileUpload' }
      & Pick<DynamicCompetitionSurveySectionQuestionFileUpload, 'id' | 'name' | 'comment' | 'fileUpload' | 'createdAt' | 'updatedAt'>
    )>>, options: Array<(
      { __typename?: 'DynamicCompetitionSurveySectionQuestionOption' }
      & Pick<DynamicCompetitionSurveySectionQuestionOption, 'id' | 'value' | 'order'>
    )>, subQuestions: Array<(
      { __typename?: 'DynamicCompetitionSurveySectionSubQuestion' }
      & Pick<DynamicCompetitionSurveySectionSubQuestion, 'id' | 'name' | 'questionText' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType' | 'selectedOption' | 'selectedOptionOrder' | 'responseShortAnswer' | 'responseText' | 'responseNumber' | 'responseBoolean'>
      & { responses?: Maybe<Array<(
        { __typename?: 'DynamicCompetitionSurveySectionSubQuestionMultipleResponse' }
        & Pick<DynamicCompetitionSurveySectionSubQuestionMultipleResponse, 'id' | 'value' | 'createdAt' | 'updatedAt'>
      )>>, fileUploads?: Maybe<Array<(
        { __typename?: 'DynamicCompetitionSurveySectionSubQuestionFileUpload' }
        & Pick<DynamicCompetitionSurveySectionSubQuestionFileUpload, 'id' | 'name' | 'comment' | 'fileUpload' | 'createdAt' | 'updatedAt'>
      )>>, options: Array<(
        { __typename?: 'DynamicCompetitionSurveySectionSubQuestionOption' }
        & Pick<DynamicCompetitionSurveySectionSubQuestionOption, 'id' | 'value' | 'order'>
      )> }
    )> }
  ) }
);

export type UpdateDynamicCompetitionSurveyShortAnswerResponseMutationVariables = Exact<{
  competitionSurveyId: Scalars['String'];
  questionId: Scalars['String'];
  response: Scalars['String'];
}>;


export type UpdateDynamicCompetitionSurveyShortAnswerResponseMutation = (
  { __typename?: 'Mutation' }
  & { updateDynamicCompetitionSurveyShortAnswerResponse: (
    { __typename?: 'DynamicCompetitionSurveySectionQuestion' }
    & Pick<DynamicCompetitionSurveySectionQuestion, 'id' | 'name' | 'description' | 'additionalText1' | 'additionalText2' | 'additionalText3' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType' | 'selectedOption' | 'selectedOptionOrder' | 'responseShortAnswer' | 'responseText' | 'responseNumber' | 'responseBoolean'>
    & { assessmentObject: (
      { __typename?: 'AssessmentObject' }
      & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name'>
    ), coach?: Maybe<(
      { __typename?: 'Coach' }
      & Pick<Coach, 'id' | 'teamId' | 'teamName' | 'teamAbbreviation' | 'sport' | 'teamCity' | 'userId' | 'type' | 'role' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
    )>, team?: Maybe<(
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'timeZone' | 'classification' | 'gender' | 'website' | 'logo' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'createdAt' | 'updatedAt'>
    )>, official?: Maybe<(
      { __typename?: 'Official' }
      & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
      & { externalIds?: Maybe<Array<(
        { __typename?: 'OfficialExternalId' }
        & Pick<OfficialExternalId, 'id' | 'organizationId' | 'externalId'>
      )>> }
    )>, organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'name' | 'sport' | 'status' | 'abbreviation' | 'site' | 'logo' | 'createdAt' | 'updatedAt'>
    )>, responses?: Maybe<Array<(
      { __typename?: 'DynamicCompetitionSurveySectionQuestionMultipleResponse' }
      & Pick<DynamicCompetitionSurveySectionQuestionMultipleResponse, 'id' | 'value' | 'createdAt' | 'updatedAt'>
    )>>, fileUploads?: Maybe<Array<(
      { __typename?: 'DynamicCompetitionSurveySectionQuestionFileUpload' }
      & Pick<DynamicCompetitionSurveySectionQuestionFileUpload, 'id' | 'name' | 'comment' | 'fileUpload' | 'createdAt' | 'updatedAt'>
    )>>, options: Array<(
      { __typename?: 'DynamicCompetitionSurveySectionQuestionOption' }
      & Pick<DynamicCompetitionSurveySectionQuestionOption, 'id' | 'value' | 'order'>
    )>, subQuestions: Array<(
      { __typename?: 'DynamicCompetitionSurveySectionSubQuestion' }
      & Pick<DynamicCompetitionSurveySectionSubQuestion, 'id' | 'name' | 'questionText' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType' | 'selectedOption' | 'selectedOptionOrder' | 'responseShortAnswer' | 'responseText' | 'responseNumber' | 'responseBoolean'>
      & { responses?: Maybe<Array<(
        { __typename?: 'DynamicCompetitionSurveySectionSubQuestionMultipleResponse' }
        & Pick<DynamicCompetitionSurveySectionSubQuestionMultipleResponse, 'id' | 'value' | 'createdAt' | 'updatedAt'>
      )>>, fileUploads?: Maybe<Array<(
        { __typename?: 'DynamicCompetitionSurveySectionSubQuestionFileUpload' }
        & Pick<DynamicCompetitionSurveySectionSubQuestionFileUpload, 'id' | 'name' | 'comment' | 'fileUpload' | 'createdAt' | 'updatedAt'>
      )>>, options: Array<(
        { __typename?: 'DynamicCompetitionSurveySectionSubQuestionOption' }
        & Pick<DynamicCompetitionSurveySectionSubQuestionOption, 'id' | 'value' | 'order'>
      )> }
    )> }
  ) }
);

export type UpdateDynamicCompetitionSurveyTextResponseMutationVariables = Exact<{
  competitionSurveyId: Scalars['String'];
  questionId: Scalars['String'];
  response: Scalars['String'];
}>;


export type UpdateDynamicCompetitionSurveyTextResponseMutation = (
  { __typename?: 'Mutation' }
  & { updateDynamicCompetitionSurveyTextResponse: (
    { __typename?: 'DynamicCompetitionSurveySectionQuestion' }
    & Pick<DynamicCompetitionSurveySectionQuestion, 'id' | 'name' | 'description' | 'additionalText1' | 'additionalText2' | 'additionalText3' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType' | 'selectedOption' | 'selectedOptionOrder' | 'responseShortAnswer' | 'responseText' | 'responseNumber' | 'responseBoolean'>
    & { assessmentObject: (
      { __typename?: 'AssessmentObject' }
      & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name'>
    ), coach?: Maybe<(
      { __typename?: 'Coach' }
      & Pick<Coach, 'id' | 'teamId' | 'teamName' | 'teamAbbreviation' | 'sport' | 'teamCity' | 'userId' | 'type' | 'role' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
    )>, team?: Maybe<(
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'timeZone' | 'classification' | 'gender' | 'website' | 'logo' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'createdAt' | 'updatedAt'>
    )>, official?: Maybe<(
      { __typename?: 'Official' }
      & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
      & { externalIds?: Maybe<Array<(
        { __typename?: 'OfficialExternalId' }
        & Pick<OfficialExternalId, 'id' | 'organizationId' | 'externalId'>
      )>> }
    )>, organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'name' | 'sport' | 'status' | 'abbreviation' | 'site' | 'logo' | 'createdAt' | 'updatedAt'>
    )>, responses?: Maybe<Array<(
      { __typename?: 'DynamicCompetitionSurveySectionQuestionMultipleResponse' }
      & Pick<DynamicCompetitionSurveySectionQuestionMultipleResponse, 'id' | 'value' | 'createdAt' | 'updatedAt'>
    )>>, fileUploads?: Maybe<Array<(
      { __typename?: 'DynamicCompetitionSurveySectionQuestionFileUpload' }
      & Pick<DynamicCompetitionSurveySectionQuestionFileUpload, 'id' | 'name' | 'comment' | 'fileUpload' | 'createdAt' | 'updatedAt'>
    )>>, options: Array<(
      { __typename?: 'DynamicCompetitionSurveySectionQuestionOption' }
      & Pick<DynamicCompetitionSurveySectionQuestionOption, 'id' | 'value' | 'order'>
    )>, subQuestions: Array<(
      { __typename?: 'DynamicCompetitionSurveySectionSubQuestion' }
      & Pick<DynamicCompetitionSurveySectionSubQuestion, 'id' | 'name' | 'questionText' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType' | 'selectedOption' | 'selectedOptionOrder' | 'responseShortAnswer' | 'responseText' | 'responseNumber' | 'responseBoolean'>
      & { responses?: Maybe<Array<(
        { __typename?: 'DynamicCompetitionSurveySectionSubQuestionMultipleResponse' }
        & Pick<DynamicCompetitionSurveySectionSubQuestionMultipleResponse, 'id' | 'value' | 'createdAt' | 'updatedAt'>
      )>>, fileUploads?: Maybe<Array<(
        { __typename?: 'DynamicCompetitionSurveySectionSubQuestionFileUpload' }
        & Pick<DynamicCompetitionSurveySectionSubQuestionFileUpload, 'id' | 'name' | 'comment' | 'fileUpload' | 'createdAt' | 'updatedAt'>
      )>>, options: Array<(
        { __typename?: 'DynamicCompetitionSurveySectionSubQuestionOption' }
        & Pick<DynamicCompetitionSurveySectionSubQuestionOption, 'id' | 'value' | 'order'>
      )> }
    )> }
  ) }
);

export type UpdateDynamicCompetitionSurveyNumberResponseMutationVariables = Exact<{
  competitionSurveyId: Scalars['String'];
  questionId: Scalars['String'];
  response: Scalars['Float'];
}>;


export type UpdateDynamicCompetitionSurveyNumberResponseMutation = (
  { __typename?: 'Mutation' }
  & { updateDynamicCompetitionSurveyNumberResponse: (
    { __typename?: 'DynamicCompetitionSurveySectionQuestion' }
    & Pick<DynamicCompetitionSurveySectionQuestion, 'id' | 'name' | 'description' | 'additionalText1' | 'additionalText2' | 'additionalText3' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType' | 'selectedOption' | 'selectedOptionOrder' | 'responseShortAnswer' | 'responseText' | 'responseNumber' | 'responseBoolean'>
    & { assessmentObject: (
      { __typename?: 'AssessmentObject' }
      & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name'>
    ), coach?: Maybe<(
      { __typename?: 'Coach' }
      & Pick<Coach, 'id' | 'teamId' | 'teamName' | 'teamAbbreviation' | 'sport' | 'teamCity' | 'userId' | 'type' | 'role' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
    )>, team?: Maybe<(
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'timeZone' | 'classification' | 'gender' | 'website' | 'logo' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'createdAt' | 'updatedAt'>
    )>, official?: Maybe<(
      { __typename?: 'Official' }
      & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
      & { externalIds?: Maybe<Array<(
        { __typename?: 'OfficialExternalId' }
        & Pick<OfficialExternalId, 'id' | 'organizationId' | 'externalId'>
      )>> }
    )>, organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'name' | 'sport' | 'status' | 'abbreviation' | 'site' | 'logo' | 'createdAt' | 'updatedAt'>
    )>, responses?: Maybe<Array<(
      { __typename?: 'DynamicCompetitionSurveySectionQuestionMultipleResponse' }
      & Pick<DynamicCompetitionSurveySectionQuestionMultipleResponse, 'id' | 'value' | 'createdAt' | 'updatedAt'>
    )>>, fileUploads?: Maybe<Array<(
      { __typename?: 'DynamicCompetitionSurveySectionQuestionFileUpload' }
      & Pick<DynamicCompetitionSurveySectionQuestionFileUpload, 'id' | 'name' | 'comment' | 'fileUpload' | 'createdAt' | 'updatedAt'>
    )>>, options: Array<(
      { __typename?: 'DynamicCompetitionSurveySectionQuestionOption' }
      & Pick<DynamicCompetitionSurveySectionQuestionOption, 'id' | 'value' | 'order'>
    )>, subQuestions: Array<(
      { __typename?: 'DynamicCompetitionSurveySectionSubQuestion' }
      & Pick<DynamicCompetitionSurveySectionSubQuestion, 'id' | 'name' | 'questionText' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType' | 'selectedOption' | 'selectedOptionOrder' | 'responseShortAnswer' | 'responseText' | 'responseNumber' | 'responseBoolean'>
      & { responses?: Maybe<Array<(
        { __typename?: 'DynamicCompetitionSurveySectionSubQuestionMultipleResponse' }
        & Pick<DynamicCompetitionSurveySectionSubQuestionMultipleResponse, 'id' | 'value' | 'createdAt' | 'updatedAt'>
      )>>, fileUploads?: Maybe<Array<(
        { __typename?: 'DynamicCompetitionSurveySectionSubQuestionFileUpload' }
        & Pick<DynamicCompetitionSurveySectionSubQuestionFileUpload, 'id' | 'name' | 'comment' | 'fileUpload' | 'createdAt' | 'updatedAt'>
      )>>, options: Array<(
        { __typename?: 'DynamicCompetitionSurveySectionSubQuestionOption' }
        & Pick<DynamicCompetitionSurveySectionSubQuestionOption, 'id' | 'value' | 'order'>
      )> }
    )> }
  ) }
);

export type UpdateDynamicCompetitionSurveyBooleanResponseMutationVariables = Exact<{
  competitionSurveyId: Scalars['String'];
  questionId: Scalars['String'];
  response: Scalars['Boolean'];
}>;


export type UpdateDynamicCompetitionSurveyBooleanResponseMutation = (
  { __typename?: 'Mutation' }
  & { updateDynamicCompetitionSurveyBooleanResponse: (
    { __typename?: 'DynamicCompetitionSurveySectionQuestion' }
    & Pick<DynamicCompetitionSurveySectionQuestion, 'id' | 'name' | 'description' | 'additionalText1' | 'additionalText2' | 'additionalText3' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType' | 'selectedOption' | 'selectedOptionOrder' | 'responseShortAnswer' | 'responseText' | 'responseNumber' | 'responseBoolean'>
    & { assessmentObject: (
      { __typename?: 'AssessmentObject' }
      & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name'>
    ), coach?: Maybe<(
      { __typename?: 'Coach' }
      & Pick<Coach, 'id' | 'teamId' | 'teamName' | 'teamAbbreviation' | 'sport' | 'teamCity' | 'userId' | 'type' | 'role' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
    )>, team?: Maybe<(
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'timeZone' | 'classification' | 'gender' | 'website' | 'logo' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'createdAt' | 'updatedAt'>
    )>, official?: Maybe<(
      { __typename?: 'Official' }
      & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
      & { externalIds?: Maybe<Array<(
        { __typename?: 'OfficialExternalId' }
        & Pick<OfficialExternalId, 'id' | 'organizationId' | 'externalId'>
      )>> }
    )>, organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'name' | 'sport' | 'status' | 'abbreviation' | 'site' | 'logo' | 'createdAt' | 'updatedAt'>
    )>, responses?: Maybe<Array<(
      { __typename?: 'DynamicCompetitionSurveySectionQuestionMultipleResponse' }
      & Pick<DynamicCompetitionSurveySectionQuestionMultipleResponse, 'id' | 'value' | 'createdAt' | 'updatedAt'>
    )>>, fileUploads?: Maybe<Array<(
      { __typename?: 'DynamicCompetitionSurveySectionQuestionFileUpload' }
      & Pick<DynamicCompetitionSurveySectionQuestionFileUpload, 'id' | 'name' | 'comment' | 'fileUpload' | 'createdAt' | 'updatedAt'>
    )>>, options: Array<(
      { __typename?: 'DynamicCompetitionSurveySectionQuestionOption' }
      & Pick<DynamicCompetitionSurveySectionQuestionOption, 'id' | 'value' | 'order'>
    )>, subQuestions: Array<(
      { __typename?: 'DynamicCompetitionSurveySectionSubQuestion' }
      & Pick<DynamicCompetitionSurveySectionSubQuestion, 'id' | 'name' | 'questionText' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType' | 'selectedOption' | 'selectedOptionOrder' | 'responseShortAnswer' | 'responseText' | 'responseNumber' | 'responseBoolean'>
      & { responses?: Maybe<Array<(
        { __typename?: 'DynamicCompetitionSurveySectionSubQuestionMultipleResponse' }
        & Pick<DynamicCompetitionSurveySectionSubQuestionMultipleResponse, 'id' | 'value' | 'createdAt' | 'updatedAt'>
      )>>, fileUploads?: Maybe<Array<(
        { __typename?: 'DynamicCompetitionSurveySectionSubQuestionFileUpload' }
        & Pick<DynamicCompetitionSurveySectionSubQuestionFileUpload, 'id' | 'name' | 'comment' | 'fileUpload' | 'createdAt' | 'updatedAt'>
      )>>, options: Array<(
        { __typename?: 'DynamicCompetitionSurveySectionSubQuestionOption' }
        & Pick<DynamicCompetitionSurveySectionSubQuestionOption, 'id' | 'value' | 'order'>
      )> }
    )> }
  ) }
);

export type AddDynamicCompetitionSurveyMultipleResponseMutationVariables = Exact<{
  competitionSurveyId: Scalars['String'];
  questionId: Scalars['String'];
  response: Scalars['String'];
}>;


export type AddDynamicCompetitionSurveyMultipleResponseMutation = (
  { __typename?: 'Mutation' }
  & { addDynamicCompetitionSurveyMultipleResponse: (
    { __typename?: 'DynamicCompetitionSurveySectionQuestionMultipleResponse' }
    & Pick<DynamicCompetitionSurveySectionQuestionMultipleResponse, 'id' | 'value' | 'createdAt' | 'updatedAt'>
  ) }
);

export type RemoveDynamicCompetitionSurveyMultipleResponseMutationVariables = Exact<{
  competitionSurveyId: Scalars['String'];
  questionId: Scalars['String'];
  responseId: Scalars['String'];
}>;


export type RemoveDynamicCompetitionSurveyMultipleResponseMutation = (
  { __typename?: 'Mutation' }
  & { removeDynamicCompetitionSurveyMultipleResponse: (
    { __typename?: 'DynamicCompetitionSurveySectionQuestion' }
    & Pick<DynamicCompetitionSurveySectionQuestion, 'id' | 'name' | 'description' | 'additionalText1' | 'additionalText2' | 'additionalText3' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType' | 'selectedOption' | 'selectedOptionOrder' | 'responseShortAnswer' | 'responseText' | 'responseNumber' | 'responseBoolean'>
    & { assessmentObject: (
      { __typename?: 'AssessmentObject' }
      & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name'>
    ), coach?: Maybe<(
      { __typename?: 'Coach' }
      & Pick<Coach, 'id' | 'teamId' | 'teamName' | 'teamAbbreviation' | 'sport' | 'teamCity' | 'userId' | 'type' | 'role' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
    )>, team?: Maybe<(
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'timeZone' | 'classification' | 'gender' | 'website' | 'logo' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'createdAt' | 'updatedAt'>
    )>, official?: Maybe<(
      { __typename?: 'Official' }
      & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
      & { externalIds?: Maybe<Array<(
        { __typename?: 'OfficialExternalId' }
        & Pick<OfficialExternalId, 'id' | 'organizationId' | 'externalId'>
      )>> }
    )>, organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'name' | 'sport' | 'status' | 'abbreviation' | 'site' | 'logo' | 'createdAt' | 'updatedAt'>
    )>, responses?: Maybe<Array<(
      { __typename?: 'DynamicCompetitionSurveySectionQuestionMultipleResponse' }
      & Pick<DynamicCompetitionSurveySectionQuestionMultipleResponse, 'id' | 'value' | 'createdAt' | 'updatedAt'>
    )>>, fileUploads?: Maybe<Array<(
      { __typename?: 'DynamicCompetitionSurveySectionQuestionFileUpload' }
      & Pick<DynamicCompetitionSurveySectionQuestionFileUpload, 'id' | 'name' | 'comment' | 'fileUpload' | 'createdAt' | 'updatedAt'>
    )>>, options: Array<(
      { __typename?: 'DynamicCompetitionSurveySectionQuestionOption' }
      & Pick<DynamicCompetitionSurveySectionQuestionOption, 'id' | 'value' | 'order'>
    )>, subQuestions: Array<(
      { __typename?: 'DynamicCompetitionSurveySectionSubQuestion' }
      & Pick<DynamicCompetitionSurveySectionSubQuestion, 'id' | 'name' | 'questionText' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType' | 'selectedOption' | 'selectedOptionOrder' | 'responseShortAnswer' | 'responseText' | 'responseNumber' | 'responseBoolean'>
      & { responses?: Maybe<Array<(
        { __typename?: 'DynamicCompetitionSurveySectionSubQuestionMultipleResponse' }
        & Pick<DynamicCompetitionSurveySectionSubQuestionMultipleResponse, 'id' | 'value' | 'createdAt' | 'updatedAt'>
      )>>, fileUploads?: Maybe<Array<(
        { __typename?: 'DynamicCompetitionSurveySectionSubQuestionFileUpload' }
        & Pick<DynamicCompetitionSurveySectionSubQuestionFileUpload, 'id' | 'name' | 'comment' | 'fileUpload' | 'createdAt' | 'updatedAt'>
      )>>, options: Array<(
        { __typename?: 'DynamicCompetitionSurveySectionSubQuestionOption' }
        & Pick<DynamicCompetitionSurveySectionSubQuestionOption, 'id' | 'value' | 'order'>
      )> }
    )> }
  ) }
);

export type RemoveDynamicCompetitionSurveyFileUploadMutationVariables = Exact<{
  competitionSurveyId: Scalars['String'];
  questionId: Scalars['String'];
  fileUploadId: Scalars['String'];
}>;


export type RemoveDynamicCompetitionSurveyFileUploadMutation = (
  { __typename?: 'Mutation' }
  & { removeDynamicCompetitionSurveyFileUpload: (
    { __typename?: 'DynamicCompetitionSurveySectionQuestion' }
    & Pick<DynamicCompetitionSurveySectionQuestion, 'id' | 'name' | 'description' | 'additionalText1' | 'additionalText2' | 'additionalText3' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType' | 'selectedOption' | 'selectedOptionOrder' | 'responseShortAnswer' | 'responseText' | 'responseNumber' | 'responseBoolean'>
    & { assessmentObject: (
      { __typename?: 'AssessmentObject' }
      & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name'>
    ), coach?: Maybe<(
      { __typename?: 'Coach' }
      & Pick<Coach, 'id' | 'teamId' | 'teamName' | 'teamAbbreviation' | 'sport' | 'teamCity' | 'userId' | 'type' | 'role' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
    )>, team?: Maybe<(
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'timeZone' | 'classification' | 'gender' | 'website' | 'logo' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'createdAt' | 'updatedAt'>
    )>, official?: Maybe<(
      { __typename?: 'Official' }
      & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
      & { externalIds?: Maybe<Array<(
        { __typename?: 'OfficialExternalId' }
        & Pick<OfficialExternalId, 'id' | 'organizationId' | 'externalId'>
      )>> }
    )>, organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'name' | 'sport' | 'status' | 'abbreviation' | 'site' | 'logo' | 'createdAt' | 'updatedAt'>
    )>, responses?: Maybe<Array<(
      { __typename?: 'DynamicCompetitionSurveySectionQuestionMultipleResponse' }
      & Pick<DynamicCompetitionSurveySectionQuestionMultipleResponse, 'id' | 'value' | 'createdAt' | 'updatedAt'>
    )>>, fileUploads?: Maybe<Array<(
      { __typename?: 'DynamicCompetitionSurveySectionQuestionFileUpload' }
      & Pick<DynamicCompetitionSurveySectionQuestionFileUpload, 'id' | 'name' | 'comment' | 'fileUpload' | 'createdAt' | 'updatedAt'>
    )>>, options: Array<(
      { __typename?: 'DynamicCompetitionSurveySectionQuestionOption' }
      & Pick<DynamicCompetitionSurveySectionQuestionOption, 'id' | 'value' | 'order'>
    )>, subQuestions: Array<(
      { __typename?: 'DynamicCompetitionSurveySectionSubQuestion' }
      & Pick<DynamicCompetitionSurveySectionSubQuestion, 'id' | 'name' | 'questionText' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType' | 'selectedOption' | 'selectedOptionOrder' | 'responseShortAnswer' | 'responseText' | 'responseNumber' | 'responseBoolean'>
      & { responses?: Maybe<Array<(
        { __typename?: 'DynamicCompetitionSurveySectionSubQuestionMultipleResponse' }
        & Pick<DynamicCompetitionSurveySectionSubQuestionMultipleResponse, 'id' | 'value' | 'createdAt' | 'updatedAt'>
      )>>, fileUploads?: Maybe<Array<(
        { __typename?: 'DynamicCompetitionSurveySectionSubQuestionFileUpload' }
        & Pick<DynamicCompetitionSurveySectionSubQuestionFileUpload, 'id' | 'name' | 'comment' | 'fileUpload' | 'createdAt' | 'updatedAt'>
      )>>, options: Array<(
        { __typename?: 'DynamicCompetitionSurveySectionSubQuestionOption' }
        & Pick<DynamicCompetitionSurveySectionSubQuestionOption, 'id' | 'value' | 'order'>
      )> }
    )> }
  ) }
);

export type RefreshDynamicCompetitionSurveyFileUploadLinkMutationVariables = Exact<{
  fileUploadId: Scalars['String'];
}>;


export type RefreshDynamicCompetitionSurveyFileUploadLinkMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'refreshDynamicCompetitionSurveyFileUploadLink'>
);

export type UpdateDynamicCompetitionSurveySubQuestionMultipleChoiceResponseMutationVariables = Exact<{
  competitionSurveyId: Scalars['String'];
  questionId: Scalars['String'];
  subQuestionId: Scalars['String'];
  selectedOptionId: Scalars['String'];
}>;


export type UpdateDynamicCompetitionSurveySubQuestionMultipleChoiceResponseMutation = (
  { __typename?: 'Mutation' }
  & { updateDynamicCompetitionSurveySubQuestionMultipleChoiceResponse: (
    { __typename?: 'DynamicCompetitionSurveySectionSubQuestion' }
    & Pick<DynamicCompetitionSurveySectionSubQuestion, 'id' | 'name' | 'questionText' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType' | 'selectedOption' | 'selectedOptionOrder' | 'responseShortAnswer' | 'responseText' | 'responseNumber' | 'responseBoolean'>
    & { responses?: Maybe<Array<(
      { __typename?: 'DynamicCompetitionSurveySectionSubQuestionMultipleResponse' }
      & Pick<DynamicCompetitionSurveySectionSubQuestionMultipleResponse, 'id' | 'value' | 'createdAt' | 'updatedAt'>
    )>>, fileUploads?: Maybe<Array<(
      { __typename?: 'DynamicCompetitionSurveySectionSubQuestionFileUpload' }
      & Pick<DynamicCompetitionSurveySectionSubQuestionFileUpload, 'id' | 'name' | 'comment' | 'fileUpload' | 'createdAt' | 'updatedAt'>
    )>>, options: Array<(
      { __typename?: 'DynamicCompetitionSurveySectionSubQuestionOption' }
      & Pick<DynamicCompetitionSurveySectionSubQuestionOption, 'id' | 'value' | 'order'>
    )> }
  ) }
);

export type UpdateDynamicCompetitionSurveySubQuestionShortAnswerResponseMutationVariables = Exact<{
  competitionSurveyId: Scalars['String'];
  questionId: Scalars['String'];
  subQuestionId: Scalars['String'];
  response: Scalars['String'];
}>;


export type UpdateDynamicCompetitionSurveySubQuestionShortAnswerResponseMutation = (
  { __typename?: 'Mutation' }
  & { updateDynamicCompetitionSurveySubQuestionShortAnswerResponse: (
    { __typename?: 'DynamicCompetitionSurveySectionSubQuestion' }
    & Pick<DynamicCompetitionSurveySectionSubQuestion, 'id' | 'name' | 'questionText' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType' | 'selectedOption' | 'selectedOptionOrder' | 'responseShortAnswer' | 'responseText' | 'responseNumber' | 'responseBoolean'>
    & { responses?: Maybe<Array<(
      { __typename?: 'DynamicCompetitionSurveySectionSubQuestionMultipleResponse' }
      & Pick<DynamicCompetitionSurveySectionSubQuestionMultipleResponse, 'id' | 'value' | 'createdAt' | 'updatedAt'>
    )>>, fileUploads?: Maybe<Array<(
      { __typename?: 'DynamicCompetitionSurveySectionSubQuestionFileUpload' }
      & Pick<DynamicCompetitionSurveySectionSubQuestionFileUpload, 'id' | 'name' | 'comment' | 'fileUpload' | 'createdAt' | 'updatedAt'>
    )>>, options: Array<(
      { __typename?: 'DynamicCompetitionSurveySectionSubQuestionOption' }
      & Pick<DynamicCompetitionSurveySectionSubQuestionOption, 'id' | 'value' | 'order'>
    )> }
  ) }
);

export type UpdateDynamicCompetitionSurveySubQuestionTextResponseMutationVariables = Exact<{
  competitionSurveyId: Scalars['String'];
  questionId: Scalars['String'];
  subQuestionId: Scalars['String'];
  response: Scalars['String'];
}>;


export type UpdateDynamicCompetitionSurveySubQuestionTextResponseMutation = (
  { __typename?: 'Mutation' }
  & { updateDynamicCompetitionSurveySubQuestionTextResponse: (
    { __typename?: 'DynamicCompetitionSurveySectionSubQuestion' }
    & Pick<DynamicCompetitionSurveySectionSubQuestion, 'id' | 'name' | 'questionText' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType' | 'selectedOption' | 'selectedOptionOrder' | 'responseShortAnswer' | 'responseText' | 'responseNumber' | 'responseBoolean'>
    & { responses?: Maybe<Array<(
      { __typename?: 'DynamicCompetitionSurveySectionSubQuestionMultipleResponse' }
      & Pick<DynamicCompetitionSurveySectionSubQuestionMultipleResponse, 'id' | 'value' | 'createdAt' | 'updatedAt'>
    )>>, fileUploads?: Maybe<Array<(
      { __typename?: 'DynamicCompetitionSurveySectionSubQuestionFileUpload' }
      & Pick<DynamicCompetitionSurveySectionSubQuestionFileUpload, 'id' | 'name' | 'comment' | 'fileUpload' | 'createdAt' | 'updatedAt'>
    )>>, options: Array<(
      { __typename?: 'DynamicCompetitionSurveySectionSubQuestionOption' }
      & Pick<DynamicCompetitionSurveySectionSubQuestionOption, 'id' | 'value' | 'order'>
    )> }
  ) }
);

export type UpdateDynamicCompetitionSurveySubQuestionNumberResponseMutationVariables = Exact<{
  competitionSurveyId: Scalars['String'];
  questionId: Scalars['String'];
  subQuestionId: Scalars['String'];
  response: Scalars['Float'];
}>;


export type UpdateDynamicCompetitionSurveySubQuestionNumberResponseMutation = (
  { __typename?: 'Mutation' }
  & { updateDynamicCompetitionSurveySubQuestionNumberResponse: (
    { __typename?: 'DynamicCompetitionSurveySectionSubQuestion' }
    & Pick<DynamicCompetitionSurveySectionSubQuestion, 'id' | 'name' | 'questionText' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType' | 'selectedOption' | 'selectedOptionOrder' | 'responseShortAnswer' | 'responseText' | 'responseNumber' | 'responseBoolean'>
    & { responses?: Maybe<Array<(
      { __typename?: 'DynamicCompetitionSurveySectionSubQuestionMultipleResponse' }
      & Pick<DynamicCompetitionSurveySectionSubQuestionMultipleResponse, 'id' | 'value' | 'createdAt' | 'updatedAt'>
    )>>, fileUploads?: Maybe<Array<(
      { __typename?: 'DynamicCompetitionSurveySectionSubQuestionFileUpload' }
      & Pick<DynamicCompetitionSurveySectionSubQuestionFileUpload, 'id' | 'name' | 'comment' | 'fileUpload' | 'createdAt' | 'updatedAt'>
    )>>, options: Array<(
      { __typename?: 'DynamicCompetitionSurveySectionSubQuestionOption' }
      & Pick<DynamicCompetitionSurveySectionSubQuestionOption, 'id' | 'value' | 'order'>
    )> }
  ) }
);

export type UpdateDynamicCompetitionSurveySubQuestionBooleanResponseMutationVariables = Exact<{
  competitionSurveyId: Scalars['String'];
  questionId: Scalars['String'];
  subQuestionId: Scalars['String'];
  response: Scalars['Boolean'];
}>;


export type UpdateDynamicCompetitionSurveySubQuestionBooleanResponseMutation = (
  { __typename?: 'Mutation' }
  & { updateDynamicCompetitionSurveySubQuestionBooleanResponse: (
    { __typename?: 'DynamicCompetitionSurveySectionSubQuestion' }
    & Pick<DynamicCompetitionSurveySectionSubQuestion, 'id' | 'name' | 'questionText' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType' | 'selectedOption' | 'selectedOptionOrder' | 'responseShortAnswer' | 'responseText' | 'responseNumber' | 'responseBoolean'>
    & { responses?: Maybe<Array<(
      { __typename?: 'DynamicCompetitionSurveySectionSubQuestionMultipleResponse' }
      & Pick<DynamicCompetitionSurveySectionSubQuestionMultipleResponse, 'id' | 'value' | 'createdAt' | 'updatedAt'>
    )>>, fileUploads?: Maybe<Array<(
      { __typename?: 'DynamicCompetitionSurveySectionSubQuestionFileUpload' }
      & Pick<DynamicCompetitionSurveySectionSubQuestionFileUpload, 'id' | 'name' | 'comment' | 'fileUpload' | 'createdAt' | 'updatedAt'>
    )>>, options: Array<(
      { __typename?: 'DynamicCompetitionSurveySectionSubQuestionOption' }
      & Pick<DynamicCompetitionSurveySectionSubQuestionOption, 'id' | 'value' | 'order'>
    )> }
  ) }
);

export type UpdateDynamicCompetitionSurveyMultiPartQuestionMutationVariables = Exact<{
  competitionSurveyId: Scalars['String'];
  questionId: Scalars['String'];
  multipleResponses?: Maybe<Array<UpdateDynamicCompetitionSurveyMultiPartQuestionMultipleChoiceResponseInput> | UpdateDynamicCompetitionSurveyMultiPartQuestionMultipleChoiceResponseInput>;
  shortAnswerResponses?: Maybe<Array<UpdateDynamicCompetitionSurveyMultiPartQuestionShortAnswerResponseInput> | UpdateDynamicCompetitionSurveyMultiPartQuestionShortAnswerResponseInput>;
  textResponses?: Maybe<Array<UpdateDynamicCompetitionSurveyMultiPartQuestionTextResponseInput> | UpdateDynamicCompetitionSurveyMultiPartQuestionTextResponseInput>;
  numberResponses?: Maybe<Array<UpdateDynamicCompetitionSurveyMultiPartQuestionNumberResponseInput> | UpdateDynamicCompetitionSurveyMultiPartQuestionNumberResponseInput>;
}>;


export type UpdateDynamicCompetitionSurveyMultiPartQuestionMutation = (
  { __typename?: 'Mutation' }
  & { updateDynamicCompetitionSurveyMultiPartQuestion: (
    { __typename?: 'DynamicCompetitionSurveySectionQuestion' }
    & Pick<DynamicCompetitionSurveySectionQuestion, 'id' | 'name' | 'description' | 'additionalText1' | 'additionalText2' | 'additionalText3' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType' | 'selectedOption' | 'selectedOptionOrder' | 'responseShortAnswer' | 'responseText' | 'responseNumber' | 'responseBoolean'>
    & { assessmentObject: (
      { __typename?: 'AssessmentObject' }
      & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name'>
    ), coach?: Maybe<(
      { __typename?: 'Coach' }
      & Pick<Coach, 'id' | 'teamId' | 'teamName' | 'teamAbbreviation' | 'sport' | 'teamCity' | 'userId' | 'type' | 'role' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
    )>, team?: Maybe<(
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'timeZone' | 'classification' | 'gender' | 'website' | 'logo' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'createdAt' | 'updatedAt'>
    )>, official?: Maybe<(
      { __typename?: 'Official' }
      & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
      & { externalIds?: Maybe<Array<(
        { __typename?: 'OfficialExternalId' }
        & Pick<OfficialExternalId, 'id' | 'organizationId' | 'externalId'>
      )>> }
    )>, organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'name' | 'sport' | 'status' | 'abbreviation' | 'site' | 'logo' | 'createdAt' | 'updatedAt'>
    )>, responses?: Maybe<Array<(
      { __typename?: 'DynamicCompetitionSurveySectionQuestionMultipleResponse' }
      & Pick<DynamicCompetitionSurveySectionQuestionMultipleResponse, 'id' | 'value' | 'createdAt' | 'updatedAt'>
    )>>, fileUploads?: Maybe<Array<(
      { __typename?: 'DynamicCompetitionSurveySectionQuestionFileUpload' }
      & Pick<DynamicCompetitionSurveySectionQuestionFileUpload, 'id' | 'name' | 'comment' | 'fileUpload' | 'createdAt' | 'updatedAt'>
    )>>, options: Array<(
      { __typename?: 'DynamicCompetitionSurveySectionQuestionOption' }
      & Pick<DynamicCompetitionSurveySectionQuestionOption, 'id' | 'value' | 'order'>
    )>, subQuestions: Array<(
      { __typename?: 'DynamicCompetitionSurveySectionSubQuestion' }
      & Pick<DynamicCompetitionSurveySectionSubQuestion, 'id' | 'name' | 'questionText' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType' | 'selectedOption' | 'selectedOptionOrder' | 'responseShortAnswer' | 'responseText' | 'responseNumber' | 'responseBoolean'>
      & { responses?: Maybe<Array<(
        { __typename?: 'DynamicCompetitionSurveySectionSubQuestionMultipleResponse' }
        & Pick<DynamicCompetitionSurveySectionSubQuestionMultipleResponse, 'id' | 'value' | 'createdAt' | 'updatedAt'>
      )>>, fileUploads?: Maybe<Array<(
        { __typename?: 'DynamicCompetitionSurveySectionSubQuestionFileUpload' }
        & Pick<DynamicCompetitionSurveySectionSubQuestionFileUpload, 'id' | 'name' | 'comment' | 'fileUpload' | 'createdAt' | 'updatedAt'>
      )>>, options: Array<(
        { __typename?: 'DynamicCompetitionSurveySectionSubQuestionOption' }
        & Pick<DynamicCompetitionSurveySectionSubQuestionOption, 'id' | 'value' | 'order'>
      )> }
    )> }
  ) }
);

export type AddDynamicCompetitionSurveySubQuestionMultipleResponseMutationVariables = Exact<{
  competitionSurveyId: Scalars['String'];
  questionId: Scalars['String'];
  subQuestionId: Scalars['String'];
  response: Scalars['String'];
}>;


export type AddDynamicCompetitionSurveySubQuestionMultipleResponseMutation = (
  { __typename?: 'Mutation' }
  & { addDynamicCompetitionSurveySubQuestionMultipleResponse: (
    { __typename?: 'DynamicCompetitionSurveySectionSubQuestionMultipleResponse' }
    & Pick<DynamicCompetitionSurveySectionSubQuestionMultipleResponse, 'id' | 'value' | 'createdAt' | 'updatedAt'>
  ) }
);

export type RemoveDynamicCompetitionSurveySubQuestionMultipleResponseMutationVariables = Exact<{
  competitionSurveyId: Scalars['String'];
  questionId: Scalars['String'];
  subQuestionId: Scalars['String'];
  responseId: Scalars['String'];
}>;


export type RemoveDynamicCompetitionSurveySubQuestionMultipleResponseMutation = (
  { __typename?: 'Mutation' }
  & { removeDynamicCompetitionSurveySubQuestionMultipleResponse: (
    { __typename?: 'DynamicCompetitionSurveySectionSubQuestion' }
    & Pick<DynamicCompetitionSurveySectionSubQuestion, 'id' | 'name' | 'questionText' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType' | 'selectedOption' | 'selectedOptionOrder' | 'responseShortAnswer' | 'responseText' | 'responseNumber' | 'responseBoolean'>
    & { responses?: Maybe<Array<(
      { __typename?: 'DynamicCompetitionSurveySectionSubQuestionMultipleResponse' }
      & Pick<DynamicCompetitionSurveySectionSubQuestionMultipleResponse, 'id' | 'value' | 'createdAt' | 'updatedAt'>
    )>>, fileUploads?: Maybe<Array<(
      { __typename?: 'DynamicCompetitionSurveySectionSubQuestionFileUpload' }
      & Pick<DynamicCompetitionSurveySectionSubQuestionFileUpload, 'id' | 'name' | 'comment' | 'fileUpload' | 'createdAt' | 'updatedAt'>
    )>>, options: Array<(
      { __typename?: 'DynamicCompetitionSurveySectionSubQuestionOption' }
      & Pick<DynamicCompetitionSurveySectionSubQuestionOption, 'id' | 'value' | 'order'>
    )> }
  ) }
);

export type ListSupplementalReportsForDynamicCompetitionSurveyQueryVariables = Exact<{
  competitionSurveyId: Scalars['String'];
}>;


export type ListSupplementalReportsForDynamicCompetitionSurveyQuery = (
  { __typename?: 'Query' }
  & { listSupplementalReportsForDynamicCompetitionSurvey: (
    { __typename?: 'SupplementalReports' }
    & { supplementalReports: Array<(
      { __typename?: 'SupplementalReport' }
      & Pick<SupplementalReport, 'id' | 'competitionSurveyId' | 'dynamicCompetitionSurveyId' | 'organizationId' | 'organizationName' | 'leagueId' | 'leagueName' | 'seasonId' | 'seasonName' | 'competitionSurveySectionQuestionId' | 'competitionSurveyFeedbackId' | 'dynamicCompetitionSurveySectionQuestionId' | 'dynamicCompetitionSurveyFeedbackId' | 'dynamicCompetitionSurveySubFeedbackId' | 'type' | 'status' | 'title' | 'report' | 'reportVersion' | 'time' | 'approvedDate' | 'reopenedDate' | 'submittedAt' | 'createdAt' | 'updatedAt'>
      & { submittedBy?: Maybe<(
        { __typename?: 'SupplementalReportSubmittedByInfo' }
        & Pick<SupplementalReportSubmittedByInfo, 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'teamId' | 'teamName' | 'teamLogo' | 'officialId' | 'assessmentObjectId' | 'assessmentObjectName'>
      )>, competitionInfo: (
        { __typename?: 'SupplementalReportCompetitionInfo' }
        & Pick<SupplementalReportCompetitionInfo, 'competitionId' | 'externalId' | 'sport' | 'homeTeamId' | 'homeTeamName' | 'homeTeamScore' | 'homeTeamTieBreakerScore' | 'awayTeamId' | 'awayTeamName' | 'awayTeamScore' | 'awayTeamTieBreakerScore' | 'startTime' | 'timeZone' | 'location' | 'outcome' | 'verificationStatus'>
      ), assessmentObject?: Maybe<(
        { __typename?: 'AssessmentObject' }
        & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name' | 'createdAt' | 'updatedAt'>
      )>, official?: Maybe<(
        { __typename?: 'Official' }
        & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
      )>, team?: Maybe<(
        { __typename?: 'Team' }
        & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'classification' | 'gender' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'timeZone' | 'website' | 'logo' | 'createdAt' | 'updatedAt'>
      )> }
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type AddSupplementalReportToDynamicCompetitionSurveyMutationVariables = Exact<{
  competitionSurveyId: Scalars['String'];
  title: Scalars['String'];
  supplementalReportType: SupplementalReportTypes;
  time?: Maybe<Scalars['String']>;
  teamId?: Maybe<Scalars['String']>;
  report?: Maybe<Scalars['String']>;
}>;


export type AddSupplementalReportToDynamicCompetitionSurveyMutation = (
  { __typename?: 'Mutation' }
  & { addSupplementalReportToDynamicCompetitionSurvey: (
    { __typename?: 'SupplementalReport' }
    & Pick<SupplementalReport, 'id' | 'competitionSurveyId' | 'dynamicCompetitionSurveyId' | 'organizationId' | 'organizationName' | 'leagueId' | 'leagueName' | 'seasonId' | 'seasonName' | 'competitionSurveySectionQuestionId' | 'competitionSurveyFeedbackId' | 'dynamicCompetitionSurveySectionQuestionId' | 'dynamicCompetitionSurveyFeedbackId' | 'dynamicCompetitionSurveySubFeedbackId' | 'type' | 'status' | 'title' | 'report' | 'reportVersion' | 'time' | 'approvedDate' | 'reopenedDate' | 'submittedAt' | 'createdAt' | 'updatedAt'>
    & { submittedBy?: Maybe<(
      { __typename?: 'SupplementalReportSubmittedByInfo' }
      & Pick<SupplementalReportSubmittedByInfo, 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'teamId' | 'teamName' | 'teamLogo' | 'officialId' | 'assessmentObjectId' | 'assessmentObjectName'>
    )>, competitionInfo: (
      { __typename?: 'SupplementalReportCompetitionInfo' }
      & Pick<SupplementalReportCompetitionInfo, 'competitionId' | 'externalId' | 'sport' | 'homeTeamId' | 'homeTeamName' | 'homeTeamScore' | 'homeTeamTieBreakerScore' | 'awayTeamId' | 'awayTeamName' | 'awayTeamScore' | 'awayTeamTieBreakerScore' | 'startTime' | 'timeZone' | 'location' | 'outcome' | 'verificationStatus'>
    ), assessmentObject?: Maybe<(
      { __typename?: 'AssessmentObject' }
      & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name' | 'createdAt' | 'updatedAt'>
    )>, official?: Maybe<(
      { __typename?: 'Official' }
      & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
    )>, team?: Maybe<(
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'classification' | 'gender' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'timeZone' | 'website' | 'logo' | 'createdAt' | 'updatedAt'>
    )> }
  ) }
);

export type RemoveSupplementalReportFromDynamicCompetitionSurveyMutationVariables = Exact<{
  competitionSurveyId: Scalars['String'];
  supplementalReportId: Scalars['String'];
}>;


export type RemoveSupplementalReportFromDynamicCompetitionSurveyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeSupplementalReportFromDynamicCompetitionSurvey'>
);

export type EditSupplementalReportForDynamicCompetitionSurveyMutationVariables = Exact<{
  competitionSurveyId: Scalars['String'];
  supplementalReportId: Scalars['String'];
  title: Scalars['String'];
  supplementalReportType: SupplementalReportTypes;
  time?: Maybe<Scalars['String']>;
  teamId?: Maybe<Scalars['String']>;
  report?: Maybe<Scalars['String']>;
}>;


export type EditSupplementalReportForDynamicCompetitionSurveyMutation = (
  { __typename?: 'Mutation' }
  & { editSupplementalReportForDynamicCompetitionSurvey: (
    { __typename?: 'SupplementalReport' }
    & Pick<SupplementalReport, 'id' | 'competitionSurveyId' | 'dynamicCompetitionSurveyId' | 'organizationId' | 'organizationName' | 'leagueId' | 'leagueName' | 'seasonId' | 'seasonName' | 'competitionSurveySectionQuestionId' | 'competitionSurveyFeedbackId' | 'dynamicCompetitionSurveySectionQuestionId' | 'dynamicCompetitionSurveyFeedbackId' | 'dynamicCompetitionSurveySubFeedbackId' | 'type' | 'status' | 'title' | 'report' | 'reportVersion' | 'time' | 'approvedDate' | 'reopenedDate' | 'submittedAt' | 'createdAt' | 'updatedAt'>
    & { submittedBy?: Maybe<(
      { __typename?: 'SupplementalReportSubmittedByInfo' }
      & Pick<SupplementalReportSubmittedByInfo, 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'teamId' | 'teamName' | 'teamLogo' | 'officialId' | 'assessmentObjectId' | 'assessmentObjectName'>
    )>, competitionInfo: (
      { __typename?: 'SupplementalReportCompetitionInfo' }
      & Pick<SupplementalReportCompetitionInfo, 'competitionId' | 'externalId' | 'sport' | 'homeTeamId' | 'homeTeamName' | 'homeTeamScore' | 'homeTeamTieBreakerScore' | 'awayTeamId' | 'awayTeamName' | 'awayTeamScore' | 'awayTeamTieBreakerScore' | 'startTime' | 'timeZone' | 'location' | 'outcome' | 'verificationStatus'>
    ), assessmentObject?: Maybe<(
      { __typename?: 'AssessmentObject' }
      & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name' | 'createdAt' | 'updatedAt'>
    )>, official?: Maybe<(
      { __typename?: 'Official' }
      & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
    )>, team?: Maybe<(
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'classification' | 'gender' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'timeZone' | 'website' | 'logo' | 'createdAt' | 'updatedAt'>
    )> }
  ) }
);

export type ListKeyIncidentsForDynamicCompetitionSurveyQueryVariables = Exact<{
  competitionSurveyId: Scalars['String'];
}>;


export type ListKeyIncidentsForDynamicCompetitionSurveyQuery = (
  { __typename?: 'Query' }
  & { listKeyIncidentsForDynamicCompetitionSurvey: (
    { __typename?: 'KeyIncidents' }
    & { keyIncidents: Array<(
      { __typename?: 'KeyIncident' }
      & Pick<KeyIncident, 'id' | 'competitionSurveyId' | 'dynamicCompetitionSurveyId' | 'organizationId' | 'organizationName' | 'leagueId' | 'leagueName' | 'seasonId' | 'seasonName' | 'competitionSurveyFeedbackId' | 'dynamicCompetitionSurveyFeedbackId' | 'dynamicCompetitionSurveySubFeedbackId' | 'type' | 'status' | 'description' | 'time' | 'impactedOutcome' | 'resolvedDate' | 'reopenedDate' | 'createdAt' | 'updatedAt'>
      & { submittedBy: (
        { __typename?: 'KeyIncidentSubmittedByInfo' }
        & Pick<KeyIncidentSubmittedByInfo, 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'teamId' | 'teamName' | 'teamLogo' | 'officialId' | 'assessmentObjectId' | 'assessmentObjectName'>
      ), competitionInfo: (
        { __typename?: 'KeyIncidentCompetitionInfo' }
        & Pick<KeyIncidentCompetitionInfo, 'competitionId' | 'externalId' | 'sport' | 'homeTeamId' | 'homeTeamName' | 'homeTeamScore' | 'homeTeamTieBreakerScore' | 'awayTeamId' | 'awayTeamName' | 'awayTeamScore' | 'awayTeamTieBreakerScore' | 'startTime' | 'timeZone' | 'location' | 'outcome' | 'verificationStatus'>
      ), assessmentObject?: Maybe<(
        { __typename?: 'AssessmentObject' }
        & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name' | 'createdAt' | 'updatedAt'>
      )>, official?: Maybe<(
        { __typename?: 'Official' }
        & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
      )>, team?: Maybe<(
        { __typename?: 'Team' }
        & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'classification' | 'gender' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'timeZone' | 'website' | 'logo' | 'createdAt' | 'updatedAt'>
      )> }
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type SignDynamicCompetitionSurveyMutationVariables = Exact<{
  competitionSurveyId: Scalars['String'];
}>;


export type SignDynamicCompetitionSurveyMutation = (
  { __typename?: 'Mutation' }
  & { signDynamicCompetitionSurvey: (
    { __typename?: 'DynamicCompetitionSurveySignature' }
    & Pick<DynamicCompetitionSurveySignature, 'competitionSurveyId' | 'signedDate' | 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic'>
  ) }
);

export type CompleteDynamicCompetitionSurveyMutationVariables = Exact<{
  competitionSurveyId: Scalars['String'];
}>;


export type CompleteDynamicCompetitionSurveyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'completeDynamicCompetitionSurvey'>
);

export type ConvertDynamicCompetitionSurveyMultipleResponseToKeyIncidentMutationVariables = Exact<{
  competitionSurveyId: Scalars['String'];
  questionId: Scalars['String'];
  feedbackResponseId: Scalars['String'];
  incidentType: KeyIncidentTypes;
  impactedOutcome: Scalars['Boolean'];
  time?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
}>;


export type ConvertDynamicCompetitionSurveyMultipleResponseToKeyIncidentMutation = (
  { __typename?: 'Mutation' }
  & { convertDynamicCompetitionSurveyMultipleResponseToKeyIncident: (
    { __typename?: 'KeyIncident' }
    & Pick<KeyIncident, 'id' | 'competitionSurveyId' | 'dynamicCompetitionSurveyId' | 'organizationId' | 'organizationName' | 'leagueId' | 'leagueName' | 'seasonId' | 'seasonName' | 'competitionSurveyFeedbackId' | 'dynamicCompetitionSurveyFeedbackId' | 'dynamicCompetitionSurveySubFeedbackId' | 'type' | 'status' | 'description' | 'time' | 'impactedOutcome' | 'resolvedDate' | 'reopenedDate' | 'createdAt' | 'updatedAt'>
    & { submittedBy: (
      { __typename?: 'KeyIncidentSubmittedByInfo' }
      & Pick<KeyIncidentSubmittedByInfo, 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'teamId' | 'teamName' | 'teamLogo' | 'officialId' | 'assessmentObjectId' | 'assessmentObjectName'>
    ), competitionInfo: (
      { __typename?: 'KeyIncidentCompetitionInfo' }
      & Pick<KeyIncidentCompetitionInfo, 'competitionId' | 'externalId' | 'sport' | 'homeTeamId' | 'homeTeamName' | 'homeTeamScore' | 'homeTeamTieBreakerScore' | 'awayTeamId' | 'awayTeamName' | 'awayTeamScore' | 'awayTeamTieBreakerScore' | 'startTime' | 'timeZone' | 'location' | 'outcome' | 'verificationStatus'>
    ), assessmentObject?: Maybe<(
      { __typename?: 'AssessmentObject' }
      & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name' | 'createdAt' | 'updatedAt'>
    )>, official?: Maybe<(
      { __typename?: 'Official' }
      & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
    )>, team?: Maybe<(
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'classification' | 'gender' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'timeZone' | 'website' | 'logo' | 'createdAt' | 'updatedAt'>
    )> }
  ) }
);

export type ConvertDynamicCompetitionSurveySubMultipleResponseToKeyIncidentMutationVariables = Exact<{
  competitionSurveyId: Scalars['String'];
  subQuestionId: Scalars['String'];
  feedbackResponseId: Scalars['String'];
  incidentType: KeyIncidentTypes;
  impactedOutcome: Scalars['Boolean'];
  time?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
}>;


export type ConvertDynamicCompetitionSurveySubMultipleResponseToKeyIncidentMutation = (
  { __typename?: 'Mutation' }
  & { convertDynamicCompetitionSurveySubMultipleResponseToKeyIncident: (
    { __typename?: 'KeyIncident' }
    & Pick<KeyIncident, 'id' | 'competitionSurveyId' | 'dynamicCompetitionSurveyId' | 'organizationId' | 'organizationName' | 'leagueId' | 'leagueName' | 'seasonId' | 'seasonName' | 'competitionSurveyFeedbackId' | 'dynamicCompetitionSurveyFeedbackId' | 'dynamicCompetitionSurveySubFeedbackId' | 'type' | 'status' | 'description' | 'time' | 'impactedOutcome' | 'resolvedDate' | 'reopenedDate' | 'createdAt' | 'updatedAt'>
    & { submittedBy: (
      { __typename?: 'KeyIncidentSubmittedByInfo' }
      & Pick<KeyIncidentSubmittedByInfo, 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'teamId' | 'teamName' | 'teamLogo' | 'officialId' | 'assessmentObjectId' | 'assessmentObjectName'>
    ), competitionInfo: (
      { __typename?: 'KeyIncidentCompetitionInfo' }
      & Pick<KeyIncidentCompetitionInfo, 'competitionId' | 'externalId' | 'sport' | 'homeTeamId' | 'homeTeamName' | 'homeTeamScore' | 'homeTeamTieBreakerScore' | 'awayTeamId' | 'awayTeamName' | 'awayTeamScore' | 'awayTeamTieBreakerScore' | 'startTime' | 'timeZone' | 'location' | 'outcome' | 'verificationStatus'>
    ), assessmentObject?: Maybe<(
      { __typename?: 'AssessmentObject' }
      & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name' | 'createdAt' | 'updatedAt'>
    )>, official?: Maybe<(
      { __typename?: 'Official' }
      & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
    )>, team?: Maybe<(
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'classification' | 'gender' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'timeZone' | 'website' | 'logo' | 'createdAt' | 'updatedAt'>
    )> }
  ) }
);

export type AddKeyIncidentToDynamicCompetitionSurveyMutationVariables = Exact<{
  competitionSurveyId: Scalars['String'];
  description: Scalars['String'];
  incidentType: KeyIncidentTypes;
  impactedOutcome: Scalars['Boolean'];
  time?: Maybe<Scalars['String']>;
  teamId?: Maybe<Scalars['String']>;
  sectionId?: Maybe<Scalars['String']>;
  questionId?: Maybe<Scalars['String']>;
}>;


export type AddKeyIncidentToDynamicCompetitionSurveyMutation = (
  { __typename?: 'Mutation' }
  & { addKeyIncidentToDynamicCompetitionSurvey: (
    { __typename?: 'KeyIncident' }
    & Pick<KeyIncident, 'id' | 'competitionSurveyId' | 'organizationId' | 'organizationName' | 'leagueId' | 'leagueName' | 'seasonId' | 'seasonName' | 'competitionSurveyFeedbackId' | 'dynamicCompetitionSurveyFeedbackId' | 'dynamicCompetitionSurveySubFeedbackId' | 'type' | 'status' | 'description' | 'time' | 'impactedOutcome' | 'resolvedDate' | 'reopenedDate' | 'createdAt' | 'updatedAt'>
    & { submittedBy: (
      { __typename?: 'KeyIncidentSubmittedByInfo' }
      & Pick<KeyIncidentSubmittedByInfo, 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'teamId' | 'teamName' | 'teamLogo' | 'officialId' | 'assessmentObjectId' | 'assessmentObjectName'>
    ), competitionInfo: (
      { __typename?: 'KeyIncidentCompetitionInfo' }
      & Pick<KeyIncidentCompetitionInfo, 'competitionId' | 'externalId' | 'sport' | 'homeTeamId' | 'homeTeamName' | 'homeTeamScore' | 'homeTeamTieBreakerScore' | 'awayTeamId' | 'awayTeamName' | 'awayTeamScore' | 'awayTeamTieBreakerScore' | 'startTime' | 'timeZone' | 'location' | 'outcome' | 'verificationStatus'>
    ), assessmentObject?: Maybe<(
      { __typename?: 'AssessmentObject' }
      & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name' | 'createdAt' | 'updatedAt'>
    )>, official?: Maybe<(
      { __typename?: 'Official' }
      & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
    )>, team?: Maybe<(
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'classification' | 'gender' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'timeZone' | 'website' | 'logo' | 'createdAt' | 'updatedAt'>
    )> }
  ) }
);

export type EditKeyIncidentForDynamicCompetitionSurveyMutationVariables = Exact<{
  competitionSurveyId: Scalars['String'];
  keyIncidentId: Scalars['String'];
  description: Scalars['String'];
  incidentType: KeyIncidentTypes;
  impactedOutcome: Scalars['Boolean'];
  time?: Maybe<Scalars['String']>;
}>;


export type EditKeyIncidentForDynamicCompetitionSurveyMutation = (
  { __typename?: 'Mutation' }
  & { editKeyIncidentForDynamicCompetitionSurvey: (
    { __typename?: 'KeyIncident' }
    & Pick<KeyIncident, 'id' | 'competitionSurveyId' | 'organizationId' | 'organizationName' | 'leagueId' | 'leagueName' | 'seasonId' | 'seasonName' | 'competitionSurveyFeedbackId' | 'dynamicCompetitionSurveyFeedbackId' | 'dynamicCompetitionSurveySubFeedbackId' | 'type' | 'status' | 'description' | 'time' | 'impactedOutcome' | 'resolvedDate' | 'reopenedDate' | 'createdAt' | 'updatedAt'>
    & { submittedBy: (
      { __typename?: 'KeyIncidentSubmittedByInfo' }
      & Pick<KeyIncidentSubmittedByInfo, 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'teamId' | 'teamName' | 'teamLogo' | 'officialId' | 'assessmentObjectId' | 'assessmentObjectName'>
    ), competitionInfo: (
      { __typename?: 'KeyIncidentCompetitionInfo' }
      & Pick<KeyIncidentCompetitionInfo, 'competitionId' | 'externalId' | 'sport' | 'homeTeamId' | 'homeTeamName' | 'homeTeamScore' | 'homeTeamTieBreakerScore' | 'awayTeamId' | 'awayTeamName' | 'awayTeamScore' | 'awayTeamTieBreakerScore' | 'startTime' | 'timeZone' | 'location' | 'outcome' | 'verificationStatus'>
    ), assessmentObject?: Maybe<(
      { __typename?: 'AssessmentObject' }
      & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name' | 'createdAt' | 'updatedAt'>
    )>, official?: Maybe<(
      { __typename?: 'Official' }
      & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
    )>, team?: Maybe<(
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'classification' | 'gender' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'timeZone' | 'website' | 'logo' | 'createdAt' | 'updatedAt'>
    )> }
  ) }
);

export type RemoveKeyIncidentFromDynamicCompetitionSurveyMutationVariables = Exact<{
  competitionSurveyId: Scalars['String'];
  keyIncidentId: Scalars['String'];
}>;


export type RemoveKeyIncidentFromDynamicCompetitionSurveyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeKeyIncidentFromDynamicCompetitionSurvey'>
);

export type GetDynamicCompetitionSurveyByIdWithTokenQueryVariables = Exact<{
  surveyToken: Scalars['String'];
}>;


export type GetDynamicCompetitionSurveyByIdWithTokenQuery = (
  { __typename?: 'Query' }
  & { getDynamicCompetitionSurveyByIdWithToken: (
    { __typename?: 'DynamicCompetitionSurvey' }
    & Pick<DynamicCompetitionSurvey, 'id' | 'surveyId' | 'orgId' | 'seasonId' | 'sport' | 'finished' | 'completedDate' | 'hasSupplementalReport' | 'allowAdditionalSupplementalReports' | 'allowAdditionalSupplementalReportsCutoffDays' | 'hasCompetitionVerification' | 'hasIncidentReport' | 'allowAdditionalIncidentReports' | 'allowAdditionalIncidentReportsCutoffDays' | 'hasSignatureSection' | 'signatureText' | 'createdAt' | 'updatedAt'>
    & { signature?: Maybe<(
      { __typename?: 'DynamicCompetitionSurveySignature' }
      & Pick<DynamicCompetitionSurveySignature, 'competitionSurveyId' | 'signedDate' | 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic'>
    )>, recipients: Array<(
      { __typename?: 'DynamicCompetitionSurveyUser' }
      & Pick<DynamicCompetitionSurveyUser, 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'tookSurvey'>
    )>, competition: (
      { __typename?: 'Competition' }
      & Pick<Competition, 'id' | 'externalId' | 'sport' | 'startTime' | 'timeZone' | 'location' | 'homeTeamScore' | 'homeTeamTieBreakerScore' | 'awayTeamScore' | 'awayTeamTieBreakerScore' | 'createdAt' | 'updatedAt'>
      & { homeTeam: (
        { __typename?: 'Team' }
        & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'classification' | 'gender' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'timeZone' | 'website' | 'logo' | 'createdAt' | 'updatedAt'>
      ), awayTeam: (
        { __typename?: 'Team' }
        & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'classification' | 'gender' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'timeZone' | 'website' | 'logo' | 'createdAt' | 'updatedAt'>
      ), organization: (
        { __typename?: 'Organization' }
        & Pick<Organization, 'id' | 'name' | 'sport' | 'status' | 'abbreviation' | 'site' | 'logo' | 'createdAt' | 'updatedAt'>
      ), season?: Maybe<(
        { __typename?: 'Season' }
        & Pick<Season, 'id' | 'leagueId' | 'leagueName' | 'leagueLogo' | 'name' | 'description' | 'status' | 'startDate' | 'endDate' | 'createdAt' | 'updatedAt'>
      )>, officials: Array<(
        { __typename?: 'CompetitionOfficial' }
        & Pick<CompetitionOfficial, 'id' | 'competitionId' | 'assessmentObjectId' | 'order' | 'type' | 'createdAt' | 'updatedAt'>
        & { official: (
          { __typename?: 'Official' }
          & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    ), info: (
      { __typename?: 'DynamicCompetitionSurveyInfo' }
      & Pick<DynamicCompetitionSurveyInfo, 'name' | 'description' | 'audience'>
    ), sections: Array<(
      { __typename?: 'DynamicCompetitionSurveySection' }
      & Pick<DynamicCompetitionSurveySection, 'id' | 'name' | 'description' | 'requireSupplementalReport' | 'finished' | 'order' | 'createdAt' | 'updatedAt'>
      & { questions: Array<(
        { __typename?: 'DynamicCompetitionSurveySectionQuestion' }
        & Pick<DynamicCompetitionSurveySectionQuestion, 'id' | 'name' | 'description' | 'additionalText1' | 'additionalText2' | 'additionalText3' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType' | 'selectedOption' | 'selectedOptionOrder' | 'responseShortAnswer' | 'responseText' | 'responseNumber' | 'responseBoolean'>
        & { assessmentObject: (
          { __typename?: 'AssessmentObject' }
          & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name'>
        ), coach?: Maybe<(
          { __typename?: 'Coach' }
          & Pick<Coach, 'id' | 'teamId' | 'teamName' | 'teamAbbreviation' | 'sport' | 'teamCity' | 'userId' | 'type' | 'role' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
        )>, team?: Maybe<(
          { __typename?: 'Team' }
          & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'timeZone' | 'classification' | 'gender' | 'website' | 'logo' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'createdAt' | 'updatedAt'>
        )>, official?: Maybe<(
          { __typename?: 'Official' }
          & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
          & { externalIds?: Maybe<Array<(
            { __typename?: 'OfficialExternalId' }
            & Pick<OfficialExternalId, 'id' | 'organizationId' | 'externalId'>
          )>> }
        )>, organization?: Maybe<(
          { __typename?: 'Organization' }
          & Pick<Organization, 'id' | 'name' | 'sport' | 'status' | 'abbreviation' | 'site' | 'logo' | 'createdAt' | 'updatedAt'>
        )>, responses?: Maybe<Array<(
          { __typename?: 'DynamicCompetitionSurveySectionQuestionMultipleResponse' }
          & Pick<DynamicCompetitionSurveySectionQuestionMultipleResponse, 'id' | 'value' | 'createdAt' | 'updatedAt'>
        )>>, fileUploads?: Maybe<Array<(
          { __typename?: 'DynamicCompetitionSurveySectionQuestionFileUpload' }
          & Pick<DynamicCompetitionSurveySectionQuestionFileUpload, 'id' | 'name' | 'comment' | 'fileUpload' | 'createdAt' | 'updatedAt'>
        )>>, options: Array<(
          { __typename?: 'DynamicCompetitionSurveySectionQuestionOption' }
          & Pick<DynamicCompetitionSurveySectionQuestionOption, 'id' | 'value' | 'order'>
        )>, subQuestions: Array<(
          { __typename?: 'DynamicCompetitionSurveySectionSubQuestion' }
          & Pick<DynamicCompetitionSurveySectionSubQuestion, 'id' | 'name' | 'questionText' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType' | 'selectedOption' | 'selectedOptionOrder' | 'responseShortAnswer' | 'responseText' | 'responseNumber' | 'responseBoolean'>
          & { responses?: Maybe<Array<(
            { __typename?: 'DynamicCompetitionSurveySectionSubQuestionMultipleResponse' }
            & Pick<DynamicCompetitionSurveySectionSubQuestionMultipleResponse, 'id' | 'value' | 'createdAt' | 'updatedAt'>
          )>>, fileUploads?: Maybe<Array<(
            { __typename?: 'DynamicCompetitionSurveySectionSubQuestionFileUpload' }
            & Pick<DynamicCompetitionSurveySectionSubQuestionFileUpload, 'id' | 'name' | 'comment' | 'fileUpload' | 'createdAt' | 'updatedAt'>
          )>>, options: Array<(
            { __typename?: 'DynamicCompetitionSurveySectionSubQuestionOption' }
            & Pick<DynamicCompetitionSurveySectionSubQuestionOption, 'id' | 'value' | 'order'>
          )> }
        )> }
      )> }
    )> }
  ) }
);

export type ListStatsForDynamicCompetitionSurveyByIdWithTokenQueryVariables = Exact<{
  surveyToken: Scalars['String'];
}>;


export type ListStatsForDynamicCompetitionSurveyByIdWithTokenQuery = (
  { __typename?: 'Query' }
  & { listStatsForDynamicCompetitionSurveyByIdWithToken: Array<(
    { __typename?: 'CompetitionStat' }
    & Pick<CompetitionStat, 'id' | 'competitionId' | 'teamId' | 'name' | 'displayText' | 'valueString' | 'valueNumeric' | 'valueDate' | 'createdAt' | 'updatedAt'>
  )> }
);

export type StartDynamicCompetitionSurveyWithTokenMutationVariables = Exact<{
  surveyToken: Scalars['String'];
}>;


export type StartDynamicCompetitionSurveyWithTokenMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'startDynamicCompetitionSurveyWithToken'>
);

export type UpdateDynamicCompetitionSurveyCompetitionScoreWithTokenMutationVariables = Exact<{
  surveyToken: Scalars['String'];
  homeTeamScore: Scalars['Int'];
  awayTeamScore: Scalars['Int'];
  homeTeamTieBreakerScore?: Maybe<Scalars['Int']>;
  awayTeamTieBreakerScore?: Maybe<Scalars['Int']>;
}>;


export type UpdateDynamicCompetitionSurveyCompetitionScoreWithTokenMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateDynamicCompetitionSurveyCompetitionScoreWithToken'>
);

export type UpdateDynamicCompetitionSurveyMultipleChoiceResponseWithTokenMutationVariables = Exact<{
  surveyToken: Scalars['String'];
  questionId: Scalars['String'];
  selectedOptionId: Scalars['String'];
}>;


export type UpdateDynamicCompetitionSurveyMultipleChoiceResponseWithTokenMutation = (
  { __typename?: 'Mutation' }
  & { updateDynamicCompetitionSurveyMultipleChoiceResponseWithToken: (
    { __typename?: 'DynamicCompetitionSurveySectionQuestion' }
    & Pick<DynamicCompetitionSurveySectionQuestion, 'id' | 'name' | 'description' | 'additionalText1' | 'additionalText2' | 'additionalText3' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType' | 'selectedOption' | 'selectedOptionOrder' | 'responseShortAnswer' | 'responseText' | 'responseNumber' | 'responseBoolean'>
    & { assessmentObject: (
      { __typename?: 'AssessmentObject' }
      & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name'>
    ), coach?: Maybe<(
      { __typename?: 'Coach' }
      & Pick<Coach, 'id' | 'teamId' | 'teamName' | 'teamAbbreviation' | 'sport' | 'teamCity' | 'userId' | 'type' | 'role' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
    )>, team?: Maybe<(
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'timeZone' | 'classification' | 'gender' | 'website' | 'logo' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'createdAt' | 'updatedAt'>
    )>, official?: Maybe<(
      { __typename?: 'Official' }
      & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
      & { externalIds?: Maybe<Array<(
        { __typename?: 'OfficialExternalId' }
        & Pick<OfficialExternalId, 'id' | 'organizationId' | 'externalId'>
      )>> }
    )>, organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'name' | 'sport' | 'status' | 'abbreviation' | 'site' | 'logo' | 'createdAt' | 'updatedAt'>
    )>, responses?: Maybe<Array<(
      { __typename?: 'DynamicCompetitionSurveySectionQuestionMultipleResponse' }
      & Pick<DynamicCompetitionSurveySectionQuestionMultipleResponse, 'id' | 'value' | 'createdAt' | 'updatedAt'>
    )>>, fileUploads?: Maybe<Array<(
      { __typename?: 'DynamicCompetitionSurveySectionQuestionFileUpload' }
      & Pick<DynamicCompetitionSurveySectionQuestionFileUpload, 'id' | 'name' | 'comment' | 'fileUpload' | 'createdAt' | 'updatedAt'>
    )>>, options: Array<(
      { __typename?: 'DynamicCompetitionSurveySectionQuestionOption' }
      & Pick<DynamicCompetitionSurveySectionQuestionOption, 'id' | 'value' | 'order'>
    )>, subQuestions: Array<(
      { __typename?: 'DynamicCompetitionSurveySectionSubQuestion' }
      & Pick<DynamicCompetitionSurveySectionSubQuestion, 'id' | 'name' | 'questionText' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType' | 'selectedOption' | 'selectedOptionOrder' | 'responseShortAnswer' | 'responseText' | 'responseNumber' | 'responseBoolean'>
      & { responses?: Maybe<Array<(
        { __typename?: 'DynamicCompetitionSurveySectionSubQuestionMultipleResponse' }
        & Pick<DynamicCompetitionSurveySectionSubQuestionMultipleResponse, 'id' | 'value' | 'createdAt' | 'updatedAt'>
      )>>, fileUploads?: Maybe<Array<(
        { __typename?: 'DynamicCompetitionSurveySectionSubQuestionFileUpload' }
        & Pick<DynamicCompetitionSurveySectionSubQuestionFileUpload, 'id' | 'name' | 'comment' | 'fileUpload' | 'createdAt' | 'updatedAt'>
      )>>, options: Array<(
        { __typename?: 'DynamicCompetitionSurveySectionSubQuestionOption' }
        & Pick<DynamicCompetitionSurveySectionSubQuestionOption, 'id' | 'value' | 'order'>
      )> }
    )> }
  ) }
);

export type UpdateDynamicCompetitionSurveyShortAnswerResponseWithTokenMutationVariables = Exact<{
  surveyToken: Scalars['String'];
  questionId: Scalars['String'];
  response: Scalars['String'];
}>;


export type UpdateDynamicCompetitionSurveyShortAnswerResponseWithTokenMutation = (
  { __typename?: 'Mutation' }
  & { updateDynamicCompetitionSurveyShortAnswerResponseWithToken: (
    { __typename?: 'DynamicCompetitionSurveySectionQuestion' }
    & Pick<DynamicCompetitionSurveySectionQuestion, 'id' | 'name' | 'description' | 'additionalText1' | 'additionalText2' | 'additionalText3' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType' | 'selectedOption' | 'selectedOptionOrder' | 'responseShortAnswer' | 'responseText' | 'responseNumber' | 'responseBoolean'>
    & { assessmentObject: (
      { __typename?: 'AssessmentObject' }
      & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name'>
    ), coach?: Maybe<(
      { __typename?: 'Coach' }
      & Pick<Coach, 'id' | 'teamId' | 'teamName' | 'teamAbbreviation' | 'sport' | 'teamCity' | 'userId' | 'type' | 'role' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
    )>, team?: Maybe<(
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'timeZone' | 'classification' | 'gender' | 'website' | 'logo' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'createdAt' | 'updatedAt'>
    )>, official?: Maybe<(
      { __typename?: 'Official' }
      & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
      & { externalIds?: Maybe<Array<(
        { __typename?: 'OfficialExternalId' }
        & Pick<OfficialExternalId, 'id' | 'organizationId' | 'externalId'>
      )>> }
    )>, organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'name' | 'sport' | 'status' | 'abbreviation' | 'site' | 'logo' | 'createdAt' | 'updatedAt'>
    )>, responses?: Maybe<Array<(
      { __typename?: 'DynamicCompetitionSurveySectionQuestionMultipleResponse' }
      & Pick<DynamicCompetitionSurveySectionQuestionMultipleResponse, 'id' | 'value' | 'createdAt' | 'updatedAt'>
    )>>, fileUploads?: Maybe<Array<(
      { __typename?: 'DynamicCompetitionSurveySectionQuestionFileUpload' }
      & Pick<DynamicCompetitionSurveySectionQuestionFileUpload, 'id' | 'name' | 'comment' | 'fileUpload' | 'createdAt' | 'updatedAt'>
    )>>, options: Array<(
      { __typename?: 'DynamicCompetitionSurveySectionQuestionOption' }
      & Pick<DynamicCompetitionSurveySectionQuestionOption, 'id' | 'value' | 'order'>
    )>, subQuestions: Array<(
      { __typename?: 'DynamicCompetitionSurveySectionSubQuestion' }
      & Pick<DynamicCompetitionSurveySectionSubQuestion, 'id' | 'name' | 'questionText' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType' | 'selectedOption' | 'selectedOptionOrder' | 'responseShortAnswer' | 'responseText' | 'responseNumber' | 'responseBoolean'>
      & { responses?: Maybe<Array<(
        { __typename?: 'DynamicCompetitionSurveySectionSubQuestionMultipleResponse' }
        & Pick<DynamicCompetitionSurveySectionSubQuestionMultipleResponse, 'id' | 'value' | 'createdAt' | 'updatedAt'>
      )>>, fileUploads?: Maybe<Array<(
        { __typename?: 'DynamicCompetitionSurveySectionSubQuestionFileUpload' }
        & Pick<DynamicCompetitionSurveySectionSubQuestionFileUpload, 'id' | 'name' | 'comment' | 'fileUpload' | 'createdAt' | 'updatedAt'>
      )>>, options: Array<(
        { __typename?: 'DynamicCompetitionSurveySectionSubQuestionOption' }
        & Pick<DynamicCompetitionSurveySectionSubQuestionOption, 'id' | 'value' | 'order'>
      )> }
    )> }
  ) }
);

export type UpdateDynamicCompetitionSurveyTextResponseWithTokenMutationVariables = Exact<{
  surveyToken: Scalars['String'];
  questionId: Scalars['String'];
  response: Scalars['String'];
}>;


export type UpdateDynamicCompetitionSurveyTextResponseWithTokenMutation = (
  { __typename?: 'Mutation' }
  & { updateDynamicCompetitionSurveyTextResponseWithToken: (
    { __typename?: 'DynamicCompetitionSurveySectionQuestion' }
    & Pick<DynamicCompetitionSurveySectionQuestion, 'id' | 'name' | 'description' | 'additionalText1' | 'additionalText2' | 'additionalText3' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType' | 'selectedOption' | 'selectedOptionOrder' | 'responseShortAnswer' | 'responseText' | 'responseNumber' | 'responseBoolean'>
    & { assessmentObject: (
      { __typename?: 'AssessmentObject' }
      & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name'>
    ), coach?: Maybe<(
      { __typename?: 'Coach' }
      & Pick<Coach, 'id' | 'teamId' | 'teamName' | 'teamAbbreviation' | 'sport' | 'teamCity' | 'userId' | 'type' | 'role' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
    )>, team?: Maybe<(
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'timeZone' | 'classification' | 'gender' | 'website' | 'logo' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'createdAt' | 'updatedAt'>
    )>, official?: Maybe<(
      { __typename?: 'Official' }
      & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
      & { externalIds?: Maybe<Array<(
        { __typename?: 'OfficialExternalId' }
        & Pick<OfficialExternalId, 'id' | 'organizationId' | 'externalId'>
      )>> }
    )>, organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'name' | 'sport' | 'status' | 'abbreviation' | 'site' | 'logo' | 'createdAt' | 'updatedAt'>
    )>, responses?: Maybe<Array<(
      { __typename?: 'DynamicCompetitionSurveySectionQuestionMultipleResponse' }
      & Pick<DynamicCompetitionSurveySectionQuestionMultipleResponse, 'id' | 'value' | 'createdAt' | 'updatedAt'>
    )>>, fileUploads?: Maybe<Array<(
      { __typename?: 'DynamicCompetitionSurveySectionQuestionFileUpload' }
      & Pick<DynamicCompetitionSurveySectionQuestionFileUpload, 'id' | 'name' | 'comment' | 'fileUpload' | 'createdAt' | 'updatedAt'>
    )>>, options: Array<(
      { __typename?: 'DynamicCompetitionSurveySectionQuestionOption' }
      & Pick<DynamicCompetitionSurveySectionQuestionOption, 'id' | 'value' | 'order'>
    )>, subQuestions: Array<(
      { __typename?: 'DynamicCompetitionSurveySectionSubQuestion' }
      & Pick<DynamicCompetitionSurveySectionSubQuestion, 'id' | 'name' | 'questionText' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType' | 'selectedOption' | 'selectedOptionOrder' | 'responseShortAnswer' | 'responseText' | 'responseNumber' | 'responseBoolean'>
      & { responses?: Maybe<Array<(
        { __typename?: 'DynamicCompetitionSurveySectionSubQuestionMultipleResponse' }
        & Pick<DynamicCompetitionSurveySectionSubQuestionMultipleResponse, 'id' | 'value' | 'createdAt' | 'updatedAt'>
      )>>, fileUploads?: Maybe<Array<(
        { __typename?: 'DynamicCompetitionSurveySectionSubQuestionFileUpload' }
        & Pick<DynamicCompetitionSurveySectionSubQuestionFileUpload, 'id' | 'name' | 'comment' | 'fileUpload' | 'createdAt' | 'updatedAt'>
      )>>, options: Array<(
        { __typename?: 'DynamicCompetitionSurveySectionSubQuestionOption' }
        & Pick<DynamicCompetitionSurveySectionSubQuestionOption, 'id' | 'value' | 'order'>
      )> }
    )> }
  ) }
);

export type UpdateDynamicCompetitionSurveyNumberResponseWithTokenMutationVariables = Exact<{
  surveyToken: Scalars['String'];
  questionId: Scalars['String'];
  response: Scalars['Float'];
}>;


export type UpdateDynamicCompetitionSurveyNumberResponseWithTokenMutation = (
  { __typename?: 'Mutation' }
  & { updateDynamicCompetitionSurveyNumberResponseWithToken: (
    { __typename?: 'DynamicCompetitionSurveySectionQuestion' }
    & Pick<DynamicCompetitionSurveySectionQuestion, 'id' | 'name' | 'description' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType' | 'selectedOption' | 'selectedOptionOrder' | 'responseShortAnswer' | 'responseText' | 'responseNumber' | 'responseBoolean'>
    & { responses?: Maybe<Array<(
      { __typename?: 'DynamicCompetitionSurveySectionQuestionMultipleResponse' }
      & Pick<DynamicCompetitionSurveySectionQuestionMultipleResponse, 'id' | 'value' | 'createdAt' | 'updatedAt'>
    )>>, fileUploads?: Maybe<Array<(
      { __typename?: 'DynamicCompetitionSurveySectionQuestionFileUpload' }
      & Pick<DynamicCompetitionSurveySectionQuestionFileUpload, 'id' | 'name' | 'comment' | 'fileUpload' | 'createdAt' | 'updatedAt'>
    )>>, options: Array<(
      { __typename?: 'DynamicCompetitionSurveySectionQuestionOption' }
      & Pick<DynamicCompetitionSurveySectionQuestionOption, 'id' | 'value' | 'order'>
    )>, subQuestions: Array<(
      { __typename?: 'DynamicCompetitionSurveySectionSubQuestion' }
      & Pick<DynamicCompetitionSurveySectionSubQuestion, 'id' | 'name' | 'questionText' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType' | 'selectedOption' | 'selectedOptionOrder' | 'responseShortAnswer' | 'responseText' | 'responseNumber' | 'responseBoolean'>
      & { responses?: Maybe<Array<(
        { __typename?: 'DynamicCompetitionSurveySectionSubQuestionMultipleResponse' }
        & Pick<DynamicCompetitionSurveySectionSubQuestionMultipleResponse, 'id' | 'value' | 'createdAt' | 'updatedAt'>
      )>>, fileUploads?: Maybe<Array<(
        { __typename?: 'DynamicCompetitionSurveySectionSubQuestionFileUpload' }
        & Pick<DynamicCompetitionSurveySectionSubQuestionFileUpload, 'id' | 'name' | 'comment' | 'fileUpload' | 'createdAt' | 'updatedAt'>
      )>>, options: Array<(
        { __typename?: 'DynamicCompetitionSurveySectionSubQuestionOption' }
        & Pick<DynamicCompetitionSurveySectionSubQuestionOption, 'id' | 'value' | 'order'>
      )> }
    )> }
  ) }
);

export type UpdateDynamicCompetitionSurveyBooleanResponseWithTokenMutationVariables = Exact<{
  surveyToken: Scalars['String'];
  questionId: Scalars['String'];
  response: Scalars['Boolean'];
}>;


export type UpdateDynamicCompetitionSurveyBooleanResponseWithTokenMutation = (
  { __typename?: 'Mutation' }
  & { updateDynamicCompetitionSurveyBooleanResponseWithToken: (
    { __typename?: 'DynamicCompetitionSurveySectionQuestion' }
    & Pick<DynamicCompetitionSurveySectionQuestion, 'id' | 'name' | 'description' | 'additionalText1' | 'additionalText2' | 'additionalText3' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType' | 'selectedOption' | 'selectedOptionOrder' | 'responseShortAnswer' | 'responseText' | 'responseNumber' | 'responseBoolean'>
    & { assessmentObject: (
      { __typename?: 'AssessmentObject' }
      & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name'>
    ), coach?: Maybe<(
      { __typename?: 'Coach' }
      & Pick<Coach, 'id' | 'teamId' | 'teamName' | 'teamAbbreviation' | 'sport' | 'teamCity' | 'userId' | 'type' | 'role' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
    )>, team?: Maybe<(
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'timeZone' | 'classification' | 'gender' | 'website' | 'logo' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'createdAt' | 'updatedAt'>
    )>, official?: Maybe<(
      { __typename?: 'Official' }
      & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
      & { externalIds?: Maybe<Array<(
        { __typename?: 'OfficialExternalId' }
        & Pick<OfficialExternalId, 'id' | 'organizationId' | 'externalId'>
      )>> }
    )>, organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'name' | 'sport' | 'status' | 'abbreviation' | 'site' | 'logo' | 'createdAt' | 'updatedAt'>
    )>, responses?: Maybe<Array<(
      { __typename?: 'DynamicCompetitionSurveySectionQuestionMultipleResponse' }
      & Pick<DynamicCompetitionSurveySectionQuestionMultipleResponse, 'id' | 'value' | 'createdAt' | 'updatedAt'>
    )>>, fileUploads?: Maybe<Array<(
      { __typename?: 'DynamicCompetitionSurveySectionQuestionFileUpload' }
      & Pick<DynamicCompetitionSurveySectionQuestionFileUpload, 'id' | 'name' | 'comment' | 'fileUpload' | 'createdAt' | 'updatedAt'>
    )>>, options: Array<(
      { __typename?: 'DynamicCompetitionSurveySectionQuestionOption' }
      & Pick<DynamicCompetitionSurveySectionQuestionOption, 'id' | 'value' | 'order'>
    )>, subQuestions: Array<(
      { __typename?: 'DynamicCompetitionSurveySectionSubQuestion' }
      & Pick<DynamicCompetitionSurveySectionSubQuestion, 'id' | 'name' | 'questionText' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType' | 'selectedOption' | 'selectedOptionOrder' | 'responseShortAnswer' | 'responseText' | 'responseNumber' | 'responseBoolean'>
      & { responses?: Maybe<Array<(
        { __typename?: 'DynamicCompetitionSurveySectionSubQuestionMultipleResponse' }
        & Pick<DynamicCompetitionSurveySectionSubQuestionMultipleResponse, 'id' | 'value' | 'createdAt' | 'updatedAt'>
      )>>, fileUploads?: Maybe<Array<(
        { __typename?: 'DynamicCompetitionSurveySectionSubQuestionFileUpload' }
        & Pick<DynamicCompetitionSurveySectionSubQuestionFileUpload, 'id' | 'name' | 'comment' | 'fileUpload' | 'createdAt' | 'updatedAt'>
      )>>, options: Array<(
        { __typename?: 'DynamicCompetitionSurveySectionSubQuestionOption' }
        & Pick<DynamicCompetitionSurveySectionSubQuestionOption, 'id' | 'value' | 'order'>
      )> }
    )> }
  ) }
);

export type AddDynamicCompetitionSurveyMultipleResponseWithTokenMutationVariables = Exact<{
  surveyToken: Scalars['String'];
  questionId: Scalars['String'];
  response: Scalars['String'];
}>;


export type AddDynamicCompetitionSurveyMultipleResponseWithTokenMutation = (
  { __typename?: 'Mutation' }
  & { addDynamicCompetitionSurveyMultipleResponseWithToken: (
    { __typename?: 'DynamicCompetitionSurveySectionQuestionMultipleResponse' }
    & Pick<DynamicCompetitionSurveySectionQuestionMultipleResponse, 'id' | 'value' | 'createdAt' | 'updatedAt'>
  ) }
);

export type RemoveDynamicCompetitionSurveyMultipleResponseWithTokenMutationVariables = Exact<{
  surveyToken: Scalars['String'];
  questionId: Scalars['String'];
  responseId: Scalars['String'];
}>;


export type RemoveDynamicCompetitionSurveyMultipleResponseWithTokenMutation = (
  { __typename?: 'Mutation' }
  & { removeDynamicCompetitionSurveyMultipleResponseWithToken: (
    { __typename?: 'DynamicCompetitionSurveySectionQuestion' }
    & Pick<DynamicCompetitionSurveySectionQuestion, 'id' | 'name' | 'description' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType' | 'selectedOption' | 'selectedOptionOrder' | 'responseShortAnswer' | 'responseText' | 'responseNumber' | 'responseBoolean'>
    & { responses?: Maybe<Array<(
      { __typename?: 'DynamicCompetitionSurveySectionQuestionMultipleResponse' }
      & Pick<DynamicCompetitionSurveySectionQuestionMultipleResponse, 'id' | 'value' | 'createdAt' | 'updatedAt'>
    )>>, fileUploads?: Maybe<Array<(
      { __typename?: 'DynamicCompetitionSurveySectionQuestionFileUpload' }
      & Pick<DynamicCompetitionSurveySectionQuestionFileUpload, 'id' | 'name' | 'comment' | 'fileUpload' | 'createdAt' | 'updatedAt'>
    )>>, options: Array<(
      { __typename?: 'DynamicCompetitionSurveySectionQuestionOption' }
      & Pick<DynamicCompetitionSurveySectionQuestionOption, 'id' | 'value' | 'order'>
    )>, subQuestions: Array<(
      { __typename?: 'DynamicCompetitionSurveySectionSubQuestion' }
      & Pick<DynamicCompetitionSurveySectionSubQuestion, 'id' | 'name' | 'questionText' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType' | 'selectedOption' | 'selectedOptionOrder' | 'responseShortAnswer' | 'responseText' | 'responseNumber' | 'responseBoolean'>
      & { responses?: Maybe<Array<(
        { __typename?: 'DynamicCompetitionSurveySectionSubQuestionMultipleResponse' }
        & Pick<DynamicCompetitionSurveySectionSubQuestionMultipleResponse, 'id' | 'value' | 'createdAt' | 'updatedAt'>
      )>>, fileUploads?: Maybe<Array<(
        { __typename?: 'DynamicCompetitionSurveySectionSubQuestionFileUpload' }
        & Pick<DynamicCompetitionSurveySectionSubQuestionFileUpload, 'id' | 'name' | 'comment' | 'fileUpload' | 'createdAt' | 'updatedAt'>
      )>>, options: Array<(
        { __typename?: 'DynamicCompetitionSurveySectionSubQuestionOption' }
        & Pick<DynamicCompetitionSurveySectionSubQuestionOption, 'id' | 'value' | 'order'>
      )> }
    )> }
  ) }
);

export type AddDynamicCompetitionSurveyFileUploadWithTokenMutationVariables = Exact<{
  surveyToken: Scalars['String'];
  questionId: Scalars['String'];
  file: Scalars['Upload'];
  comment?: Maybe<Scalars['String']>;
}>;


export type AddDynamicCompetitionSurveyFileUploadWithTokenMutation = (
  { __typename?: 'Mutation' }
  & { addDynamicCompetitionSurveyFileUploadWithToken: (
    { __typename?: 'DynamicCompetitionSurveySectionQuestionFileUpload' }
    & Pick<DynamicCompetitionSurveySectionQuestionFileUpload, 'id' | 'name' | 'comment' | 'fileUpload' | 'createdAt' | 'updatedAt'>
  ) }
);

export type RemoveDynamicCompetitionSurveyFileUploadWithTokenMutationVariables = Exact<{
  surveyToken: Scalars['String'];
  questionId: Scalars['String'];
  fileUploadId: Scalars['String'];
}>;


export type RemoveDynamicCompetitionSurveyFileUploadWithTokenMutation = (
  { __typename?: 'Mutation' }
  & { removeDynamicCompetitionSurveyFileUploadWithToken: (
    { __typename?: 'DynamicCompetitionSurveySectionQuestion' }
    & Pick<DynamicCompetitionSurveySectionQuestion, 'id' | 'name' | 'description' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType' | 'selectedOption' | 'selectedOptionOrder' | 'responseShortAnswer' | 'responseText' | 'responseNumber' | 'responseBoolean'>
    & { responses?: Maybe<Array<(
      { __typename?: 'DynamicCompetitionSurveySectionQuestionMultipleResponse' }
      & Pick<DynamicCompetitionSurveySectionQuestionMultipleResponse, 'id' | 'value' | 'createdAt' | 'updatedAt'>
    )>>, fileUploads?: Maybe<Array<(
      { __typename?: 'DynamicCompetitionSurveySectionQuestionFileUpload' }
      & Pick<DynamicCompetitionSurveySectionQuestionFileUpload, 'id' | 'name' | 'comment' | 'fileUpload' | 'createdAt' | 'updatedAt'>
    )>>, options: Array<(
      { __typename?: 'DynamicCompetitionSurveySectionQuestionOption' }
      & Pick<DynamicCompetitionSurveySectionQuestionOption, 'id' | 'value' | 'order'>
    )>, subQuestions: Array<(
      { __typename?: 'DynamicCompetitionSurveySectionSubQuestion' }
      & Pick<DynamicCompetitionSurveySectionSubQuestion, 'id' | 'name' | 'questionText' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType' | 'selectedOption' | 'selectedOptionOrder' | 'responseShortAnswer' | 'responseText' | 'responseNumber' | 'responseBoolean'>
      & { responses?: Maybe<Array<(
        { __typename?: 'DynamicCompetitionSurveySectionSubQuestionMultipleResponse' }
        & Pick<DynamicCompetitionSurveySectionSubQuestionMultipleResponse, 'id' | 'value' | 'createdAt' | 'updatedAt'>
      )>>, fileUploads?: Maybe<Array<(
        { __typename?: 'DynamicCompetitionSurveySectionSubQuestionFileUpload' }
        & Pick<DynamicCompetitionSurveySectionSubQuestionFileUpload, 'id' | 'name' | 'comment' | 'fileUpload' | 'createdAt' | 'updatedAt'>
      )>>, options: Array<(
        { __typename?: 'DynamicCompetitionSurveySectionSubQuestionOption' }
        & Pick<DynamicCompetitionSurveySectionSubQuestionOption, 'id' | 'value' | 'order'>
      )> }
    )> }
  ) }
);

export type UpdateDynamicCompetitionSurveySubQuestionMultipleChoiceResponseWithTokenMutationVariables = Exact<{
  surveyToken: Scalars['String'];
  questionId: Scalars['String'];
  subQuestionId: Scalars['String'];
  selectedOptionId: Scalars['String'];
}>;


export type UpdateDynamicCompetitionSurveySubQuestionMultipleChoiceResponseWithTokenMutation = (
  { __typename?: 'Mutation' }
  & { updateDynamicCompetitionSurveySubQuestionMultipleChoiceResponseWithToken: (
    { __typename?: 'DynamicCompetitionSurveySectionSubQuestion' }
    & Pick<DynamicCompetitionSurveySectionSubQuestion, 'id' | 'name' | 'questionText' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType' | 'selectedOption' | 'selectedOptionOrder' | 'responseShortAnswer' | 'responseText' | 'responseNumber' | 'responseBoolean'>
    & { responses?: Maybe<Array<(
      { __typename?: 'DynamicCompetitionSurveySectionSubQuestionMultipleResponse' }
      & Pick<DynamicCompetitionSurveySectionSubQuestionMultipleResponse, 'id' | 'value' | 'createdAt' | 'updatedAt'>
    )>>, fileUploads?: Maybe<Array<(
      { __typename?: 'DynamicCompetitionSurveySectionSubQuestionFileUpload' }
      & Pick<DynamicCompetitionSurveySectionSubQuestionFileUpload, 'id' | 'name' | 'comment' | 'fileUpload' | 'createdAt' | 'updatedAt'>
    )>>, options: Array<(
      { __typename?: 'DynamicCompetitionSurveySectionSubQuestionOption' }
      & Pick<DynamicCompetitionSurveySectionSubQuestionOption, 'id' | 'value' | 'order'>
    )> }
  ) }
);

export type UpdateDynamicCompetitionSurveySubQuestionShortAnswerResponseWithTokenMutationVariables = Exact<{
  surveyToken: Scalars['String'];
  questionId: Scalars['String'];
  subQuestionId: Scalars['String'];
  response: Scalars['String'];
}>;


export type UpdateDynamicCompetitionSurveySubQuestionShortAnswerResponseWithTokenMutation = (
  { __typename?: 'Mutation' }
  & { updateDynamicCompetitionSurveySubQuestionShortAnswerResponseWithToken: (
    { __typename?: 'DynamicCompetitionSurveySectionSubQuestion' }
    & Pick<DynamicCompetitionSurveySectionSubQuestion, 'id' | 'name' | 'questionText' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType' | 'selectedOption' | 'selectedOptionOrder' | 'responseShortAnswer' | 'responseText' | 'responseNumber' | 'responseBoolean'>
    & { responses?: Maybe<Array<(
      { __typename?: 'DynamicCompetitionSurveySectionSubQuestionMultipleResponse' }
      & Pick<DynamicCompetitionSurveySectionSubQuestionMultipleResponse, 'id' | 'value' | 'createdAt' | 'updatedAt'>
    )>>, fileUploads?: Maybe<Array<(
      { __typename?: 'DynamicCompetitionSurveySectionSubQuestionFileUpload' }
      & Pick<DynamicCompetitionSurveySectionSubQuestionFileUpload, 'id' | 'name' | 'comment' | 'fileUpload' | 'createdAt' | 'updatedAt'>
    )>>, options: Array<(
      { __typename?: 'DynamicCompetitionSurveySectionSubQuestionOption' }
      & Pick<DynamicCompetitionSurveySectionSubQuestionOption, 'id' | 'value' | 'order'>
    )> }
  ) }
);

export type UpdateDynamicCompetitionSurveySubQuestionTextResponseWithTokenMutationVariables = Exact<{
  surveyToken: Scalars['String'];
  questionId: Scalars['String'];
  subQuestionId: Scalars['String'];
  response: Scalars['String'];
}>;


export type UpdateDynamicCompetitionSurveySubQuestionTextResponseWithTokenMutation = (
  { __typename?: 'Mutation' }
  & { updateDynamicCompetitionSurveySubQuestionTextResponseWithToken: (
    { __typename?: 'DynamicCompetitionSurveySectionSubQuestion' }
    & Pick<DynamicCompetitionSurveySectionSubQuestion, 'id' | 'name' | 'questionText' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType' | 'selectedOption' | 'selectedOptionOrder' | 'responseShortAnswer' | 'responseText' | 'responseNumber' | 'responseBoolean'>
    & { responses?: Maybe<Array<(
      { __typename?: 'DynamicCompetitionSurveySectionSubQuestionMultipleResponse' }
      & Pick<DynamicCompetitionSurveySectionSubQuestionMultipleResponse, 'id' | 'value' | 'createdAt' | 'updatedAt'>
    )>>, fileUploads?: Maybe<Array<(
      { __typename?: 'DynamicCompetitionSurveySectionSubQuestionFileUpload' }
      & Pick<DynamicCompetitionSurveySectionSubQuestionFileUpload, 'id' | 'name' | 'comment' | 'fileUpload' | 'createdAt' | 'updatedAt'>
    )>>, options: Array<(
      { __typename?: 'DynamicCompetitionSurveySectionSubQuestionOption' }
      & Pick<DynamicCompetitionSurveySectionSubQuestionOption, 'id' | 'value' | 'order'>
    )> }
  ) }
);

export type UpdateDynamicCompetitionSurveySubQuestionNumberResponseWithTokenMutationVariables = Exact<{
  surveyToken: Scalars['String'];
  questionId: Scalars['String'];
  subQuestionId: Scalars['String'];
  response: Scalars['Float'];
}>;


export type UpdateDynamicCompetitionSurveySubQuestionNumberResponseWithTokenMutation = (
  { __typename?: 'Mutation' }
  & { updateDynamicCompetitionSurveySubQuestionNumberResponseWithToken: (
    { __typename?: 'DynamicCompetitionSurveySectionSubQuestion' }
    & Pick<DynamicCompetitionSurveySectionSubQuestion, 'id' | 'name' | 'questionText' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType' | 'selectedOption' | 'selectedOptionOrder' | 'responseShortAnswer' | 'responseText' | 'responseNumber' | 'responseBoolean'>
    & { responses?: Maybe<Array<(
      { __typename?: 'DynamicCompetitionSurveySectionSubQuestionMultipleResponse' }
      & Pick<DynamicCompetitionSurveySectionSubQuestionMultipleResponse, 'id' | 'value' | 'createdAt' | 'updatedAt'>
    )>>, fileUploads?: Maybe<Array<(
      { __typename?: 'DynamicCompetitionSurveySectionSubQuestionFileUpload' }
      & Pick<DynamicCompetitionSurveySectionSubQuestionFileUpload, 'id' | 'name' | 'comment' | 'fileUpload' | 'createdAt' | 'updatedAt'>
    )>>, options: Array<(
      { __typename?: 'DynamicCompetitionSurveySectionSubQuestionOption' }
      & Pick<DynamicCompetitionSurveySectionSubQuestionOption, 'id' | 'value' | 'order'>
    )> }
  ) }
);

export type UpdateDynamicCompetitionSurveySubQuestionBooleanResponseWithTokenMutationVariables = Exact<{
  surveyToken: Scalars['String'];
  questionId: Scalars['String'];
  subQuestionId: Scalars['String'];
  response: Scalars['Boolean'];
}>;


export type UpdateDynamicCompetitionSurveySubQuestionBooleanResponseWithTokenMutation = (
  { __typename?: 'Mutation' }
  & { updateDynamicCompetitionSurveySubQuestionBooleanResponseWithToken: (
    { __typename?: 'DynamicCompetitionSurveySectionSubQuestion' }
    & Pick<DynamicCompetitionSurveySectionSubQuestion, 'id' | 'name' | 'questionText' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType' | 'selectedOption' | 'selectedOptionOrder' | 'responseShortAnswer' | 'responseText' | 'responseNumber' | 'responseBoolean'>
    & { responses?: Maybe<Array<(
      { __typename?: 'DynamicCompetitionSurveySectionSubQuestionMultipleResponse' }
      & Pick<DynamicCompetitionSurveySectionSubQuestionMultipleResponse, 'id' | 'value' | 'createdAt' | 'updatedAt'>
    )>>, fileUploads?: Maybe<Array<(
      { __typename?: 'DynamicCompetitionSurveySectionSubQuestionFileUpload' }
      & Pick<DynamicCompetitionSurveySectionSubQuestionFileUpload, 'id' | 'name' | 'comment' | 'fileUpload' | 'createdAt' | 'updatedAt'>
    )>>, options: Array<(
      { __typename?: 'DynamicCompetitionSurveySectionSubQuestionOption' }
      & Pick<DynamicCompetitionSurveySectionSubQuestionOption, 'id' | 'value' | 'order'>
    )> }
  ) }
);

export type UpdateDynamicCompetitionSurveyMultiPartQuestionWithTokenMutationVariables = Exact<{
  surveyToken: Scalars['String'];
  questionId: Scalars['String'];
  multipleResponses?: Maybe<Array<UpdateDynamicCompetitionSurveyMultiPartQuestionMultipleChoiceResponseInput> | UpdateDynamicCompetitionSurveyMultiPartQuestionMultipleChoiceResponseInput>;
  shortAnswerResponses?: Maybe<Array<UpdateDynamicCompetitionSurveyMultiPartQuestionShortAnswerResponseInput> | UpdateDynamicCompetitionSurveyMultiPartQuestionShortAnswerResponseInput>;
  textResponses?: Maybe<Array<UpdateDynamicCompetitionSurveyMultiPartQuestionTextResponseInput> | UpdateDynamicCompetitionSurveyMultiPartQuestionTextResponseInput>;
  numberResponses?: Maybe<Array<UpdateDynamicCompetitionSurveyMultiPartQuestionNumberResponseInput> | UpdateDynamicCompetitionSurveyMultiPartQuestionNumberResponseInput>;
}>;


export type UpdateDynamicCompetitionSurveyMultiPartQuestionWithTokenMutation = (
  { __typename?: 'Mutation' }
  & { updateDynamicCompetitionSurveyMultiPartQuestionWithToken: (
    { __typename?: 'DynamicCompetitionSurveySectionQuestion' }
    & Pick<DynamicCompetitionSurveySectionQuestion, 'id' | 'name' | 'description' | 'additionalText1' | 'additionalText2' | 'additionalText3' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType' | 'selectedOption' | 'selectedOptionOrder' | 'responseShortAnswer' | 'responseText' | 'responseNumber' | 'responseBoolean'>
    & { assessmentObject: (
      { __typename?: 'AssessmentObject' }
      & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name'>
    ), coach?: Maybe<(
      { __typename?: 'Coach' }
      & Pick<Coach, 'id' | 'teamId' | 'teamName' | 'teamAbbreviation' | 'sport' | 'teamCity' | 'userId' | 'type' | 'role' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
    )>, team?: Maybe<(
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'timeZone' | 'classification' | 'gender' | 'website' | 'logo' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'createdAt' | 'updatedAt'>
    )>, official?: Maybe<(
      { __typename?: 'Official' }
      & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
      & { externalIds?: Maybe<Array<(
        { __typename?: 'OfficialExternalId' }
        & Pick<OfficialExternalId, 'id' | 'organizationId' | 'externalId'>
      )>> }
    )>, organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'name' | 'sport' | 'status' | 'abbreviation' | 'site' | 'logo' | 'createdAt' | 'updatedAt'>
    )>, responses?: Maybe<Array<(
      { __typename?: 'DynamicCompetitionSurveySectionQuestionMultipleResponse' }
      & Pick<DynamicCompetitionSurveySectionQuestionMultipleResponse, 'id' | 'value' | 'createdAt' | 'updatedAt'>
    )>>, fileUploads?: Maybe<Array<(
      { __typename?: 'DynamicCompetitionSurveySectionQuestionFileUpload' }
      & Pick<DynamicCompetitionSurveySectionQuestionFileUpload, 'id' | 'name' | 'comment' | 'fileUpload' | 'createdAt' | 'updatedAt'>
    )>>, options: Array<(
      { __typename?: 'DynamicCompetitionSurveySectionQuestionOption' }
      & Pick<DynamicCompetitionSurveySectionQuestionOption, 'id' | 'value' | 'order'>
    )>, subQuestions: Array<(
      { __typename?: 'DynamicCompetitionSurveySectionSubQuestion' }
      & Pick<DynamicCompetitionSurveySectionSubQuestion, 'id' | 'name' | 'questionText' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType' | 'selectedOption' | 'selectedOptionOrder' | 'responseShortAnswer' | 'responseText' | 'responseNumber' | 'responseBoolean'>
      & { responses?: Maybe<Array<(
        { __typename?: 'DynamicCompetitionSurveySectionSubQuestionMultipleResponse' }
        & Pick<DynamicCompetitionSurveySectionSubQuestionMultipleResponse, 'id' | 'value' | 'createdAt' | 'updatedAt'>
      )>>, fileUploads?: Maybe<Array<(
        { __typename?: 'DynamicCompetitionSurveySectionSubQuestionFileUpload' }
        & Pick<DynamicCompetitionSurveySectionSubQuestionFileUpload, 'id' | 'name' | 'comment' | 'fileUpload' | 'createdAt' | 'updatedAt'>
      )>>, options: Array<(
        { __typename?: 'DynamicCompetitionSurveySectionSubQuestionOption' }
        & Pick<DynamicCompetitionSurveySectionSubQuestionOption, 'id' | 'value' | 'order'>
      )> }
    )> }
  ) }
);

export type AddDynamicCompetitionSurveySubQuestionMultipleResponseWithTokenMutationVariables = Exact<{
  surveyToken: Scalars['String'];
  questionId: Scalars['String'];
  subQuestionId: Scalars['String'];
  response: Scalars['String'];
}>;


export type AddDynamicCompetitionSurveySubQuestionMultipleResponseWithTokenMutation = (
  { __typename?: 'Mutation' }
  & { addDynamicCompetitionSurveySubQuestionMultipleResponseWithToken: (
    { __typename?: 'DynamicCompetitionSurveySectionSubQuestionMultipleResponse' }
    & Pick<DynamicCompetitionSurveySectionSubQuestionMultipleResponse, 'id' | 'value' | 'createdAt' | 'updatedAt'>
  ) }
);

export type RemoveDynamicCompetitionSurveySubQuestionMultipleResponseWithTokenMutationVariables = Exact<{
  surveyToken: Scalars['String'];
  questionId: Scalars['String'];
  subQuestionId: Scalars['String'];
  responseId: Scalars['String'];
}>;


export type RemoveDynamicCompetitionSurveySubQuestionMultipleResponseWithTokenMutation = (
  { __typename?: 'Mutation' }
  & { removeDynamicCompetitionSurveySubQuestionMultipleResponseWithToken: (
    { __typename?: 'DynamicCompetitionSurveySectionSubQuestion' }
    & Pick<DynamicCompetitionSurveySectionSubQuestion, 'id' | 'name' | 'questionText' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType' | 'selectedOption' | 'selectedOptionOrder' | 'responseShortAnswer' | 'responseText' | 'responseNumber' | 'responseBoolean'>
    & { responses?: Maybe<Array<(
      { __typename?: 'DynamicCompetitionSurveySectionSubQuestionMultipleResponse' }
      & Pick<DynamicCompetitionSurveySectionSubQuestionMultipleResponse, 'id' | 'value' | 'createdAt' | 'updatedAt'>
    )>>, fileUploads?: Maybe<Array<(
      { __typename?: 'DynamicCompetitionSurveySectionSubQuestionFileUpload' }
      & Pick<DynamicCompetitionSurveySectionSubQuestionFileUpload, 'id' | 'name' | 'comment' | 'fileUpload' | 'createdAt' | 'updatedAt'>
    )>>, options: Array<(
      { __typename?: 'DynamicCompetitionSurveySectionSubQuestionOption' }
      & Pick<DynamicCompetitionSurveySectionSubQuestionOption, 'id' | 'value' | 'order'>
    )> }
  ) }
);

export type ListSupplementalReportsForDynamicCompetitionSurveyWithTokenQueryVariables = Exact<{
  surveyToken: Scalars['String'];
}>;


export type ListSupplementalReportsForDynamicCompetitionSurveyWithTokenQuery = (
  { __typename?: 'Query' }
  & { listSupplementalReportsForDynamicCompetitionSurveyWithToken: (
    { __typename?: 'SupplementalReports' }
    & { supplementalReports: Array<(
      { __typename?: 'SupplementalReport' }
      & Pick<SupplementalReport, 'id' | 'competitionSurveyId' | 'dynamicCompetitionSurveyId' | 'organizationId' | 'organizationName' | 'leagueId' | 'leagueName' | 'seasonId' | 'seasonName' | 'competitionSurveySectionQuestionId' | 'competitionSurveyFeedbackId' | 'dynamicCompetitionSurveySectionQuestionId' | 'dynamicCompetitionSurveyFeedbackId' | 'dynamicCompetitionSurveySubFeedbackId' | 'type' | 'status' | 'title' | 'report' | 'reportVersion' | 'time' | 'approvedDate' | 'reopenedDate' | 'submittedAt' | 'createdAt' | 'updatedAt'>
      & { submittedBy?: Maybe<(
        { __typename?: 'SupplementalReportSubmittedByInfo' }
        & Pick<SupplementalReportSubmittedByInfo, 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'teamId' | 'teamName' | 'teamLogo' | 'officialId' | 'assessmentObjectId' | 'assessmentObjectName'>
      )>, competitionInfo: (
        { __typename?: 'SupplementalReportCompetitionInfo' }
        & Pick<SupplementalReportCompetitionInfo, 'competitionId' | 'externalId' | 'sport' | 'homeTeamId' | 'homeTeamName' | 'homeTeamScore' | 'homeTeamTieBreakerScore' | 'awayTeamId' | 'awayTeamName' | 'awayTeamScore' | 'awayTeamTieBreakerScore' | 'startTime' | 'timeZone' | 'location' | 'outcome' | 'verificationStatus'>
      ), assessmentObject?: Maybe<(
        { __typename?: 'AssessmentObject' }
        & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name' | 'createdAt' | 'updatedAt'>
      )>, official?: Maybe<(
        { __typename?: 'Official' }
        & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
      )>, team?: Maybe<(
        { __typename?: 'Team' }
        & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'classification' | 'gender' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'timeZone' | 'website' | 'logo' | 'createdAt' | 'updatedAt'>
      )> }
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type AddSupplementalReportToDynamicCompetitionSurveyWithTokenMutationVariables = Exact<{
  surveyToken: Scalars['String'];
  title: Scalars['String'];
  supplementalReportType: SupplementalReportTypes;
  time?: Maybe<Scalars['String']>;
  teamId?: Maybe<Scalars['String']>;
  report?: Maybe<Scalars['String']>;
}>;


export type AddSupplementalReportToDynamicCompetitionSurveyWithTokenMutation = (
  { __typename?: 'Mutation' }
  & { addSupplementalReportToDynamicCompetitionSurveyWithToken: (
    { __typename?: 'SupplementalReport' }
    & Pick<SupplementalReport, 'id' | 'competitionSurveyId' | 'dynamicCompetitionSurveyId' | 'organizationId' | 'organizationName' | 'leagueId' | 'leagueName' | 'seasonId' | 'seasonName' | 'competitionSurveySectionQuestionId' | 'competitionSurveyFeedbackId' | 'dynamicCompetitionSurveySectionQuestionId' | 'dynamicCompetitionSurveyFeedbackId' | 'dynamicCompetitionSurveySubFeedbackId' | 'type' | 'status' | 'title' | 'report' | 'reportVersion' | 'time' | 'approvedDate' | 'reopenedDate' | 'submittedAt' | 'createdAt' | 'updatedAt'>
    & { submittedBy?: Maybe<(
      { __typename?: 'SupplementalReportSubmittedByInfo' }
      & Pick<SupplementalReportSubmittedByInfo, 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'teamId' | 'teamName' | 'teamLogo' | 'officialId' | 'assessmentObjectId' | 'assessmentObjectName'>
    )>, competitionInfo: (
      { __typename?: 'SupplementalReportCompetitionInfo' }
      & Pick<SupplementalReportCompetitionInfo, 'competitionId' | 'externalId' | 'sport' | 'homeTeamId' | 'homeTeamName' | 'homeTeamScore' | 'homeTeamTieBreakerScore' | 'awayTeamId' | 'awayTeamName' | 'awayTeamScore' | 'awayTeamTieBreakerScore' | 'startTime' | 'timeZone' | 'location' | 'outcome' | 'verificationStatus'>
    ), assessmentObject?: Maybe<(
      { __typename?: 'AssessmentObject' }
      & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name' | 'createdAt' | 'updatedAt'>
    )>, official?: Maybe<(
      { __typename?: 'Official' }
      & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
    )>, team?: Maybe<(
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'classification' | 'gender' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'timeZone' | 'website' | 'logo' | 'createdAt' | 'updatedAt'>
    )> }
  ) }
);

export type RemoveSupplementalReportFromDynamicCompetitionSurveyWithTokenMutationVariables = Exact<{
  surveyToken: Scalars['String'];
  supplementalReportId: Scalars['String'];
}>;


export type RemoveSupplementalReportFromDynamicCompetitionSurveyWithTokenMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeSupplementalReportFromDynamicCompetitionSurveyWithToken'>
);

export type EditSupplementalReportForDynamicCompetitionSurveyWithTokenMutationVariables = Exact<{
  surveyToken: Scalars['String'];
  supplementalReportId: Scalars['String'];
  title: Scalars['String'];
  supplementalReportType: SupplementalReportTypes;
  time?: Maybe<Scalars['String']>;
  teamId?: Maybe<Scalars['String']>;
  report?: Maybe<Scalars['String']>;
}>;


export type EditSupplementalReportForDynamicCompetitionSurveyWithTokenMutation = (
  { __typename?: 'Mutation' }
  & { editSupplementalReportForDynamicCompetitionSurveyWithToken: (
    { __typename?: 'SupplementalReport' }
    & Pick<SupplementalReport, 'id' | 'competitionSurveyId' | 'dynamicCompetitionSurveyId' | 'organizationId' | 'organizationName' | 'leagueId' | 'leagueName' | 'seasonId' | 'seasonName' | 'competitionSurveySectionQuestionId' | 'competitionSurveyFeedbackId' | 'dynamicCompetitionSurveySectionQuestionId' | 'dynamicCompetitionSurveyFeedbackId' | 'dynamicCompetitionSurveySubFeedbackId' | 'type' | 'status' | 'title' | 'report' | 'reportVersion' | 'time' | 'approvedDate' | 'reopenedDate' | 'submittedAt' | 'createdAt' | 'updatedAt'>
    & { submittedBy?: Maybe<(
      { __typename?: 'SupplementalReportSubmittedByInfo' }
      & Pick<SupplementalReportSubmittedByInfo, 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'teamId' | 'teamName' | 'teamLogo' | 'officialId' | 'assessmentObjectId' | 'assessmentObjectName'>
    )>, competitionInfo: (
      { __typename?: 'SupplementalReportCompetitionInfo' }
      & Pick<SupplementalReportCompetitionInfo, 'competitionId' | 'externalId' | 'sport' | 'homeTeamId' | 'homeTeamName' | 'homeTeamScore' | 'homeTeamTieBreakerScore' | 'awayTeamId' | 'awayTeamName' | 'awayTeamScore' | 'awayTeamTieBreakerScore' | 'startTime' | 'timeZone' | 'location' | 'outcome' | 'verificationStatus'>
    ), assessmentObject?: Maybe<(
      { __typename?: 'AssessmentObject' }
      & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name' | 'createdAt' | 'updatedAt'>
    )>, official?: Maybe<(
      { __typename?: 'Official' }
      & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
    )>, team?: Maybe<(
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'classification' | 'gender' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'timeZone' | 'website' | 'logo' | 'createdAt' | 'updatedAt'>
    )> }
  ) }
);

export type ListKeyIncidentsForDynamicCompetitionSurveyWithTokenQueryVariables = Exact<{
  surveyToken: Scalars['String'];
}>;


export type ListKeyIncidentsForDynamicCompetitionSurveyWithTokenQuery = (
  { __typename?: 'Query' }
  & { listKeyIncidentsForDynamicCompetitionSurveyWithToken: (
    { __typename?: 'KeyIncidents' }
    & { keyIncidents: Array<(
      { __typename?: 'KeyIncident' }
      & Pick<KeyIncident, 'id' | 'competitionSurveyId' | 'dynamicCompetitionSurveyId' | 'organizationId' | 'organizationName' | 'leagueId' | 'leagueName' | 'seasonId' | 'seasonName' | 'competitionSurveyFeedbackId' | 'dynamicCompetitionSurveyFeedbackId' | 'dynamicCompetitionSurveySubFeedbackId' | 'type' | 'status' | 'description' | 'time' | 'impactedOutcome' | 'resolvedDate' | 'reopenedDate' | 'createdAt' | 'updatedAt'>
      & { submittedBy: (
        { __typename?: 'KeyIncidentSubmittedByInfo' }
        & Pick<KeyIncidentSubmittedByInfo, 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'teamId' | 'teamName' | 'teamLogo' | 'officialId' | 'assessmentObjectId' | 'assessmentObjectName'>
      ), competitionInfo: (
        { __typename?: 'KeyIncidentCompetitionInfo' }
        & Pick<KeyIncidentCompetitionInfo, 'competitionId' | 'externalId' | 'sport' | 'homeTeamId' | 'homeTeamName' | 'homeTeamScore' | 'homeTeamTieBreakerScore' | 'awayTeamId' | 'awayTeamName' | 'awayTeamScore' | 'awayTeamTieBreakerScore' | 'startTime' | 'timeZone' | 'location' | 'outcome' | 'verificationStatus'>
      ), assessmentObject?: Maybe<(
        { __typename?: 'AssessmentObject' }
        & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name' | 'createdAt' | 'updatedAt'>
      )>, official?: Maybe<(
        { __typename?: 'Official' }
        & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
      )>, team?: Maybe<(
        { __typename?: 'Team' }
        & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'classification' | 'gender' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'timeZone' | 'website' | 'logo' | 'createdAt' | 'updatedAt'>
      )> }
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type SignDynamicCompetitionSurveyWithTokenMutationVariables = Exact<{
  surveyToken: Scalars['String'];
}>;


export type SignDynamicCompetitionSurveyWithTokenMutation = (
  { __typename?: 'Mutation' }
  & { signDynamicCompetitionSurveyWithToken: (
    { __typename?: 'DynamicCompetitionSurveySignature' }
    & Pick<DynamicCompetitionSurveySignature, 'competitionSurveyId' | 'signedDate' | 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic'>
  ) }
);

export type CompleteDynamicCompetitionSurveyWithTokenMutationVariables = Exact<{
  surveyToken: Scalars['String'];
}>;


export type CompleteDynamicCompetitionSurveyWithTokenMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'completeDynamicCompetitionSurveyWithToken'>
);

export type ConvertDynamicCompetitionSurveyMultipleResponseToKeyIncidentWithTokenMutationVariables = Exact<{
  surveyToken: Scalars['String'];
  questionId: Scalars['String'];
  feedbackResponseId: Scalars['String'];
  incidentType: KeyIncidentTypes;
  impactedOutcome: Scalars['Boolean'];
  time?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
}>;


export type ConvertDynamicCompetitionSurveyMultipleResponseToKeyIncidentWithTokenMutation = (
  { __typename?: 'Mutation' }
  & { convertDynamicCompetitionSurveyMultipleResponseToKeyIncidentWithToken: (
    { __typename?: 'KeyIncident' }
    & Pick<KeyIncident, 'id' | 'competitionSurveyId' | 'dynamicCompetitionSurveyId' | 'organizationId' | 'organizationName' | 'leagueId' | 'leagueName' | 'seasonId' | 'seasonName' | 'competitionSurveyFeedbackId' | 'dynamicCompetitionSurveyFeedbackId' | 'dynamicCompetitionSurveySubFeedbackId' | 'type' | 'status' | 'description' | 'time' | 'impactedOutcome' | 'resolvedDate' | 'reopenedDate' | 'createdAt' | 'updatedAt'>
    & { submittedBy: (
      { __typename?: 'KeyIncidentSubmittedByInfo' }
      & Pick<KeyIncidentSubmittedByInfo, 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'teamId' | 'teamName' | 'teamLogo' | 'officialId' | 'assessmentObjectId' | 'assessmentObjectName'>
    ), competitionInfo: (
      { __typename?: 'KeyIncidentCompetitionInfo' }
      & Pick<KeyIncidentCompetitionInfo, 'competitionId' | 'externalId' | 'sport' | 'homeTeamId' | 'homeTeamName' | 'homeTeamScore' | 'homeTeamTieBreakerScore' | 'awayTeamId' | 'awayTeamName' | 'awayTeamScore' | 'awayTeamTieBreakerScore' | 'startTime' | 'timeZone' | 'location' | 'outcome' | 'verificationStatus'>
    ), assessmentObject?: Maybe<(
      { __typename?: 'AssessmentObject' }
      & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name' | 'createdAt' | 'updatedAt'>
    )>, official?: Maybe<(
      { __typename?: 'Official' }
      & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
    )>, team?: Maybe<(
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'classification' | 'gender' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'timeZone' | 'website' | 'logo' | 'createdAt' | 'updatedAt'>
    )> }
  ) }
);

export type ConvertDynamicCompetitionSurveySubMultipleResponseToKeyIncidentWithTokenMutationVariables = Exact<{
  surveyToken: Scalars['String'];
  subQuestionId: Scalars['String'];
  feedbackResponseId: Scalars['String'];
  incidentType: KeyIncidentTypes;
  impactedOutcome: Scalars['Boolean'];
  time?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
}>;


export type ConvertDynamicCompetitionSurveySubMultipleResponseToKeyIncidentWithTokenMutation = (
  { __typename?: 'Mutation' }
  & { convertDynamicCompetitionSurveySubMultipleResponseToKeyIncidentWithToken: (
    { __typename?: 'KeyIncident' }
    & Pick<KeyIncident, 'id' | 'competitionSurveyId' | 'dynamicCompetitionSurveyId' | 'organizationId' | 'organizationName' | 'leagueId' | 'leagueName' | 'seasonId' | 'seasonName' | 'competitionSurveyFeedbackId' | 'dynamicCompetitionSurveyFeedbackId' | 'dynamicCompetitionSurveySubFeedbackId' | 'type' | 'status' | 'description' | 'time' | 'impactedOutcome' | 'resolvedDate' | 'reopenedDate' | 'createdAt' | 'updatedAt'>
    & { submittedBy: (
      { __typename?: 'KeyIncidentSubmittedByInfo' }
      & Pick<KeyIncidentSubmittedByInfo, 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'teamId' | 'teamName' | 'teamLogo' | 'officialId' | 'assessmentObjectId' | 'assessmentObjectName'>
    ), competitionInfo: (
      { __typename?: 'KeyIncidentCompetitionInfo' }
      & Pick<KeyIncidentCompetitionInfo, 'competitionId' | 'externalId' | 'sport' | 'homeTeamId' | 'homeTeamName' | 'homeTeamScore' | 'homeTeamTieBreakerScore' | 'awayTeamId' | 'awayTeamName' | 'awayTeamScore' | 'awayTeamTieBreakerScore' | 'startTime' | 'timeZone' | 'location' | 'outcome' | 'verificationStatus'>
    ), assessmentObject?: Maybe<(
      { __typename?: 'AssessmentObject' }
      & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name' | 'createdAt' | 'updatedAt'>
    )>, official?: Maybe<(
      { __typename?: 'Official' }
      & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
    )>, team?: Maybe<(
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'classification' | 'gender' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'timeZone' | 'website' | 'logo' | 'createdAt' | 'updatedAt'>
    )> }
  ) }
);

export type AddKeyIncidentToDynamicCompetitionSurveyWithTokenMutationVariables = Exact<{
  surveyToken: Scalars['String'];
  description: Scalars['String'];
  incidentType: KeyIncidentTypes;
  impactedOutcome: Scalars['Boolean'];
  time?: Maybe<Scalars['String']>;
  teamId?: Maybe<Scalars['String']>;
  sectionId?: Maybe<Scalars['String']>;
  questionId?: Maybe<Scalars['String']>;
}>;


export type AddKeyIncidentToDynamicCompetitionSurveyWithTokenMutation = (
  { __typename?: 'Mutation' }
  & { addKeyIncidentToDynamicCompetitionSurveyWithToken: (
    { __typename?: 'KeyIncident' }
    & Pick<KeyIncident, 'id' | 'competitionSurveyId' | 'dynamicCompetitionSurveyId' | 'organizationId' | 'organizationName' | 'leagueId' | 'leagueName' | 'seasonId' | 'seasonName' | 'competitionSurveyFeedbackId' | 'dynamicCompetitionSurveyFeedbackId' | 'dynamicCompetitionSurveySubFeedbackId' | 'type' | 'status' | 'description' | 'time' | 'impactedOutcome' | 'resolvedDate' | 'reopenedDate' | 'createdAt' | 'updatedAt'>
    & { submittedBy: (
      { __typename?: 'KeyIncidentSubmittedByInfo' }
      & Pick<KeyIncidentSubmittedByInfo, 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'teamId' | 'teamName' | 'teamLogo' | 'officialId' | 'assessmentObjectId' | 'assessmentObjectName'>
    ), competitionInfo: (
      { __typename?: 'KeyIncidentCompetitionInfo' }
      & Pick<KeyIncidentCompetitionInfo, 'competitionId' | 'externalId' | 'sport' | 'homeTeamId' | 'homeTeamName' | 'homeTeamScore' | 'homeTeamTieBreakerScore' | 'awayTeamId' | 'awayTeamName' | 'awayTeamScore' | 'awayTeamTieBreakerScore' | 'startTime' | 'timeZone' | 'location' | 'outcome' | 'verificationStatus'>
    ), assessmentObject?: Maybe<(
      { __typename?: 'AssessmentObject' }
      & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name' | 'createdAt' | 'updatedAt'>
    )>, official?: Maybe<(
      { __typename?: 'Official' }
      & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
    )>, team?: Maybe<(
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'classification' | 'gender' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'timeZone' | 'website' | 'logo' | 'createdAt' | 'updatedAt'>
    )> }
  ) }
);

export type EditKeyIncidentForDynamicCompetitionSurveyWithTokenMutationVariables = Exact<{
  surveyToken: Scalars['String'];
  keyIncidentId: Scalars['String'];
  description: Scalars['String'];
  incidentType: KeyIncidentTypes;
  impactedOutcome: Scalars['Boolean'];
  time?: Maybe<Scalars['String']>;
}>;


export type EditKeyIncidentForDynamicCompetitionSurveyWithTokenMutation = (
  { __typename?: 'Mutation' }
  & { editKeyIncidentForDynamicCompetitionSurveyWithToken: (
    { __typename?: 'KeyIncident' }
    & Pick<KeyIncident, 'id' | 'competitionSurveyId' | 'dynamicCompetitionSurveyId' | 'organizationId' | 'organizationName' | 'leagueId' | 'leagueName' | 'seasonId' | 'seasonName' | 'competitionSurveyFeedbackId' | 'dynamicCompetitionSurveyFeedbackId' | 'dynamicCompetitionSurveySubFeedbackId' | 'type' | 'status' | 'description' | 'time' | 'impactedOutcome' | 'resolvedDate' | 'reopenedDate' | 'createdAt' | 'updatedAt'>
    & { submittedBy: (
      { __typename?: 'KeyIncidentSubmittedByInfo' }
      & Pick<KeyIncidentSubmittedByInfo, 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'teamId' | 'teamName' | 'teamLogo' | 'officialId' | 'assessmentObjectId' | 'assessmentObjectName'>
    ), competitionInfo: (
      { __typename?: 'KeyIncidentCompetitionInfo' }
      & Pick<KeyIncidentCompetitionInfo, 'competitionId' | 'externalId' | 'sport' | 'homeTeamId' | 'homeTeamName' | 'homeTeamScore' | 'homeTeamTieBreakerScore' | 'awayTeamId' | 'awayTeamName' | 'awayTeamScore' | 'awayTeamTieBreakerScore' | 'startTime' | 'timeZone' | 'location' | 'outcome' | 'verificationStatus'>
    ), assessmentObject?: Maybe<(
      { __typename?: 'AssessmentObject' }
      & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name' | 'createdAt' | 'updatedAt'>
    )>, official?: Maybe<(
      { __typename?: 'Official' }
      & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
    )>, team?: Maybe<(
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'classification' | 'gender' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'timeZone' | 'website' | 'logo' | 'createdAt' | 'updatedAt'>
    )> }
  ) }
);

export type RemoveKeyIncidentFromDynamicCompetitionSurveyWithTokenMutationVariables = Exact<{
  surveyToken: Scalars['String'];
  keyIncidentId: Scalars['String'];
}>;


export type RemoveKeyIncidentFromDynamicCompetitionSurveyWithTokenMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeKeyIncidentFromDynamicCompetitionSurveyWithToken'>
);

export type CreateDynamicSurveyIntegrationForOrganizationMutationVariables = Exact<{
  orgId: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  status: DynamicSurveyIntegrationStatuses;
  queueName: Scalars['String'];
}>;


export type CreateDynamicSurveyIntegrationForOrganizationMutation = (
  { __typename?: 'Mutation' }
  & { createDynamicSurveyIntegrationForOrganization: (
    { __typename?: 'DynamicSurveyIntegration' }
    & Pick<DynamicSurveyIntegration, 'id' | 'orgId' | 'orgName' | 'orgAbbreviation' | 'name' | 'description' | 'status' | 'queueName' | 'createdAt' | 'updatedAt'>
    & { additionalOptions: Array<(
      { __typename?: 'DynamicSurveyIntegrationAdditionalOption' }
      & Pick<DynamicSurveyIntegrationAdditionalOption, 'id' | 'name' | 'value' | 'createdAt' | 'updatedAt'>
    )> }
  ) }
);

export type GetDynamicSurveyIntegrationByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetDynamicSurveyIntegrationByIdQuery = (
  { __typename?: 'Query' }
  & { getDynamicSurveyIntegrationById: (
    { __typename?: 'DynamicSurveyIntegration' }
    & Pick<DynamicSurveyIntegration, 'id' | 'orgId' | 'orgName' | 'orgAbbreviation' | 'name' | 'description' | 'status' | 'queueName' | 'createdAt' | 'updatedAt'>
    & { additionalOptions: Array<(
      { __typename?: 'DynamicSurveyIntegrationAdditionalOption' }
      & Pick<DynamicSurveyIntegrationAdditionalOption, 'id' | 'name' | 'value' | 'createdAt' | 'updatedAt'>
    )> }
  ) }
);

export type ListDynamicSurveyIntegrationsQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  orgId?: Maybe<Scalars['String']>;
  orgName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  queueName?: Maybe<Scalars['String']>;
  status?: Maybe<DynamicSurveyIntegrationStatuses>;
}>;


export type ListDynamicSurveyIntegrationsQuery = (
  { __typename?: 'Query' }
  & { listDynamicSurveyIntegrations: (
    { __typename?: 'DynamicSurveyIntegrations' }
    & { integrations: Array<(
      { __typename?: 'DynamicSurveyIntegration' }
      & Pick<DynamicSurveyIntegration, 'id' | 'orgId' | 'orgName' | 'orgAbbreviation' | 'name' | 'description' | 'status' | 'queueName' | 'createdAt' | 'updatedAt'>
      & { additionalOptions: Array<(
        { __typename?: 'DynamicSurveyIntegrationAdditionalOption' }
        & Pick<DynamicSurveyIntegrationAdditionalOption, 'id' | 'name' | 'value' | 'createdAt' | 'updatedAt'>
      )> }
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type UpdateDynamicSurveyIntegrationMutationVariables = Exact<{
  id: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  status: DynamicSurveyIntegrationStatuses;
  queueName: Scalars['String'];
}>;


export type UpdateDynamicSurveyIntegrationMutation = (
  { __typename?: 'Mutation' }
  & { updateDynamicSurveyIntegration: (
    { __typename?: 'DynamicSurveyIntegration' }
    & Pick<DynamicSurveyIntegration, 'id' | 'orgId' | 'orgName' | 'orgAbbreviation' | 'name' | 'description' | 'status' | 'queueName' | 'createdAt' | 'updatedAt'>
    & { additionalOptions: Array<(
      { __typename?: 'DynamicSurveyIntegrationAdditionalOption' }
      & Pick<DynamicSurveyIntegrationAdditionalOption, 'id' | 'name' | 'value' | 'createdAt' | 'updatedAt'>
    )> }
  ) }
);

export type DeleteDynamicSurveyIntegrationMutationVariables = Exact<{
  integrationId: Scalars['String'];
}>;


export type DeleteDynamicSurveyIntegrationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteDynamicSurveyIntegration'>
);

export type ActivateDynamicSurveyMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type ActivateDynamicSurveyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'activateDynamicSurvey'>
);

export type DeactivateDynamicSurveyMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeactivateDynamicSurveyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deactivateDynamicSurvey'>
);

export type ListAdditionalOptionsForDynamicSurveyIntegrationQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  integrationId: Scalars['String'];
}>;


export type ListAdditionalOptionsForDynamicSurveyIntegrationQuery = (
  { __typename?: 'Query' }
  & { listAdditionalOptionsForDynamicSurveyIntegration: (
    { __typename?: 'DynamicSurveyIntegrationAdditionalOptions' }
    & { additionalOptions: Array<(
      { __typename?: 'DynamicSurveyIntegrationAdditionalOption' }
      & Pick<DynamicSurveyIntegrationAdditionalOption, 'id' | 'name' | 'value' | 'createdAt' | 'updatedAt'>
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type AddAdditionalOptionToDynamicSurveyIntegrationMutationVariables = Exact<{
  integrationId: Scalars['String'];
  name: Scalars['String'];
  value: Scalars['String'];
}>;


export type AddAdditionalOptionToDynamicSurveyIntegrationMutation = (
  { __typename?: 'Mutation' }
  & { addAdditionalOptionToDynamicSurveyIntegration: (
    { __typename?: 'DynamicSurveyIntegrationAdditionalOption' }
    & Pick<DynamicSurveyIntegrationAdditionalOption, 'id' | 'name' | 'value' | 'createdAt' | 'updatedAt'>
  ) }
);

export type UpdateAdditionalOptionForDynamicSurveyIntegrationMutationVariables = Exact<{
  integrationId: Scalars['String'];
  additionalOptionId: Scalars['String'];
  name: Scalars['String'];
  value: Scalars['String'];
}>;


export type UpdateAdditionalOptionForDynamicSurveyIntegrationMutation = (
  { __typename?: 'Mutation' }
  & { updateAdditionalOptionForDynamicSurveyIntegration: (
    { __typename?: 'DynamicSurveyIntegrationAdditionalOption' }
    & Pick<DynamicSurveyIntegrationAdditionalOption, 'id' | 'name' | 'value' | 'createdAt' | 'updatedAt'>
  ) }
);

export type RemoveAdditionalOptionFromDynamicSurveyIntegrationMutationVariables = Exact<{
  integrationId: Scalars['String'];
  additionalOptionId: Scalars['String'];
}>;


export type RemoveAdditionalOptionFromDynamicSurveyIntegrationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeAdditionalOptionFromDynamicSurveyIntegration'>
);

export type CreateSeasonDynamicSurveyMutationVariables = Exact<{
  orgId: Scalars['String'];
  integrationId: Scalars['String'];
  seasonId: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  audience: SurveyAudiences;
  sendDelayMinutes: Scalars['Int'];
  reminderType: SurveyReminderTypes;
  dailyReminders?: Maybe<SurveyDailyRemindersInput>;
  levelReminders?: Maybe<SurveyLevelRemindersInput>;
  recipientAssessmentObjectId?: Maybe<Scalars['String']>;
}>;


export type CreateSeasonDynamicSurveyMutation = (
  { __typename?: 'Mutation' }
  & { createSeasonDynamicSurvey: (
    { __typename?: 'DynamicSurvey' }
    & Pick<DynamicSurvey, 'id' | 'sport' | 'status' | 'activatedAt' | 'createdAt' | 'updatedAt' | 'orgId' | 'seasonId' | 'hasSignatureSection' | 'signatureText' | 'hasCompetitionVerification' | 'hasIncidentReport' | 'hasSupplementalReport'>
    & { additionsConfig: (
      { __typename?: 'DynamicSurveyAdditionsConfig' }
      & Pick<DynamicSurveyAdditionsConfig, 'allowAdditionalSupplementalReports' | 'allowAdditionalSupplementalReportsCutoffDays' | 'allowAdditionalIncidentReports' | 'allowAdditionalIncidentReportsCutoffDays'>
    ), recipients: Array<(
      { __typename?: 'SurveyAssessmentObjectRecipient' }
      & Pick<SurveyAssessmentObjectRecipient, 'id' | 'dynamicSurveyId' | 'surveyId'>
      & { assessmentObject: (
        { __typename?: 'AssessmentObject' }
        & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name'>
      ) }
    )>, keyIncidentRecipients: Array<(
      { __typename?: 'SurveyKeyIncidentRecipient' }
      & Pick<SurveyKeyIncidentRecipient, 'id' | 'orgMembershipId' | 'orgId' | 'orgName' | 'orgAbbreviation' | 'orgLogo' | 'title' | 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic'>
    )>, supplementalReportRecipients: Array<(
      { __typename?: 'SurveySupplementalReportRecipient' }
      & Pick<SurveySupplementalReportRecipient, 'id' | 'orgMembershipId' | 'orgId' | 'orgName' | 'orgAbbreviation' | 'orgLogo' | 'title' | 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic'>
    )>, info: (
      { __typename?: 'DynamicSurveyInfo' }
      & Pick<DynamicSurveyInfo, 'name' | 'description' | 'audience' | 'sendDelayMinutes'>
    ), reminders: (
      { __typename?: 'SurveyRemindersConfig' }
      & Pick<SurveyRemindersConfig, 'type'>
      & { dailyReminders?: Maybe<(
        { __typename?: 'SurveyDailyRemindersConfig' }
        & Pick<SurveyDailyRemindersConfig, 'sendReminders' | 'sendReminderDays' | 'sendReminderCutoffDays'>
      )>, levelReminders?: Maybe<(
        { __typename?: 'SurveyLevelRemindersConfig' }
        & Pick<SurveyLevelRemindersConfig, 'sendRemindersLevel1' | 'sendReminderDaysLevel1' | 'sendRemindersLevel2' | 'sendReminderDaysLevel2' | 'sendRemindersLevel3' | 'sendReminderDaysLevel3'>
      )> }
    ), integration: (
      { __typename?: 'DynamicSurveyIntegration' }
      & Pick<DynamicSurveyIntegration, 'id' | 'orgId' | 'orgName' | 'orgAbbreviation' | 'name' | 'description' | 'status' | 'queueName' | 'createdAt' | 'updatedAt'>
    ), sections: Array<(
      { __typename?: 'DynamicSurveySection' }
      & Pick<DynamicSurveySection, 'id' | 'name' | 'description' | 'order' | 'isDynamic' | 'requireSupplementalReport' | 'supplementalReportType' | 'allowAdditions' | 'createdAt' | 'updatedAt'>
      & { questions: Array<(
        { __typename?: 'DynamicSurveySectionQuestion' }
        & Pick<DynamicSurveySectionQuestion, 'id' | 'name' | 'description' | 'order' | 'isRequired' | 'allowCorrections' | 'canAddToKeyIncidents' | 'questionType'>
        & { options: Array<(
          { __typename?: 'DynamicSurveySectionQuestionOption' }
          & Pick<DynamicSurveySectionQuestionOption, 'id' | 'value' | 'order'>
        )>, assessmentObject: (
          { __typename?: 'AssessmentObject' }
          & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name'>
        ), subQuestions: Array<(
          { __typename?: 'DynamicSurveySectionSubQuestion' }
          & Pick<DynamicSurveySectionSubQuestion, 'id' | 'name' | 'questionText' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType'>
          & { options: Array<(
            { __typename?: 'DynamicSurveySectionSubQuestionOption' }
            & Pick<DynamicSurveySectionSubQuestionOption, 'id' | 'value' | 'order'>
          )> }
        )> }
      )> }
    )> }
  ) }
);

export type GetDynamicSurveyByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetDynamicSurveyByIdQuery = (
  { __typename?: 'Query' }
  & { getDynamicSurveyById: (
    { __typename?: 'DynamicSurvey' }
    & Pick<DynamicSurvey, 'id' | 'sport' | 'status' | 'activatedAt' | 'createdAt' | 'updatedAt' | 'orgId' | 'seasonId' | 'hasSignatureSection' | 'signatureText' | 'hasCompetitionVerification' | 'hasIncidentReport' | 'hasSupplementalReport'>
    & { additionsConfig: (
      { __typename?: 'DynamicSurveyAdditionsConfig' }
      & Pick<DynamicSurveyAdditionsConfig, 'allowAdditionalSupplementalReports' | 'allowAdditionalSupplementalReportsCutoffDays' | 'allowAdditionalIncidentReports' | 'allowAdditionalIncidentReportsCutoffDays'>
    ), recipients: Array<(
      { __typename?: 'SurveyAssessmentObjectRecipient' }
      & Pick<SurveyAssessmentObjectRecipient, 'id' | 'dynamicSurveyId' | 'surveyId'>
      & { assessmentObject: (
        { __typename?: 'AssessmentObject' }
        & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name'>
      ) }
    )>, keyIncidentRecipients: Array<(
      { __typename?: 'SurveyKeyIncidentRecipient' }
      & Pick<SurveyKeyIncidentRecipient, 'id' | 'orgMembershipId' | 'orgId' | 'orgName' | 'orgAbbreviation' | 'orgLogo' | 'title' | 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic'>
    )>, supplementalReportRecipients: Array<(
      { __typename?: 'SurveySupplementalReportRecipient' }
      & Pick<SurveySupplementalReportRecipient, 'id' | 'orgMembershipId' | 'orgId' | 'orgName' | 'orgAbbreviation' | 'orgLogo' | 'title' | 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic'>
    )>, info: (
      { __typename?: 'DynamicSurveyInfo' }
      & Pick<DynamicSurveyInfo, 'name' | 'description' | 'audience' | 'sendDelayMinutes'>
    ), reminders: (
      { __typename?: 'SurveyRemindersConfig' }
      & Pick<SurveyRemindersConfig, 'type'>
      & { dailyReminders?: Maybe<(
        { __typename?: 'SurveyDailyRemindersConfig' }
        & Pick<SurveyDailyRemindersConfig, 'sendReminders' | 'sendReminderDays' | 'sendReminderCutoffDays'>
      )>, levelReminders?: Maybe<(
        { __typename?: 'SurveyLevelRemindersConfig' }
        & Pick<SurveyLevelRemindersConfig, 'sendRemindersLevel1' | 'sendReminderDaysLevel1' | 'sendRemindersLevel2' | 'sendReminderDaysLevel2' | 'sendRemindersLevel3' | 'sendReminderDaysLevel3'>
      )> }
    ), integration: (
      { __typename?: 'DynamicSurveyIntegration' }
      & Pick<DynamicSurveyIntegration, 'id' | 'orgId' | 'orgName' | 'orgAbbreviation' | 'name' | 'description' | 'status' | 'queueName' | 'createdAt' | 'updatedAt'>
    ), sections: Array<(
      { __typename?: 'DynamicSurveySection' }
      & Pick<DynamicSurveySection, 'id' | 'name' | 'description' | 'order' | 'isDynamic' | 'requireSupplementalReport' | 'supplementalReportType' | 'allowAdditions' | 'createdAt' | 'updatedAt'>
      & { questions: Array<(
        { __typename?: 'DynamicSurveySectionQuestion' }
        & Pick<DynamicSurveySectionQuestion, 'id' | 'name' | 'description' | 'order' | 'isRequired' | 'allowCorrections' | 'canAddToKeyIncidents' | 'questionType'>
        & { options: Array<(
          { __typename?: 'DynamicSurveySectionQuestionOption' }
          & Pick<DynamicSurveySectionQuestionOption, 'id' | 'value' | 'order'>
        )>, assessmentObject: (
          { __typename?: 'AssessmentObject' }
          & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name'>
        ), subQuestions: Array<(
          { __typename?: 'DynamicSurveySectionSubQuestion' }
          & Pick<DynamicSurveySectionSubQuestion, 'id' | 'name' | 'questionText' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType'>
          & { options: Array<(
            { __typename?: 'DynamicSurveySectionSubQuestionOption' }
            & Pick<DynamicSurveySectionSubQuestionOption, 'id' | 'value' | 'order'>
          )> }
        )> }
      )> }
    )> }
  ) }
);

export type ListDynamicCompetitionSurveySummariesForOrganizationQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  orgId: Scalars['String'];
  leagueId: Scalars['String'];
  seasonId: Scalars['String'];
  surveyId: Scalars['String'];
}>;


export type ListDynamicCompetitionSurveySummariesForOrganizationQuery = (
  { __typename?: 'Query' }
  & { listDynamicCompetitionSurveySummariesForOrganization: (
    { __typename?: 'DynamicCompetitionSurveySummaries' }
    & { competitionSurveys: Array<(
      { __typename?: 'DynamicCompetitionSurveySummary' }
      & Pick<DynamicCompetitionSurveySummary, 'id' | 'surveyName' | 'completedDate' | 'createdAt' | 'teamId' | 'teamName' | 'teamLogo' | 'officialId' | 'officialType' | 'competitionId' | 'startTime' | 'externalId' | 'homeTeamScore' | 'homeTeamTieBreakerScore' | 'awayTeamScore' | 'awayTeamTieBreakerScore' | 'homeTeamId' | 'homeTeamName' | 'homeTeamAbbreviation' | 'homeTeamLogo' | 'awayTeamId' | 'awayTeamName' | 'awayTeamAbbreviation' | 'awayTeamLogo' | 'tookSurvey' | 'tookSurveyUserId' | 'keyIncidentsCount' | 'supplementalReportsCount'>
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type DeleteDynamicSurveyMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteDynamicSurveyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteDynamicSurvey'>
);

export type ListDynamicSurveysForOrganizationQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  orgId: Scalars['String'];
  integrationId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<SurveyStatuses>;
  audience?: Maybe<SurveyAudiences>;
}>;


export type ListDynamicSurveysForOrganizationQuery = (
  { __typename?: 'Query' }
  & { listDynamicSurveysForOrganization: (
    { __typename?: 'DynamicSurveys' }
    & { surveys: Array<(
      { __typename?: 'DynamicSurvey' }
      & Pick<DynamicSurvey, 'id' | 'sport' | 'status' | 'activatedAt' | 'createdAt' | 'updatedAt' | 'orgId' | 'seasonId' | 'hasSignatureSection' | 'signatureText' | 'hasCompetitionVerification' | 'hasIncidentReport' | 'hasSupplementalReport'>
      & { additionsConfig: (
        { __typename?: 'DynamicSurveyAdditionsConfig' }
        & Pick<DynamicSurveyAdditionsConfig, 'allowAdditionalSupplementalReports' | 'allowAdditionalSupplementalReportsCutoffDays' | 'allowAdditionalIncidentReports' | 'allowAdditionalIncidentReportsCutoffDays'>
      ), recipients: Array<(
        { __typename?: 'SurveyAssessmentObjectRecipient' }
        & Pick<SurveyAssessmentObjectRecipient, 'id' | 'dynamicSurveyId' | 'surveyId'>
        & { assessmentObject: (
          { __typename?: 'AssessmentObject' }
          & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name'>
        ) }
      )>, keyIncidentRecipients: Array<(
        { __typename?: 'SurveyKeyIncidentRecipient' }
        & Pick<SurveyKeyIncidentRecipient, 'id' | 'orgMembershipId' | 'orgId' | 'orgName' | 'orgAbbreviation' | 'orgLogo' | 'title' | 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic'>
      )>, supplementalReportRecipients: Array<(
        { __typename?: 'SurveySupplementalReportRecipient' }
        & Pick<SurveySupplementalReportRecipient, 'id' | 'orgMembershipId' | 'orgId' | 'orgName' | 'orgAbbreviation' | 'orgLogo' | 'title' | 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic'>
      )>, info: (
        { __typename?: 'DynamicSurveyInfo' }
        & Pick<DynamicSurveyInfo, 'name' | 'description' | 'audience' | 'sendDelayMinutes'>
      ), reminders: (
        { __typename?: 'SurveyRemindersConfig' }
        & Pick<SurveyRemindersConfig, 'type'>
        & { dailyReminders?: Maybe<(
          { __typename?: 'SurveyDailyRemindersConfig' }
          & Pick<SurveyDailyRemindersConfig, 'sendReminders' | 'sendReminderDays' | 'sendReminderCutoffDays'>
        )>, levelReminders?: Maybe<(
          { __typename?: 'SurveyLevelRemindersConfig' }
          & Pick<SurveyLevelRemindersConfig, 'sendRemindersLevel1' | 'sendReminderDaysLevel1' | 'sendRemindersLevel2' | 'sendReminderDaysLevel2' | 'sendRemindersLevel3' | 'sendReminderDaysLevel3'>
        )> }
      ), integration: (
        { __typename?: 'DynamicSurveyIntegration' }
        & Pick<DynamicSurveyIntegration, 'id' | 'orgId' | 'orgName' | 'orgAbbreviation' | 'name' | 'description' | 'status' | 'queueName' | 'createdAt' | 'updatedAt'>
      ), sections: Array<(
        { __typename?: 'DynamicSurveySection' }
        & Pick<DynamicSurveySection, 'id' | 'name' | 'description' | 'order' | 'isDynamic' | 'requireSupplementalReport' | 'supplementalReportType' | 'allowAdditions' | 'createdAt' | 'updatedAt'>
        & { questions: Array<(
          { __typename?: 'DynamicSurveySectionQuestion' }
          & Pick<DynamicSurveySectionQuestion, 'id' | 'name' | 'description' | 'order' | 'isRequired' | 'allowCorrections' | 'canAddToKeyIncidents' | 'questionType'>
          & { options: Array<(
            { __typename?: 'DynamicSurveySectionQuestionOption' }
            & Pick<DynamicSurveySectionQuestionOption, 'id' | 'value' | 'order'>
          )>, assessmentObject: (
            { __typename?: 'AssessmentObject' }
            & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name'>
          ), subQuestions: Array<(
            { __typename?: 'DynamicSurveySectionSubQuestion' }
            & Pick<DynamicSurveySectionSubQuestion, 'id' | 'name' | 'questionText' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType'>
            & { options: Array<(
              { __typename?: 'DynamicSurveySectionSubQuestionOption' }
              & Pick<DynamicSurveySectionSubQuestionOption, 'id' | 'value' | 'order'>
            )> }
          )> }
        )> }
      )> }
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type UpdateDynamicSurveyInfoMutationVariables = Exact<{
  surveyId: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  audience: SurveyAudiences;
  sendDelayMinutes: Scalars['Int'];
}>;


export type UpdateDynamicSurveyInfoMutation = (
  { __typename?: 'Mutation' }
  & { updateDynamicSurveyInfo: (
    { __typename?: 'DynamicSurveyInfo' }
    & Pick<DynamicSurveyInfo, 'name' | 'description' | 'audience' | 'sendDelayMinutes'>
  ) }
);

export type UpdateDynamicSurveyRemindersMutationVariables = Exact<{
  surveyId: Scalars['String'];
  type: SurveyReminderTypes;
  dailyReminders?: Maybe<SurveyDailyRemindersInput>;
  levelReminders?: Maybe<SurveyLevelRemindersInput>;
}>;


export type UpdateDynamicSurveyRemindersMutation = (
  { __typename?: 'Mutation' }
  & { updateDynamicSurveyReminders: (
    { __typename?: 'SurveyRemindersConfig' }
    & Pick<SurveyRemindersConfig, 'type'>
    & { dailyReminders?: Maybe<(
      { __typename?: 'SurveyDailyRemindersConfig' }
      & Pick<SurveyDailyRemindersConfig, 'sendReminders' | 'sendReminderDays' | 'sendReminderCutoffDays'>
    )>, levelReminders?: Maybe<(
      { __typename?: 'SurveyLevelRemindersConfig' }
      & Pick<SurveyLevelRemindersConfig, 'sendRemindersLevel1' | 'sendReminderDaysLevel1' | 'sendRemindersLevel2' | 'sendReminderDaysLevel2' | 'sendRemindersLevel3' | 'sendReminderDaysLevel3'>
    )> }
  ) }
);

export type ListReminderLevelRecipientsForDynamicSurveyQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  surveyId: Scalars['String'];
}>;


export type ListReminderLevelRecipientsForDynamicSurveyQuery = (
  { __typename?: 'Query' }
  & { listReminderLevelRecipientsForDynamicSurvey: (
    { __typename?: 'SurveyReminderLevelRecipients' }
    & { recipients: Array<(
      { __typename?: 'SurveyReminderLevelRecipient' }
      & Pick<SurveyReminderLevelRecipient, 'id' | 'orgMembershipId' | 'orgId' | 'orgName' | 'orgAbbreviation' | 'orgLogo' | 'title' | 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'level1Recipient' | 'level2Recipient' | 'level3Recipient'>
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type AddDynamicSurveyReminderLevelReportRecipientMutationVariables = Exact<{
  surveyId: Scalars['String'];
  orgMembershipId: Scalars['String'];
  level1Recipient: Scalars['Boolean'];
  level2Recipient: Scalars['Boolean'];
  level3Recipient: Scalars['Boolean'];
}>;


export type AddDynamicSurveyReminderLevelReportRecipientMutation = (
  { __typename?: 'Mutation' }
  & { addDynamicSurveyReminderLevelReportRecipient: (
    { __typename?: 'SurveyReminderLevelRecipient' }
    & Pick<SurveyReminderLevelRecipient, 'id' | 'orgMembershipId' | 'orgId' | 'orgName' | 'orgAbbreviation' | 'orgLogo' | 'title' | 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'level1Recipient' | 'level2Recipient' | 'level3Recipient'>
  ) }
);

export type UpdateDynamicSurveyReminderLevelRecipientMutationVariables = Exact<{
  recipientId: Scalars['String'];
  level1Recipient: Scalars['Boolean'];
  level2Recipient: Scalars['Boolean'];
  level3Recipient: Scalars['Boolean'];
}>;


export type UpdateDynamicSurveyReminderLevelRecipientMutation = (
  { __typename?: 'Mutation' }
  & { updateDynamicSurveyReminderLevelRecipient: (
    { __typename?: 'SurveyReminderLevelRecipient' }
    & Pick<SurveyReminderLevelRecipient, 'id' | 'orgMembershipId' | 'orgId' | 'orgName' | 'orgAbbreviation' | 'orgLogo' | 'title' | 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'level1Recipient' | 'level2Recipient' | 'level3Recipient'>
  ) }
);

export type DeleteDynamicSurveyReminderLevelRecipientMutationVariables = Exact<{
  recipientId: Scalars['String'];
}>;


export type DeleteDynamicSurveyReminderLevelRecipientMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteDynamicSurveyReminderLevelRecipient'>
);

export type AddDynamicSurveySignatureSectionMutationVariables = Exact<{
  surveyId: Scalars['String'];
  signatureText: Scalars['String'];
}>;


export type AddDynamicSurveySignatureSectionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addDynamicSurveySignatureSection'>
);

export type UpdateDynamicSurveySignatureSectionTextMutationVariables = Exact<{
  surveyId: Scalars['String'];
  signatureText: Scalars['String'];
}>;


export type UpdateDynamicSurveySignatureSectionTextMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateDynamicSurveySignatureSectionText'>
);

export type DeleteDynamicSurveySignatureSectionMutationVariables = Exact<{
  surveyId: Scalars['String'];
}>;


export type DeleteDynamicSurveySignatureSectionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteDynamicSurveySignatureSection'>
);

export type AddDynamicSurveyAssessmentObjectRecipientMutationVariables = Exact<{
  surveyId: Scalars['String'];
  assessmentObjectId: Scalars['String'];
}>;


export type AddDynamicSurveyAssessmentObjectRecipientMutation = (
  { __typename?: 'Mutation' }
  & { addDynamicSurveyAssessmentObjectRecipient: (
    { __typename?: 'SurveyAssessmentObjectRecipient' }
    & Pick<SurveyAssessmentObjectRecipient, 'id' | 'dynamicSurveyId' | 'surveyId'>
    & { assessmentObject: (
      { __typename?: 'AssessmentObject' }
      & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name'>
    ) }
  ) }
);

export type DeleteDynamicSurveyAssessmentObjectRecipientMutationVariables = Exact<{
  recipientId: Scalars['String'];
}>;


export type DeleteDynamicSurveyAssessmentObjectRecipientMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteDynamicSurveyAssessmentObjectRecipient'>
);

export type ListAvailableAssessmentObjectsForDynamicSurveysRecipientsQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  surveyId: Scalars['String'];
}>;


export type ListAvailableAssessmentObjectsForDynamicSurveysRecipientsQuery = (
  { __typename?: 'Query' }
  & { listAvailableAssessmentObjectsForDynamicSurveysRecipients: (
    { __typename?: 'AssessmentObjects' }
    & { assessmentObjects: Array<(
      { __typename?: 'AssessmentObject' }
      & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name'>
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type SetDynamicSurveyCompetitionVerificationMutationVariables = Exact<{
  surveyId: Scalars['String'];
  enabled: Scalars['Boolean'];
}>;


export type SetDynamicSurveyCompetitionVerificationMutation = (
  { __typename?: 'Mutation' }
  & { setDynamicSurveyCompetitionVerification: (
    { __typename?: 'DynamicSurveyCompetitionVerification' }
    & Pick<DynamicSurveyCompetitionVerification, 'enabled'>
  ) }
);

export type AddDynamicSurveyIncidentReportMutationVariables = Exact<{
  surveyId: Scalars['String'];
  orgMembershipId: Scalars['String'];
}>;


export type AddDynamicSurveyIncidentReportMutation = (
  { __typename?: 'Mutation' }
  & { addDynamicSurveyIncidentReport: (
    { __typename?: 'SurveyKeyIncidentRecipient' }
    & Pick<SurveyKeyIncidentRecipient, 'id' | 'orgMembershipId' | 'orgId' | 'orgName' | 'orgAbbreviation' | 'orgLogo' | 'title' | 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic'>
  ) }
);

export type DeleteDynamicSurveyIncidentReportMutationVariables = Exact<{
  surveyId: Scalars['String'];
}>;


export type DeleteDynamicSurveyIncidentReportMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteDynamicSurveyIncidentReport'>
);

export type AddDynamicSurveyIncidentReportRecipientMutationVariables = Exact<{
  surveyId: Scalars['String'];
  orgMembershipId: Scalars['String'];
}>;


export type AddDynamicSurveyIncidentReportRecipientMutation = (
  { __typename?: 'Mutation' }
  & { addDynamicSurveyIncidentReportRecipient: (
    { __typename?: 'SurveyKeyIncidentRecipient' }
    & Pick<SurveyKeyIncidentRecipient, 'id' | 'orgMembershipId' | 'orgId' | 'orgName' | 'orgAbbreviation' | 'orgLogo' | 'title' | 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic'>
  ) }
);

export type DeleteDynamicSurveyIncidentReportRecipientMutationVariables = Exact<{
  recipientId: Scalars['String'];
}>;


export type DeleteDynamicSurveyIncidentReportRecipientMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteDynamicSurveyIncidentReportRecipient'>
);

export type SetDynamicSurveyAllowAdditionalIncidentReportsMutationVariables = Exact<{
  surveyId: Scalars['String'];
  allowAdditions: Scalars['Boolean'];
}>;


export type SetDynamicSurveyAllowAdditionalIncidentReportsMutation = (
  { __typename?: 'Mutation' }
  & { setDynamicSurveyAllowAdditionalIncidentReports: (
    { __typename?: 'DynamicSurveyAdditionsConfig' }
    & Pick<DynamicSurveyAdditionsConfig, 'allowAdditionalSupplementalReports' | 'allowAdditionalSupplementalReportsCutoffDays' | 'allowAdditionalIncidentReports' | 'allowAdditionalIncidentReportsCutoffDays'>
  ) }
);

export type SetDynamicSurveyAllowAdditionalIncidentReportsCutoffDaysMutationVariables = Exact<{
  surveyId: Scalars['String'];
  allowAdditionsCutoffDays?: Maybe<Scalars['Int']>;
}>;


export type SetDynamicSurveyAllowAdditionalIncidentReportsCutoffDaysMutation = (
  { __typename?: 'Mutation' }
  & { setDynamicSurveyAllowAdditionalIncidentReportsCutoffDays: (
    { __typename?: 'DynamicSurveyAdditionsConfig' }
    & Pick<DynamicSurveyAdditionsConfig, 'allowAdditionalSupplementalReports' | 'allowAdditionalSupplementalReportsCutoffDays' | 'allowAdditionalIncidentReports' | 'allowAdditionalIncidentReportsCutoffDays'>
  ) }
);

export type AddDynamicSurveySupplementalReportMutationVariables = Exact<{
  surveyId: Scalars['String'];
  orgMembershipId: Scalars['String'];
}>;


export type AddDynamicSurveySupplementalReportMutation = (
  { __typename?: 'Mutation' }
  & { addDynamicSurveySupplementalReport: (
    { __typename?: 'SurveySupplementalReportRecipient' }
    & Pick<SurveySupplementalReportRecipient, 'id' | 'orgMembershipId' | 'orgId' | 'orgName' | 'orgAbbreviation' | 'orgLogo' | 'title' | 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic'>
  ) }
);

export type DeleteDynamicSurveySupplementalReportMutationVariables = Exact<{
  surveyId: Scalars['String'];
}>;


export type DeleteDynamicSurveySupplementalReportMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteDynamicSurveySupplementalReport'>
);

export type AddDynamicSurveySupplementalReportRecipientMutationVariables = Exact<{
  surveyId: Scalars['String'];
  orgMembershipId: Scalars['String'];
}>;


export type AddDynamicSurveySupplementalReportRecipientMutation = (
  { __typename?: 'Mutation' }
  & { addDynamicSurveySupplementalReportRecipient: (
    { __typename?: 'SurveySupplementalReportRecipient' }
    & Pick<SurveySupplementalReportRecipient, 'id' | 'orgMembershipId' | 'orgId' | 'orgName' | 'orgAbbreviation' | 'orgLogo' | 'title' | 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic'>
  ) }
);

export type DeleteDynamicSurveySupplementalReportRecipientMutationVariables = Exact<{
  recipientId: Scalars['String'];
}>;


export type DeleteDynamicSurveySupplementalReportRecipientMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteDynamicSurveySupplementalReportRecipient'>
);

export type SetDynamicSurveyAllowAdditionalSupplementalReportsMutationVariables = Exact<{
  surveyId: Scalars['String'];
  allowAdditions: Scalars['Boolean'];
}>;


export type SetDynamicSurveyAllowAdditionalSupplementalReportsMutation = (
  { __typename?: 'Mutation' }
  & { setDynamicSurveyAllowAdditionalSupplementalReports: (
    { __typename?: 'DynamicSurveyAdditionsConfig' }
    & Pick<DynamicSurveyAdditionsConfig, 'allowAdditionalSupplementalReports' | 'allowAdditionalSupplementalReportsCutoffDays' | 'allowAdditionalIncidentReports' | 'allowAdditionalIncidentReportsCutoffDays'>
  ) }
);

export type SetDynamicSurveyAllowAdditionalSupplementalReportsCutoffDaysMutationVariables = Exact<{
  surveyId: Scalars['String'];
  allowAdditionsCutoffDays?: Maybe<Scalars['Int']>;
}>;


export type SetDynamicSurveyAllowAdditionalSupplementalReportsCutoffDaysMutation = (
  { __typename?: 'Mutation' }
  & { setDynamicSurveyAllowAdditionalSupplementalReportsCutoffDays: (
    { __typename?: 'DynamicSurveyAdditionsConfig' }
    & Pick<DynamicSurveyAdditionsConfig, 'allowAdditionalSupplementalReports' | 'allowAdditionalSupplementalReportsCutoffDays' | 'allowAdditionalIncidentReports' | 'allowAdditionalIncidentReportsCutoffDays'>
  ) }
);

export type AddDynamicSurveySectionMutationVariables = Exact<{
  surveyId: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  isDynamic: Scalars['Boolean'];
  allowAdditions: Scalars['Boolean'];
  requireSupplementalReport: Scalars['Boolean'];
  supplementalReportType?: Maybe<SupplementalReportTypes>;
}>;


export type AddDynamicSurveySectionMutation = (
  { __typename?: 'Mutation' }
  & { addDynamicSurveySection: (
    { __typename?: 'DynamicSurveySection' }
    & Pick<DynamicSurveySection, 'id' | 'name' | 'description' | 'order' | 'isDynamic' | 'requireSupplementalReport' | 'supplementalReportType' | 'allowAdditions' | 'createdAt' | 'updatedAt'>
    & { questions: Array<(
      { __typename?: 'DynamicSurveySectionQuestion' }
      & Pick<DynamicSurveySectionQuestion, 'id' | 'name' | 'description' | 'order' | 'isRequired' | 'allowCorrections' | 'canAddToKeyIncidents' | 'questionType'>
      & { options: Array<(
        { __typename?: 'DynamicSurveySectionQuestionOption' }
        & Pick<DynamicSurveySectionQuestionOption, 'id' | 'value' | 'order'>
      )>, assessmentObject: (
        { __typename?: 'AssessmentObject' }
        & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name'>
      ), subQuestions: Array<(
        { __typename?: 'DynamicSurveySectionSubQuestion' }
        & Pick<DynamicSurveySectionSubQuestion, 'id' | 'name' | 'questionText' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType'>
        & { options: Array<(
          { __typename?: 'DynamicSurveySectionSubQuestionOption' }
          & Pick<DynamicSurveySectionSubQuestionOption, 'id' | 'value' | 'order'>
        )> }
      )> }
    )> }
  ) }
);

export type UpdateDynamicSurveySectionMutationVariables = Exact<{
  sectionId: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  allowAdditions: Scalars['Boolean'];
  order: Scalars['Int'];
}>;


export type UpdateDynamicSurveySectionMutation = (
  { __typename?: 'Mutation' }
  & { updateDynamicSurveySection: (
    { __typename?: 'DynamicSurveySection' }
    & Pick<DynamicSurveySection, 'id' | 'name' | 'description' | 'order' | 'isDynamic' | 'requireSupplementalReport' | 'allowAdditions' | 'createdAt' | 'updatedAt'>
    & { questions: Array<(
      { __typename?: 'DynamicSurveySectionQuestion' }
      & Pick<DynamicSurveySectionQuestion, 'id' | 'name' | 'description' | 'order' | 'isRequired' | 'allowCorrections' | 'canAddToKeyIncidents' | 'questionType'>
      & { options: Array<(
        { __typename?: 'DynamicSurveySectionQuestionOption' }
        & Pick<DynamicSurveySectionQuestionOption, 'id' | 'value' | 'order'>
      )>, assessmentObject: (
        { __typename?: 'AssessmentObject' }
        & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name'>
      ), subQuestions: Array<(
        { __typename?: 'DynamicSurveySectionSubQuestion' }
        & Pick<DynamicSurveySectionSubQuestion, 'id' | 'name' | 'questionText' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType'>
        & { options: Array<(
          { __typename?: 'DynamicSurveySectionSubQuestionOption' }
          & Pick<DynamicSurveySectionSubQuestionOption, 'id' | 'value' | 'order'>
        )> }
      )> }
    )> }
  ) }
);

export type UpdateDynamicSurveySectionSupplementalReportMutationVariables = Exact<{
  sectionId: Scalars['String'];
  requireSupplementalReport: Scalars['Boolean'];
  supplementalReportType?: Maybe<SupplementalReportTypes>;
}>;


export type UpdateDynamicSurveySectionSupplementalReportMutation = (
  { __typename?: 'Mutation' }
  & { updateDynamicSurveySectionSupplementalReport: (
    { __typename?: 'DynamicSurveySection' }
    & Pick<DynamicSurveySection, 'id' | 'name' | 'description' | 'order' | 'isDynamic' | 'requireSupplementalReport' | 'supplementalReportType' | 'allowAdditions' | 'createdAt' | 'updatedAt'>
    & { questions: Array<(
      { __typename?: 'DynamicSurveySectionQuestion' }
      & Pick<DynamicSurveySectionQuestion, 'id' | 'name' | 'description' | 'order' | 'isRequired' | 'allowCorrections' | 'canAddToKeyIncidents' | 'questionType'>
      & { options: Array<(
        { __typename?: 'DynamicSurveySectionQuestionOption' }
        & Pick<DynamicSurveySectionQuestionOption, 'id' | 'value' | 'order'>
      )>, assessmentObject: (
        { __typename?: 'AssessmentObject' }
        & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name'>
      ), subQuestions: Array<(
        { __typename?: 'DynamicSurveySectionSubQuestion' }
        & Pick<DynamicSurveySectionSubQuestion, 'id' | 'name' | 'questionText' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType'>
        & { options: Array<(
          { __typename?: 'DynamicSurveySectionSubQuestionOption' }
          & Pick<DynamicSurveySectionSubQuestionOption, 'id' | 'value' | 'order'>
        )> }
      )> }
    )> }
  ) }
);

export type DeleteDynamicSurveySectionMutationVariables = Exact<{
  sectionId: Scalars['String'];
}>;


export type DeleteDynamicSurveySectionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteDynamicSurveySection'>
);

export type AddDynamicSurveySectionQuestionMutationVariables = Exact<{
  sectionId: Scalars['String'];
  assessmentObjectId: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  isRequired: Scalars['Boolean'];
  allowCorrections: Scalars['Boolean'];
  questionType: SurveyQuestionTypes;
}>;


export type AddDynamicSurveySectionQuestionMutation = (
  { __typename?: 'Mutation' }
  & { addDynamicSurveySectionQuestion: (
    { __typename?: 'DynamicSurveySectionQuestion' }
    & Pick<DynamicSurveySectionQuestion, 'id' | 'name' | 'description' | 'order' | 'isRequired' | 'allowCorrections' | 'canAddToKeyIncidents' | 'questionType' | 'createdAt' | 'updatedAt'>
    & { options: Array<(
      { __typename?: 'DynamicSurveySectionQuestionOption' }
      & Pick<DynamicSurveySectionQuestionOption, 'id' | 'value' | 'order'>
    )>, assessmentObject: (
      { __typename?: 'AssessmentObject' }
      & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name'>
    ), subQuestions: Array<(
      { __typename?: 'DynamicSurveySectionSubQuestion' }
      & Pick<DynamicSurveySectionSubQuestion, 'id' | 'name' | 'questionText' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType'>
      & { options: Array<(
        { __typename?: 'DynamicSurveySectionSubQuestionOption' }
        & Pick<DynamicSurveySectionSubQuestionOption, 'id' | 'value' | 'order'>
      )> }
    )> }
  ) }
);

export type UpdateDynamicSurveySectionQuestionMutationVariables = Exact<{
  questionId: Scalars['String'];
  assessmentObjectId: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  isRequired: Scalars['Boolean'];
  allowCorrections: Scalars['Boolean'];
  order: Scalars['Int'];
}>;


export type UpdateDynamicSurveySectionQuestionMutation = (
  { __typename?: 'Mutation' }
  & { updateDynamicSurveySectionQuestion: (
    { __typename?: 'DynamicSurveySectionQuestion' }
    & Pick<DynamicSurveySectionQuestion, 'id' | 'name' | 'description' | 'order' | 'isRequired' | 'allowCorrections' | 'canAddToKeyIncidents' | 'questionType' | 'createdAt' | 'updatedAt'>
    & { options: Array<(
      { __typename?: 'DynamicSurveySectionQuestionOption' }
      & Pick<DynamicSurveySectionQuestionOption, 'id' | 'value' | 'order'>
    )>, assessmentObject: (
      { __typename?: 'AssessmentObject' }
      & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name'>
    ), subQuestions: Array<(
      { __typename?: 'DynamicSurveySectionSubQuestion' }
      & Pick<DynamicSurveySectionSubQuestion, 'id' | 'name' | 'questionText' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType'>
      & { options: Array<(
        { __typename?: 'DynamicSurveySectionSubQuestionOption' }
        & Pick<DynamicSurveySectionSubQuestionOption, 'id' | 'value' | 'order'>
      )> }
    )> }
  ) }
);

export type DeleteDynamicSurveySectionQuestionMutationVariables = Exact<{
  questionId: Scalars['String'];
}>;


export type DeleteDynamicSurveySectionQuestionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteDynamicSurveySectionQuestion'>
);

export type SetDynamicSurveySectionQuestionCanAddToKeyIncidentsMutationVariables = Exact<{
  questionId: Scalars['String'];
  canAddToKeyIncidents: Scalars['Boolean'];
}>;


export type SetDynamicSurveySectionQuestionCanAddToKeyIncidentsMutation = (
  { __typename?: 'Mutation' }
  & { setDynamicSurveySectionQuestionCanAddToKeyIncidents: (
    { __typename?: 'DynamicSurveySectionQuestion' }
    & Pick<DynamicSurveySectionQuestion, 'id' | 'name' | 'description' | 'order' | 'isRequired' | 'allowCorrections' | 'canAddToKeyIncidents' | 'questionType' | 'createdAt' | 'updatedAt'>
    & { options: Array<(
      { __typename?: 'DynamicSurveySectionQuestionOption' }
      & Pick<DynamicSurveySectionQuestionOption, 'id' | 'value' | 'order'>
    )>, assessmentObject: (
      { __typename?: 'AssessmentObject' }
      & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name'>
    ), subQuestions: Array<(
      { __typename?: 'DynamicSurveySectionSubQuestion' }
      & Pick<DynamicSurveySectionSubQuestion, 'id' | 'name' | 'questionText' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType'>
      & { options: Array<(
        { __typename?: 'DynamicSurveySectionSubQuestionOption' }
        & Pick<DynamicSurveySectionSubQuestionOption, 'id' | 'value' | 'order'>
      )> }
    )> }
  ) }
);

export type AddDynamicSurveySectionQuestionOptionMutationVariables = Exact<{
  questionId: Scalars['String'];
  value: Scalars['String'];
}>;


export type AddDynamicSurveySectionQuestionOptionMutation = (
  { __typename?: 'Mutation' }
  & { addDynamicSurveySectionQuestionOption: (
    { __typename?: 'DynamicSurveySectionQuestionOption' }
    & Pick<DynamicSurveySectionQuestionOption, 'id' | 'value' | 'order'>
  ) }
);

export type DeleteDynamicSurveySectionQuestionOptionMutationVariables = Exact<{
  optionId: Scalars['String'];
}>;


export type DeleteDynamicSurveySectionQuestionOptionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteDynamicSurveySectionQuestionOption'>
);

export type UpdateDynamicSurveySectionQuestionOptionMutationVariables = Exact<{
  optionId: Scalars['String'];
  value: Scalars['String'];
  order: Scalars['Int'];
}>;


export type UpdateDynamicSurveySectionQuestionOptionMutation = (
  { __typename?: 'Mutation' }
  & { updateDynamicSurveySectionQuestionOption: (
    { __typename?: 'DynamicSurveySectionQuestionOption' }
    & Pick<DynamicSurveySectionQuestionOption, 'id' | 'value' | 'order'>
  ) }
);

export type AddDynamicSurveySectionSubQuestionMutationVariables = Exact<{
  questionId: Scalars['String'];
  name: Scalars['String'];
  questionText: Scalars['String'];
  isRequired: Scalars['Boolean'];
  questionType: SurveyQuestionTypes;
}>;


export type AddDynamicSurveySectionSubQuestionMutation = (
  { __typename?: 'Mutation' }
  & { addDynamicSurveySectionSubQuestion: (
    { __typename?: 'DynamicSurveySectionSubQuestion' }
    & Pick<DynamicSurveySectionSubQuestion, 'id' | 'name' | 'questionText' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType' | 'createdAt' | 'updatedAt'>
    & { options: Array<(
      { __typename?: 'DynamicSurveySectionSubQuestionOption' }
      & Pick<DynamicSurveySectionSubQuestionOption, 'id' | 'value' | 'order'>
    )> }
  ) }
);

export type UpdateDynamicSurveySectionSubQuestionMutationVariables = Exact<{
  subQuestionId: Scalars['String'];
  name: Scalars['String'];
  questionText: Scalars['String'];
  isRequired: Scalars['Boolean'];
  order: Scalars['Int'];
}>;


export type UpdateDynamicSurveySectionSubQuestionMutation = (
  { __typename?: 'Mutation' }
  & { updateDynamicSurveySectionSubQuestion: (
    { __typename?: 'DynamicSurveySectionSubQuestion' }
    & Pick<DynamicSurveySectionSubQuestion, 'id' | 'name' | 'questionText' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType' | 'createdAt' | 'updatedAt'>
    & { options: Array<(
      { __typename?: 'DynamicSurveySectionSubQuestionOption' }
      & Pick<DynamicSurveySectionSubQuestionOption, 'id' | 'value' | 'order'>
    )> }
  ) }
);

export type DeleteDynamicSurveySectionSubQuestionMutationVariables = Exact<{
  subQuestionId: Scalars['String'];
}>;


export type DeleteDynamicSurveySectionSubQuestionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteDynamicSurveySectionSubQuestion'>
);

export type SetDynamicSurveySectionSubQuestionCanAddToKeyIncidentsMutationVariables = Exact<{
  subQuestionId: Scalars['String'];
  canAddToKeyIncidents: Scalars['Boolean'];
}>;


export type SetDynamicSurveySectionSubQuestionCanAddToKeyIncidentsMutation = (
  { __typename?: 'Mutation' }
  & { setDynamicSurveySectionSubQuestionCanAddToKeyIncidents: (
    { __typename?: 'DynamicSurveySectionSubQuestion' }
    & Pick<DynamicSurveySectionSubQuestion, 'id' | 'name' | 'questionText' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType' | 'createdAt' | 'updatedAt'>
    & { options: Array<(
      { __typename?: 'DynamicSurveySectionSubQuestionOption' }
      & Pick<DynamicSurveySectionSubQuestionOption, 'id' | 'value' | 'order'>
    )> }
  ) }
);

export type AddDynamicSurveySectionSubQuestionOptionMutationVariables = Exact<{
  subQuestionId: Scalars['String'];
  value: Scalars['String'];
}>;


export type AddDynamicSurveySectionSubQuestionOptionMutation = (
  { __typename?: 'Mutation' }
  & { addDynamicSurveySectionSubQuestionOption: (
    { __typename?: 'DynamicSurveySectionSubQuestionOption' }
    & Pick<DynamicSurveySectionSubQuestionOption, 'id' | 'value' | 'order'>
  ) }
);

export type DeleteDynamicSurveySectionSubQuestionOptionMutationVariables = Exact<{
  optionId: Scalars['String'];
}>;


export type DeleteDynamicSurveySectionSubQuestionOptionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteDynamicSurveySectionSubQuestionOption'>
);

export type UpdateDynamicSurveySectionSubQuestionOptionMutationVariables = Exact<{
  optionId: Scalars['String'];
  value: Scalars['String'];
  order: Scalars['Int'];
}>;


export type UpdateDynamicSurveySectionSubQuestionOptionMutation = (
  { __typename?: 'Mutation' }
  & { updateDynamicSurveySectionSubQuestionOption: (
    { __typename?: 'DynamicSurveySectionSubQuestionOption' }
    & Pick<DynamicSurveySectionSubQuestionOption, 'id' | 'value' | 'order'>
  ) }
);

export type AddDynamicSurveySummaryRecipientMutationVariables = Exact<{
  surveyId: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive: Scalars['Boolean'];
}>;


export type AddDynamicSurveySummaryRecipientMutation = (
  { __typename?: 'Mutation' }
  & { addDynamicSurveySummaryRecipient: (
    { __typename?: 'SurveySummaryRecipient' }
    & Pick<SurveySummaryRecipient, 'id' | 'email' | 'firstName' | 'lastName' | 'isActive'>
  ) }
);

export type DeleteDynamicSurveySummaryRecipientMutationVariables = Exact<{
  recipientId: Scalars['String'];
}>;


export type DeleteDynamicSurveySummaryRecipientMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteDynamicSurveySummaryRecipient'>
);

export type SetIsActiveForDynamicSurveySummaryRecipientMutationVariables = Exact<{
  recipientId: Scalars['String'];
  isActive: Scalars['Boolean'];
}>;


export type SetIsActiveForDynamicSurveySummaryRecipientMutation = (
  { __typename?: 'Mutation' }
  & { setIsActiveForDynamicSurveySummaryRecipient: (
    { __typename?: 'SurveySummaryRecipient' }
    & Pick<SurveySummaryRecipient, 'id' | 'email' | 'firstName' | 'lastName' | 'isActive'>
  ) }
);

export type ListSummaryRecipientsForDynamicSurveyQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  surveyId: Scalars['String'];
  emailOrName?: Maybe<Scalars['String']>;
}>;


export type ListSummaryRecipientsForDynamicSurveyQuery = (
  { __typename?: 'Query' }
  & { listSummaryRecipientsForDynamicSurvey: (
    { __typename?: 'SurveySummaryRecipients' }
    & { recipients: Array<(
      { __typename?: 'SurveySummaryRecipient' }
      & Pick<SurveySummaryRecipient, 'id' | 'email' | 'firstName' | 'lastName' | 'isActive'>
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type ListAvailableCompetitionsForFeedbackForMeQueryVariables = Exact<{ [key: string]: never; }>;


export type ListAvailableCompetitionsForFeedbackForMeQuery = (
  { __typename?: 'Query' }
  & { listAvailableCompetitionsForFeedbackForMe: Array<(
    { __typename?: 'CompetitionsAvailableForAdditionalFeedback' }
    & Pick<CompetitionsAvailableForAdditionalFeedback, 'organizationId' | 'organizationName' | 'leagueId' | 'leagueName' | 'seasonId' | 'seasonName' | 'competitionId' | 'competitionExternalId' | 'startTime' | 'sport' | 'homeTeamId' | 'homeTeamName' | 'homeTeamAbbreviation' | 'awayTeamId' | 'awayTeamName' | 'awayTeamAbbreviation' | 'canAddKeyIncidentsToAssessments' | 'canAddKeyIncidentsToDynamicSurveys' | 'canAddSupplementalReportsToDynamicSurveys'>
  )> }
);

export type ListSourcesAvailableForAdditionalFeedbackForCompetitionsForMeQueryVariables = Exact<{
  competitionId: Scalars['String'];
  source: FeedbackSource;
  type: FeedbackType;
}>;


export type ListSourcesAvailableForAdditionalFeedbackForCompetitionsForMeQuery = (
  { __typename?: 'Query' }
  & { listSourcesAvailableForAdditionalFeedbackForCompetitionsForMe: Array<(
    { __typename?: 'SourcesAvailableForAdditionalFeedback' }
    & Pick<SourcesAvailableForAdditionalFeedback, 'organizationId' | 'organizationName' | 'leagueId' | 'leagueName' | 'seasonId' | 'seasonName' | 'competitionId' | 'competitionExternalId' | 'startTime' | 'sport' | 'homeTeamId' | 'homeTeamName' | 'homeTeamAbbreviation' | 'awayTeamId' | 'awayTeamName' | 'awayTeamAbbreviation' | 'sourceType' | 'feedbackType' | 'surveyId' | 'surveyName'>
  )> }
);

export type AddAdditionalSupplementalReportToDynamicCompetitionSurveyMutationVariables = Exact<{
  competitionSurveyId: Scalars['String'];
  title: Scalars['String'];
  supplementalReportType: SupplementalReportTypes;
  time?: Maybe<Scalars['String']>;
  teamId?: Maybe<Scalars['String']>;
  report?: Maybe<Scalars['String']>;
}>;


export type AddAdditionalSupplementalReportToDynamicCompetitionSurveyMutation = (
  { __typename?: 'Mutation' }
  & { addAdditionalSupplementalReportToDynamicCompetitionSurvey: (
    { __typename?: 'SupplementalReport' }
    & Pick<SupplementalReport, 'id' | 'competitionSurveyId' | 'dynamicCompetitionSurveyId' | 'organizationId' | 'organizationName' | 'leagueId' | 'leagueName' | 'seasonId' | 'seasonName' | 'competitionSurveySectionQuestionId' | 'competitionSurveyFeedbackId' | 'dynamicCompetitionSurveySectionQuestionId' | 'dynamicCompetitionSurveyFeedbackId' | 'dynamicCompetitionSurveySubFeedbackId' | 'type' | 'status' | 'title' | 'report' | 'reportVersion' | 'time' | 'approvedDate' | 'reopenedDate' | 'submittedAt' | 'createdAt' | 'updatedAt'>
    & { submittedBy?: Maybe<(
      { __typename?: 'SupplementalReportSubmittedByInfo' }
      & Pick<SupplementalReportSubmittedByInfo, 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'teamId' | 'teamName' | 'teamLogo' | 'officialId' | 'assessmentObjectId' | 'assessmentObjectName'>
    )>, competitionInfo: (
      { __typename?: 'SupplementalReportCompetitionInfo' }
      & Pick<SupplementalReportCompetitionInfo, 'competitionId' | 'externalId' | 'sport' | 'homeTeamId' | 'homeTeamName' | 'homeTeamScore' | 'homeTeamTieBreakerScore' | 'awayTeamId' | 'awayTeamName' | 'awayTeamScore' | 'awayTeamTieBreakerScore' | 'startTime' | 'timeZone' | 'location'>
    ), assessmentObject?: Maybe<(
      { __typename?: 'AssessmentObject' }
      & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name' | 'createdAt' | 'updatedAt'>
    )>, official?: Maybe<(
      { __typename?: 'Official' }
      & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
    )>, team?: Maybe<(
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'classification' | 'gender' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'timeZone' | 'website' | 'logo' | 'createdAt' | 'updatedAt'>
    )> }
  ) }
);

export type ListAvailableCompetitionOfficialsForAdditionalKeyIncidentsQueryVariables = Exact<{
  competitionSurveyId: Scalars['String'];
  source: FeedbackSource;
}>;


export type ListAvailableCompetitionOfficialsForAdditionalKeyIncidentsQuery = (
  { __typename?: 'Query' }
  & { listAvailableCompetitionOfficialsForAdditionalKeyIncidents: Array<(
    { __typename?: 'CompetitionOfficialsAvailableForAdditionalKeyIncidents' }
    & Pick<CompetitionOfficialsAvailableForAdditionalKeyIncidents, 'sectionId' | 'sectionName' | 'competitionOfficialId' | 'assessmentObjectId' | 'type' | 'order'>
    & { official: (
      { __typename?: 'Official' }
      & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
    ) }
  )> }
);

export type ListAvailableFeedbackToConvertForAdditionalKeyIncidentsQueryVariables = Exact<{
  competitionSurveyId: Scalars['String'];
  source: FeedbackSource;
}>;


export type ListAvailableFeedbackToConvertForAdditionalKeyIncidentsQuery = (
  { __typename?: 'Query' }
  & { listAvailableFeedbackToConvertForAdditionalKeyIncidents: Array<(
    { __typename?: 'FeedbackAvailableToConvertToKeyIncident' }
    & Pick<FeedbackAvailableToConvertToKeyIncident, 'surveyId' | 'sectionId' | 'assessmentObjectId' | 'assessmentObjectName' | 'officialId' | 'officialFirstName' | 'officialLastName' | 'questionId' | 'questionName' | 'responseId' | 'response'>
  )> }
);

export type AddAdditionalKeyIncidentToCompetitionSurveyMutationVariables = Exact<{
  competitionSurveyId: Scalars['String'];
  source: FeedbackSource;
  description: Scalars['String'];
  incidentType: KeyIncidentTypes;
  impactedOutcome: Scalars['Boolean'];
  time?: Maybe<Scalars['String']>;
  sectionId?: Maybe<Scalars['String']>;
  questionId?: Maybe<Scalars['String']>;
  feedbackResponseId?: Maybe<Scalars['String']>;
  periodId?: Maybe<Scalars['String']>;
  teamId?: Maybe<Scalars['String']>;
}>;


export type AddAdditionalKeyIncidentToCompetitionSurveyMutation = (
  { __typename?: 'Mutation' }
  & { addAdditionalKeyIncidentToCompetitionSurvey: (
    { __typename?: 'KeyIncident' }
    & Pick<KeyIncident, 'id' | 'competitionSurveyId' | 'dynamicCompetitionSurveyId' | 'organizationId' | 'organizationName' | 'leagueId' | 'leagueName' | 'seasonId' | 'seasonName' | 'competitionSurveyFeedbackId' | 'type' | 'status' | 'description' | 'time' | 'videoStatus' | 'videoUrl' | 'videoError' | 'impactedOutcome' | 'resolvedDate' | 'reopenedDate' | 'createdAt' | 'updatedAt'>
    & { submittedBy: (
      { __typename?: 'KeyIncidentSubmittedByInfo' }
      & Pick<KeyIncidentSubmittedByInfo, 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'teamId' | 'teamName' | 'teamLogo'>
    ), competitionInfo: (
      { __typename?: 'KeyIncidentCompetitionInfo' }
      & Pick<KeyIncidentCompetitionInfo, 'competitionId' | 'externalId' | 'sport' | 'homeTeamId' | 'homeTeamName' | 'homeTeamScore' | 'homeTeamTieBreakerScore' | 'awayTeamId' | 'awayTeamName' | 'awayTeamScore' | 'awayTeamTieBreakerScore' | 'startTime' | 'timeZone' | 'location'>
    ), assessmentObject?: Maybe<(
      { __typename?: 'AssessmentObject' }
      & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name' | 'createdAt' | 'updatedAt'>
    )>, official?: Maybe<(
      { __typename?: 'Official' }
      & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
    )>, period?: Maybe<(
      { __typename?: 'Period' }
      & Pick<Period, 'id' | 'sport' | 'name' | 'createdAt' | 'updatedAt'>
    )> }
  ) }
);

export type GetKeyIncidentByIdQueryVariables = Exact<{
  keyIncidentId: Scalars['String'];
}>;


export type GetKeyIncidentByIdQuery = (
  { __typename?: 'Query' }
  & { getKeyIncidentById: (
    { __typename?: 'KeyIncident' }
    & Pick<KeyIncident, 'id' | 'competitionSurveyId' | 'dynamicCompetitionSurveyId' | 'organizationId' | 'organizationName' | 'leagueId' | 'leagueName' | 'seasonId' | 'seasonName' | 'competitionSurveyFeedbackId' | 'dynamicCompetitionSurveyFeedbackId' | 'dynamicCompetitionSurveySubFeedbackId' | 'type' | 'status' | 'description' | 'time' | 'videoStatus' | 'videoUrl' | 'videoError' | 'videoIntegrationRetryCount' | 'impactedOutcome' | 'resolvedDate' | 'reopenedDate' | 'createdAt' | 'updatedAt' | 'reviewStartedAt'>
    & { submittedBy: (
      { __typename?: 'KeyIncidentSubmittedByInfo' }
      & Pick<KeyIncidentSubmittedByInfo, 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'teamId' | 'teamName' | 'teamLogo' | 'officialId' | 'assessmentObjectId' | 'assessmentObjectName'>
    ), reviewStartedBy?: Maybe<(
      { __typename?: 'KeyIncidentReviewStartedByInfo' }
      & Pick<KeyIncidentReviewStartedByInfo, 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'title' | 'organizationName' | 'organizationAbbreviation'>
    )>, resolvedBy?: Maybe<(
      { __typename?: 'KeyIncidentResolvedByInfo' }
      & Pick<KeyIncidentResolvedByInfo, 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'title' | 'organizationName' | 'organizationAbbreviation'>
    )>, competitionInfo: (
      { __typename?: 'KeyIncidentCompetitionInfo' }
      & Pick<KeyIncidentCompetitionInfo, 'competitionId' | 'externalId' | 'sport' | 'homeTeamId' | 'homeTeamName' | 'homeTeamScore' | 'homeTeamTieBreakerScore' | 'awayTeamId' | 'awayTeamName' | 'awayTeamScore' | 'awayTeamTieBreakerScore' | 'startTime' | 'timeZone' | 'location' | 'outcome' | 'verificationStatus'>
    ), assessmentObject?: Maybe<(
      { __typename?: 'AssessmentObject' }
      & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name' | 'createdAt' | 'updatedAt'>
    )>, official?: Maybe<(
      { __typename?: 'Official' }
      & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
    )>, team?: Maybe<(
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'classification' | 'gender' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'timeZone' | 'website' | 'logo' | 'createdAt' | 'updatedAt'>
    )>, period?: Maybe<(
      { __typename?: 'Period' }
      & Pick<Period, 'id' | 'sport' | 'name'>
    )> }
  ) }
);

export type ListKeyIncidentsForCompetitionQueryVariables = Exact<{
  competitionId: Scalars['String'];
}>;


export type ListKeyIncidentsForCompetitionQuery = (
  { __typename?: 'Query' }
  & { listKeyIncidentsForCompetition: (
    { __typename?: 'KeyIncidentsForCompetition' }
    & { keyIncidents: Array<(
      { __typename?: 'KeyIncident' }
      & Pick<KeyIncident, 'id' | 'competitionSurveyId' | 'dynamicCompetitionSurveyId' | 'organizationId' | 'organizationName' | 'leagueId' | 'leagueName' | 'seasonId' | 'seasonName' | 'competitionSurveyFeedbackId' | 'dynamicCompetitionSurveyFeedbackId' | 'dynamicCompetitionSurveySubFeedbackId' | 'type' | 'status' | 'description' | 'time' | 'videoStatus' | 'videoUrl' | 'videoError' | 'videoIntegrationRetryCount' | 'impactedOutcome' | 'resolvedDate' | 'reopenedDate' | 'createdAt' | 'updatedAt' | 'reviewStartedAt'>
      & { submittedBy: (
        { __typename?: 'KeyIncidentSubmittedByInfo' }
        & Pick<KeyIncidentSubmittedByInfo, 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'teamId' | 'teamName' | 'teamLogo' | 'officialId' | 'assessmentObjectId' | 'assessmentObjectName'>
      ), reviewStartedBy?: Maybe<(
        { __typename?: 'KeyIncidentReviewStartedByInfo' }
        & Pick<KeyIncidentReviewStartedByInfo, 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'title' | 'organizationName' | 'organizationAbbreviation'>
      )>, resolvedBy?: Maybe<(
        { __typename?: 'KeyIncidentResolvedByInfo' }
        & Pick<KeyIncidentResolvedByInfo, 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'title' | 'organizationName' | 'organizationAbbreviation'>
      )>, competitionInfo: (
        { __typename?: 'KeyIncidentCompetitionInfo' }
        & Pick<KeyIncidentCompetitionInfo, 'competitionId' | 'externalId' | 'sport' | 'homeTeamId' | 'homeTeamName' | 'homeTeamScore' | 'homeTeamTieBreakerScore' | 'awayTeamId' | 'awayTeamName' | 'awayTeamScore' | 'awayTeamTieBreakerScore' | 'startTime' | 'timeZone' | 'location' | 'outcome' | 'verificationStatus'>
      ), assessmentObject?: Maybe<(
        { __typename?: 'AssessmentObject' }
        & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name' | 'createdAt' | 'updatedAt'>
      )>, official?: Maybe<(
        { __typename?: 'Official' }
        & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
      )>, team?: Maybe<(
        { __typename?: 'Team' }
        & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'classification' | 'gender' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'timeZone' | 'website' | 'logo' | 'createdAt' | 'updatedAt'>
      )>, period?: Maybe<(
        { __typename?: 'Period' }
        & Pick<Period, 'id' | 'sport' | 'name'>
      )> }
    )>, competitionInfo: (
      { __typename?: 'KeyIncidentCompetitionInfo' }
      & Pick<KeyIncidentCompetitionInfo, 'competitionId' | 'externalId' | 'sport' | 'homeTeamId' | 'homeTeamName' | 'homeTeamLogo' | 'homeTeamScore' | 'homeTeamTieBreakerScore' | 'awayTeamId' | 'awayTeamName' | 'awayTeamLogo' | 'awayTeamScore' | 'awayTeamTieBreakerScore' | 'startTime' | 'timeZone' | 'location' | 'outcome' | 'verificationStatus'>
    ) }
  ) }
);

export type ListKeyIncidentsForCompetitionAndSurveyQueryVariables = Exact<{
  orgId: Scalars['String'];
  competitionId: Scalars['String'];
  surveyId: Scalars['String'];
}>;


export type ListKeyIncidentsForCompetitionAndSurveyQuery = (
  { __typename?: 'Query' }
  & { listKeyIncidentsForCompetitionAndSurvey: Array<(
    { __typename?: 'KeyIncident' }
    & Pick<KeyIncident, 'id' | 'competitionSurveyId' | 'dynamicCompetitionSurveyId' | 'organizationId' | 'organizationName' | 'leagueId' | 'leagueName' | 'seasonId' | 'seasonName' | 'competitionSurveyFeedbackId' | 'dynamicCompetitionSurveyFeedbackId' | 'dynamicCompetitionSurveySubFeedbackId' | 'type' | 'status' | 'description' | 'time' | 'videoStatus' | 'videoUrl' | 'videoError' | 'videoIntegrationRetryCount' | 'impactedOutcome' | 'resolvedDate' | 'reopenedDate' | 'createdAt' | 'updatedAt' | 'reviewStartedAt'>
    & { submittedBy: (
      { __typename?: 'KeyIncidentSubmittedByInfo' }
      & Pick<KeyIncidentSubmittedByInfo, 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'teamId' | 'teamName' | 'teamLogo' | 'officialId' | 'assessmentObjectId' | 'assessmentObjectName'>
    ), reviewStartedBy?: Maybe<(
      { __typename?: 'KeyIncidentReviewStartedByInfo' }
      & Pick<KeyIncidentReviewStartedByInfo, 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'title' | 'organizationName' | 'organizationAbbreviation'>
    )>, resolvedBy?: Maybe<(
      { __typename?: 'KeyIncidentResolvedByInfo' }
      & Pick<KeyIncidentResolvedByInfo, 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'title' | 'organizationName' | 'organizationAbbreviation'>
    )>, competitionInfo: (
      { __typename?: 'KeyIncidentCompetitionInfo' }
      & Pick<KeyIncidentCompetitionInfo, 'competitionId' | 'externalId' | 'sport' | 'homeTeamId' | 'homeTeamName' | 'homeTeamScore' | 'homeTeamTieBreakerScore' | 'awayTeamId' | 'awayTeamName' | 'awayTeamScore' | 'awayTeamTieBreakerScore' | 'startTime' | 'timeZone' | 'location' | 'outcome' | 'verificationStatus'>
    ), assessmentObject?: Maybe<(
      { __typename?: 'AssessmentObject' }
      & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name' | 'createdAt' | 'updatedAt'>
    )>, official?: Maybe<(
      { __typename?: 'Official' }
      & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
    )>, team?: Maybe<(
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'classification' | 'gender' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'timeZone' | 'website' | 'logo' | 'createdAt' | 'updatedAt'>
    )>, period?: Maybe<(
      { __typename?: 'Period' }
      & Pick<Period, 'id' | 'sport' | 'name'>
    )> }
  )> }
);

export type ListKeyIncidentSummariesForOrgQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  orgId: Scalars['String'];
  leagueId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  teamSearch?: Maybe<Scalars['String']>;
  submittedBySearch?: Maybe<Scalars['String']>;
  competitionDateFrom?: Maybe<Scalars['String']>;
  competitionDateTo?: Maybe<Scalars['String']>;
  statuses?: Maybe<Array<KeyIncidentStatus> | KeyIncidentStatus>;
  impactedOutcome?: Maybe<Scalars['Boolean']>;
  videoStatuses?: Maybe<Array<KeyIncidentVideoStatus> | KeyIncidentVideoStatus>;
  lastUpdatedDateFrom?: Maybe<Scalars['String']>;
  lastUpdatedDateTo?: Maybe<Scalars['String']>;
}>;


export type ListKeyIncidentSummariesForOrgQuery = (
  { __typename?: 'Query' }
  & { listKeyIncidentSummariesForOrg: (
    { __typename?: 'KeyIncidentSummaries' }
    & { keyIncidents: Array<(
      { __typename?: 'KeyIncidentSummary' }
      & Pick<KeyIncidentSummary, 'id' | 'organizationId' | 'organizationName' | 'leagueId' | 'leagueName' | 'seasonId' | 'seasonName' | 'competitionId' | 'competitionExternalId' | 'startTime' | 'timeZone' | 'homeTeamId' | 'homeTeamName' | 'homeTeamLogo' | 'homeTeamScore' | 'homeTeamTieBreakerScore' | 'awayTeamId' | 'awayTeamScore' | 'awayTeamTieBreakerScore' | 'awayTeamName' | 'awayTeamLogo' | 'type' | 'status' | 'impactedOutcome' | 'description' | 'time' | 'createdAt' | 'updatedAt' | 'submittedBy' | 'submittedByAssessmentObjectName' | 'submittedByTeam' | 'reviewStartedBy' | 'resolvedBy' | 'officialName' | 'teamName' | 'coachName' | 'assessmentObjectName' | 'resolvedAt' | 'reopenedAt' | 'videoStatus' | 'videoError' | 'reviewStartedAt' | 'messageCount'>
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type ListKeyIncidentSummariesForOrgOfficialQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  orgId: Scalars['String'];
  officialId: Scalars['String'];
  leagueId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  teamSearch?: Maybe<Scalars['String']>;
  submittedBySearch?: Maybe<Scalars['String']>;
  competitionDateFrom?: Maybe<Scalars['String']>;
  competitionDateTo?: Maybe<Scalars['String']>;
  statuses?: Maybe<Array<KeyIncidentStatus> | KeyIncidentStatus>;
  impactedOutcome?: Maybe<Scalars['Boolean']>;
  videoStatuses?: Maybe<Array<KeyIncidentVideoStatus> | KeyIncidentVideoStatus>;
  lastUpdatedDateFrom?: Maybe<Scalars['String']>;
  lastUpdatedDateTo?: Maybe<Scalars['String']>;
}>;


export type ListKeyIncidentSummariesForOrgOfficialQuery = (
  { __typename?: 'Query' }
  & { listKeyIncidentSummariesForOrgOfficial: (
    { __typename?: 'KeyIncidentSummaries' }
    & { keyIncidents: Array<(
      { __typename?: 'KeyIncidentSummary' }
      & Pick<KeyIncidentSummary, 'id' | 'organizationId' | 'organizationName' | 'leagueId' | 'leagueName' | 'seasonId' | 'seasonName' | 'competitionId' | 'competitionExternalId' | 'startTime' | 'timeZone' | 'homeTeamId' | 'homeTeamName' | 'homeTeamLogo' | 'homeTeamScore' | 'homeTeamTieBreakerScore' | 'awayTeamId' | 'awayTeamScore' | 'awayTeamTieBreakerScore' | 'awayTeamName' | 'awayTeamLogo' | 'type' | 'status' | 'impactedOutcome' | 'description' | 'time' | 'createdAt' | 'updatedAt' | 'submittedBy' | 'submittedByAssessmentObjectName' | 'submittedByTeam' | 'reviewStartedBy' | 'resolvedBy' | 'officialName' | 'teamName' | 'coachName' | 'assessmentObjectName' | 'resolvedAt' | 'reopenedAt' | 'videoStatus' | 'videoError' | 'reviewStartedAt' | 'messageCount'>
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type ListKeyIncidentSummariesForUserQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  leagueId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  teamSearch?: Maybe<Scalars['String']>;
  submittedBySearch?: Maybe<Scalars['String']>;
  competitionDateFrom?: Maybe<Scalars['String']>;
  competitionDateTo?: Maybe<Scalars['String']>;
  statuses?: Maybe<Array<KeyIncidentStatus> | KeyIncidentStatus>;
  impactedOutcome?: Maybe<Scalars['Boolean']>;
  videoStatuses?: Maybe<Array<KeyIncidentVideoStatus> | KeyIncidentVideoStatus>;
  lastUpdatedDateFrom?: Maybe<Scalars['String']>;
  lastUpdatedDateTo?: Maybe<Scalars['String']>;
}>;


export type ListKeyIncidentSummariesForUserQuery = (
  { __typename?: 'Query' }
  & { listKeyIncidentSummariesForUser: (
    { __typename?: 'KeyIncidentSummaries' }
    & { keyIncidents: Array<(
      { __typename?: 'KeyIncidentSummary' }
      & Pick<KeyIncidentSummary, 'id' | 'organizationId' | 'organizationName' | 'leagueId' | 'leagueName' | 'seasonId' | 'seasonName' | 'competitionId' | 'competitionExternalId' | 'startTime' | 'timeZone' | 'homeTeamId' | 'homeTeamName' | 'homeTeamLogo' | 'homeTeamScore' | 'homeTeamTieBreakerScore' | 'awayTeamId' | 'awayTeamScore' | 'awayTeamTieBreakerScore' | 'awayTeamName' | 'awayTeamLogo' | 'type' | 'status' | 'impactedOutcome' | 'description' | 'time' | 'createdAt' | 'updatedAt' | 'submittedBy' | 'submittedByAssessmentObjectName' | 'submittedByTeam' | 'reviewStartedBy' | 'resolvedBy' | 'officialName' | 'teamName' | 'coachName' | 'assessmentObjectName' | 'resolvedAt' | 'reopenedAt' | 'videoStatus' | 'videoError' | 'reviewStartedAt' | 'messageCount'>
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type BeginReviewForKeyIncidentMutationVariables = Exact<{
  keyIncidentId: Scalars['String'];
}>;


export type BeginReviewForKeyIncidentMutation = (
  { __typename?: 'Mutation' }
  & { beginReviewForKeyIncident: (
    { __typename?: 'KeyIncident' }
    & Pick<KeyIncident, 'id' | 'competitionSurveyId' | 'dynamicCompetitionSurveyId' | 'organizationId' | 'organizationName' | 'leagueId' | 'leagueName' | 'seasonId' | 'seasonName' | 'competitionSurveyFeedbackId' | 'dynamicCompetitionSurveyFeedbackId' | 'dynamicCompetitionSurveySubFeedbackId' | 'type' | 'status' | 'description' | 'time' | 'videoStatus' | 'videoUrl' | 'videoError' | 'videoIntegrationRetryCount' | 'impactedOutcome' | 'resolvedDate' | 'reopenedDate' | 'createdAt' | 'updatedAt' | 'reviewStartedAt'>
    & { submittedBy: (
      { __typename?: 'KeyIncidentSubmittedByInfo' }
      & Pick<KeyIncidentSubmittedByInfo, 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'teamId' | 'teamName' | 'teamLogo' | 'officialId' | 'assessmentObjectId' | 'assessmentObjectName'>
    ), reviewStartedBy?: Maybe<(
      { __typename?: 'KeyIncidentReviewStartedByInfo' }
      & Pick<KeyIncidentReviewStartedByInfo, 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'title' | 'organizationName' | 'organizationAbbreviation'>
    )>, resolvedBy?: Maybe<(
      { __typename?: 'KeyIncidentResolvedByInfo' }
      & Pick<KeyIncidentResolvedByInfo, 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'title' | 'organizationName' | 'organizationAbbreviation'>
    )>, competitionInfo: (
      { __typename?: 'KeyIncidentCompetitionInfo' }
      & Pick<KeyIncidentCompetitionInfo, 'competitionId' | 'externalId' | 'sport' | 'homeTeamId' | 'homeTeamName' | 'homeTeamScore' | 'homeTeamTieBreakerScore' | 'awayTeamId' | 'awayTeamName' | 'awayTeamScore' | 'awayTeamTieBreakerScore' | 'startTime' | 'timeZone' | 'location' | 'outcome' | 'verificationStatus'>
    ), assessmentObject?: Maybe<(
      { __typename?: 'AssessmentObject' }
      & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name' | 'createdAt' | 'updatedAt'>
    )>, official?: Maybe<(
      { __typename?: 'Official' }
      & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
    )>, team?: Maybe<(
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'classification' | 'gender' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'timeZone' | 'website' | 'logo' | 'createdAt' | 'updatedAt'>
    )>, period?: Maybe<(
      { __typename?: 'Period' }
      & Pick<Period, 'id' | 'sport' | 'name'>
    )> }
  ) }
);

export type ResolveKeyIncidentMutationVariables = Exact<{
  keyIncidentId: Scalars['String'];
  comment: Scalars['String'];
}>;


export type ResolveKeyIncidentMutation = (
  { __typename?: 'Mutation' }
  & { resolveKeyIncident: (
    { __typename?: 'KeyIncidentResolved' }
    & { message: (
      { __typename?: 'KeyIncidentMessage' }
      & Pick<KeyIncidentMessage, 'id' | 'keyIncidentId' | 'message' | 'createdAt' | 'updatedAt' | 'editedAt'>
      & { author?: Maybe<(
        { __typename?: 'KeyIncidentMessageAuthorInfo' }
        & Pick<KeyIncidentMessageAuthorInfo, 'id' | 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'authorType' | 'teamId' | 'teamName' | 'teamLogo' | 'title' | 'organizationName' | 'organizationAbbreviation'>
      )> }
    ), keyIncident: (
      { __typename?: 'KeyIncident' }
      & Pick<KeyIncident, 'id' | 'competitionSurveyId' | 'dynamicCompetitionSurveyId' | 'organizationId' | 'organizationName' | 'leagueId' | 'leagueName' | 'seasonId' | 'seasonName' | 'competitionSurveyFeedbackId' | 'dynamicCompetitionSurveyFeedbackId' | 'dynamicCompetitionSurveySubFeedbackId' | 'type' | 'status' | 'description' | 'time' | 'videoStatus' | 'videoUrl' | 'videoError' | 'videoIntegrationRetryCount' | 'impactedOutcome' | 'resolvedDate' | 'reopenedDate' | 'createdAt' | 'updatedAt' | 'reviewStartedAt'>
      & { submittedBy: (
        { __typename?: 'KeyIncidentSubmittedByInfo' }
        & Pick<KeyIncidentSubmittedByInfo, 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'teamId' | 'teamName' | 'teamLogo' | 'officialId' | 'assessmentObjectId' | 'assessmentObjectName'>
      ), reviewStartedBy?: Maybe<(
        { __typename?: 'KeyIncidentReviewStartedByInfo' }
        & Pick<KeyIncidentReviewStartedByInfo, 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'title' | 'organizationName' | 'organizationAbbreviation'>
      )>, resolvedBy?: Maybe<(
        { __typename?: 'KeyIncidentResolvedByInfo' }
        & Pick<KeyIncidentResolvedByInfo, 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'title' | 'organizationName' | 'organizationAbbreviation'>
      )>, competitionInfo: (
        { __typename?: 'KeyIncidentCompetitionInfo' }
        & Pick<KeyIncidentCompetitionInfo, 'competitionId' | 'externalId' | 'sport' | 'homeTeamId' | 'homeTeamName' | 'homeTeamScore' | 'homeTeamTieBreakerScore' | 'awayTeamId' | 'awayTeamName' | 'awayTeamScore' | 'awayTeamTieBreakerScore' | 'startTime' | 'timeZone' | 'location' | 'outcome' | 'verificationStatus'>
      ), assessmentObject?: Maybe<(
        { __typename?: 'AssessmentObject' }
        & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name' | 'createdAt' | 'updatedAt'>
      )>, official?: Maybe<(
        { __typename?: 'Official' }
        & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
      )>, team?: Maybe<(
        { __typename?: 'Team' }
        & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'classification' | 'gender' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'timeZone' | 'website' | 'logo' | 'createdAt' | 'updatedAt'>
      )>, period?: Maybe<(
        { __typename?: 'Period' }
        & Pick<Period, 'id' | 'sport' | 'name'>
      )> }
    ) }
  ) }
);

export type ListCommentsForKeyIncidentQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  keyIncidentId: Scalars['String'];
}>;


export type ListCommentsForKeyIncidentQuery = (
  { __typename?: 'Query' }
  & { listCommentsForKeyIncident: (
    { __typename?: 'KeyIncidentMessages' }
    & { messages: Array<(
      { __typename?: 'KeyIncidentMessage' }
      & Pick<KeyIncidentMessage, 'id' | 'keyIncidentId' | 'message' | 'createdAt' | 'updatedAt' | 'editedAt'>
      & { author?: Maybe<(
        { __typename?: 'KeyIncidentMessageAuthorInfo' }
        & Pick<KeyIncidentMessageAuthorInfo, 'id' | 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'authorType' | 'teamId' | 'teamName' | 'teamLogo' | 'title' | 'organizationName' | 'organizationAbbreviation'>
      )> }
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type AddCommentToKeyIncidentMutationVariables = Exact<{
  keyIncidentId: Scalars['String'];
  comment: Scalars['String'];
}>;


export type AddCommentToKeyIncidentMutation = (
  { __typename?: 'Mutation' }
  & { addCommentToKeyIncident: (
    { __typename?: 'KeyIncidentMessage' }
    & Pick<KeyIncidentMessage, 'id' | 'keyIncidentId' | 'message' | 'createdAt' | 'updatedAt' | 'editedAt'>
    & { author?: Maybe<(
      { __typename?: 'KeyIncidentMessageAuthorInfo' }
      & Pick<KeyIncidentMessageAuthorInfo, 'id' | 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'authorType' | 'teamId' | 'teamName' | 'teamLogo' | 'title' | 'organizationName' | 'organizationAbbreviation'>
    )> }
  ) }
);

export type EditKeyIncidentMutationVariables = Exact<{
  keyIncidentId: Scalars['String'];
  time?: Maybe<Scalars['String']>;
  periodId?: Maybe<Scalars['String']>;
}>;


export type EditKeyIncidentMutation = (
  { __typename?: 'Mutation' }
  & { editKeyIncident: (
    { __typename?: 'KeyIncident' }
    & Pick<KeyIncident, 'id' | 'competitionSurveyId' | 'organizationId' | 'organizationName' | 'leagueId' | 'leagueName' | 'seasonId' | 'seasonName' | 'competitionSurveyFeedbackId' | 'type' | 'status' | 'description' | 'time' | 'videoStatus' | 'videoUrl' | 'videoError' | 'videoIntegrationRetryCount' | 'impactedOutcome' | 'resolvedDate' | 'reopenedDate' | 'createdAt' | 'updatedAt' | 'reviewStartedAt'>
    & { submittedBy: (
      { __typename?: 'KeyIncidentSubmittedByInfo' }
      & Pick<KeyIncidentSubmittedByInfo, 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'teamId' | 'teamName' | 'teamLogo'>
    ), reviewStartedBy?: Maybe<(
      { __typename?: 'KeyIncidentReviewStartedByInfo' }
      & Pick<KeyIncidentReviewStartedByInfo, 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic'>
    )>, resolvedBy?: Maybe<(
      { __typename?: 'KeyIncidentResolvedByInfo' }
      & Pick<KeyIncidentResolvedByInfo, 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic'>
    )>, competitionInfo: (
      { __typename?: 'KeyIncidentCompetitionInfo' }
      & Pick<KeyIncidentCompetitionInfo, 'competitionId' | 'externalId' | 'sport' | 'homeTeamId' | 'homeTeamName' | 'homeTeamScore' | 'homeTeamTieBreakerScore' | 'awayTeamId' | 'awayTeamName' | 'awayTeamScore' | 'awayTeamTieBreakerScore' | 'startTime' | 'timeZone' | 'location' | 'outcome' | 'verificationStatus'>
    ), assessmentObject?: Maybe<(
      { __typename?: 'AssessmentObject' }
      & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name' | 'createdAt' | 'updatedAt'>
    )>, official?: Maybe<(
      { __typename?: 'Official' }
      & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
    )>, period?: Maybe<(
      { __typename?: 'Period' }
      & Pick<Period, 'id' | 'sport' | 'name' | 'createdAt' | 'updatedAt'>
    )> }
  ) }
);

export type ListExternalLinksForKeyIncidentQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  keyIncidentId: Scalars['String'];
  types?: Maybe<Array<KeyIncidentExternalLinkTypes> | KeyIncidentExternalLinkTypes>;
}>;


export type ListExternalLinksForKeyIncidentQuery = (
  { __typename?: 'Query' }
  & { listExternalLinksForKeyIncident: (
    { __typename?: 'KeyIncidentExternalLinks' }
    & { externalLinks: Array<(
      { __typename?: 'KeyIncidentExternalLink' }
      & Pick<KeyIncidentExternalLink, 'id' | 'keyIncidentId' | 'link' | 'type' | 'description' | 'createdByFirstName' | 'createdByLastName' | 'createdAt' | 'updatedAt'>
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type AddExternalLinkToKeyIncidentMutationVariables = Exact<{
  keyIncidentId: Scalars['String'];
  link: Scalars['String'];
  type: KeyIncidentExternalLinkTypes;
  description?: Maybe<Scalars['String']>;
}>;


export type AddExternalLinkToKeyIncidentMutation = (
  { __typename?: 'Mutation' }
  & { addExternalLinkToKeyIncident: (
    { __typename?: 'KeyIncidentExternalLink' }
    & Pick<KeyIncidentExternalLink, 'id' | 'keyIncidentId' | 'link' | 'type' | 'description' | 'createdByFirstName' | 'createdByLastName' | 'createdAt' | 'updatedAt'>
  ) }
);

export type DeleteKeyIncidentExternalLinkMutationVariables = Exact<{
  externalLinkId: Scalars['String'];
}>;


export type DeleteKeyIncidentExternalLinkMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteKeyIncidentExternalLink'>
);

export type CreateKeyIncidentVideoIntegrationForOrganizationMutationVariables = Exact<{
  orgId: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  status: KeyIncidentVideoIntegrationStatuses;
  queueName: Scalars['String'];
  enableRetry: Scalars['Boolean'];
  maxRetries?: Maybe<Scalars['Int']>;
  retryIntervalMinutes?: Maybe<Scalars['Int']>;
}>;


export type CreateKeyIncidentVideoIntegrationForOrganizationMutation = (
  { __typename?: 'Mutation' }
  & { createKeyIncidentVideoIntegrationForOrganization: (
    { __typename?: 'KeyIncidentVideoIntegration' }
    & Pick<KeyIncidentVideoIntegration, 'id' | 'orgId' | 'orgName' | 'orgAbbreviation' | 'name' | 'description' | 'status' | 'queueName' | 'enableRetry' | 'maxRetries' | 'retryIntervalMinutes' | 'createdAt' | 'updatedAt'>
    & { additionalOptions: Array<(
      { __typename?: 'KeyIncidentVideoIntegrationAdditionalOption' }
      & Pick<KeyIncidentVideoIntegrationAdditionalOption, 'id' | 'name' | 'value' | 'createdAt' | 'updatedAt'>
    )> }
  ) }
);

export type GetKeyIncidentVideoIntegrationByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetKeyIncidentVideoIntegrationByIdQuery = (
  { __typename?: 'Query' }
  & { getKeyIncidentVideoIntegrationById: (
    { __typename?: 'KeyIncidentVideoIntegration' }
    & Pick<KeyIncidentVideoIntegration, 'id' | 'orgId' | 'orgName' | 'orgAbbreviation' | 'name' | 'description' | 'status' | 'queueName' | 'enableRetry' | 'maxRetries' | 'retryIntervalMinutes' | 'createdAt' | 'updatedAt'>
    & { additionalOptions: Array<(
      { __typename?: 'KeyIncidentVideoIntegrationAdditionalOption' }
      & Pick<KeyIncidentVideoIntegrationAdditionalOption, 'id' | 'name' | 'value' | 'createdAt' | 'updatedAt'>
    )> }
  ) }
);

export type ListKeyIncidentVideoIntegrationsQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  orgId?: Maybe<Scalars['String']>;
  orgName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  queueName?: Maybe<Scalars['String']>;
  status?: Maybe<KeyIncidentVideoIntegrationStatuses>;
}>;


export type ListKeyIncidentVideoIntegrationsQuery = (
  { __typename?: 'Query' }
  & { listKeyIncidentVideoIntegrations: (
    { __typename?: 'KeyIncidentVideoIntegrations' }
    & { integrations: Array<(
      { __typename?: 'KeyIncidentVideoIntegration' }
      & Pick<KeyIncidentVideoIntegration, 'id' | 'orgId' | 'orgName' | 'orgAbbreviation' | 'name' | 'description' | 'status' | 'queueName' | 'enableRetry' | 'maxRetries' | 'retryIntervalMinutes' | 'createdAt' | 'updatedAt'>
      & { additionalOptions: Array<(
        { __typename?: 'KeyIncidentVideoIntegrationAdditionalOption' }
        & Pick<KeyIncidentVideoIntegrationAdditionalOption, 'id' | 'name' | 'value' | 'createdAt' | 'updatedAt'>
      )> }
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type UpdateKeyIncidentVideoIntegrationMutationVariables = Exact<{
  id: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  status: KeyIncidentVideoIntegrationStatuses;
  queueName: Scalars['String'];
  enableRetry: Scalars['Boolean'];
  maxRetries?: Maybe<Scalars['Int']>;
  retryIntervalMinutes?: Maybe<Scalars['Int']>;
}>;


export type UpdateKeyIncidentVideoIntegrationMutation = (
  { __typename?: 'Mutation' }
  & { updateKeyIncidentVideoIntegration: (
    { __typename?: 'KeyIncidentVideoIntegration' }
    & Pick<KeyIncidentVideoIntegration, 'id' | 'orgId' | 'orgName' | 'orgAbbreviation' | 'name' | 'description' | 'status' | 'queueName' | 'enableRetry' | 'maxRetries' | 'retryIntervalMinutes' | 'createdAt' | 'updatedAt'>
    & { additionalOptions: Array<(
      { __typename?: 'KeyIncidentVideoIntegrationAdditionalOption' }
      & Pick<KeyIncidentVideoIntegrationAdditionalOption, 'id' | 'name' | 'value' | 'createdAt' | 'updatedAt'>
    )> }
  ) }
);

export type DeleteKeyIncidentVideoIntegrationMutationVariables = Exact<{
  integrationId: Scalars['String'];
}>;


export type DeleteKeyIncidentVideoIntegrationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteKeyIncidentVideoIntegration'>
);

export type ListAdditionalOptionsForKeyIncidentVideoIntegrationQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  integrationId: Scalars['String'];
}>;


export type ListAdditionalOptionsForKeyIncidentVideoIntegrationQuery = (
  { __typename?: 'Query' }
  & { listAdditionalOptionsForKeyIncidentVideoIntegration: (
    { __typename?: 'KeyIncidentVideoIntegrationAdditionalOptions' }
    & { additionalOptions: Array<(
      { __typename?: 'KeyIncidentVideoIntegrationAdditionalOption' }
      & Pick<KeyIncidentVideoIntegrationAdditionalOption, 'id' | 'name' | 'value' | 'createdAt' | 'updatedAt'>
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type AddAdditionalOptionToKeyIncidentVideoIntegrationMutationVariables = Exact<{
  integrationId: Scalars['String'];
  name: Scalars['String'];
  value: Scalars['String'];
}>;


export type AddAdditionalOptionToKeyIncidentVideoIntegrationMutation = (
  { __typename?: 'Mutation' }
  & { addAdditionalOptionToKeyIncidentVideoIntegration: (
    { __typename?: 'KeyIncidentVideoIntegrationAdditionalOption' }
    & Pick<KeyIncidentVideoIntegrationAdditionalOption, 'id' | 'name' | 'value' | 'createdAt' | 'updatedAt'>
  ) }
);

export type RemoveAdditionalOptionFromKeyIncidentVideoIntegrationMutationVariables = Exact<{
  integrationId: Scalars['String'];
  additionalOptionId: Scalars['String'];
}>;


export type RemoveAdditionalOptionFromKeyIncidentVideoIntegrationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeAdditionalOptionFromKeyIncidentVideoIntegration'>
);

export type CreateLeagueMutationVariables = Exact<{
  orgId: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  abbreviation?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
}>;


export type CreateLeagueMutation = (
  { __typename?: 'Mutation' }
  & { createLeague: (
    { __typename?: 'League' }
    & Pick<League, 'id' | 'orgId' | 'name' | 'description' | 'abbreviation' | 'logo' | 'status' | 'createdAt' | 'updatedAt'>
    & { tags: Array<(
      { __typename?: 'LeagueTag' }
      & Pick<LeagueTag, 'id' | 'leagueId' | 'tag'>
    )> }
  ) }
);

export type GetLeagueByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetLeagueByIdQuery = (
  { __typename?: 'Query' }
  & { getLeagueById: (
    { __typename?: 'League' }
    & Pick<League, 'id' | 'orgId' | 'name' | 'description' | 'abbreviation' | 'logo' | 'status' | 'createdAt' | 'updatedAt'>
    & { tags: Array<(
      { __typename?: 'LeagueTag' }
      & Pick<LeagueTag, 'id' | 'leagueId' | 'tag'>
    )> }
  ) }
);

export type ListLeaguesForOrganizationQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  orgId: Scalars['String'];
  tags: Array<Scalars['String']> | Scalars['String'];
  status?: Maybe<LeagueStatuses>;
  name?: Maybe<Scalars['String']>;
}>;


export type ListLeaguesForOrganizationQuery = (
  { __typename?: 'Query' }
  & { listLeaguesForOrganization: (
    { __typename?: 'Leagues' }
    & { leagues: Array<(
      { __typename?: 'League' }
      & Pick<League, 'id' | 'orgId' | 'name' | 'description' | 'abbreviation' | 'logo' | 'status' | 'createdAt' | 'updatedAt'>
      & { tags: Array<(
        { __typename?: 'LeagueTag' }
        & Pick<LeagueTag, 'id' | 'leagueId' | 'tag'>
      )> }
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type DeleteLeagueMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteLeagueMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteLeague'>
);

export type UpdateLeagueMutationVariables = Exact<{
  id: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  abbreviation?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
}>;


export type UpdateLeagueMutation = (
  { __typename?: 'Mutation' }
  & { updateLeague: (
    { __typename?: 'League' }
    & Pick<League, 'id' | 'orgId' | 'name' | 'description' | 'abbreviation' | 'logo' | 'status' | 'createdAt' | 'updatedAt'>
    & { tags: Array<(
      { __typename?: 'LeagueTag' }
      & Pick<LeagueTag, 'id' | 'leagueId' | 'tag'>
    )> }
  ) }
);

export type AddLeagueTagMutationVariables = Exact<{
  leagueId: Scalars['String'];
  tag: Scalars['String'];
}>;


export type AddLeagueTagMutation = (
  { __typename?: 'Mutation' }
  & { addLeagueTag: (
    { __typename?: 'LeagueTag' }
    & Pick<LeagueTag, 'id' | 'leagueId' | 'tag'>
  ) }
);

export type RemoveLeagueTagMutationVariables = Exact<{
  tagId: Scalars['String'];
}>;


export type RemoveLeagueTagMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeLeagueTag'>
);

export type AddLeagueMembershipMutationVariables = Exact<{
  leagueId: Scalars['String'];
  userId: Scalars['String'];
}>;


export type AddLeagueMembershipMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addLeagueMembership'>
);

export type RemoveLeagueMembershipMutationVariables = Exact<{
  leagueId: Scalars['String'];
  userId: Scalars['String'];
}>;


export type RemoveLeagueMembershipMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeLeagueMembership'>
);

export type ListLeagueMembershipUsersQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  leagueId: Scalars['String'];
  nameOrEmail?: Maybe<Scalars['String']>;
}>;


export type ListLeagueMembershipUsersQuery = (
  { __typename?: 'Query' }
  & { listLeagueMembershipUsers: (
    { __typename?: 'LeagueMembershipUsers' }
    & Pick<LeagueMembershipUsers, 'leagueId'>
    & { leagueMemberships: Array<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'firstName' | 'lastName' | 'isAdmin' | 'status' | 'createdAt' | 'updatedAt'>
      & { organizationMemberships: Array<(
        { __typename?: 'OrganizationMembership' }
        & Pick<OrganizationMembership, 'id' | 'orgId' | 'roles' | 'permissions' | 'title'>
        & { leagueMemberships: Array<(
          { __typename?: 'OrganizationLeagueMembership' }
          & Pick<OrganizationLeagueMembership, 'leagueId' | 'name' | 'abbreviation'>
        )> }
      )> }
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type ListDynamicCompetitionSurveyIntegrationsQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  orgName?: Maybe<Scalars['String']>;
  leagueName?: Maybe<Scalars['String']>;
  seasonName?: Maybe<Scalars['String']>;
  competitionExternalId?: Maybe<Scalars['String']>;
  homeTeamName?: Maybe<Scalars['String']>;
  awayTeamName?: Maybe<Scalars['String']>;
  fromDate?: Maybe<Scalars['String']>;
  toDate?: Maybe<Scalars['String']>;
  status?: Maybe<DynamicCompetitionSurveyIntegrationStatuses>;
}>;


export type ListDynamicCompetitionSurveyIntegrationsQuery = (
  { __typename?: 'Query' }
  & { listDynamicCompetitionSurveyIntegrations: (
    { __typename?: 'DynamicCompetitionSurveyIntegrations' }
    & { integrations: Array<(
      { __typename?: 'DynamicCompetitionSurveyIntegration' }
      & Pick<DynamicCompetitionSurveyIntegration, 'id' | 'organizationId' | 'organizationName' | 'leagueId' | 'leagueName' | 'seasonId' | 'seasonName' | 'surveyName' | 'surveyAudience' | 'competitionId' | 'competitionExternalId' | 'competitionStartTime' | 'competitionTimeZone' | 'homeTeamId' | 'homeTeamName' | 'awayTeamId' | 'awayTeamName' | 'integrationStatus' | 'integrationSent' | 'integrationProcess' | 'errorCount'>
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type FindDynamicCompetitionSurveyIntegrationErrorsByUuidQueryVariables = Exact<{
  dynamicCompetitionSurveyId: Scalars['String'];
}>;


export type FindDynamicCompetitionSurveyIntegrationErrorsByUuidQuery = (
  { __typename?: 'Query' }
  & { findDynamicCompetitionSurveyIntegrationErrorsByUuid: (
    { __typename?: 'DynamicCompetitionSurveyIntegrationErrors' }
    & Pick<DynamicCompetitionSurveyIntegrationErrors, 'total'>
    & { errors: Array<(
      { __typename?: 'DynamicCompetitionSurveyIntegrationError' }
      & Pick<DynamicCompetitionSurveyIntegrationError, 'id' | 'survey' | 'error' | 'createdAt' | 'updatedAt'>
    )> }
  ) }
);

export type RetryDynamicCompetitionIntegrationSurveyMutationVariables = Exact<{
  dynamicCompetitionSurveyId: Scalars['String'];
}>;


export type RetryDynamicCompetitionIntegrationSurveyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'retryDynamicCompetitionIntegrationSurvey'>
);

export type ListCompetitionIntegrationRequestsQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  orgName?: Maybe<Scalars['String']>;
  leagueName?: Maybe<Scalars['String']>;
  seasonName?: Maybe<Scalars['String']>;
  fromDate?: Maybe<Scalars['Date']>;
  toDate?: Maybe<Scalars['Date']>;
  integrationSearch?: Maybe<Scalars['String']>;
  statuses?: Maybe<Array<CompetitionIntegrationRequestStatuses> | CompetitionIntegrationRequestStatuses>;
}>;


export type ListCompetitionIntegrationRequestsQuery = (
  { __typename?: 'Query' }
  & { listCompetitionIntegrationRequests: (
    { __typename?: 'AdminManageCompetitionIntegrationRequestSummaries' }
    & { requests: Array<(
      { __typename?: 'AdminManageCompetitionIntegrationRequestSummary' }
      & Pick<AdminManageCompetitionIntegrationRequestSummary, 'id' | 'seasonId' | 'seasonName' | 'seasonIntegrationId' | 'orgName' | 'leagueName' | 'integrationId' | 'integrationName' | 'status' | 'error' | 'totalCount' | 'processedCount' | 'errorCount' | 'successCount' | 'createdAt' | 'updatedAt'>
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type CreateMediaOrganizationMutationVariables = Exact<{
  name: Scalars['String'];
  abbreviation?: Maybe<Scalars['String']>;
  site?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
}>;


export type CreateMediaOrganizationMutation = (
  { __typename?: 'Mutation' }
  & { createMediaOrganization: (
    { __typename?: 'MediaOrganization' }
    & Pick<MediaOrganization, 'id' | 'name' | 'status' | 'abbreviation' | 'site' | 'logo' | 'createdAt' | 'updatedAt'>
  ) }
);

export type UpdateMediaOrganizationMutationVariables = Exact<{
  id: Scalars['String'];
  name: Scalars['String'];
  abbreviation?: Maybe<Scalars['String']>;
  site?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
}>;


export type UpdateMediaOrganizationMutation = (
  { __typename?: 'Mutation' }
  & { updateMediaOrganization: (
    { __typename?: 'MediaOrganization' }
    & Pick<MediaOrganization, 'id' | 'name' | 'status' | 'abbreviation' | 'site' | 'logo' | 'createdAt' | 'updatedAt'>
  ) }
);

export type DeleteMediaOrganizationMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteMediaOrganizationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteMediaOrganization'>
);

export type GetMediaOrganizationByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetMediaOrganizationByIdQuery = (
  { __typename?: 'Query' }
  & { getMediaOrganizationById: (
    { __typename?: 'MediaOrganization' }
    & Pick<MediaOrganization, 'id' | 'name' | 'status' | 'abbreviation' | 'site' | 'logo' | 'createdAt' | 'updatedAt'>
  ) }
);

export type ListMediaOrganizationsQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  status?: Maybe<MediaOrganizationStatuses>;
  name?: Maybe<Scalars['String']>;
}>;


export type ListMediaOrganizationsQuery = (
  { __typename?: 'Query' }
  & { listMediaOrganizations: (
    { __typename?: 'MediaOrganizations' }
    & { mediaOrganizations: Array<(
      { __typename?: 'MediaOrganization' }
      & Pick<MediaOrganization, 'id' | 'name' | 'status' | 'abbreviation' | 'site' | 'logo' | 'createdAt' | 'updatedAt'>
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type GetOrganizationCompetitionMetricsQueryVariables = Exact<{
  orgId: Scalars['String'];
  leagueId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
}>;


export type GetOrganizationCompetitionMetricsQuery = (
  { __typename?: 'Query' }
  & { getOrganizationCompetitionMetrics: (
    { __typename?: 'OrganizationCompetitionMetrics' }
    & Pick<OrganizationCompetitionMetrics, 'organizationId' | 'totalCompetitions' | 'competitionsThisWeek' | 'competitionsThisMonth' | 'competitionsThisYear'>
  ) }
);

export type GetOrganizationTeamCompetitionSurveyMetricsQueryVariables = Exact<{
  orgId: Scalars['String'];
  leagueId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
}>;


export type GetOrganizationTeamCompetitionSurveyMetricsQuery = (
  { __typename?: 'Query' }
  & { getOrganizationTeamCompetitionSurveyMetrics: (
    { __typename?: 'OrganizationCompetitionSurveyMetrics' }
    & Pick<OrganizationCompetitionSurveyMetrics, 'organizationId' | 'leagueId' | 'seasonId' | 'totalSurveys' | 'completedSurveys' | 'incompleteSurveys' | 'completionPercentage' | 'totalSurveysThisWeek' | 'completedSurveysThisWeek' | 'incompleteSurveysThisWeek' | 'completionPercentageThisWeek' | 'totalSurveysThisMonth' | 'completedSurveysThisMonth' | 'incompleteSurveysThisMonth' | 'completionPercentageThisMonth' | 'totalSurveysThisYear' | 'completedSurveysThisYear' | 'incompleteSurveysThisYear' | 'completionPercentageThisYear'>
  ) }
);

export type GetOrganizationOfficialCompetitionSurveyMetricsQueryVariables = Exact<{
  orgId: Scalars['String'];
  leagueId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
}>;


export type GetOrganizationOfficialCompetitionSurveyMetricsQuery = (
  { __typename?: 'Query' }
  & { getOrganizationOfficialCompetitionSurveyMetrics: (
    { __typename?: 'OrganizationCompetitionSurveyMetrics' }
    & Pick<OrganizationCompetitionSurveyMetrics, 'organizationId' | 'leagueId' | 'seasonId' | 'totalSurveys' | 'completedSurveys' | 'incompleteSurveys' | 'completionPercentage' | 'totalSurveysThisWeek' | 'completedSurveysThisWeek' | 'incompleteSurveysThisWeek' | 'completionPercentageThisWeek' | 'totalSurveysThisMonth' | 'completedSurveysThisMonth' | 'incompleteSurveysThisMonth' | 'completionPercentageThisMonth' | 'totalSurveysThisYear' | 'completedSurveysThisYear' | 'incompleteSurveysThisYear' | 'completionPercentageThisYear'>
  ) }
);

export type GetOrganizationOfficialDynamicCompetitionSurveyMetricsQueryVariables = Exact<{
  orgId: Scalars['String'];
  leagueId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
}>;


export type GetOrganizationOfficialDynamicCompetitionSurveyMetricsQuery = (
  { __typename?: 'Query' }
  & { getOrganizationOfficialDynamicCompetitionSurveyMetrics: (
    { __typename?: 'OrganizationCompetitionSurveyMetrics' }
    & Pick<OrganizationCompetitionSurveyMetrics, 'organizationId' | 'leagueId' | 'seasonId' | 'totalSurveys' | 'completedSurveys' | 'incompleteSurveys' | 'completionPercentage' | 'totalSurveysThisWeek' | 'completedSurveysThisWeek' | 'incompleteSurveysThisWeek' | 'completionPercentageThisWeek' | 'totalSurveysThisMonth' | 'completedSurveysThisMonth' | 'incompleteSurveysThisMonth' | 'completionPercentageThisMonth' | 'totalSurveysThisYear' | 'completedSurveysThisYear' | 'incompleteSurveysThisYear' | 'completionPercentageThisYear'>
  ) }
);

export type GetOrganizationKeyIncidentMetricsQueryVariables = Exact<{
  orgId: Scalars['String'];
  leagueId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
}>;


export type GetOrganizationKeyIncidentMetricsQuery = (
  { __typename?: 'Query' }
  & { getOrganizationKeyIncidentMetrics: (
    { __typename?: 'OrganizationKeyIncidentMetrics' }
    & Pick<OrganizationKeyIncidentMetrics, 'organizationId' | 'leagueId' | 'seasonId' | 'totalKeyIncidents' | 'resolved' | 'unresolved' | 'resolvedPercentage' | 'totalKeyIncidentsThisWeek' | 'resolvedThisWeek' | 'unresolvedThisWeek' | 'resolvedPercentageThisWeek' | 'totalKeyIncidentsThisMonth' | 'resolvedThisMonth' | 'unresolvedThisMonth' | 'resolvedPercentageThisMonth' | 'totalKeyIncidentsThisYear' | 'resolvedThisYear' | 'unresolvedThisYear' | 'resolvedPercentageThisYear'>
  ) }
);

export type GetOrganizationSupplementalReportMetricsQueryVariables = Exact<{
  orgId: Scalars['String'];
  leagueId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
}>;


export type GetOrganizationSupplementalReportMetricsQuery = (
  { __typename?: 'Query' }
  & { getOrganizationSupplementalReportMetrics: (
    { __typename?: 'OrganizationSupplementalReportMetrics' }
    & Pick<OrganizationSupplementalReportMetrics, 'organizationId' | 'leagueId' | 'seasonId' | 'totalSupplementalReports' | 'approved' | 'unapproved' | 'approvedPercentage' | 'totalSupplementalReportsThisWeek' | 'approvedThisWeek' | 'unapprovedThisWeek' | 'approvedPercentageThisWeek' | 'totalSupplementalReportsThisMonth' | 'approvedThisMonth' | 'unapprovedThisMonth' | 'approvedPercentageThisMonth' | 'totalSupplementalReportsThisYear' | 'approvedThisYear' | 'unapprovedThisYear' | 'approvedPercentageThisYear'>
  ) }
);

export type ListOrganizationTopOfficialsMetricsQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  orgId: Scalars['String'];
  leagueId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  assessmentObjectId?: Maybe<Scalars['String']>;
  minCompetitionCount?: Maybe<Scalars['Int']>;
}>;


export type ListOrganizationTopOfficialsMetricsQuery = (
  { __typename?: 'Query' }
  & { listOrganizationTopOfficialsMetrics: (
    { __typename?: 'OrganizationTopOfficials' }
    & { officials: Array<(
      { __typename?: 'OrganizationTopOfficial' }
      & Pick<OrganizationTopOfficial, 'assessmentObjectId' | 'assessmentObjectName' | 'officialId' | 'officialFirstName' | 'officialLastName' | 'officialProfilePic' | 'totalCompetitions' | 'totalKeyIncidents' | 'totalRatingQuestionsCount' | 'totalPoints' | 'maxPointsPossible' | 'averageCompetitionScore'>
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type CreateOfficialMutationVariables = Exact<{
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  sport: Sports;
  externalId?: Maybe<CreateOfficialExternalIdInput>;
}>;


export type CreateOfficialMutation = (
  { __typename?: 'Mutation' }
  & { createOfficial: (
    { __typename?: 'Official' }
    & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'userStatus' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
    & { externalIds?: Maybe<Array<(
      { __typename?: 'OfficialExternalId' }
      & Pick<OfficialExternalId, 'id' | 'organizationId' | 'externalId'>
    )>> }
  ) }
);

export type GetOfficialByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetOfficialByIdQuery = (
  { __typename?: 'Query' }
  & { getOfficialById: (
    { __typename?: 'Official' }
    & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'userStatus' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
    & { externalIds?: Maybe<Array<(
      { __typename?: 'OfficialExternalId' }
      & Pick<OfficialExternalId, 'id' | 'organizationId' | 'externalId'>
    )>> }
  ) }
);

export type ListOfficialsQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  sport: Sports;
  nameOrEmail?: Maybe<Scalars['String']>;
  status?: Maybe<OfficialStatuses>;
  externalId?: Maybe<Scalars['String']>;
}>;


export type ListOfficialsQuery = (
  { __typename?: 'Query' }
  & { listOfficials: (
    { __typename?: 'Officials' }
    & { officials: Array<(
      { __typename?: 'Official' }
      & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'userStatus' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
      & { externalIds?: Maybe<Array<(
        { __typename?: 'OfficialExternalId' }
        & Pick<OfficialExternalId, 'id' | 'organizationId' | 'externalId'>
      )>> }
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type DeactivateOfficialMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeactivateOfficialMutation = (
  { __typename?: 'Mutation' }
  & { deactivateOfficial: (
    { __typename?: 'Official' }
    & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'userStatus' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
    & { externalIds?: Maybe<Array<(
      { __typename?: 'OfficialExternalId' }
      & Pick<OfficialExternalId, 'id' | 'organizationId' | 'externalId'>
    )>> }
  ) }
);

export type DeleteOfficialMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteOfficialMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteOfficial'>
);

export type GetOrgOfficialSummaryByIdQueryVariables = Exact<{
  officialId: Scalars['String'];
  orgId: Scalars['String'];
  leagueId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
}>;


export type GetOrgOfficialSummaryByIdQuery = (
  { __typename?: 'Query' }
  & { getOrgOfficialSummaryById: (
    { __typename?: 'OfficialSummary' }
    & Pick<OfficialSummary, 'id' | 'externalId' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'totalCompetitions' | 'totalKeyIncidents' | 'totalRatingQuestionsCount' | 'totalPoints' | 'maxPointsPossible' | 'averageCompetitionScore'>
  ) }
);

export type ListCompetitionSummariesForOrgOfficialQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  officialId: Scalars['String'];
  orgId: Scalars['String'];
  leagueId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  leagueSearch?: Maybe<Scalars['String']>;
  seasonSearch?: Maybe<Scalars['String']>;
  assessmentObjectUuid?: Maybe<Scalars['String']>;
  teamsSearch?: Maybe<Scalars['String']>;
  dateFrom?: Maybe<Scalars['String']>;
  dateTo?: Maybe<Scalars['String']>;
}>;


export type ListCompetitionSummariesForOrgOfficialQuery = (
  { __typename?: 'Query' }
  & { listCompetitionSummariesForOrgOfficial: (
    { __typename?: 'OfficialCompetitionSummaries' }
    & { competitionSummaries: Array<(
      { __typename?: 'OfficialCompetitionSummary' }
      & Pick<OfficialCompetitionSummary, 'leagueId' | 'leagueName' | 'seasonId' | 'seasonName' | 'officialId' | 'officialEmail' | 'officialExternalId' | 'officialFirstName' | 'officialLastName' | 'officialProfilePic' | 'competitionId' | 'competitionStartTime' | 'competitionLocation' | 'homeTeamId' | 'homeTeamName' | 'homeTeamLogo' | 'homeTeamScore' | 'homeTeamTieBreakerScore' | 'awayTeamId' | 'awayTeamName' | 'awayTeamLogo' | 'awayTeamScore' | 'awayTeamTieBreakerScore' | 'organizationId' | 'assessmentObjectId' | 'assessmentObjectName' | 'impactedOutcomeCount' | 'keyIncidentsCount' | 'ratingQuestionsCount' | 'totalPoints' | 'maxPointsPossible' | 'averageCompetitionScore'>
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type ListFeedbackForOrgOfficialQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  officialId: Scalars['String'];
  orgId: Scalars['String'];
  leagueId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  submittedByTeamSearch?: Maybe<Scalars['String']>;
  dateFrom?: Maybe<Scalars['String']>;
  dateTo?: Maybe<Scalars['String']>;
}>;


export type ListFeedbackForOrgOfficialQuery = (
  { __typename?: 'Query' }
  & { listFeedbackForOrgOfficial: (
    { __typename?: 'OrgOfficialFeedbacks' }
    & { feedback: Array<(
      { __typename?: 'OrgOfficialFeedback' }
      & Pick<OrgOfficialFeedback, 'leagueId' | 'leagueName' | 'seasonId' | 'seasonName' | 'competitionId' | 'competitionStartTime' | 'competitionLocation' | 'homeTeamId' | 'homeTeamName' | 'homeTeamLogo' | 'homeTeamScore' | 'homeTeamTieBreakerScore' | 'awayTeamId' | 'awayTeamName' | 'awayTeamLogo' | 'awayTeamScore' | 'awayTeamTieBreakerScore' | 'submittedByTeamId' | 'submittedByTeamName' | 'submittedByTeamLogo' | 'assessmentObjectId' | 'assessmentObjectName' | 'feedback'>
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type GetOrganizationByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetOrganizationByIdQuery = (
  { __typename?: 'Query' }
  & { getOrganizationById: (
    { __typename?: 'Organization' }
    & Pick<Organization, 'id' | 'name' | 'sport' | 'status' | 'abbreviation' | 'site' | 'logo' | 'createdAt' | 'updatedAt'>
  ) }
);

export type UpdateOrganizationMutationVariables = Exact<{
  id: Scalars['String'];
  name: Scalars['String'];
  abbreviation: Scalars['String'];
  site?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
}>;


export type UpdateOrganizationMutation = (
  { __typename?: 'Mutation' }
  & { updateOrganization: (
    { __typename?: 'Organization' }
    & Pick<Organization, 'id' | 'name' | 'sport' | 'status' | 'abbreviation' | 'site' | 'logo' | 'createdAt' | 'updatedAt'>
  ) }
);

export type UpdateOrganizationStatusMutationVariables = Exact<{
  organizationId: Scalars['String'];
  organizationStatus: OrganizationStatuses;
}>;


export type UpdateOrganizationStatusMutation = (
  { __typename?: 'Mutation' }
  & { updateOrganizationStatus: (
    { __typename?: 'Organization' }
    & Pick<Organization, 'id' | 'name' | 'status'>
  ) }
);

export type ListOrganizationUsersQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  organizationId: Scalars['String'];
  nameOrEmail?: Maybe<Scalars['String']>;
}>;


export type ListOrganizationUsersQuery = (
  { __typename?: 'Query' }
  & { listOrganizationUsers: (
    { __typename?: 'OrganizationUsers' }
    & { users: Array<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'firstName' | 'lastName' | 'isAdmin' | 'status' | 'createdAt' | 'updatedAt'>
      & { organizationMemberships: Array<(
        { __typename?: 'OrganizationMembership' }
        & Pick<OrganizationMembership, 'id' | 'orgId' | 'roles' | 'permissions' | 'title'>
        & { leagueMemberships: Array<(
          { __typename?: 'OrganizationLeagueMembership' }
          & Pick<OrganizationLeagueMembership, 'leagueId' | 'name' | 'abbreviation' | 'logo'>
        )> }
      )> }
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type RemoveUserFromOrganizationMutationVariables = Exact<{
  organizationId: Scalars['String'];
  userId: Scalars['String'];
}>;


export type RemoveUserFromOrganizationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeUserFromOrganization'>
);

export type CreateNewOrganizationUserMutationVariables = Exact<{
  organizationId: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  roles: Array<OrganizationUserRoles> | OrganizationUserRoles;
  permissions: Array<OrganizationUserPermissions> | OrganizationUserPermissions;
  title?: Maybe<Scalars['String']>;
}>;


export type CreateNewOrganizationUserMutation = (
  { __typename?: 'Mutation' }
  & { createNewOrganizationUser: (
    { __typename?: 'CreateNewOrganizationUserResponse' }
    & Pick<CreateNewOrganizationUserResponse, 'emailSent'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'firstName' | 'lastName' | 'isAdmin' | 'status' | 'createdAt' | 'updatedAt'>
      & { organizationMemberships: Array<(
        { __typename?: 'OrganizationMembership' }
        & Pick<OrganizationMembership, 'id' | 'orgId' | 'roles' | 'permissions' | 'title'>
        & { leagueMemberships: Array<(
          { __typename?: 'OrganizationLeagueMembership' }
          & Pick<OrganizationLeagueMembership, 'leagueId' | 'name' | 'abbreviation' | 'logo'>
        )> }
      )> }
    ) }
  ) }
);

export type AddUserToOrganizationMutationVariables = Exact<{
  organizationId: Scalars['String'];
  email: Scalars['String'];
  roles: Array<OrganizationUserRoles> | OrganizationUserRoles;
  permissions: Array<OrganizationUserPermissions> | OrganizationUserPermissions;
  title?: Maybe<Scalars['String']>;
}>;


export type AddUserToOrganizationMutation = (
  { __typename?: 'Mutation' }
  & { addUserToOrganization: (
    { __typename?: 'AddUserToOrganizationResponse' }
    & Pick<AddUserToOrganizationResponse, 'emailSent'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'firstName' | 'lastName' | 'isAdmin' | 'status' | 'createdAt' | 'updatedAt'>
      & { organizationMemberships: Array<(
        { __typename?: 'OrganizationMembership' }
        & Pick<OrganizationMembership, 'id' | 'orgId' | 'roles' | 'permissions' | 'title'>
        & { leagueMemberships: Array<(
          { __typename?: 'OrganizationLeagueMembership' }
          & Pick<OrganizationLeagueMembership, 'leagueId' | 'name' | 'abbreviation' | 'logo'>
        )> }
      )> }
    ) }
  ) }
);

export type UpdateUserOrganizationMembershipMutationVariables = Exact<{
  organizationId: Scalars['String'];
  userId: Scalars['String'];
  roles: Array<OrganizationUserRoles> | OrganizationUserRoles;
  permissions: Array<OrganizationUserPermissions> | OrganizationUserPermissions;
  title?: Maybe<Scalars['String']>;
}>;


export type UpdateUserOrganizationMembershipMutation = (
  { __typename?: 'Mutation' }
  & { updateUserOrganizationMembership: (
    { __typename?: 'OrganizationMembership' }
    & Pick<OrganizationMembership, 'id' | 'orgId' | 'roles' | 'permissions' | 'title'>
    & { leagueMemberships: Array<(
      { __typename?: 'OrganizationLeagueMembership' }
      & Pick<OrganizationLeagueMembership, 'leagueId' | 'name' | 'abbreviation' | 'logo'>
    )> }
  ) }
);

export type GetUserOrganizationMembershipQueryVariables = Exact<{
  organizationId: Scalars['String'];
  userId: Scalars['String'];
}>;


export type GetUserOrganizationMembershipQuery = (
  { __typename?: 'Query' }
  & { getUserOrganizationMembership: (
    { __typename?: 'OrganizationMembership' }
    & Pick<OrganizationMembership, 'id' | 'orgId' | 'roles' | 'permissions' | 'title'>
    & { leagueMemberships: Array<(
      { __typename?: 'OrganizationLeagueMembership' }
      & Pick<OrganizationLeagueMembership, 'leagueId' | 'name' | 'abbreviation' | 'logo'>
    )> }
  ) }
);

export type ListOfficialAssessmentObjectsForOrganizationQueryVariables = Exact<{
  organizationId: Scalars['String'];
  leagueId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
}>;


export type ListOfficialAssessmentObjectsForOrganizationQuery = (
  { __typename?: 'Query' }
  & { listOfficialAssessmentObjectsForOrganization: Array<(
    { __typename?: 'AssessmentObject' }
    & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'name' | 'createdAt' | 'updatedAt'>
  )> }
);

export type ListFeaturesForOrganizationQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  organizationId: Scalars['String'];
  feature?: Maybe<Scalars['String']>;
}>;


export type ListFeaturesForOrganizationQuery = (
  { __typename?: 'Query' }
  & { listFeaturesForOrganization: (
    { __typename?: 'OrganizationFeatures' }
    & Pick<OrganizationFeatures, 'orgId' | 'orgName'>
    & { features: Array<(
      { __typename?: 'OrganizationFeature' }
      & Pick<OrganizationFeature, 'id' | 'feature' | 'active' | 'createdAt' | 'updatedAt'>
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type ListContactsForOrganizationQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  organizationId: Scalars['String'];
  search?: Maybe<Scalars['String']>;
  activeOnly?: Maybe<Scalars['Boolean']>;
}>;


export type ListContactsForOrganizationQuery = (
  { __typename?: 'Query' }
  & { listContactsForOrganization: (
    { __typename?: 'OrganizationContacts' }
    & Pick<OrganizationContacts, 'orgId' | 'orgName'>
    & { contacts: Array<(
      { __typename?: 'OrganizationContact' }
      & Pick<OrganizationContact, 'id' | 'firstName' | 'lastName' | 'isActive' | 'email' | 'phone' | 'createdAt' | 'updatedAt'>
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type CreateOrganizationContactMutationVariables = Exact<{
  organizationId: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
}>;


export type CreateOrganizationContactMutation = (
  { __typename?: 'Mutation' }
  & { createOrganizationContact: (
    { __typename?: 'OrganizationContact' }
    & Pick<OrganizationContact, 'id' | 'firstName' | 'lastName' | 'isActive' | 'email' | 'phone' | 'createdAt' | 'updatedAt'>
  ) }
);

export type UpdateOrganizationContactMutationVariables = Exact<{
  organizationId: Scalars['String'];
  contactId: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
}>;


export type UpdateOrganizationContactMutation = (
  { __typename?: 'Mutation' }
  & { updateOrganizationContact: (
    { __typename?: 'OrganizationContact' }
    & Pick<OrganizationContact, 'id' | 'firstName' | 'lastName' | 'isActive' | 'email' | 'phone' | 'createdAt' | 'updatedAt'>
  ) }
);

export type DeleteOrganizationContactMutationVariables = Exact<{
  organizationId: Scalars['String'];
  contactId: Scalars['String'];
}>;


export type DeleteOrganizationContactMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteOrganizationContact'>
);

export type ListTeamParticipationSummariesForOrgQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  orgId: Scalars['String'];
  leagueId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  descending?: Maybe<Scalars['Boolean']>;
  leagueName?: Maybe<Scalars['String']>;
  seasonName?: Maybe<Scalars['String']>;
  teamNameOrAbbreviation?: Maybe<Scalars['String']>;
  fromDate?: Maybe<Scalars['String']>;
  toDate?: Maybe<Scalars['String']>;
  status?: Maybe<SurveyParticipationStatusesFilter>;
}>;


export type ListTeamParticipationSummariesForOrgQuery = (
  { __typename?: 'Query' }
  & { listTeamParticipationSummariesForOrg: (
    { __typename?: 'OrganizationTeamParticipationSummaries' }
    & { participationSummaries: Array<(
      { __typename?: 'OrganizationTeamParticipationSummary' }
      & Pick<OrganizationTeamParticipationSummary, 'organizationId' | 'organizationName' | 'leagueId' | 'leagueName' | 'seasonId' | 'seasonName' | 'teamId' | 'teamName' | 'teamLogo' | 'competitionSurveyCount' | 'notStartedCompetitionSurveyCount' | 'incompleteCompetitionSurveyCount' | 'completedCompetitionSurveyCount' | 'completionCompetitionSurveyPercentage'>
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type ListTeamSurveyParticipationForOrgQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  orgId: Scalars['String'];
  teamId: Scalars['String'];
  status: SurveyParticipationStatuses;
  recipientStatus: SurveyRecipientParticipationStatuses;
  leagueId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  fromDate?: Maybe<Scalars['String']>;
  toDate?: Maybe<Scalars['String']>;
  leagueName?: Maybe<Scalars['String']>;
  seasonName?: Maybe<Scalars['String']>;
  teamNameOrAbbreviation?: Maybe<Scalars['String']>;
  userStatus?: Maybe<UserStatuses>;
  coachStatus?: Maybe<CoachStatuses>;
  surveyName?: Maybe<Scalars['String']>;
}>;


export type ListTeamSurveyParticipationForOrgQuery = (
  { __typename?: 'Query' }
  & { listTeamSurveyParticipationForOrg: (
    { __typename?: 'OrganizationTeamSurveyParticipations' }
    & { participations: Array<(
      { __typename?: 'OrganizationTeamSurveyParticipation' }
      & Pick<OrganizationTeamSurveyParticipation, 'id' | 'organizationId' | 'organizationName' | 'leagueId' | 'leagueName' | 'seasonId' | 'seasonName' | 'teamId' | 'teamName' | 'teamLogo' | 'competitionId' | 'externalId' | 'startTime' | 'timeZone' | 'location' | 'homeTeamId' | 'homeTeamName' | 'homeTeamLogo' | 'homeTeamScore' | 'homeTeamTieBreakerScore' | 'awayTeamId' | 'awayTeamName' | 'awayTeamLogo' | 'awayTeamScore' | 'awayTeamTieBreakerScore' | 'competitionSurveyId' | 'surveyId' | 'surveyName' | 'finished' | 'completedDate' | 'lastNotificationSent' | 'lastNotificationTypeSent' | 'lastEmailSent' | 'tookSurvey' | 'userId' | 'userFirstName' | 'userLastName' | 'userStatus' | 'userEmail' | 'coachId' | 'coachType' | 'coachRole' | 'coachStatus'>
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type ListOfficialParticipationSummariesForOrgQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  orgId: Scalars['String'];
  leagueId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  descending?: Maybe<Scalars['Boolean']>;
  leagueName?: Maybe<Scalars['String']>;
  seasonName?: Maybe<Scalars['String']>;
  teamNameOrAbbreviation?: Maybe<Scalars['String']>;
  fromDate?: Maybe<Scalars['String']>;
  toDate?: Maybe<Scalars['String']>;
  status?: Maybe<SurveyParticipationStatusesFilter>;
}>;


export type ListOfficialParticipationSummariesForOrgQuery = (
  { __typename?: 'Query' }
  & { listOfficialParticipationSummariesForOrg: (
    { __typename?: 'OrganizationOfficialParticipationSummaries' }
    & { participationSummaries: Array<(
      { __typename?: 'OrganizationOfficialParticipationSummary' }
      & Pick<OrganizationOfficialParticipationSummary, 'organizationId' | 'organizationName' | 'leagueId' | 'leagueName' | 'seasonId' | 'seasonName' | 'competitionId' | 'externalId' | 'startTime' | 'timeZone' | 'location' | 'homeTeamId' | 'homeTeamName' | 'homeTeamLogo' | 'homeTeamScore' | 'homeTeamTieBreakerScore' | 'awayTeamId' | 'awayTeamName' | 'awayTeamLogo' | 'awayTeamScore' | 'awayTeamTieBreakerScore' | 'competitionSurveyCount' | 'notStartedCompetitionSurveyCount' | 'incompleteCompetitionSurveyCount' | 'completedCompetitionSurveyCount' | 'completionCompetitionSurveyPercentage'>
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type ListOfficialSurveyParticipationForOrgQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  orgId: Scalars['String'];
  competitionId: Scalars['String'];
  status: SurveyParticipationStatuses;
  recipientStatus: SurveyRecipientParticipationStatuses;
  leagueId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  fromDate?: Maybe<Scalars['String']>;
  toDate?: Maybe<Scalars['String']>;
  leagueName?: Maybe<Scalars['String']>;
  seasonName?: Maybe<Scalars['String']>;
  teamNameOrAbbreviation?: Maybe<Scalars['String']>;
  userStatus?: Maybe<UserStatuses>;
  officialStatus?: Maybe<OfficialStatuses>;
  surveyName?: Maybe<Scalars['String']>;
  officialNameOrEmail?: Maybe<Scalars['String']>;
}>;


export type ListOfficialSurveyParticipationForOrgQuery = (
  { __typename?: 'Query' }
  & { listOfficialSurveyParticipationForOrg: (
    { __typename?: 'OrganizationOfficialSurveyParticipations' }
    & { participations: Array<(
      { __typename?: 'OrganizationOfficialSurveyParticipation' }
      & Pick<OrganizationOfficialSurveyParticipation, 'id' | 'organizationId' | 'organizationName' | 'leagueId' | 'leagueName' | 'seasonId' | 'seasonName' | 'competitionId' | 'externalId' | 'startTime' | 'timeZone' | 'location' | 'homeTeamId' | 'homeTeamName' | 'homeTeamLogo' | 'homeTeamScore' | 'homeTeamTieBreakerScore' | 'awayTeamId' | 'awayTeamName' | 'awayTeamLogo' | 'awayTeamScore' | 'awayTeamTieBreakerScore' | 'competitionSurveyId' | 'surveyId' | 'surveyName' | 'isDynamicSurvey' | 'finished' | 'completedDate' | 'lastNotificationSent' | 'lastNotificationTypeSent' | 'lastEmailSent' | 'tookSurvey' | 'userId' | 'userFirstName' | 'userLastName' | 'userStatus' | 'userEmail' | 'competitionOfficialId' | 'officialId' | 'officialStatus' | 'assessmentObjectId' | 'assessmentObjectName'>
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type ResendCompetitionSurveyNotificationEmailMutationVariables = Exact<{
  orgId: Scalars['String'];
  competitionSurveyId: Scalars['String'];
  userId: Scalars['String'];
  reminderType: EmailSurveyReminderType;
}>;


export type ResendCompetitionSurveyNotificationEmailMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'resendCompetitionSurveyNotificationEmail'>
);

export type ResendDynamicCompetitionSurveyNotificationEmailMutationVariables = Exact<{
  orgId: Scalars['String'];
  dynamicCompetitionSurveyId: Scalars['String'];
  userId: Scalars['String'];
  reminderType: EmailSurveyReminderType;
}>;


export type ResendDynamicCompetitionSurveyNotificationEmailMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'resendDynamicCompetitionSurveyNotificationEmail'>
);

export type RemoveCompetitionSurveyParticipationMutationVariables = Exact<{
  orgId: Scalars['String'];
  competitionSurveyId: Scalars['String'];
  userId: Scalars['String'];
}>;


export type RemoveCompetitionSurveyParticipationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeCompetitionSurveyParticipation'>
);

export type ResetTookCompetitionSurveyMutationVariables = Exact<{
  orgId: Scalars['String'];
  competitionSurveyId: Scalars['String'];
}>;


export type ResetTookCompetitionSurveyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'resetTookCompetitionSurvey'>
);

export type ReopenCompetitionSurveyMutationVariables = Exact<{
  orgId: Scalars['String'];
  competitionSurveyId: Scalars['String'];
}>;


export type ReopenCompetitionSurveyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'reopenCompetitionSurvey'>
);

export type ResetTookDynamicCompetitionSurveyMutationVariables = Exact<{
  orgId: Scalars['String'];
  dynamicCompetitionSurveyId: Scalars['String'];
}>;


export type ResetTookDynamicCompetitionSurveyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'resetTookDynamicCompetitionSurvey'>
);

export type ReopenDynamicCompetitionSurveyMutationVariables = Exact<{
  orgId: Scalars['String'];
  dynamicCompetitionSurveyId: Scalars['String'];
}>;


export type ReopenDynamicCompetitionSurveyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'reopenDynamicCompetitionSurvey'>
);

export type CreateSeasonMutationVariables = Exact<{
  leagueId: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
}>;


export type CreateSeasonMutation = (
  { __typename?: 'Mutation' }
  & { createSeason: (
    { __typename?: 'Season' }
    & Pick<Season, 'id' | 'leagueId' | 'leagueName' | 'leagueLogo' | 'teamKitEnabled' | 'competitionIntegrationEnabled' | 'mediaMembersEnabled' | 'name' | 'description' | 'status' | 'startDate' | 'endDate' | 'createdAt' | 'updatedAt'>
  ) }
);

export type GetSeasonByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetSeasonByIdQuery = (
  { __typename?: 'Query' }
  & { getSeasonById: (
    { __typename?: 'Season' }
    & Pick<Season, 'id' | 'leagueId' | 'leagueName' | 'leagueLogo' | 'teamKitEnabled' | 'competitionIntegrationEnabled' | 'mediaMembersEnabled' | 'name' | 'description' | 'status' | 'startDate' | 'endDate' | 'createdAt' | 'updatedAt'>
  ) }
);

export type ListSeasonsForLeagueQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  leagueId: Scalars['String'];
  status?: Maybe<SeasonStatuses>;
  name?: Maybe<Scalars['String']>;
}>;


export type ListSeasonsForLeagueQuery = (
  { __typename?: 'Query' }
  & { listSeasonsForLeague: (
    { __typename?: 'Seasons' }
    & { seasons: Array<(
      { __typename?: 'Season' }
      & Pick<Season, 'id' | 'leagueId' | 'leagueName' | 'leagueLogo' | 'teamKitEnabled' | 'competitionIntegrationEnabled' | 'mediaMembersEnabled' | 'name' | 'description' | 'status' | 'startDate' | 'endDate' | 'createdAt' | 'updatedAt'>
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type DeleteSeasonMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteSeasonMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSeason'>
);

export type UpdateSeasonMutationVariables = Exact<{
  id: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
}>;


export type UpdateSeasonMutation = (
  { __typename?: 'Mutation' }
  & { updateSeason: (
    { __typename?: 'Season' }
    & Pick<Season, 'id' | 'leagueId' | 'leagueName' | 'leagueLogo' | 'teamKitEnabled' | 'competitionIntegrationEnabled' | 'mediaMembersEnabled' | 'name' | 'description' | 'status' | 'startDate' | 'endDate' | 'createdAt' | 'updatedAt'>
  ) }
);

export type ActivateSeasonMutationVariables = Exact<{
  id: Scalars['String'];
  sendCoachEmails: Scalars['Boolean'];
  sendOfficialEmails: Scalars['Boolean'];
}>;


export type ActivateSeasonMutation = (
  { __typename?: 'Mutation' }
  & { activateSeason: (
    { __typename?: 'Season' }
    & Pick<Season, 'id' | 'leagueId' | 'leagueName' | 'leagueLogo' | 'teamKitEnabled' | 'competitionIntegrationEnabled' | 'mediaMembersEnabled' | 'name' | 'description' | 'status' | 'startDate' | 'endDate' | 'createdAt' | 'updatedAt'>
  ) }
);

export type DeactivateSeasonMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeactivateSeasonMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deactivateSeason'>
);

export type GetSeasonSetupInfoQueryVariables = Exact<{
  seasonId: Scalars['String'];
}>;


export type GetSeasonSetupInfoQuery = (
  { __typename?: 'Query' }
  & { getSeasonSetupInfo: (
    { __typename?: 'SeasonSetupInfo' }
    & Pick<SeasonSetupInfo, 'seasonId' | 'leagueId' | 'leagueName' | 'name' | 'status' | 'periodCount' | 'divisionCount' | 'divisionsWithoutTeamsCount' | 'teamsWithoutDivisionsCount' | 'competitionOfficialCount' | 'teamCount' | 'coachCount' | 'officialCount' | 'surveyCount' | 'competitionCount' | 'leagueMembershipCount'>
  ) }
);

export type ListTeamsForSeasonQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  seasonId: Scalars['String'];
  search?: Maybe<Scalars['String']>;
  countryCodes?: Maybe<Array<CountryCodes> | CountryCodes>;
  classifications?: Maybe<Array<TeamClassifications> | TeamClassifications>;
  ageGroup?: Maybe<Scalars['String']>;
  parentOrganizationName?: Maybe<Scalars['String']>;
}>;


export type ListTeamsForSeasonQuery = (
  { __typename?: 'Query' }
  & { listTeamsForSeason: (
    { __typename?: 'SeasonTeams' }
    & Pick<SeasonTeams, 'seasonId'>
    & { teams: Array<(
      { __typename?: 'SeasonTeam' }
      & Pick<SeasonTeam, 'id' | 'seasonId'>
      & { team: (
        { __typename?: 'Team' }
        & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'classification' | 'gender' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'timeZone' | 'website' | 'logo' | 'createdAt' | 'updatedAt'>
      ) }
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type RemoveTeamFromSeasonMutationVariables = Exact<{
  seasonId: Scalars['String'];
  teamId: Scalars['String'];
}>;


export type RemoveTeamFromSeasonMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeTeamFromSeason'>
);

export type ListCoachesForSeasonQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  seasonId: Scalars['String'];
  search?: Maybe<Scalars['String']>;
  type?: Maybe<CoachTypes>;
  status?: Maybe<CoachStatuses>;
  role?: Maybe<Scalars['String']>;
  team?: Maybe<Scalars['String']>;
  userStatus?: Maybe<UserStatuses>;
}>;


export type ListCoachesForSeasonQuery = (
  { __typename?: 'Query' }
  & { listCoachesForSeason: (
    { __typename?: 'SeasonCoaches' }
    & Pick<SeasonCoaches, 'seasonId'>
    & { coaches: Array<(
      { __typename?: 'Coach' }
      & Pick<Coach, 'id' | 'teamId' | 'teamName' | 'teamAbbreviation' | 'sport' | 'teamCity' | 'userId' | 'type' | 'role' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'userStatus' | 'createdAt' | 'updatedAt'>
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type CreateNewSeasonCoachMutationVariables = Exact<{
  seasonId: Scalars['String'];
  teamId: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  type: CoachTypes;
  status: CoachStatuses;
  addToOpenSurveys: Scalars['Boolean'];
  role?: Maybe<Scalars['String']>;
}>;


export type CreateNewSeasonCoachMutation = (
  { __typename?: 'Mutation' }
  & { createNewSeasonCoach: (
    { __typename?: 'Coach' }
    & Pick<Coach, 'id' | 'teamId' | 'teamName' | 'teamAbbreviation' | 'sport' | 'teamCity' | 'userId' | 'type' | 'role' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'userStatus' | 'createdAt' | 'updatedAt'>
  ) }
);

export type ListOfficialsForSeasonQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  seasonId: Scalars['String'];
  nameOrEmail?: Maybe<Scalars['String']>;
  status?: Maybe<Array<OfficialStatuses> | OfficialStatuses>;
  userStatus?: Maybe<Array<UserStatuses> | UserStatuses>;
  externalId?: Maybe<Scalars['String']>;
}>;


export type ListOfficialsForSeasonQuery = (
  { __typename?: 'Query' }
  & { listOfficialsForSeason: (
    { __typename?: 'SeasonOfficials' }
    & Pick<SeasonOfficials, 'seasonId'>
    & { officials: Array<(
      { __typename?: 'SeasonOfficial' }
      & Pick<SeasonOfficial, 'id' | 'seasonId'>
      & { official: (
        { __typename?: 'Official' }
        & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'userStatus' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
        & { externalIds?: Maybe<Array<(
          { __typename?: 'OfficialExternalId' }
          & Pick<OfficialExternalId, 'id' | 'organizationId' | 'externalId'>
        )>> }
      ) }
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type CreateNewOfficialForSeasonMutationVariables = Exact<{
  seasonId: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  sendEmail: Scalars['Boolean'];
  externalId?: Maybe<Scalars['String']>;
}>;


export type CreateNewOfficialForSeasonMutation = (
  { __typename?: 'Mutation' }
  & { createNewOfficialForSeason: (
    { __typename?: 'SeasonOfficialAdded' }
    & Pick<SeasonOfficialAdded, 'emailSent' | 'newUser'>
    & { seasonOfficial: (
      { __typename?: 'SeasonOfficial' }
      & Pick<SeasonOfficial, 'id' | 'seasonId'>
      & { official: (
        { __typename?: 'Official' }
        & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
        & { externalIds?: Maybe<Array<(
          { __typename?: 'OfficialExternalId' }
          & Pick<OfficialExternalId, 'id' | 'organizationId' | 'externalId'>
        )>> }
      ) }
    ) }
  ) }
);

export type AddOfficialToSeasonMutationVariables = Exact<{
  seasonId: Scalars['String'];
  officialId: Scalars['String'];
  sendEmail: Scalars['Boolean'];
}>;


export type AddOfficialToSeasonMutation = (
  { __typename?: 'Mutation' }
  & { addOfficialToSeason: (
    { __typename?: 'SeasonOfficial' }
    & Pick<SeasonOfficial, 'id' | 'seasonId'>
    & { official: (
      { __typename?: 'Official' }
      & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
      & { externalIds?: Maybe<Array<(
        { __typename?: 'OfficialExternalId' }
        & Pick<OfficialExternalId, 'id' | 'organizationId' | 'externalId'>
      )>> }
    ) }
  ) }
);

export type RemoveOfficialFromSeasonMutationVariables = Exact<{
  seasonId: Scalars['String'];
  officialId: Scalars['String'];
}>;


export type RemoveOfficialFromSeasonMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeOfficialFromSeason'>
);

export type InviteSeasonOfficialUserMutationVariables = Exact<{
  seasonId: Scalars['String'];
  officialId: Scalars['String'];
}>;


export type InviteSeasonOfficialUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'inviteSeasonOfficialUser'>
);

export type AddOfficialAssessmentObjectToSeasonMutationVariables = Exact<{
  seasonId: Scalars['String'];
  assessmentObjectId: Scalars['String'];
  order: Scalars['Int'];
  isOptional: Scalars['Boolean'];
}>;


export type AddOfficialAssessmentObjectToSeasonMutation = (
  { __typename?: 'Mutation' }
  & { addOfficialAssessmentObjectToSeason: (
    { __typename?: 'SeasonOfficialAssessmentObject' }
    & Pick<SeasonOfficialAssessmentObject, 'id' | 'seasonId' | 'order' | 'isOptional'>
    & { assessmentObject: (
      { __typename?: 'AssessmentObject' }
      & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name' | 'createdAt' | 'updatedAt'>
    ) }
  ) }
);

export type SetIsOptionalForSeasonOfficialAssessmentObjectMutationVariables = Exact<{
  id: Scalars['String'];
  isOptional: Scalars['Boolean'];
}>;


export type SetIsOptionalForSeasonOfficialAssessmentObjectMutation = (
  { __typename?: 'Mutation' }
  & { setIsOptionalForSeasonOfficialAssessmentObject: (
    { __typename?: 'SeasonOfficialAssessmentObject' }
    & Pick<SeasonOfficialAssessmentObject, 'id' | 'seasonId' | 'order' | 'isOptional'>
    & { assessmentObject: (
      { __typename?: 'AssessmentObject' }
      & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name' | 'createdAt' | 'updatedAt'>
    ) }
  ) }
);

export type ListOfficialAssessmentObjectsForSeasonQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  seasonId: Scalars['String'];
  name?: Maybe<Scalars['String']>;
}>;


export type ListOfficialAssessmentObjectsForSeasonQuery = (
  { __typename?: 'Query' }
  & { listOfficialAssessmentObjectsForSeason: (
    { __typename?: 'SeasonOfficialAssessmentObjects' }
    & Pick<SeasonOfficialAssessmentObjects, 'seasonId'>
    & { assessmentObjects: Array<(
      { __typename?: 'SeasonOfficialAssessmentObject' }
      & Pick<SeasonOfficialAssessmentObject, 'id' | 'seasonId' | 'order' | 'isOptional'>
      & { assessmentObject: (
        { __typename?: 'AssessmentObject' }
        & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name' | 'createdAt' | 'updatedAt'>
      ) }
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type RemoveOfficialAssessmentObjectFromSeasonMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RemoveOfficialAssessmentObjectFromSeasonMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeOfficialAssessmentObjectFromSeason'>
);

export type ListAvailableAssessmentObjectsForSeasonQueryVariables = Exact<{
  seasonId: Scalars['String'];
}>;


export type ListAvailableAssessmentObjectsForSeasonQuery = (
  { __typename?: 'Query' }
  & { listAvailableAssessmentObjectsForSeason: (
    { __typename?: 'AssessmentObjectsForSeason' }
    & Pick<AssessmentObjectsForSeason, 'seasonId'>
    & { assessmentObjects: Array<(
      { __typename?: 'AssessmentObject' }
      & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name' | 'createdAt' | 'updatedAt'>
    )> }
  ) }
);

export type AddPeriodToSeasonMutationVariables = Exact<{
  seasonId: Scalars['String'];
  periodId: Scalars['String'];
  order: Scalars['Int'];
}>;


export type AddPeriodToSeasonMutation = (
  { __typename?: 'Mutation' }
  & { addPeriodToSeason: (
    { __typename?: 'SeasonPeriod' }
    & Pick<SeasonPeriod, 'id' | 'seasonId' | 'order'>
    & { period: (
      { __typename?: 'Period' }
      & Pick<Period, 'id' | 'sport' | 'name' | 'createdAt' | 'updatedAt'>
    ) }
  ) }
);

export type ListSeasonPeriodsForSeasonQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  seasonId: Scalars['String'];
  name?: Maybe<Scalars['String']>;
}>;


export type ListSeasonPeriodsForSeasonQuery = (
  { __typename?: 'Query' }
  & { listSeasonPeriodsForSeason: (
    { __typename?: 'SeasonPeriods' }
    & Pick<SeasonPeriods, 'seasonId'>
    & { periods: Array<(
      { __typename?: 'SeasonPeriod' }
      & Pick<SeasonPeriod, 'id' | 'seasonId' | 'order'>
      & { period: (
        { __typename?: 'Period' }
        & Pick<Period, 'id' | 'sport' | 'name' | 'createdAt' | 'updatedAt'>
      ) }
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type RemoveSeasonPeriodFromSeasonMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RemoveSeasonPeriodFromSeasonMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeSeasonPeriodFromSeason'>
);

export type ListAvailablePeriodsForSeasonQueryVariables = Exact<{
  seasonId: Scalars['String'];
}>;


export type ListAvailablePeriodsForSeasonQuery = (
  { __typename?: 'Query' }
  & { listAvailablePeriodsForSeason: (
    { __typename?: 'PeriodsForSeason' }
    & Pick<PeriodsForSeason, 'seasonId'>
    & { periods: Array<(
      { __typename?: 'Period' }
      & Pick<Period, 'id' | 'sport' | 'name' | 'createdAt' | 'updatedAt'>
    )> }
  ) }
);

export type CreateSeasonDivisionMutationVariables = Exact<{
  seasonId: Scalars['String'];
  name: Scalars['String'];
}>;


export type CreateSeasonDivisionMutation = (
  { __typename?: 'Mutation' }
  & { createSeasonDivision: (
    { __typename?: 'SeasonDivision' }
    & Pick<SeasonDivision, 'id' | 'seasonId' | 'name'>
    & { aliases: Array<(
      { __typename?: 'SeasonDivisionAlias' }
      & Pick<SeasonDivisionAlias, 'id' | 'seasonDivisionId' | 'alias'>
    )> }
  ) }
);

export type ListSeasonDivisionsForSeasonQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  seasonId: Scalars['String'];
  name?: Maybe<Scalars['String']>;
}>;


export type ListSeasonDivisionsForSeasonQuery = (
  { __typename?: 'Query' }
  & { listSeasonDivisionsForSeason: (
    { __typename?: 'SeasonDivisions' }
    & Pick<SeasonDivisions, 'seasonId'>
    & { seasonDivisions: Array<(
      { __typename?: 'SeasonDivision' }
      & Pick<SeasonDivision, 'id' | 'seasonId' | 'name'>
      & { aliases: Array<(
        { __typename?: 'SeasonDivisionAlias' }
        & Pick<SeasonDivisionAlias, 'id' | 'seasonDivisionId' | 'alias'>
      )> }
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type GetSeasonDivisionByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetSeasonDivisionByIdQuery = (
  { __typename?: 'Query' }
  & { getSeasonDivisionById: (
    { __typename?: 'SeasonDivision' }
    & Pick<SeasonDivision, 'id' | 'seasonId' | 'name'>
    & { aliases: Array<(
      { __typename?: 'SeasonDivisionAlias' }
      & Pick<SeasonDivisionAlias, 'id' | 'seasonDivisionId' | 'alias'>
    )> }
  ) }
);

export type UpdateSeasonDivisionMutationVariables = Exact<{
  id: Scalars['String'];
  name: Scalars['String'];
}>;


export type UpdateSeasonDivisionMutation = (
  { __typename?: 'Mutation' }
  & { updateSeasonDivision: (
    { __typename?: 'SeasonDivision' }
    & Pick<SeasonDivision, 'id' | 'seasonId' | 'name'>
    & { aliases: Array<(
      { __typename?: 'SeasonDivisionAlias' }
      & Pick<SeasonDivisionAlias, 'id' | 'seasonDivisionId' | 'alias'>
    )> }
  ) }
);

export type DeleteSeasonDivisionMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteSeasonDivisionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSeasonDivision'>
);

export type CreateSeasonDivisionAliasMutationVariables = Exact<{
  seasonDivisionId: Scalars['String'];
  alias: Scalars['String'];
}>;


export type CreateSeasonDivisionAliasMutation = (
  { __typename?: 'Mutation' }
  & { createSeasonDivisionAlias: (
    { __typename?: 'SeasonDivisionAlias' }
    & Pick<SeasonDivisionAlias, 'id' | 'seasonDivisionId' | 'alias'>
  ) }
);

export type ListSeasonDivisionAliasesQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  seasonDivisionId: Scalars['String'];
  alias?: Maybe<Scalars['String']>;
}>;


export type ListSeasonDivisionAliasesQuery = (
  { __typename?: 'Query' }
  & { listSeasonDivisionAliases: (
    { __typename?: 'SeasonDivisionAliases' }
    & Pick<SeasonDivisionAliases, 'seasonDivisionId'>
    & { aliases: Array<(
      { __typename?: 'SeasonDivisionAlias' }
      & Pick<SeasonDivisionAlias, 'id' | 'seasonDivisionId' | 'alias'>
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type UpdateSeasonDivisionAliasMutationVariables = Exact<{
  id: Scalars['String'];
  alias: Scalars['String'];
}>;


export type UpdateSeasonDivisionAliasMutation = (
  { __typename?: 'Mutation' }
  & { updateSeasonDivisionAlias: (
    { __typename?: 'SeasonDivisionAlias' }
    & Pick<SeasonDivisionAlias, 'id' | 'seasonDivisionId' | 'alias'>
  ) }
);

export type DeleteSeasonDivisionAliasMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteSeasonDivisionAliasMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSeasonDivisionAlias'>
);

export type ListSeasonTeamsForSeasonDivisionQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  seasonId: Scalars['String'];
  seasonDivisionId?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  countryCodes?: Maybe<Array<CountryCodes> | CountryCodes>;
  classifications?: Maybe<Array<TeamClassifications> | TeamClassifications>;
  ageGroup?: Maybe<Scalars['String']>;
  parentOrganizationName?: Maybe<Scalars['String']>;
}>;


export type ListSeasonTeamsForSeasonDivisionQuery = (
  { __typename?: 'Query' }
  & { listSeasonTeamsForSeasonDivision: (
    { __typename?: 'SeasonDivisionTeams' }
    & Pick<SeasonDivisionTeams, 'seasonId'>
    & { seasonDivisionTeams: Array<(
      { __typename?: 'SeasonDivisionTeam' }
      & Pick<SeasonDivisionTeam, 'id' | 'seasonId'>
      & { seasonDivision: (
        { __typename?: 'SeasonDivision' }
        & Pick<SeasonDivision, 'id' | 'seasonId' | 'name'>
        & { aliases: Array<(
          { __typename?: 'SeasonDivisionAlias' }
          & Pick<SeasonDivisionAlias, 'id' | 'seasonDivisionId' | 'alias'>
        )> }
      ), seasonTeam: (
        { __typename?: 'SeasonTeam' }
        & Pick<SeasonTeam, 'id' | 'seasonId'>
        & { team: (
          { __typename?: 'Team' }
          & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'timeZone' | 'website' | 'logo' | 'classification' | 'gender' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'createdAt' | 'updatedAt'>
        ) }
      ) }
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type ListSeasonTeamsAvailableForSeasonDivisionQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  seasonId: Scalars['String'];
  seasonDivisionId: Scalars['String'];
  search?: Maybe<Scalars['String']>;
}>;


export type ListSeasonTeamsAvailableForSeasonDivisionQuery = (
  { __typename?: 'Query' }
  & { listSeasonTeamsAvailableForSeasonDivision: (
    { __typename?: 'SeasonTeams' }
    & Pick<SeasonTeams, 'seasonId'>
    & { teams: Array<(
      { __typename?: 'SeasonTeam' }
      & Pick<SeasonTeam, 'id' | 'seasonId'>
      & { team: (
        { __typename?: 'Team' }
        & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'timeZone' | 'website' | 'logo' | 'classification' | 'gender' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'createdAt' | 'updatedAt'>
      ) }
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type AddSeasonTeamToSeasonDivisionMutationVariables = Exact<{
  seasonId: Scalars['String'];
  seasonDivisionId: Scalars['String'];
  seasonTeamId: Scalars['String'];
}>;


export type AddSeasonTeamToSeasonDivisionMutation = (
  { __typename?: 'Mutation' }
  & { addSeasonTeamToSeasonDivision: (
    { __typename?: 'SeasonDivisionTeam' }
    & Pick<SeasonDivisionTeam, 'id' | 'seasonId'>
    & { seasonDivision: (
      { __typename?: 'SeasonDivision' }
      & Pick<SeasonDivision, 'id' | 'seasonId' | 'name'>
      & { aliases: Array<(
        { __typename?: 'SeasonDivisionAlias' }
        & Pick<SeasonDivisionAlias, 'id' | 'seasonDivisionId' | 'alias'>
      )> }
    ), seasonTeam: (
      { __typename?: 'SeasonTeam' }
      & Pick<SeasonTeam, 'id' | 'seasonId'>
      & { team: (
        { __typename?: 'Team' }
        & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'timeZone' | 'website' | 'logo' | 'classification' | 'gender' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'createdAt' | 'updatedAt'>
      ) }
    ) }
  ) }
);

export type DeleteSeasonDivisionTeamMutationVariables = Exact<{
  seasonId: Scalars['String'];
  seasonDivisionTeamId: Scalars['String'];
}>;


export type DeleteSeasonDivisionTeamMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSeasonDivisionTeam'>
);

export type UpdateTeamKitEnabledForSeasonMutationVariables = Exact<{
  seasonId: Scalars['String'];
  enabled: Scalars['Boolean'];
}>;


export type UpdateTeamKitEnabledForSeasonMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateTeamKitEnabledForSeason'>
);

export type FindSeasonTeamKitForSeasonQueryVariables = Exact<{
  orgId: Scalars['String'];
  seasonId: Scalars['String'];
}>;


export type FindSeasonTeamKitForSeasonQuery = (
  { __typename?: 'Query' }
  & { findSeasonTeamKitForSeason?: Maybe<(
    { __typename?: 'SeasonTeamKit' }
    & Pick<SeasonTeamKit, 'orgId' | 'seasonId' | 'id' | 'status' | 'sendPreCompetitionDays' | 'sendReminders' | 'sendReminderDays' | 'sendReminderCutoffDays' | 'activatedAt' | 'createdAt' | 'updatedAt'>
  )> }
);

export type GetSeasonTeamKitByIdQueryVariables = Exact<{
  seasonTeamKitId: Scalars['String'];
}>;


export type GetSeasonTeamKitByIdQuery = (
  { __typename?: 'Query' }
  & { getSeasonTeamKitById?: Maybe<(
    { __typename?: 'SeasonTeamKit' }
    & Pick<SeasonTeamKit, 'orgId' | 'seasonId' | 'id' | 'status' | 'sendPreCompetitionDays' | 'sendReminders' | 'sendReminderDays' | 'sendReminderCutoffDays' | 'activatedAt' | 'createdAt' | 'updatedAt'>
  )> }
);

export type CreateSeasonTeamKitMutationVariables = Exact<{
  orgId: Scalars['String'];
  seasonId: Scalars['String'];
  sendPreCompetitionDays: Scalars['Int'];
  sendReminders: Scalars['Boolean'];
  sendReminderDays: Scalars['Int'];
  sendReminderCutoffDays: Scalars['Int'];
}>;


export type CreateSeasonTeamKitMutation = (
  { __typename?: 'Mutation' }
  & { createSeasonTeamKit: (
    { __typename?: 'SeasonTeamKit' }
    & Pick<SeasonTeamKit, 'orgId' | 'seasonId' | 'id' | 'status' | 'sendPreCompetitionDays' | 'sendReminders' | 'sendReminderDays' | 'sendReminderCutoffDays' | 'activatedAt' | 'createdAt' | 'updatedAt'>
  ) }
);

export type UpdateSeasonTeamKitMutationVariables = Exact<{
  orgId: Scalars['String'];
  seasonId: Scalars['String'];
  seasonTeamKitId: Scalars['String'];
  sendPreCompetitionDays: Scalars['Int'];
  sendReminders: Scalars['Boolean'];
  sendReminderDays: Scalars['Int'];
  sendReminderCutoffDays: Scalars['Int'];
}>;


export type UpdateSeasonTeamKitMutation = (
  { __typename?: 'Mutation' }
  & { updateSeasonTeamKit: (
    { __typename?: 'SeasonTeamKit' }
    & Pick<SeasonTeamKit, 'orgId' | 'seasonId' | 'id' | 'status' | 'sendPreCompetitionDays' | 'sendReminders' | 'sendReminderDays' | 'sendReminderCutoffDays' | 'activatedAt' | 'createdAt' | 'updatedAt'>
  ) }
);

export type ListSeasonTeamKitRolesQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  seasonId: Scalars['String'];
  type?: Maybe<TeamKitRoleType>;
}>;


export type ListSeasonTeamKitRolesQuery = (
  { __typename?: 'Query' }
  & { listSeasonTeamKitRoles: (
    { __typename?: 'SeasonTeamKitRoles' }
    & Pick<SeasonTeamKitRoles, 'seasonId'>
    & { kitRoles: Array<(
      { __typename?: 'SeasonTeamKitRole' }
      & Pick<SeasonTeamKitRole, 'id' | 'value' | 'type' | 'order'>
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type CreateSeasonTeamKitRoleMutationVariables = Exact<{
  seasonId: Scalars['String'];
  value: Scalars['String'];
  type: TeamKitRoleType;
}>;


export type CreateSeasonTeamKitRoleMutation = (
  { __typename?: 'Mutation' }
  & { createSeasonTeamKitRole: (
    { __typename?: 'SeasonTeamKitRole' }
    & Pick<SeasonTeamKitRole, 'id' | 'value' | 'type' | 'order'>
  ) }
);

export type UpdateSeasonTeamKitRoleMutationVariables = Exact<{
  seasonId: Scalars['String'];
  kitRoleId: Scalars['String'];
  value: Scalars['String'];
  type: TeamKitRoleType;
}>;


export type UpdateSeasonTeamKitRoleMutation = (
  { __typename?: 'Mutation' }
  & { updateSeasonTeamKitRole: (
    { __typename?: 'SeasonTeamKitRole' }
    & Pick<SeasonTeamKitRole, 'id' | 'value' | 'type' | 'order'>
  ) }
);

export type DeleteSeasonTeamKitRoleMutationVariables = Exact<{
  seasonId: Scalars['String'];
  kitRoleId: Scalars['String'];
}>;


export type DeleteSeasonTeamKitRoleMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSeasonTeamKitRole'>
);

export type ListSeasonTeamKitApparelDefinitionsQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  seasonId: Scalars['String'];
}>;


export type ListSeasonTeamKitApparelDefinitionsQuery = (
  { __typename?: 'Query' }
  & { listSeasonTeamKitApparelDefinitions: (
    { __typename?: 'SeasonTeamKitApparelDefinitions' }
    & Pick<SeasonTeamKitApparelDefinitions, 'seasonId'>
    & { definitions: Array<(
      { __typename?: 'SeasonTeamKitApparelDefinition' }
      & Pick<SeasonTeamKitApparelDefinition, 'id' | 'value' | 'order'>
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type CreateSeasonTeamKitApparelDefinitionMutationVariables = Exact<{
  seasonId: Scalars['String'];
  value: Scalars['String'];
}>;


export type CreateSeasonTeamKitApparelDefinitionMutation = (
  { __typename?: 'Mutation' }
  & { createSeasonTeamKitApparelDefinition: (
    { __typename?: 'SeasonTeamKitApparelDefinition' }
    & Pick<SeasonTeamKitApparelDefinition, 'id' | 'value' | 'order'>
  ) }
);

export type UpdateSeasonTeamKitApparelDefinitionMutationVariables = Exact<{
  seasonId: Scalars['String'];
  definitionId: Scalars['String'];
  value: Scalars['String'];
}>;


export type UpdateSeasonTeamKitApparelDefinitionMutation = (
  { __typename?: 'Mutation' }
  & { updateSeasonTeamKitApparelDefinition: (
    { __typename?: 'SeasonTeamKitApparelDefinition' }
    & Pick<SeasonTeamKitApparelDefinition, 'id' | 'value' | 'order'>
  ) }
);

export type DeleteSeasonTeamKitApparelDefinitionMutationVariables = Exact<{
  seasonId: Scalars['String'];
  definitionId: Scalars['String'];
}>;


export type DeleteSeasonTeamKitApparelDefinitionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSeasonTeamKitApparelDefinition'>
);

export type ListSeasonTeamKitContactDefinitionsQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  seasonId: Scalars['String'];
  type?: Maybe<TeamKitContactType>;
}>;


export type ListSeasonTeamKitContactDefinitionsQuery = (
  { __typename?: 'Query' }
  & { listSeasonTeamKitContactDefinitions: (
    { __typename?: 'SeasonTeamKitContactDefinitions' }
    & Pick<SeasonTeamKitContactDefinitions, 'seasonId'>
    & { definitions: Array<(
      { __typename?: 'SeasonTeamKitContactDefinition' }
      & Pick<SeasonTeamKitContactDefinition, 'id' | 'value' | 'order' | 'type'>
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type CreateSeasonTeamKitContactDefinitionMutationVariables = Exact<{
  seasonId: Scalars['String'];
  value: Scalars['String'];
  type: TeamKitContactType;
}>;


export type CreateSeasonTeamKitContactDefinitionMutation = (
  { __typename?: 'Mutation' }
  & { createSeasonTeamKitContactDefinition: (
    { __typename?: 'SeasonTeamKitContactDefinition' }
    & Pick<SeasonTeamKitContactDefinition, 'id' | 'value' | 'order' | 'type'>
  ) }
);

export type UpdateSeasonTeamKitContactDefinitionMutationVariables = Exact<{
  seasonId: Scalars['String'];
  contactDefinitionId: Scalars['String'];
  value: Scalars['String'];
  type: TeamKitContactType;
}>;


export type UpdateSeasonTeamKitContactDefinitionMutation = (
  { __typename?: 'Mutation' }
  & { updateSeasonTeamKitContactDefinition: (
    { __typename?: 'SeasonTeamKitContactDefinition' }
    & Pick<SeasonTeamKitContactDefinition, 'id' | 'value' | 'order' | 'type'>
  ) }
);

export type DeleteSeasonTeamKitContactDefinitionMutationVariables = Exact<{
  seasonId: Scalars['String'];
  contactDefinitionId: Scalars['String'];
}>;


export type DeleteSeasonTeamKitContactDefinitionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSeasonTeamKitContactDefinition'>
);

export type ListSeasonTeamKitContactOptionsQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  seasonId: Scalars['String'];
  contactDefinitionId: Scalars['String'];
  teamId?: Maybe<Scalars['String']>;
}>;


export type ListSeasonTeamKitContactOptionsQuery = (
  { __typename?: 'Query' }
  & { listSeasonTeamKitContactOptions: (
    { __typename?: 'SeasonTeamKitContactOptions' }
    & Pick<SeasonTeamKitContactOptions, 'seasonId'>
    & { options: Array<(
      { __typename?: 'SeasonTeamKitContactOption' }
      & Pick<SeasonTeamKitContactOption, 'id' | 'seasonId' | 'definitionId' | 'orgContactId' | 'teamContactId' | 'isDefault' | 'contact' | 'email' | 'phone' | 'firstName' | 'lastName'>
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type CreateSeasonTeamKitContactOptionMutationVariables = Exact<{
  seasonId: Scalars['String'];
  contactDefinitionId: Scalars['String'];
  isDefault: Scalars['Boolean'];
  orgContactId?: Maybe<Scalars['String']>;
  teamContactId?: Maybe<Scalars['String']>;
  teamId?: Maybe<Scalars['String']>;
}>;


export type CreateSeasonTeamKitContactOptionMutation = (
  { __typename?: 'Mutation' }
  & { createSeasonTeamKitContactOption: (
    { __typename?: 'SeasonTeamKitContactOption' }
    & Pick<SeasonTeamKitContactOption, 'id' | 'seasonId' | 'definitionId' | 'orgContactId' | 'teamContactId' | 'isDefault' | 'contact' | 'email' | 'phone' | 'firstName' | 'lastName'>
  ) }
);

export type SetDefaultSeasonTeamKitContactOptionMutationVariables = Exact<{
  seasonId: Scalars['String'];
  contactOptionId: Scalars['String'];
  isDefault: Scalars['Boolean'];
  teamId?: Maybe<Scalars['String']>;
}>;


export type SetDefaultSeasonTeamKitContactOptionMutation = (
  { __typename?: 'Mutation' }
  & { setDefaultSeasonTeamKitContactOption: (
    { __typename?: 'SeasonTeamKitContactOption' }
    & Pick<SeasonTeamKitContactOption, 'id' | 'seasonId' | 'definitionId' | 'orgContactId' | 'teamContactId' | 'isDefault' | 'contact' | 'email' | 'phone' | 'firstName' | 'lastName'>
  ) }
);

export type DeleteSeasonTeamKitContactOptionMutationVariables = Exact<{
  seasonId: Scalars['String'];
  contactOptionId: Scalars['String'];
  teamId?: Maybe<Scalars['String']>;
}>;


export type DeleteSeasonTeamKitContactOptionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSeasonTeamKitContactOption'>
);

export type ListTeamKitBroadcastOptionsForSeasonQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  seasonId: Scalars['String'];
  teamId?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
}>;


export type ListTeamKitBroadcastOptionsForSeasonQuery = (
  { __typename?: 'Query' }
  & { listTeamKitBroadcastOptionsForSeason: (
    { __typename?: 'SeasonTeamKitBroadcastOptions' }
    & Pick<SeasonTeamKitBroadcastOptions, 'seasonId'>
    & { broadcastOptions: Array<(
      { __typename?: 'SeasonTeamKitBroadcastOption' }
      & Pick<SeasonTeamKitBroadcastOption, 'id' | 'seasonId' | 'teamId' | 'teamName' | 'value' | 'isActive'>
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type CreateTeamKitBroadcastOptionForSeasonMutationVariables = Exact<{
  seasonId: Scalars['String'];
  teamId?: Maybe<Scalars['String']>;
  value: Scalars['String'];
  isActive: Scalars['Boolean'];
}>;


export type CreateTeamKitBroadcastOptionForSeasonMutation = (
  { __typename?: 'Mutation' }
  & { createTeamKitBroadcastOptionForSeason: (
    { __typename?: 'SeasonTeamKitBroadcastOption' }
    & Pick<SeasonTeamKitBroadcastOption, 'id' | 'seasonId' | 'teamId' | 'teamName' | 'value' | 'isActive'>
  ) }
);

export type UpdateTeamKitBroadcastOptionForSeasonMutationVariables = Exact<{
  seasonId: Scalars['String'];
  teamId?: Maybe<Scalars['String']>;
  optionId: Scalars['String'];
  value: Scalars['String'];
  isActive: Scalars['Boolean'];
}>;


export type UpdateTeamKitBroadcastOptionForSeasonMutation = (
  { __typename?: 'Mutation' }
  & { updateTeamKitBroadcastOptionForSeason: (
    { __typename?: 'SeasonTeamKitBroadcastOption' }
    & Pick<SeasonTeamKitBroadcastOption, 'id' | 'seasonId' | 'teamId' | 'teamName' | 'value' | 'isActive'>
  ) }
);

export type DeleteTeamKitBroadcastOptionForSeasonMutationVariables = Exact<{
  seasonId: Scalars['String'];
  teamId?: Maybe<Scalars['String']>;
  optionId: Scalars['String'];
}>;


export type DeleteTeamKitBroadcastOptionForSeasonMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteTeamKitBroadcastOptionForSeason'>
);

export type ListTeamKitSeasonApparelsQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  seasonId: Scalars['String'];
  kitRoleId: Scalars['String'];
  kitApparelId: Scalars['String'];
  teamId?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
}>;


export type ListTeamKitSeasonApparelsQuery = (
  { __typename?: 'Query' }
  & { listTeamKitSeasonApparels: (
    { __typename?: 'TeamKitSeasonApparels' }
    & Pick<TeamKitSeasonApparels, 'seasonId' | 'teamId'>
    & { apparels: Array<(
      { __typename?: 'TeamKitSeasonApparel' }
      & Pick<TeamKitSeasonApparel, 'seasonId' | 'teamId' | 'teamName' | 'id' | 'isActive' | 'name' | 'color' | 'imageUrl'>
      & { kitApparel: (
        { __typename?: 'SeasonTeamKitApparelDefinition' }
        & Pick<SeasonTeamKitApparelDefinition, 'id' | 'value' | 'order'>
      ), kitRole: (
        { __typename?: 'SeasonTeamKitRole' }
        & Pick<SeasonTeamKitRole, 'id' | 'value' | 'type' | 'order'>
      ) }
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type UpdateTeamKitSeasonApparelMutationVariables = Exact<{
  seasonId: Scalars['String'];
  kitRoleId: Scalars['String'];
  kitApparelId: Scalars['String'];
  teamId?: Maybe<Scalars['String']>;
  seasonApparelId: Scalars['String'];
  name: Scalars['String'];
  color: Scalars['String'];
  isActive: Scalars['Boolean'];
}>;


export type UpdateTeamKitSeasonApparelMutation = (
  { __typename?: 'Mutation' }
  & { updateTeamKitSeasonApparel: (
    { __typename?: 'TeamKitSeasonApparel' }
    & Pick<TeamKitSeasonApparel, 'seasonId' | 'teamId' | 'teamName' | 'id' | 'isActive' | 'name' | 'color' | 'imageUrl'>
    & { kitApparel: (
      { __typename?: 'SeasonTeamKitApparelDefinition' }
      & Pick<SeasonTeamKitApparelDefinition, 'id' | 'value' | 'order'>
    ), kitRole: (
      { __typename?: 'SeasonTeamKitRole' }
      & Pick<SeasonTeamKitRole, 'id' | 'value' | 'type' | 'order'>
    ) }
  ) }
);

export type DeleteTeamKitSeasonApparelMutationVariables = Exact<{
  seasonId: Scalars['String'];
  kitRoleId: Scalars['String'];
  kitApparelId: Scalars['String'];
  seasonApparelId: Scalars['String'];
  teamId?: Maybe<Scalars['String']>;
}>;


export type DeleteTeamKitSeasonApparelMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteTeamKitSeasonApparel'>
);

export type ListTeamKitSeasonKitsQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  seasonId: Scalars['String'];
  kitRoleId: Scalars['String'];
  type?: Maybe<TeamKitType>;
  teamId?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
}>;


export type ListTeamKitSeasonKitsQuery = (
  { __typename?: 'Query' }
  & { listTeamKitSeasonKits: (
    { __typename?: 'TeamKitSeasonKits' }
    & Pick<TeamKitSeasonKits, 'seasonId'>
    & { kits: Array<(
      { __typename?: 'TeamKitSeasonKit' }
      & Pick<TeamKitSeasonKit, 'seasonId' | 'teamId' | 'teamName' | 'roleId' | 'role' | 'definitionId' | 'definition' | 'id' | 'type'>
      & { apparel: (
        { __typename?: 'TeamKitSeasonApparel' }
        & Pick<TeamKitSeasonApparel, 'seasonId' | 'teamId' | 'teamName' | 'id' | 'isActive' | 'name' | 'color' | 'imageUrl'>
      ) }
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type CreateTeamKitSeasonKitMutationVariables = Exact<{
  seasonId: Scalars['String'];
  kitRoleId: Scalars['String'];
  definitionId: Scalars['String'];
  teamId?: Maybe<Scalars['String']>;
  apparelId: Scalars['String'];
  type: TeamKitType;
}>;


export type CreateTeamKitSeasonKitMutation = (
  { __typename?: 'Mutation' }
  & { createTeamKitSeasonKit: (
    { __typename?: 'TeamKitSeasonKit' }
    & Pick<TeamKitSeasonKit, 'seasonId' | 'teamId' | 'teamName' | 'roleId' | 'role' | 'definitionId' | 'definition' | 'id' | 'type'>
    & { apparel: (
      { __typename?: 'TeamKitSeasonApparel' }
      & Pick<TeamKitSeasonApparel, 'seasonId' | 'teamId' | 'teamName' | 'id' | 'isActive' | 'name' | 'color' | 'imageUrl'>
    ) }
  ) }
);

export type UpdateTeamKitSeasonKitMutationVariables = Exact<{
  seasonId: Scalars['String'];
  kitRoleId: Scalars['String'];
  definitionId: Scalars['String'];
  teamId?: Maybe<Scalars['String']>;
  apparelId: Scalars['String'];
  seasonKitId: Scalars['String'];
  type: TeamKitType;
}>;


export type UpdateTeamKitSeasonKitMutation = (
  { __typename?: 'Mutation' }
  & { updateTeamKitSeasonKit: (
    { __typename?: 'TeamKitSeasonKit' }
    & Pick<TeamKitSeasonKit, 'seasonId' | 'teamId' | 'teamName' | 'roleId' | 'role' | 'definitionId' | 'definition' | 'id' | 'type'>
    & { apparel: (
      { __typename?: 'TeamKitSeasonApparel' }
      & Pick<TeamKitSeasonApparel, 'seasonId' | 'teamId' | 'teamName' | 'id' | 'isActive' | 'name' | 'color' | 'imageUrl'>
    ) }
  ) }
);

export type DeleteTeamKitSeasonKitMutationVariables = Exact<{
  seasonId: Scalars['String'];
  seasonKitId: Scalars['String'];
  teamId?: Maybe<Scalars['String']>;
}>;


export type DeleteTeamKitSeasonKitMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteTeamKitSeasonKit'>
);

export type ListSeasonTeamKitOrgRecipientsQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  orgId: Scalars['String'];
  seasonId: Scalars['String'];
}>;


export type ListSeasonTeamKitOrgRecipientsQuery = (
  { __typename?: 'Query' }
  & { listSeasonTeamKitOrgRecipients: (
    { __typename?: 'SeasonTeamKitOrgRecipients' }
    & Pick<SeasonTeamKitOrgRecipients, 'seasonId'>
    & { recipients: Array<(
      { __typename?: 'SeasonTeamKitOrgRecipient' }
      & Pick<SeasonTeamKitOrgRecipient, 'id' | 'SeasonId' | 'orgMembershipId' | 'orgId' | 'orgName' | 'orgAbbreviation' | 'orgLogo' | 'isActive' | 'title' | 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic'>
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type AddSeasonTeamKitOrgRecipientMutationVariables = Exact<{
  orgId: Scalars['String'];
  seasonId: Scalars['String'];
  orgMembershipId: Scalars['String'];
}>;


export type AddSeasonTeamKitOrgRecipientMutation = (
  { __typename?: 'Mutation' }
  & { addSeasonTeamKitOrgRecipient: (
    { __typename?: 'SeasonTeamKitOrgRecipient' }
    & Pick<SeasonTeamKitOrgRecipient, 'id' | 'SeasonId' | 'orgMembershipId' | 'orgId' | 'orgName' | 'orgAbbreviation' | 'orgLogo' | 'isActive' | 'title' | 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic'>
  ) }
);

export type ActivateSeasonTeamKitOrgRecipientMutationVariables = Exact<{
  orgId: Scalars['String'];
  seasonId: Scalars['String'];
  orgRecipientId: Scalars['String'];
  isActive: Scalars['Boolean'];
}>;


export type ActivateSeasonTeamKitOrgRecipientMutation = (
  { __typename?: 'Mutation' }
  & { activateSeasonTeamKitOrgRecipient: (
    { __typename?: 'SeasonTeamKitOrgRecipient' }
    & Pick<SeasonTeamKitOrgRecipient, 'id' | 'SeasonId' | 'orgMembershipId' | 'orgId' | 'orgName' | 'orgAbbreviation' | 'orgLogo' | 'isActive' | 'title' | 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic'>
  ) }
);

export type DeleteSeasonTeamKitOrgRecipientMutationVariables = Exact<{
  orgId: Scalars['String'];
  seasonId: Scalars['String'];
  orgRecipientId: Scalars['String'];
}>;


export type DeleteSeasonTeamKitOrgRecipientMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSeasonTeamKitOrgRecipient'>
);

export type GetTeamKitOrgSetupSummaryForSeasonQueryVariables = Exact<{
  seasonId: Scalars['String'];
}>;


export type GetTeamKitOrgSetupSummaryForSeasonQuery = (
  { __typename?: 'Query' }
  & { getTeamKitOrgSetupSummaryForSeason: (
    { __typename?: 'TeamKitSeasonOrgSetupSummary' }
    & Pick<TeamKitSeasonOrgSetupSummary, 'orgId' | 'orgName' | 'orgLogo' | 'leagueId' | 'leagueName' | 'seasonId' | 'seasonName' | 'orgRecipientsCount' | 'orgContactsCount' | 'orgContactOptionsCount' | 'orgContactOptionsTarget' | 'orgApparelSetCount' | 'orgTotalApparelSetTarget'>
    & { apparelsForRoles?: Maybe<Array<(
      { __typename?: 'TeamKitSeasonOrgApparelByRoleSetupSummary' }
      & Pick<TeamKitSeasonOrgApparelByRoleSetupSummary, 'seasonId' | 'seasonName' | 'roleId' | 'role' | 'roleOrder' | 'definitionId' | 'definition' | 'definitionOrder' | 'apparelCount'>
    )>> }
  ) }
);

export type ActivateTeamKitForSeasonMutationVariables = Exact<{
  orgId: Scalars['String'];
  seasonId: Scalars['String'];
  seasonTeamKitId: Scalars['String'];
  sendTeamEmails: Scalars['Boolean'];
}>;


export type ActivateTeamKitForSeasonMutation = (
  { __typename?: 'Mutation' }
  & { activateTeamKitForSeason: (
    { __typename?: 'SeasonTeamKitActivation' }
    & Pick<SeasonTeamKitActivation, 'seasonId' | 'errors'>
    & { teamKit: (
      { __typename?: 'SeasonTeamKit' }
      & Pick<SeasonTeamKit, 'orgId' | 'seasonId' | 'id' | 'status' | 'sendPreCompetitionDays' | 'sendReminders' | 'sendReminderDays' | 'sendReminderCutoffDays' | 'activatedAt' | 'createdAt' | 'updatedAt'>
    ) }
  ) }
);

export type DeactivateTeamKitForSeasonMutationVariables = Exact<{
  orgId: Scalars['String'];
  seasonId: Scalars['String'];
  seasonTeamKitId: Scalars['String'];
}>;


export type DeactivateTeamKitForSeasonMutation = (
  { __typename?: 'Mutation' }
  & { deactivateTeamKitForSeason: (
    { __typename?: 'SeasonTeamKit' }
    & Pick<SeasonTeamKit, 'orgId' | 'seasonId' | 'id' | 'status' | 'sendPreCompetitionDays' | 'sendReminders' | 'sendReminderDays' | 'sendReminderCutoffDays' | 'activatedAt' | 'createdAt' | 'updatedAt'>
  ) }
);

export type ListAvailableCompetitionIntegrationsForSeasonInputQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  orgId: Scalars['String'];
  seasonId: Scalars['String'];
  nameSearch?: Maybe<Scalars['String']>;
}>;


export type ListAvailableCompetitionIntegrationsForSeasonInputQuery = (
  { __typename?: 'Query' }
  & { listAvailableCompetitionIntegrationsForSeasonInput: (
    { __typename?: 'CompetitionIntegrations' }
    & { integrations: Array<(
      { __typename?: 'CompetitionIntegration' }
      & Pick<CompetitionIntegration, 'id' | 'orgId' | 'orgName' | 'orgAbbreviation' | 'name' | 'description' | 'status' | 'queueName' | 'createdAt' | 'updatedAt'>
      & { additionalOptions: Array<(
        { __typename?: 'CompetitionIntegrationAdditionalOption' }
        & Pick<CompetitionIntegrationAdditionalOption, 'id' | 'name' | 'isRequired'>
      )> }
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type ListSeasonCompetitionIntegrationsForSeasonInputQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  orgId: Scalars['String'];
  seasonId: Scalars['String'];
}>;


export type ListSeasonCompetitionIntegrationsForSeasonInputQuery = (
  { __typename?: 'Query' }
  & { listSeasonCompetitionIntegrationsForSeasonInput?: Maybe<(
    { __typename?: 'SeasonCompetitionIntegrations' }
    & { seasonIntegrations: Array<(
      { __typename?: 'SeasonCompetitionIntegration' }
      & Pick<SeasonCompetitionIntegration, 'id' | 'seasonId' | 'seasonName' | 'status' | 'externalIdOrg' | 'lastRan' | 'fromDateMinutes' | 'toDateMinutes'>
      & { integration: (
        { __typename?: 'CompetitionIntegration' }
        & Pick<CompetitionIntegration, 'id' | 'orgId' | 'orgName' | 'orgAbbreviation' | 'name' | 'description' | 'status' | 'queueName' | 'createdAt' | 'updatedAt'>
        & { additionalOptions: Array<(
          { __typename?: 'CompetitionIntegrationAdditionalOption' }
          & Pick<CompetitionIntegrationAdditionalOption, 'id' | 'name' | 'isRequired'>
        )> }
      ), seasonOptions: Array<(
        { __typename?: 'SeasonCompetitionIntegrationOption' }
        & Pick<SeasonCompetitionIntegrationOption, 'id' | 'name' | 'value' | 'isRequired'>
      )> }
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  )> }
);

export type UpdateCompetitionIntegrationEnabledForSeasonMutationVariables = Exact<{
  seasonId: Scalars['String'];
  enabled: Scalars['Boolean'];
}>;


export type UpdateCompetitionIntegrationEnabledForSeasonMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateCompetitionIntegrationEnabledForSeason'>
);

export type CreateSeasonCompetitionIntegrationMutationVariables = Exact<{
  orgId: Scalars['String'];
  seasonId: Scalars['String'];
  integrationId: Scalars['String'];
}>;


export type CreateSeasonCompetitionIntegrationMutation = (
  { __typename?: 'Mutation' }
  & { createSeasonCompetitionIntegration: (
    { __typename?: 'SeasonCompetitionIntegration' }
    & Pick<SeasonCompetitionIntegration, 'id' | 'seasonId' | 'seasonName' | 'status' | 'externalIdOrg' | 'lastRan' | 'fromDateMinutes' | 'toDateMinutes'>
    & { integration: (
      { __typename?: 'CompetitionIntegration' }
      & Pick<CompetitionIntegration, 'id' | 'orgId' | 'orgName' | 'orgAbbreviation' | 'name' | 'description' | 'status' | 'queueName' | 'createdAt' | 'updatedAt'>
      & { additionalOptions: Array<(
        { __typename?: 'CompetitionIntegrationAdditionalOption' }
        & Pick<CompetitionIntegrationAdditionalOption, 'id' | 'name' | 'isRequired' | 'createdAt' | 'updatedAt'>
      )> }
    ), seasonOptions: Array<(
      { __typename?: 'SeasonCompetitionIntegrationOption' }
      & Pick<SeasonCompetitionIntegrationOption, 'id' | 'name' | 'value' | 'isRequired'>
    )> }
  ) }
);

export type GetSeasonCompetitionIntegrationByIdQueryVariables = Exact<{
  integrationId: Scalars['String'];
}>;


export type GetSeasonCompetitionIntegrationByIdQuery = (
  { __typename?: 'Query' }
  & { getSeasonCompetitionIntegrationById: (
    { __typename?: 'SeasonCompetitionIntegration' }
    & Pick<SeasonCompetitionIntegration, 'id' | 'seasonId' | 'seasonName' | 'status' | 'externalIdOrg' | 'lastRan' | 'fromDateMinutes' | 'toDateMinutes'>
    & { integration: (
      { __typename?: 'CompetitionIntegration' }
      & Pick<CompetitionIntegration, 'id' | 'orgId' | 'orgName' | 'orgAbbreviation' | 'name' | 'description' | 'status' | 'queueName' | 'createdAt' | 'updatedAt'>
      & { additionalOptions: Array<(
        { __typename?: 'CompetitionIntegrationAdditionalOption' }
        & Pick<CompetitionIntegrationAdditionalOption, 'id' | 'name' | 'isRequired'>
      )> }
    ), seasonOptions: Array<(
      { __typename?: 'SeasonCompetitionIntegrationOption' }
      & Pick<SeasonCompetitionIntegrationOption, 'id' | 'name' | 'value' | 'isRequired'>
    )> }
  ) }
);

export type ListOptionsForSeasonCompetitionIntegrationQueryVariables = Exact<{
  seasonIntegrationId: Scalars['String'];
}>;


export type ListOptionsForSeasonCompetitionIntegrationQuery = (
  { __typename?: 'Query' }
  & { listOptionsForSeasonCompetitionIntegration: Array<(
    { __typename?: 'SeasonCompetitionIntegrationOption' }
    & Pick<SeasonCompetitionIntegrationOption, 'id' | 'name' | 'value' | 'isRequired'>
  )> }
);

export type DeleteSeasonCompetitionIntegrationMutationVariables = Exact<{
  seasonIntegrationId: Scalars['String'];
}>;


export type DeleteSeasonCompetitionIntegrationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSeasonCompetitionIntegration'>
);

export type UpdateSeasonCompetitionIntegrationMutationVariables = Exact<{
  seasonIntegrationId: Scalars['String'];
  fromDateMinutes?: Maybe<Scalars['Int']>;
  toDateMinutes?: Maybe<Scalars['Int']>;
  externalIdOrg?: Maybe<Scalars['String']>;
}>;


export type UpdateSeasonCompetitionIntegrationMutation = (
  { __typename?: 'Mutation' }
  & { updateSeasonCompetitionIntegration: (
    { __typename?: 'SeasonCompetitionIntegration' }
    & Pick<SeasonCompetitionIntegration, 'id' | 'seasonId' | 'seasonName' | 'status' | 'externalIdOrg' | 'lastRan' | 'fromDateMinutes' | 'toDateMinutes'>
    & { integration: (
      { __typename?: 'CompetitionIntegration' }
      & Pick<CompetitionIntegration, 'id' | 'orgId' | 'orgName' | 'orgAbbreviation' | 'name' | 'description' | 'status' | 'queueName' | 'createdAt' | 'updatedAt'>
      & { additionalOptions: Array<(
        { __typename?: 'CompetitionIntegrationAdditionalOption' }
        & Pick<CompetitionIntegrationAdditionalOption, 'id' | 'name' | 'isRequired' | 'createdAt' | 'updatedAt'>
      )> }
    ), seasonOptions: Array<(
      { __typename?: 'SeasonCompetitionIntegrationOption' }
      & Pick<SeasonCompetitionIntegrationOption, 'id' | 'name' | 'value' | 'isRequired'>
    )> }
  ) }
);

export type UpdateSeasonCompetitionIntegrationOptionMutationVariables = Exact<{
  optionId: Scalars['String'];
  value?: Maybe<Scalars['String']>;
}>;


export type UpdateSeasonCompetitionIntegrationOptionMutation = (
  { __typename?: 'Mutation' }
  & { updateSeasonCompetitionIntegrationOption: (
    { __typename?: 'SeasonCompetitionIntegrationOption' }
    & Pick<SeasonCompetitionIntegrationOption, 'id' | 'name' | 'value' | 'isRequired'>
  ) }
);

export type ActivateSeasonCompetitionIntegrationMutationVariables = Exact<{
  integrationId: Scalars['String'];
}>;


export type ActivateSeasonCompetitionIntegrationMutation = (
  { __typename?: 'Mutation' }
  & { activateSeasonCompetitionIntegration: (
    { __typename?: 'SeasonCompetitionIntegration' }
    & Pick<SeasonCompetitionIntegration, 'id' | 'seasonId' | 'seasonName' | 'status' | 'externalIdOrg' | 'lastRan' | 'fromDateMinutes' | 'toDateMinutes'>
    & { integration: (
      { __typename?: 'CompetitionIntegration' }
      & Pick<CompetitionIntegration, 'id' | 'orgId' | 'orgName' | 'orgAbbreviation' | 'name' | 'description' | 'status' | 'queueName' | 'createdAt' | 'updatedAt'>
      & { additionalOptions: Array<(
        { __typename?: 'CompetitionIntegrationAdditionalOption' }
        & Pick<CompetitionIntegrationAdditionalOption, 'id' | 'name' | 'isRequired' | 'createdAt' | 'updatedAt'>
      )> }
    ), seasonOptions: Array<(
      { __typename?: 'SeasonCompetitionIntegrationOption' }
      & Pick<SeasonCompetitionIntegrationOption, 'id' | 'name' | 'value' | 'isRequired'>
    )> }
  ) }
);

export type DisableSeasonCompetitionIntegrationMutationVariables = Exact<{
  integrationId: Scalars['String'];
}>;


export type DisableSeasonCompetitionIntegrationMutation = (
  { __typename?: 'Mutation' }
  & { disableSeasonCompetitionIntegration: (
    { __typename?: 'SeasonCompetitionIntegration' }
    & Pick<SeasonCompetitionIntegration, 'id' | 'seasonId' | 'seasonName' | 'status' | 'externalIdOrg' | 'lastRan' | 'fromDateMinutes' | 'toDateMinutes'>
    & { integration: (
      { __typename?: 'CompetitionIntegration' }
      & Pick<CompetitionIntegration, 'id' | 'orgId' | 'orgName' | 'orgAbbreviation' | 'name' | 'description' | 'status' | 'queueName' | 'createdAt' | 'updatedAt'>
      & { additionalOptions: Array<(
        { __typename?: 'CompetitionIntegrationAdditionalOption' }
        & Pick<CompetitionIntegrationAdditionalOption, 'id' | 'name' | 'isRequired' | 'createdAt' | 'updatedAt'>
      )> }
    ), seasonOptions: Array<(
      { __typename?: 'SeasonCompetitionIntegrationOption' }
      & Pick<SeasonCompetitionIntegrationOption, 'id' | 'name' | 'value' | 'isRequired'>
    )> }
  ) }
);

export type RunSeasonCompetitionIntegrationMutationVariables = Exact<{
  seasonIntegrationId: Scalars['String'];
}>;


export type RunSeasonCompetitionIntegrationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'runSeasonCompetitionIntegration'>
);

export type UpdateMediaMembersEnabledForSeasonMutationVariables = Exact<{
  seasonId: Scalars['String'];
  enabled: Scalars['Boolean'];
}>;


export type UpdateMediaMembersEnabledForSeasonMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateMediaMembersEnabledForSeason'>
);

export type GetSeasonMediaMembersConfigByIdQueryVariables = Exact<{
  seasonMediaMembersConfigId: Scalars['String'];
}>;


export type GetSeasonMediaMembersConfigByIdQuery = (
  { __typename?: 'Query' }
  & { getSeasonMediaMembersConfigById?: Maybe<(
    { __typename?: 'SeasonMediaMembersConfig' }
    & Pick<SeasonMediaMembersConfig, 'orgId' | 'seasonId' | 'id' | 'status' | 'allowIncidentReports' | 'allowIncidentReportsCutoffDays' | 'allowIncidentReportsDelayMinutes' | 'activatedAt' | 'createdAt' | 'updatedAt'>
  )> }
);

export type FindSeasonMediaMembersConfigForSeasonQueryVariables = Exact<{
  orgId: Scalars['String'];
  seasonId: Scalars['String'];
}>;


export type FindSeasonMediaMembersConfigForSeasonQuery = (
  { __typename?: 'Query' }
  & { findSeasonMediaMembersConfigForSeason?: Maybe<(
    { __typename?: 'SeasonMediaMembersConfig' }
    & Pick<SeasonMediaMembersConfig, 'orgId' | 'seasonId' | 'id' | 'status' | 'allowIncidentReports' | 'allowIncidentReportsCutoffDays' | 'allowIncidentReportsDelayMinutes' | 'activatedAt' | 'createdAt' | 'updatedAt'>
  )> }
);

export type CreateSeasonMediaMembersConfigMutationVariables = Exact<{
  orgId: Scalars['String'];
  seasonId: Scalars['String'];
  allowIncidentReports: Scalars['Boolean'];
  allowIncidentReportsDelayMinutes?: Maybe<Scalars['Int']>;
  allowIncidentReportsCutoffDays?: Maybe<Scalars['Int']>;
}>;


export type CreateSeasonMediaMembersConfigMutation = (
  { __typename?: 'Mutation' }
  & { createSeasonMediaMembersConfig: (
    { __typename?: 'SeasonMediaMembersConfig' }
    & Pick<SeasonMediaMembersConfig, 'orgId' | 'seasonId' | 'id' | 'status' | 'allowIncidentReports' | 'allowIncidentReportsCutoffDays' | 'allowIncidentReportsDelayMinutes' | 'activatedAt' | 'createdAt' | 'updatedAt'>
  ) }
);

export type UpdateSeasonMediaMembersConfigMutationVariables = Exact<{
  orgId: Scalars['String'];
  seasonId: Scalars['String'];
  seasonMediaMembersConfigId: Scalars['String'];
  allowIncidentReports: Scalars['Boolean'];
  allowIncidentReportsCutoffDays?: Maybe<Scalars['Int']>;
  allowIncidentReportsDelayMinutes?: Maybe<Scalars['Int']>;
}>;


export type UpdateSeasonMediaMembersConfigMutation = (
  { __typename?: 'Mutation' }
  & { updateSeasonMediaMembersConfig: (
    { __typename?: 'SeasonMediaMembersConfig' }
    & Pick<SeasonMediaMembersConfig, 'orgId' | 'seasonId' | 'id' | 'status' | 'allowIncidentReports' | 'allowIncidentReportsCutoffDays' | 'allowIncidentReportsDelayMinutes' | 'activatedAt' | 'createdAt' | 'updatedAt'>
  ) }
);

export type GetMediaMembersOrgSetupSummaryForSeasonQueryVariables = Exact<{
  seasonId: Scalars['String'];
}>;


export type GetMediaMembersOrgSetupSummaryForSeasonQuery = (
  { __typename?: 'Query' }
  & { getMediaMembersOrgSetupSummaryForSeason: (
    { __typename?: 'MediaMembersSeasonOrgSetupSummary' }
    & Pick<MediaMembersSeasonOrgSetupSummary, 'orgId' | 'orgName' | 'orgLogo' | 'leagueId' | 'leagueName' | 'seasonId' | 'seasonName' | 'kiEnabled' | 'kiRecipientsCount' | 'mediaMembersCount'>
  ) }
);

export type DeactivateMediaMembersConfigForSeasonMutationVariables = Exact<{
  orgId: Scalars['String'];
  seasonId: Scalars['String'];
  seasonMediaMembersConfigId: Scalars['String'];
}>;


export type DeactivateMediaMembersConfigForSeasonMutation = (
  { __typename?: 'Mutation' }
  & { deactivateMediaMembersConfigForSeason: (
    { __typename?: 'SeasonMediaMembersConfig' }
    & Pick<SeasonMediaMembersConfig, 'orgId' | 'seasonId' | 'id' | 'status' | 'allowIncidentReports' | 'allowIncidentReportsCutoffDays' | 'allowIncidentReportsDelayMinutes' | 'activatedAt' | 'createdAt' | 'updatedAt'>
  ) }
);

export type ListSeasonMediaMembersOrgRecipientsQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  orgId: Scalars['String'];
  seasonId: Scalars['String'];
}>;


export type ListSeasonMediaMembersOrgRecipientsQuery = (
  { __typename?: 'Query' }
  & { listSeasonMediaMembersOrgRecipients: (
    { __typename?: 'SeasonMediaMembersOrgRecipients' }
    & Pick<SeasonMediaMembersOrgRecipients, 'seasonId'>
    & { recipients: Array<(
      { __typename?: 'SeasonMediaMembersOrgRecipient' }
      & Pick<SeasonMediaMembersOrgRecipient, 'id' | 'SeasonId' | 'orgMembershipId' | 'orgId' | 'orgName' | 'orgAbbreviation' | 'orgLogo' | 'isActive' | 'isKIRecipient' | 'title' | 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic'>
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type AddSeasonMediaMembersOrgRecipientMutationVariables = Exact<{
  orgId: Scalars['String'];
  seasonId: Scalars['String'];
  orgMembershipId: Scalars['String'];
  isActive: Scalars['Boolean'];
  isKIRecipient: Scalars['Boolean'];
}>;


export type AddSeasonMediaMembersOrgRecipientMutation = (
  { __typename?: 'Mutation' }
  & { addSeasonMediaMembersOrgRecipient: (
    { __typename?: 'SeasonMediaMembersOrgRecipient' }
    & Pick<SeasonMediaMembersOrgRecipient, 'id' | 'SeasonId' | 'orgMembershipId' | 'orgId' | 'orgName' | 'orgAbbreviation' | 'orgLogo' | 'isActive' | 'isKIRecipient' | 'title' | 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic'>
  ) }
);

export type DeleteSeasonMediaMembersOrgRecipientMutationVariables = Exact<{
  orgId: Scalars['String'];
  seasonId: Scalars['String'];
  orgRecipientId: Scalars['String'];
}>;


export type DeleteSeasonMediaMembersOrgRecipientMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSeasonMediaMembersOrgRecipient'>
);

export type ActivateSeasonMediaMembersOrgRecipientMutationVariables = Exact<{
  orgId: Scalars['String'];
  seasonId: Scalars['String'];
  orgRecipientId: Scalars['String'];
  isActive: Scalars['Boolean'];
}>;


export type ActivateSeasonMediaMembersOrgRecipientMutation = (
  { __typename?: 'Mutation' }
  & { activateSeasonMediaMembersOrgRecipient: (
    { __typename?: 'SeasonMediaMembersOrgRecipient' }
    & Pick<SeasonMediaMembersOrgRecipient, 'id' | 'SeasonId' | 'orgMembershipId' | 'orgId' | 'orgName' | 'orgAbbreviation' | 'orgLogo' | 'isActive' | 'isKIRecipient' | 'title' | 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic'>
  ) }
);

export type SetIsKiRecipientForSeasonMediaMembersOrgRecipientMutationVariables = Exact<{
  orgId: Scalars['String'];
  seasonId: Scalars['String'];
  orgRecipientId: Scalars['String'];
  isKIRecipient: Scalars['Boolean'];
}>;


export type SetIsKiRecipientForSeasonMediaMembersOrgRecipientMutation = (
  { __typename?: 'Mutation' }
  & { setIsKIRecipientForSeasonMediaMembersOrgRecipient: (
    { __typename?: 'SeasonMediaMembersOrgRecipient' }
    & Pick<SeasonMediaMembersOrgRecipient, 'id' | 'SeasonId' | 'orgMembershipId' | 'orgId' | 'orgName' | 'orgAbbreviation' | 'orgLogo' | 'isActive' | 'isKIRecipient' | 'title' | 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic'>
  ) }
);

export type ListSportsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListSportsQuery = (
  { __typename?: 'Query' }
  & { listSports: Array<(
    { __typename?: 'Sport' }
    & Pick<Sport, 'sport'>
  )> }
);

export type ListAssessmentObjectsQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  sport?: Maybe<Sports>;
  type?: Maybe<AssessmentObjectTypes>;
  classification?: Maybe<AssessmentObjectClassifications>;
}>;


export type ListAssessmentObjectsQuery = (
  { __typename?: 'Query' }
  & { listAssessmentObjects: (
    { __typename?: 'AssessmentObjects' }
    & { assessmentObjects: Array<(
      { __typename?: 'AssessmentObject' }
      & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name' | 'createdAt' | 'updatedAt'>
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type ListPeriodsQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  sport?: Maybe<Sports>;
  name?: Maybe<Scalars['String']>;
}>;


export type ListPeriodsQuery = (
  { __typename?: 'Query' }
  & { listPeriods: (
    { __typename?: 'Periods' }
    & { periods: Array<(
      { __typename?: 'Period' }
      & Pick<Period, 'id' | 'sport' | 'name' | 'createdAt' | 'updatedAt'>
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type ListSupplementalReportsForOrgQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  orgId: Scalars['String'];
  leagueId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  teamSearch?: Maybe<Scalars['String']>;
  type?: Maybe<SupplementalReportTypes>;
  status?: Maybe<SupplementalReportStatus>;
  submittedBySearch?: Maybe<Scalars['String']>;
  competitionDate?: Maybe<Scalars['String']>;
}>;


export type ListSupplementalReportsForOrgQuery = (
  { __typename?: 'Query' }
  & { listSupplementalReportsForOrg: (
    { __typename?: 'SupplementalReports' }
    & { supplementalReports: Array<(
      { __typename?: 'SupplementalReport' }
      & Pick<SupplementalReport, 'id' | 'competitionSurveyId' | 'dynamicCompetitionSurveyId' | 'organizationId' | 'organizationName' | 'leagueId' | 'leagueName' | 'seasonId' | 'seasonName' | 'competitionSurveySectionQuestionId' | 'competitionSurveyFeedbackId' | 'dynamicCompetitionSurveySectionQuestionId' | 'dynamicCompetitionSurveyFeedbackId' | 'dynamicCompetitionSurveySubFeedbackId' | 'type' | 'status' | 'title' | 'report' | 'reportVersion' | 'time' | 'approvedDate' | 'reopenedDate' | 'submittedAt' | 'createdAt' | 'updatedAt'>
      & { submittedBy?: Maybe<(
        { __typename?: 'SupplementalReportSubmittedByInfo' }
        & Pick<SupplementalReportSubmittedByInfo, 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'teamId' | 'teamName' | 'teamLogo' | 'officialId' | 'assessmentObjectId' | 'assessmentObjectName'>
      )>, approvedBy?: Maybe<(
        { __typename?: 'SupplementalReportApprovedByInfo' }
        & Pick<SupplementalReportApprovedByInfo, 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'title' | 'organizationName' | 'organizationAbbreviation'>
      )>, competitionInfo: (
        { __typename?: 'SupplementalReportCompetitionInfo' }
        & Pick<SupplementalReportCompetitionInfo, 'competitionId' | 'externalId' | 'sport' | 'homeTeamId' | 'homeTeamName' | 'homeTeamScore' | 'homeTeamTieBreakerScore' | 'awayTeamId' | 'awayTeamName' | 'awayTeamScore' | 'awayTeamTieBreakerScore' | 'startTime' | 'timeZone' | 'location' | 'outcome' | 'verificationStatus'>
      ), assessmentObject?: Maybe<(
        { __typename?: 'AssessmentObject' }
        & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name' | 'createdAt' | 'updatedAt'>
      )>, official?: Maybe<(
        { __typename?: 'Official' }
        & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
      )>, team?: Maybe<(
        { __typename?: 'Team' }
        & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'classification' | 'gender' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'timeZone' | 'website' | 'logo' | 'createdAt' | 'updatedAt'>
      )> }
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type ListSupplementalReportsForUserQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  leagueId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  teamSearch?: Maybe<Scalars['String']>;
  type?: Maybe<SupplementalReportTypes>;
  status?: Maybe<SupplementalReportStatus>;
  submittedBySearch?: Maybe<Scalars['String']>;
  competitionDate?: Maybe<Scalars['String']>;
}>;


export type ListSupplementalReportsForUserQuery = (
  { __typename?: 'Query' }
  & { listSupplementalReportsForUser: (
    { __typename?: 'SupplementalReports' }
    & { supplementalReports: Array<(
      { __typename?: 'SupplementalReport' }
      & Pick<SupplementalReport, 'id' | 'competitionSurveyId' | 'dynamicCompetitionSurveyId' | 'organizationId' | 'organizationName' | 'leagueId' | 'leagueName' | 'seasonId' | 'seasonName' | 'competitionSurveySectionQuestionId' | 'competitionSurveyFeedbackId' | 'dynamicCompetitionSurveySectionQuestionId' | 'dynamicCompetitionSurveyFeedbackId' | 'dynamicCompetitionSurveySubFeedbackId' | 'type' | 'status' | 'title' | 'report' | 'reportVersion' | 'time' | 'approvedDate' | 'reopenedDate' | 'submittedAt' | 'createdAt' | 'updatedAt'>
      & { submittedBy?: Maybe<(
        { __typename?: 'SupplementalReportSubmittedByInfo' }
        & Pick<SupplementalReportSubmittedByInfo, 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'teamId' | 'teamName' | 'teamLogo' | 'officialId' | 'assessmentObjectId' | 'assessmentObjectName'>
      )>, approvedBy?: Maybe<(
        { __typename?: 'SupplementalReportApprovedByInfo' }
        & Pick<SupplementalReportApprovedByInfo, 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'title' | 'organizationName' | 'organizationAbbreviation'>
      )>, competitionInfo: (
        { __typename?: 'SupplementalReportCompetitionInfo' }
        & Pick<SupplementalReportCompetitionInfo, 'competitionId' | 'externalId' | 'sport' | 'homeTeamId' | 'homeTeamName' | 'homeTeamScore' | 'homeTeamTieBreakerScore' | 'awayTeamId' | 'awayTeamName' | 'awayTeamScore' | 'awayTeamTieBreakerScore' | 'startTime' | 'timeZone' | 'location' | 'outcome' | 'verificationStatus'>
      ), assessmentObject?: Maybe<(
        { __typename?: 'AssessmentObject' }
        & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name' | 'createdAt' | 'updatedAt'>
      )>, official?: Maybe<(
        { __typename?: 'Official' }
        & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
      )>, team?: Maybe<(
        { __typename?: 'Team' }
        & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'classification' | 'gender' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'timeZone' | 'website' | 'logo' | 'createdAt' | 'updatedAt'>
      )> }
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type ListOrgSupplementalReportsForCompetitionQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  competitionId: Scalars['String'];
  teamSearch?: Maybe<Scalars['String']>;
  type?: Maybe<SupplementalReportTypes>;
  status?: Maybe<SupplementalReportStatus>;
}>;


export type ListOrgSupplementalReportsForCompetitionQuery = (
  { __typename?: 'Query' }
  & { listOrgSupplementalReportsForCompetition: (
    { __typename?: 'SupplementalReports' }
    & { supplementalReports: Array<(
      { __typename?: 'SupplementalReport' }
      & Pick<SupplementalReport, 'id' | 'competitionSurveyId' | 'dynamicCompetitionSurveyId' | 'organizationId' | 'organizationName' | 'leagueId' | 'leagueName' | 'seasonId' | 'seasonName' | 'competitionSurveySectionQuestionId' | 'competitionSurveyFeedbackId' | 'dynamicCompetitionSurveySectionQuestionId' | 'dynamicCompetitionSurveyFeedbackId' | 'dynamicCompetitionSurveySubFeedbackId' | 'type' | 'status' | 'title' | 'report' | 'reportVersion' | 'time' | 'approvedDate' | 'reopenedDate' | 'submittedAt' | 'createdAt' | 'updatedAt'>
      & { submittedBy?: Maybe<(
        { __typename?: 'SupplementalReportSubmittedByInfo' }
        & Pick<SupplementalReportSubmittedByInfo, 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'teamId' | 'teamName' | 'teamLogo' | 'officialId' | 'assessmentObjectId' | 'assessmentObjectName'>
      )>, approvedBy?: Maybe<(
        { __typename?: 'SupplementalReportApprovedByInfo' }
        & Pick<SupplementalReportApprovedByInfo, 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'title' | 'organizationName' | 'organizationAbbreviation'>
      )>, competitionInfo: (
        { __typename?: 'SupplementalReportCompetitionInfo' }
        & Pick<SupplementalReportCompetitionInfo, 'competitionId' | 'externalId' | 'sport' | 'homeTeamId' | 'homeTeamName' | 'homeTeamScore' | 'homeTeamTieBreakerScore' | 'awayTeamId' | 'awayTeamName' | 'awayTeamScore' | 'awayTeamTieBreakerScore' | 'startTime' | 'timeZone' | 'location' | 'outcome' | 'verificationStatus'>
      ), assessmentObject?: Maybe<(
        { __typename?: 'AssessmentObject' }
        & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name' | 'createdAt' | 'updatedAt'>
      )>, official?: Maybe<(
        { __typename?: 'Official' }
        & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
      )>, team?: Maybe<(
        { __typename?: 'Team' }
        & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'classification' | 'gender' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'timeZone' | 'website' | 'logo' | 'createdAt' | 'updatedAt'>
      )> }
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type ListUserSupplementalReportsForCompetitionQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  competitionId: Scalars['String'];
  teamSearch?: Maybe<Scalars['String']>;
  type?: Maybe<SupplementalReportTypes>;
  status?: Maybe<SupplementalReportStatus>;
}>;


export type ListUserSupplementalReportsForCompetitionQuery = (
  { __typename?: 'Query' }
  & { listUserSupplementalReportsForCompetition: (
    { __typename?: 'SupplementalReports' }
    & { supplementalReports: Array<(
      { __typename?: 'SupplementalReport' }
      & Pick<SupplementalReport, 'id' | 'competitionSurveyId' | 'dynamicCompetitionSurveyId' | 'organizationId' | 'organizationName' | 'leagueId' | 'leagueName' | 'seasonId' | 'seasonName' | 'competitionSurveySectionQuestionId' | 'competitionSurveyFeedbackId' | 'dynamicCompetitionSurveySectionQuestionId' | 'dynamicCompetitionSurveyFeedbackId' | 'dynamicCompetitionSurveySubFeedbackId' | 'type' | 'status' | 'title' | 'report' | 'reportVersion' | 'time' | 'approvedDate' | 'reopenedDate' | 'submittedAt' | 'createdAt' | 'updatedAt'>
      & { submittedBy?: Maybe<(
        { __typename?: 'SupplementalReportSubmittedByInfo' }
        & Pick<SupplementalReportSubmittedByInfo, 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'teamId' | 'teamName' | 'teamLogo' | 'officialId' | 'assessmentObjectId' | 'assessmentObjectName'>
      )>, approvedBy?: Maybe<(
        { __typename?: 'SupplementalReportApprovedByInfo' }
        & Pick<SupplementalReportApprovedByInfo, 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'title' | 'organizationName' | 'organizationAbbreviation'>
      )>, competitionInfo: (
        { __typename?: 'SupplementalReportCompetitionInfo' }
        & Pick<SupplementalReportCompetitionInfo, 'competitionId' | 'externalId' | 'sport' | 'homeTeamId' | 'homeTeamName' | 'homeTeamScore' | 'homeTeamTieBreakerScore' | 'awayTeamId' | 'awayTeamName' | 'awayTeamScore' | 'awayTeamTieBreakerScore' | 'startTime' | 'timeZone' | 'location' | 'outcome' | 'verificationStatus'>
      ), assessmentObject?: Maybe<(
        { __typename?: 'AssessmentObject' }
        & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name' | 'createdAt' | 'updatedAt'>
      )>, official?: Maybe<(
        { __typename?: 'Official' }
        & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
      )>, team?: Maybe<(
        { __typename?: 'Team' }
        & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'classification' | 'gender' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'timeZone' | 'website' | 'logo' | 'createdAt' | 'updatedAt'>
      )> }
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type GetSupplementalReportByIdQueryVariables = Exact<{
  supplementalReportId: Scalars['String'];
}>;


export type GetSupplementalReportByIdQuery = (
  { __typename?: 'Query' }
  & { getSupplementalReportById: (
    { __typename?: 'SupplementalReport' }
    & Pick<SupplementalReport, 'id' | 'competitionSurveyId' | 'dynamicCompetitionSurveyId' | 'organizationId' | 'organizationName' | 'leagueId' | 'leagueName' | 'seasonId' | 'seasonName' | 'competitionSurveySectionQuestionId' | 'competitionSurveyFeedbackId' | 'dynamicCompetitionSurveySectionQuestionId' | 'dynamicCompetitionSurveyFeedbackId' | 'dynamicCompetitionSurveySubFeedbackId' | 'type' | 'status' | 'title' | 'report' | 'reportVersion' | 'time' | 'approvedDate' | 'reopenedDate' | 'submittedAt' | 'createdAt' | 'updatedAt'>
    & { submittedBy?: Maybe<(
      { __typename?: 'SupplementalReportSubmittedByInfo' }
      & Pick<SupplementalReportSubmittedByInfo, 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'teamId' | 'teamName' | 'teamLogo' | 'officialId' | 'assessmentObjectId' | 'assessmentObjectName'>
    )>, approvedBy?: Maybe<(
      { __typename?: 'SupplementalReportApprovedByInfo' }
      & Pick<SupplementalReportApprovedByInfo, 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'title' | 'organizationName' | 'organizationAbbreviation'>
    )>, competitionInfo: (
      { __typename?: 'SupplementalReportCompetitionInfo' }
      & Pick<SupplementalReportCompetitionInfo, 'competitionId' | 'externalId' | 'sport' | 'homeTeamId' | 'homeTeamName' | 'homeTeamScore' | 'homeTeamTieBreakerScore' | 'awayTeamId' | 'awayTeamName' | 'awayTeamScore' | 'awayTeamTieBreakerScore' | 'startTime' | 'timeZone' | 'location' | 'outcome' | 'verificationStatus'>
    ), assessmentObject?: Maybe<(
      { __typename?: 'AssessmentObject' }
      & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name' | 'createdAt' | 'updatedAt'>
    )>, official?: Maybe<(
      { __typename?: 'Official' }
      & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
    )>, team?: Maybe<(
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'classification' | 'gender' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'timeZone' | 'website' | 'logo' | 'createdAt' | 'updatedAt'>
    )> }
  ) }
);

export type UpdateReportForSupplementalReportMutationVariables = Exact<{
  supplementalReportId: Scalars['String'];
  report: Scalars['String'];
}>;


export type UpdateReportForSupplementalReportMutation = (
  { __typename?: 'Mutation' }
  & { updateReportForSupplementalReport: (
    { __typename?: 'SupplementalReport' }
    & Pick<SupplementalReport, 'id' | 'competitionSurveyId' | 'dynamicCompetitionSurveyId' | 'organizationId' | 'organizationName' | 'leagueId' | 'leagueName' | 'seasonId' | 'seasonName' | 'competitionSurveySectionQuestionId' | 'competitionSurveyFeedbackId' | 'dynamicCompetitionSurveySectionQuestionId' | 'dynamicCompetitionSurveyFeedbackId' | 'dynamicCompetitionSurveySubFeedbackId' | 'type' | 'status' | 'title' | 'report' | 'reportVersion' | 'time' | 'approvedDate' | 'reopenedDate' | 'submittedAt' | 'createdAt' | 'updatedAt'>
    & { submittedBy?: Maybe<(
      { __typename?: 'SupplementalReportSubmittedByInfo' }
      & Pick<SupplementalReportSubmittedByInfo, 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'teamId' | 'teamName' | 'teamLogo' | 'officialId' | 'assessmentObjectId' | 'assessmentObjectName'>
    )>, approvedBy?: Maybe<(
      { __typename?: 'SupplementalReportApprovedByInfo' }
      & Pick<SupplementalReportApprovedByInfo, 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'title' | 'organizationName' | 'organizationAbbreviation'>
    )>, competitionInfo: (
      { __typename?: 'SupplementalReportCompetitionInfo' }
      & Pick<SupplementalReportCompetitionInfo, 'competitionId' | 'externalId' | 'sport' | 'homeTeamId' | 'homeTeamName' | 'homeTeamScore' | 'homeTeamTieBreakerScore' | 'awayTeamId' | 'awayTeamName' | 'awayTeamScore' | 'awayTeamTieBreakerScore' | 'startTime' | 'timeZone' | 'location' | 'outcome' | 'verificationStatus'>
    ), assessmentObject?: Maybe<(
      { __typename?: 'AssessmentObject' }
      & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name' | 'createdAt' | 'updatedAt'>
    )>, official?: Maybe<(
      { __typename?: 'Official' }
      & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
    )>, team?: Maybe<(
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'classification' | 'gender' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'timeZone' | 'website' | 'logo' | 'createdAt' | 'updatedAt'>
    )> }
  ) }
);

export type RequestChangeForSupplementalReportMutationVariables = Exact<{
  supplementalReportId: Scalars['String'];
  message: Scalars['String'];
}>;


export type RequestChangeForSupplementalReportMutation = (
  { __typename?: 'Mutation' }
  & { requestChangeForSupplementalReport: (
    { __typename?: 'SupplementalReport' }
    & Pick<SupplementalReport, 'id' | 'competitionSurveyId' | 'dynamicCompetitionSurveyId' | 'organizationId' | 'organizationName' | 'leagueId' | 'leagueName' | 'seasonId' | 'seasonName' | 'competitionSurveySectionQuestionId' | 'competitionSurveyFeedbackId' | 'dynamicCompetitionSurveySectionQuestionId' | 'dynamicCompetitionSurveyFeedbackId' | 'dynamicCompetitionSurveySubFeedbackId' | 'type' | 'status' | 'title' | 'report' | 'reportVersion' | 'time' | 'approvedDate' | 'reopenedDate' | 'submittedAt' | 'createdAt' | 'updatedAt'>
    & { submittedBy?: Maybe<(
      { __typename?: 'SupplementalReportSubmittedByInfo' }
      & Pick<SupplementalReportSubmittedByInfo, 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'teamId' | 'teamName' | 'teamLogo' | 'officialId' | 'assessmentObjectId' | 'assessmentObjectName'>
    )>, approvedBy?: Maybe<(
      { __typename?: 'SupplementalReportApprovedByInfo' }
      & Pick<SupplementalReportApprovedByInfo, 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'title' | 'organizationName' | 'organizationAbbreviation'>
    )>, competitionInfo: (
      { __typename?: 'SupplementalReportCompetitionInfo' }
      & Pick<SupplementalReportCompetitionInfo, 'competitionId' | 'externalId' | 'sport' | 'homeTeamId' | 'homeTeamName' | 'homeTeamScore' | 'homeTeamTieBreakerScore' | 'awayTeamId' | 'awayTeamName' | 'awayTeamScore' | 'awayTeamTieBreakerScore' | 'startTime' | 'timeZone' | 'location' | 'outcome' | 'verificationStatus'>
    ), assessmentObject?: Maybe<(
      { __typename?: 'AssessmentObject' }
      & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name' | 'createdAt' | 'updatedAt'>
    )>, official?: Maybe<(
      { __typename?: 'Official' }
      & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
    )>, team?: Maybe<(
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'classification' | 'gender' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'timeZone' | 'website' | 'logo' | 'createdAt' | 'updatedAt'>
    )> }
  ) }
);

export type ApproveSupplementalReportMutationVariables = Exact<{
  supplementalReportId: Scalars['String'];
}>;


export type ApproveSupplementalReportMutation = (
  { __typename?: 'Mutation' }
  & { approveSupplementalReport: (
    { __typename?: 'SupplementalReport' }
    & Pick<SupplementalReport, 'id' | 'competitionSurveyId' | 'dynamicCompetitionSurveyId' | 'organizationId' | 'organizationName' | 'leagueId' | 'leagueName' | 'seasonId' | 'seasonName' | 'competitionSurveySectionQuestionId' | 'competitionSurveyFeedbackId' | 'dynamicCompetitionSurveySectionQuestionId' | 'dynamicCompetitionSurveyFeedbackId' | 'dynamicCompetitionSurveySubFeedbackId' | 'type' | 'status' | 'title' | 'report' | 'reportVersion' | 'time' | 'approvedDate' | 'reopenedDate' | 'submittedAt' | 'createdAt' | 'updatedAt'>
    & { submittedBy?: Maybe<(
      { __typename?: 'SupplementalReportSubmittedByInfo' }
      & Pick<SupplementalReportSubmittedByInfo, 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'teamId' | 'teamName' | 'teamLogo' | 'officialId' | 'assessmentObjectId' | 'assessmentObjectName'>
    )>, approvedBy?: Maybe<(
      { __typename?: 'SupplementalReportApprovedByInfo' }
      & Pick<SupplementalReportApprovedByInfo, 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'title' | 'organizationName' | 'organizationAbbreviation'>
    )>, competitionInfo: (
      { __typename?: 'SupplementalReportCompetitionInfo' }
      & Pick<SupplementalReportCompetitionInfo, 'competitionId' | 'externalId' | 'sport' | 'homeTeamId' | 'homeTeamName' | 'homeTeamScore' | 'homeTeamTieBreakerScore' | 'awayTeamId' | 'awayTeamName' | 'awayTeamScore' | 'awayTeamTieBreakerScore' | 'startTime' | 'timeZone' | 'location' | 'outcome' | 'verificationStatus'>
    ), assessmentObject?: Maybe<(
      { __typename?: 'AssessmentObject' }
      & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name' | 'createdAt' | 'updatedAt'>
    )>, official?: Maybe<(
      { __typename?: 'Official' }
      & Pick<Official, 'id' | 'sport' | 'userId' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'createdAt' | 'updatedAt'>
    )>, team?: Maybe<(
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'classification' | 'gender' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'timeZone' | 'website' | 'logo' | 'createdAt' | 'updatedAt'>
    )> }
  ) }
);

export type ListCommentsForSupplementalReportQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  supplementalReportId: Scalars['String'];
}>;


export type ListCommentsForSupplementalReportQuery = (
  { __typename?: 'Query' }
  & { listCommentsForSupplementalReport: (
    { __typename?: 'SupplementalReportMessages' }
    & { messages: Array<(
      { __typename?: 'SupplementalReportMessage' }
      & Pick<SupplementalReportMessage, 'id' | 'supplementalReportId' | 'message' | 'createdAt' | 'updatedAt' | 'editedAt'>
      & { author?: Maybe<(
        { __typename?: 'SupplementalReportMessageAuthorInfo' }
        & Pick<SupplementalReportMessageAuthorInfo, 'id' | 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'authorType' | 'teamId' | 'teamName' | 'teamLogo' | 'title' | 'organizationName' | 'organizationAbbreviation' | 'assessmentObjectId' | 'assessmentObjectName'>
      )> }
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type AddCommentToSupplementalReportMutationVariables = Exact<{
  supplementalReportId: Scalars['String'];
  comment: Scalars['String'];
}>;


export type AddCommentToSupplementalReportMutation = (
  { __typename?: 'Mutation' }
  & { addCommentToSupplementalReport: (
    { __typename?: 'SupplementalReportMessage' }
    & Pick<SupplementalReportMessage, 'id' | 'supplementalReportId' | 'message' | 'createdAt' | 'updatedAt' | 'editedAt'>
    & { author?: Maybe<(
      { __typename?: 'SupplementalReportMessageAuthorInfo' }
      & Pick<SupplementalReportMessageAuthorInfo, 'id' | 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'authorType' | 'teamId' | 'teamName' | 'teamLogo' | 'title' | 'organizationName' | 'organizationAbbreviation' | 'assessmentObjectId' | 'assessmentObjectName'>
    )> }
  ) }
);

export type GetSurveySummaryRecipientNotificationSettingByTokenQueryVariables = Exact<{
  summaryNotificationSettingsToken: Scalars['String'];
}>;


export type GetSurveySummaryRecipientNotificationSettingByTokenQuery = (
  { __typename?: 'Query' }
  & { getSurveySummaryRecipientNotificationSettingByToken: (
    { __typename?: 'SurveySummaryNotificationSetting' }
    & Pick<SurveySummaryNotificationSetting, 'id' | 'email' | 'firstName' | 'lastName' | 'surveyName' | 'isActive'>
  ) }
);

export type UpdateSurveySummaryRecipientNotificationSettingByTokenMutationVariables = Exact<{
  summaryNotificationSettingsToken: Scalars['String'];
  isActive: Scalars['Boolean'];
}>;


export type UpdateSurveySummaryRecipientNotificationSettingByTokenMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateSurveySummaryRecipientNotificationSettingByToken'>
);

export type ListMySurveySummaryRecipientNotificationSettingsQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  surveyName?: Maybe<Scalars['String']>;
}>;


export type ListMySurveySummaryRecipientNotificationSettingsQuery = (
  { __typename?: 'Query' }
  & { listMySurveySummaryRecipientNotificationSettings: (
    { __typename?: 'SurveySummaryNotificationSettings' }
    & { settings: Array<(
      { __typename?: 'SurveySummaryNotificationSetting' }
      & Pick<SurveySummaryNotificationSetting, 'id' | 'email' | 'firstName' | 'lastName' | 'surveyName' | 'isActive'>
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type UpdateMySurveySummaryRecipientNotificationSettingMutationVariables = Exact<{
  recipientId: Scalars['String'];
  isActive: Scalars['Boolean'];
}>;


export type UpdateMySurveySummaryRecipientNotificationSettingMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateMySurveySummaryRecipientNotificationSetting'>
);

export type ListAdminSurveyTemplatesQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  sport?: Maybe<Sports>;
  status?: Maybe<SurveyStatuses>;
  name?: Maybe<Scalars['String']>;
}>;


export type ListAdminSurveyTemplatesQuery = (
  { __typename?: 'Query' }
  & { listAdminSurveyTemplates: (
    { __typename?: 'SurveyTemplates' }
    & { templates: Array<(
      { __typename?: 'SurveyTemplate' }
      & Pick<SurveyTemplate, 'id' | 'sport' | 'status' | 'orgId' | 'hasIncidentReport' | 'createdAt' | 'updatedAt'>
      & { templateInfo: (
        { __typename?: 'SurveyTemplateInfo' }
        & Pick<SurveyTemplateInfo, 'name' | 'description' | 'audience' | 'sendDelayMinutes'>
      ), sections: Array<(
        { __typename?: 'SurveyTemplateSection' }
        & Pick<SurveyTemplateSection, 'id' | 'name' | 'description' | 'order' | 'createdAt' | 'updatedAt'>
        & { questions: Array<(
          { __typename?: 'SurveyTemplateSectionQuestion' }
          & Pick<SurveyTemplateSectionQuestion, 'id' | 'name' | 'description' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType'>
          & { options: Array<(
            { __typename?: 'SurveyTemplateSectionQuestionOption' }
            & Pick<SurveyTemplateSectionQuestionOption, 'id' | 'value' | 'order'>
          )> }
        )>, assessmentObject: (
          { __typename?: 'AssessmentObject' }
          & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name'>
        ) }
      )> }
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type ListSurveyTemplatesForOrganizationQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  orgId: Scalars['String'];
  adminFilter: SurveyTemplateAdminFilters;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<SurveyStatuses>;
}>;


export type ListSurveyTemplatesForOrganizationQuery = (
  { __typename?: 'Query' }
  & { listSurveyTemplatesForOrganization: (
    { __typename?: 'SurveyTemplates' }
    & { templates: Array<(
      { __typename?: 'SurveyTemplate' }
      & Pick<SurveyTemplate, 'id' | 'sport' | 'status' | 'orgId' | 'hasIncidentReport' | 'createdAt' | 'updatedAt'>
      & { templateInfo: (
        { __typename?: 'SurveyTemplateInfo' }
        & Pick<SurveyTemplateInfo, 'name' | 'description' | 'audience' | 'sendDelayMinutes'>
      ), sections: Array<(
        { __typename?: 'SurveyTemplateSection' }
        & Pick<SurveyTemplateSection, 'id' | 'name' | 'description' | 'order' | 'createdAt' | 'updatedAt'>
        & { questions: Array<(
          { __typename?: 'SurveyTemplateSectionQuestion' }
          & Pick<SurveyTemplateSectionQuestion, 'id' | 'name' | 'description' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType'>
          & { options: Array<(
            { __typename?: 'SurveyTemplateSectionQuestionOption' }
            & Pick<SurveyTemplateSectionQuestionOption, 'id' | 'value' | 'order'>
          )> }
        )>, assessmentObject: (
          { __typename?: 'AssessmentObject' }
          & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name'>
        ) }
      )> }
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type CreateAdminSurveyTemplateMutationVariables = Exact<{
  sport: Sports;
  name: Scalars['String'];
  description: Scalars['String'];
  audience: SurveyAudiences;
  sendDelayMinutes: Scalars['Int'];
}>;


export type CreateAdminSurveyTemplateMutation = (
  { __typename?: 'Mutation' }
  & { createAdminSurveyTemplate: (
    { __typename?: 'SurveyTemplate' }
    & Pick<SurveyTemplate, 'id' | 'sport' | 'status' | 'orgId' | 'hasIncidentReport' | 'createdAt' | 'updatedAt'>
    & { templateInfo: (
      { __typename?: 'SurveyTemplateInfo' }
      & Pick<SurveyTemplateInfo, 'name' | 'description' | 'audience' | 'sendDelayMinutes'>
    ), sections: Array<(
      { __typename?: 'SurveyTemplateSection' }
      & Pick<SurveyTemplateSection, 'id' | 'name' | 'description' | 'order' | 'createdAt' | 'updatedAt'>
      & { questions: Array<(
        { __typename?: 'SurveyTemplateSectionQuestion' }
        & Pick<SurveyTemplateSectionQuestion, 'id' | 'name' | 'description' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType'>
        & { options: Array<(
          { __typename?: 'SurveyTemplateSectionQuestionOption' }
          & Pick<SurveyTemplateSectionQuestionOption, 'id' | 'value' | 'order'>
        )> }
      )>, assessmentObject: (
        { __typename?: 'AssessmentObject' }
        & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name'>
      ) }
    )> }
  ) }
);

export type CreateOrganizationSurveyTemplateMutationVariables = Exact<{
  orgId: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  audience: SurveyAudiences;
  sendDelayMinutes: Scalars['Int'];
}>;


export type CreateOrganizationSurveyTemplateMutation = (
  { __typename?: 'Mutation' }
  & { createOrganizationSurveyTemplate: (
    { __typename?: 'SurveyTemplate' }
    & Pick<SurveyTemplate, 'id' | 'sport' | 'status' | 'orgId' | 'hasIncidentReport' | 'createdAt' | 'updatedAt'>
    & { templateInfo: (
      { __typename?: 'SurveyTemplateInfo' }
      & Pick<SurveyTemplateInfo, 'name' | 'description' | 'audience' | 'sendDelayMinutes'>
    ), sections: Array<(
      { __typename?: 'SurveyTemplateSection' }
      & Pick<SurveyTemplateSection, 'id' | 'name' | 'description' | 'order' | 'createdAt' | 'updatedAt'>
      & { questions: Array<(
        { __typename?: 'SurveyTemplateSectionQuestion' }
        & Pick<SurveyTemplateSectionQuestion, 'id' | 'name' | 'description' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType'>
        & { options: Array<(
          { __typename?: 'SurveyTemplateSectionQuestionOption' }
          & Pick<SurveyTemplateSectionQuestionOption, 'id' | 'value' | 'order'>
        )> }
      )>, assessmentObject: (
        { __typename?: 'AssessmentObject' }
        & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name'>
      ) }
    )> }
  ) }
);

export type GetSurveyTemplateByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetSurveyTemplateByIdQuery = (
  { __typename?: 'Query' }
  & { getSurveyTemplateById: (
    { __typename?: 'SurveyTemplate' }
    & Pick<SurveyTemplate, 'id' | 'sport' | 'status' | 'orgId' | 'hasIncidentReport' | 'createdAt' | 'updatedAt'>
    & { templateInfo: (
      { __typename?: 'SurveyTemplateInfo' }
      & Pick<SurveyTemplateInfo, 'name' | 'description' | 'audience' | 'sendDelayMinutes'>
    ), sections: Array<(
      { __typename?: 'SurveyTemplateSection' }
      & Pick<SurveyTemplateSection, 'id' | 'name' | 'description' | 'order' | 'createdAt' | 'updatedAt'>
      & { questions: Array<(
        { __typename?: 'SurveyTemplateSectionQuestion' }
        & Pick<SurveyTemplateSectionQuestion, 'id' | 'name' | 'description' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType'>
        & { options: Array<(
          { __typename?: 'SurveyTemplateSectionQuestionOption' }
          & Pick<SurveyTemplateSectionQuestionOption, 'id' | 'value' | 'order'>
        )> }
      )>, assessmentObject: (
        { __typename?: 'AssessmentObject' }
        & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name'>
      ) }
    )> }
  ) }
);

export type DeleteSurveyTemplateMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteSurveyTemplateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSurveyTemplate'>
);

export type UpdateSurveyTemplateInfoMutationVariables = Exact<{
  id: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  audience: SurveyAudiences;
  sendDelayMinutes: Scalars['Int'];
}>;


export type UpdateSurveyTemplateInfoMutation = (
  { __typename?: 'Mutation' }
  & { updateSurveyTemplateInfo: (
    { __typename?: 'SurveyTemplateInfo' }
    & Pick<SurveyTemplateInfo, 'name' | 'description' | 'audience' | 'sendDelayMinutes'>
  ) }
);

export type AddSurveyTemplateIncidentReportMutationVariables = Exact<{
  templateId: Scalars['String'];
}>;


export type AddSurveyTemplateIncidentReportMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addSurveyTemplateIncidentReport'>
);

export type DeleteSurveyTemplateIncidentReportMutationVariables = Exact<{
  templateId: Scalars['String'];
}>;


export type DeleteSurveyTemplateIncidentReportMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSurveyTemplateIncidentReport'>
);

export type AddSurveyTemplateSectionMutationVariables = Exact<{
  templateId: Scalars['String'];
  assessmentObjectId: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
}>;


export type AddSurveyTemplateSectionMutation = (
  { __typename?: 'Mutation' }
  & { addSurveyTemplateSection: (
    { __typename?: 'SurveyTemplateSection' }
    & Pick<SurveyTemplateSection, 'id' | 'name' | 'description' | 'order' | 'createdAt' | 'updatedAt'>
    & { questions: Array<(
      { __typename?: 'SurveyTemplateSectionQuestion' }
      & Pick<SurveyTemplateSectionQuestion, 'id' | 'name' | 'description' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType'>
      & { options: Array<(
        { __typename?: 'SurveyTemplateSectionQuestionOption' }
        & Pick<SurveyTemplateSectionQuestionOption, 'id' | 'value' | 'order'>
      )> }
    )>, assessmentObject: (
      { __typename?: 'AssessmentObject' }
      & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name'>
    ) }
  ) }
);

export type UpdateSurveyTemplateSectionMutationVariables = Exact<{
  sectionId: Scalars['String'];
  assessmentObjectId: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  order: Scalars['Int'];
}>;


export type UpdateSurveyTemplateSectionMutation = (
  { __typename?: 'Mutation' }
  & { updateSurveyTemplateSection: (
    { __typename?: 'SurveyTemplateSection' }
    & Pick<SurveyTemplateSection, 'id' | 'name' | 'description' | 'order' | 'createdAt' | 'updatedAt'>
    & { questions: Array<(
      { __typename?: 'SurveyTemplateSectionQuestion' }
      & Pick<SurveyTemplateSectionQuestion, 'id' | 'name' | 'description' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType'>
      & { options: Array<(
        { __typename?: 'SurveyTemplateSectionQuestionOption' }
        & Pick<SurveyTemplateSectionQuestionOption, 'id' | 'value' | 'order'>
      )> }
    )>, assessmentObject: (
      { __typename?: 'AssessmentObject' }
      & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name'>
    ) }
  ) }
);

export type DeleteSurveyTemplateSectionMutationVariables = Exact<{
  sectionId: Scalars['String'];
}>;


export type DeleteSurveyTemplateSectionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSurveyTemplateSection'>
);

export type AddSurveyTemplateSectionQuestionMutationVariables = Exact<{
  sectionId: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  isRequired: Scalars['Boolean'];
  questionType: SurveyQuestionTypes;
}>;


export type AddSurveyTemplateSectionQuestionMutation = (
  { __typename?: 'Mutation' }
  & { addSurveyTemplateSectionQuestion: (
    { __typename?: 'SurveyTemplateSectionQuestion' }
    & Pick<SurveyTemplateSectionQuestion, 'id' | 'name' | 'description' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType' | 'createdAt' | 'updatedAt'>
    & { options: Array<(
      { __typename?: 'SurveyTemplateSectionQuestionOption' }
      & Pick<SurveyTemplateSectionQuestionOption, 'id' | 'value' | 'order'>
    )> }
  ) }
);

export type UpdateSurveyTemplateSectionQuestionMutationVariables = Exact<{
  questionId: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  isRequired: Scalars['Boolean'];
  order: Scalars['Int'];
}>;


export type UpdateSurveyTemplateSectionQuestionMutation = (
  { __typename?: 'Mutation' }
  & { updateSurveyTemplateSectionQuestion: (
    { __typename?: 'SurveyTemplateSectionQuestion' }
    & Pick<SurveyTemplateSectionQuestion, 'id' | 'name' | 'description' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType' | 'createdAt' | 'updatedAt'>
    & { options: Array<(
      { __typename?: 'SurveyTemplateSectionQuestionOption' }
      & Pick<SurveyTemplateSectionQuestionOption, 'id' | 'value' | 'order'>
    )> }
  ) }
);

export type SetSurveyTemplateSectionQuestionCanAddToKeyIncidentsMutationVariables = Exact<{
  questionId: Scalars['String'];
  canAddToKeyIncidents: Scalars['Boolean'];
}>;


export type SetSurveyTemplateSectionQuestionCanAddToKeyIncidentsMutation = (
  { __typename?: 'Mutation' }
  & { setSurveyTemplateSectionQuestionCanAddToKeyIncidents: (
    { __typename?: 'SurveyTemplateSectionQuestion' }
    & Pick<SurveyTemplateSectionQuestion, 'id' | 'name' | 'description' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType' | 'createdAt' | 'updatedAt'>
    & { options: Array<(
      { __typename?: 'SurveyTemplateSectionQuestionOption' }
      & Pick<SurveyTemplateSectionQuestionOption, 'id' | 'value' | 'order'>
    )> }
  ) }
);

export type DeleteSurveyTemplateSectionQuestionMutationVariables = Exact<{
  questionId: Scalars['String'];
}>;


export type DeleteSurveyTemplateSectionQuestionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSurveyTemplateSectionQuestion'>
);

export type AddSurveyTemplateSectionQuestionOptionMutationVariables = Exact<{
  questionId: Scalars['String'];
  value: Scalars['String'];
}>;


export type AddSurveyTemplateSectionQuestionOptionMutation = (
  { __typename?: 'Mutation' }
  & { addSurveyTemplateSectionQuestionOption: (
    { __typename?: 'SurveyTemplateSectionQuestionOption' }
    & Pick<SurveyTemplateSectionQuestionOption, 'id' | 'value' | 'order'>
  ) }
);

export type DeleteSurveyTemplateSectionQuestionOptionMutationVariables = Exact<{
  optionId: Scalars['String'];
}>;


export type DeleteSurveyTemplateSectionQuestionOptionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSurveyTemplateSectionQuestionOption'>
);

export type UpdateSurveyTemplateSectionQuestionOptionMutationVariables = Exact<{
  optionId: Scalars['String'];
  value: Scalars['String'];
  order: Scalars['Int'];
}>;


export type UpdateSurveyTemplateSectionQuestionOptionMutation = (
  { __typename?: 'Mutation' }
  & { updateSurveyTemplateSectionQuestionOption: (
    { __typename?: 'SurveyTemplateSectionQuestionOption' }
    & Pick<SurveyTemplateSectionQuestionOption, 'id' | 'value' | 'order'>
  ) }
);

export type CreateSeasonSurveyMutationVariables = Exact<{
  orgId: Scalars['String'];
  seasonId: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  audience: SurveyAudiences;
  sendDelayMinutes: Scalars['Int'];
  reminderType: SurveyReminderTypes;
  dailyReminders?: Maybe<SurveyDailyRemindersInput>;
  levelReminders?: Maybe<SurveyLevelRemindersInput>;
  recipientAssessmentObjectId?: Maybe<Scalars['String']>;
}>;


export type CreateSeasonSurveyMutation = (
  { __typename?: 'Mutation' }
  & { createSeasonSurvey: (
    { __typename?: 'Survey' }
    & Pick<Survey, 'id' | 'sport' | 'status' | 'activatedAt' | 'createdAt' | 'updatedAt' | 'orgId' | 'seasonId' | 'hasCompetitionVerification' | 'hasIncidentReport'>
    & { additionsConfig: (
      { __typename?: 'SurveyAdditionsConfig' }
      & Pick<SurveyAdditionsConfig, 'allowAdditionalIncidentReports' | 'allowAdditionalIncidentReportsCutoffDays'>
    ), kiVideoIntegration?: Maybe<(
      { __typename?: 'KeyIncidentVideoIntegration' }
      & Pick<KeyIncidentVideoIntegration, 'id' | 'orgId' | 'orgName' | 'orgAbbreviation' | 'name' | 'description' | 'status' | 'queueName' | 'createdAt' | 'updatedAt'>
    )>, recipients: Array<(
      { __typename?: 'SurveyAssessmentObjectRecipient' }
      & Pick<SurveyAssessmentObjectRecipient, 'id' | 'dynamicSurveyId' | 'surveyId'>
      & { assessmentObject: (
        { __typename?: 'AssessmentObject' }
        & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name'>
      ) }
    )>, keyIncidentRecipients: Array<(
      { __typename?: 'SurveyKeyIncidentRecipient' }
      & Pick<SurveyKeyIncidentRecipient, 'id' | 'orgMembershipId' | 'orgId' | 'orgName' | 'orgAbbreviation' | 'orgLogo' | 'title' | 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic'>
    )>, info: (
      { __typename?: 'SurveyInfo' }
      & Pick<SurveyInfo, 'name' | 'description' | 'audience' | 'sendDelayMinutes'>
    ), reminders: (
      { __typename?: 'SurveyRemindersConfig' }
      & Pick<SurveyRemindersConfig, 'type'>
      & { dailyReminders?: Maybe<(
        { __typename?: 'SurveyDailyRemindersConfig' }
        & Pick<SurveyDailyRemindersConfig, 'sendReminders' | 'sendReminderDays' | 'sendReminderCutoffDays'>
      )>, levelReminders?: Maybe<(
        { __typename?: 'SurveyLevelRemindersConfig' }
        & Pick<SurveyLevelRemindersConfig, 'sendRemindersLevel1' | 'sendReminderDaysLevel1' | 'sendRemindersLevel2' | 'sendReminderDaysLevel2' | 'sendRemindersLevel3' | 'sendReminderDaysLevel3'>
      )> }
    ), sections: Array<(
      { __typename?: 'SurveySection' }
      & Pick<SurveySection, 'id' | 'name' | 'description' | 'order' | 'createdAt' | 'updatedAt'>
      & { questions: Array<(
        { __typename?: 'SurveySectionQuestion' }
        & Pick<SurveySectionQuestion, 'id' | 'name' | 'description' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType'>
        & { options: Array<(
          { __typename?: 'SurveySectionQuestionOption' }
          & Pick<SurveySectionQuestionOption, 'id' | 'value' | 'order'>
        )> }
      )>, assessmentObject: (
        { __typename?: 'AssessmentObject' }
        & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name'>
      ) }
    )> }
  ) }
);

export type CreateSeasonSurveyFromTemplateMutationVariables = Exact<{
  templateId: Scalars['String'];
  orgId: Scalars['String'];
  seasonId: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  sendDelayMinutes: Scalars['Int'];
  reminderType: SurveyReminderTypes;
  dailyReminders?: Maybe<SurveyDailyRemindersInput>;
  levelReminders?: Maybe<SurveyLevelRemindersInput>;
  orgMembershipId?: Maybe<Scalars['String']>;
  recipientAssessmentObjectId?: Maybe<Scalars['String']>;
}>;


export type CreateSeasonSurveyFromTemplateMutation = (
  { __typename?: 'Mutation' }
  & { createSeasonSurveyFromTemplate: (
    { __typename?: 'Survey' }
    & Pick<Survey, 'id' | 'sport' | 'status' | 'activatedAt' | 'createdAt' | 'updatedAt' | 'orgId' | 'seasonId' | 'hasCompetitionVerification' | 'hasIncidentReport'>
    & { additionsConfig: (
      { __typename?: 'SurveyAdditionsConfig' }
      & Pick<SurveyAdditionsConfig, 'allowAdditionalIncidentReports' | 'allowAdditionalIncidentReportsCutoffDays'>
    ), kiVideoIntegration?: Maybe<(
      { __typename?: 'KeyIncidentVideoIntegration' }
      & Pick<KeyIncidentVideoIntegration, 'id' | 'orgId' | 'orgName' | 'orgAbbreviation' | 'name' | 'description' | 'status' | 'queueName' | 'createdAt' | 'updatedAt'>
    )>, recipients: Array<(
      { __typename?: 'SurveyAssessmentObjectRecipient' }
      & Pick<SurveyAssessmentObjectRecipient, 'id' | 'dynamicSurveyId' | 'surveyId'>
      & { assessmentObject: (
        { __typename?: 'AssessmentObject' }
        & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name'>
      ) }
    )>, keyIncidentRecipients: Array<(
      { __typename?: 'SurveyKeyIncidentRecipient' }
      & Pick<SurveyKeyIncidentRecipient, 'id' | 'orgMembershipId' | 'orgId' | 'orgName' | 'orgAbbreviation' | 'orgLogo' | 'title' | 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic'>
    )>, info: (
      { __typename?: 'SurveyInfo' }
      & Pick<SurveyInfo, 'name' | 'description' | 'audience' | 'sendDelayMinutes'>
    ), reminders: (
      { __typename?: 'SurveyRemindersConfig' }
      & Pick<SurveyRemindersConfig, 'type'>
      & { dailyReminders?: Maybe<(
        { __typename?: 'SurveyDailyRemindersConfig' }
        & Pick<SurveyDailyRemindersConfig, 'sendReminders' | 'sendReminderDays' | 'sendReminderCutoffDays'>
      )>, levelReminders?: Maybe<(
        { __typename?: 'SurveyLevelRemindersConfig' }
        & Pick<SurveyLevelRemindersConfig, 'sendRemindersLevel1' | 'sendReminderDaysLevel1' | 'sendRemindersLevel2' | 'sendReminderDaysLevel2' | 'sendRemindersLevel3' | 'sendReminderDaysLevel3'>
      )> }
    ), sections: Array<(
      { __typename?: 'SurveySection' }
      & Pick<SurveySection, 'id' | 'name' | 'description' | 'order' | 'createdAt' | 'updatedAt'>
      & { questions: Array<(
        { __typename?: 'SurveySectionQuestion' }
        & Pick<SurveySectionQuestion, 'id' | 'name' | 'description' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType'>
        & { options: Array<(
          { __typename?: 'SurveySectionQuestionOption' }
          & Pick<SurveySectionQuestionOption, 'id' | 'value' | 'order'>
        )> }
      )>, assessmentObject: (
        { __typename?: 'AssessmentObject' }
        & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name'>
      ) }
    )> }
  ) }
);

export type GetSurveyByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetSurveyByIdQuery = (
  { __typename?: 'Query' }
  & { getSurveyById: (
    { __typename?: 'Survey' }
    & Pick<Survey, 'id' | 'sport' | 'status' | 'activatedAt' | 'createdAt' | 'updatedAt' | 'orgId' | 'seasonId' | 'hasCompetitionVerification' | 'hasIncidentReport'>
    & { additionsConfig: (
      { __typename?: 'SurveyAdditionsConfig' }
      & Pick<SurveyAdditionsConfig, 'allowAdditionalIncidentReports' | 'allowAdditionalIncidentReportsCutoffDays'>
    ), kiVideoIntegration?: Maybe<(
      { __typename?: 'KeyIncidentVideoIntegration' }
      & Pick<KeyIncidentVideoIntegration, 'id' | 'orgId' | 'orgName' | 'orgAbbreviation' | 'name' | 'description' | 'status' | 'queueName' | 'createdAt' | 'updatedAt'>
    )>, recipients: Array<(
      { __typename?: 'SurveyAssessmentObjectRecipient' }
      & Pick<SurveyAssessmentObjectRecipient, 'id' | 'dynamicSurveyId' | 'surveyId'>
      & { assessmentObject: (
        { __typename?: 'AssessmentObject' }
        & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name'>
      ) }
    )>, keyIncidentRecipients: Array<(
      { __typename?: 'SurveyKeyIncidentRecipient' }
      & Pick<SurveyKeyIncidentRecipient, 'id' | 'orgMembershipId' | 'orgId' | 'orgName' | 'orgAbbreviation' | 'orgLogo' | 'title' | 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic'>
    )>, info: (
      { __typename?: 'SurveyInfo' }
      & Pick<SurveyInfo, 'name' | 'description' | 'audience' | 'sendDelayMinutes'>
    ), reminders: (
      { __typename?: 'SurveyRemindersConfig' }
      & Pick<SurveyRemindersConfig, 'type'>
      & { dailyReminders?: Maybe<(
        { __typename?: 'SurveyDailyRemindersConfig' }
        & Pick<SurveyDailyRemindersConfig, 'sendReminders' | 'sendReminderDays' | 'sendReminderCutoffDays'>
      )>, levelReminders?: Maybe<(
        { __typename?: 'SurveyLevelRemindersConfig' }
        & Pick<SurveyLevelRemindersConfig, 'sendRemindersLevel1' | 'sendReminderDaysLevel1' | 'sendRemindersLevel2' | 'sendReminderDaysLevel2' | 'sendRemindersLevel3' | 'sendReminderDaysLevel3'>
      )> }
    ), sections: Array<(
      { __typename?: 'SurveySection' }
      & Pick<SurveySection, 'id' | 'name' | 'description' | 'order' | 'createdAt' | 'updatedAt'>
      & { questions: Array<(
        { __typename?: 'SurveySectionQuestion' }
        & Pick<SurveySectionQuestion, 'id' | 'name' | 'description' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType'>
        & { options: Array<(
          { __typename?: 'SurveySectionQuestionOption' }
          & Pick<SurveySectionQuestionOption, 'id' | 'value' | 'order'>
        )> }
      )>, assessmentObject: (
        { __typename?: 'AssessmentObject' }
        & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name'>
      ) }
    )> }
  ) }
);

export type ListSurveysForOrganizationQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  orgId: Scalars['String'];
  seasonId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<SurveyStatuses>;
  audience?: Maybe<SurveyAudiences>;
}>;


export type ListSurveysForOrganizationQuery = (
  { __typename?: 'Query' }
  & { listSurveysForOrganization: (
    { __typename?: 'Surveys' }
    & { surveys: Array<(
      { __typename?: 'Survey' }
      & Pick<Survey, 'id' | 'sport' | 'status' | 'orgId' | 'hasCompetitionVerification' | 'hasIncidentReport' | 'activatedAt' | 'createdAt' | 'updatedAt'>
      & { additionsConfig: (
        { __typename?: 'SurveyAdditionsConfig' }
        & Pick<SurveyAdditionsConfig, 'allowAdditionalIncidentReports' | 'allowAdditionalIncidentReportsCutoffDays'>
      ), kiVideoIntegration?: Maybe<(
        { __typename?: 'KeyIncidentVideoIntegration' }
        & Pick<KeyIncidentVideoIntegration, 'id' | 'orgId' | 'orgName' | 'orgAbbreviation' | 'name' | 'description' | 'status' | 'queueName' | 'createdAt' | 'updatedAt'>
      )>, recipients: Array<(
        { __typename?: 'SurveyAssessmentObjectRecipient' }
        & Pick<SurveyAssessmentObjectRecipient, 'id' | 'dynamicSurveyId' | 'surveyId'>
        & { assessmentObject: (
          { __typename?: 'AssessmentObject' }
          & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name'>
        ) }
      )>, keyIncidentRecipients: Array<(
        { __typename?: 'SurveyKeyIncidentRecipient' }
        & Pick<SurveyKeyIncidentRecipient, 'id' | 'orgMembershipId' | 'orgId' | 'orgName' | 'orgAbbreviation' | 'orgLogo' | 'title' | 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic'>
      )>, info: (
        { __typename?: 'SurveyInfo' }
        & Pick<SurveyInfo, 'name' | 'description' | 'audience' | 'sendDelayMinutes'>
      ), reminders: (
        { __typename?: 'SurveyRemindersConfig' }
        & Pick<SurveyRemindersConfig, 'type'>
        & { dailyReminders?: Maybe<(
          { __typename?: 'SurveyDailyRemindersConfig' }
          & Pick<SurveyDailyRemindersConfig, 'sendReminders' | 'sendReminderDays' | 'sendReminderCutoffDays'>
        )>, levelReminders?: Maybe<(
          { __typename?: 'SurveyLevelRemindersConfig' }
          & Pick<SurveyLevelRemindersConfig, 'sendRemindersLevel1' | 'sendReminderDaysLevel1' | 'sendRemindersLevel2' | 'sendReminderDaysLevel2' | 'sendRemindersLevel3' | 'sendReminderDaysLevel3'>
        )> }
      ), sections: Array<(
        { __typename?: 'SurveySection' }
        & Pick<SurveySection, 'id' | 'name' | 'description' | 'order' | 'createdAt' | 'updatedAt'>
        & { questions: Array<(
          { __typename?: 'SurveySectionQuestion' }
          & Pick<SurveySectionQuestion, 'id' | 'name' | 'description' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType'>
          & { options: Array<(
            { __typename?: 'SurveySectionQuestionOption' }
            & Pick<SurveySectionQuestionOption, 'id' | 'value' | 'order'>
          )> }
        )>, assessmentObject: (
          { __typename?: 'AssessmentObject' }
          & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name'>
        ) }
      )> }
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type DeleteSurveyMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteSurveyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSurvey'>
);

export type ActivateSurveyMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type ActivateSurveyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'activateSurvey'>
);

export type DeactivateSurveyMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeactivateSurveyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deactivateSurvey'>
);

export type UpdateSurveyInfoMutationVariables = Exact<{
  surveyId: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  audience: SurveyAudiences;
  sendDelayMinutes: Scalars['Int'];
}>;


export type UpdateSurveyInfoMutation = (
  { __typename?: 'Mutation' }
  & { updateSurveyInfo: (
    { __typename?: 'SurveyInfo' }
    & Pick<SurveyInfo, 'name' | 'description' | 'audience' | 'sendDelayMinutes'>
  ) }
);

export type SetSurveyCompetitionVerificationMutationVariables = Exact<{
  surveyId: Scalars['String'];
  enabled: Scalars['Boolean'];
}>;


export type SetSurveyCompetitionVerificationMutation = (
  { __typename?: 'Mutation' }
  & { setSurveyCompetitionVerification: (
    { __typename?: 'SurveyCompetitionVerification' }
    & Pick<SurveyCompetitionVerification, 'enabled'>
  ) }
);

export type UpdateSurveyRemindersMutationVariables = Exact<{
  surveyId: Scalars['String'];
  type: SurveyReminderTypes;
  dailyReminders?: Maybe<SurveyDailyRemindersInput>;
  levelReminders?: Maybe<SurveyLevelRemindersInput>;
}>;


export type UpdateSurveyRemindersMutation = (
  { __typename?: 'Mutation' }
  & { updateSurveyReminders: (
    { __typename?: 'SurveyRemindersConfig' }
    & Pick<SurveyRemindersConfig, 'type'>
    & { dailyReminders?: Maybe<(
      { __typename?: 'SurveyDailyRemindersConfig' }
      & Pick<SurveyDailyRemindersConfig, 'sendReminders' | 'sendReminderDays' | 'sendReminderCutoffDays'>
    )>, levelReminders?: Maybe<(
      { __typename?: 'SurveyLevelRemindersConfig' }
      & Pick<SurveyLevelRemindersConfig, 'sendRemindersLevel1' | 'sendReminderDaysLevel1' | 'sendRemindersLevel2' | 'sendReminderDaysLevel2' | 'sendRemindersLevel3' | 'sendReminderDaysLevel3'>
    )> }
  ) }
);

export type ListReminderLevelRecipientsForSurveyQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  surveyId: Scalars['String'];
}>;


export type ListReminderLevelRecipientsForSurveyQuery = (
  { __typename?: 'Query' }
  & { listReminderLevelRecipientsForSurvey: (
    { __typename?: 'SurveyReminderLevelRecipients' }
    & { recipients: Array<(
      { __typename?: 'SurveyReminderLevelRecipient' }
      & Pick<SurveyReminderLevelRecipient, 'id' | 'orgMembershipId' | 'orgId' | 'orgName' | 'orgAbbreviation' | 'orgLogo' | 'title' | 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'level1Recipient' | 'level2Recipient' | 'level3Recipient'>
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type AddSurveyReminderLevelReportRecipientMutationVariables = Exact<{
  surveyId: Scalars['String'];
  orgMembershipId: Scalars['String'];
  level1Recipient: Scalars['Boolean'];
  level2Recipient: Scalars['Boolean'];
  level3Recipient: Scalars['Boolean'];
}>;


export type AddSurveyReminderLevelReportRecipientMutation = (
  { __typename?: 'Mutation' }
  & { addSurveyReminderLevelReportRecipient: (
    { __typename?: 'SurveyReminderLevelRecipient' }
    & Pick<SurveyReminderLevelRecipient, 'id' | 'orgMembershipId' | 'orgId' | 'orgName' | 'orgAbbreviation' | 'orgLogo' | 'title' | 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'level1Recipient' | 'level2Recipient' | 'level3Recipient'>
  ) }
);

export type UpdateSurveyReminderLevelRecipientMutationVariables = Exact<{
  recipientId: Scalars['String'];
  level1Recipient: Scalars['Boolean'];
  level2Recipient: Scalars['Boolean'];
  level3Recipient: Scalars['Boolean'];
}>;


export type UpdateSurveyReminderLevelRecipientMutation = (
  { __typename?: 'Mutation' }
  & { updateSurveyReminderLevelRecipient: (
    { __typename?: 'SurveyReminderLevelRecipient' }
    & Pick<SurveyReminderLevelRecipient, 'id' | 'orgMembershipId' | 'orgId' | 'orgName' | 'orgAbbreviation' | 'orgLogo' | 'title' | 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'level1Recipient' | 'level2Recipient' | 'level3Recipient'>
  ) }
);

export type DeleteSurveyReminderLevelRecipientMutationVariables = Exact<{
  recipientId: Scalars['String'];
}>;


export type DeleteSurveyReminderLevelRecipientMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSurveyReminderLevelRecipient'>
);

export type AddSurveyAssessmentObjectRecipientMutationVariables = Exact<{
  surveyId: Scalars['String'];
  assessmentObjectId: Scalars['String'];
}>;


export type AddSurveyAssessmentObjectRecipientMutation = (
  { __typename?: 'Mutation' }
  & { addSurveyAssessmentObjectRecipient: (
    { __typename?: 'SurveyAssessmentObjectRecipient' }
    & Pick<SurveyAssessmentObjectRecipient, 'id' | 'dynamicSurveyId' | 'surveyId'>
    & { assessmentObject: (
      { __typename?: 'AssessmentObject' }
      & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name'>
    ) }
  ) }
);

export type DeleteSurveyAssessmentObjectRecipientMutationVariables = Exact<{
  recipientId: Scalars['String'];
}>;


export type DeleteSurveyAssessmentObjectRecipientMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSurveyAssessmentObjectRecipient'>
);

export type ListAvailableAssessmentObjectsForSurveysRecipientsQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  surveyId: Scalars['String'];
}>;


export type ListAvailableAssessmentObjectsForSurveysRecipientsQuery = (
  { __typename?: 'Query' }
  & { listAvailableAssessmentObjectsForSurveysRecipients: (
    { __typename?: 'AssessmentObjects' }
    & { assessmentObjects: Array<(
      { __typename?: 'AssessmentObject' }
      & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name'>
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type AddSurveyIncidentReportMutationVariables = Exact<{
  surveyId: Scalars['String'];
  orgMembershipId: Scalars['String'];
}>;


export type AddSurveyIncidentReportMutation = (
  { __typename?: 'Mutation' }
  & { addSurveyIncidentReport: (
    { __typename?: 'SurveyKeyIncidentRecipient' }
    & Pick<SurveyKeyIncidentRecipient, 'id' | 'orgMembershipId' | 'orgId' | 'orgName' | 'orgAbbreviation' | 'orgLogo' | 'title' | 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic'>
  ) }
);

export type DeleteSurveyIncidentReportMutationVariables = Exact<{
  surveyId: Scalars['String'];
}>;


export type DeleteSurveyIncidentReportMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSurveyIncidentReport'>
);

export type AddSurveyIncidentReportRecipientMutationVariables = Exact<{
  surveyId: Scalars['String'];
  orgMembershipId: Scalars['String'];
}>;


export type AddSurveyIncidentReportRecipientMutation = (
  { __typename?: 'Mutation' }
  & { addSurveyIncidentReportRecipient: (
    { __typename?: 'SurveyKeyIncidentRecipient' }
    & Pick<SurveyKeyIncidentRecipient, 'id' | 'orgMembershipId' | 'orgId' | 'orgName' | 'orgAbbreviation' | 'orgLogo' | 'title' | 'userId' | 'email' | 'firstName' | 'lastName' | 'profilePic'>
  ) }
);

export type DeleteSurveyIncidentReportRecipientMutationVariables = Exact<{
  recipientId: Scalars['String'];
}>;


export type DeleteSurveyIncidentReportRecipientMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSurveyIncidentReportRecipient'>
);

export type AddKeyIncidentVideoIntegrationToSurveyMutationVariables = Exact<{
  surveyId: Scalars['String'];
  integrationId: Scalars['String'];
}>;


export type AddKeyIncidentVideoIntegrationToSurveyMutation = (
  { __typename?: 'Mutation' }
  & { addKeyIncidentVideoIntegrationToSurvey: (
    { __typename?: 'KeyIncidentVideoIntegration' }
    & Pick<KeyIncidentVideoIntegration, 'id' | 'orgId' | 'orgName' | 'orgAbbreviation' | 'name' | 'description' | 'status' | 'queueName' | 'createdAt' | 'updatedAt'>
  ) }
);

export type RemoveKeyIncidentVideoIntegrationFromSurveyMutationVariables = Exact<{
  surveyId: Scalars['String'];
}>;


export type RemoveKeyIncidentVideoIntegrationFromSurveyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeKeyIncidentVideoIntegrationFromSurvey'>
);

export type SetSurveyAllowAdditionalIncidentReportsMutationVariables = Exact<{
  surveyId: Scalars['String'];
  allowAdditions: Scalars['Boolean'];
}>;


export type SetSurveyAllowAdditionalIncidentReportsMutation = (
  { __typename?: 'Mutation' }
  & { setSurveyAllowAdditionalIncidentReports: (
    { __typename?: 'SurveyAdditionsConfig' }
    & Pick<SurveyAdditionsConfig, 'allowAdditionalIncidentReports' | 'allowAdditionalIncidentReportsCutoffDays'>
  ) }
);

export type SetSurveyAllowAdditionalIncidentReportsCutoffDaysMutationVariables = Exact<{
  surveyId: Scalars['String'];
  allowAdditionsCutoffDays?: Maybe<Scalars['Int']>;
}>;


export type SetSurveyAllowAdditionalIncidentReportsCutoffDaysMutation = (
  { __typename?: 'Mutation' }
  & { setSurveyAllowAdditionalIncidentReportsCutoffDays: (
    { __typename?: 'SurveyAdditionsConfig' }
    & Pick<SurveyAdditionsConfig, 'allowAdditionalIncidentReports' | 'allowAdditionalIncidentReportsCutoffDays'>
  ) }
);

export type AddSurveySectionMutationVariables = Exact<{
  surveyId: Scalars['String'];
  assessmentObjectId: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
}>;


export type AddSurveySectionMutation = (
  { __typename?: 'Mutation' }
  & { addSurveySection: (
    { __typename?: 'SurveySection' }
    & Pick<SurveySection, 'id' | 'name' | 'description' | 'order' | 'createdAt' | 'updatedAt'>
    & { questions: Array<(
      { __typename?: 'SurveySectionQuestion' }
      & Pick<SurveySectionQuestion, 'id' | 'name' | 'description' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType'>
      & { options: Array<(
        { __typename?: 'SurveySectionQuestionOption' }
        & Pick<SurveySectionQuestionOption, 'id' | 'value' | 'order'>
      )> }
    )>, assessmentObject: (
      { __typename?: 'AssessmentObject' }
      & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name'>
    ) }
  ) }
);

export type UpdateSurveySectionMutationVariables = Exact<{
  sectionId: Scalars['String'];
  assessmentObjectId: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  order: Scalars['Int'];
}>;


export type UpdateSurveySectionMutation = (
  { __typename?: 'Mutation' }
  & { updateSurveySection: (
    { __typename?: 'SurveySection' }
    & Pick<SurveySection, 'id' | 'name' | 'description' | 'order' | 'createdAt' | 'updatedAt'>
    & { questions: Array<(
      { __typename?: 'SurveySectionQuestion' }
      & Pick<SurveySectionQuestion, 'id' | 'name' | 'description' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType'>
      & { options: Array<(
        { __typename?: 'SurveySectionQuestionOption' }
        & Pick<SurveySectionQuestionOption, 'id' | 'value' | 'order'>
      )> }
    )>, assessmentObject: (
      { __typename?: 'AssessmentObject' }
      & Pick<AssessmentObject, 'id' | 'sport' | 'type' | 'classification' | 'name'>
    ) }
  ) }
);

export type DeleteSurveySectionMutationVariables = Exact<{
  sectionId: Scalars['String'];
}>;


export type DeleteSurveySectionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSurveySection'>
);

export type AddSurveySectionQuestionMutationVariables = Exact<{
  sectionId: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  isRequired: Scalars['Boolean'];
  questionType: SurveyQuestionTypes;
}>;


export type AddSurveySectionQuestionMutation = (
  { __typename?: 'Mutation' }
  & { addSurveySectionQuestion: (
    { __typename?: 'SurveySectionQuestion' }
    & Pick<SurveySectionQuestion, 'id' | 'name' | 'description' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType' | 'createdAt' | 'updatedAt'>
    & { options: Array<(
      { __typename?: 'SurveySectionQuestionOption' }
      & Pick<SurveySectionQuestionOption, 'id' | 'value' | 'order'>
    )> }
  ) }
);

export type UpdateSurveySectionQuestionMutationVariables = Exact<{
  questionId: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  isRequired: Scalars['Boolean'];
  order: Scalars['Int'];
}>;


export type UpdateSurveySectionQuestionMutation = (
  { __typename?: 'Mutation' }
  & { updateSurveySectionQuestion: (
    { __typename?: 'SurveySectionQuestion' }
    & Pick<SurveySectionQuestion, 'id' | 'name' | 'description' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType' | 'createdAt' | 'updatedAt'>
    & { options: Array<(
      { __typename?: 'SurveySectionQuestionOption' }
      & Pick<SurveySectionQuestionOption, 'id' | 'value' | 'order'>
    )> }
  ) }
);

export type DeleteSurveySectionQuestionMutationVariables = Exact<{
  questionId: Scalars['String'];
}>;


export type DeleteSurveySectionQuestionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSurveySectionQuestion'>
);

export type SetSurveySectionQuestionCanAddToKeyIncidentsMutationVariables = Exact<{
  questionId: Scalars['String'];
  canAddToKeyIncidents: Scalars['Boolean'];
}>;


export type SetSurveySectionQuestionCanAddToKeyIncidentsMutation = (
  { __typename?: 'Mutation' }
  & { setSurveySectionQuestionCanAddToKeyIncidents: (
    { __typename?: 'SurveySectionQuestion' }
    & Pick<SurveySectionQuestion, 'id' | 'name' | 'description' | 'order' | 'isRequired' | 'canAddToKeyIncidents' | 'questionType' | 'createdAt' | 'updatedAt'>
    & { options: Array<(
      { __typename?: 'SurveySectionQuestionOption' }
      & Pick<SurveySectionQuestionOption, 'id' | 'value' | 'order'>
    )> }
  ) }
);

export type AddSurveySectionQuestionOptionMutationVariables = Exact<{
  questionId: Scalars['String'];
  value: Scalars['String'];
}>;


export type AddSurveySectionQuestionOptionMutation = (
  { __typename?: 'Mutation' }
  & { addSurveySectionQuestionOption: (
    { __typename?: 'SurveySectionQuestionOption' }
    & Pick<SurveySectionQuestionOption, 'id' | 'value' | 'order'>
  ) }
);

export type DeleteSurveySectionQuestionOptionMutationVariables = Exact<{
  optionId: Scalars['String'];
}>;


export type DeleteSurveySectionQuestionOptionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSurveySectionQuestionOption'>
);

export type UpdateSurveySectionQuestionOptionMutationVariables = Exact<{
  optionId: Scalars['String'];
  value: Scalars['String'];
  order: Scalars['Int'];
}>;


export type UpdateSurveySectionQuestionOptionMutation = (
  { __typename?: 'Mutation' }
  & { updateSurveySectionQuestionOption: (
    { __typename?: 'SurveySectionQuestionOption' }
    & Pick<SurveySectionQuestionOption, 'id' | 'value' | 'order'>
  ) }
);

export type GetTeamByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetTeamByIdQuery = (
  { __typename?: 'Query' }
  & { getTeamById: (
    { __typename?: 'Team' }
    & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'classification' | 'gender' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'timeZone' | 'website' | 'logo' | 'createdAt' | 'updatedAt'>
  ) }
);

export type UpdateTeamForSeasonMutationVariables = Exact<{
  organizationId: Scalars['String'];
  seasonId: Scalars['String'];
  teamId: Scalars['String'];
  name: Scalars['String'];
  abbreviation?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  stateAbbreviation?: Maybe<Scalars['String']>;
  countryCode: CountryCodes;
  timeZone: Scalars['String'];
  classification: TeamClassifications;
  gender: TeamGenders;
  parentOrganizationName?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  ageGroup?: Maybe<Scalars['String']>;
}>;


export type UpdateTeamForSeasonMutation = (
  { __typename?: 'Mutation' }
  & { updateTeamForSeason: (
    { __typename?: 'Team' }
    & Pick<Team, 'id' | 'name' | 'abbreviation' | 'sport' | 'city' | 'stateAbbreviation' | 'countryCode' | 'timeZone' | 'website' | 'logo' | 'classification' | 'gender' | 'parentOrganizationName' | 'externalId' | 'ageGroup' | 'createdAt' | 'updatedAt'>
  ) }
);

export type ListTeamCoachesQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  teamId: Scalars['String'];
  search?: Maybe<Scalars['String']>;
  type?: Maybe<CoachTypes>;
  status?: Maybe<CoachStatuses>;
  role?: Maybe<Scalars['String']>;
  userStatus?: Maybe<UserStatuses>;
}>;


export type ListTeamCoachesQuery = (
  { __typename?: 'Query' }
  & { listTeamCoaches: (
    { __typename?: 'TeamCoaches' }
    & Pick<TeamCoaches, 'teamId'>
    & { coaches: Array<(
      { __typename?: 'Coach' }
      & Pick<Coach, 'id' | 'teamId' | 'teamName' | 'teamAbbreviation' | 'sport' | 'teamCity' | 'userId' | 'type' | 'role' | 'status' | 'email' | 'firstName' | 'lastName' | 'profilePic' | 'userStatus' | 'createdAt' | 'updatedAt'>
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type CreateTeamAliasMutationVariables = Exact<{
  teamId: Scalars['String'];
  alias: Scalars['String'];
}>;


export type CreateTeamAliasMutation = (
  { __typename?: 'Mutation' }
  & { createTeamAlias: (
    { __typename?: 'TeamAlias' }
    & Pick<TeamAlias, 'id' | 'teamId' | 'alias'>
  ) }
);

export type ListTeamAliasesQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  teamId: Scalars['String'];
  alias?: Maybe<Scalars['String']>;
}>;


export type ListTeamAliasesQuery = (
  { __typename?: 'Query' }
  & { listTeamAliases: (
    { __typename?: 'TeamAliases' }
    & Pick<TeamAliases, 'teamId'>
    & { aliases: Array<(
      { __typename?: 'TeamAlias' }
      & Pick<TeamAlias, 'id' | 'teamId' | 'alias'>
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type UpdateTeamAliasMutationVariables = Exact<{
  id: Scalars['String'];
  alias: Scalars['String'];
}>;


export type UpdateTeamAliasMutation = (
  { __typename?: 'Mutation' }
  & { updateTeamAlias: (
    { __typename?: 'TeamAlias' }
    & Pick<TeamAlias, 'id' | 'teamId' | 'alias'>
  ) }
);

export type DeleteTeamAliasMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteTeamAliasMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteTeamAlias'>
);

export type ListContactsForTeamForSeasonQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  organizationId: Scalars['String'];
  seasonId: Scalars['String'];
  teamId: Scalars['String'];
  search?: Maybe<Scalars['String']>;
  activeOnly?: Maybe<Scalars['Boolean']>;
}>;


export type ListContactsForTeamForSeasonQuery = (
  { __typename?: 'Query' }
  & { listContactsForTeamForSeason: (
    { __typename?: 'TeamContacts' }
    & Pick<TeamContacts, 'teamId' | 'teamName'>
    & { contacts: Array<(
      { __typename?: 'TeamContact' }
      & Pick<TeamContact, 'id' | 'firstName' | 'lastName' | 'isActive' | 'email' | 'phone' | 'createdAt' | 'updatedAt'>
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type CreateTeamContactForSeasonMutationVariables = Exact<{
  organizationId: Scalars['String'];
  seasonId: Scalars['String'];
  teamId: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
}>;


export type CreateTeamContactForSeasonMutation = (
  { __typename?: 'Mutation' }
  & { createTeamContactForSeason: (
    { __typename?: 'TeamContact' }
    & Pick<TeamContact, 'id' | 'firstName' | 'lastName' | 'isActive' | 'email' | 'phone' | 'createdAt' | 'updatedAt'>
  ) }
);

export type UpdateTeamContactForSeasonMutationVariables = Exact<{
  organizationId: Scalars['String'];
  seasonId: Scalars['String'];
  teamId: Scalars['String'];
  contactId: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
}>;


export type UpdateTeamContactForSeasonMutation = (
  { __typename?: 'Mutation' }
  & { updateTeamContactForSeason: (
    { __typename?: 'TeamContact' }
    & Pick<TeamContact, 'id' | 'firstName' | 'lastName' | 'isActive' | 'email' | 'phone' | 'createdAt' | 'updatedAt'>
  ) }
);

export type DeleteTeamContactForSeasonMutationVariables = Exact<{
  organizationId: Scalars['String'];
  seasonId: Scalars['String'];
  teamId: Scalars['String'];
  contactId: Scalars['String'];
}>;


export type DeleteTeamContactForSeasonMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteTeamContactForSeason'>
);

export type ListTeamKitRecipientsForSeasonTeamQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  organizationId: Scalars['String'];
  seasonId: Scalars['String'];
  teamId: Scalars['String'];
  search?: Maybe<Scalars['String']>;
  activeOnly?: Maybe<Scalars['Boolean']>;
}>;


export type ListTeamKitRecipientsForSeasonTeamQuery = (
  { __typename?: 'Query' }
  & { listTeamKitRecipientsForSeasonTeam: (
    { __typename?: 'TeamKitTeamRecipients' }
    & Pick<TeamKitTeamRecipients, 'teamId'>
    & { recipients: Array<(
      { __typename?: 'TeamKitTeamRecipient' }
      & Pick<TeamKitTeamRecipient, 'id' | 'teamId' | 'teamName' | 'teamAbbreviation' | 'teamLogo' | 'teamCity' | 'role' | 'userId' | 'firstName' | 'lastName' | 'isActive' | 'email' | 'profilePic' | 'userStatus' | 'createdAt' | 'updatedAt'>
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type CreateTeamKitTeamRecipientForSeasonMutationVariables = Exact<{
  organizationId: Scalars['String'];
  seasonId: Scalars['String'];
  teamId: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  addToExistingCompetitions: Scalars['Boolean'];
  role?: Maybe<Scalars['String']>;
}>;


export type CreateTeamKitTeamRecipientForSeasonMutation = (
  { __typename?: 'Mutation' }
  & { createTeamKitTeamRecipientForSeason: (
    { __typename?: 'TeamKitTeamRecipient' }
    & Pick<TeamKitTeamRecipient, 'id' | 'teamId' | 'teamName' | 'teamAbbreviation' | 'teamLogo' | 'teamCity' | 'role' | 'userId' | 'firstName' | 'lastName' | 'isActive' | 'email' | 'profilePic' | 'userStatus' | 'createdAt' | 'updatedAt'>
  ) }
);

export type UpdateTeamKitTeamRecipientForSeasonMutationVariables = Exact<{
  organizationId: Scalars['String'];
  seasonId: Scalars['String'];
  teamId: Scalars['String'];
  recipientId: Scalars['String'];
  role?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
}>;


export type UpdateTeamKitTeamRecipientForSeasonMutation = (
  { __typename?: 'Mutation' }
  & { updateTeamKitTeamRecipientForSeason: (
    { __typename?: 'TeamKitTeamRecipient' }
    & Pick<TeamKitTeamRecipient, 'id' | 'teamId' | 'teamName' | 'teamAbbreviation' | 'teamLogo' | 'teamCity' | 'role' | 'userId' | 'firstName' | 'lastName' | 'isActive' | 'email' | 'profilePic' | 'userStatus' | 'createdAt' | 'updatedAt'>
  ) }
);

export type ActivateSeasonTeamKitTeamRecipientMutationVariables = Exact<{
  organizationId: Scalars['String'];
  seasonId: Scalars['String'];
  teamId: Scalars['String'];
  recipientId: Scalars['String'];
  isActive: Scalars['Boolean'];
}>;


export type ActivateSeasonTeamKitTeamRecipientMutation = (
  { __typename?: 'Mutation' }
  & { activateSeasonTeamKitTeamRecipient: (
    { __typename?: 'TeamKitTeamRecipient' }
    & Pick<TeamKitTeamRecipient, 'id' | 'teamId' | 'teamName' | 'teamAbbreviation' | 'teamLogo' | 'teamCity' | 'role' | 'userId' | 'firstName' | 'lastName' | 'isActive' | 'email' | 'profilePic' | 'userStatus' | 'createdAt' | 'updatedAt'>
  ) }
);

export type ListTeamKitTeamSetupSummariesForSeasonQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  seasonId: Scalars['String'];
  search?: Maybe<Scalars['String']>;
}>;


export type ListTeamKitTeamSetupSummariesForSeasonQuery = (
  { __typename?: 'Query' }
  & { listTeamKitTeamSetupSummariesForSeason: (
    { __typename?: 'TeamKitSeasonTeamSetupSummaries' }
    & Pick<TeamKitSeasonTeamSetupSummaries, 'seasonId'>
    & { summaries: Array<(
      { __typename?: 'TeamKitSeasonTeamSetupSummary' }
      & Pick<TeamKitSeasonTeamSetupSummary, 'seasonId' | 'seasonName' | 'teamId' | 'teamName' | 'teamLogo' | 'teamRecipientsCount' | 'teamContactsCount' | 'teamHomeContactOptionsCount' | 'teamHomeContactOptionsTarget' | 'teamAwayContactOptionsCount' | 'teamAwayContactOptionsTarget' | 'teamBroadcastOptionsCount' | 'teamApparelSetCount' | 'teamTotalApparelSetTarget'>
    )>, pagination: (
      { __typename?: 'OffsetPagination' }
      & Pick<OffsetPagination, 'total' | 'size' | 'offset' | 'limit' | 'page' | 'totalPages'>
    ) }
  ) }
);

export type GetTeamKitTeamSetupSummaryForSeasonTeamQueryVariables = Exact<{
  seasonId: Scalars['String'];
  teamId: Scalars['String'];
}>;


export type GetTeamKitTeamSetupSummaryForSeasonTeamQuery = (
  { __typename?: 'Query' }
  & { getTeamKitTeamSetupSummaryForSeasonTeam: (
    { __typename?: 'TeamKitSeasonTeamSetupSummary' }
    & Pick<TeamKitSeasonTeamSetupSummary, 'seasonId' | 'seasonName' | 'teamId' | 'teamName' | 'teamLogo' | 'teamRecipientsCount' | 'teamHomeContactOptionsCount' | 'teamHomeContactOptionsTarget' | 'teamAwayContactOptionsCount' | 'teamAwayContactOptionsTarget' | 'teamContactsCount' | 'teamBroadcastOptionsCount' | 'teamApparelSetCount' | 'teamTotalApparelSetTarget'>
    & { apparelsForRoles?: Maybe<Array<(
      { __typename?: 'TeamKitSeasonTeamApparelByRoleSetupSummary' }
      & Pick<TeamKitSeasonTeamApparelByRoleSetupSummary, 'seasonId' | 'seasonName' | 'teamId' | 'teamName' | 'teamLogo' | 'roleId' | 'role' | 'roleOrder' | 'definitionId' | 'definition' | 'definitionOrder' | 'apparelCount'>
    )>> }
  ) }
);

export type UploadUserProfilePicMutationVariables = Exact<{
  file: Scalars['Upload'];
}>;


export type UploadUserProfilePicMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'uploadUserProfilePicture'>
);

export type UpdateProfileMutationVariables = Exact<{
  firstName: Scalars['String'];
  lastName: Scalars['String'];
}>;


export type UpdateProfileMutation = (
  { __typename?: 'Mutation' }
  & { updateMyProfile: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName'>
  ) }
);

export type ListUserNotificationSettingsQueryVariables = Exact<{
  notificationSettingsToken: Scalars['String'];
}>;


export type ListUserNotificationSettingsQuery = (
  { __typename?: 'Query' }
  & { listUserNotificationSettings: (
    { __typename?: 'UserNotificationSettings' }
    & Pick<UserNotificationSettings, 'userId'>
    & { settings: Array<(
      { __typename?: 'UserNotificationSetting' }
      & Pick<UserNotificationSetting, 'id' | 'setting' | 'enabled'>
    )> }
  ) }
);

export type MyNotificationSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type MyNotificationSettingsQuery = (
  { __typename?: 'Query' }
  & { myNotificationSettings: (
    { __typename?: 'UserNotificationSettings' }
    & Pick<UserNotificationSettings, 'userId'>
    & { settings: Array<(
      { __typename?: 'UserNotificationSetting' }
      & Pick<UserNotificationSetting, 'id' | 'setting' | 'enabled'>
    )> }
  ) }
);

export type UpdateUserNotificationSettingsMutationVariables = Exact<{
  notificationSettingsToken: Scalars['String'];
  enableSurveyNotifications: Scalars['Boolean'];
  enableIncidentReports: Scalars['Boolean'];
  enableSupplementalReports: Scalars['Boolean'];
  enableTeamKits: Scalars['Boolean'];
}>;


export type UpdateUserNotificationSettingsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateUserNotificationSettings'>
);

export type UpdateMyNotificationSettingsMutationVariables = Exact<{
  enableSurveyNotifications: Scalars['Boolean'];
  enableIncidentReports: Scalars['Boolean'];
  enableSupplementalReports: Scalars['Boolean'];
  enableTeamKits: Scalars['Boolean'];
}>;


export type UpdateMyNotificationSettingsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateMyNotificationSettings'>
);


export const CreateAssessmentObjectDocument = gql`
    mutation createAssessmentObject($sport: Sports!, $type: AssessmentObjectTypes!, $name: String!, $classification: AssessmentObjectClassifications) {
  createAssessmentObject(
    input: {sport: $sport, type: $type, name: $name, classification: $classification}
  ) {
    id
    sport
    type
    classification
    name
  }
}
    `;
export type CreateAssessmentObjectMutationFn = Apollo.MutationFunction<CreateAssessmentObjectMutation, CreateAssessmentObjectMutationVariables>;

/**
 * __useCreateAssessmentObjectMutation__
 *
 * To run a mutation, you first call `useCreateAssessmentObjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAssessmentObjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAssessmentObjectMutation, { data, loading, error }] = useCreateAssessmentObjectMutation({
 *   variables: {
 *      sport: // value for 'sport'
 *      type: // value for 'type'
 *      name: // value for 'name'
 *      classification: // value for 'classification'
 *   },
 * });
 */
export function useCreateAssessmentObjectMutation(baseOptions?: Apollo.MutationHookOptions<CreateAssessmentObjectMutation, CreateAssessmentObjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAssessmentObjectMutation, CreateAssessmentObjectMutationVariables>(CreateAssessmentObjectDocument, options);
      }
export type CreateAssessmentObjectMutationHookResult = ReturnType<typeof useCreateAssessmentObjectMutation>;
export type CreateAssessmentObjectMutationResult = Apollo.MutationResult<CreateAssessmentObjectMutation>;
export type CreateAssessmentObjectMutationOptions = Apollo.BaseMutationOptions<CreateAssessmentObjectMutation, CreateAssessmentObjectMutationVariables>;
export const UpdateAssessmentObjectDocument = gql`
    mutation updateAssessmentObject($id: String!, $type: AssessmentObjectTypes!, $name: String!, $classification: AssessmentObjectClassifications) {
  updateAssessmentObject(
    input: {id: $id, type: $type, name: $name, classification: $classification}
  ) {
    id
    sport
    type
    classification
    name
  }
}
    `;
export type UpdateAssessmentObjectMutationFn = Apollo.MutationFunction<UpdateAssessmentObjectMutation, UpdateAssessmentObjectMutationVariables>;

/**
 * __useUpdateAssessmentObjectMutation__
 *
 * To run a mutation, you first call `useUpdateAssessmentObjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssessmentObjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssessmentObjectMutation, { data, loading, error }] = useUpdateAssessmentObjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *      type: // value for 'type'
 *      name: // value for 'name'
 *      classification: // value for 'classification'
 *   },
 * });
 */
export function useUpdateAssessmentObjectMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAssessmentObjectMutation, UpdateAssessmentObjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAssessmentObjectMutation, UpdateAssessmentObjectMutationVariables>(UpdateAssessmentObjectDocument, options);
      }
export type UpdateAssessmentObjectMutationHookResult = ReturnType<typeof useUpdateAssessmentObjectMutation>;
export type UpdateAssessmentObjectMutationResult = Apollo.MutationResult<UpdateAssessmentObjectMutation>;
export type UpdateAssessmentObjectMutationOptions = Apollo.BaseMutationOptions<UpdateAssessmentObjectMutation, UpdateAssessmentObjectMutationVariables>;
export const DeleteAssessmentObjectDocument = gql`
    mutation deleteAssessmentObject($id: String!) {
  deleteAssessmentObject(input: {id: $id})
}
    `;
export type DeleteAssessmentObjectMutationFn = Apollo.MutationFunction<DeleteAssessmentObjectMutation, DeleteAssessmentObjectMutationVariables>;

/**
 * __useDeleteAssessmentObjectMutation__
 *
 * To run a mutation, you first call `useDeleteAssessmentObjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAssessmentObjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAssessmentObjectMutation, { data, loading, error }] = useDeleteAssessmentObjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAssessmentObjectMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAssessmentObjectMutation, DeleteAssessmentObjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAssessmentObjectMutation, DeleteAssessmentObjectMutationVariables>(DeleteAssessmentObjectDocument, options);
      }
export type DeleteAssessmentObjectMutationHookResult = ReturnType<typeof useDeleteAssessmentObjectMutation>;
export type DeleteAssessmentObjectMutationResult = Apollo.MutationResult<DeleteAssessmentObjectMutation>;
export type DeleteAssessmentObjectMutationOptions = Apollo.BaseMutationOptions<DeleteAssessmentObjectMutation, DeleteAssessmentObjectMutationVariables>;
export const ListOrganizationsDocument = gql`
    query listOrganizations($offset: Int!, $limit: Int!, $sport: Sports, $status: OrganizationStatuses, $name: String, $includeDeleted: Boolean) {
  listOrganizations(
    input: {sport: $sport, status: $status, name: $name, includeDeleted: $includeDeleted, pagination: {offset: $offset, limit: $limit}}
  ) {
    organizations {
      id
      name
      sport
      status
      abbreviation
      site
      logo
      createdAt
      updatedAt
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListOrganizationsQuery__
 *
 * To run a query within a React component, call `useListOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListOrganizationsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      sport: // value for 'sport'
 *      status: // value for 'status'
 *      name: // value for 'name'
 *      includeDeleted: // value for 'includeDeleted'
 *   },
 * });
 */
export function useListOrganizationsQuery(baseOptions: Apollo.QueryHookOptions<ListOrganizationsQuery, ListOrganizationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListOrganizationsQuery, ListOrganizationsQueryVariables>(ListOrganizationsDocument, options);
      }
export function useListOrganizationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListOrganizationsQuery, ListOrganizationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListOrganizationsQuery, ListOrganizationsQueryVariables>(ListOrganizationsDocument, options);
        }
export type ListOrganizationsQueryHookResult = ReturnType<typeof useListOrganizationsQuery>;
export type ListOrganizationsLazyQueryHookResult = ReturnType<typeof useListOrganizationsLazyQuery>;
export type ListOrganizationsQueryResult = Apollo.QueryResult<ListOrganizationsQuery, ListOrganizationsQueryVariables>;
export const CreateOrganizationDocument = gql`
    mutation createOrganization($sport: Sports!, $name: String!, $abbreviation: String!, $site: String, $logoUrl: String) {
  createOrganization(
    input: {sport: $sport, name: $name, abbreviation: $abbreviation, site: $site, logoUrl: $logoUrl}
  ) {
    id
    name
    sport
    status
    abbreviation
    site
    logo
    createdAt
    updatedAt
  }
}
    `;
export type CreateOrganizationMutationFn = Apollo.MutationFunction<CreateOrganizationMutation, CreateOrganizationMutationVariables>;

/**
 * __useCreateOrganizationMutation__
 *
 * To run a mutation, you first call `useCreateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganizationMutation, { data, loading, error }] = useCreateOrganizationMutation({
 *   variables: {
 *      sport: // value for 'sport'
 *      name: // value for 'name'
 *      abbreviation: // value for 'abbreviation'
 *      site: // value for 'site'
 *      logoUrl: // value for 'logoUrl'
 *   },
 * });
 */
export function useCreateOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrganizationMutation, CreateOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrganizationMutation, CreateOrganizationMutationVariables>(CreateOrganizationDocument, options);
      }
export type CreateOrganizationMutationHookResult = ReturnType<typeof useCreateOrganizationMutation>;
export type CreateOrganizationMutationResult = Apollo.MutationResult<CreateOrganizationMutation>;
export type CreateOrganizationMutationOptions = Apollo.BaseMutationOptions<CreateOrganizationMutation, CreateOrganizationMutationVariables>;
export const DeleteOrganizationDocument = gql`
    mutation deleteOrganization($id: String!) {
  deleteOrganization(input: {id: $id})
}
    `;
export type DeleteOrganizationMutationFn = Apollo.MutationFunction<DeleteOrganizationMutation, DeleteOrganizationMutationVariables>;

/**
 * __useDeleteOrganizationMutation__
 *
 * To run a mutation, you first call `useDeleteOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrganizationMutation, { data, loading, error }] = useDeleteOrganizationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOrganizationMutation, DeleteOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOrganizationMutation, DeleteOrganizationMutationVariables>(DeleteOrganizationDocument, options);
      }
export type DeleteOrganizationMutationHookResult = ReturnType<typeof useDeleteOrganizationMutation>;
export type DeleteOrganizationMutationResult = Apollo.MutationResult<DeleteOrganizationMutation>;
export type DeleteOrganizationMutationOptions = Apollo.BaseMutationOptions<DeleteOrganizationMutation, DeleteOrganizationMutationVariables>;
export const SetFeatureForOrganizationDocument = gql`
    mutation setFeatureForOrganization($organizationId: String!, $feature: OrgFeatures!, $enabled: Boolean!) {
  setFeatureForOrganization(
    input: {organizationId: $organizationId, feature: $feature, enabled: $enabled}
  ) {
    id
    feature
    active
    createdAt
    updatedAt
  }
}
    `;
export type SetFeatureForOrganizationMutationFn = Apollo.MutationFunction<SetFeatureForOrganizationMutation, SetFeatureForOrganizationMutationVariables>;

/**
 * __useSetFeatureForOrganizationMutation__
 *
 * To run a mutation, you first call `useSetFeatureForOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetFeatureForOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setFeatureForOrganizationMutation, { data, loading, error }] = useSetFeatureForOrganizationMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      feature: // value for 'feature'
 *      enabled: // value for 'enabled'
 *   },
 * });
 */
export function useSetFeatureForOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<SetFeatureForOrganizationMutation, SetFeatureForOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetFeatureForOrganizationMutation, SetFeatureForOrganizationMutationVariables>(SetFeatureForOrganizationDocument, options);
      }
export type SetFeatureForOrganizationMutationHookResult = ReturnType<typeof useSetFeatureForOrganizationMutation>;
export type SetFeatureForOrganizationMutationResult = Apollo.MutationResult<SetFeatureForOrganizationMutation>;
export type SetFeatureForOrganizationMutationOptions = Apollo.BaseMutationOptions<SetFeatureForOrganizationMutation, SetFeatureForOrganizationMutationVariables>;
export const AddFeatureForOrganizationDocument = gql`
    mutation addFeatureForOrganization($organizationId: String!, $feature: OrgFeatures!, $enabled: Boolean!) {
  addFeatureForOrganization(
    input: {organizationId: $organizationId, feature: $feature, enabled: $enabled}
  ) {
    id
    feature
    active
    createdAt
    updatedAt
  }
}
    `;
export type AddFeatureForOrganizationMutationFn = Apollo.MutationFunction<AddFeatureForOrganizationMutation, AddFeatureForOrganizationMutationVariables>;

/**
 * __useAddFeatureForOrganizationMutation__
 *
 * To run a mutation, you first call `useAddFeatureForOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFeatureForOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFeatureForOrganizationMutation, { data, loading, error }] = useAddFeatureForOrganizationMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      feature: // value for 'feature'
 *      enabled: // value for 'enabled'
 *   },
 * });
 */
export function useAddFeatureForOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<AddFeatureForOrganizationMutation, AddFeatureForOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddFeatureForOrganizationMutation, AddFeatureForOrganizationMutationVariables>(AddFeatureForOrganizationDocument, options);
      }
export type AddFeatureForOrganizationMutationHookResult = ReturnType<typeof useAddFeatureForOrganizationMutation>;
export type AddFeatureForOrganizationMutationResult = Apollo.MutationResult<AddFeatureForOrganizationMutation>;
export type AddFeatureForOrganizationMutationOptions = Apollo.BaseMutationOptions<AddFeatureForOrganizationMutation, AddFeatureForOrganizationMutationVariables>;
export const CreatePeriodDocument = gql`
    mutation createPeriod($sport: Sports!, $name: String!) {
  createPeriod(input: {sport: $sport, name: $name}) {
    id
    sport
    name
    createdAt
    updatedAt
  }
}
    `;
export type CreatePeriodMutationFn = Apollo.MutationFunction<CreatePeriodMutation, CreatePeriodMutationVariables>;

/**
 * __useCreatePeriodMutation__
 *
 * To run a mutation, you first call `useCreatePeriodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePeriodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPeriodMutation, { data, loading, error }] = useCreatePeriodMutation({
 *   variables: {
 *      sport: // value for 'sport'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreatePeriodMutation(baseOptions?: Apollo.MutationHookOptions<CreatePeriodMutation, CreatePeriodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePeriodMutation, CreatePeriodMutationVariables>(CreatePeriodDocument, options);
      }
export type CreatePeriodMutationHookResult = ReturnType<typeof useCreatePeriodMutation>;
export type CreatePeriodMutationResult = Apollo.MutationResult<CreatePeriodMutation>;
export type CreatePeriodMutationOptions = Apollo.BaseMutationOptions<CreatePeriodMutation, CreatePeriodMutationVariables>;
export const UpdatePeriodDocument = gql`
    mutation updatePeriod($id: String!, $name: String!) {
  updatePeriod(input: {id: $id, name: $name}) {
    id
    sport
    name
    createdAt
    updatedAt
  }
}
    `;
export type UpdatePeriodMutationFn = Apollo.MutationFunction<UpdatePeriodMutation, UpdatePeriodMutationVariables>;

/**
 * __useUpdatePeriodMutation__
 *
 * To run a mutation, you first call `useUpdatePeriodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePeriodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePeriodMutation, { data, loading, error }] = useUpdatePeriodMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdatePeriodMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePeriodMutation, UpdatePeriodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePeriodMutation, UpdatePeriodMutationVariables>(UpdatePeriodDocument, options);
      }
export type UpdatePeriodMutationHookResult = ReturnType<typeof useUpdatePeriodMutation>;
export type UpdatePeriodMutationResult = Apollo.MutationResult<UpdatePeriodMutation>;
export type UpdatePeriodMutationOptions = Apollo.BaseMutationOptions<UpdatePeriodMutation, UpdatePeriodMutationVariables>;
export const DeletePeriodDocument = gql`
    mutation deletePeriod($id: String!) {
  deletePeriod(input: {id: $id})
}
    `;
export type DeletePeriodMutationFn = Apollo.MutationFunction<DeletePeriodMutation, DeletePeriodMutationVariables>;

/**
 * __useDeletePeriodMutation__
 *
 * To run a mutation, you first call `useDeletePeriodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePeriodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePeriodMutation, { data, loading, error }] = useDeletePeriodMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePeriodMutation(baseOptions?: Apollo.MutationHookOptions<DeletePeriodMutation, DeletePeriodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePeriodMutation, DeletePeriodMutationVariables>(DeletePeriodDocument, options);
      }
export type DeletePeriodMutationHookResult = ReturnType<typeof useDeletePeriodMutation>;
export type DeletePeriodMutationResult = Apollo.MutationResult<DeletePeriodMutation>;
export type DeletePeriodMutationOptions = Apollo.BaseMutationOptions<DeletePeriodMutation, DeletePeriodMutationVariables>;
export const ListAdminUsersDocument = gql`
    query listAdminUsers($offset: Int!, $limit: Int!) {
  listAdminUsers(input: {pagination: {offset: $offset, limit: $limit}}) {
    adminUsers {
      id
      email
      firstName
      lastName
      isAdmin
      organizationMemberships {
        id
        title
        orgId
        name
        abbreviation
        logo
        roles
        permissions
        leagueMemberships {
          leagueId
          name
          abbreviation
          logo
        }
      }
      status
      createdAt
      updatedAt
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListAdminUsersQuery__
 *
 * To run a query within a React component, call `useListAdminUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAdminUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAdminUsersQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useListAdminUsersQuery(baseOptions: Apollo.QueryHookOptions<ListAdminUsersQuery, ListAdminUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAdminUsersQuery, ListAdminUsersQueryVariables>(ListAdminUsersDocument, options);
      }
export function useListAdminUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAdminUsersQuery, ListAdminUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAdminUsersQuery, ListAdminUsersQueryVariables>(ListAdminUsersDocument, options);
        }
export type ListAdminUsersQueryHookResult = ReturnType<typeof useListAdminUsersQuery>;
export type ListAdminUsersLazyQueryHookResult = ReturnType<typeof useListAdminUsersLazyQuery>;
export type ListAdminUsersQueryResult = Apollo.QueryResult<ListAdminUsersQuery, ListAdminUsersQueryVariables>;
export const InviteAdminUserDocument = gql`
    mutation InviteAdminUser($email: String!, $firstName: String!, $lastName: String!) {
  inviteAdminUser(
    input: {email: $email, firstName: $firstName, lastName: $lastName}
  ) {
    created
    emailSent
  }
}
    `;
export type InviteAdminUserMutationFn = Apollo.MutationFunction<InviteAdminUserMutation, InviteAdminUserMutationVariables>;

/**
 * __useInviteAdminUserMutation__
 *
 * To run a mutation, you first call `useInviteAdminUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteAdminUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteAdminUserMutation, { data, loading, error }] = useInviteAdminUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *   },
 * });
 */
export function useInviteAdminUserMutation(baseOptions?: Apollo.MutationHookOptions<InviteAdminUserMutation, InviteAdminUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InviteAdminUserMutation, InviteAdminUserMutationVariables>(InviteAdminUserDocument, options);
      }
export type InviteAdminUserMutationHookResult = ReturnType<typeof useInviteAdminUserMutation>;
export type InviteAdminUserMutationResult = Apollo.MutationResult<InviteAdminUserMutation>;
export type InviteAdminUserMutationOptions = Apollo.BaseMutationOptions<InviteAdminUserMutation, InviteAdminUserMutationVariables>;
export const GetAdminUserByIdDocument = gql`
    query getAdminUserById($id: String!) {
  getAdminUserById(input: {id: $id}) {
    id
    email
    firstName
    lastName
    status
    isAdmin
    organizationMemberships {
      id
      orgId
      roles
      permissions
      title
      leagueMemberships {
        leagueId
        name
        abbreviation
        logo
      }
    }
    profilePic
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetAdminUserByIdQuery__
 *
 * To run a query within a React component, call `useGetAdminUserByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminUserByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminUserByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAdminUserByIdQuery(baseOptions: Apollo.QueryHookOptions<GetAdminUserByIdQuery, GetAdminUserByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdminUserByIdQuery, GetAdminUserByIdQueryVariables>(GetAdminUserByIdDocument, options);
      }
export function useGetAdminUserByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdminUserByIdQuery, GetAdminUserByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdminUserByIdQuery, GetAdminUserByIdQueryVariables>(GetAdminUserByIdDocument, options);
        }
export type GetAdminUserByIdQueryHookResult = ReturnType<typeof useGetAdminUserByIdQuery>;
export type GetAdminUserByIdLazyQueryHookResult = ReturnType<typeof useGetAdminUserByIdLazyQuery>;
export type GetAdminUserByIdQueryResult = Apollo.QueryResult<GetAdminUserByIdQuery, GetAdminUserByIdQueryVariables>;
export const GetMyAlertByIdDocument = gql`
    query getMyAlertById($id: String!) {
  getMyAlertById(input: {id: $id}) {
    id
    userId
    title
    message
    read
    route
    routeText
    component
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetMyAlertByIdQuery__
 *
 * To run a query within a React component, call `useGetMyAlertByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyAlertByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyAlertByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMyAlertByIdQuery(baseOptions: Apollo.QueryHookOptions<GetMyAlertByIdQuery, GetMyAlertByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyAlertByIdQuery, GetMyAlertByIdQueryVariables>(GetMyAlertByIdDocument, options);
      }
export function useGetMyAlertByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyAlertByIdQuery, GetMyAlertByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyAlertByIdQuery, GetMyAlertByIdQueryVariables>(GetMyAlertByIdDocument, options);
        }
export type GetMyAlertByIdQueryHookResult = ReturnType<typeof useGetMyAlertByIdQuery>;
export type GetMyAlertByIdLazyQueryHookResult = ReturnType<typeof useGetMyAlertByIdLazyQuery>;
export type GetMyAlertByIdQueryResult = Apollo.QueryResult<GetMyAlertByIdQuery, GetMyAlertByIdQueryVariables>;
export const ListMyAlertsDocument = gql`
    query listMyAlerts($offset: Int!, $limit: Int!, $read: Boolean, $title: String, $components: [UserAlertComponents!]) {
  listMyAlerts(
    input: {read: $read, title: $title, components: $components, pagination: {offset: $offset, limit: $limit}}
  ) {
    alerts {
      id
      userId
      title
      message
      read
      route
      routeText
      component
      createdAt
      updatedAt
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListMyAlertsQuery__
 *
 * To run a query within a React component, call `useListMyAlertsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListMyAlertsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListMyAlertsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      read: // value for 'read'
 *      title: // value for 'title'
 *      components: // value for 'components'
 *   },
 * });
 */
export function useListMyAlertsQuery(baseOptions: Apollo.QueryHookOptions<ListMyAlertsQuery, ListMyAlertsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListMyAlertsQuery, ListMyAlertsQueryVariables>(ListMyAlertsDocument, options);
      }
export function useListMyAlertsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListMyAlertsQuery, ListMyAlertsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListMyAlertsQuery, ListMyAlertsQueryVariables>(ListMyAlertsDocument, options);
        }
export type ListMyAlertsQueryHookResult = ReturnType<typeof useListMyAlertsQuery>;
export type ListMyAlertsLazyQueryHookResult = ReturnType<typeof useListMyAlertsLazyQuery>;
export type ListMyAlertsQueryResult = Apollo.QueryResult<ListMyAlertsQuery, ListMyAlertsQueryVariables>;
export const DeleteMyAlertDocument = gql`
    mutation deleteMyAlert($id: String!) {
  deleteMyAlert(input: {id: $id})
}
    `;
export type DeleteMyAlertMutationFn = Apollo.MutationFunction<DeleteMyAlertMutation, DeleteMyAlertMutationVariables>;

/**
 * __useDeleteMyAlertMutation__
 *
 * To run a mutation, you first call `useDeleteMyAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMyAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMyAlertMutation, { data, loading, error }] = useDeleteMyAlertMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMyAlertMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMyAlertMutation, DeleteMyAlertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMyAlertMutation, DeleteMyAlertMutationVariables>(DeleteMyAlertDocument, options);
      }
export type DeleteMyAlertMutationHookResult = ReturnType<typeof useDeleteMyAlertMutation>;
export type DeleteMyAlertMutationResult = Apollo.MutationResult<DeleteMyAlertMutation>;
export type DeleteMyAlertMutationOptions = Apollo.BaseMutationOptions<DeleteMyAlertMutation, DeleteMyAlertMutationVariables>;
export const DeleteMySelectedAlertsDocument = gql`
    mutation deleteMySelectedAlerts($ids: [String!]!) {
  deleteMySelectedAlerts(input: {ids: $ids})
}
    `;
export type DeleteMySelectedAlertsMutationFn = Apollo.MutationFunction<DeleteMySelectedAlertsMutation, DeleteMySelectedAlertsMutationVariables>;

/**
 * __useDeleteMySelectedAlertsMutation__
 *
 * To run a mutation, you first call `useDeleteMySelectedAlertsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMySelectedAlertsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMySelectedAlertsMutation, { data, loading, error }] = useDeleteMySelectedAlertsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteMySelectedAlertsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMySelectedAlertsMutation, DeleteMySelectedAlertsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMySelectedAlertsMutation, DeleteMySelectedAlertsMutationVariables>(DeleteMySelectedAlertsDocument, options);
      }
export type DeleteMySelectedAlertsMutationHookResult = ReturnType<typeof useDeleteMySelectedAlertsMutation>;
export type DeleteMySelectedAlertsMutationResult = Apollo.MutationResult<DeleteMySelectedAlertsMutation>;
export type DeleteMySelectedAlertsMutationOptions = Apollo.BaseMutationOptions<DeleteMySelectedAlertsMutation, DeleteMySelectedAlertsMutationVariables>;
export const DeleteAllMyReadAlertsDocument = gql`
    mutation deleteAllMyReadAlerts {
  deleteAllMyReadAlerts
}
    `;
export type DeleteAllMyReadAlertsMutationFn = Apollo.MutationFunction<DeleteAllMyReadAlertsMutation, DeleteAllMyReadAlertsMutationVariables>;

/**
 * __useDeleteAllMyReadAlertsMutation__
 *
 * To run a mutation, you first call `useDeleteAllMyReadAlertsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAllMyReadAlertsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAllMyReadAlertsMutation, { data, loading, error }] = useDeleteAllMyReadAlertsMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteAllMyReadAlertsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAllMyReadAlertsMutation, DeleteAllMyReadAlertsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAllMyReadAlertsMutation, DeleteAllMyReadAlertsMutationVariables>(DeleteAllMyReadAlertsDocument, options);
      }
export type DeleteAllMyReadAlertsMutationHookResult = ReturnType<typeof useDeleteAllMyReadAlertsMutation>;
export type DeleteAllMyReadAlertsMutationResult = Apollo.MutationResult<DeleteAllMyReadAlertsMutation>;
export type DeleteAllMyReadAlertsMutationOptions = Apollo.BaseMutationOptions<DeleteAllMyReadAlertsMutation, DeleteAllMyReadAlertsMutationVariables>;
export const SetReadForMyAlertDocument = gql`
    mutation setReadForMyAlert($id: String!, $read: Boolean!) {
  setReadForMyAlert(input: {id: $id, read: $read}) {
    id
    userId
    title
    message
    read
    route
    routeText
    component
    createdAt
    updatedAt
  }
}
    `;
export type SetReadForMyAlertMutationFn = Apollo.MutationFunction<SetReadForMyAlertMutation, SetReadForMyAlertMutationVariables>;

/**
 * __useSetReadForMyAlertMutation__
 *
 * To run a mutation, you first call `useSetReadForMyAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetReadForMyAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setReadForMyAlertMutation, { data, loading, error }] = useSetReadForMyAlertMutation({
 *   variables: {
 *      id: // value for 'id'
 *      read: // value for 'read'
 *   },
 * });
 */
export function useSetReadForMyAlertMutation(baseOptions?: Apollo.MutationHookOptions<SetReadForMyAlertMutation, SetReadForMyAlertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetReadForMyAlertMutation, SetReadForMyAlertMutationVariables>(SetReadForMyAlertDocument, options);
      }
export type SetReadForMyAlertMutationHookResult = ReturnType<typeof useSetReadForMyAlertMutation>;
export type SetReadForMyAlertMutationResult = Apollo.MutationResult<SetReadForMyAlertMutation>;
export type SetReadForMyAlertMutationOptions = Apollo.BaseMutationOptions<SetReadForMyAlertMutation, SetReadForMyAlertMutationVariables>;
export const MarkMySelectedAlertsAsReadDocument = gql`
    mutation markMySelectedAlertsAsRead($ids: [String!]!) {
  markMySelectedAlertsAsRead(input: {ids: $ids})
}
    `;
export type MarkMySelectedAlertsAsReadMutationFn = Apollo.MutationFunction<MarkMySelectedAlertsAsReadMutation, MarkMySelectedAlertsAsReadMutationVariables>;

/**
 * __useMarkMySelectedAlertsAsReadMutation__
 *
 * To run a mutation, you first call `useMarkMySelectedAlertsAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkMySelectedAlertsAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markMySelectedAlertsAsReadMutation, { data, loading, error }] = useMarkMySelectedAlertsAsReadMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useMarkMySelectedAlertsAsReadMutation(baseOptions?: Apollo.MutationHookOptions<MarkMySelectedAlertsAsReadMutation, MarkMySelectedAlertsAsReadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkMySelectedAlertsAsReadMutation, MarkMySelectedAlertsAsReadMutationVariables>(MarkMySelectedAlertsAsReadDocument, options);
      }
export type MarkMySelectedAlertsAsReadMutationHookResult = ReturnType<typeof useMarkMySelectedAlertsAsReadMutation>;
export type MarkMySelectedAlertsAsReadMutationResult = Apollo.MutationResult<MarkMySelectedAlertsAsReadMutation>;
export type MarkMySelectedAlertsAsReadMutationOptions = Apollo.BaseMutationOptions<MarkMySelectedAlertsAsReadMutation, MarkMySelectedAlertsAsReadMutationVariables>;
export const MarkAllMyAlertsAsReadDocument = gql`
    mutation markAllMyAlertsAsRead {
  markAllMyAlertsAsRead
}
    `;
export type MarkAllMyAlertsAsReadMutationFn = Apollo.MutationFunction<MarkAllMyAlertsAsReadMutation, MarkAllMyAlertsAsReadMutationVariables>;

/**
 * __useMarkAllMyAlertsAsReadMutation__
 *
 * To run a mutation, you first call `useMarkAllMyAlertsAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAllMyAlertsAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAllMyAlertsAsReadMutation, { data, loading, error }] = useMarkAllMyAlertsAsReadMutation({
 *   variables: {
 *   },
 * });
 */
export function useMarkAllMyAlertsAsReadMutation(baseOptions?: Apollo.MutationHookOptions<MarkAllMyAlertsAsReadMutation, MarkAllMyAlertsAsReadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkAllMyAlertsAsReadMutation, MarkAllMyAlertsAsReadMutationVariables>(MarkAllMyAlertsAsReadDocument, options);
      }
export type MarkAllMyAlertsAsReadMutationHookResult = ReturnType<typeof useMarkAllMyAlertsAsReadMutation>;
export type MarkAllMyAlertsAsReadMutationResult = Apollo.MutationResult<MarkAllMyAlertsAsReadMutation>;
export type MarkAllMyAlertsAsReadMutationOptions = Apollo.BaseMutationOptions<MarkAllMyAlertsAsReadMutation, MarkAllMyAlertsAsReadMutationVariables>;
export const GetMyNewAlertsCountDocument = gql`
    query getMyNewAlertsCount {
  getMyNewAlertsCount
}
    `;

/**
 * __useGetMyNewAlertsCountQuery__
 *
 * To run a query within a React component, call `useGetMyNewAlertsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyNewAlertsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyNewAlertsCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyNewAlertsCountQuery(baseOptions?: Apollo.QueryHookOptions<GetMyNewAlertsCountQuery, GetMyNewAlertsCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyNewAlertsCountQuery, GetMyNewAlertsCountQueryVariables>(GetMyNewAlertsCountDocument, options);
      }
export function useGetMyNewAlertsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyNewAlertsCountQuery, GetMyNewAlertsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyNewAlertsCountQuery, GetMyNewAlertsCountQueryVariables>(GetMyNewAlertsCountDocument, options);
        }
export type GetMyNewAlertsCountQueryHookResult = ReturnType<typeof useGetMyNewAlertsCountQuery>;
export type GetMyNewAlertsCountLazyQueryHookResult = ReturnType<typeof useGetMyNewAlertsCountLazyQuery>;
export type GetMyNewAlertsCountQueryResult = Apollo.QueryResult<GetMyNewAlertsCountQuery, GetMyNewAlertsCountQueryVariables>;
export const NewUserAlertsNotificationDocument = gql`
    subscription newUserAlertsNotification {
  newUserAlertsNotification {
    id
    userId
    title
    message
    route
    routeText
    component
    date
  }
}
    `;

/**
 * __useNewUserAlertsNotificationSubscription__
 *
 * To run a query within a React component, call `useNewUserAlertsNotificationSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNewUserAlertsNotificationSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewUserAlertsNotificationSubscription({
 *   variables: {
 *   },
 * });
 */
export function useNewUserAlertsNotificationSubscription(baseOptions?: Apollo.SubscriptionHookOptions<NewUserAlertsNotificationSubscription, NewUserAlertsNotificationSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<NewUserAlertsNotificationSubscription, NewUserAlertsNotificationSubscriptionVariables>(NewUserAlertsNotificationDocument, options);
      }
export type NewUserAlertsNotificationSubscriptionHookResult = ReturnType<typeof useNewUserAlertsNotificationSubscription>;
export type NewUserAlertsNotificationSubscriptionResult = Apollo.SubscriptionResult<NewUserAlertsNotificationSubscription>;
export const ListAllSurveysForOrganizationDocument = gql`
    query listAllSurveysForOrganization($offset: Int!, $limit: Int!, $orgId: String!, $seasonId: String, $status: SurveyStatuses, $audience: SurveyAudiences, $type: SurveyTypes) {
  listAllSurveysForOrganization(
    input: {orgId: $orgId, seasonId: $seasonId, status: $status, audience: $audience, type: $type, pagination: {offset: $offset, limit: $limit}}
  ) {
    surveys {
      id
      type
      sport
      status
      orgId
      seasonId
      hasSignatureSection
      hasCompetitionVerification
      hasIncidentReport
      hasKeyIncidentVideoIntegration
      name
      description
      audience
      sendDelayMinutes
      reminders {
        type
        dailyReminders {
          sendReminders
          sendReminderDays
          sendReminderCutoffDays
        }
        levelReminders {
          sendRemindersLevel1
          sendReminderDaysLevel1
          sendRemindersLevel2
          sendReminderDaysLevel2
          sendRemindersLevel3
          sendReminderDaysLevel3
        }
      }
      sectionCount
      activatedAt
      createdAt
      updatedAt
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListAllSurveysForOrganizationQuery__
 *
 * To run a query within a React component, call `useListAllSurveysForOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAllSurveysForOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAllSurveysForOrganizationQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      orgId: // value for 'orgId'
 *      seasonId: // value for 'seasonId'
 *      status: // value for 'status'
 *      audience: // value for 'audience'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useListAllSurveysForOrganizationQuery(baseOptions: Apollo.QueryHookOptions<ListAllSurveysForOrganizationQuery, ListAllSurveysForOrganizationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAllSurveysForOrganizationQuery, ListAllSurveysForOrganizationQueryVariables>(ListAllSurveysForOrganizationDocument, options);
      }
export function useListAllSurveysForOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAllSurveysForOrganizationQuery, ListAllSurveysForOrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAllSurveysForOrganizationQuery, ListAllSurveysForOrganizationQueryVariables>(ListAllSurveysForOrganizationDocument, options);
        }
export type ListAllSurveysForOrganizationQueryHookResult = ReturnType<typeof useListAllSurveysForOrganizationQuery>;
export type ListAllSurveysForOrganizationLazyQueryHookResult = ReturnType<typeof useListAllSurveysForOrganizationLazyQuery>;
export type ListAllSurveysForOrganizationQueryResult = Apollo.QueryResult<ListAllSurveysForOrganizationQuery, ListAllSurveysForOrganizationQueryVariables>;
export const LoginDocument = gql`
    mutation Login($email: String!, $password: String!, $rememberMe: Boolean) {
  login(input: {email: $email, password: $password, rememberMe: $rememberMe}) {
    accessToken
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      rememberMe: // value for 'rememberMe'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const MeDocument = gql`
    query Me {
  me {
    id
    firstName
    lastName
    email
    isAdmin
    status
    organizationMemberships {
      id
      orgId
      name
      abbreviation
      logo
      sport
      roles
      permissions
      title
      leagueMemberships {
        leagueId
        name
        abbreviation
        logo
      }
    }
    profilePic
    createdAt
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const GetMyOrganizationContextDocument = gql`
    query getMyOrganizationContext($orgId: String!, $leagueId: String, $seasonId: String) {
  getMyOrganizationContext(
    input: {orgId: $orgId, leagueId: $leagueId, seasonId: $seasonId}
  ) {
    orgFeatures {
      id
      feature
      active
      createdAt
      updatedAt
    }
    league {
      id
      orgId
      name
      description
      abbreviation
      logo
      status
      tags {
        id
        leagueId
        tag
      }
      createdAt
      updatedAt
    }
    season {
      id
      leagueId
      leagueName
      leagueLogo
      teamKitEnabled
      competitionIntegrationEnabled
      mediaMembersEnabled
      name
      description
      status
      startDate
      endDate
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useGetMyOrganizationContextQuery__
 *
 * To run a query within a React component, call `useGetMyOrganizationContextQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyOrganizationContextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyOrganizationContextQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      leagueId: // value for 'leagueId'
 *      seasonId: // value for 'seasonId'
 *   },
 * });
 */
export function useGetMyOrganizationContextQuery(baseOptions: Apollo.QueryHookOptions<GetMyOrganizationContextQuery, GetMyOrganizationContextQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyOrganizationContextQuery, GetMyOrganizationContextQueryVariables>(GetMyOrganizationContextDocument, options);
      }
export function useGetMyOrganizationContextLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyOrganizationContextQuery, GetMyOrganizationContextQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyOrganizationContextQuery, GetMyOrganizationContextQueryVariables>(GetMyOrganizationContextDocument, options);
        }
export type GetMyOrganizationContextQueryHookResult = ReturnType<typeof useGetMyOrganizationContextQuery>;
export type GetMyOrganizationContextLazyQueryHookResult = ReturnType<typeof useGetMyOrganizationContextLazyQuery>;
export type GetMyOrganizationContextQueryResult = Apollo.QueryResult<GetMyOrganizationContextQuery, GetMyOrganizationContextQueryVariables>;
export const CheckUserConfirmationDocument = gql`
    query checkUserConfirmation($token: String!) {
  checkConfirmationToken(input: {confirmationToken: $token}) {
    isValid
  }
}
    `;

/**
 * __useCheckUserConfirmationQuery__
 *
 * To run a query within a React component, call `useCheckUserConfirmationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckUserConfirmationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckUserConfirmationQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useCheckUserConfirmationQuery(baseOptions: Apollo.QueryHookOptions<CheckUserConfirmationQuery, CheckUserConfirmationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckUserConfirmationQuery, CheckUserConfirmationQueryVariables>(CheckUserConfirmationDocument, options);
      }
export function useCheckUserConfirmationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckUserConfirmationQuery, CheckUserConfirmationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckUserConfirmationQuery, CheckUserConfirmationQueryVariables>(CheckUserConfirmationDocument, options);
        }
export type CheckUserConfirmationQueryHookResult = ReturnType<typeof useCheckUserConfirmationQuery>;
export type CheckUserConfirmationLazyQueryHookResult = ReturnType<typeof useCheckUserConfirmationLazyQuery>;
export type CheckUserConfirmationQueryResult = Apollo.QueryResult<CheckUserConfirmationQuery, CheckUserConfirmationQueryVariables>;
export const ResendUserConfirmationEmailDocument = gql`
    mutation resendUserConfirmationEmail($email: String!) {
  resendUserConfirmationEmail(input: {email: $email})
}
    `;
export type ResendUserConfirmationEmailMutationFn = Apollo.MutationFunction<ResendUserConfirmationEmailMutation, ResendUserConfirmationEmailMutationVariables>;

/**
 * __useResendUserConfirmationEmailMutation__
 *
 * To run a mutation, you first call `useResendUserConfirmationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendUserConfirmationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendUserConfirmationEmailMutation, { data, loading, error }] = useResendUserConfirmationEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useResendUserConfirmationEmailMutation(baseOptions?: Apollo.MutationHookOptions<ResendUserConfirmationEmailMutation, ResendUserConfirmationEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendUserConfirmationEmailMutation, ResendUserConfirmationEmailMutationVariables>(ResendUserConfirmationEmailDocument, options);
      }
export type ResendUserConfirmationEmailMutationHookResult = ReturnType<typeof useResendUserConfirmationEmailMutation>;
export type ResendUserConfirmationEmailMutationResult = Apollo.MutationResult<ResendUserConfirmationEmailMutation>;
export type ResendUserConfirmationEmailMutationOptions = Apollo.BaseMutationOptions<ResendUserConfirmationEmailMutation, ResendUserConfirmationEmailMutationVariables>;
export const ConfirmUserDocument = gql`
    mutation confirmUser($token: String!, $password: String!, $passwordConfirmation: String!) {
  confirmUser(
    input: {confirmationToken: $token, password: $password, passwordConfirmation: $passwordConfirmation}
  )
}
    `;
export type ConfirmUserMutationFn = Apollo.MutationFunction<ConfirmUserMutation, ConfirmUserMutationVariables>;

/**
 * __useConfirmUserMutation__
 *
 * To run a mutation, you first call `useConfirmUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmUserMutation, { data, loading, error }] = useConfirmUserMutation({
 *   variables: {
 *      token: // value for 'token'
 *      password: // value for 'password'
 *      passwordConfirmation: // value for 'passwordConfirmation'
 *   },
 * });
 */
export function useConfirmUserMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmUserMutation, ConfirmUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfirmUserMutation, ConfirmUserMutationVariables>(ConfirmUserDocument, options);
      }
export type ConfirmUserMutationHookResult = ReturnType<typeof useConfirmUserMutation>;
export type ConfirmUserMutationResult = Apollo.MutationResult<ConfirmUserMutation>;
export type ConfirmUserMutationOptions = Apollo.BaseMutationOptions<ConfirmUserMutation, ConfirmUserMutationVariables>;
export const UpdateMyPasswordDocument = gql`
    mutation updateMyPassword($password: String!, $passwordConfirmation: String!) {
  updateMyPassword(
    input: {password: $password, passwordConfirmation: $passwordConfirmation}
  )
}
    `;
export type UpdateMyPasswordMutationFn = Apollo.MutationFunction<UpdateMyPasswordMutation, UpdateMyPasswordMutationVariables>;

/**
 * __useUpdateMyPasswordMutation__
 *
 * To run a mutation, you first call `useUpdateMyPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMyPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMyPasswordMutation, { data, loading, error }] = useUpdateMyPasswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *      passwordConfirmation: // value for 'passwordConfirmation'
 *   },
 * });
 */
export function useUpdateMyPasswordMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMyPasswordMutation, UpdateMyPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMyPasswordMutation, UpdateMyPasswordMutationVariables>(UpdateMyPasswordDocument, options);
      }
export type UpdateMyPasswordMutationHookResult = ReturnType<typeof useUpdateMyPasswordMutation>;
export type UpdateMyPasswordMutationResult = Apollo.MutationResult<UpdateMyPasswordMutation>;
export type UpdateMyPasswordMutationOptions = Apollo.BaseMutationOptions<UpdateMyPasswordMutation, UpdateMyPasswordMutationVariables>;
export const RequestResetPasswordLinkDocument = gql`
    mutation requestResetPasswordLink($email: String!) {
  requestResetPasswordLink(input: {email: $email})
}
    `;
export type RequestResetPasswordLinkMutationFn = Apollo.MutationFunction<RequestResetPasswordLinkMutation, RequestResetPasswordLinkMutationVariables>;

/**
 * __useRequestResetPasswordLinkMutation__
 *
 * To run a mutation, you first call `useRequestResetPasswordLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestResetPasswordLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestResetPasswordLinkMutation, { data, loading, error }] = useRequestResetPasswordLinkMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRequestResetPasswordLinkMutation(baseOptions?: Apollo.MutationHookOptions<RequestResetPasswordLinkMutation, RequestResetPasswordLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestResetPasswordLinkMutation, RequestResetPasswordLinkMutationVariables>(RequestResetPasswordLinkDocument, options);
      }
export type RequestResetPasswordLinkMutationHookResult = ReturnType<typeof useRequestResetPasswordLinkMutation>;
export type RequestResetPasswordLinkMutationResult = Apollo.MutationResult<RequestResetPasswordLinkMutation>;
export type RequestResetPasswordLinkMutationOptions = Apollo.BaseMutationOptions<RequestResetPasswordLinkMutation, RequestResetPasswordLinkMutationVariables>;
export const CheckResetPasswordTokenDocument = gql`
    query checkResetPasswordToken($token: String!) {
  checkResetPasswordToken(input: {resetPasswordToken: $token}) {
    isValid
  }
}
    `;

/**
 * __useCheckResetPasswordTokenQuery__
 *
 * To run a query within a React component, call `useCheckResetPasswordTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckResetPasswordTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckResetPasswordTokenQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useCheckResetPasswordTokenQuery(baseOptions: Apollo.QueryHookOptions<CheckResetPasswordTokenQuery, CheckResetPasswordTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckResetPasswordTokenQuery, CheckResetPasswordTokenQueryVariables>(CheckResetPasswordTokenDocument, options);
      }
export function useCheckResetPasswordTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckResetPasswordTokenQuery, CheckResetPasswordTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckResetPasswordTokenQuery, CheckResetPasswordTokenQueryVariables>(CheckResetPasswordTokenDocument, options);
        }
export type CheckResetPasswordTokenQueryHookResult = ReturnType<typeof useCheckResetPasswordTokenQuery>;
export type CheckResetPasswordTokenLazyQueryHookResult = ReturnType<typeof useCheckResetPasswordTokenLazyQuery>;
export type CheckResetPasswordTokenQueryResult = Apollo.QueryResult<CheckResetPasswordTokenQuery, CheckResetPasswordTokenQueryVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($token: String!, $password: String!, $passwordConfirmation: String!) {
  resetPassword(
    input: {token: $token, password: $password, passwordConfirmation: $passwordConfirmation}
  )
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      token: // value for 'token'
 *      password: // value for 'password'
 *      passwordConfirmation: // value for 'passwordConfirmation'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const GetBatchSeasonCoachUploadByIdDocument = gql`
    query getBatchSeasonCoachUploadById($id: String!) {
  getBatchSeasonCoachUploadById(input: {id: $id}) {
    id
    organizationId
    seasonId
    seasonName
    fileName
    status
    error
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetBatchSeasonCoachUploadByIdQuery__
 *
 * To run a query within a React component, call `useGetBatchSeasonCoachUploadByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBatchSeasonCoachUploadByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBatchSeasonCoachUploadByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBatchSeasonCoachUploadByIdQuery(baseOptions: Apollo.QueryHookOptions<GetBatchSeasonCoachUploadByIdQuery, GetBatchSeasonCoachUploadByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBatchSeasonCoachUploadByIdQuery, GetBatchSeasonCoachUploadByIdQueryVariables>(GetBatchSeasonCoachUploadByIdDocument, options);
      }
export function useGetBatchSeasonCoachUploadByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBatchSeasonCoachUploadByIdQuery, GetBatchSeasonCoachUploadByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBatchSeasonCoachUploadByIdQuery, GetBatchSeasonCoachUploadByIdQueryVariables>(GetBatchSeasonCoachUploadByIdDocument, options);
        }
export type GetBatchSeasonCoachUploadByIdQueryHookResult = ReturnType<typeof useGetBatchSeasonCoachUploadByIdQuery>;
export type GetBatchSeasonCoachUploadByIdLazyQueryHookResult = ReturnType<typeof useGetBatchSeasonCoachUploadByIdLazyQuery>;
export type GetBatchSeasonCoachUploadByIdQueryResult = Apollo.QueryResult<GetBatchSeasonCoachUploadByIdQuery, GetBatchSeasonCoachUploadByIdQueryVariables>;
export const ListBatchSeasonCoachUploadsForSeasonDocument = gql`
    query listBatchSeasonCoachUploadsForSeason($offset: Int!, $limit: Int!, $seasonId: String!, $fileName: String, $status: BatchFileUploadStatuses) {
  listBatchSeasonCoachUploadsForSeason(
    input: {seasonId: $seasonId, fileName: $fileName, status: $status, pagination: {offset: $offset, limit: $limit}}
  ) {
    seasonId
    uploads {
      id
      organizationId
      seasonId
      seasonName
      fileName
      status
      error
      createdAt
      updatedAt
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListBatchSeasonCoachUploadsForSeasonQuery__
 *
 * To run a query within a React component, call `useListBatchSeasonCoachUploadsForSeasonQuery` and pass it any options that fit your needs.
 * When your component renders, `useListBatchSeasonCoachUploadsForSeasonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListBatchSeasonCoachUploadsForSeasonQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      seasonId: // value for 'seasonId'
 *      fileName: // value for 'fileName'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useListBatchSeasonCoachUploadsForSeasonQuery(baseOptions: Apollo.QueryHookOptions<ListBatchSeasonCoachUploadsForSeasonQuery, ListBatchSeasonCoachUploadsForSeasonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListBatchSeasonCoachUploadsForSeasonQuery, ListBatchSeasonCoachUploadsForSeasonQueryVariables>(ListBatchSeasonCoachUploadsForSeasonDocument, options);
      }
export function useListBatchSeasonCoachUploadsForSeasonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListBatchSeasonCoachUploadsForSeasonQuery, ListBatchSeasonCoachUploadsForSeasonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListBatchSeasonCoachUploadsForSeasonQuery, ListBatchSeasonCoachUploadsForSeasonQueryVariables>(ListBatchSeasonCoachUploadsForSeasonDocument, options);
        }
export type ListBatchSeasonCoachUploadsForSeasonQueryHookResult = ReturnType<typeof useListBatchSeasonCoachUploadsForSeasonQuery>;
export type ListBatchSeasonCoachUploadsForSeasonLazyQueryHookResult = ReturnType<typeof useListBatchSeasonCoachUploadsForSeasonLazyQuery>;
export type ListBatchSeasonCoachUploadsForSeasonQueryResult = Apollo.QueryResult<ListBatchSeasonCoachUploadsForSeasonQuery, ListBatchSeasonCoachUploadsForSeasonQueryVariables>;
export const ListRowsForBatchSeasonCoachUploadDocument = gql`
    query listRowsForBatchSeasonCoachUpload($offset: Int!, $limit: Int!, $uploadId: String!) {
  listRowsForBatchSeasonCoachUpload(
    input: {uploadId: $uploadId, pagination: {offset: $offset, limit: $limit}}
  ) {
    uploadId
    rows {
      id
      uploadId
      rowNumber
      value
      status
      errors {
        id
        columnName
        error
        createdAt
        updatedAt
      }
      coachId
      createdAt
      updatedAt
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListRowsForBatchSeasonCoachUploadQuery__
 *
 * To run a query within a React component, call `useListRowsForBatchSeasonCoachUploadQuery` and pass it any options that fit your needs.
 * When your component renders, `useListRowsForBatchSeasonCoachUploadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListRowsForBatchSeasonCoachUploadQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      uploadId: // value for 'uploadId'
 *   },
 * });
 */
export function useListRowsForBatchSeasonCoachUploadQuery(baseOptions: Apollo.QueryHookOptions<ListRowsForBatchSeasonCoachUploadQuery, ListRowsForBatchSeasonCoachUploadQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListRowsForBatchSeasonCoachUploadQuery, ListRowsForBatchSeasonCoachUploadQueryVariables>(ListRowsForBatchSeasonCoachUploadDocument, options);
      }
export function useListRowsForBatchSeasonCoachUploadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListRowsForBatchSeasonCoachUploadQuery, ListRowsForBatchSeasonCoachUploadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListRowsForBatchSeasonCoachUploadQuery, ListRowsForBatchSeasonCoachUploadQueryVariables>(ListRowsForBatchSeasonCoachUploadDocument, options);
        }
export type ListRowsForBatchSeasonCoachUploadQueryHookResult = ReturnType<typeof useListRowsForBatchSeasonCoachUploadQuery>;
export type ListRowsForBatchSeasonCoachUploadLazyQueryHookResult = ReturnType<typeof useListRowsForBatchSeasonCoachUploadLazyQuery>;
export type ListRowsForBatchSeasonCoachUploadQueryResult = Apollo.QueryResult<ListRowsForBatchSeasonCoachUploadQuery, ListRowsForBatchSeasonCoachUploadQueryVariables>;
export const ListErrorsForBatchSeasonCoachUploadRowDocument = gql`
    query listErrorsForBatchSeasonCoachUploadRow($offset: Int!, $limit: Int!, $rowId: String!) {
  listErrorsForBatchSeasonCoachUploadRow(
    input: {rowId: $rowId, pagination: {offset: $offset, limit: $limit}}
  ) {
    rowId
    rowErrors {
      id
      columnName
      error
      createdAt
      updatedAt
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListErrorsForBatchSeasonCoachUploadRowQuery__
 *
 * To run a query within a React component, call `useListErrorsForBatchSeasonCoachUploadRowQuery` and pass it any options that fit your needs.
 * When your component renders, `useListErrorsForBatchSeasonCoachUploadRowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListErrorsForBatchSeasonCoachUploadRowQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      rowId: // value for 'rowId'
 *   },
 * });
 */
export function useListErrorsForBatchSeasonCoachUploadRowQuery(baseOptions: Apollo.QueryHookOptions<ListErrorsForBatchSeasonCoachUploadRowQuery, ListErrorsForBatchSeasonCoachUploadRowQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListErrorsForBatchSeasonCoachUploadRowQuery, ListErrorsForBatchSeasonCoachUploadRowQueryVariables>(ListErrorsForBatchSeasonCoachUploadRowDocument, options);
      }
export function useListErrorsForBatchSeasonCoachUploadRowLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListErrorsForBatchSeasonCoachUploadRowQuery, ListErrorsForBatchSeasonCoachUploadRowQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListErrorsForBatchSeasonCoachUploadRowQuery, ListErrorsForBatchSeasonCoachUploadRowQueryVariables>(ListErrorsForBatchSeasonCoachUploadRowDocument, options);
        }
export type ListErrorsForBatchSeasonCoachUploadRowQueryHookResult = ReturnType<typeof useListErrorsForBatchSeasonCoachUploadRowQuery>;
export type ListErrorsForBatchSeasonCoachUploadRowLazyQueryHookResult = ReturnType<typeof useListErrorsForBatchSeasonCoachUploadRowLazyQuery>;
export type ListErrorsForBatchSeasonCoachUploadRowQueryResult = Apollo.QueryResult<ListErrorsForBatchSeasonCoachUploadRowQuery, ListErrorsForBatchSeasonCoachUploadRowQueryVariables>;
export const GetBatchSeasonCompetitionUploadByIdDocument = gql`
    query getBatchSeasonCompetitionUploadById($id: String!) {
  getBatchSeasonCompetitionUploadById(input: {id: $id}) {
    id
    organizationId
    seasonId
    seasonName
    fileName
    status
    error
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetBatchSeasonCompetitionUploadByIdQuery__
 *
 * To run a query within a React component, call `useGetBatchSeasonCompetitionUploadByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBatchSeasonCompetitionUploadByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBatchSeasonCompetitionUploadByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBatchSeasonCompetitionUploadByIdQuery(baseOptions: Apollo.QueryHookOptions<GetBatchSeasonCompetitionUploadByIdQuery, GetBatchSeasonCompetitionUploadByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBatchSeasonCompetitionUploadByIdQuery, GetBatchSeasonCompetitionUploadByIdQueryVariables>(GetBatchSeasonCompetitionUploadByIdDocument, options);
      }
export function useGetBatchSeasonCompetitionUploadByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBatchSeasonCompetitionUploadByIdQuery, GetBatchSeasonCompetitionUploadByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBatchSeasonCompetitionUploadByIdQuery, GetBatchSeasonCompetitionUploadByIdQueryVariables>(GetBatchSeasonCompetitionUploadByIdDocument, options);
        }
export type GetBatchSeasonCompetitionUploadByIdQueryHookResult = ReturnType<typeof useGetBatchSeasonCompetitionUploadByIdQuery>;
export type GetBatchSeasonCompetitionUploadByIdLazyQueryHookResult = ReturnType<typeof useGetBatchSeasonCompetitionUploadByIdLazyQuery>;
export type GetBatchSeasonCompetitionUploadByIdQueryResult = Apollo.QueryResult<GetBatchSeasonCompetitionUploadByIdQuery, GetBatchSeasonCompetitionUploadByIdQueryVariables>;
export const ListBatchSeasonCompetitionUploadsForSeasonDocument = gql`
    query listBatchSeasonCompetitionUploadsForSeason($offset: Int!, $limit: Int!, $seasonId: String!, $fileName: String, $status: BatchFileUploadStatuses) {
  listBatchSeasonCompetitionUploadsForSeason(
    input: {seasonId: $seasonId, fileName: $fileName, status: $status, pagination: {offset: $offset, limit: $limit}}
  ) {
    seasonId
    uploads {
      id
      organizationId
      seasonId
      seasonName
      fileName
      status
      error
      createdAt
      updatedAt
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListBatchSeasonCompetitionUploadsForSeasonQuery__
 *
 * To run a query within a React component, call `useListBatchSeasonCompetitionUploadsForSeasonQuery` and pass it any options that fit your needs.
 * When your component renders, `useListBatchSeasonCompetitionUploadsForSeasonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListBatchSeasonCompetitionUploadsForSeasonQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      seasonId: // value for 'seasonId'
 *      fileName: // value for 'fileName'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useListBatchSeasonCompetitionUploadsForSeasonQuery(baseOptions: Apollo.QueryHookOptions<ListBatchSeasonCompetitionUploadsForSeasonQuery, ListBatchSeasonCompetitionUploadsForSeasonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListBatchSeasonCompetitionUploadsForSeasonQuery, ListBatchSeasonCompetitionUploadsForSeasonQueryVariables>(ListBatchSeasonCompetitionUploadsForSeasonDocument, options);
      }
export function useListBatchSeasonCompetitionUploadsForSeasonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListBatchSeasonCompetitionUploadsForSeasonQuery, ListBatchSeasonCompetitionUploadsForSeasonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListBatchSeasonCompetitionUploadsForSeasonQuery, ListBatchSeasonCompetitionUploadsForSeasonQueryVariables>(ListBatchSeasonCompetitionUploadsForSeasonDocument, options);
        }
export type ListBatchSeasonCompetitionUploadsForSeasonQueryHookResult = ReturnType<typeof useListBatchSeasonCompetitionUploadsForSeasonQuery>;
export type ListBatchSeasonCompetitionUploadsForSeasonLazyQueryHookResult = ReturnType<typeof useListBatchSeasonCompetitionUploadsForSeasonLazyQuery>;
export type ListBatchSeasonCompetitionUploadsForSeasonQueryResult = Apollo.QueryResult<ListBatchSeasonCompetitionUploadsForSeasonQuery, ListBatchSeasonCompetitionUploadsForSeasonQueryVariables>;
export const ListRowsForBatchSeasonCompetitionUploadDocument = gql`
    query listRowsForBatchSeasonCompetitionUpload($offset: Int!, $limit: Int!, $uploadId: String!) {
  listRowsForBatchSeasonCompetitionUpload(
    input: {uploadId: $uploadId, pagination: {offset: $offset, limit: $limit}}
  ) {
    uploadId
    rows {
      id
      uploadId
      rowNumber
      value
      status
      competitionId
      errors {
        id
        columnName
        error
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListRowsForBatchSeasonCompetitionUploadQuery__
 *
 * To run a query within a React component, call `useListRowsForBatchSeasonCompetitionUploadQuery` and pass it any options that fit your needs.
 * When your component renders, `useListRowsForBatchSeasonCompetitionUploadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListRowsForBatchSeasonCompetitionUploadQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      uploadId: // value for 'uploadId'
 *   },
 * });
 */
export function useListRowsForBatchSeasonCompetitionUploadQuery(baseOptions: Apollo.QueryHookOptions<ListRowsForBatchSeasonCompetitionUploadQuery, ListRowsForBatchSeasonCompetitionUploadQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListRowsForBatchSeasonCompetitionUploadQuery, ListRowsForBatchSeasonCompetitionUploadQueryVariables>(ListRowsForBatchSeasonCompetitionUploadDocument, options);
      }
export function useListRowsForBatchSeasonCompetitionUploadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListRowsForBatchSeasonCompetitionUploadQuery, ListRowsForBatchSeasonCompetitionUploadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListRowsForBatchSeasonCompetitionUploadQuery, ListRowsForBatchSeasonCompetitionUploadQueryVariables>(ListRowsForBatchSeasonCompetitionUploadDocument, options);
        }
export type ListRowsForBatchSeasonCompetitionUploadQueryHookResult = ReturnType<typeof useListRowsForBatchSeasonCompetitionUploadQuery>;
export type ListRowsForBatchSeasonCompetitionUploadLazyQueryHookResult = ReturnType<typeof useListRowsForBatchSeasonCompetitionUploadLazyQuery>;
export type ListRowsForBatchSeasonCompetitionUploadQueryResult = Apollo.QueryResult<ListRowsForBatchSeasonCompetitionUploadQuery, ListRowsForBatchSeasonCompetitionUploadQueryVariables>;
export const ListErrorsForBatchSeasonCompetitionUploadRowDocument = gql`
    query listErrorsForBatchSeasonCompetitionUploadRow($offset: Int!, $limit: Int!, $rowId: String!) {
  listErrorsForBatchSeasonCompetitionUploadRow(
    input: {rowId: $rowId, pagination: {offset: $offset, limit: $limit}}
  ) {
    rowId
    rowErrors {
      id
      columnName
      error
      createdAt
      updatedAt
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListErrorsForBatchSeasonCompetitionUploadRowQuery__
 *
 * To run a query within a React component, call `useListErrorsForBatchSeasonCompetitionUploadRowQuery` and pass it any options that fit your needs.
 * When your component renders, `useListErrorsForBatchSeasonCompetitionUploadRowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListErrorsForBatchSeasonCompetitionUploadRowQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      rowId: // value for 'rowId'
 *   },
 * });
 */
export function useListErrorsForBatchSeasonCompetitionUploadRowQuery(baseOptions: Apollo.QueryHookOptions<ListErrorsForBatchSeasonCompetitionUploadRowQuery, ListErrorsForBatchSeasonCompetitionUploadRowQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListErrorsForBatchSeasonCompetitionUploadRowQuery, ListErrorsForBatchSeasonCompetitionUploadRowQueryVariables>(ListErrorsForBatchSeasonCompetitionUploadRowDocument, options);
      }
export function useListErrorsForBatchSeasonCompetitionUploadRowLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListErrorsForBatchSeasonCompetitionUploadRowQuery, ListErrorsForBatchSeasonCompetitionUploadRowQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListErrorsForBatchSeasonCompetitionUploadRowQuery, ListErrorsForBatchSeasonCompetitionUploadRowQueryVariables>(ListErrorsForBatchSeasonCompetitionUploadRowDocument, options);
        }
export type ListErrorsForBatchSeasonCompetitionUploadRowQueryHookResult = ReturnType<typeof useListErrorsForBatchSeasonCompetitionUploadRowQuery>;
export type ListErrorsForBatchSeasonCompetitionUploadRowLazyQueryHookResult = ReturnType<typeof useListErrorsForBatchSeasonCompetitionUploadRowLazyQuery>;
export type ListErrorsForBatchSeasonCompetitionUploadRowQueryResult = Apollo.QueryResult<ListErrorsForBatchSeasonCompetitionUploadRowQuery, ListErrorsForBatchSeasonCompetitionUploadRowQueryVariables>;
export const GetBatchSeasonOfficialUploadByIdDocument = gql`
    query getBatchSeasonOfficialUploadById($id: String!) {
  getBatchSeasonOfficialUploadById(input: {id: $id}) {
    id
    organizationId
    seasonId
    seasonName
    fileName
    status
    error
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetBatchSeasonOfficialUploadByIdQuery__
 *
 * To run a query within a React component, call `useGetBatchSeasonOfficialUploadByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBatchSeasonOfficialUploadByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBatchSeasonOfficialUploadByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBatchSeasonOfficialUploadByIdQuery(baseOptions: Apollo.QueryHookOptions<GetBatchSeasonOfficialUploadByIdQuery, GetBatchSeasonOfficialUploadByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBatchSeasonOfficialUploadByIdQuery, GetBatchSeasonOfficialUploadByIdQueryVariables>(GetBatchSeasonOfficialUploadByIdDocument, options);
      }
export function useGetBatchSeasonOfficialUploadByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBatchSeasonOfficialUploadByIdQuery, GetBatchSeasonOfficialUploadByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBatchSeasonOfficialUploadByIdQuery, GetBatchSeasonOfficialUploadByIdQueryVariables>(GetBatchSeasonOfficialUploadByIdDocument, options);
        }
export type GetBatchSeasonOfficialUploadByIdQueryHookResult = ReturnType<typeof useGetBatchSeasonOfficialUploadByIdQuery>;
export type GetBatchSeasonOfficialUploadByIdLazyQueryHookResult = ReturnType<typeof useGetBatchSeasonOfficialUploadByIdLazyQuery>;
export type GetBatchSeasonOfficialUploadByIdQueryResult = Apollo.QueryResult<GetBatchSeasonOfficialUploadByIdQuery, GetBatchSeasonOfficialUploadByIdQueryVariables>;
export const ListBatchSeasonOfficialUploadsForSeasonDocument = gql`
    query listBatchSeasonOfficialUploadsForSeason($offset: Int!, $limit: Int!, $seasonId: String!, $fileName: String, $status: BatchFileUploadStatuses) {
  listBatchSeasonOfficialUploadsForSeason(
    input: {seasonId: $seasonId, fileName: $fileName, status: $status, pagination: {offset: $offset, limit: $limit}}
  ) {
    seasonId
    uploads {
      id
      organizationId
      seasonId
      seasonName
      fileName
      status
      error
      createdAt
      updatedAt
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListBatchSeasonOfficialUploadsForSeasonQuery__
 *
 * To run a query within a React component, call `useListBatchSeasonOfficialUploadsForSeasonQuery` and pass it any options that fit your needs.
 * When your component renders, `useListBatchSeasonOfficialUploadsForSeasonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListBatchSeasonOfficialUploadsForSeasonQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      seasonId: // value for 'seasonId'
 *      fileName: // value for 'fileName'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useListBatchSeasonOfficialUploadsForSeasonQuery(baseOptions: Apollo.QueryHookOptions<ListBatchSeasonOfficialUploadsForSeasonQuery, ListBatchSeasonOfficialUploadsForSeasonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListBatchSeasonOfficialUploadsForSeasonQuery, ListBatchSeasonOfficialUploadsForSeasonQueryVariables>(ListBatchSeasonOfficialUploadsForSeasonDocument, options);
      }
export function useListBatchSeasonOfficialUploadsForSeasonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListBatchSeasonOfficialUploadsForSeasonQuery, ListBatchSeasonOfficialUploadsForSeasonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListBatchSeasonOfficialUploadsForSeasonQuery, ListBatchSeasonOfficialUploadsForSeasonQueryVariables>(ListBatchSeasonOfficialUploadsForSeasonDocument, options);
        }
export type ListBatchSeasonOfficialUploadsForSeasonQueryHookResult = ReturnType<typeof useListBatchSeasonOfficialUploadsForSeasonQuery>;
export type ListBatchSeasonOfficialUploadsForSeasonLazyQueryHookResult = ReturnType<typeof useListBatchSeasonOfficialUploadsForSeasonLazyQuery>;
export type ListBatchSeasonOfficialUploadsForSeasonQueryResult = Apollo.QueryResult<ListBatchSeasonOfficialUploadsForSeasonQuery, ListBatchSeasonOfficialUploadsForSeasonQueryVariables>;
export const ListRowsForBatchSeasonOfficialUploadDocument = gql`
    query listRowsForBatchSeasonOfficialUpload($offset: Int!, $limit: Int!, $uploadId: String!) {
  listRowsForBatchSeasonOfficialUpload(
    input: {uploadId: $uploadId, pagination: {offset: $offset, limit: $limit}}
  ) {
    uploadId
    rows {
      id
      uploadId
      rowNumber
      value
      status
      officialId
      errors {
        id
        columnName
        error
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListRowsForBatchSeasonOfficialUploadQuery__
 *
 * To run a query within a React component, call `useListRowsForBatchSeasonOfficialUploadQuery` and pass it any options that fit your needs.
 * When your component renders, `useListRowsForBatchSeasonOfficialUploadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListRowsForBatchSeasonOfficialUploadQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      uploadId: // value for 'uploadId'
 *   },
 * });
 */
export function useListRowsForBatchSeasonOfficialUploadQuery(baseOptions: Apollo.QueryHookOptions<ListRowsForBatchSeasonOfficialUploadQuery, ListRowsForBatchSeasonOfficialUploadQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListRowsForBatchSeasonOfficialUploadQuery, ListRowsForBatchSeasonOfficialUploadQueryVariables>(ListRowsForBatchSeasonOfficialUploadDocument, options);
      }
export function useListRowsForBatchSeasonOfficialUploadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListRowsForBatchSeasonOfficialUploadQuery, ListRowsForBatchSeasonOfficialUploadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListRowsForBatchSeasonOfficialUploadQuery, ListRowsForBatchSeasonOfficialUploadQueryVariables>(ListRowsForBatchSeasonOfficialUploadDocument, options);
        }
export type ListRowsForBatchSeasonOfficialUploadQueryHookResult = ReturnType<typeof useListRowsForBatchSeasonOfficialUploadQuery>;
export type ListRowsForBatchSeasonOfficialUploadLazyQueryHookResult = ReturnType<typeof useListRowsForBatchSeasonOfficialUploadLazyQuery>;
export type ListRowsForBatchSeasonOfficialUploadQueryResult = Apollo.QueryResult<ListRowsForBatchSeasonOfficialUploadQuery, ListRowsForBatchSeasonOfficialUploadQueryVariables>;
export const ListErrorsForBatchSeasonOfficialUploadRowDocument = gql`
    query listErrorsForBatchSeasonOfficialUploadRow($offset: Int!, $limit: Int!, $rowId: String!) {
  listErrorsForBatchSeasonOfficialUploadRow(
    input: {rowId: $rowId, pagination: {offset: $offset, limit: $limit}}
  ) {
    rowId
    rowErrors {
      id
      columnName
      error
      createdAt
      updatedAt
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListErrorsForBatchSeasonOfficialUploadRowQuery__
 *
 * To run a query within a React component, call `useListErrorsForBatchSeasonOfficialUploadRowQuery` and pass it any options that fit your needs.
 * When your component renders, `useListErrorsForBatchSeasonOfficialUploadRowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListErrorsForBatchSeasonOfficialUploadRowQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      rowId: // value for 'rowId'
 *   },
 * });
 */
export function useListErrorsForBatchSeasonOfficialUploadRowQuery(baseOptions: Apollo.QueryHookOptions<ListErrorsForBatchSeasonOfficialUploadRowQuery, ListErrorsForBatchSeasonOfficialUploadRowQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListErrorsForBatchSeasonOfficialUploadRowQuery, ListErrorsForBatchSeasonOfficialUploadRowQueryVariables>(ListErrorsForBatchSeasonOfficialUploadRowDocument, options);
      }
export function useListErrorsForBatchSeasonOfficialUploadRowLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListErrorsForBatchSeasonOfficialUploadRowQuery, ListErrorsForBatchSeasonOfficialUploadRowQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListErrorsForBatchSeasonOfficialUploadRowQuery, ListErrorsForBatchSeasonOfficialUploadRowQueryVariables>(ListErrorsForBatchSeasonOfficialUploadRowDocument, options);
        }
export type ListErrorsForBatchSeasonOfficialUploadRowQueryHookResult = ReturnType<typeof useListErrorsForBatchSeasonOfficialUploadRowQuery>;
export type ListErrorsForBatchSeasonOfficialUploadRowLazyQueryHookResult = ReturnType<typeof useListErrorsForBatchSeasonOfficialUploadRowLazyQuery>;
export type ListErrorsForBatchSeasonOfficialUploadRowQueryResult = Apollo.QueryResult<ListErrorsForBatchSeasonOfficialUploadRowQuery, ListErrorsForBatchSeasonOfficialUploadRowQueryVariables>;
export const GetBatchSeasonSurveyResponseUploadByIdDocument = gql`
    query getBatchSeasonSurveyResponseUploadById($id: String!) {
  getBatchSeasonSurveyResponseUploadById(input: {id: $id}) {
    id
    organizationId
    seasonId
    seasonName
    surveyId
    surveyName
    fileName
    status
    error
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetBatchSeasonSurveyResponseUploadByIdQuery__
 *
 * To run a query within a React component, call `useGetBatchSeasonSurveyResponseUploadByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBatchSeasonSurveyResponseUploadByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBatchSeasonSurveyResponseUploadByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBatchSeasonSurveyResponseUploadByIdQuery(baseOptions: Apollo.QueryHookOptions<GetBatchSeasonSurveyResponseUploadByIdQuery, GetBatchSeasonSurveyResponseUploadByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBatchSeasonSurveyResponseUploadByIdQuery, GetBatchSeasonSurveyResponseUploadByIdQueryVariables>(GetBatchSeasonSurveyResponseUploadByIdDocument, options);
      }
export function useGetBatchSeasonSurveyResponseUploadByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBatchSeasonSurveyResponseUploadByIdQuery, GetBatchSeasonSurveyResponseUploadByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBatchSeasonSurveyResponseUploadByIdQuery, GetBatchSeasonSurveyResponseUploadByIdQueryVariables>(GetBatchSeasonSurveyResponseUploadByIdDocument, options);
        }
export type GetBatchSeasonSurveyResponseUploadByIdQueryHookResult = ReturnType<typeof useGetBatchSeasonSurveyResponseUploadByIdQuery>;
export type GetBatchSeasonSurveyResponseUploadByIdLazyQueryHookResult = ReturnType<typeof useGetBatchSeasonSurveyResponseUploadByIdLazyQuery>;
export type GetBatchSeasonSurveyResponseUploadByIdQueryResult = Apollo.QueryResult<GetBatchSeasonSurveyResponseUploadByIdQuery, GetBatchSeasonSurveyResponseUploadByIdQueryVariables>;
export const ListBatchSeasonSurveyResponseUploadsDocument = gql`
    query listBatchSeasonSurveyResponseUploads($offset: Int!, $limit: Int!, $organizationName: String, $seasonName: String, $fileName: String, $status: BatchFileUploadStatuses, $legacyType: BatchSeasonLegacySurveyTypes) {
  listBatchSeasonSurveyResponseUploads(
    input: {organizationName: $organizationName, seasonName: $seasonName, fileName: $fileName, status: $status, legacyType: $legacyType, pagination: {offset: $offset, limit: $limit}}
  ) {
    uploads {
      id
      organizationId
      seasonId
      seasonName
      surveyId
      surveyName
      fileName
      status
      createdAt
      updatedAt
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListBatchSeasonSurveyResponseUploadsQuery__
 *
 * To run a query within a React component, call `useListBatchSeasonSurveyResponseUploadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListBatchSeasonSurveyResponseUploadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListBatchSeasonSurveyResponseUploadsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      organizationName: // value for 'organizationName'
 *      seasonName: // value for 'seasonName'
 *      fileName: // value for 'fileName'
 *      status: // value for 'status'
 *      legacyType: // value for 'legacyType'
 *   },
 * });
 */
export function useListBatchSeasonSurveyResponseUploadsQuery(baseOptions: Apollo.QueryHookOptions<ListBatchSeasonSurveyResponseUploadsQuery, ListBatchSeasonSurveyResponseUploadsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListBatchSeasonSurveyResponseUploadsQuery, ListBatchSeasonSurveyResponseUploadsQueryVariables>(ListBatchSeasonSurveyResponseUploadsDocument, options);
      }
export function useListBatchSeasonSurveyResponseUploadsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListBatchSeasonSurveyResponseUploadsQuery, ListBatchSeasonSurveyResponseUploadsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListBatchSeasonSurveyResponseUploadsQuery, ListBatchSeasonSurveyResponseUploadsQueryVariables>(ListBatchSeasonSurveyResponseUploadsDocument, options);
        }
export type ListBatchSeasonSurveyResponseUploadsQueryHookResult = ReturnType<typeof useListBatchSeasonSurveyResponseUploadsQuery>;
export type ListBatchSeasonSurveyResponseUploadsLazyQueryHookResult = ReturnType<typeof useListBatchSeasonSurveyResponseUploadsLazyQuery>;
export type ListBatchSeasonSurveyResponseUploadsQueryResult = Apollo.QueryResult<ListBatchSeasonSurveyResponseUploadsQuery, ListBatchSeasonSurveyResponseUploadsQueryVariables>;
export const ListRowsForBatchSeasonSurveyResponseUploadDocument = gql`
    query listRowsForBatchSeasonSurveyResponseUpload($offset: Int!, $limit: Int!, $uploadId: String!) {
  listRowsForBatchSeasonSurveyResponseUpload(
    input: {uploadId: $uploadId, pagination: {offset: $offset, limit: $limit}}
  ) {
    uploadId
    rows {
      id
      uploadId
      rowNumber
      value
      status
      competitionSurveyId
      errors {
        id
        columnName
        error
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListRowsForBatchSeasonSurveyResponseUploadQuery__
 *
 * To run a query within a React component, call `useListRowsForBatchSeasonSurveyResponseUploadQuery` and pass it any options that fit your needs.
 * When your component renders, `useListRowsForBatchSeasonSurveyResponseUploadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListRowsForBatchSeasonSurveyResponseUploadQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      uploadId: // value for 'uploadId'
 *   },
 * });
 */
export function useListRowsForBatchSeasonSurveyResponseUploadQuery(baseOptions: Apollo.QueryHookOptions<ListRowsForBatchSeasonSurveyResponseUploadQuery, ListRowsForBatchSeasonSurveyResponseUploadQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListRowsForBatchSeasonSurveyResponseUploadQuery, ListRowsForBatchSeasonSurveyResponseUploadQueryVariables>(ListRowsForBatchSeasonSurveyResponseUploadDocument, options);
      }
export function useListRowsForBatchSeasonSurveyResponseUploadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListRowsForBatchSeasonSurveyResponseUploadQuery, ListRowsForBatchSeasonSurveyResponseUploadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListRowsForBatchSeasonSurveyResponseUploadQuery, ListRowsForBatchSeasonSurveyResponseUploadQueryVariables>(ListRowsForBatchSeasonSurveyResponseUploadDocument, options);
        }
export type ListRowsForBatchSeasonSurveyResponseUploadQueryHookResult = ReturnType<typeof useListRowsForBatchSeasonSurveyResponseUploadQuery>;
export type ListRowsForBatchSeasonSurveyResponseUploadLazyQueryHookResult = ReturnType<typeof useListRowsForBatchSeasonSurveyResponseUploadLazyQuery>;
export type ListRowsForBatchSeasonSurveyResponseUploadQueryResult = Apollo.QueryResult<ListRowsForBatchSeasonSurveyResponseUploadQuery, ListRowsForBatchSeasonSurveyResponseUploadQueryVariables>;
export const ListErrorsForBatchSeasonSurveyResponseUploadRowDocument = gql`
    query listErrorsForBatchSeasonSurveyResponseUploadRow($offset: Int!, $limit: Int!, $rowId: String!) {
  listErrorsForBatchSeasonSurveyResponseUploadRow(
    input: {rowId: $rowId, pagination: {offset: $offset, limit: $limit}}
  ) {
    rowId
    rowErrors {
      id
      columnName
      error
      createdAt
      updatedAt
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListErrorsForBatchSeasonSurveyResponseUploadRowQuery__
 *
 * To run a query within a React component, call `useListErrorsForBatchSeasonSurveyResponseUploadRowQuery` and pass it any options that fit your needs.
 * When your component renders, `useListErrorsForBatchSeasonSurveyResponseUploadRowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListErrorsForBatchSeasonSurveyResponseUploadRowQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      rowId: // value for 'rowId'
 *   },
 * });
 */
export function useListErrorsForBatchSeasonSurveyResponseUploadRowQuery(baseOptions: Apollo.QueryHookOptions<ListErrorsForBatchSeasonSurveyResponseUploadRowQuery, ListErrorsForBatchSeasonSurveyResponseUploadRowQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListErrorsForBatchSeasonSurveyResponseUploadRowQuery, ListErrorsForBatchSeasonSurveyResponseUploadRowQueryVariables>(ListErrorsForBatchSeasonSurveyResponseUploadRowDocument, options);
      }
export function useListErrorsForBatchSeasonSurveyResponseUploadRowLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListErrorsForBatchSeasonSurveyResponseUploadRowQuery, ListErrorsForBatchSeasonSurveyResponseUploadRowQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListErrorsForBatchSeasonSurveyResponseUploadRowQuery, ListErrorsForBatchSeasonSurveyResponseUploadRowQueryVariables>(ListErrorsForBatchSeasonSurveyResponseUploadRowDocument, options);
        }
export type ListErrorsForBatchSeasonSurveyResponseUploadRowQueryHookResult = ReturnType<typeof useListErrorsForBatchSeasonSurveyResponseUploadRowQuery>;
export type ListErrorsForBatchSeasonSurveyResponseUploadRowLazyQueryHookResult = ReturnType<typeof useListErrorsForBatchSeasonSurveyResponseUploadRowLazyQuery>;
export type ListErrorsForBatchSeasonSurveyResponseUploadRowQueryResult = Apollo.QueryResult<ListErrorsForBatchSeasonSurveyResponseUploadRowQuery, ListErrorsForBatchSeasonSurveyResponseUploadRowQueryVariables>;
export const GetBatchSeasonTeamUploadByIdDocument = gql`
    query getBatchSeasonTeamUploadById($id: String!) {
  getBatchSeasonTeamUploadById(input: {id: $id}) {
    id
    organizationId
    seasonId
    seasonName
    fileName
    status
    error
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetBatchSeasonTeamUploadByIdQuery__
 *
 * To run a query within a React component, call `useGetBatchSeasonTeamUploadByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBatchSeasonTeamUploadByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBatchSeasonTeamUploadByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBatchSeasonTeamUploadByIdQuery(baseOptions: Apollo.QueryHookOptions<GetBatchSeasonTeamUploadByIdQuery, GetBatchSeasonTeamUploadByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBatchSeasonTeamUploadByIdQuery, GetBatchSeasonTeamUploadByIdQueryVariables>(GetBatchSeasonTeamUploadByIdDocument, options);
      }
export function useGetBatchSeasonTeamUploadByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBatchSeasonTeamUploadByIdQuery, GetBatchSeasonTeamUploadByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBatchSeasonTeamUploadByIdQuery, GetBatchSeasonTeamUploadByIdQueryVariables>(GetBatchSeasonTeamUploadByIdDocument, options);
        }
export type GetBatchSeasonTeamUploadByIdQueryHookResult = ReturnType<typeof useGetBatchSeasonTeamUploadByIdQuery>;
export type GetBatchSeasonTeamUploadByIdLazyQueryHookResult = ReturnType<typeof useGetBatchSeasonTeamUploadByIdLazyQuery>;
export type GetBatchSeasonTeamUploadByIdQueryResult = Apollo.QueryResult<GetBatchSeasonTeamUploadByIdQuery, GetBatchSeasonTeamUploadByIdQueryVariables>;
export const ListBatchSeasonTeamUploadsForSeasonDocument = gql`
    query listBatchSeasonTeamUploadsForSeason($offset: Int!, $limit: Int!, $seasonId: String!, $fileName: String, $status: BatchFileUploadStatuses) {
  listBatchSeasonTeamUploadsForSeason(
    input: {seasonId: $seasonId, fileName: $fileName, status: $status, pagination: {offset: $offset, limit: $limit}}
  ) {
    seasonId
    uploads {
      id
      organizationId
      seasonId
      seasonName
      fileName
      status
      error
      createdAt
      updatedAt
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListBatchSeasonTeamUploadsForSeasonQuery__
 *
 * To run a query within a React component, call `useListBatchSeasonTeamUploadsForSeasonQuery` and pass it any options that fit your needs.
 * When your component renders, `useListBatchSeasonTeamUploadsForSeasonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListBatchSeasonTeamUploadsForSeasonQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      seasonId: // value for 'seasonId'
 *      fileName: // value for 'fileName'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useListBatchSeasonTeamUploadsForSeasonQuery(baseOptions: Apollo.QueryHookOptions<ListBatchSeasonTeamUploadsForSeasonQuery, ListBatchSeasonTeamUploadsForSeasonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListBatchSeasonTeamUploadsForSeasonQuery, ListBatchSeasonTeamUploadsForSeasonQueryVariables>(ListBatchSeasonTeamUploadsForSeasonDocument, options);
      }
export function useListBatchSeasonTeamUploadsForSeasonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListBatchSeasonTeamUploadsForSeasonQuery, ListBatchSeasonTeamUploadsForSeasonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListBatchSeasonTeamUploadsForSeasonQuery, ListBatchSeasonTeamUploadsForSeasonQueryVariables>(ListBatchSeasonTeamUploadsForSeasonDocument, options);
        }
export type ListBatchSeasonTeamUploadsForSeasonQueryHookResult = ReturnType<typeof useListBatchSeasonTeamUploadsForSeasonQuery>;
export type ListBatchSeasonTeamUploadsForSeasonLazyQueryHookResult = ReturnType<typeof useListBatchSeasonTeamUploadsForSeasonLazyQuery>;
export type ListBatchSeasonTeamUploadsForSeasonQueryResult = Apollo.QueryResult<ListBatchSeasonTeamUploadsForSeasonQuery, ListBatchSeasonTeamUploadsForSeasonQueryVariables>;
export const ListRowsForBatchSeasonTeamUploadDocument = gql`
    query listRowsForBatchSeasonTeamUpload($offset: Int!, $limit: Int!, $uploadId: String!) {
  listRowsForBatchSeasonTeamUpload(
    input: {uploadId: $uploadId, pagination: {offset: $offset, limit: $limit}}
  ) {
    uploadId
    rows {
      id
      uploadId
      rowNumber
      value
      status
      teamId
      errors {
        id
        columnName
        error
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListRowsForBatchSeasonTeamUploadQuery__
 *
 * To run a query within a React component, call `useListRowsForBatchSeasonTeamUploadQuery` and pass it any options that fit your needs.
 * When your component renders, `useListRowsForBatchSeasonTeamUploadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListRowsForBatchSeasonTeamUploadQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      uploadId: // value for 'uploadId'
 *   },
 * });
 */
export function useListRowsForBatchSeasonTeamUploadQuery(baseOptions: Apollo.QueryHookOptions<ListRowsForBatchSeasonTeamUploadQuery, ListRowsForBatchSeasonTeamUploadQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListRowsForBatchSeasonTeamUploadQuery, ListRowsForBatchSeasonTeamUploadQueryVariables>(ListRowsForBatchSeasonTeamUploadDocument, options);
      }
export function useListRowsForBatchSeasonTeamUploadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListRowsForBatchSeasonTeamUploadQuery, ListRowsForBatchSeasonTeamUploadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListRowsForBatchSeasonTeamUploadQuery, ListRowsForBatchSeasonTeamUploadQueryVariables>(ListRowsForBatchSeasonTeamUploadDocument, options);
        }
export type ListRowsForBatchSeasonTeamUploadQueryHookResult = ReturnType<typeof useListRowsForBatchSeasonTeamUploadQuery>;
export type ListRowsForBatchSeasonTeamUploadLazyQueryHookResult = ReturnType<typeof useListRowsForBatchSeasonTeamUploadLazyQuery>;
export type ListRowsForBatchSeasonTeamUploadQueryResult = Apollo.QueryResult<ListRowsForBatchSeasonTeamUploadQuery, ListRowsForBatchSeasonTeamUploadQueryVariables>;
export const ListErrorsForBatchSeasonTeamUploadRowDocument = gql`
    query listErrorsForBatchSeasonTeamUploadRow($offset: Int!, $limit: Int!, $rowId: String!) {
  listErrorsForBatchSeasonTeamUploadRow(
    input: {rowId: $rowId, pagination: {offset: $offset, limit: $limit}}
  ) {
    rowId
    rowErrors {
      id
      columnName
      error
      createdAt
      updatedAt
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListErrorsForBatchSeasonTeamUploadRowQuery__
 *
 * To run a query within a React component, call `useListErrorsForBatchSeasonTeamUploadRowQuery` and pass it any options that fit your needs.
 * When your component renders, `useListErrorsForBatchSeasonTeamUploadRowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListErrorsForBatchSeasonTeamUploadRowQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      rowId: // value for 'rowId'
 *   },
 * });
 */
export function useListErrorsForBatchSeasonTeamUploadRowQuery(baseOptions: Apollo.QueryHookOptions<ListErrorsForBatchSeasonTeamUploadRowQuery, ListErrorsForBatchSeasonTeamUploadRowQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListErrorsForBatchSeasonTeamUploadRowQuery, ListErrorsForBatchSeasonTeamUploadRowQueryVariables>(ListErrorsForBatchSeasonTeamUploadRowDocument, options);
      }
export function useListErrorsForBatchSeasonTeamUploadRowLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListErrorsForBatchSeasonTeamUploadRowQuery, ListErrorsForBatchSeasonTeamUploadRowQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListErrorsForBatchSeasonTeamUploadRowQuery, ListErrorsForBatchSeasonTeamUploadRowQueryVariables>(ListErrorsForBatchSeasonTeamUploadRowDocument, options);
        }
export type ListErrorsForBatchSeasonTeamUploadRowQueryHookResult = ReturnType<typeof useListErrorsForBatchSeasonTeamUploadRowQuery>;
export type ListErrorsForBatchSeasonTeamUploadRowLazyQueryHookResult = ReturnType<typeof useListErrorsForBatchSeasonTeamUploadRowLazyQuery>;
export type ListErrorsForBatchSeasonTeamUploadRowQueryResult = Apollo.QueryResult<ListErrorsForBatchSeasonTeamUploadRowQuery, ListErrorsForBatchSeasonTeamUploadRowQueryVariables>;
export const GetCoachByIdDocument = gql`
    query getCoachById($id: String!) {
  getCoachById(input: {id: $id}) {
    id
    teamId
    teamName
    teamAbbreviation
    sport
    teamCity
    userId
    type
    role
    status
    email
    firstName
    lastName
    profilePic
    userStatus
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetCoachByIdQuery__
 *
 * To run a query within a React component, call `useGetCoachByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCoachByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCoachByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCoachByIdQuery(baseOptions: Apollo.QueryHookOptions<GetCoachByIdQuery, GetCoachByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCoachByIdQuery, GetCoachByIdQueryVariables>(GetCoachByIdDocument, options);
      }
export function useGetCoachByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCoachByIdQuery, GetCoachByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCoachByIdQuery, GetCoachByIdQueryVariables>(GetCoachByIdDocument, options);
        }
export type GetCoachByIdQueryHookResult = ReturnType<typeof useGetCoachByIdQuery>;
export type GetCoachByIdLazyQueryHookResult = ReturnType<typeof useGetCoachByIdLazyQuery>;
export type GetCoachByIdQueryResult = Apollo.QueryResult<GetCoachByIdQuery, GetCoachByIdQueryVariables>;
export const DeactivateCoachDocument = gql`
    mutation deactivateCoach($coachId: String!, $deactivateUser: Boolean!, $seasonId: String) {
  deactivateCoach(
    input: {coachId: $coachId, deactivateUser: $deactivateUser, seasonId: $seasonId}
  )
}
    `;
export type DeactivateCoachMutationFn = Apollo.MutationFunction<DeactivateCoachMutation, DeactivateCoachMutationVariables>;

/**
 * __useDeactivateCoachMutation__
 *
 * To run a mutation, you first call `useDeactivateCoachMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateCoachMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateCoachMutation, { data, loading, error }] = useDeactivateCoachMutation({
 *   variables: {
 *      coachId: // value for 'coachId'
 *      deactivateUser: // value for 'deactivateUser'
 *      seasonId: // value for 'seasonId'
 *   },
 * });
 */
export function useDeactivateCoachMutation(baseOptions?: Apollo.MutationHookOptions<DeactivateCoachMutation, DeactivateCoachMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeactivateCoachMutation, DeactivateCoachMutationVariables>(DeactivateCoachDocument, options);
      }
export type DeactivateCoachMutationHookResult = ReturnType<typeof useDeactivateCoachMutation>;
export type DeactivateCoachMutationResult = Apollo.MutationResult<DeactivateCoachMutation>;
export type DeactivateCoachMutationOptions = Apollo.BaseMutationOptions<DeactivateCoachMutation, DeactivateCoachMutationVariables>;
export const ReactivateCoachDocument = gql`
    mutation reactivateCoach($coachId: String!, $seasonId: String) {
  reactivateCoach(input: {coachId: $coachId, seasonId: $seasonId})
}
    `;
export type ReactivateCoachMutationFn = Apollo.MutationFunction<ReactivateCoachMutation, ReactivateCoachMutationVariables>;

/**
 * __useReactivateCoachMutation__
 *
 * To run a mutation, you first call `useReactivateCoachMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReactivateCoachMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reactivateCoachMutation, { data, loading, error }] = useReactivateCoachMutation({
 *   variables: {
 *      coachId: // value for 'coachId'
 *      seasonId: // value for 'seasonId'
 *   },
 * });
 */
export function useReactivateCoachMutation(baseOptions?: Apollo.MutationHookOptions<ReactivateCoachMutation, ReactivateCoachMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReactivateCoachMutation, ReactivateCoachMutationVariables>(ReactivateCoachDocument, options);
      }
export type ReactivateCoachMutationHookResult = ReturnType<typeof useReactivateCoachMutation>;
export type ReactivateCoachMutationResult = Apollo.MutationResult<ReactivateCoachMutation>;
export type ReactivateCoachMutationOptions = Apollo.BaseMutationOptions<ReactivateCoachMutation, ReactivateCoachMutationVariables>;
export const EditCoachDocument = gql`
    mutation editCoach($coachId: String!, $type: CoachTypes!, $role: String, $seasonId: String) {
  editCoach(
    input: {coachId: $coachId, type: $type, role: $role, seasonId: $seasonId}
  ) {
    id
    teamId
    teamName
    teamAbbreviation
    sport
    teamCity
    userId
    type
    role
    status
    email
    firstName
    lastName
    profilePic
    userStatus
    createdAt
    updatedAt
  }
}
    `;
export type EditCoachMutationFn = Apollo.MutationFunction<EditCoachMutation, EditCoachMutationVariables>;

/**
 * __useEditCoachMutation__
 *
 * To run a mutation, you first call `useEditCoachMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCoachMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCoachMutation, { data, loading, error }] = useEditCoachMutation({
 *   variables: {
 *      coachId: // value for 'coachId'
 *      type: // value for 'type'
 *      role: // value for 'role'
 *      seasonId: // value for 'seasonId'
 *   },
 * });
 */
export function useEditCoachMutation(baseOptions?: Apollo.MutationHookOptions<EditCoachMutation, EditCoachMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditCoachMutation, EditCoachMutationVariables>(EditCoachDocument, options);
      }
export type EditCoachMutationHookResult = ReturnType<typeof useEditCoachMutation>;
export type EditCoachMutationResult = Apollo.MutationResult<EditCoachMutation>;
export type EditCoachMutationOptions = Apollo.BaseMutationOptions<EditCoachMutation, EditCoachMutationVariables>;
export const CreateCompetitionIntegrationForOrganizationDocument = gql`
    mutation createCompetitionIntegrationForOrganization($orgId: String!, $name: String!, $description: String!, $status: CompetitionIntegrationStatuses!, $queueName: String!, $additionalOptions: [CreateCompetitionIntegrationForOrganizationAdditionalOptionInput!]!) {
  createCompetitionIntegrationForOrganization(
    input: {orgId: $orgId, name: $name, description: $description, status: $status, queueName: $queueName, additionalOptions: $additionalOptions}
  ) {
    id
    orgId
    orgName
    orgAbbreviation
    name
    description
    status
    queueName
    additionalOptions {
      id
      name
      isRequired
    }
    createdAt
    updatedAt
  }
}
    `;
export type CreateCompetitionIntegrationForOrganizationMutationFn = Apollo.MutationFunction<CreateCompetitionIntegrationForOrganizationMutation, CreateCompetitionIntegrationForOrganizationMutationVariables>;

/**
 * __useCreateCompetitionIntegrationForOrganizationMutation__
 *
 * To run a mutation, you first call `useCreateCompetitionIntegrationForOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompetitionIntegrationForOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompetitionIntegrationForOrganizationMutation, { data, loading, error }] = useCreateCompetitionIntegrationForOrganizationMutation({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      status: // value for 'status'
 *      queueName: // value for 'queueName'
 *      additionalOptions: // value for 'additionalOptions'
 *   },
 * });
 */
export function useCreateCompetitionIntegrationForOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<CreateCompetitionIntegrationForOrganizationMutation, CreateCompetitionIntegrationForOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCompetitionIntegrationForOrganizationMutation, CreateCompetitionIntegrationForOrganizationMutationVariables>(CreateCompetitionIntegrationForOrganizationDocument, options);
      }
export type CreateCompetitionIntegrationForOrganizationMutationHookResult = ReturnType<typeof useCreateCompetitionIntegrationForOrganizationMutation>;
export type CreateCompetitionIntegrationForOrganizationMutationResult = Apollo.MutationResult<CreateCompetitionIntegrationForOrganizationMutation>;
export type CreateCompetitionIntegrationForOrganizationMutationOptions = Apollo.BaseMutationOptions<CreateCompetitionIntegrationForOrganizationMutation, CreateCompetitionIntegrationForOrganizationMutationVariables>;
export const GetCompetitionIntegrationByIdDocument = gql`
    query getCompetitionIntegrationById($id: String!) {
  getCompetitionIntegrationById(input: {id: $id}) {
    id
    orgId
    orgName
    orgAbbreviation
    name
    description
    status
    queueName
    additionalOptions {
      id
      name
      isRequired
    }
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetCompetitionIntegrationByIdQuery__
 *
 * To run a query within a React component, call `useGetCompetitionIntegrationByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompetitionIntegrationByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompetitionIntegrationByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCompetitionIntegrationByIdQuery(baseOptions: Apollo.QueryHookOptions<GetCompetitionIntegrationByIdQuery, GetCompetitionIntegrationByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompetitionIntegrationByIdQuery, GetCompetitionIntegrationByIdQueryVariables>(GetCompetitionIntegrationByIdDocument, options);
      }
export function useGetCompetitionIntegrationByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompetitionIntegrationByIdQuery, GetCompetitionIntegrationByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompetitionIntegrationByIdQuery, GetCompetitionIntegrationByIdQueryVariables>(GetCompetitionIntegrationByIdDocument, options);
        }
export type GetCompetitionIntegrationByIdQueryHookResult = ReturnType<typeof useGetCompetitionIntegrationByIdQuery>;
export type GetCompetitionIntegrationByIdLazyQueryHookResult = ReturnType<typeof useGetCompetitionIntegrationByIdLazyQuery>;
export type GetCompetitionIntegrationByIdQueryResult = Apollo.QueryResult<GetCompetitionIntegrationByIdQuery, GetCompetitionIntegrationByIdQueryVariables>;
export const ListCompetitionIntegrationsDocument = gql`
    query listCompetitionIntegrations($offset: Int!, $limit: Int!, $orgId: String, $orgName: String, $name: String, $queueName: String, $status: CompetitionIntegrationStatuses) {
  listCompetitionIntegrations(
    input: {orgId: $orgId, orgName: $orgName, name: $name, queueName: $queueName, status: $status, pagination: {offset: $offset, limit: $limit}}
  ) {
    integrations {
      id
      orgId
      orgName
      orgAbbreviation
      name
      description
      status
      queueName
      additionalOptions {
        id
        name
        isRequired
      }
      createdAt
      updatedAt
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListCompetitionIntegrationsQuery__
 *
 * To run a query within a React component, call `useListCompetitionIntegrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCompetitionIntegrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCompetitionIntegrationsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      orgId: // value for 'orgId'
 *      orgName: // value for 'orgName'
 *      name: // value for 'name'
 *      queueName: // value for 'queueName'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useListCompetitionIntegrationsQuery(baseOptions: Apollo.QueryHookOptions<ListCompetitionIntegrationsQuery, ListCompetitionIntegrationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListCompetitionIntegrationsQuery, ListCompetitionIntegrationsQueryVariables>(ListCompetitionIntegrationsDocument, options);
      }
export function useListCompetitionIntegrationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListCompetitionIntegrationsQuery, ListCompetitionIntegrationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListCompetitionIntegrationsQuery, ListCompetitionIntegrationsQueryVariables>(ListCompetitionIntegrationsDocument, options);
        }
export type ListCompetitionIntegrationsQueryHookResult = ReturnType<typeof useListCompetitionIntegrationsQuery>;
export type ListCompetitionIntegrationsLazyQueryHookResult = ReturnType<typeof useListCompetitionIntegrationsLazyQuery>;
export type ListCompetitionIntegrationsQueryResult = Apollo.QueryResult<ListCompetitionIntegrationsQuery, ListCompetitionIntegrationsQueryVariables>;
export const UpdateCompetitionIntegrationDocument = gql`
    mutation updateCompetitionIntegration($id: String!, $name: String!, $description: String!, $status: CompetitionIntegrationStatuses!, $queueName: String!) {
  updateCompetitionIntegration(
    input: {id: $id, name: $name, description: $description, status: $status, queueName: $queueName}
  ) {
    id
    orgId
    orgName
    orgAbbreviation
    name
    description
    status
    queueName
    additionalOptions {
      id
      name
      isRequired
    }
    createdAt
    updatedAt
  }
}
    `;
export type UpdateCompetitionIntegrationMutationFn = Apollo.MutationFunction<UpdateCompetitionIntegrationMutation, UpdateCompetitionIntegrationMutationVariables>;

/**
 * __useUpdateCompetitionIntegrationMutation__
 *
 * To run a mutation, you first call `useUpdateCompetitionIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompetitionIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompetitionIntegrationMutation, { data, loading, error }] = useUpdateCompetitionIntegrationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      status: // value for 'status'
 *      queueName: // value for 'queueName'
 *   },
 * });
 */
export function useUpdateCompetitionIntegrationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompetitionIntegrationMutation, UpdateCompetitionIntegrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompetitionIntegrationMutation, UpdateCompetitionIntegrationMutationVariables>(UpdateCompetitionIntegrationDocument, options);
      }
export type UpdateCompetitionIntegrationMutationHookResult = ReturnType<typeof useUpdateCompetitionIntegrationMutation>;
export type UpdateCompetitionIntegrationMutationResult = Apollo.MutationResult<UpdateCompetitionIntegrationMutation>;
export type UpdateCompetitionIntegrationMutationOptions = Apollo.BaseMutationOptions<UpdateCompetitionIntegrationMutation, UpdateCompetitionIntegrationMutationVariables>;
export const DeleteCompetitionIntegrationDocument = gql`
    mutation deleteCompetitionIntegration($integrationId: String!) {
  deleteCompetitionIntegration(input: {integrationId: $integrationId})
}
    `;
export type DeleteCompetitionIntegrationMutationFn = Apollo.MutationFunction<DeleteCompetitionIntegrationMutation, DeleteCompetitionIntegrationMutationVariables>;

/**
 * __useDeleteCompetitionIntegrationMutation__
 *
 * To run a mutation, you first call `useDeleteCompetitionIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompetitionIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompetitionIntegrationMutation, { data, loading, error }] = useDeleteCompetitionIntegrationMutation({
 *   variables: {
 *      integrationId: // value for 'integrationId'
 *   },
 * });
 */
export function useDeleteCompetitionIntegrationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCompetitionIntegrationMutation, DeleteCompetitionIntegrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCompetitionIntegrationMutation, DeleteCompetitionIntegrationMutationVariables>(DeleteCompetitionIntegrationDocument, options);
      }
export type DeleteCompetitionIntegrationMutationHookResult = ReturnType<typeof useDeleteCompetitionIntegrationMutation>;
export type DeleteCompetitionIntegrationMutationResult = Apollo.MutationResult<DeleteCompetitionIntegrationMutation>;
export type DeleteCompetitionIntegrationMutationOptions = Apollo.BaseMutationOptions<DeleteCompetitionIntegrationMutation, DeleteCompetitionIntegrationMutationVariables>;
export const ListAdditionalOptionsForCompetitionIntegrationDocument = gql`
    query listAdditionalOptionsForCompetitionIntegration($offset: Int!, $limit: Int!, $integrationId: String!) {
  listAdditionalOptionsForCompetitionIntegration(
    input: {integrationId: $integrationId, pagination: {offset: $offset, limit: $limit}}
  ) {
    additionalOptions {
      id
      name
      isRequired
      createdAt
      updatedAt
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListAdditionalOptionsForCompetitionIntegrationQuery__
 *
 * To run a query within a React component, call `useListAdditionalOptionsForCompetitionIntegrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAdditionalOptionsForCompetitionIntegrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAdditionalOptionsForCompetitionIntegrationQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      integrationId: // value for 'integrationId'
 *   },
 * });
 */
export function useListAdditionalOptionsForCompetitionIntegrationQuery(baseOptions: Apollo.QueryHookOptions<ListAdditionalOptionsForCompetitionIntegrationQuery, ListAdditionalOptionsForCompetitionIntegrationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAdditionalOptionsForCompetitionIntegrationQuery, ListAdditionalOptionsForCompetitionIntegrationQueryVariables>(ListAdditionalOptionsForCompetitionIntegrationDocument, options);
      }
export function useListAdditionalOptionsForCompetitionIntegrationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAdditionalOptionsForCompetitionIntegrationQuery, ListAdditionalOptionsForCompetitionIntegrationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAdditionalOptionsForCompetitionIntegrationQuery, ListAdditionalOptionsForCompetitionIntegrationQueryVariables>(ListAdditionalOptionsForCompetitionIntegrationDocument, options);
        }
export type ListAdditionalOptionsForCompetitionIntegrationQueryHookResult = ReturnType<typeof useListAdditionalOptionsForCompetitionIntegrationQuery>;
export type ListAdditionalOptionsForCompetitionIntegrationLazyQueryHookResult = ReturnType<typeof useListAdditionalOptionsForCompetitionIntegrationLazyQuery>;
export type ListAdditionalOptionsForCompetitionIntegrationQueryResult = Apollo.QueryResult<ListAdditionalOptionsForCompetitionIntegrationQuery, ListAdditionalOptionsForCompetitionIntegrationQueryVariables>;
export const AddAdditionalOptionToCompetitionIntegrationDocument = gql`
    mutation addAdditionalOptionToCompetitionIntegration($integrationId: String!, $name: String!, $isRequired: Boolean!) {
  addAdditionalOptionToCompetitionIntegration(
    input: {integrationId: $integrationId, name: $name, isRequired: $isRequired}
  ) {
    id
    name
    isRequired
    createdAt
    updatedAt
  }
}
    `;
export type AddAdditionalOptionToCompetitionIntegrationMutationFn = Apollo.MutationFunction<AddAdditionalOptionToCompetitionIntegrationMutation, AddAdditionalOptionToCompetitionIntegrationMutationVariables>;

/**
 * __useAddAdditionalOptionToCompetitionIntegrationMutation__
 *
 * To run a mutation, you first call `useAddAdditionalOptionToCompetitionIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAdditionalOptionToCompetitionIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAdditionalOptionToCompetitionIntegrationMutation, { data, loading, error }] = useAddAdditionalOptionToCompetitionIntegrationMutation({
 *   variables: {
 *      integrationId: // value for 'integrationId'
 *      name: // value for 'name'
 *      isRequired: // value for 'isRequired'
 *   },
 * });
 */
export function useAddAdditionalOptionToCompetitionIntegrationMutation(baseOptions?: Apollo.MutationHookOptions<AddAdditionalOptionToCompetitionIntegrationMutation, AddAdditionalOptionToCompetitionIntegrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddAdditionalOptionToCompetitionIntegrationMutation, AddAdditionalOptionToCompetitionIntegrationMutationVariables>(AddAdditionalOptionToCompetitionIntegrationDocument, options);
      }
export type AddAdditionalOptionToCompetitionIntegrationMutationHookResult = ReturnType<typeof useAddAdditionalOptionToCompetitionIntegrationMutation>;
export type AddAdditionalOptionToCompetitionIntegrationMutationResult = Apollo.MutationResult<AddAdditionalOptionToCompetitionIntegrationMutation>;
export type AddAdditionalOptionToCompetitionIntegrationMutationOptions = Apollo.BaseMutationOptions<AddAdditionalOptionToCompetitionIntegrationMutation, AddAdditionalOptionToCompetitionIntegrationMutationVariables>;
export const UpdateAdditionalOptionForCompetitionIntegrationDocument = gql`
    mutation updateAdditionalOptionForCompetitionIntegration($integrationId: String!, $additionalOptionId: String!, $name: String!, $isRequired: Boolean!) {
  updateAdditionalOptionForCompetitionIntegration(
    input: {integrationId: $integrationId, additionalOptionId: $additionalOptionId, name: $name, isRequired: $isRequired}
  ) {
    id
    name
    isRequired
    createdAt
    updatedAt
  }
}
    `;
export type UpdateAdditionalOptionForCompetitionIntegrationMutationFn = Apollo.MutationFunction<UpdateAdditionalOptionForCompetitionIntegrationMutation, UpdateAdditionalOptionForCompetitionIntegrationMutationVariables>;

/**
 * __useUpdateAdditionalOptionForCompetitionIntegrationMutation__
 *
 * To run a mutation, you first call `useUpdateAdditionalOptionForCompetitionIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdditionalOptionForCompetitionIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdditionalOptionForCompetitionIntegrationMutation, { data, loading, error }] = useUpdateAdditionalOptionForCompetitionIntegrationMutation({
 *   variables: {
 *      integrationId: // value for 'integrationId'
 *      additionalOptionId: // value for 'additionalOptionId'
 *      name: // value for 'name'
 *      isRequired: // value for 'isRequired'
 *   },
 * });
 */
export function useUpdateAdditionalOptionForCompetitionIntegrationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAdditionalOptionForCompetitionIntegrationMutation, UpdateAdditionalOptionForCompetitionIntegrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAdditionalOptionForCompetitionIntegrationMutation, UpdateAdditionalOptionForCompetitionIntegrationMutationVariables>(UpdateAdditionalOptionForCompetitionIntegrationDocument, options);
      }
export type UpdateAdditionalOptionForCompetitionIntegrationMutationHookResult = ReturnType<typeof useUpdateAdditionalOptionForCompetitionIntegrationMutation>;
export type UpdateAdditionalOptionForCompetitionIntegrationMutationResult = Apollo.MutationResult<UpdateAdditionalOptionForCompetitionIntegrationMutation>;
export type UpdateAdditionalOptionForCompetitionIntegrationMutationOptions = Apollo.BaseMutationOptions<UpdateAdditionalOptionForCompetitionIntegrationMutation, UpdateAdditionalOptionForCompetitionIntegrationMutationVariables>;
export const RemoveAdditionalOptionFromCompetitionIntegrationDocument = gql`
    mutation removeAdditionalOptionFromCompetitionIntegration($integrationId: String!, $additionalOptionId: String!) {
  removeAdditionalOptionFromCompetitionIntegration(
    input: {integrationId: $integrationId, additionalOptionId: $additionalOptionId}
  )
}
    `;
export type RemoveAdditionalOptionFromCompetitionIntegrationMutationFn = Apollo.MutationFunction<RemoveAdditionalOptionFromCompetitionIntegrationMutation, RemoveAdditionalOptionFromCompetitionIntegrationMutationVariables>;

/**
 * __useRemoveAdditionalOptionFromCompetitionIntegrationMutation__
 *
 * To run a mutation, you first call `useRemoveAdditionalOptionFromCompetitionIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAdditionalOptionFromCompetitionIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAdditionalOptionFromCompetitionIntegrationMutation, { data, loading, error }] = useRemoveAdditionalOptionFromCompetitionIntegrationMutation({
 *   variables: {
 *      integrationId: // value for 'integrationId'
 *      additionalOptionId: // value for 'additionalOptionId'
 *   },
 * });
 */
export function useRemoveAdditionalOptionFromCompetitionIntegrationMutation(baseOptions?: Apollo.MutationHookOptions<RemoveAdditionalOptionFromCompetitionIntegrationMutation, RemoveAdditionalOptionFromCompetitionIntegrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveAdditionalOptionFromCompetitionIntegrationMutation, RemoveAdditionalOptionFromCompetitionIntegrationMutationVariables>(RemoveAdditionalOptionFromCompetitionIntegrationDocument, options);
      }
export type RemoveAdditionalOptionFromCompetitionIntegrationMutationHookResult = ReturnType<typeof useRemoveAdditionalOptionFromCompetitionIntegrationMutation>;
export type RemoveAdditionalOptionFromCompetitionIntegrationMutationResult = Apollo.MutationResult<RemoveAdditionalOptionFromCompetitionIntegrationMutation>;
export type RemoveAdditionalOptionFromCompetitionIntegrationMutationOptions = Apollo.BaseMutationOptions<RemoveAdditionalOptionFromCompetitionIntegrationMutation, RemoveAdditionalOptionFromCompetitionIntegrationMutationVariables>;
export const ListCompetitionIntegrationRequestsForSeasonDocument = gql`
    query listCompetitionIntegrationRequestsForSeason($offset: Int!, $limit: Int!, $seasonId: String!, $dateFrom: Date, $dateTo: Date, $integrationSearch: String, $statuses: [CompetitionIntegrationRequestStatuses!]) {
  listCompetitionIntegrationRequestsForSeason(
    input: {seasonId: $seasonId, dateFrom: $dateFrom, dateTo: $dateTo, integrationSearch: $integrationSearch, statuses: $statuses, pagination: {offset: $offset, limit: $limit}}
  ) {
    requests {
      id
      seasonId
      seasonName
      seasonIntegrationId
      integrationId
      integrationName
      status
      error
      totalCount
      processedCount
      errorCount
      successCount
      createdAt
      updatedAt
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListCompetitionIntegrationRequestsForSeasonQuery__
 *
 * To run a query within a React component, call `useListCompetitionIntegrationRequestsForSeasonQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCompetitionIntegrationRequestsForSeasonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCompetitionIntegrationRequestsForSeasonQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      seasonId: // value for 'seasonId'
 *      dateFrom: // value for 'dateFrom'
 *      dateTo: // value for 'dateTo'
 *      integrationSearch: // value for 'integrationSearch'
 *      statuses: // value for 'statuses'
 *   },
 * });
 */
export function useListCompetitionIntegrationRequestsForSeasonQuery(baseOptions: Apollo.QueryHookOptions<ListCompetitionIntegrationRequestsForSeasonQuery, ListCompetitionIntegrationRequestsForSeasonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListCompetitionIntegrationRequestsForSeasonQuery, ListCompetitionIntegrationRequestsForSeasonQueryVariables>(ListCompetitionIntegrationRequestsForSeasonDocument, options);
      }
export function useListCompetitionIntegrationRequestsForSeasonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListCompetitionIntegrationRequestsForSeasonQuery, ListCompetitionIntegrationRequestsForSeasonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListCompetitionIntegrationRequestsForSeasonQuery, ListCompetitionIntegrationRequestsForSeasonQueryVariables>(ListCompetitionIntegrationRequestsForSeasonDocument, options);
        }
export type ListCompetitionIntegrationRequestsForSeasonQueryHookResult = ReturnType<typeof useListCompetitionIntegrationRequestsForSeasonQuery>;
export type ListCompetitionIntegrationRequestsForSeasonLazyQueryHookResult = ReturnType<typeof useListCompetitionIntegrationRequestsForSeasonLazyQuery>;
export type ListCompetitionIntegrationRequestsForSeasonQueryResult = Apollo.QueryResult<ListCompetitionIntegrationRequestsForSeasonQuery, ListCompetitionIntegrationRequestsForSeasonQueryVariables>;
export const ListCompetitionIntegrationResponsesForRequestDocument = gql`
    query listCompetitionIntegrationResponsesForRequest($offset: Int!, $limit: Int!, $requestId: String!, $statuses: [CompetitionIntegrationResponseStatuses!]) {
  listCompetitionIntegrationResponsesForRequest(
    input: {requestId: $requestId, statuses: $statuses, pagination: {offset: $offset, limit: $limit}}
  ) {
    responses {
      id
      requestId
      index
      total
      status
      data
      errors {
        id
        error
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListCompetitionIntegrationResponsesForRequestQuery__
 *
 * To run a query within a React component, call `useListCompetitionIntegrationResponsesForRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCompetitionIntegrationResponsesForRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCompetitionIntegrationResponsesForRequestQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      requestId: // value for 'requestId'
 *      statuses: // value for 'statuses'
 *   },
 * });
 */
export function useListCompetitionIntegrationResponsesForRequestQuery(baseOptions: Apollo.QueryHookOptions<ListCompetitionIntegrationResponsesForRequestQuery, ListCompetitionIntegrationResponsesForRequestQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListCompetitionIntegrationResponsesForRequestQuery, ListCompetitionIntegrationResponsesForRequestQueryVariables>(ListCompetitionIntegrationResponsesForRequestDocument, options);
      }
export function useListCompetitionIntegrationResponsesForRequestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListCompetitionIntegrationResponsesForRequestQuery, ListCompetitionIntegrationResponsesForRequestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListCompetitionIntegrationResponsesForRequestQuery, ListCompetitionIntegrationResponsesForRequestQueryVariables>(ListCompetitionIntegrationResponsesForRequestDocument, options);
        }
export type ListCompetitionIntegrationResponsesForRequestQueryHookResult = ReturnType<typeof useListCompetitionIntegrationResponsesForRequestQuery>;
export type ListCompetitionIntegrationResponsesForRequestLazyQueryHookResult = ReturnType<typeof useListCompetitionIntegrationResponsesForRequestLazyQuery>;
export type ListCompetitionIntegrationResponsesForRequestQueryResult = Apollo.QueryResult<ListCompetitionIntegrationResponsesForRequestQuery, ListCompetitionIntegrationResponsesForRequestQueryVariables>;
export const ListStatsForCompetitionDocument = gql`
    query listStatsForCompetition($offset: Int!, $limit: Int!, $competitionId: String!, $nameOrDisplayText: String, $teamName: String) {
  listStatsForCompetition(
    input: {competitionId: $competitionId, nameOrDisplayText: $nameOrDisplayText, teamName: $teamName, pagination: {offset: $offset, limit: $limit}}
  ) {
    competitionId
    stats {
      id
      competitionId
      teamId
      name
      displayText
      valueString
      valueNumeric
      valueDate
      createdAt
      updatedAt
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListStatsForCompetitionQuery__
 *
 * To run a query within a React component, call `useListStatsForCompetitionQuery` and pass it any options that fit your needs.
 * When your component renders, `useListStatsForCompetitionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListStatsForCompetitionQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      competitionId: // value for 'competitionId'
 *      nameOrDisplayText: // value for 'nameOrDisplayText'
 *      teamName: // value for 'teamName'
 *   },
 * });
 */
export function useListStatsForCompetitionQuery(baseOptions: Apollo.QueryHookOptions<ListStatsForCompetitionQuery, ListStatsForCompetitionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListStatsForCompetitionQuery, ListStatsForCompetitionQueryVariables>(ListStatsForCompetitionDocument, options);
      }
export function useListStatsForCompetitionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListStatsForCompetitionQuery, ListStatsForCompetitionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListStatsForCompetitionQuery, ListStatsForCompetitionQueryVariables>(ListStatsForCompetitionDocument, options);
        }
export type ListStatsForCompetitionQueryHookResult = ReturnType<typeof useListStatsForCompetitionQuery>;
export type ListStatsForCompetitionLazyQueryHookResult = ReturnType<typeof useListStatsForCompetitionLazyQuery>;
export type ListStatsForCompetitionQueryResult = Apollo.QueryResult<ListStatsForCompetitionQuery, ListStatsForCompetitionQueryVariables>;
export const ListStatsForCompetitionByTeamDocument = gql`
    query listStatsForCompetitionByTeam($competitionId: String!, $teamId: String) {
  listStatsForCompetitionByTeam(
    input: {competitionId: $competitionId, teamId: $teamId}
  ) {
    id
    competitionId
    teamId
    name
    displayText
    valueString
    valueNumeric
    valueDate
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useListStatsForCompetitionByTeamQuery__
 *
 * To run a query within a React component, call `useListStatsForCompetitionByTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useListStatsForCompetitionByTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListStatsForCompetitionByTeamQuery({
 *   variables: {
 *      competitionId: // value for 'competitionId'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useListStatsForCompetitionByTeamQuery(baseOptions: Apollo.QueryHookOptions<ListStatsForCompetitionByTeamQuery, ListStatsForCompetitionByTeamQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListStatsForCompetitionByTeamQuery, ListStatsForCompetitionByTeamQueryVariables>(ListStatsForCompetitionByTeamDocument, options);
      }
export function useListStatsForCompetitionByTeamLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListStatsForCompetitionByTeamQuery, ListStatsForCompetitionByTeamQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListStatsForCompetitionByTeamQuery, ListStatsForCompetitionByTeamQueryVariables>(ListStatsForCompetitionByTeamDocument, options);
        }
export type ListStatsForCompetitionByTeamQueryHookResult = ReturnType<typeof useListStatsForCompetitionByTeamQuery>;
export type ListStatsForCompetitionByTeamLazyQueryHookResult = ReturnType<typeof useListStatsForCompetitionByTeamLazyQuery>;
export type ListStatsForCompetitionByTeamQueryResult = Apollo.QueryResult<ListStatsForCompetitionByTeamQuery, ListStatsForCompetitionByTeamQueryVariables>;
export const GetCompetitionSurveyByIdWithTokenDocument = gql`
    query getCompetitionSurveyByIdWithToken($surveyToken: String!) {
  getCompetitionSurveyByIdWithToken(input: {surveyToken: $surveyToken}) {
    id
    surveyId
    orgId
    seasonId
    sport
    finished
    hasCompetitionVerification
    hasIncidentReport
    allowAdditionalIncidentReports
    allowAdditionalIncidentReportsCutoffDays
    createdAt
    updatedAt
    completedDate
    recipients {
      userId
      email
      firstName
      lastName
      profilePic
      tookSurvey
    }
    competition {
      id
      externalId
      sport
      startTime
      timeZone
      location
      homeTeamScore
      homeTeamTieBreakerScore
      awayTeamScore
      awayTeamTieBreakerScore
      homeTeam {
        id
        name
        abbreviation
        sport
        city
        stateAbbreviation
        countryCode
        classification
        gender
        parentOrganizationName
        externalId
        ageGroup
        timeZone
        website
        logo
        createdAt
        updatedAt
      }
      awayTeam {
        id
        name
        abbreviation
        sport
        city
        stateAbbreviation
        countryCode
        classification
        gender
        parentOrganizationName
        externalId
        ageGroup
        timeZone
        website
        logo
        createdAt
        updatedAt
      }
      organization {
        id
        name
        sport
        status
        abbreviation
        site
        logo
        createdAt
        updatedAt
      }
      season {
        id
        leagueId
        leagueName
        leagueLogo
        name
        description
        status
        startDate
        endDate
        createdAt
        updatedAt
      }
      officials {
        id
        competitionId
        assessmentObjectId
        order
        official {
          id
          sport
          userId
          status
          email
          firstName
          lastName
          profilePic
          createdAt
          updatedAt
        }
        type
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
    info {
      name
      description
      audience
    }
    sections {
      id
      name
      description
      order
      finished
      createdAt
      updatedAt
      coach {
        id
        teamId
        teamName
        teamAbbreviation
        sport
        teamCity
        userId
        type
        role
        status
        email
        firstName
        lastName
        profilePic
        createdAt
        updatedAt
      }
      official {
        id
        sport
        userId
        status
        email
        firstName
        lastName
        externalIds {
          id
          organizationId
          externalId
        }
        profilePic
        createdAt
        updatedAt
      }
      organization {
        id
        name
        sport
        status
        abbreviation
        site
        logo
        createdAt
        updatedAt
      }
      questions {
        id
        name
        description
        order
        isRequired
        canAddToKeyIncidents
        questionType
        selectedOption
        selectedOptionOrder
        responseShortAnswer
        responseText
        responseNumber
        responses {
          id
          value
          createdAt
          updatedAt
        }
        options {
          id
          value
          order
        }
      }
      assessmentObject {
        id
        sport
        type
        classification
        name
      }
    }
  }
}
    `;

/**
 * __useGetCompetitionSurveyByIdWithTokenQuery__
 *
 * To run a query within a React component, call `useGetCompetitionSurveyByIdWithTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompetitionSurveyByIdWithTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompetitionSurveyByIdWithTokenQuery({
 *   variables: {
 *      surveyToken: // value for 'surveyToken'
 *   },
 * });
 */
export function useGetCompetitionSurveyByIdWithTokenQuery(baseOptions: Apollo.QueryHookOptions<GetCompetitionSurveyByIdWithTokenQuery, GetCompetitionSurveyByIdWithTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompetitionSurveyByIdWithTokenQuery, GetCompetitionSurveyByIdWithTokenQueryVariables>(GetCompetitionSurveyByIdWithTokenDocument, options);
      }
export function useGetCompetitionSurveyByIdWithTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompetitionSurveyByIdWithTokenQuery, GetCompetitionSurveyByIdWithTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompetitionSurveyByIdWithTokenQuery, GetCompetitionSurveyByIdWithTokenQueryVariables>(GetCompetitionSurveyByIdWithTokenDocument, options);
        }
export type GetCompetitionSurveyByIdWithTokenQueryHookResult = ReturnType<typeof useGetCompetitionSurveyByIdWithTokenQuery>;
export type GetCompetitionSurveyByIdWithTokenLazyQueryHookResult = ReturnType<typeof useGetCompetitionSurveyByIdWithTokenLazyQuery>;
export type GetCompetitionSurveyByIdWithTokenQueryResult = Apollo.QueryResult<GetCompetitionSurveyByIdWithTokenQuery, GetCompetitionSurveyByIdWithTokenQueryVariables>;
export const StartCompetitionSurveyWithTokenDocument = gql`
    mutation startCompetitionSurveyWithToken($surveyToken: String!) {
  startCompetitionSurveyWithToken(input: {surveyToken: $surveyToken})
}
    `;
export type StartCompetitionSurveyWithTokenMutationFn = Apollo.MutationFunction<StartCompetitionSurveyWithTokenMutation, StartCompetitionSurveyWithTokenMutationVariables>;

/**
 * __useStartCompetitionSurveyWithTokenMutation__
 *
 * To run a mutation, you first call `useStartCompetitionSurveyWithTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartCompetitionSurveyWithTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startCompetitionSurveyWithTokenMutation, { data, loading, error }] = useStartCompetitionSurveyWithTokenMutation({
 *   variables: {
 *      surveyToken: // value for 'surveyToken'
 *   },
 * });
 */
export function useStartCompetitionSurveyWithTokenMutation(baseOptions?: Apollo.MutationHookOptions<StartCompetitionSurveyWithTokenMutation, StartCompetitionSurveyWithTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartCompetitionSurveyWithTokenMutation, StartCompetitionSurveyWithTokenMutationVariables>(StartCompetitionSurveyWithTokenDocument, options);
      }
export type StartCompetitionSurveyWithTokenMutationHookResult = ReturnType<typeof useStartCompetitionSurveyWithTokenMutation>;
export type StartCompetitionSurveyWithTokenMutationResult = Apollo.MutationResult<StartCompetitionSurveyWithTokenMutation>;
export type StartCompetitionSurveyWithTokenMutationOptions = Apollo.BaseMutationOptions<StartCompetitionSurveyWithTokenMutation, StartCompetitionSurveyWithTokenMutationVariables>;
export const UpdateCompetitionSurveyCompetitionScoreWithTokenDocument = gql`
    mutation updateCompetitionSurveyCompetitionScoreWithToken($surveyToken: String!, $homeTeamScore: Int!, $awayTeamScore: Int!, $homeTeamTieBreakerScore: Int, $awayTeamTieBreakerScore: Int) {
  updateCompetitionSurveyCompetitionScoreWithToken(
    input: {surveyToken: $surveyToken, homeTeamScore: $homeTeamScore, awayTeamScore: $awayTeamScore, homeTeamTieBreakerScore: $homeTeamTieBreakerScore, awayTeamTieBreakerScore: $awayTeamTieBreakerScore}
  )
}
    `;
export type UpdateCompetitionSurveyCompetitionScoreWithTokenMutationFn = Apollo.MutationFunction<UpdateCompetitionSurveyCompetitionScoreWithTokenMutation, UpdateCompetitionSurveyCompetitionScoreWithTokenMutationVariables>;

/**
 * __useUpdateCompetitionSurveyCompetitionScoreWithTokenMutation__
 *
 * To run a mutation, you first call `useUpdateCompetitionSurveyCompetitionScoreWithTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompetitionSurveyCompetitionScoreWithTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompetitionSurveyCompetitionScoreWithTokenMutation, { data, loading, error }] = useUpdateCompetitionSurveyCompetitionScoreWithTokenMutation({
 *   variables: {
 *      surveyToken: // value for 'surveyToken'
 *      homeTeamScore: // value for 'homeTeamScore'
 *      awayTeamScore: // value for 'awayTeamScore'
 *      homeTeamTieBreakerScore: // value for 'homeTeamTieBreakerScore'
 *      awayTeamTieBreakerScore: // value for 'awayTeamTieBreakerScore'
 *   },
 * });
 */
export function useUpdateCompetitionSurveyCompetitionScoreWithTokenMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompetitionSurveyCompetitionScoreWithTokenMutation, UpdateCompetitionSurveyCompetitionScoreWithTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompetitionSurveyCompetitionScoreWithTokenMutation, UpdateCompetitionSurveyCompetitionScoreWithTokenMutationVariables>(UpdateCompetitionSurveyCompetitionScoreWithTokenDocument, options);
      }
export type UpdateCompetitionSurveyCompetitionScoreWithTokenMutationHookResult = ReturnType<typeof useUpdateCompetitionSurveyCompetitionScoreWithTokenMutation>;
export type UpdateCompetitionSurveyCompetitionScoreWithTokenMutationResult = Apollo.MutationResult<UpdateCompetitionSurveyCompetitionScoreWithTokenMutation>;
export type UpdateCompetitionSurveyCompetitionScoreWithTokenMutationOptions = Apollo.BaseMutationOptions<UpdateCompetitionSurveyCompetitionScoreWithTokenMutation, UpdateCompetitionSurveyCompetitionScoreWithTokenMutationVariables>;
export const UpdateCompetitionSurveyMultipleChoiceResponseWithTokenDocument = gql`
    mutation updateCompetitionSurveyMultipleChoiceResponseWithToken($surveyToken: String!, $questionId: String!, $selectedOptionId: String!) {
  updateCompetitionSurveyMultipleChoiceResponseWithToken(
    input: {surveyToken: $surveyToken, questionId: $questionId, selectedOptionId: $selectedOptionId}
  ) {
    id
    name
    description
    order
    isRequired
    canAddToKeyIncidents
    questionType
    selectedOption
    selectedOptionOrder
    responseShortAnswer
    responseText
    responseNumber
    responses {
      id
      value
      createdAt
      updatedAt
    }
    options {
      id
      value
      order
    }
  }
}
    `;
export type UpdateCompetitionSurveyMultipleChoiceResponseWithTokenMutationFn = Apollo.MutationFunction<UpdateCompetitionSurveyMultipleChoiceResponseWithTokenMutation, UpdateCompetitionSurveyMultipleChoiceResponseWithTokenMutationVariables>;

/**
 * __useUpdateCompetitionSurveyMultipleChoiceResponseWithTokenMutation__
 *
 * To run a mutation, you first call `useUpdateCompetitionSurveyMultipleChoiceResponseWithTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompetitionSurveyMultipleChoiceResponseWithTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompetitionSurveyMultipleChoiceResponseWithTokenMutation, { data, loading, error }] = useUpdateCompetitionSurveyMultipleChoiceResponseWithTokenMutation({
 *   variables: {
 *      surveyToken: // value for 'surveyToken'
 *      questionId: // value for 'questionId'
 *      selectedOptionId: // value for 'selectedOptionId'
 *   },
 * });
 */
export function useUpdateCompetitionSurveyMultipleChoiceResponseWithTokenMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompetitionSurveyMultipleChoiceResponseWithTokenMutation, UpdateCompetitionSurveyMultipleChoiceResponseWithTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompetitionSurveyMultipleChoiceResponseWithTokenMutation, UpdateCompetitionSurveyMultipleChoiceResponseWithTokenMutationVariables>(UpdateCompetitionSurveyMultipleChoiceResponseWithTokenDocument, options);
      }
export type UpdateCompetitionSurveyMultipleChoiceResponseWithTokenMutationHookResult = ReturnType<typeof useUpdateCompetitionSurveyMultipleChoiceResponseWithTokenMutation>;
export type UpdateCompetitionSurveyMultipleChoiceResponseWithTokenMutationResult = Apollo.MutationResult<UpdateCompetitionSurveyMultipleChoiceResponseWithTokenMutation>;
export type UpdateCompetitionSurveyMultipleChoiceResponseWithTokenMutationOptions = Apollo.BaseMutationOptions<UpdateCompetitionSurveyMultipleChoiceResponseWithTokenMutation, UpdateCompetitionSurveyMultipleChoiceResponseWithTokenMutationVariables>;
export const UpdateCompetitionSurveyShortAnswerResponseWithTokenDocument = gql`
    mutation updateCompetitionSurveyShortAnswerResponseWithToken($surveyToken: String!, $questionId: String!, $response: String!) {
  updateCompetitionSurveyShortAnswerResponseWithToken(
    input: {surveyToken: $surveyToken, questionId: $questionId, response: $response}
  ) {
    id
    name
    description
    order
    isRequired
    canAddToKeyIncidents
    questionType
    selectedOption
    selectedOptionOrder
    responseShortAnswer
    responseText
    responseNumber
    responses {
      id
      value
      createdAt
      updatedAt
    }
    options {
      id
      value
      order
    }
  }
}
    `;
export type UpdateCompetitionSurveyShortAnswerResponseWithTokenMutationFn = Apollo.MutationFunction<UpdateCompetitionSurveyShortAnswerResponseWithTokenMutation, UpdateCompetitionSurveyShortAnswerResponseWithTokenMutationVariables>;

/**
 * __useUpdateCompetitionSurveyShortAnswerResponseWithTokenMutation__
 *
 * To run a mutation, you first call `useUpdateCompetitionSurveyShortAnswerResponseWithTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompetitionSurveyShortAnswerResponseWithTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompetitionSurveyShortAnswerResponseWithTokenMutation, { data, loading, error }] = useUpdateCompetitionSurveyShortAnswerResponseWithTokenMutation({
 *   variables: {
 *      surveyToken: // value for 'surveyToken'
 *      questionId: // value for 'questionId'
 *      response: // value for 'response'
 *   },
 * });
 */
export function useUpdateCompetitionSurveyShortAnswerResponseWithTokenMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompetitionSurveyShortAnswerResponseWithTokenMutation, UpdateCompetitionSurveyShortAnswerResponseWithTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompetitionSurveyShortAnswerResponseWithTokenMutation, UpdateCompetitionSurveyShortAnswerResponseWithTokenMutationVariables>(UpdateCompetitionSurveyShortAnswerResponseWithTokenDocument, options);
      }
export type UpdateCompetitionSurveyShortAnswerResponseWithTokenMutationHookResult = ReturnType<typeof useUpdateCompetitionSurveyShortAnswerResponseWithTokenMutation>;
export type UpdateCompetitionSurveyShortAnswerResponseWithTokenMutationResult = Apollo.MutationResult<UpdateCompetitionSurveyShortAnswerResponseWithTokenMutation>;
export type UpdateCompetitionSurveyShortAnswerResponseWithTokenMutationOptions = Apollo.BaseMutationOptions<UpdateCompetitionSurveyShortAnswerResponseWithTokenMutation, UpdateCompetitionSurveyShortAnswerResponseWithTokenMutationVariables>;
export const UpdateCompetitionSurveyTextResponseWithTokenDocument = gql`
    mutation updateCompetitionSurveyTextResponseWithToken($surveyToken: String!, $questionId: String!, $response: String!) {
  updateCompetitionSurveyTextResponseWithToken(
    input: {surveyToken: $surveyToken, questionId: $questionId, response: $response}
  ) {
    id
    name
    description
    order
    isRequired
    canAddToKeyIncidents
    questionType
    selectedOption
    selectedOptionOrder
    responseShortAnswer
    responseText
    responseNumber
    responses {
      id
      value
      createdAt
      updatedAt
    }
    options {
      id
      value
      order
    }
  }
}
    `;
export type UpdateCompetitionSurveyTextResponseWithTokenMutationFn = Apollo.MutationFunction<UpdateCompetitionSurveyTextResponseWithTokenMutation, UpdateCompetitionSurveyTextResponseWithTokenMutationVariables>;

/**
 * __useUpdateCompetitionSurveyTextResponseWithTokenMutation__
 *
 * To run a mutation, you first call `useUpdateCompetitionSurveyTextResponseWithTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompetitionSurveyTextResponseWithTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompetitionSurveyTextResponseWithTokenMutation, { data, loading, error }] = useUpdateCompetitionSurveyTextResponseWithTokenMutation({
 *   variables: {
 *      surveyToken: // value for 'surveyToken'
 *      questionId: // value for 'questionId'
 *      response: // value for 'response'
 *   },
 * });
 */
export function useUpdateCompetitionSurveyTextResponseWithTokenMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompetitionSurveyTextResponseWithTokenMutation, UpdateCompetitionSurveyTextResponseWithTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompetitionSurveyTextResponseWithTokenMutation, UpdateCompetitionSurveyTextResponseWithTokenMutationVariables>(UpdateCompetitionSurveyTextResponseWithTokenDocument, options);
      }
export type UpdateCompetitionSurveyTextResponseWithTokenMutationHookResult = ReturnType<typeof useUpdateCompetitionSurveyTextResponseWithTokenMutation>;
export type UpdateCompetitionSurveyTextResponseWithTokenMutationResult = Apollo.MutationResult<UpdateCompetitionSurveyTextResponseWithTokenMutation>;
export type UpdateCompetitionSurveyTextResponseWithTokenMutationOptions = Apollo.BaseMutationOptions<UpdateCompetitionSurveyTextResponseWithTokenMutation, UpdateCompetitionSurveyTextResponseWithTokenMutationVariables>;
export const UpdateCompetitionSurveyNumberResponseWithTokenDocument = gql`
    mutation updateCompetitionSurveyNumberResponseWithToken($surveyToken: String!, $questionId: String!, $response: Float!) {
  updateCompetitionSurveyNumberResponseWithToken(
    input: {surveyToken: $surveyToken, questionId: $questionId, response: $response}
  ) {
    id
    name
    description
    order
    isRequired
    canAddToKeyIncidents
    questionType
    selectedOption
    selectedOptionOrder
    responseShortAnswer
    responseText
    responseNumber
    responses {
      id
      value
      createdAt
      updatedAt
    }
    options {
      id
      value
      order
    }
  }
}
    `;
export type UpdateCompetitionSurveyNumberResponseWithTokenMutationFn = Apollo.MutationFunction<UpdateCompetitionSurveyNumberResponseWithTokenMutation, UpdateCompetitionSurveyNumberResponseWithTokenMutationVariables>;

/**
 * __useUpdateCompetitionSurveyNumberResponseWithTokenMutation__
 *
 * To run a mutation, you first call `useUpdateCompetitionSurveyNumberResponseWithTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompetitionSurveyNumberResponseWithTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompetitionSurveyNumberResponseWithTokenMutation, { data, loading, error }] = useUpdateCompetitionSurveyNumberResponseWithTokenMutation({
 *   variables: {
 *      surveyToken: // value for 'surveyToken'
 *      questionId: // value for 'questionId'
 *      response: // value for 'response'
 *   },
 * });
 */
export function useUpdateCompetitionSurveyNumberResponseWithTokenMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompetitionSurveyNumberResponseWithTokenMutation, UpdateCompetitionSurveyNumberResponseWithTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompetitionSurveyNumberResponseWithTokenMutation, UpdateCompetitionSurveyNumberResponseWithTokenMutationVariables>(UpdateCompetitionSurveyNumberResponseWithTokenDocument, options);
      }
export type UpdateCompetitionSurveyNumberResponseWithTokenMutationHookResult = ReturnType<typeof useUpdateCompetitionSurveyNumberResponseWithTokenMutation>;
export type UpdateCompetitionSurveyNumberResponseWithTokenMutationResult = Apollo.MutationResult<UpdateCompetitionSurveyNumberResponseWithTokenMutation>;
export type UpdateCompetitionSurveyNumberResponseWithTokenMutationOptions = Apollo.BaseMutationOptions<UpdateCompetitionSurveyNumberResponseWithTokenMutation, UpdateCompetitionSurveyNumberResponseWithTokenMutationVariables>;
export const ListKeyIncidentsForCompetitionSurveyWithTokenDocument = gql`
    query listKeyIncidentsForCompetitionSurveyWithToken($surveyToken: String!) {
  listKeyIncidentsForCompetitionSurveyWithToken(
    input: {surveyToken: $surveyToken}
  ) {
    keyIncidents {
      id
      competitionSurveyId
      dynamicCompetitionSurveyId
      submittedBy {
        userId
        email
        firstName
        lastName
        profilePic
        teamId
        teamName
        teamLogo
        officialId
        assessmentObjectId
        assessmentObjectName
      }
      competitionInfo {
        competitionId
        externalId
        sport
        homeTeamId
        homeTeamName
        homeTeamScore
        homeTeamTieBreakerScore
        awayTeamId
        awayTeamName
        awayTeamScore
        awayTeamTieBreakerScore
        startTime
        timeZone
        location
      }
      assessmentObject {
        id
        sport
        type
        classification
        name
        createdAt
        updatedAt
      }
      official {
        id
        sport
        userId
        status
        email
        firstName
        lastName
        profilePic
        createdAt
        updatedAt
      }
      team {
        id
        name
        abbreviation
        sport
        city
        stateAbbreviation
        countryCode
        classification
        gender
        parentOrganizationName
        externalId
        ageGroup
        timeZone
        website
        logo
        createdAt
        updatedAt
      }
      organizationId
      organizationName
      leagueId
      leagueName
      seasonId
      seasonName
      competitionSurveyFeedbackId
      dynamicCompetitionSurveyFeedbackId
      dynamicCompetitionSurveySubFeedbackId
      type
      status
      description
      time
      videoStatus
      videoUrl
      videoError
      period {
        id
        sport
        name
        createdAt
        updatedAt
      }
      impactedOutcome
      resolvedDate
      reopenedDate
      createdAt
      updatedAt
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListKeyIncidentsForCompetitionSurveyWithTokenQuery__
 *
 * To run a query within a React component, call `useListKeyIncidentsForCompetitionSurveyWithTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useListKeyIncidentsForCompetitionSurveyWithTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListKeyIncidentsForCompetitionSurveyWithTokenQuery({
 *   variables: {
 *      surveyToken: // value for 'surveyToken'
 *   },
 * });
 */
export function useListKeyIncidentsForCompetitionSurveyWithTokenQuery(baseOptions: Apollo.QueryHookOptions<ListKeyIncidentsForCompetitionSurveyWithTokenQuery, ListKeyIncidentsForCompetitionSurveyWithTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListKeyIncidentsForCompetitionSurveyWithTokenQuery, ListKeyIncidentsForCompetitionSurveyWithTokenQueryVariables>(ListKeyIncidentsForCompetitionSurveyWithTokenDocument, options);
      }
export function useListKeyIncidentsForCompetitionSurveyWithTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListKeyIncidentsForCompetitionSurveyWithTokenQuery, ListKeyIncidentsForCompetitionSurveyWithTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListKeyIncidentsForCompetitionSurveyWithTokenQuery, ListKeyIncidentsForCompetitionSurveyWithTokenQueryVariables>(ListKeyIncidentsForCompetitionSurveyWithTokenDocument, options);
        }
export type ListKeyIncidentsForCompetitionSurveyWithTokenQueryHookResult = ReturnType<typeof useListKeyIncidentsForCompetitionSurveyWithTokenQuery>;
export type ListKeyIncidentsForCompetitionSurveyWithTokenLazyQueryHookResult = ReturnType<typeof useListKeyIncidentsForCompetitionSurveyWithTokenLazyQuery>;
export type ListKeyIncidentsForCompetitionSurveyWithTokenQueryResult = Apollo.QueryResult<ListKeyIncidentsForCompetitionSurveyWithTokenQuery, ListKeyIncidentsForCompetitionSurveyWithTokenQueryVariables>;
export const ConvertCompetitionSurveySectionFeedbackToKeyIncidentWithTokenDocument = gql`
    mutation convertCompetitionSurveySectionFeedbackToKeyIncidentWithToken($surveyToken: String!, $questionId: String!, $feedbackResponseId: String!, $incidentType: KeyIncidentTypes!, $impactedOutcome: Boolean!, $time: String, $description: String, $periodId: String, $externalLinks: [CreateKeyIncidentExternalLinkForCompetitionSurveyInput!]!) {
  convertCompetitionSurveySectionFeedbackToKeyIncidentWithToken(
    input: {surveyToken: $surveyToken, questionId: $questionId, feedbackResponseId: $feedbackResponseId, incidentType: $incidentType, impactedOutcome: $impactedOutcome, time: $time, description: $description, periodId: $periodId, externalLinks: $externalLinks}
  ) {
    id
    competitionSurveyId
    dynamicCompetitionSurveyId
    submittedBy {
      userId
      email
      firstName
      lastName
      profilePic
      teamId
      teamName
      teamLogo
      officialId
      assessmentObjectId
      assessmentObjectName
    }
    competitionInfo {
      competitionId
      externalId
      sport
      homeTeamId
      homeTeamName
      homeTeamScore
      homeTeamTieBreakerScore
      awayTeamId
      awayTeamName
      awayTeamScore
      awayTeamTieBreakerScore
      startTime
      timeZone
      location
    }
    assessmentObject {
      id
      sport
      type
      classification
      name
      createdAt
      updatedAt
    }
    official {
      id
      sport
      userId
      status
      email
      firstName
      lastName
      profilePic
      createdAt
      updatedAt
    }
    team {
      id
      name
      abbreviation
      sport
      city
      stateAbbreviation
      countryCode
      classification
      gender
      parentOrganizationName
      externalId
      ageGroup
      timeZone
      website
      logo
      createdAt
      updatedAt
    }
    organizationId
    organizationName
    leagueId
    leagueName
    seasonId
    seasonName
    competitionSurveyFeedbackId
    dynamicCompetitionSurveyFeedbackId
    dynamicCompetitionSurveySubFeedbackId
    type
    status
    description
    time
    videoStatus
    videoUrl
    videoError
    period {
      id
      sport
      name
      createdAt
      updatedAt
    }
    impactedOutcome
    resolvedDate
    reopenedDate
    createdAt
    updatedAt
  }
}
    `;
export type ConvertCompetitionSurveySectionFeedbackToKeyIncidentWithTokenMutationFn = Apollo.MutationFunction<ConvertCompetitionSurveySectionFeedbackToKeyIncidentWithTokenMutation, ConvertCompetitionSurveySectionFeedbackToKeyIncidentWithTokenMutationVariables>;

/**
 * __useConvertCompetitionSurveySectionFeedbackToKeyIncidentWithTokenMutation__
 *
 * To run a mutation, you first call `useConvertCompetitionSurveySectionFeedbackToKeyIncidentWithTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConvertCompetitionSurveySectionFeedbackToKeyIncidentWithTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [convertCompetitionSurveySectionFeedbackToKeyIncidentWithTokenMutation, { data, loading, error }] = useConvertCompetitionSurveySectionFeedbackToKeyIncidentWithTokenMutation({
 *   variables: {
 *      surveyToken: // value for 'surveyToken'
 *      questionId: // value for 'questionId'
 *      feedbackResponseId: // value for 'feedbackResponseId'
 *      incidentType: // value for 'incidentType'
 *      impactedOutcome: // value for 'impactedOutcome'
 *      time: // value for 'time'
 *      description: // value for 'description'
 *      periodId: // value for 'periodId'
 *      externalLinks: // value for 'externalLinks'
 *   },
 * });
 */
export function useConvertCompetitionSurveySectionFeedbackToKeyIncidentWithTokenMutation(baseOptions?: Apollo.MutationHookOptions<ConvertCompetitionSurveySectionFeedbackToKeyIncidentWithTokenMutation, ConvertCompetitionSurveySectionFeedbackToKeyIncidentWithTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConvertCompetitionSurveySectionFeedbackToKeyIncidentWithTokenMutation, ConvertCompetitionSurveySectionFeedbackToKeyIncidentWithTokenMutationVariables>(ConvertCompetitionSurveySectionFeedbackToKeyIncidentWithTokenDocument, options);
      }
export type ConvertCompetitionSurveySectionFeedbackToKeyIncidentWithTokenMutationHookResult = ReturnType<typeof useConvertCompetitionSurveySectionFeedbackToKeyIncidentWithTokenMutation>;
export type ConvertCompetitionSurveySectionFeedbackToKeyIncidentWithTokenMutationResult = Apollo.MutationResult<ConvertCompetitionSurveySectionFeedbackToKeyIncidentWithTokenMutation>;
export type ConvertCompetitionSurveySectionFeedbackToKeyIncidentWithTokenMutationOptions = Apollo.BaseMutationOptions<ConvertCompetitionSurveySectionFeedbackToKeyIncidentWithTokenMutation, ConvertCompetitionSurveySectionFeedbackToKeyIncidentWithTokenMutationVariables>;
export const AddKeyIncidentToCompetitionSurveyWithTokenDocument = gql`
    mutation addKeyIncidentToCompetitionSurveyWithToken($surveyToken: String!, $description: String!, $incidentType: KeyIncidentTypes!, $impactedOutcome: Boolean!, $time: String, $sectionId: String, $periodId: String, $externalLinks: [CreateKeyIncidentExternalLinkForCompetitionSurveyInput!]!) {
  addKeyIncidentToCompetitionSurveyWithToken(
    input: {surveyToken: $surveyToken, description: $description, incidentType: $incidentType, impactedOutcome: $impactedOutcome, time: $time, sectionId: $sectionId, periodId: $periodId, externalLinks: $externalLinks}
  ) {
    id
    competitionSurveyId
    dynamicCompetitionSurveyId
    submittedBy {
      userId
      email
      firstName
      lastName
      profilePic
      teamId
      teamName
      teamLogo
      officialId
      assessmentObjectId
      assessmentObjectName
    }
    competitionInfo {
      competitionId
      externalId
      sport
      homeTeamId
      homeTeamName
      homeTeamScore
      homeTeamTieBreakerScore
      awayTeamId
      awayTeamName
      awayTeamScore
      awayTeamTieBreakerScore
      startTime
      timeZone
      location
    }
    assessmentObject {
      id
      sport
      type
      classification
      name
      createdAt
      updatedAt
    }
    official {
      id
      sport
      userId
      status
      email
      firstName
      lastName
      profilePic
      createdAt
      updatedAt
    }
    team {
      id
      name
      abbreviation
      sport
      city
      stateAbbreviation
      countryCode
      classification
      gender
      parentOrganizationName
      externalId
      ageGroup
      timeZone
      website
      logo
      createdAt
      updatedAt
    }
    organizationId
    organizationName
    leagueId
    leagueName
    seasonId
    seasonName
    competitionSurveyFeedbackId
    dynamicCompetitionSurveyFeedbackId
    dynamicCompetitionSurveySubFeedbackId
    type
    status
    description
    time
    videoStatus
    videoUrl
    videoError
    period {
      id
      sport
      name
      createdAt
      updatedAt
    }
    impactedOutcome
    resolvedDate
    reopenedDate
    createdAt
    updatedAt
  }
}
    `;
export type AddKeyIncidentToCompetitionSurveyWithTokenMutationFn = Apollo.MutationFunction<AddKeyIncidentToCompetitionSurveyWithTokenMutation, AddKeyIncidentToCompetitionSurveyWithTokenMutationVariables>;

/**
 * __useAddKeyIncidentToCompetitionSurveyWithTokenMutation__
 *
 * To run a mutation, you first call `useAddKeyIncidentToCompetitionSurveyWithTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddKeyIncidentToCompetitionSurveyWithTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addKeyIncidentToCompetitionSurveyWithTokenMutation, { data, loading, error }] = useAddKeyIncidentToCompetitionSurveyWithTokenMutation({
 *   variables: {
 *      surveyToken: // value for 'surveyToken'
 *      description: // value for 'description'
 *      incidentType: // value for 'incidentType'
 *      impactedOutcome: // value for 'impactedOutcome'
 *      time: // value for 'time'
 *      sectionId: // value for 'sectionId'
 *      periodId: // value for 'periodId'
 *      externalLinks: // value for 'externalLinks'
 *   },
 * });
 */
export function useAddKeyIncidentToCompetitionSurveyWithTokenMutation(baseOptions?: Apollo.MutationHookOptions<AddKeyIncidentToCompetitionSurveyWithTokenMutation, AddKeyIncidentToCompetitionSurveyWithTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddKeyIncidentToCompetitionSurveyWithTokenMutation, AddKeyIncidentToCompetitionSurveyWithTokenMutationVariables>(AddKeyIncidentToCompetitionSurveyWithTokenDocument, options);
      }
export type AddKeyIncidentToCompetitionSurveyWithTokenMutationHookResult = ReturnType<typeof useAddKeyIncidentToCompetitionSurveyWithTokenMutation>;
export type AddKeyIncidentToCompetitionSurveyWithTokenMutationResult = Apollo.MutationResult<AddKeyIncidentToCompetitionSurveyWithTokenMutation>;
export type AddKeyIncidentToCompetitionSurveyWithTokenMutationOptions = Apollo.BaseMutationOptions<AddKeyIncidentToCompetitionSurveyWithTokenMutation, AddKeyIncidentToCompetitionSurveyWithTokenMutationVariables>;
export const RemoveKeyIncidentFromCompetitionSurveyWithTokenDocument = gql`
    mutation removeKeyIncidentFromCompetitionSurveyWithToken($surveyToken: String!, $keyIncidentId: String!) {
  removeKeyIncidentFromCompetitionSurveyWithToken(
    input: {surveyToken: $surveyToken, keyIncidentId: $keyIncidentId}
  )
}
    `;
export type RemoveKeyIncidentFromCompetitionSurveyWithTokenMutationFn = Apollo.MutationFunction<RemoveKeyIncidentFromCompetitionSurveyWithTokenMutation, RemoveKeyIncidentFromCompetitionSurveyWithTokenMutationVariables>;

/**
 * __useRemoveKeyIncidentFromCompetitionSurveyWithTokenMutation__
 *
 * To run a mutation, you first call `useRemoveKeyIncidentFromCompetitionSurveyWithTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveKeyIncidentFromCompetitionSurveyWithTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeKeyIncidentFromCompetitionSurveyWithTokenMutation, { data, loading, error }] = useRemoveKeyIncidentFromCompetitionSurveyWithTokenMutation({
 *   variables: {
 *      surveyToken: // value for 'surveyToken'
 *      keyIncidentId: // value for 'keyIncidentId'
 *   },
 * });
 */
export function useRemoveKeyIncidentFromCompetitionSurveyWithTokenMutation(baseOptions?: Apollo.MutationHookOptions<RemoveKeyIncidentFromCompetitionSurveyWithTokenMutation, RemoveKeyIncidentFromCompetitionSurveyWithTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveKeyIncidentFromCompetitionSurveyWithTokenMutation, RemoveKeyIncidentFromCompetitionSurveyWithTokenMutationVariables>(RemoveKeyIncidentFromCompetitionSurveyWithTokenDocument, options);
      }
export type RemoveKeyIncidentFromCompetitionSurveyWithTokenMutationHookResult = ReturnType<typeof useRemoveKeyIncidentFromCompetitionSurveyWithTokenMutation>;
export type RemoveKeyIncidentFromCompetitionSurveyWithTokenMutationResult = Apollo.MutationResult<RemoveKeyIncidentFromCompetitionSurveyWithTokenMutation>;
export type RemoveKeyIncidentFromCompetitionSurveyWithTokenMutationOptions = Apollo.BaseMutationOptions<RemoveKeyIncidentFromCompetitionSurveyWithTokenMutation, RemoveKeyIncidentFromCompetitionSurveyWithTokenMutationVariables>;
export const EditKeyIncidentToCompetitionSurveyWithTokenDocument = gql`
    mutation editKeyIncidentToCompetitionSurveyWithToken($surveyToken: String!, $keyIncidentId: String!, $description: String!, $incidentType: KeyIncidentTypes!, $impactedOutcome: Boolean!, $time: String, $periodId: String) {
  editKeyIncidentToCompetitionSurveyWithToken(
    input: {surveyToken: $surveyToken, keyIncidentId: $keyIncidentId, description: $description, incidentType: $incidentType, impactedOutcome: $impactedOutcome, time: $time, periodId: $periodId}
  ) {
    id
    competitionSurveyId
    dynamicCompetitionSurveyId
    submittedBy {
      userId
      email
      firstName
      lastName
      profilePic
      teamId
      teamName
      teamLogo
      officialId
      assessmentObjectId
      assessmentObjectName
    }
    competitionInfo {
      competitionId
      externalId
      sport
      homeTeamId
      homeTeamName
      homeTeamScore
      homeTeamTieBreakerScore
      awayTeamId
      awayTeamName
      awayTeamScore
      awayTeamTieBreakerScore
      startTime
      timeZone
      location
    }
    assessmentObject {
      id
      sport
      type
      classification
      name
      createdAt
      updatedAt
    }
    official {
      id
      sport
      userId
      status
      email
      firstName
      lastName
      profilePic
      createdAt
      updatedAt
    }
    team {
      id
      name
      abbreviation
      sport
      city
      stateAbbreviation
      countryCode
      classification
      gender
      parentOrganizationName
      externalId
      ageGroup
      timeZone
      website
      logo
      createdAt
      updatedAt
    }
    organizationId
    organizationName
    leagueId
    leagueName
    seasonId
    seasonName
    competitionSurveyFeedbackId
    dynamicCompetitionSurveyFeedbackId
    dynamicCompetitionSurveySubFeedbackId
    type
    status
    description
    time
    videoStatus
    videoUrl
    videoError
    period {
      id
      sport
      name
      createdAt
      updatedAt
    }
    impactedOutcome
    resolvedDate
    reopenedDate
    createdAt
    updatedAt
  }
}
    `;
export type EditKeyIncidentToCompetitionSurveyWithTokenMutationFn = Apollo.MutationFunction<EditKeyIncidentToCompetitionSurveyWithTokenMutation, EditKeyIncidentToCompetitionSurveyWithTokenMutationVariables>;

/**
 * __useEditKeyIncidentToCompetitionSurveyWithTokenMutation__
 *
 * To run a mutation, you first call `useEditKeyIncidentToCompetitionSurveyWithTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditKeyIncidentToCompetitionSurveyWithTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editKeyIncidentToCompetitionSurveyWithTokenMutation, { data, loading, error }] = useEditKeyIncidentToCompetitionSurveyWithTokenMutation({
 *   variables: {
 *      surveyToken: // value for 'surveyToken'
 *      keyIncidentId: // value for 'keyIncidentId'
 *      description: // value for 'description'
 *      incidentType: // value for 'incidentType'
 *      impactedOutcome: // value for 'impactedOutcome'
 *      time: // value for 'time'
 *      periodId: // value for 'periodId'
 *   },
 * });
 */
export function useEditKeyIncidentToCompetitionSurveyWithTokenMutation(baseOptions?: Apollo.MutationHookOptions<EditKeyIncidentToCompetitionSurveyWithTokenMutation, EditKeyIncidentToCompetitionSurveyWithTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditKeyIncidentToCompetitionSurveyWithTokenMutation, EditKeyIncidentToCompetitionSurveyWithTokenMutationVariables>(EditKeyIncidentToCompetitionSurveyWithTokenDocument, options);
      }
export type EditKeyIncidentToCompetitionSurveyWithTokenMutationHookResult = ReturnType<typeof useEditKeyIncidentToCompetitionSurveyWithTokenMutation>;
export type EditKeyIncidentToCompetitionSurveyWithTokenMutationResult = Apollo.MutationResult<EditKeyIncidentToCompetitionSurveyWithTokenMutation>;
export type EditKeyIncidentToCompetitionSurveyWithTokenMutationOptions = Apollo.BaseMutationOptions<EditKeyIncidentToCompetitionSurveyWithTokenMutation, EditKeyIncidentToCompetitionSurveyWithTokenMutationVariables>;
export const ListExternalLinksForKeyIncidentForCompetitionSurveyWithTokenDocument = gql`
    query listExternalLinksForKeyIncidentForCompetitionSurveyWithToken($surveyToken: String!, $keyIncidentId: String!) {
  listExternalLinksForKeyIncidentForCompetitionSurveyWithToken(
    input: {surveyToken: $surveyToken, keyIncidentId: $keyIncidentId}
  ) {
    externalLinks {
      id
      keyIncidentId
      link
      type
      description
      createdByFirstName
      createdByLastName
      createdAt
      updatedAt
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListExternalLinksForKeyIncidentForCompetitionSurveyWithTokenQuery__
 *
 * To run a query within a React component, call `useListExternalLinksForKeyIncidentForCompetitionSurveyWithTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useListExternalLinksForKeyIncidentForCompetitionSurveyWithTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListExternalLinksForKeyIncidentForCompetitionSurveyWithTokenQuery({
 *   variables: {
 *      surveyToken: // value for 'surveyToken'
 *      keyIncidentId: // value for 'keyIncidentId'
 *   },
 * });
 */
export function useListExternalLinksForKeyIncidentForCompetitionSurveyWithTokenQuery(baseOptions: Apollo.QueryHookOptions<ListExternalLinksForKeyIncidentForCompetitionSurveyWithTokenQuery, ListExternalLinksForKeyIncidentForCompetitionSurveyWithTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListExternalLinksForKeyIncidentForCompetitionSurveyWithTokenQuery, ListExternalLinksForKeyIncidentForCompetitionSurveyWithTokenQueryVariables>(ListExternalLinksForKeyIncidentForCompetitionSurveyWithTokenDocument, options);
      }
export function useListExternalLinksForKeyIncidentForCompetitionSurveyWithTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListExternalLinksForKeyIncidentForCompetitionSurveyWithTokenQuery, ListExternalLinksForKeyIncidentForCompetitionSurveyWithTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListExternalLinksForKeyIncidentForCompetitionSurveyWithTokenQuery, ListExternalLinksForKeyIncidentForCompetitionSurveyWithTokenQueryVariables>(ListExternalLinksForKeyIncidentForCompetitionSurveyWithTokenDocument, options);
        }
export type ListExternalLinksForKeyIncidentForCompetitionSurveyWithTokenQueryHookResult = ReturnType<typeof useListExternalLinksForKeyIncidentForCompetitionSurveyWithTokenQuery>;
export type ListExternalLinksForKeyIncidentForCompetitionSurveyWithTokenLazyQueryHookResult = ReturnType<typeof useListExternalLinksForKeyIncidentForCompetitionSurveyWithTokenLazyQuery>;
export type ListExternalLinksForKeyIncidentForCompetitionSurveyWithTokenQueryResult = Apollo.QueryResult<ListExternalLinksForKeyIncidentForCompetitionSurveyWithTokenQuery, ListExternalLinksForKeyIncidentForCompetitionSurveyWithTokenQueryVariables>;
export const RemoveExternalLinkFromKeyIncidentFromCompetitionSurveyWithTokenDocument = gql`
    mutation removeExternalLinkFromKeyIncidentFromCompetitionSurveyWithToken($surveyToken: String!, $externalLinkId: String!) {
  removeExternalLinkFromKeyIncidentFromCompetitionSurveyWithToken(
    input: {surveyToken: $surveyToken, externalLinkId: $externalLinkId}
  )
}
    `;
export type RemoveExternalLinkFromKeyIncidentFromCompetitionSurveyWithTokenMutationFn = Apollo.MutationFunction<RemoveExternalLinkFromKeyIncidentFromCompetitionSurveyWithTokenMutation, RemoveExternalLinkFromKeyIncidentFromCompetitionSurveyWithTokenMutationVariables>;

/**
 * __useRemoveExternalLinkFromKeyIncidentFromCompetitionSurveyWithTokenMutation__
 *
 * To run a mutation, you first call `useRemoveExternalLinkFromKeyIncidentFromCompetitionSurveyWithTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveExternalLinkFromKeyIncidentFromCompetitionSurveyWithTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeExternalLinkFromKeyIncidentFromCompetitionSurveyWithTokenMutation, { data, loading, error }] = useRemoveExternalLinkFromKeyIncidentFromCompetitionSurveyWithTokenMutation({
 *   variables: {
 *      surveyToken: // value for 'surveyToken'
 *      externalLinkId: // value for 'externalLinkId'
 *   },
 * });
 */
export function useRemoveExternalLinkFromKeyIncidentFromCompetitionSurveyWithTokenMutation(baseOptions?: Apollo.MutationHookOptions<RemoveExternalLinkFromKeyIncidentFromCompetitionSurveyWithTokenMutation, RemoveExternalLinkFromKeyIncidentFromCompetitionSurveyWithTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveExternalLinkFromKeyIncidentFromCompetitionSurveyWithTokenMutation, RemoveExternalLinkFromKeyIncidentFromCompetitionSurveyWithTokenMutationVariables>(RemoveExternalLinkFromKeyIncidentFromCompetitionSurveyWithTokenDocument, options);
      }
export type RemoveExternalLinkFromKeyIncidentFromCompetitionSurveyWithTokenMutationHookResult = ReturnType<typeof useRemoveExternalLinkFromKeyIncidentFromCompetitionSurveyWithTokenMutation>;
export type RemoveExternalLinkFromKeyIncidentFromCompetitionSurveyWithTokenMutationResult = Apollo.MutationResult<RemoveExternalLinkFromKeyIncidentFromCompetitionSurveyWithTokenMutation>;
export type RemoveExternalLinkFromKeyIncidentFromCompetitionSurveyWithTokenMutationOptions = Apollo.BaseMutationOptions<RemoveExternalLinkFromKeyIncidentFromCompetitionSurveyWithTokenMutation, RemoveExternalLinkFromKeyIncidentFromCompetitionSurveyWithTokenMutationVariables>;
export const AddExternalLinkToKeyIncidentForCompetitionSurveyWithTokenDocument = gql`
    mutation addExternalLinkToKeyIncidentForCompetitionSurveyWithToken($surveyToken: String!, $keyIncidentId: String!, $link: String!, $type: KeyIncidentExternalLinkTypes!, $description: String) {
  addExternalLinkToKeyIncidentForCompetitionSurveyWithToken(
    input: {surveyToken: $surveyToken, keyIncidentId: $keyIncidentId, link: $link, type: $type, description: $description}
  ) {
    id
    keyIncidentId
    link
    type
    description
    createdByFirstName
    createdByLastName
    createdAt
    updatedAt
  }
}
    `;
export type AddExternalLinkToKeyIncidentForCompetitionSurveyWithTokenMutationFn = Apollo.MutationFunction<AddExternalLinkToKeyIncidentForCompetitionSurveyWithTokenMutation, AddExternalLinkToKeyIncidentForCompetitionSurveyWithTokenMutationVariables>;

/**
 * __useAddExternalLinkToKeyIncidentForCompetitionSurveyWithTokenMutation__
 *
 * To run a mutation, you first call `useAddExternalLinkToKeyIncidentForCompetitionSurveyWithTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddExternalLinkToKeyIncidentForCompetitionSurveyWithTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addExternalLinkToKeyIncidentForCompetitionSurveyWithTokenMutation, { data, loading, error }] = useAddExternalLinkToKeyIncidentForCompetitionSurveyWithTokenMutation({
 *   variables: {
 *      surveyToken: // value for 'surveyToken'
 *      keyIncidentId: // value for 'keyIncidentId'
 *      link: // value for 'link'
 *      type: // value for 'type'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useAddExternalLinkToKeyIncidentForCompetitionSurveyWithTokenMutation(baseOptions?: Apollo.MutationHookOptions<AddExternalLinkToKeyIncidentForCompetitionSurveyWithTokenMutation, AddExternalLinkToKeyIncidentForCompetitionSurveyWithTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddExternalLinkToKeyIncidentForCompetitionSurveyWithTokenMutation, AddExternalLinkToKeyIncidentForCompetitionSurveyWithTokenMutationVariables>(AddExternalLinkToKeyIncidentForCompetitionSurveyWithTokenDocument, options);
      }
export type AddExternalLinkToKeyIncidentForCompetitionSurveyWithTokenMutationHookResult = ReturnType<typeof useAddExternalLinkToKeyIncidentForCompetitionSurveyWithTokenMutation>;
export type AddExternalLinkToKeyIncidentForCompetitionSurveyWithTokenMutationResult = Apollo.MutationResult<AddExternalLinkToKeyIncidentForCompetitionSurveyWithTokenMutation>;
export type AddExternalLinkToKeyIncidentForCompetitionSurveyWithTokenMutationOptions = Apollo.BaseMutationOptions<AddExternalLinkToKeyIncidentForCompetitionSurveyWithTokenMutation, AddExternalLinkToKeyIncidentForCompetitionSurveyWithTokenMutationVariables>;
export const CompleteCompetitionSurveyWithTokenDocument = gql`
    mutation completeCompetitionSurveyWithToken($surveyToken: String!) {
  completeCompetitionSurveyWithToken(input: {surveyToken: $surveyToken})
}
    `;
export type CompleteCompetitionSurveyWithTokenMutationFn = Apollo.MutationFunction<CompleteCompetitionSurveyWithTokenMutation, CompleteCompetitionSurveyWithTokenMutationVariables>;

/**
 * __useCompleteCompetitionSurveyWithTokenMutation__
 *
 * To run a mutation, you first call `useCompleteCompetitionSurveyWithTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteCompetitionSurveyWithTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeCompetitionSurveyWithTokenMutation, { data, loading, error }] = useCompleteCompetitionSurveyWithTokenMutation({
 *   variables: {
 *      surveyToken: // value for 'surveyToken'
 *   },
 * });
 */
export function useCompleteCompetitionSurveyWithTokenMutation(baseOptions?: Apollo.MutationHookOptions<CompleteCompetitionSurveyWithTokenMutation, CompleteCompetitionSurveyWithTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteCompetitionSurveyWithTokenMutation, CompleteCompetitionSurveyWithTokenMutationVariables>(CompleteCompetitionSurveyWithTokenDocument, options);
      }
export type CompleteCompetitionSurveyWithTokenMutationHookResult = ReturnType<typeof useCompleteCompetitionSurveyWithTokenMutation>;
export type CompleteCompetitionSurveyWithTokenMutationResult = Apollo.MutationResult<CompleteCompetitionSurveyWithTokenMutation>;
export type CompleteCompetitionSurveyWithTokenMutationOptions = Apollo.BaseMutationOptions<CompleteCompetitionSurveyWithTokenMutation, CompleteCompetitionSurveyWithTokenMutationVariables>;
export const GetCompetitionSurveyByIdDocument = gql`
    query getCompetitionSurveyById($competitionSurveyId: String!) {
  getCompetitionSurveyById(input: {competitionSurveyId: $competitionSurveyId}) {
    id
    surveyId
    orgId
    seasonId
    sport
    finished
    hasCompetitionVerification
    hasIncidentReport
    allowAdditionalIncidentReports
    allowAdditionalIncidentReportsCutoffDays
    createdAt
    updatedAt
    completedDate
    recipients {
      userId
      email
      firstName
      lastName
      profilePic
      tookSurvey
    }
    competition {
      id
      externalId
      sport
      startTime
      timeZone
      location
      homeTeamScore
      homeTeamTieBreakerScore
      awayTeamScore
      awayTeamTieBreakerScore
      homeTeam {
        id
        name
        abbreviation
        sport
        city
        stateAbbreviation
        countryCode
        classification
        gender
        parentOrganizationName
        externalId
        ageGroup
        timeZone
        website
        logo
        createdAt
        updatedAt
      }
      awayTeam {
        id
        name
        abbreviation
        sport
        city
        stateAbbreviation
        countryCode
        classification
        gender
        parentOrganizationName
        externalId
        ageGroup
        timeZone
        website
        logo
        createdAt
        updatedAt
      }
      organization {
        id
        name
        sport
        status
        abbreviation
        site
        logo
        createdAt
        updatedAt
      }
      season {
        id
        leagueId
        leagueName
        leagueLogo
        name
        description
        status
        startDate
        endDate
        createdAt
        updatedAt
      }
      officials {
        id
        competitionId
        assessmentObjectId
        order
        official {
          id
          sport
          userId
          status
          email
          firstName
          lastName
          profilePic
          createdAt
          updatedAt
        }
        type
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
    info {
      name
      description
      audience
    }
    sections {
      id
      name
      description
      finished
      order
      createdAt
      updatedAt
      coach {
        id
        teamId
        teamName
        teamAbbreviation
        sport
        teamCity
        userId
        type
        role
        status
        email
        firstName
        lastName
        profilePic
        createdAt
        updatedAt
      }
      official {
        id
        sport
        userId
        status
        email
        firstName
        lastName
        externalIds {
          id
          organizationId
          externalId
        }
        profilePic
        createdAt
        updatedAt
      }
      organization {
        id
        name
        sport
        status
        abbreviation
        site
        logo
        createdAt
        updatedAt
      }
      questions {
        id
        name
        description
        order
        isRequired
        canAddToKeyIncidents
        questionType
        selectedOption
        selectedOptionOrder
        responseShortAnswer
        responseText
        responseNumber
        responses {
          id
          value
          createdAt
          updatedAt
        }
        options {
          id
          value
          order
        }
      }
      assessmentObject {
        id
        sport
        type
        classification
        name
      }
    }
  }
}
    `;

/**
 * __useGetCompetitionSurveyByIdQuery__
 *
 * To run a query within a React component, call `useGetCompetitionSurveyByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompetitionSurveyByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompetitionSurveyByIdQuery({
 *   variables: {
 *      competitionSurveyId: // value for 'competitionSurveyId'
 *   },
 * });
 */
export function useGetCompetitionSurveyByIdQuery(baseOptions: Apollo.QueryHookOptions<GetCompetitionSurveyByIdQuery, GetCompetitionSurveyByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompetitionSurveyByIdQuery, GetCompetitionSurveyByIdQueryVariables>(GetCompetitionSurveyByIdDocument, options);
      }
export function useGetCompetitionSurveyByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompetitionSurveyByIdQuery, GetCompetitionSurveyByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompetitionSurveyByIdQuery, GetCompetitionSurveyByIdQueryVariables>(GetCompetitionSurveyByIdDocument, options);
        }
export type GetCompetitionSurveyByIdQueryHookResult = ReturnType<typeof useGetCompetitionSurveyByIdQuery>;
export type GetCompetitionSurveyByIdLazyQueryHookResult = ReturnType<typeof useGetCompetitionSurveyByIdLazyQuery>;
export type GetCompetitionSurveyByIdQueryResult = Apollo.QueryResult<GetCompetitionSurveyByIdQuery, GetCompetitionSurveyByIdQueryVariables>;
export const StartCompetitionSurveyDocument = gql`
    mutation startCompetitionSurvey($competitionSurveyId: String!) {
  startCompetitionSurvey(input: {competitionSurveyId: $competitionSurveyId})
}
    `;
export type StartCompetitionSurveyMutationFn = Apollo.MutationFunction<StartCompetitionSurveyMutation, StartCompetitionSurveyMutationVariables>;

/**
 * __useStartCompetitionSurveyMutation__
 *
 * To run a mutation, you first call `useStartCompetitionSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartCompetitionSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startCompetitionSurveyMutation, { data, loading, error }] = useStartCompetitionSurveyMutation({
 *   variables: {
 *      competitionSurveyId: // value for 'competitionSurveyId'
 *   },
 * });
 */
export function useStartCompetitionSurveyMutation(baseOptions?: Apollo.MutationHookOptions<StartCompetitionSurveyMutation, StartCompetitionSurveyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartCompetitionSurveyMutation, StartCompetitionSurveyMutationVariables>(StartCompetitionSurveyDocument, options);
      }
export type StartCompetitionSurveyMutationHookResult = ReturnType<typeof useStartCompetitionSurveyMutation>;
export type StartCompetitionSurveyMutationResult = Apollo.MutationResult<StartCompetitionSurveyMutation>;
export type StartCompetitionSurveyMutationOptions = Apollo.BaseMutationOptions<StartCompetitionSurveyMutation, StartCompetitionSurveyMutationVariables>;
export const UpdateCompetitionSurveyCompetitionScoreDocument = gql`
    mutation updateCompetitionSurveyCompetitionScore($competitionSurveyId: String!, $homeTeamScore: Int!, $awayTeamScore: Int!, $homeTeamTieBreakerScore: Int, $awayTeamTieBreakerScore: Int) {
  updateCompetitionSurveyCompetitionScore(
    input: {competitionSurveyId: $competitionSurveyId, homeTeamScore: $homeTeamScore, awayTeamScore: $awayTeamScore, homeTeamTieBreakerScore: $homeTeamTieBreakerScore, awayTeamTieBreakerScore: $awayTeamTieBreakerScore}
  )
}
    `;
export type UpdateCompetitionSurveyCompetitionScoreMutationFn = Apollo.MutationFunction<UpdateCompetitionSurveyCompetitionScoreMutation, UpdateCompetitionSurveyCompetitionScoreMutationVariables>;

/**
 * __useUpdateCompetitionSurveyCompetitionScoreMutation__
 *
 * To run a mutation, you first call `useUpdateCompetitionSurveyCompetitionScoreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompetitionSurveyCompetitionScoreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompetitionSurveyCompetitionScoreMutation, { data, loading, error }] = useUpdateCompetitionSurveyCompetitionScoreMutation({
 *   variables: {
 *      competitionSurveyId: // value for 'competitionSurveyId'
 *      homeTeamScore: // value for 'homeTeamScore'
 *      awayTeamScore: // value for 'awayTeamScore'
 *      homeTeamTieBreakerScore: // value for 'homeTeamTieBreakerScore'
 *      awayTeamTieBreakerScore: // value for 'awayTeamTieBreakerScore'
 *   },
 * });
 */
export function useUpdateCompetitionSurveyCompetitionScoreMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompetitionSurveyCompetitionScoreMutation, UpdateCompetitionSurveyCompetitionScoreMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompetitionSurveyCompetitionScoreMutation, UpdateCompetitionSurveyCompetitionScoreMutationVariables>(UpdateCompetitionSurveyCompetitionScoreDocument, options);
      }
export type UpdateCompetitionSurveyCompetitionScoreMutationHookResult = ReturnType<typeof useUpdateCompetitionSurveyCompetitionScoreMutation>;
export type UpdateCompetitionSurveyCompetitionScoreMutationResult = Apollo.MutationResult<UpdateCompetitionSurveyCompetitionScoreMutation>;
export type UpdateCompetitionSurveyCompetitionScoreMutationOptions = Apollo.BaseMutationOptions<UpdateCompetitionSurveyCompetitionScoreMutation, UpdateCompetitionSurveyCompetitionScoreMutationVariables>;
export const UpdateCompetitionSurveyMultipleChoiceResponseDocument = gql`
    mutation updateCompetitionSurveyMultipleChoiceResponse($competitionSurveyId: String!, $questionId: String!, $selectedOptionId: String!) {
  updateCompetitionSurveyMultipleChoiceResponse(
    input: {competitionSurveyId: $competitionSurveyId, questionId: $questionId, selectedOptionId: $selectedOptionId}
  ) {
    id
    name
    description
    order
    isRequired
    canAddToKeyIncidents
    questionType
    selectedOption
    selectedOptionOrder
    responseShortAnswer
    responseText
    responseNumber
    responses {
      id
      value
      createdAt
      updatedAt
    }
    options {
      id
      value
      order
    }
  }
}
    `;
export type UpdateCompetitionSurveyMultipleChoiceResponseMutationFn = Apollo.MutationFunction<UpdateCompetitionSurveyMultipleChoiceResponseMutation, UpdateCompetitionSurveyMultipleChoiceResponseMutationVariables>;

/**
 * __useUpdateCompetitionSurveyMultipleChoiceResponseMutation__
 *
 * To run a mutation, you first call `useUpdateCompetitionSurveyMultipleChoiceResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompetitionSurveyMultipleChoiceResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompetitionSurveyMultipleChoiceResponseMutation, { data, loading, error }] = useUpdateCompetitionSurveyMultipleChoiceResponseMutation({
 *   variables: {
 *      competitionSurveyId: // value for 'competitionSurveyId'
 *      questionId: // value for 'questionId'
 *      selectedOptionId: // value for 'selectedOptionId'
 *   },
 * });
 */
export function useUpdateCompetitionSurveyMultipleChoiceResponseMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompetitionSurveyMultipleChoiceResponseMutation, UpdateCompetitionSurveyMultipleChoiceResponseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompetitionSurveyMultipleChoiceResponseMutation, UpdateCompetitionSurveyMultipleChoiceResponseMutationVariables>(UpdateCompetitionSurveyMultipleChoiceResponseDocument, options);
      }
export type UpdateCompetitionSurveyMultipleChoiceResponseMutationHookResult = ReturnType<typeof useUpdateCompetitionSurveyMultipleChoiceResponseMutation>;
export type UpdateCompetitionSurveyMultipleChoiceResponseMutationResult = Apollo.MutationResult<UpdateCompetitionSurveyMultipleChoiceResponseMutation>;
export type UpdateCompetitionSurveyMultipleChoiceResponseMutationOptions = Apollo.BaseMutationOptions<UpdateCompetitionSurveyMultipleChoiceResponseMutation, UpdateCompetitionSurveyMultipleChoiceResponseMutationVariables>;
export const UpdateCompetitionSurveyShortAnswerResponseDocument = gql`
    mutation updateCompetitionSurveyShortAnswerResponse($competitionSurveyId: String!, $questionId: String!, $response: String!) {
  updateCompetitionSurveyShortAnswerResponse(
    input: {competitionSurveyId: $competitionSurveyId, questionId: $questionId, response: $response}
  ) {
    id
    name
    description
    order
    isRequired
    canAddToKeyIncidents
    questionType
    selectedOption
    selectedOptionOrder
    responseShortAnswer
    responseText
    responseNumber
    responses {
      id
      value
      createdAt
      updatedAt
    }
    options {
      id
      value
      order
    }
  }
}
    `;
export type UpdateCompetitionSurveyShortAnswerResponseMutationFn = Apollo.MutationFunction<UpdateCompetitionSurveyShortAnswerResponseMutation, UpdateCompetitionSurveyShortAnswerResponseMutationVariables>;

/**
 * __useUpdateCompetitionSurveyShortAnswerResponseMutation__
 *
 * To run a mutation, you first call `useUpdateCompetitionSurveyShortAnswerResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompetitionSurveyShortAnswerResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompetitionSurveyShortAnswerResponseMutation, { data, loading, error }] = useUpdateCompetitionSurveyShortAnswerResponseMutation({
 *   variables: {
 *      competitionSurveyId: // value for 'competitionSurveyId'
 *      questionId: // value for 'questionId'
 *      response: // value for 'response'
 *   },
 * });
 */
export function useUpdateCompetitionSurveyShortAnswerResponseMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompetitionSurveyShortAnswerResponseMutation, UpdateCompetitionSurveyShortAnswerResponseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompetitionSurveyShortAnswerResponseMutation, UpdateCompetitionSurveyShortAnswerResponseMutationVariables>(UpdateCompetitionSurveyShortAnswerResponseDocument, options);
      }
export type UpdateCompetitionSurveyShortAnswerResponseMutationHookResult = ReturnType<typeof useUpdateCompetitionSurveyShortAnswerResponseMutation>;
export type UpdateCompetitionSurveyShortAnswerResponseMutationResult = Apollo.MutationResult<UpdateCompetitionSurveyShortAnswerResponseMutation>;
export type UpdateCompetitionSurveyShortAnswerResponseMutationOptions = Apollo.BaseMutationOptions<UpdateCompetitionSurveyShortAnswerResponseMutation, UpdateCompetitionSurveyShortAnswerResponseMutationVariables>;
export const UpdateCompetitionSurveyTextResponseDocument = gql`
    mutation updateCompetitionSurveyTextResponse($competitionSurveyId: String!, $questionId: String!, $response: String!) {
  updateCompetitionSurveyTextResponse(
    input: {competitionSurveyId: $competitionSurveyId, questionId: $questionId, response: $response}
  ) {
    id
    name
    description
    order
    isRequired
    canAddToKeyIncidents
    questionType
    selectedOption
    selectedOptionOrder
    responseShortAnswer
    responseText
    responseNumber
    responses {
      id
      value
      createdAt
      updatedAt
    }
    options {
      id
      value
      order
    }
  }
}
    `;
export type UpdateCompetitionSurveyTextResponseMutationFn = Apollo.MutationFunction<UpdateCompetitionSurveyTextResponseMutation, UpdateCompetitionSurveyTextResponseMutationVariables>;

/**
 * __useUpdateCompetitionSurveyTextResponseMutation__
 *
 * To run a mutation, you first call `useUpdateCompetitionSurveyTextResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompetitionSurveyTextResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompetitionSurveyTextResponseMutation, { data, loading, error }] = useUpdateCompetitionSurveyTextResponseMutation({
 *   variables: {
 *      competitionSurveyId: // value for 'competitionSurveyId'
 *      questionId: // value for 'questionId'
 *      response: // value for 'response'
 *   },
 * });
 */
export function useUpdateCompetitionSurveyTextResponseMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompetitionSurveyTextResponseMutation, UpdateCompetitionSurveyTextResponseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompetitionSurveyTextResponseMutation, UpdateCompetitionSurveyTextResponseMutationVariables>(UpdateCompetitionSurveyTextResponseDocument, options);
      }
export type UpdateCompetitionSurveyTextResponseMutationHookResult = ReturnType<typeof useUpdateCompetitionSurveyTextResponseMutation>;
export type UpdateCompetitionSurveyTextResponseMutationResult = Apollo.MutationResult<UpdateCompetitionSurveyTextResponseMutation>;
export type UpdateCompetitionSurveyTextResponseMutationOptions = Apollo.BaseMutationOptions<UpdateCompetitionSurveyTextResponseMutation, UpdateCompetitionSurveyTextResponseMutationVariables>;
export const UpdateCompetitionSurveyNumberResponseDocument = gql`
    mutation updateCompetitionSurveyNumberResponse($competitionSurveyId: String!, $questionId: String!, $response: Float!) {
  updateCompetitionSurveyNumberResponse(
    input: {competitionSurveyId: $competitionSurveyId, questionId: $questionId, response: $response}
  ) {
    id
    name
    description
    order
    isRequired
    canAddToKeyIncidents
    questionType
    selectedOption
    selectedOptionOrder
    responseShortAnswer
    responseText
    responseNumber
    responses {
      id
      value
      createdAt
      updatedAt
    }
    options {
      id
      value
      order
    }
  }
}
    `;
export type UpdateCompetitionSurveyNumberResponseMutationFn = Apollo.MutationFunction<UpdateCompetitionSurveyNumberResponseMutation, UpdateCompetitionSurveyNumberResponseMutationVariables>;

/**
 * __useUpdateCompetitionSurveyNumberResponseMutation__
 *
 * To run a mutation, you first call `useUpdateCompetitionSurveyNumberResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompetitionSurveyNumberResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompetitionSurveyNumberResponseMutation, { data, loading, error }] = useUpdateCompetitionSurveyNumberResponseMutation({
 *   variables: {
 *      competitionSurveyId: // value for 'competitionSurveyId'
 *      questionId: // value for 'questionId'
 *      response: // value for 'response'
 *   },
 * });
 */
export function useUpdateCompetitionSurveyNumberResponseMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompetitionSurveyNumberResponseMutation, UpdateCompetitionSurveyNumberResponseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompetitionSurveyNumberResponseMutation, UpdateCompetitionSurveyNumberResponseMutationVariables>(UpdateCompetitionSurveyNumberResponseDocument, options);
      }
export type UpdateCompetitionSurveyNumberResponseMutationHookResult = ReturnType<typeof useUpdateCompetitionSurveyNumberResponseMutation>;
export type UpdateCompetitionSurveyNumberResponseMutationResult = Apollo.MutationResult<UpdateCompetitionSurveyNumberResponseMutation>;
export type UpdateCompetitionSurveyNumberResponseMutationOptions = Apollo.BaseMutationOptions<UpdateCompetitionSurveyNumberResponseMutation, UpdateCompetitionSurveyNumberResponseMutationVariables>;
export const AddCompetitionSurveyMultipleResponseWithTokenDocument = gql`
    mutation addCompetitionSurveyMultipleResponseWithToken($surveyToken: String!, $questionId: String!, $response: String!) {
  addCompetitionSurveyMultipleResponseWithToken(
    input: {surveyToken: $surveyToken, questionId: $questionId, response: $response}
  ) {
    id
    value
    createdAt
    updatedAt
  }
}
    `;
export type AddCompetitionSurveyMultipleResponseWithTokenMutationFn = Apollo.MutationFunction<AddCompetitionSurveyMultipleResponseWithTokenMutation, AddCompetitionSurveyMultipleResponseWithTokenMutationVariables>;

/**
 * __useAddCompetitionSurveyMultipleResponseWithTokenMutation__
 *
 * To run a mutation, you first call `useAddCompetitionSurveyMultipleResponseWithTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCompetitionSurveyMultipleResponseWithTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCompetitionSurveyMultipleResponseWithTokenMutation, { data, loading, error }] = useAddCompetitionSurveyMultipleResponseWithTokenMutation({
 *   variables: {
 *      surveyToken: // value for 'surveyToken'
 *      questionId: // value for 'questionId'
 *      response: // value for 'response'
 *   },
 * });
 */
export function useAddCompetitionSurveyMultipleResponseWithTokenMutation(baseOptions?: Apollo.MutationHookOptions<AddCompetitionSurveyMultipleResponseWithTokenMutation, AddCompetitionSurveyMultipleResponseWithTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCompetitionSurveyMultipleResponseWithTokenMutation, AddCompetitionSurveyMultipleResponseWithTokenMutationVariables>(AddCompetitionSurveyMultipleResponseWithTokenDocument, options);
      }
export type AddCompetitionSurveyMultipleResponseWithTokenMutationHookResult = ReturnType<typeof useAddCompetitionSurveyMultipleResponseWithTokenMutation>;
export type AddCompetitionSurveyMultipleResponseWithTokenMutationResult = Apollo.MutationResult<AddCompetitionSurveyMultipleResponseWithTokenMutation>;
export type AddCompetitionSurveyMultipleResponseWithTokenMutationOptions = Apollo.BaseMutationOptions<AddCompetitionSurveyMultipleResponseWithTokenMutation, AddCompetitionSurveyMultipleResponseWithTokenMutationVariables>;
export const RemoveCompetitionSurveyMultipleResponseWithTokenDocument = gql`
    mutation removeCompetitionSurveyMultipleResponseWithToken($surveyToken: String!, $questionId: String!, $responseId: String!) {
  removeCompetitionSurveyMultipleResponseWithToken(
    input: {surveyToken: $surveyToken, questionId: $questionId, responseId: $responseId}
  ) {
    id
    name
    description
    order
    isRequired
    canAddToKeyIncidents
    questionType
    selectedOption
    selectedOptionOrder
    responseShortAnswer
    responseText
    responseNumber
    responses {
      id
      value
      createdAt
      updatedAt
    }
    options {
      id
      value
      order
    }
  }
}
    `;
export type RemoveCompetitionSurveyMultipleResponseWithTokenMutationFn = Apollo.MutationFunction<RemoveCompetitionSurveyMultipleResponseWithTokenMutation, RemoveCompetitionSurveyMultipleResponseWithTokenMutationVariables>;

/**
 * __useRemoveCompetitionSurveyMultipleResponseWithTokenMutation__
 *
 * To run a mutation, you first call `useRemoveCompetitionSurveyMultipleResponseWithTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCompetitionSurveyMultipleResponseWithTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCompetitionSurveyMultipleResponseWithTokenMutation, { data, loading, error }] = useRemoveCompetitionSurveyMultipleResponseWithTokenMutation({
 *   variables: {
 *      surveyToken: // value for 'surveyToken'
 *      questionId: // value for 'questionId'
 *      responseId: // value for 'responseId'
 *   },
 * });
 */
export function useRemoveCompetitionSurveyMultipleResponseWithTokenMutation(baseOptions?: Apollo.MutationHookOptions<RemoveCompetitionSurveyMultipleResponseWithTokenMutation, RemoveCompetitionSurveyMultipleResponseWithTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveCompetitionSurveyMultipleResponseWithTokenMutation, RemoveCompetitionSurveyMultipleResponseWithTokenMutationVariables>(RemoveCompetitionSurveyMultipleResponseWithTokenDocument, options);
      }
export type RemoveCompetitionSurveyMultipleResponseWithTokenMutationHookResult = ReturnType<typeof useRemoveCompetitionSurveyMultipleResponseWithTokenMutation>;
export type RemoveCompetitionSurveyMultipleResponseWithTokenMutationResult = Apollo.MutationResult<RemoveCompetitionSurveyMultipleResponseWithTokenMutation>;
export type RemoveCompetitionSurveyMultipleResponseWithTokenMutationOptions = Apollo.BaseMutationOptions<RemoveCompetitionSurveyMultipleResponseWithTokenMutation, RemoveCompetitionSurveyMultipleResponseWithTokenMutationVariables>;
export const AddCompetitionSurveyMultipleResponseDocument = gql`
    mutation addCompetitionSurveyMultipleResponse($competitionSurveyId: String!, $questionId: String!, $response: String!) {
  addCompetitionSurveyMultipleResponse(
    input: {competitionSurveyId: $competitionSurveyId, questionId: $questionId, response: $response}
  ) {
    id
    value
    createdAt
    updatedAt
  }
}
    `;
export type AddCompetitionSurveyMultipleResponseMutationFn = Apollo.MutationFunction<AddCompetitionSurveyMultipleResponseMutation, AddCompetitionSurveyMultipleResponseMutationVariables>;

/**
 * __useAddCompetitionSurveyMultipleResponseMutation__
 *
 * To run a mutation, you first call `useAddCompetitionSurveyMultipleResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCompetitionSurveyMultipleResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCompetitionSurveyMultipleResponseMutation, { data, loading, error }] = useAddCompetitionSurveyMultipleResponseMutation({
 *   variables: {
 *      competitionSurveyId: // value for 'competitionSurveyId'
 *      questionId: // value for 'questionId'
 *      response: // value for 'response'
 *   },
 * });
 */
export function useAddCompetitionSurveyMultipleResponseMutation(baseOptions?: Apollo.MutationHookOptions<AddCompetitionSurveyMultipleResponseMutation, AddCompetitionSurveyMultipleResponseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCompetitionSurveyMultipleResponseMutation, AddCompetitionSurveyMultipleResponseMutationVariables>(AddCompetitionSurveyMultipleResponseDocument, options);
      }
export type AddCompetitionSurveyMultipleResponseMutationHookResult = ReturnType<typeof useAddCompetitionSurveyMultipleResponseMutation>;
export type AddCompetitionSurveyMultipleResponseMutationResult = Apollo.MutationResult<AddCompetitionSurveyMultipleResponseMutation>;
export type AddCompetitionSurveyMultipleResponseMutationOptions = Apollo.BaseMutationOptions<AddCompetitionSurveyMultipleResponseMutation, AddCompetitionSurveyMultipleResponseMutationVariables>;
export const RemoveCompetitionSurveyMultipleResponseDocument = gql`
    mutation removeCompetitionSurveyMultipleResponse($competitionSurveyId: String!, $questionId: String!, $responseId: String!) {
  removeCompetitionSurveyMultipleResponse(
    input: {competitionSurveyId: $competitionSurveyId, questionId: $questionId, responseId: $responseId}
  ) {
    id
    name
    description
    order
    isRequired
    canAddToKeyIncidents
    questionType
    selectedOption
    selectedOptionOrder
    responseShortAnswer
    responseText
    responseNumber
    responses {
      id
      value
      createdAt
      updatedAt
    }
    options {
      id
      value
      order
    }
  }
}
    `;
export type RemoveCompetitionSurveyMultipleResponseMutationFn = Apollo.MutationFunction<RemoveCompetitionSurveyMultipleResponseMutation, RemoveCompetitionSurveyMultipleResponseMutationVariables>;

/**
 * __useRemoveCompetitionSurveyMultipleResponseMutation__
 *
 * To run a mutation, you first call `useRemoveCompetitionSurveyMultipleResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCompetitionSurveyMultipleResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCompetitionSurveyMultipleResponseMutation, { data, loading, error }] = useRemoveCompetitionSurveyMultipleResponseMutation({
 *   variables: {
 *      competitionSurveyId: // value for 'competitionSurveyId'
 *      questionId: // value for 'questionId'
 *      responseId: // value for 'responseId'
 *   },
 * });
 */
export function useRemoveCompetitionSurveyMultipleResponseMutation(baseOptions?: Apollo.MutationHookOptions<RemoveCompetitionSurveyMultipleResponseMutation, RemoveCompetitionSurveyMultipleResponseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveCompetitionSurveyMultipleResponseMutation, RemoveCompetitionSurveyMultipleResponseMutationVariables>(RemoveCompetitionSurveyMultipleResponseDocument, options);
      }
export type RemoveCompetitionSurveyMultipleResponseMutationHookResult = ReturnType<typeof useRemoveCompetitionSurveyMultipleResponseMutation>;
export type RemoveCompetitionSurveyMultipleResponseMutationResult = Apollo.MutationResult<RemoveCompetitionSurveyMultipleResponseMutation>;
export type RemoveCompetitionSurveyMultipleResponseMutationOptions = Apollo.BaseMutationOptions<RemoveCompetitionSurveyMultipleResponseMutation, RemoveCompetitionSurveyMultipleResponseMutationVariables>;
export const ListKeyIncidentsForCompetitionSurveyDocument = gql`
    query listKeyIncidentsForCompetitionSurvey($competitionSurveyId: String!) {
  listKeyIncidentsForCompetitionSurvey(
    input: {competitionSurveyId: $competitionSurveyId}
  ) {
    keyIncidents {
      id
      competitionSurveyId
      submittedBy {
        userId
        email
        firstName
        lastName
        profilePic
        teamId
        teamName
        teamLogo
        officialId
        assessmentObjectId
        assessmentObjectName
      }
      competitionInfo {
        competitionId
        externalId
        sport
        homeTeamId
        homeTeamName
        homeTeamScore
        homeTeamTieBreakerScore
        awayTeamId
        awayTeamName
        awayTeamScore
        awayTeamTieBreakerScore
        startTime
        timeZone
        location
      }
      assessmentObject {
        id
        sport
        type
        classification
        name
        createdAt
        updatedAt
      }
      official {
        id
        sport
        userId
        status
        email
        firstName
        lastName
        profilePic
        createdAt
        updatedAt
      }
      team {
        id
        name
        abbreviation
        sport
        city
        stateAbbreviation
        countryCode
        classification
        gender
        parentOrganizationName
        externalId
        ageGroup
        timeZone
        website
        logo
        createdAt
        updatedAt
      }
      organizationId
      organizationName
      leagueId
      leagueName
      seasonId
      seasonName
      competitionSurveyFeedbackId
      dynamicCompetitionSurveyFeedbackId
      dynamicCompetitionSurveySubFeedbackId
      type
      status
      description
      time
      videoStatus
      videoUrl
      videoError
      period {
        id
        sport
        name
        createdAt
        updatedAt
      }
      impactedOutcome
      resolvedDate
      reopenedDate
      createdAt
      updatedAt
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListKeyIncidentsForCompetitionSurveyQuery__
 *
 * To run a query within a React component, call `useListKeyIncidentsForCompetitionSurveyQuery` and pass it any options that fit your needs.
 * When your component renders, `useListKeyIncidentsForCompetitionSurveyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListKeyIncidentsForCompetitionSurveyQuery({
 *   variables: {
 *      competitionSurveyId: // value for 'competitionSurveyId'
 *   },
 * });
 */
export function useListKeyIncidentsForCompetitionSurveyQuery(baseOptions: Apollo.QueryHookOptions<ListKeyIncidentsForCompetitionSurveyQuery, ListKeyIncidentsForCompetitionSurveyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListKeyIncidentsForCompetitionSurveyQuery, ListKeyIncidentsForCompetitionSurveyQueryVariables>(ListKeyIncidentsForCompetitionSurveyDocument, options);
      }
export function useListKeyIncidentsForCompetitionSurveyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListKeyIncidentsForCompetitionSurveyQuery, ListKeyIncidentsForCompetitionSurveyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListKeyIncidentsForCompetitionSurveyQuery, ListKeyIncidentsForCompetitionSurveyQueryVariables>(ListKeyIncidentsForCompetitionSurveyDocument, options);
        }
export type ListKeyIncidentsForCompetitionSurveyQueryHookResult = ReturnType<typeof useListKeyIncidentsForCompetitionSurveyQuery>;
export type ListKeyIncidentsForCompetitionSurveyLazyQueryHookResult = ReturnType<typeof useListKeyIncidentsForCompetitionSurveyLazyQuery>;
export type ListKeyIncidentsForCompetitionSurveyQueryResult = Apollo.QueryResult<ListKeyIncidentsForCompetitionSurveyQuery, ListKeyIncidentsForCompetitionSurveyQueryVariables>;
export const ConvertCompetitionSurveySectionFeedbackToKeyIncidentDocument = gql`
    mutation convertCompetitionSurveySectionFeedbackToKeyIncident($competitionSurveyId: String!, $questionId: String!, $feedbackResponseId: String!, $incidentType: KeyIncidentTypes!, $impactedOutcome: Boolean!, $time: String, $description: String, $periodId: String, $externalLinks: [CreateKeyIncidentExternalLinkForCompetitionSurveyInput!]!) {
  convertCompetitionSurveySectionFeedbackToKeyIncident(
    input: {competitionSurveyId: $competitionSurveyId, questionId: $questionId, feedbackResponseId: $feedbackResponseId, incidentType: $incidentType, impactedOutcome: $impactedOutcome, time: $time, description: $description, periodId: $periodId, externalLinks: $externalLinks}
  ) {
    id
    competitionSurveyId
    submittedBy {
      userId
      email
      firstName
      lastName
      profilePic
      teamId
      teamName
      teamLogo
      officialId
      assessmentObjectId
      assessmentObjectName
    }
    competitionInfo {
      competitionId
      externalId
      sport
      homeTeamId
      homeTeamName
      homeTeamScore
      homeTeamTieBreakerScore
      awayTeamId
      awayTeamName
      awayTeamScore
      awayTeamTieBreakerScore
      startTime
      timeZone
      location
    }
    assessmentObject {
      id
      sport
      type
      classification
      name
      createdAt
      updatedAt
    }
    official {
      id
      sport
      userId
      status
      email
      firstName
      lastName
      profilePic
      createdAt
      updatedAt
    }
    team {
      id
      name
      abbreviation
      sport
      city
      stateAbbreviation
      countryCode
      classification
      gender
      parentOrganizationName
      externalId
      ageGroup
      timeZone
      website
      logo
      createdAt
      updatedAt
    }
    organizationId
    organizationName
    leagueId
    leagueName
    seasonId
    seasonName
    competitionSurveyFeedbackId
    dynamicCompetitionSurveyFeedbackId
    dynamicCompetitionSurveySubFeedbackId
    type
    status
    description
    time
    videoStatus
    videoUrl
    videoError
    period {
      id
      sport
      name
      createdAt
      updatedAt
    }
    impactedOutcome
    resolvedDate
    reopenedDate
    createdAt
    updatedAt
  }
}
    `;
export type ConvertCompetitionSurveySectionFeedbackToKeyIncidentMutationFn = Apollo.MutationFunction<ConvertCompetitionSurveySectionFeedbackToKeyIncidentMutation, ConvertCompetitionSurveySectionFeedbackToKeyIncidentMutationVariables>;

/**
 * __useConvertCompetitionSurveySectionFeedbackToKeyIncidentMutation__
 *
 * To run a mutation, you first call `useConvertCompetitionSurveySectionFeedbackToKeyIncidentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConvertCompetitionSurveySectionFeedbackToKeyIncidentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [convertCompetitionSurveySectionFeedbackToKeyIncidentMutation, { data, loading, error }] = useConvertCompetitionSurveySectionFeedbackToKeyIncidentMutation({
 *   variables: {
 *      competitionSurveyId: // value for 'competitionSurveyId'
 *      questionId: // value for 'questionId'
 *      feedbackResponseId: // value for 'feedbackResponseId'
 *      incidentType: // value for 'incidentType'
 *      impactedOutcome: // value for 'impactedOutcome'
 *      time: // value for 'time'
 *      description: // value for 'description'
 *      periodId: // value for 'periodId'
 *      externalLinks: // value for 'externalLinks'
 *   },
 * });
 */
export function useConvertCompetitionSurveySectionFeedbackToKeyIncidentMutation(baseOptions?: Apollo.MutationHookOptions<ConvertCompetitionSurveySectionFeedbackToKeyIncidentMutation, ConvertCompetitionSurveySectionFeedbackToKeyIncidentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConvertCompetitionSurveySectionFeedbackToKeyIncidentMutation, ConvertCompetitionSurveySectionFeedbackToKeyIncidentMutationVariables>(ConvertCompetitionSurveySectionFeedbackToKeyIncidentDocument, options);
      }
export type ConvertCompetitionSurveySectionFeedbackToKeyIncidentMutationHookResult = ReturnType<typeof useConvertCompetitionSurveySectionFeedbackToKeyIncidentMutation>;
export type ConvertCompetitionSurveySectionFeedbackToKeyIncidentMutationResult = Apollo.MutationResult<ConvertCompetitionSurveySectionFeedbackToKeyIncidentMutation>;
export type ConvertCompetitionSurveySectionFeedbackToKeyIncidentMutationOptions = Apollo.BaseMutationOptions<ConvertCompetitionSurveySectionFeedbackToKeyIncidentMutation, ConvertCompetitionSurveySectionFeedbackToKeyIncidentMutationVariables>;
export const AddKeyIncidentToCompetitionSurveyDocument = gql`
    mutation addKeyIncidentToCompetitionSurvey($competitionSurveyId: String!, $description: String!, $incidentType: KeyIncidentTypes!, $impactedOutcome: Boolean!, $time: String, $sectionId: String, $periodId: String, $externalLinks: [CreateKeyIncidentExternalLinkForCompetitionSurveyInput!]!) {
  addKeyIncidentToCompetitionSurvey(
    input: {competitionSurveyId: $competitionSurveyId, description: $description, incidentType: $incidentType, impactedOutcome: $impactedOutcome, time: $time, sectionId: $sectionId, periodId: $periodId, externalLinks: $externalLinks}
  ) {
    id
    competitionSurveyId
    submittedBy {
      userId
      email
      firstName
      lastName
      profilePic
      teamId
      teamName
      teamLogo
      officialId
      assessmentObjectId
      assessmentObjectName
    }
    competitionInfo {
      competitionId
      externalId
      sport
      homeTeamId
      homeTeamName
      homeTeamScore
      homeTeamTieBreakerScore
      awayTeamId
      awayTeamName
      awayTeamScore
      awayTeamTieBreakerScore
      startTime
      timeZone
      location
    }
    assessmentObject {
      id
      sport
      type
      classification
      name
      createdAt
      updatedAt
    }
    official {
      id
      sport
      userId
      status
      email
      firstName
      lastName
      profilePic
      createdAt
      updatedAt
    }
    team {
      id
      name
      abbreviation
      sport
      city
      stateAbbreviation
      countryCode
      classification
      gender
      parentOrganizationName
      externalId
      ageGroup
      timeZone
      website
      logo
      createdAt
      updatedAt
    }
    organizationId
    organizationName
    leagueId
    leagueName
    seasonId
    seasonName
    competitionSurveyFeedbackId
    dynamicCompetitionSurveyFeedbackId
    dynamicCompetitionSurveySubFeedbackId
    type
    status
    description
    time
    videoStatus
    videoUrl
    videoError
    period {
      id
      sport
      name
      createdAt
      updatedAt
    }
    impactedOutcome
    resolvedDate
    reopenedDate
    createdAt
    updatedAt
  }
}
    `;
export type AddKeyIncidentToCompetitionSurveyMutationFn = Apollo.MutationFunction<AddKeyIncidentToCompetitionSurveyMutation, AddKeyIncidentToCompetitionSurveyMutationVariables>;

/**
 * __useAddKeyIncidentToCompetitionSurveyMutation__
 *
 * To run a mutation, you first call `useAddKeyIncidentToCompetitionSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddKeyIncidentToCompetitionSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addKeyIncidentToCompetitionSurveyMutation, { data, loading, error }] = useAddKeyIncidentToCompetitionSurveyMutation({
 *   variables: {
 *      competitionSurveyId: // value for 'competitionSurveyId'
 *      description: // value for 'description'
 *      incidentType: // value for 'incidentType'
 *      impactedOutcome: // value for 'impactedOutcome'
 *      time: // value for 'time'
 *      sectionId: // value for 'sectionId'
 *      periodId: // value for 'periodId'
 *      externalLinks: // value for 'externalLinks'
 *   },
 * });
 */
export function useAddKeyIncidentToCompetitionSurveyMutation(baseOptions?: Apollo.MutationHookOptions<AddKeyIncidentToCompetitionSurveyMutation, AddKeyIncidentToCompetitionSurveyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddKeyIncidentToCompetitionSurveyMutation, AddKeyIncidentToCompetitionSurveyMutationVariables>(AddKeyIncidentToCompetitionSurveyDocument, options);
      }
export type AddKeyIncidentToCompetitionSurveyMutationHookResult = ReturnType<typeof useAddKeyIncidentToCompetitionSurveyMutation>;
export type AddKeyIncidentToCompetitionSurveyMutationResult = Apollo.MutationResult<AddKeyIncidentToCompetitionSurveyMutation>;
export type AddKeyIncidentToCompetitionSurveyMutationOptions = Apollo.BaseMutationOptions<AddKeyIncidentToCompetitionSurveyMutation, AddKeyIncidentToCompetitionSurveyMutationVariables>;
export const EditKeyIncidentToCompetitionSurveyDocument = gql`
    mutation editKeyIncidentToCompetitionSurvey($competitionSurveyId: String!, $keyIncidentId: String!, $description: String!, $incidentType: KeyIncidentTypes!, $impactedOutcome: Boolean!, $time: String, $periodId: String) {
  editKeyIncidentToCompetitionSurvey(
    input: {competitionSurveyId: $competitionSurveyId, keyIncidentId: $keyIncidentId, description: $description, incidentType: $incidentType, impactedOutcome: $impactedOutcome, time: $time, periodId: $periodId}
  ) {
    id
    competitionSurveyId
    submittedBy {
      userId
      email
      firstName
      lastName
      profilePic
      teamId
      teamName
      teamLogo
      officialId
      assessmentObjectId
      assessmentObjectName
    }
    competitionInfo {
      competitionId
      externalId
      sport
      homeTeamId
      homeTeamName
      homeTeamScore
      homeTeamTieBreakerScore
      awayTeamId
      awayTeamName
      awayTeamScore
      awayTeamTieBreakerScore
      startTime
      timeZone
      location
    }
    assessmentObject {
      id
      sport
      type
      classification
      name
      createdAt
      updatedAt
    }
    official {
      id
      sport
      userId
      status
      email
      firstName
      lastName
      profilePic
      createdAt
      updatedAt
    }
    team {
      id
      name
      abbreviation
      sport
      city
      stateAbbreviation
      countryCode
      classification
      gender
      parentOrganizationName
      externalId
      ageGroup
      timeZone
      website
      logo
      createdAt
      updatedAt
    }
    organizationId
    organizationName
    leagueId
    leagueName
    seasonId
    seasonName
    competitionSurveyFeedbackId
    dynamicCompetitionSurveyFeedbackId
    dynamicCompetitionSurveySubFeedbackId
    type
    status
    description
    time
    videoStatus
    videoUrl
    videoError
    period {
      id
      sport
      name
      createdAt
      updatedAt
    }
    impactedOutcome
    resolvedDate
    reopenedDate
    createdAt
    updatedAt
  }
}
    `;
export type EditKeyIncidentToCompetitionSurveyMutationFn = Apollo.MutationFunction<EditKeyIncidentToCompetitionSurveyMutation, EditKeyIncidentToCompetitionSurveyMutationVariables>;

/**
 * __useEditKeyIncidentToCompetitionSurveyMutation__
 *
 * To run a mutation, you first call `useEditKeyIncidentToCompetitionSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditKeyIncidentToCompetitionSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editKeyIncidentToCompetitionSurveyMutation, { data, loading, error }] = useEditKeyIncidentToCompetitionSurveyMutation({
 *   variables: {
 *      competitionSurveyId: // value for 'competitionSurveyId'
 *      keyIncidentId: // value for 'keyIncidentId'
 *      description: // value for 'description'
 *      incidentType: // value for 'incidentType'
 *      impactedOutcome: // value for 'impactedOutcome'
 *      time: // value for 'time'
 *      periodId: // value for 'periodId'
 *   },
 * });
 */
export function useEditKeyIncidentToCompetitionSurveyMutation(baseOptions?: Apollo.MutationHookOptions<EditKeyIncidentToCompetitionSurveyMutation, EditKeyIncidentToCompetitionSurveyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditKeyIncidentToCompetitionSurveyMutation, EditKeyIncidentToCompetitionSurveyMutationVariables>(EditKeyIncidentToCompetitionSurveyDocument, options);
      }
export type EditKeyIncidentToCompetitionSurveyMutationHookResult = ReturnType<typeof useEditKeyIncidentToCompetitionSurveyMutation>;
export type EditKeyIncidentToCompetitionSurveyMutationResult = Apollo.MutationResult<EditKeyIncidentToCompetitionSurveyMutation>;
export type EditKeyIncidentToCompetitionSurveyMutationOptions = Apollo.BaseMutationOptions<EditKeyIncidentToCompetitionSurveyMutation, EditKeyIncidentToCompetitionSurveyMutationVariables>;
export const RemoveKeyIncidentFromCompetitionSurveyDocument = gql`
    mutation removeKeyIncidentFromCompetitionSurvey($competitionSurveyId: String!, $keyIncidentId: String!) {
  removeKeyIncidentFromCompetitionSurvey(
    input: {competitionSurveyId: $competitionSurveyId, keyIncidentId: $keyIncidentId}
  )
}
    `;
export type RemoveKeyIncidentFromCompetitionSurveyMutationFn = Apollo.MutationFunction<RemoveKeyIncidentFromCompetitionSurveyMutation, RemoveKeyIncidentFromCompetitionSurveyMutationVariables>;

/**
 * __useRemoveKeyIncidentFromCompetitionSurveyMutation__
 *
 * To run a mutation, you first call `useRemoveKeyIncidentFromCompetitionSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveKeyIncidentFromCompetitionSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeKeyIncidentFromCompetitionSurveyMutation, { data, loading, error }] = useRemoveKeyIncidentFromCompetitionSurveyMutation({
 *   variables: {
 *      competitionSurveyId: // value for 'competitionSurveyId'
 *      keyIncidentId: // value for 'keyIncidentId'
 *   },
 * });
 */
export function useRemoveKeyIncidentFromCompetitionSurveyMutation(baseOptions?: Apollo.MutationHookOptions<RemoveKeyIncidentFromCompetitionSurveyMutation, RemoveKeyIncidentFromCompetitionSurveyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveKeyIncidentFromCompetitionSurveyMutation, RemoveKeyIncidentFromCompetitionSurveyMutationVariables>(RemoveKeyIncidentFromCompetitionSurveyDocument, options);
      }
export type RemoveKeyIncidentFromCompetitionSurveyMutationHookResult = ReturnType<typeof useRemoveKeyIncidentFromCompetitionSurveyMutation>;
export type RemoveKeyIncidentFromCompetitionSurveyMutationResult = Apollo.MutationResult<RemoveKeyIncidentFromCompetitionSurveyMutation>;
export type RemoveKeyIncidentFromCompetitionSurveyMutationOptions = Apollo.BaseMutationOptions<RemoveKeyIncidentFromCompetitionSurveyMutation, RemoveKeyIncidentFromCompetitionSurveyMutationVariables>;
export const ListExternalLinksForKeyIncidentForCompetitionSurveyDocument = gql`
    query listExternalLinksForKeyIncidentForCompetitionSurvey($competitionSurveyId: String!, $keyIncidentId: String!) {
  listExternalLinksForKeyIncidentForCompetitionSurvey(
    input: {competitionSurveyId: $competitionSurveyId, keyIncidentId: $keyIncidentId}
  ) {
    externalLinks {
      id
      keyIncidentId
      link
      type
      description
      createdByFirstName
      createdByLastName
      createdAt
      updatedAt
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListExternalLinksForKeyIncidentForCompetitionSurveyQuery__
 *
 * To run a query within a React component, call `useListExternalLinksForKeyIncidentForCompetitionSurveyQuery` and pass it any options that fit your needs.
 * When your component renders, `useListExternalLinksForKeyIncidentForCompetitionSurveyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListExternalLinksForKeyIncidentForCompetitionSurveyQuery({
 *   variables: {
 *      competitionSurveyId: // value for 'competitionSurveyId'
 *      keyIncidentId: // value for 'keyIncidentId'
 *   },
 * });
 */
export function useListExternalLinksForKeyIncidentForCompetitionSurveyQuery(baseOptions: Apollo.QueryHookOptions<ListExternalLinksForKeyIncidentForCompetitionSurveyQuery, ListExternalLinksForKeyIncidentForCompetitionSurveyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListExternalLinksForKeyIncidentForCompetitionSurveyQuery, ListExternalLinksForKeyIncidentForCompetitionSurveyQueryVariables>(ListExternalLinksForKeyIncidentForCompetitionSurveyDocument, options);
      }
export function useListExternalLinksForKeyIncidentForCompetitionSurveyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListExternalLinksForKeyIncidentForCompetitionSurveyQuery, ListExternalLinksForKeyIncidentForCompetitionSurveyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListExternalLinksForKeyIncidentForCompetitionSurveyQuery, ListExternalLinksForKeyIncidentForCompetitionSurveyQueryVariables>(ListExternalLinksForKeyIncidentForCompetitionSurveyDocument, options);
        }
export type ListExternalLinksForKeyIncidentForCompetitionSurveyQueryHookResult = ReturnType<typeof useListExternalLinksForKeyIncidentForCompetitionSurveyQuery>;
export type ListExternalLinksForKeyIncidentForCompetitionSurveyLazyQueryHookResult = ReturnType<typeof useListExternalLinksForKeyIncidentForCompetitionSurveyLazyQuery>;
export type ListExternalLinksForKeyIncidentForCompetitionSurveyQueryResult = Apollo.QueryResult<ListExternalLinksForKeyIncidentForCompetitionSurveyQuery, ListExternalLinksForKeyIncidentForCompetitionSurveyQueryVariables>;
export const RemoveExternalLinkFromKeyIncidentFromCompetitionSurveyDocument = gql`
    mutation removeExternalLinkFromKeyIncidentFromCompetitionSurvey($competitionSurveyId: String!, $externalLinkId: String!) {
  removeExternalLinkFromKeyIncidentFromCompetitionSurvey(
    input: {competitionSurveyId: $competitionSurveyId, externalLinkId: $externalLinkId}
  )
}
    `;
export type RemoveExternalLinkFromKeyIncidentFromCompetitionSurveyMutationFn = Apollo.MutationFunction<RemoveExternalLinkFromKeyIncidentFromCompetitionSurveyMutation, RemoveExternalLinkFromKeyIncidentFromCompetitionSurveyMutationVariables>;

/**
 * __useRemoveExternalLinkFromKeyIncidentFromCompetitionSurveyMutation__
 *
 * To run a mutation, you first call `useRemoveExternalLinkFromKeyIncidentFromCompetitionSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveExternalLinkFromKeyIncidentFromCompetitionSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeExternalLinkFromKeyIncidentFromCompetitionSurveyMutation, { data, loading, error }] = useRemoveExternalLinkFromKeyIncidentFromCompetitionSurveyMutation({
 *   variables: {
 *      competitionSurveyId: // value for 'competitionSurveyId'
 *      externalLinkId: // value for 'externalLinkId'
 *   },
 * });
 */
export function useRemoveExternalLinkFromKeyIncidentFromCompetitionSurveyMutation(baseOptions?: Apollo.MutationHookOptions<RemoveExternalLinkFromKeyIncidentFromCompetitionSurveyMutation, RemoveExternalLinkFromKeyIncidentFromCompetitionSurveyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveExternalLinkFromKeyIncidentFromCompetitionSurveyMutation, RemoveExternalLinkFromKeyIncidentFromCompetitionSurveyMutationVariables>(RemoveExternalLinkFromKeyIncidentFromCompetitionSurveyDocument, options);
      }
export type RemoveExternalLinkFromKeyIncidentFromCompetitionSurveyMutationHookResult = ReturnType<typeof useRemoveExternalLinkFromKeyIncidentFromCompetitionSurveyMutation>;
export type RemoveExternalLinkFromKeyIncidentFromCompetitionSurveyMutationResult = Apollo.MutationResult<RemoveExternalLinkFromKeyIncidentFromCompetitionSurveyMutation>;
export type RemoveExternalLinkFromKeyIncidentFromCompetitionSurveyMutationOptions = Apollo.BaseMutationOptions<RemoveExternalLinkFromKeyIncidentFromCompetitionSurveyMutation, RemoveExternalLinkFromKeyIncidentFromCompetitionSurveyMutationVariables>;
export const AddExternalLinkToKeyIncidentToCompetitionSurveyDocument = gql`
    mutation addExternalLinkToKeyIncidentToCompetitionSurvey($competitionSurveyId: String!, $keyIncidentId: String!, $link: String!, $type: KeyIncidentExternalLinkTypes!, $description: String) {
  addExternalLinkToKeyIncidentToCompetitionSurvey(
    input: {competitionSurveyId: $competitionSurveyId, keyIncidentId: $keyIncidentId, link: $link, type: $type, description: $description}
  ) {
    id
    keyIncidentId
    link
    type
    description
    createdByFirstName
    createdByLastName
    createdAt
    updatedAt
  }
}
    `;
export type AddExternalLinkToKeyIncidentToCompetitionSurveyMutationFn = Apollo.MutationFunction<AddExternalLinkToKeyIncidentToCompetitionSurveyMutation, AddExternalLinkToKeyIncidentToCompetitionSurveyMutationVariables>;

/**
 * __useAddExternalLinkToKeyIncidentToCompetitionSurveyMutation__
 *
 * To run a mutation, you first call `useAddExternalLinkToKeyIncidentToCompetitionSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddExternalLinkToKeyIncidentToCompetitionSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addExternalLinkToKeyIncidentToCompetitionSurveyMutation, { data, loading, error }] = useAddExternalLinkToKeyIncidentToCompetitionSurveyMutation({
 *   variables: {
 *      competitionSurveyId: // value for 'competitionSurveyId'
 *      keyIncidentId: // value for 'keyIncidentId'
 *      link: // value for 'link'
 *      type: // value for 'type'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useAddExternalLinkToKeyIncidentToCompetitionSurveyMutation(baseOptions?: Apollo.MutationHookOptions<AddExternalLinkToKeyIncidentToCompetitionSurveyMutation, AddExternalLinkToKeyIncidentToCompetitionSurveyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddExternalLinkToKeyIncidentToCompetitionSurveyMutation, AddExternalLinkToKeyIncidentToCompetitionSurveyMutationVariables>(AddExternalLinkToKeyIncidentToCompetitionSurveyDocument, options);
      }
export type AddExternalLinkToKeyIncidentToCompetitionSurveyMutationHookResult = ReturnType<typeof useAddExternalLinkToKeyIncidentToCompetitionSurveyMutation>;
export type AddExternalLinkToKeyIncidentToCompetitionSurveyMutationResult = Apollo.MutationResult<AddExternalLinkToKeyIncidentToCompetitionSurveyMutation>;
export type AddExternalLinkToKeyIncidentToCompetitionSurveyMutationOptions = Apollo.BaseMutationOptions<AddExternalLinkToKeyIncidentToCompetitionSurveyMutation, AddExternalLinkToKeyIncidentToCompetitionSurveyMutationVariables>;
export const CompleteCompetitionSurveyDocument = gql`
    mutation completeCompetitionSurvey($competitionSurveyId: String!) {
  completeCompetitionSurvey(input: {competitionSurveyId: $competitionSurveyId})
}
    `;
export type CompleteCompetitionSurveyMutationFn = Apollo.MutationFunction<CompleteCompetitionSurveyMutation, CompleteCompetitionSurveyMutationVariables>;

/**
 * __useCompleteCompetitionSurveyMutation__
 *
 * To run a mutation, you first call `useCompleteCompetitionSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteCompetitionSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeCompetitionSurveyMutation, { data, loading, error }] = useCompleteCompetitionSurveyMutation({
 *   variables: {
 *      competitionSurveyId: // value for 'competitionSurveyId'
 *   },
 * });
 */
export function useCompleteCompetitionSurveyMutation(baseOptions?: Apollo.MutationHookOptions<CompleteCompetitionSurveyMutation, CompleteCompetitionSurveyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteCompetitionSurveyMutation, CompleteCompetitionSurveyMutationVariables>(CompleteCompetitionSurveyDocument, options);
      }
export type CompleteCompetitionSurveyMutationHookResult = ReturnType<typeof useCompleteCompetitionSurveyMutation>;
export type CompleteCompetitionSurveyMutationResult = Apollo.MutationResult<CompleteCompetitionSurveyMutation>;
export type CompleteCompetitionSurveyMutationOptions = Apollo.BaseMutationOptions<CompleteCompetitionSurveyMutation, CompleteCompetitionSurveyMutationVariables>;
export const ListCompetitionSurveyResponsesForOrganizationDocument = gql`
    query listCompetitionSurveyResponsesForOrganization($offset: Int!, $limit: Int!, $orgId: String!, $leagueId: String!, $seasonId: String!, $surveyId: String!) {
  listCompetitionSurveyResponsesForOrganization(
    input: {orgId: $orgId, leagueId: $leagueId, seasonId: $seasonId, surveyId: $surveyId, pagination: {offset: $offset, limit: $limit}}
  ) {
    responses {
      id
      completedDate
      teamId
      teamName
      orgInfo {
        sport
        orgId
        orgName
        leagueId
        leagueName
        seasonId
        seasonName
      }
      surveyInfo {
        surveyId
        surveyName
        audience
      }
      competitionInfo {
        competitionId
        competitionDate
        homeTeamId
        homeTeam
        homeTeamScore
        homeTeamTieBreakerScore
        awayTeamId
        awayTeam
        awayTeamScore
        awayTeamTieBreakerScore
      }
      userInfo {
        userId
        coachId
        email
        firstName
        lastName
        profilePic
      }
      sections {
        id
        name
        description
        order
        assessmentObject {
          id
          sport
          type
          classification
          name
        }
        official {
          id
          sport
          userId
          status
          email
          firstName
          lastName
          externalIds {
            id
            organizationId
            externalId
          }
          profilePic
          createdAt
          updatedAt
        }
        coach {
          id
          teamId
          teamName
          teamAbbreviation
          sport
          teamCity
          userId
          type
          role
          status
          email
          firstName
          lastName
          profilePic
          createdAt
          updatedAt
        }
        organization {
          id
          name
          sport
          status
          abbreviation
          site
          logo
          createdAt
          updatedAt
        }
        questions {
          id
          name
          description
          order
          questionType
          selectedOption
          selectedOptionOrder
          responseShortAnswer
          responseText
          responseNumber
          responses
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListCompetitionSurveyResponsesForOrganizationQuery__
 *
 * To run a query within a React component, call `useListCompetitionSurveyResponsesForOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCompetitionSurveyResponsesForOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCompetitionSurveyResponsesForOrganizationQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      orgId: // value for 'orgId'
 *      leagueId: // value for 'leagueId'
 *      seasonId: // value for 'seasonId'
 *      surveyId: // value for 'surveyId'
 *   },
 * });
 */
export function useListCompetitionSurveyResponsesForOrganizationQuery(baseOptions: Apollo.QueryHookOptions<ListCompetitionSurveyResponsesForOrganizationQuery, ListCompetitionSurveyResponsesForOrganizationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListCompetitionSurveyResponsesForOrganizationQuery, ListCompetitionSurveyResponsesForOrganizationQueryVariables>(ListCompetitionSurveyResponsesForOrganizationDocument, options);
      }
export function useListCompetitionSurveyResponsesForOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListCompetitionSurveyResponsesForOrganizationQuery, ListCompetitionSurveyResponsesForOrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListCompetitionSurveyResponsesForOrganizationQuery, ListCompetitionSurveyResponsesForOrganizationQueryVariables>(ListCompetitionSurveyResponsesForOrganizationDocument, options);
        }
export type ListCompetitionSurveyResponsesForOrganizationQueryHookResult = ReturnType<typeof useListCompetitionSurveyResponsesForOrganizationQuery>;
export type ListCompetitionSurveyResponsesForOrganizationLazyQueryHookResult = ReturnType<typeof useListCompetitionSurveyResponsesForOrganizationLazyQuery>;
export type ListCompetitionSurveyResponsesForOrganizationQueryResult = Apollo.QueryResult<ListCompetitionSurveyResponsesForOrganizationQuery, ListCompetitionSurveyResponsesForOrganizationQueryVariables>;
export const ListSurveyResponsesForCompetitionAndSurveyDocument = gql`
    query listSurveyResponsesForCompetitionAndSurvey($orgId: String!, $competitionId: String!, $surveyId: String!) {
  listSurveyResponsesForCompetitionAndSurvey(
    input: {orgId: $orgId, competitionId: $competitionId, surveyId: $surveyId}
  ) {
    orgInfo {
      sport
      orgId
      orgName
      leagueId
      leagueName
      seasonId
      seasonName
    }
    surveyInfo {
      surveyId
      surveyName
      audience
    }
    competitionInfo {
      competitionId
      competitionDate
      externalId
      homeTeamId
      homeTeam
      homeTeamAbbreviation
      homeTeamScore
      homeTeamTieBreakerScore
      awayTeamId
      awayTeam
      awayTeamAbbreviation
      awayTeamScore
      awayTeamTieBreakerScore
    }
    responses {
      id
      completedDate
      teamId
      teamName
      submittedBy {
        userId
        coachId
        email
        firstName
        lastName
        profilePic
      }
      sections {
        id
        name
        description
        order
        assessmentObject {
          id
          sport
          type
          classification
          name
        }
        official {
          id
          sport
          userId
          status
          email
          firstName
          lastName
          externalIds {
            id
            organizationId
            externalId
          }
          profilePic
          createdAt
          updatedAt
        }
        coach {
          id
          teamId
          teamName
          teamAbbreviation
          sport
          teamCity
          userId
          type
          role
          status
          email
          firstName
          lastName
          profilePic
          createdAt
          updatedAt
        }
        organization {
          id
          name
          sport
          status
          abbreviation
          site
          logo
          createdAt
          updatedAt
        }
        questions {
          id
          name
          description
          order
          questionType
          selectedOption
          selectedOptionOrder
          responseShortAnswer
          responseText
          responseNumber
          responses
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useListSurveyResponsesForCompetitionAndSurveyQuery__
 *
 * To run a query within a React component, call `useListSurveyResponsesForCompetitionAndSurveyQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSurveyResponsesForCompetitionAndSurveyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSurveyResponsesForCompetitionAndSurveyQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      competitionId: // value for 'competitionId'
 *      surveyId: // value for 'surveyId'
 *   },
 * });
 */
export function useListSurveyResponsesForCompetitionAndSurveyQuery(baseOptions: Apollo.QueryHookOptions<ListSurveyResponsesForCompetitionAndSurveyQuery, ListSurveyResponsesForCompetitionAndSurveyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListSurveyResponsesForCompetitionAndSurveyQuery, ListSurveyResponsesForCompetitionAndSurveyQueryVariables>(ListSurveyResponsesForCompetitionAndSurveyDocument, options);
      }
export function useListSurveyResponsesForCompetitionAndSurveyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListSurveyResponsesForCompetitionAndSurveyQuery, ListSurveyResponsesForCompetitionAndSurveyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListSurveyResponsesForCompetitionAndSurveyQuery, ListSurveyResponsesForCompetitionAndSurveyQueryVariables>(ListSurveyResponsesForCompetitionAndSurveyDocument, options);
        }
export type ListSurveyResponsesForCompetitionAndSurveyQueryHookResult = ReturnType<typeof useListSurveyResponsesForCompetitionAndSurveyQuery>;
export type ListSurveyResponsesForCompetitionAndSurveyLazyQueryHookResult = ReturnType<typeof useListSurveyResponsesForCompetitionAndSurveyLazyQuery>;
export type ListSurveyResponsesForCompetitionAndSurveyQueryResult = Apollo.QueryResult<ListSurveyResponsesForCompetitionAndSurveyQuery, ListSurveyResponsesForCompetitionAndSurveyQueryVariables>;
export const ListMyCompetitionSurveysDocument = gql`
    query listMyCompetitionSurveys($offset: Int!, $limit: Int!) {
  listMyCompetitionSurveys(input: {pagination: {offset: $offset, limit: $limit}}) {
    competitionSurveys {
      competitionSurveyId
      surveyName
      finished
      completedDate
      createdAt
      teamId
      teamName
      teamLogo
      competitionId
      startTime
      homeTeamId
      homeTeamName
      homeTeamLogo
      awayTeamId
      awayTeamName
      awayTeamLogo
      tookSurvey
      tookSurveyUserId
      averageScore
      keyIncidentsCount
      isDynamic
      completedPercentage
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListMyCompetitionSurveysQuery__
 *
 * To run a query within a React component, call `useListMyCompetitionSurveysQuery` and pass it any options that fit your needs.
 * When your component renders, `useListMyCompetitionSurveysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListMyCompetitionSurveysQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useListMyCompetitionSurveysQuery(baseOptions: Apollo.QueryHookOptions<ListMyCompetitionSurveysQuery, ListMyCompetitionSurveysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListMyCompetitionSurveysQuery, ListMyCompetitionSurveysQueryVariables>(ListMyCompetitionSurveysDocument, options);
      }
export function useListMyCompetitionSurveysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListMyCompetitionSurveysQuery, ListMyCompetitionSurveysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListMyCompetitionSurveysQuery, ListMyCompetitionSurveysQueryVariables>(ListMyCompetitionSurveysDocument, options);
        }
export type ListMyCompetitionSurveysQueryHookResult = ReturnType<typeof useListMyCompetitionSurveysQuery>;
export type ListMyCompetitionSurveysLazyQueryHookResult = ReturnType<typeof useListMyCompetitionSurveysLazyQuery>;
export type ListMyCompetitionSurveysQueryResult = Apollo.QueryResult<ListMyCompetitionSurveysQuery, ListMyCompetitionSurveysQueryVariables>;
export const ListMyCompetitionTeamKitsDocument = gql`
    query listMyCompetitionTeamKits($offset: Int!, $limit: Int!, $fromDate: String, $toDate: String, $teamSearch: String, $statuses: [CompetitionTeamKitStatus!]) {
  listMyCompetitionTeamKits(
    input: {fromDate: $fromDate, toDate: $toDate, teamSearch: $teamSearch, statuses: $statuses, pagination: {offset: $offset, limit: $limit}}
  ) {
    competitionTeamKits {
      id
      organizationId
      organizationName
      seasonId
      seasonName
      seasonTeamKitId
      competitionId
      startTime
      timeZone
      homeTeamId
      homeTeamName
      homeTeamLogo
      awayTeamId
      awayTeamName
      awayTeamLogo
      status
      completedDate
      createdAt
      updatedAt
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListMyCompetitionTeamKitsQuery__
 *
 * To run a query within a React component, call `useListMyCompetitionTeamKitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListMyCompetitionTeamKitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListMyCompetitionTeamKitsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *      teamSearch: // value for 'teamSearch'
 *      statuses: // value for 'statuses'
 *   },
 * });
 */
export function useListMyCompetitionTeamKitsQuery(baseOptions: Apollo.QueryHookOptions<ListMyCompetitionTeamKitsQuery, ListMyCompetitionTeamKitsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListMyCompetitionTeamKitsQuery, ListMyCompetitionTeamKitsQueryVariables>(ListMyCompetitionTeamKitsDocument, options);
      }
export function useListMyCompetitionTeamKitsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListMyCompetitionTeamKitsQuery, ListMyCompetitionTeamKitsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListMyCompetitionTeamKitsQuery, ListMyCompetitionTeamKitsQueryVariables>(ListMyCompetitionTeamKitsDocument, options);
        }
export type ListMyCompetitionTeamKitsQueryHookResult = ReturnType<typeof useListMyCompetitionTeamKitsQuery>;
export type ListMyCompetitionTeamKitsLazyQueryHookResult = ReturnType<typeof useListMyCompetitionTeamKitsLazyQuery>;
export type ListMyCompetitionTeamKitsQueryResult = Apollo.QueryResult<ListMyCompetitionTeamKitsQuery, ListMyCompetitionTeamKitsQueryVariables>;
export const ListCompetitionTeamKitsForOrganizationDocument = gql`
    query listCompetitionTeamKitsForOrganization($orgId: String!, $leagueId: String, $seasonId: String, $fromDate: String, $toDate: String, $teamSearch: String, $statuses: [CompetitionTeamKitStatus!], $offset: Int!, $limit: Int!) {
  listCompetitionTeamKitsForOrganization(
    input: {orgId: $orgId, leagueId: $leagueId, seasonId: $seasonId, fromDate: $fromDate, toDate: $toDate, teamSearch: $teamSearch, statuses: $statuses, pagination: {offset: $offset, limit: $limit}}
  ) {
    competitionTeamKits {
      id
      organizationId
      organizationName
      seasonId
      seasonName
      seasonTeamKitId
      competitionId
      startTime
      timeZone
      homeTeamId
      homeTeamName
      homeTeamLogo
      awayTeamId
      awayTeamName
      awayTeamLogo
      status
      completedDate
      createdAt
      updatedAt
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListCompetitionTeamKitsForOrganizationQuery__
 *
 * To run a query within a React component, call `useListCompetitionTeamKitsForOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCompetitionTeamKitsForOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCompetitionTeamKitsForOrganizationQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      leagueId: // value for 'leagueId'
 *      seasonId: // value for 'seasonId'
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *      teamSearch: // value for 'teamSearch'
 *      statuses: // value for 'statuses'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useListCompetitionTeamKitsForOrganizationQuery(baseOptions: Apollo.QueryHookOptions<ListCompetitionTeamKitsForOrganizationQuery, ListCompetitionTeamKitsForOrganizationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListCompetitionTeamKitsForOrganizationQuery, ListCompetitionTeamKitsForOrganizationQueryVariables>(ListCompetitionTeamKitsForOrganizationDocument, options);
      }
export function useListCompetitionTeamKitsForOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListCompetitionTeamKitsForOrganizationQuery, ListCompetitionTeamKitsForOrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListCompetitionTeamKitsForOrganizationQuery, ListCompetitionTeamKitsForOrganizationQueryVariables>(ListCompetitionTeamKitsForOrganizationDocument, options);
        }
export type ListCompetitionTeamKitsForOrganizationQueryHookResult = ReturnType<typeof useListCompetitionTeamKitsForOrganizationQuery>;
export type ListCompetitionTeamKitsForOrganizationLazyQueryHookResult = ReturnType<typeof useListCompetitionTeamKitsForOrganizationLazyQuery>;
export type ListCompetitionTeamKitsForOrganizationQueryResult = Apollo.QueryResult<ListCompetitionTeamKitsForOrganizationQuery, ListCompetitionTeamKitsForOrganizationQueryVariables>;
export const ListAvailableContactForCompetitionTeamKitContactDocument = gql`
    query listAvailableContactForCompetitionTeamKitContact($offset: Int!, $limit: Int!, $competitionTeamKitContactId: String!) {
  listAvailableContactForCompetitionTeamKitContact(
    input: {competitionTeamKitContactId: $competitionTeamKitContactId, pagination: {offset: $offset, limit: $limit}}
  ) {
    seasonId
    options {
      id
      seasonId
      definitionId
      orgContactId
      teamContactId
      isDefault
      contact
      email
      phone
      firstName
      lastName
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListAvailableContactForCompetitionTeamKitContactQuery__
 *
 * To run a query within a React component, call `useListAvailableContactForCompetitionTeamKitContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAvailableContactForCompetitionTeamKitContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAvailableContactForCompetitionTeamKitContactQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      competitionTeamKitContactId: // value for 'competitionTeamKitContactId'
 *   },
 * });
 */
export function useListAvailableContactForCompetitionTeamKitContactQuery(baseOptions: Apollo.QueryHookOptions<ListAvailableContactForCompetitionTeamKitContactQuery, ListAvailableContactForCompetitionTeamKitContactQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAvailableContactForCompetitionTeamKitContactQuery, ListAvailableContactForCompetitionTeamKitContactQueryVariables>(ListAvailableContactForCompetitionTeamKitContactDocument, options);
      }
export function useListAvailableContactForCompetitionTeamKitContactLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAvailableContactForCompetitionTeamKitContactQuery, ListAvailableContactForCompetitionTeamKitContactQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAvailableContactForCompetitionTeamKitContactQuery, ListAvailableContactForCompetitionTeamKitContactQueryVariables>(ListAvailableContactForCompetitionTeamKitContactDocument, options);
        }
export type ListAvailableContactForCompetitionTeamKitContactQueryHookResult = ReturnType<typeof useListAvailableContactForCompetitionTeamKitContactQuery>;
export type ListAvailableContactForCompetitionTeamKitContactLazyQueryHookResult = ReturnType<typeof useListAvailableContactForCompetitionTeamKitContactLazyQuery>;
export type ListAvailableContactForCompetitionTeamKitContactQueryResult = Apollo.QueryResult<ListAvailableContactForCompetitionTeamKitContactQuery, ListAvailableContactForCompetitionTeamKitContactQueryVariables>;
export const GetCompetitionTeamKitByIdDocument = gql`
    query getCompetitionTeamKitById($competitionTeamKitId: String!) {
  getCompetitionTeamKitById(input: {competitionTeamKitId: $competitionTeamKitId}) {
    id
    organizationId
    organizationName
    seasonId
    seasonName
    seasonTeamKitId
    competitionId
    startTime
    timeZone
    homeTeamId
    homeTeamName
    homeTeamLogo
    awayTeamId
    awayTeamName
    awayTeamLogo
    status
    completedDate
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetCompetitionTeamKitByIdQuery__
 *
 * To run a query within a React component, call `useGetCompetitionTeamKitByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompetitionTeamKitByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompetitionTeamKitByIdQuery({
 *   variables: {
 *      competitionTeamKitId: // value for 'competitionTeamKitId'
 *   },
 * });
 */
export function useGetCompetitionTeamKitByIdQuery(baseOptions: Apollo.QueryHookOptions<GetCompetitionTeamKitByIdQuery, GetCompetitionTeamKitByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompetitionTeamKitByIdQuery, GetCompetitionTeamKitByIdQueryVariables>(GetCompetitionTeamKitByIdDocument, options);
      }
export function useGetCompetitionTeamKitByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompetitionTeamKitByIdQuery, GetCompetitionTeamKitByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompetitionTeamKitByIdQuery, GetCompetitionTeamKitByIdQueryVariables>(GetCompetitionTeamKitByIdDocument, options);
        }
export type GetCompetitionTeamKitByIdQueryHookResult = ReturnType<typeof useGetCompetitionTeamKitByIdQuery>;
export type GetCompetitionTeamKitByIdLazyQueryHookResult = ReturnType<typeof useGetCompetitionTeamKitByIdLazyQuery>;
export type GetCompetitionTeamKitByIdQueryResult = Apollo.QueryResult<GetCompetitionTeamKitByIdQuery, GetCompetitionTeamKitByIdQueryVariables>;
export const SendCompetitionTeamKitToHomeTeamEditDocument = gql`
    mutation sendCompetitionTeamKitToHomeTeamEdit($competitionTeamKitId: String!) {
  sendCompetitionTeamKitToHomeTeamEdit(
    input: {competitionTeamKitId: $competitionTeamKitId}
  ) {
    id
    organizationId
    organizationName
    seasonId
    seasonName
    seasonTeamKitId
    competitionId
    startTime
    timeZone
    homeTeamId
    homeTeamName
    homeTeamLogo
    awayTeamId
    awayTeamName
    awayTeamLogo
    status
    completedDate
    createdAt
    updatedAt
  }
}
    `;
export type SendCompetitionTeamKitToHomeTeamEditMutationFn = Apollo.MutationFunction<SendCompetitionTeamKitToHomeTeamEditMutation, SendCompetitionTeamKitToHomeTeamEditMutationVariables>;

/**
 * __useSendCompetitionTeamKitToHomeTeamEditMutation__
 *
 * To run a mutation, you first call `useSendCompetitionTeamKitToHomeTeamEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendCompetitionTeamKitToHomeTeamEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendCompetitionTeamKitToHomeTeamEditMutation, { data, loading, error }] = useSendCompetitionTeamKitToHomeTeamEditMutation({
 *   variables: {
 *      competitionTeamKitId: // value for 'competitionTeamKitId'
 *   },
 * });
 */
export function useSendCompetitionTeamKitToHomeTeamEditMutation(baseOptions?: Apollo.MutationHookOptions<SendCompetitionTeamKitToHomeTeamEditMutation, SendCompetitionTeamKitToHomeTeamEditMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendCompetitionTeamKitToHomeTeamEditMutation, SendCompetitionTeamKitToHomeTeamEditMutationVariables>(SendCompetitionTeamKitToHomeTeamEditDocument, options);
      }
export type SendCompetitionTeamKitToHomeTeamEditMutationHookResult = ReturnType<typeof useSendCompetitionTeamKitToHomeTeamEditMutation>;
export type SendCompetitionTeamKitToHomeTeamEditMutationResult = Apollo.MutationResult<SendCompetitionTeamKitToHomeTeamEditMutation>;
export type SendCompetitionTeamKitToHomeTeamEditMutationOptions = Apollo.BaseMutationOptions<SendCompetitionTeamKitToHomeTeamEditMutation, SendCompetitionTeamKitToHomeTeamEditMutationVariables>;
export const SendCompetitionTeamKitToAwayTeamEditDocument = gql`
    mutation sendCompetitionTeamKitToAwayTeamEdit($competitionTeamKitId: String!) {
  sendCompetitionTeamKitToAwayTeamEdit(
    input: {competitionTeamKitId: $competitionTeamKitId}
  ) {
    id
    organizationId
    organizationName
    seasonId
    seasonName
    seasonTeamKitId
    competitionId
    startTime
    timeZone
    homeTeamId
    homeTeamName
    homeTeamLogo
    awayTeamId
    awayTeamName
    awayTeamLogo
    status
    completedDate
    createdAt
    updatedAt
  }
}
    `;
export type SendCompetitionTeamKitToAwayTeamEditMutationFn = Apollo.MutationFunction<SendCompetitionTeamKitToAwayTeamEditMutation, SendCompetitionTeamKitToAwayTeamEditMutationVariables>;

/**
 * __useSendCompetitionTeamKitToAwayTeamEditMutation__
 *
 * To run a mutation, you first call `useSendCompetitionTeamKitToAwayTeamEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendCompetitionTeamKitToAwayTeamEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendCompetitionTeamKitToAwayTeamEditMutation, { data, loading, error }] = useSendCompetitionTeamKitToAwayTeamEditMutation({
 *   variables: {
 *      competitionTeamKitId: // value for 'competitionTeamKitId'
 *   },
 * });
 */
export function useSendCompetitionTeamKitToAwayTeamEditMutation(baseOptions?: Apollo.MutationHookOptions<SendCompetitionTeamKitToAwayTeamEditMutation, SendCompetitionTeamKitToAwayTeamEditMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendCompetitionTeamKitToAwayTeamEditMutation, SendCompetitionTeamKitToAwayTeamEditMutationVariables>(SendCompetitionTeamKitToAwayTeamEditDocument, options);
      }
export type SendCompetitionTeamKitToAwayTeamEditMutationHookResult = ReturnType<typeof useSendCompetitionTeamKitToAwayTeamEditMutation>;
export type SendCompetitionTeamKitToAwayTeamEditMutationResult = Apollo.MutationResult<SendCompetitionTeamKitToAwayTeamEditMutation>;
export type SendCompetitionTeamKitToAwayTeamEditMutationOptions = Apollo.BaseMutationOptions<SendCompetitionTeamKitToAwayTeamEditMutation, SendCompetitionTeamKitToAwayTeamEditMutationVariables>;
export const SendCompetitionTeamKitToLeagueEditDocument = gql`
    mutation sendCompetitionTeamKitToLeagueEdit($competitionTeamKitId: String!) {
  sendCompetitionTeamKitToLeagueEdit(
    input: {competitionTeamKitId: $competitionTeamKitId}
  ) {
    id
    organizationId
    organizationName
    seasonId
    seasonName
    seasonTeamKitId
    competitionId
    startTime
    timeZone
    homeTeamId
    homeTeamName
    homeTeamLogo
    awayTeamId
    awayTeamName
    awayTeamLogo
    status
    completedDate
    createdAt
    updatedAt
  }
}
    `;
export type SendCompetitionTeamKitToLeagueEditMutationFn = Apollo.MutationFunction<SendCompetitionTeamKitToLeagueEditMutation, SendCompetitionTeamKitToLeagueEditMutationVariables>;

/**
 * __useSendCompetitionTeamKitToLeagueEditMutation__
 *
 * To run a mutation, you first call `useSendCompetitionTeamKitToLeagueEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendCompetitionTeamKitToLeagueEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendCompetitionTeamKitToLeagueEditMutation, { data, loading, error }] = useSendCompetitionTeamKitToLeagueEditMutation({
 *   variables: {
 *      competitionTeamKitId: // value for 'competitionTeamKitId'
 *   },
 * });
 */
export function useSendCompetitionTeamKitToLeagueEditMutation(baseOptions?: Apollo.MutationHookOptions<SendCompetitionTeamKitToLeagueEditMutation, SendCompetitionTeamKitToLeagueEditMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendCompetitionTeamKitToLeagueEditMutation, SendCompetitionTeamKitToLeagueEditMutationVariables>(SendCompetitionTeamKitToLeagueEditDocument, options);
      }
export type SendCompetitionTeamKitToLeagueEditMutationHookResult = ReturnType<typeof useSendCompetitionTeamKitToLeagueEditMutation>;
export type SendCompetitionTeamKitToLeagueEditMutationResult = Apollo.MutationResult<SendCompetitionTeamKitToLeagueEditMutation>;
export type SendCompetitionTeamKitToLeagueEditMutationOptions = Apollo.BaseMutationOptions<SendCompetitionTeamKitToLeagueEditMutation, SendCompetitionTeamKitToLeagueEditMutationVariables>;
export const SendCompetitionTeamKitRequestedFixToLeagueEditDocument = gql`
    mutation sendCompetitionTeamKitRequestedFixToLeagueEdit($competitionTeamKitId: String!, $forHomeTeam: Boolean!) {
  sendCompetitionTeamKitRequestedFixToLeagueEdit(
    input: {competitionTeamKitId: $competitionTeamKitId, forHomeTeam: $forHomeTeam}
  ) {
    id
    organizationId
    organizationName
    seasonId
    seasonName
    seasonTeamKitId
    competitionId
    startTime
    timeZone
    homeTeamId
    homeTeamName
    homeTeamLogo
    awayTeamId
    awayTeamName
    awayTeamLogo
    status
    completedDate
    createdAt
    updatedAt
  }
}
    `;
export type SendCompetitionTeamKitRequestedFixToLeagueEditMutationFn = Apollo.MutationFunction<SendCompetitionTeamKitRequestedFixToLeagueEditMutation, SendCompetitionTeamKitRequestedFixToLeagueEditMutationVariables>;

/**
 * __useSendCompetitionTeamKitRequestedFixToLeagueEditMutation__
 *
 * To run a mutation, you first call `useSendCompetitionTeamKitRequestedFixToLeagueEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendCompetitionTeamKitRequestedFixToLeagueEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendCompetitionTeamKitRequestedFixToLeagueEditMutation, { data, loading, error }] = useSendCompetitionTeamKitRequestedFixToLeagueEditMutation({
 *   variables: {
 *      competitionTeamKitId: // value for 'competitionTeamKitId'
 *      forHomeTeam: // value for 'forHomeTeam'
 *   },
 * });
 */
export function useSendCompetitionTeamKitRequestedFixToLeagueEditMutation(baseOptions?: Apollo.MutationHookOptions<SendCompetitionTeamKitRequestedFixToLeagueEditMutation, SendCompetitionTeamKitRequestedFixToLeagueEditMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendCompetitionTeamKitRequestedFixToLeagueEditMutation, SendCompetitionTeamKitRequestedFixToLeagueEditMutationVariables>(SendCompetitionTeamKitRequestedFixToLeagueEditDocument, options);
      }
export type SendCompetitionTeamKitRequestedFixToLeagueEditMutationHookResult = ReturnType<typeof useSendCompetitionTeamKitRequestedFixToLeagueEditMutation>;
export type SendCompetitionTeamKitRequestedFixToLeagueEditMutationResult = Apollo.MutationResult<SendCompetitionTeamKitRequestedFixToLeagueEditMutation>;
export type SendCompetitionTeamKitRequestedFixToLeagueEditMutationOptions = Apollo.BaseMutationOptions<SendCompetitionTeamKitRequestedFixToLeagueEditMutation, SendCompetitionTeamKitRequestedFixToLeagueEditMutationVariables>;
export const CompetitionTeamKitApparelApprovalDocument = gql`
    mutation competitionTeamKitApparelApproval($competitionTeamKitApparelId: String!, $approved: Boolean!) {
  competitionTeamKitApparelApproval(
    input: {competitionTeamKitApparelId: $competitionTeamKitApparelId, approved: $approved}
  ) {
    id
    competitionTeamKitId
    type
    status
    seasonKitRoleId
    roleType
    roleName
    roleOrder
    seasonKitApparelDefinitionId
    definitionName
    definitionOrder
    seasonApparelId
    name
    color
    image
    createdAt
    updatedAt
  }
}
    `;
export type CompetitionTeamKitApparelApprovalMutationFn = Apollo.MutationFunction<CompetitionTeamKitApparelApprovalMutation, CompetitionTeamKitApparelApprovalMutationVariables>;

/**
 * __useCompetitionTeamKitApparelApprovalMutation__
 *
 * To run a mutation, you first call `useCompetitionTeamKitApparelApprovalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompetitionTeamKitApparelApprovalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [competitionTeamKitApparelApprovalMutation, { data, loading, error }] = useCompetitionTeamKitApparelApprovalMutation({
 *   variables: {
 *      competitionTeamKitApparelId: // value for 'competitionTeamKitApparelId'
 *      approved: // value for 'approved'
 *   },
 * });
 */
export function useCompetitionTeamKitApparelApprovalMutation(baseOptions?: Apollo.MutationHookOptions<CompetitionTeamKitApparelApprovalMutation, CompetitionTeamKitApparelApprovalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompetitionTeamKitApparelApprovalMutation, CompetitionTeamKitApparelApprovalMutationVariables>(CompetitionTeamKitApparelApprovalDocument, options);
      }
export type CompetitionTeamKitApparelApprovalMutationHookResult = ReturnType<typeof useCompetitionTeamKitApparelApprovalMutation>;
export type CompetitionTeamKitApparelApprovalMutationResult = Apollo.MutationResult<CompetitionTeamKitApparelApprovalMutation>;
export type CompetitionTeamKitApparelApprovalMutationOptions = Apollo.BaseMutationOptions<CompetitionTeamKitApparelApprovalMutation, CompetitionTeamKitApparelApprovalMutationVariables>;
export const ApproveCompetitionTeamKitDocument = gql`
    mutation approveCompetitionTeamKit($competitionTeamKitId: String!) {
  approveCompetitionTeamKit(input: {competitionTeamKitId: $competitionTeamKitId}) {
    id
    organizationId
    organizationName
    seasonId
    seasonName
    seasonTeamKitId
    competitionId
    startTime
    timeZone
    homeTeamId
    homeTeamName
    homeTeamLogo
    awayTeamId
    awayTeamName
    awayTeamLogo
    status
    completedDate
    createdAt
    updatedAt
  }
}
    `;
export type ApproveCompetitionTeamKitMutationFn = Apollo.MutationFunction<ApproveCompetitionTeamKitMutation, ApproveCompetitionTeamKitMutationVariables>;

/**
 * __useApproveCompetitionTeamKitMutation__
 *
 * To run a mutation, you first call `useApproveCompetitionTeamKitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveCompetitionTeamKitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveCompetitionTeamKitMutation, { data, loading, error }] = useApproveCompetitionTeamKitMutation({
 *   variables: {
 *      competitionTeamKitId: // value for 'competitionTeamKitId'
 *   },
 * });
 */
export function useApproveCompetitionTeamKitMutation(baseOptions?: Apollo.MutationHookOptions<ApproveCompetitionTeamKitMutation, ApproveCompetitionTeamKitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApproveCompetitionTeamKitMutation, ApproveCompetitionTeamKitMutationVariables>(ApproveCompetitionTeamKitDocument, options);
      }
export type ApproveCompetitionTeamKitMutationHookResult = ReturnType<typeof useApproveCompetitionTeamKitMutation>;
export type ApproveCompetitionTeamKitMutationResult = Apollo.MutationResult<ApproveCompetitionTeamKitMutation>;
export type ApproveCompetitionTeamKitMutationOptions = Apollo.BaseMutationOptions<ApproveCompetitionTeamKitMutation, ApproveCompetitionTeamKitMutationVariables>;
export const CompetitionTeamKitRequestFixDocument = gql`
    mutation competitionTeamKitRequestFix($competitionTeamKitId: String!, $forHomeTeam: Boolean!) {
  competitionTeamKitRequestFix(
    input: {competitionTeamKitId: $competitionTeamKitId, forHomeTeam: $forHomeTeam}
  ) {
    id
    organizationId
    organizationName
    seasonId
    seasonName
    seasonTeamKitId
    competitionId
    startTime
    timeZone
    homeTeamId
    homeTeamName
    homeTeamLogo
    awayTeamId
    awayTeamName
    awayTeamLogo
    status
    completedDate
    createdAt
    updatedAt
  }
}
    `;
export type CompetitionTeamKitRequestFixMutationFn = Apollo.MutationFunction<CompetitionTeamKitRequestFixMutation, CompetitionTeamKitRequestFixMutationVariables>;

/**
 * __useCompetitionTeamKitRequestFixMutation__
 *
 * To run a mutation, you first call `useCompetitionTeamKitRequestFixMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompetitionTeamKitRequestFixMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [competitionTeamKitRequestFixMutation, { data, loading, error }] = useCompetitionTeamKitRequestFixMutation({
 *   variables: {
 *      competitionTeamKitId: // value for 'competitionTeamKitId'
 *      forHomeTeam: // value for 'forHomeTeam'
 *   },
 * });
 */
export function useCompetitionTeamKitRequestFixMutation(baseOptions?: Apollo.MutationHookOptions<CompetitionTeamKitRequestFixMutation, CompetitionTeamKitRequestFixMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompetitionTeamKitRequestFixMutation, CompetitionTeamKitRequestFixMutationVariables>(CompetitionTeamKitRequestFixDocument, options);
      }
export type CompetitionTeamKitRequestFixMutationHookResult = ReturnType<typeof useCompetitionTeamKitRequestFixMutation>;
export type CompetitionTeamKitRequestFixMutationResult = Apollo.MutationResult<CompetitionTeamKitRequestFixMutation>;
export type CompetitionTeamKitRequestFixMutationOptions = Apollo.BaseMutationOptions<CompetitionTeamKitRequestFixMutation, CompetitionTeamKitRequestFixMutationVariables>;
export const ReopenCompetitionTeamKitDocument = gql`
    mutation reopenCompetitionTeamKit($competitionTeamKitId: String!) {
  reopenCompetitionTeamKit(input: {competitionTeamKitId: $competitionTeamKitId}) {
    id
    organizationId
    organizationName
    seasonId
    seasonName
    seasonTeamKitId
    competitionId
    startTime
    timeZone
    homeTeamId
    homeTeamName
    homeTeamLogo
    awayTeamId
    awayTeamName
    awayTeamLogo
    status
    completedDate
    createdAt
    updatedAt
  }
}
    `;
export type ReopenCompetitionTeamKitMutationFn = Apollo.MutationFunction<ReopenCompetitionTeamKitMutation, ReopenCompetitionTeamKitMutationVariables>;

/**
 * __useReopenCompetitionTeamKitMutation__
 *
 * To run a mutation, you first call `useReopenCompetitionTeamKitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReopenCompetitionTeamKitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reopenCompetitionTeamKitMutation, { data, loading, error }] = useReopenCompetitionTeamKitMutation({
 *   variables: {
 *      competitionTeamKitId: // value for 'competitionTeamKitId'
 *   },
 * });
 */
export function useReopenCompetitionTeamKitMutation(baseOptions?: Apollo.MutationHookOptions<ReopenCompetitionTeamKitMutation, ReopenCompetitionTeamKitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReopenCompetitionTeamKitMutation, ReopenCompetitionTeamKitMutationVariables>(ReopenCompetitionTeamKitDocument, options);
      }
export type ReopenCompetitionTeamKitMutationHookResult = ReturnType<typeof useReopenCompetitionTeamKitMutation>;
export type ReopenCompetitionTeamKitMutationResult = Apollo.MutationResult<ReopenCompetitionTeamKitMutation>;
export type ReopenCompetitionTeamKitMutationOptions = Apollo.BaseMutationOptions<ReopenCompetitionTeamKitMutation, ReopenCompetitionTeamKitMutationVariables>;
export const ListCompetitionTeamKitRecipientsDocument = gql`
    query listCompetitionTeamKitRecipients($competitionTeamKitId: String!) {
  listCompetitionTeamKitRecipients(
    input: {competitionTeamKitId: $competitionTeamKitId}
  ) {
    id
    competitionTeamKitId
    type
    userId
    email
    firstName
    lastName
    profilePic
    notificationSent
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useListCompetitionTeamKitRecipientsQuery__
 *
 * To run a query within a React component, call `useListCompetitionTeamKitRecipientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCompetitionTeamKitRecipientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCompetitionTeamKitRecipientsQuery({
 *   variables: {
 *      competitionTeamKitId: // value for 'competitionTeamKitId'
 *   },
 * });
 */
export function useListCompetitionTeamKitRecipientsQuery(baseOptions: Apollo.QueryHookOptions<ListCompetitionTeamKitRecipientsQuery, ListCompetitionTeamKitRecipientsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListCompetitionTeamKitRecipientsQuery, ListCompetitionTeamKitRecipientsQueryVariables>(ListCompetitionTeamKitRecipientsDocument, options);
      }
export function useListCompetitionTeamKitRecipientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListCompetitionTeamKitRecipientsQuery, ListCompetitionTeamKitRecipientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListCompetitionTeamKitRecipientsQuery, ListCompetitionTeamKitRecipientsQueryVariables>(ListCompetitionTeamKitRecipientsDocument, options);
        }
export type ListCompetitionTeamKitRecipientsQueryHookResult = ReturnType<typeof useListCompetitionTeamKitRecipientsQuery>;
export type ListCompetitionTeamKitRecipientsLazyQueryHookResult = ReturnType<typeof useListCompetitionTeamKitRecipientsLazyQuery>;
export type ListCompetitionTeamKitRecipientsQueryResult = Apollo.QueryResult<ListCompetitionTeamKitRecipientsQuery, ListCompetitionTeamKitRecipientsQueryVariables>;
export const ListCompetitionTeamKitBroadcastOptionsDocument = gql`
    query listCompetitionTeamKitBroadcastOptions($competitionTeamKitId: String!, $offset: Int!, $limit: Int!) {
  listCompetitionTeamKitBroadcastOptions(
    input: {competitionTeamKitId: $competitionTeamKitId, pagination: {offset: $offset, limit: $limit}}
  ) {
    broadcastOptions {
      id
      competitionTeamKitId
      broadcastOptionId
      name
      createdAt
      updatedAt
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListCompetitionTeamKitBroadcastOptionsQuery__
 *
 * To run a query within a React component, call `useListCompetitionTeamKitBroadcastOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCompetitionTeamKitBroadcastOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCompetitionTeamKitBroadcastOptionsQuery({
 *   variables: {
 *      competitionTeamKitId: // value for 'competitionTeamKitId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useListCompetitionTeamKitBroadcastOptionsQuery(baseOptions: Apollo.QueryHookOptions<ListCompetitionTeamKitBroadcastOptionsQuery, ListCompetitionTeamKitBroadcastOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListCompetitionTeamKitBroadcastOptionsQuery, ListCompetitionTeamKitBroadcastOptionsQueryVariables>(ListCompetitionTeamKitBroadcastOptionsDocument, options);
      }
export function useListCompetitionTeamKitBroadcastOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListCompetitionTeamKitBroadcastOptionsQuery, ListCompetitionTeamKitBroadcastOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListCompetitionTeamKitBroadcastOptionsQuery, ListCompetitionTeamKitBroadcastOptionsQueryVariables>(ListCompetitionTeamKitBroadcastOptionsDocument, options);
        }
export type ListCompetitionTeamKitBroadcastOptionsQueryHookResult = ReturnType<typeof useListCompetitionTeamKitBroadcastOptionsQuery>;
export type ListCompetitionTeamKitBroadcastOptionsLazyQueryHookResult = ReturnType<typeof useListCompetitionTeamKitBroadcastOptionsLazyQuery>;
export type ListCompetitionTeamKitBroadcastOptionsQueryResult = Apollo.QueryResult<ListCompetitionTeamKitBroadcastOptionsQuery, ListCompetitionTeamKitBroadcastOptionsQueryVariables>;
export const ListAvailableBroadcastOptionsForCompetitionTeamKitDocument = gql`
    query listAvailableBroadcastOptionsForCompetitionTeamKit($competitionTeamKitId: String!, $offset: Int!, $limit: Int!) {
  listAvailableBroadcastOptionsForCompetitionTeamKit(
    input: {competitionTeamKitId: $competitionTeamKitId, pagination: {offset: $offset, limit: $limit}}
  ) {
    seasonId
    broadcastOptions {
      id
      seasonId
      teamId
      teamName
      value
      isActive
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListAvailableBroadcastOptionsForCompetitionTeamKitQuery__
 *
 * To run a query within a React component, call `useListAvailableBroadcastOptionsForCompetitionTeamKitQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAvailableBroadcastOptionsForCompetitionTeamKitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAvailableBroadcastOptionsForCompetitionTeamKitQuery({
 *   variables: {
 *      competitionTeamKitId: // value for 'competitionTeamKitId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useListAvailableBroadcastOptionsForCompetitionTeamKitQuery(baseOptions: Apollo.QueryHookOptions<ListAvailableBroadcastOptionsForCompetitionTeamKitQuery, ListAvailableBroadcastOptionsForCompetitionTeamKitQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAvailableBroadcastOptionsForCompetitionTeamKitQuery, ListAvailableBroadcastOptionsForCompetitionTeamKitQueryVariables>(ListAvailableBroadcastOptionsForCompetitionTeamKitDocument, options);
      }
export function useListAvailableBroadcastOptionsForCompetitionTeamKitLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAvailableBroadcastOptionsForCompetitionTeamKitQuery, ListAvailableBroadcastOptionsForCompetitionTeamKitQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAvailableBroadcastOptionsForCompetitionTeamKitQuery, ListAvailableBroadcastOptionsForCompetitionTeamKitQueryVariables>(ListAvailableBroadcastOptionsForCompetitionTeamKitDocument, options);
        }
export type ListAvailableBroadcastOptionsForCompetitionTeamKitQueryHookResult = ReturnType<typeof useListAvailableBroadcastOptionsForCompetitionTeamKitQuery>;
export type ListAvailableBroadcastOptionsForCompetitionTeamKitLazyQueryHookResult = ReturnType<typeof useListAvailableBroadcastOptionsForCompetitionTeamKitLazyQuery>;
export type ListAvailableBroadcastOptionsForCompetitionTeamKitQueryResult = Apollo.QueryResult<ListAvailableBroadcastOptionsForCompetitionTeamKitQuery, ListAvailableBroadcastOptionsForCompetitionTeamKitQueryVariables>;
export const AddBroadcastOptionToCompetitionTeamKitDocument = gql`
    mutation addBroadcastOptionToCompetitionTeamKit($competitionTeamKitId: String!, $broadcastOptionId: String!) {
  addBroadcastOptionToCompetitionTeamKit(
    input: {competitionTeamKitId: $competitionTeamKitId, broadcastOptionId: $broadcastOptionId}
  ) {
    id
    competitionTeamKitId
    broadcastOptionId
    name
    createdAt
    updatedAt
  }
}
    `;
export type AddBroadcastOptionToCompetitionTeamKitMutationFn = Apollo.MutationFunction<AddBroadcastOptionToCompetitionTeamKitMutation, AddBroadcastOptionToCompetitionTeamKitMutationVariables>;

/**
 * __useAddBroadcastOptionToCompetitionTeamKitMutation__
 *
 * To run a mutation, you first call `useAddBroadcastOptionToCompetitionTeamKitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddBroadcastOptionToCompetitionTeamKitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addBroadcastOptionToCompetitionTeamKitMutation, { data, loading, error }] = useAddBroadcastOptionToCompetitionTeamKitMutation({
 *   variables: {
 *      competitionTeamKitId: // value for 'competitionTeamKitId'
 *      broadcastOptionId: // value for 'broadcastOptionId'
 *   },
 * });
 */
export function useAddBroadcastOptionToCompetitionTeamKitMutation(baseOptions?: Apollo.MutationHookOptions<AddBroadcastOptionToCompetitionTeamKitMutation, AddBroadcastOptionToCompetitionTeamKitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddBroadcastOptionToCompetitionTeamKitMutation, AddBroadcastOptionToCompetitionTeamKitMutationVariables>(AddBroadcastOptionToCompetitionTeamKitDocument, options);
      }
export type AddBroadcastOptionToCompetitionTeamKitMutationHookResult = ReturnType<typeof useAddBroadcastOptionToCompetitionTeamKitMutation>;
export type AddBroadcastOptionToCompetitionTeamKitMutationResult = Apollo.MutationResult<AddBroadcastOptionToCompetitionTeamKitMutation>;
export type AddBroadcastOptionToCompetitionTeamKitMutationOptions = Apollo.BaseMutationOptions<AddBroadcastOptionToCompetitionTeamKitMutation, AddBroadcastOptionToCompetitionTeamKitMutationVariables>;
export const RemoveBroadcastOptionFromCompetitionTeamKitDocument = gql`
    mutation removeBroadcastOptionFromCompetitionTeamKit($competitionTeamKitId: String!, $broadcastOptionId: String!) {
  removeBroadcastOptionFromCompetitionTeamKit(
    input: {competitionTeamKitId: $competitionTeamKitId, broadcastOptionId: $broadcastOptionId}
  )
}
    `;
export type RemoveBroadcastOptionFromCompetitionTeamKitMutationFn = Apollo.MutationFunction<RemoveBroadcastOptionFromCompetitionTeamKitMutation, RemoveBroadcastOptionFromCompetitionTeamKitMutationVariables>;

/**
 * __useRemoveBroadcastOptionFromCompetitionTeamKitMutation__
 *
 * To run a mutation, you first call `useRemoveBroadcastOptionFromCompetitionTeamKitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveBroadcastOptionFromCompetitionTeamKitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeBroadcastOptionFromCompetitionTeamKitMutation, { data, loading, error }] = useRemoveBroadcastOptionFromCompetitionTeamKitMutation({
 *   variables: {
 *      competitionTeamKitId: // value for 'competitionTeamKitId'
 *      broadcastOptionId: // value for 'broadcastOptionId'
 *   },
 * });
 */
export function useRemoveBroadcastOptionFromCompetitionTeamKitMutation(baseOptions?: Apollo.MutationHookOptions<RemoveBroadcastOptionFromCompetitionTeamKitMutation, RemoveBroadcastOptionFromCompetitionTeamKitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveBroadcastOptionFromCompetitionTeamKitMutation, RemoveBroadcastOptionFromCompetitionTeamKitMutationVariables>(RemoveBroadcastOptionFromCompetitionTeamKitDocument, options);
      }
export type RemoveBroadcastOptionFromCompetitionTeamKitMutationHookResult = ReturnType<typeof useRemoveBroadcastOptionFromCompetitionTeamKitMutation>;
export type RemoveBroadcastOptionFromCompetitionTeamKitMutationResult = Apollo.MutationResult<RemoveBroadcastOptionFromCompetitionTeamKitMutation>;
export type RemoveBroadcastOptionFromCompetitionTeamKitMutationOptions = Apollo.BaseMutationOptions<RemoveBroadcastOptionFromCompetitionTeamKitMutation, RemoveBroadcastOptionFromCompetitionTeamKitMutationVariables>;
export const ListCompetitionTeamKitContactsDocument = gql`
    query listCompetitionTeamKitContacts($competitionTeamKitId: String!, $offset: Int!, $limit: Int!) {
  listCompetitionTeamKitContacts(
    input: {competitionTeamKitId: $competitionTeamKitId, pagination: {offset: $offset, limit: $limit}}
  ) {
    contacts {
      id
      competitionTeamKitId
      type
      name
      order
      seasonContactDefinitionId
      teamContactId
      organizationContactId
      email
      phone
      firstName
      lastName
      createdAt
      updatedAt
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListCompetitionTeamKitContactsQuery__
 *
 * To run a query within a React component, call `useListCompetitionTeamKitContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCompetitionTeamKitContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCompetitionTeamKitContactsQuery({
 *   variables: {
 *      competitionTeamKitId: // value for 'competitionTeamKitId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useListCompetitionTeamKitContactsQuery(baseOptions: Apollo.QueryHookOptions<ListCompetitionTeamKitContactsQuery, ListCompetitionTeamKitContactsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListCompetitionTeamKitContactsQuery, ListCompetitionTeamKitContactsQueryVariables>(ListCompetitionTeamKitContactsDocument, options);
      }
export function useListCompetitionTeamKitContactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListCompetitionTeamKitContactsQuery, ListCompetitionTeamKitContactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListCompetitionTeamKitContactsQuery, ListCompetitionTeamKitContactsQueryVariables>(ListCompetitionTeamKitContactsDocument, options);
        }
export type ListCompetitionTeamKitContactsQueryHookResult = ReturnType<typeof useListCompetitionTeamKitContactsQuery>;
export type ListCompetitionTeamKitContactsLazyQueryHookResult = ReturnType<typeof useListCompetitionTeamKitContactsLazyQuery>;
export type ListCompetitionTeamKitContactsQueryResult = Apollo.QueryResult<ListCompetitionTeamKitContactsQuery, ListCompetitionTeamKitContactsQueryVariables>;
export const GetCompetitionTeamKitContactByIdDocument = gql`
    query getCompetitionTeamKitContactById($competitionTeamKitContactId: String!) {
  getCompetitionTeamKitContactById(
    input: {competitionTeamKitContactId: $competitionTeamKitContactId}
  ) {
    id
    competitionTeamKitId
    type
    name
    order
    seasonContactDefinitionId
    teamContactId
    organizationContactId
    email
    phone
    firstName
    lastName
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetCompetitionTeamKitContactByIdQuery__
 *
 * To run a query within a React component, call `useGetCompetitionTeamKitContactByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompetitionTeamKitContactByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompetitionTeamKitContactByIdQuery({
 *   variables: {
 *      competitionTeamKitContactId: // value for 'competitionTeamKitContactId'
 *   },
 * });
 */
export function useGetCompetitionTeamKitContactByIdQuery(baseOptions: Apollo.QueryHookOptions<GetCompetitionTeamKitContactByIdQuery, GetCompetitionTeamKitContactByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompetitionTeamKitContactByIdQuery, GetCompetitionTeamKitContactByIdQueryVariables>(GetCompetitionTeamKitContactByIdDocument, options);
      }
export function useGetCompetitionTeamKitContactByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompetitionTeamKitContactByIdQuery, GetCompetitionTeamKitContactByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompetitionTeamKitContactByIdQuery, GetCompetitionTeamKitContactByIdQueryVariables>(GetCompetitionTeamKitContactByIdDocument, options);
        }
export type GetCompetitionTeamKitContactByIdQueryHookResult = ReturnType<typeof useGetCompetitionTeamKitContactByIdQuery>;
export type GetCompetitionTeamKitContactByIdLazyQueryHookResult = ReturnType<typeof useGetCompetitionTeamKitContactByIdLazyQuery>;
export type GetCompetitionTeamKitContactByIdQueryResult = Apollo.QueryResult<GetCompetitionTeamKitContactByIdQuery, GetCompetitionTeamKitContactByIdQueryVariables>;
export const ClearSelectionForCompetitionTeamKitContactDocument = gql`
    mutation clearSelectionForCompetitionTeamKitContact($competitionTeamKitContactId: String!) {
  clearSelectionForCompetitionTeamKitContact(
    input: {competitionTeamKitContactId: $competitionTeamKitContactId}
  ) {
    id
    competitionTeamKitId
    type
    name
    order
    seasonContactDefinitionId
    teamContactId
    organizationContactId
    email
    phone
    firstName
    lastName
    createdAt
    updatedAt
  }
}
    `;
export type ClearSelectionForCompetitionTeamKitContactMutationFn = Apollo.MutationFunction<ClearSelectionForCompetitionTeamKitContactMutation, ClearSelectionForCompetitionTeamKitContactMutationVariables>;

/**
 * __useClearSelectionForCompetitionTeamKitContactMutation__
 *
 * To run a mutation, you first call `useClearSelectionForCompetitionTeamKitContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearSelectionForCompetitionTeamKitContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearSelectionForCompetitionTeamKitContactMutation, { data, loading, error }] = useClearSelectionForCompetitionTeamKitContactMutation({
 *   variables: {
 *      competitionTeamKitContactId: // value for 'competitionTeamKitContactId'
 *   },
 * });
 */
export function useClearSelectionForCompetitionTeamKitContactMutation(baseOptions?: Apollo.MutationHookOptions<ClearSelectionForCompetitionTeamKitContactMutation, ClearSelectionForCompetitionTeamKitContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClearSelectionForCompetitionTeamKitContactMutation, ClearSelectionForCompetitionTeamKitContactMutationVariables>(ClearSelectionForCompetitionTeamKitContactDocument, options);
      }
export type ClearSelectionForCompetitionTeamKitContactMutationHookResult = ReturnType<typeof useClearSelectionForCompetitionTeamKitContactMutation>;
export type ClearSelectionForCompetitionTeamKitContactMutationResult = Apollo.MutationResult<ClearSelectionForCompetitionTeamKitContactMutation>;
export type ClearSelectionForCompetitionTeamKitContactMutationOptions = Apollo.BaseMutationOptions<ClearSelectionForCompetitionTeamKitContactMutation, ClearSelectionForCompetitionTeamKitContactMutationVariables>;
export const UpdateSelectionForCompetitionTeamKitContactDocument = gql`
    mutation updateSelectionForCompetitionTeamKitContact($competitionTeamKitContactId: String!, $selectedOptionId: String!) {
  updateSelectionForCompetitionTeamKitContact(
    input: {competitionTeamKitContactId: $competitionTeamKitContactId, selectedOptionId: $selectedOptionId}
  ) {
    id
    competitionTeamKitId
    type
    name
    order
    seasonContactDefinitionId
    teamContactId
    organizationContactId
    email
    phone
    firstName
    lastName
    createdAt
    updatedAt
  }
}
    `;
export type UpdateSelectionForCompetitionTeamKitContactMutationFn = Apollo.MutationFunction<UpdateSelectionForCompetitionTeamKitContactMutation, UpdateSelectionForCompetitionTeamKitContactMutationVariables>;

/**
 * __useUpdateSelectionForCompetitionTeamKitContactMutation__
 *
 * To run a mutation, you first call `useUpdateSelectionForCompetitionTeamKitContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSelectionForCompetitionTeamKitContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSelectionForCompetitionTeamKitContactMutation, { data, loading, error }] = useUpdateSelectionForCompetitionTeamKitContactMutation({
 *   variables: {
 *      competitionTeamKitContactId: // value for 'competitionTeamKitContactId'
 *      selectedOptionId: // value for 'selectedOptionId'
 *   },
 * });
 */
export function useUpdateSelectionForCompetitionTeamKitContactMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSelectionForCompetitionTeamKitContactMutation, UpdateSelectionForCompetitionTeamKitContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSelectionForCompetitionTeamKitContactMutation, UpdateSelectionForCompetitionTeamKitContactMutationVariables>(UpdateSelectionForCompetitionTeamKitContactDocument, options);
      }
export type UpdateSelectionForCompetitionTeamKitContactMutationHookResult = ReturnType<typeof useUpdateSelectionForCompetitionTeamKitContactMutation>;
export type UpdateSelectionForCompetitionTeamKitContactMutationResult = Apollo.MutationResult<UpdateSelectionForCompetitionTeamKitContactMutation>;
export type UpdateSelectionForCompetitionTeamKitContactMutationOptions = Apollo.BaseMutationOptions<UpdateSelectionForCompetitionTeamKitContactMutation, UpdateSelectionForCompetitionTeamKitContactMutationVariables>;
export const AddNewContactToCompetitionTeamKitContactDocument = gql`
    mutation addNewContactToCompetitionTeamKitContact($competitionTeamKitContactId: String!, $firstName: String!, $lastName: String!, $email: String, $phone: String) {
  addNewContactToCompetitionTeamKitContact(
    input: {competitionTeamKitContactId: $competitionTeamKitContactId, firstName: $firstName, lastName: $lastName, email: $email, phone: $phone}
  ) {
    id
    competitionTeamKitId
    type
    name
    order
    seasonContactDefinitionId
    teamContactId
    organizationContactId
    email
    phone
    firstName
    lastName
    createdAt
    updatedAt
  }
}
    `;
export type AddNewContactToCompetitionTeamKitContactMutationFn = Apollo.MutationFunction<AddNewContactToCompetitionTeamKitContactMutation, AddNewContactToCompetitionTeamKitContactMutationVariables>;

/**
 * __useAddNewContactToCompetitionTeamKitContactMutation__
 *
 * To run a mutation, you first call `useAddNewContactToCompetitionTeamKitContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddNewContactToCompetitionTeamKitContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addNewContactToCompetitionTeamKitContactMutation, { data, loading, error }] = useAddNewContactToCompetitionTeamKitContactMutation({
 *   variables: {
 *      competitionTeamKitContactId: // value for 'competitionTeamKitContactId'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function useAddNewContactToCompetitionTeamKitContactMutation(baseOptions?: Apollo.MutationHookOptions<AddNewContactToCompetitionTeamKitContactMutation, AddNewContactToCompetitionTeamKitContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddNewContactToCompetitionTeamKitContactMutation, AddNewContactToCompetitionTeamKitContactMutationVariables>(AddNewContactToCompetitionTeamKitContactDocument, options);
      }
export type AddNewContactToCompetitionTeamKitContactMutationHookResult = ReturnType<typeof useAddNewContactToCompetitionTeamKitContactMutation>;
export type AddNewContactToCompetitionTeamKitContactMutationResult = Apollo.MutationResult<AddNewContactToCompetitionTeamKitContactMutation>;
export type AddNewContactToCompetitionTeamKitContactMutationOptions = Apollo.BaseMutationOptions<AddNewContactToCompetitionTeamKitContactMutation, AddNewContactToCompetitionTeamKitContactMutationVariables>;
export const ListKitRolesForCompetitionTeamKitDocument = gql`
    query listKitRolesForCompetitionTeamKit($offset: Int!, $limit: Int!, $competitionTeamKitId: String!) {
  listKitRolesForCompetitionTeamKit(
    input: {competitionTeamKitId: $competitionTeamKitId, pagination: {offset: $offset, limit: $limit}}
  ) {
    seasonId
    kitRoles {
      id
      value
      type
      order
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListKitRolesForCompetitionTeamKitQuery__
 *
 * To run a query within a React component, call `useListKitRolesForCompetitionTeamKitQuery` and pass it any options that fit your needs.
 * When your component renders, `useListKitRolesForCompetitionTeamKitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListKitRolesForCompetitionTeamKitQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      competitionTeamKitId: // value for 'competitionTeamKitId'
 *   },
 * });
 */
export function useListKitRolesForCompetitionTeamKitQuery(baseOptions: Apollo.QueryHookOptions<ListKitRolesForCompetitionTeamKitQuery, ListKitRolesForCompetitionTeamKitQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListKitRolesForCompetitionTeamKitQuery, ListKitRolesForCompetitionTeamKitQueryVariables>(ListKitRolesForCompetitionTeamKitDocument, options);
      }
export function useListKitRolesForCompetitionTeamKitLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListKitRolesForCompetitionTeamKitQuery, ListKitRolesForCompetitionTeamKitQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListKitRolesForCompetitionTeamKitQuery, ListKitRolesForCompetitionTeamKitQueryVariables>(ListKitRolesForCompetitionTeamKitDocument, options);
        }
export type ListKitRolesForCompetitionTeamKitQueryHookResult = ReturnType<typeof useListKitRolesForCompetitionTeamKitQuery>;
export type ListKitRolesForCompetitionTeamKitLazyQueryHookResult = ReturnType<typeof useListKitRolesForCompetitionTeamKitLazyQuery>;
export type ListKitRolesForCompetitionTeamKitQueryResult = Apollo.QueryResult<ListKitRolesForCompetitionTeamKitQuery, ListKitRolesForCompetitionTeamKitQueryVariables>;
export const ListCompetitionTeamKitApparelsDocument = gql`
    query listCompetitionTeamKitApparels($offset: Int!, $limit: Int!, $competitionTeamKitId: String!) {
  listCompetitionTeamKitApparels(
    input: {competitionTeamKitId: $competitionTeamKitId, pagination: {offset: $offset, limit: $limit}}
  ) {
    apparels {
      id
      competitionTeamKitId
      type
      status
      seasonKitRoleId
      roleType
      roleName
      roleOrder
      seasonKitApparelDefinitionId
      definitionName
      definitionOrder
      seasonApparelId
      name
      color
      image
      createdAt
      updatedAt
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListCompetitionTeamKitApparelsQuery__
 *
 * To run a query within a React component, call `useListCompetitionTeamKitApparelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCompetitionTeamKitApparelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCompetitionTeamKitApparelsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      competitionTeamKitId: // value for 'competitionTeamKitId'
 *   },
 * });
 */
export function useListCompetitionTeamKitApparelsQuery(baseOptions: Apollo.QueryHookOptions<ListCompetitionTeamKitApparelsQuery, ListCompetitionTeamKitApparelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListCompetitionTeamKitApparelsQuery, ListCompetitionTeamKitApparelsQueryVariables>(ListCompetitionTeamKitApparelsDocument, options);
      }
export function useListCompetitionTeamKitApparelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListCompetitionTeamKitApparelsQuery, ListCompetitionTeamKitApparelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListCompetitionTeamKitApparelsQuery, ListCompetitionTeamKitApparelsQueryVariables>(ListCompetitionTeamKitApparelsDocument, options);
        }
export type ListCompetitionTeamKitApparelsQueryHookResult = ReturnType<typeof useListCompetitionTeamKitApparelsQuery>;
export type ListCompetitionTeamKitApparelsLazyQueryHookResult = ReturnType<typeof useListCompetitionTeamKitApparelsLazyQuery>;
export type ListCompetitionTeamKitApparelsQueryResult = Apollo.QueryResult<ListCompetitionTeamKitApparelsQuery, ListCompetitionTeamKitApparelsQueryVariables>;
export const GetCompetitionTeamKitApparelByIdDocument = gql`
    query getCompetitionTeamKitApparelById($competitionTeamKitApparelId: String!) {
  getCompetitionTeamKitApparelById(
    input: {competitionTeamKitApparelId: $competitionTeamKitApparelId}
  ) {
    id
    competitionTeamKitId
    type
    status
    seasonKitRoleId
    roleType
    roleName
    roleOrder
    seasonKitApparelDefinitionId
    definitionName
    definitionOrder
    seasonApparelId
    name
    color
    image
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetCompetitionTeamKitApparelByIdQuery__
 *
 * To run a query within a React component, call `useGetCompetitionTeamKitApparelByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompetitionTeamKitApparelByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompetitionTeamKitApparelByIdQuery({
 *   variables: {
 *      competitionTeamKitApparelId: // value for 'competitionTeamKitApparelId'
 *   },
 * });
 */
export function useGetCompetitionTeamKitApparelByIdQuery(baseOptions: Apollo.QueryHookOptions<GetCompetitionTeamKitApparelByIdQuery, GetCompetitionTeamKitApparelByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompetitionTeamKitApparelByIdQuery, GetCompetitionTeamKitApparelByIdQueryVariables>(GetCompetitionTeamKitApparelByIdDocument, options);
      }
export function useGetCompetitionTeamKitApparelByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompetitionTeamKitApparelByIdQuery, GetCompetitionTeamKitApparelByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompetitionTeamKitApparelByIdQuery, GetCompetitionTeamKitApparelByIdQueryVariables>(GetCompetitionTeamKitApparelByIdDocument, options);
        }
export type GetCompetitionTeamKitApparelByIdQueryHookResult = ReturnType<typeof useGetCompetitionTeamKitApparelByIdQuery>;
export type GetCompetitionTeamKitApparelByIdLazyQueryHookResult = ReturnType<typeof useGetCompetitionTeamKitApparelByIdLazyQuery>;
export type GetCompetitionTeamKitApparelByIdQueryResult = Apollo.QueryResult<GetCompetitionTeamKitApparelByIdQuery, GetCompetitionTeamKitApparelByIdQueryVariables>;
export const ListAvailableSelectionsForCompetitionTeamKitApparelDocument = gql`
    query listAvailableSelectionsForCompetitionTeamKitApparel($offset: Int!, $limit: Int!, $competitionTeamKitApparelId: String!) {
  listAvailableSelectionsForCompetitionTeamKitApparel(
    input: {competitionTeamKitApparelId: $competitionTeamKitApparelId, pagination: {offset: $offset, limit: $limit}}
  ) {
    seasonId
    teamId
    apparels {
      seasonId
      teamId
      teamName
      id
      isActive
      name
      color
      imageUrl
      kitApparel {
        id
        value
        order
      }
      kitRole {
        id
        value
        type
        order
      }
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListAvailableSelectionsForCompetitionTeamKitApparelQuery__
 *
 * To run a query within a React component, call `useListAvailableSelectionsForCompetitionTeamKitApparelQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAvailableSelectionsForCompetitionTeamKitApparelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAvailableSelectionsForCompetitionTeamKitApparelQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      competitionTeamKitApparelId: // value for 'competitionTeamKitApparelId'
 *   },
 * });
 */
export function useListAvailableSelectionsForCompetitionTeamKitApparelQuery(baseOptions: Apollo.QueryHookOptions<ListAvailableSelectionsForCompetitionTeamKitApparelQuery, ListAvailableSelectionsForCompetitionTeamKitApparelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAvailableSelectionsForCompetitionTeamKitApparelQuery, ListAvailableSelectionsForCompetitionTeamKitApparelQueryVariables>(ListAvailableSelectionsForCompetitionTeamKitApparelDocument, options);
      }
export function useListAvailableSelectionsForCompetitionTeamKitApparelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAvailableSelectionsForCompetitionTeamKitApparelQuery, ListAvailableSelectionsForCompetitionTeamKitApparelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAvailableSelectionsForCompetitionTeamKitApparelQuery, ListAvailableSelectionsForCompetitionTeamKitApparelQueryVariables>(ListAvailableSelectionsForCompetitionTeamKitApparelDocument, options);
        }
export type ListAvailableSelectionsForCompetitionTeamKitApparelQueryHookResult = ReturnType<typeof useListAvailableSelectionsForCompetitionTeamKitApparelQuery>;
export type ListAvailableSelectionsForCompetitionTeamKitApparelLazyQueryHookResult = ReturnType<typeof useListAvailableSelectionsForCompetitionTeamKitApparelLazyQuery>;
export type ListAvailableSelectionsForCompetitionTeamKitApparelQueryResult = Apollo.QueryResult<ListAvailableSelectionsForCompetitionTeamKitApparelQuery, ListAvailableSelectionsForCompetitionTeamKitApparelQueryVariables>;
export const ClearSelectionForCompetitionTeamKitApparelDocument = gql`
    mutation clearSelectionForCompetitionTeamKitApparel($competitionTeamKitApparelId: String!) {
  clearSelectionForCompetitionTeamKitApparel(
    input: {competitionTeamKitApparelId: $competitionTeamKitApparelId}
  ) {
    id
    competitionTeamKitId
    type
    status
    seasonKitRoleId
    roleType
    roleName
    roleOrder
    seasonKitApparelDefinitionId
    definitionName
    definitionOrder
    seasonApparelId
    name
    color
    image
    createdAt
    updatedAt
  }
}
    `;
export type ClearSelectionForCompetitionTeamKitApparelMutationFn = Apollo.MutationFunction<ClearSelectionForCompetitionTeamKitApparelMutation, ClearSelectionForCompetitionTeamKitApparelMutationVariables>;

/**
 * __useClearSelectionForCompetitionTeamKitApparelMutation__
 *
 * To run a mutation, you first call `useClearSelectionForCompetitionTeamKitApparelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearSelectionForCompetitionTeamKitApparelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearSelectionForCompetitionTeamKitApparelMutation, { data, loading, error }] = useClearSelectionForCompetitionTeamKitApparelMutation({
 *   variables: {
 *      competitionTeamKitApparelId: // value for 'competitionTeamKitApparelId'
 *   },
 * });
 */
export function useClearSelectionForCompetitionTeamKitApparelMutation(baseOptions?: Apollo.MutationHookOptions<ClearSelectionForCompetitionTeamKitApparelMutation, ClearSelectionForCompetitionTeamKitApparelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClearSelectionForCompetitionTeamKitApparelMutation, ClearSelectionForCompetitionTeamKitApparelMutationVariables>(ClearSelectionForCompetitionTeamKitApparelDocument, options);
      }
export type ClearSelectionForCompetitionTeamKitApparelMutationHookResult = ReturnType<typeof useClearSelectionForCompetitionTeamKitApparelMutation>;
export type ClearSelectionForCompetitionTeamKitApparelMutationResult = Apollo.MutationResult<ClearSelectionForCompetitionTeamKitApparelMutation>;
export type ClearSelectionForCompetitionTeamKitApparelMutationOptions = Apollo.BaseMutationOptions<ClearSelectionForCompetitionTeamKitApparelMutation, ClearSelectionForCompetitionTeamKitApparelMutationVariables>;
export const UpdateSelectionForCompetitionTeamKitApparelDocument = gql`
    mutation updateSelectionForCompetitionTeamKitApparel($competitionTeamKitApparelId: String!, $selectedApparelId: String!) {
  updateSelectionForCompetitionTeamKitApparel(
    input: {competitionTeamKitApparelId: $competitionTeamKitApparelId, selectedApparelId: $selectedApparelId}
  ) {
    id
    competitionTeamKitId
    type
    status
    seasonKitRoleId
    roleType
    roleName
    roleOrder
    seasonKitApparelDefinitionId
    definitionName
    definitionOrder
    seasonApparelId
    name
    color
    image
    createdAt
    updatedAt
  }
}
    `;
export type UpdateSelectionForCompetitionTeamKitApparelMutationFn = Apollo.MutationFunction<UpdateSelectionForCompetitionTeamKitApparelMutation, UpdateSelectionForCompetitionTeamKitApparelMutationVariables>;

/**
 * __useUpdateSelectionForCompetitionTeamKitApparelMutation__
 *
 * To run a mutation, you first call `useUpdateSelectionForCompetitionTeamKitApparelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSelectionForCompetitionTeamKitApparelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSelectionForCompetitionTeamKitApparelMutation, { data, loading, error }] = useUpdateSelectionForCompetitionTeamKitApparelMutation({
 *   variables: {
 *      competitionTeamKitApparelId: // value for 'competitionTeamKitApparelId'
 *      selectedApparelId: // value for 'selectedApparelId'
 *   },
 * });
 */
export function useUpdateSelectionForCompetitionTeamKitApparelMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSelectionForCompetitionTeamKitApparelMutation, UpdateSelectionForCompetitionTeamKitApparelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSelectionForCompetitionTeamKitApparelMutation, UpdateSelectionForCompetitionTeamKitApparelMutationVariables>(UpdateSelectionForCompetitionTeamKitApparelDocument, options);
      }
export type UpdateSelectionForCompetitionTeamKitApparelMutationHookResult = ReturnType<typeof useUpdateSelectionForCompetitionTeamKitApparelMutation>;
export type UpdateSelectionForCompetitionTeamKitApparelMutationResult = Apollo.MutationResult<UpdateSelectionForCompetitionTeamKitApparelMutation>;
export type UpdateSelectionForCompetitionTeamKitApparelMutationOptions = Apollo.BaseMutationOptions<UpdateSelectionForCompetitionTeamKitApparelMutation, UpdateSelectionForCompetitionTeamKitApparelMutationVariables>;
export const ListCompetitionTeamKitCompetitionInfosDocument = gql`
    query listCompetitionTeamKitCompetitionInfos($competitionTeamKitId: String!, $offset: Int!, $limit: Int!) {
  listCompetitionTeamKitCompetitionInfos(
    input: {competitionTeamKitId: $competitionTeamKitId, pagination: {offset: $offset, limit: $limit}}
  ) {
    competitionInfos {
      id
      competitionTeamKitId
      value
      createdAt
      updatedAt
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListCompetitionTeamKitCompetitionInfosQuery__
 *
 * To run a query within a React component, call `useListCompetitionTeamKitCompetitionInfosQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCompetitionTeamKitCompetitionInfosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCompetitionTeamKitCompetitionInfosQuery({
 *   variables: {
 *      competitionTeamKitId: // value for 'competitionTeamKitId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useListCompetitionTeamKitCompetitionInfosQuery(baseOptions: Apollo.QueryHookOptions<ListCompetitionTeamKitCompetitionInfosQuery, ListCompetitionTeamKitCompetitionInfosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListCompetitionTeamKitCompetitionInfosQuery, ListCompetitionTeamKitCompetitionInfosQueryVariables>(ListCompetitionTeamKitCompetitionInfosDocument, options);
      }
export function useListCompetitionTeamKitCompetitionInfosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListCompetitionTeamKitCompetitionInfosQuery, ListCompetitionTeamKitCompetitionInfosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListCompetitionTeamKitCompetitionInfosQuery, ListCompetitionTeamKitCompetitionInfosQueryVariables>(ListCompetitionTeamKitCompetitionInfosDocument, options);
        }
export type ListCompetitionTeamKitCompetitionInfosQueryHookResult = ReturnType<typeof useListCompetitionTeamKitCompetitionInfosQuery>;
export type ListCompetitionTeamKitCompetitionInfosLazyQueryHookResult = ReturnType<typeof useListCompetitionTeamKitCompetitionInfosLazyQuery>;
export type ListCompetitionTeamKitCompetitionInfosQueryResult = Apollo.QueryResult<ListCompetitionTeamKitCompetitionInfosQuery, ListCompetitionTeamKitCompetitionInfosQueryVariables>;
export const AddCompetitionInfoToCompetitionTeamKitDocument = gql`
    mutation addCompetitionInfoToCompetitionTeamKit($competitionTeamKitId: String!, $value: String!) {
  addCompetitionInfoToCompetitionTeamKit(
    input: {competitionTeamKitId: $competitionTeamKitId, value: $value}
  ) {
    id
    competitionTeamKitId
    value
    createdAt
    updatedAt
  }
}
    `;
export type AddCompetitionInfoToCompetitionTeamKitMutationFn = Apollo.MutationFunction<AddCompetitionInfoToCompetitionTeamKitMutation, AddCompetitionInfoToCompetitionTeamKitMutationVariables>;

/**
 * __useAddCompetitionInfoToCompetitionTeamKitMutation__
 *
 * To run a mutation, you first call `useAddCompetitionInfoToCompetitionTeamKitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCompetitionInfoToCompetitionTeamKitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCompetitionInfoToCompetitionTeamKitMutation, { data, loading, error }] = useAddCompetitionInfoToCompetitionTeamKitMutation({
 *   variables: {
 *      competitionTeamKitId: // value for 'competitionTeamKitId'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useAddCompetitionInfoToCompetitionTeamKitMutation(baseOptions?: Apollo.MutationHookOptions<AddCompetitionInfoToCompetitionTeamKitMutation, AddCompetitionInfoToCompetitionTeamKitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCompetitionInfoToCompetitionTeamKitMutation, AddCompetitionInfoToCompetitionTeamKitMutationVariables>(AddCompetitionInfoToCompetitionTeamKitDocument, options);
      }
export type AddCompetitionInfoToCompetitionTeamKitMutationHookResult = ReturnType<typeof useAddCompetitionInfoToCompetitionTeamKitMutation>;
export type AddCompetitionInfoToCompetitionTeamKitMutationResult = Apollo.MutationResult<AddCompetitionInfoToCompetitionTeamKitMutation>;
export type AddCompetitionInfoToCompetitionTeamKitMutationOptions = Apollo.BaseMutationOptions<AddCompetitionInfoToCompetitionTeamKitMutation, AddCompetitionInfoToCompetitionTeamKitMutationVariables>;
export const UpdateCompetitionTeamKitCompetitionInfoDocument = gql`
    mutation updateCompetitionTeamKitCompetitionInfo($competitionTeamKitId: String!, $competitionInfoId: String!, $value: String!) {
  updateCompetitionTeamKitCompetitionInfo(
    input: {competitionTeamKitId: $competitionTeamKitId, competitionInfoId: $competitionInfoId, value: $value}
  ) {
    id
    competitionTeamKitId
    value
    createdAt
    updatedAt
  }
}
    `;
export type UpdateCompetitionTeamKitCompetitionInfoMutationFn = Apollo.MutationFunction<UpdateCompetitionTeamKitCompetitionInfoMutation, UpdateCompetitionTeamKitCompetitionInfoMutationVariables>;

/**
 * __useUpdateCompetitionTeamKitCompetitionInfoMutation__
 *
 * To run a mutation, you first call `useUpdateCompetitionTeamKitCompetitionInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompetitionTeamKitCompetitionInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompetitionTeamKitCompetitionInfoMutation, { data, loading, error }] = useUpdateCompetitionTeamKitCompetitionInfoMutation({
 *   variables: {
 *      competitionTeamKitId: // value for 'competitionTeamKitId'
 *      competitionInfoId: // value for 'competitionInfoId'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useUpdateCompetitionTeamKitCompetitionInfoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompetitionTeamKitCompetitionInfoMutation, UpdateCompetitionTeamKitCompetitionInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompetitionTeamKitCompetitionInfoMutation, UpdateCompetitionTeamKitCompetitionInfoMutationVariables>(UpdateCompetitionTeamKitCompetitionInfoDocument, options);
      }
export type UpdateCompetitionTeamKitCompetitionInfoMutationHookResult = ReturnType<typeof useUpdateCompetitionTeamKitCompetitionInfoMutation>;
export type UpdateCompetitionTeamKitCompetitionInfoMutationResult = Apollo.MutationResult<UpdateCompetitionTeamKitCompetitionInfoMutation>;
export type UpdateCompetitionTeamKitCompetitionInfoMutationOptions = Apollo.BaseMutationOptions<UpdateCompetitionTeamKitCompetitionInfoMutation, UpdateCompetitionTeamKitCompetitionInfoMutationVariables>;
export const RemoveCompetitionInfoFromCompetitionTeamKitDocument = gql`
    mutation removeCompetitionInfoFromCompetitionTeamKit($competitionTeamKitId: String!, $competitionInfoId: String!) {
  removeCompetitionInfoFromCompetitionTeamKit(
    input: {competitionTeamKitId: $competitionTeamKitId, competitionInfoId: $competitionInfoId}
  )
}
    `;
export type RemoveCompetitionInfoFromCompetitionTeamKitMutationFn = Apollo.MutationFunction<RemoveCompetitionInfoFromCompetitionTeamKitMutation, RemoveCompetitionInfoFromCompetitionTeamKitMutationVariables>;

/**
 * __useRemoveCompetitionInfoFromCompetitionTeamKitMutation__
 *
 * To run a mutation, you first call `useRemoveCompetitionInfoFromCompetitionTeamKitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCompetitionInfoFromCompetitionTeamKitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCompetitionInfoFromCompetitionTeamKitMutation, { data, loading, error }] = useRemoveCompetitionInfoFromCompetitionTeamKitMutation({
 *   variables: {
 *      competitionTeamKitId: // value for 'competitionTeamKitId'
 *      competitionInfoId: // value for 'competitionInfoId'
 *   },
 * });
 */
export function useRemoveCompetitionInfoFromCompetitionTeamKitMutation(baseOptions?: Apollo.MutationHookOptions<RemoveCompetitionInfoFromCompetitionTeamKitMutation, RemoveCompetitionInfoFromCompetitionTeamKitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveCompetitionInfoFromCompetitionTeamKitMutation, RemoveCompetitionInfoFromCompetitionTeamKitMutationVariables>(RemoveCompetitionInfoFromCompetitionTeamKitDocument, options);
      }
export type RemoveCompetitionInfoFromCompetitionTeamKitMutationHookResult = ReturnType<typeof useRemoveCompetitionInfoFromCompetitionTeamKitMutation>;
export type RemoveCompetitionInfoFromCompetitionTeamKitMutationResult = Apollo.MutationResult<RemoveCompetitionInfoFromCompetitionTeamKitMutation>;
export type RemoveCompetitionInfoFromCompetitionTeamKitMutationOptions = Apollo.BaseMutationOptions<RemoveCompetitionInfoFromCompetitionTeamKitMutation, RemoveCompetitionInfoFromCompetitionTeamKitMutationVariables>;
export const CreateCompetitionForSeasonDocument = gql`
    mutation createCompetitionForSeason($seasonId: String!, $homeTeamId: String!, $awayTeamId: String!, $startTime: Date!, $timeZone: String!, $status: CompetitionStatuses!, $externalId: String, $location: String, $homeTeamScore: Int, $homeTeamTieBreakerScore: Int, $awayTeamScore: Int, $awayTeamTieBreakerScore: Int) {
  createCompetitionForSeason(
    input: {seasonId: $seasonId, homeTeamId: $homeTeamId, awayTeamId: $awayTeamId, startTime: $startTime, timeZone: $timeZone, status: $status, externalId: $externalId, location: $location, homeTeamScore: $homeTeamScore, homeTeamTieBreakerScore: $homeTeamTieBreakerScore, awayTeamScore: $awayTeamScore, awayTeamTieBreakerScore: $awayTeamTieBreakerScore}
  ) {
    id
    externalId
    sport
    status
    startTime
    outcome
    verificationStatus
    timeZone
    location
    homeTeamScore
    homeTeamTieBreakerScore
    awayTeamScore
    awayTeamTieBreakerScore
    homeTeam {
      id
      name
      abbreviation
      sport
      city
      stateAbbreviation
      countryCode
      classification
      gender
      parentOrganizationName
      externalId
      ageGroup
      timeZone
      website
      logo
      createdAt
      updatedAt
    }
    awayTeam {
      id
      name
      abbreviation
      sport
      city
      stateAbbreviation
      countryCode
      classification
      gender
      parentOrganizationName
      externalId
      ageGroup
      timeZone
      website
      logo
      createdAt
      updatedAt
    }
    organization {
      id
      name
      sport
      status
      abbreviation
      site
      logo
      createdAt
      updatedAt
    }
    season {
      id
      leagueId
      leagueName
      leagueLogo
      name
      description
      status
      startDate
      endDate
      createdAt
      updatedAt
    }
    officials {
      id
      competitionId
      assessmentObjectId
      order
      isOptional
      official {
        id
        sport
        userId
        status
        firstName
        lastName
        profilePic
        createdAt
        updatedAt
      }
      type
      createdAt
      updatedAt
    }
    createdAt
    updatedAt
  }
}
    `;
export type CreateCompetitionForSeasonMutationFn = Apollo.MutationFunction<CreateCompetitionForSeasonMutation, CreateCompetitionForSeasonMutationVariables>;

/**
 * __useCreateCompetitionForSeasonMutation__
 *
 * To run a mutation, you first call `useCreateCompetitionForSeasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompetitionForSeasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompetitionForSeasonMutation, { data, loading, error }] = useCreateCompetitionForSeasonMutation({
 *   variables: {
 *      seasonId: // value for 'seasonId'
 *      homeTeamId: // value for 'homeTeamId'
 *      awayTeamId: // value for 'awayTeamId'
 *      startTime: // value for 'startTime'
 *      timeZone: // value for 'timeZone'
 *      status: // value for 'status'
 *      externalId: // value for 'externalId'
 *      location: // value for 'location'
 *      homeTeamScore: // value for 'homeTeamScore'
 *      homeTeamTieBreakerScore: // value for 'homeTeamTieBreakerScore'
 *      awayTeamScore: // value for 'awayTeamScore'
 *      awayTeamTieBreakerScore: // value for 'awayTeamTieBreakerScore'
 *   },
 * });
 */
export function useCreateCompetitionForSeasonMutation(baseOptions?: Apollo.MutationHookOptions<CreateCompetitionForSeasonMutation, CreateCompetitionForSeasonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCompetitionForSeasonMutation, CreateCompetitionForSeasonMutationVariables>(CreateCompetitionForSeasonDocument, options);
      }
export type CreateCompetitionForSeasonMutationHookResult = ReturnType<typeof useCreateCompetitionForSeasonMutation>;
export type CreateCompetitionForSeasonMutationResult = Apollo.MutationResult<CreateCompetitionForSeasonMutation>;
export type CreateCompetitionForSeasonMutationOptions = Apollo.BaseMutationOptions<CreateCompetitionForSeasonMutation, CreateCompetitionForSeasonMutationVariables>;
export const GetCompetitionByIdDocument = gql`
    query getCompetitionById($id: String!) {
  getCompetitionById(input: {id: $id}) {
    id
    externalId
    sport
    status
    startTime
    outcome
    verificationStatus
    timeZone
    location
    homeTeamScore
    homeTeamTieBreakerScore
    awayTeamScore
    awayTeamTieBreakerScore
    homeTeamName
    homeTeam {
      id
      name
      abbreviation
      sport
      city
      stateAbbreviation
      countryCode
      classification
      gender
      parentOrganizationName
      externalId
      ageGroup
      timeZone
      website
      logo
      createdAt
      updatedAt
    }
    awayTeamName
    awayTeam {
      id
      name
      abbreviation
      sport
      city
      stateAbbreviation
      countryCode
      classification
      gender
      parentOrganizationName
      externalId
      ageGroup
      timeZone
      website
      logo
      createdAt
      updatedAt
    }
    organization {
      id
      name
      sport
      status
      abbreviation
      site
      logo
      createdAt
      updatedAt
    }
    season {
      id
      leagueId
      leagueName
      leagueLogo
      name
      description
      status
      startDate
      endDate
      createdAt
      updatedAt
    }
    officials {
      id
      competitionId
      assessmentObjectId
      order
      isOptional
      official {
        id
        sport
        userId
        status
        firstName
        lastName
        profilePic
        createdAt
        updatedAt
      }
      type
      createdAt
      updatedAt
    }
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetCompetitionByIdQuery__
 *
 * To run a query within a React component, call `useGetCompetitionByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompetitionByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompetitionByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCompetitionByIdQuery(baseOptions: Apollo.QueryHookOptions<GetCompetitionByIdQuery, GetCompetitionByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompetitionByIdQuery, GetCompetitionByIdQueryVariables>(GetCompetitionByIdDocument, options);
      }
export function useGetCompetitionByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompetitionByIdQuery, GetCompetitionByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompetitionByIdQuery, GetCompetitionByIdQueryVariables>(GetCompetitionByIdDocument, options);
        }
export type GetCompetitionByIdQueryHookResult = ReturnType<typeof useGetCompetitionByIdQuery>;
export type GetCompetitionByIdLazyQueryHookResult = ReturnType<typeof useGetCompetitionByIdLazyQuery>;
export type GetCompetitionByIdQueryResult = Apollo.QueryResult<GetCompetitionByIdQuery, GetCompetitionByIdQueryVariables>;
export const ListCompetitionsForSeasonDocument = gql`
    query listCompetitionsForSeason($offset: Int!, $limit: Int!, $seasonId: String!, $dateFrom: String, $dateTo: String, $teamsSearch: String, $externalIdSearch: String, $statuses: [CompetitionStatuses!]) {
  listCompetitionsForSeason(
    input: {seasonId: $seasonId, dateFrom: $dateFrom, dateTo: $dateTo, teamsSearch: $teamsSearch, externalIdSearch: $externalIdSearch, statuses: $statuses, pagination: {offset: $offset, limit: $limit}}
  ) {
    competitions {
      id
      externalId
      sport
      status
      startTime
      outcome
      verificationStatus
      timeZone
      location
      homeTeamScore
      homeTeamTieBreakerScore
      awayTeamScore
      awayTeamTieBreakerScore
      homeTeam {
        id
        name
        abbreviation
        sport
        city
        stateAbbreviation
        countryCode
        classification
        gender
        parentOrganizationName
        externalId
        ageGroup
        timeZone
        website
        logo
        createdAt
        updatedAt
      }
      awayTeam {
        id
        name
        abbreviation
        sport
        city
        stateAbbreviation
        countryCode
        classification
        gender
        parentOrganizationName
        externalId
        ageGroup
        timeZone
        website
        logo
        createdAt
        updatedAt
      }
      organization {
        id
        name
        sport
        status
        abbreviation
        site
        logo
        createdAt
        updatedAt
      }
      season {
        id
        leagueId
        leagueName
        leagueLogo
        name
        description
        status
        startDate
        endDate
        createdAt
        updatedAt
      }
      officials {
        id
        competitionId
        assessmentObjectId
        order
        isOptional
        official {
          id
          sport
          userId
          status
          firstName
          lastName
          profilePic
          createdAt
          updatedAt
        }
        type
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListCompetitionsForSeasonQuery__
 *
 * To run a query within a React component, call `useListCompetitionsForSeasonQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCompetitionsForSeasonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCompetitionsForSeasonQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      seasonId: // value for 'seasonId'
 *      dateFrom: // value for 'dateFrom'
 *      dateTo: // value for 'dateTo'
 *      teamsSearch: // value for 'teamsSearch'
 *      externalIdSearch: // value for 'externalIdSearch'
 *      statuses: // value for 'statuses'
 *   },
 * });
 */
export function useListCompetitionsForSeasonQuery(baseOptions: Apollo.QueryHookOptions<ListCompetitionsForSeasonQuery, ListCompetitionsForSeasonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListCompetitionsForSeasonQuery, ListCompetitionsForSeasonQueryVariables>(ListCompetitionsForSeasonDocument, options);
      }
export function useListCompetitionsForSeasonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListCompetitionsForSeasonQuery, ListCompetitionsForSeasonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListCompetitionsForSeasonQuery, ListCompetitionsForSeasonQueryVariables>(ListCompetitionsForSeasonDocument, options);
        }
export type ListCompetitionsForSeasonQueryHookResult = ReturnType<typeof useListCompetitionsForSeasonQuery>;
export type ListCompetitionsForSeasonLazyQueryHookResult = ReturnType<typeof useListCompetitionsForSeasonLazyQuery>;
export type ListCompetitionsForSeasonQueryResult = Apollo.QueryResult<ListCompetitionsForSeasonQuery, ListCompetitionsForSeasonQueryVariables>;
export const ListCompetitionsForLeagueDocument = gql`
    query listCompetitionsForLeague($offset: Int!, $limit: Int!, $leagueId: String!, $seasonId: String, $dateFrom: String, $dateTo: String, $teamsSearch: String, $externalIdSearch: String, $statuses: [CompetitionStatuses!]) {
  listCompetitionsForLeague(
    input: {leagueId: $leagueId, seasonId: $seasonId, dateFrom: $dateFrom, dateTo: $dateTo, teamsSearch: $teamsSearch, externalIdSearch: $externalIdSearch, statuses: $statuses, pagination: {offset: $offset, limit: $limit}}
  ) {
    competitions {
      id
      externalId
      sport
      status
      startTime
      outcome
      verificationStatus
      timeZone
      location
      homeTeamScore
      homeTeamTieBreakerScore
      awayTeamScore
      awayTeamTieBreakerScore
      homeTeam {
        id
        name
        abbreviation
        sport
        city
        stateAbbreviation
        countryCode
        classification
        gender
        parentOrganizationName
        externalId
        ageGroup
        timeZone
        website
        logo
        createdAt
        updatedAt
      }
      awayTeam {
        id
        name
        abbreviation
        sport
        city
        stateAbbreviation
        countryCode
        classification
        gender
        parentOrganizationName
        externalId
        ageGroup
        timeZone
        website
        logo
        createdAt
        updatedAt
      }
      organization {
        id
        name
        sport
        status
        abbreviation
        site
        logo
        createdAt
        updatedAt
      }
      season {
        id
        leagueId
        leagueName
        leagueLogo
        name
        description
        status
        startDate
        endDate
        createdAt
        updatedAt
      }
      officials {
        id
        competitionId
        assessmentObjectId
        order
        isOptional
        official {
          id
          sport
          userId
          status
          firstName
          lastName
          profilePic
          createdAt
          updatedAt
        }
        type
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListCompetitionsForLeagueQuery__
 *
 * To run a query within a React component, call `useListCompetitionsForLeagueQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCompetitionsForLeagueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCompetitionsForLeagueQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      leagueId: // value for 'leagueId'
 *      seasonId: // value for 'seasonId'
 *      dateFrom: // value for 'dateFrom'
 *      dateTo: // value for 'dateTo'
 *      teamsSearch: // value for 'teamsSearch'
 *      externalIdSearch: // value for 'externalIdSearch'
 *      statuses: // value for 'statuses'
 *   },
 * });
 */
export function useListCompetitionsForLeagueQuery(baseOptions: Apollo.QueryHookOptions<ListCompetitionsForLeagueQuery, ListCompetitionsForLeagueQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListCompetitionsForLeagueQuery, ListCompetitionsForLeagueQueryVariables>(ListCompetitionsForLeagueDocument, options);
      }
export function useListCompetitionsForLeagueLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListCompetitionsForLeagueQuery, ListCompetitionsForLeagueQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListCompetitionsForLeagueQuery, ListCompetitionsForLeagueQueryVariables>(ListCompetitionsForLeagueDocument, options);
        }
export type ListCompetitionsForLeagueQueryHookResult = ReturnType<typeof useListCompetitionsForLeagueQuery>;
export type ListCompetitionsForLeagueLazyQueryHookResult = ReturnType<typeof useListCompetitionsForLeagueLazyQuery>;
export type ListCompetitionsForLeagueQueryResult = Apollo.QueryResult<ListCompetitionsForLeagueQuery, ListCompetitionsForLeagueQueryVariables>;
export const ActivateCompetitionDocument = gql`
    mutation activateCompetition($competitionId: String!) {
  activateCompetition(input: {competitionId: $competitionId}) {
    id
    externalId
    sport
    status
    startTime
    outcome
    verificationStatus
    timeZone
    location
    homeTeamScore
    homeTeamTieBreakerScore
    awayTeamScore
    awayTeamTieBreakerScore
    homeTeam {
      id
      name
      abbreviation
      sport
      city
      stateAbbreviation
      countryCode
      classification
      gender
      parentOrganizationName
      externalId
      ageGroup
      timeZone
      website
      logo
      createdAt
      updatedAt
    }
    awayTeam {
      id
      name
      abbreviation
      sport
      city
      stateAbbreviation
      countryCode
      classification
      gender
      parentOrganizationName
      externalId
      ageGroup
      timeZone
      website
      logo
      createdAt
      updatedAt
    }
    organization {
      id
      name
      sport
      status
      abbreviation
      site
      logo
      createdAt
      updatedAt
    }
    season {
      id
      leagueId
      leagueName
      leagueLogo
      name
      description
      status
      startDate
      endDate
      createdAt
      updatedAt
    }
    officials {
      id
      competitionId
      assessmentObjectId
      order
      isOptional
      official {
        id
        sport
        userId
        status
        firstName
        lastName
        profilePic
        createdAt
        updatedAt
      }
      type
      createdAt
      updatedAt
    }
    createdAt
    updatedAt
  }
}
    `;
export type ActivateCompetitionMutationFn = Apollo.MutationFunction<ActivateCompetitionMutation, ActivateCompetitionMutationVariables>;

/**
 * __useActivateCompetitionMutation__
 *
 * To run a mutation, you first call `useActivateCompetitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateCompetitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateCompetitionMutation, { data, loading, error }] = useActivateCompetitionMutation({
 *   variables: {
 *      competitionId: // value for 'competitionId'
 *   },
 * });
 */
export function useActivateCompetitionMutation(baseOptions?: Apollo.MutationHookOptions<ActivateCompetitionMutation, ActivateCompetitionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateCompetitionMutation, ActivateCompetitionMutationVariables>(ActivateCompetitionDocument, options);
      }
export type ActivateCompetitionMutationHookResult = ReturnType<typeof useActivateCompetitionMutation>;
export type ActivateCompetitionMutationResult = Apollo.MutationResult<ActivateCompetitionMutation>;
export type ActivateCompetitionMutationOptions = Apollo.BaseMutationOptions<ActivateCompetitionMutation, ActivateCompetitionMutationVariables>;
export const DeleteCompetitionDocument = gql`
    mutation deleteCompetition($id: String!) {
  deleteCompetition(input: {id: $id})
}
    `;
export type DeleteCompetitionMutationFn = Apollo.MutationFunction<DeleteCompetitionMutation, DeleteCompetitionMutationVariables>;

/**
 * __useDeleteCompetitionMutation__
 *
 * To run a mutation, you first call `useDeleteCompetitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompetitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompetitionMutation, { data, loading, error }] = useDeleteCompetitionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCompetitionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCompetitionMutation, DeleteCompetitionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCompetitionMutation, DeleteCompetitionMutationVariables>(DeleteCompetitionDocument, options);
      }
export type DeleteCompetitionMutationHookResult = ReturnType<typeof useDeleteCompetitionMutation>;
export type DeleteCompetitionMutationResult = Apollo.MutationResult<DeleteCompetitionMutation>;
export type DeleteCompetitionMutationOptions = Apollo.BaseMutationOptions<DeleteCompetitionMutation, DeleteCompetitionMutationVariables>;
export const UpdateCompetitionDocument = gql`
    mutation updateCompetition($competitionId: String!, $seasonId: String!, $homeTeamId: String!, $awayTeamId: String!, $startTime: Date!, $timeZone: String!, $externalId: String, $location: String, $homeTeamScore: Int, $homeTeamTieBreakerScore: Int, $awayTeamScore: Int, $awayTeamTieBreakerScore: Int) {
  updateCompetition(
    input: {competitionId: $competitionId, seasonId: $seasonId, homeTeamId: $homeTeamId, awayTeamId: $awayTeamId, startTime: $startTime, timeZone: $timeZone, externalId: $externalId, location: $location, homeTeamScore: $homeTeamScore, homeTeamTieBreakerScore: $homeTeamTieBreakerScore, awayTeamScore: $awayTeamScore, awayTeamTieBreakerScore: $awayTeamTieBreakerScore}
  ) {
    id
    externalId
    sport
    status
    startTime
    outcome
    verificationStatus
    timeZone
    location
    homeTeamScore
    homeTeamTieBreakerScore
    awayTeamScore
    awayTeamTieBreakerScore
    homeTeam {
      id
      name
      abbreviation
      sport
      city
      stateAbbreviation
      countryCode
      classification
      gender
      parentOrganizationName
      externalId
      ageGroup
      timeZone
      website
      logo
      createdAt
      updatedAt
    }
    awayTeam {
      id
      name
      abbreviation
      sport
      city
      stateAbbreviation
      countryCode
      classification
      gender
      parentOrganizationName
      externalId
      ageGroup
      timeZone
      website
      logo
      createdAt
      updatedAt
    }
    organization {
      id
      name
      sport
      status
      abbreviation
      site
      logo
      createdAt
      updatedAt
    }
    season {
      id
      leagueId
      leagueName
      leagueLogo
      name
      description
      status
      startDate
      endDate
      createdAt
      updatedAt
    }
    officials {
      id
      competitionId
      assessmentObjectId
      order
      isOptional
      official {
        id
        sport
        userId
        status
        firstName
        lastName
        profilePic
        createdAt
        updatedAt
      }
      type
      createdAt
      updatedAt
    }
    createdAt
    updatedAt
  }
}
    `;
export type UpdateCompetitionMutationFn = Apollo.MutationFunction<UpdateCompetitionMutation, UpdateCompetitionMutationVariables>;

/**
 * __useUpdateCompetitionMutation__
 *
 * To run a mutation, you first call `useUpdateCompetitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompetitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompetitionMutation, { data, loading, error }] = useUpdateCompetitionMutation({
 *   variables: {
 *      competitionId: // value for 'competitionId'
 *      seasonId: // value for 'seasonId'
 *      homeTeamId: // value for 'homeTeamId'
 *      awayTeamId: // value for 'awayTeamId'
 *      startTime: // value for 'startTime'
 *      timeZone: // value for 'timeZone'
 *      externalId: // value for 'externalId'
 *      location: // value for 'location'
 *      homeTeamScore: // value for 'homeTeamScore'
 *      homeTeamTieBreakerScore: // value for 'homeTeamTieBreakerScore'
 *      awayTeamScore: // value for 'awayTeamScore'
 *      awayTeamTieBreakerScore: // value for 'awayTeamTieBreakerScore'
 *   },
 * });
 */
export function useUpdateCompetitionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompetitionMutation, UpdateCompetitionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompetitionMutation, UpdateCompetitionMutationVariables>(UpdateCompetitionDocument, options);
      }
export type UpdateCompetitionMutationHookResult = ReturnType<typeof useUpdateCompetitionMutation>;
export type UpdateCompetitionMutationResult = Apollo.MutationResult<UpdateCompetitionMutation>;
export type UpdateCompetitionMutationOptions = Apollo.BaseMutationOptions<UpdateCompetitionMutation, UpdateCompetitionMutationVariables>;
export const UpdateCompetitionScoreDocument = gql`
    mutation updateCompetitionScore($competitionId: String!, $homeTeamScore: Int, $homeTeamTieBreakerScore: Int, $awayTeamScore: Int, $awayTeamTieBreakerScore: Int) {
  updateCompetitionScore(
    input: {competitionId: $competitionId, homeTeamScore: $homeTeamScore, homeTeamTieBreakerScore: $homeTeamTieBreakerScore, awayTeamScore: $awayTeamScore, awayTeamTieBreakerScore: $awayTeamTieBreakerScore}
  ) {
    id
    externalId
    sport
    status
    startTime
    outcome
    verificationStatus
    timeZone
    location
    homeTeamScore
    homeTeamTieBreakerScore
    awayTeamScore
    awayTeamTieBreakerScore
    homeTeam {
      id
      name
      abbreviation
      sport
      city
      stateAbbreviation
      countryCode
      classification
      gender
      parentOrganizationName
      externalId
      ageGroup
      timeZone
      website
      logo
      createdAt
      updatedAt
    }
    awayTeam {
      id
      name
      abbreviation
      sport
      city
      stateAbbreviation
      countryCode
      classification
      gender
      parentOrganizationName
      externalId
      ageGroup
      timeZone
      website
      logo
      createdAt
      updatedAt
    }
    organization {
      id
      name
      sport
      status
      abbreviation
      site
      logo
      createdAt
      updatedAt
    }
    season {
      id
      leagueId
      leagueName
      leagueLogo
      name
      description
      status
      startDate
      endDate
      createdAt
      updatedAt
    }
    officials {
      id
      competitionId
      assessmentObjectId
      order
      isOptional
      official {
        id
        sport
        userId
        status
        firstName
        lastName
        profilePic
        createdAt
        updatedAt
      }
      type
      createdAt
      updatedAt
    }
    createdAt
    updatedAt
  }
}
    `;
export type UpdateCompetitionScoreMutationFn = Apollo.MutationFunction<UpdateCompetitionScoreMutation, UpdateCompetitionScoreMutationVariables>;

/**
 * __useUpdateCompetitionScoreMutation__
 *
 * To run a mutation, you first call `useUpdateCompetitionScoreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompetitionScoreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompetitionScoreMutation, { data, loading, error }] = useUpdateCompetitionScoreMutation({
 *   variables: {
 *      competitionId: // value for 'competitionId'
 *      homeTeamScore: // value for 'homeTeamScore'
 *      homeTeamTieBreakerScore: // value for 'homeTeamTieBreakerScore'
 *      awayTeamScore: // value for 'awayTeamScore'
 *      awayTeamTieBreakerScore: // value for 'awayTeamTieBreakerScore'
 *   },
 * });
 */
export function useUpdateCompetitionScoreMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompetitionScoreMutation, UpdateCompetitionScoreMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompetitionScoreMutation, UpdateCompetitionScoreMutationVariables>(UpdateCompetitionScoreDocument, options);
      }
export type UpdateCompetitionScoreMutationHookResult = ReturnType<typeof useUpdateCompetitionScoreMutation>;
export type UpdateCompetitionScoreMutationResult = Apollo.MutationResult<UpdateCompetitionScoreMutation>;
export type UpdateCompetitionScoreMutationOptions = Apollo.BaseMutationOptions<UpdateCompetitionScoreMutation, UpdateCompetitionScoreMutationVariables>;
export const AddOfficialToCompetitionDocument = gql`
    mutation addOfficialToCompetition($competitionId: String!, $officialId: String!, $assessmentObjectId: String!) {
  addOfficialToCompetition(
    input: {competitionId: $competitionId, officialId: $officialId, assessmentObjectId: $assessmentObjectId}
  ) {
    id
    competitionId
    assessmentObjectId
    type
    order
    isOptional
    official {
      id
      sport
      userId
      status
      firstName
      lastName
      profilePic
      createdAt
      updatedAt
    }
    createdAt
    updatedAt
  }
}
    `;
export type AddOfficialToCompetitionMutationFn = Apollo.MutationFunction<AddOfficialToCompetitionMutation, AddOfficialToCompetitionMutationVariables>;

/**
 * __useAddOfficialToCompetitionMutation__
 *
 * To run a mutation, you first call `useAddOfficialToCompetitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOfficialToCompetitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOfficialToCompetitionMutation, { data, loading, error }] = useAddOfficialToCompetitionMutation({
 *   variables: {
 *      competitionId: // value for 'competitionId'
 *      officialId: // value for 'officialId'
 *      assessmentObjectId: // value for 'assessmentObjectId'
 *   },
 * });
 */
export function useAddOfficialToCompetitionMutation(baseOptions?: Apollo.MutationHookOptions<AddOfficialToCompetitionMutation, AddOfficialToCompetitionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddOfficialToCompetitionMutation, AddOfficialToCompetitionMutationVariables>(AddOfficialToCompetitionDocument, options);
      }
export type AddOfficialToCompetitionMutationHookResult = ReturnType<typeof useAddOfficialToCompetitionMutation>;
export type AddOfficialToCompetitionMutationResult = Apollo.MutationResult<AddOfficialToCompetitionMutation>;
export type AddOfficialToCompetitionMutationOptions = Apollo.BaseMutationOptions<AddOfficialToCompetitionMutation, AddOfficialToCompetitionMutationVariables>;
export const ListOfficialsForCompetitionDocument = gql`
    query listOfficialsForCompetition($offset: Int!, $limit: Int!, $competitionId: String!, $nameOrEmail: String, $status: OfficialStatuses, $type: String) {
  listOfficialsForCompetition(
    input: {competitionId: $competitionId, nameOrEmail: $nameOrEmail, status: $status, type: $type, pagination: {offset: $offset, limit: $limit}}
  ) {
    competitionId
    competitionOfficials {
      id
      competitionId
      assessmentObjectId
      type
      order
      isOptional
      official {
        id
        sport
        userId
        status
        firstName
        lastName
        profilePic
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListOfficialsForCompetitionQuery__
 *
 * To run a query within a React component, call `useListOfficialsForCompetitionQuery` and pass it any options that fit your needs.
 * When your component renders, `useListOfficialsForCompetitionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListOfficialsForCompetitionQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      competitionId: // value for 'competitionId'
 *      nameOrEmail: // value for 'nameOrEmail'
 *      status: // value for 'status'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useListOfficialsForCompetitionQuery(baseOptions: Apollo.QueryHookOptions<ListOfficialsForCompetitionQuery, ListOfficialsForCompetitionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListOfficialsForCompetitionQuery, ListOfficialsForCompetitionQueryVariables>(ListOfficialsForCompetitionDocument, options);
      }
export function useListOfficialsForCompetitionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListOfficialsForCompetitionQuery, ListOfficialsForCompetitionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListOfficialsForCompetitionQuery, ListOfficialsForCompetitionQueryVariables>(ListOfficialsForCompetitionDocument, options);
        }
export type ListOfficialsForCompetitionQueryHookResult = ReturnType<typeof useListOfficialsForCompetitionQuery>;
export type ListOfficialsForCompetitionLazyQueryHookResult = ReturnType<typeof useListOfficialsForCompetitionLazyQuery>;
export type ListOfficialsForCompetitionQueryResult = Apollo.QueryResult<ListOfficialsForCompetitionQuery, ListOfficialsForCompetitionQueryVariables>;
export const RemoveOfficialFromCompetitionDocument = gql`
    mutation removeOfficialFromCompetition($competitionId: String!, $officialId: String!) {
  removeOfficialFromCompetition(
    input: {competitionId: $competitionId, officialId: $officialId}
  )
}
    `;
export type RemoveOfficialFromCompetitionMutationFn = Apollo.MutationFunction<RemoveOfficialFromCompetitionMutation, RemoveOfficialFromCompetitionMutationVariables>;

/**
 * __useRemoveOfficialFromCompetitionMutation__
 *
 * To run a mutation, you first call `useRemoveOfficialFromCompetitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOfficialFromCompetitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeOfficialFromCompetitionMutation, { data, loading, error }] = useRemoveOfficialFromCompetitionMutation({
 *   variables: {
 *      competitionId: // value for 'competitionId'
 *      officialId: // value for 'officialId'
 *   },
 * });
 */
export function useRemoveOfficialFromCompetitionMutation(baseOptions?: Apollo.MutationHookOptions<RemoveOfficialFromCompetitionMutation, RemoveOfficialFromCompetitionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveOfficialFromCompetitionMutation, RemoveOfficialFromCompetitionMutationVariables>(RemoveOfficialFromCompetitionDocument, options);
      }
export type RemoveOfficialFromCompetitionMutationHookResult = ReturnType<typeof useRemoveOfficialFromCompetitionMutation>;
export type RemoveOfficialFromCompetitionMutationResult = Apollo.MutationResult<RemoveOfficialFromCompetitionMutation>;
export type RemoveOfficialFromCompetitionMutationOptions = Apollo.BaseMutationOptions<RemoveOfficialFromCompetitionMutation, RemoveOfficialFromCompetitionMutationVariables>;
export const UpdateCompetitionOfficialDocument = gql`
    mutation updateCompetitionOfficial($competitionOfficialId: String!, $officialId: String!) {
  updateCompetitionOfficial(
    input: {competitionOfficialId: $competitionOfficialId, officialId: $officialId}
  ) {
    id
    competitionId
    assessmentObjectId
    type
    order
    isOptional
    official {
      id
      sport
      userId
      status
      email
      firstName
      lastName
      profilePic
      createdAt
      updatedAt
    }
    createdAt
    updatedAt
  }
}
    `;
export type UpdateCompetitionOfficialMutationFn = Apollo.MutationFunction<UpdateCompetitionOfficialMutation, UpdateCompetitionOfficialMutationVariables>;

/**
 * __useUpdateCompetitionOfficialMutation__
 *
 * To run a mutation, you first call `useUpdateCompetitionOfficialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompetitionOfficialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompetitionOfficialMutation, { data, loading, error }] = useUpdateCompetitionOfficialMutation({
 *   variables: {
 *      competitionOfficialId: // value for 'competitionOfficialId'
 *      officialId: // value for 'officialId'
 *   },
 * });
 */
export function useUpdateCompetitionOfficialMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompetitionOfficialMutation, UpdateCompetitionOfficialMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompetitionOfficialMutation, UpdateCompetitionOfficialMutationVariables>(UpdateCompetitionOfficialDocument, options);
      }
export type UpdateCompetitionOfficialMutationHookResult = ReturnType<typeof useUpdateCompetitionOfficialMutation>;
export type UpdateCompetitionOfficialMutationResult = Apollo.MutationResult<UpdateCompetitionOfficialMutation>;
export type UpdateCompetitionOfficialMutationOptions = Apollo.BaseMutationOptions<UpdateCompetitionOfficialMutation, UpdateCompetitionOfficialMutationVariables>;
export const ListCompetitionSummariesForOrgDocument = gql`
    query listCompetitionSummariesForOrg($offset: Int!, $limit: Int!, $orgId: String!, $includeNoResponses: Boolean!, $leagueId: String, $seasonId: String, $dateFrom: String, $dateTo: String, $externalIdSearch: String, $teamsSearch: String, $leagueSearch: String, $seasonSearch: String) {
  listCompetitionSummariesForOrg(
    input: {orgId: $orgId, includeNoResponses: $includeNoResponses, leagueId: $leagueId, seasonId: $seasonId, dateFrom: $dateFrom, dateTo: $dateTo, externalIdSearch: $externalIdSearch, teamsSearch: $teamsSearch, leagueSearch: $leagueSearch, seasonSearch: $seasonSearch, pagination: {offset: $offset, limit: $limit}}
  ) {
    competitionSummaries {
      leagueName
      seasonName
      competitionId
      competitionStartTime
      competitionTimeZone
      competitionLocation
      competitionExternalId
      competitionOutcome
      competitionVerificationStatus
      homeTeamId
      homeTeamName
      homeTeamAbbreviation
      homeTeamLogo
      homeTeamScore
      homeTeamTieBreakerScore
      awayTeamId
      awayTeamName
      awayTeamAbbreviation
      awayTeamLogo
      awayTeamScore
      awayTeamTieBreakerScore
      hasSeasonTeamsAssessment
      hasSeasonOfficialsAssessment
      hasSeasonOfficialsDynamicSurvey
      hasSeasonOfficialsDynamicSurveyAssessment
      hasSeasonTeamKit
      homeTeamAssessmentCreated
      awayTeamAssessmentCreated
      officialsAssessmentCreated
      officialsDynamicSurveyCreated
      teamKitCreated
      awayTeamSubmitted
      homeTeamSubmitted
      officialsAssessmentSubmitted
      officialsDynamicSurveyAssessmentSubmitted
      officialsDynamicSurveySubmitted
      totalKeyIncidents
      totalOpenNormalKeyIncidents
      totalOpenKeyIncidentsImpactingGameOutcome
      totalResolvedKeyIncidents
      totalSupplementalReportCount
      totalOpenSupplementalReportCount
      totalApprovedSupplementalReportCount
      officialsByHomeTeamAverageCompetitionScore
      homeTeamByOfficialsAverageCompetitionScore
      officialsByAwayTeamAverageCompetitionScore
      awayTeamByOfficialsAverageCompetitionScore
      officialDynamicSurveyId
      officialDynamicSurveyName
      competitionTeamKitId
      competitionTeamKitStatus
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListCompetitionSummariesForOrgQuery__
 *
 * To run a query within a React component, call `useListCompetitionSummariesForOrgQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCompetitionSummariesForOrgQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCompetitionSummariesForOrgQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      orgId: // value for 'orgId'
 *      includeNoResponses: // value for 'includeNoResponses'
 *      leagueId: // value for 'leagueId'
 *      seasonId: // value for 'seasonId'
 *      dateFrom: // value for 'dateFrom'
 *      dateTo: // value for 'dateTo'
 *      externalIdSearch: // value for 'externalIdSearch'
 *      teamsSearch: // value for 'teamsSearch'
 *      leagueSearch: // value for 'leagueSearch'
 *      seasonSearch: // value for 'seasonSearch'
 *   },
 * });
 */
export function useListCompetitionSummariesForOrgQuery(baseOptions: Apollo.QueryHookOptions<ListCompetitionSummariesForOrgQuery, ListCompetitionSummariesForOrgQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListCompetitionSummariesForOrgQuery, ListCompetitionSummariesForOrgQueryVariables>(ListCompetitionSummariesForOrgDocument, options);
      }
export function useListCompetitionSummariesForOrgLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListCompetitionSummariesForOrgQuery, ListCompetitionSummariesForOrgQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListCompetitionSummariesForOrgQuery, ListCompetitionSummariesForOrgQueryVariables>(ListCompetitionSummariesForOrgDocument, options);
        }
export type ListCompetitionSummariesForOrgQueryHookResult = ReturnType<typeof useListCompetitionSummariesForOrgQuery>;
export type ListCompetitionSummariesForOrgLazyQueryHookResult = ReturnType<typeof useListCompetitionSummariesForOrgLazyQuery>;
export type ListCompetitionSummariesForOrgQueryResult = Apollo.QueryResult<ListCompetitionSummariesForOrgQuery, ListCompetitionSummariesForOrgQueryVariables>;
export const ListAllSurveyInfoForCompetitionForOrgDocument = gql`
    query listAllSurveyInfoForCompetitionForOrg($orgId: String!, $competitionId: String!) {
  listAllSurveyInfoForCompetitionForOrg(
    input: {orgId: $orgId, competitionId: $competitionId}
  ) {
    competitionSurveyId
    surveyId
    surveyName
    competitionId
    audience
    isDynamic
  }
}
    `;

/**
 * __useListAllSurveyInfoForCompetitionForOrgQuery__
 *
 * To run a query within a React component, call `useListAllSurveyInfoForCompetitionForOrgQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAllSurveyInfoForCompetitionForOrgQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAllSurveyInfoForCompetitionForOrgQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      competitionId: // value for 'competitionId'
 *   },
 * });
 */
export function useListAllSurveyInfoForCompetitionForOrgQuery(baseOptions: Apollo.QueryHookOptions<ListAllSurveyInfoForCompetitionForOrgQuery, ListAllSurveyInfoForCompetitionForOrgQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAllSurveyInfoForCompetitionForOrgQuery, ListAllSurveyInfoForCompetitionForOrgQueryVariables>(ListAllSurveyInfoForCompetitionForOrgDocument, options);
      }
export function useListAllSurveyInfoForCompetitionForOrgLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAllSurveyInfoForCompetitionForOrgQuery, ListAllSurveyInfoForCompetitionForOrgQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAllSurveyInfoForCompetitionForOrgQuery, ListAllSurveyInfoForCompetitionForOrgQueryVariables>(ListAllSurveyInfoForCompetitionForOrgDocument, options);
        }
export type ListAllSurveyInfoForCompetitionForOrgQueryHookResult = ReturnType<typeof useListAllSurveyInfoForCompetitionForOrgQuery>;
export type ListAllSurveyInfoForCompetitionForOrgLazyQueryHookResult = ReturnType<typeof useListAllSurveyInfoForCompetitionForOrgLazyQuery>;
export type ListAllSurveyInfoForCompetitionForOrgQueryResult = Apollo.QueryResult<ListAllSurveyInfoForCompetitionForOrgQuery, ListAllSurveyInfoForCompetitionForOrgQueryVariables>;
export const ListAvailablePeriodsForCompetitionDocument = gql`
    query listAvailablePeriodsForCompetition($offset: Int!, $limit: Int!, $competitionId: String!) {
  listAvailablePeriodsForCompetition(
    input: {competitionId: $competitionId, pagination: {offset: $offset, limit: $limit}}
  ) {
    periods {
      id
      name
      sport
      createdAt
      updatedAt
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListAvailablePeriodsForCompetitionQuery__
 *
 * To run a query within a React component, call `useListAvailablePeriodsForCompetitionQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAvailablePeriodsForCompetitionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAvailablePeriodsForCompetitionQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      competitionId: // value for 'competitionId'
 *   },
 * });
 */
export function useListAvailablePeriodsForCompetitionQuery(baseOptions: Apollo.QueryHookOptions<ListAvailablePeriodsForCompetitionQuery, ListAvailablePeriodsForCompetitionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAvailablePeriodsForCompetitionQuery, ListAvailablePeriodsForCompetitionQueryVariables>(ListAvailablePeriodsForCompetitionDocument, options);
      }
export function useListAvailablePeriodsForCompetitionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAvailablePeriodsForCompetitionQuery, ListAvailablePeriodsForCompetitionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAvailablePeriodsForCompetitionQuery, ListAvailablePeriodsForCompetitionQueryVariables>(ListAvailablePeriodsForCompetitionDocument, options);
        }
export type ListAvailablePeriodsForCompetitionQueryHookResult = ReturnType<typeof useListAvailablePeriodsForCompetitionQuery>;
export type ListAvailablePeriodsForCompetitionLazyQueryHookResult = ReturnType<typeof useListAvailablePeriodsForCompetitionLazyQuery>;
export type ListAvailablePeriodsForCompetitionQueryResult = Apollo.QueryResult<ListAvailablePeriodsForCompetitionQuery, ListAvailablePeriodsForCompetitionQueryVariables>;
export const ListAvailablePeriodsForCompetitionWithTokenDocument = gql`
    query listAvailablePeriodsForCompetitionWithToken($offset: Int!, $limit: Int!, $competitionId: String!, $surveyToken: String!) {
  listAvailablePeriodsForCompetitionWithToken(
    input: {surveyToken: $surveyToken, competitionId: $competitionId, pagination: {offset: $offset, limit: $limit}}
  ) {
    periods {
      id
      name
      sport
      createdAt
      updatedAt
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListAvailablePeriodsForCompetitionWithTokenQuery__
 *
 * To run a query within a React component, call `useListAvailablePeriodsForCompetitionWithTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAvailablePeriodsForCompetitionWithTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAvailablePeriodsForCompetitionWithTokenQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      competitionId: // value for 'competitionId'
 *      surveyToken: // value for 'surveyToken'
 *   },
 * });
 */
export function useListAvailablePeriodsForCompetitionWithTokenQuery(baseOptions: Apollo.QueryHookOptions<ListAvailablePeriodsForCompetitionWithTokenQuery, ListAvailablePeriodsForCompetitionWithTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAvailablePeriodsForCompetitionWithTokenQuery, ListAvailablePeriodsForCompetitionWithTokenQueryVariables>(ListAvailablePeriodsForCompetitionWithTokenDocument, options);
      }
export function useListAvailablePeriodsForCompetitionWithTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAvailablePeriodsForCompetitionWithTokenQuery, ListAvailablePeriodsForCompetitionWithTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAvailablePeriodsForCompetitionWithTokenQuery, ListAvailablePeriodsForCompetitionWithTokenQueryVariables>(ListAvailablePeriodsForCompetitionWithTokenDocument, options);
        }
export type ListAvailablePeriodsForCompetitionWithTokenQueryHookResult = ReturnType<typeof useListAvailablePeriodsForCompetitionWithTokenQuery>;
export type ListAvailablePeriodsForCompetitionWithTokenLazyQueryHookResult = ReturnType<typeof useListAvailablePeriodsForCompetitionWithTokenLazyQuery>;
export type ListAvailablePeriodsForCompetitionWithTokenQueryResult = Apollo.QueryResult<ListAvailablePeriodsForCompetitionWithTokenQuery, ListAvailablePeriodsForCompetitionWithTokenQueryVariables>;
export const GetDynamicCompetitionSurveyByIdDocument = gql`
    query getDynamicCompetitionSurveyById($competitionSurveyId: String!) {
  getDynamicCompetitionSurveyById(
    input: {competitionSurveyId: $competitionSurveyId}
  ) {
    id
    surveyId
    orgId
    seasonId
    sport
    finished
    completedDate
    signature {
      competitionSurveyId
      signedDate
      userId
      email
      firstName
      lastName
      profilePic
    }
    hasSupplementalReport
    allowAdditionalSupplementalReports
    allowAdditionalSupplementalReportsCutoffDays
    hasCompetitionVerification
    hasIncidentReport
    allowAdditionalIncidentReports
    allowAdditionalIncidentReportsCutoffDays
    hasSignatureSection
    signatureText
    createdAt
    updatedAt
    recipients {
      userId
      email
      firstName
      lastName
      profilePic
      tookSurvey
    }
    competition {
      id
      externalId
      sport
      startTime
      timeZone
      location
      homeTeamScore
      homeTeamTieBreakerScore
      awayTeamScore
      awayTeamTieBreakerScore
      homeTeam {
        id
        name
        abbreviation
        sport
        city
        stateAbbreviation
        countryCode
        classification
        gender
        parentOrganizationName
        externalId
        ageGroup
        timeZone
        website
        logo
        createdAt
        updatedAt
      }
      awayTeam {
        id
        name
        abbreviation
        sport
        city
        stateAbbreviation
        countryCode
        classification
        gender
        parentOrganizationName
        externalId
        ageGroup
        timeZone
        website
        logo
        createdAt
        updatedAt
      }
      organization {
        id
        name
        sport
        status
        abbreviation
        site
        logo
        createdAt
        updatedAt
      }
      season {
        id
        leagueId
        leagueName
        leagueLogo
        name
        description
        status
        startDate
        endDate
        createdAt
        updatedAt
      }
      officials {
        id
        competitionId
        assessmentObjectId
        order
        official {
          id
          sport
          userId
          status
          email
          firstName
          lastName
          profilePic
          createdAt
          updatedAt
        }
        type
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
    info {
      name
      description
      audience
    }
    sections {
      id
      name
      description
      finished
      order
      createdAt
      updatedAt
      requireSupplementalReport
      questions {
        id
        name
        description
        additionalText1
        additionalText2
        additionalText3
        order
        isRequired
        canAddToKeyIncidents
        assessmentObject {
          id
          sport
          type
          classification
          name
        }
        coach {
          id
          teamId
          teamName
          teamAbbreviation
          sport
          teamCity
          userId
          type
          role
          status
          email
          firstName
          lastName
          profilePic
          createdAt
          updatedAt
        }
        team {
          id
          name
          abbreviation
          sport
          city
          stateAbbreviation
          countryCode
          timeZone
          classification
          gender
          website
          logo
          parentOrganizationName
          externalId
          ageGroup
          createdAt
          updatedAt
        }
        official {
          id
          sport
          userId
          status
          email
          firstName
          lastName
          externalIds {
            id
            organizationId
            externalId
          }
          profilePic
          createdAt
          updatedAt
        }
        organization {
          id
          name
          sport
          status
          abbreviation
          site
          logo
          createdAt
          updatedAt
        }
        questionType
        selectedOption
        selectedOptionOrder
        responseShortAnswer
        responseText
        responseNumber
        responseBoolean
        responses {
          id
          value
          createdAt
          updatedAt
        }
        fileUploads {
          id
          name
          comment
          fileUpload
          createdAt
          updatedAt
        }
        options {
          id
          value
          order
        }
        subQuestions {
          id
          name
          questionText
          order
          isRequired
          canAddToKeyIncidents
          questionType
          selectedOption
          selectedOptionOrder
          responseShortAnswer
          responseText
          responseNumber
          responseBoolean
          responses {
            id
            value
            createdAt
            updatedAt
          }
          fileUploads {
            id
            name
            comment
            fileUpload
            createdAt
            updatedAt
          }
          options {
            id
            value
            order
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetDynamicCompetitionSurveyByIdQuery__
 *
 * To run a query within a React component, call `useGetDynamicCompetitionSurveyByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDynamicCompetitionSurveyByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDynamicCompetitionSurveyByIdQuery({
 *   variables: {
 *      competitionSurveyId: // value for 'competitionSurveyId'
 *   },
 * });
 */
export function useGetDynamicCompetitionSurveyByIdQuery(baseOptions: Apollo.QueryHookOptions<GetDynamicCompetitionSurveyByIdQuery, GetDynamicCompetitionSurveyByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDynamicCompetitionSurveyByIdQuery, GetDynamicCompetitionSurveyByIdQueryVariables>(GetDynamicCompetitionSurveyByIdDocument, options);
      }
export function useGetDynamicCompetitionSurveyByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDynamicCompetitionSurveyByIdQuery, GetDynamicCompetitionSurveyByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDynamicCompetitionSurveyByIdQuery, GetDynamicCompetitionSurveyByIdQueryVariables>(GetDynamicCompetitionSurveyByIdDocument, options);
        }
export type GetDynamicCompetitionSurveyByIdQueryHookResult = ReturnType<typeof useGetDynamicCompetitionSurveyByIdQuery>;
export type GetDynamicCompetitionSurveyByIdLazyQueryHookResult = ReturnType<typeof useGetDynamicCompetitionSurveyByIdLazyQuery>;
export type GetDynamicCompetitionSurveyByIdQueryResult = Apollo.QueryResult<GetDynamicCompetitionSurveyByIdQuery, GetDynamicCompetitionSurveyByIdQueryVariables>;
export const ListStatsForDynamicCompetitionSurveyByIdDocument = gql`
    query listStatsForDynamicCompetitionSurveyById($competitionSurveyId: String!) {
  listStatsForDynamicCompetitionSurveyById(
    input: {competitionSurveyId: $competitionSurveyId}
  ) {
    id
    competitionId
    teamId
    name
    displayText
    valueString
    valueNumeric
    valueDate
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useListStatsForDynamicCompetitionSurveyByIdQuery__
 *
 * To run a query within a React component, call `useListStatsForDynamicCompetitionSurveyByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useListStatsForDynamicCompetitionSurveyByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListStatsForDynamicCompetitionSurveyByIdQuery({
 *   variables: {
 *      competitionSurveyId: // value for 'competitionSurveyId'
 *   },
 * });
 */
export function useListStatsForDynamicCompetitionSurveyByIdQuery(baseOptions: Apollo.QueryHookOptions<ListStatsForDynamicCompetitionSurveyByIdQuery, ListStatsForDynamicCompetitionSurveyByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListStatsForDynamicCompetitionSurveyByIdQuery, ListStatsForDynamicCompetitionSurveyByIdQueryVariables>(ListStatsForDynamicCompetitionSurveyByIdDocument, options);
      }
export function useListStatsForDynamicCompetitionSurveyByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListStatsForDynamicCompetitionSurveyByIdQuery, ListStatsForDynamicCompetitionSurveyByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListStatsForDynamicCompetitionSurveyByIdQuery, ListStatsForDynamicCompetitionSurveyByIdQueryVariables>(ListStatsForDynamicCompetitionSurveyByIdDocument, options);
        }
export type ListStatsForDynamicCompetitionSurveyByIdQueryHookResult = ReturnType<typeof useListStatsForDynamicCompetitionSurveyByIdQuery>;
export type ListStatsForDynamicCompetitionSurveyByIdLazyQueryHookResult = ReturnType<typeof useListStatsForDynamicCompetitionSurveyByIdLazyQuery>;
export type ListStatsForDynamicCompetitionSurveyByIdQueryResult = Apollo.QueryResult<ListStatsForDynamicCompetitionSurveyByIdQuery, ListStatsForDynamicCompetitionSurveyByIdQueryVariables>;
export const StartDynamicCompetitionSurveyDocument = gql`
    mutation startDynamicCompetitionSurvey($competitionSurveyId: String!) {
  startDynamicCompetitionSurvey(
    input: {competitionSurveyId: $competitionSurveyId}
  )
}
    `;
export type StartDynamicCompetitionSurveyMutationFn = Apollo.MutationFunction<StartDynamicCompetitionSurveyMutation, StartDynamicCompetitionSurveyMutationVariables>;

/**
 * __useStartDynamicCompetitionSurveyMutation__
 *
 * To run a mutation, you first call `useStartDynamicCompetitionSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartDynamicCompetitionSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startDynamicCompetitionSurveyMutation, { data, loading, error }] = useStartDynamicCompetitionSurveyMutation({
 *   variables: {
 *      competitionSurveyId: // value for 'competitionSurveyId'
 *   },
 * });
 */
export function useStartDynamicCompetitionSurveyMutation(baseOptions?: Apollo.MutationHookOptions<StartDynamicCompetitionSurveyMutation, StartDynamicCompetitionSurveyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartDynamicCompetitionSurveyMutation, StartDynamicCompetitionSurveyMutationVariables>(StartDynamicCompetitionSurveyDocument, options);
      }
export type StartDynamicCompetitionSurveyMutationHookResult = ReturnType<typeof useStartDynamicCompetitionSurveyMutation>;
export type StartDynamicCompetitionSurveyMutationResult = Apollo.MutationResult<StartDynamicCompetitionSurveyMutation>;
export type StartDynamicCompetitionSurveyMutationOptions = Apollo.BaseMutationOptions<StartDynamicCompetitionSurveyMutation, StartDynamicCompetitionSurveyMutationVariables>;
export const UpdateDynamicCompetitionSurveyCompetitionScoreDocument = gql`
    mutation updateDynamicCompetitionSurveyCompetitionScore($competitionSurveyId: String!, $homeTeamScore: Int!, $awayTeamScore: Int!, $homeTeamTieBreakerScore: Int, $awayTeamTieBreakerScore: Int) {
  updateDynamicCompetitionSurveyCompetitionScore(
    input: {competitionSurveyId: $competitionSurveyId, homeTeamScore: $homeTeamScore, awayTeamScore: $awayTeamScore, homeTeamTieBreakerScore: $homeTeamTieBreakerScore, awayTeamTieBreakerScore: $awayTeamTieBreakerScore}
  )
}
    `;
export type UpdateDynamicCompetitionSurveyCompetitionScoreMutationFn = Apollo.MutationFunction<UpdateDynamicCompetitionSurveyCompetitionScoreMutation, UpdateDynamicCompetitionSurveyCompetitionScoreMutationVariables>;

/**
 * __useUpdateDynamicCompetitionSurveyCompetitionScoreMutation__
 *
 * To run a mutation, you first call `useUpdateDynamicCompetitionSurveyCompetitionScoreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDynamicCompetitionSurveyCompetitionScoreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDynamicCompetitionSurveyCompetitionScoreMutation, { data, loading, error }] = useUpdateDynamicCompetitionSurveyCompetitionScoreMutation({
 *   variables: {
 *      competitionSurveyId: // value for 'competitionSurveyId'
 *      homeTeamScore: // value for 'homeTeamScore'
 *      awayTeamScore: // value for 'awayTeamScore'
 *      homeTeamTieBreakerScore: // value for 'homeTeamTieBreakerScore'
 *      awayTeamTieBreakerScore: // value for 'awayTeamTieBreakerScore'
 *   },
 * });
 */
export function useUpdateDynamicCompetitionSurveyCompetitionScoreMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDynamicCompetitionSurveyCompetitionScoreMutation, UpdateDynamicCompetitionSurveyCompetitionScoreMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDynamicCompetitionSurveyCompetitionScoreMutation, UpdateDynamicCompetitionSurveyCompetitionScoreMutationVariables>(UpdateDynamicCompetitionSurveyCompetitionScoreDocument, options);
      }
export type UpdateDynamicCompetitionSurveyCompetitionScoreMutationHookResult = ReturnType<typeof useUpdateDynamicCompetitionSurveyCompetitionScoreMutation>;
export type UpdateDynamicCompetitionSurveyCompetitionScoreMutationResult = Apollo.MutationResult<UpdateDynamicCompetitionSurveyCompetitionScoreMutation>;
export type UpdateDynamicCompetitionSurveyCompetitionScoreMutationOptions = Apollo.BaseMutationOptions<UpdateDynamicCompetitionSurveyCompetitionScoreMutation, UpdateDynamicCompetitionSurveyCompetitionScoreMutationVariables>;
export const UpdateDynamicCompetitionSurveyMultipleChoiceResponseDocument = gql`
    mutation updateDynamicCompetitionSurveyMultipleChoiceResponse($competitionSurveyId: String!, $questionId: String!, $selectedOptionId: String!) {
  updateDynamicCompetitionSurveyMultipleChoiceResponse(
    input: {competitionSurveyId: $competitionSurveyId, questionId: $questionId, selectedOptionId: $selectedOptionId}
  ) {
    id
    name
    description
    additionalText1
    additionalText2
    additionalText3
    order
    isRequired
    canAddToKeyIncidents
    assessmentObject {
      id
      sport
      type
      classification
      name
    }
    coach {
      id
      teamId
      teamName
      teamAbbreviation
      sport
      teamCity
      userId
      type
      role
      status
      email
      firstName
      lastName
      profilePic
      createdAt
      updatedAt
    }
    team {
      id
      name
      abbreviation
      sport
      city
      stateAbbreviation
      countryCode
      timeZone
      classification
      gender
      website
      logo
      parentOrganizationName
      externalId
      ageGroup
      createdAt
      updatedAt
    }
    official {
      id
      sport
      userId
      status
      email
      firstName
      lastName
      externalIds {
        id
        organizationId
        externalId
      }
      profilePic
      createdAt
      updatedAt
    }
    organization {
      id
      name
      sport
      status
      abbreviation
      site
      logo
      createdAt
      updatedAt
    }
    questionType
    selectedOption
    selectedOptionOrder
    responseShortAnswer
    responseText
    responseNumber
    responseBoolean
    responses {
      id
      value
      createdAt
      updatedAt
    }
    fileUploads {
      id
      name
      comment
      fileUpload
      createdAt
      updatedAt
    }
    options {
      id
      value
      order
    }
    subQuestions {
      id
      name
      questionText
      order
      isRequired
      canAddToKeyIncidents
      questionType
      selectedOption
      selectedOptionOrder
      responseShortAnswer
      responseText
      responseNumber
      responseBoolean
      responses {
        id
        value
        createdAt
        updatedAt
      }
      fileUploads {
        id
        name
        comment
        fileUpload
        createdAt
        updatedAt
      }
      options {
        id
        value
        order
      }
    }
  }
}
    `;
export type UpdateDynamicCompetitionSurveyMultipleChoiceResponseMutationFn = Apollo.MutationFunction<UpdateDynamicCompetitionSurveyMultipleChoiceResponseMutation, UpdateDynamicCompetitionSurveyMultipleChoiceResponseMutationVariables>;

/**
 * __useUpdateDynamicCompetitionSurveyMultipleChoiceResponseMutation__
 *
 * To run a mutation, you first call `useUpdateDynamicCompetitionSurveyMultipleChoiceResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDynamicCompetitionSurveyMultipleChoiceResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDynamicCompetitionSurveyMultipleChoiceResponseMutation, { data, loading, error }] = useUpdateDynamicCompetitionSurveyMultipleChoiceResponseMutation({
 *   variables: {
 *      competitionSurveyId: // value for 'competitionSurveyId'
 *      questionId: // value for 'questionId'
 *      selectedOptionId: // value for 'selectedOptionId'
 *   },
 * });
 */
export function useUpdateDynamicCompetitionSurveyMultipleChoiceResponseMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDynamicCompetitionSurveyMultipleChoiceResponseMutation, UpdateDynamicCompetitionSurveyMultipleChoiceResponseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDynamicCompetitionSurveyMultipleChoiceResponseMutation, UpdateDynamicCompetitionSurveyMultipleChoiceResponseMutationVariables>(UpdateDynamicCompetitionSurveyMultipleChoiceResponseDocument, options);
      }
export type UpdateDynamicCompetitionSurveyMultipleChoiceResponseMutationHookResult = ReturnType<typeof useUpdateDynamicCompetitionSurveyMultipleChoiceResponseMutation>;
export type UpdateDynamicCompetitionSurveyMultipleChoiceResponseMutationResult = Apollo.MutationResult<UpdateDynamicCompetitionSurveyMultipleChoiceResponseMutation>;
export type UpdateDynamicCompetitionSurveyMultipleChoiceResponseMutationOptions = Apollo.BaseMutationOptions<UpdateDynamicCompetitionSurveyMultipleChoiceResponseMutation, UpdateDynamicCompetitionSurveyMultipleChoiceResponseMutationVariables>;
export const UpdateDynamicCompetitionSurveyShortAnswerResponseDocument = gql`
    mutation updateDynamicCompetitionSurveyShortAnswerResponse($competitionSurveyId: String!, $questionId: String!, $response: String!) {
  updateDynamicCompetitionSurveyShortAnswerResponse(
    input: {competitionSurveyId: $competitionSurveyId, questionId: $questionId, response: $response}
  ) {
    id
    name
    description
    additionalText1
    additionalText2
    additionalText3
    order
    isRequired
    canAddToKeyIncidents
    assessmentObject {
      id
      sport
      type
      classification
      name
    }
    coach {
      id
      teamId
      teamName
      teamAbbreviation
      sport
      teamCity
      userId
      type
      role
      status
      email
      firstName
      lastName
      profilePic
      createdAt
      updatedAt
    }
    team {
      id
      name
      abbreviation
      sport
      city
      stateAbbreviation
      countryCode
      timeZone
      classification
      gender
      website
      logo
      parentOrganizationName
      externalId
      ageGroup
      createdAt
      updatedAt
    }
    official {
      id
      sport
      userId
      status
      email
      firstName
      lastName
      externalIds {
        id
        organizationId
        externalId
      }
      profilePic
      createdAt
      updatedAt
    }
    organization {
      id
      name
      sport
      status
      abbreviation
      site
      logo
      createdAt
      updatedAt
    }
    questionType
    selectedOption
    selectedOptionOrder
    responseShortAnswer
    responseText
    responseNumber
    responseBoolean
    responses {
      id
      value
      createdAt
      updatedAt
    }
    fileUploads {
      id
      name
      comment
      fileUpload
      createdAt
      updatedAt
    }
    options {
      id
      value
      order
    }
    subQuestions {
      id
      name
      questionText
      order
      isRequired
      canAddToKeyIncidents
      questionType
      selectedOption
      selectedOptionOrder
      responseShortAnswer
      responseText
      responseNumber
      responseBoolean
      responses {
        id
        value
        createdAt
        updatedAt
      }
      fileUploads {
        id
        name
        comment
        fileUpload
        createdAt
        updatedAt
      }
      options {
        id
        value
        order
      }
    }
  }
}
    `;
export type UpdateDynamicCompetitionSurveyShortAnswerResponseMutationFn = Apollo.MutationFunction<UpdateDynamicCompetitionSurveyShortAnswerResponseMutation, UpdateDynamicCompetitionSurveyShortAnswerResponseMutationVariables>;

/**
 * __useUpdateDynamicCompetitionSurveyShortAnswerResponseMutation__
 *
 * To run a mutation, you first call `useUpdateDynamicCompetitionSurveyShortAnswerResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDynamicCompetitionSurveyShortAnswerResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDynamicCompetitionSurveyShortAnswerResponseMutation, { data, loading, error }] = useUpdateDynamicCompetitionSurveyShortAnswerResponseMutation({
 *   variables: {
 *      competitionSurveyId: // value for 'competitionSurveyId'
 *      questionId: // value for 'questionId'
 *      response: // value for 'response'
 *   },
 * });
 */
export function useUpdateDynamicCompetitionSurveyShortAnswerResponseMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDynamicCompetitionSurveyShortAnswerResponseMutation, UpdateDynamicCompetitionSurveyShortAnswerResponseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDynamicCompetitionSurveyShortAnswerResponseMutation, UpdateDynamicCompetitionSurveyShortAnswerResponseMutationVariables>(UpdateDynamicCompetitionSurveyShortAnswerResponseDocument, options);
      }
export type UpdateDynamicCompetitionSurveyShortAnswerResponseMutationHookResult = ReturnType<typeof useUpdateDynamicCompetitionSurveyShortAnswerResponseMutation>;
export type UpdateDynamicCompetitionSurveyShortAnswerResponseMutationResult = Apollo.MutationResult<UpdateDynamicCompetitionSurveyShortAnswerResponseMutation>;
export type UpdateDynamicCompetitionSurveyShortAnswerResponseMutationOptions = Apollo.BaseMutationOptions<UpdateDynamicCompetitionSurveyShortAnswerResponseMutation, UpdateDynamicCompetitionSurveyShortAnswerResponseMutationVariables>;
export const UpdateDynamicCompetitionSurveyTextResponseDocument = gql`
    mutation updateDynamicCompetitionSurveyTextResponse($competitionSurveyId: String!, $questionId: String!, $response: String!) {
  updateDynamicCompetitionSurveyTextResponse(
    input: {competitionSurveyId: $competitionSurveyId, questionId: $questionId, response: $response}
  ) {
    id
    name
    description
    additionalText1
    additionalText2
    additionalText3
    order
    isRequired
    canAddToKeyIncidents
    assessmentObject {
      id
      sport
      type
      classification
      name
    }
    coach {
      id
      teamId
      teamName
      teamAbbreviation
      sport
      teamCity
      userId
      type
      role
      status
      email
      firstName
      lastName
      profilePic
      createdAt
      updatedAt
    }
    team {
      id
      name
      abbreviation
      sport
      city
      stateAbbreviation
      countryCode
      timeZone
      classification
      gender
      website
      logo
      parentOrganizationName
      externalId
      ageGroup
      createdAt
      updatedAt
    }
    official {
      id
      sport
      userId
      status
      email
      firstName
      lastName
      externalIds {
        id
        organizationId
        externalId
      }
      profilePic
      createdAt
      updatedAt
    }
    organization {
      id
      name
      sport
      status
      abbreviation
      site
      logo
      createdAt
      updatedAt
    }
    questionType
    selectedOption
    selectedOptionOrder
    responseShortAnswer
    responseText
    responseNumber
    responseBoolean
    responses {
      id
      value
      createdAt
      updatedAt
    }
    fileUploads {
      id
      name
      comment
      fileUpload
      createdAt
      updatedAt
    }
    options {
      id
      value
      order
    }
    subQuestions {
      id
      name
      questionText
      order
      isRequired
      canAddToKeyIncidents
      questionType
      selectedOption
      selectedOptionOrder
      responseShortAnswer
      responseText
      responseNumber
      responseBoolean
      responses {
        id
        value
        createdAt
        updatedAt
      }
      fileUploads {
        id
        name
        comment
        fileUpload
        createdAt
        updatedAt
      }
      options {
        id
        value
        order
      }
    }
  }
}
    `;
export type UpdateDynamicCompetitionSurveyTextResponseMutationFn = Apollo.MutationFunction<UpdateDynamicCompetitionSurveyTextResponseMutation, UpdateDynamicCompetitionSurveyTextResponseMutationVariables>;

/**
 * __useUpdateDynamicCompetitionSurveyTextResponseMutation__
 *
 * To run a mutation, you first call `useUpdateDynamicCompetitionSurveyTextResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDynamicCompetitionSurveyTextResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDynamicCompetitionSurveyTextResponseMutation, { data, loading, error }] = useUpdateDynamicCompetitionSurveyTextResponseMutation({
 *   variables: {
 *      competitionSurveyId: // value for 'competitionSurveyId'
 *      questionId: // value for 'questionId'
 *      response: // value for 'response'
 *   },
 * });
 */
export function useUpdateDynamicCompetitionSurveyTextResponseMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDynamicCompetitionSurveyTextResponseMutation, UpdateDynamicCompetitionSurveyTextResponseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDynamicCompetitionSurveyTextResponseMutation, UpdateDynamicCompetitionSurveyTextResponseMutationVariables>(UpdateDynamicCompetitionSurveyTextResponseDocument, options);
      }
export type UpdateDynamicCompetitionSurveyTextResponseMutationHookResult = ReturnType<typeof useUpdateDynamicCompetitionSurveyTextResponseMutation>;
export type UpdateDynamicCompetitionSurveyTextResponseMutationResult = Apollo.MutationResult<UpdateDynamicCompetitionSurveyTextResponseMutation>;
export type UpdateDynamicCompetitionSurveyTextResponseMutationOptions = Apollo.BaseMutationOptions<UpdateDynamicCompetitionSurveyTextResponseMutation, UpdateDynamicCompetitionSurveyTextResponseMutationVariables>;
export const UpdateDynamicCompetitionSurveyNumberResponseDocument = gql`
    mutation updateDynamicCompetitionSurveyNumberResponse($competitionSurveyId: String!, $questionId: String!, $response: Float!) {
  updateDynamicCompetitionSurveyNumberResponse(
    input: {competitionSurveyId: $competitionSurveyId, questionId: $questionId, response: $response}
  ) {
    id
    name
    description
    additionalText1
    additionalText2
    additionalText3
    order
    isRequired
    canAddToKeyIncidents
    assessmentObject {
      id
      sport
      type
      classification
      name
    }
    coach {
      id
      teamId
      teamName
      teamAbbreviation
      sport
      teamCity
      userId
      type
      role
      status
      email
      firstName
      lastName
      profilePic
      createdAt
      updatedAt
    }
    team {
      id
      name
      abbreviation
      sport
      city
      stateAbbreviation
      countryCode
      timeZone
      classification
      gender
      website
      logo
      parentOrganizationName
      externalId
      ageGroup
      createdAt
      updatedAt
    }
    official {
      id
      sport
      userId
      status
      email
      firstName
      lastName
      externalIds {
        id
        organizationId
        externalId
      }
      profilePic
      createdAt
      updatedAt
    }
    organization {
      id
      name
      sport
      status
      abbreviation
      site
      logo
      createdAt
      updatedAt
    }
    questionType
    selectedOption
    selectedOptionOrder
    responseShortAnswer
    responseText
    responseNumber
    responseBoolean
    responses {
      id
      value
      createdAt
      updatedAt
    }
    fileUploads {
      id
      name
      comment
      fileUpload
      createdAt
      updatedAt
    }
    options {
      id
      value
      order
    }
    subQuestions {
      id
      name
      questionText
      order
      isRequired
      canAddToKeyIncidents
      questionType
      selectedOption
      selectedOptionOrder
      responseShortAnswer
      responseText
      responseNumber
      responseBoolean
      responses {
        id
        value
        createdAt
        updatedAt
      }
      fileUploads {
        id
        name
        comment
        fileUpload
        createdAt
        updatedAt
      }
      options {
        id
        value
        order
      }
    }
  }
}
    `;
export type UpdateDynamicCompetitionSurveyNumberResponseMutationFn = Apollo.MutationFunction<UpdateDynamicCompetitionSurveyNumberResponseMutation, UpdateDynamicCompetitionSurveyNumberResponseMutationVariables>;

/**
 * __useUpdateDynamicCompetitionSurveyNumberResponseMutation__
 *
 * To run a mutation, you first call `useUpdateDynamicCompetitionSurveyNumberResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDynamicCompetitionSurveyNumberResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDynamicCompetitionSurveyNumberResponseMutation, { data, loading, error }] = useUpdateDynamicCompetitionSurveyNumberResponseMutation({
 *   variables: {
 *      competitionSurveyId: // value for 'competitionSurveyId'
 *      questionId: // value for 'questionId'
 *      response: // value for 'response'
 *   },
 * });
 */
export function useUpdateDynamicCompetitionSurveyNumberResponseMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDynamicCompetitionSurveyNumberResponseMutation, UpdateDynamicCompetitionSurveyNumberResponseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDynamicCompetitionSurveyNumberResponseMutation, UpdateDynamicCompetitionSurveyNumberResponseMutationVariables>(UpdateDynamicCompetitionSurveyNumberResponseDocument, options);
      }
export type UpdateDynamicCompetitionSurveyNumberResponseMutationHookResult = ReturnType<typeof useUpdateDynamicCompetitionSurveyNumberResponseMutation>;
export type UpdateDynamicCompetitionSurveyNumberResponseMutationResult = Apollo.MutationResult<UpdateDynamicCompetitionSurveyNumberResponseMutation>;
export type UpdateDynamicCompetitionSurveyNumberResponseMutationOptions = Apollo.BaseMutationOptions<UpdateDynamicCompetitionSurveyNumberResponseMutation, UpdateDynamicCompetitionSurveyNumberResponseMutationVariables>;
export const UpdateDynamicCompetitionSurveyBooleanResponseDocument = gql`
    mutation updateDynamicCompetitionSurveyBooleanResponse($competitionSurveyId: String!, $questionId: String!, $response: Boolean!) {
  updateDynamicCompetitionSurveyBooleanResponse(
    input: {competitionSurveyId: $competitionSurveyId, questionId: $questionId, response: $response}
  ) {
    id
    name
    description
    additionalText1
    additionalText2
    additionalText3
    order
    isRequired
    canAddToKeyIncidents
    assessmentObject {
      id
      sport
      type
      classification
      name
    }
    coach {
      id
      teamId
      teamName
      teamAbbreviation
      sport
      teamCity
      userId
      type
      role
      status
      email
      firstName
      lastName
      profilePic
      createdAt
      updatedAt
    }
    team {
      id
      name
      abbreviation
      sport
      city
      stateAbbreviation
      countryCode
      timeZone
      classification
      gender
      website
      logo
      parentOrganizationName
      externalId
      ageGroup
      createdAt
      updatedAt
    }
    official {
      id
      sport
      userId
      status
      email
      firstName
      lastName
      externalIds {
        id
        organizationId
        externalId
      }
      profilePic
      createdAt
      updatedAt
    }
    organization {
      id
      name
      sport
      status
      abbreviation
      site
      logo
      createdAt
      updatedAt
    }
    questionType
    selectedOption
    selectedOptionOrder
    responseShortAnswer
    responseText
    responseNumber
    responseBoolean
    responses {
      id
      value
      createdAt
      updatedAt
    }
    fileUploads {
      id
      name
      comment
      fileUpload
      createdAt
      updatedAt
    }
    options {
      id
      value
      order
    }
    subQuestions {
      id
      name
      questionText
      order
      isRequired
      canAddToKeyIncidents
      questionType
      selectedOption
      selectedOptionOrder
      responseShortAnswer
      responseText
      responseNumber
      responseBoolean
      responses {
        id
        value
        createdAt
        updatedAt
      }
      fileUploads {
        id
        name
        comment
        fileUpload
        createdAt
        updatedAt
      }
      options {
        id
        value
        order
      }
    }
  }
}
    `;
export type UpdateDynamicCompetitionSurveyBooleanResponseMutationFn = Apollo.MutationFunction<UpdateDynamicCompetitionSurveyBooleanResponseMutation, UpdateDynamicCompetitionSurveyBooleanResponseMutationVariables>;

/**
 * __useUpdateDynamicCompetitionSurveyBooleanResponseMutation__
 *
 * To run a mutation, you first call `useUpdateDynamicCompetitionSurveyBooleanResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDynamicCompetitionSurveyBooleanResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDynamicCompetitionSurveyBooleanResponseMutation, { data, loading, error }] = useUpdateDynamicCompetitionSurveyBooleanResponseMutation({
 *   variables: {
 *      competitionSurveyId: // value for 'competitionSurveyId'
 *      questionId: // value for 'questionId'
 *      response: // value for 'response'
 *   },
 * });
 */
export function useUpdateDynamicCompetitionSurveyBooleanResponseMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDynamicCompetitionSurveyBooleanResponseMutation, UpdateDynamicCompetitionSurveyBooleanResponseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDynamicCompetitionSurveyBooleanResponseMutation, UpdateDynamicCompetitionSurveyBooleanResponseMutationVariables>(UpdateDynamicCompetitionSurveyBooleanResponseDocument, options);
      }
export type UpdateDynamicCompetitionSurveyBooleanResponseMutationHookResult = ReturnType<typeof useUpdateDynamicCompetitionSurveyBooleanResponseMutation>;
export type UpdateDynamicCompetitionSurveyBooleanResponseMutationResult = Apollo.MutationResult<UpdateDynamicCompetitionSurveyBooleanResponseMutation>;
export type UpdateDynamicCompetitionSurveyBooleanResponseMutationOptions = Apollo.BaseMutationOptions<UpdateDynamicCompetitionSurveyBooleanResponseMutation, UpdateDynamicCompetitionSurveyBooleanResponseMutationVariables>;
export const AddDynamicCompetitionSurveyMultipleResponseDocument = gql`
    mutation addDynamicCompetitionSurveyMultipleResponse($competitionSurveyId: String!, $questionId: String!, $response: String!) {
  addDynamicCompetitionSurveyMultipleResponse(
    input: {competitionSurveyId: $competitionSurveyId, questionId: $questionId, response: $response}
  ) {
    id
    value
    createdAt
    updatedAt
  }
}
    `;
export type AddDynamicCompetitionSurveyMultipleResponseMutationFn = Apollo.MutationFunction<AddDynamicCompetitionSurveyMultipleResponseMutation, AddDynamicCompetitionSurveyMultipleResponseMutationVariables>;

/**
 * __useAddDynamicCompetitionSurveyMultipleResponseMutation__
 *
 * To run a mutation, you first call `useAddDynamicCompetitionSurveyMultipleResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDynamicCompetitionSurveyMultipleResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDynamicCompetitionSurveyMultipleResponseMutation, { data, loading, error }] = useAddDynamicCompetitionSurveyMultipleResponseMutation({
 *   variables: {
 *      competitionSurveyId: // value for 'competitionSurveyId'
 *      questionId: // value for 'questionId'
 *      response: // value for 'response'
 *   },
 * });
 */
export function useAddDynamicCompetitionSurveyMultipleResponseMutation(baseOptions?: Apollo.MutationHookOptions<AddDynamicCompetitionSurveyMultipleResponseMutation, AddDynamicCompetitionSurveyMultipleResponseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddDynamicCompetitionSurveyMultipleResponseMutation, AddDynamicCompetitionSurveyMultipleResponseMutationVariables>(AddDynamicCompetitionSurveyMultipleResponseDocument, options);
      }
export type AddDynamicCompetitionSurveyMultipleResponseMutationHookResult = ReturnType<typeof useAddDynamicCompetitionSurveyMultipleResponseMutation>;
export type AddDynamicCompetitionSurveyMultipleResponseMutationResult = Apollo.MutationResult<AddDynamicCompetitionSurveyMultipleResponseMutation>;
export type AddDynamicCompetitionSurveyMultipleResponseMutationOptions = Apollo.BaseMutationOptions<AddDynamicCompetitionSurveyMultipleResponseMutation, AddDynamicCompetitionSurveyMultipleResponseMutationVariables>;
export const RemoveDynamicCompetitionSurveyMultipleResponseDocument = gql`
    mutation removeDynamicCompetitionSurveyMultipleResponse($competitionSurveyId: String!, $questionId: String!, $responseId: String!) {
  removeDynamicCompetitionSurveyMultipleResponse(
    input: {competitionSurveyId: $competitionSurveyId, questionId: $questionId, responseId: $responseId}
  ) {
    id
    name
    description
    additionalText1
    additionalText2
    additionalText3
    order
    isRequired
    canAddToKeyIncidents
    assessmentObject {
      id
      sport
      type
      classification
      name
    }
    coach {
      id
      teamId
      teamName
      teamAbbreviation
      sport
      teamCity
      userId
      type
      role
      status
      email
      firstName
      lastName
      profilePic
      createdAt
      updatedAt
    }
    team {
      id
      name
      abbreviation
      sport
      city
      stateAbbreviation
      countryCode
      timeZone
      classification
      gender
      website
      logo
      parentOrganizationName
      externalId
      ageGroup
      createdAt
      updatedAt
    }
    official {
      id
      sport
      userId
      status
      email
      firstName
      lastName
      externalIds {
        id
        organizationId
        externalId
      }
      profilePic
      createdAt
      updatedAt
    }
    organization {
      id
      name
      sport
      status
      abbreviation
      site
      logo
      createdAt
      updatedAt
    }
    questionType
    selectedOption
    selectedOptionOrder
    responseShortAnswer
    responseText
    responseNumber
    responseBoolean
    responses {
      id
      value
      createdAt
      updatedAt
    }
    fileUploads {
      id
      name
      comment
      fileUpload
      createdAt
      updatedAt
    }
    options {
      id
      value
      order
    }
    subQuestions {
      id
      name
      questionText
      order
      isRequired
      canAddToKeyIncidents
      questionType
      selectedOption
      selectedOptionOrder
      responseShortAnswer
      responseText
      responseNumber
      responseBoolean
      responses {
        id
        value
        createdAt
        updatedAt
      }
      fileUploads {
        id
        name
        comment
        fileUpload
        createdAt
        updatedAt
      }
      options {
        id
        value
        order
      }
    }
  }
}
    `;
export type RemoveDynamicCompetitionSurveyMultipleResponseMutationFn = Apollo.MutationFunction<RemoveDynamicCompetitionSurveyMultipleResponseMutation, RemoveDynamicCompetitionSurveyMultipleResponseMutationVariables>;

/**
 * __useRemoveDynamicCompetitionSurveyMultipleResponseMutation__
 *
 * To run a mutation, you first call `useRemoveDynamicCompetitionSurveyMultipleResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveDynamicCompetitionSurveyMultipleResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeDynamicCompetitionSurveyMultipleResponseMutation, { data, loading, error }] = useRemoveDynamicCompetitionSurveyMultipleResponseMutation({
 *   variables: {
 *      competitionSurveyId: // value for 'competitionSurveyId'
 *      questionId: // value for 'questionId'
 *      responseId: // value for 'responseId'
 *   },
 * });
 */
export function useRemoveDynamicCompetitionSurveyMultipleResponseMutation(baseOptions?: Apollo.MutationHookOptions<RemoveDynamicCompetitionSurveyMultipleResponseMutation, RemoveDynamicCompetitionSurveyMultipleResponseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveDynamicCompetitionSurveyMultipleResponseMutation, RemoveDynamicCompetitionSurveyMultipleResponseMutationVariables>(RemoveDynamicCompetitionSurveyMultipleResponseDocument, options);
      }
export type RemoveDynamicCompetitionSurveyMultipleResponseMutationHookResult = ReturnType<typeof useRemoveDynamicCompetitionSurveyMultipleResponseMutation>;
export type RemoveDynamicCompetitionSurveyMultipleResponseMutationResult = Apollo.MutationResult<RemoveDynamicCompetitionSurveyMultipleResponseMutation>;
export type RemoveDynamicCompetitionSurveyMultipleResponseMutationOptions = Apollo.BaseMutationOptions<RemoveDynamicCompetitionSurveyMultipleResponseMutation, RemoveDynamicCompetitionSurveyMultipleResponseMutationVariables>;
export const RemoveDynamicCompetitionSurveyFileUploadDocument = gql`
    mutation removeDynamicCompetitionSurveyFileUpload($competitionSurveyId: String!, $questionId: String!, $fileUploadId: String!) {
  removeDynamicCompetitionSurveyFileUpload(
    input: {competitionSurveyId: $competitionSurveyId, questionId: $questionId, fileUploadId: $fileUploadId}
  ) {
    id
    name
    description
    additionalText1
    additionalText2
    additionalText3
    order
    isRequired
    canAddToKeyIncidents
    assessmentObject {
      id
      sport
      type
      classification
      name
    }
    coach {
      id
      teamId
      teamName
      teamAbbreviation
      sport
      teamCity
      userId
      type
      role
      status
      email
      firstName
      lastName
      profilePic
      createdAt
      updatedAt
    }
    team {
      id
      name
      abbreviation
      sport
      city
      stateAbbreviation
      countryCode
      timeZone
      classification
      gender
      website
      logo
      parentOrganizationName
      externalId
      ageGroup
      createdAt
      updatedAt
    }
    official {
      id
      sport
      userId
      status
      email
      firstName
      lastName
      externalIds {
        id
        organizationId
        externalId
      }
      profilePic
      createdAt
      updatedAt
    }
    organization {
      id
      name
      sport
      status
      abbreviation
      site
      logo
      createdAt
      updatedAt
    }
    questionType
    selectedOption
    selectedOptionOrder
    responseShortAnswer
    responseText
    responseNumber
    responseBoolean
    responses {
      id
      value
      createdAt
      updatedAt
    }
    fileUploads {
      id
      name
      comment
      fileUpload
      createdAt
      updatedAt
    }
    options {
      id
      value
      order
    }
    subQuestions {
      id
      name
      questionText
      order
      isRequired
      canAddToKeyIncidents
      questionType
      selectedOption
      selectedOptionOrder
      responseShortAnswer
      responseText
      responseNumber
      responseBoolean
      responses {
        id
        value
        createdAt
        updatedAt
      }
      fileUploads {
        id
        name
        comment
        fileUpload
        createdAt
        updatedAt
      }
      options {
        id
        value
        order
      }
    }
  }
}
    `;
export type RemoveDynamicCompetitionSurveyFileUploadMutationFn = Apollo.MutationFunction<RemoveDynamicCompetitionSurveyFileUploadMutation, RemoveDynamicCompetitionSurveyFileUploadMutationVariables>;

/**
 * __useRemoveDynamicCompetitionSurveyFileUploadMutation__
 *
 * To run a mutation, you first call `useRemoveDynamicCompetitionSurveyFileUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveDynamicCompetitionSurveyFileUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeDynamicCompetitionSurveyFileUploadMutation, { data, loading, error }] = useRemoveDynamicCompetitionSurveyFileUploadMutation({
 *   variables: {
 *      competitionSurveyId: // value for 'competitionSurveyId'
 *      questionId: // value for 'questionId'
 *      fileUploadId: // value for 'fileUploadId'
 *   },
 * });
 */
export function useRemoveDynamicCompetitionSurveyFileUploadMutation(baseOptions?: Apollo.MutationHookOptions<RemoveDynamicCompetitionSurveyFileUploadMutation, RemoveDynamicCompetitionSurveyFileUploadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveDynamicCompetitionSurveyFileUploadMutation, RemoveDynamicCompetitionSurveyFileUploadMutationVariables>(RemoveDynamicCompetitionSurveyFileUploadDocument, options);
      }
export type RemoveDynamicCompetitionSurveyFileUploadMutationHookResult = ReturnType<typeof useRemoveDynamicCompetitionSurveyFileUploadMutation>;
export type RemoveDynamicCompetitionSurveyFileUploadMutationResult = Apollo.MutationResult<RemoveDynamicCompetitionSurveyFileUploadMutation>;
export type RemoveDynamicCompetitionSurveyFileUploadMutationOptions = Apollo.BaseMutationOptions<RemoveDynamicCompetitionSurveyFileUploadMutation, RemoveDynamicCompetitionSurveyFileUploadMutationVariables>;
export const RefreshDynamicCompetitionSurveyFileUploadLinkDocument = gql`
    mutation refreshDynamicCompetitionSurveyFileUploadLink($fileUploadId: String!) {
  refreshDynamicCompetitionSurveyFileUploadLink(
    input: {fileUploadId: $fileUploadId}
  )
}
    `;
export type RefreshDynamicCompetitionSurveyFileUploadLinkMutationFn = Apollo.MutationFunction<RefreshDynamicCompetitionSurveyFileUploadLinkMutation, RefreshDynamicCompetitionSurveyFileUploadLinkMutationVariables>;

/**
 * __useRefreshDynamicCompetitionSurveyFileUploadLinkMutation__
 *
 * To run a mutation, you first call `useRefreshDynamicCompetitionSurveyFileUploadLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshDynamicCompetitionSurveyFileUploadLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshDynamicCompetitionSurveyFileUploadLinkMutation, { data, loading, error }] = useRefreshDynamicCompetitionSurveyFileUploadLinkMutation({
 *   variables: {
 *      fileUploadId: // value for 'fileUploadId'
 *   },
 * });
 */
export function useRefreshDynamicCompetitionSurveyFileUploadLinkMutation(baseOptions?: Apollo.MutationHookOptions<RefreshDynamicCompetitionSurveyFileUploadLinkMutation, RefreshDynamicCompetitionSurveyFileUploadLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshDynamicCompetitionSurveyFileUploadLinkMutation, RefreshDynamicCompetitionSurveyFileUploadLinkMutationVariables>(RefreshDynamicCompetitionSurveyFileUploadLinkDocument, options);
      }
export type RefreshDynamicCompetitionSurveyFileUploadLinkMutationHookResult = ReturnType<typeof useRefreshDynamicCompetitionSurveyFileUploadLinkMutation>;
export type RefreshDynamicCompetitionSurveyFileUploadLinkMutationResult = Apollo.MutationResult<RefreshDynamicCompetitionSurveyFileUploadLinkMutation>;
export type RefreshDynamicCompetitionSurveyFileUploadLinkMutationOptions = Apollo.BaseMutationOptions<RefreshDynamicCompetitionSurveyFileUploadLinkMutation, RefreshDynamicCompetitionSurveyFileUploadLinkMutationVariables>;
export const UpdateDynamicCompetitionSurveySubQuestionMultipleChoiceResponseDocument = gql`
    mutation updateDynamicCompetitionSurveySubQuestionMultipleChoiceResponse($competitionSurveyId: String!, $questionId: String!, $subQuestionId: String!, $selectedOptionId: String!) {
  updateDynamicCompetitionSurveySubQuestionMultipleChoiceResponse(
    input: {competitionSurveyId: $competitionSurveyId, questionId: $questionId, subQuestionId: $subQuestionId, selectedOptionId: $selectedOptionId}
  ) {
    id
    name
    questionText
    order
    isRequired
    canAddToKeyIncidents
    questionType
    selectedOption
    selectedOptionOrder
    responseShortAnswer
    responseText
    responseNumber
    responseBoolean
    responses {
      id
      value
      createdAt
      updatedAt
    }
    fileUploads {
      id
      name
      comment
      fileUpload
      createdAt
      updatedAt
    }
    options {
      id
      value
      order
    }
  }
}
    `;
export type UpdateDynamicCompetitionSurveySubQuestionMultipleChoiceResponseMutationFn = Apollo.MutationFunction<UpdateDynamicCompetitionSurveySubQuestionMultipleChoiceResponseMutation, UpdateDynamicCompetitionSurveySubQuestionMultipleChoiceResponseMutationVariables>;

/**
 * __useUpdateDynamicCompetitionSurveySubQuestionMultipleChoiceResponseMutation__
 *
 * To run a mutation, you first call `useUpdateDynamicCompetitionSurveySubQuestionMultipleChoiceResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDynamicCompetitionSurveySubQuestionMultipleChoiceResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDynamicCompetitionSurveySubQuestionMultipleChoiceResponseMutation, { data, loading, error }] = useUpdateDynamicCompetitionSurveySubQuestionMultipleChoiceResponseMutation({
 *   variables: {
 *      competitionSurveyId: // value for 'competitionSurveyId'
 *      questionId: // value for 'questionId'
 *      subQuestionId: // value for 'subQuestionId'
 *      selectedOptionId: // value for 'selectedOptionId'
 *   },
 * });
 */
export function useUpdateDynamicCompetitionSurveySubQuestionMultipleChoiceResponseMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDynamicCompetitionSurveySubQuestionMultipleChoiceResponseMutation, UpdateDynamicCompetitionSurveySubQuestionMultipleChoiceResponseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDynamicCompetitionSurveySubQuestionMultipleChoiceResponseMutation, UpdateDynamicCompetitionSurveySubQuestionMultipleChoiceResponseMutationVariables>(UpdateDynamicCompetitionSurveySubQuestionMultipleChoiceResponseDocument, options);
      }
export type UpdateDynamicCompetitionSurveySubQuestionMultipleChoiceResponseMutationHookResult = ReturnType<typeof useUpdateDynamicCompetitionSurveySubQuestionMultipleChoiceResponseMutation>;
export type UpdateDynamicCompetitionSurveySubQuestionMultipleChoiceResponseMutationResult = Apollo.MutationResult<UpdateDynamicCompetitionSurveySubQuestionMultipleChoiceResponseMutation>;
export type UpdateDynamicCompetitionSurveySubQuestionMultipleChoiceResponseMutationOptions = Apollo.BaseMutationOptions<UpdateDynamicCompetitionSurveySubQuestionMultipleChoiceResponseMutation, UpdateDynamicCompetitionSurveySubQuestionMultipleChoiceResponseMutationVariables>;
export const UpdateDynamicCompetitionSurveySubQuestionShortAnswerResponseDocument = gql`
    mutation updateDynamicCompetitionSurveySubQuestionShortAnswerResponse($competitionSurveyId: String!, $questionId: String!, $subQuestionId: String!, $response: String!) {
  updateDynamicCompetitionSurveySubQuestionShortAnswerResponse(
    input: {competitionSurveyId: $competitionSurveyId, questionId: $questionId, subQuestionId: $subQuestionId, response: $response}
  ) {
    id
    name
    questionText
    order
    isRequired
    canAddToKeyIncidents
    questionType
    selectedOption
    selectedOptionOrder
    responseShortAnswer
    responseText
    responseNumber
    responseBoolean
    responses {
      id
      value
      createdAt
      updatedAt
    }
    fileUploads {
      id
      name
      comment
      fileUpload
      createdAt
      updatedAt
    }
    options {
      id
      value
      order
    }
  }
}
    `;
export type UpdateDynamicCompetitionSurveySubQuestionShortAnswerResponseMutationFn = Apollo.MutationFunction<UpdateDynamicCompetitionSurveySubQuestionShortAnswerResponseMutation, UpdateDynamicCompetitionSurveySubQuestionShortAnswerResponseMutationVariables>;

/**
 * __useUpdateDynamicCompetitionSurveySubQuestionShortAnswerResponseMutation__
 *
 * To run a mutation, you first call `useUpdateDynamicCompetitionSurveySubQuestionShortAnswerResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDynamicCompetitionSurveySubQuestionShortAnswerResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDynamicCompetitionSurveySubQuestionShortAnswerResponseMutation, { data, loading, error }] = useUpdateDynamicCompetitionSurveySubQuestionShortAnswerResponseMutation({
 *   variables: {
 *      competitionSurveyId: // value for 'competitionSurveyId'
 *      questionId: // value for 'questionId'
 *      subQuestionId: // value for 'subQuestionId'
 *      response: // value for 'response'
 *   },
 * });
 */
export function useUpdateDynamicCompetitionSurveySubQuestionShortAnswerResponseMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDynamicCompetitionSurveySubQuestionShortAnswerResponseMutation, UpdateDynamicCompetitionSurveySubQuestionShortAnswerResponseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDynamicCompetitionSurveySubQuestionShortAnswerResponseMutation, UpdateDynamicCompetitionSurveySubQuestionShortAnswerResponseMutationVariables>(UpdateDynamicCompetitionSurveySubQuestionShortAnswerResponseDocument, options);
      }
export type UpdateDynamicCompetitionSurveySubQuestionShortAnswerResponseMutationHookResult = ReturnType<typeof useUpdateDynamicCompetitionSurveySubQuestionShortAnswerResponseMutation>;
export type UpdateDynamicCompetitionSurveySubQuestionShortAnswerResponseMutationResult = Apollo.MutationResult<UpdateDynamicCompetitionSurveySubQuestionShortAnswerResponseMutation>;
export type UpdateDynamicCompetitionSurveySubQuestionShortAnswerResponseMutationOptions = Apollo.BaseMutationOptions<UpdateDynamicCompetitionSurveySubQuestionShortAnswerResponseMutation, UpdateDynamicCompetitionSurveySubQuestionShortAnswerResponseMutationVariables>;
export const UpdateDynamicCompetitionSurveySubQuestionTextResponseDocument = gql`
    mutation updateDynamicCompetitionSurveySubQuestionTextResponse($competitionSurveyId: String!, $questionId: String!, $subQuestionId: String!, $response: String!) {
  updateDynamicCompetitionSurveySubQuestionTextResponse(
    input: {competitionSurveyId: $competitionSurveyId, questionId: $questionId, subQuestionId: $subQuestionId, response: $response}
  ) {
    id
    name
    questionText
    order
    isRequired
    canAddToKeyIncidents
    questionType
    selectedOption
    selectedOptionOrder
    responseShortAnswer
    responseText
    responseNumber
    responseBoolean
    responses {
      id
      value
      createdAt
      updatedAt
    }
    fileUploads {
      id
      name
      comment
      fileUpload
      createdAt
      updatedAt
    }
    options {
      id
      value
      order
    }
  }
}
    `;
export type UpdateDynamicCompetitionSurveySubQuestionTextResponseMutationFn = Apollo.MutationFunction<UpdateDynamicCompetitionSurveySubQuestionTextResponseMutation, UpdateDynamicCompetitionSurveySubQuestionTextResponseMutationVariables>;

/**
 * __useUpdateDynamicCompetitionSurveySubQuestionTextResponseMutation__
 *
 * To run a mutation, you first call `useUpdateDynamicCompetitionSurveySubQuestionTextResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDynamicCompetitionSurveySubQuestionTextResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDynamicCompetitionSurveySubQuestionTextResponseMutation, { data, loading, error }] = useUpdateDynamicCompetitionSurveySubQuestionTextResponseMutation({
 *   variables: {
 *      competitionSurveyId: // value for 'competitionSurveyId'
 *      questionId: // value for 'questionId'
 *      subQuestionId: // value for 'subQuestionId'
 *      response: // value for 'response'
 *   },
 * });
 */
export function useUpdateDynamicCompetitionSurveySubQuestionTextResponseMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDynamicCompetitionSurveySubQuestionTextResponseMutation, UpdateDynamicCompetitionSurveySubQuestionTextResponseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDynamicCompetitionSurveySubQuestionTextResponseMutation, UpdateDynamicCompetitionSurveySubQuestionTextResponseMutationVariables>(UpdateDynamicCompetitionSurveySubQuestionTextResponseDocument, options);
      }
export type UpdateDynamicCompetitionSurveySubQuestionTextResponseMutationHookResult = ReturnType<typeof useUpdateDynamicCompetitionSurveySubQuestionTextResponseMutation>;
export type UpdateDynamicCompetitionSurveySubQuestionTextResponseMutationResult = Apollo.MutationResult<UpdateDynamicCompetitionSurveySubQuestionTextResponseMutation>;
export type UpdateDynamicCompetitionSurveySubQuestionTextResponseMutationOptions = Apollo.BaseMutationOptions<UpdateDynamicCompetitionSurveySubQuestionTextResponseMutation, UpdateDynamicCompetitionSurveySubQuestionTextResponseMutationVariables>;
export const UpdateDynamicCompetitionSurveySubQuestionNumberResponseDocument = gql`
    mutation updateDynamicCompetitionSurveySubQuestionNumberResponse($competitionSurveyId: String!, $questionId: String!, $subQuestionId: String!, $response: Float!) {
  updateDynamicCompetitionSurveySubQuestionNumberResponse(
    input: {competitionSurveyId: $competitionSurveyId, questionId: $questionId, subQuestionId: $subQuestionId, response: $response}
  ) {
    id
    name
    questionText
    order
    isRequired
    canAddToKeyIncidents
    questionType
    selectedOption
    selectedOptionOrder
    responseShortAnswer
    responseText
    responseNumber
    responseBoolean
    responses {
      id
      value
      createdAt
      updatedAt
    }
    fileUploads {
      id
      name
      comment
      fileUpload
      createdAt
      updatedAt
    }
    options {
      id
      value
      order
    }
  }
}
    `;
export type UpdateDynamicCompetitionSurveySubQuestionNumberResponseMutationFn = Apollo.MutationFunction<UpdateDynamicCompetitionSurveySubQuestionNumberResponseMutation, UpdateDynamicCompetitionSurveySubQuestionNumberResponseMutationVariables>;

/**
 * __useUpdateDynamicCompetitionSurveySubQuestionNumberResponseMutation__
 *
 * To run a mutation, you first call `useUpdateDynamicCompetitionSurveySubQuestionNumberResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDynamicCompetitionSurveySubQuestionNumberResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDynamicCompetitionSurveySubQuestionNumberResponseMutation, { data, loading, error }] = useUpdateDynamicCompetitionSurveySubQuestionNumberResponseMutation({
 *   variables: {
 *      competitionSurveyId: // value for 'competitionSurveyId'
 *      questionId: // value for 'questionId'
 *      subQuestionId: // value for 'subQuestionId'
 *      response: // value for 'response'
 *   },
 * });
 */
export function useUpdateDynamicCompetitionSurveySubQuestionNumberResponseMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDynamicCompetitionSurveySubQuestionNumberResponseMutation, UpdateDynamicCompetitionSurveySubQuestionNumberResponseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDynamicCompetitionSurveySubQuestionNumberResponseMutation, UpdateDynamicCompetitionSurveySubQuestionNumberResponseMutationVariables>(UpdateDynamicCompetitionSurveySubQuestionNumberResponseDocument, options);
      }
export type UpdateDynamicCompetitionSurveySubQuestionNumberResponseMutationHookResult = ReturnType<typeof useUpdateDynamicCompetitionSurveySubQuestionNumberResponseMutation>;
export type UpdateDynamicCompetitionSurveySubQuestionNumberResponseMutationResult = Apollo.MutationResult<UpdateDynamicCompetitionSurveySubQuestionNumberResponseMutation>;
export type UpdateDynamicCompetitionSurveySubQuestionNumberResponseMutationOptions = Apollo.BaseMutationOptions<UpdateDynamicCompetitionSurveySubQuestionNumberResponseMutation, UpdateDynamicCompetitionSurveySubQuestionNumberResponseMutationVariables>;
export const UpdateDynamicCompetitionSurveySubQuestionBooleanResponseDocument = gql`
    mutation updateDynamicCompetitionSurveySubQuestionBooleanResponse($competitionSurveyId: String!, $questionId: String!, $subQuestionId: String!, $response: Boolean!) {
  updateDynamicCompetitionSurveySubQuestionBooleanResponse(
    input: {competitionSurveyId: $competitionSurveyId, questionId: $questionId, subQuestionId: $subQuestionId, response: $response}
  ) {
    id
    name
    questionText
    order
    isRequired
    canAddToKeyIncidents
    questionType
    selectedOption
    selectedOptionOrder
    responseShortAnswer
    responseText
    responseNumber
    responseBoolean
    responses {
      id
      value
      createdAt
      updatedAt
    }
    fileUploads {
      id
      name
      comment
      fileUpload
      createdAt
      updatedAt
    }
    options {
      id
      value
      order
    }
  }
}
    `;
export type UpdateDynamicCompetitionSurveySubQuestionBooleanResponseMutationFn = Apollo.MutationFunction<UpdateDynamicCompetitionSurveySubQuestionBooleanResponseMutation, UpdateDynamicCompetitionSurveySubQuestionBooleanResponseMutationVariables>;

/**
 * __useUpdateDynamicCompetitionSurveySubQuestionBooleanResponseMutation__
 *
 * To run a mutation, you first call `useUpdateDynamicCompetitionSurveySubQuestionBooleanResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDynamicCompetitionSurveySubQuestionBooleanResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDynamicCompetitionSurveySubQuestionBooleanResponseMutation, { data, loading, error }] = useUpdateDynamicCompetitionSurveySubQuestionBooleanResponseMutation({
 *   variables: {
 *      competitionSurveyId: // value for 'competitionSurveyId'
 *      questionId: // value for 'questionId'
 *      subQuestionId: // value for 'subQuestionId'
 *      response: // value for 'response'
 *   },
 * });
 */
export function useUpdateDynamicCompetitionSurveySubQuestionBooleanResponseMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDynamicCompetitionSurveySubQuestionBooleanResponseMutation, UpdateDynamicCompetitionSurveySubQuestionBooleanResponseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDynamicCompetitionSurveySubQuestionBooleanResponseMutation, UpdateDynamicCompetitionSurveySubQuestionBooleanResponseMutationVariables>(UpdateDynamicCompetitionSurveySubQuestionBooleanResponseDocument, options);
      }
export type UpdateDynamicCompetitionSurveySubQuestionBooleanResponseMutationHookResult = ReturnType<typeof useUpdateDynamicCompetitionSurveySubQuestionBooleanResponseMutation>;
export type UpdateDynamicCompetitionSurveySubQuestionBooleanResponseMutationResult = Apollo.MutationResult<UpdateDynamicCompetitionSurveySubQuestionBooleanResponseMutation>;
export type UpdateDynamicCompetitionSurveySubQuestionBooleanResponseMutationOptions = Apollo.BaseMutationOptions<UpdateDynamicCompetitionSurveySubQuestionBooleanResponseMutation, UpdateDynamicCompetitionSurveySubQuestionBooleanResponseMutationVariables>;
export const UpdateDynamicCompetitionSurveyMultiPartQuestionDocument = gql`
    mutation updateDynamicCompetitionSurveyMultiPartQuestion($competitionSurveyId: String!, $questionId: String!, $multipleResponses: [UpdateDynamicCompetitionSurveyMultiPartQuestionMultipleChoiceResponseInput!], $shortAnswerResponses: [UpdateDynamicCompetitionSurveyMultiPartQuestionShortAnswerResponseInput!], $textResponses: [UpdateDynamicCompetitionSurveyMultiPartQuestionTextResponseInput!], $numberResponses: [UpdateDynamicCompetitionSurveyMultiPartQuestionNumberResponseInput!]) {
  updateDynamicCompetitionSurveyMultiPartQuestion(
    input: {competitionSurveyId: $competitionSurveyId, questionId: $questionId, multipleResponses: $multipleResponses, shortAnswerResponses: $shortAnswerResponses, textResponses: $textResponses, numberResponses: $numberResponses}
  ) {
    id
    name
    description
    additionalText1
    additionalText2
    additionalText3
    order
    isRequired
    canAddToKeyIncidents
    assessmentObject {
      id
      sport
      type
      classification
      name
    }
    coach {
      id
      teamId
      teamName
      teamAbbreviation
      sport
      teamCity
      userId
      type
      role
      status
      email
      firstName
      lastName
      profilePic
      createdAt
      updatedAt
    }
    team {
      id
      name
      abbreviation
      sport
      city
      stateAbbreviation
      countryCode
      timeZone
      classification
      gender
      website
      logo
      parentOrganizationName
      externalId
      ageGroup
      createdAt
      updatedAt
    }
    official {
      id
      sport
      userId
      status
      email
      firstName
      lastName
      externalIds {
        id
        organizationId
        externalId
      }
      profilePic
      createdAt
      updatedAt
    }
    organization {
      id
      name
      sport
      status
      abbreviation
      site
      logo
      createdAt
      updatedAt
    }
    questionType
    selectedOption
    selectedOptionOrder
    responseShortAnswer
    responseText
    responseNumber
    responseBoolean
    responses {
      id
      value
      createdAt
      updatedAt
    }
    fileUploads {
      id
      name
      comment
      fileUpload
      createdAt
      updatedAt
    }
    options {
      id
      value
      order
    }
    subQuestions {
      id
      name
      questionText
      order
      isRequired
      canAddToKeyIncidents
      questionType
      selectedOption
      selectedOptionOrder
      responseShortAnswer
      responseText
      responseNumber
      responseBoolean
      responses {
        id
        value
        createdAt
        updatedAt
      }
      fileUploads {
        id
        name
        comment
        fileUpload
        createdAt
        updatedAt
      }
      options {
        id
        value
        order
      }
    }
  }
}
    `;
export type UpdateDynamicCompetitionSurveyMultiPartQuestionMutationFn = Apollo.MutationFunction<UpdateDynamicCompetitionSurveyMultiPartQuestionMutation, UpdateDynamicCompetitionSurveyMultiPartQuestionMutationVariables>;

/**
 * __useUpdateDynamicCompetitionSurveyMultiPartQuestionMutation__
 *
 * To run a mutation, you first call `useUpdateDynamicCompetitionSurveyMultiPartQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDynamicCompetitionSurveyMultiPartQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDynamicCompetitionSurveyMultiPartQuestionMutation, { data, loading, error }] = useUpdateDynamicCompetitionSurveyMultiPartQuestionMutation({
 *   variables: {
 *      competitionSurveyId: // value for 'competitionSurveyId'
 *      questionId: // value for 'questionId'
 *      multipleResponses: // value for 'multipleResponses'
 *      shortAnswerResponses: // value for 'shortAnswerResponses'
 *      textResponses: // value for 'textResponses'
 *      numberResponses: // value for 'numberResponses'
 *   },
 * });
 */
export function useUpdateDynamicCompetitionSurveyMultiPartQuestionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDynamicCompetitionSurveyMultiPartQuestionMutation, UpdateDynamicCompetitionSurveyMultiPartQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDynamicCompetitionSurveyMultiPartQuestionMutation, UpdateDynamicCompetitionSurveyMultiPartQuestionMutationVariables>(UpdateDynamicCompetitionSurveyMultiPartQuestionDocument, options);
      }
export type UpdateDynamicCompetitionSurveyMultiPartQuestionMutationHookResult = ReturnType<typeof useUpdateDynamicCompetitionSurveyMultiPartQuestionMutation>;
export type UpdateDynamicCompetitionSurveyMultiPartQuestionMutationResult = Apollo.MutationResult<UpdateDynamicCompetitionSurveyMultiPartQuestionMutation>;
export type UpdateDynamicCompetitionSurveyMultiPartQuestionMutationOptions = Apollo.BaseMutationOptions<UpdateDynamicCompetitionSurveyMultiPartQuestionMutation, UpdateDynamicCompetitionSurveyMultiPartQuestionMutationVariables>;
export const AddDynamicCompetitionSurveySubQuestionMultipleResponseDocument = gql`
    mutation addDynamicCompetitionSurveySubQuestionMultipleResponse($competitionSurveyId: String!, $questionId: String!, $subQuestionId: String!, $response: String!) {
  addDynamicCompetitionSurveySubQuestionMultipleResponse(
    input: {competitionSurveyId: $competitionSurveyId, questionId: $questionId, subQuestionId: $subQuestionId, response: $response}
  ) {
    id
    value
    createdAt
    updatedAt
  }
}
    `;
export type AddDynamicCompetitionSurveySubQuestionMultipleResponseMutationFn = Apollo.MutationFunction<AddDynamicCompetitionSurveySubQuestionMultipleResponseMutation, AddDynamicCompetitionSurveySubQuestionMultipleResponseMutationVariables>;

/**
 * __useAddDynamicCompetitionSurveySubQuestionMultipleResponseMutation__
 *
 * To run a mutation, you first call `useAddDynamicCompetitionSurveySubQuestionMultipleResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDynamicCompetitionSurveySubQuestionMultipleResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDynamicCompetitionSurveySubQuestionMultipleResponseMutation, { data, loading, error }] = useAddDynamicCompetitionSurveySubQuestionMultipleResponseMutation({
 *   variables: {
 *      competitionSurveyId: // value for 'competitionSurveyId'
 *      questionId: // value for 'questionId'
 *      subQuestionId: // value for 'subQuestionId'
 *      response: // value for 'response'
 *   },
 * });
 */
export function useAddDynamicCompetitionSurveySubQuestionMultipleResponseMutation(baseOptions?: Apollo.MutationHookOptions<AddDynamicCompetitionSurveySubQuestionMultipleResponseMutation, AddDynamicCompetitionSurveySubQuestionMultipleResponseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddDynamicCompetitionSurveySubQuestionMultipleResponseMutation, AddDynamicCompetitionSurveySubQuestionMultipleResponseMutationVariables>(AddDynamicCompetitionSurveySubQuestionMultipleResponseDocument, options);
      }
export type AddDynamicCompetitionSurveySubQuestionMultipleResponseMutationHookResult = ReturnType<typeof useAddDynamicCompetitionSurveySubQuestionMultipleResponseMutation>;
export type AddDynamicCompetitionSurveySubQuestionMultipleResponseMutationResult = Apollo.MutationResult<AddDynamicCompetitionSurveySubQuestionMultipleResponseMutation>;
export type AddDynamicCompetitionSurveySubQuestionMultipleResponseMutationOptions = Apollo.BaseMutationOptions<AddDynamicCompetitionSurveySubQuestionMultipleResponseMutation, AddDynamicCompetitionSurveySubQuestionMultipleResponseMutationVariables>;
export const RemoveDynamicCompetitionSurveySubQuestionMultipleResponseDocument = gql`
    mutation removeDynamicCompetitionSurveySubQuestionMultipleResponse($competitionSurveyId: String!, $questionId: String!, $subQuestionId: String!, $responseId: String!) {
  removeDynamicCompetitionSurveySubQuestionMultipleResponse(
    input: {competitionSurveyId: $competitionSurveyId, questionId: $questionId, subQuestionId: $subQuestionId, responseId: $responseId}
  ) {
    id
    name
    questionText
    order
    isRequired
    canAddToKeyIncidents
    questionType
    selectedOption
    selectedOptionOrder
    responseShortAnswer
    responseText
    responseNumber
    responseBoolean
    responses {
      id
      value
      createdAt
      updatedAt
    }
    fileUploads {
      id
      name
      comment
      fileUpload
      createdAt
      updatedAt
    }
    options {
      id
      value
      order
    }
  }
}
    `;
export type RemoveDynamicCompetitionSurveySubQuestionMultipleResponseMutationFn = Apollo.MutationFunction<RemoveDynamicCompetitionSurveySubQuestionMultipleResponseMutation, RemoveDynamicCompetitionSurveySubQuestionMultipleResponseMutationVariables>;

/**
 * __useRemoveDynamicCompetitionSurveySubQuestionMultipleResponseMutation__
 *
 * To run a mutation, you first call `useRemoveDynamicCompetitionSurveySubQuestionMultipleResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveDynamicCompetitionSurveySubQuestionMultipleResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeDynamicCompetitionSurveySubQuestionMultipleResponseMutation, { data, loading, error }] = useRemoveDynamicCompetitionSurveySubQuestionMultipleResponseMutation({
 *   variables: {
 *      competitionSurveyId: // value for 'competitionSurveyId'
 *      questionId: // value for 'questionId'
 *      subQuestionId: // value for 'subQuestionId'
 *      responseId: // value for 'responseId'
 *   },
 * });
 */
export function useRemoveDynamicCompetitionSurveySubQuestionMultipleResponseMutation(baseOptions?: Apollo.MutationHookOptions<RemoveDynamicCompetitionSurveySubQuestionMultipleResponseMutation, RemoveDynamicCompetitionSurveySubQuestionMultipleResponseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveDynamicCompetitionSurveySubQuestionMultipleResponseMutation, RemoveDynamicCompetitionSurveySubQuestionMultipleResponseMutationVariables>(RemoveDynamicCompetitionSurveySubQuestionMultipleResponseDocument, options);
      }
export type RemoveDynamicCompetitionSurveySubQuestionMultipleResponseMutationHookResult = ReturnType<typeof useRemoveDynamicCompetitionSurveySubQuestionMultipleResponseMutation>;
export type RemoveDynamicCompetitionSurveySubQuestionMultipleResponseMutationResult = Apollo.MutationResult<RemoveDynamicCompetitionSurveySubQuestionMultipleResponseMutation>;
export type RemoveDynamicCompetitionSurveySubQuestionMultipleResponseMutationOptions = Apollo.BaseMutationOptions<RemoveDynamicCompetitionSurveySubQuestionMultipleResponseMutation, RemoveDynamicCompetitionSurveySubQuestionMultipleResponseMutationVariables>;
export const ListSupplementalReportsForDynamicCompetitionSurveyDocument = gql`
    query listSupplementalReportsForDynamicCompetitionSurvey($competitionSurveyId: String!) {
  listSupplementalReportsForDynamicCompetitionSurvey(
    input: {competitionSurveyId: $competitionSurveyId}
  ) {
    supplementalReports {
      id
      competitionSurveyId
      dynamicCompetitionSurveyId
      submittedBy {
        userId
        email
        firstName
        lastName
        profilePic
        teamId
        teamName
        teamLogo
        officialId
        assessmentObjectId
        assessmentObjectName
      }
      competitionInfo {
        competitionId
        externalId
        sport
        homeTeamId
        homeTeamName
        homeTeamScore
        homeTeamTieBreakerScore
        awayTeamId
        awayTeamName
        awayTeamScore
        awayTeamTieBreakerScore
        startTime
        timeZone
        location
        outcome
        verificationStatus
      }
      assessmentObject {
        id
        sport
        type
        classification
        name
        createdAt
        updatedAt
      }
      official {
        id
        sport
        userId
        status
        email
        firstName
        lastName
        profilePic
        createdAt
        updatedAt
      }
      team {
        id
        name
        abbreviation
        sport
        city
        stateAbbreviation
        countryCode
        classification
        gender
        parentOrganizationName
        externalId
        ageGroup
        timeZone
        website
        logo
        createdAt
        updatedAt
      }
      organizationId
      organizationName
      leagueId
      leagueName
      seasonId
      seasonName
      competitionSurveySectionQuestionId
      competitionSurveyFeedbackId
      dynamicCompetitionSurveySectionQuestionId
      dynamicCompetitionSurveyFeedbackId
      dynamicCompetitionSurveySubFeedbackId
      type
      status
      title
      report
      reportVersion
      time
      approvedDate
      reopenedDate
      submittedAt
      createdAt
      updatedAt
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListSupplementalReportsForDynamicCompetitionSurveyQuery__
 *
 * To run a query within a React component, call `useListSupplementalReportsForDynamicCompetitionSurveyQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSupplementalReportsForDynamicCompetitionSurveyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSupplementalReportsForDynamicCompetitionSurveyQuery({
 *   variables: {
 *      competitionSurveyId: // value for 'competitionSurveyId'
 *   },
 * });
 */
export function useListSupplementalReportsForDynamicCompetitionSurveyQuery(baseOptions: Apollo.QueryHookOptions<ListSupplementalReportsForDynamicCompetitionSurveyQuery, ListSupplementalReportsForDynamicCompetitionSurveyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListSupplementalReportsForDynamicCompetitionSurveyQuery, ListSupplementalReportsForDynamicCompetitionSurveyQueryVariables>(ListSupplementalReportsForDynamicCompetitionSurveyDocument, options);
      }
export function useListSupplementalReportsForDynamicCompetitionSurveyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListSupplementalReportsForDynamicCompetitionSurveyQuery, ListSupplementalReportsForDynamicCompetitionSurveyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListSupplementalReportsForDynamicCompetitionSurveyQuery, ListSupplementalReportsForDynamicCompetitionSurveyQueryVariables>(ListSupplementalReportsForDynamicCompetitionSurveyDocument, options);
        }
export type ListSupplementalReportsForDynamicCompetitionSurveyQueryHookResult = ReturnType<typeof useListSupplementalReportsForDynamicCompetitionSurveyQuery>;
export type ListSupplementalReportsForDynamicCompetitionSurveyLazyQueryHookResult = ReturnType<typeof useListSupplementalReportsForDynamicCompetitionSurveyLazyQuery>;
export type ListSupplementalReportsForDynamicCompetitionSurveyQueryResult = Apollo.QueryResult<ListSupplementalReportsForDynamicCompetitionSurveyQuery, ListSupplementalReportsForDynamicCompetitionSurveyQueryVariables>;
export const AddSupplementalReportToDynamicCompetitionSurveyDocument = gql`
    mutation addSupplementalReportToDynamicCompetitionSurvey($competitionSurveyId: String!, $title: String!, $supplementalReportType: SupplementalReportTypes!, $time: String, $teamId: String, $report: String) {
  addSupplementalReportToDynamicCompetitionSurvey(
    input: {competitionSurveyId: $competitionSurveyId, title: $title, supplementalReportType: $supplementalReportType, time: $time, teamId: $teamId, report: $report}
  ) {
    id
    competitionSurveyId
    dynamicCompetitionSurveyId
    submittedBy {
      userId
      email
      firstName
      lastName
      profilePic
      teamId
      teamName
      teamLogo
      officialId
      assessmentObjectId
      assessmentObjectName
    }
    competitionInfo {
      competitionId
      externalId
      sport
      homeTeamId
      homeTeamName
      homeTeamScore
      homeTeamTieBreakerScore
      awayTeamId
      awayTeamName
      awayTeamScore
      awayTeamTieBreakerScore
      startTime
      timeZone
      location
      outcome
      verificationStatus
    }
    assessmentObject {
      id
      sport
      type
      classification
      name
      createdAt
      updatedAt
    }
    official {
      id
      sport
      userId
      status
      email
      firstName
      lastName
      profilePic
      createdAt
      updatedAt
    }
    team {
      id
      name
      abbreviation
      sport
      city
      stateAbbreviation
      countryCode
      classification
      gender
      parentOrganizationName
      externalId
      ageGroup
      timeZone
      website
      logo
      createdAt
      updatedAt
    }
    organizationId
    organizationName
    leagueId
    leagueName
    seasonId
    seasonName
    competitionSurveySectionQuestionId
    competitionSurveyFeedbackId
    dynamicCompetitionSurveySectionQuestionId
    dynamicCompetitionSurveyFeedbackId
    dynamicCompetitionSurveySubFeedbackId
    type
    status
    title
    report
    reportVersion
    time
    approvedDate
    reopenedDate
    submittedAt
    createdAt
    updatedAt
  }
}
    `;
export type AddSupplementalReportToDynamicCompetitionSurveyMutationFn = Apollo.MutationFunction<AddSupplementalReportToDynamicCompetitionSurveyMutation, AddSupplementalReportToDynamicCompetitionSurveyMutationVariables>;

/**
 * __useAddSupplementalReportToDynamicCompetitionSurveyMutation__
 *
 * To run a mutation, you first call `useAddSupplementalReportToDynamicCompetitionSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSupplementalReportToDynamicCompetitionSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSupplementalReportToDynamicCompetitionSurveyMutation, { data, loading, error }] = useAddSupplementalReportToDynamicCompetitionSurveyMutation({
 *   variables: {
 *      competitionSurveyId: // value for 'competitionSurveyId'
 *      title: // value for 'title'
 *      supplementalReportType: // value for 'supplementalReportType'
 *      time: // value for 'time'
 *      teamId: // value for 'teamId'
 *      report: // value for 'report'
 *   },
 * });
 */
export function useAddSupplementalReportToDynamicCompetitionSurveyMutation(baseOptions?: Apollo.MutationHookOptions<AddSupplementalReportToDynamicCompetitionSurveyMutation, AddSupplementalReportToDynamicCompetitionSurveyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddSupplementalReportToDynamicCompetitionSurveyMutation, AddSupplementalReportToDynamicCompetitionSurveyMutationVariables>(AddSupplementalReportToDynamicCompetitionSurveyDocument, options);
      }
export type AddSupplementalReportToDynamicCompetitionSurveyMutationHookResult = ReturnType<typeof useAddSupplementalReportToDynamicCompetitionSurveyMutation>;
export type AddSupplementalReportToDynamicCompetitionSurveyMutationResult = Apollo.MutationResult<AddSupplementalReportToDynamicCompetitionSurveyMutation>;
export type AddSupplementalReportToDynamicCompetitionSurveyMutationOptions = Apollo.BaseMutationOptions<AddSupplementalReportToDynamicCompetitionSurveyMutation, AddSupplementalReportToDynamicCompetitionSurveyMutationVariables>;
export const RemoveSupplementalReportFromDynamicCompetitionSurveyDocument = gql`
    mutation removeSupplementalReportFromDynamicCompetitionSurvey($competitionSurveyId: String!, $supplementalReportId: String!) {
  removeSupplementalReportFromDynamicCompetitionSurvey(
    input: {competitionSurveyId: $supplementalReportId, supplementalReportId: $supplementalReportId}
  )
}
    `;
export type RemoveSupplementalReportFromDynamicCompetitionSurveyMutationFn = Apollo.MutationFunction<RemoveSupplementalReportFromDynamicCompetitionSurveyMutation, RemoveSupplementalReportFromDynamicCompetitionSurveyMutationVariables>;

/**
 * __useRemoveSupplementalReportFromDynamicCompetitionSurveyMutation__
 *
 * To run a mutation, you first call `useRemoveSupplementalReportFromDynamicCompetitionSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSupplementalReportFromDynamicCompetitionSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSupplementalReportFromDynamicCompetitionSurveyMutation, { data, loading, error }] = useRemoveSupplementalReportFromDynamicCompetitionSurveyMutation({
 *   variables: {
 *      competitionSurveyId: // value for 'competitionSurveyId'
 *      supplementalReportId: // value for 'supplementalReportId'
 *   },
 * });
 */
export function useRemoveSupplementalReportFromDynamicCompetitionSurveyMutation(baseOptions?: Apollo.MutationHookOptions<RemoveSupplementalReportFromDynamicCompetitionSurveyMutation, RemoveSupplementalReportFromDynamicCompetitionSurveyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveSupplementalReportFromDynamicCompetitionSurveyMutation, RemoveSupplementalReportFromDynamicCompetitionSurveyMutationVariables>(RemoveSupplementalReportFromDynamicCompetitionSurveyDocument, options);
      }
export type RemoveSupplementalReportFromDynamicCompetitionSurveyMutationHookResult = ReturnType<typeof useRemoveSupplementalReportFromDynamicCompetitionSurveyMutation>;
export type RemoveSupplementalReportFromDynamicCompetitionSurveyMutationResult = Apollo.MutationResult<RemoveSupplementalReportFromDynamicCompetitionSurveyMutation>;
export type RemoveSupplementalReportFromDynamicCompetitionSurveyMutationOptions = Apollo.BaseMutationOptions<RemoveSupplementalReportFromDynamicCompetitionSurveyMutation, RemoveSupplementalReportFromDynamicCompetitionSurveyMutationVariables>;
export const EditSupplementalReportForDynamicCompetitionSurveyDocument = gql`
    mutation editSupplementalReportForDynamicCompetitionSurvey($competitionSurveyId: String!, $supplementalReportId: String!, $title: String!, $supplementalReportType: SupplementalReportTypes!, $time: String, $teamId: String, $report: String) {
  editSupplementalReportForDynamicCompetitionSurvey(
    input: {competitionSurveyId: $competitionSurveyId, supplementalReportId: $supplementalReportId, title: $title, supplementalReportType: $supplementalReportType, time: $time, teamId: $teamId, report: $report}
  ) {
    id
    competitionSurveyId
    dynamicCompetitionSurveyId
    submittedBy {
      userId
      email
      firstName
      lastName
      profilePic
      teamId
      teamName
      teamLogo
      officialId
      assessmentObjectId
      assessmentObjectName
    }
    competitionInfo {
      competitionId
      externalId
      sport
      homeTeamId
      homeTeamName
      homeTeamScore
      homeTeamTieBreakerScore
      awayTeamId
      awayTeamName
      awayTeamScore
      awayTeamTieBreakerScore
      startTime
      timeZone
      location
      outcome
      verificationStatus
    }
    assessmentObject {
      id
      sport
      type
      classification
      name
      createdAt
      updatedAt
    }
    official {
      id
      sport
      userId
      status
      email
      firstName
      lastName
      profilePic
      createdAt
      updatedAt
    }
    team {
      id
      name
      abbreviation
      sport
      city
      stateAbbreviation
      countryCode
      classification
      gender
      parentOrganizationName
      externalId
      ageGroup
      timeZone
      website
      logo
      createdAt
      updatedAt
    }
    organizationId
    organizationName
    leagueId
    leagueName
    seasonId
    seasonName
    competitionSurveySectionQuestionId
    competitionSurveyFeedbackId
    dynamicCompetitionSurveySectionQuestionId
    dynamicCompetitionSurveyFeedbackId
    dynamicCompetitionSurveySubFeedbackId
    type
    status
    title
    report
    reportVersion
    time
    approvedDate
    reopenedDate
    submittedAt
    createdAt
    updatedAt
  }
}
    `;
export type EditSupplementalReportForDynamicCompetitionSurveyMutationFn = Apollo.MutationFunction<EditSupplementalReportForDynamicCompetitionSurveyMutation, EditSupplementalReportForDynamicCompetitionSurveyMutationVariables>;

/**
 * __useEditSupplementalReportForDynamicCompetitionSurveyMutation__
 *
 * To run a mutation, you first call `useEditSupplementalReportForDynamicCompetitionSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditSupplementalReportForDynamicCompetitionSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editSupplementalReportForDynamicCompetitionSurveyMutation, { data, loading, error }] = useEditSupplementalReportForDynamicCompetitionSurveyMutation({
 *   variables: {
 *      competitionSurveyId: // value for 'competitionSurveyId'
 *      supplementalReportId: // value for 'supplementalReportId'
 *      title: // value for 'title'
 *      supplementalReportType: // value for 'supplementalReportType'
 *      time: // value for 'time'
 *      teamId: // value for 'teamId'
 *      report: // value for 'report'
 *   },
 * });
 */
export function useEditSupplementalReportForDynamicCompetitionSurveyMutation(baseOptions?: Apollo.MutationHookOptions<EditSupplementalReportForDynamicCompetitionSurveyMutation, EditSupplementalReportForDynamicCompetitionSurveyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditSupplementalReportForDynamicCompetitionSurveyMutation, EditSupplementalReportForDynamicCompetitionSurveyMutationVariables>(EditSupplementalReportForDynamicCompetitionSurveyDocument, options);
      }
export type EditSupplementalReportForDynamicCompetitionSurveyMutationHookResult = ReturnType<typeof useEditSupplementalReportForDynamicCompetitionSurveyMutation>;
export type EditSupplementalReportForDynamicCompetitionSurveyMutationResult = Apollo.MutationResult<EditSupplementalReportForDynamicCompetitionSurveyMutation>;
export type EditSupplementalReportForDynamicCompetitionSurveyMutationOptions = Apollo.BaseMutationOptions<EditSupplementalReportForDynamicCompetitionSurveyMutation, EditSupplementalReportForDynamicCompetitionSurveyMutationVariables>;
export const ListKeyIncidentsForDynamicCompetitionSurveyDocument = gql`
    query listKeyIncidentsForDynamicCompetitionSurvey($competitionSurveyId: String!) {
  listKeyIncidentsForDynamicCompetitionSurvey(
    input: {competitionSurveyId: $competitionSurveyId}
  ) {
    keyIncidents {
      id
      competitionSurveyId
      dynamicCompetitionSurveyId
      submittedBy {
        userId
        email
        firstName
        lastName
        profilePic
        teamId
        teamName
        teamLogo
        officialId
        assessmentObjectId
        assessmentObjectName
      }
      competitionInfo {
        competitionId
        externalId
        sport
        homeTeamId
        homeTeamName
        homeTeamScore
        homeTeamTieBreakerScore
        awayTeamId
        awayTeamName
        awayTeamScore
        awayTeamTieBreakerScore
        startTime
        timeZone
        location
        outcome
        verificationStatus
      }
      assessmentObject {
        id
        sport
        type
        classification
        name
        createdAt
        updatedAt
      }
      official {
        id
        sport
        userId
        status
        email
        firstName
        lastName
        profilePic
        createdAt
        updatedAt
      }
      team {
        id
        name
        abbreviation
        sport
        city
        stateAbbreviation
        countryCode
        classification
        gender
        parentOrganizationName
        externalId
        ageGroup
        timeZone
        website
        logo
        createdAt
        updatedAt
      }
      organizationId
      organizationName
      leagueId
      leagueName
      seasonId
      seasonName
      competitionSurveyFeedbackId
      dynamicCompetitionSurveyFeedbackId
      dynamicCompetitionSurveySubFeedbackId
      type
      status
      description
      time
      impactedOutcome
      resolvedDate
      reopenedDate
      createdAt
      updatedAt
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListKeyIncidentsForDynamicCompetitionSurveyQuery__
 *
 * To run a query within a React component, call `useListKeyIncidentsForDynamicCompetitionSurveyQuery` and pass it any options that fit your needs.
 * When your component renders, `useListKeyIncidentsForDynamicCompetitionSurveyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListKeyIncidentsForDynamicCompetitionSurveyQuery({
 *   variables: {
 *      competitionSurveyId: // value for 'competitionSurveyId'
 *   },
 * });
 */
export function useListKeyIncidentsForDynamicCompetitionSurveyQuery(baseOptions: Apollo.QueryHookOptions<ListKeyIncidentsForDynamicCompetitionSurveyQuery, ListKeyIncidentsForDynamicCompetitionSurveyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListKeyIncidentsForDynamicCompetitionSurveyQuery, ListKeyIncidentsForDynamicCompetitionSurveyQueryVariables>(ListKeyIncidentsForDynamicCompetitionSurveyDocument, options);
      }
export function useListKeyIncidentsForDynamicCompetitionSurveyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListKeyIncidentsForDynamicCompetitionSurveyQuery, ListKeyIncidentsForDynamicCompetitionSurveyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListKeyIncidentsForDynamicCompetitionSurveyQuery, ListKeyIncidentsForDynamicCompetitionSurveyQueryVariables>(ListKeyIncidentsForDynamicCompetitionSurveyDocument, options);
        }
export type ListKeyIncidentsForDynamicCompetitionSurveyQueryHookResult = ReturnType<typeof useListKeyIncidentsForDynamicCompetitionSurveyQuery>;
export type ListKeyIncidentsForDynamicCompetitionSurveyLazyQueryHookResult = ReturnType<typeof useListKeyIncidentsForDynamicCompetitionSurveyLazyQuery>;
export type ListKeyIncidentsForDynamicCompetitionSurveyQueryResult = Apollo.QueryResult<ListKeyIncidentsForDynamicCompetitionSurveyQuery, ListKeyIncidentsForDynamicCompetitionSurveyQueryVariables>;
export const SignDynamicCompetitionSurveyDocument = gql`
    mutation signDynamicCompetitionSurvey($competitionSurveyId: String!) {
  signDynamicCompetitionSurvey(input: {competitionSurveyId: $competitionSurveyId}) {
    competitionSurveyId
    signedDate
    userId
    email
    firstName
    lastName
    profilePic
  }
}
    `;
export type SignDynamicCompetitionSurveyMutationFn = Apollo.MutationFunction<SignDynamicCompetitionSurveyMutation, SignDynamicCompetitionSurveyMutationVariables>;

/**
 * __useSignDynamicCompetitionSurveyMutation__
 *
 * To run a mutation, you first call `useSignDynamicCompetitionSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignDynamicCompetitionSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signDynamicCompetitionSurveyMutation, { data, loading, error }] = useSignDynamicCompetitionSurveyMutation({
 *   variables: {
 *      competitionSurveyId: // value for 'competitionSurveyId'
 *   },
 * });
 */
export function useSignDynamicCompetitionSurveyMutation(baseOptions?: Apollo.MutationHookOptions<SignDynamicCompetitionSurveyMutation, SignDynamicCompetitionSurveyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignDynamicCompetitionSurveyMutation, SignDynamicCompetitionSurveyMutationVariables>(SignDynamicCompetitionSurveyDocument, options);
      }
export type SignDynamicCompetitionSurveyMutationHookResult = ReturnType<typeof useSignDynamicCompetitionSurveyMutation>;
export type SignDynamicCompetitionSurveyMutationResult = Apollo.MutationResult<SignDynamicCompetitionSurveyMutation>;
export type SignDynamicCompetitionSurveyMutationOptions = Apollo.BaseMutationOptions<SignDynamicCompetitionSurveyMutation, SignDynamicCompetitionSurveyMutationVariables>;
export const CompleteDynamicCompetitionSurveyDocument = gql`
    mutation completeDynamicCompetitionSurvey($competitionSurveyId: String!) {
  completeDynamicCompetitionSurvey(
    input: {competitionSurveyId: $competitionSurveyId}
  )
}
    `;
export type CompleteDynamicCompetitionSurveyMutationFn = Apollo.MutationFunction<CompleteDynamicCompetitionSurveyMutation, CompleteDynamicCompetitionSurveyMutationVariables>;

/**
 * __useCompleteDynamicCompetitionSurveyMutation__
 *
 * To run a mutation, you first call `useCompleteDynamicCompetitionSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteDynamicCompetitionSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeDynamicCompetitionSurveyMutation, { data, loading, error }] = useCompleteDynamicCompetitionSurveyMutation({
 *   variables: {
 *      competitionSurveyId: // value for 'competitionSurveyId'
 *   },
 * });
 */
export function useCompleteDynamicCompetitionSurveyMutation(baseOptions?: Apollo.MutationHookOptions<CompleteDynamicCompetitionSurveyMutation, CompleteDynamicCompetitionSurveyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteDynamicCompetitionSurveyMutation, CompleteDynamicCompetitionSurveyMutationVariables>(CompleteDynamicCompetitionSurveyDocument, options);
      }
export type CompleteDynamicCompetitionSurveyMutationHookResult = ReturnType<typeof useCompleteDynamicCompetitionSurveyMutation>;
export type CompleteDynamicCompetitionSurveyMutationResult = Apollo.MutationResult<CompleteDynamicCompetitionSurveyMutation>;
export type CompleteDynamicCompetitionSurveyMutationOptions = Apollo.BaseMutationOptions<CompleteDynamicCompetitionSurveyMutation, CompleteDynamicCompetitionSurveyMutationVariables>;
export const ConvertDynamicCompetitionSurveyMultipleResponseToKeyIncidentDocument = gql`
    mutation convertDynamicCompetitionSurveyMultipleResponseToKeyIncident($competitionSurveyId: String!, $questionId: String!, $feedbackResponseId: String!, $incidentType: KeyIncidentTypes!, $impactedOutcome: Boolean!, $time: String, $description: String) {
  convertDynamicCompetitionSurveyMultipleResponseToKeyIncident(
    input: {competitionSurveyId: $competitionSurveyId, questionId: $questionId, feedbackResponseId: $feedbackResponseId, incidentType: $incidentType, impactedOutcome: $impactedOutcome, time: $time, description: $description}
  ) {
    id
    competitionSurveyId
    dynamicCompetitionSurveyId
    submittedBy {
      userId
      email
      firstName
      lastName
      profilePic
      teamId
      teamName
      teamLogo
      officialId
      assessmentObjectId
      assessmentObjectName
    }
    competitionInfo {
      competitionId
      externalId
      sport
      homeTeamId
      homeTeamName
      homeTeamScore
      homeTeamTieBreakerScore
      awayTeamId
      awayTeamName
      awayTeamScore
      awayTeamTieBreakerScore
      startTime
      timeZone
      location
      outcome
      verificationStatus
    }
    assessmentObject {
      id
      sport
      type
      classification
      name
      createdAt
      updatedAt
    }
    official {
      id
      sport
      userId
      status
      email
      firstName
      lastName
      profilePic
      createdAt
      updatedAt
    }
    team {
      id
      name
      abbreviation
      sport
      city
      stateAbbreviation
      countryCode
      classification
      gender
      parentOrganizationName
      externalId
      ageGroup
      timeZone
      website
      logo
      createdAt
      updatedAt
    }
    organizationId
    organizationName
    leagueId
    leagueName
    seasonId
    seasonName
    competitionSurveyFeedbackId
    dynamicCompetitionSurveyFeedbackId
    dynamicCompetitionSurveySubFeedbackId
    type
    status
    description
    time
    impactedOutcome
    resolvedDate
    reopenedDate
    createdAt
    updatedAt
  }
}
    `;
export type ConvertDynamicCompetitionSurveyMultipleResponseToKeyIncidentMutationFn = Apollo.MutationFunction<ConvertDynamicCompetitionSurveyMultipleResponseToKeyIncidentMutation, ConvertDynamicCompetitionSurveyMultipleResponseToKeyIncidentMutationVariables>;

/**
 * __useConvertDynamicCompetitionSurveyMultipleResponseToKeyIncidentMutation__
 *
 * To run a mutation, you first call `useConvertDynamicCompetitionSurveyMultipleResponseToKeyIncidentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConvertDynamicCompetitionSurveyMultipleResponseToKeyIncidentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [convertDynamicCompetitionSurveyMultipleResponseToKeyIncidentMutation, { data, loading, error }] = useConvertDynamicCompetitionSurveyMultipleResponseToKeyIncidentMutation({
 *   variables: {
 *      competitionSurveyId: // value for 'competitionSurveyId'
 *      questionId: // value for 'questionId'
 *      feedbackResponseId: // value for 'feedbackResponseId'
 *      incidentType: // value for 'incidentType'
 *      impactedOutcome: // value for 'impactedOutcome'
 *      time: // value for 'time'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useConvertDynamicCompetitionSurveyMultipleResponseToKeyIncidentMutation(baseOptions?: Apollo.MutationHookOptions<ConvertDynamicCompetitionSurveyMultipleResponseToKeyIncidentMutation, ConvertDynamicCompetitionSurveyMultipleResponseToKeyIncidentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConvertDynamicCompetitionSurveyMultipleResponseToKeyIncidentMutation, ConvertDynamicCompetitionSurveyMultipleResponseToKeyIncidentMutationVariables>(ConvertDynamicCompetitionSurveyMultipleResponseToKeyIncidentDocument, options);
      }
export type ConvertDynamicCompetitionSurveyMultipleResponseToKeyIncidentMutationHookResult = ReturnType<typeof useConvertDynamicCompetitionSurveyMultipleResponseToKeyIncidentMutation>;
export type ConvertDynamicCompetitionSurveyMultipleResponseToKeyIncidentMutationResult = Apollo.MutationResult<ConvertDynamicCompetitionSurveyMultipleResponseToKeyIncidentMutation>;
export type ConvertDynamicCompetitionSurveyMultipleResponseToKeyIncidentMutationOptions = Apollo.BaseMutationOptions<ConvertDynamicCompetitionSurveyMultipleResponseToKeyIncidentMutation, ConvertDynamicCompetitionSurveyMultipleResponseToKeyIncidentMutationVariables>;
export const ConvertDynamicCompetitionSurveySubMultipleResponseToKeyIncidentDocument = gql`
    mutation convertDynamicCompetitionSurveySubMultipleResponseToKeyIncident($competitionSurveyId: String!, $subQuestionId: String!, $feedbackResponseId: String!, $incidentType: KeyIncidentTypes!, $impactedOutcome: Boolean!, $time: String, $description: String) {
  convertDynamicCompetitionSurveySubMultipleResponseToKeyIncident(
    input: {competitionSurveyId: $competitionSurveyId, subQuestionId: $subQuestionId, feedbackResponseId: $feedbackResponseId, incidentType: $incidentType, impactedOutcome: $impactedOutcome, time: $time, description: $description}
  ) {
    id
    competitionSurveyId
    dynamicCompetitionSurveyId
    submittedBy {
      userId
      email
      firstName
      lastName
      profilePic
      teamId
      teamName
      teamLogo
      officialId
      assessmentObjectId
      assessmentObjectName
    }
    competitionInfo {
      competitionId
      externalId
      sport
      homeTeamId
      homeTeamName
      homeTeamScore
      homeTeamTieBreakerScore
      awayTeamId
      awayTeamName
      awayTeamScore
      awayTeamTieBreakerScore
      startTime
      timeZone
      location
      outcome
      verificationStatus
    }
    assessmentObject {
      id
      sport
      type
      classification
      name
      createdAt
      updatedAt
    }
    official {
      id
      sport
      userId
      status
      email
      firstName
      lastName
      profilePic
      createdAt
      updatedAt
    }
    team {
      id
      name
      abbreviation
      sport
      city
      stateAbbreviation
      countryCode
      classification
      gender
      parentOrganizationName
      externalId
      ageGroup
      timeZone
      website
      logo
      createdAt
      updatedAt
    }
    organizationId
    organizationName
    leagueId
    leagueName
    seasonId
    seasonName
    competitionSurveyFeedbackId
    dynamicCompetitionSurveyFeedbackId
    dynamicCompetitionSurveySubFeedbackId
    type
    status
    description
    time
    impactedOutcome
    resolvedDate
    reopenedDate
    createdAt
    updatedAt
  }
}
    `;
export type ConvertDynamicCompetitionSurveySubMultipleResponseToKeyIncidentMutationFn = Apollo.MutationFunction<ConvertDynamicCompetitionSurveySubMultipleResponseToKeyIncidentMutation, ConvertDynamicCompetitionSurveySubMultipleResponseToKeyIncidentMutationVariables>;

/**
 * __useConvertDynamicCompetitionSurveySubMultipleResponseToKeyIncidentMutation__
 *
 * To run a mutation, you first call `useConvertDynamicCompetitionSurveySubMultipleResponseToKeyIncidentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConvertDynamicCompetitionSurveySubMultipleResponseToKeyIncidentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [convertDynamicCompetitionSurveySubMultipleResponseToKeyIncidentMutation, { data, loading, error }] = useConvertDynamicCompetitionSurveySubMultipleResponseToKeyIncidentMutation({
 *   variables: {
 *      competitionSurveyId: // value for 'competitionSurveyId'
 *      subQuestionId: // value for 'subQuestionId'
 *      feedbackResponseId: // value for 'feedbackResponseId'
 *      incidentType: // value for 'incidentType'
 *      impactedOutcome: // value for 'impactedOutcome'
 *      time: // value for 'time'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useConvertDynamicCompetitionSurveySubMultipleResponseToKeyIncidentMutation(baseOptions?: Apollo.MutationHookOptions<ConvertDynamicCompetitionSurveySubMultipleResponseToKeyIncidentMutation, ConvertDynamicCompetitionSurveySubMultipleResponseToKeyIncidentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConvertDynamicCompetitionSurveySubMultipleResponseToKeyIncidentMutation, ConvertDynamicCompetitionSurveySubMultipleResponseToKeyIncidentMutationVariables>(ConvertDynamicCompetitionSurveySubMultipleResponseToKeyIncidentDocument, options);
      }
export type ConvertDynamicCompetitionSurveySubMultipleResponseToKeyIncidentMutationHookResult = ReturnType<typeof useConvertDynamicCompetitionSurveySubMultipleResponseToKeyIncidentMutation>;
export type ConvertDynamicCompetitionSurveySubMultipleResponseToKeyIncidentMutationResult = Apollo.MutationResult<ConvertDynamicCompetitionSurveySubMultipleResponseToKeyIncidentMutation>;
export type ConvertDynamicCompetitionSurveySubMultipleResponseToKeyIncidentMutationOptions = Apollo.BaseMutationOptions<ConvertDynamicCompetitionSurveySubMultipleResponseToKeyIncidentMutation, ConvertDynamicCompetitionSurveySubMultipleResponseToKeyIncidentMutationVariables>;
export const AddKeyIncidentToDynamicCompetitionSurveyDocument = gql`
    mutation addKeyIncidentToDynamicCompetitionSurvey($competitionSurveyId: String!, $description: String!, $incidentType: KeyIncidentTypes!, $impactedOutcome: Boolean!, $time: String, $teamId: String, $sectionId: String, $questionId: String) {
  addKeyIncidentToDynamicCompetitionSurvey(
    input: {competitionSurveyId: $competitionSurveyId, description: $description, incidentType: $incidentType, impactedOutcome: $impactedOutcome, time: $time, teamId: $teamId, sectionId: $sectionId, questionId: $questionId}
  ) {
    id
    competitionSurveyId
    submittedBy {
      userId
      email
      firstName
      lastName
      profilePic
      teamId
      teamName
      teamLogo
      officialId
      assessmentObjectId
      assessmentObjectName
    }
    competitionInfo {
      competitionId
      externalId
      sport
      homeTeamId
      homeTeamName
      homeTeamScore
      homeTeamTieBreakerScore
      awayTeamId
      awayTeamName
      awayTeamScore
      awayTeamTieBreakerScore
      startTime
      timeZone
      location
      outcome
      verificationStatus
    }
    assessmentObject {
      id
      sport
      type
      classification
      name
      createdAt
      updatedAt
    }
    official {
      id
      sport
      userId
      status
      email
      firstName
      lastName
      profilePic
      createdAt
      updatedAt
    }
    team {
      id
      name
      abbreviation
      sport
      city
      stateAbbreviation
      countryCode
      classification
      gender
      parentOrganizationName
      externalId
      ageGroup
      timeZone
      website
      logo
      createdAt
      updatedAt
    }
    organizationId
    organizationName
    leagueId
    leagueName
    seasonId
    seasonName
    competitionSurveyFeedbackId
    dynamicCompetitionSurveyFeedbackId
    dynamicCompetitionSurveySubFeedbackId
    type
    status
    description
    time
    impactedOutcome
    resolvedDate
    reopenedDate
    createdAt
    updatedAt
  }
}
    `;
export type AddKeyIncidentToDynamicCompetitionSurveyMutationFn = Apollo.MutationFunction<AddKeyIncidentToDynamicCompetitionSurveyMutation, AddKeyIncidentToDynamicCompetitionSurveyMutationVariables>;

/**
 * __useAddKeyIncidentToDynamicCompetitionSurveyMutation__
 *
 * To run a mutation, you first call `useAddKeyIncidentToDynamicCompetitionSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddKeyIncidentToDynamicCompetitionSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addKeyIncidentToDynamicCompetitionSurveyMutation, { data, loading, error }] = useAddKeyIncidentToDynamicCompetitionSurveyMutation({
 *   variables: {
 *      competitionSurveyId: // value for 'competitionSurveyId'
 *      description: // value for 'description'
 *      incidentType: // value for 'incidentType'
 *      impactedOutcome: // value for 'impactedOutcome'
 *      time: // value for 'time'
 *      teamId: // value for 'teamId'
 *      sectionId: // value for 'sectionId'
 *      questionId: // value for 'questionId'
 *   },
 * });
 */
export function useAddKeyIncidentToDynamicCompetitionSurveyMutation(baseOptions?: Apollo.MutationHookOptions<AddKeyIncidentToDynamicCompetitionSurveyMutation, AddKeyIncidentToDynamicCompetitionSurveyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddKeyIncidentToDynamicCompetitionSurveyMutation, AddKeyIncidentToDynamicCompetitionSurveyMutationVariables>(AddKeyIncidentToDynamicCompetitionSurveyDocument, options);
      }
export type AddKeyIncidentToDynamicCompetitionSurveyMutationHookResult = ReturnType<typeof useAddKeyIncidentToDynamicCompetitionSurveyMutation>;
export type AddKeyIncidentToDynamicCompetitionSurveyMutationResult = Apollo.MutationResult<AddKeyIncidentToDynamicCompetitionSurveyMutation>;
export type AddKeyIncidentToDynamicCompetitionSurveyMutationOptions = Apollo.BaseMutationOptions<AddKeyIncidentToDynamicCompetitionSurveyMutation, AddKeyIncidentToDynamicCompetitionSurveyMutationVariables>;
export const EditKeyIncidentForDynamicCompetitionSurveyDocument = gql`
    mutation editKeyIncidentForDynamicCompetitionSurvey($competitionSurveyId: String!, $keyIncidentId: String!, $description: String!, $incidentType: KeyIncidentTypes!, $impactedOutcome: Boolean!, $time: String) {
  editKeyIncidentForDynamicCompetitionSurvey(
    input: {competitionSurveyId: $competitionSurveyId, keyIncidentId: $keyIncidentId, description: $description, incidentType: $incidentType, impactedOutcome: $impactedOutcome, time: $time}
  ) {
    id
    competitionSurveyId
    submittedBy {
      userId
      email
      firstName
      lastName
      profilePic
      teamId
      teamName
      teamLogo
      officialId
      assessmentObjectId
      assessmentObjectName
    }
    competitionInfo {
      competitionId
      externalId
      sport
      homeTeamId
      homeTeamName
      homeTeamScore
      homeTeamTieBreakerScore
      awayTeamId
      awayTeamName
      awayTeamScore
      awayTeamTieBreakerScore
      startTime
      timeZone
      location
      outcome
      verificationStatus
    }
    assessmentObject {
      id
      sport
      type
      classification
      name
      createdAt
      updatedAt
    }
    official {
      id
      sport
      userId
      status
      email
      firstName
      lastName
      profilePic
      createdAt
      updatedAt
    }
    team {
      id
      name
      abbreviation
      sport
      city
      stateAbbreviation
      countryCode
      classification
      gender
      parentOrganizationName
      externalId
      ageGroup
      timeZone
      website
      logo
      createdAt
      updatedAt
    }
    organizationId
    organizationName
    leagueId
    leagueName
    seasonId
    seasonName
    competitionSurveyFeedbackId
    dynamicCompetitionSurveyFeedbackId
    dynamicCompetitionSurveySubFeedbackId
    type
    status
    description
    time
    impactedOutcome
    resolvedDate
    reopenedDate
    createdAt
    updatedAt
  }
}
    `;
export type EditKeyIncidentForDynamicCompetitionSurveyMutationFn = Apollo.MutationFunction<EditKeyIncidentForDynamicCompetitionSurveyMutation, EditKeyIncidentForDynamicCompetitionSurveyMutationVariables>;

/**
 * __useEditKeyIncidentForDynamicCompetitionSurveyMutation__
 *
 * To run a mutation, you first call `useEditKeyIncidentForDynamicCompetitionSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditKeyIncidentForDynamicCompetitionSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editKeyIncidentForDynamicCompetitionSurveyMutation, { data, loading, error }] = useEditKeyIncidentForDynamicCompetitionSurveyMutation({
 *   variables: {
 *      competitionSurveyId: // value for 'competitionSurveyId'
 *      keyIncidentId: // value for 'keyIncidentId'
 *      description: // value for 'description'
 *      incidentType: // value for 'incidentType'
 *      impactedOutcome: // value for 'impactedOutcome'
 *      time: // value for 'time'
 *   },
 * });
 */
export function useEditKeyIncidentForDynamicCompetitionSurveyMutation(baseOptions?: Apollo.MutationHookOptions<EditKeyIncidentForDynamicCompetitionSurveyMutation, EditKeyIncidentForDynamicCompetitionSurveyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditKeyIncidentForDynamicCompetitionSurveyMutation, EditKeyIncidentForDynamicCompetitionSurveyMutationVariables>(EditKeyIncidentForDynamicCompetitionSurveyDocument, options);
      }
export type EditKeyIncidentForDynamicCompetitionSurveyMutationHookResult = ReturnType<typeof useEditKeyIncidentForDynamicCompetitionSurveyMutation>;
export type EditKeyIncidentForDynamicCompetitionSurveyMutationResult = Apollo.MutationResult<EditKeyIncidentForDynamicCompetitionSurveyMutation>;
export type EditKeyIncidentForDynamicCompetitionSurveyMutationOptions = Apollo.BaseMutationOptions<EditKeyIncidentForDynamicCompetitionSurveyMutation, EditKeyIncidentForDynamicCompetitionSurveyMutationVariables>;
export const RemoveKeyIncidentFromDynamicCompetitionSurveyDocument = gql`
    mutation removeKeyIncidentFromDynamicCompetitionSurvey($competitionSurveyId: String!, $keyIncidentId: String!) {
  removeKeyIncidentFromDynamicCompetitionSurvey(
    input: {competitionSurveyId: $competitionSurveyId, keyIncidentId: $keyIncidentId}
  )
}
    `;
export type RemoveKeyIncidentFromDynamicCompetitionSurveyMutationFn = Apollo.MutationFunction<RemoveKeyIncidentFromDynamicCompetitionSurveyMutation, RemoveKeyIncidentFromDynamicCompetitionSurveyMutationVariables>;

/**
 * __useRemoveKeyIncidentFromDynamicCompetitionSurveyMutation__
 *
 * To run a mutation, you first call `useRemoveKeyIncidentFromDynamicCompetitionSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveKeyIncidentFromDynamicCompetitionSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeKeyIncidentFromDynamicCompetitionSurveyMutation, { data, loading, error }] = useRemoveKeyIncidentFromDynamicCompetitionSurveyMutation({
 *   variables: {
 *      competitionSurveyId: // value for 'competitionSurveyId'
 *      keyIncidentId: // value for 'keyIncidentId'
 *   },
 * });
 */
export function useRemoveKeyIncidentFromDynamicCompetitionSurveyMutation(baseOptions?: Apollo.MutationHookOptions<RemoveKeyIncidentFromDynamicCompetitionSurveyMutation, RemoveKeyIncidentFromDynamicCompetitionSurveyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveKeyIncidentFromDynamicCompetitionSurveyMutation, RemoveKeyIncidentFromDynamicCompetitionSurveyMutationVariables>(RemoveKeyIncidentFromDynamicCompetitionSurveyDocument, options);
      }
export type RemoveKeyIncidentFromDynamicCompetitionSurveyMutationHookResult = ReturnType<typeof useRemoveKeyIncidentFromDynamicCompetitionSurveyMutation>;
export type RemoveKeyIncidentFromDynamicCompetitionSurveyMutationResult = Apollo.MutationResult<RemoveKeyIncidentFromDynamicCompetitionSurveyMutation>;
export type RemoveKeyIncidentFromDynamicCompetitionSurveyMutationOptions = Apollo.BaseMutationOptions<RemoveKeyIncidentFromDynamicCompetitionSurveyMutation, RemoveKeyIncidentFromDynamicCompetitionSurveyMutationVariables>;
export const GetDynamicCompetitionSurveyByIdWithTokenDocument = gql`
    query getDynamicCompetitionSurveyByIdWithToken($surveyToken: String!) {
  getDynamicCompetitionSurveyByIdWithToken(input: {surveyToken: $surveyToken}) {
    id
    surveyId
    orgId
    seasonId
    sport
    finished
    completedDate
    signature {
      competitionSurveyId
      signedDate
      userId
      email
      firstName
      lastName
      profilePic
    }
    hasSupplementalReport
    allowAdditionalSupplementalReports
    allowAdditionalSupplementalReportsCutoffDays
    hasCompetitionVerification
    hasIncidentReport
    allowAdditionalIncidentReports
    allowAdditionalIncidentReportsCutoffDays
    hasSignatureSection
    signatureText
    createdAt
    updatedAt
    recipients {
      userId
      email
      firstName
      lastName
      profilePic
      tookSurvey
    }
    competition {
      id
      externalId
      sport
      startTime
      timeZone
      location
      homeTeamScore
      homeTeamTieBreakerScore
      awayTeamScore
      awayTeamTieBreakerScore
      homeTeam {
        id
        name
        abbreviation
        sport
        city
        stateAbbreviation
        countryCode
        classification
        gender
        parentOrganizationName
        externalId
        ageGroup
        timeZone
        website
        logo
        createdAt
        updatedAt
      }
      awayTeam {
        id
        name
        abbreviation
        sport
        city
        stateAbbreviation
        countryCode
        classification
        gender
        parentOrganizationName
        externalId
        ageGroup
        timeZone
        website
        logo
        createdAt
        updatedAt
      }
      organization {
        id
        name
        sport
        status
        abbreviation
        site
        logo
        createdAt
        updatedAt
      }
      season {
        id
        leagueId
        leagueName
        leagueLogo
        name
        description
        status
        startDate
        endDate
        createdAt
        updatedAt
      }
      officials {
        id
        competitionId
        assessmentObjectId
        order
        official {
          id
          sport
          userId
          status
          email
          firstName
          lastName
          profilePic
          createdAt
          updatedAt
        }
        type
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
    info {
      name
      description
      audience
    }
    sections {
      id
      name
      description
      requireSupplementalReport
      finished
      order
      createdAt
      updatedAt
      questions {
        id
        name
        description
        additionalText1
        additionalText2
        additionalText3
        order
        isRequired
        canAddToKeyIncidents
        assessmentObject {
          id
          sport
          type
          classification
          name
        }
        coach {
          id
          teamId
          teamName
          teamAbbreviation
          sport
          teamCity
          userId
          type
          role
          status
          email
          firstName
          lastName
          profilePic
          createdAt
          updatedAt
        }
        team {
          id
          name
          abbreviation
          sport
          city
          stateAbbreviation
          countryCode
          timeZone
          classification
          gender
          website
          logo
          parentOrganizationName
          externalId
          ageGroup
          createdAt
          updatedAt
        }
        official {
          id
          sport
          userId
          status
          email
          firstName
          lastName
          externalIds {
            id
            organizationId
            externalId
          }
          profilePic
          createdAt
          updatedAt
        }
        organization {
          id
          name
          sport
          status
          abbreviation
          site
          logo
          createdAt
          updatedAt
        }
        questionType
        selectedOption
        selectedOptionOrder
        responseShortAnswer
        responseText
        responseNumber
        responseBoolean
        responses {
          id
          value
          createdAt
          updatedAt
        }
        fileUploads {
          id
          name
          comment
          fileUpload
          createdAt
          updatedAt
        }
        options {
          id
          value
          order
        }
        subQuestions {
          id
          name
          questionText
          order
          isRequired
          canAddToKeyIncidents
          questionType
          selectedOption
          selectedOptionOrder
          responseShortAnswer
          responseText
          responseNumber
          responseBoolean
          responses {
            id
            value
            createdAt
            updatedAt
          }
          fileUploads {
            id
            name
            comment
            fileUpload
            createdAt
            updatedAt
          }
          options {
            id
            value
            order
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetDynamicCompetitionSurveyByIdWithTokenQuery__
 *
 * To run a query within a React component, call `useGetDynamicCompetitionSurveyByIdWithTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDynamicCompetitionSurveyByIdWithTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDynamicCompetitionSurveyByIdWithTokenQuery({
 *   variables: {
 *      surveyToken: // value for 'surveyToken'
 *   },
 * });
 */
export function useGetDynamicCompetitionSurveyByIdWithTokenQuery(baseOptions: Apollo.QueryHookOptions<GetDynamicCompetitionSurveyByIdWithTokenQuery, GetDynamicCompetitionSurveyByIdWithTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDynamicCompetitionSurveyByIdWithTokenQuery, GetDynamicCompetitionSurveyByIdWithTokenQueryVariables>(GetDynamicCompetitionSurveyByIdWithTokenDocument, options);
      }
export function useGetDynamicCompetitionSurveyByIdWithTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDynamicCompetitionSurveyByIdWithTokenQuery, GetDynamicCompetitionSurveyByIdWithTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDynamicCompetitionSurveyByIdWithTokenQuery, GetDynamicCompetitionSurveyByIdWithTokenQueryVariables>(GetDynamicCompetitionSurveyByIdWithTokenDocument, options);
        }
export type GetDynamicCompetitionSurveyByIdWithTokenQueryHookResult = ReturnType<typeof useGetDynamicCompetitionSurveyByIdWithTokenQuery>;
export type GetDynamicCompetitionSurveyByIdWithTokenLazyQueryHookResult = ReturnType<typeof useGetDynamicCompetitionSurveyByIdWithTokenLazyQuery>;
export type GetDynamicCompetitionSurveyByIdWithTokenQueryResult = Apollo.QueryResult<GetDynamicCompetitionSurveyByIdWithTokenQuery, GetDynamicCompetitionSurveyByIdWithTokenQueryVariables>;
export const ListStatsForDynamicCompetitionSurveyByIdWithTokenDocument = gql`
    query listStatsForDynamicCompetitionSurveyByIdWithToken($surveyToken: String!) {
  listStatsForDynamicCompetitionSurveyByIdWithToken(
    input: {surveyToken: $surveyToken}
  ) {
    id
    competitionId
    teamId
    name
    displayText
    valueString
    valueNumeric
    valueDate
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useListStatsForDynamicCompetitionSurveyByIdWithTokenQuery__
 *
 * To run a query within a React component, call `useListStatsForDynamicCompetitionSurveyByIdWithTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useListStatsForDynamicCompetitionSurveyByIdWithTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListStatsForDynamicCompetitionSurveyByIdWithTokenQuery({
 *   variables: {
 *      surveyToken: // value for 'surveyToken'
 *   },
 * });
 */
export function useListStatsForDynamicCompetitionSurveyByIdWithTokenQuery(baseOptions: Apollo.QueryHookOptions<ListStatsForDynamicCompetitionSurveyByIdWithTokenQuery, ListStatsForDynamicCompetitionSurveyByIdWithTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListStatsForDynamicCompetitionSurveyByIdWithTokenQuery, ListStatsForDynamicCompetitionSurveyByIdWithTokenQueryVariables>(ListStatsForDynamicCompetitionSurveyByIdWithTokenDocument, options);
      }
export function useListStatsForDynamicCompetitionSurveyByIdWithTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListStatsForDynamicCompetitionSurveyByIdWithTokenQuery, ListStatsForDynamicCompetitionSurveyByIdWithTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListStatsForDynamicCompetitionSurveyByIdWithTokenQuery, ListStatsForDynamicCompetitionSurveyByIdWithTokenQueryVariables>(ListStatsForDynamicCompetitionSurveyByIdWithTokenDocument, options);
        }
export type ListStatsForDynamicCompetitionSurveyByIdWithTokenQueryHookResult = ReturnType<typeof useListStatsForDynamicCompetitionSurveyByIdWithTokenQuery>;
export type ListStatsForDynamicCompetitionSurveyByIdWithTokenLazyQueryHookResult = ReturnType<typeof useListStatsForDynamicCompetitionSurveyByIdWithTokenLazyQuery>;
export type ListStatsForDynamicCompetitionSurveyByIdWithTokenQueryResult = Apollo.QueryResult<ListStatsForDynamicCompetitionSurveyByIdWithTokenQuery, ListStatsForDynamicCompetitionSurveyByIdWithTokenQueryVariables>;
export const StartDynamicCompetitionSurveyWithTokenDocument = gql`
    mutation startDynamicCompetitionSurveyWithToken($surveyToken: String!) {
  startDynamicCompetitionSurveyWithToken(input: {surveyToken: $surveyToken})
}
    `;
export type StartDynamicCompetitionSurveyWithTokenMutationFn = Apollo.MutationFunction<StartDynamicCompetitionSurveyWithTokenMutation, StartDynamicCompetitionSurveyWithTokenMutationVariables>;

/**
 * __useStartDynamicCompetitionSurveyWithTokenMutation__
 *
 * To run a mutation, you first call `useStartDynamicCompetitionSurveyWithTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartDynamicCompetitionSurveyWithTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startDynamicCompetitionSurveyWithTokenMutation, { data, loading, error }] = useStartDynamicCompetitionSurveyWithTokenMutation({
 *   variables: {
 *      surveyToken: // value for 'surveyToken'
 *   },
 * });
 */
export function useStartDynamicCompetitionSurveyWithTokenMutation(baseOptions?: Apollo.MutationHookOptions<StartDynamicCompetitionSurveyWithTokenMutation, StartDynamicCompetitionSurveyWithTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartDynamicCompetitionSurveyWithTokenMutation, StartDynamicCompetitionSurveyWithTokenMutationVariables>(StartDynamicCompetitionSurveyWithTokenDocument, options);
      }
export type StartDynamicCompetitionSurveyWithTokenMutationHookResult = ReturnType<typeof useStartDynamicCompetitionSurveyWithTokenMutation>;
export type StartDynamicCompetitionSurveyWithTokenMutationResult = Apollo.MutationResult<StartDynamicCompetitionSurveyWithTokenMutation>;
export type StartDynamicCompetitionSurveyWithTokenMutationOptions = Apollo.BaseMutationOptions<StartDynamicCompetitionSurveyWithTokenMutation, StartDynamicCompetitionSurveyWithTokenMutationVariables>;
export const UpdateDynamicCompetitionSurveyCompetitionScoreWithTokenDocument = gql`
    mutation updateDynamicCompetitionSurveyCompetitionScoreWithToken($surveyToken: String!, $homeTeamScore: Int!, $awayTeamScore: Int!, $homeTeamTieBreakerScore: Int, $awayTeamTieBreakerScore: Int) {
  updateDynamicCompetitionSurveyCompetitionScoreWithToken(
    input: {surveyToken: $surveyToken, homeTeamScore: $homeTeamScore, awayTeamScore: $awayTeamScore, homeTeamTieBreakerScore: $homeTeamTieBreakerScore, awayTeamTieBreakerScore: $awayTeamTieBreakerScore}
  )
}
    `;
export type UpdateDynamicCompetitionSurveyCompetitionScoreWithTokenMutationFn = Apollo.MutationFunction<UpdateDynamicCompetitionSurveyCompetitionScoreWithTokenMutation, UpdateDynamicCompetitionSurveyCompetitionScoreWithTokenMutationVariables>;

/**
 * __useUpdateDynamicCompetitionSurveyCompetitionScoreWithTokenMutation__
 *
 * To run a mutation, you first call `useUpdateDynamicCompetitionSurveyCompetitionScoreWithTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDynamicCompetitionSurveyCompetitionScoreWithTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDynamicCompetitionSurveyCompetitionScoreWithTokenMutation, { data, loading, error }] = useUpdateDynamicCompetitionSurveyCompetitionScoreWithTokenMutation({
 *   variables: {
 *      surveyToken: // value for 'surveyToken'
 *      homeTeamScore: // value for 'homeTeamScore'
 *      awayTeamScore: // value for 'awayTeamScore'
 *      homeTeamTieBreakerScore: // value for 'homeTeamTieBreakerScore'
 *      awayTeamTieBreakerScore: // value for 'awayTeamTieBreakerScore'
 *   },
 * });
 */
export function useUpdateDynamicCompetitionSurveyCompetitionScoreWithTokenMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDynamicCompetitionSurveyCompetitionScoreWithTokenMutation, UpdateDynamicCompetitionSurveyCompetitionScoreWithTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDynamicCompetitionSurveyCompetitionScoreWithTokenMutation, UpdateDynamicCompetitionSurveyCompetitionScoreWithTokenMutationVariables>(UpdateDynamicCompetitionSurveyCompetitionScoreWithTokenDocument, options);
      }
export type UpdateDynamicCompetitionSurveyCompetitionScoreWithTokenMutationHookResult = ReturnType<typeof useUpdateDynamicCompetitionSurveyCompetitionScoreWithTokenMutation>;
export type UpdateDynamicCompetitionSurveyCompetitionScoreWithTokenMutationResult = Apollo.MutationResult<UpdateDynamicCompetitionSurveyCompetitionScoreWithTokenMutation>;
export type UpdateDynamicCompetitionSurveyCompetitionScoreWithTokenMutationOptions = Apollo.BaseMutationOptions<UpdateDynamicCompetitionSurveyCompetitionScoreWithTokenMutation, UpdateDynamicCompetitionSurveyCompetitionScoreWithTokenMutationVariables>;
export const UpdateDynamicCompetitionSurveyMultipleChoiceResponseWithTokenDocument = gql`
    mutation updateDynamicCompetitionSurveyMultipleChoiceResponseWithToken($surveyToken: String!, $questionId: String!, $selectedOptionId: String!) {
  updateDynamicCompetitionSurveyMultipleChoiceResponseWithToken(
    input: {surveyToken: $surveyToken, questionId: $questionId, selectedOptionId: $selectedOptionId}
  ) {
    id
    name
    description
    additionalText1
    additionalText2
    additionalText3
    order
    isRequired
    canAddToKeyIncidents
    assessmentObject {
      id
      sport
      type
      classification
      name
    }
    coach {
      id
      teamId
      teamName
      teamAbbreviation
      sport
      teamCity
      userId
      type
      role
      status
      email
      firstName
      lastName
      profilePic
      createdAt
      updatedAt
    }
    team {
      id
      name
      abbreviation
      sport
      city
      stateAbbreviation
      countryCode
      timeZone
      classification
      gender
      website
      logo
      parentOrganizationName
      externalId
      ageGroup
      createdAt
      updatedAt
    }
    official {
      id
      sport
      userId
      status
      email
      firstName
      lastName
      externalIds {
        id
        organizationId
        externalId
      }
      profilePic
      createdAt
      updatedAt
    }
    organization {
      id
      name
      sport
      status
      abbreviation
      site
      logo
      createdAt
      updatedAt
    }
    questionType
    selectedOption
    selectedOptionOrder
    responseShortAnswer
    responseText
    responseNumber
    responseBoolean
    responses {
      id
      value
      createdAt
      updatedAt
    }
    fileUploads {
      id
      name
      comment
      fileUpload
      createdAt
      updatedAt
    }
    options {
      id
      value
      order
    }
    subQuestions {
      id
      name
      questionText
      order
      isRequired
      canAddToKeyIncidents
      questionType
      selectedOption
      selectedOptionOrder
      responseShortAnswer
      responseText
      responseNumber
      responseBoolean
      responses {
        id
        value
        createdAt
        updatedAt
      }
      fileUploads {
        id
        name
        comment
        fileUpload
        createdAt
        updatedAt
      }
      options {
        id
        value
        order
      }
    }
  }
}
    `;
export type UpdateDynamicCompetitionSurveyMultipleChoiceResponseWithTokenMutationFn = Apollo.MutationFunction<UpdateDynamicCompetitionSurveyMultipleChoiceResponseWithTokenMutation, UpdateDynamicCompetitionSurveyMultipleChoiceResponseWithTokenMutationVariables>;

/**
 * __useUpdateDynamicCompetitionSurveyMultipleChoiceResponseWithTokenMutation__
 *
 * To run a mutation, you first call `useUpdateDynamicCompetitionSurveyMultipleChoiceResponseWithTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDynamicCompetitionSurveyMultipleChoiceResponseWithTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDynamicCompetitionSurveyMultipleChoiceResponseWithTokenMutation, { data, loading, error }] = useUpdateDynamicCompetitionSurveyMultipleChoiceResponseWithTokenMutation({
 *   variables: {
 *      surveyToken: // value for 'surveyToken'
 *      questionId: // value for 'questionId'
 *      selectedOptionId: // value for 'selectedOptionId'
 *   },
 * });
 */
export function useUpdateDynamicCompetitionSurveyMultipleChoiceResponseWithTokenMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDynamicCompetitionSurveyMultipleChoiceResponseWithTokenMutation, UpdateDynamicCompetitionSurveyMultipleChoiceResponseWithTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDynamicCompetitionSurveyMultipleChoiceResponseWithTokenMutation, UpdateDynamicCompetitionSurveyMultipleChoiceResponseWithTokenMutationVariables>(UpdateDynamicCompetitionSurveyMultipleChoiceResponseWithTokenDocument, options);
      }
export type UpdateDynamicCompetitionSurveyMultipleChoiceResponseWithTokenMutationHookResult = ReturnType<typeof useUpdateDynamicCompetitionSurveyMultipleChoiceResponseWithTokenMutation>;
export type UpdateDynamicCompetitionSurveyMultipleChoiceResponseWithTokenMutationResult = Apollo.MutationResult<UpdateDynamicCompetitionSurveyMultipleChoiceResponseWithTokenMutation>;
export type UpdateDynamicCompetitionSurveyMultipleChoiceResponseWithTokenMutationOptions = Apollo.BaseMutationOptions<UpdateDynamicCompetitionSurveyMultipleChoiceResponseWithTokenMutation, UpdateDynamicCompetitionSurveyMultipleChoiceResponseWithTokenMutationVariables>;
export const UpdateDynamicCompetitionSurveyShortAnswerResponseWithTokenDocument = gql`
    mutation updateDynamicCompetitionSurveyShortAnswerResponseWithToken($surveyToken: String!, $questionId: String!, $response: String!) {
  updateDynamicCompetitionSurveyShortAnswerResponseWithToken(
    input: {surveyToken: $surveyToken, questionId: $questionId, response: $response}
  ) {
    id
    name
    description
    additionalText1
    additionalText2
    additionalText3
    order
    isRequired
    canAddToKeyIncidents
    assessmentObject {
      id
      sport
      type
      classification
      name
    }
    coach {
      id
      teamId
      teamName
      teamAbbreviation
      sport
      teamCity
      userId
      type
      role
      status
      email
      firstName
      lastName
      profilePic
      createdAt
      updatedAt
    }
    team {
      id
      name
      abbreviation
      sport
      city
      stateAbbreviation
      countryCode
      timeZone
      classification
      gender
      website
      logo
      parentOrganizationName
      externalId
      ageGroup
      createdAt
      updatedAt
    }
    official {
      id
      sport
      userId
      status
      email
      firstName
      lastName
      externalIds {
        id
        organizationId
        externalId
      }
      profilePic
      createdAt
      updatedAt
    }
    organization {
      id
      name
      sport
      status
      abbreviation
      site
      logo
      createdAt
      updatedAt
    }
    questionType
    selectedOption
    selectedOptionOrder
    responseShortAnswer
    responseText
    responseNumber
    responseBoolean
    responses {
      id
      value
      createdAt
      updatedAt
    }
    fileUploads {
      id
      name
      comment
      fileUpload
      createdAt
      updatedAt
    }
    options {
      id
      value
      order
    }
    subQuestions {
      id
      name
      questionText
      order
      isRequired
      canAddToKeyIncidents
      questionType
      selectedOption
      selectedOptionOrder
      responseShortAnswer
      responseText
      responseNumber
      responseBoolean
      responses {
        id
        value
        createdAt
        updatedAt
      }
      fileUploads {
        id
        name
        comment
        fileUpload
        createdAt
        updatedAt
      }
      options {
        id
        value
        order
      }
    }
  }
}
    `;
export type UpdateDynamicCompetitionSurveyShortAnswerResponseWithTokenMutationFn = Apollo.MutationFunction<UpdateDynamicCompetitionSurveyShortAnswerResponseWithTokenMutation, UpdateDynamicCompetitionSurveyShortAnswerResponseWithTokenMutationVariables>;

/**
 * __useUpdateDynamicCompetitionSurveyShortAnswerResponseWithTokenMutation__
 *
 * To run a mutation, you first call `useUpdateDynamicCompetitionSurveyShortAnswerResponseWithTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDynamicCompetitionSurveyShortAnswerResponseWithTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDynamicCompetitionSurveyShortAnswerResponseWithTokenMutation, { data, loading, error }] = useUpdateDynamicCompetitionSurveyShortAnswerResponseWithTokenMutation({
 *   variables: {
 *      surveyToken: // value for 'surveyToken'
 *      questionId: // value for 'questionId'
 *      response: // value for 'response'
 *   },
 * });
 */
export function useUpdateDynamicCompetitionSurveyShortAnswerResponseWithTokenMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDynamicCompetitionSurveyShortAnswerResponseWithTokenMutation, UpdateDynamicCompetitionSurveyShortAnswerResponseWithTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDynamicCompetitionSurveyShortAnswerResponseWithTokenMutation, UpdateDynamicCompetitionSurveyShortAnswerResponseWithTokenMutationVariables>(UpdateDynamicCompetitionSurveyShortAnswerResponseWithTokenDocument, options);
      }
export type UpdateDynamicCompetitionSurveyShortAnswerResponseWithTokenMutationHookResult = ReturnType<typeof useUpdateDynamicCompetitionSurveyShortAnswerResponseWithTokenMutation>;
export type UpdateDynamicCompetitionSurveyShortAnswerResponseWithTokenMutationResult = Apollo.MutationResult<UpdateDynamicCompetitionSurveyShortAnswerResponseWithTokenMutation>;
export type UpdateDynamicCompetitionSurveyShortAnswerResponseWithTokenMutationOptions = Apollo.BaseMutationOptions<UpdateDynamicCompetitionSurveyShortAnswerResponseWithTokenMutation, UpdateDynamicCompetitionSurveyShortAnswerResponseWithTokenMutationVariables>;
export const UpdateDynamicCompetitionSurveyTextResponseWithTokenDocument = gql`
    mutation updateDynamicCompetitionSurveyTextResponseWithToken($surveyToken: String!, $questionId: String!, $response: String!) {
  updateDynamicCompetitionSurveyTextResponseWithToken(
    input: {surveyToken: $surveyToken, questionId: $questionId, response: $response}
  ) {
    id
    name
    description
    additionalText1
    additionalText2
    additionalText3
    order
    isRequired
    canAddToKeyIncidents
    assessmentObject {
      id
      sport
      type
      classification
      name
    }
    coach {
      id
      teamId
      teamName
      teamAbbreviation
      sport
      teamCity
      userId
      type
      role
      status
      email
      firstName
      lastName
      profilePic
      createdAt
      updatedAt
    }
    team {
      id
      name
      abbreviation
      sport
      city
      stateAbbreviation
      countryCode
      timeZone
      classification
      gender
      website
      logo
      parentOrganizationName
      externalId
      ageGroup
      createdAt
      updatedAt
    }
    official {
      id
      sport
      userId
      status
      email
      firstName
      lastName
      externalIds {
        id
        organizationId
        externalId
      }
      profilePic
      createdAt
      updatedAt
    }
    organization {
      id
      name
      sport
      status
      abbreviation
      site
      logo
      createdAt
      updatedAt
    }
    questionType
    selectedOption
    selectedOptionOrder
    responseShortAnswer
    responseText
    responseNumber
    responseBoolean
    responses {
      id
      value
      createdAt
      updatedAt
    }
    fileUploads {
      id
      name
      comment
      fileUpload
      createdAt
      updatedAt
    }
    options {
      id
      value
      order
    }
    subQuestions {
      id
      name
      questionText
      order
      isRequired
      canAddToKeyIncidents
      questionType
      selectedOption
      selectedOptionOrder
      responseShortAnswer
      responseText
      responseNumber
      responseBoolean
      responses {
        id
        value
        createdAt
        updatedAt
      }
      fileUploads {
        id
        name
        comment
        fileUpload
        createdAt
        updatedAt
      }
      options {
        id
        value
        order
      }
    }
  }
}
    `;
export type UpdateDynamicCompetitionSurveyTextResponseWithTokenMutationFn = Apollo.MutationFunction<UpdateDynamicCompetitionSurveyTextResponseWithTokenMutation, UpdateDynamicCompetitionSurveyTextResponseWithTokenMutationVariables>;

/**
 * __useUpdateDynamicCompetitionSurveyTextResponseWithTokenMutation__
 *
 * To run a mutation, you first call `useUpdateDynamicCompetitionSurveyTextResponseWithTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDynamicCompetitionSurveyTextResponseWithTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDynamicCompetitionSurveyTextResponseWithTokenMutation, { data, loading, error }] = useUpdateDynamicCompetitionSurveyTextResponseWithTokenMutation({
 *   variables: {
 *      surveyToken: // value for 'surveyToken'
 *      questionId: // value for 'questionId'
 *      response: // value for 'response'
 *   },
 * });
 */
export function useUpdateDynamicCompetitionSurveyTextResponseWithTokenMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDynamicCompetitionSurveyTextResponseWithTokenMutation, UpdateDynamicCompetitionSurveyTextResponseWithTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDynamicCompetitionSurveyTextResponseWithTokenMutation, UpdateDynamicCompetitionSurveyTextResponseWithTokenMutationVariables>(UpdateDynamicCompetitionSurveyTextResponseWithTokenDocument, options);
      }
export type UpdateDynamicCompetitionSurveyTextResponseWithTokenMutationHookResult = ReturnType<typeof useUpdateDynamicCompetitionSurveyTextResponseWithTokenMutation>;
export type UpdateDynamicCompetitionSurveyTextResponseWithTokenMutationResult = Apollo.MutationResult<UpdateDynamicCompetitionSurveyTextResponseWithTokenMutation>;
export type UpdateDynamicCompetitionSurveyTextResponseWithTokenMutationOptions = Apollo.BaseMutationOptions<UpdateDynamicCompetitionSurveyTextResponseWithTokenMutation, UpdateDynamicCompetitionSurveyTextResponseWithTokenMutationVariables>;
export const UpdateDynamicCompetitionSurveyNumberResponseWithTokenDocument = gql`
    mutation updateDynamicCompetitionSurveyNumberResponseWithToken($surveyToken: String!, $questionId: String!, $response: Float!) {
  updateDynamicCompetitionSurveyNumberResponseWithToken(
    input: {surveyToken: $surveyToken, questionId: $questionId, response: $response}
  ) {
    id
    name
    description
    order
    isRequired
    canAddToKeyIncidents
    questionType
    selectedOption
    selectedOptionOrder
    responseShortAnswer
    responseText
    responseNumber
    responseBoolean
    responses {
      id
      value
      createdAt
      updatedAt
    }
    fileUploads {
      id
      name
      comment
      fileUpload
      createdAt
      updatedAt
    }
    options {
      id
      value
      order
    }
    subQuestions {
      id
      name
      questionText
      order
      isRequired
      canAddToKeyIncidents
      questionType
      selectedOption
      selectedOptionOrder
      responseShortAnswer
      responseText
      responseNumber
      responseBoolean
      responses {
        id
        value
        createdAt
        updatedAt
      }
      fileUploads {
        id
        name
        comment
        fileUpload
        createdAt
        updatedAt
      }
      options {
        id
        value
        order
      }
    }
  }
}
    `;
export type UpdateDynamicCompetitionSurveyNumberResponseWithTokenMutationFn = Apollo.MutationFunction<UpdateDynamicCompetitionSurveyNumberResponseWithTokenMutation, UpdateDynamicCompetitionSurveyNumberResponseWithTokenMutationVariables>;

/**
 * __useUpdateDynamicCompetitionSurveyNumberResponseWithTokenMutation__
 *
 * To run a mutation, you first call `useUpdateDynamicCompetitionSurveyNumberResponseWithTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDynamicCompetitionSurveyNumberResponseWithTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDynamicCompetitionSurveyNumberResponseWithTokenMutation, { data, loading, error }] = useUpdateDynamicCompetitionSurveyNumberResponseWithTokenMutation({
 *   variables: {
 *      surveyToken: // value for 'surveyToken'
 *      questionId: // value for 'questionId'
 *      response: // value for 'response'
 *   },
 * });
 */
export function useUpdateDynamicCompetitionSurveyNumberResponseWithTokenMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDynamicCompetitionSurveyNumberResponseWithTokenMutation, UpdateDynamicCompetitionSurveyNumberResponseWithTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDynamicCompetitionSurveyNumberResponseWithTokenMutation, UpdateDynamicCompetitionSurveyNumberResponseWithTokenMutationVariables>(UpdateDynamicCompetitionSurveyNumberResponseWithTokenDocument, options);
      }
export type UpdateDynamicCompetitionSurveyNumberResponseWithTokenMutationHookResult = ReturnType<typeof useUpdateDynamicCompetitionSurveyNumberResponseWithTokenMutation>;
export type UpdateDynamicCompetitionSurveyNumberResponseWithTokenMutationResult = Apollo.MutationResult<UpdateDynamicCompetitionSurveyNumberResponseWithTokenMutation>;
export type UpdateDynamicCompetitionSurveyNumberResponseWithTokenMutationOptions = Apollo.BaseMutationOptions<UpdateDynamicCompetitionSurveyNumberResponseWithTokenMutation, UpdateDynamicCompetitionSurveyNumberResponseWithTokenMutationVariables>;
export const UpdateDynamicCompetitionSurveyBooleanResponseWithTokenDocument = gql`
    mutation updateDynamicCompetitionSurveyBooleanResponseWithToken($surveyToken: String!, $questionId: String!, $response: Boolean!) {
  updateDynamicCompetitionSurveyBooleanResponseWithToken(
    input: {surveyToken: $surveyToken, questionId: $questionId, response: $response}
  ) {
    id
    name
    description
    additionalText1
    additionalText2
    additionalText3
    order
    isRequired
    canAddToKeyIncidents
    assessmentObject {
      id
      sport
      type
      classification
      name
    }
    coach {
      id
      teamId
      teamName
      teamAbbreviation
      sport
      teamCity
      userId
      type
      role
      status
      email
      firstName
      lastName
      profilePic
      createdAt
      updatedAt
    }
    team {
      id
      name
      abbreviation
      sport
      city
      stateAbbreviation
      countryCode
      timeZone
      classification
      gender
      website
      logo
      parentOrganizationName
      externalId
      ageGroup
      createdAt
      updatedAt
    }
    official {
      id
      sport
      userId
      status
      email
      firstName
      lastName
      externalIds {
        id
        organizationId
        externalId
      }
      profilePic
      createdAt
      updatedAt
    }
    organization {
      id
      name
      sport
      status
      abbreviation
      site
      logo
      createdAt
      updatedAt
    }
    questionType
    selectedOption
    selectedOptionOrder
    responseShortAnswer
    responseText
    responseNumber
    responseBoolean
    responses {
      id
      value
      createdAt
      updatedAt
    }
    fileUploads {
      id
      name
      comment
      fileUpload
      createdAt
      updatedAt
    }
    options {
      id
      value
      order
    }
    subQuestions {
      id
      name
      questionText
      order
      isRequired
      canAddToKeyIncidents
      questionType
      selectedOption
      selectedOptionOrder
      responseShortAnswer
      responseText
      responseNumber
      responseBoolean
      responses {
        id
        value
        createdAt
        updatedAt
      }
      fileUploads {
        id
        name
        comment
        fileUpload
        createdAt
        updatedAt
      }
      options {
        id
        value
        order
      }
    }
  }
}
    `;
export type UpdateDynamicCompetitionSurveyBooleanResponseWithTokenMutationFn = Apollo.MutationFunction<UpdateDynamicCompetitionSurveyBooleanResponseWithTokenMutation, UpdateDynamicCompetitionSurveyBooleanResponseWithTokenMutationVariables>;

/**
 * __useUpdateDynamicCompetitionSurveyBooleanResponseWithTokenMutation__
 *
 * To run a mutation, you first call `useUpdateDynamicCompetitionSurveyBooleanResponseWithTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDynamicCompetitionSurveyBooleanResponseWithTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDynamicCompetitionSurveyBooleanResponseWithTokenMutation, { data, loading, error }] = useUpdateDynamicCompetitionSurveyBooleanResponseWithTokenMutation({
 *   variables: {
 *      surveyToken: // value for 'surveyToken'
 *      questionId: // value for 'questionId'
 *      response: // value for 'response'
 *   },
 * });
 */
export function useUpdateDynamicCompetitionSurveyBooleanResponseWithTokenMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDynamicCompetitionSurveyBooleanResponseWithTokenMutation, UpdateDynamicCompetitionSurveyBooleanResponseWithTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDynamicCompetitionSurveyBooleanResponseWithTokenMutation, UpdateDynamicCompetitionSurveyBooleanResponseWithTokenMutationVariables>(UpdateDynamicCompetitionSurveyBooleanResponseWithTokenDocument, options);
      }
export type UpdateDynamicCompetitionSurveyBooleanResponseWithTokenMutationHookResult = ReturnType<typeof useUpdateDynamicCompetitionSurveyBooleanResponseWithTokenMutation>;
export type UpdateDynamicCompetitionSurveyBooleanResponseWithTokenMutationResult = Apollo.MutationResult<UpdateDynamicCompetitionSurveyBooleanResponseWithTokenMutation>;
export type UpdateDynamicCompetitionSurveyBooleanResponseWithTokenMutationOptions = Apollo.BaseMutationOptions<UpdateDynamicCompetitionSurveyBooleanResponseWithTokenMutation, UpdateDynamicCompetitionSurveyBooleanResponseWithTokenMutationVariables>;
export const AddDynamicCompetitionSurveyMultipleResponseWithTokenDocument = gql`
    mutation addDynamicCompetitionSurveyMultipleResponseWithToken($surveyToken: String!, $questionId: String!, $response: String!) {
  addDynamicCompetitionSurveyMultipleResponseWithToken(
    input: {surveyToken: $surveyToken, questionId: $questionId, response: $response}
  ) {
    id
    value
    createdAt
    updatedAt
  }
}
    `;
export type AddDynamicCompetitionSurveyMultipleResponseWithTokenMutationFn = Apollo.MutationFunction<AddDynamicCompetitionSurveyMultipleResponseWithTokenMutation, AddDynamicCompetitionSurveyMultipleResponseWithTokenMutationVariables>;

/**
 * __useAddDynamicCompetitionSurveyMultipleResponseWithTokenMutation__
 *
 * To run a mutation, you first call `useAddDynamicCompetitionSurveyMultipleResponseWithTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDynamicCompetitionSurveyMultipleResponseWithTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDynamicCompetitionSurveyMultipleResponseWithTokenMutation, { data, loading, error }] = useAddDynamicCompetitionSurveyMultipleResponseWithTokenMutation({
 *   variables: {
 *      surveyToken: // value for 'surveyToken'
 *      questionId: // value for 'questionId'
 *      response: // value for 'response'
 *   },
 * });
 */
export function useAddDynamicCompetitionSurveyMultipleResponseWithTokenMutation(baseOptions?: Apollo.MutationHookOptions<AddDynamicCompetitionSurveyMultipleResponseWithTokenMutation, AddDynamicCompetitionSurveyMultipleResponseWithTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddDynamicCompetitionSurveyMultipleResponseWithTokenMutation, AddDynamicCompetitionSurveyMultipleResponseWithTokenMutationVariables>(AddDynamicCompetitionSurveyMultipleResponseWithTokenDocument, options);
      }
export type AddDynamicCompetitionSurveyMultipleResponseWithTokenMutationHookResult = ReturnType<typeof useAddDynamicCompetitionSurveyMultipleResponseWithTokenMutation>;
export type AddDynamicCompetitionSurveyMultipleResponseWithTokenMutationResult = Apollo.MutationResult<AddDynamicCompetitionSurveyMultipleResponseWithTokenMutation>;
export type AddDynamicCompetitionSurveyMultipleResponseWithTokenMutationOptions = Apollo.BaseMutationOptions<AddDynamicCompetitionSurveyMultipleResponseWithTokenMutation, AddDynamicCompetitionSurveyMultipleResponseWithTokenMutationVariables>;
export const RemoveDynamicCompetitionSurveyMultipleResponseWithTokenDocument = gql`
    mutation removeDynamicCompetitionSurveyMultipleResponseWithToken($surveyToken: String!, $questionId: String!, $responseId: String!) {
  removeDynamicCompetitionSurveyMultipleResponseWithToken(
    input: {surveyToken: $surveyToken, questionId: $questionId, responseId: $responseId}
  ) {
    id
    name
    description
    order
    isRequired
    canAddToKeyIncidents
    questionType
    selectedOption
    selectedOptionOrder
    responseShortAnswer
    responseText
    responseNumber
    responseBoolean
    responses {
      id
      value
      createdAt
      updatedAt
    }
    fileUploads {
      id
      name
      comment
      fileUpload
      createdAt
      updatedAt
    }
    options {
      id
      value
      order
    }
    subQuestions {
      id
      name
      questionText
      order
      isRequired
      canAddToKeyIncidents
      questionType
      selectedOption
      selectedOptionOrder
      responseShortAnswer
      responseText
      responseNumber
      responseBoolean
      responses {
        id
        value
        createdAt
        updatedAt
      }
      fileUploads {
        id
        name
        comment
        fileUpload
        createdAt
        updatedAt
      }
      options {
        id
        value
        order
      }
    }
  }
}
    `;
export type RemoveDynamicCompetitionSurveyMultipleResponseWithTokenMutationFn = Apollo.MutationFunction<RemoveDynamicCompetitionSurveyMultipleResponseWithTokenMutation, RemoveDynamicCompetitionSurveyMultipleResponseWithTokenMutationVariables>;

/**
 * __useRemoveDynamicCompetitionSurveyMultipleResponseWithTokenMutation__
 *
 * To run a mutation, you first call `useRemoveDynamicCompetitionSurveyMultipleResponseWithTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveDynamicCompetitionSurveyMultipleResponseWithTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeDynamicCompetitionSurveyMultipleResponseWithTokenMutation, { data, loading, error }] = useRemoveDynamicCompetitionSurveyMultipleResponseWithTokenMutation({
 *   variables: {
 *      surveyToken: // value for 'surveyToken'
 *      questionId: // value for 'questionId'
 *      responseId: // value for 'responseId'
 *   },
 * });
 */
export function useRemoveDynamicCompetitionSurveyMultipleResponseWithTokenMutation(baseOptions?: Apollo.MutationHookOptions<RemoveDynamicCompetitionSurveyMultipleResponseWithTokenMutation, RemoveDynamicCompetitionSurveyMultipleResponseWithTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveDynamicCompetitionSurveyMultipleResponseWithTokenMutation, RemoveDynamicCompetitionSurveyMultipleResponseWithTokenMutationVariables>(RemoveDynamicCompetitionSurveyMultipleResponseWithTokenDocument, options);
      }
export type RemoveDynamicCompetitionSurveyMultipleResponseWithTokenMutationHookResult = ReturnType<typeof useRemoveDynamicCompetitionSurveyMultipleResponseWithTokenMutation>;
export type RemoveDynamicCompetitionSurveyMultipleResponseWithTokenMutationResult = Apollo.MutationResult<RemoveDynamicCompetitionSurveyMultipleResponseWithTokenMutation>;
export type RemoveDynamicCompetitionSurveyMultipleResponseWithTokenMutationOptions = Apollo.BaseMutationOptions<RemoveDynamicCompetitionSurveyMultipleResponseWithTokenMutation, RemoveDynamicCompetitionSurveyMultipleResponseWithTokenMutationVariables>;
export const AddDynamicCompetitionSurveyFileUploadWithTokenDocument = gql`
    mutation addDynamicCompetitionSurveyFileUploadWithToken($surveyToken: String!, $questionId: String!, $file: Upload!, $comment: String) {
  addDynamicCompetitionSurveyFileUploadWithToken(
    file: $file
    input: {surveyToken: $surveyToken, questionId: $questionId, comment: $comment}
  ) {
    id
    name
    comment
    fileUpload
    createdAt
    updatedAt
  }
}
    `;
export type AddDynamicCompetitionSurveyFileUploadWithTokenMutationFn = Apollo.MutationFunction<AddDynamicCompetitionSurveyFileUploadWithTokenMutation, AddDynamicCompetitionSurveyFileUploadWithTokenMutationVariables>;

/**
 * __useAddDynamicCompetitionSurveyFileUploadWithTokenMutation__
 *
 * To run a mutation, you first call `useAddDynamicCompetitionSurveyFileUploadWithTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDynamicCompetitionSurveyFileUploadWithTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDynamicCompetitionSurveyFileUploadWithTokenMutation, { data, loading, error }] = useAddDynamicCompetitionSurveyFileUploadWithTokenMutation({
 *   variables: {
 *      surveyToken: // value for 'surveyToken'
 *      questionId: // value for 'questionId'
 *      file: // value for 'file'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useAddDynamicCompetitionSurveyFileUploadWithTokenMutation(baseOptions?: Apollo.MutationHookOptions<AddDynamicCompetitionSurveyFileUploadWithTokenMutation, AddDynamicCompetitionSurveyFileUploadWithTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddDynamicCompetitionSurveyFileUploadWithTokenMutation, AddDynamicCompetitionSurveyFileUploadWithTokenMutationVariables>(AddDynamicCompetitionSurveyFileUploadWithTokenDocument, options);
      }
export type AddDynamicCompetitionSurveyFileUploadWithTokenMutationHookResult = ReturnType<typeof useAddDynamicCompetitionSurveyFileUploadWithTokenMutation>;
export type AddDynamicCompetitionSurveyFileUploadWithTokenMutationResult = Apollo.MutationResult<AddDynamicCompetitionSurveyFileUploadWithTokenMutation>;
export type AddDynamicCompetitionSurveyFileUploadWithTokenMutationOptions = Apollo.BaseMutationOptions<AddDynamicCompetitionSurveyFileUploadWithTokenMutation, AddDynamicCompetitionSurveyFileUploadWithTokenMutationVariables>;
export const RemoveDynamicCompetitionSurveyFileUploadWithTokenDocument = gql`
    mutation removeDynamicCompetitionSurveyFileUploadWithToken($surveyToken: String!, $questionId: String!, $fileUploadId: String!) {
  removeDynamicCompetitionSurveyFileUploadWithToken(
    input: {surveyToken: $surveyToken, questionId: $questionId, fileUploadId: $fileUploadId}
  ) {
    id
    name
    description
    order
    isRequired
    canAddToKeyIncidents
    questionType
    selectedOption
    selectedOptionOrder
    responseShortAnswer
    responseText
    responseNumber
    responseBoolean
    responses {
      id
      value
      createdAt
      updatedAt
    }
    fileUploads {
      id
      name
      comment
      fileUpload
      createdAt
      updatedAt
    }
    options {
      id
      value
      order
    }
    subQuestions {
      id
      name
      questionText
      order
      isRequired
      canAddToKeyIncidents
      questionType
      selectedOption
      selectedOptionOrder
      responseShortAnswer
      responseText
      responseNumber
      responseBoolean
      responses {
        id
        value
        createdAt
        updatedAt
      }
      fileUploads {
        id
        name
        comment
        fileUpload
        createdAt
        updatedAt
      }
      options {
        id
        value
        order
      }
    }
  }
}
    `;
export type RemoveDynamicCompetitionSurveyFileUploadWithTokenMutationFn = Apollo.MutationFunction<RemoveDynamicCompetitionSurveyFileUploadWithTokenMutation, RemoveDynamicCompetitionSurveyFileUploadWithTokenMutationVariables>;

/**
 * __useRemoveDynamicCompetitionSurveyFileUploadWithTokenMutation__
 *
 * To run a mutation, you first call `useRemoveDynamicCompetitionSurveyFileUploadWithTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveDynamicCompetitionSurveyFileUploadWithTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeDynamicCompetitionSurveyFileUploadWithTokenMutation, { data, loading, error }] = useRemoveDynamicCompetitionSurveyFileUploadWithTokenMutation({
 *   variables: {
 *      surveyToken: // value for 'surveyToken'
 *      questionId: // value for 'questionId'
 *      fileUploadId: // value for 'fileUploadId'
 *   },
 * });
 */
export function useRemoveDynamicCompetitionSurveyFileUploadWithTokenMutation(baseOptions?: Apollo.MutationHookOptions<RemoveDynamicCompetitionSurveyFileUploadWithTokenMutation, RemoveDynamicCompetitionSurveyFileUploadWithTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveDynamicCompetitionSurveyFileUploadWithTokenMutation, RemoveDynamicCompetitionSurveyFileUploadWithTokenMutationVariables>(RemoveDynamicCompetitionSurveyFileUploadWithTokenDocument, options);
      }
export type RemoveDynamicCompetitionSurveyFileUploadWithTokenMutationHookResult = ReturnType<typeof useRemoveDynamicCompetitionSurveyFileUploadWithTokenMutation>;
export type RemoveDynamicCompetitionSurveyFileUploadWithTokenMutationResult = Apollo.MutationResult<RemoveDynamicCompetitionSurveyFileUploadWithTokenMutation>;
export type RemoveDynamicCompetitionSurveyFileUploadWithTokenMutationOptions = Apollo.BaseMutationOptions<RemoveDynamicCompetitionSurveyFileUploadWithTokenMutation, RemoveDynamicCompetitionSurveyFileUploadWithTokenMutationVariables>;
export const UpdateDynamicCompetitionSurveySubQuestionMultipleChoiceResponseWithTokenDocument = gql`
    mutation updateDynamicCompetitionSurveySubQuestionMultipleChoiceResponseWithToken($surveyToken: String!, $questionId: String!, $subQuestionId: String!, $selectedOptionId: String!) {
  updateDynamicCompetitionSurveySubQuestionMultipleChoiceResponseWithToken(
    input: {surveyToken: $surveyToken, questionId: $questionId, subQuestionId: $subQuestionId, selectedOptionId: $selectedOptionId}
  ) {
    id
    name
    questionText
    order
    isRequired
    canAddToKeyIncidents
    questionType
    selectedOption
    selectedOptionOrder
    responseShortAnswer
    responseText
    responseNumber
    responseBoolean
    responses {
      id
      value
      createdAt
      updatedAt
    }
    fileUploads {
      id
      name
      comment
      fileUpload
      createdAt
      updatedAt
    }
    options {
      id
      value
      order
    }
  }
}
    `;
export type UpdateDynamicCompetitionSurveySubQuestionMultipleChoiceResponseWithTokenMutationFn = Apollo.MutationFunction<UpdateDynamicCompetitionSurveySubQuestionMultipleChoiceResponseWithTokenMutation, UpdateDynamicCompetitionSurveySubQuestionMultipleChoiceResponseWithTokenMutationVariables>;

/**
 * __useUpdateDynamicCompetitionSurveySubQuestionMultipleChoiceResponseWithTokenMutation__
 *
 * To run a mutation, you first call `useUpdateDynamicCompetitionSurveySubQuestionMultipleChoiceResponseWithTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDynamicCompetitionSurveySubQuestionMultipleChoiceResponseWithTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDynamicCompetitionSurveySubQuestionMultipleChoiceResponseWithTokenMutation, { data, loading, error }] = useUpdateDynamicCompetitionSurveySubQuestionMultipleChoiceResponseWithTokenMutation({
 *   variables: {
 *      surveyToken: // value for 'surveyToken'
 *      questionId: // value for 'questionId'
 *      subQuestionId: // value for 'subQuestionId'
 *      selectedOptionId: // value for 'selectedOptionId'
 *   },
 * });
 */
export function useUpdateDynamicCompetitionSurveySubQuestionMultipleChoiceResponseWithTokenMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDynamicCompetitionSurveySubQuestionMultipleChoiceResponseWithTokenMutation, UpdateDynamicCompetitionSurveySubQuestionMultipleChoiceResponseWithTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDynamicCompetitionSurveySubQuestionMultipleChoiceResponseWithTokenMutation, UpdateDynamicCompetitionSurveySubQuestionMultipleChoiceResponseWithTokenMutationVariables>(UpdateDynamicCompetitionSurveySubQuestionMultipleChoiceResponseWithTokenDocument, options);
      }
export type UpdateDynamicCompetitionSurveySubQuestionMultipleChoiceResponseWithTokenMutationHookResult = ReturnType<typeof useUpdateDynamicCompetitionSurveySubQuestionMultipleChoiceResponseWithTokenMutation>;
export type UpdateDynamicCompetitionSurveySubQuestionMultipleChoiceResponseWithTokenMutationResult = Apollo.MutationResult<UpdateDynamicCompetitionSurveySubQuestionMultipleChoiceResponseWithTokenMutation>;
export type UpdateDynamicCompetitionSurveySubQuestionMultipleChoiceResponseWithTokenMutationOptions = Apollo.BaseMutationOptions<UpdateDynamicCompetitionSurveySubQuestionMultipleChoiceResponseWithTokenMutation, UpdateDynamicCompetitionSurveySubQuestionMultipleChoiceResponseWithTokenMutationVariables>;
export const UpdateDynamicCompetitionSurveySubQuestionShortAnswerResponseWithTokenDocument = gql`
    mutation updateDynamicCompetitionSurveySubQuestionShortAnswerResponseWithToken($surveyToken: String!, $questionId: String!, $subQuestionId: String!, $response: String!) {
  updateDynamicCompetitionSurveySubQuestionShortAnswerResponseWithToken(
    input: {surveyToken: $surveyToken, questionId: $questionId, subQuestionId: $subQuestionId, response: $response}
  ) {
    id
    name
    questionText
    order
    isRequired
    canAddToKeyIncidents
    questionType
    selectedOption
    selectedOptionOrder
    responseShortAnswer
    responseText
    responseNumber
    responseBoolean
    responses {
      id
      value
      createdAt
      updatedAt
    }
    fileUploads {
      id
      name
      comment
      fileUpload
      createdAt
      updatedAt
    }
    options {
      id
      value
      order
    }
  }
}
    `;
export type UpdateDynamicCompetitionSurveySubQuestionShortAnswerResponseWithTokenMutationFn = Apollo.MutationFunction<UpdateDynamicCompetitionSurveySubQuestionShortAnswerResponseWithTokenMutation, UpdateDynamicCompetitionSurveySubQuestionShortAnswerResponseWithTokenMutationVariables>;

/**
 * __useUpdateDynamicCompetitionSurveySubQuestionShortAnswerResponseWithTokenMutation__
 *
 * To run a mutation, you first call `useUpdateDynamicCompetitionSurveySubQuestionShortAnswerResponseWithTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDynamicCompetitionSurveySubQuestionShortAnswerResponseWithTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDynamicCompetitionSurveySubQuestionShortAnswerResponseWithTokenMutation, { data, loading, error }] = useUpdateDynamicCompetitionSurveySubQuestionShortAnswerResponseWithTokenMutation({
 *   variables: {
 *      surveyToken: // value for 'surveyToken'
 *      questionId: // value for 'questionId'
 *      subQuestionId: // value for 'subQuestionId'
 *      response: // value for 'response'
 *   },
 * });
 */
export function useUpdateDynamicCompetitionSurveySubQuestionShortAnswerResponseWithTokenMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDynamicCompetitionSurveySubQuestionShortAnswerResponseWithTokenMutation, UpdateDynamicCompetitionSurveySubQuestionShortAnswerResponseWithTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDynamicCompetitionSurveySubQuestionShortAnswerResponseWithTokenMutation, UpdateDynamicCompetitionSurveySubQuestionShortAnswerResponseWithTokenMutationVariables>(UpdateDynamicCompetitionSurveySubQuestionShortAnswerResponseWithTokenDocument, options);
      }
export type UpdateDynamicCompetitionSurveySubQuestionShortAnswerResponseWithTokenMutationHookResult = ReturnType<typeof useUpdateDynamicCompetitionSurveySubQuestionShortAnswerResponseWithTokenMutation>;
export type UpdateDynamicCompetitionSurveySubQuestionShortAnswerResponseWithTokenMutationResult = Apollo.MutationResult<UpdateDynamicCompetitionSurveySubQuestionShortAnswerResponseWithTokenMutation>;
export type UpdateDynamicCompetitionSurveySubQuestionShortAnswerResponseWithTokenMutationOptions = Apollo.BaseMutationOptions<UpdateDynamicCompetitionSurveySubQuestionShortAnswerResponseWithTokenMutation, UpdateDynamicCompetitionSurveySubQuestionShortAnswerResponseWithTokenMutationVariables>;
export const UpdateDynamicCompetitionSurveySubQuestionTextResponseWithTokenDocument = gql`
    mutation updateDynamicCompetitionSurveySubQuestionTextResponseWithToken($surveyToken: String!, $questionId: String!, $subQuestionId: String!, $response: String!) {
  updateDynamicCompetitionSurveySubQuestionTextResponseWithToken(
    input: {surveyToken: $surveyToken, questionId: $questionId, subQuestionId: $subQuestionId, response: $response}
  ) {
    id
    name
    questionText
    order
    isRequired
    canAddToKeyIncidents
    questionType
    selectedOption
    selectedOptionOrder
    responseShortAnswer
    responseText
    responseNumber
    responseBoolean
    responses {
      id
      value
      createdAt
      updatedAt
    }
    fileUploads {
      id
      name
      comment
      fileUpload
      createdAt
      updatedAt
    }
    options {
      id
      value
      order
    }
  }
}
    `;
export type UpdateDynamicCompetitionSurveySubQuestionTextResponseWithTokenMutationFn = Apollo.MutationFunction<UpdateDynamicCompetitionSurveySubQuestionTextResponseWithTokenMutation, UpdateDynamicCompetitionSurveySubQuestionTextResponseWithTokenMutationVariables>;

/**
 * __useUpdateDynamicCompetitionSurveySubQuestionTextResponseWithTokenMutation__
 *
 * To run a mutation, you first call `useUpdateDynamicCompetitionSurveySubQuestionTextResponseWithTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDynamicCompetitionSurveySubQuestionTextResponseWithTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDynamicCompetitionSurveySubQuestionTextResponseWithTokenMutation, { data, loading, error }] = useUpdateDynamicCompetitionSurveySubQuestionTextResponseWithTokenMutation({
 *   variables: {
 *      surveyToken: // value for 'surveyToken'
 *      questionId: // value for 'questionId'
 *      subQuestionId: // value for 'subQuestionId'
 *      response: // value for 'response'
 *   },
 * });
 */
export function useUpdateDynamicCompetitionSurveySubQuestionTextResponseWithTokenMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDynamicCompetitionSurveySubQuestionTextResponseWithTokenMutation, UpdateDynamicCompetitionSurveySubQuestionTextResponseWithTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDynamicCompetitionSurveySubQuestionTextResponseWithTokenMutation, UpdateDynamicCompetitionSurveySubQuestionTextResponseWithTokenMutationVariables>(UpdateDynamicCompetitionSurveySubQuestionTextResponseWithTokenDocument, options);
      }
export type UpdateDynamicCompetitionSurveySubQuestionTextResponseWithTokenMutationHookResult = ReturnType<typeof useUpdateDynamicCompetitionSurveySubQuestionTextResponseWithTokenMutation>;
export type UpdateDynamicCompetitionSurveySubQuestionTextResponseWithTokenMutationResult = Apollo.MutationResult<UpdateDynamicCompetitionSurveySubQuestionTextResponseWithTokenMutation>;
export type UpdateDynamicCompetitionSurveySubQuestionTextResponseWithTokenMutationOptions = Apollo.BaseMutationOptions<UpdateDynamicCompetitionSurveySubQuestionTextResponseWithTokenMutation, UpdateDynamicCompetitionSurveySubQuestionTextResponseWithTokenMutationVariables>;
export const UpdateDynamicCompetitionSurveySubQuestionNumberResponseWithTokenDocument = gql`
    mutation updateDynamicCompetitionSurveySubQuestionNumberResponseWithToken($surveyToken: String!, $questionId: String!, $subQuestionId: String!, $response: Float!) {
  updateDynamicCompetitionSurveySubQuestionNumberResponseWithToken(
    input: {surveyToken: $surveyToken, questionId: $questionId, subQuestionId: $subQuestionId, response: $response}
  ) {
    id
    name
    questionText
    order
    isRequired
    canAddToKeyIncidents
    questionType
    selectedOption
    selectedOptionOrder
    responseShortAnswer
    responseText
    responseNumber
    responseBoolean
    responses {
      id
      value
      createdAt
      updatedAt
    }
    fileUploads {
      id
      name
      comment
      fileUpload
      createdAt
      updatedAt
    }
    options {
      id
      value
      order
    }
  }
}
    `;
export type UpdateDynamicCompetitionSurveySubQuestionNumberResponseWithTokenMutationFn = Apollo.MutationFunction<UpdateDynamicCompetitionSurveySubQuestionNumberResponseWithTokenMutation, UpdateDynamicCompetitionSurveySubQuestionNumberResponseWithTokenMutationVariables>;

/**
 * __useUpdateDynamicCompetitionSurveySubQuestionNumberResponseWithTokenMutation__
 *
 * To run a mutation, you first call `useUpdateDynamicCompetitionSurveySubQuestionNumberResponseWithTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDynamicCompetitionSurveySubQuestionNumberResponseWithTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDynamicCompetitionSurveySubQuestionNumberResponseWithTokenMutation, { data, loading, error }] = useUpdateDynamicCompetitionSurveySubQuestionNumberResponseWithTokenMutation({
 *   variables: {
 *      surveyToken: // value for 'surveyToken'
 *      questionId: // value for 'questionId'
 *      subQuestionId: // value for 'subQuestionId'
 *      response: // value for 'response'
 *   },
 * });
 */
export function useUpdateDynamicCompetitionSurveySubQuestionNumberResponseWithTokenMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDynamicCompetitionSurveySubQuestionNumberResponseWithTokenMutation, UpdateDynamicCompetitionSurveySubQuestionNumberResponseWithTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDynamicCompetitionSurveySubQuestionNumberResponseWithTokenMutation, UpdateDynamicCompetitionSurveySubQuestionNumberResponseWithTokenMutationVariables>(UpdateDynamicCompetitionSurveySubQuestionNumberResponseWithTokenDocument, options);
      }
export type UpdateDynamicCompetitionSurveySubQuestionNumberResponseWithTokenMutationHookResult = ReturnType<typeof useUpdateDynamicCompetitionSurveySubQuestionNumberResponseWithTokenMutation>;
export type UpdateDynamicCompetitionSurveySubQuestionNumberResponseWithTokenMutationResult = Apollo.MutationResult<UpdateDynamicCompetitionSurveySubQuestionNumberResponseWithTokenMutation>;
export type UpdateDynamicCompetitionSurveySubQuestionNumberResponseWithTokenMutationOptions = Apollo.BaseMutationOptions<UpdateDynamicCompetitionSurveySubQuestionNumberResponseWithTokenMutation, UpdateDynamicCompetitionSurveySubQuestionNumberResponseWithTokenMutationVariables>;
export const UpdateDynamicCompetitionSurveySubQuestionBooleanResponseWithTokenDocument = gql`
    mutation updateDynamicCompetitionSurveySubQuestionBooleanResponseWithToken($surveyToken: String!, $questionId: String!, $subQuestionId: String!, $response: Boolean!) {
  updateDynamicCompetitionSurveySubQuestionBooleanResponseWithToken(
    input: {surveyToken: $surveyToken, questionId: $questionId, subQuestionId: $subQuestionId, response: $response}
  ) {
    id
    name
    questionText
    order
    isRequired
    canAddToKeyIncidents
    questionType
    selectedOption
    selectedOptionOrder
    responseShortAnswer
    responseText
    responseNumber
    responseBoolean
    responses {
      id
      value
      createdAt
      updatedAt
    }
    fileUploads {
      id
      name
      comment
      fileUpload
      createdAt
      updatedAt
    }
    options {
      id
      value
      order
    }
  }
}
    `;
export type UpdateDynamicCompetitionSurveySubQuestionBooleanResponseWithTokenMutationFn = Apollo.MutationFunction<UpdateDynamicCompetitionSurveySubQuestionBooleanResponseWithTokenMutation, UpdateDynamicCompetitionSurveySubQuestionBooleanResponseWithTokenMutationVariables>;

/**
 * __useUpdateDynamicCompetitionSurveySubQuestionBooleanResponseWithTokenMutation__
 *
 * To run a mutation, you first call `useUpdateDynamicCompetitionSurveySubQuestionBooleanResponseWithTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDynamicCompetitionSurveySubQuestionBooleanResponseWithTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDynamicCompetitionSurveySubQuestionBooleanResponseWithTokenMutation, { data, loading, error }] = useUpdateDynamicCompetitionSurveySubQuestionBooleanResponseWithTokenMutation({
 *   variables: {
 *      surveyToken: // value for 'surveyToken'
 *      questionId: // value for 'questionId'
 *      subQuestionId: // value for 'subQuestionId'
 *      response: // value for 'response'
 *   },
 * });
 */
export function useUpdateDynamicCompetitionSurveySubQuestionBooleanResponseWithTokenMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDynamicCompetitionSurveySubQuestionBooleanResponseWithTokenMutation, UpdateDynamicCompetitionSurveySubQuestionBooleanResponseWithTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDynamicCompetitionSurveySubQuestionBooleanResponseWithTokenMutation, UpdateDynamicCompetitionSurveySubQuestionBooleanResponseWithTokenMutationVariables>(UpdateDynamicCompetitionSurveySubQuestionBooleanResponseWithTokenDocument, options);
      }
export type UpdateDynamicCompetitionSurveySubQuestionBooleanResponseWithTokenMutationHookResult = ReturnType<typeof useUpdateDynamicCompetitionSurveySubQuestionBooleanResponseWithTokenMutation>;
export type UpdateDynamicCompetitionSurveySubQuestionBooleanResponseWithTokenMutationResult = Apollo.MutationResult<UpdateDynamicCompetitionSurveySubQuestionBooleanResponseWithTokenMutation>;
export type UpdateDynamicCompetitionSurveySubQuestionBooleanResponseWithTokenMutationOptions = Apollo.BaseMutationOptions<UpdateDynamicCompetitionSurveySubQuestionBooleanResponseWithTokenMutation, UpdateDynamicCompetitionSurveySubQuestionBooleanResponseWithTokenMutationVariables>;
export const UpdateDynamicCompetitionSurveyMultiPartQuestionWithTokenDocument = gql`
    mutation updateDynamicCompetitionSurveyMultiPartQuestionWithToken($surveyToken: String!, $questionId: String!, $multipleResponses: [UpdateDynamicCompetitionSurveyMultiPartQuestionMultipleChoiceResponseInput!], $shortAnswerResponses: [UpdateDynamicCompetitionSurveyMultiPartQuestionShortAnswerResponseInput!], $textResponses: [UpdateDynamicCompetitionSurveyMultiPartQuestionTextResponseInput!], $numberResponses: [UpdateDynamicCompetitionSurveyMultiPartQuestionNumberResponseInput!]) {
  updateDynamicCompetitionSurveyMultiPartQuestionWithToken(
    input: {surveyToken: $surveyToken, questionId: $questionId, multipleResponses: $multipleResponses, shortAnswerResponses: $shortAnswerResponses, textResponses: $textResponses, numberResponses: $numberResponses}
  ) {
    id
    name
    description
    additionalText1
    additionalText2
    additionalText3
    order
    isRequired
    canAddToKeyIncidents
    assessmentObject {
      id
      sport
      type
      classification
      name
    }
    coach {
      id
      teamId
      teamName
      teamAbbreviation
      sport
      teamCity
      userId
      type
      role
      status
      email
      firstName
      lastName
      profilePic
      createdAt
      updatedAt
    }
    team {
      id
      name
      abbreviation
      sport
      city
      stateAbbreviation
      countryCode
      timeZone
      classification
      gender
      website
      logo
      parentOrganizationName
      externalId
      ageGroup
      createdAt
      updatedAt
    }
    official {
      id
      sport
      userId
      status
      email
      firstName
      lastName
      externalIds {
        id
        organizationId
        externalId
      }
      profilePic
      createdAt
      updatedAt
    }
    organization {
      id
      name
      sport
      status
      abbreviation
      site
      logo
      createdAt
      updatedAt
    }
    questionType
    selectedOption
    selectedOptionOrder
    responseShortAnswer
    responseText
    responseNumber
    responseBoolean
    responses {
      id
      value
      createdAt
      updatedAt
    }
    fileUploads {
      id
      name
      comment
      fileUpload
      createdAt
      updatedAt
    }
    options {
      id
      value
      order
    }
    subQuestions {
      id
      name
      questionText
      order
      isRequired
      canAddToKeyIncidents
      questionType
      selectedOption
      selectedOptionOrder
      responseShortAnswer
      responseText
      responseNumber
      responseBoolean
      responses {
        id
        value
        createdAt
        updatedAt
      }
      fileUploads {
        id
        name
        comment
        fileUpload
        createdAt
        updatedAt
      }
      options {
        id
        value
        order
      }
    }
  }
}
    `;
export type UpdateDynamicCompetitionSurveyMultiPartQuestionWithTokenMutationFn = Apollo.MutationFunction<UpdateDynamicCompetitionSurveyMultiPartQuestionWithTokenMutation, UpdateDynamicCompetitionSurveyMultiPartQuestionWithTokenMutationVariables>;

/**
 * __useUpdateDynamicCompetitionSurveyMultiPartQuestionWithTokenMutation__
 *
 * To run a mutation, you first call `useUpdateDynamicCompetitionSurveyMultiPartQuestionWithTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDynamicCompetitionSurveyMultiPartQuestionWithTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDynamicCompetitionSurveyMultiPartQuestionWithTokenMutation, { data, loading, error }] = useUpdateDynamicCompetitionSurveyMultiPartQuestionWithTokenMutation({
 *   variables: {
 *      surveyToken: // value for 'surveyToken'
 *      questionId: // value for 'questionId'
 *      multipleResponses: // value for 'multipleResponses'
 *      shortAnswerResponses: // value for 'shortAnswerResponses'
 *      textResponses: // value for 'textResponses'
 *      numberResponses: // value for 'numberResponses'
 *   },
 * });
 */
export function useUpdateDynamicCompetitionSurveyMultiPartQuestionWithTokenMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDynamicCompetitionSurveyMultiPartQuestionWithTokenMutation, UpdateDynamicCompetitionSurveyMultiPartQuestionWithTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDynamicCompetitionSurveyMultiPartQuestionWithTokenMutation, UpdateDynamicCompetitionSurveyMultiPartQuestionWithTokenMutationVariables>(UpdateDynamicCompetitionSurveyMultiPartQuestionWithTokenDocument, options);
      }
export type UpdateDynamicCompetitionSurveyMultiPartQuestionWithTokenMutationHookResult = ReturnType<typeof useUpdateDynamicCompetitionSurveyMultiPartQuestionWithTokenMutation>;
export type UpdateDynamicCompetitionSurveyMultiPartQuestionWithTokenMutationResult = Apollo.MutationResult<UpdateDynamicCompetitionSurveyMultiPartQuestionWithTokenMutation>;
export type UpdateDynamicCompetitionSurveyMultiPartQuestionWithTokenMutationOptions = Apollo.BaseMutationOptions<UpdateDynamicCompetitionSurveyMultiPartQuestionWithTokenMutation, UpdateDynamicCompetitionSurveyMultiPartQuestionWithTokenMutationVariables>;
export const AddDynamicCompetitionSurveySubQuestionMultipleResponseWithTokenDocument = gql`
    mutation addDynamicCompetitionSurveySubQuestionMultipleResponseWithToken($surveyToken: String!, $questionId: String!, $subQuestionId: String!, $response: String!) {
  addDynamicCompetitionSurveySubQuestionMultipleResponseWithToken(
    input: {surveyToken: $surveyToken, questionId: $questionId, subQuestionId: $subQuestionId, response: $response}
  ) {
    id
    value
    createdAt
    updatedAt
  }
}
    `;
export type AddDynamicCompetitionSurveySubQuestionMultipleResponseWithTokenMutationFn = Apollo.MutationFunction<AddDynamicCompetitionSurveySubQuestionMultipleResponseWithTokenMutation, AddDynamicCompetitionSurveySubQuestionMultipleResponseWithTokenMutationVariables>;

/**
 * __useAddDynamicCompetitionSurveySubQuestionMultipleResponseWithTokenMutation__
 *
 * To run a mutation, you first call `useAddDynamicCompetitionSurveySubQuestionMultipleResponseWithTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDynamicCompetitionSurveySubQuestionMultipleResponseWithTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDynamicCompetitionSurveySubQuestionMultipleResponseWithTokenMutation, { data, loading, error }] = useAddDynamicCompetitionSurveySubQuestionMultipleResponseWithTokenMutation({
 *   variables: {
 *      surveyToken: // value for 'surveyToken'
 *      questionId: // value for 'questionId'
 *      subQuestionId: // value for 'subQuestionId'
 *      response: // value for 'response'
 *   },
 * });
 */
export function useAddDynamicCompetitionSurveySubQuestionMultipleResponseWithTokenMutation(baseOptions?: Apollo.MutationHookOptions<AddDynamicCompetitionSurveySubQuestionMultipleResponseWithTokenMutation, AddDynamicCompetitionSurveySubQuestionMultipleResponseWithTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddDynamicCompetitionSurveySubQuestionMultipleResponseWithTokenMutation, AddDynamicCompetitionSurveySubQuestionMultipleResponseWithTokenMutationVariables>(AddDynamicCompetitionSurveySubQuestionMultipleResponseWithTokenDocument, options);
      }
export type AddDynamicCompetitionSurveySubQuestionMultipleResponseWithTokenMutationHookResult = ReturnType<typeof useAddDynamicCompetitionSurveySubQuestionMultipleResponseWithTokenMutation>;
export type AddDynamicCompetitionSurveySubQuestionMultipleResponseWithTokenMutationResult = Apollo.MutationResult<AddDynamicCompetitionSurveySubQuestionMultipleResponseWithTokenMutation>;
export type AddDynamicCompetitionSurveySubQuestionMultipleResponseWithTokenMutationOptions = Apollo.BaseMutationOptions<AddDynamicCompetitionSurveySubQuestionMultipleResponseWithTokenMutation, AddDynamicCompetitionSurveySubQuestionMultipleResponseWithTokenMutationVariables>;
export const RemoveDynamicCompetitionSurveySubQuestionMultipleResponseWithTokenDocument = gql`
    mutation removeDynamicCompetitionSurveySubQuestionMultipleResponseWithToken($surveyToken: String!, $questionId: String!, $subQuestionId: String!, $responseId: String!) {
  removeDynamicCompetitionSurveySubQuestionMultipleResponseWithToken(
    input: {surveyToken: $surveyToken, questionId: $questionId, subQuestionId: $subQuestionId, responseId: $responseId}
  ) {
    id
    name
    questionText
    order
    isRequired
    canAddToKeyIncidents
    questionType
    selectedOption
    selectedOptionOrder
    responseShortAnswer
    responseText
    responseNumber
    responseBoolean
    responses {
      id
      value
      createdAt
      updatedAt
    }
    fileUploads {
      id
      name
      comment
      fileUpload
      createdAt
      updatedAt
    }
    options {
      id
      value
      order
    }
  }
}
    `;
export type RemoveDynamicCompetitionSurveySubQuestionMultipleResponseWithTokenMutationFn = Apollo.MutationFunction<RemoveDynamicCompetitionSurveySubQuestionMultipleResponseWithTokenMutation, RemoveDynamicCompetitionSurveySubQuestionMultipleResponseWithTokenMutationVariables>;

/**
 * __useRemoveDynamicCompetitionSurveySubQuestionMultipleResponseWithTokenMutation__
 *
 * To run a mutation, you first call `useRemoveDynamicCompetitionSurveySubQuestionMultipleResponseWithTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveDynamicCompetitionSurveySubQuestionMultipleResponseWithTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeDynamicCompetitionSurveySubQuestionMultipleResponseWithTokenMutation, { data, loading, error }] = useRemoveDynamicCompetitionSurveySubQuestionMultipleResponseWithTokenMutation({
 *   variables: {
 *      surveyToken: // value for 'surveyToken'
 *      questionId: // value for 'questionId'
 *      subQuestionId: // value for 'subQuestionId'
 *      responseId: // value for 'responseId'
 *   },
 * });
 */
export function useRemoveDynamicCompetitionSurveySubQuestionMultipleResponseWithTokenMutation(baseOptions?: Apollo.MutationHookOptions<RemoveDynamicCompetitionSurveySubQuestionMultipleResponseWithTokenMutation, RemoveDynamicCompetitionSurveySubQuestionMultipleResponseWithTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveDynamicCompetitionSurveySubQuestionMultipleResponseWithTokenMutation, RemoveDynamicCompetitionSurveySubQuestionMultipleResponseWithTokenMutationVariables>(RemoveDynamicCompetitionSurveySubQuestionMultipleResponseWithTokenDocument, options);
      }
export type RemoveDynamicCompetitionSurveySubQuestionMultipleResponseWithTokenMutationHookResult = ReturnType<typeof useRemoveDynamicCompetitionSurveySubQuestionMultipleResponseWithTokenMutation>;
export type RemoveDynamicCompetitionSurveySubQuestionMultipleResponseWithTokenMutationResult = Apollo.MutationResult<RemoveDynamicCompetitionSurveySubQuestionMultipleResponseWithTokenMutation>;
export type RemoveDynamicCompetitionSurveySubQuestionMultipleResponseWithTokenMutationOptions = Apollo.BaseMutationOptions<RemoveDynamicCompetitionSurveySubQuestionMultipleResponseWithTokenMutation, RemoveDynamicCompetitionSurveySubQuestionMultipleResponseWithTokenMutationVariables>;
export const ListSupplementalReportsForDynamicCompetitionSurveyWithTokenDocument = gql`
    query listSupplementalReportsForDynamicCompetitionSurveyWithToken($surveyToken: String!) {
  listSupplementalReportsForDynamicCompetitionSurveyWithToken(
    input: {surveyToken: $surveyToken}
  ) {
    supplementalReports {
      id
      competitionSurveyId
      dynamicCompetitionSurveyId
      submittedBy {
        userId
        email
        firstName
        lastName
        profilePic
        teamId
        teamName
        teamLogo
        officialId
        assessmentObjectId
        assessmentObjectName
      }
      competitionInfo {
        competitionId
        externalId
        sport
        homeTeamId
        homeTeamName
        homeTeamScore
        homeTeamTieBreakerScore
        awayTeamId
        awayTeamName
        awayTeamScore
        awayTeamTieBreakerScore
        startTime
        timeZone
        location
        outcome
        verificationStatus
      }
      assessmentObject {
        id
        sport
        type
        classification
        name
        createdAt
        updatedAt
      }
      official {
        id
        sport
        userId
        status
        email
        firstName
        lastName
        profilePic
        createdAt
        updatedAt
      }
      team {
        id
        name
        abbreviation
        sport
        city
        stateAbbreviation
        countryCode
        classification
        gender
        parentOrganizationName
        externalId
        ageGroup
        timeZone
        website
        logo
        createdAt
        updatedAt
      }
      organizationId
      organizationName
      leagueId
      leagueName
      seasonId
      seasonName
      competitionSurveySectionQuestionId
      competitionSurveyFeedbackId
      dynamicCompetitionSurveySectionQuestionId
      dynamicCompetitionSurveyFeedbackId
      dynamicCompetitionSurveySubFeedbackId
      type
      status
      title
      report
      reportVersion
      time
      approvedDate
      reopenedDate
      submittedAt
      createdAt
      updatedAt
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListSupplementalReportsForDynamicCompetitionSurveyWithTokenQuery__
 *
 * To run a query within a React component, call `useListSupplementalReportsForDynamicCompetitionSurveyWithTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSupplementalReportsForDynamicCompetitionSurveyWithTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSupplementalReportsForDynamicCompetitionSurveyWithTokenQuery({
 *   variables: {
 *      surveyToken: // value for 'surveyToken'
 *   },
 * });
 */
export function useListSupplementalReportsForDynamicCompetitionSurveyWithTokenQuery(baseOptions: Apollo.QueryHookOptions<ListSupplementalReportsForDynamicCompetitionSurveyWithTokenQuery, ListSupplementalReportsForDynamicCompetitionSurveyWithTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListSupplementalReportsForDynamicCompetitionSurveyWithTokenQuery, ListSupplementalReportsForDynamicCompetitionSurveyWithTokenQueryVariables>(ListSupplementalReportsForDynamicCompetitionSurveyWithTokenDocument, options);
      }
export function useListSupplementalReportsForDynamicCompetitionSurveyWithTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListSupplementalReportsForDynamicCompetitionSurveyWithTokenQuery, ListSupplementalReportsForDynamicCompetitionSurveyWithTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListSupplementalReportsForDynamicCompetitionSurveyWithTokenQuery, ListSupplementalReportsForDynamicCompetitionSurveyWithTokenQueryVariables>(ListSupplementalReportsForDynamicCompetitionSurveyWithTokenDocument, options);
        }
export type ListSupplementalReportsForDynamicCompetitionSurveyWithTokenQueryHookResult = ReturnType<typeof useListSupplementalReportsForDynamicCompetitionSurveyWithTokenQuery>;
export type ListSupplementalReportsForDynamicCompetitionSurveyWithTokenLazyQueryHookResult = ReturnType<typeof useListSupplementalReportsForDynamicCompetitionSurveyWithTokenLazyQuery>;
export type ListSupplementalReportsForDynamicCompetitionSurveyWithTokenQueryResult = Apollo.QueryResult<ListSupplementalReportsForDynamicCompetitionSurveyWithTokenQuery, ListSupplementalReportsForDynamicCompetitionSurveyWithTokenQueryVariables>;
export const AddSupplementalReportToDynamicCompetitionSurveyWithTokenDocument = gql`
    mutation addSupplementalReportToDynamicCompetitionSurveyWithToken($surveyToken: String!, $title: String!, $supplementalReportType: SupplementalReportTypes!, $time: String, $teamId: String, $report: String) {
  addSupplementalReportToDynamicCompetitionSurveyWithToken(
    input: {surveyToken: $surveyToken, title: $title, supplementalReportType: $supplementalReportType, time: $time, teamId: $teamId, report: $report}
  ) {
    id
    competitionSurveyId
    dynamicCompetitionSurveyId
    submittedBy {
      userId
      email
      firstName
      lastName
      profilePic
      teamId
      teamName
      teamLogo
      officialId
      assessmentObjectId
      assessmentObjectName
    }
    competitionInfo {
      competitionId
      externalId
      sport
      homeTeamId
      homeTeamName
      homeTeamScore
      homeTeamTieBreakerScore
      awayTeamId
      awayTeamName
      awayTeamScore
      awayTeamTieBreakerScore
      startTime
      timeZone
      location
      outcome
      verificationStatus
    }
    assessmentObject {
      id
      sport
      type
      classification
      name
      createdAt
      updatedAt
    }
    official {
      id
      sport
      userId
      status
      email
      firstName
      lastName
      profilePic
      createdAt
      updatedAt
    }
    team {
      id
      name
      abbreviation
      sport
      city
      stateAbbreviation
      countryCode
      classification
      gender
      parentOrganizationName
      externalId
      ageGroup
      timeZone
      website
      logo
      createdAt
      updatedAt
    }
    organizationId
    organizationName
    leagueId
    leagueName
    seasonId
    seasonName
    competitionSurveySectionQuestionId
    competitionSurveyFeedbackId
    dynamicCompetitionSurveySectionQuestionId
    dynamicCompetitionSurveyFeedbackId
    dynamicCompetitionSurveySubFeedbackId
    type
    status
    title
    report
    reportVersion
    time
    approvedDate
    reopenedDate
    submittedAt
    createdAt
    updatedAt
  }
}
    `;
export type AddSupplementalReportToDynamicCompetitionSurveyWithTokenMutationFn = Apollo.MutationFunction<AddSupplementalReportToDynamicCompetitionSurveyWithTokenMutation, AddSupplementalReportToDynamicCompetitionSurveyWithTokenMutationVariables>;

/**
 * __useAddSupplementalReportToDynamicCompetitionSurveyWithTokenMutation__
 *
 * To run a mutation, you first call `useAddSupplementalReportToDynamicCompetitionSurveyWithTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSupplementalReportToDynamicCompetitionSurveyWithTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSupplementalReportToDynamicCompetitionSurveyWithTokenMutation, { data, loading, error }] = useAddSupplementalReportToDynamicCompetitionSurveyWithTokenMutation({
 *   variables: {
 *      surveyToken: // value for 'surveyToken'
 *      title: // value for 'title'
 *      supplementalReportType: // value for 'supplementalReportType'
 *      time: // value for 'time'
 *      teamId: // value for 'teamId'
 *      report: // value for 'report'
 *   },
 * });
 */
export function useAddSupplementalReportToDynamicCompetitionSurveyWithTokenMutation(baseOptions?: Apollo.MutationHookOptions<AddSupplementalReportToDynamicCompetitionSurveyWithTokenMutation, AddSupplementalReportToDynamicCompetitionSurveyWithTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddSupplementalReportToDynamicCompetitionSurveyWithTokenMutation, AddSupplementalReportToDynamicCompetitionSurveyWithTokenMutationVariables>(AddSupplementalReportToDynamicCompetitionSurveyWithTokenDocument, options);
      }
export type AddSupplementalReportToDynamicCompetitionSurveyWithTokenMutationHookResult = ReturnType<typeof useAddSupplementalReportToDynamicCompetitionSurveyWithTokenMutation>;
export type AddSupplementalReportToDynamicCompetitionSurveyWithTokenMutationResult = Apollo.MutationResult<AddSupplementalReportToDynamicCompetitionSurveyWithTokenMutation>;
export type AddSupplementalReportToDynamicCompetitionSurveyWithTokenMutationOptions = Apollo.BaseMutationOptions<AddSupplementalReportToDynamicCompetitionSurveyWithTokenMutation, AddSupplementalReportToDynamicCompetitionSurveyWithTokenMutationVariables>;
export const RemoveSupplementalReportFromDynamicCompetitionSurveyWithTokenDocument = gql`
    mutation removeSupplementalReportFromDynamicCompetitionSurveyWithToken($surveyToken: String!, $supplementalReportId: String!) {
  removeSupplementalReportFromDynamicCompetitionSurveyWithToken(
    input: {surveyToken: $surveyToken, supplementalReportId: $supplementalReportId}
  )
}
    `;
export type RemoveSupplementalReportFromDynamicCompetitionSurveyWithTokenMutationFn = Apollo.MutationFunction<RemoveSupplementalReportFromDynamicCompetitionSurveyWithTokenMutation, RemoveSupplementalReportFromDynamicCompetitionSurveyWithTokenMutationVariables>;

/**
 * __useRemoveSupplementalReportFromDynamicCompetitionSurveyWithTokenMutation__
 *
 * To run a mutation, you first call `useRemoveSupplementalReportFromDynamicCompetitionSurveyWithTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSupplementalReportFromDynamicCompetitionSurveyWithTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSupplementalReportFromDynamicCompetitionSurveyWithTokenMutation, { data, loading, error }] = useRemoveSupplementalReportFromDynamicCompetitionSurveyWithTokenMutation({
 *   variables: {
 *      surveyToken: // value for 'surveyToken'
 *      supplementalReportId: // value for 'supplementalReportId'
 *   },
 * });
 */
export function useRemoveSupplementalReportFromDynamicCompetitionSurveyWithTokenMutation(baseOptions?: Apollo.MutationHookOptions<RemoveSupplementalReportFromDynamicCompetitionSurveyWithTokenMutation, RemoveSupplementalReportFromDynamicCompetitionSurveyWithTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveSupplementalReportFromDynamicCompetitionSurveyWithTokenMutation, RemoveSupplementalReportFromDynamicCompetitionSurveyWithTokenMutationVariables>(RemoveSupplementalReportFromDynamicCompetitionSurveyWithTokenDocument, options);
      }
export type RemoveSupplementalReportFromDynamicCompetitionSurveyWithTokenMutationHookResult = ReturnType<typeof useRemoveSupplementalReportFromDynamicCompetitionSurveyWithTokenMutation>;
export type RemoveSupplementalReportFromDynamicCompetitionSurveyWithTokenMutationResult = Apollo.MutationResult<RemoveSupplementalReportFromDynamicCompetitionSurveyWithTokenMutation>;
export type RemoveSupplementalReportFromDynamicCompetitionSurveyWithTokenMutationOptions = Apollo.BaseMutationOptions<RemoveSupplementalReportFromDynamicCompetitionSurveyWithTokenMutation, RemoveSupplementalReportFromDynamicCompetitionSurveyWithTokenMutationVariables>;
export const EditSupplementalReportForDynamicCompetitionSurveyWithTokenDocument = gql`
    mutation editSupplementalReportForDynamicCompetitionSurveyWithToken($surveyToken: String!, $supplementalReportId: String!, $title: String!, $supplementalReportType: SupplementalReportTypes!, $time: String, $teamId: String, $report: String) {
  editSupplementalReportForDynamicCompetitionSurveyWithToken(
    input: {surveyToken: $surveyToken, supplementalReportId: $supplementalReportId, title: $title, supplementalReportType: $supplementalReportType, time: $time, teamId: $teamId, report: $report}
  ) {
    id
    competitionSurveyId
    dynamicCompetitionSurveyId
    submittedBy {
      userId
      email
      firstName
      lastName
      profilePic
      teamId
      teamName
      teamLogo
      officialId
      assessmentObjectId
      assessmentObjectName
    }
    competitionInfo {
      competitionId
      externalId
      sport
      homeTeamId
      homeTeamName
      homeTeamScore
      homeTeamTieBreakerScore
      awayTeamId
      awayTeamName
      awayTeamScore
      awayTeamTieBreakerScore
      startTime
      timeZone
      location
      outcome
      verificationStatus
    }
    assessmentObject {
      id
      sport
      type
      classification
      name
      createdAt
      updatedAt
    }
    official {
      id
      sport
      userId
      status
      email
      firstName
      lastName
      profilePic
      createdAt
      updatedAt
    }
    team {
      id
      name
      abbreviation
      sport
      city
      stateAbbreviation
      countryCode
      classification
      gender
      parentOrganizationName
      externalId
      ageGroup
      timeZone
      website
      logo
      createdAt
      updatedAt
    }
    organizationId
    organizationName
    leagueId
    leagueName
    seasonId
    seasonName
    competitionSurveySectionQuestionId
    competitionSurveyFeedbackId
    dynamicCompetitionSurveySectionQuestionId
    dynamicCompetitionSurveyFeedbackId
    dynamicCompetitionSurveySubFeedbackId
    type
    status
    title
    report
    reportVersion
    time
    approvedDate
    reopenedDate
    submittedAt
    createdAt
    updatedAt
  }
}
    `;
export type EditSupplementalReportForDynamicCompetitionSurveyWithTokenMutationFn = Apollo.MutationFunction<EditSupplementalReportForDynamicCompetitionSurveyWithTokenMutation, EditSupplementalReportForDynamicCompetitionSurveyWithTokenMutationVariables>;

/**
 * __useEditSupplementalReportForDynamicCompetitionSurveyWithTokenMutation__
 *
 * To run a mutation, you first call `useEditSupplementalReportForDynamicCompetitionSurveyWithTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditSupplementalReportForDynamicCompetitionSurveyWithTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editSupplementalReportForDynamicCompetitionSurveyWithTokenMutation, { data, loading, error }] = useEditSupplementalReportForDynamicCompetitionSurveyWithTokenMutation({
 *   variables: {
 *      surveyToken: // value for 'surveyToken'
 *      supplementalReportId: // value for 'supplementalReportId'
 *      title: // value for 'title'
 *      supplementalReportType: // value for 'supplementalReportType'
 *      time: // value for 'time'
 *      teamId: // value for 'teamId'
 *      report: // value for 'report'
 *   },
 * });
 */
export function useEditSupplementalReportForDynamicCompetitionSurveyWithTokenMutation(baseOptions?: Apollo.MutationHookOptions<EditSupplementalReportForDynamicCompetitionSurveyWithTokenMutation, EditSupplementalReportForDynamicCompetitionSurveyWithTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditSupplementalReportForDynamicCompetitionSurveyWithTokenMutation, EditSupplementalReportForDynamicCompetitionSurveyWithTokenMutationVariables>(EditSupplementalReportForDynamicCompetitionSurveyWithTokenDocument, options);
      }
export type EditSupplementalReportForDynamicCompetitionSurveyWithTokenMutationHookResult = ReturnType<typeof useEditSupplementalReportForDynamicCompetitionSurveyWithTokenMutation>;
export type EditSupplementalReportForDynamicCompetitionSurveyWithTokenMutationResult = Apollo.MutationResult<EditSupplementalReportForDynamicCompetitionSurveyWithTokenMutation>;
export type EditSupplementalReportForDynamicCompetitionSurveyWithTokenMutationOptions = Apollo.BaseMutationOptions<EditSupplementalReportForDynamicCompetitionSurveyWithTokenMutation, EditSupplementalReportForDynamicCompetitionSurveyWithTokenMutationVariables>;
export const ListKeyIncidentsForDynamicCompetitionSurveyWithTokenDocument = gql`
    query listKeyIncidentsForDynamicCompetitionSurveyWithToken($surveyToken: String!) {
  listKeyIncidentsForDynamicCompetitionSurveyWithToken(
    input: {surveyToken: $surveyToken}
  ) {
    keyIncidents {
      id
      competitionSurveyId
      dynamicCompetitionSurveyId
      submittedBy {
        userId
        email
        firstName
        lastName
        profilePic
        teamId
        teamName
        teamLogo
        officialId
        assessmentObjectId
        assessmentObjectName
      }
      competitionInfo {
        competitionId
        externalId
        sport
        homeTeamId
        homeTeamName
        homeTeamScore
        homeTeamTieBreakerScore
        awayTeamId
        awayTeamName
        awayTeamScore
        awayTeamTieBreakerScore
        startTime
        timeZone
        location
        outcome
        verificationStatus
      }
      assessmentObject {
        id
        sport
        type
        classification
        name
        createdAt
        updatedAt
      }
      official {
        id
        sport
        userId
        status
        email
        firstName
        lastName
        profilePic
        createdAt
        updatedAt
      }
      team {
        id
        name
        abbreviation
        sport
        city
        stateAbbreviation
        countryCode
        classification
        gender
        parentOrganizationName
        externalId
        ageGroup
        timeZone
        website
        logo
        createdAt
        updatedAt
      }
      organizationId
      organizationName
      leagueId
      leagueName
      seasonId
      seasonName
      competitionSurveyFeedbackId
      dynamicCompetitionSurveyFeedbackId
      dynamicCompetitionSurveySubFeedbackId
      type
      status
      description
      time
      impactedOutcome
      resolvedDate
      reopenedDate
      createdAt
      updatedAt
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListKeyIncidentsForDynamicCompetitionSurveyWithTokenQuery__
 *
 * To run a query within a React component, call `useListKeyIncidentsForDynamicCompetitionSurveyWithTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useListKeyIncidentsForDynamicCompetitionSurveyWithTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListKeyIncidentsForDynamicCompetitionSurveyWithTokenQuery({
 *   variables: {
 *      surveyToken: // value for 'surveyToken'
 *   },
 * });
 */
export function useListKeyIncidentsForDynamicCompetitionSurveyWithTokenQuery(baseOptions: Apollo.QueryHookOptions<ListKeyIncidentsForDynamicCompetitionSurveyWithTokenQuery, ListKeyIncidentsForDynamicCompetitionSurveyWithTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListKeyIncidentsForDynamicCompetitionSurveyWithTokenQuery, ListKeyIncidentsForDynamicCompetitionSurveyWithTokenQueryVariables>(ListKeyIncidentsForDynamicCompetitionSurveyWithTokenDocument, options);
      }
export function useListKeyIncidentsForDynamicCompetitionSurveyWithTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListKeyIncidentsForDynamicCompetitionSurveyWithTokenQuery, ListKeyIncidentsForDynamicCompetitionSurveyWithTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListKeyIncidentsForDynamicCompetitionSurveyWithTokenQuery, ListKeyIncidentsForDynamicCompetitionSurveyWithTokenQueryVariables>(ListKeyIncidentsForDynamicCompetitionSurveyWithTokenDocument, options);
        }
export type ListKeyIncidentsForDynamicCompetitionSurveyWithTokenQueryHookResult = ReturnType<typeof useListKeyIncidentsForDynamicCompetitionSurveyWithTokenQuery>;
export type ListKeyIncidentsForDynamicCompetitionSurveyWithTokenLazyQueryHookResult = ReturnType<typeof useListKeyIncidentsForDynamicCompetitionSurveyWithTokenLazyQuery>;
export type ListKeyIncidentsForDynamicCompetitionSurveyWithTokenQueryResult = Apollo.QueryResult<ListKeyIncidentsForDynamicCompetitionSurveyWithTokenQuery, ListKeyIncidentsForDynamicCompetitionSurveyWithTokenQueryVariables>;
export const SignDynamicCompetitionSurveyWithTokenDocument = gql`
    mutation signDynamicCompetitionSurveyWithToken($surveyToken: String!) {
  signDynamicCompetitionSurveyWithToken(input: {surveyToken: $surveyToken}) {
    competitionSurveyId
    signedDate
    userId
    email
    firstName
    lastName
    profilePic
  }
}
    `;
export type SignDynamicCompetitionSurveyWithTokenMutationFn = Apollo.MutationFunction<SignDynamicCompetitionSurveyWithTokenMutation, SignDynamicCompetitionSurveyWithTokenMutationVariables>;

/**
 * __useSignDynamicCompetitionSurveyWithTokenMutation__
 *
 * To run a mutation, you first call `useSignDynamicCompetitionSurveyWithTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignDynamicCompetitionSurveyWithTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signDynamicCompetitionSurveyWithTokenMutation, { data, loading, error }] = useSignDynamicCompetitionSurveyWithTokenMutation({
 *   variables: {
 *      surveyToken: // value for 'surveyToken'
 *   },
 * });
 */
export function useSignDynamicCompetitionSurveyWithTokenMutation(baseOptions?: Apollo.MutationHookOptions<SignDynamicCompetitionSurveyWithTokenMutation, SignDynamicCompetitionSurveyWithTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignDynamicCompetitionSurveyWithTokenMutation, SignDynamicCompetitionSurveyWithTokenMutationVariables>(SignDynamicCompetitionSurveyWithTokenDocument, options);
      }
export type SignDynamicCompetitionSurveyWithTokenMutationHookResult = ReturnType<typeof useSignDynamicCompetitionSurveyWithTokenMutation>;
export type SignDynamicCompetitionSurveyWithTokenMutationResult = Apollo.MutationResult<SignDynamicCompetitionSurveyWithTokenMutation>;
export type SignDynamicCompetitionSurveyWithTokenMutationOptions = Apollo.BaseMutationOptions<SignDynamicCompetitionSurveyWithTokenMutation, SignDynamicCompetitionSurveyWithTokenMutationVariables>;
export const CompleteDynamicCompetitionSurveyWithTokenDocument = gql`
    mutation completeDynamicCompetitionSurveyWithToken($surveyToken: String!) {
  completeDynamicCompetitionSurveyWithToken(input: {surveyToken: $surveyToken})
}
    `;
export type CompleteDynamicCompetitionSurveyWithTokenMutationFn = Apollo.MutationFunction<CompleteDynamicCompetitionSurveyWithTokenMutation, CompleteDynamicCompetitionSurveyWithTokenMutationVariables>;

/**
 * __useCompleteDynamicCompetitionSurveyWithTokenMutation__
 *
 * To run a mutation, you first call `useCompleteDynamicCompetitionSurveyWithTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteDynamicCompetitionSurveyWithTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeDynamicCompetitionSurveyWithTokenMutation, { data, loading, error }] = useCompleteDynamicCompetitionSurveyWithTokenMutation({
 *   variables: {
 *      surveyToken: // value for 'surveyToken'
 *   },
 * });
 */
export function useCompleteDynamicCompetitionSurveyWithTokenMutation(baseOptions?: Apollo.MutationHookOptions<CompleteDynamicCompetitionSurveyWithTokenMutation, CompleteDynamicCompetitionSurveyWithTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteDynamicCompetitionSurveyWithTokenMutation, CompleteDynamicCompetitionSurveyWithTokenMutationVariables>(CompleteDynamicCompetitionSurveyWithTokenDocument, options);
      }
export type CompleteDynamicCompetitionSurveyWithTokenMutationHookResult = ReturnType<typeof useCompleteDynamicCompetitionSurveyWithTokenMutation>;
export type CompleteDynamicCompetitionSurveyWithTokenMutationResult = Apollo.MutationResult<CompleteDynamicCompetitionSurveyWithTokenMutation>;
export type CompleteDynamicCompetitionSurveyWithTokenMutationOptions = Apollo.BaseMutationOptions<CompleteDynamicCompetitionSurveyWithTokenMutation, CompleteDynamicCompetitionSurveyWithTokenMutationVariables>;
export const ConvertDynamicCompetitionSurveyMultipleResponseToKeyIncidentWithTokenDocument = gql`
    mutation convertDynamicCompetitionSurveyMultipleResponseToKeyIncidentWithToken($surveyToken: String!, $questionId: String!, $feedbackResponseId: String!, $incidentType: KeyIncidentTypes!, $impactedOutcome: Boolean!, $time: String, $description: String) {
  convertDynamicCompetitionSurveyMultipleResponseToKeyIncidentWithToken(
    input: {surveyToken: $surveyToken, questionId: $questionId, feedbackResponseId: $feedbackResponseId, incidentType: $incidentType, impactedOutcome: $impactedOutcome, time: $time, description: $description}
  ) {
    id
    competitionSurveyId
    dynamicCompetitionSurveyId
    submittedBy {
      userId
      email
      firstName
      lastName
      profilePic
      teamId
      teamName
      teamLogo
      officialId
      assessmentObjectId
      assessmentObjectName
    }
    competitionInfo {
      competitionId
      externalId
      sport
      homeTeamId
      homeTeamName
      homeTeamScore
      homeTeamTieBreakerScore
      awayTeamId
      awayTeamName
      awayTeamScore
      awayTeamTieBreakerScore
      startTime
      timeZone
      location
      outcome
      verificationStatus
    }
    assessmentObject {
      id
      sport
      type
      classification
      name
      createdAt
      updatedAt
    }
    official {
      id
      sport
      userId
      status
      email
      firstName
      lastName
      profilePic
      createdAt
      updatedAt
    }
    team {
      id
      name
      abbreviation
      sport
      city
      stateAbbreviation
      countryCode
      classification
      gender
      parentOrganizationName
      externalId
      ageGroup
      timeZone
      website
      logo
      createdAt
      updatedAt
    }
    organizationId
    organizationName
    leagueId
    leagueName
    seasonId
    seasonName
    competitionSurveyFeedbackId
    dynamicCompetitionSurveyFeedbackId
    dynamicCompetitionSurveySubFeedbackId
    type
    status
    description
    time
    impactedOutcome
    resolvedDate
    reopenedDate
    createdAt
    updatedAt
  }
}
    `;
export type ConvertDynamicCompetitionSurveyMultipleResponseToKeyIncidentWithTokenMutationFn = Apollo.MutationFunction<ConvertDynamicCompetitionSurveyMultipleResponseToKeyIncidentWithTokenMutation, ConvertDynamicCompetitionSurveyMultipleResponseToKeyIncidentWithTokenMutationVariables>;

/**
 * __useConvertDynamicCompetitionSurveyMultipleResponseToKeyIncidentWithTokenMutation__
 *
 * To run a mutation, you first call `useConvertDynamicCompetitionSurveyMultipleResponseToKeyIncidentWithTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConvertDynamicCompetitionSurveyMultipleResponseToKeyIncidentWithTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [convertDynamicCompetitionSurveyMultipleResponseToKeyIncidentWithTokenMutation, { data, loading, error }] = useConvertDynamicCompetitionSurveyMultipleResponseToKeyIncidentWithTokenMutation({
 *   variables: {
 *      surveyToken: // value for 'surveyToken'
 *      questionId: // value for 'questionId'
 *      feedbackResponseId: // value for 'feedbackResponseId'
 *      incidentType: // value for 'incidentType'
 *      impactedOutcome: // value for 'impactedOutcome'
 *      time: // value for 'time'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useConvertDynamicCompetitionSurveyMultipleResponseToKeyIncidentWithTokenMutation(baseOptions?: Apollo.MutationHookOptions<ConvertDynamicCompetitionSurveyMultipleResponseToKeyIncidentWithTokenMutation, ConvertDynamicCompetitionSurveyMultipleResponseToKeyIncidentWithTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConvertDynamicCompetitionSurveyMultipleResponseToKeyIncidentWithTokenMutation, ConvertDynamicCompetitionSurveyMultipleResponseToKeyIncidentWithTokenMutationVariables>(ConvertDynamicCompetitionSurveyMultipleResponseToKeyIncidentWithTokenDocument, options);
      }
export type ConvertDynamicCompetitionSurveyMultipleResponseToKeyIncidentWithTokenMutationHookResult = ReturnType<typeof useConvertDynamicCompetitionSurveyMultipleResponseToKeyIncidentWithTokenMutation>;
export type ConvertDynamicCompetitionSurveyMultipleResponseToKeyIncidentWithTokenMutationResult = Apollo.MutationResult<ConvertDynamicCompetitionSurveyMultipleResponseToKeyIncidentWithTokenMutation>;
export type ConvertDynamicCompetitionSurveyMultipleResponseToKeyIncidentWithTokenMutationOptions = Apollo.BaseMutationOptions<ConvertDynamicCompetitionSurveyMultipleResponseToKeyIncidentWithTokenMutation, ConvertDynamicCompetitionSurveyMultipleResponseToKeyIncidentWithTokenMutationVariables>;
export const ConvertDynamicCompetitionSurveySubMultipleResponseToKeyIncidentWithTokenDocument = gql`
    mutation convertDynamicCompetitionSurveySubMultipleResponseToKeyIncidentWithToken($surveyToken: String!, $subQuestionId: String!, $feedbackResponseId: String!, $incidentType: KeyIncidentTypes!, $impactedOutcome: Boolean!, $time: String, $description: String) {
  convertDynamicCompetitionSurveySubMultipleResponseToKeyIncidentWithToken(
    input: {surveyToken: $surveyToken, subQuestionId: $subQuestionId, feedbackResponseId: $feedbackResponseId, incidentType: $incidentType, impactedOutcome: $impactedOutcome, time: $time, description: $description}
  ) {
    id
    competitionSurveyId
    dynamicCompetitionSurveyId
    submittedBy {
      userId
      email
      firstName
      lastName
      profilePic
      teamId
      teamName
      teamLogo
      officialId
      assessmentObjectId
      assessmentObjectName
    }
    competitionInfo {
      competitionId
      externalId
      sport
      homeTeamId
      homeTeamName
      homeTeamScore
      homeTeamTieBreakerScore
      awayTeamId
      awayTeamName
      awayTeamScore
      awayTeamTieBreakerScore
      startTime
      timeZone
      location
      outcome
      verificationStatus
    }
    assessmentObject {
      id
      sport
      type
      classification
      name
      createdAt
      updatedAt
    }
    official {
      id
      sport
      userId
      status
      email
      firstName
      lastName
      profilePic
      createdAt
      updatedAt
    }
    team {
      id
      name
      abbreviation
      sport
      city
      stateAbbreviation
      countryCode
      classification
      gender
      parentOrganizationName
      externalId
      ageGroup
      timeZone
      website
      logo
      createdAt
      updatedAt
    }
    organizationId
    organizationName
    leagueId
    leagueName
    seasonId
    seasonName
    competitionSurveyFeedbackId
    dynamicCompetitionSurveyFeedbackId
    dynamicCompetitionSurveySubFeedbackId
    type
    status
    description
    time
    impactedOutcome
    resolvedDate
    reopenedDate
    createdAt
    updatedAt
  }
}
    `;
export type ConvertDynamicCompetitionSurveySubMultipleResponseToKeyIncidentWithTokenMutationFn = Apollo.MutationFunction<ConvertDynamicCompetitionSurveySubMultipleResponseToKeyIncidentWithTokenMutation, ConvertDynamicCompetitionSurveySubMultipleResponseToKeyIncidentWithTokenMutationVariables>;

/**
 * __useConvertDynamicCompetitionSurveySubMultipleResponseToKeyIncidentWithTokenMutation__
 *
 * To run a mutation, you first call `useConvertDynamicCompetitionSurveySubMultipleResponseToKeyIncidentWithTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConvertDynamicCompetitionSurveySubMultipleResponseToKeyIncidentWithTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [convertDynamicCompetitionSurveySubMultipleResponseToKeyIncidentWithTokenMutation, { data, loading, error }] = useConvertDynamicCompetitionSurveySubMultipleResponseToKeyIncidentWithTokenMutation({
 *   variables: {
 *      surveyToken: // value for 'surveyToken'
 *      subQuestionId: // value for 'subQuestionId'
 *      feedbackResponseId: // value for 'feedbackResponseId'
 *      incidentType: // value for 'incidentType'
 *      impactedOutcome: // value for 'impactedOutcome'
 *      time: // value for 'time'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useConvertDynamicCompetitionSurveySubMultipleResponseToKeyIncidentWithTokenMutation(baseOptions?: Apollo.MutationHookOptions<ConvertDynamicCompetitionSurveySubMultipleResponseToKeyIncidentWithTokenMutation, ConvertDynamicCompetitionSurveySubMultipleResponseToKeyIncidentWithTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConvertDynamicCompetitionSurveySubMultipleResponseToKeyIncidentWithTokenMutation, ConvertDynamicCompetitionSurveySubMultipleResponseToKeyIncidentWithTokenMutationVariables>(ConvertDynamicCompetitionSurveySubMultipleResponseToKeyIncidentWithTokenDocument, options);
      }
export type ConvertDynamicCompetitionSurveySubMultipleResponseToKeyIncidentWithTokenMutationHookResult = ReturnType<typeof useConvertDynamicCompetitionSurveySubMultipleResponseToKeyIncidentWithTokenMutation>;
export type ConvertDynamicCompetitionSurveySubMultipleResponseToKeyIncidentWithTokenMutationResult = Apollo.MutationResult<ConvertDynamicCompetitionSurveySubMultipleResponseToKeyIncidentWithTokenMutation>;
export type ConvertDynamicCompetitionSurveySubMultipleResponseToKeyIncidentWithTokenMutationOptions = Apollo.BaseMutationOptions<ConvertDynamicCompetitionSurveySubMultipleResponseToKeyIncidentWithTokenMutation, ConvertDynamicCompetitionSurveySubMultipleResponseToKeyIncidentWithTokenMutationVariables>;
export const AddKeyIncidentToDynamicCompetitionSurveyWithTokenDocument = gql`
    mutation addKeyIncidentToDynamicCompetitionSurveyWithToken($surveyToken: String!, $description: String!, $incidentType: KeyIncidentTypes!, $impactedOutcome: Boolean!, $time: String, $teamId: String, $sectionId: String, $questionId: String) {
  addKeyIncidentToDynamicCompetitionSurveyWithToken(
    input: {surveyToken: $surveyToken, description: $description, incidentType: $incidentType, impactedOutcome: $impactedOutcome, time: $time, teamId: $teamId, sectionId: $sectionId, questionId: $questionId}
  ) {
    id
    competitionSurveyId
    dynamicCompetitionSurveyId
    submittedBy {
      userId
      email
      firstName
      lastName
      profilePic
      teamId
      teamName
      teamLogo
      officialId
      assessmentObjectId
      assessmentObjectName
    }
    competitionInfo {
      competitionId
      externalId
      sport
      homeTeamId
      homeTeamName
      homeTeamScore
      homeTeamTieBreakerScore
      awayTeamId
      awayTeamName
      awayTeamScore
      awayTeamTieBreakerScore
      startTime
      timeZone
      location
      outcome
      verificationStatus
    }
    assessmentObject {
      id
      sport
      type
      classification
      name
      createdAt
      updatedAt
    }
    official {
      id
      sport
      userId
      status
      email
      firstName
      lastName
      profilePic
      createdAt
      updatedAt
    }
    team {
      id
      name
      abbreviation
      sport
      city
      stateAbbreviation
      countryCode
      classification
      gender
      parentOrganizationName
      externalId
      ageGroup
      timeZone
      website
      logo
      createdAt
      updatedAt
    }
    organizationId
    organizationName
    leagueId
    leagueName
    seasonId
    seasonName
    competitionSurveyFeedbackId
    dynamicCompetitionSurveyFeedbackId
    dynamicCompetitionSurveySubFeedbackId
    type
    status
    description
    time
    impactedOutcome
    resolvedDate
    reopenedDate
    createdAt
    updatedAt
  }
}
    `;
export type AddKeyIncidentToDynamicCompetitionSurveyWithTokenMutationFn = Apollo.MutationFunction<AddKeyIncidentToDynamicCompetitionSurveyWithTokenMutation, AddKeyIncidentToDynamicCompetitionSurveyWithTokenMutationVariables>;

/**
 * __useAddKeyIncidentToDynamicCompetitionSurveyWithTokenMutation__
 *
 * To run a mutation, you first call `useAddKeyIncidentToDynamicCompetitionSurveyWithTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddKeyIncidentToDynamicCompetitionSurveyWithTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addKeyIncidentToDynamicCompetitionSurveyWithTokenMutation, { data, loading, error }] = useAddKeyIncidentToDynamicCompetitionSurveyWithTokenMutation({
 *   variables: {
 *      surveyToken: // value for 'surveyToken'
 *      description: // value for 'description'
 *      incidentType: // value for 'incidentType'
 *      impactedOutcome: // value for 'impactedOutcome'
 *      time: // value for 'time'
 *      teamId: // value for 'teamId'
 *      sectionId: // value for 'sectionId'
 *      questionId: // value for 'questionId'
 *   },
 * });
 */
export function useAddKeyIncidentToDynamicCompetitionSurveyWithTokenMutation(baseOptions?: Apollo.MutationHookOptions<AddKeyIncidentToDynamicCompetitionSurveyWithTokenMutation, AddKeyIncidentToDynamicCompetitionSurveyWithTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddKeyIncidentToDynamicCompetitionSurveyWithTokenMutation, AddKeyIncidentToDynamicCompetitionSurveyWithTokenMutationVariables>(AddKeyIncidentToDynamicCompetitionSurveyWithTokenDocument, options);
      }
export type AddKeyIncidentToDynamicCompetitionSurveyWithTokenMutationHookResult = ReturnType<typeof useAddKeyIncidentToDynamicCompetitionSurveyWithTokenMutation>;
export type AddKeyIncidentToDynamicCompetitionSurveyWithTokenMutationResult = Apollo.MutationResult<AddKeyIncidentToDynamicCompetitionSurveyWithTokenMutation>;
export type AddKeyIncidentToDynamicCompetitionSurveyWithTokenMutationOptions = Apollo.BaseMutationOptions<AddKeyIncidentToDynamicCompetitionSurveyWithTokenMutation, AddKeyIncidentToDynamicCompetitionSurveyWithTokenMutationVariables>;
export const EditKeyIncidentForDynamicCompetitionSurveyWithTokenDocument = gql`
    mutation editKeyIncidentForDynamicCompetitionSurveyWithToken($surveyToken: String!, $keyIncidentId: String!, $description: String!, $incidentType: KeyIncidentTypes!, $impactedOutcome: Boolean!, $time: String) {
  editKeyIncidentForDynamicCompetitionSurveyWithToken(
    input: {surveyToken: $surveyToken, keyIncidentId: $keyIncidentId, description: $description, incidentType: $incidentType, impactedOutcome: $impactedOutcome, time: $time}
  ) {
    id
    competitionSurveyId
    dynamicCompetitionSurveyId
    submittedBy {
      userId
      email
      firstName
      lastName
      profilePic
      teamId
      teamName
      teamLogo
      officialId
      assessmentObjectId
      assessmentObjectName
    }
    competitionInfo {
      competitionId
      externalId
      sport
      homeTeamId
      homeTeamName
      homeTeamScore
      homeTeamTieBreakerScore
      awayTeamId
      awayTeamName
      awayTeamScore
      awayTeamTieBreakerScore
      startTime
      timeZone
      location
      outcome
      verificationStatus
    }
    assessmentObject {
      id
      sport
      type
      classification
      name
      createdAt
      updatedAt
    }
    official {
      id
      sport
      userId
      status
      email
      firstName
      lastName
      profilePic
      createdAt
      updatedAt
    }
    team {
      id
      name
      abbreviation
      sport
      city
      stateAbbreviation
      countryCode
      classification
      gender
      parentOrganizationName
      externalId
      ageGroup
      timeZone
      website
      logo
      createdAt
      updatedAt
    }
    organizationId
    organizationName
    leagueId
    leagueName
    seasonId
    seasonName
    competitionSurveyFeedbackId
    dynamicCompetitionSurveyFeedbackId
    dynamicCompetitionSurveySubFeedbackId
    type
    status
    description
    time
    impactedOutcome
    resolvedDate
    reopenedDate
    createdAt
    updatedAt
  }
}
    `;
export type EditKeyIncidentForDynamicCompetitionSurveyWithTokenMutationFn = Apollo.MutationFunction<EditKeyIncidentForDynamicCompetitionSurveyWithTokenMutation, EditKeyIncidentForDynamicCompetitionSurveyWithTokenMutationVariables>;

/**
 * __useEditKeyIncidentForDynamicCompetitionSurveyWithTokenMutation__
 *
 * To run a mutation, you first call `useEditKeyIncidentForDynamicCompetitionSurveyWithTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditKeyIncidentForDynamicCompetitionSurveyWithTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editKeyIncidentForDynamicCompetitionSurveyWithTokenMutation, { data, loading, error }] = useEditKeyIncidentForDynamicCompetitionSurveyWithTokenMutation({
 *   variables: {
 *      surveyToken: // value for 'surveyToken'
 *      keyIncidentId: // value for 'keyIncidentId'
 *      description: // value for 'description'
 *      incidentType: // value for 'incidentType'
 *      impactedOutcome: // value for 'impactedOutcome'
 *      time: // value for 'time'
 *   },
 * });
 */
export function useEditKeyIncidentForDynamicCompetitionSurveyWithTokenMutation(baseOptions?: Apollo.MutationHookOptions<EditKeyIncidentForDynamicCompetitionSurveyWithTokenMutation, EditKeyIncidentForDynamicCompetitionSurveyWithTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditKeyIncidentForDynamicCompetitionSurveyWithTokenMutation, EditKeyIncidentForDynamicCompetitionSurveyWithTokenMutationVariables>(EditKeyIncidentForDynamicCompetitionSurveyWithTokenDocument, options);
      }
export type EditKeyIncidentForDynamicCompetitionSurveyWithTokenMutationHookResult = ReturnType<typeof useEditKeyIncidentForDynamicCompetitionSurveyWithTokenMutation>;
export type EditKeyIncidentForDynamicCompetitionSurveyWithTokenMutationResult = Apollo.MutationResult<EditKeyIncidentForDynamicCompetitionSurveyWithTokenMutation>;
export type EditKeyIncidentForDynamicCompetitionSurveyWithTokenMutationOptions = Apollo.BaseMutationOptions<EditKeyIncidentForDynamicCompetitionSurveyWithTokenMutation, EditKeyIncidentForDynamicCompetitionSurveyWithTokenMutationVariables>;
export const RemoveKeyIncidentFromDynamicCompetitionSurveyWithTokenDocument = gql`
    mutation removeKeyIncidentFromDynamicCompetitionSurveyWithToken($surveyToken: String!, $keyIncidentId: String!) {
  removeKeyIncidentFromDynamicCompetitionSurveyWithToken(
    input: {surveyToken: $surveyToken, keyIncidentId: $keyIncidentId}
  )
}
    `;
export type RemoveKeyIncidentFromDynamicCompetitionSurveyWithTokenMutationFn = Apollo.MutationFunction<RemoveKeyIncidentFromDynamicCompetitionSurveyWithTokenMutation, RemoveKeyIncidentFromDynamicCompetitionSurveyWithTokenMutationVariables>;

/**
 * __useRemoveKeyIncidentFromDynamicCompetitionSurveyWithTokenMutation__
 *
 * To run a mutation, you first call `useRemoveKeyIncidentFromDynamicCompetitionSurveyWithTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveKeyIncidentFromDynamicCompetitionSurveyWithTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeKeyIncidentFromDynamicCompetitionSurveyWithTokenMutation, { data, loading, error }] = useRemoveKeyIncidentFromDynamicCompetitionSurveyWithTokenMutation({
 *   variables: {
 *      surveyToken: // value for 'surveyToken'
 *      keyIncidentId: // value for 'keyIncidentId'
 *   },
 * });
 */
export function useRemoveKeyIncidentFromDynamicCompetitionSurveyWithTokenMutation(baseOptions?: Apollo.MutationHookOptions<RemoveKeyIncidentFromDynamicCompetitionSurveyWithTokenMutation, RemoveKeyIncidentFromDynamicCompetitionSurveyWithTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveKeyIncidentFromDynamicCompetitionSurveyWithTokenMutation, RemoveKeyIncidentFromDynamicCompetitionSurveyWithTokenMutationVariables>(RemoveKeyIncidentFromDynamicCompetitionSurveyWithTokenDocument, options);
      }
export type RemoveKeyIncidentFromDynamicCompetitionSurveyWithTokenMutationHookResult = ReturnType<typeof useRemoveKeyIncidentFromDynamicCompetitionSurveyWithTokenMutation>;
export type RemoveKeyIncidentFromDynamicCompetitionSurveyWithTokenMutationResult = Apollo.MutationResult<RemoveKeyIncidentFromDynamicCompetitionSurveyWithTokenMutation>;
export type RemoveKeyIncidentFromDynamicCompetitionSurveyWithTokenMutationOptions = Apollo.BaseMutationOptions<RemoveKeyIncidentFromDynamicCompetitionSurveyWithTokenMutation, RemoveKeyIncidentFromDynamicCompetitionSurveyWithTokenMutationVariables>;
export const CreateDynamicSurveyIntegrationForOrganizationDocument = gql`
    mutation createDynamicSurveyIntegrationForOrganization($orgId: String!, $name: String!, $description: String!, $status: DynamicSurveyIntegrationStatuses!, $queueName: String!) {
  createDynamicSurveyIntegrationForOrganization(
    input: {orgId: $orgId, name: $name, description: $description, status: $status, queueName: $queueName}
  ) {
    id
    orgId
    orgName
    orgAbbreviation
    name
    description
    status
    queueName
    additionalOptions {
      id
      name
      value
      createdAt
      updatedAt
    }
    createdAt
    updatedAt
  }
}
    `;
export type CreateDynamicSurveyIntegrationForOrganizationMutationFn = Apollo.MutationFunction<CreateDynamicSurveyIntegrationForOrganizationMutation, CreateDynamicSurveyIntegrationForOrganizationMutationVariables>;

/**
 * __useCreateDynamicSurveyIntegrationForOrganizationMutation__
 *
 * To run a mutation, you first call `useCreateDynamicSurveyIntegrationForOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDynamicSurveyIntegrationForOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDynamicSurveyIntegrationForOrganizationMutation, { data, loading, error }] = useCreateDynamicSurveyIntegrationForOrganizationMutation({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      status: // value for 'status'
 *      queueName: // value for 'queueName'
 *   },
 * });
 */
export function useCreateDynamicSurveyIntegrationForOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<CreateDynamicSurveyIntegrationForOrganizationMutation, CreateDynamicSurveyIntegrationForOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDynamicSurveyIntegrationForOrganizationMutation, CreateDynamicSurveyIntegrationForOrganizationMutationVariables>(CreateDynamicSurveyIntegrationForOrganizationDocument, options);
      }
export type CreateDynamicSurveyIntegrationForOrganizationMutationHookResult = ReturnType<typeof useCreateDynamicSurveyIntegrationForOrganizationMutation>;
export type CreateDynamicSurveyIntegrationForOrganizationMutationResult = Apollo.MutationResult<CreateDynamicSurveyIntegrationForOrganizationMutation>;
export type CreateDynamicSurveyIntegrationForOrganizationMutationOptions = Apollo.BaseMutationOptions<CreateDynamicSurveyIntegrationForOrganizationMutation, CreateDynamicSurveyIntegrationForOrganizationMutationVariables>;
export const GetDynamicSurveyIntegrationByIdDocument = gql`
    query getDynamicSurveyIntegrationById($id: String!) {
  getDynamicSurveyIntegrationById(input: {id: $id}) {
    id
    orgId
    orgName
    orgAbbreviation
    name
    description
    status
    queueName
    additionalOptions {
      id
      name
      value
      createdAt
      updatedAt
    }
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetDynamicSurveyIntegrationByIdQuery__
 *
 * To run a query within a React component, call `useGetDynamicSurveyIntegrationByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDynamicSurveyIntegrationByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDynamicSurveyIntegrationByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDynamicSurveyIntegrationByIdQuery(baseOptions: Apollo.QueryHookOptions<GetDynamicSurveyIntegrationByIdQuery, GetDynamicSurveyIntegrationByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDynamicSurveyIntegrationByIdQuery, GetDynamicSurveyIntegrationByIdQueryVariables>(GetDynamicSurveyIntegrationByIdDocument, options);
      }
export function useGetDynamicSurveyIntegrationByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDynamicSurveyIntegrationByIdQuery, GetDynamicSurveyIntegrationByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDynamicSurveyIntegrationByIdQuery, GetDynamicSurveyIntegrationByIdQueryVariables>(GetDynamicSurveyIntegrationByIdDocument, options);
        }
export type GetDynamicSurveyIntegrationByIdQueryHookResult = ReturnType<typeof useGetDynamicSurveyIntegrationByIdQuery>;
export type GetDynamicSurveyIntegrationByIdLazyQueryHookResult = ReturnType<typeof useGetDynamicSurveyIntegrationByIdLazyQuery>;
export type GetDynamicSurveyIntegrationByIdQueryResult = Apollo.QueryResult<GetDynamicSurveyIntegrationByIdQuery, GetDynamicSurveyIntegrationByIdQueryVariables>;
export const ListDynamicSurveyIntegrationsDocument = gql`
    query listDynamicSurveyIntegrations($offset: Int!, $limit: Int!, $orgId: String, $orgName: String, $name: String, $queueName: String, $status: DynamicSurveyIntegrationStatuses) {
  listDynamicSurveyIntegrations(
    input: {orgId: $orgId, orgName: $orgName, name: $name, queueName: $queueName, status: $status, pagination: {offset: $offset, limit: $limit}}
  ) {
    integrations {
      id
      orgId
      orgName
      orgAbbreviation
      name
      description
      status
      queueName
      additionalOptions {
        id
        name
        value
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListDynamicSurveyIntegrationsQuery__
 *
 * To run a query within a React component, call `useListDynamicSurveyIntegrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListDynamicSurveyIntegrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListDynamicSurveyIntegrationsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      orgId: // value for 'orgId'
 *      orgName: // value for 'orgName'
 *      name: // value for 'name'
 *      queueName: // value for 'queueName'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useListDynamicSurveyIntegrationsQuery(baseOptions: Apollo.QueryHookOptions<ListDynamicSurveyIntegrationsQuery, ListDynamicSurveyIntegrationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListDynamicSurveyIntegrationsQuery, ListDynamicSurveyIntegrationsQueryVariables>(ListDynamicSurveyIntegrationsDocument, options);
      }
export function useListDynamicSurveyIntegrationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListDynamicSurveyIntegrationsQuery, ListDynamicSurveyIntegrationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListDynamicSurveyIntegrationsQuery, ListDynamicSurveyIntegrationsQueryVariables>(ListDynamicSurveyIntegrationsDocument, options);
        }
export type ListDynamicSurveyIntegrationsQueryHookResult = ReturnType<typeof useListDynamicSurveyIntegrationsQuery>;
export type ListDynamicSurveyIntegrationsLazyQueryHookResult = ReturnType<typeof useListDynamicSurveyIntegrationsLazyQuery>;
export type ListDynamicSurveyIntegrationsQueryResult = Apollo.QueryResult<ListDynamicSurveyIntegrationsQuery, ListDynamicSurveyIntegrationsQueryVariables>;
export const UpdateDynamicSurveyIntegrationDocument = gql`
    mutation updateDynamicSurveyIntegration($id: String!, $name: String!, $description: String!, $status: DynamicSurveyIntegrationStatuses!, $queueName: String!) {
  updateDynamicSurveyIntegration(
    input: {id: $id, name: $name, description: $description, status: $status, queueName: $queueName}
  ) {
    id
    orgId
    orgName
    orgAbbreviation
    name
    description
    status
    queueName
    additionalOptions {
      id
      name
      value
      createdAt
      updatedAt
    }
    createdAt
    updatedAt
  }
}
    `;
export type UpdateDynamicSurveyIntegrationMutationFn = Apollo.MutationFunction<UpdateDynamicSurveyIntegrationMutation, UpdateDynamicSurveyIntegrationMutationVariables>;

/**
 * __useUpdateDynamicSurveyIntegrationMutation__
 *
 * To run a mutation, you first call `useUpdateDynamicSurveyIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDynamicSurveyIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDynamicSurveyIntegrationMutation, { data, loading, error }] = useUpdateDynamicSurveyIntegrationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      status: // value for 'status'
 *      queueName: // value for 'queueName'
 *   },
 * });
 */
export function useUpdateDynamicSurveyIntegrationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDynamicSurveyIntegrationMutation, UpdateDynamicSurveyIntegrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDynamicSurveyIntegrationMutation, UpdateDynamicSurveyIntegrationMutationVariables>(UpdateDynamicSurveyIntegrationDocument, options);
      }
export type UpdateDynamicSurveyIntegrationMutationHookResult = ReturnType<typeof useUpdateDynamicSurveyIntegrationMutation>;
export type UpdateDynamicSurveyIntegrationMutationResult = Apollo.MutationResult<UpdateDynamicSurveyIntegrationMutation>;
export type UpdateDynamicSurveyIntegrationMutationOptions = Apollo.BaseMutationOptions<UpdateDynamicSurveyIntegrationMutation, UpdateDynamicSurveyIntegrationMutationVariables>;
export const DeleteDynamicSurveyIntegrationDocument = gql`
    mutation deleteDynamicSurveyIntegration($integrationId: String!) {
  deleteDynamicSurveyIntegration(input: {integrationId: $integrationId})
}
    `;
export type DeleteDynamicSurveyIntegrationMutationFn = Apollo.MutationFunction<DeleteDynamicSurveyIntegrationMutation, DeleteDynamicSurveyIntegrationMutationVariables>;

/**
 * __useDeleteDynamicSurveyIntegrationMutation__
 *
 * To run a mutation, you first call `useDeleteDynamicSurveyIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDynamicSurveyIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDynamicSurveyIntegrationMutation, { data, loading, error }] = useDeleteDynamicSurveyIntegrationMutation({
 *   variables: {
 *      integrationId: // value for 'integrationId'
 *   },
 * });
 */
export function useDeleteDynamicSurveyIntegrationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDynamicSurveyIntegrationMutation, DeleteDynamicSurveyIntegrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDynamicSurveyIntegrationMutation, DeleteDynamicSurveyIntegrationMutationVariables>(DeleteDynamicSurveyIntegrationDocument, options);
      }
export type DeleteDynamicSurveyIntegrationMutationHookResult = ReturnType<typeof useDeleteDynamicSurveyIntegrationMutation>;
export type DeleteDynamicSurveyIntegrationMutationResult = Apollo.MutationResult<DeleteDynamicSurveyIntegrationMutation>;
export type DeleteDynamicSurveyIntegrationMutationOptions = Apollo.BaseMutationOptions<DeleteDynamicSurveyIntegrationMutation, DeleteDynamicSurveyIntegrationMutationVariables>;
export const ActivateDynamicSurveyDocument = gql`
    mutation activateDynamicSurvey($id: String!) {
  activateDynamicSurvey(input: {id: $id})
}
    `;
export type ActivateDynamicSurveyMutationFn = Apollo.MutationFunction<ActivateDynamicSurveyMutation, ActivateDynamicSurveyMutationVariables>;

/**
 * __useActivateDynamicSurveyMutation__
 *
 * To run a mutation, you first call `useActivateDynamicSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateDynamicSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateDynamicSurveyMutation, { data, loading, error }] = useActivateDynamicSurveyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useActivateDynamicSurveyMutation(baseOptions?: Apollo.MutationHookOptions<ActivateDynamicSurveyMutation, ActivateDynamicSurveyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateDynamicSurveyMutation, ActivateDynamicSurveyMutationVariables>(ActivateDynamicSurveyDocument, options);
      }
export type ActivateDynamicSurveyMutationHookResult = ReturnType<typeof useActivateDynamicSurveyMutation>;
export type ActivateDynamicSurveyMutationResult = Apollo.MutationResult<ActivateDynamicSurveyMutation>;
export type ActivateDynamicSurveyMutationOptions = Apollo.BaseMutationOptions<ActivateDynamicSurveyMutation, ActivateDynamicSurveyMutationVariables>;
export const DeactivateDynamicSurveyDocument = gql`
    mutation deactivateDynamicSurvey($id: String!) {
  deactivateDynamicSurvey(input: {id: $id})
}
    `;
export type DeactivateDynamicSurveyMutationFn = Apollo.MutationFunction<DeactivateDynamicSurveyMutation, DeactivateDynamicSurveyMutationVariables>;

/**
 * __useDeactivateDynamicSurveyMutation__
 *
 * To run a mutation, you first call `useDeactivateDynamicSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateDynamicSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateDynamicSurveyMutation, { data, loading, error }] = useDeactivateDynamicSurveyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeactivateDynamicSurveyMutation(baseOptions?: Apollo.MutationHookOptions<DeactivateDynamicSurveyMutation, DeactivateDynamicSurveyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeactivateDynamicSurveyMutation, DeactivateDynamicSurveyMutationVariables>(DeactivateDynamicSurveyDocument, options);
      }
export type DeactivateDynamicSurveyMutationHookResult = ReturnType<typeof useDeactivateDynamicSurveyMutation>;
export type DeactivateDynamicSurveyMutationResult = Apollo.MutationResult<DeactivateDynamicSurveyMutation>;
export type DeactivateDynamicSurveyMutationOptions = Apollo.BaseMutationOptions<DeactivateDynamicSurveyMutation, DeactivateDynamicSurveyMutationVariables>;
export const ListAdditionalOptionsForDynamicSurveyIntegrationDocument = gql`
    query listAdditionalOptionsForDynamicSurveyIntegration($offset: Int!, $limit: Int!, $integrationId: String!) {
  listAdditionalOptionsForDynamicSurveyIntegration(
    input: {integrationId: $integrationId, pagination: {offset: $offset, limit: $limit}}
  ) {
    additionalOptions {
      id
      name
      value
      createdAt
      updatedAt
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListAdditionalOptionsForDynamicSurveyIntegrationQuery__
 *
 * To run a query within a React component, call `useListAdditionalOptionsForDynamicSurveyIntegrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAdditionalOptionsForDynamicSurveyIntegrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAdditionalOptionsForDynamicSurveyIntegrationQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      integrationId: // value for 'integrationId'
 *   },
 * });
 */
export function useListAdditionalOptionsForDynamicSurveyIntegrationQuery(baseOptions: Apollo.QueryHookOptions<ListAdditionalOptionsForDynamicSurveyIntegrationQuery, ListAdditionalOptionsForDynamicSurveyIntegrationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAdditionalOptionsForDynamicSurveyIntegrationQuery, ListAdditionalOptionsForDynamicSurveyIntegrationQueryVariables>(ListAdditionalOptionsForDynamicSurveyIntegrationDocument, options);
      }
export function useListAdditionalOptionsForDynamicSurveyIntegrationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAdditionalOptionsForDynamicSurveyIntegrationQuery, ListAdditionalOptionsForDynamicSurveyIntegrationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAdditionalOptionsForDynamicSurveyIntegrationQuery, ListAdditionalOptionsForDynamicSurveyIntegrationQueryVariables>(ListAdditionalOptionsForDynamicSurveyIntegrationDocument, options);
        }
export type ListAdditionalOptionsForDynamicSurveyIntegrationQueryHookResult = ReturnType<typeof useListAdditionalOptionsForDynamicSurveyIntegrationQuery>;
export type ListAdditionalOptionsForDynamicSurveyIntegrationLazyQueryHookResult = ReturnType<typeof useListAdditionalOptionsForDynamicSurveyIntegrationLazyQuery>;
export type ListAdditionalOptionsForDynamicSurveyIntegrationQueryResult = Apollo.QueryResult<ListAdditionalOptionsForDynamicSurveyIntegrationQuery, ListAdditionalOptionsForDynamicSurveyIntegrationQueryVariables>;
export const AddAdditionalOptionToDynamicSurveyIntegrationDocument = gql`
    mutation addAdditionalOptionToDynamicSurveyIntegration($integrationId: String!, $name: String!, $value: String!) {
  addAdditionalOptionToDynamicSurveyIntegration(
    input: {integrationId: $integrationId, name: $name, value: $value}
  ) {
    id
    name
    value
    createdAt
    updatedAt
  }
}
    `;
export type AddAdditionalOptionToDynamicSurveyIntegrationMutationFn = Apollo.MutationFunction<AddAdditionalOptionToDynamicSurveyIntegrationMutation, AddAdditionalOptionToDynamicSurveyIntegrationMutationVariables>;

/**
 * __useAddAdditionalOptionToDynamicSurveyIntegrationMutation__
 *
 * To run a mutation, you first call `useAddAdditionalOptionToDynamicSurveyIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAdditionalOptionToDynamicSurveyIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAdditionalOptionToDynamicSurveyIntegrationMutation, { data, loading, error }] = useAddAdditionalOptionToDynamicSurveyIntegrationMutation({
 *   variables: {
 *      integrationId: // value for 'integrationId'
 *      name: // value for 'name'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useAddAdditionalOptionToDynamicSurveyIntegrationMutation(baseOptions?: Apollo.MutationHookOptions<AddAdditionalOptionToDynamicSurveyIntegrationMutation, AddAdditionalOptionToDynamicSurveyIntegrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddAdditionalOptionToDynamicSurveyIntegrationMutation, AddAdditionalOptionToDynamicSurveyIntegrationMutationVariables>(AddAdditionalOptionToDynamicSurveyIntegrationDocument, options);
      }
export type AddAdditionalOptionToDynamicSurveyIntegrationMutationHookResult = ReturnType<typeof useAddAdditionalOptionToDynamicSurveyIntegrationMutation>;
export type AddAdditionalOptionToDynamicSurveyIntegrationMutationResult = Apollo.MutationResult<AddAdditionalOptionToDynamicSurveyIntegrationMutation>;
export type AddAdditionalOptionToDynamicSurveyIntegrationMutationOptions = Apollo.BaseMutationOptions<AddAdditionalOptionToDynamicSurveyIntegrationMutation, AddAdditionalOptionToDynamicSurveyIntegrationMutationVariables>;
export const UpdateAdditionalOptionForDynamicSurveyIntegrationDocument = gql`
    mutation updateAdditionalOptionForDynamicSurveyIntegration($integrationId: String!, $additionalOptionId: String!, $name: String!, $value: String!) {
  updateAdditionalOptionForDynamicSurveyIntegration(
    input: {integrationId: $integrationId, additionalOptionId: $additionalOptionId, name: $name, value: $value}
  ) {
    id
    name
    value
    createdAt
    updatedAt
  }
}
    `;
export type UpdateAdditionalOptionForDynamicSurveyIntegrationMutationFn = Apollo.MutationFunction<UpdateAdditionalOptionForDynamicSurveyIntegrationMutation, UpdateAdditionalOptionForDynamicSurveyIntegrationMutationVariables>;

/**
 * __useUpdateAdditionalOptionForDynamicSurveyIntegrationMutation__
 *
 * To run a mutation, you first call `useUpdateAdditionalOptionForDynamicSurveyIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdditionalOptionForDynamicSurveyIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdditionalOptionForDynamicSurveyIntegrationMutation, { data, loading, error }] = useUpdateAdditionalOptionForDynamicSurveyIntegrationMutation({
 *   variables: {
 *      integrationId: // value for 'integrationId'
 *      additionalOptionId: // value for 'additionalOptionId'
 *      name: // value for 'name'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useUpdateAdditionalOptionForDynamicSurveyIntegrationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAdditionalOptionForDynamicSurveyIntegrationMutation, UpdateAdditionalOptionForDynamicSurveyIntegrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAdditionalOptionForDynamicSurveyIntegrationMutation, UpdateAdditionalOptionForDynamicSurveyIntegrationMutationVariables>(UpdateAdditionalOptionForDynamicSurveyIntegrationDocument, options);
      }
export type UpdateAdditionalOptionForDynamicSurveyIntegrationMutationHookResult = ReturnType<typeof useUpdateAdditionalOptionForDynamicSurveyIntegrationMutation>;
export type UpdateAdditionalOptionForDynamicSurveyIntegrationMutationResult = Apollo.MutationResult<UpdateAdditionalOptionForDynamicSurveyIntegrationMutation>;
export type UpdateAdditionalOptionForDynamicSurveyIntegrationMutationOptions = Apollo.BaseMutationOptions<UpdateAdditionalOptionForDynamicSurveyIntegrationMutation, UpdateAdditionalOptionForDynamicSurveyIntegrationMutationVariables>;
export const RemoveAdditionalOptionFromDynamicSurveyIntegrationDocument = gql`
    mutation removeAdditionalOptionFromDynamicSurveyIntegration($integrationId: String!, $additionalOptionId: String!) {
  removeAdditionalOptionFromDynamicSurveyIntegration(
    input: {integrationId: $integrationId, additionalOptionId: $additionalOptionId}
  )
}
    `;
export type RemoveAdditionalOptionFromDynamicSurveyIntegrationMutationFn = Apollo.MutationFunction<RemoveAdditionalOptionFromDynamicSurveyIntegrationMutation, RemoveAdditionalOptionFromDynamicSurveyIntegrationMutationVariables>;

/**
 * __useRemoveAdditionalOptionFromDynamicSurveyIntegrationMutation__
 *
 * To run a mutation, you first call `useRemoveAdditionalOptionFromDynamicSurveyIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAdditionalOptionFromDynamicSurveyIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAdditionalOptionFromDynamicSurveyIntegrationMutation, { data, loading, error }] = useRemoveAdditionalOptionFromDynamicSurveyIntegrationMutation({
 *   variables: {
 *      integrationId: // value for 'integrationId'
 *      additionalOptionId: // value for 'additionalOptionId'
 *   },
 * });
 */
export function useRemoveAdditionalOptionFromDynamicSurveyIntegrationMutation(baseOptions?: Apollo.MutationHookOptions<RemoveAdditionalOptionFromDynamicSurveyIntegrationMutation, RemoveAdditionalOptionFromDynamicSurveyIntegrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveAdditionalOptionFromDynamicSurveyIntegrationMutation, RemoveAdditionalOptionFromDynamicSurveyIntegrationMutationVariables>(RemoveAdditionalOptionFromDynamicSurveyIntegrationDocument, options);
      }
export type RemoveAdditionalOptionFromDynamicSurveyIntegrationMutationHookResult = ReturnType<typeof useRemoveAdditionalOptionFromDynamicSurveyIntegrationMutation>;
export type RemoveAdditionalOptionFromDynamicSurveyIntegrationMutationResult = Apollo.MutationResult<RemoveAdditionalOptionFromDynamicSurveyIntegrationMutation>;
export type RemoveAdditionalOptionFromDynamicSurveyIntegrationMutationOptions = Apollo.BaseMutationOptions<RemoveAdditionalOptionFromDynamicSurveyIntegrationMutation, RemoveAdditionalOptionFromDynamicSurveyIntegrationMutationVariables>;
export const CreateSeasonDynamicSurveyDocument = gql`
    mutation createSeasonDynamicSurvey($orgId: String!, $integrationId: String!, $seasonId: String!, $name: String!, $description: String!, $audience: SurveyAudiences!, $sendDelayMinutes: Int!, $reminderType: SurveyReminderTypes!, $dailyReminders: SurveyDailyRemindersInput, $levelReminders: SurveyLevelRemindersInput, $recipientAssessmentObjectId: String) {
  createSeasonDynamicSurvey(
    input: {orgId: $orgId, integrationId: $integrationId, seasonId: $seasonId, name: $name, description: $description, audience: $audience, sendDelayMinutes: $sendDelayMinutes, reminderType: $reminderType, dailyReminders: $dailyReminders, levelReminders: $levelReminders, recipientAssessmentObjectId: $recipientAssessmentObjectId}
  ) {
    id
    sport
    status
    activatedAt
    createdAt
    updatedAt
    orgId
    seasonId
    hasSignatureSection
    signatureText
    hasCompetitionVerification
    hasIncidentReport
    hasSupplementalReport
    additionsConfig {
      allowAdditionalSupplementalReports
      allowAdditionalSupplementalReportsCutoffDays
      allowAdditionalIncidentReports
      allowAdditionalIncidentReportsCutoffDays
    }
    recipients {
      id
      dynamicSurveyId
      surveyId
      assessmentObject {
        id
        sport
        type
        classification
        name
      }
    }
    keyIncidentRecipients {
      id
      orgMembershipId
      orgId
      orgName
      orgAbbreviation
      orgLogo
      title
      userId
      email
      firstName
      lastName
      profilePic
    }
    supplementalReportRecipients {
      id
      orgMembershipId
      orgId
      orgName
      orgAbbreviation
      orgLogo
      title
      userId
      email
      firstName
      lastName
      profilePic
    }
    info {
      name
      description
      audience
      sendDelayMinutes
    }
    reminders {
      type
      dailyReminders {
        sendReminders
        sendReminderDays
        sendReminderCutoffDays
      }
      levelReminders {
        sendRemindersLevel1
        sendReminderDaysLevel1
        sendRemindersLevel2
        sendReminderDaysLevel2
        sendRemindersLevel3
        sendReminderDaysLevel3
      }
    }
    integration {
      id
      orgId
      orgName
      orgAbbreviation
      name
      description
      status
      queueName
      createdAt
      updatedAt
    }
    sections {
      id
      name
      description
      order
      isDynamic
      requireSupplementalReport
      supplementalReportType
      allowAdditions
      questions {
        id
        name
        description
        order
        isRequired
        allowCorrections
        canAddToKeyIncidents
        questionType
        options {
          id
          value
          order
        }
        assessmentObject {
          id
          sport
          type
          classification
          name
        }
        subQuestions {
          id
          name
          questionText
          order
          isRequired
          canAddToKeyIncidents
          questionType
          options {
            id
            value
            order
          }
        }
      }
      createdAt
      updatedAt
    }
  }
}
    `;
export type CreateSeasonDynamicSurveyMutationFn = Apollo.MutationFunction<CreateSeasonDynamicSurveyMutation, CreateSeasonDynamicSurveyMutationVariables>;

/**
 * __useCreateSeasonDynamicSurveyMutation__
 *
 * To run a mutation, you first call `useCreateSeasonDynamicSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSeasonDynamicSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSeasonDynamicSurveyMutation, { data, loading, error }] = useCreateSeasonDynamicSurveyMutation({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      integrationId: // value for 'integrationId'
 *      seasonId: // value for 'seasonId'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      audience: // value for 'audience'
 *      sendDelayMinutes: // value for 'sendDelayMinutes'
 *      reminderType: // value for 'reminderType'
 *      dailyReminders: // value for 'dailyReminders'
 *      levelReminders: // value for 'levelReminders'
 *      recipientAssessmentObjectId: // value for 'recipientAssessmentObjectId'
 *   },
 * });
 */
export function useCreateSeasonDynamicSurveyMutation(baseOptions?: Apollo.MutationHookOptions<CreateSeasonDynamicSurveyMutation, CreateSeasonDynamicSurveyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSeasonDynamicSurveyMutation, CreateSeasonDynamicSurveyMutationVariables>(CreateSeasonDynamicSurveyDocument, options);
      }
export type CreateSeasonDynamicSurveyMutationHookResult = ReturnType<typeof useCreateSeasonDynamicSurveyMutation>;
export type CreateSeasonDynamicSurveyMutationResult = Apollo.MutationResult<CreateSeasonDynamicSurveyMutation>;
export type CreateSeasonDynamicSurveyMutationOptions = Apollo.BaseMutationOptions<CreateSeasonDynamicSurveyMutation, CreateSeasonDynamicSurveyMutationVariables>;
export const GetDynamicSurveyByIdDocument = gql`
    query getDynamicSurveyById($id: String!) {
  getDynamicSurveyById(input: {id: $id}) {
    id
    sport
    status
    activatedAt
    createdAt
    updatedAt
    orgId
    seasonId
    hasSignatureSection
    signatureText
    hasCompetitionVerification
    hasIncidentReport
    hasSupplementalReport
    additionsConfig {
      allowAdditionalSupplementalReports
      allowAdditionalSupplementalReportsCutoffDays
      allowAdditionalIncidentReports
      allowAdditionalIncidentReportsCutoffDays
    }
    recipients {
      id
      dynamicSurveyId
      surveyId
      assessmentObject {
        id
        sport
        type
        classification
        name
      }
    }
    keyIncidentRecipients {
      id
      orgMembershipId
      orgId
      orgName
      orgAbbreviation
      orgLogo
      title
      userId
      email
      firstName
      lastName
      profilePic
    }
    supplementalReportRecipients {
      id
      orgMembershipId
      orgId
      orgName
      orgAbbreviation
      orgLogo
      title
      userId
      email
      firstName
      lastName
      profilePic
    }
    info {
      name
      description
      audience
      sendDelayMinutes
    }
    reminders {
      type
      dailyReminders {
        sendReminders
        sendReminderDays
        sendReminderCutoffDays
      }
      levelReminders {
        sendRemindersLevel1
        sendReminderDaysLevel1
        sendRemindersLevel2
        sendReminderDaysLevel2
        sendRemindersLevel3
        sendReminderDaysLevel3
      }
    }
    integration {
      id
      orgId
      orgName
      orgAbbreviation
      name
      description
      status
      queueName
      createdAt
      updatedAt
    }
    sections {
      id
      name
      description
      order
      isDynamic
      requireSupplementalReport
      supplementalReportType
      allowAdditions
      questions {
        id
        name
        description
        order
        isRequired
        allowCorrections
        canAddToKeyIncidents
        questionType
        options {
          id
          value
          order
        }
        assessmentObject {
          id
          sport
          type
          classification
          name
        }
        subQuestions {
          id
          name
          questionText
          order
          isRequired
          canAddToKeyIncidents
          questionType
          options {
            id
            value
            order
          }
        }
      }
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useGetDynamicSurveyByIdQuery__
 *
 * To run a query within a React component, call `useGetDynamicSurveyByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDynamicSurveyByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDynamicSurveyByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDynamicSurveyByIdQuery(baseOptions: Apollo.QueryHookOptions<GetDynamicSurveyByIdQuery, GetDynamicSurveyByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDynamicSurveyByIdQuery, GetDynamicSurveyByIdQueryVariables>(GetDynamicSurveyByIdDocument, options);
      }
export function useGetDynamicSurveyByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDynamicSurveyByIdQuery, GetDynamicSurveyByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDynamicSurveyByIdQuery, GetDynamicSurveyByIdQueryVariables>(GetDynamicSurveyByIdDocument, options);
        }
export type GetDynamicSurveyByIdQueryHookResult = ReturnType<typeof useGetDynamicSurveyByIdQuery>;
export type GetDynamicSurveyByIdLazyQueryHookResult = ReturnType<typeof useGetDynamicSurveyByIdLazyQuery>;
export type GetDynamicSurveyByIdQueryResult = Apollo.QueryResult<GetDynamicSurveyByIdQuery, GetDynamicSurveyByIdQueryVariables>;
export const ListDynamicCompetitionSurveySummariesForOrganizationDocument = gql`
    query listDynamicCompetitionSurveySummariesForOrganization($offset: Int!, $limit: Int!, $orgId: String!, $leagueId: String!, $seasonId: String!, $surveyId: String!) {
  listDynamicCompetitionSurveySummariesForOrganization(
    input: {orgId: $orgId, leagueId: $leagueId, seasonId: $seasonId, surveyId: $surveyId, pagination: {offset: $offset, limit: $limit}}
  ) {
    competitionSurveys {
      id
      surveyName
      completedDate
      createdAt
      teamId
      teamName
      teamLogo
      officialId
      officialType
      competitionId
      startTime
      externalId
      homeTeamScore
      homeTeamTieBreakerScore
      awayTeamScore
      awayTeamTieBreakerScore
      homeTeamId
      homeTeamName
      homeTeamAbbreviation
      homeTeamLogo
      awayTeamId
      awayTeamName
      awayTeamAbbreviation
      awayTeamLogo
      tookSurvey
      tookSurveyUserId
      keyIncidentsCount
      supplementalReportsCount
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListDynamicCompetitionSurveySummariesForOrganizationQuery__
 *
 * To run a query within a React component, call `useListDynamicCompetitionSurveySummariesForOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useListDynamicCompetitionSurveySummariesForOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListDynamicCompetitionSurveySummariesForOrganizationQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      orgId: // value for 'orgId'
 *      leagueId: // value for 'leagueId'
 *      seasonId: // value for 'seasonId'
 *      surveyId: // value for 'surveyId'
 *   },
 * });
 */
export function useListDynamicCompetitionSurveySummariesForOrganizationQuery(baseOptions: Apollo.QueryHookOptions<ListDynamicCompetitionSurveySummariesForOrganizationQuery, ListDynamicCompetitionSurveySummariesForOrganizationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListDynamicCompetitionSurveySummariesForOrganizationQuery, ListDynamicCompetitionSurveySummariesForOrganizationQueryVariables>(ListDynamicCompetitionSurveySummariesForOrganizationDocument, options);
      }
export function useListDynamicCompetitionSurveySummariesForOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListDynamicCompetitionSurveySummariesForOrganizationQuery, ListDynamicCompetitionSurveySummariesForOrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListDynamicCompetitionSurveySummariesForOrganizationQuery, ListDynamicCompetitionSurveySummariesForOrganizationQueryVariables>(ListDynamicCompetitionSurveySummariesForOrganizationDocument, options);
        }
export type ListDynamicCompetitionSurveySummariesForOrganizationQueryHookResult = ReturnType<typeof useListDynamicCompetitionSurveySummariesForOrganizationQuery>;
export type ListDynamicCompetitionSurveySummariesForOrganizationLazyQueryHookResult = ReturnType<typeof useListDynamicCompetitionSurveySummariesForOrganizationLazyQuery>;
export type ListDynamicCompetitionSurveySummariesForOrganizationQueryResult = Apollo.QueryResult<ListDynamicCompetitionSurveySummariesForOrganizationQuery, ListDynamicCompetitionSurveySummariesForOrganizationQueryVariables>;
export const DeleteDynamicSurveyDocument = gql`
    mutation deleteDynamicSurvey($id: String!) {
  deleteDynamicSurvey(input: {id: $id})
}
    `;
export type DeleteDynamicSurveyMutationFn = Apollo.MutationFunction<DeleteDynamicSurveyMutation, DeleteDynamicSurveyMutationVariables>;

/**
 * __useDeleteDynamicSurveyMutation__
 *
 * To run a mutation, you first call `useDeleteDynamicSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDynamicSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDynamicSurveyMutation, { data, loading, error }] = useDeleteDynamicSurveyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDynamicSurveyMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDynamicSurveyMutation, DeleteDynamicSurveyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDynamicSurveyMutation, DeleteDynamicSurveyMutationVariables>(DeleteDynamicSurveyDocument, options);
      }
export type DeleteDynamicSurveyMutationHookResult = ReturnType<typeof useDeleteDynamicSurveyMutation>;
export type DeleteDynamicSurveyMutationResult = Apollo.MutationResult<DeleteDynamicSurveyMutation>;
export type DeleteDynamicSurveyMutationOptions = Apollo.BaseMutationOptions<DeleteDynamicSurveyMutation, DeleteDynamicSurveyMutationVariables>;
export const ListDynamicSurveysForOrganizationDocument = gql`
    query listDynamicSurveysForOrganization($offset: Int!, $limit: Int!, $orgId: String!, $integrationId: String, $seasonId: String, $name: String, $status: SurveyStatuses, $audience: SurveyAudiences) {
  listDynamicSurveysForOrganization(
    input: {orgId: $orgId, integrationId: $integrationId, seasonId: $seasonId, status: $status, name: $name, audience: $audience, pagination: {offset: $offset, limit: $limit}}
  ) {
    surveys {
      id
      sport
      status
      activatedAt
      createdAt
      updatedAt
      orgId
      seasonId
      hasSignatureSection
      signatureText
      hasCompetitionVerification
      hasIncidentReport
      hasSupplementalReport
      additionsConfig {
        allowAdditionalSupplementalReports
        allowAdditionalSupplementalReportsCutoffDays
        allowAdditionalIncidentReports
        allowAdditionalIncidentReportsCutoffDays
      }
      recipients {
        id
        dynamicSurveyId
        surveyId
        assessmentObject {
          id
          sport
          type
          classification
          name
        }
      }
      keyIncidentRecipients {
        id
        orgMembershipId
        orgId
        orgName
        orgAbbreviation
        orgLogo
        title
        userId
        email
        firstName
        lastName
        profilePic
      }
      supplementalReportRecipients {
        id
        orgMembershipId
        orgId
        orgName
        orgAbbreviation
        orgLogo
        title
        userId
        email
        firstName
        lastName
        profilePic
      }
      info {
        name
        description
        audience
        sendDelayMinutes
      }
      reminders {
        type
        dailyReminders {
          sendReminders
          sendReminderDays
          sendReminderCutoffDays
        }
        levelReminders {
          sendRemindersLevel1
          sendReminderDaysLevel1
          sendRemindersLevel2
          sendReminderDaysLevel2
          sendRemindersLevel3
          sendReminderDaysLevel3
        }
      }
      integration {
        id
        orgId
        orgName
        orgAbbreviation
        name
        description
        status
        queueName
        createdAt
        updatedAt
      }
      sections {
        id
        name
        description
        order
        isDynamic
        requireSupplementalReport
        supplementalReportType
        allowAdditions
        questions {
          id
          name
          description
          order
          isRequired
          allowCorrections
          canAddToKeyIncidents
          questionType
          options {
            id
            value
            order
          }
          assessmentObject {
            id
            sport
            type
            classification
            name
          }
          subQuestions {
            id
            name
            questionText
            order
            isRequired
            canAddToKeyIncidents
            questionType
            options {
              id
              value
              order
            }
          }
        }
        createdAt
        updatedAt
      }
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListDynamicSurveysForOrganizationQuery__
 *
 * To run a query within a React component, call `useListDynamicSurveysForOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useListDynamicSurveysForOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListDynamicSurveysForOrganizationQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      orgId: // value for 'orgId'
 *      integrationId: // value for 'integrationId'
 *      seasonId: // value for 'seasonId'
 *      name: // value for 'name'
 *      status: // value for 'status'
 *      audience: // value for 'audience'
 *   },
 * });
 */
export function useListDynamicSurveysForOrganizationQuery(baseOptions: Apollo.QueryHookOptions<ListDynamicSurveysForOrganizationQuery, ListDynamicSurveysForOrganizationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListDynamicSurveysForOrganizationQuery, ListDynamicSurveysForOrganizationQueryVariables>(ListDynamicSurveysForOrganizationDocument, options);
      }
export function useListDynamicSurveysForOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListDynamicSurveysForOrganizationQuery, ListDynamicSurveysForOrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListDynamicSurveysForOrganizationQuery, ListDynamicSurveysForOrganizationQueryVariables>(ListDynamicSurveysForOrganizationDocument, options);
        }
export type ListDynamicSurveysForOrganizationQueryHookResult = ReturnType<typeof useListDynamicSurveysForOrganizationQuery>;
export type ListDynamicSurveysForOrganizationLazyQueryHookResult = ReturnType<typeof useListDynamicSurveysForOrganizationLazyQuery>;
export type ListDynamicSurveysForOrganizationQueryResult = Apollo.QueryResult<ListDynamicSurveysForOrganizationQuery, ListDynamicSurveysForOrganizationQueryVariables>;
export const UpdateDynamicSurveyInfoDocument = gql`
    mutation updateDynamicSurveyInfo($surveyId: String!, $name: String!, $description: String!, $audience: SurveyAudiences!, $sendDelayMinutes: Int!) {
  updateDynamicSurveyInfo(
    input: {surveyId: $surveyId, name: $name, description: $description, audience: $audience, sendDelayMinutes: $sendDelayMinutes}
  ) {
    name
    description
    audience
    sendDelayMinutes
  }
}
    `;
export type UpdateDynamicSurveyInfoMutationFn = Apollo.MutationFunction<UpdateDynamicSurveyInfoMutation, UpdateDynamicSurveyInfoMutationVariables>;

/**
 * __useUpdateDynamicSurveyInfoMutation__
 *
 * To run a mutation, you first call `useUpdateDynamicSurveyInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDynamicSurveyInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDynamicSurveyInfoMutation, { data, loading, error }] = useUpdateDynamicSurveyInfoMutation({
 *   variables: {
 *      surveyId: // value for 'surveyId'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      audience: // value for 'audience'
 *      sendDelayMinutes: // value for 'sendDelayMinutes'
 *   },
 * });
 */
export function useUpdateDynamicSurveyInfoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDynamicSurveyInfoMutation, UpdateDynamicSurveyInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDynamicSurveyInfoMutation, UpdateDynamicSurveyInfoMutationVariables>(UpdateDynamicSurveyInfoDocument, options);
      }
export type UpdateDynamicSurveyInfoMutationHookResult = ReturnType<typeof useUpdateDynamicSurveyInfoMutation>;
export type UpdateDynamicSurveyInfoMutationResult = Apollo.MutationResult<UpdateDynamicSurveyInfoMutation>;
export type UpdateDynamicSurveyInfoMutationOptions = Apollo.BaseMutationOptions<UpdateDynamicSurveyInfoMutation, UpdateDynamicSurveyInfoMutationVariables>;
export const UpdateDynamicSurveyRemindersDocument = gql`
    mutation updateDynamicSurveyReminders($surveyId: String!, $type: SurveyReminderTypes!, $dailyReminders: SurveyDailyRemindersInput, $levelReminders: SurveyLevelRemindersInput) {
  updateDynamicSurveyReminders(
    input: {surveyId: $surveyId, type: $type, dailyReminders: $dailyReminders, levelReminders: $levelReminders}
  ) {
    type
    dailyReminders {
      sendReminders
      sendReminderDays
      sendReminderCutoffDays
    }
    levelReminders {
      sendRemindersLevel1
      sendReminderDaysLevel1
      sendRemindersLevel2
      sendReminderDaysLevel2
      sendRemindersLevel3
      sendReminderDaysLevel3
    }
  }
}
    `;
export type UpdateDynamicSurveyRemindersMutationFn = Apollo.MutationFunction<UpdateDynamicSurveyRemindersMutation, UpdateDynamicSurveyRemindersMutationVariables>;

/**
 * __useUpdateDynamicSurveyRemindersMutation__
 *
 * To run a mutation, you first call `useUpdateDynamicSurveyRemindersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDynamicSurveyRemindersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDynamicSurveyRemindersMutation, { data, loading, error }] = useUpdateDynamicSurveyRemindersMutation({
 *   variables: {
 *      surveyId: // value for 'surveyId'
 *      type: // value for 'type'
 *      dailyReminders: // value for 'dailyReminders'
 *      levelReminders: // value for 'levelReminders'
 *   },
 * });
 */
export function useUpdateDynamicSurveyRemindersMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDynamicSurveyRemindersMutation, UpdateDynamicSurveyRemindersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDynamicSurveyRemindersMutation, UpdateDynamicSurveyRemindersMutationVariables>(UpdateDynamicSurveyRemindersDocument, options);
      }
export type UpdateDynamicSurveyRemindersMutationHookResult = ReturnType<typeof useUpdateDynamicSurveyRemindersMutation>;
export type UpdateDynamicSurveyRemindersMutationResult = Apollo.MutationResult<UpdateDynamicSurveyRemindersMutation>;
export type UpdateDynamicSurveyRemindersMutationOptions = Apollo.BaseMutationOptions<UpdateDynamicSurveyRemindersMutation, UpdateDynamicSurveyRemindersMutationVariables>;
export const ListReminderLevelRecipientsForDynamicSurveyDocument = gql`
    query listReminderLevelRecipientsForDynamicSurvey($offset: Int!, $limit: Int!, $surveyId: String!) {
  listReminderLevelRecipientsForDynamicSurvey(
    input: {surveyId: $surveyId, pagination: {offset: $offset, limit: $limit}}
  ) {
    recipients {
      id
      orgMembershipId
      orgId
      orgName
      orgAbbreviation
      orgLogo
      title
      userId
      email
      firstName
      lastName
      profilePic
      level1Recipient
      level2Recipient
      level3Recipient
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListReminderLevelRecipientsForDynamicSurveyQuery__
 *
 * To run a query within a React component, call `useListReminderLevelRecipientsForDynamicSurveyQuery` and pass it any options that fit your needs.
 * When your component renders, `useListReminderLevelRecipientsForDynamicSurveyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListReminderLevelRecipientsForDynamicSurveyQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      surveyId: // value for 'surveyId'
 *   },
 * });
 */
export function useListReminderLevelRecipientsForDynamicSurveyQuery(baseOptions: Apollo.QueryHookOptions<ListReminderLevelRecipientsForDynamicSurveyQuery, ListReminderLevelRecipientsForDynamicSurveyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListReminderLevelRecipientsForDynamicSurveyQuery, ListReminderLevelRecipientsForDynamicSurveyQueryVariables>(ListReminderLevelRecipientsForDynamicSurveyDocument, options);
      }
export function useListReminderLevelRecipientsForDynamicSurveyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListReminderLevelRecipientsForDynamicSurveyQuery, ListReminderLevelRecipientsForDynamicSurveyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListReminderLevelRecipientsForDynamicSurveyQuery, ListReminderLevelRecipientsForDynamicSurveyQueryVariables>(ListReminderLevelRecipientsForDynamicSurveyDocument, options);
        }
export type ListReminderLevelRecipientsForDynamicSurveyQueryHookResult = ReturnType<typeof useListReminderLevelRecipientsForDynamicSurveyQuery>;
export type ListReminderLevelRecipientsForDynamicSurveyLazyQueryHookResult = ReturnType<typeof useListReminderLevelRecipientsForDynamicSurveyLazyQuery>;
export type ListReminderLevelRecipientsForDynamicSurveyQueryResult = Apollo.QueryResult<ListReminderLevelRecipientsForDynamicSurveyQuery, ListReminderLevelRecipientsForDynamicSurveyQueryVariables>;
export const AddDynamicSurveyReminderLevelReportRecipientDocument = gql`
    mutation addDynamicSurveyReminderLevelReportRecipient($surveyId: String!, $orgMembershipId: String!, $level1Recipient: Boolean!, $level2Recipient: Boolean!, $level3Recipient: Boolean!) {
  addDynamicSurveyReminderLevelReportRecipient(
    input: {surveyId: $surveyId, orgMembershipId: $orgMembershipId, level1Recipient: $level1Recipient, level2Recipient: $level2Recipient, level3Recipient: $level3Recipient}
  ) {
    id
    orgMembershipId
    orgId
    orgName
    orgAbbreviation
    orgLogo
    title
    userId
    email
    firstName
    lastName
    profilePic
    level1Recipient
    level2Recipient
    level3Recipient
  }
}
    `;
export type AddDynamicSurveyReminderLevelReportRecipientMutationFn = Apollo.MutationFunction<AddDynamicSurveyReminderLevelReportRecipientMutation, AddDynamicSurveyReminderLevelReportRecipientMutationVariables>;

/**
 * __useAddDynamicSurveyReminderLevelReportRecipientMutation__
 *
 * To run a mutation, you first call `useAddDynamicSurveyReminderLevelReportRecipientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDynamicSurveyReminderLevelReportRecipientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDynamicSurveyReminderLevelReportRecipientMutation, { data, loading, error }] = useAddDynamicSurveyReminderLevelReportRecipientMutation({
 *   variables: {
 *      surveyId: // value for 'surveyId'
 *      orgMembershipId: // value for 'orgMembershipId'
 *      level1Recipient: // value for 'level1Recipient'
 *      level2Recipient: // value for 'level2Recipient'
 *      level3Recipient: // value for 'level3Recipient'
 *   },
 * });
 */
export function useAddDynamicSurveyReminderLevelReportRecipientMutation(baseOptions?: Apollo.MutationHookOptions<AddDynamicSurveyReminderLevelReportRecipientMutation, AddDynamicSurveyReminderLevelReportRecipientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddDynamicSurveyReminderLevelReportRecipientMutation, AddDynamicSurveyReminderLevelReportRecipientMutationVariables>(AddDynamicSurveyReminderLevelReportRecipientDocument, options);
      }
export type AddDynamicSurveyReminderLevelReportRecipientMutationHookResult = ReturnType<typeof useAddDynamicSurveyReminderLevelReportRecipientMutation>;
export type AddDynamicSurveyReminderLevelReportRecipientMutationResult = Apollo.MutationResult<AddDynamicSurveyReminderLevelReportRecipientMutation>;
export type AddDynamicSurveyReminderLevelReportRecipientMutationOptions = Apollo.BaseMutationOptions<AddDynamicSurveyReminderLevelReportRecipientMutation, AddDynamicSurveyReminderLevelReportRecipientMutationVariables>;
export const UpdateDynamicSurveyReminderLevelRecipientDocument = gql`
    mutation updateDynamicSurveyReminderLevelRecipient($recipientId: String!, $level1Recipient: Boolean!, $level2Recipient: Boolean!, $level3Recipient: Boolean!) {
  updateDynamicSurveyReminderLevelRecipient(
    input: {recipientId: $recipientId, level1Recipient: $level1Recipient, level2Recipient: $level2Recipient, level3Recipient: $level3Recipient}
  ) {
    id
    orgMembershipId
    orgId
    orgName
    orgAbbreviation
    orgLogo
    title
    userId
    email
    firstName
    lastName
    profilePic
    level1Recipient
    level2Recipient
    level3Recipient
  }
}
    `;
export type UpdateDynamicSurveyReminderLevelRecipientMutationFn = Apollo.MutationFunction<UpdateDynamicSurveyReminderLevelRecipientMutation, UpdateDynamicSurveyReminderLevelRecipientMutationVariables>;

/**
 * __useUpdateDynamicSurveyReminderLevelRecipientMutation__
 *
 * To run a mutation, you first call `useUpdateDynamicSurveyReminderLevelRecipientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDynamicSurveyReminderLevelRecipientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDynamicSurveyReminderLevelRecipientMutation, { data, loading, error }] = useUpdateDynamicSurveyReminderLevelRecipientMutation({
 *   variables: {
 *      recipientId: // value for 'recipientId'
 *      level1Recipient: // value for 'level1Recipient'
 *      level2Recipient: // value for 'level2Recipient'
 *      level3Recipient: // value for 'level3Recipient'
 *   },
 * });
 */
export function useUpdateDynamicSurveyReminderLevelRecipientMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDynamicSurveyReminderLevelRecipientMutation, UpdateDynamicSurveyReminderLevelRecipientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDynamicSurveyReminderLevelRecipientMutation, UpdateDynamicSurveyReminderLevelRecipientMutationVariables>(UpdateDynamicSurveyReminderLevelRecipientDocument, options);
      }
export type UpdateDynamicSurveyReminderLevelRecipientMutationHookResult = ReturnType<typeof useUpdateDynamicSurveyReminderLevelRecipientMutation>;
export type UpdateDynamicSurveyReminderLevelRecipientMutationResult = Apollo.MutationResult<UpdateDynamicSurveyReminderLevelRecipientMutation>;
export type UpdateDynamicSurveyReminderLevelRecipientMutationOptions = Apollo.BaseMutationOptions<UpdateDynamicSurveyReminderLevelRecipientMutation, UpdateDynamicSurveyReminderLevelRecipientMutationVariables>;
export const DeleteDynamicSurveyReminderLevelRecipientDocument = gql`
    mutation deleteDynamicSurveyReminderLevelRecipient($recipientId: String!) {
  deleteDynamicSurveyReminderLevelRecipient(input: {recipientId: $recipientId})
}
    `;
export type DeleteDynamicSurveyReminderLevelRecipientMutationFn = Apollo.MutationFunction<DeleteDynamicSurveyReminderLevelRecipientMutation, DeleteDynamicSurveyReminderLevelRecipientMutationVariables>;

/**
 * __useDeleteDynamicSurveyReminderLevelRecipientMutation__
 *
 * To run a mutation, you first call `useDeleteDynamicSurveyReminderLevelRecipientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDynamicSurveyReminderLevelRecipientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDynamicSurveyReminderLevelRecipientMutation, { data, loading, error }] = useDeleteDynamicSurveyReminderLevelRecipientMutation({
 *   variables: {
 *      recipientId: // value for 'recipientId'
 *   },
 * });
 */
export function useDeleteDynamicSurveyReminderLevelRecipientMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDynamicSurveyReminderLevelRecipientMutation, DeleteDynamicSurveyReminderLevelRecipientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDynamicSurveyReminderLevelRecipientMutation, DeleteDynamicSurveyReminderLevelRecipientMutationVariables>(DeleteDynamicSurveyReminderLevelRecipientDocument, options);
      }
export type DeleteDynamicSurveyReminderLevelRecipientMutationHookResult = ReturnType<typeof useDeleteDynamicSurveyReminderLevelRecipientMutation>;
export type DeleteDynamicSurveyReminderLevelRecipientMutationResult = Apollo.MutationResult<DeleteDynamicSurveyReminderLevelRecipientMutation>;
export type DeleteDynamicSurveyReminderLevelRecipientMutationOptions = Apollo.BaseMutationOptions<DeleteDynamicSurveyReminderLevelRecipientMutation, DeleteDynamicSurveyReminderLevelRecipientMutationVariables>;
export const AddDynamicSurveySignatureSectionDocument = gql`
    mutation addDynamicSurveySignatureSection($surveyId: String!, $signatureText: String!) {
  addDynamicSurveySignatureSection(
    input: {surveyId: $surveyId, signatureText: $signatureText}
  )
}
    `;
export type AddDynamicSurveySignatureSectionMutationFn = Apollo.MutationFunction<AddDynamicSurveySignatureSectionMutation, AddDynamicSurveySignatureSectionMutationVariables>;

/**
 * __useAddDynamicSurveySignatureSectionMutation__
 *
 * To run a mutation, you first call `useAddDynamicSurveySignatureSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDynamicSurveySignatureSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDynamicSurveySignatureSectionMutation, { data, loading, error }] = useAddDynamicSurveySignatureSectionMutation({
 *   variables: {
 *      surveyId: // value for 'surveyId'
 *      signatureText: // value for 'signatureText'
 *   },
 * });
 */
export function useAddDynamicSurveySignatureSectionMutation(baseOptions?: Apollo.MutationHookOptions<AddDynamicSurveySignatureSectionMutation, AddDynamicSurveySignatureSectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddDynamicSurveySignatureSectionMutation, AddDynamicSurveySignatureSectionMutationVariables>(AddDynamicSurveySignatureSectionDocument, options);
      }
export type AddDynamicSurveySignatureSectionMutationHookResult = ReturnType<typeof useAddDynamicSurveySignatureSectionMutation>;
export type AddDynamicSurveySignatureSectionMutationResult = Apollo.MutationResult<AddDynamicSurveySignatureSectionMutation>;
export type AddDynamicSurveySignatureSectionMutationOptions = Apollo.BaseMutationOptions<AddDynamicSurveySignatureSectionMutation, AddDynamicSurveySignatureSectionMutationVariables>;
export const UpdateDynamicSurveySignatureSectionTextDocument = gql`
    mutation updateDynamicSurveySignatureSectionText($surveyId: String!, $signatureText: String!) {
  updateDynamicSurveySignatureSectionText(
    input: {surveyId: $surveyId, signatureText: $signatureText}
  )
}
    `;
export type UpdateDynamicSurveySignatureSectionTextMutationFn = Apollo.MutationFunction<UpdateDynamicSurveySignatureSectionTextMutation, UpdateDynamicSurveySignatureSectionTextMutationVariables>;

/**
 * __useUpdateDynamicSurveySignatureSectionTextMutation__
 *
 * To run a mutation, you first call `useUpdateDynamicSurveySignatureSectionTextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDynamicSurveySignatureSectionTextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDynamicSurveySignatureSectionTextMutation, { data, loading, error }] = useUpdateDynamicSurveySignatureSectionTextMutation({
 *   variables: {
 *      surveyId: // value for 'surveyId'
 *      signatureText: // value for 'signatureText'
 *   },
 * });
 */
export function useUpdateDynamicSurveySignatureSectionTextMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDynamicSurveySignatureSectionTextMutation, UpdateDynamicSurveySignatureSectionTextMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDynamicSurveySignatureSectionTextMutation, UpdateDynamicSurveySignatureSectionTextMutationVariables>(UpdateDynamicSurveySignatureSectionTextDocument, options);
      }
export type UpdateDynamicSurveySignatureSectionTextMutationHookResult = ReturnType<typeof useUpdateDynamicSurveySignatureSectionTextMutation>;
export type UpdateDynamicSurveySignatureSectionTextMutationResult = Apollo.MutationResult<UpdateDynamicSurveySignatureSectionTextMutation>;
export type UpdateDynamicSurveySignatureSectionTextMutationOptions = Apollo.BaseMutationOptions<UpdateDynamicSurveySignatureSectionTextMutation, UpdateDynamicSurveySignatureSectionTextMutationVariables>;
export const DeleteDynamicSurveySignatureSectionDocument = gql`
    mutation deleteDynamicSurveySignatureSection($surveyId: String!) {
  deleteDynamicSurveySignatureSection(input: {surveyId: $surveyId})
}
    `;
export type DeleteDynamicSurveySignatureSectionMutationFn = Apollo.MutationFunction<DeleteDynamicSurveySignatureSectionMutation, DeleteDynamicSurveySignatureSectionMutationVariables>;

/**
 * __useDeleteDynamicSurveySignatureSectionMutation__
 *
 * To run a mutation, you first call `useDeleteDynamicSurveySignatureSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDynamicSurveySignatureSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDynamicSurveySignatureSectionMutation, { data, loading, error }] = useDeleteDynamicSurveySignatureSectionMutation({
 *   variables: {
 *      surveyId: // value for 'surveyId'
 *   },
 * });
 */
export function useDeleteDynamicSurveySignatureSectionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDynamicSurveySignatureSectionMutation, DeleteDynamicSurveySignatureSectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDynamicSurveySignatureSectionMutation, DeleteDynamicSurveySignatureSectionMutationVariables>(DeleteDynamicSurveySignatureSectionDocument, options);
      }
export type DeleteDynamicSurveySignatureSectionMutationHookResult = ReturnType<typeof useDeleteDynamicSurveySignatureSectionMutation>;
export type DeleteDynamicSurveySignatureSectionMutationResult = Apollo.MutationResult<DeleteDynamicSurveySignatureSectionMutation>;
export type DeleteDynamicSurveySignatureSectionMutationOptions = Apollo.BaseMutationOptions<DeleteDynamicSurveySignatureSectionMutation, DeleteDynamicSurveySignatureSectionMutationVariables>;
export const AddDynamicSurveyAssessmentObjectRecipientDocument = gql`
    mutation addDynamicSurveyAssessmentObjectRecipient($surveyId: String!, $assessmentObjectId: String!) {
  addDynamicSurveyAssessmentObjectRecipient(
    input: {surveyId: $surveyId, assessmentObjectId: $assessmentObjectId}
  ) {
    id
    dynamicSurveyId
    surveyId
    assessmentObject {
      id
      sport
      type
      classification
      name
    }
  }
}
    `;
export type AddDynamicSurveyAssessmentObjectRecipientMutationFn = Apollo.MutationFunction<AddDynamicSurveyAssessmentObjectRecipientMutation, AddDynamicSurveyAssessmentObjectRecipientMutationVariables>;

/**
 * __useAddDynamicSurveyAssessmentObjectRecipientMutation__
 *
 * To run a mutation, you first call `useAddDynamicSurveyAssessmentObjectRecipientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDynamicSurveyAssessmentObjectRecipientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDynamicSurveyAssessmentObjectRecipientMutation, { data, loading, error }] = useAddDynamicSurveyAssessmentObjectRecipientMutation({
 *   variables: {
 *      surveyId: // value for 'surveyId'
 *      assessmentObjectId: // value for 'assessmentObjectId'
 *   },
 * });
 */
export function useAddDynamicSurveyAssessmentObjectRecipientMutation(baseOptions?: Apollo.MutationHookOptions<AddDynamicSurveyAssessmentObjectRecipientMutation, AddDynamicSurveyAssessmentObjectRecipientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddDynamicSurveyAssessmentObjectRecipientMutation, AddDynamicSurveyAssessmentObjectRecipientMutationVariables>(AddDynamicSurveyAssessmentObjectRecipientDocument, options);
      }
export type AddDynamicSurveyAssessmentObjectRecipientMutationHookResult = ReturnType<typeof useAddDynamicSurveyAssessmentObjectRecipientMutation>;
export type AddDynamicSurveyAssessmentObjectRecipientMutationResult = Apollo.MutationResult<AddDynamicSurveyAssessmentObjectRecipientMutation>;
export type AddDynamicSurveyAssessmentObjectRecipientMutationOptions = Apollo.BaseMutationOptions<AddDynamicSurveyAssessmentObjectRecipientMutation, AddDynamicSurveyAssessmentObjectRecipientMutationVariables>;
export const DeleteDynamicSurveyAssessmentObjectRecipientDocument = gql`
    mutation deleteDynamicSurveyAssessmentObjectRecipient($recipientId: String!) {
  deleteDynamicSurveyAssessmentObjectRecipient(input: {recipientId: $recipientId})
}
    `;
export type DeleteDynamicSurveyAssessmentObjectRecipientMutationFn = Apollo.MutationFunction<DeleteDynamicSurveyAssessmentObjectRecipientMutation, DeleteDynamicSurveyAssessmentObjectRecipientMutationVariables>;

/**
 * __useDeleteDynamicSurveyAssessmentObjectRecipientMutation__
 *
 * To run a mutation, you first call `useDeleteDynamicSurveyAssessmentObjectRecipientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDynamicSurveyAssessmentObjectRecipientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDynamicSurveyAssessmentObjectRecipientMutation, { data, loading, error }] = useDeleteDynamicSurveyAssessmentObjectRecipientMutation({
 *   variables: {
 *      recipientId: // value for 'recipientId'
 *   },
 * });
 */
export function useDeleteDynamicSurveyAssessmentObjectRecipientMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDynamicSurveyAssessmentObjectRecipientMutation, DeleteDynamicSurveyAssessmentObjectRecipientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDynamicSurveyAssessmentObjectRecipientMutation, DeleteDynamicSurveyAssessmentObjectRecipientMutationVariables>(DeleteDynamicSurveyAssessmentObjectRecipientDocument, options);
      }
export type DeleteDynamicSurveyAssessmentObjectRecipientMutationHookResult = ReturnType<typeof useDeleteDynamicSurveyAssessmentObjectRecipientMutation>;
export type DeleteDynamicSurveyAssessmentObjectRecipientMutationResult = Apollo.MutationResult<DeleteDynamicSurveyAssessmentObjectRecipientMutation>;
export type DeleteDynamicSurveyAssessmentObjectRecipientMutationOptions = Apollo.BaseMutationOptions<DeleteDynamicSurveyAssessmentObjectRecipientMutation, DeleteDynamicSurveyAssessmentObjectRecipientMutationVariables>;
export const ListAvailableAssessmentObjectsForDynamicSurveysRecipientsDocument = gql`
    query listAvailableAssessmentObjectsForDynamicSurveysRecipients($offset: Int!, $limit: Int!, $surveyId: String!) {
  listAvailableAssessmentObjectsForDynamicSurveysRecipients(
    input: {surveyId: $surveyId, pagination: {offset: $offset, limit: $limit}}
  ) {
    assessmentObjects {
      id
      sport
      type
      classification
      name
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListAvailableAssessmentObjectsForDynamicSurveysRecipientsQuery__
 *
 * To run a query within a React component, call `useListAvailableAssessmentObjectsForDynamicSurveysRecipientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAvailableAssessmentObjectsForDynamicSurveysRecipientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAvailableAssessmentObjectsForDynamicSurveysRecipientsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      surveyId: // value for 'surveyId'
 *   },
 * });
 */
export function useListAvailableAssessmentObjectsForDynamicSurveysRecipientsQuery(baseOptions: Apollo.QueryHookOptions<ListAvailableAssessmentObjectsForDynamicSurveysRecipientsQuery, ListAvailableAssessmentObjectsForDynamicSurveysRecipientsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAvailableAssessmentObjectsForDynamicSurveysRecipientsQuery, ListAvailableAssessmentObjectsForDynamicSurveysRecipientsQueryVariables>(ListAvailableAssessmentObjectsForDynamicSurveysRecipientsDocument, options);
      }
export function useListAvailableAssessmentObjectsForDynamicSurveysRecipientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAvailableAssessmentObjectsForDynamicSurveysRecipientsQuery, ListAvailableAssessmentObjectsForDynamicSurveysRecipientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAvailableAssessmentObjectsForDynamicSurveysRecipientsQuery, ListAvailableAssessmentObjectsForDynamicSurveysRecipientsQueryVariables>(ListAvailableAssessmentObjectsForDynamicSurveysRecipientsDocument, options);
        }
export type ListAvailableAssessmentObjectsForDynamicSurveysRecipientsQueryHookResult = ReturnType<typeof useListAvailableAssessmentObjectsForDynamicSurveysRecipientsQuery>;
export type ListAvailableAssessmentObjectsForDynamicSurveysRecipientsLazyQueryHookResult = ReturnType<typeof useListAvailableAssessmentObjectsForDynamicSurveysRecipientsLazyQuery>;
export type ListAvailableAssessmentObjectsForDynamicSurveysRecipientsQueryResult = Apollo.QueryResult<ListAvailableAssessmentObjectsForDynamicSurveysRecipientsQuery, ListAvailableAssessmentObjectsForDynamicSurveysRecipientsQueryVariables>;
export const SetDynamicSurveyCompetitionVerificationDocument = gql`
    mutation setDynamicSurveyCompetitionVerification($surveyId: String!, $enabled: Boolean!) {
  setDynamicSurveyCompetitionVerification(
    input: {surveyId: $surveyId, enabled: $enabled}
  ) {
    enabled
  }
}
    `;
export type SetDynamicSurveyCompetitionVerificationMutationFn = Apollo.MutationFunction<SetDynamicSurveyCompetitionVerificationMutation, SetDynamicSurveyCompetitionVerificationMutationVariables>;

/**
 * __useSetDynamicSurveyCompetitionVerificationMutation__
 *
 * To run a mutation, you first call `useSetDynamicSurveyCompetitionVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDynamicSurveyCompetitionVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDynamicSurveyCompetitionVerificationMutation, { data, loading, error }] = useSetDynamicSurveyCompetitionVerificationMutation({
 *   variables: {
 *      surveyId: // value for 'surveyId'
 *      enabled: // value for 'enabled'
 *   },
 * });
 */
export function useSetDynamicSurveyCompetitionVerificationMutation(baseOptions?: Apollo.MutationHookOptions<SetDynamicSurveyCompetitionVerificationMutation, SetDynamicSurveyCompetitionVerificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetDynamicSurveyCompetitionVerificationMutation, SetDynamicSurveyCompetitionVerificationMutationVariables>(SetDynamicSurveyCompetitionVerificationDocument, options);
      }
export type SetDynamicSurveyCompetitionVerificationMutationHookResult = ReturnType<typeof useSetDynamicSurveyCompetitionVerificationMutation>;
export type SetDynamicSurveyCompetitionVerificationMutationResult = Apollo.MutationResult<SetDynamicSurveyCompetitionVerificationMutation>;
export type SetDynamicSurveyCompetitionVerificationMutationOptions = Apollo.BaseMutationOptions<SetDynamicSurveyCompetitionVerificationMutation, SetDynamicSurveyCompetitionVerificationMutationVariables>;
export const AddDynamicSurveyIncidentReportDocument = gql`
    mutation addDynamicSurveyIncidentReport($surveyId: String!, $orgMembershipId: String!) {
  addDynamicSurveyIncidentReport(
    input: {surveyId: $surveyId, orgMembershipId: $orgMembershipId}
  ) {
    id
    orgMembershipId
    orgId
    orgName
    orgAbbreviation
    orgLogo
    title
    userId
    email
    firstName
    lastName
    profilePic
  }
}
    `;
export type AddDynamicSurveyIncidentReportMutationFn = Apollo.MutationFunction<AddDynamicSurveyIncidentReportMutation, AddDynamicSurveyIncidentReportMutationVariables>;

/**
 * __useAddDynamicSurveyIncidentReportMutation__
 *
 * To run a mutation, you first call `useAddDynamicSurveyIncidentReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDynamicSurveyIncidentReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDynamicSurveyIncidentReportMutation, { data, loading, error }] = useAddDynamicSurveyIncidentReportMutation({
 *   variables: {
 *      surveyId: // value for 'surveyId'
 *      orgMembershipId: // value for 'orgMembershipId'
 *   },
 * });
 */
export function useAddDynamicSurveyIncidentReportMutation(baseOptions?: Apollo.MutationHookOptions<AddDynamicSurveyIncidentReportMutation, AddDynamicSurveyIncidentReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddDynamicSurveyIncidentReportMutation, AddDynamicSurveyIncidentReportMutationVariables>(AddDynamicSurveyIncidentReportDocument, options);
      }
export type AddDynamicSurveyIncidentReportMutationHookResult = ReturnType<typeof useAddDynamicSurveyIncidentReportMutation>;
export type AddDynamicSurveyIncidentReportMutationResult = Apollo.MutationResult<AddDynamicSurveyIncidentReportMutation>;
export type AddDynamicSurveyIncidentReportMutationOptions = Apollo.BaseMutationOptions<AddDynamicSurveyIncidentReportMutation, AddDynamicSurveyIncidentReportMutationVariables>;
export const DeleteDynamicSurveyIncidentReportDocument = gql`
    mutation deleteDynamicSurveyIncidentReport($surveyId: String!) {
  deleteDynamicSurveyIncidentReport(input: {surveyId: $surveyId})
}
    `;
export type DeleteDynamicSurveyIncidentReportMutationFn = Apollo.MutationFunction<DeleteDynamicSurveyIncidentReportMutation, DeleteDynamicSurveyIncidentReportMutationVariables>;

/**
 * __useDeleteDynamicSurveyIncidentReportMutation__
 *
 * To run a mutation, you first call `useDeleteDynamicSurveyIncidentReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDynamicSurveyIncidentReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDynamicSurveyIncidentReportMutation, { data, loading, error }] = useDeleteDynamicSurveyIncidentReportMutation({
 *   variables: {
 *      surveyId: // value for 'surveyId'
 *   },
 * });
 */
export function useDeleteDynamicSurveyIncidentReportMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDynamicSurveyIncidentReportMutation, DeleteDynamicSurveyIncidentReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDynamicSurveyIncidentReportMutation, DeleteDynamicSurveyIncidentReportMutationVariables>(DeleteDynamicSurveyIncidentReportDocument, options);
      }
export type DeleteDynamicSurveyIncidentReportMutationHookResult = ReturnType<typeof useDeleteDynamicSurveyIncidentReportMutation>;
export type DeleteDynamicSurveyIncidentReportMutationResult = Apollo.MutationResult<DeleteDynamicSurveyIncidentReportMutation>;
export type DeleteDynamicSurveyIncidentReportMutationOptions = Apollo.BaseMutationOptions<DeleteDynamicSurveyIncidentReportMutation, DeleteDynamicSurveyIncidentReportMutationVariables>;
export const AddDynamicSurveyIncidentReportRecipientDocument = gql`
    mutation addDynamicSurveyIncidentReportRecipient($surveyId: String!, $orgMembershipId: String!) {
  addDynamicSurveyIncidentReportRecipient(
    input: {surveyId: $surveyId, orgMembershipId: $orgMembershipId}
  ) {
    id
    orgMembershipId
    orgId
    orgName
    orgAbbreviation
    orgLogo
    title
    userId
    email
    firstName
    lastName
    profilePic
  }
}
    `;
export type AddDynamicSurveyIncidentReportRecipientMutationFn = Apollo.MutationFunction<AddDynamicSurveyIncidentReportRecipientMutation, AddDynamicSurveyIncidentReportRecipientMutationVariables>;

/**
 * __useAddDynamicSurveyIncidentReportRecipientMutation__
 *
 * To run a mutation, you first call `useAddDynamicSurveyIncidentReportRecipientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDynamicSurveyIncidentReportRecipientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDynamicSurveyIncidentReportRecipientMutation, { data, loading, error }] = useAddDynamicSurveyIncidentReportRecipientMutation({
 *   variables: {
 *      surveyId: // value for 'surveyId'
 *      orgMembershipId: // value for 'orgMembershipId'
 *   },
 * });
 */
export function useAddDynamicSurveyIncidentReportRecipientMutation(baseOptions?: Apollo.MutationHookOptions<AddDynamicSurveyIncidentReportRecipientMutation, AddDynamicSurveyIncidentReportRecipientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddDynamicSurveyIncidentReportRecipientMutation, AddDynamicSurveyIncidentReportRecipientMutationVariables>(AddDynamicSurveyIncidentReportRecipientDocument, options);
      }
export type AddDynamicSurveyIncidentReportRecipientMutationHookResult = ReturnType<typeof useAddDynamicSurveyIncidentReportRecipientMutation>;
export type AddDynamicSurveyIncidentReportRecipientMutationResult = Apollo.MutationResult<AddDynamicSurveyIncidentReportRecipientMutation>;
export type AddDynamicSurveyIncidentReportRecipientMutationOptions = Apollo.BaseMutationOptions<AddDynamicSurveyIncidentReportRecipientMutation, AddDynamicSurveyIncidentReportRecipientMutationVariables>;
export const DeleteDynamicSurveyIncidentReportRecipientDocument = gql`
    mutation deleteDynamicSurveyIncidentReportRecipient($recipientId: String!) {
  deleteDynamicSurveyIncidentReportRecipient(input: {recipientId: $recipientId})
}
    `;
export type DeleteDynamicSurveyIncidentReportRecipientMutationFn = Apollo.MutationFunction<DeleteDynamicSurveyIncidentReportRecipientMutation, DeleteDynamicSurveyIncidentReportRecipientMutationVariables>;

/**
 * __useDeleteDynamicSurveyIncidentReportRecipientMutation__
 *
 * To run a mutation, you first call `useDeleteDynamicSurveyIncidentReportRecipientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDynamicSurveyIncidentReportRecipientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDynamicSurveyIncidentReportRecipientMutation, { data, loading, error }] = useDeleteDynamicSurveyIncidentReportRecipientMutation({
 *   variables: {
 *      recipientId: // value for 'recipientId'
 *   },
 * });
 */
export function useDeleteDynamicSurveyIncidentReportRecipientMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDynamicSurveyIncidentReportRecipientMutation, DeleteDynamicSurveyIncidentReportRecipientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDynamicSurveyIncidentReportRecipientMutation, DeleteDynamicSurveyIncidentReportRecipientMutationVariables>(DeleteDynamicSurveyIncidentReportRecipientDocument, options);
      }
export type DeleteDynamicSurveyIncidentReportRecipientMutationHookResult = ReturnType<typeof useDeleteDynamicSurveyIncidentReportRecipientMutation>;
export type DeleteDynamicSurveyIncidentReportRecipientMutationResult = Apollo.MutationResult<DeleteDynamicSurveyIncidentReportRecipientMutation>;
export type DeleteDynamicSurveyIncidentReportRecipientMutationOptions = Apollo.BaseMutationOptions<DeleteDynamicSurveyIncidentReportRecipientMutation, DeleteDynamicSurveyIncidentReportRecipientMutationVariables>;
export const SetDynamicSurveyAllowAdditionalIncidentReportsDocument = gql`
    mutation setDynamicSurveyAllowAdditionalIncidentReports($surveyId: String!, $allowAdditions: Boolean!) {
  setDynamicSurveyAllowAdditionalIncidentReports(
    input: {surveyId: $surveyId, allowAdditions: $allowAdditions}
  ) {
    allowAdditionalSupplementalReports
    allowAdditionalSupplementalReportsCutoffDays
    allowAdditionalIncidentReports
    allowAdditionalIncidentReportsCutoffDays
  }
}
    `;
export type SetDynamicSurveyAllowAdditionalIncidentReportsMutationFn = Apollo.MutationFunction<SetDynamicSurveyAllowAdditionalIncidentReportsMutation, SetDynamicSurveyAllowAdditionalIncidentReportsMutationVariables>;

/**
 * __useSetDynamicSurveyAllowAdditionalIncidentReportsMutation__
 *
 * To run a mutation, you first call `useSetDynamicSurveyAllowAdditionalIncidentReportsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDynamicSurveyAllowAdditionalIncidentReportsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDynamicSurveyAllowAdditionalIncidentReportsMutation, { data, loading, error }] = useSetDynamicSurveyAllowAdditionalIncidentReportsMutation({
 *   variables: {
 *      surveyId: // value for 'surveyId'
 *      allowAdditions: // value for 'allowAdditions'
 *   },
 * });
 */
export function useSetDynamicSurveyAllowAdditionalIncidentReportsMutation(baseOptions?: Apollo.MutationHookOptions<SetDynamicSurveyAllowAdditionalIncidentReportsMutation, SetDynamicSurveyAllowAdditionalIncidentReportsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetDynamicSurveyAllowAdditionalIncidentReportsMutation, SetDynamicSurveyAllowAdditionalIncidentReportsMutationVariables>(SetDynamicSurveyAllowAdditionalIncidentReportsDocument, options);
      }
export type SetDynamicSurveyAllowAdditionalIncidentReportsMutationHookResult = ReturnType<typeof useSetDynamicSurveyAllowAdditionalIncidentReportsMutation>;
export type SetDynamicSurveyAllowAdditionalIncidentReportsMutationResult = Apollo.MutationResult<SetDynamicSurveyAllowAdditionalIncidentReportsMutation>;
export type SetDynamicSurveyAllowAdditionalIncidentReportsMutationOptions = Apollo.BaseMutationOptions<SetDynamicSurveyAllowAdditionalIncidentReportsMutation, SetDynamicSurveyAllowAdditionalIncidentReportsMutationVariables>;
export const SetDynamicSurveyAllowAdditionalIncidentReportsCutoffDaysDocument = gql`
    mutation setDynamicSurveyAllowAdditionalIncidentReportsCutoffDays($surveyId: String!, $allowAdditionsCutoffDays: Int) {
  setDynamicSurveyAllowAdditionalIncidentReportsCutoffDays(
    input: {surveyId: $surveyId, allowAdditionsCutoffDays: $allowAdditionsCutoffDays}
  ) {
    allowAdditionalSupplementalReports
    allowAdditionalSupplementalReportsCutoffDays
    allowAdditionalIncidentReports
    allowAdditionalIncidentReportsCutoffDays
  }
}
    `;
export type SetDynamicSurveyAllowAdditionalIncidentReportsCutoffDaysMutationFn = Apollo.MutationFunction<SetDynamicSurveyAllowAdditionalIncidentReportsCutoffDaysMutation, SetDynamicSurveyAllowAdditionalIncidentReportsCutoffDaysMutationVariables>;

/**
 * __useSetDynamicSurveyAllowAdditionalIncidentReportsCutoffDaysMutation__
 *
 * To run a mutation, you first call `useSetDynamicSurveyAllowAdditionalIncidentReportsCutoffDaysMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDynamicSurveyAllowAdditionalIncidentReportsCutoffDaysMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDynamicSurveyAllowAdditionalIncidentReportsCutoffDaysMutation, { data, loading, error }] = useSetDynamicSurveyAllowAdditionalIncidentReportsCutoffDaysMutation({
 *   variables: {
 *      surveyId: // value for 'surveyId'
 *      allowAdditionsCutoffDays: // value for 'allowAdditionsCutoffDays'
 *   },
 * });
 */
export function useSetDynamicSurveyAllowAdditionalIncidentReportsCutoffDaysMutation(baseOptions?: Apollo.MutationHookOptions<SetDynamicSurveyAllowAdditionalIncidentReportsCutoffDaysMutation, SetDynamicSurveyAllowAdditionalIncidentReportsCutoffDaysMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetDynamicSurveyAllowAdditionalIncidentReportsCutoffDaysMutation, SetDynamicSurveyAllowAdditionalIncidentReportsCutoffDaysMutationVariables>(SetDynamicSurveyAllowAdditionalIncidentReportsCutoffDaysDocument, options);
      }
export type SetDynamicSurveyAllowAdditionalIncidentReportsCutoffDaysMutationHookResult = ReturnType<typeof useSetDynamicSurveyAllowAdditionalIncidentReportsCutoffDaysMutation>;
export type SetDynamicSurveyAllowAdditionalIncidentReportsCutoffDaysMutationResult = Apollo.MutationResult<SetDynamicSurveyAllowAdditionalIncidentReportsCutoffDaysMutation>;
export type SetDynamicSurveyAllowAdditionalIncidentReportsCutoffDaysMutationOptions = Apollo.BaseMutationOptions<SetDynamicSurveyAllowAdditionalIncidentReportsCutoffDaysMutation, SetDynamicSurveyAllowAdditionalIncidentReportsCutoffDaysMutationVariables>;
export const AddDynamicSurveySupplementalReportDocument = gql`
    mutation addDynamicSurveySupplementalReport($surveyId: String!, $orgMembershipId: String!) {
  addDynamicSurveySupplementalReport(
    input: {surveyId: $surveyId, orgMembershipId: $orgMembershipId}
  ) {
    id
    orgMembershipId
    orgId
    orgName
    orgAbbreviation
    orgLogo
    title
    userId
    email
    firstName
    lastName
    profilePic
  }
}
    `;
export type AddDynamicSurveySupplementalReportMutationFn = Apollo.MutationFunction<AddDynamicSurveySupplementalReportMutation, AddDynamicSurveySupplementalReportMutationVariables>;

/**
 * __useAddDynamicSurveySupplementalReportMutation__
 *
 * To run a mutation, you first call `useAddDynamicSurveySupplementalReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDynamicSurveySupplementalReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDynamicSurveySupplementalReportMutation, { data, loading, error }] = useAddDynamicSurveySupplementalReportMutation({
 *   variables: {
 *      surveyId: // value for 'surveyId'
 *      orgMembershipId: // value for 'orgMembershipId'
 *   },
 * });
 */
export function useAddDynamicSurveySupplementalReportMutation(baseOptions?: Apollo.MutationHookOptions<AddDynamicSurveySupplementalReportMutation, AddDynamicSurveySupplementalReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddDynamicSurveySupplementalReportMutation, AddDynamicSurveySupplementalReportMutationVariables>(AddDynamicSurveySupplementalReportDocument, options);
      }
export type AddDynamicSurveySupplementalReportMutationHookResult = ReturnType<typeof useAddDynamicSurveySupplementalReportMutation>;
export type AddDynamicSurveySupplementalReportMutationResult = Apollo.MutationResult<AddDynamicSurveySupplementalReportMutation>;
export type AddDynamicSurveySupplementalReportMutationOptions = Apollo.BaseMutationOptions<AddDynamicSurveySupplementalReportMutation, AddDynamicSurveySupplementalReportMutationVariables>;
export const DeleteDynamicSurveySupplementalReportDocument = gql`
    mutation deleteDynamicSurveySupplementalReport($surveyId: String!) {
  deleteDynamicSurveySupplementalReport(input: {surveyId: $surveyId})
}
    `;
export type DeleteDynamicSurveySupplementalReportMutationFn = Apollo.MutationFunction<DeleteDynamicSurveySupplementalReportMutation, DeleteDynamicSurveySupplementalReportMutationVariables>;

/**
 * __useDeleteDynamicSurveySupplementalReportMutation__
 *
 * To run a mutation, you first call `useDeleteDynamicSurveySupplementalReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDynamicSurveySupplementalReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDynamicSurveySupplementalReportMutation, { data, loading, error }] = useDeleteDynamicSurveySupplementalReportMutation({
 *   variables: {
 *      surveyId: // value for 'surveyId'
 *   },
 * });
 */
export function useDeleteDynamicSurveySupplementalReportMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDynamicSurveySupplementalReportMutation, DeleteDynamicSurveySupplementalReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDynamicSurveySupplementalReportMutation, DeleteDynamicSurveySupplementalReportMutationVariables>(DeleteDynamicSurveySupplementalReportDocument, options);
      }
export type DeleteDynamicSurveySupplementalReportMutationHookResult = ReturnType<typeof useDeleteDynamicSurveySupplementalReportMutation>;
export type DeleteDynamicSurveySupplementalReportMutationResult = Apollo.MutationResult<DeleteDynamicSurveySupplementalReportMutation>;
export type DeleteDynamicSurveySupplementalReportMutationOptions = Apollo.BaseMutationOptions<DeleteDynamicSurveySupplementalReportMutation, DeleteDynamicSurveySupplementalReportMutationVariables>;
export const AddDynamicSurveySupplementalReportRecipientDocument = gql`
    mutation addDynamicSurveySupplementalReportRecipient($surveyId: String!, $orgMembershipId: String!) {
  addDynamicSurveySupplementalReportRecipient(
    input: {surveyId: $surveyId, orgMembershipId: $orgMembershipId}
  ) {
    id
    orgMembershipId
    orgId
    orgName
    orgAbbreviation
    orgLogo
    title
    userId
    email
    firstName
    lastName
    profilePic
  }
}
    `;
export type AddDynamicSurveySupplementalReportRecipientMutationFn = Apollo.MutationFunction<AddDynamicSurveySupplementalReportRecipientMutation, AddDynamicSurveySupplementalReportRecipientMutationVariables>;

/**
 * __useAddDynamicSurveySupplementalReportRecipientMutation__
 *
 * To run a mutation, you first call `useAddDynamicSurveySupplementalReportRecipientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDynamicSurveySupplementalReportRecipientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDynamicSurveySupplementalReportRecipientMutation, { data, loading, error }] = useAddDynamicSurveySupplementalReportRecipientMutation({
 *   variables: {
 *      surveyId: // value for 'surveyId'
 *      orgMembershipId: // value for 'orgMembershipId'
 *   },
 * });
 */
export function useAddDynamicSurveySupplementalReportRecipientMutation(baseOptions?: Apollo.MutationHookOptions<AddDynamicSurveySupplementalReportRecipientMutation, AddDynamicSurveySupplementalReportRecipientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddDynamicSurveySupplementalReportRecipientMutation, AddDynamicSurveySupplementalReportRecipientMutationVariables>(AddDynamicSurveySupplementalReportRecipientDocument, options);
      }
export type AddDynamicSurveySupplementalReportRecipientMutationHookResult = ReturnType<typeof useAddDynamicSurveySupplementalReportRecipientMutation>;
export type AddDynamicSurveySupplementalReportRecipientMutationResult = Apollo.MutationResult<AddDynamicSurveySupplementalReportRecipientMutation>;
export type AddDynamicSurveySupplementalReportRecipientMutationOptions = Apollo.BaseMutationOptions<AddDynamicSurveySupplementalReportRecipientMutation, AddDynamicSurveySupplementalReportRecipientMutationVariables>;
export const DeleteDynamicSurveySupplementalReportRecipientDocument = gql`
    mutation deleteDynamicSurveySupplementalReportRecipient($recipientId: String!) {
  deleteDynamicSurveySupplementalReportRecipient(
    input: {recipientId: $recipientId}
  )
}
    `;
export type DeleteDynamicSurveySupplementalReportRecipientMutationFn = Apollo.MutationFunction<DeleteDynamicSurveySupplementalReportRecipientMutation, DeleteDynamicSurveySupplementalReportRecipientMutationVariables>;

/**
 * __useDeleteDynamicSurveySupplementalReportRecipientMutation__
 *
 * To run a mutation, you first call `useDeleteDynamicSurveySupplementalReportRecipientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDynamicSurveySupplementalReportRecipientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDynamicSurveySupplementalReportRecipientMutation, { data, loading, error }] = useDeleteDynamicSurveySupplementalReportRecipientMutation({
 *   variables: {
 *      recipientId: // value for 'recipientId'
 *   },
 * });
 */
export function useDeleteDynamicSurveySupplementalReportRecipientMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDynamicSurveySupplementalReportRecipientMutation, DeleteDynamicSurveySupplementalReportRecipientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDynamicSurveySupplementalReportRecipientMutation, DeleteDynamicSurveySupplementalReportRecipientMutationVariables>(DeleteDynamicSurveySupplementalReportRecipientDocument, options);
      }
export type DeleteDynamicSurveySupplementalReportRecipientMutationHookResult = ReturnType<typeof useDeleteDynamicSurveySupplementalReportRecipientMutation>;
export type DeleteDynamicSurveySupplementalReportRecipientMutationResult = Apollo.MutationResult<DeleteDynamicSurveySupplementalReportRecipientMutation>;
export type DeleteDynamicSurveySupplementalReportRecipientMutationOptions = Apollo.BaseMutationOptions<DeleteDynamicSurveySupplementalReportRecipientMutation, DeleteDynamicSurveySupplementalReportRecipientMutationVariables>;
export const SetDynamicSurveyAllowAdditionalSupplementalReportsDocument = gql`
    mutation setDynamicSurveyAllowAdditionalSupplementalReports($surveyId: String!, $allowAdditions: Boolean!) {
  setDynamicSurveyAllowAdditionalSupplementalReports(
    input: {surveyId: $surveyId, allowAdditions: $allowAdditions}
  ) {
    allowAdditionalSupplementalReports
    allowAdditionalSupplementalReportsCutoffDays
    allowAdditionalIncidentReports
    allowAdditionalIncidentReportsCutoffDays
  }
}
    `;
export type SetDynamicSurveyAllowAdditionalSupplementalReportsMutationFn = Apollo.MutationFunction<SetDynamicSurveyAllowAdditionalSupplementalReportsMutation, SetDynamicSurveyAllowAdditionalSupplementalReportsMutationVariables>;

/**
 * __useSetDynamicSurveyAllowAdditionalSupplementalReportsMutation__
 *
 * To run a mutation, you first call `useSetDynamicSurveyAllowAdditionalSupplementalReportsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDynamicSurveyAllowAdditionalSupplementalReportsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDynamicSurveyAllowAdditionalSupplementalReportsMutation, { data, loading, error }] = useSetDynamicSurveyAllowAdditionalSupplementalReportsMutation({
 *   variables: {
 *      surveyId: // value for 'surveyId'
 *      allowAdditions: // value for 'allowAdditions'
 *   },
 * });
 */
export function useSetDynamicSurveyAllowAdditionalSupplementalReportsMutation(baseOptions?: Apollo.MutationHookOptions<SetDynamicSurveyAllowAdditionalSupplementalReportsMutation, SetDynamicSurveyAllowAdditionalSupplementalReportsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetDynamicSurveyAllowAdditionalSupplementalReportsMutation, SetDynamicSurveyAllowAdditionalSupplementalReportsMutationVariables>(SetDynamicSurveyAllowAdditionalSupplementalReportsDocument, options);
      }
export type SetDynamicSurveyAllowAdditionalSupplementalReportsMutationHookResult = ReturnType<typeof useSetDynamicSurveyAllowAdditionalSupplementalReportsMutation>;
export type SetDynamicSurveyAllowAdditionalSupplementalReportsMutationResult = Apollo.MutationResult<SetDynamicSurveyAllowAdditionalSupplementalReportsMutation>;
export type SetDynamicSurveyAllowAdditionalSupplementalReportsMutationOptions = Apollo.BaseMutationOptions<SetDynamicSurveyAllowAdditionalSupplementalReportsMutation, SetDynamicSurveyAllowAdditionalSupplementalReportsMutationVariables>;
export const SetDynamicSurveyAllowAdditionalSupplementalReportsCutoffDaysDocument = gql`
    mutation setDynamicSurveyAllowAdditionalSupplementalReportsCutoffDays($surveyId: String!, $allowAdditionsCutoffDays: Int) {
  setDynamicSurveyAllowAdditionalSupplementalReportsCutoffDays(
    input: {surveyId: $surveyId, allowAdditionsCutoffDays: $allowAdditionsCutoffDays}
  ) {
    allowAdditionalSupplementalReports
    allowAdditionalSupplementalReportsCutoffDays
    allowAdditionalIncidentReports
    allowAdditionalIncidentReportsCutoffDays
  }
}
    `;
export type SetDynamicSurveyAllowAdditionalSupplementalReportsCutoffDaysMutationFn = Apollo.MutationFunction<SetDynamicSurveyAllowAdditionalSupplementalReportsCutoffDaysMutation, SetDynamicSurveyAllowAdditionalSupplementalReportsCutoffDaysMutationVariables>;

/**
 * __useSetDynamicSurveyAllowAdditionalSupplementalReportsCutoffDaysMutation__
 *
 * To run a mutation, you first call `useSetDynamicSurveyAllowAdditionalSupplementalReportsCutoffDaysMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDynamicSurveyAllowAdditionalSupplementalReportsCutoffDaysMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDynamicSurveyAllowAdditionalSupplementalReportsCutoffDaysMutation, { data, loading, error }] = useSetDynamicSurveyAllowAdditionalSupplementalReportsCutoffDaysMutation({
 *   variables: {
 *      surveyId: // value for 'surveyId'
 *      allowAdditionsCutoffDays: // value for 'allowAdditionsCutoffDays'
 *   },
 * });
 */
export function useSetDynamicSurveyAllowAdditionalSupplementalReportsCutoffDaysMutation(baseOptions?: Apollo.MutationHookOptions<SetDynamicSurveyAllowAdditionalSupplementalReportsCutoffDaysMutation, SetDynamicSurveyAllowAdditionalSupplementalReportsCutoffDaysMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetDynamicSurveyAllowAdditionalSupplementalReportsCutoffDaysMutation, SetDynamicSurveyAllowAdditionalSupplementalReportsCutoffDaysMutationVariables>(SetDynamicSurveyAllowAdditionalSupplementalReportsCutoffDaysDocument, options);
      }
export type SetDynamicSurveyAllowAdditionalSupplementalReportsCutoffDaysMutationHookResult = ReturnType<typeof useSetDynamicSurveyAllowAdditionalSupplementalReportsCutoffDaysMutation>;
export type SetDynamicSurveyAllowAdditionalSupplementalReportsCutoffDaysMutationResult = Apollo.MutationResult<SetDynamicSurveyAllowAdditionalSupplementalReportsCutoffDaysMutation>;
export type SetDynamicSurveyAllowAdditionalSupplementalReportsCutoffDaysMutationOptions = Apollo.BaseMutationOptions<SetDynamicSurveyAllowAdditionalSupplementalReportsCutoffDaysMutation, SetDynamicSurveyAllowAdditionalSupplementalReportsCutoffDaysMutationVariables>;
export const AddDynamicSurveySectionDocument = gql`
    mutation addDynamicSurveySection($surveyId: String!, $name: String!, $description: String!, $isDynamic: Boolean!, $allowAdditions: Boolean!, $requireSupplementalReport: Boolean!, $supplementalReportType: SupplementalReportTypes) {
  addDynamicSurveySection(
    input: {surveyId: $surveyId, name: $name, description: $description, isDynamic: $isDynamic, allowAdditions: $allowAdditions, requireSupplementalReport: $requireSupplementalReport, supplementalReportType: $supplementalReportType}
  ) {
    id
    name
    description
    order
    isDynamic
    requireSupplementalReport
    supplementalReportType
    allowAdditions
    questions {
      id
      name
      description
      order
      isRequired
      allowCorrections
      canAddToKeyIncidents
      questionType
      options {
        id
        value
        order
      }
      assessmentObject {
        id
        sport
        type
        classification
        name
      }
      subQuestions {
        id
        name
        questionText
        order
        isRequired
        canAddToKeyIncidents
        questionType
        options {
          id
          value
          order
        }
      }
    }
    createdAt
    updatedAt
  }
}
    `;
export type AddDynamicSurveySectionMutationFn = Apollo.MutationFunction<AddDynamicSurveySectionMutation, AddDynamicSurveySectionMutationVariables>;

/**
 * __useAddDynamicSurveySectionMutation__
 *
 * To run a mutation, you first call `useAddDynamicSurveySectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDynamicSurveySectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDynamicSurveySectionMutation, { data, loading, error }] = useAddDynamicSurveySectionMutation({
 *   variables: {
 *      surveyId: // value for 'surveyId'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      isDynamic: // value for 'isDynamic'
 *      allowAdditions: // value for 'allowAdditions'
 *      requireSupplementalReport: // value for 'requireSupplementalReport'
 *      supplementalReportType: // value for 'supplementalReportType'
 *   },
 * });
 */
export function useAddDynamicSurveySectionMutation(baseOptions?: Apollo.MutationHookOptions<AddDynamicSurveySectionMutation, AddDynamicSurveySectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddDynamicSurveySectionMutation, AddDynamicSurveySectionMutationVariables>(AddDynamicSurveySectionDocument, options);
      }
export type AddDynamicSurveySectionMutationHookResult = ReturnType<typeof useAddDynamicSurveySectionMutation>;
export type AddDynamicSurveySectionMutationResult = Apollo.MutationResult<AddDynamicSurveySectionMutation>;
export type AddDynamicSurveySectionMutationOptions = Apollo.BaseMutationOptions<AddDynamicSurveySectionMutation, AddDynamicSurveySectionMutationVariables>;
export const UpdateDynamicSurveySectionDocument = gql`
    mutation updateDynamicSurveySection($sectionId: String!, $name: String!, $description: String!, $allowAdditions: Boolean!, $order: Int!) {
  updateDynamicSurveySection(
    input: {sectionId: $sectionId, name: $name, description: $description, allowAdditions: $allowAdditions, order: $order}
  ) {
    id
    name
    description
    order
    isDynamic
    requireSupplementalReport
    allowAdditions
    questions {
      id
      name
      description
      order
      isRequired
      allowCorrections
      canAddToKeyIncidents
      questionType
      options {
        id
        value
        order
      }
      assessmentObject {
        id
        sport
        type
        classification
        name
      }
      subQuestions {
        id
        name
        questionText
        order
        isRequired
        canAddToKeyIncidents
        questionType
        options {
          id
          value
          order
        }
      }
    }
    createdAt
    updatedAt
  }
}
    `;
export type UpdateDynamicSurveySectionMutationFn = Apollo.MutationFunction<UpdateDynamicSurveySectionMutation, UpdateDynamicSurveySectionMutationVariables>;

/**
 * __useUpdateDynamicSurveySectionMutation__
 *
 * To run a mutation, you first call `useUpdateDynamicSurveySectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDynamicSurveySectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDynamicSurveySectionMutation, { data, loading, error }] = useUpdateDynamicSurveySectionMutation({
 *   variables: {
 *      sectionId: // value for 'sectionId'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      allowAdditions: // value for 'allowAdditions'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useUpdateDynamicSurveySectionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDynamicSurveySectionMutation, UpdateDynamicSurveySectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDynamicSurveySectionMutation, UpdateDynamicSurveySectionMutationVariables>(UpdateDynamicSurveySectionDocument, options);
      }
export type UpdateDynamicSurveySectionMutationHookResult = ReturnType<typeof useUpdateDynamicSurveySectionMutation>;
export type UpdateDynamicSurveySectionMutationResult = Apollo.MutationResult<UpdateDynamicSurveySectionMutation>;
export type UpdateDynamicSurveySectionMutationOptions = Apollo.BaseMutationOptions<UpdateDynamicSurveySectionMutation, UpdateDynamicSurveySectionMutationVariables>;
export const UpdateDynamicSurveySectionSupplementalReportDocument = gql`
    mutation updateDynamicSurveySectionSupplementalReport($sectionId: String!, $requireSupplementalReport: Boolean!, $supplementalReportType: SupplementalReportTypes) {
  updateDynamicSurveySectionSupplementalReport(
    input: {sectionId: $sectionId, requireSupplementalReport: $requireSupplementalReport, supplementalReportType: $supplementalReportType}
  ) {
    id
    name
    description
    order
    isDynamic
    requireSupplementalReport
    supplementalReportType
    allowAdditions
    questions {
      id
      name
      description
      order
      isRequired
      allowCorrections
      canAddToKeyIncidents
      questionType
      options {
        id
        value
        order
      }
      assessmentObject {
        id
        sport
        type
        classification
        name
      }
      subQuestions {
        id
        name
        questionText
        order
        isRequired
        canAddToKeyIncidents
        questionType
        options {
          id
          value
          order
        }
      }
    }
    createdAt
    updatedAt
  }
}
    `;
export type UpdateDynamicSurveySectionSupplementalReportMutationFn = Apollo.MutationFunction<UpdateDynamicSurveySectionSupplementalReportMutation, UpdateDynamicSurveySectionSupplementalReportMutationVariables>;

/**
 * __useUpdateDynamicSurveySectionSupplementalReportMutation__
 *
 * To run a mutation, you first call `useUpdateDynamicSurveySectionSupplementalReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDynamicSurveySectionSupplementalReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDynamicSurveySectionSupplementalReportMutation, { data, loading, error }] = useUpdateDynamicSurveySectionSupplementalReportMutation({
 *   variables: {
 *      sectionId: // value for 'sectionId'
 *      requireSupplementalReport: // value for 'requireSupplementalReport'
 *      supplementalReportType: // value for 'supplementalReportType'
 *   },
 * });
 */
export function useUpdateDynamicSurveySectionSupplementalReportMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDynamicSurveySectionSupplementalReportMutation, UpdateDynamicSurveySectionSupplementalReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDynamicSurveySectionSupplementalReportMutation, UpdateDynamicSurveySectionSupplementalReportMutationVariables>(UpdateDynamicSurveySectionSupplementalReportDocument, options);
      }
export type UpdateDynamicSurveySectionSupplementalReportMutationHookResult = ReturnType<typeof useUpdateDynamicSurveySectionSupplementalReportMutation>;
export type UpdateDynamicSurveySectionSupplementalReportMutationResult = Apollo.MutationResult<UpdateDynamicSurveySectionSupplementalReportMutation>;
export type UpdateDynamicSurveySectionSupplementalReportMutationOptions = Apollo.BaseMutationOptions<UpdateDynamicSurveySectionSupplementalReportMutation, UpdateDynamicSurveySectionSupplementalReportMutationVariables>;
export const DeleteDynamicSurveySectionDocument = gql`
    mutation deleteDynamicSurveySection($sectionId: String!) {
  deleteDynamicSurveySection(input: {sectionId: $sectionId})
}
    `;
export type DeleteDynamicSurveySectionMutationFn = Apollo.MutationFunction<DeleteDynamicSurveySectionMutation, DeleteDynamicSurveySectionMutationVariables>;

/**
 * __useDeleteDynamicSurveySectionMutation__
 *
 * To run a mutation, you first call `useDeleteDynamicSurveySectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDynamicSurveySectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDynamicSurveySectionMutation, { data, loading, error }] = useDeleteDynamicSurveySectionMutation({
 *   variables: {
 *      sectionId: // value for 'sectionId'
 *   },
 * });
 */
export function useDeleteDynamicSurveySectionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDynamicSurveySectionMutation, DeleteDynamicSurveySectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDynamicSurveySectionMutation, DeleteDynamicSurveySectionMutationVariables>(DeleteDynamicSurveySectionDocument, options);
      }
export type DeleteDynamicSurveySectionMutationHookResult = ReturnType<typeof useDeleteDynamicSurveySectionMutation>;
export type DeleteDynamicSurveySectionMutationResult = Apollo.MutationResult<DeleteDynamicSurveySectionMutation>;
export type DeleteDynamicSurveySectionMutationOptions = Apollo.BaseMutationOptions<DeleteDynamicSurveySectionMutation, DeleteDynamicSurveySectionMutationVariables>;
export const AddDynamicSurveySectionQuestionDocument = gql`
    mutation addDynamicSurveySectionQuestion($sectionId: String!, $assessmentObjectId: String!, $name: String!, $description: String!, $isRequired: Boolean!, $allowCorrections: Boolean!, $questionType: SurveyQuestionTypes!) {
  addDynamicSurveySectionQuestion(
    input: {sectionId: $sectionId, assessmentObjectId: $assessmentObjectId, name: $name, description: $description, isRequired: $isRequired, allowCorrections: $allowCorrections, questionType: $questionType}
  ) {
    id
    name
    description
    order
    isRequired
    allowCorrections
    canAddToKeyIncidents
    questionType
    options {
      id
      value
      order
    }
    assessmentObject {
      id
      sport
      type
      classification
      name
    }
    subQuestions {
      id
      name
      questionText
      order
      isRequired
      canAddToKeyIncidents
      questionType
      options {
        id
        value
        order
      }
    }
    createdAt
    updatedAt
  }
}
    `;
export type AddDynamicSurveySectionQuestionMutationFn = Apollo.MutationFunction<AddDynamicSurveySectionQuestionMutation, AddDynamicSurveySectionQuestionMutationVariables>;

/**
 * __useAddDynamicSurveySectionQuestionMutation__
 *
 * To run a mutation, you first call `useAddDynamicSurveySectionQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDynamicSurveySectionQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDynamicSurveySectionQuestionMutation, { data, loading, error }] = useAddDynamicSurveySectionQuestionMutation({
 *   variables: {
 *      sectionId: // value for 'sectionId'
 *      assessmentObjectId: // value for 'assessmentObjectId'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      isRequired: // value for 'isRequired'
 *      allowCorrections: // value for 'allowCorrections'
 *      questionType: // value for 'questionType'
 *   },
 * });
 */
export function useAddDynamicSurveySectionQuestionMutation(baseOptions?: Apollo.MutationHookOptions<AddDynamicSurveySectionQuestionMutation, AddDynamicSurveySectionQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddDynamicSurveySectionQuestionMutation, AddDynamicSurveySectionQuestionMutationVariables>(AddDynamicSurveySectionQuestionDocument, options);
      }
export type AddDynamicSurveySectionQuestionMutationHookResult = ReturnType<typeof useAddDynamicSurveySectionQuestionMutation>;
export type AddDynamicSurveySectionQuestionMutationResult = Apollo.MutationResult<AddDynamicSurveySectionQuestionMutation>;
export type AddDynamicSurveySectionQuestionMutationOptions = Apollo.BaseMutationOptions<AddDynamicSurveySectionQuestionMutation, AddDynamicSurveySectionQuestionMutationVariables>;
export const UpdateDynamicSurveySectionQuestionDocument = gql`
    mutation updateDynamicSurveySectionQuestion($questionId: String!, $assessmentObjectId: String!, $name: String!, $description: String!, $isRequired: Boolean!, $allowCorrections: Boolean!, $order: Int!) {
  updateDynamicSurveySectionQuestion(
    input: {questionId: $questionId, assessmentObjectId: $assessmentObjectId, name: $name, description: $description, isRequired: $isRequired, allowCorrections: $allowCorrections, order: $order}
  ) {
    id
    name
    description
    order
    isRequired
    allowCorrections
    canAddToKeyIncidents
    questionType
    options {
      id
      value
      order
    }
    assessmentObject {
      id
      sport
      type
      classification
      name
    }
    subQuestions {
      id
      name
      questionText
      order
      isRequired
      canAddToKeyIncidents
      questionType
      options {
        id
        value
        order
      }
    }
    createdAt
    updatedAt
  }
}
    `;
export type UpdateDynamicSurveySectionQuestionMutationFn = Apollo.MutationFunction<UpdateDynamicSurveySectionQuestionMutation, UpdateDynamicSurveySectionQuestionMutationVariables>;

/**
 * __useUpdateDynamicSurveySectionQuestionMutation__
 *
 * To run a mutation, you first call `useUpdateDynamicSurveySectionQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDynamicSurveySectionQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDynamicSurveySectionQuestionMutation, { data, loading, error }] = useUpdateDynamicSurveySectionQuestionMutation({
 *   variables: {
 *      questionId: // value for 'questionId'
 *      assessmentObjectId: // value for 'assessmentObjectId'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      isRequired: // value for 'isRequired'
 *      allowCorrections: // value for 'allowCorrections'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useUpdateDynamicSurveySectionQuestionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDynamicSurveySectionQuestionMutation, UpdateDynamicSurveySectionQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDynamicSurveySectionQuestionMutation, UpdateDynamicSurveySectionQuestionMutationVariables>(UpdateDynamicSurveySectionQuestionDocument, options);
      }
export type UpdateDynamicSurveySectionQuestionMutationHookResult = ReturnType<typeof useUpdateDynamicSurveySectionQuestionMutation>;
export type UpdateDynamicSurveySectionQuestionMutationResult = Apollo.MutationResult<UpdateDynamicSurveySectionQuestionMutation>;
export type UpdateDynamicSurveySectionQuestionMutationOptions = Apollo.BaseMutationOptions<UpdateDynamicSurveySectionQuestionMutation, UpdateDynamicSurveySectionQuestionMutationVariables>;
export const DeleteDynamicSurveySectionQuestionDocument = gql`
    mutation deleteDynamicSurveySectionQuestion($questionId: String!) {
  deleteDynamicSurveySectionQuestion(input: {questionId: $questionId})
}
    `;
export type DeleteDynamicSurveySectionQuestionMutationFn = Apollo.MutationFunction<DeleteDynamicSurveySectionQuestionMutation, DeleteDynamicSurveySectionQuestionMutationVariables>;

/**
 * __useDeleteDynamicSurveySectionQuestionMutation__
 *
 * To run a mutation, you first call `useDeleteDynamicSurveySectionQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDynamicSurveySectionQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDynamicSurveySectionQuestionMutation, { data, loading, error }] = useDeleteDynamicSurveySectionQuestionMutation({
 *   variables: {
 *      questionId: // value for 'questionId'
 *   },
 * });
 */
export function useDeleteDynamicSurveySectionQuestionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDynamicSurveySectionQuestionMutation, DeleteDynamicSurveySectionQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDynamicSurveySectionQuestionMutation, DeleteDynamicSurveySectionQuestionMutationVariables>(DeleteDynamicSurveySectionQuestionDocument, options);
      }
export type DeleteDynamicSurveySectionQuestionMutationHookResult = ReturnType<typeof useDeleteDynamicSurveySectionQuestionMutation>;
export type DeleteDynamicSurveySectionQuestionMutationResult = Apollo.MutationResult<DeleteDynamicSurveySectionQuestionMutation>;
export type DeleteDynamicSurveySectionQuestionMutationOptions = Apollo.BaseMutationOptions<DeleteDynamicSurveySectionQuestionMutation, DeleteDynamicSurveySectionQuestionMutationVariables>;
export const SetDynamicSurveySectionQuestionCanAddToKeyIncidentsDocument = gql`
    mutation setDynamicSurveySectionQuestionCanAddToKeyIncidents($questionId: String!, $canAddToKeyIncidents: Boolean!) {
  setDynamicSurveySectionQuestionCanAddToKeyIncidents(
    input: {questionId: $questionId, canAddToKeyIncidents: $canAddToKeyIncidents}
  ) {
    id
    name
    description
    order
    isRequired
    allowCorrections
    canAddToKeyIncidents
    questionType
    options {
      id
      value
      order
    }
    assessmentObject {
      id
      sport
      type
      classification
      name
    }
    subQuestions {
      id
      name
      questionText
      order
      isRequired
      canAddToKeyIncidents
      questionType
      options {
        id
        value
        order
      }
    }
    createdAt
    updatedAt
  }
}
    `;
export type SetDynamicSurveySectionQuestionCanAddToKeyIncidentsMutationFn = Apollo.MutationFunction<SetDynamicSurveySectionQuestionCanAddToKeyIncidentsMutation, SetDynamicSurveySectionQuestionCanAddToKeyIncidentsMutationVariables>;

/**
 * __useSetDynamicSurveySectionQuestionCanAddToKeyIncidentsMutation__
 *
 * To run a mutation, you first call `useSetDynamicSurveySectionQuestionCanAddToKeyIncidentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDynamicSurveySectionQuestionCanAddToKeyIncidentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDynamicSurveySectionQuestionCanAddToKeyIncidentsMutation, { data, loading, error }] = useSetDynamicSurveySectionQuestionCanAddToKeyIncidentsMutation({
 *   variables: {
 *      questionId: // value for 'questionId'
 *      canAddToKeyIncidents: // value for 'canAddToKeyIncidents'
 *   },
 * });
 */
export function useSetDynamicSurveySectionQuestionCanAddToKeyIncidentsMutation(baseOptions?: Apollo.MutationHookOptions<SetDynamicSurveySectionQuestionCanAddToKeyIncidentsMutation, SetDynamicSurveySectionQuestionCanAddToKeyIncidentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetDynamicSurveySectionQuestionCanAddToKeyIncidentsMutation, SetDynamicSurveySectionQuestionCanAddToKeyIncidentsMutationVariables>(SetDynamicSurveySectionQuestionCanAddToKeyIncidentsDocument, options);
      }
export type SetDynamicSurveySectionQuestionCanAddToKeyIncidentsMutationHookResult = ReturnType<typeof useSetDynamicSurveySectionQuestionCanAddToKeyIncidentsMutation>;
export type SetDynamicSurveySectionQuestionCanAddToKeyIncidentsMutationResult = Apollo.MutationResult<SetDynamicSurveySectionQuestionCanAddToKeyIncidentsMutation>;
export type SetDynamicSurveySectionQuestionCanAddToKeyIncidentsMutationOptions = Apollo.BaseMutationOptions<SetDynamicSurveySectionQuestionCanAddToKeyIncidentsMutation, SetDynamicSurveySectionQuestionCanAddToKeyIncidentsMutationVariables>;
export const AddDynamicSurveySectionQuestionOptionDocument = gql`
    mutation addDynamicSurveySectionQuestionOption($questionId: String!, $value: String!) {
  addDynamicSurveySectionQuestionOption(
    input: {questionId: $questionId, value: $value}
  ) {
    id
    value
    order
  }
}
    `;
export type AddDynamicSurveySectionQuestionOptionMutationFn = Apollo.MutationFunction<AddDynamicSurveySectionQuestionOptionMutation, AddDynamicSurveySectionQuestionOptionMutationVariables>;

/**
 * __useAddDynamicSurveySectionQuestionOptionMutation__
 *
 * To run a mutation, you first call `useAddDynamicSurveySectionQuestionOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDynamicSurveySectionQuestionOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDynamicSurveySectionQuestionOptionMutation, { data, loading, error }] = useAddDynamicSurveySectionQuestionOptionMutation({
 *   variables: {
 *      questionId: // value for 'questionId'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useAddDynamicSurveySectionQuestionOptionMutation(baseOptions?: Apollo.MutationHookOptions<AddDynamicSurveySectionQuestionOptionMutation, AddDynamicSurveySectionQuestionOptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddDynamicSurveySectionQuestionOptionMutation, AddDynamicSurveySectionQuestionOptionMutationVariables>(AddDynamicSurveySectionQuestionOptionDocument, options);
      }
export type AddDynamicSurveySectionQuestionOptionMutationHookResult = ReturnType<typeof useAddDynamicSurveySectionQuestionOptionMutation>;
export type AddDynamicSurveySectionQuestionOptionMutationResult = Apollo.MutationResult<AddDynamicSurveySectionQuestionOptionMutation>;
export type AddDynamicSurveySectionQuestionOptionMutationOptions = Apollo.BaseMutationOptions<AddDynamicSurveySectionQuestionOptionMutation, AddDynamicSurveySectionQuestionOptionMutationVariables>;
export const DeleteDynamicSurveySectionQuestionOptionDocument = gql`
    mutation deleteDynamicSurveySectionQuestionOption($optionId: String!) {
  deleteDynamicSurveySectionQuestionOption(input: {optionId: $optionId})
}
    `;
export type DeleteDynamicSurveySectionQuestionOptionMutationFn = Apollo.MutationFunction<DeleteDynamicSurveySectionQuestionOptionMutation, DeleteDynamicSurveySectionQuestionOptionMutationVariables>;

/**
 * __useDeleteDynamicSurveySectionQuestionOptionMutation__
 *
 * To run a mutation, you first call `useDeleteDynamicSurveySectionQuestionOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDynamicSurveySectionQuestionOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDynamicSurveySectionQuestionOptionMutation, { data, loading, error }] = useDeleteDynamicSurveySectionQuestionOptionMutation({
 *   variables: {
 *      optionId: // value for 'optionId'
 *   },
 * });
 */
export function useDeleteDynamicSurveySectionQuestionOptionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDynamicSurveySectionQuestionOptionMutation, DeleteDynamicSurveySectionQuestionOptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDynamicSurveySectionQuestionOptionMutation, DeleteDynamicSurveySectionQuestionOptionMutationVariables>(DeleteDynamicSurveySectionQuestionOptionDocument, options);
      }
export type DeleteDynamicSurveySectionQuestionOptionMutationHookResult = ReturnType<typeof useDeleteDynamicSurveySectionQuestionOptionMutation>;
export type DeleteDynamicSurveySectionQuestionOptionMutationResult = Apollo.MutationResult<DeleteDynamicSurveySectionQuestionOptionMutation>;
export type DeleteDynamicSurveySectionQuestionOptionMutationOptions = Apollo.BaseMutationOptions<DeleteDynamicSurveySectionQuestionOptionMutation, DeleteDynamicSurveySectionQuestionOptionMutationVariables>;
export const UpdateDynamicSurveySectionQuestionOptionDocument = gql`
    mutation updateDynamicSurveySectionQuestionOption($optionId: String!, $value: String!, $order: Int!) {
  updateDynamicSurveySectionQuestionOption(
    input: {optionId: $optionId, value: $value, order: $order}
  ) {
    id
    value
    order
  }
}
    `;
export type UpdateDynamicSurveySectionQuestionOptionMutationFn = Apollo.MutationFunction<UpdateDynamicSurveySectionQuestionOptionMutation, UpdateDynamicSurveySectionQuestionOptionMutationVariables>;

/**
 * __useUpdateDynamicSurveySectionQuestionOptionMutation__
 *
 * To run a mutation, you first call `useUpdateDynamicSurveySectionQuestionOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDynamicSurveySectionQuestionOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDynamicSurveySectionQuestionOptionMutation, { data, loading, error }] = useUpdateDynamicSurveySectionQuestionOptionMutation({
 *   variables: {
 *      optionId: // value for 'optionId'
 *      value: // value for 'value'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useUpdateDynamicSurveySectionQuestionOptionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDynamicSurveySectionQuestionOptionMutation, UpdateDynamicSurveySectionQuestionOptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDynamicSurveySectionQuestionOptionMutation, UpdateDynamicSurveySectionQuestionOptionMutationVariables>(UpdateDynamicSurveySectionQuestionOptionDocument, options);
      }
export type UpdateDynamicSurveySectionQuestionOptionMutationHookResult = ReturnType<typeof useUpdateDynamicSurveySectionQuestionOptionMutation>;
export type UpdateDynamicSurveySectionQuestionOptionMutationResult = Apollo.MutationResult<UpdateDynamicSurveySectionQuestionOptionMutation>;
export type UpdateDynamicSurveySectionQuestionOptionMutationOptions = Apollo.BaseMutationOptions<UpdateDynamicSurveySectionQuestionOptionMutation, UpdateDynamicSurveySectionQuestionOptionMutationVariables>;
export const AddDynamicSurveySectionSubQuestionDocument = gql`
    mutation addDynamicSurveySectionSubQuestion($questionId: String!, $name: String!, $questionText: String!, $isRequired: Boolean!, $questionType: SurveyQuestionTypes!) {
  addDynamicSurveySectionSubQuestion(
    input: {questionId: $questionId, name: $name, questionText: $questionText, isRequired: $isRequired, questionType: $questionType}
  ) {
    id
    name
    questionText
    order
    isRequired
    canAddToKeyIncidents
    questionType
    options {
      id
      value
      order
    }
    createdAt
    updatedAt
  }
}
    `;
export type AddDynamicSurveySectionSubQuestionMutationFn = Apollo.MutationFunction<AddDynamicSurveySectionSubQuestionMutation, AddDynamicSurveySectionSubQuestionMutationVariables>;

/**
 * __useAddDynamicSurveySectionSubQuestionMutation__
 *
 * To run a mutation, you first call `useAddDynamicSurveySectionSubQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDynamicSurveySectionSubQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDynamicSurveySectionSubQuestionMutation, { data, loading, error }] = useAddDynamicSurveySectionSubQuestionMutation({
 *   variables: {
 *      questionId: // value for 'questionId'
 *      name: // value for 'name'
 *      questionText: // value for 'questionText'
 *      isRequired: // value for 'isRequired'
 *      questionType: // value for 'questionType'
 *   },
 * });
 */
export function useAddDynamicSurveySectionSubQuestionMutation(baseOptions?: Apollo.MutationHookOptions<AddDynamicSurveySectionSubQuestionMutation, AddDynamicSurveySectionSubQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddDynamicSurveySectionSubQuestionMutation, AddDynamicSurveySectionSubQuestionMutationVariables>(AddDynamicSurveySectionSubQuestionDocument, options);
      }
export type AddDynamicSurveySectionSubQuestionMutationHookResult = ReturnType<typeof useAddDynamicSurveySectionSubQuestionMutation>;
export type AddDynamicSurveySectionSubQuestionMutationResult = Apollo.MutationResult<AddDynamicSurveySectionSubQuestionMutation>;
export type AddDynamicSurveySectionSubQuestionMutationOptions = Apollo.BaseMutationOptions<AddDynamicSurveySectionSubQuestionMutation, AddDynamicSurveySectionSubQuestionMutationVariables>;
export const UpdateDynamicSurveySectionSubQuestionDocument = gql`
    mutation updateDynamicSurveySectionSubQuestion($subQuestionId: String!, $name: String!, $questionText: String!, $isRequired: Boolean!, $order: Int!) {
  updateDynamicSurveySectionSubQuestion(
    input: {subQuestionId: $subQuestionId, name: $name, questionText: $questionText, isRequired: $isRequired, order: $order}
  ) {
    id
    name
    questionText
    order
    isRequired
    canAddToKeyIncidents
    questionType
    options {
      id
      value
      order
    }
    createdAt
    updatedAt
  }
}
    `;
export type UpdateDynamicSurveySectionSubQuestionMutationFn = Apollo.MutationFunction<UpdateDynamicSurveySectionSubQuestionMutation, UpdateDynamicSurveySectionSubQuestionMutationVariables>;

/**
 * __useUpdateDynamicSurveySectionSubQuestionMutation__
 *
 * To run a mutation, you first call `useUpdateDynamicSurveySectionSubQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDynamicSurveySectionSubQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDynamicSurveySectionSubQuestionMutation, { data, loading, error }] = useUpdateDynamicSurveySectionSubQuestionMutation({
 *   variables: {
 *      subQuestionId: // value for 'subQuestionId'
 *      name: // value for 'name'
 *      questionText: // value for 'questionText'
 *      isRequired: // value for 'isRequired'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useUpdateDynamicSurveySectionSubQuestionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDynamicSurveySectionSubQuestionMutation, UpdateDynamicSurveySectionSubQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDynamicSurveySectionSubQuestionMutation, UpdateDynamicSurveySectionSubQuestionMutationVariables>(UpdateDynamicSurveySectionSubQuestionDocument, options);
      }
export type UpdateDynamicSurveySectionSubQuestionMutationHookResult = ReturnType<typeof useUpdateDynamicSurveySectionSubQuestionMutation>;
export type UpdateDynamicSurveySectionSubQuestionMutationResult = Apollo.MutationResult<UpdateDynamicSurveySectionSubQuestionMutation>;
export type UpdateDynamicSurveySectionSubQuestionMutationOptions = Apollo.BaseMutationOptions<UpdateDynamicSurveySectionSubQuestionMutation, UpdateDynamicSurveySectionSubQuestionMutationVariables>;
export const DeleteDynamicSurveySectionSubQuestionDocument = gql`
    mutation deleteDynamicSurveySectionSubQuestion($subQuestionId: String!) {
  deleteDynamicSurveySectionSubQuestion(input: {subQuestionId: $subQuestionId})
}
    `;
export type DeleteDynamicSurveySectionSubQuestionMutationFn = Apollo.MutationFunction<DeleteDynamicSurveySectionSubQuestionMutation, DeleteDynamicSurveySectionSubQuestionMutationVariables>;

/**
 * __useDeleteDynamicSurveySectionSubQuestionMutation__
 *
 * To run a mutation, you first call `useDeleteDynamicSurveySectionSubQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDynamicSurveySectionSubQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDynamicSurveySectionSubQuestionMutation, { data, loading, error }] = useDeleteDynamicSurveySectionSubQuestionMutation({
 *   variables: {
 *      subQuestionId: // value for 'subQuestionId'
 *   },
 * });
 */
export function useDeleteDynamicSurveySectionSubQuestionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDynamicSurveySectionSubQuestionMutation, DeleteDynamicSurveySectionSubQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDynamicSurveySectionSubQuestionMutation, DeleteDynamicSurveySectionSubQuestionMutationVariables>(DeleteDynamicSurveySectionSubQuestionDocument, options);
      }
export type DeleteDynamicSurveySectionSubQuestionMutationHookResult = ReturnType<typeof useDeleteDynamicSurveySectionSubQuestionMutation>;
export type DeleteDynamicSurveySectionSubQuestionMutationResult = Apollo.MutationResult<DeleteDynamicSurveySectionSubQuestionMutation>;
export type DeleteDynamicSurveySectionSubQuestionMutationOptions = Apollo.BaseMutationOptions<DeleteDynamicSurveySectionSubQuestionMutation, DeleteDynamicSurveySectionSubQuestionMutationVariables>;
export const SetDynamicSurveySectionSubQuestionCanAddToKeyIncidentsDocument = gql`
    mutation setDynamicSurveySectionSubQuestionCanAddToKeyIncidents($subQuestionId: String!, $canAddToKeyIncidents: Boolean!) {
  setDynamicSurveySectionSubQuestionCanAddToKeyIncidents(
    input: {subQuestionId: $subQuestionId, canAddToKeyIncidents: $canAddToKeyIncidents}
  ) {
    id
    name
    questionText
    order
    isRequired
    canAddToKeyIncidents
    questionType
    options {
      id
      value
      order
    }
    createdAt
    updatedAt
  }
}
    `;
export type SetDynamicSurveySectionSubQuestionCanAddToKeyIncidentsMutationFn = Apollo.MutationFunction<SetDynamicSurveySectionSubQuestionCanAddToKeyIncidentsMutation, SetDynamicSurveySectionSubQuestionCanAddToKeyIncidentsMutationVariables>;

/**
 * __useSetDynamicSurveySectionSubQuestionCanAddToKeyIncidentsMutation__
 *
 * To run a mutation, you first call `useSetDynamicSurveySectionSubQuestionCanAddToKeyIncidentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDynamicSurveySectionSubQuestionCanAddToKeyIncidentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDynamicSurveySectionSubQuestionCanAddToKeyIncidentsMutation, { data, loading, error }] = useSetDynamicSurveySectionSubQuestionCanAddToKeyIncidentsMutation({
 *   variables: {
 *      subQuestionId: // value for 'subQuestionId'
 *      canAddToKeyIncidents: // value for 'canAddToKeyIncidents'
 *   },
 * });
 */
export function useSetDynamicSurveySectionSubQuestionCanAddToKeyIncidentsMutation(baseOptions?: Apollo.MutationHookOptions<SetDynamicSurveySectionSubQuestionCanAddToKeyIncidentsMutation, SetDynamicSurveySectionSubQuestionCanAddToKeyIncidentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetDynamicSurveySectionSubQuestionCanAddToKeyIncidentsMutation, SetDynamicSurveySectionSubQuestionCanAddToKeyIncidentsMutationVariables>(SetDynamicSurveySectionSubQuestionCanAddToKeyIncidentsDocument, options);
      }
export type SetDynamicSurveySectionSubQuestionCanAddToKeyIncidentsMutationHookResult = ReturnType<typeof useSetDynamicSurveySectionSubQuestionCanAddToKeyIncidentsMutation>;
export type SetDynamicSurveySectionSubQuestionCanAddToKeyIncidentsMutationResult = Apollo.MutationResult<SetDynamicSurveySectionSubQuestionCanAddToKeyIncidentsMutation>;
export type SetDynamicSurveySectionSubQuestionCanAddToKeyIncidentsMutationOptions = Apollo.BaseMutationOptions<SetDynamicSurveySectionSubQuestionCanAddToKeyIncidentsMutation, SetDynamicSurveySectionSubQuestionCanAddToKeyIncidentsMutationVariables>;
export const AddDynamicSurveySectionSubQuestionOptionDocument = gql`
    mutation addDynamicSurveySectionSubQuestionOption($subQuestionId: String!, $value: String!) {
  addDynamicSurveySectionSubQuestionOption(
    input: {subQuestionId: $subQuestionId, value: $value}
  ) {
    id
    value
    order
  }
}
    `;
export type AddDynamicSurveySectionSubQuestionOptionMutationFn = Apollo.MutationFunction<AddDynamicSurveySectionSubQuestionOptionMutation, AddDynamicSurveySectionSubQuestionOptionMutationVariables>;

/**
 * __useAddDynamicSurveySectionSubQuestionOptionMutation__
 *
 * To run a mutation, you first call `useAddDynamicSurveySectionSubQuestionOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDynamicSurveySectionSubQuestionOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDynamicSurveySectionSubQuestionOptionMutation, { data, loading, error }] = useAddDynamicSurveySectionSubQuestionOptionMutation({
 *   variables: {
 *      subQuestionId: // value for 'subQuestionId'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useAddDynamicSurveySectionSubQuestionOptionMutation(baseOptions?: Apollo.MutationHookOptions<AddDynamicSurveySectionSubQuestionOptionMutation, AddDynamicSurveySectionSubQuestionOptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddDynamicSurveySectionSubQuestionOptionMutation, AddDynamicSurveySectionSubQuestionOptionMutationVariables>(AddDynamicSurveySectionSubQuestionOptionDocument, options);
      }
export type AddDynamicSurveySectionSubQuestionOptionMutationHookResult = ReturnType<typeof useAddDynamicSurveySectionSubQuestionOptionMutation>;
export type AddDynamicSurveySectionSubQuestionOptionMutationResult = Apollo.MutationResult<AddDynamicSurveySectionSubQuestionOptionMutation>;
export type AddDynamicSurveySectionSubQuestionOptionMutationOptions = Apollo.BaseMutationOptions<AddDynamicSurveySectionSubQuestionOptionMutation, AddDynamicSurveySectionSubQuestionOptionMutationVariables>;
export const DeleteDynamicSurveySectionSubQuestionOptionDocument = gql`
    mutation deleteDynamicSurveySectionSubQuestionOption($optionId: String!) {
  deleteDynamicSurveySectionSubQuestionOption(input: {optionId: $optionId})
}
    `;
export type DeleteDynamicSurveySectionSubQuestionOptionMutationFn = Apollo.MutationFunction<DeleteDynamicSurveySectionSubQuestionOptionMutation, DeleteDynamicSurveySectionSubQuestionOptionMutationVariables>;

/**
 * __useDeleteDynamicSurveySectionSubQuestionOptionMutation__
 *
 * To run a mutation, you first call `useDeleteDynamicSurveySectionSubQuestionOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDynamicSurveySectionSubQuestionOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDynamicSurveySectionSubQuestionOptionMutation, { data, loading, error }] = useDeleteDynamicSurveySectionSubQuestionOptionMutation({
 *   variables: {
 *      optionId: // value for 'optionId'
 *   },
 * });
 */
export function useDeleteDynamicSurveySectionSubQuestionOptionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDynamicSurveySectionSubQuestionOptionMutation, DeleteDynamicSurveySectionSubQuestionOptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDynamicSurveySectionSubQuestionOptionMutation, DeleteDynamicSurveySectionSubQuestionOptionMutationVariables>(DeleteDynamicSurveySectionSubQuestionOptionDocument, options);
      }
export type DeleteDynamicSurveySectionSubQuestionOptionMutationHookResult = ReturnType<typeof useDeleteDynamicSurveySectionSubQuestionOptionMutation>;
export type DeleteDynamicSurveySectionSubQuestionOptionMutationResult = Apollo.MutationResult<DeleteDynamicSurveySectionSubQuestionOptionMutation>;
export type DeleteDynamicSurveySectionSubQuestionOptionMutationOptions = Apollo.BaseMutationOptions<DeleteDynamicSurveySectionSubQuestionOptionMutation, DeleteDynamicSurveySectionSubQuestionOptionMutationVariables>;
export const UpdateDynamicSurveySectionSubQuestionOptionDocument = gql`
    mutation updateDynamicSurveySectionSubQuestionOption($optionId: String!, $value: String!, $order: Int!) {
  updateDynamicSurveySectionSubQuestionOption(
    input: {optionId: $optionId, value: $value, order: $order}
  ) {
    id
    value
    order
  }
}
    `;
export type UpdateDynamicSurveySectionSubQuestionOptionMutationFn = Apollo.MutationFunction<UpdateDynamicSurveySectionSubQuestionOptionMutation, UpdateDynamicSurveySectionSubQuestionOptionMutationVariables>;

/**
 * __useUpdateDynamicSurveySectionSubQuestionOptionMutation__
 *
 * To run a mutation, you first call `useUpdateDynamicSurveySectionSubQuestionOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDynamicSurveySectionSubQuestionOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDynamicSurveySectionSubQuestionOptionMutation, { data, loading, error }] = useUpdateDynamicSurveySectionSubQuestionOptionMutation({
 *   variables: {
 *      optionId: // value for 'optionId'
 *      value: // value for 'value'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useUpdateDynamicSurveySectionSubQuestionOptionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDynamicSurveySectionSubQuestionOptionMutation, UpdateDynamicSurveySectionSubQuestionOptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDynamicSurveySectionSubQuestionOptionMutation, UpdateDynamicSurveySectionSubQuestionOptionMutationVariables>(UpdateDynamicSurveySectionSubQuestionOptionDocument, options);
      }
export type UpdateDynamicSurveySectionSubQuestionOptionMutationHookResult = ReturnType<typeof useUpdateDynamicSurveySectionSubQuestionOptionMutation>;
export type UpdateDynamicSurveySectionSubQuestionOptionMutationResult = Apollo.MutationResult<UpdateDynamicSurveySectionSubQuestionOptionMutation>;
export type UpdateDynamicSurveySectionSubQuestionOptionMutationOptions = Apollo.BaseMutationOptions<UpdateDynamicSurveySectionSubQuestionOptionMutation, UpdateDynamicSurveySectionSubQuestionOptionMutationVariables>;
export const AddDynamicSurveySummaryRecipientDocument = gql`
    mutation addDynamicSurveySummaryRecipient($surveyId: String!, $email: String!, $firstName: String!, $lastName: String!, $isActive: Boolean!) {
  addDynamicSurveySummaryRecipient(
    input: {surveyId: $surveyId, email: $email, firstName: $firstName, lastName: $lastName, isActive: $isActive}
  ) {
    id
    email
    firstName
    lastName
    isActive
  }
}
    `;
export type AddDynamicSurveySummaryRecipientMutationFn = Apollo.MutationFunction<AddDynamicSurveySummaryRecipientMutation, AddDynamicSurveySummaryRecipientMutationVariables>;

/**
 * __useAddDynamicSurveySummaryRecipientMutation__
 *
 * To run a mutation, you first call `useAddDynamicSurveySummaryRecipientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDynamicSurveySummaryRecipientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDynamicSurveySummaryRecipientMutation, { data, loading, error }] = useAddDynamicSurveySummaryRecipientMutation({
 *   variables: {
 *      surveyId: // value for 'surveyId'
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useAddDynamicSurveySummaryRecipientMutation(baseOptions?: Apollo.MutationHookOptions<AddDynamicSurveySummaryRecipientMutation, AddDynamicSurveySummaryRecipientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddDynamicSurveySummaryRecipientMutation, AddDynamicSurveySummaryRecipientMutationVariables>(AddDynamicSurveySummaryRecipientDocument, options);
      }
export type AddDynamicSurveySummaryRecipientMutationHookResult = ReturnType<typeof useAddDynamicSurveySummaryRecipientMutation>;
export type AddDynamicSurveySummaryRecipientMutationResult = Apollo.MutationResult<AddDynamicSurveySummaryRecipientMutation>;
export type AddDynamicSurveySummaryRecipientMutationOptions = Apollo.BaseMutationOptions<AddDynamicSurveySummaryRecipientMutation, AddDynamicSurveySummaryRecipientMutationVariables>;
export const DeleteDynamicSurveySummaryRecipientDocument = gql`
    mutation deleteDynamicSurveySummaryRecipient($recipientId: String!) {
  deleteDynamicSurveySummaryRecipient(input: {recipientId: $recipientId})
}
    `;
export type DeleteDynamicSurveySummaryRecipientMutationFn = Apollo.MutationFunction<DeleteDynamicSurveySummaryRecipientMutation, DeleteDynamicSurveySummaryRecipientMutationVariables>;

/**
 * __useDeleteDynamicSurveySummaryRecipientMutation__
 *
 * To run a mutation, you first call `useDeleteDynamicSurveySummaryRecipientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDynamicSurveySummaryRecipientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDynamicSurveySummaryRecipientMutation, { data, loading, error }] = useDeleteDynamicSurveySummaryRecipientMutation({
 *   variables: {
 *      recipientId: // value for 'recipientId'
 *   },
 * });
 */
export function useDeleteDynamicSurveySummaryRecipientMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDynamicSurveySummaryRecipientMutation, DeleteDynamicSurveySummaryRecipientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDynamicSurveySummaryRecipientMutation, DeleteDynamicSurveySummaryRecipientMutationVariables>(DeleteDynamicSurveySummaryRecipientDocument, options);
      }
export type DeleteDynamicSurveySummaryRecipientMutationHookResult = ReturnType<typeof useDeleteDynamicSurveySummaryRecipientMutation>;
export type DeleteDynamicSurveySummaryRecipientMutationResult = Apollo.MutationResult<DeleteDynamicSurveySummaryRecipientMutation>;
export type DeleteDynamicSurveySummaryRecipientMutationOptions = Apollo.BaseMutationOptions<DeleteDynamicSurveySummaryRecipientMutation, DeleteDynamicSurveySummaryRecipientMutationVariables>;
export const SetIsActiveForDynamicSurveySummaryRecipientDocument = gql`
    mutation setIsActiveForDynamicSurveySummaryRecipient($recipientId: String!, $isActive: Boolean!) {
  setIsActiveForDynamicSurveySummaryRecipient(
    input: {recipientId: $recipientId, isActive: $isActive}
  ) {
    id
    email
    firstName
    lastName
    isActive
  }
}
    `;
export type SetIsActiveForDynamicSurveySummaryRecipientMutationFn = Apollo.MutationFunction<SetIsActiveForDynamicSurveySummaryRecipientMutation, SetIsActiveForDynamicSurveySummaryRecipientMutationVariables>;

/**
 * __useSetIsActiveForDynamicSurveySummaryRecipientMutation__
 *
 * To run a mutation, you first call `useSetIsActiveForDynamicSurveySummaryRecipientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetIsActiveForDynamicSurveySummaryRecipientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setIsActiveForDynamicSurveySummaryRecipientMutation, { data, loading, error }] = useSetIsActiveForDynamicSurveySummaryRecipientMutation({
 *   variables: {
 *      recipientId: // value for 'recipientId'
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useSetIsActiveForDynamicSurveySummaryRecipientMutation(baseOptions?: Apollo.MutationHookOptions<SetIsActiveForDynamicSurveySummaryRecipientMutation, SetIsActiveForDynamicSurveySummaryRecipientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetIsActiveForDynamicSurveySummaryRecipientMutation, SetIsActiveForDynamicSurveySummaryRecipientMutationVariables>(SetIsActiveForDynamicSurveySummaryRecipientDocument, options);
      }
export type SetIsActiveForDynamicSurveySummaryRecipientMutationHookResult = ReturnType<typeof useSetIsActiveForDynamicSurveySummaryRecipientMutation>;
export type SetIsActiveForDynamicSurveySummaryRecipientMutationResult = Apollo.MutationResult<SetIsActiveForDynamicSurveySummaryRecipientMutation>;
export type SetIsActiveForDynamicSurveySummaryRecipientMutationOptions = Apollo.BaseMutationOptions<SetIsActiveForDynamicSurveySummaryRecipientMutation, SetIsActiveForDynamicSurveySummaryRecipientMutationVariables>;
export const ListSummaryRecipientsForDynamicSurveyDocument = gql`
    query listSummaryRecipientsForDynamicSurvey($offset: Int!, $limit: Int!, $surveyId: String!, $emailOrName: String) {
  listSummaryRecipientsForDynamicSurvey(
    input: {surveyId: $surveyId, emailOrName: $emailOrName, pagination: {offset: $offset, limit: $limit}}
  ) {
    recipients {
      id
      email
      firstName
      lastName
      isActive
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListSummaryRecipientsForDynamicSurveyQuery__
 *
 * To run a query within a React component, call `useListSummaryRecipientsForDynamicSurveyQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSummaryRecipientsForDynamicSurveyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSummaryRecipientsForDynamicSurveyQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      surveyId: // value for 'surveyId'
 *      emailOrName: // value for 'emailOrName'
 *   },
 * });
 */
export function useListSummaryRecipientsForDynamicSurveyQuery(baseOptions: Apollo.QueryHookOptions<ListSummaryRecipientsForDynamicSurveyQuery, ListSummaryRecipientsForDynamicSurveyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListSummaryRecipientsForDynamicSurveyQuery, ListSummaryRecipientsForDynamicSurveyQueryVariables>(ListSummaryRecipientsForDynamicSurveyDocument, options);
      }
export function useListSummaryRecipientsForDynamicSurveyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListSummaryRecipientsForDynamicSurveyQuery, ListSummaryRecipientsForDynamicSurveyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListSummaryRecipientsForDynamicSurveyQuery, ListSummaryRecipientsForDynamicSurveyQueryVariables>(ListSummaryRecipientsForDynamicSurveyDocument, options);
        }
export type ListSummaryRecipientsForDynamicSurveyQueryHookResult = ReturnType<typeof useListSummaryRecipientsForDynamicSurveyQuery>;
export type ListSummaryRecipientsForDynamicSurveyLazyQueryHookResult = ReturnType<typeof useListSummaryRecipientsForDynamicSurveyLazyQuery>;
export type ListSummaryRecipientsForDynamicSurveyQueryResult = Apollo.QueryResult<ListSummaryRecipientsForDynamicSurveyQuery, ListSummaryRecipientsForDynamicSurveyQueryVariables>;
export const ListAvailableCompetitionsForFeedbackForMeDocument = gql`
    query listAvailableCompetitionsForFeedbackForMe {
  listAvailableCompetitionsForFeedbackForMe {
    organizationId
    organizationName
    leagueId
    leagueName
    seasonId
    seasonName
    competitionId
    competitionExternalId
    startTime
    sport
    homeTeamId
    homeTeamName
    homeTeamAbbreviation
    awayTeamId
    awayTeamName
    awayTeamAbbreviation
    canAddKeyIncidentsToAssessments
    canAddKeyIncidentsToDynamicSurveys
    canAddSupplementalReportsToDynamicSurveys
  }
}
    `;

/**
 * __useListAvailableCompetitionsForFeedbackForMeQuery__
 *
 * To run a query within a React component, call `useListAvailableCompetitionsForFeedbackForMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAvailableCompetitionsForFeedbackForMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAvailableCompetitionsForFeedbackForMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useListAvailableCompetitionsForFeedbackForMeQuery(baseOptions?: Apollo.QueryHookOptions<ListAvailableCompetitionsForFeedbackForMeQuery, ListAvailableCompetitionsForFeedbackForMeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAvailableCompetitionsForFeedbackForMeQuery, ListAvailableCompetitionsForFeedbackForMeQueryVariables>(ListAvailableCompetitionsForFeedbackForMeDocument, options);
      }
export function useListAvailableCompetitionsForFeedbackForMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAvailableCompetitionsForFeedbackForMeQuery, ListAvailableCompetitionsForFeedbackForMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAvailableCompetitionsForFeedbackForMeQuery, ListAvailableCompetitionsForFeedbackForMeQueryVariables>(ListAvailableCompetitionsForFeedbackForMeDocument, options);
        }
export type ListAvailableCompetitionsForFeedbackForMeQueryHookResult = ReturnType<typeof useListAvailableCompetitionsForFeedbackForMeQuery>;
export type ListAvailableCompetitionsForFeedbackForMeLazyQueryHookResult = ReturnType<typeof useListAvailableCompetitionsForFeedbackForMeLazyQuery>;
export type ListAvailableCompetitionsForFeedbackForMeQueryResult = Apollo.QueryResult<ListAvailableCompetitionsForFeedbackForMeQuery, ListAvailableCompetitionsForFeedbackForMeQueryVariables>;
export const ListSourcesAvailableForAdditionalFeedbackForCompetitionsForMeDocument = gql`
    query listSourcesAvailableForAdditionalFeedbackForCompetitionsForMe($competitionId: String!, $source: FeedbackSource!, $type: FeedbackType!) {
  listSourcesAvailableForAdditionalFeedbackForCompetitionsForMe(
    input: {competitionId: $competitionId, source: $source, type: $type}
  ) {
    organizationId
    organizationName
    leagueId
    leagueName
    seasonId
    seasonName
    competitionId
    competitionExternalId
    startTime
    sport
    homeTeamId
    homeTeamName
    homeTeamAbbreviation
    awayTeamId
    awayTeamName
    awayTeamAbbreviation
    sourceType
    feedbackType
    surveyId
    surveyName
  }
}
    `;

/**
 * __useListSourcesAvailableForAdditionalFeedbackForCompetitionsForMeQuery__
 *
 * To run a query within a React component, call `useListSourcesAvailableForAdditionalFeedbackForCompetitionsForMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSourcesAvailableForAdditionalFeedbackForCompetitionsForMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSourcesAvailableForAdditionalFeedbackForCompetitionsForMeQuery({
 *   variables: {
 *      competitionId: // value for 'competitionId'
 *      source: // value for 'source'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useListSourcesAvailableForAdditionalFeedbackForCompetitionsForMeQuery(baseOptions: Apollo.QueryHookOptions<ListSourcesAvailableForAdditionalFeedbackForCompetitionsForMeQuery, ListSourcesAvailableForAdditionalFeedbackForCompetitionsForMeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListSourcesAvailableForAdditionalFeedbackForCompetitionsForMeQuery, ListSourcesAvailableForAdditionalFeedbackForCompetitionsForMeQueryVariables>(ListSourcesAvailableForAdditionalFeedbackForCompetitionsForMeDocument, options);
      }
export function useListSourcesAvailableForAdditionalFeedbackForCompetitionsForMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListSourcesAvailableForAdditionalFeedbackForCompetitionsForMeQuery, ListSourcesAvailableForAdditionalFeedbackForCompetitionsForMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListSourcesAvailableForAdditionalFeedbackForCompetitionsForMeQuery, ListSourcesAvailableForAdditionalFeedbackForCompetitionsForMeQueryVariables>(ListSourcesAvailableForAdditionalFeedbackForCompetitionsForMeDocument, options);
        }
export type ListSourcesAvailableForAdditionalFeedbackForCompetitionsForMeQueryHookResult = ReturnType<typeof useListSourcesAvailableForAdditionalFeedbackForCompetitionsForMeQuery>;
export type ListSourcesAvailableForAdditionalFeedbackForCompetitionsForMeLazyQueryHookResult = ReturnType<typeof useListSourcesAvailableForAdditionalFeedbackForCompetitionsForMeLazyQuery>;
export type ListSourcesAvailableForAdditionalFeedbackForCompetitionsForMeQueryResult = Apollo.QueryResult<ListSourcesAvailableForAdditionalFeedbackForCompetitionsForMeQuery, ListSourcesAvailableForAdditionalFeedbackForCompetitionsForMeQueryVariables>;
export const AddAdditionalSupplementalReportToDynamicCompetitionSurveyDocument = gql`
    mutation addAdditionalSupplementalReportToDynamicCompetitionSurvey($competitionSurveyId: String!, $title: String!, $supplementalReportType: SupplementalReportTypes!, $time: String, $teamId: String, $report: String) {
  addAdditionalSupplementalReportToDynamicCompetitionSurvey(
    input: {competitionSurveyId: $competitionSurveyId, title: $title, supplementalReportType: $supplementalReportType, time: $time, teamId: $teamId, report: $report}
  ) {
    id
    competitionSurveyId
    dynamicCompetitionSurveyId
    submittedBy {
      userId
      email
      firstName
      lastName
      profilePic
      teamId
      teamName
      teamLogo
      officialId
      assessmentObjectId
      assessmentObjectName
    }
    competitionInfo {
      competitionId
      externalId
      sport
      homeTeamId
      homeTeamName
      homeTeamScore
      homeTeamTieBreakerScore
      awayTeamId
      awayTeamName
      awayTeamScore
      awayTeamTieBreakerScore
      startTime
      timeZone
      location
    }
    assessmentObject {
      id
      sport
      type
      classification
      name
      createdAt
      updatedAt
    }
    official {
      id
      sport
      userId
      status
      email
      firstName
      lastName
      profilePic
      createdAt
      updatedAt
    }
    team {
      id
      name
      abbreviation
      sport
      city
      stateAbbreviation
      countryCode
      classification
      gender
      parentOrganizationName
      externalId
      ageGroup
      timeZone
      website
      logo
      createdAt
      updatedAt
    }
    organizationId
    organizationName
    leagueId
    leagueName
    seasonId
    seasonName
    competitionSurveySectionQuestionId
    competitionSurveyFeedbackId
    dynamicCompetitionSurveySectionQuestionId
    dynamicCompetitionSurveyFeedbackId
    dynamicCompetitionSurveySubFeedbackId
    type
    status
    title
    report
    reportVersion
    time
    approvedDate
    reopenedDate
    submittedAt
    createdAt
    updatedAt
  }
}
    `;
export type AddAdditionalSupplementalReportToDynamicCompetitionSurveyMutationFn = Apollo.MutationFunction<AddAdditionalSupplementalReportToDynamicCompetitionSurveyMutation, AddAdditionalSupplementalReportToDynamicCompetitionSurveyMutationVariables>;

/**
 * __useAddAdditionalSupplementalReportToDynamicCompetitionSurveyMutation__
 *
 * To run a mutation, you first call `useAddAdditionalSupplementalReportToDynamicCompetitionSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAdditionalSupplementalReportToDynamicCompetitionSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAdditionalSupplementalReportToDynamicCompetitionSurveyMutation, { data, loading, error }] = useAddAdditionalSupplementalReportToDynamicCompetitionSurveyMutation({
 *   variables: {
 *      competitionSurveyId: // value for 'competitionSurveyId'
 *      title: // value for 'title'
 *      supplementalReportType: // value for 'supplementalReportType'
 *      time: // value for 'time'
 *      teamId: // value for 'teamId'
 *      report: // value for 'report'
 *   },
 * });
 */
export function useAddAdditionalSupplementalReportToDynamicCompetitionSurveyMutation(baseOptions?: Apollo.MutationHookOptions<AddAdditionalSupplementalReportToDynamicCompetitionSurveyMutation, AddAdditionalSupplementalReportToDynamicCompetitionSurveyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddAdditionalSupplementalReportToDynamicCompetitionSurveyMutation, AddAdditionalSupplementalReportToDynamicCompetitionSurveyMutationVariables>(AddAdditionalSupplementalReportToDynamicCompetitionSurveyDocument, options);
      }
export type AddAdditionalSupplementalReportToDynamicCompetitionSurveyMutationHookResult = ReturnType<typeof useAddAdditionalSupplementalReportToDynamicCompetitionSurveyMutation>;
export type AddAdditionalSupplementalReportToDynamicCompetitionSurveyMutationResult = Apollo.MutationResult<AddAdditionalSupplementalReportToDynamicCompetitionSurveyMutation>;
export type AddAdditionalSupplementalReportToDynamicCompetitionSurveyMutationOptions = Apollo.BaseMutationOptions<AddAdditionalSupplementalReportToDynamicCompetitionSurveyMutation, AddAdditionalSupplementalReportToDynamicCompetitionSurveyMutationVariables>;
export const ListAvailableCompetitionOfficialsForAdditionalKeyIncidentsDocument = gql`
    query listAvailableCompetitionOfficialsForAdditionalKeyIncidents($competitionSurveyId: String!, $source: FeedbackSource!) {
  listAvailableCompetitionOfficialsForAdditionalKeyIncidents(
    input: {competitionSurveyId: $competitionSurveyId, source: $source}
  ) {
    sectionId
    sectionName
    competitionOfficialId
    assessmentObjectId
    type
    order
    official {
      id
      sport
      userId
      status
      firstName
      lastName
      profilePic
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useListAvailableCompetitionOfficialsForAdditionalKeyIncidentsQuery__
 *
 * To run a query within a React component, call `useListAvailableCompetitionOfficialsForAdditionalKeyIncidentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAvailableCompetitionOfficialsForAdditionalKeyIncidentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAvailableCompetitionOfficialsForAdditionalKeyIncidentsQuery({
 *   variables: {
 *      competitionSurveyId: // value for 'competitionSurveyId'
 *      source: // value for 'source'
 *   },
 * });
 */
export function useListAvailableCompetitionOfficialsForAdditionalKeyIncidentsQuery(baseOptions: Apollo.QueryHookOptions<ListAvailableCompetitionOfficialsForAdditionalKeyIncidentsQuery, ListAvailableCompetitionOfficialsForAdditionalKeyIncidentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAvailableCompetitionOfficialsForAdditionalKeyIncidentsQuery, ListAvailableCompetitionOfficialsForAdditionalKeyIncidentsQueryVariables>(ListAvailableCompetitionOfficialsForAdditionalKeyIncidentsDocument, options);
      }
export function useListAvailableCompetitionOfficialsForAdditionalKeyIncidentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAvailableCompetitionOfficialsForAdditionalKeyIncidentsQuery, ListAvailableCompetitionOfficialsForAdditionalKeyIncidentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAvailableCompetitionOfficialsForAdditionalKeyIncidentsQuery, ListAvailableCompetitionOfficialsForAdditionalKeyIncidentsQueryVariables>(ListAvailableCompetitionOfficialsForAdditionalKeyIncidentsDocument, options);
        }
export type ListAvailableCompetitionOfficialsForAdditionalKeyIncidentsQueryHookResult = ReturnType<typeof useListAvailableCompetitionOfficialsForAdditionalKeyIncidentsQuery>;
export type ListAvailableCompetitionOfficialsForAdditionalKeyIncidentsLazyQueryHookResult = ReturnType<typeof useListAvailableCompetitionOfficialsForAdditionalKeyIncidentsLazyQuery>;
export type ListAvailableCompetitionOfficialsForAdditionalKeyIncidentsQueryResult = Apollo.QueryResult<ListAvailableCompetitionOfficialsForAdditionalKeyIncidentsQuery, ListAvailableCompetitionOfficialsForAdditionalKeyIncidentsQueryVariables>;
export const ListAvailableFeedbackToConvertForAdditionalKeyIncidentsDocument = gql`
    query listAvailableFeedbackToConvertForAdditionalKeyIncidents($competitionSurveyId: String!, $source: FeedbackSource!) {
  listAvailableFeedbackToConvertForAdditionalKeyIncidents(
    input: {competitionSurveyId: $competitionSurveyId, source: $source}
  ) {
    surveyId
    sectionId
    assessmentObjectId
    assessmentObjectName
    officialId
    officialFirstName
    officialLastName
    questionId
    questionName
    responseId
    response
  }
}
    `;

/**
 * __useListAvailableFeedbackToConvertForAdditionalKeyIncidentsQuery__
 *
 * To run a query within a React component, call `useListAvailableFeedbackToConvertForAdditionalKeyIncidentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAvailableFeedbackToConvertForAdditionalKeyIncidentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAvailableFeedbackToConvertForAdditionalKeyIncidentsQuery({
 *   variables: {
 *      competitionSurveyId: // value for 'competitionSurveyId'
 *      source: // value for 'source'
 *   },
 * });
 */
export function useListAvailableFeedbackToConvertForAdditionalKeyIncidentsQuery(baseOptions: Apollo.QueryHookOptions<ListAvailableFeedbackToConvertForAdditionalKeyIncidentsQuery, ListAvailableFeedbackToConvertForAdditionalKeyIncidentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAvailableFeedbackToConvertForAdditionalKeyIncidentsQuery, ListAvailableFeedbackToConvertForAdditionalKeyIncidentsQueryVariables>(ListAvailableFeedbackToConvertForAdditionalKeyIncidentsDocument, options);
      }
export function useListAvailableFeedbackToConvertForAdditionalKeyIncidentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAvailableFeedbackToConvertForAdditionalKeyIncidentsQuery, ListAvailableFeedbackToConvertForAdditionalKeyIncidentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAvailableFeedbackToConvertForAdditionalKeyIncidentsQuery, ListAvailableFeedbackToConvertForAdditionalKeyIncidentsQueryVariables>(ListAvailableFeedbackToConvertForAdditionalKeyIncidentsDocument, options);
        }
export type ListAvailableFeedbackToConvertForAdditionalKeyIncidentsQueryHookResult = ReturnType<typeof useListAvailableFeedbackToConvertForAdditionalKeyIncidentsQuery>;
export type ListAvailableFeedbackToConvertForAdditionalKeyIncidentsLazyQueryHookResult = ReturnType<typeof useListAvailableFeedbackToConvertForAdditionalKeyIncidentsLazyQuery>;
export type ListAvailableFeedbackToConvertForAdditionalKeyIncidentsQueryResult = Apollo.QueryResult<ListAvailableFeedbackToConvertForAdditionalKeyIncidentsQuery, ListAvailableFeedbackToConvertForAdditionalKeyIncidentsQueryVariables>;
export const AddAdditionalKeyIncidentToCompetitionSurveyDocument = gql`
    mutation addAdditionalKeyIncidentToCompetitionSurvey($competitionSurveyId: String!, $source: FeedbackSource!, $description: String!, $incidentType: KeyIncidentTypes!, $impactedOutcome: Boolean!, $time: String, $sectionId: String, $questionId: String, $feedbackResponseId: String, $periodId: String, $teamId: String) {
  addAdditionalKeyIncidentToCompetitionSurvey(
    input: {competitionSurveyId: $competitionSurveyId, source: $source, description: $description, incidentType: $incidentType, impactedOutcome: $impactedOutcome, time: $time, sectionId: $sectionId, questionId: $questionId, feedbackResponseId: $feedbackResponseId, periodId: $periodId, teamId: $teamId}
  ) {
    id
    competitionSurveyId
    dynamicCompetitionSurveyId
    submittedBy {
      userId
      email
      firstName
      lastName
      profilePic
      teamId
      teamName
      teamLogo
    }
    competitionInfo {
      competitionId
      externalId
      sport
      homeTeamId
      homeTeamName
      homeTeamScore
      homeTeamTieBreakerScore
      awayTeamId
      awayTeamName
      awayTeamScore
      awayTeamTieBreakerScore
      startTime
      timeZone
      location
    }
    assessmentObject {
      id
      sport
      type
      classification
      name
      createdAt
      updatedAt
    }
    official {
      id
      sport
      userId
      status
      email
      firstName
      lastName
      profilePic
      createdAt
      updatedAt
    }
    organizationId
    organizationName
    leagueId
    leagueName
    seasonId
    seasonName
    competitionSurveyFeedbackId
    type
    status
    description
    time
    videoStatus
    videoUrl
    videoError
    period {
      id
      sport
      name
      createdAt
      updatedAt
    }
    impactedOutcome
    resolvedDate
    reopenedDate
    createdAt
    updatedAt
  }
}
    `;
export type AddAdditionalKeyIncidentToCompetitionSurveyMutationFn = Apollo.MutationFunction<AddAdditionalKeyIncidentToCompetitionSurveyMutation, AddAdditionalKeyIncidentToCompetitionSurveyMutationVariables>;

/**
 * __useAddAdditionalKeyIncidentToCompetitionSurveyMutation__
 *
 * To run a mutation, you first call `useAddAdditionalKeyIncidentToCompetitionSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAdditionalKeyIncidentToCompetitionSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAdditionalKeyIncidentToCompetitionSurveyMutation, { data, loading, error }] = useAddAdditionalKeyIncidentToCompetitionSurveyMutation({
 *   variables: {
 *      competitionSurveyId: // value for 'competitionSurveyId'
 *      source: // value for 'source'
 *      description: // value for 'description'
 *      incidentType: // value for 'incidentType'
 *      impactedOutcome: // value for 'impactedOutcome'
 *      time: // value for 'time'
 *      sectionId: // value for 'sectionId'
 *      questionId: // value for 'questionId'
 *      feedbackResponseId: // value for 'feedbackResponseId'
 *      periodId: // value for 'periodId'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useAddAdditionalKeyIncidentToCompetitionSurveyMutation(baseOptions?: Apollo.MutationHookOptions<AddAdditionalKeyIncidentToCompetitionSurveyMutation, AddAdditionalKeyIncidentToCompetitionSurveyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddAdditionalKeyIncidentToCompetitionSurveyMutation, AddAdditionalKeyIncidentToCompetitionSurveyMutationVariables>(AddAdditionalKeyIncidentToCompetitionSurveyDocument, options);
      }
export type AddAdditionalKeyIncidentToCompetitionSurveyMutationHookResult = ReturnType<typeof useAddAdditionalKeyIncidentToCompetitionSurveyMutation>;
export type AddAdditionalKeyIncidentToCompetitionSurveyMutationResult = Apollo.MutationResult<AddAdditionalKeyIncidentToCompetitionSurveyMutation>;
export type AddAdditionalKeyIncidentToCompetitionSurveyMutationOptions = Apollo.BaseMutationOptions<AddAdditionalKeyIncidentToCompetitionSurveyMutation, AddAdditionalKeyIncidentToCompetitionSurveyMutationVariables>;
export const GetKeyIncidentByIdDocument = gql`
    query getKeyIncidentById($keyIncidentId: String!) {
  getKeyIncidentById(input: {keyIncidentId: $keyIncidentId}) {
    id
    competitionSurveyId
    dynamicCompetitionSurveyId
    submittedBy {
      userId
      email
      firstName
      lastName
      profilePic
      teamId
      teamName
      teamLogo
      officialId
      assessmentObjectId
      assessmentObjectName
    }
    reviewStartedBy {
      userId
      email
      firstName
      lastName
      profilePic
      title
      organizationName
      organizationAbbreviation
    }
    resolvedBy {
      userId
      email
      firstName
      lastName
      profilePic
      title
      organizationName
      organizationAbbreviation
    }
    competitionInfo {
      competitionId
      externalId
      sport
      homeTeamId
      homeTeamName
      homeTeamScore
      homeTeamTieBreakerScore
      awayTeamId
      awayTeamName
      awayTeamScore
      awayTeamTieBreakerScore
      startTime
      timeZone
      location
      outcome
      verificationStatus
    }
    assessmentObject {
      id
      sport
      type
      classification
      name
      createdAt
      updatedAt
    }
    official {
      id
      sport
      userId
      status
      email
      firstName
      lastName
      profilePic
      createdAt
      updatedAt
    }
    team {
      id
      name
      abbreviation
      sport
      city
      stateAbbreviation
      countryCode
      classification
      gender
      parentOrganizationName
      externalId
      ageGroup
      timeZone
      website
      logo
      createdAt
      updatedAt
    }
    organizationId
    organizationName
    leagueId
    leagueName
    seasonId
    seasonName
    competitionSurveyFeedbackId
    dynamicCompetitionSurveyFeedbackId
    dynamicCompetitionSurveySubFeedbackId
    type
    status
    description
    time
    videoStatus
    videoUrl
    videoError
    videoIntegrationRetryCount
    period {
      id
      sport
      name
    }
    impactedOutcome
    resolvedDate
    reopenedDate
    createdAt
    updatedAt
    reviewStartedAt
  }
}
    `;

/**
 * __useGetKeyIncidentByIdQuery__
 *
 * To run a query within a React component, call `useGetKeyIncidentByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKeyIncidentByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKeyIncidentByIdQuery({
 *   variables: {
 *      keyIncidentId: // value for 'keyIncidentId'
 *   },
 * });
 */
export function useGetKeyIncidentByIdQuery(baseOptions: Apollo.QueryHookOptions<GetKeyIncidentByIdQuery, GetKeyIncidentByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetKeyIncidentByIdQuery, GetKeyIncidentByIdQueryVariables>(GetKeyIncidentByIdDocument, options);
      }
export function useGetKeyIncidentByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetKeyIncidentByIdQuery, GetKeyIncidentByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetKeyIncidentByIdQuery, GetKeyIncidentByIdQueryVariables>(GetKeyIncidentByIdDocument, options);
        }
export type GetKeyIncidentByIdQueryHookResult = ReturnType<typeof useGetKeyIncidentByIdQuery>;
export type GetKeyIncidentByIdLazyQueryHookResult = ReturnType<typeof useGetKeyIncidentByIdLazyQuery>;
export type GetKeyIncidentByIdQueryResult = Apollo.QueryResult<GetKeyIncidentByIdQuery, GetKeyIncidentByIdQueryVariables>;
export const ListKeyIncidentsForCompetitionDocument = gql`
    query listKeyIncidentsForCompetition($competitionId: String!) {
  listKeyIncidentsForCompetition(input: {competitionId: $competitionId}) {
    keyIncidents {
      id
      competitionSurveyId
      dynamicCompetitionSurveyId
      submittedBy {
        userId
        email
        firstName
        lastName
        profilePic
        teamId
        teamName
        teamLogo
        officialId
        assessmentObjectId
        assessmentObjectName
      }
      reviewStartedBy {
        userId
        email
        firstName
        lastName
        profilePic
        title
        organizationName
        organizationAbbreviation
      }
      resolvedBy {
        userId
        email
        firstName
        lastName
        profilePic
        title
        organizationName
        organizationAbbreviation
      }
      competitionInfo {
        competitionId
        externalId
        sport
        homeTeamId
        homeTeamName
        homeTeamScore
        homeTeamTieBreakerScore
        awayTeamId
        awayTeamName
        awayTeamScore
        awayTeamTieBreakerScore
        startTime
        timeZone
        location
        outcome
        verificationStatus
      }
      assessmentObject {
        id
        sport
        type
        classification
        name
        createdAt
        updatedAt
      }
      official {
        id
        sport
        userId
        status
        email
        firstName
        lastName
        profilePic
        createdAt
        updatedAt
      }
      team {
        id
        name
        abbreviation
        sport
        city
        stateAbbreviation
        countryCode
        classification
        gender
        parentOrganizationName
        externalId
        ageGroup
        timeZone
        website
        logo
        createdAt
        updatedAt
      }
      organizationId
      organizationName
      leagueId
      leagueName
      seasonId
      seasonName
      competitionSurveyFeedbackId
      dynamicCompetitionSurveyFeedbackId
      dynamicCompetitionSurveySubFeedbackId
      type
      status
      description
      time
      videoStatus
      videoUrl
      videoError
      videoIntegrationRetryCount
      period {
        id
        sport
        name
      }
      impactedOutcome
      resolvedDate
      reopenedDate
      createdAt
      updatedAt
      reviewStartedAt
    }
    competitionInfo {
      competitionId
      externalId
      sport
      homeTeamId
      homeTeamName
      homeTeamLogo
      homeTeamScore
      homeTeamTieBreakerScore
      awayTeamId
      awayTeamName
      awayTeamLogo
      awayTeamScore
      awayTeamTieBreakerScore
      startTime
      timeZone
      location
      outcome
      verificationStatus
    }
  }
}
    `;

/**
 * __useListKeyIncidentsForCompetitionQuery__
 *
 * To run a query within a React component, call `useListKeyIncidentsForCompetitionQuery` and pass it any options that fit your needs.
 * When your component renders, `useListKeyIncidentsForCompetitionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListKeyIncidentsForCompetitionQuery({
 *   variables: {
 *      competitionId: // value for 'competitionId'
 *   },
 * });
 */
export function useListKeyIncidentsForCompetitionQuery(baseOptions: Apollo.QueryHookOptions<ListKeyIncidentsForCompetitionQuery, ListKeyIncidentsForCompetitionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListKeyIncidentsForCompetitionQuery, ListKeyIncidentsForCompetitionQueryVariables>(ListKeyIncidentsForCompetitionDocument, options);
      }
export function useListKeyIncidentsForCompetitionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListKeyIncidentsForCompetitionQuery, ListKeyIncidentsForCompetitionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListKeyIncidentsForCompetitionQuery, ListKeyIncidentsForCompetitionQueryVariables>(ListKeyIncidentsForCompetitionDocument, options);
        }
export type ListKeyIncidentsForCompetitionQueryHookResult = ReturnType<typeof useListKeyIncidentsForCompetitionQuery>;
export type ListKeyIncidentsForCompetitionLazyQueryHookResult = ReturnType<typeof useListKeyIncidentsForCompetitionLazyQuery>;
export type ListKeyIncidentsForCompetitionQueryResult = Apollo.QueryResult<ListKeyIncidentsForCompetitionQuery, ListKeyIncidentsForCompetitionQueryVariables>;
export const ListKeyIncidentsForCompetitionAndSurveyDocument = gql`
    query listKeyIncidentsForCompetitionAndSurvey($orgId: String!, $competitionId: String!, $surveyId: String!) {
  listKeyIncidentsForCompetitionAndSurvey(
    input: {orgId: $orgId, competitionId: $competitionId, surveyId: $surveyId}
  ) {
    id
    competitionSurveyId
    dynamicCompetitionSurveyId
    submittedBy {
      userId
      email
      firstName
      lastName
      profilePic
      teamId
      teamName
      teamLogo
      officialId
      assessmentObjectId
      assessmentObjectName
    }
    reviewStartedBy {
      userId
      email
      firstName
      lastName
      profilePic
      title
      organizationName
      organizationAbbreviation
    }
    resolvedBy {
      userId
      email
      firstName
      lastName
      profilePic
      title
      organizationName
      organizationAbbreviation
    }
    competitionInfo {
      competitionId
      externalId
      sport
      homeTeamId
      homeTeamName
      homeTeamScore
      homeTeamTieBreakerScore
      awayTeamId
      awayTeamName
      awayTeamScore
      awayTeamTieBreakerScore
      startTime
      timeZone
      location
      outcome
      verificationStatus
    }
    assessmentObject {
      id
      sport
      type
      classification
      name
      createdAt
      updatedAt
    }
    official {
      id
      sport
      userId
      status
      email
      firstName
      lastName
      profilePic
      createdAt
      updatedAt
    }
    team {
      id
      name
      abbreviation
      sport
      city
      stateAbbreviation
      countryCode
      classification
      gender
      parentOrganizationName
      externalId
      ageGroup
      timeZone
      website
      logo
      createdAt
      updatedAt
    }
    organizationId
    organizationName
    leagueId
    leagueName
    seasonId
    seasonName
    competitionSurveyFeedbackId
    dynamicCompetitionSurveyFeedbackId
    dynamicCompetitionSurveySubFeedbackId
    type
    status
    description
    time
    videoStatus
    videoUrl
    videoError
    videoIntegrationRetryCount
    period {
      id
      sport
      name
    }
    impactedOutcome
    resolvedDate
    reopenedDate
    createdAt
    updatedAt
    reviewStartedAt
  }
}
    `;

/**
 * __useListKeyIncidentsForCompetitionAndSurveyQuery__
 *
 * To run a query within a React component, call `useListKeyIncidentsForCompetitionAndSurveyQuery` and pass it any options that fit your needs.
 * When your component renders, `useListKeyIncidentsForCompetitionAndSurveyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListKeyIncidentsForCompetitionAndSurveyQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      competitionId: // value for 'competitionId'
 *      surveyId: // value for 'surveyId'
 *   },
 * });
 */
export function useListKeyIncidentsForCompetitionAndSurveyQuery(baseOptions: Apollo.QueryHookOptions<ListKeyIncidentsForCompetitionAndSurveyQuery, ListKeyIncidentsForCompetitionAndSurveyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListKeyIncidentsForCompetitionAndSurveyQuery, ListKeyIncidentsForCompetitionAndSurveyQueryVariables>(ListKeyIncidentsForCompetitionAndSurveyDocument, options);
      }
export function useListKeyIncidentsForCompetitionAndSurveyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListKeyIncidentsForCompetitionAndSurveyQuery, ListKeyIncidentsForCompetitionAndSurveyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListKeyIncidentsForCompetitionAndSurveyQuery, ListKeyIncidentsForCompetitionAndSurveyQueryVariables>(ListKeyIncidentsForCompetitionAndSurveyDocument, options);
        }
export type ListKeyIncidentsForCompetitionAndSurveyQueryHookResult = ReturnType<typeof useListKeyIncidentsForCompetitionAndSurveyQuery>;
export type ListKeyIncidentsForCompetitionAndSurveyLazyQueryHookResult = ReturnType<typeof useListKeyIncidentsForCompetitionAndSurveyLazyQuery>;
export type ListKeyIncidentsForCompetitionAndSurveyQueryResult = Apollo.QueryResult<ListKeyIncidentsForCompetitionAndSurveyQuery, ListKeyIncidentsForCompetitionAndSurveyQueryVariables>;
export const ListKeyIncidentSummariesForOrgDocument = gql`
    query listKeyIncidentSummariesForOrg($offset: Int!, $limit: Int!, $orgId: String!, $leagueId: String, $seasonId: String, $teamSearch: String, $submittedBySearch: String, $competitionDateFrom: String, $competitionDateTo: String, $statuses: [KeyIncidentStatus!], $impactedOutcome: Boolean, $videoStatuses: [KeyIncidentVideoStatus!], $lastUpdatedDateFrom: String, $lastUpdatedDateTo: String) {
  listKeyIncidentSummariesForOrg(
    input: {orgId: $orgId, filters: {leagueId: $leagueId, seasonId: $seasonId, teamSearch: $teamSearch, submittedBySearch: $submittedBySearch, competitionDateFrom: $competitionDateFrom, competitionDateTo: $competitionDateTo, statuses: $statuses, videoStatuses: $videoStatuses, impactedOutcome: $impactedOutcome, lastUpdatedDateFrom: $lastUpdatedDateFrom, lastUpdatedDateTo: $lastUpdatedDateTo}, pagination: {offset: $offset, limit: $limit}}
  ) {
    keyIncidents {
      id
      organizationId
      organizationName
      leagueId
      leagueName
      seasonId
      seasonName
      competitionId
      competitionExternalId
      startTime
      timeZone
      homeTeamId
      homeTeamName
      homeTeamLogo
      homeTeamScore
      homeTeamTieBreakerScore
      awayTeamId
      awayTeamScore
      awayTeamTieBreakerScore
      awayTeamName
      awayTeamLogo
      type
      status
      impactedOutcome
      description
      time
      createdAt
      updatedAt
      submittedBy
      submittedByAssessmentObjectName
      submittedByTeam
      reviewStartedBy
      resolvedBy
      officialName
      teamName
      coachName
      assessmentObjectName
      resolvedAt
      reopenedAt
      videoStatus
      videoError
      reviewStartedAt
      messageCount
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListKeyIncidentSummariesForOrgQuery__
 *
 * To run a query within a React component, call `useListKeyIncidentSummariesForOrgQuery` and pass it any options that fit your needs.
 * When your component renders, `useListKeyIncidentSummariesForOrgQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListKeyIncidentSummariesForOrgQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      orgId: // value for 'orgId'
 *      leagueId: // value for 'leagueId'
 *      seasonId: // value for 'seasonId'
 *      teamSearch: // value for 'teamSearch'
 *      submittedBySearch: // value for 'submittedBySearch'
 *      competitionDateFrom: // value for 'competitionDateFrom'
 *      competitionDateTo: // value for 'competitionDateTo'
 *      statuses: // value for 'statuses'
 *      impactedOutcome: // value for 'impactedOutcome'
 *      videoStatuses: // value for 'videoStatuses'
 *      lastUpdatedDateFrom: // value for 'lastUpdatedDateFrom'
 *      lastUpdatedDateTo: // value for 'lastUpdatedDateTo'
 *   },
 * });
 */
export function useListKeyIncidentSummariesForOrgQuery(baseOptions: Apollo.QueryHookOptions<ListKeyIncidentSummariesForOrgQuery, ListKeyIncidentSummariesForOrgQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListKeyIncidentSummariesForOrgQuery, ListKeyIncidentSummariesForOrgQueryVariables>(ListKeyIncidentSummariesForOrgDocument, options);
      }
export function useListKeyIncidentSummariesForOrgLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListKeyIncidentSummariesForOrgQuery, ListKeyIncidentSummariesForOrgQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListKeyIncidentSummariesForOrgQuery, ListKeyIncidentSummariesForOrgQueryVariables>(ListKeyIncidentSummariesForOrgDocument, options);
        }
export type ListKeyIncidentSummariesForOrgQueryHookResult = ReturnType<typeof useListKeyIncidentSummariesForOrgQuery>;
export type ListKeyIncidentSummariesForOrgLazyQueryHookResult = ReturnType<typeof useListKeyIncidentSummariesForOrgLazyQuery>;
export type ListKeyIncidentSummariesForOrgQueryResult = Apollo.QueryResult<ListKeyIncidentSummariesForOrgQuery, ListKeyIncidentSummariesForOrgQueryVariables>;
export const ListKeyIncidentSummariesForOrgOfficialDocument = gql`
    query listKeyIncidentSummariesForOrgOfficial($offset: Int!, $limit: Int!, $orgId: String!, $officialId: String!, $leagueId: String, $seasonId: String, $teamSearch: String, $submittedBySearch: String, $competitionDateFrom: String, $competitionDateTo: String, $statuses: [KeyIncidentStatus!], $impactedOutcome: Boolean, $videoStatuses: [KeyIncidentVideoStatus!], $lastUpdatedDateFrom: String, $lastUpdatedDateTo: String) {
  listKeyIncidentSummariesForOrgOfficial(
    input: {officialId: $officialId, orgId: $orgId, leagueId: $leagueId, seasonId: $seasonId, filters: {teamSearch: $teamSearch, submittedBySearch: $submittedBySearch, competitionDateFrom: $competitionDateFrom, competitionDateTo: $competitionDateTo, statuses: $statuses, videoStatuses: $videoStatuses, impactedOutcome: $impactedOutcome, lastUpdatedDateFrom: $lastUpdatedDateFrom, lastUpdatedDateTo: $lastUpdatedDateTo}, pagination: {offset: $offset, limit: $limit}}
  ) {
    keyIncidents {
      id
      organizationId
      organizationName
      leagueId
      leagueName
      seasonId
      seasonName
      competitionId
      competitionExternalId
      startTime
      timeZone
      homeTeamId
      homeTeamName
      homeTeamLogo
      homeTeamScore
      homeTeamTieBreakerScore
      awayTeamId
      awayTeamScore
      awayTeamTieBreakerScore
      awayTeamName
      awayTeamLogo
      type
      status
      impactedOutcome
      description
      time
      createdAt
      updatedAt
      submittedBy
      submittedByAssessmentObjectName
      submittedByTeam
      reviewStartedBy
      resolvedBy
      officialName
      teamName
      coachName
      assessmentObjectName
      resolvedAt
      reopenedAt
      videoStatus
      videoError
      reviewStartedAt
      messageCount
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListKeyIncidentSummariesForOrgOfficialQuery__
 *
 * To run a query within a React component, call `useListKeyIncidentSummariesForOrgOfficialQuery` and pass it any options that fit your needs.
 * When your component renders, `useListKeyIncidentSummariesForOrgOfficialQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListKeyIncidentSummariesForOrgOfficialQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      orgId: // value for 'orgId'
 *      officialId: // value for 'officialId'
 *      leagueId: // value for 'leagueId'
 *      seasonId: // value for 'seasonId'
 *      teamSearch: // value for 'teamSearch'
 *      submittedBySearch: // value for 'submittedBySearch'
 *      competitionDateFrom: // value for 'competitionDateFrom'
 *      competitionDateTo: // value for 'competitionDateTo'
 *      statuses: // value for 'statuses'
 *      impactedOutcome: // value for 'impactedOutcome'
 *      videoStatuses: // value for 'videoStatuses'
 *      lastUpdatedDateFrom: // value for 'lastUpdatedDateFrom'
 *      lastUpdatedDateTo: // value for 'lastUpdatedDateTo'
 *   },
 * });
 */
export function useListKeyIncidentSummariesForOrgOfficialQuery(baseOptions: Apollo.QueryHookOptions<ListKeyIncidentSummariesForOrgOfficialQuery, ListKeyIncidentSummariesForOrgOfficialQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListKeyIncidentSummariesForOrgOfficialQuery, ListKeyIncidentSummariesForOrgOfficialQueryVariables>(ListKeyIncidentSummariesForOrgOfficialDocument, options);
      }
export function useListKeyIncidentSummariesForOrgOfficialLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListKeyIncidentSummariesForOrgOfficialQuery, ListKeyIncidentSummariesForOrgOfficialQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListKeyIncidentSummariesForOrgOfficialQuery, ListKeyIncidentSummariesForOrgOfficialQueryVariables>(ListKeyIncidentSummariesForOrgOfficialDocument, options);
        }
export type ListKeyIncidentSummariesForOrgOfficialQueryHookResult = ReturnType<typeof useListKeyIncidentSummariesForOrgOfficialQuery>;
export type ListKeyIncidentSummariesForOrgOfficialLazyQueryHookResult = ReturnType<typeof useListKeyIncidentSummariesForOrgOfficialLazyQuery>;
export type ListKeyIncidentSummariesForOrgOfficialQueryResult = Apollo.QueryResult<ListKeyIncidentSummariesForOrgOfficialQuery, ListKeyIncidentSummariesForOrgOfficialQueryVariables>;
export const ListKeyIncidentSummariesForUserDocument = gql`
    query listKeyIncidentSummariesForUser($offset: Int!, $limit: Int!, $leagueId: String, $seasonId: String, $teamSearch: String, $submittedBySearch: String, $competitionDateFrom: String, $competitionDateTo: String, $statuses: [KeyIncidentStatus!], $impactedOutcome: Boolean, $videoStatuses: [KeyIncidentVideoStatus!], $lastUpdatedDateFrom: String, $lastUpdatedDateTo: String) {
  listKeyIncidentSummariesForUser(
    input: {filters: {leagueId: $leagueId, seasonId: $seasonId, teamSearch: $teamSearch, submittedBySearch: $submittedBySearch, competitionDateFrom: $competitionDateFrom, competitionDateTo: $competitionDateTo, statuses: $statuses, videoStatuses: $videoStatuses, impactedOutcome: $impactedOutcome, lastUpdatedDateFrom: $lastUpdatedDateFrom, lastUpdatedDateTo: $lastUpdatedDateTo}, pagination: {offset: $offset, limit: $limit}}
  ) {
    keyIncidents {
      id
      organizationId
      organizationName
      leagueId
      leagueName
      seasonId
      seasonName
      competitionId
      competitionExternalId
      startTime
      timeZone
      homeTeamId
      homeTeamName
      homeTeamLogo
      homeTeamScore
      homeTeamTieBreakerScore
      awayTeamId
      awayTeamScore
      awayTeamTieBreakerScore
      awayTeamName
      awayTeamLogo
      type
      status
      impactedOutcome
      description
      time
      createdAt
      updatedAt
      submittedBy
      submittedByAssessmentObjectName
      submittedByTeam
      reviewStartedBy
      resolvedBy
      officialName
      teamName
      coachName
      assessmentObjectName
      resolvedAt
      reopenedAt
      videoStatus
      videoError
      reviewStartedAt
      messageCount
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListKeyIncidentSummariesForUserQuery__
 *
 * To run a query within a React component, call `useListKeyIncidentSummariesForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useListKeyIncidentSummariesForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListKeyIncidentSummariesForUserQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      leagueId: // value for 'leagueId'
 *      seasonId: // value for 'seasonId'
 *      teamSearch: // value for 'teamSearch'
 *      submittedBySearch: // value for 'submittedBySearch'
 *      competitionDateFrom: // value for 'competitionDateFrom'
 *      competitionDateTo: // value for 'competitionDateTo'
 *      statuses: // value for 'statuses'
 *      impactedOutcome: // value for 'impactedOutcome'
 *      videoStatuses: // value for 'videoStatuses'
 *      lastUpdatedDateFrom: // value for 'lastUpdatedDateFrom'
 *      lastUpdatedDateTo: // value for 'lastUpdatedDateTo'
 *   },
 * });
 */
export function useListKeyIncidentSummariesForUserQuery(baseOptions: Apollo.QueryHookOptions<ListKeyIncidentSummariesForUserQuery, ListKeyIncidentSummariesForUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListKeyIncidentSummariesForUserQuery, ListKeyIncidentSummariesForUserQueryVariables>(ListKeyIncidentSummariesForUserDocument, options);
      }
export function useListKeyIncidentSummariesForUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListKeyIncidentSummariesForUserQuery, ListKeyIncidentSummariesForUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListKeyIncidentSummariesForUserQuery, ListKeyIncidentSummariesForUserQueryVariables>(ListKeyIncidentSummariesForUserDocument, options);
        }
export type ListKeyIncidentSummariesForUserQueryHookResult = ReturnType<typeof useListKeyIncidentSummariesForUserQuery>;
export type ListKeyIncidentSummariesForUserLazyQueryHookResult = ReturnType<typeof useListKeyIncidentSummariesForUserLazyQuery>;
export type ListKeyIncidentSummariesForUserQueryResult = Apollo.QueryResult<ListKeyIncidentSummariesForUserQuery, ListKeyIncidentSummariesForUserQueryVariables>;
export const BeginReviewForKeyIncidentDocument = gql`
    mutation beginReviewForKeyIncident($keyIncidentId: String!) {
  beginReviewForKeyIncident(input: {keyIncidentId: $keyIncidentId}) {
    id
    competitionSurveyId
    dynamicCompetitionSurveyId
    submittedBy {
      userId
      email
      firstName
      lastName
      profilePic
      teamId
      teamName
      teamLogo
      officialId
      assessmentObjectId
      assessmentObjectName
    }
    reviewStartedBy {
      userId
      email
      firstName
      lastName
      profilePic
      title
      organizationName
      organizationAbbreviation
    }
    resolvedBy {
      userId
      email
      firstName
      lastName
      profilePic
      title
      organizationName
      organizationAbbreviation
    }
    competitionInfo {
      competitionId
      externalId
      sport
      homeTeamId
      homeTeamName
      homeTeamScore
      homeTeamTieBreakerScore
      awayTeamId
      awayTeamName
      awayTeamScore
      awayTeamTieBreakerScore
      startTime
      timeZone
      location
      outcome
      verificationStatus
    }
    assessmentObject {
      id
      sport
      type
      classification
      name
      createdAt
      updatedAt
    }
    official {
      id
      sport
      userId
      status
      email
      firstName
      lastName
      profilePic
      createdAt
      updatedAt
    }
    team {
      id
      name
      abbreviation
      sport
      city
      stateAbbreviation
      countryCode
      classification
      gender
      parentOrganizationName
      externalId
      ageGroup
      timeZone
      website
      logo
      createdAt
      updatedAt
    }
    organizationId
    organizationName
    leagueId
    leagueName
    seasonId
    seasonName
    competitionSurveyFeedbackId
    dynamicCompetitionSurveyFeedbackId
    dynamicCompetitionSurveySubFeedbackId
    type
    status
    description
    time
    videoStatus
    videoUrl
    videoError
    videoIntegrationRetryCount
    period {
      id
      sport
      name
    }
    impactedOutcome
    resolvedDate
    reopenedDate
    createdAt
    updatedAt
    reviewStartedAt
  }
}
    `;
export type BeginReviewForKeyIncidentMutationFn = Apollo.MutationFunction<BeginReviewForKeyIncidentMutation, BeginReviewForKeyIncidentMutationVariables>;

/**
 * __useBeginReviewForKeyIncidentMutation__
 *
 * To run a mutation, you first call `useBeginReviewForKeyIncidentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBeginReviewForKeyIncidentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [beginReviewForKeyIncidentMutation, { data, loading, error }] = useBeginReviewForKeyIncidentMutation({
 *   variables: {
 *      keyIncidentId: // value for 'keyIncidentId'
 *   },
 * });
 */
export function useBeginReviewForKeyIncidentMutation(baseOptions?: Apollo.MutationHookOptions<BeginReviewForKeyIncidentMutation, BeginReviewForKeyIncidentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BeginReviewForKeyIncidentMutation, BeginReviewForKeyIncidentMutationVariables>(BeginReviewForKeyIncidentDocument, options);
      }
export type BeginReviewForKeyIncidentMutationHookResult = ReturnType<typeof useBeginReviewForKeyIncidentMutation>;
export type BeginReviewForKeyIncidentMutationResult = Apollo.MutationResult<BeginReviewForKeyIncidentMutation>;
export type BeginReviewForKeyIncidentMutationOptions = Apollo.BaseMutationOptions<BeginReviewForKeyIncidentMutation, BeginReviewForKeyIncidentMutationVariables>;
export const ResolveKeyIncidentDocument = gql`
    mutation resolveKeyIncident($keyIncidentId: String!, $comment: String!) {
  resolveKeyIncident(input: {keyIncidentId: $keyIncidentId, comment: $comment}) {
    message {
      id
      keyIncidentId
      author {
        id
        userId
        email
        firstName
        lastName
        profilePic
        authorType
        teamId
        teamName
        teamLogo
        title
        organizationName
        organizationAbbreviation
      }
      message
      createdAt
      updatedAt
      editedAt
    }
    keyIncident {
      id
      competitionSurveyId
      dynamicCompetitionSurveyId
      submittedBy {
        userId
        email
        firstName
        lastName
        profilePic
        teamId
        teamName
        teamLogo
        officialId
        assessmentObjectId
        assessmentObjectName
      }
      reviewStartedBy {
        userId
        email
        firstName
        lastName
        profilePic
        title
        organizationName
        organizationAbbreviation
      }
      resolvedBy {
        userId
        email
        firstName
        lastName
        profilePic
        title
        organizationName
        organizationAbbreviation
      }
      competitionInfo {
        competitionId
        externalId
        sport
        homeTeamId
        homeTeamName
        homeTeamScore
        homeTeamTieBreakerScore
        awayTeamId
        awayTeamName
        awayTeamScore
        awayTeamTieBreakerScore
        startTime
        timeZone
        location
        outcome
        verificationStatus
      }
      assessmentObject {
        id
        sport
        type
        classification
        name
        createdAt
        updatedAt
      }
      official {
        id
        sport
        userId
        status
        email
        firstName
        lastName
        profilePic
        createdAt
        updatedAt
      }
      team {
        id
        name
        abbreviation
        sport
        city
        stateAbbreviation
        countryCode
        classification
        gender
        parentOrganizationName
        externalId
        ageGroup
        timeZone
        website
        logo
        createdAt
        updatedAt
      }
      organizationId
      organizationName
      leagueId
      leagueName
      seasonId
      seasonName
      competitionSurveyFeedbackId
      dynamicCompetitionSurveyFeedbackId
      dynamicCompetitionSurveySubFeedbackId
      type
      status
      description
      time
      videoStatus
      videoUrl
      videoError
      videoIntegrationRetryCount
      period {
        id
        sport
        name
      }
      impactedOutcome
      resolvedDate
      reopenedDate
      createdAt
      updatedAt
      reviewStartedAt
    }
  }
}
    `;
export type ResolveKeyIncidentMutationFn = Apollo.MutationFunction<ResolveKeyIncidentMutation, ResolveKeyIncidentMutationVariables>;

/**
 * __useResolveKeyIncidentMutation__
 *
 * To run a mutation, you first call `useResolveKeyIncidentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResolveKeyIncidentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resolveKeyIncidentMutation, { data, loading, error }] = useResolveKeyIncidentMutation({
 *   variables: {
 *      keyIncidentId: // value for 'keyIncidentId'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useResolveKeyIncidentMutation(baseOptions?: Apollo.MutationHookOptions<ResolveKeyIncidentMutation, ResolveKeyIncidentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResolveKeyIncidentMutation, ResolveKeyIncidentMutationVariables>(ResolveKeyIncidentDocument, options);
      }
export type ResolveKeyIncidentMutationHookResult = ReturnType<typeof useResolveKeyIncidentMutation>;
export type ResolveKeyIncidentMutationResult = Apollo.MutationResult<ResolveKeyIncidentMutation>;
export type ResolveKeyIncidentMutationOptions = Apollo.BaseMutationOptions<ResolveKeyIncidentMutation, ResolveKeyIncidentMutationVariables>;
export const ListCommentsForKeyIncidentDocument = gql`
    query listCommentsForKeyIncident($offset: Int!, $limit: Int!, $keyIncidentId: String!) {
  listCommentsForKeyIncident(
    input: {keyIncidentId: $keyIncidentId, pagination: {offset: $offset, limit: $limit}}
  ) {
    messages {
      id
      keyIncidentId
      author {
        id
        userId
        email
        firstName
        lastName
        profilePic
        authorType
        teamId
        teamName
        teamLogo
        title
        organizationName
        organizationAbbreviation
      }
      message
      createdAt
      updatedAt
      editedAt
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListCommentsForKeyIncidentQuery__
 *
 * To run a query within a React component, call `useListCommentsForKeyIncidentQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCommentsForKeyIncidentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCommentsForKeyIncidentQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      keyIncidentId: // value for 'keyIncidentId'
 *   },
 * });
 */
export function useListCommentsForKeyIncidentQuery(baseOptions: Apollo.QueryHookOptions<ListCommentsForKeyIncidentQuery, ListCommentsForKeyIncidentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListCommentsForKeyIncidentQuery, ListCommentsForKeyIncidentQueryVariables>(ListCommentsForKeyIncidentDocument, options);
      }
export function useListCommentsForKeyIncidentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListCommentsForKeyIncidentQuery, ListCommentsForKeyIncidentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListCommentsForKeyIncidentQuery, ListCommentsForKeyIncidentQueryVariables>(ListCommentsForKeyIncidentDocument, options);
        }
export type ListCommentsForKeyIncidentQueryHookResult = ReturnType<typeof useListCommentsForKeyIncidentQuery>;
export type ListCommentsForKeyIncidentLazyQueryHookResult = ReturnType<typeof useListCommentsForKeyIncidentLazyQuery>;
export type ListCommentsForKeyIncidentQueryResult = Apollo.QueryResult<ListCommentsForKeyIncidentQuery, ListCommentsForKeyIncidentQueryVariables>;
export const AddCommentToKeyIncidentDocument = gql`
    mutation addCommentToKeyIncident($keyIncidentId: String!, $comment: String!) {
  addCommentToKeyIncident(
    input: {keyIncidentId: $keyIncidentId, comment: $comment}
  ) {
    id
    keyIncidentId
    author {
      id
      userId
      email
      firstName
      lastName
      profilePic
      authorType
      teamId
      teamName
      teamLogo
      title
      organizationName
      organizationAbbreviation
    }
    message
    createdAt
    updatedAt
    editedAt
  }
}
    `;
export type AddCommentToKeyIncidentMutationFn = Apollo.MutationFunction<AddCommentToKeyIncidentMutation, AddCommentToKeyIncidentMutationVariables>;

/**
 * __useAddCommentToKeyIncidentMutation__
 *
 * To run a mutation, you first call `useAddCommentToKeyIncidentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCommentToKeyIncidentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCommentToKeyIncidentMutation, { data, loading, error }] = useAddCommentToKeyIncidentMutation({
 *   variables: {
 *      keyIncidentId: // value for 'keyIncidentId'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useAddCommentToKeyIncidentMutation(baseOptions?: Apollo.MutationHookOptions<AddCommentToKeyIncidentMutation, AddCommentToKeyIncidentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCommentToKeyIncidentMutation, AddCommentToKeyIncidentMutationVariables>(AddCommentToKeyIncidentDocument, options);
      }
export type AddCommentToKeyIncidentMutationHookResult = ReturnType<typeof useAddCommentToKeyIncidentMutation>;
export type AddCommentToKeyIncidentMutationResult = Apollo.MutationResult<AddCommentToKeyIncidentMutation>;
export type AddCommentToKeyIncidentMutationOptions = Apollo.BaseMutationOptions<AddCommentToKeyIncidentMutation, AddCommentToKeyIncidentMutationVariables>;
export const EditKeyIncidentDocument = gql`
    mutation editKeyIncident($keyIncidentId: String!, $time: String, $periodId: String) {
  editKeyIncident(
    input: {keyIncidentId: $keyIncidentId, time: $time, periodId: $periodId}
  ) {
    id
    competitionSurveyId
    submittedBy {
      userId
      email
      firstName
      lastName
      profilePic
      teamId
      teamName
      teamLogo
    }
    reviewStartedBy {
      userId
      email
      firstName
      lastName
      profilePic
    }
    resolvedBy {
      userId
      email
      firstName
      lastName
      profilePic
    }
    competitionInfo {
      competitionId
      externalId
      sport
      homeTeamId
      homeTeamName
      homeTeamScore
      homeTeamTieBreakerScore
      awayTeamId
      awayTeamName
      awayTeamScore
      awayTeamTieBreakerScore
      startTime
      timeZone
      location
      outcome
      verificationStatus
    }
    assessmentObject {
      id
      sport
      type
      classification
      name
      createdAt
      updatedAt
    }
    official {
      id
      sport
      userId
      status
      email
      firstName
      lastName
      profilePic
      createdAt
      updatedAt
    }
    organizationId
    organizationName
    leagueId
    leagueName
    seasonId
    seasonName
    competitionSurveyFeedbackId
    type
    status
    description
    time
    videoStatus
    videoUrl
    videoError
    videoIntegrationRetryCount
    period {
      id
      sport
      name
      createdAt
      updatedAt
    }
    impactedOutcome
    resolvedDate
    reopenedDate
    createdAt
    updatedAt
    reviewStartedAt
  }
}
    `;
export type EditKeyIncidentMutationFn = Apollo.MutationFunction<EditKeyIncidentMutation, EditKeyIncidentMutationVariables>;

/**
 * __useEditKeyIncidentMutation__
 *
 * To run a mutation, you first call `useEditKeyIncidentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditKeyIncidentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editKeyIncidentMutation, { data, loading, error }] = useEditKeyIncidentMutation({
 *   variables: {
 *      keyIncidentId: // value for 'keyIncidentId'
 *      time: // value for 'time'
 *      periodId: // value for 'periodId'
 *   },
 * });
 */
export function useEditKeyIncidentMutation(baseOptions?: Apollo.MutationHookOptions<EditKeyIncidentMutation, EditKeyIncidentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditKeyIncidentMutation, EditKeyIncidentMutationVariables>(EditKeyIncidentDocument, options);
      }
export type EditKeyIncidentMutationHookResult = ReturnType<typeof useEditKeyIncidentMutation>;
export type EditKeyIncidentMutationResult = Apollo.MutationResult<EditKeyIncidentMutation>;
export type EditKeyIncidentMutationOptions = Apollo.BaseMutationOptions<EditKeyIncidentMutation, EditKeyIncidentMutationVariables>;
export const ListExternalLinksForKeyIncidentDocument = gql`
    query listExternalLinksForKeyIncident($offset: Int!, $limit: Int!, $keyIncidentId: String!, $types: [KeyIncidentExternalLinkTypes!]) {
  listExternalLinksForKeyIncident(
    input: {keyIncidentId: $keyIncidentId, types: $types, pagination: {offset: $offset, limit: $limit}}
  ) {
    externalLinks {
      id
      keyIncidentId
      link
      type
      description
      createdByFirstName
      createdByLastName
      createdAt
      updatedAt
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListExternalLinksForKeyIncidentQuery__
 *
 * To run a query within a React component, call `useListExternalLinksForKeyIncidentQuery` and pass it any options that fit your needs.
 * When your component renders, `useListExternalLinksForKeyIncidentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListExternalLinksForKeyIncidentQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      keyIncidentId: // value for 'keyIncidentId'
 *      types: // value for 'types'
 *   },
 * });
 */
export function useListExternalLinksForKeyIncidentQuery(baseOptions: Apollo.QueryHookOptions<ListExternalLinksForKeyIncidentQuery, ListExternalLinksForKeyIncidentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListExternalLinksForKeyIncidentQuery, ListExternalLinksForKeyIncidentQueryVariables>(ListExternalLinksForKeyIncidentDocument, options);
      }
export function useListExternalLinksForKeyIncidentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListExternalLinksForKeyIncidentQuery, ListExternalLinksForKeyIncidentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListExternalLinksForKeyIncidentQuery, ListExternalLinksForKeyIncidentQueryVariables>(ListExternalLinksForKeyIncidentDocument, options);
        }
export type ListExternalLinksForKeyIncidentQueryHookResult = ReturnType<typeof useListExternalLinksForKeyIncidentQuery>;
export type ListExternalLinksForKeyIncidentLazyQueryHookResult = ReturnType<typeof useListExternalLinksForKeyIncidentLazyQuery>;
export type ListExternalLinksForKeyIncidentQueryResult = Apollo.QueryResult<ListExternalLinksForKeyIncidentQuery, ListExternalLinksForKeyIncidentQueryVariables>;
export const AddExternalLinkToKeyIncidentDocument = gql`
    mutation addExternalLinkToKeyIncident($keyIncidentId: String!, $link: String!, $type: KeyIncidentExternalLinkTypes!, $description: String) {
  addExternalLinkToKeyIncident(
    input: {keyIncidentId: $keyIncidentId, link: $link, type: $type, description: $description}
  ) {
    id
    keyIncidentId
    link
    type
    description
    createdByFirstName
    createdByLastName
    createdAt
    updatedAt
  }
}
    `;
export type AddExternalLinkToKeyIncidentMutationFn = Apollo.MutationFunction<AddExternalLinkToKeyIncidentMutation, AddExternalLinkToKeyIncidentMutationVariables>;

/**
 * __useAddExternalLinkToKeyIncidentMutation__
 *
 * To run a mutation, you first call `useAddExternalLinkToKeyIncidentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddExternalLinkToKeyIncidentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addExternalLinkToKeyIncidentMutation, { data, loading, error }] = useAddExternalLinkToKeyIncidentMutation({
 *   variables: {
 *      keyIncidentId: // value for 'keyIncidentId'
 *      link: // value for 'link'
 *      type: // value for 'type'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useAddExternalLinkToKeyIncidentMutation(baseOptions?: Apollo.MutationHookOptions<AddExternalLinkToKeyIncidentMutation, AddExternalLinkToKeyIncidentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddExternalLinkToKeyIncidentMutation, AddExternalLinkToKeyIncidentMutationVariables>(AddExternalLinkToKeyIncidentDocument, options);
      }
export type AddExternalLinkToKeyIncidentMutationHookResult = ReturnType<typeof useAddExternalLinkToKeyIncidentMutation>;
export type AddExternalLinkToKeyIncidentMutationResult = Apollo.MutationResult<AddExternalLinkToKeyIncidentMutation>;
export type AddExternalLinkToKeyIncidentMutationOptions = Apollo.BaseMutationOptions<AddExternalLinkToKeyIncidentMutation, AddExternalLinkToKeyIncidentMutationVariables>;
export const DeleteKeyIncidentExternalLinkDocument = gql`
    mutation deleteKeyIncidentExternalLink($externalLinkId: String!) {
  deleteKeyIncidentExternalLink(input: {externalLinkId: $externalLinkId})
}
    `;
export type DeleteKeyIncidentExternalLinkMutationFn = Apollo.MutationFunction<DeleteKeyIncidentExternalLinkMutation, DeleteKeyIncidentExternalLinkMutationVariables>;

/**
 * __useDeleteKeyIncidentExternalLinkMutation__
 *
 * To run a mutation, you first call `useDeleteKeyIncidentExternalLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteKeyIncidentExternalLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteKeyIncidentExternalLinkMutation, { data, loading, error }] = useDeleteKeyIncidentExternalLinkMutation({
 *   variables: {
 *      externalLinkId: // value for 'externalLinkId'
 *   },
 * });
 */
export function useDeleteKeyIncidentExternalLinkMutation(baseOptions?: Apollo.MutationHookOptions<DeleteKeyIncidentExternalLinkMutation, DeleteKeyIncidentExternalLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteKeyIncidentExternalLinkMutation, DeleteKeyIncidentExternalLinkMutationVariables>(DeleteKeyIncidentExternalLinkDocument, options);
      }
export type DeleteKeyIncidentExternalLinkMutationHookResult = ReturnType<typeof useDeleteKeyIncidentExternalLinkMutation>;
export type DeleteKeyIncidentExternalLinkMutationResult = Apollo.MutationResult<DeleteKeyIncidentExternalLinkMutation>;
export type DeleteKeyIncidentExternalLinkMutationOptions = Apollo.BaseMutationOptions<DeleteKeyIncidentExternalLinkMutation, DeleteKeyIncidentExternalLinkMutationVariables>;
export const CreateKeyIncidentVideoIntegrationForOrganizationDocument = gql`
    mutation createKeyIncidentVideoIntegrationForOrganization($orgId: String!, $name: String!, $description: String!, $status: KeyIncidentVideoIntegrationStatuses!, $queueName: String!, $enableRetry: Boolean!, $maxRetries: Int, $retryIntervalMinutes: Int) {
  createKeyIncidentVideoIntegrationForOrganization(
    input: {orgId: $orgId, name: $name, description: $description, status: $status, queueName: $queueName, enableRetry: $enableRetry, maxRetries: $maxRetries, retryIntervalMinutes: $retryIntervalMinutes}
  ) {
    id
    orgId
    orgName
    orgAbbreviation
    name
    description
    status
    queueName
    enableRetry
    maxRetries
    retryIntervalMinutes
    additionalOptions {
      id
      name
      value
      createdAt
      updatedAt
    }
    createdAt
    updatedAt
  }
}
    `;
export type CreateKeyIncidentVideoIntegrationForOrganizationMutationFn = Apollo.MutationFunction<CreateKeyIncidentVideoIntegrationForOrganizationMutation, CreateKeyIncidentVideoIntegrationForOrganizationMutationVariables>;

/**
 * __useCreateKeyIncidentVideoIntegrationForOrganizationMutation__
 *
 * To run a mutation, you first call `useCreateKeyIncidentVideoIntegrationForOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateKeyIncidentVideoIntegrationForOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createKeyIncidentVideoIntegrationForOrganizationMutation, { data, loading, error }] = useCreateKeyIncidentVideoIntegrationForOrganizationMutation({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      status: // value for 'status'
 *      queueName: // value for 'queueName'
 *      enableRetry: // value for 'enableRetry'
 *      maxRetries: // value for 'maxRetries'
 *      retryIntervalMinutes: // value for 'retryIntervalMinutes'
 *   },
 * });
 */
export function useCreateKeyIncidentVideoIntegrationForOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<CreateKeyIncidentVideoIntegrationForOrganizationMutation, CreateKeyIncidentVideoIntegrationForOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateKeyIncidentVideoIntegrationForOrganizationMutation, CreateKeyIncidentVideoIntegrationForOrganizationMutationVariables>(CreateKeyIncidentVideoIntegrationForOrganizationDocument, options);
      }
export type CreateKeyIncidentVideoIntegrationForOrganizationMutationHookResult = ReturnType<typeof useCreateKeyIncidentVideoIntegrationForOrganizationMutation>;
export type CreateKeyIncidentVideoIntegrationForOrganizationMutationResult = Apollo.MutationResult<CreateKeyIncidentVideoIntegrationForOrganizationMutation>;
export type CreateKeyIncidentVideoIntegrationForOrganizationMutationOptions = Apollo.BaseMutationOptions<CreateKeyIncidentVideoIntegrationForOrganizationMutation, CreateKeyIncidentVideoIntegrationForOrganizationMutationVariables>;
export const GetKeyIncidentVideoIntegrationByIdDocument = gql`
    query getKeyIncidentVideoIntegrationById($id: String!) {
  getKeyIncidentVideoIntegrationById(input: {id: $id}) {
    id
    orgId
    orgName
    orgAbbreviation
    name
    description
    status
    queueName
    enableRetry
    maxRetries
    retryIntervalMinutes
    additionalOptions {
      id
      name
      value
      createdAt
      updatedAt
    }
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetKeyIncidentVideoIntegrationByIdQuery__
 *
 * To run a query within a React component, call `useGetKeyIncidentVideoIntegrationByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKeyIncidentVideoIntegrationByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKeyIncidentVideoIntegrationByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetKeyIncidentVideoIntegrationByIdQuery(baseOptions: Apollo.QueryHookOptions<GetKeyIncidentVideoIntegrationByIdQuery, GetKeyIncidentVideoIntegrationByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetKeyIncidentVideoIntegrationByIdQuery, GetKeyIncidentVideoIntegrationByIdQueryVariables>(GetKeyIncidentVideoIntegrationByIdDocument, options);
      }
export function useGetKeyIncidentVideoIntegrationByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetKeyIncidentVideoIntegrationByIdQuery, GetKeyIncidentVideoIntegrationByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetKeyIncidentVideoIntegrationByIdQuery, GetKeyIncidentVideoIntegrationByIdQueryVariables>(GetKeyIncidentVideoIntegrationByIdDocument, options);
        }
export type GetKeyIncidentVideoIntegrationByIdQueryHookResult = ReturnType<typeof useGetKeyIncidentVideoIntegrationByIdQuery>;
export type GetKeyIncidentVideoIntegrationByIdLazyQueryHookResult = ReturnType<typeof useGetKeyIncidentVideoIntegrationByIdLazyQuery>;
export type GetKeyIncidentVideoIntegrationByIdQueryResult = Apollo.QueryResult<GetKeyIncidentVideoIntegrationByIdQuery, GetKeyIncidentVideoIntegrationByIdQueryVariables>;
export const ListKeyIncidentVideoIntegrationsDocument = gql`
    query listKeyIncidentVideoIntegrations($offset: Int!, $limit: Int!, $orgId: String, $orgName: String, $name: String, $queueName: String, $status: KeyIncidentVideoIntegrationStatuses) {
  listKeyIncidentVideoIntegrations(
    input: {orgId: $orgId, orgName: $orgName, name: $name, queueName: $queueName, status: $status, pagination: {offset: $offset, limit: $limit}}
  ) {
    integrations {
      id
      orgId
      orgName
      orgAbbreviation
      name
      description
      status
      queueName
      enableRetry
      maxRetries
      retryIntervalMinutes
      additionalOptions {
        id
        name
        value
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListKeyIncidentVideoIntegrationsQuery__
 *
 * To run a query within a React component, call `useListKeyIncidentVideoIntegrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListKeyIncidentVideoIntegrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListKeyIncidentVideoIntegrationsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      orgId: // value for 'orgId'
 *      orgName: // value for 'orgName'
 *      name: // value for 'name'
 *      queueName: // value for 'queueName'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useListKeyIncidentVideoIntegrationsQuery(baseOptions: Apollo.QueryHookOptions<ListKeyIncidentVideoIntegrationsQuery, ListKeyIncidentVideoIntegrationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListKeyIncidentVideoIntegrationsQuery, ListKeyIncidentVideoIntegrationsQueryVariables>(ListKeyIncidentVideoIntegrationsDocument, options);
      }
export function useListKeyIncidentVideoIntegrationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListKeyIncidentVideoIntegrationsQuery, ListKeyIncidentVideoIntegrationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListKeyIncidentVideoIntegrationsQuery, ListKeyIncidentVideoIntegrationsQueryVariables>(ListKeyIncidentVideoIntegrationsDocument, options);
        }
export type ListKeyIncidentVideoIntegrationsQueryHookResult = ReturnType<typeof useListKeyIncidentVideoIntegrationsQuery>;
export type ListKeyIncidentVideoIntegrationsLazyQueryHookResult = ReturnType<typeof useListKeyIncidentVideoIntegrationsLazyQuery>;
export type ListKeyIncidentVideoIntegrationsQueryResult = Apollo.QueryResult<ListKeyIncidentVideoIntegrationsQuery, ListKeyIncidentVideoIntegrationsQueryVariables>;
export const UpdateKeyIncidentVideoIntegrationDocument = gql`
    mutation updateKeyIncidentVideoIntegration($id: String!, $name: String!, $description: String!, $status: KeyIncidentVideoIntegrationStatuses!, $queueName: String!, $enableRetry: Boolean!, $maxRetries: Int, $retryIntervalMinutes: Int) {
  updateKeyIncidentVideoIntegration(
    input: {id: $id, name: $name, description: $description, status: $status, queueName: $queueName, enableRetry: $enableRetry, maxRetries: $maxRetries, retryIntervalMinutes: $retryIntervalMinutes}
  ) {
    id
    orgId
    orgName
    orgAbbreviation
    name
    description
    status
    queueName
    enableRetry
    maxRetries
    retryIntervalMinutes
    additionalOptions {
      id
      name
      value
      createdAt
      updatedAt
    }
    createdAt
    updatedAt
  }
}
    `;
export type UpdateKeyIncidentVideoIntegrationMutationFn = Apollo.MutationFunction<UpdateKeyIncidentVideoIntegrationMutation, UpdateKeyIncidentVideoIntegrationMutationVariables>;

/**
 * __useUpdateKeyIncidentVideoIntegrationMutation__
 *
 * To run a mutation, you first call `useUpdateKeyIncidentVideoIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateKeyIncidentVideoIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateKeyIncidentVideoIntegrationMutation, { data, loading, error }] = useUpdateKeyIncidentVideoIntegrationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      status: // value for 'status'
 *      queueName: // value for 'queueName'
 *      enableRetry: // value for 'enableRetry'
 *      maxRetries: // value for 'maxRetries'
 *      retryIntervalMinutes: // value for 'retryIntervalMinutes'
 *   },
 * });
 */
export function useUpdateKeyIncidentVideoIntegrationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateKeyIncidentVideoIntegrationMutation, UpdateKeyIncidentVideoIntegrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateKeyIncidentVideoIntegrationMutation, UpdateKeyIncidentVideoIntegrationMutationVariables>(UpdateKeyIncidentVideoIntegrationDocument, options);
      }
export type UpdateKeyIncidentVideoIntegrationMutationHookResult = ReturnType<typeof useUpdateKeyIncidentVideoIntegrationMutation>;
export type UpdateKeyIncidentVideoIntegrationMutationResult = Apollo.MutationResult<UpdateKeyIncidentVideoIntegrationMutation>;
export type UpdateKeyIncidentVideoIntegrationMutationOptions = Apollo.BaseMutationOptions<UpdateKeyIncidentVideoIntegrationMutation, UpdateKeyIncidentVideoIntegrationMutationVariables>;
export const DeleteKeyIncidentVideoIntegrationDocument = gql`
    mutation deleteKeyIncidentVideoIntegration($integrationId: String!) {
  deleteKeyIncidentVideoIntegration(input: {integrationId: $integrationId})
}
    `;
export type DeleteKeyIncidentVideoIntegrationMutationFn = Apollo.MutationFunction<DeleteKeyIncidentVideoIntegrationMutation, DeleteKeyIncidentVideoIntegrationMutationVariables>;

/**
 * __useDeleteKeyIncidentVideoIntegrationMutation__
 *
 * To run a mutation, you first call `useDeleteKeyIncidentVideoIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteKeyIncidentVideoIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteKeyIncidentVideoIntegrationMutation, { data, loading, error }] = useDeleteKeyIncidentVideoIntegrationMutation({
 *   variables: {
 *      integrationId: // value for 'integrationId'
 *   },
 * });
 */
export function useDeleteKeyIncidentVideoIntegrationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteKeyIncidentVideoIntegrationMutation, DeleteKeyIncidentVideoIntegrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteKeyIncidentVideoIntegrationMutation, DeleteKeyIncidentVideoIntegrationMutationVariables>(DeleteKeyIncidentVideoIntegrationDocument, options);
      }
export type DeleteKeyIncidentVideoIntegrationMutationHookResult = ReturnType<typeof useDeleteKeyIncidentVideoIntegrationMutation>;
export type DeleteKeyIncidentVideoIntegrationMutationResult = Apollo.MutationResult<DeleteKeyIncidentVideoIntegrationMutation>;
export type DeleteKeyIncidentVideoIntegrationMutationOptions = Apollo.BaseMutationOptions<DeleteKeyIncidentVideoIntegrationMutation, DeleteKeyIncidentVideoIntegrationMutationVariables>;
export const ListAdditionalOptionsForKeyIncidentVideoIntegrationDocument = gql`
    query listAdditionalOptionsForKeyIncidentVideoIntegration($offset: Int!, $limit: Int!, $integrationId: String!) {
  listAdditionalOptionsForKeyIncidentVideoIntegration(
    input: {integrationId: $integrationId, pagination: {offset: $offset, limit: $limit}}
  ) {
    additionalOptions {
      id
      name
      value
      createdAt
      updatedAt
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListAdditionalOptionsForKeyIncidentVideoIntegrationQuery__
 *
 * To run a query within a React component, call `useListAdditionalOptionsForKeyIncidentVideoIntegrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAdditionalOptionsForKeyIncidentVideoIntegrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAdditionalOptionsForKeyIncidentVideoIntegrationQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      integrationId: // value for 'integrationId'
 *   },
 * });
 */
export function useListAdditionalOptionsForKeyIncidentVideoIntegrationQuery(baseOptions: Apollo.QueryHookOptions<ListAdditionalOptionsForKeyIncidentVideoIntegrationQuery, ListAdditionalOptionsForKeyIncidentVideoIntegrationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAdditionalOptionsForKeyIncidentVideoIntegrationQuery, ListAdditionalOptionsForKeyIncidentVideoIntegrationQueryVariables>(ListAdditionalOptionsForKeyIncidentVideoIntegrationDocument, options);
      }
export function useListAdditionalOptionsForKeyIncidentVideoIntegrationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAdditionalOptionsForKeyIncidentVideoIntegrationQuery, ListAdditionalOptionsForKeyIncidentVideoIntegrationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAdditionalOptionsForKeyIncidentVideoIntegrationQuery, ListAdditionalOptionsForKeyIncidentVideoIntegrationQueryVariables>(ListAdditionalOptionsForKeyIncidentVideoIntegrationDocument, options);
        }
export type ListAdditionalOptionsForKeyIncidentVideoIntegrationQueryHookResult = ReturnType<typeof useListAdditionalOptionsForKeyIncidentVideoIntegrationQuery>;
export type ListAdditionalOptionsForKeyIncidentVideoIntegrationLazyQueryHookResult = ReturnType<typeof useListAdditionalOptionsForKeyIncidentVideoIntegrationLazyQuery>;
export type ListAdditionalOptionsForKeyIncidentVideoIntegrationQueryResult = Apollo.QueryResult<ListAdditionalOptionsForKeyIncidentVideoIntegrationQuery, ListAdditionalOptionsForKeyIncidentVideoIntegrationQueryVariables>;
export const AddAdditionalOptionToKeyIncidentVideoIntegrationDocument = gql`
    mutation addAdditionalOptionToKeyIncidentVideoIntegration($integrationId: String!, $name: String!, $value: String!) {
  addAdditionalOptionToKeyIncidentVideoIntegration(
    input: {integrationId: $integrationId, name: $name, value: $value}
  ) {
    id
    name
    value
    createdAt
    updatedAt
  }
}
    `;
export type AddAdditionalOptionToKeyIncidentVideoIntegrationMutationFn = Apollo.MutationFunction<AddAdditionalOptionToKeyIncidentVideoIntegrationMutation, AddAdditionalOptionToKeyIncidentVideoIntegrationMutationVariables>;

/**
 * __useAddAdditionalOptionToKeyIncidentVideoIntegrationMutation__
 *
 * To run a mutation, you first call `useAddAdditionalOptionToKeyIncidentVideoIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAdditionalOptionToKeyIncidentVideoIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAdditionalOptionToKeyIncidentVideoIntegrationMutation, { data, loading, error }] = useAddAdditionalOptionToKeyIncidentVideoIntegrationMutation({
 *   variables: {
 *      integrationId: // value for 'integrationId'
 *      name: // value for 'name'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useAddAdditionalOptionToKeyIncidentVideoIntegrationMutation(baseOptions?: Apollo.MutationHookOptions<AddAdditionalOptionToKeyIncidentVideoIntegrationMutation, AddAdditionalOptionToKeyIncidentVideoIntegrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddAdditionalOptionToKeyIncidentVideoIntegrationMutation, AddAdditionalOptionToKeyIncidentVideoIntegrationMutationVariables>(AddAdditionalOptionToKeyIncidentVideoIntegrationDocument, options);
      }
export type AddAdditionalOptionToKeyIncidentVideoIntegrationMutationHookResult = ReturnType<typeof useAddAdditionalOptionToKeyIncidentVideoIntegrationMutation>;
export type AddAdditionalOptionToKeyIncidentVideoIntegrationMutationResult = Apollo.MutationResult<AddAdditionalOptionToKeyIncidentVideoIntegrationMutation>;
export type AddAdditionalOptionToKeyIncidentVideoIntegrationMutationOptions = Apollo.BaseMutationOptions<AddAdditionalOptionToKeyIncidentVideoIntegrationMutation, AddAdditionalOptionToKeyIncidentVideoIntegrationMutationVariables>;
export const RemoveAdditionalOptionFromKeyIncidentVideoIntegrationDocument = gql`
    mutation removeAdditionalOptionFromKeyIncidentVideoIntegration($integrationId: String!, $additionalOptionId: String!) {
  removeAdditionalOptionFromKeyIncidentVideoIntegration(
    input: {integrationId: $integrationId, additionalOptionId: $additionalOptionId}
  )
}
    `;
export type RemoveAdditionalOptionFromKeyIncidentVideoIntegrationMutationFn = Apollo.MutationFunction<RemoveAdditionalOptionFromKeyIncidentVideoIntegrationMutation, RemoveAdditionalOptionFromKeyIncidentVideoIntegrationMutationVariables>;

/**
 * __useRemoveAdditionalOptionFromKeyIncidentVideoIntegrationMutation__
 *
 * To run a mutation, you first call `useRemoveAdditionalOptionFromKeyIncidentVideoIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAdditionalOptionFromKeyIncidentVideoIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAdditionalOptionFromKeyIncidentVideoIntegrationMutation, { data, loading, error }] = useRemoveAdditionalOptionFromKeyIncidentVideoIntegrationMutation({
 *   variables: {
 *      integrationId: // value for 'integrationId'
 *      additionalOptionId: // value for 'additionalOptionId'
 *   },
 * });
 */
export function useRemoveAdditionalOptionFromKeyIncidentVideoIntegrationMutation(baseOptions?: Apollo.MutationHookOptions<RemoveAdditionalOptionFromKeyIncidentVideoIntegrationMutation, RemoveAdditionalOptionFromKeyIncidentVideoIntegrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveAdditionalOptionFromKeyIncidentVideoIntegrationMutation, RemoveAdditionalOptionFromKeyIncidentVideoIntegrationMutationVariables>(RemoveAdditionalOptionFromKeyIncidentVideoIntegrationDocument, options);
      }
export type RemoveAdditionalOptionFromKeyIncidentVideoIntegrationMutationHookResult = ReturnType<typeof useRemoveAdditionalOptionFromKeyIncidentVideoIntegrationMutation>;
export type RemoveAdditionalOptionFromKeyIncidentVideoIntegrationMutationResult = Apollo.MutationResult<RemoveAdditionalOptionFromKeyIncidentVideoIntegrationMutation>;
export type RemoveAdditionalOptionFromKeyIncidentVideoIntegrationMutationOptions = Apollo.BaseMutationOptions<RemoveAdditionalOptionFromKeyIncidentVideoIntegrationMutation, RemoveAdditionalOptionFromKeyIncidentVideoIntegrationMutationVariables>;
export const CreateLeagueDocument = gql`
    mutation createLeague($orgId: String!, $name: String!, $description: String, $abbreviation: String, $logo: String) {
  createLeague(
    input: {orgId: $orgId, name: $name, description: $description, abbreviation: $abbreviation, logo: $logo}
  ) {
    id
    orgId
    name
    description
    abbreviation
    logo
    status
    tags {
      id
      leagueId
      tag
    }
    createdAt
    updatedAt
  }
}
    `;
export type CreateLeagueMutationFn = Apollo.MutationFunction<CreateLeagueMutation, CreateLeagueMutationVariables>;

/**
 * __useCreateLeagueMutation__
 *
 * To run a mutation, you first call `useCreateLeagueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLeagueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLeagueMutation, { data, loading, error }] = useCreateLeagueMutation({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      abbreviation: // value for 'abbreviation'
 *      logo: // value for 'logo'
 *   },
 * });
 */
export function useCreateLeagueMutation(baseOptions?: Apollo.MutationHookOptions<CreateLeagueMutation, CreateLeagueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLeagueMutation, CreateLeagueMutationVariables>(CreateLeagueDocument, options);
      }
export type CreateLeagueMutationHookResult = ReturnType<typeof useCreateLeagueMutation>;
export type CreateLeagueMutationResult = Apollo.MutationResult<CreateLeagueMutation>;
export type CreateLeagueMutationOptions = Apollo.BaseMutationOptions<CreateLeagueMutation, CreateLeagueMutationVariables>;
export const GetLeagueByIdDocument = gql`
    query getLeagueById($id: String!) {
  getLeagueById(input: {id: $id}) {
    id
    orgId
    name
    description
    abbreviation
    logo
    status
    tags {
      id
      leagueId
      tag
    }
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetLeagueByIdQuery__
 *
 * To run a query within a React component, call `useGetLeagueByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLeagueByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLeagueByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLeagueByIdQuery(baseOptions: Apollo.QueryHookOptions<GetLeagueByIdQuery, GetLeagueByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLeagueByIdQuery, GetLeagueByIdQueryVariables>(GetLeagueByIdDocument, options);
      }
export function useGetLeagueByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLeagueByIdQuery, GetLeagueByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLeagueByIdQuery, GetLeagueByIdQueryVariables>(GetLeagueByIdDocument, options);
        }
export type GetLeagueByIdQueryHookResult = ReturnType<typeof useGetLeagueByIdQuery>;
export type GetLeagueByIdLazyQueryHookResult = ReturnType<typeof useGetLeagueByIdLazyQuery>;
export type GetLeagueByIdQueryResult = Apollo.QueryResult<GetLeagueByIdQuery, GetLeagueByIdQueryVariables>;
export const ListLeaguesForOrganizationDocument = gql`
    query listLeaguesForOrganization($offset: Int!, $limit: Int!, $orgId: String!, $tags: [String!]!, $status: LeagueStatuses, $name: String) {
  listLeaguesForOrganization(
    input: {orgId: $orgId, tags: $tags, status: $status, name: $name, pagination: {offset: $offset, limit: $limit}}
  ) {
    leagues {
      id
      orgId
      name
      description
      abbreviation
      logo
      status
      tags {
        id
        leagueId
        tag
      }
      createdAt
      updatedAt
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListLeaguesForOrganizationQuery__
 *
 * To run a query within a React component, call `useListLeaguesForOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useListLeaguesForOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListLeaguesForOrganizationQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      orgId: // value for 'orgId'
 *      tags: // value for 'tags'
 *      status: // value for 'status'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useListLeaguesForOrganizationQuery(baseOptions: Apollo.QueryHookOptions<ListLeaguesForOrganizationQuery, ListLeaguesForOrganizationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListLeaguesForOrganizationQuery, ListLeaguesForOrganizationQueryVariables>(ListLeaguesForOrganizationDocument, options);
      }
export function useListLeaguesForOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListLeaguesForOrganizationQuery, ListLeaguesForOrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListLeaguesForOrganizationQuery, ListLeaguesForOrganizationQueryVariables>(ListLeaguesForOrganizationDocument, options);
        }
export type ListLeaguesForOrganizationQueryHookResult = ReturnType<typeof useListLeaguesForOrganizationQuery>;
export type ListLeaguesForOrganizationLazyQueryHookResult = ReturnType<typeof useListLeaguesForOrganizationLazyQuery>;
export type ListLeaguesForOrganizationQueryResult = Apollo.QueryResult<ListLeaguesForOrganizationQuery, ListLeaguesForOrganizationQueryVariables>;
export const DeleteLeagueDocument = gql`
    mutation deleteLeague($id: String!) {
  deleteLeague(input: {id: $id})
}
    `;
export type DeleteLeagueMutationFn = Apollo.MutationFunction<DeleteLeagueMutation, DeleteLeagueMutationVariables>;

/**
 * __useDeleteLeagueMutation__
 *
 * To run a mutation, you first call `useDeleteLeagueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLeagueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLeagueMutation, { data, loading, error }] = useDeleteLeagueMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLeagueMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLeagueMutation, DeleteLeagueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteLeagueMutation, DeleteLeagueMutationVariables>(DeleteLeagueDocument, options);
      }
export type DeleteLeagueMutationHookResult = ReturnType<typeof useDeleteLeagueMutation>;
export type DeleteLeagueMutationResult = Apollo.MutationResult<DeleteLeagueMutation>;
export type DeleteLeagueMutationOptions = Apollo.BaseMutationOptions<DeleteLeagueMutation, DeleteLeagueMutationVariables>;
export const UpdateLeagueDocument = gql`
    mutation updateLeague($id: String!, $name: String!, $description: String, $abbreviation: String, $logo: String) {
  updateLeague(
    input: {id: $id, name: $name, description: $description, abbreviation: $abbreviation, logo: $logo}
  ) {
    id
    orgId
    name
    description
    abbreviation
    logo
    status
    tags {
      id
      leagueId
      tag
    }
    createdAt
    updatedAt
  }
}
    `;
export type UpdateLeagueMutationFn = Apollo.MutationFunction<UpdateLeagueMutation, UpdateLeagueMutationVariables>;

/**
 * __useUpdateLeagueMutation__
 *
 * To run a mutation, you first call `useUpdateLeagueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLeagueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLeagueMutation, { data, loading, error }] = useUpdateLeagueMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      abbreviation: // value for 'abbreviation'
 *      logo: // value for 'logo'
 *   },
 * });
 */
export function useUpdateLeagueMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLeagueMutation, UpdateLeagueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLeagueMutation, UpdateLeagueMutationVariables>(UpdateLeagueDocument, options);
      }
export type UpdateLeagueMutationHookResult = ReturnType<typeof useUpdateLeagueMutation>;
export type UpdateLeagueMutationResult = Apollo.MutationResult<UpdateLeagueMutation>;
export type UpdateLeagueMutationOptions = Apollo.BaseMutationOptions<UpdateLeagueMutation, UpdateLeagueMutationVariables>;
export const AddLeagueTagDocument = gql`
    mutation addLeagueTag($leagueId: String!, $tag: String!) {
  addLeagueTag(input: {leagueId: $leagueId, tag: $tag}) {
    id
    leagueId
    tag
  }
}
    `;
export type AddLeagueTagMutationFn = Apollo.MutationFunction<AddLeagueTagMutation, AddLeagueTagMutationVariables>;

/**
 * __useAddLeagueTagMutation__
 *
 * To run a mutation, you first call `useAddLeagueTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddLeagueTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addLeagueTagMutation, { data, loading, error }] = useAddLeagueTagMutation({
 *   variables: {
 *      leagueId: // value for 'leagueId'
 *      tag: // value for 'tag'
 *   },
 * });
 */
export function useAddLeagueTagMutation(baseOptions?: Apollo.MutationHookOptions<AddLeagueTagMutation, AddLeagueTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddLeagueTagMutation, AddLeagueTagMutationVariables>(AddLeagueTagDocument, options);
      }
export type AddLeagueTagMutationHookResult = ReturnType<typeof useAddLeagueTagMutation>;
export type AddLeagueTagMutationResult = Apollo.MutationResult<AddLeagueTagMutation>;
export type AddLeagueTagMutationOptions = Apollo.BaseMutationOptions<AddLeagueTagMutation, AddLeagueTagMutationVariables>;
export const RemoveLeagueTagDocument = gql`
    mutation removeLeagueTag($tagId: String!) {
  removeLeagueTag(input: {tagId: $tagId})
}
    `;
export type RemoveLeagueTagMutationFn = Apollo.MutationFunction<RemoveLeagueTagMutation, RemoveLeagueTagMutationVariables>;

/**
 * __useRemoveLeagueTagMutation__
 *
 * To run a mutation, you first call `useRemoveLeagueTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveLeagueTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeLeagueTagMutation, { data, loading, error }] = useRemoveLeagueTagMutation({
 *   variables: {
 *      tagId: // value for 'tagId'
 *   },
 * });
 */
export function useRemoveLeagueTagMutation(baseOptions?: Apollo.MutationHookOptions<RemoveLeagueTagMutation, RemoveLeagueTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveLeagueTagMutation, RemoveLeagueTagMutationVariables>(RemoveLeagueTagDocument, options);
      }
export type RemoveLeagueTagMutationHookResult = ReturnType<typeof useRemoveLeagueTagMutation>;
export type RemoveLeagueTagMutationResult = Apollo.MutationResult<RemoveLeagueTagMutation>;
export type RemoveLeagueTagMutationOptions = Apollo.BaseMutationOptions<RemoveLeagueTagMutation, RemoveLeagueTagMutationVariables>;
export const AddLeagueMembershipDocument = gql`
    mutation addLeagueMembership($leagueId: String!, $userId: String!) {
  addLeagueMembership(input: {leagueId: $leagueId, userId: $userId})
}
    `;
export type AddLeagueMembershipMutationFn = Apollo.MutationFunction<AddLeagueMembershipMutation, AddLeagueMembershipMutationVariables>;

/**
 * __useAddLeagueMembershipMutation__
 *
 * To run a mutation, you first call `useAddLeagueMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddLeagueMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addLeagueMembershipMutation, { data, loading, error }] = useAddLeagueMembershipMutation({
 *   variables: {
 *      leagueId: // value for 'leagueId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAddLeagueMembershipMutation(baseOptions?: Apollo.MutationHookOptions<AddLeagueMembershipMutation, AddLeagueMembershipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddLeagueMembershipMutation, AddLeagueMembershipMutationVariables>(AddLeagueMembershipDocument, options);
      }
export type AddLeagueMembershipMutationHookResult = ReturnType<typeof useAddLeagueMembershipMutation>;
export type AddLeagueMembershipMutationResult = Apollo.MutationResult<AddLeagueMembershipMutation>;
export type AddLeagueMembershipMutationOptions = Apollo.BaseMutationOptions<AddLeagueMembershipMutation, AddLeagueMembershipMutationVariables>;
export const RemoveLeagueMembershipDocument = gql`
    mutation removeLeagueMembership($leagueId: String!, $userId: String!) {
  removeLeagueMembership(input: {leagueId: $leagueId, userId: $userId})
}
    `;
export type RemoveLeagueMembershipMutationFn = Apollo.MutationFunction<RemoveLeagueMembershipMutation, RemoveLeagueMembershipMutationVariables>;

/**
 * __useRemoveLeagueMembershipMutation__
 *
 * To run a mutation, you first call `useRemoveLeagueMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveLeagueMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeLeagueMembershipMutation, { data, loading, error }] = useRemoveLeagueMembershipMutation({
 *   variables: {
 *      leagueId: // value for 'leagueId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useRemoveLeagueMembershipMutation(baseOptions?: Apollo.MutationHookOptions<RemoveLeagueMembershipMutation, RemoveLeagueMembershipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveLeagueMembershipMutation, RemoveLeagueMembershipMutationVariables>(RemoveLeagueMembershipDocument, options);
      }
export type RemoveLeagueMembershipMutationHookResult = ReturnType<typeof useRemoveLeagueMembershipMutation>;
export type RemoveLeagueMembershipMutationResult = Apollo.MutationResult<RemoveLeagueMembershipMutation>;
export type RemoveLeagueMembershipMutationOptions = Apollo.BaseMutationOptions<RemoveLeagueMembershipMutation, RemoveLeagueMembershipMutationVariables>;
export const ListLeagueMembershipUsersDocument = gql`
    query listLeagueMembershipUsers($offset: Int!, $limit: Int!, $leagueId: String!, $nameOrEmail: String) {
  listLeagueMembershipUsers(
    input: {leagueId: $leagueId, nameOrEmail: $nameOrEmail, pagination: {offset: $offset, limit: $limit}}
  ) {
    leagueId
    leagueMemberships {
      id
      email
      firstName
      lastName
      isAdmin
      organizationMemberships {
        id
        orgId
        roles
        permissions
        title
        leagueMemberships {
          leagueId
          name
          abbreviation
        }
      }
      status
      createdAt
      updatedAt
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListLeagueMembershipUsersQuery__
 *
 * To run a query within a React component, call `useListLeagueMembershipUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListLeagueMembershipUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListLeagueMembershipUsersQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      leagueId: // value for 'leagueId'
 *      nameOrEmail: // value for 'nameOrEmail'
 *   },
 * });
 */
export function useListLeagueMembershipUsersQuery(baseOptions: Apollo.QueryHookOptions<ListLeagueMembershipUsersQuery, ListLeagueMembershipUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListLeagueMembershipUsersQuery, ListLeagueMembershipUsersQueryVariables>(ListLeagueMembershipUsersDocument, options);
      }
export function useListLeagueMembershipUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListLeagueMembershipUsersQuery, ListLeagueMembershipUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListLeagueMembershipUsersQuery, ListLeagueMembershipUsersQueryVariables>(ListLeagueMembershipUsersDocument, options);
        }
export type ListLeagueMembershipUsersQueryHookResult = ReturnType<typeof useListLeagueMembershipUsersQuery>;
export type ListLeagueMembershipUsersLazyQueryHookResult = ReturnType<typeof useListLeagueMembershipUsersLazyQuery>;
export type ListLeagueMembershipUsersQueryResult = Apollo.QueryResult<ListLeagueMembershipUsersQuery, ListLeagueMembershipUsersQueryVariables>;
export const ListDynamicCompetitionSurveyIntegrationsDocument = gql`
    query listDynamicCompetitionSurveyIntegrations($offset: Int!, $limit: Int!, $orgName: String, $leagueName: String, $seasonName: String, $competitionExternalId: String, $homeTeamName: String, $awayTeamName: String, $fromDate: String, $toDate: String, $status: DynamicCompetitionSurveyIntegrationStatuses) {
  listDynamicCompetitionSurveyIntegrations(
    input: {orgName: $orgName, leagueName: $leagueName, seasonName: $seasonName, competitionExternalId: $competitionExternalId, homeTeamName: $homeTeamName, awayTeamName: $awayTeamName, fromDate: $fromDate, toDate: $toDate, status: $status, pagination: {offset: $offset, limit: $limit}}
  ) {
    integrations {
      id
      organizationId
      organizationName
      leagueId
      leagueName
      seasonId
      seasonName
      surveyName
      surveyAudience
      competitionId
      competitionExternalId
      competitionStartTime
      competitionTimeZone
      homeTeamId
      homeTeamName
      awayTeamId
      awayTeamName
      integrationStatus
      integrationSent
      integrationProcess
      errorCount
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListDynamicCompetitionSurveyIntegrationsQuery__
 *
 * To run a query within a React component, call `useListDynamicCompetitionSurveyIntegrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListDynamicCompetitionSurveyIntegrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListDynamicCompetitionSurveyIntegrationsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      orgName: // value for 'orgName'
 *      leagueName: // value for 'leagueName'
 *      seasonName: // value for 'seasonName'
 *      competitionExternalId: // value for 'competitionExternalId'
 *      homeTeamName: // value for 'homeTeamName'
 *      awayTeamName: // value for 'awayTeamName'
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useListDynamicCompetitionSurveyIntegrationsQuery(baseOptions: Apollo.QueryHookOptions<ListDynamicCompetitionSurveyIntegrationsQuery, ListDynamicCompetitionSurveyIntegrationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListDynamicCompetitionSurveyIntegrationsQuery, ListDynamicCompetitionSurveyIntegrationsQueryVariables>(ListDynamicCompetitionSurveyIntegrationsDocument, options);
      }
export function useListDynamicCompetitionSurveyIntegrationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListDynamicCompetitionSurveyIntegrationsQuery, ListDynamicCompetitionSurveyIntegrationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListDynamicCompetitionSurveyIntegrationsQuery, ListDynamicCompetitionSurveyIntegrationsQueryVariables>(ListDynamicCompetitionSurveyIntegrationsDocument, options);
        }
export type ListDynamicCompetitionSurveyIntegrationsQueryHookResult = ReturnType<typeof useListDynamicCompetitionSurveyIntegrationsQuery>;
export type ListDynamicCompetitionSurveyIntegrationsLazyQueryHookResult = ReturnType<typeof useListDynamicCompetitionSurveyIntegrationsLazyQuery>;
export type ListDynamicCompetitionSurveyIntegrationsQueryResult = Apollo.QueryResult<ListDynamicCompetitionSurveyIntegrationsQuery, ListDynamicCompetitionSurveyIntegrationsQueryVariables>;
export const FindDynamicCompetitionSurveyIntegrationErrorsByUuidDocument = gql`
    query findDynamicCompetitionSurveyIntegrationErrorsByUuid($dynamicCompetitionSurveyId: String!) {
  findDynamicCompetitionSurveyIntegrationErrorsByUuid(
    input: {dynamicCompetitionSurveyId: $dynamicCompetitionSurveyId}
  ) {
    errors {
      id
      survey
      error
      createdAt
      updatedAt
    }
    total
  }
}
    `;

/**
 * __useFindDynamicCompetitionSurveyIntegrationErrorsByUuidQuery__
 *
 * To run a query within a React component, call `useFindDynamicCompetitionSurveyIntegrationErrorsByUuidQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindDynamicCompetitionSurveyIntegrationErrorsByUuidQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindDynamicCompetitionSurveyIntegrationErrorsByUuidQuery({
 *   variables: {
 *      dynamicCompetitionSurveyId: // value for 'dynamicCompetitionSurveyId'
 *   },
 * });
 */
export function useFindDynamicCompetitionSurveyIntegrationErrorsByUuidQuery(baseOptions: Apollo.QueryHookOptions<FindDynamicCompetitionSurveyIntegrationErrorsByUuidQuery, FindDynamicCompetitionSurveyIntegrationErrorsByUuidQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindDynamicCompetitionSurveyIntegrationErrorsByUuidQuery, FindDynamicCompetitionSurveyIntegrationErrorsByUuidQueryVariables>(FindDynamicCompetitionSurveyIntegrationErrorsByUuidDocument, options);
      }
export function useFindDynamicCompetitionSurveyIntegrationErrorsByUuidLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindDynamicCompetitionSurveyIntegrationErrorsByUuidQuery, FindDynamicCompetitionSurveyIntegrationErrorsByUuidQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindDynamicCompetitionSurveyIntegrationErrorsByUuidQuery, FindDynamicCompetitionSurveyIntegrationErrorsByUuidQueryVariables>(FindDynamicCompetitionSurveyIntegrationErrorsByUuidDocument, options);
        }
export type FindDynamicCompetitionSurveyIntegrationErrorsByUuidQueryHookResult = ReturnType<typeof useFindDynamicCompetitionSurveyIntegrationErrorsByUuidQuery>;
export type FindDynamicCompetitionSurveyIntegrationErrorsByUuidLazyQueryHookResult = ReturnType<typeof useFindDynamicCompetitionSurveyIntegrationErrorsByUuidLazyQuery>;
export type FindDynamicCompetitionSurveyIntegrationErrorsByUuidQueryResult = Apollo.QueryResult<FindDynamicCompetitionSurveyIntegrationErrorsByUuidQuery, FindDynamicCompetitionSurveyIntegrationErrorsByUuidQueryVariables>;
export const RetryDynamicCompetitionIntegrationSurveyDocument = gql`
    mutation retryDynamicCompetitionIntegrationSurvey($dynamicCompetitionSurveyId: String!) {
  retryDynamicCompetitionIntegrationSurvey(
    input: {dynamicCompetitionSurveyId: $dynamicCompetitionSurveyId}
  )
}
    `;
export type RetryDynamicCompetitionIntegrationSurveyMutationFn = Apollo.MutationFunction<RetryDynamicCompetitionIntegrationSurveyMutation, RetryDynamicCompetitionIntegrationSurveyMutationVariables>;

/**
 * __useRetryDynamicCompetitionIntegrationSurveyMutation__
 *
 * To run a mutation, you first call `useRetryDynamicCompetitionIntegrationSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRetryDynamicCompetitionIntegrationSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [retryDynamicCompetitionIntegrationSurveyMutation, { data, loading, error }] = useRetryDynamicCompetitionIntegrationSurveyMutation({
 *   variables: {
 *      dynamicCompetitionSurveyId: // value for 'dynamicCompetitionSurveyId'
 *   },
 * });
 */
export function useRetryDynamicCompetitionIntegrationSurveyMutation(baseOptions?: Apollo.MutationHookOptions<RetryDynamicCompetitionIntegrationSurveyMutation, RetryDynamicCompetitionIntegrationSurveyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RetryDynamicCompetitionIntegrationSurveyMutation, RetryDynamicCompetitionIntegrationSurveyMutationVariables>(RetryDynamicCompetitionIntegrationSurveyDocument, options);
      }
export type RetryDynamicCompetitionIntegrationSurveyMutationHookResult = ReturnType<typeof useRetryDynamicCompetitionIntegrationSurveyMutation>;
export type RetryDynamicCompetitionIntegrationSurveyMutationResult = Apollo.MutationResult<RetryDynamicCompetitionIntegrationSurveyMutation>;
export type RetryDynamicCompetitionIntegrationSurveyMutationOptions = Apollo.BaseMutationOptions<RetryDynamicCompetitionIntegrationSurveyMutation, RetryDynamicCompetitionIntegrationSurveyMutationVariables>;
export const ListCompetitionIntegrationRequestsDocument = gql`
    query listCompetitionIntegrationRequests($offset: Int!, $limit: Int!, $orgName: String, $leagueName: String, $seasonName: String, $fromDate: Date, $toDate: Date, $integrationSearch: String, $statuses: [CompetitionIntegrationRequestStatuses!]) {
  listCompetitionIntegrationRequests(
    input: {orgName: $orgName, leagueName: $leagueName, seasonName: $seasonName, fromDate: $fromDate, toDate: $toDate, integrationSearch: $integrationSearch, statuses: $statuses, pagination: {offset: $offset, limit: $limit}}
  ) {
    requests {
      id
      seasonId
      seasonName
      seasonIntegrationId
      orgName
      leagueName
      integrationId
      integrationName
      status
      error
      totalCount
      processedCount
      errorCount
      successCount
      createdAt
      updatedAt
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListCompetitionIntegrationRequestsQuery__
 *
 * To run a query within a React component, call `useListCompetitionIntegrationRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCompetitionIntegrationRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCompetitionIntegrationRequestsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      orgName: // value for 'orgName'
 *      leagueName: // value for 'leagueName'
 *      seasonName: // value for 'seasonName'
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *      integrationSearch: // value for 'integrationSearch'
 *      statuses: // value for 'statuses'
 *   },
 * });
 */
export function useListCompetitionIntegrationRequestsQuery(baseOptions: Apollo.QueryHookOptions<ListCompetitionIntegrationRequestsQuery, ListCompetitionIntegrationRequestsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListCompetitionIntegrationRequestsQuery, ListCompetitionIntegrationRequestsQueryVariables>(ListCompetitionIntegrationRequestsDocument, options);
      }
export function useListCompetitionIntegrationRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListCompetitionIntegrationRequestsQuery, ListCompetitionIntegrationRequestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListCompetitionIntegrationRequestsQuery, ListCompetitionIntegrationRequestsQueryVariables>(ListCompetitionIntegrationRequestsDocument, options);
        }
export type ListCompetitionIntegrationRequestsQueryHookResult = ReturnType<typeof useListCompetitionIntegrationRequestsQuery>;
export type ListCompetitionIntegrationRequestsLazyQueryHookResult = ReturnType<typeof useListCompetitionIntegrationRequestsLazyQuery>;
export type ListCompetitionIntegrationRequestsQueryResult = Apollo.QueryResult<ListCompetitionIntegrationRequestsQuery, ListCompetitionIntegrationRequestsQueryVariables>;
export const CreateMediaOrganizationDocument = gql`
    mutation createMediaOrganization($name: String!, $abbreviation: String, $site: String, $logoUrl: String) {
  createMediaOrganization(
    input: {name: $name, abbreviation: $abbreviation, site: $site, logoUrl: $logoUrl}
  ) {
    id
    name
    status
    abbreviation
    site
    logo
    createdAt
    updatedAt
  }
}
    `;
export type CreateMediaOrganizationMutationFn = Apollo.MutationFunction<CreateMediaOrganizationMutation, CreateMediaOrganizationMutationVariables>;

/**
 * __useCreateMediaOrganizationMutation__
 *
 * To run a mutation, you first call `useCreateMediaOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMediaOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMediaOrganizationMutation, { data, loading, error }] = useCreateMediaOrganizationMutation({
 *   variables: {
 *      name: // value for 'name'
 *      abbreviation: // value for 'abbreviation'
 *      site: // value for 'site'
 *      logoUrl: // value for 'logoUrl'
 *   },
 * });
 */
export function useCreateMediaOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<CreateMediaOrganizationMutation, CreateMediaOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMediaOrganizationMutation, CreateMediaOrganizationMutationVariables>(CreateMediaOrganizationDocument, options);
      }
export type CreateMediaOrganizationMutationHookResult = ReturnType<typeof useCreateMediaOrganizationMutation>;
export type CreateMediaOrganizationMutationResult = Apollo.MutationResult<CreateMediaOrganizationMutation>;
export type CreateMediaOrganizationMutationOptions = Apollo.BaseMutationOptions<CreateMediaOrganizationMutation, CreateMediaOrganizationMutationVariables>;
export const UpdateMediaOrganizationDocument = gql`
    mutation updateMediaOrganization($id: String!, $name: String!, $abbreviation: String, $site: String, $logoUrl: String) {
  updateMediaOrganization(
    input: {id: $id, name: $name, abbreviation: $abbreviation, site: $site, logoUrl: $logoUrl}
  ) {
    id
    name
    status
    abbreviation
    site
    logo
    createdAt
    updatedAt
  }
}
    `;
export type UpdateMediaOrganizationMutationFn = Apollo.MutationFunction<UpdateMediaOrganizationMutation, UpdateMediaOrganizationMutationVariables>;

/**
 * __useUpdateMediaOrganizationMutation__
 *
 * To run a mutation, you first call `useUpdateMediaOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMediaOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMediaOrganizationMutation, { data, loading, error }] = useUpdateMediaOrganizationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      abbreviation: // value for 'abbreviation'
 *      site: // value for 'site'
 *      logoUrl: // value for 'logoUrl'
 *   },
 * });
 */
export function useUpdateMediaOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMediaOrganizationMutation, UpdateMediaOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMediaOrganizationMutation, UpdateMediaOrganizationMutationVariables>(UpdateMediaOrganizationDocument, options);
      }
export type UpdateMediaOrganizationMutationHookResult = ReturnType<typeof useUpdateMediaOrganizationMutation>;
export type UpdateMediaOrganizationMutationResult = Apollo.MutationResult<UpdateMediaOrganizationMutation>;
export type UpdateMediaOrganizationMutationOptions = Apollo.BaseMutationOptions<UpdateMediaOrganizationMutation, UpdateMediaOrganizationMutationVariables>;
export const DeleteMediaOrganizationDocument = gql`
    mutation deleteMediaOrganization($id: String!) {
  deleteMediaOrganization(input: {id: $id})
}
    `;
export type DeleteMediaOrganizationMutationFn = Apollo.MutationFunction<DeleteMediaOrganizationMutation, DeleteMediaOrganizationMutationVariables>;

/**
 * __useDeleteMediaOrganizationMutation__
 *
 * To run a mutation, you first call `useDeleteMediaOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMediaOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMediaOrganizationMutation, { data, loading, error }] = useDeleteMediaOrganizationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMediaOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMediaOrganizationMutation, DeleteMediaOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMediaOrganizationMutation, DeleteMediaOrganizationMutationVariables>(DeleteMediaOrganizationDocument, options);
      }
export type DeleteMediaOrganizationMutationHookResult = ReturnType<typeof useDeleteMediaOrganizationMutation>;
export type DeleteMediaOrganizationMutationResult = Apollo.MutationResult<DeleteMediaOrganizationMutation>;
export type DeleteMediaOrganizationMutationOptions = Apollo.BaseMutationOptions<DeleteMediaOrganizationMutation, DeleteMediaOrganizationMutationVariables>;
export const GetMediaOrganizationByIdDocument = gql`
    query getMediaOrganizationById($id: String!) {
  getMediaOrganizationById(input: {id: $id}) {
    id
    name
    status
    abbreviation
    site
    logo
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetMediaOrganizationByIdQuery__
 *
 * To run a query within a React component, call `useGetMediaOrganizationByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMediaOrganizationByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMediaOrganizationByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMediaOrganizationByIdQuery(baseOptions: Apollo.QueryHookOptions<GetMediaOrganizationByIdQuery, GetMediaOrganizationByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMediaOrganizationByIdQuery, GetMediaOrganizationByIdQueryVariables>(GetMediaOrganizationByIdDocument, options);
      }
export function useGetMediaOrganizationByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMediaOrganizationByIdQuery, GetMediaOrganizationByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMediaOrganizationByIdQuery, GetMediaOrganizationByIdQueryVariables>(GetMediaOrganizationByIdDocument, options);
        }
export type GetMediaOrganizationByIdQueryHookResult = ReturnType<typeof useGetMediaOrganizationByIdQuery>;
export type GetMediaOrganizationByIdLazyQueryHookResult = ReturnType<typeof useGetMediaOrganizationByIdLazyQuery>;
export type GetMediaOrganizationByIdQueryResult = Apollo.QueryResult<GetMediaOrganizationByIdQuery, GetMediaOrganizationByIdQueryVariables>;
export const ListMediaOrganizationsDocument = gql`
    query listMediaOrganizations($offset: Int!, $limit: Int!, $status: MediaOrganizationStatuses, $name: String) {
  listMediaOrganizations(
    input: {status: $status, name: $name, pagination: {offset: $offset, limit: $limit}}
  ) {
    mediaOrganizations {
      id
      name
      status
      abbreviation
      site
      logo
      createdAt
      updatedAt
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListMediaOrganizationsQuery__
 *
 * To run a query within a React component, call `useListMediaOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListMediaOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListMediaOrganizationsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      status: // value for 'status'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useListMediaOrganizationsQuery(baseOptions: Apollo.QueryHookOptions<ListMediaOrganizationsQuery, ListMediaOrganizationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListMediaOrganizationsQuery, ListMediaOrganizationsQueryVariables>(ListMediaOrganizationsDocument, options);
      }
export function useListMediaOrganizationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListMediaOrganizationsQuery, ListMediaOrganizationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListMediaOrganizationsQuery, ListMediaOrganizationsQueryVariables>(ListMediaOrganizationsDocument, options);
        }
export type ListMediaOrganizationsQueryHookResult = ReturnType<typeof useListMediaOrganizationsQuery>;
export type ListMediaOrganizationsLazyQueryHookResult = ReturnType<typeof useListMediaOrganizationsLazyQuery>;
export type ListMediaOrganizationsQueryResult = Apollo.QueryResult<ListMediaOrganizationsQuery, ListMediaOrganizationsQueryVariables>;
export const GetOrganizationCompetitionMetricsDocument = gql`
    query getOrganizationCompetitionMetrics($orgId: String!, $leagueId: String, $seasonId: String) {
  getOrganizationCompetitionMetrics(
    input: {orgId: $orgId, leagueId: $leagueId, seasonId: $seasonId}
  ) {
    organizationId
    totalCompetitions
    competitionsThisWeek
    competitionsThisMonth
    competitionsThisYear
  }
}
    `;

/**
 * __useGetOrganizationCompetitionMetricsQuery__
 *
 * To run a query within a React component, call `useGetOrganizationCompetitionMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationCompetitionMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationCompetitionMetricsQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      leagueId: // value for 'leagueId'
 *      seasonId: // value for 'seasonId'
 *   },
 * });
 */
export function useGetOrganizationCompetitionMetricsQuery(baseOptions: Apollo.QueryHookOptions<GetOrganizationCompetitionMetricsQuery, GetOrganizationCompetitionMetricsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationCompetitionMetricsQuery, GetOrganizationCompetitionMetricsQueryVariables>(GetOrganizationCompetitionMetricsDocument, options);
      }
export function useGetOrganizationCompetitionMetricsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationCompetitionMetricsQuery, GetOrganizationCompetitionMetricsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationCompetitionMetricsQuery, GetOrganizationCompetitionMetricsQueryVariables>(GetOrganizationCompetitionMetricsDocument, options);
        }
export type GetOrganizationCompetitionMetricsQueryHookResult = ReturnType<typeof useGetOrganizationCompetitionMetricsQuery>;
export type GetOrganizationCompetitionMetricsLazyQueryHookResult = ReturnType<typeof useGetOrganizationCompetitionMetricsLazyQuery>;
export type GetOrganizationCompetitionMetricsQueryResult = Apollo.QueryResult<GetOrganizationCompetitionMetricsQuery, GetOrganizationCompetitionMetricsQueryVariables>;
export const GetOrganizationTeamCompetitionSurveyMetricsDocument = gql`
    query getOrganizationTeamCompetitionSurveyMetrics($orgId: String!, $leagueId: String, $seasonId: String) {
  getOrganizationTeamCompetitionSurveyMetrics(
    input: {orgId: $orgId, leagueId: $leagueId, seasonId: $seasonId}
  ) {
    organizationId
    leagueId
    seasonId
    totalSurveys
    completedSurveys
    incompleteSurveys
    completionPercentage
    totalSurveysThisWeek
    completedSurveysThisWeek
    incompleteSurveysThisWeek
    completionPercentageThisWeek
    totalSurveysThisMonth
    completedSurveysThisMonth
    incompleteSurveysThisMonth
    completionPercentageThisMonth
    totalSurveysThisYear
    completedSurveysThisYear
    incompleteSurveysThisYear
    completionPercentageThisYear
  }
}
    `;

/**
 * __useGetOrganizationTeamCompetitionSurveyMetricsQuery__
 *
 * To run a query within a React component, call `useGetOrganizationTeamCompetitionSurveyMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationTeamCompetitionSurveyMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationTeamCompetitionSurveyMetricsQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      leagueId: // value for 'leagueId'
 *      seasonId: // value for 'seasonId'
 *   },
 * });
 */
export function useGetOrganizationTeamCompetitionSurveyMetricsQuery(baseOptions: Apollo.QueryHookOptions<GetOrganizationTeamCompetitionSurveyMetricsQuery, GetOrganizationTeamCompetitionSurveyMetricsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationTeamCompetitionSurveyMetricsQuery, GetOrganizationTeamCompetitionSurveyMetricsQueryVariables>(GetOrganizationTeamCompetitionSurveyMetricsDocument, options);
      }
export function useGetOrganizationTeamCompetitionSurveyMetricsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationTeamCompetitionSurveyMetricsQuery, GetOrganizationTeamCompetitionSurveyMetricsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationTeamCompetitionSurveyMetricsQuery, GetOrganizationTeamCompetitionSurveyMetricsQueryVariables>(GetOrganizationTeamCompetitionSurveyMetricsDocument, options);
        }
export type GetOrganizationTeamCompetitionSurveyMetricsQueryHookResult = ReturnType<typeof useGetOrganizationTeamCompetitionSurveyMetricsQuery>;
export type GetOrganizationTeamCompetitionSurveyMetricsLazyQueryHookResult = ReturnType<typeof useGetOrganizationTeamCompetitionSurveyMetricsLazyQuery>;
export type GetOrganizationTeamCompetitionSurveyMetricsQueryResult = Apollo.QueryResult<GetOrganizationTeamCompetitionSurveyMetricsQuery, GetOrganizationTeamCompetitionSurveyMetricsQueryVariables>;
export const GetOrganizationOfficialCompetitionSurveyMetricsDocument = gql`
    query getOrganizationOfficialCompetitionSurveyMetrics($orgId: String!, $leagueId: String, $seasonId: String) {
  getOrganizationOfficialCompetitionSurveyMetrics(
    input: {orgId: $orgId, leagueId: $leagueId, seasonId: $seasonId}
  ) {
    organizationId
    leagueId
    seasonId
    totalSurveys
    completedSurveys
    incompleteSurveys
    completionPercentage
    totalSurveysThisWeek
    completedSurveysThisWeek
    incompleteSurveysThisWeek
    completionPercentageThisWeek
    totalSurveysThisMonth
    completedSurveysThisMonth
    incompleteSurveysThisMonth
    completionPercentageThisMonth
    totalSurveysThisYear
    completedSurveysThisYear
    incompleteSurveysThisYear
    completionPercentageThisYear
  }
}
    `;

/**
 * __useGetOrganizationOfficialCompetitionSurveyMetricsQuery__
 *
 * To run a query within a React component, call `useGetOrganizationOfficialCompetitionSurveyMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationOfficialCompetitionSurveyMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationOfficialCompetitionSurveyMetricsQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      leagueId: // value for 'leagueId'
 *      seasonId: // value for 'seasonId'
 *   },
 * });
 */
export function useGetOrganizationOfficialCompetitionSurveyMetricsQuery(baseOptions: Apollo.QueryHookOptions<GetOrganizationOfficialCompetitionSurveyMetricsQuery, GetOrganizationOfficialCompetitionSurveyMetricsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationOfficialCompetitionSurveyMetricsQuery, GetOrganizationOfficialCompetitionSurveyMetricsQueryVariables>(GetOrganizationOfficialCompetitionSurveyMetricsDocument, options);
      }
export function useGetOrganizationOfficialCompetitionSurveyMetricsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationOfficialCompetitionSurveyMetricsQuery, GetOrganizationOfficialCompetitionSurveyMetricsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationOfficialCompetitionSurveyMetricsQuery, GetOrganizationOfficialCompetitionSurveyMetricsQueryVariables>(GetOrganizationOfficialCompetitionSurveyMetricsDocument, options);
        }
export type GetOrganizationOfficialCompetitionSurveyMetricsQueryHookResult = ReturnType<typeof useGetOrganizationOfficialCompetitionSurveyMetricsQuery>;
export type GetOrganizationOfficialCompetitionSurveyMetricsLazyQueryHookResult = ReturnType<typeof useGetOrganizationOfficialCompetitionSurveyMetricsLazyQuery>;
export type GetOrganizationOfficialCompetitionSurveyMetricsQueryResult = Apollo.QueryResult<GetOrganizationOfficialCompetitionSurveyMetricsQuery, GetOrganizationOfficialCompetitionSurveyMetricsQueryVariables>;
export const GetOrganizationOfficialDynamicCompetitionSurveyMetricsDocument = gql`
    query getOrganizationOfficialDynamicCompetitionSurveyMetrics($orgId: String!, $leagueId: String, $seasonId: String) {
  getOrganizationOfficialDynamicCompetitionSurveyMetrics(
    input: {orgId: $orgId, leagueId: $leagueId, seasonId: $seasonId}
  ) {
    organizationId
    leagueId
    seasonId
    totalSurveys
    completedSurveys
    incompleteSurveys
    completionPercentage
    totalSurveysThisWeek
    completedSurveysThisWeek
    incompleteSurveysThisWeek
    completionPercentageThisWeek
    totalSurveysThisMonth
    completedSurveysThisMonth
    incompleteSurveysThisMonth
    completionPercentageThisMonth
    totalSurveysThisYear
    completedSurveysThisYear
    incompleteSurveysThisYear
    completionPercentageThisYear
  }
}
    `;

/**
 * __useGetOrganizationOfficialDynamicCompetitionSurveyMetricsQuery__
 *
 * To run a query within a React component, call `useGetOrganizationOfficialDynamicCompetitionSurveyMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationOfficialDynamicCompetitionSurveyMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationOfficialDynamicCompetitionSurveyMetricsQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      leagueId: // value for 'leagueId'
 *      seasonId: // value for 'seasonId'
 *   },
 * });
 */
export function useGetOrganizationOfficialDynamicCompetitionSurveyMetricsQuery(baseOptions: Apollo.QueryHookOptions<GetOrganizationOfficialDynamicCompetitionSurveyMetricsQuery, GetOrganizationOfficialDynamicCompetitionSurveyMetricsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationOfficialDynamicCompetitionSurveyMetricsQuery, GetOrganizationOfficialDynamicCompetitionSurveyMetricsQueryVariables>(GetOrganizationOfficialDynamicCompetitionSurveyMetricsDocument, options);
      }
export function useGetOrganizationOfficialDynamicCompetitionSurveyMetricsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationOfficialDynamicCompetitionSurveyMetricsQuery, GetOrganizationOfficialDynamicCompetitionSurveyMetricsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationOfficialDynamicCompetitionSurveyMetricsQuery, GetOrganizationOfficialDynamicCompetitionSurveyMetricsQueryVariables>(GetOrganizationOfficialDynamicCompetitionSurveyMetricsDocument, options);
        }
export type GetOrganizationOfficialDynamicCompetitionSurveyMetricsQueryHookResult = ReturnType<typeof useGetOrganizationOfficialDynamicCompetitionSurveyMetricsQuery>;
export type GetOrganizationOfficialDynamicCompetitionSurveyMetricsLazyQueryHookResult = ReturnType<typeof useGetOrganizationOfficialDynamicCompetitionSurveyMetricsLazyQuery>;
export type GetOrganizationOfficialDynamicCompetitionSurveyMetricsQueryResult = Apollo.QueryResult<GetOrganizationOfficialDynamicCompetitionSurveyMetricsQuery, GetOrganizationOfficialDynamicCompetitionSurveyMetricsQueryVariables>;
export const GetOrganizationKeyIncidentMetricsDocument = gql`
    query getOrganizationKeyIncidentMetrics($orgId: String!, $leagueId: String, $seasonId: String) {
  getOrganizationKeyIncidentMetrics(
    input: {orgId: $orgId, leagueId: $leagueId, seasonId: $seasonId}
  ) {
    organizationId
    leagueId
    seasonId
    totalKeyIncidents
    resolved
    unresolved
    resolvedPercentage
    totalKeyIncidentsThisWeek
    resolvedThisWeek
    unresolvedThisWeek
    resolvedPercentageThisWeek
    totalKeyIncidentsThisMonth
    resolvedThisMonth
    unresolvedThisMonth
    resolvedPercentageThisMonth
    totalKeyIncidentsThisYear
    resolvedThisYear
    unresolvedThisYear
    resolvedPercentageThisYear
  }
}
    `;

/**
 * __useGetOrganizationKeyIncidentMetricsQuery__
 *
 * To run a query within a React component, call `useGetOrganizationKeyIncidentMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationKeyIncidentMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationKeyIncidentMetricsQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      leagueId: // value for 'leagueId'
 *      seasonId: // value for 'seasonId'
 *   },
 * });
 */
export function useGetOrganizationKeyIncidentMetricsQuery(baseOptions: Apollo.QueryHookOptions<GetOrganizationKeyIncidentMetricsQuery, GetOrganizationKeyIncidentMetricsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationKeyIncidentMetricsQuery, GetOrganizationKeyIncidentMetricsQueryVariables>(GetOrganizationKeyIncidentMetricsDocument, options);
      }
export function useGetOrganizationKeyIncidentMetricsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationKeyIncidentMetricsQuery, GetOrganizationKeyIncidentMetricsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationKeyIncidentMetricsQuery, GetOrganizationKeyIncidentMetricsQueryVariables>(GetOrganizationKeyIncidentMetricsDocument, options);
        }
export type GetOrganizationKeyIncidentMetricsQueryHookResult = ReturnType<typeof useGetOrganizationKeyIncidentMetricsQuery>;
export type GetOrganizationKeyIncidentMetricsLazyQueryHookResult = ReturnType<typeof useGetOrganizationKeyIncidentMetricsLazyQuery>;
export type GetOrganizationKeyIncidentMetricsQueryResult = Apollo.QueryResult<GetOrganizationKeyIncidentMetricsQuery, GetOrganizationKeyIncidentMetricsQueryVariables>;
export const GetOrganizationSupplementalReportMetricsDocument = gql`
    query getOrganizationSupplementalReportMetrics($orgId: String!, $leagueId: String, $seasonId: String) {
  getOrganizationSupplementalReportMetrics(
    input: {orgId: $orgId, leagueId: $leagueId, seasonId: $seasonId}
  ) {
    organizationId
    leagueId
    seasonId
    totalSupplementalReports
    approved
    unapproved
    approvedPercentage
    totalSupplementalReportsThisWeek
    approvedThisWeek
    unapprovedThisWeek
    approvedPercentageThisWeek
    totalSupplementalReportsThisMonth
    approvedThisMonth
    unapprovedThisMonth
    approvedPercentageThisMonth
    totalSupplementalReportsThisYear
    approvedThisYear
    unapprovedThisYear
    approvedPercentageThisYear
  }
}
    `;

/**
 * __useGetOrganizationSupplementalReportMetricsQuery__
 *
 * To run a query within a React component, call `useGetOrganizationSupplementalReportMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationSupplementalReportMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationSupplementalReportMetricsQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      leagueId: // value for 'leagueId'
 *      seasonId: // value for 'seasonId'
 *   },
 * });
 */
export function useGetOrganizationSupplementalReportMetricsQuery(baseOptions: Apollo.QueryHookOptions<GetOrganizationSupplementalReportMetricsQuery, GetOrganizationSupplementalReportMetricsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationSupplementalReportMetricsQuery, GetOrganizationSupplementalReportMetricsQueryVariables>(GetOrganizationSupplementalReportMetricsDocument, options);
      }
export function useGetOrganizationSupplementalReportMetricsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationSupplementalReportMetricsQuery, GetOrganizationSupplementalReportMetricsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationSupplementalReportMetricsQuery, GetOrganizationSupplementalReportMetricsQueryVariables>(GetOrganizationSupplementalReportMetricsDocument, options);
        }
export type GetOrganizationSupplementalReportMetricsQueryHookResult = ReturnType<typeof useGetOrganizationSupplementalReportMetricsQuery>;
export type GetOrganizationSupplementalReportMetricsLazyQueryHookResult = ReturnType<typeof useGetOrganizationSupplementalReportMetricsLazyQuery>;
export type GetOrganizationSupplementalReportMetricsQueryResult = Apollo.QueryResult<GetOrganizationSupplementalReportMetricsQuery, GetOrganizationSupplementalReportMetricsQueryVariables>;
export const ListOrganizationTopOfficialsMetricsDocument = gql`
    query listOrganizationTopOfficialsMetrics($offset: Int!, $limit: Int!, $orgId: String!, $leagueId: String, $seasonId: String, $assessmentObjectId: String, $minCompetitionCount: Int) {
  listOrganizationTopOfficialsMetrics(
    input: {orgId: $orgId, leagueId: $leagueId, seasonId: $seasonId, assessmentObjectId: $assessmentObjectId, minCompetitionCount: $minCompetitionCount, pagination: {offset: $offset, limit: $limit}}
  ) {
    officials {
      assessmentObjectId
      assessmentObjectName
      officialId
      officialFirstName
      officialLastName
      officialProfilePic
      totalCompetitions
      totalKeyIncidents
      totalRatingQuestionsCount
      totalPoints
      maxPointsPossible
      averageCompetitionScore
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListOrganizationTopOfficialsMetricsQuery__
 *
 * To run a query within a React component, call `useListOrganizationTopOfficialsMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListOrganizationTopOfficialsMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListOrganizationTopOfficialsMetricsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      orgId: // value for 'orgId'
 *      leagueId: // value for 'leagueId'
 *      seasonId: // value for 'seasonId'
 *      assessmentObjectId: // value for 'assessmentObjectId'
 *      minCompetitionCount: // value for 'minCompetitionCount'
 *   },
 * });
 */
export function useListOrganizationTopOfficialsMetricsQuery(baseOptions: Apollo.QueryHookOptions<ListOrganizationTopOfficialsMetricsQuery, ListOrganizationTopOfficialsMetricsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListOrganizationTopOfficialsMetricsQuery, ListOrganizationTopOfficialsMetricsQueryVariables>(ListOrganizationTopOfficialsMetricsDocument, options);
      }
export function useListOrganizationTopOfficialsMetricsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListOrganizationTopOfficialsMetricsQuery, ListOrganizationTopOfficialsMetricsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListOrganizationTopOfficialsMetricsQuery, ListOrganizationTopOfficialsMetricsQueryVariables>(ListOrganizationTopOfficialsMetricsDocument, options);
        }
export type ListOrganizationTopOfficialsMetricsQueryHookResult = ReturnType<typeof useListOrganizationTopOfficialsMetricsQuery>;
export type ListOrganizationTopOfficialsMetricsLazyQueryHookResult = ReturnType<typeof useListOrganizationTopOfficialsMetricsLazyQuery>;
export type ListOrganizationTopOfficialsMetricsQueryResult = Apollo.QueryResult<ListOrganizationTopOfficialsMetricsQuery, ListOrganizationTopOfficialsMetricsQueryVariables>;
export const CreateOfficialDocument = gql`
    mutation createOfficial($email: String!, $firstName: String!, $lastName: String!, $sport: Sports!, $externalId: CreateOfficialExternalIdInput) {
  createOfficial(
    input: {email: $email, firstName: $firstName, lastName: $lastName, sport: $sport, externalId: $externalId}
  ) {
    id
    sport
    userId
    status
    userStatus
    email
    firstName
    lastName
    externalIds {
      id
      organizationId
      externalId
    }
    profilePic
    createdAt
    updatedAt
  }
}
    `;
export type CreateOfficialMutationFn = Apollo.MutationFunction<CreateOfficialMutation, CreateOfficialMutationVariables>;

/**
 * __useCreateOfficialMutation__
 *
 * To run a mutation, you first call `useCreateOfficialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOfficialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOfficialMutation, { data, loading, error }] = useCreateOfficialMutation({
 *   variables: {
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      sport: // value for 'sport'
 *      externalId: // value for 'externalId'
 *   },
 * });
 */
export function useCreateOfficialMutation(baseOptions?: Apollo.MutationHookOptions<CreateOfficialMutation, CreateOfficialMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOfficialMutation, CreateOfficialMutationVariables>(CreateOfficialDocument, options);
      }
export type CreateOfficialMutationHookResult = ReturnType<typeof useCreateOfficialMutation>;
export type CreateOfficialMutationResult = Apollo.MutationResult<CreateOfficialMutation>;
export type CreateOfficialMutationOptions = Apollo.BaseMutationOptions<CreateOfficialMutation, CreateOfficialMutationVariables>;
export const GetOfficialByIdDocument = gql`
    query getOfficialById($id: String!) {
  getOfficialById(input: {id: $id}) {
    id
    sport
    userId
    status
    userStatus
    email
    firstName
    lastName
    externalIds {
      id
      organizationId
      externalId
    }
    profilePic
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetOfficialByIdQuery__
 *
 * To run a query within a React component, call `useGetOfficialByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOfficialByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOfficialByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOfficialByIdQuery(baseOptions: Apollo.QueryHookOptions<GetOfficialByIdQuery, GetOfficialByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOfficialByIdQuery, GetOfficialByIdQueryVariables>(GetOfficialByIdDocument, options);
      }
export function useGetOfficialByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOfficialByIdQuery, GetOfficialByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOfficialByIdQuery, GetOfficialByIdQueryVariables>(GetOfficialByIdDocument, options);
        }
export type GetOfficialByIdQueryHookResult = ReturnType<typeof useGetOfficialByIdQuery>;
export type GetOfficialByIdLazyQueryHookResult = ReturnType<typeof useGetOfficialByIdLazyQuery>;
export type GetOfficialByIdQueryResult = Apollo.QueryResult<GetOfficialByIdQuery, GetOfficialByIdQueryVariables>;
export const ListOfficialsDocument = gql`
    query listOfficials($offset: Int!, $limit: Int!, $sport: Sports!, $nameOrEmail: String, $status: OfficialStatuses, $externalId: String) {
  listOfficials(
    input: {sport: $sport, nameOrEmail: $nameOrEmail, status: $status, externalId: $externalId, pagination: {offset: $offset, limit: $limit}}
  ) {
    officials {
      id
      sport
      userId
      status
      userStatus
      email
      firstName
      lastName
      externalIds {
        id
        organizationId
        externalId
      }
      profilePic
      createdAt
      updatedAt
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListOfficialsQuery__
 *
 * To run a query within a React component, call `useListOfficialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListOfficialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListOfficialsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      sport: // value for 'sport'
 *      nameOrEmail: // value for 'nameOrEmail'
 *      status: // value for 'status'
 *      externalId: // value for 'externalId'
 *   },
 * });
 */
export function useListOfficialsQuery(baseOptions: Apollo.QueryHookOptions<ListOfficialsQuery, ListOfficialsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListOfficialsQuery, ListOfficialsQueryVariables>(ListOfficialsDocument, options);
      }
export function useListOfficialsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListOfficialsQuery, ListOfficialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListOfficialsQuery, ListOfficialsQueryVariables>(ListOfficialsDocument, options);
        }
export type ListOfficialsQueryHookResult = ReturnType<typeof useListOfficialsQuery>;
export type ListOfficialsLazyQueryHookResult = ReturnType<typeof useListOfficialsLazyQuery>;
export type ListOfficialsQueryResult = Apollo.QueryResult<ListOfficialsQuery, ListOfficialsQueryVariables>;
export const DeactivateOfficialDocument = gql`
    mutation deactivateOfficial($id: String!) {
  deactivateOfficial(input: {id: $id}) {
    id
    sport
    userId
    status
    userStatus
    email
    firstName
    lastName
    externalIds {
      id
      organizationId
      externalId
    }
    profilePic
    createdAt
    updatedAt
  }
}
    `;
export type DeactivateOfficialMutationFn = Apollo.MutationFunction<DeactivateOfficialMutation, DeactivateOfficialMutationVariables>;

/**
 * __useDeactivateOfficialMutation__
 *
 * To run a mutation, you first call `useDeactivateOfficialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateOfficialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateOfficialMutation, { data, loading, error }] = useDeactivateOfficialMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeactivateOfficialMutation(baseOptions?: Apollo.MutationHookOptions<DeactivateOfficialMutation, DeactivateOfficialMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeactivateOfficialMutation, DeactivateOfficialMutationVariables>(DeactivateOfficialDocument, options);
      }
export type DeactivateOfficialMutationHookResult = ReturnType<typeof useDeactivateOfficialMutation>;
export type DeactivateOfficialMutationResult = Apollo.MutationResult<DeactivateOfficialMutation>;
export type DeactivateOfficialMutationOptions = Apollo.BaseMutationOptions<DeactivateOfficialMutation, DeactivateOfficialMutationVariables>;
export const DeleteOfficialDocument = gql`
    mutation deleteOfficial($id: String!) {
  deleteOfficial(input: {id: $id})
}
    `;
export type DeleteOfficialMutationFn = Apollo.MutationFunction<DeleteOfficialMutation, DeleteOfficialMutationVariables>;

/**
 * __useDeleteOfficialMutation__
 *
 * To run a mutation, you first call `useDeleteOfficialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOfficialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOfficialMutation, { data, loading, error }] = useDeleteOfficialMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOfficialMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOfficialMutation, DeleteOfficialMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOfficialMutation, DeleteOfficialMutationVariables>(DeleteOfficialDocument, options);
      }
export type DeleteOfficialMutationHookResult = ReturnType<typeof useDeleteOfficialMutation>;
export type DeleteOfficialMutationResult = Apollo.MutationResult<DeleteOfficialMutation>;
export type DeleteOfficialMutationOptions = Apollo.BaseMutationOptions<DeleteOfficialMutation, DeleteOfficialMutationVariables>;
export const GetOrgOfficialSummaryByIdDocument = gql`
    query getOrgOfficialSummaryById($officialId: String!, $orgId: String!, $leagueId: String, $seasonId: String) {
  getOrgOfficialSummaryById(
    input: {officialId: $officialId, orgId: $orgId, leagueId: $leagueId, seasonId: $seasonId}
  ) {
    id
    externalId
    email
    firstName
    lastName
    profilePic
    totalCompetitions
    totalKeyIncidents
    totalRatingQuestionsCount
    totalPoints
    maxPointsPossible
    averageCompetitionScore
  }
}
    `;

/**
 * __useGetOrgOfficialSummaryByIdQuery__
 *
 * To run a query within a React component, call `useGetOrgOfficialSummaryByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrgOfficialSummaryByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrgOfficialSummaryByIdQuery({
 *   variables: {
 *      officialId: // value for 'officialId'
 *      orgId: // value for 'orgId'
 *      leagueId: // value for 'leagueId'
 *      seasonId: // value for 'seasonId'
 *   },
 * });
 */
export function useGetOrgOfficialSummaryByIdQuery(baseOptions: Apollo.QueryHookOptions<GetOrgOfficialSummaryByIdQuery, GetOrgOfficialSummaryByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrgOfficialSummaryByIdQuery, GetOrgOfficialSummaryByIdQueryVariables>(GetOrgOfficialSummaryByIdDocument, options);
      }
export function useGetOrgOfficialSummaryByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrgOfficialSummaryByIdQuery, GetOrgOfficialSummaryByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrgOfficialSummaryByIdQuery, GetOrgOfficialSummaryByIdQueryVariables>(GetOrgOfficialSummaryByIdDocument, options);
        }
export type GetOrgOfficialSummaryByIdQueryHookResult = ReturnType<typeof useGetOrgOfficialSummaryByIdQuery>;
export type GetOrgOfficialSummaryByIdLazyQueryHookResult = ReturnType<typeof useGetOrgOfficialSummaryByIdLazyQuery>;
export type GetOrgOfficialSummaryByIdQueryResult = Apollo.QueryResult<GetOrgOfficialSummaryByIdQuery, GetOrgOfficialSummaryByIdQueryVariables>;
export const ListCompetitionSummariesForOrgOfficialDocument = gql`
    query listCompetitionSummariesForOrgOfficial($offset: Int!, $limit: Int!, $officialId: String!, $orgId: String!, $leagueId: String, $seasonId: String, $leagueSearch: String, $seasonSearch: String, $assessmentObjectUuid: String, $teamsSearch: String, $dateFrom: String, $dateTo: String) {
  listCompetitionSummariesForOrgOfficial(
    input: {officialId: $officialId, orgId: $orgId, leagueId: $leagueId, seasonId: $seasonId, leagueSearch: $leagueSearch, seasonSearch: $seasonSearch, assessmentObjectUuid: $assessmentObjectUuid, teamsSearch: $teamsSearch, dateFrom: $dateFrom, dateTo: $dateTo, pagination: {offset: $offset, limit: $limit}}
  ) {
    competitionSummaries {
      leagueId
      leagueName
      seasonId
      seasonName
      officialId
      officialEmail
      officialExternalId
      officialFirstName
      officialLastName
      officialProfilePic
      competitionId
      competitionStartTime
      competitionLocation
      homeTeamId
      homeTeamName
      homeTeamLogo
      homeTeamScore
      homeTeamTieBreakerScore
      awayTeamId
      awayTeamName
      awayTeamLogo
      awayTeamScore
      awayTeamTieBreakerScore
      organizationId
      leagueId
      seasonId
      assessmentObjectId
      assessmentObjectName
      impactedOutcomeCount
      keyIncidentsCount
      ratingQuestionsCount
      totalPoints
      maxPointsPossible
      averageCompetitionScore
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListCompetitionSummariesForOrgOfficialQuery__
 *
 * To run a query within a React component, call `useListCompetitionSummariesForOrgOfficialQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCompetitionSummariesForOrgOfficialQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCompetitionSummariesForOrgOfficialQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      officialId: // value for 'officialId'
 *      orgId: // value for 'orgId'
 *      leagueId: // value for 'leagueId'
 *      seasonId: // value for 'seasonId'
 *      leagueSearch: // value for 'leagueSearch'
 *      seasonSearch: // value for 'seasonSearch'
 *      assessmentObjectUuid: // value for 'assessmentObjectUuid'
 *      teamsSearch: // value for 'teamsSearch'
 *      dateFrom: // value for 'dateFrom'
 *      dateTo: // value for 'dateTo'
 *   },
 * });
 */
export function useListCompetitionSummariesForOrgOfficialQuery(baseOptions: Apollo.QueryHookOptions<ListCompetitionSummariesForOrgOfficialQuery, ListCompetitionSummariesForOrgOfficialQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListCompetitionSummariesForOrgOfficialQuery, ListCompetitionSummariesForOrgOfficialQueryVariables>(ListCompetitionSummariesForOrgOfficialDocument, options);
      }
export function useListCompetitionSummariesForOrgOfficialLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListCompetitionSummariesForOrgOfficialQuery, ListCompetitionSummariesForOrgOfficialQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListCompetitionSummariesForOrgOfficialQuery, ListCompetitionSummariesForOrgOfficialQueryVariables>(ListCompetitionSummariesForOrgOfficialDocument, options);
        }
export type ListCompetitionSummariesForOrgOfficialQueryHookResult = ReturnType<typeof useListCompetitionSummariesForOrgOfficialQuery>;
export type ListCompetitionSummariesForOrgOfficialLazyQueryHookResult = ReturnType<typeof useListCompetitionSummariesForOrgOfficialLazyQuery>;
export type ListCompetitionSummariesForOrgOfficialQueryResult = Apollo.QueryResult<ListCompetitionSummariesForOrgOfficialQuery, ListCompetitionSummariesForOrgOfficialQueryVariables>;
export const ListFeedbackForOrgOfficialDocument = gql`
    query listFeedbackForOrgOfficial($offset: Int!, $limit: Int!, $officialId: String!, $orgId: String!, $leagueId: String, $seasonId: String, $submittedByTeamSearch: String, $dateFrom: String, $dateTo: String) {
  listFeedbackForOrgOfficial(
    input: {officialId: $officialId, orgId: $orgId, leagueId: $leagueId, seasonId: $seasonId, submittedByTeamSearch: $submittedByTeamSearch, dateFrom: $dateFrom, dateTo: $dateTo, pagination: {offset: $offset, limit: $limit}}
  ) {
    feedback {
      leagueId
      leagueName
      seasonId
      seasonName
      competitionId
      competitionStartTime
      competitionLocation
      homeTeamId
      homeTeamName
      homeTeamLogo
      homeTeamScore
      homeTeamTieBreakerScore
      awayTeamId
      awayTeamName
      awayTeamLogo
      awayTeamScore
      awayTeamTieBreakerScore
      submittedByTeamId
      submittedByTeamName
      submittedByTeamLogo
      assessmentObjectId
      assessmentObjectName
      feedback
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListFeedbackForOrgOfficialQuery__
 *
 * To run a query within a React component, call `useListFeedbackForOrgOfficialQuery` and pass it any options that fit your needs.
 * When your component renders, `useListFeedbackForOrgOfficialQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListFeedbackForOrgOfficialQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      officialId: // value for 'officialId'
 *      orgId: // value for 'orgId'
 *      leagueId: // value for 'leagueId'
 *      seasonId: // value for 'seasonId'
 *      submittedByTeamSearch: // value for 'submittedByTeamSearch'
 *      dateFrom: // value for 'dateFrom'
 *      dateTo: // value for 'dateTo'
 *   },
 * });
 */
export function useListFeedbackForOrgOfficialQuery(baseOptions: Apollo.QueryHookOptions<ListFeedbackForOrgOfficialQuery, ListFeedbackForOrgOfficialQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListFeedbackForOrgOfficialQuery, ListFeedbackForOrgOfficialQueryVariables>(ListFeedbackForOrgOfficialDocument, options);
      }
export function useListFeedbackForOrgOfficialLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListFeedbackForOrgOfficialQuery, ListFeedbackForOrgOfficialQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListFeedbackForOrgOfficialQuery, ListFeedbackForOrgOfficialQueryVariables>(ListFeedbackForOrgOfficialDocument, options);
        }
export type ListFeedbackForOrgOfficialQueryHookResult = ReturnType<typeof useListFeedbackForOrgOfficialQuery>;
export type ListFeedbackForOrgOfficialLazyQueryHookResult = ReturnType<typeof useListFeedbackForOrgOfficialLazyQuery>;
export type ListFeedbackForOrgOfficialQueryResult = Apollo.QueryResult<ListFeedbackForOrgOfficialQuery, ListFeedbackForOrgOfficialQueryVariables>;
export const GetOrganizationByIdDocument = gql`
    query getOrganizationById($id: String!) {
  getOrganizationById(input: {id: $id}) {
    id
    name
    sport
    status
    abbreviation
    site
    logo
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetOrganizationByIdQuery__
 *
 * To run a query within a React component, call `useGetOrganizationByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrganizationByIdQuery(baseOptions: Apollo.QueryHookOptions<GetOrganizationByIdQuery, GetOrganizationByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationByIdQuery, GetOrganizationByIdQueryVariables>(GetOrganizationByIdDocument, options);
      }
export function useGetOrganizationByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationByIdQuery, GetOrganizationByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationByIdQuery, GetOrganizationByIdQueryVariables>(GetOrganizationByIdDocument, options);
        }
export type GetOrganizationByIdQueryHookResult = ReturnType<typeof useGetOrganizationByIdQuery>;
export type GetOrganizationByIdLazyQueryHookResult = ReturnType<typeof useGetOrganizationByIdLazyQuery>;
export type GetOrganizationByIdQueryResult = Apollo.QueryResult<GetOrganizationByIdQuery, GetOrganizationByIdQueryVariables>;
export const UpdateOrganizationDocument = gql`
    mutation updateOrganization($id: String!, $name: String!, $abbreviation: String!, $site: String, $logoUrl: String) {
  updateOrganization(
    input: {id: $id, name: $name, abbreviation: $abbreviation, site: $site, logoUrl: $logoUrl}
  ) {
    id
    name
    sport
    status
    abbreviation
    site
    logo
    createdAt
    updatedAt
  }
}
    `;
export type UpdateOrganizationMutationFn = Apollo.MutationFunction<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>;

/**
 * __useUpdateOrganizationMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationMutation, { data, loading, error }] = useUpdateOrganizationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      abbreviation: // value for 'abbreviation'
 *      site: // value for 'site'
 *      logoUrl: // value for 'logoUrl'
 *   },
 * });
 */
export function useUpdateOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>(UpdateOrganizationDocument, options);
      }
export type UpdateOrganizationMutationHookResult = ReturnType<typeof useUpdateOrganizationMutation>;
export type UpdateOrganizationMutationResult = Apollo.MutationResult<UpdateOrganizationMutation>;
export type UpdateOrganizationMutationOptions = Apollo.BaseMutationOptions<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>;
export const UpdateOrganizationStatusDocument = gql`
    mutation updateOrganizationStatus($organizationId: String!, $organizationStatus: OrganizationStatuses!) {
  updateOrganizationStatus(
    input: {organizationId: $organizationId, organizationStatus: $organizationStatus}
  ) {
    id
    name
    status
  }
}
    `;
export type UpdateOrganizationStatusMutationFn = Apollo.MutationFunction<UpdateOrganizationStatusMutation, UpdateOrganizationStatusMutationVariables>;

/**
 * __useUpdateOrganizationStatusMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationStatusMutation, { data, loading, error }] = useUpdateOrganizationStatusMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      organizationStatus: // value for 'organizationStatus'
 *   },
 * });
 */
export function useUpdateOrganizationStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrganizationStatusMutation, UpdateOrganizationStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrganizationStatusMutation, UpdateOrganizationStatusMutationVariables>(UpdateOrganizationStatusDocument, options);
      }
export type UpdateOrganizationStatusMutationHookResult = ReturnType<typeof useUpdateOrganizationStatusMutation>;
export type UpdateOrganizationStatusMutationResult = Apollo.MutationResult<UpdateOrganizationStatusMutation>;
export type UpdateOrganizationStatusMutationOptions = Apollo.BaseMutationOptions<UpdateOrganizationStatusMutation, UpdateOrganizationStatusMutationVariables>;
export const ListOrganizationUsersDocument = gql`
    query listOrganizationUsers($offset: Int!, $limit: Int!, $organizationId: String!, $nameOrEmail: String) {
  listOrganizationUsers(
    input: {organizationId: $organizationId, nameOrEmail: $nameOrEmail, pagination: {offset: $offset, limit: $limit}}
  ) {
    users {
      id
      email
      firstName
      lastName
      isAdmin
      organizationMemberships {
        id
        orgId
        roles
        permissions
        title
        leagueMemberships {
          leagueId
          name
          abbreviation
          logo
        }
      }
      status
      createdAt
      updatedAt
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListOrganizationUsersQuery__
 *
 * To run a query within a React component, call `useListOrganizationUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListOrganizationUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListOrganizationUsersQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      organizationId: // value for 'organizationId'
 *      nameOrEmail: // value for 'nameOrEmail'
 *   },
 * });
 */
export function useListOrganizationUsersQuery(baseOptions: Apollo.QueryHookOptions<ListOrganizationUsersQuery, ListOrganizationUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListOrganizationUsersQuery, ListOrganizationUsersQueryVariables>(ListOrganizationUsersDocument, options);
      }
export function useListOrganizationUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListOrganizationUsersQuery, ListOrganizationUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListOrganizationUsersQuery, ListOrganizationUsersQueryVariables>(ListOrganizationUsersDocument, options);
        }
export type ListOrganizationUsersQueryHookResult = ReturnType<typeof useListOrganizationUsersQuery>;
export type ListOrganizationUsersLazyQueryHookResult = ReturnType<typeof useListOrganizationUsersLazyQuery>;
export type ListOrganizationUsersQueryResult = Apollo.QueryResult<ListOrganizationUsersQuery, ListOrganizationUsersQueryVariables>;
export const RemoveUserFromOrganizationDocument = gql`
    mutation removeUserFromOrganization($organizationId: String!, $userId: String!) {
  removeUserFromOrganization(
    input: {organizationId: $organizationId, userId: $userId}
  )
}
    `;
export type RemoveUserFromOrganizationMutationFn = Apollo.MutationFunction<RemoveUserFromOrganizationMutation, RemoveUserFromOrganizationMutationVariables>;

/**
 * __useRemoveUserFromOrganizationMutation__
 *
 * To run a mutation, you first call `useRemoveUserFromOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserFromOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserFromOrganizationMutation, { data, loading, error }] = useRemoveUserFromOrganizationMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useRemoveUserFromOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<RemoveUserFromOrganizationMutation, RemoveUserFromOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveUserFromOrganizationMutation, RemoveUserFromOrganizationMutationVariables>(RemoveUserFromOrganizationDocument, options);
      }
export type RemoveUserFromOrganizationMutationHookResult = ReturnType<typeof useRemoveUserFromOrganizationMutation>;
export type RemoveUserFromOrganizationMutationResult = Apollo.MutationResult<RemoveUserFromOrganizationMutation>;
export type RemoveUserFromOrganizationMutationOptions = Apollo.BaseMutationOptions<RemoveUserFromOrganizationMutation, RemoveUserFromOrganizationMutationVariables>;
export const CreateNewOrganizationUserDocument = gql`
    mutation createNewOrganizationUser($organizationId: String!, $email: String!, $firstName: String!, $lastName: String!, $roles: [OrganizationUserRoles!]!, $permissions: [OrganizationUserPermissions!]!, $title: String) {
  createNewOrganizationUser(
    input: {organizationId: $organizationId, email: $email, firstName: $firstName, lastName: $lastName, roles: $roles, permissions: $permissions, title: $title}
  ) {
    user {
      id
      email
      firstName
      lastName
      isAdmin
      organizationMemberships {
        id
        orgId
        roles
        permissions
        title
        leagueMemberships {
          leagueId
          name
          abbreviation
          logo
        }
      }
      status
      createdAt
      updatedAt
    }
    emailSent
  }
}
    `;
export type CreateNewOrganizationUserMutationFn = Apollo.MutationFunction<CreateNewOrganizationUserMutation, CreateNewOrganizationUserMutationVariables>;

/**
 * __useCreateNewOrganizationUserMutation__
 *
 * To run a mutation, you first call `useCreateNewOrganizationUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewOrganizationUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewOrganizationUserMutation, { data, loading, error }] = useCreateNewOrganizationUserMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      roles: // value for 'roles'
 *      permissions: // value for 'permissions'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useCreateNewOrganizationUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateNewOrganizationUserMutation, CreateNewOrganizationUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNewOrganizationUserMutation, CreateNewOrganizationUserMutationVariables>(CreateNewOrganizationUserDocument, options);
      }
export type CreateNewOrganizationUserMutationHookResult = ReturnType<typeof useCreateNewOrganizationUserMutation>;
export type CreateNewOrganizationUserMutationResult = Apollo.MutationResult<CreateNewOrganizationUserMutation>;
export type CreateNewOrganizationUserMutationOptions = Apollo.BaseMutationOptions<CreateNewOrganizationUserMutation, CreateNewOrganizationUserMutationVariables>;
export const AddUserToOrganizationDocument = gql`
    mutation addUserToOrganization($organizationId: String!, $email: String!, $roles: [OrganizationUserRoles!]!, $permissions: [OrganizationUserPermissions!]!, $title: String) {
  addUserToOrganization(
    input: {organizationId: $organizationId, email: $email, roles: $roles, permissions: $permissions, title: $title}
  ) {
    user {
      id
      email
      firstName
      lastName
      isAdmin
      organizationMemberships {
        id
        orgId
        roles
        permissions
        title
        leagueMemberships {
          leagueId
          name
          abbreviation
          logo
        }
      }
      status
      createdAt
      updatedAt
    }
    emailSent
  }
}
    `;
export type AddUserToOrganizationMutationFn = Apollo.MutationFunction<AddUserToOrganizationMutation, AddUserToOrganizationMutationVariables>;

/**
 * __useAddUserToOrganizationMutation__
 *
 * To run a mutation, you first call `useAddUserToOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserToOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserToOrganizationMutation, { data, loading, error }] = useAddUserToOrganizationMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      email: // value for 'email'
 *      roles: // value for 'roles'
 *      permissions: // value for 'permissions'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useAddUserToOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<AddUserToOrganizationMutation, AddUserToOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddUserToOrganizationMutation, AddUserToOrganizationMutationVariables>(AddUserToOrganizationDocument, options);
      }
export type AddUserToOrganizationMutationHookResult = ReturnType<typeof useAddUserToOrganizationMutation>;
export type AddUserToOrganizationMutationResult = Apollo.MutationResult<AddUserToOrganizationMutation>;
export type AddUserToOrganizationMutationOptions = Apollo.BaseMutationOptions<AddUserToOrganizationMutation, AddUserToOrganizationMutationVariables>;
export const UpdateUserOrganizationMembershipDocument = gql`
    mutation updateUserOrganizationMembership($organizationId: String!, $userId: String!, $roles: [OrganizationUserRoles!]!, $permissions: [OrganizationUserPermissions!]!, $title: String) {
  updateUserOrganizationMembership(
    input: {organizationId: $organizationId, userId: $userId, roles: $roles, permissions: $permissions, title: $title}
  ) {
    id
    orgId
    roles
    permissions
    title
    leagueMemberships {
      leagueId
      name
      abbreviation
      logo
    }
  }
}
    `;
export type UpdateUserOrganizationMembershipMutationFn = Apollo.MutationFunction<UpdateUserOrganizationMembershipMutation, UpdateUserOrganizationMembershipMutationVariables>;

/**
 * __useUpdateUserOrganizationMembershipMutation__
 *
 * To run a mutation, you first call `useUpdateUserOrganizationMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserOrganizationMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserOrganizationMembershipMutation, { data, loading, error }] = useUpdateUserOrganizationMembershipMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      userId: // value for 'userId'
 *      roles: // value for 'roles'
 *      permissions: // value for 'permissions'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useUpdateUserOrganizationMembershipMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserOrganizationMembershipMutation, UpdateUserOrganizationMembershipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserOrganizationMembershipMutation, UpdateUserOrganizationMembershipMutationVariables>(UpdateUserOrganizationMembershipDocument, options);
      }
export type UpdateUserOrganizationMembershipMutationHookResult = ReturnType<typeof useUpdateUserOrganizationMembershipMutation>;
export type UpdateUserOrganizationMembershipMutationResult = Apollo.MutationResult<UpdateUserOrganizationMembershipMutation>;
export type UpdateUserOrganizationMembershipMutationOptions = Apollo.BaseMutationOptions<UpdateUserOrganizationMembershipMutation, UpdateUserOrganizationMembershipMutationVariables>;
export const GetUserOrganizationMembershipDocument = gql`
    query getUserOrganizationMembership($organizationId: String!, $userId: String!) {
  getUserOrganizationMembership(
    input: {organizationId: $organizationId, userId: $userId}
  ) {
    id
    orgId
    roles
    permissions
    title
    leagueMemberships {
      leagueId
      name
      abbreviation
      logo
    }
  }
}
    `;

/**
 * __useGetUserOrganizationMembershipQuery__
 *
 * To run a query within a React component, call `useGetUserOrganizationMembershipQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserOrganizationMembershipQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserOrganizationMembershipQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserOrganizationMembershipQuery(baseOptions: Apollo.QueryHookOptions<GetUserOrganizationMembershipQuery, GetUserOrganizationMembershipQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserOrganizationMembershipQuery, GetUserOrganizationMembershipQueryVariables>(GetUserOrganizationMembershipDocument, options);
      }
export function useGetUserOrganizationMembershipLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserOrganizationMembershipQuery, GetUserOrganizationMembershipQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserOrganizationMembershipQuery, GetUserOrganizationMembershipQueryVariables>(GetUserOrganizationMembershipDocument, options);
        }
export type GetUserOrganizationMembershipQueryHookResult = ReturnType<typeof useGetUserOrganizationMembershipQuery>;
export type GetUserOrganizationMembershipLazyQueryHookResult = ReturnType<typeof useGetUserOrganizationMembershipLazyQuery>;
export type GetUserOrganizationMembershipQueryResult = Apollo.QueryResult<GetUserOrganizationMembershipQuery, GetUserOrganizationMembershipQueryVariables>;
export const ListOfficialAssessmentObjectsForOrganizationDocument = gql`
    query listOfficialAssessmentObjectsForOrganization($organizationId: String!, $leagueId: String, $seasonId: String) {
  listOfficialAssessmentObjectsForOrganization(
    input: {organizationId: $organizationId, leagueId: $leagueId, seasonId: $seasonId}
  ) {
    id
    sport
    type
    name
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useListOfficialAssessmentObjectsForOrganizationQuery__
 *
 * To run a query within a React component, call `useListOfficialAssessmentObjectsForOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useListOfficialAssessmentObjectsForOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListOfficialAssessmentObjectsForOrganizationQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      leagueId: // value for 'leagueId'
 *      seasonId: // value for 'seasonId'
 *   },
 * });
 */
export function useListOfficialAssessmentObjectsForOrganizationQuery(baseOptions: Apollo.QueryHookOptions<ListOfficialAssessmentObjectsForOrganizationQuery, ListOfficialAssessmentObjectsForOrganizationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListOfficialAssessmentObjectsForOrganizationQuery, ListOfficialAssessmentObjectsForOrganizationQueryVariables>(ListOfficialAssessmentObjectsForOrganizationDocument, options);
      }
export function useListOfficialAssessmentObjectsForOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListOfficialAssessmentObjectsForOrganizationQuery, ListOfficialAssessmentObjectsForOrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListOfficialAssessmentObjectsForOrganizationQuery, ListOfficialAssessmentObjectsForOrganizationQueryVariables>(ListOfficialAssessmentObjectsForOrganizationDocument, options);
        }
export type ListOfficialAssessmentObjectsForOrganizationQueryHookResult = ReturnType<typeof useListOfficialAssessmentObjectsForOrganizationQuery>;
export type ListOfficialAssessmentObjectsForOrganizationLazyQueryHookResult = ReturnType<typeof useListOfficialAssessmentObjectsForOrganizationLazyQuery>;
export type ListOfficialAssessmentObjectsForOrganizationQueryResult = Apollo.QueryResult<ListOfficialAssessmentObjectsForOrganizationQuery, ListOfficialAssessmentObjectsForOrganizationQueryVariables>;
export const ListFeaturesForOrganizationDocument = gql`
    query listFeaturesForOrganization($offset: Int!, $limit: Int!, $organizationId: String!, $feature: String) {
  listFeaturesForOrganization(
    input: {organizationId: $organizationId, feature: $feature, pagination: {offset: $offset, limit: $limit}}
  ) {
    orgId
    orgName
    features {
      id
      feature
      active
      createdAt
      updatedAt
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListFeaturesForOrganizationQuery__
 *
 * To run a query within a React component, call `useListFeaturesForOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useListFeaturesForOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListFeaturesForOrganizationQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      organizationId: // value for 'organizationId'
 *      feature: // value for 'feature'
 *   },
 * });
 */
export function useListFeaturesForOrganizationQuery(baseOptions: Apollo.QueryHookOptions<ListFeaturesForOrganizationQuery, ListFeaturesForOrganizationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListFeaturesForOrganizationQuery, ListFeaturesForOrganizationQueryVariables>(ListFeaturesForOrganizationDocument, options);
      }
export function useListFeaturesForOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListFeaturesForOrganizationQuery, ListFeaturesForOrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListFeaturesForOrganizationQuery, ListFeaturesForOrganizationQueryVariables>(ListFeaturesForOrganizationDocument, options);
        }
export type ListFeaturesForOrganizationQueryHookResult = ReturnType<typeof useListFeaturesForOrganizationQuery>;
export type ListFeaturesForOrganizationLazyQueryHookResult = ReturnType<typeof useListFeaturesForOrganizationLazyQuery>;
export type ListFeaturesForOrganizationQueryResult = Apollo.QueryResult<ListFeaturesForOrganizationQuery, ListFeaturesForOrganizationQueryVariables>;
export const ListContactsForOrganizationDocument = gql`
    query listContactsForOrganization($offset: Int!, $limit: Int!, $organizationId: String!, $search: String, $activeOnly: Boolean) {
  listContactsForOrganization(
    input: {organizationId: $organizationId, search: $search, activeOnly: $activeOnly, pagination: {offset: $offset, limit: $limit}}
  ) {
    orgId
    orgName
    contacts {
      id
      firstName
      lastName
      isActive
      email
      phone
      createdAt
      updatedAt
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListContactsForOrganizationQuery__
 *
 * To run a query within a React component, call `useListContactsForOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useListContactsForOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListContactsForOrganizationQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      organizationId: // value for 'organizationId'
 *      search: // value for 'search'
 *      activeOnly: // value for 'activeOnly'
 *   },
 * });
 */
export function useListContactsForOrganizationQuery(baseOptions: Apollo.QueryHookOptions<ListContactsForOrganizationQuery, ListContactsForOrganizationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListContactsForOrganizationQuery, ListContactsForOrganizationQueryVariables>(ListContactsForOrganizationDocument, options);
      }
export function useListContactsForOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListContactsForOrganizationQuery, ListContactsForOrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListContactsForOrganizationQuery, ListContactsForOrganizationQueryVariables>(ListContactsForOrganizationDocument, options);
        }
export type ListContactsForOrganizationQueryHookResult = ReturnType<typeof useListContactsForOrganizationQuery>;
export type ListContactsForOrganizationLazyQueryHookResult = ReturnType<typeof useListContactsForOrganizationLazyQuery>;
export type ListContactsForOrganizationQueryResult = Apollo.QueryResult<ListContactsForOrganizationQuery, ListContactsForOrganizationQueryVariables>;
export const CreateOrganizationContactDocument = gql`
    mutation createOrganizationContact($organizationId: String!, $firstName: String!, $lastName: String!, $email: String, $phone: String) {
  createOrganizationContact(
    input: {organizationId: $organizationId, firstName: $firstName, lastName: $lastName, email: $email, phone: $phone}
  ) {
    id
    firstName
    lastName
    isActive
    email
    phone
    createdAt
    updatedAt
  }
}
    `;
export type CreateOrganizationContactMutationFn = Apollo.MutationFunction<CreateOrganizationContactMutation, CreateOrganizationContactMutationVariables>;

/**
 * __useCreateOrganizationContactMutation__
 *
 * To run a mutation, you first call `useCreateOrganizationContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganizationContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganizationContactMutation, { data, loading, error }] = useCreateOrganizationContactMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function useCreateOrganizationContactMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrganizationContactMutation, CreateOrganizationContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrganizationContactMutation, CreateOrganizationContactMutationVariables>(CreateOrganizationContactDocument, options);
      }
export type CreateOrganizationContactMutationHookResult = ReturnType<typeof useCreateOrganizationContactMutation>;
export type CreateOrganizationContactMutationResult = Apollo.MutationResult<CreateOrganizationContactMutation>;
export type CreateOrganizationContactMutationOptions = Apollo.BaseMutationOptions<CreateOrganizationContactMutation, CreateOrganizationContactMutationVariables>;
export const UpdateOrganizationContactDocument = gql`
    mutation updateOrganizationContact($organizationId: String!, $contactId: String!, $firstName: String!, $lastName: String!, $email: String, $phone: String, $isActive: Boolean!) {
  updateOrganizationContact(
    input: {organizationId: $organizationId, contactId: $contactId, firstName: $firstName, lastName: $lastName, email: $email, phone: $phone, isActive: $isActive}
  ) {
    id
    firstName
    lastName
    isActive
    email
    phone
    createdAt
    updatedAt
  }
}
    `;
export type UpdateOrganizationContactMutationFn = Apollo.MutationFunction<UpdateOrganizationContactMutation, UpdateOrganizationContactMutationVariables>;

/**
 * __useUpdateOrganizationContactMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationContactMutation, { data, loading, error }] = useUpdateOrganizationContactMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      contactId: // value for 'contactId'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      phone: // value for 'phone'
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useUpdateOrganizationContactMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrganizationContactMutation, UpdateOrganizationContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrganizationContactMutation, UpdateOrganizationContactMutationVariables>(UpdateOrganizationContactDocument, options);
      }
export type UpdateOrganizationContactMutationHookResult = ReturnType<typeof useUpdateOrganizationContactMutation>;
export type UpdateOrganizationContactMutationResult = Apollo.MutationResult<UpdateOrganizationContactMutation>;
export type UpdateOrganizationContactMutationOptions = Apollo.BaseMutationOptions<UpdateOrganizationContactMutation, UpdateOrganizationContactMutationVariables>;
export const DeleteOrganizationContactDocument = gql`
    mutation deleteOrganizationContact($organizationId: String!, $contactId: String!) {
  deleteOrganizationContact(
    input: {organizationId: $organizationId, contactId: $contactId}
  )
}
    `;
export type DeleteOrganizationContactMutationFn = Apollo.MutationFunction<DeleteOrganizationContactMutation, DeleteOrganizationContactMutationVariables>;

/**
 * __useDeleteOrganizationContactMutation__
 *
 * To run a mutation, you first call `useDeleteOrganizationContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrganizationContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrganizationContactMutation, { data, loading, error }] = useDeleteOrganizationContactMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      contactId: // value for 'contactId'
 *   },
 * });
 */
export function useDeleteOrganizationContactMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOrganizationContactMutation, DeleteOrganizationContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOrganizationContactMutation, DeleteOrganizationContactMutationVariables>(DeleteOrganizationContactDocument, options);
      }
export type DeleteOrganizationContactMutationHookResult = ReturnType<typeof useDeleteOrganizationContactMutation>;
export type DeleteOrganizationContactMutationResult = Apollo.MutationResult<DeleteOrganizationContactMutation>;
export type DeleteOrganizationContactMutationOptions = Apollo.BaseMutationOptions<DeleteOrganizationContactMutation, DeleteOrganizationContactMutationVariables>;
export const ListTeamParticipationSummariesForOrgDocument = gql`
    query listTeamParticipationSummariesForOrg($offset: Int!, $limit: Int!, $orgId: String!, $leagueId: String, $seasonId: String, $descending: Boolean, $leagueName: String, $seasonName: String, $teamNameOrAbbreviation: String, $fromDate: String, $toDate: String, $status: SurveyParticipationStatusesFilter) {
  listTeamParticipationSummariesForOrg(
    input: {orgId: $orgId, leagueId: $leagueId, seasonId: $seasonId, descending: $descending, leagueName: $leagueName, seasonName: $seasonName, teamNameOrAbbreviation: $teamNameOrAbbreviation, fromDate: $fromDate, toDate: $toDate, status: $status, pagination: {offset: $offset, limit: $limit}}
  ) {
    participationSummaries {
      organizationId
      organizationName
      leagueId
      leagueName
      seasonId
      seasonName
      teamId
      teamName
      teamLogo
      competitionSurveyCount
      notStartedCompetitionSurveyCount
      incompleteCompetitionSurveyCount
      completedCompetitionSurveyCount
      completionCompetitionSurveyPercentage
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListTeamParticipationSummariesForOrgQuery__
 *
 * To run a query within a React component, call `useListTeamParticipationSummariesForOrgQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTeamParticipationSummariesForOrgQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTeamParticipationSummariesForOrgQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      orgId: // value for 'orgId'
 *      leagueId: // value for 'leagueId'
 *      seasonId: // value for 'seasonId'
 *      descending: // value for 'descending'
 *      leagueName: // value for 'leagueName'
 *      seasonName: // value for 'seasonName'
 *      teamNameOrAbbreviation: // value for 'teamNameOrAbbreviation'
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useListTeamParticipationSummariesForOrgQuery(baseOptions: Apollo.QueryHookOptions<ListTeamParticipationSummariesForOrgQuery, ListTeamParticipationSummariesForOrgQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListTeamParticipationSummariesForOrgQuery, ListTeamParticipationSummariesForOrgQueryVariables>(ListTeamParticipationSummariesForOrgDocument, options);
      }
export function useListTeamParticipationSummariesForOrgLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListTeamParticipationSummariesForOrgQuery, ListTeamParticipationSummariesForOrgQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListTeamParticipationSummariesForOrgQuery, ListTeamParticipationSummariesForOrgQueryVariables>(ListTeamParticipationSummariesForOrgDocument, options);
        }
export type ListTeamParticipationSummariesForOrgQueryHookResult = ReturnType<typeof useListTeamParticipationSummariesForOrgQuery>;
export type ListTeamParticipationSummariesForOrgLazyQueryHookResult = ReturnType<typeof useListTeamParticipationSummariesForOrgLazyQuery>;
export type ListTeamParticipationSummariesForOrgQueryResult = Apollo.QueryResult<ListTeamParticipationSummariesForOrgQuery, ListTeamParticipationSummariesForOrgQueryVariables>;
export const ListTeamSurveyParticipationForOrgDocument = gql`
    query listTeamSurveyParticipationForOrg($offset: Int!, $limit: Int!, $orgId: String!, $teamId: String!, $status: SurveyParticipationStatuses!, $recipientStatus: SurveyRecipientParticipationStatuses!, $leagueId: String, $seasonId: String, $fromDate: String, $toDate: String, $leagueName: String, $seasonName: String, $teamNameOrAbbreviation: String, $userStatus: UserStatuses, $coachStatus: CoachStatuses, $surveyName: String) {
  listTeamSurveyParticipationForOrg(
    input: {orgId: $orgId, teamId: $teamId, status: $status, recipientStatus: $recipientStatus, leagueId: $leagueId, seasonId: $seasonId, fromDate: $fromDate, toDate: $toDate, leagueName: $leagueName, seasonName: $seasonName, teamNameOrAbbreviation: $teamNameOrAbbreviation, userStatus: $userStatus, coachStatus: $coachStatus, surveyName: $surveyName, pagination: {offset: $offset, limit: $limit}}
  ) {
    participations {
      id
      organizationId
      organizationName
      leagueId
      leagueName
      seasonId
      seasonName
      teamId
      teamName
      teamLogo
      competitionId
      externalId
      startTime
      timeZone
      location
      homeTeamId
      homeTeamName
      homeTeamLogo
      homeTeamScore
      homeTeamTieBreakerScore
      awayTeamId
      awayTeamName
      awayTeamLogo
      awayTeamScore
      awayTeamTieBreakerScore
      competitionSurveyId
      surveyId
      surveyName
      finished
      completedDate
      lastNotificationSent
      lastNotificationTypeSent
      lastEmailSent
      tookSurvey
      userId
      userFirstName
      userLastName
      userStatus
      userEmail
      coachId
      coachType
      coachRole
      coachStatus
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListTeamSurveyParticipationForOrgQuery__
 *
 * To run a query within a React component, call `useListTeamSurveyParticipationForOrgQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTeamSurveyParticipationForOrgQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTeamSurveyParticipationForOrgQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      orgId: // value for 'orgId'
 *      teamId: // value for 'teamId'
 *      status: // value for 'status'
 *      recipientStatus: // value for 'recipientStatus'
 *      leagueId: // value for 'leagueId'
 *      seasonId: // value for 'seasonId'
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *      leagueName: // value for 'leagueName'
 *      seasonName: // value for 'seasonName'
 *      teamNameOrAbbreviation: // value for 'teamNameOrAbbreviation'
 *      userStatus: // value for 'userStatus'
 *      coachStatus: // value for 'coachStatus'
 *      surveyName: // value for 'surveyName'
 *   },
 * });
 */
export function useListTeamSurveyParticipationForOrgQuery(baseOptions: Apollo.QueryHookOptions<ListTeamSurveyParticipationForOrgQuery, ListTeamSurveyParticipationForOrgQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListTeamSurveyParticipationForOrgQuery, ListTeamSurveyParticipationForOrgQueryVariables>(ListTeamSurveyParticipationForOrgDocument, options);
      }
export function useListTeamSurveyParticipationForOrgLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListTeamSurveyParticipationForOrgQuery, ListTeamSurveyParticipationForOrgQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListTeamSurveyParticipationForOrgQuery, ListTeamSurveyParticipationForOrgQueryVariables>(ListTeamSurveyParticipationForOrgDocument, options);
        }
export type ListTeamSurveyParticipationForOrgQueryHookResult = ReturnType<typeof useListTeamSurveyParticipationForOrgQuery>;
export type ListTeamSurveyParticipationForOrgLazyQueryHookResult = ReturnType<typeof useListTeamSurveyParticipationForOrgLazyQuery>;
export type ListTeamSurveyParticipationForOrgQueryResult = Apollo.QueryResult<ListTeamSurveyParticipationForOrgQuery, ListTeamSurveyParticipationForOrgQueryVariables>;
export const ListOfficialParticipationSummariesForOrgDocument = gql`
    query listOfficialParticipationSummariesForOrg($offset: Int!, $limit: Int!, $orgId: String!, $leagueId: String, $seasonId: String, $descending: Boolean, $leagueName: String, $seasonName: String, $teamNameOrAbbreviation: String, $fromDate: String, $toDate: String, $status: SurveyParticipationStatusesFilter) {
  listOfficialParticipationSummariesForOrg(
    input: {orgId: $orgId, leagueId: $leagueId, seasonId: $seasonId, descending: $descending, leagueName: $leagueName, seasonName: $seasonName, teamNameOrAbbreviation: $teamNameOrAbbreviation, fromDate: $fromDate, toDate: $toDate, status: $status, pagination: {offset: $offset, limit: $limit}}
  ) {
    participationSummaries {
      organizationId
      organizationName
      leagueId
      leagueName
      seasonId
      seasonName
      competitionId
      externalId
      startTime
      timeZone
      location
      homeTeamId
      homeTeamName
      homeTeamLogo
      homeTeamScore
      homeTeamTieBreakerScore
      awayTeamId
      awayTeamName
      awayTeamLogo
      awayTeamScore
      awayTeamTieBreakerScore
      competitionSurveyCount
      notStartedCompetitionSurveyCount
      incompleteCompetitionSurveyCount
      completedCompetitionSurveyCount
      completionCompetitionSurveyPercentage
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListOfficialParticipationSummariesForOrgQuery__
 *
 * To run a query within a React component, call `useListOfficialParticipationSummariesForOrgQuery` and pass it any options that fit your needs.
 * When your component renders, `useListOfficialParticipationSummariesForOrgQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListOfficialParticipationSummariesForOrgQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      orgId: // value for 'orgId'
 *      leagueId: // value for 'leagueId'
 *      seasonId: // value for 'seasonId'
 *      descending: // value for 'descending'
 *      leagueName: // value for 'leagueName'
 *      seasonName: // value for 'seasonName'
 *      teamNameOrAbbreviation: // value for 'teamNameOrAbbreviation'
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useListOfficialParticipationSummariesForOrgQuery(baseOptions: Apollo.QueryHookOptions<ListOfficialParticipationSummariesForOrgQuery, ListOfficialParticipationSummariesForOrgQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListOfficialParticipationSummariesForOrgQuery, ListOfficialParticipationSummariesForOrgQueryVariables>(ListOfficialParticipationSummariesForOrgDocument, options);
      }
export function useListOfficialParticipationSummariesForOrgLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListOfficialParticipationSummariesForOrgQuery, ListOfficialParticipationSummariesForOrgQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListOfficialParticipationSummariesForOrgQuery, ListOfficialParticipationSummariesForOrgQueryVariables>(ListOfficialParticipationSummariesForOrgDocument, options);
        }
export type ListOfficialParticipationSummariesForOrgQueryHookResult = ReturnType<typeof useListOfficialParticipationSummariesForOrgQuery>;
export type ListOfficialParticipationSummariesForOrgLazyQueryHookResult = ReturnType<typeof useListOfficialParticipationSummariesForOrgLazyQuery>;
export type ListOfficialParticipationSummariesForOrgQueryResult = Apollo.QueryResult<ListOfficialParticipationSummariesForOrgQuery, ListOfficialParticipationSummariesForOrgQueryVariables>;
export const ListOfficialSurveyParticipationForOrgDocument = gql`
    query listOfficialSurveyParticipationForOrg($offset: Int!, $limit: Int!, $orgId: String!, $competitionId: String!, $status: SurveyParticipationStatuses!, $recipientStatus: SurveyRecipientParticipationStatuses!, $leagueId: String, $seasonId: String, $fromDate: String, $toDate: String, $leagueName: String, $seasonName: String, $teamNameOrAbbreviation: String, $userStatus: UserStatuses, $officialStatus: OfficialStatuses, $surveyName: String, $officialNameOrEmail: String) {
  listOfficialSurveyParticipationForOrg(
    input: {orgId: $orgId, competitionId: $competitionId, status: $status, recipientStatus: $recipientStatus, leagueId: $leagueId, seasonId: $seasonId, fromDate: $fromDate, toDate: $toDate, leagueName: $leagueName, seasonName: $seasonName, teamNameOrAbbreviation: $teamNameOrAbbreviation, userStatus: $userStatus, officialStatus: $officialStatus, surveyName: $surveyName, officialNameOrEmail: $officialNameOrEmail, pagination: {offset: $offset, limit: $limit}}
  ) {
    participations {
      id
      organizationId
      organizationName
      leagueId
      leagueName
      seasonId
      seasonName
      competitionId
      externalId
      startTime
      timeZone
      location
      homeTeamId
      homeTeamName
      homeTeamLogo
      homeTeamScore
      homeTeamTieBreakerScore
      awayTeamId
      awayTeamName
      awayTeamLogo
      awayTeamScore
      awayTeamTieBreakerScore
      competitionSurveyId
      surveyId
      surveyName
      isDynamicSurvey
      finished
      completedDate
      lastNotificationSent
      lastNotificationTypeSent
      lastEmailSent
      tookSurvey
      userId
      userFirstName
      userLastName
      userStatus
      userEmail
      competitionOfficialId
      officialId
      officialStatus
      assessmentObjectId
      assessmentObjectName
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListOfficialSurveyParticipationForOrgQuery__
 *
 * To run a query within a React component, call `useListOfficialSurveyParticipationForOrgQuery` and pass it any options that fit your needs.
 * When your component renders, `useListOfficialSurveyParticipationForOrgQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListOfficialSurveyParticipationForOrgQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      orgId: // value for 'orgId'
 *      competitionId: // value for 'competitionId'
 *      status: // value for 'status'
 *      recipientStatus: // value for 'recipientStatus'
 *      leagueId: // value for 'leagueId'
 *      seasonId: // value for 'seasonId'
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *      leagueName: // value for 'leagueName'
 *      seasonName: // value for 'seasonName'
 *      teamNameOrAbbreviation: // value for 'teamNameOrAbbreviation'
 *      userStatus: // value for 'userStatus'
 *      officialStatus: // value for 'officialStatus'
 *      surveyName: // value for 'surveyName'
 *      officialNameOrEmail: // value for 'officialNameOrEmail'
 *   },
 * });
 */
export function useListOfficialSurveyParticipationForOrgQuery(baseOptions: Apollo.QueryHookOptions<ListOfficialSurveyParticipationForOrgQuery, ListOfficialSurveyParticipationForOrgQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListOfficialSurveyParticipationForOrgQuery, ListOfficialSurveyParticipationForOrgQueryVariables>(ListOfficialSurveyParticipationForOrgDocument, options);
      }
export function useListOfficialSurveyParticipationForOrgLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListOfficialSurveyParticipationForOrgQuery, ListOfficialSurveyParticipationForOrgQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListOfficialSurveyParticipationForOrgQuery, ListOfficialSurveyParticipationForOrgQueryVariables>(ListOfficialSurveyParticipationForOrgDocument, options);
        }
export type ListOfficialSurveyParticipationForOrgQueryHookResult = ReturnType<typeof useListOfficialSurveyParticipationForOrgQuery>;
export type ListOfficialSurveyParticipationForOrgLazyQueryHookResult = ReturnType<typeof useListOfficialSurveyParticipationForOrgLazyQuery>;
export type ListOfficialSurveyParticipationForOrgQueryResult = Apollo.QueryResult<ListOfficialSurveyParticipationForOrgQuery, ListOfficialSurveyParticipationForOrgQueryVariables>;
export const ResendCompetitionSurveyNotificationEmailDocument = gql`
    mutation resendCompetitionSurveyNotificationEmail($orgId: String!, $competitionSurveyId: String!, $userId: String!, $reminderType: EmailSurveyReminderType!) {
  resendCompetitionSurveyNotificationEmail(
    input: {orgId: $orgId, competitionSurveyId: $competitionSurveyId, userId: $userId, reminderType: $reminderType}
  )
}
    `;
export type ResendCompetitionSurveyNotificationEmailMutationFn = Apollo.MutationFunction<ResendCompetitionSurveyNotificationEmailMutation, ResendCompetitionSurveyNotificationEmailMutationVariables>;

/**
 * __useResendCompetitionSurveyNotificationEmailMutation__
 *
 * To run a mutation, you first call `useResendCompetitionSurveyNotificationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendCompetitionSurveyNotificationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendCompetitionSurveyNotificationEmailMutation, { data, loading, error }] = useResendCompetitionSurveyNotificationEmailMutation({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      competitionSurveyId: // value for 'competitionSurveyId'
 *      userId: // value for 'userId'
 *      reminderType: // value for 'reminderType'
 *   },
 * });
 */
export function useResendCompetitionSurveyNotificationEmailMutation(baseOptions?: Apollo.MutationHookOptions<ResendCompetitionSurveyNotificationEmailMutation, ResendCompetitionSurveyNotificationEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendCompetitionSurveyNotificationEmailMutation, ResendCompetitionSurveyNotificationEmailMutationVariables>(ResendCompetitionSurveyNotificationEmailDocument, options);
      }
export type ResendCompetitionSurveyNotificationEmailMutationHookResult = ReturnType<typeof useResendCompetitionSurveyNotificationEmailMutation>;
export type ResendCompetitionSurveyNotificationEmailMutationResult = Apollo.MutationResult<ResendCompetitionSurveyNotificationEmailMutation>;
export type ResendCompetitionSurveyNotificationEmailMutationOptions = Apollo.BaseMutationOptions<ResendCompetitionSurveyNotificationEmailMutation, ResendCompetitionSurveyNotificationEmailMutationVariables>;
export const ResendDynamicCompetitionSurveyNotificationEmailDocument = gql`
    mutation resendDynamicCompetitionSurveyNotificationEmail($orgId: String!, $dynamicCompetitionSurveyId: String!, $userId: String!, $reminderType: EmailSurveyReminderType!) {
  resendDynamicCompetitionSurveyNotificationEmail(
    input: {orgId: $orgId, dynamicCompetitionSurveyId: $dynamicCompetitionSurveyId, userId: $userId, reminderType: $reminderType}
  )
}
    `;
export type ResendDynamicCompetitionSurveyNotificationEmailMutationFn = Apollo.MutationFunction<ResendDynamicCompetitionSurveyNotificationEmailMutation, ResendDynamicCompetitionSurveyNotificationEmailMutationVariables>;

/**
 * __useResendDynamicCompetitionSurveyNotificationEmailMutation__
 *
 * To run a mutation, you first call `useResendDynamicCompetitionSurveyNotificationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendDynamicCompetitionSurveyNotificationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendDynamicCompetitionSurveyNotificationEmailMutation, { data, loading, error }] = useResendDynamicCompetitionSurveyNotificationEmailMutation({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      dynamicCompetitionSurveyId: // value for 'dynamicCompetitionSurveyId'
 *      userId: // value for 'userId'
 *      reminderType: // value for 'reminderType'
 *   },
 * });
 */
export function useResendDynamicCompetitionSurveyNotificationEmailMutation(baseOptions?: Apollo.MutationHookOptions<ResendDynamicCompetitionSurveyNotificationEmailMutation, ResendDynamicCompetitionSurveyNotificationEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendDynamicCompetitionSurveyNotificationEmailMutation, ResendDynamicCompetitionSurveyNotificationEmailMutationVariables>(ResendDynamicCompetitionSurveyNotificationEmailDocument, options);
      }
export type ResendDynamicCompetitionSurveyNotificationEmailMutationHookResult = ReturnType<typeof useResendDynamicCompetitionSurveyNotificationEmailMutation>;
export type ResendDynamicCompetitionSurveyNotificationEmailMutationResult = Apollo.MutationResult<ResendDynamicCompetitionSurveyNotificationEmailMutation>;
export type ResendDynamicCompetitionSurveyNotificationEmailMutationOptions = Apollo.BaseMutationOptions<ResendDynamicCompetitionSurveyNotificationEmailMutation, ResendDynamicCompetitionSurveyNotificationEmailMutationVariables>;
export const RemoveCompetitionSurveyParticipationDocument = gql`
    mutation removeCompetitionSurveyParticipation($orgId: String!, $competitionSurveyId: String!, $userId: String!) {
  removeCompetitionSurveyParticipation(
    input: {orgId: $orgId, competitionSurveyId: $competitionSurveyId, userId: $userId}
  )
}
    `;
export type RemoveCompetitionSurveyParticipationMutationFn = Apollo.MutationFunction<RemoveCompetitionSurveyParticipationMutation, RemoveCompetitionSurveyParticipationMutationVariables>;

/**
 * __useRemoveCompetitionSurveyParticipationMutation__
 *
 * To run a mutation, you first call `useRemoveCompetitionSurveyParticipationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCompetitionSurveyParticipationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCompetitionSurveyParticipationMutation, { data, loading, error }] = useRemoveCompetitionSurveyParticipationMutation({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      competitionSurveyId: // value for 'competitionSurveyId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useRemoveCompetitionSurveyParticipationMutation(baseOptions?: Apollo.MutationHookOptions<RemoveCompetitionSurveyParticipationMutation, RemoveCompetitionSurveyParticipationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveCompetitionSurveyParticipationMutation, RemoveCompetitionSurveyParticipationMutationVariables>(RemoveCompetitionSurveyParticipationDocument, options);
      }
export type RemoveCompetitionSurveyParticipationMutationHookResult = ReturnType<typeof useRemoveCompetitionSurveyParticipationMutation>;
export type RemoveCompetitionSurveyParticipationMutationResult = Apollo.MutationResult<RemoveCompetitionSurveyParticipationMutation>;
export type RemoveCompetitionSurveyParticipationMutationOptions = Apollo.BaseMutationOptions<RemoveCompetitionSurveyParticipationMutation, RemoveCompetitionSurveyParticipationMutationVariables>;
export const ResetTookCompetitionSurveyDocument = gql`
    mutation resetTookCompetitionSurvey($orgId: String!, $competitionSurveyId: String!) {
  resetTookCompetitionSurvey(
    input: {orgId: $orgId, competitionSurveyId: $competitionSurveyId}
  )
}
    `;
export type ResetTookCompetitionSurveyMutationFn = Apollo.MutationFunction<ResetTookCompetitionSurveyMutation, ResetTookCompetitionSurveyMutationVariables>;

/**
 * __useResetTookCompetitionSurveyMutation__
 *
 * To run a mutation, you first call `useResetTookCompetitionSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetTookCompetitionSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetTookCompetitionSurveyMutation, { data, loading, error }] = useResetTookCompetitionSurveyMutation({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      competitionSurveyId: // value for 'competitionSurveyId'
 *   },
 * });
 */
export function useResetTookCompetitionSurveyMutation(baseOptions?: Apollo.MutationHookOptions<ResetTookCompetitionSurveyMutation, ResetTookCompetitionSurveyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetTookCompetitionSurveyMutation, ResetTookCompetitionSurveyMutationVariables>(ResetTookCompetitionSurveyDocument, options);
      }
export type ResetTookCompetitionSurveyMutationHookResult = ReturnType<typeof useResetTookCompetitionSurveyMutation>;
export type ResetTookCompetitionSurveyMutationResult = Apollo.MutationResult<ResetTookCompetitionSurveyMutation>;
export type ResetTookCompetitionSurveyMutationOptions = Apollo.BaseMutationOptions<ResetTookCompetitionSurveyMutation, ResetTookCompetitionSurveyMutationVariables>;
export const ReopenCompetitionSurveyDocument = gql`
    mutation reopenCompetitionSurvey($orgId: String!, $competitionSurveyId: String!) {
  reopenCompetitionSurvey(
    input: {orgId: $orgId, competitionSurveyId: $competitionSurveyId}
  )
}
    `;
export type ReopenCompetitionSurveyMutationFn = Apollo.MutationFunction<ReopenCompetitionSurveyMutation, ReopenCompetitionSurveyMutationVariables>;

/**
 * __useReopenCompetitionSurveyMutation__
 *
 * To run a mutation, you first call `useReopenCompetitionSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReopenCompetitionSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reopenCompetitionSurveyMutation, { data, loading, error }] = useReopenCompetitionSurveyMutation({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      competitionSurveyId: // value for 'competitionSurveyId'
 *   },
 * });
 */
export function useReopenCompetitionSurveyMutation(baseOptions?: Apollo.MutationHookOptions<ReopenCompetitionSurveyMutation, ReopenCompetitionSurveyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReopenCompetitionSurveyMutation, ReopenCompetitionSurveyMutationVariables>(ReopenCompetitionSurveyDocument, options);
      }
export type ReopenCompetitionSurveyMutationHookResult = ReturnType<typeof useReopenCompetitionSurveyMutation>;
export type ReopenCompetitionSurveyMutationResult = Apollo.MutationResult<ReopenCompetitionSurveyMutation>;
export type ReopenCompetitionSurveyMutationOptions = Apollo.BaseMutationOptions<ReopenCompetitionSurveyMutation, ReopenCompetitionSurveyMutationVariables>;
export const ResetTookDynamicCompetitionSurveyDocument = gql`
    mutation resetTookDynamicCompetitionSurvey($orgId: String!, $dynamicCompetitionSurveyId: String!) {
  resetTookDynamicCompetitionSurvey(
    input: {orgId: $orgId, dynamicCompetitionSurveyId: $dynamicCompetitionSurveyId}
  )
}
    `;
export type ResetTookDynamicCompetitionSurveyMutationFn = Apollo.MutationFunction<ResetTookDynamicCompetitionSurveyMutation, ResetTookDynamicCompetitionSurveyMutationVariables>;

/**
 * __useResetTookDynamicCompetitionSurveyMutation__
 *
 * To run a mutation, you first call `useResetTookDynamicCompetitionSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetTookDynamicCompetitionSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetTookDynamicCompetitionSurveyMutation, { data, loading, error }] = useResetTookDynamicCompetitionSurveyMutation({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      dynamicCompetitionSurveyId: // value for 'dynamicCompetitionSurveyId'
 *   },
 * });
 */
export function useResetTookDynamicCompetitionSurveyMutation(baseOptions?: Apollo.MutationHookOptions<ResetTookDynamicCompetitionSurveyMutation, ResetTookDynamicCompetitionSurveyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetTookDynamicCompetitionSurveyMutation, ResetTookDynamicCompetitionSurveyMutationVariables>(ResetTookDynamicCompetitionSurveyDocument, options);
      }
export type ResetTookDynamicCompetitionSurveyMutationHookResult = ReturnType<typeof useResetTookDynamicCompetitionSurveyMutation>;
export type ResetTookDynamicCompetitionSurveyMutationResult = Apollo.MutationResult<ResetTookDynamicCompetitionSurveyMutation>;
export type ResetTookDynamicCompetitionSurveyMutationOptions = Apollo.BaseMutationOptions<ResetTookDynamicCompetitionSurveyMutation, ResetTookDynamicCompetitionSurveyMutationVariables>;
export const ReopenDynamicCompetitionSurveyDocument = gql`
    mutation reopenDynamicCompetitionSurvey($orgId: String!, $dynamicCompetitionSurveyId: String!) {
  reopenDynamicCompetitionSurvey(
    input: {orgId: $orgId, dynamicCompetitionSurveyId: $dynamicCompetitionSurveyId}
  )
}
    `;
export type ReopenDynamicCompetitionSurveyMutationFn = Apollo.MutationFunction<ReopenDynamicCompetitionSurveyMutation, ReopenDynamicCompetitionSurveyMutationVariables>;

/**
 * __useReopenDynamicCompetitionSurveyMutation__
 *
 * To run a mutation, you first call `useReopenDynamicCompetitionSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReopenDynamicCompetitionSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reopenDynamicCompetitionSurveyMutation, { data, loading, error }] = useReopenDynamicCompetitionSurveyMutation({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      dynamicCompetitionSurveyId: // value for 'dynamicCompetitionSurveyId'
 *   },
 * });
 */
export function useReopenDynamicCompetitionSurveyMutation(baseOptions?: Apollo.MutationHookOptions<ReopenDynamicCompetitionSurveyMutation, ReopenDynamicCompetitionSurveyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReopenDynamicCompetitionSurveyMutation, ReopenDynamicCompetitionSurveyMutationVariables>(ReopenDynamicCompetitionSurveyDocument, options);
      }
export type ReopenDynamicCompetitionSurveyMutationHookResult = ReturnType<typeof useReopenDynamicCompetitionSurveyMutation>;
export type ReopenDynamicCompetitionSurveyMutationResult = Apollo.MutationResult<ReopenDynamicCompetitionSurveyMutation>;
export type ReopenDynamicCompetitionSurveyMutationOptions = Apollo.BaseMutationOptions<ReopenDynamicCompetitionSurveyMutation, ReopenDynamicCompetitionSurveyMutationVariables>;
export const CreateSeasonDocument = gql`
    mutation createSeason($leagueId: String!, $name: String!, $description: String, $startDate: Date, $endDate: Date) {
  createSeason(
    input: {leagueId: $leagueId, name: $name, description: $description, startDate: $startDate, endDate: $endDate}
  ) {
    id
    leagueId
    leagueName
    leagueLogo
    teamKitEnabled
    competitionIntegrationEnabled
    mediaMembersEnabled
    name
    description
    status
    startDate
    endDate
    createdAt
    updatedAt
  }
}
    `;
export type CreateSeasonMutationFn = Apollo.MutationFunction<CreateSeasonMutation, CreateSeasonMutationVariables>;

/**
 * __useCreateSeasonMutation__
 *
 * To run a mutation, you first call `useCreateSeasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSeasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSeasonMutation, { data, loading, error }] = useCreateSeasonMutation({
 *   variables: {
 *      leagueId: // value for 'leagueId'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useCreateSeasonMutation(baseOptions?: Apollo.MutationHookOptions<CreateSeasonMutation, CreateSeasonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSeasonMutation, CreateSeasonMutationVariables>(CreateSeasonDocument, options);
      }
export type CreateSeasonMutationHookResult = ReturnType<typeof useCreateSeasonMutation>;
export type CreateSeasonMutationResult = Apollo.MutationResult<CreateSeasonMutation>;
export type CreateSeasonMutationOptions = Apollo.BaseMutationOptions<CreateSeasonMutation, CreateSeasonMutationVariables>;
export const GetSeasonByIdDocument = gql`
    query getSeasonById($id: String!) {
  getSeasonById(input: {id: $id}) {
    id
    leagueId
    leagueName
    leagueLogo
    teamKitEnabled
    competitionIntegrationEnabled
    mediaMembersEnabled
    name
    description
    status
    startDate
    endDate
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetSeasonByIdQuery__
 *
 * To run a query within a React component, call `useGetSeasonByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSeasonByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSeasonByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSeasonByIdQuery(baseOptions: Apollo.QueryHookOptions<GetSeasonByIdQuery, GetSeasonByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSeasonByIdQuery, GetSeasonByIdQueryVariables>(GetSeasonByIdDocument, options);
      }
export function useGetSeasonByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSeasonByIdQuery, GetSeasonByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSeasonByIdQuery, GetSeasonByIdQueryVariables>(GetSeasonByIdDocument, options);
        }
export type GetSeasonByIdQueryHookResult = ReturnType<typeof useGetSeasonByIdQuery>;
export type GetSeasonByIdLazyQueryHookResult = ReturnType<typeof useGetSeasonByIdLazyQuery>;
export type GetSeasonByIdQueryResult = Apollo.QueryResult<GetSeasonByIdQuery, GetSeasonByIdQueryVariables>;
export const ListSeasonsForLeagueDocument = gql`
    query listSeasonsForLeague($offset: Int!, $limit: Int!, $leagueId: String!, $status: SeasonStatuses, $name: String) {
  listSeasonsForLeague(
    input: {leagueId: $leagueId, status: $status, name: $name, pagination: {offset: $offset, limit: $limit}}
  ) {
    seasons {
      id
      leagueId
      leagueName
      leagueLogo
      teamKitEnabled
      competitionIntegrationEnabled
      mediaMembersEnabled
      name
      description
      status
      startDate
      endDate
      createdAt
      updatedAt
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListSeasonsForLeagueQuery__
 *
 * To run a query within a React component, call `useListSeasonsForLeagueQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSeasonsForLeagueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSeasonsForLeagueQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      leagueId: // value for 'leagueId'
 *      status: // value for 'status'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useListSeasonsForLeagueQuery(baseOptions: Apollo.QueryHookOptions<ListSeasonsForLeagueQuery, ListSeasonsForLeagueQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListSeasonsForLeagueQuery, ListSeasonsForLeagueQueryVariables>(ListSeasonsForLeagueDocument, options);
      }
export function useListSeasonsForLeagueLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListSeasonsForLeagueQuery, ListSeasonsForLeagueQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListSeasonsForLeagueQuery, ListSeasonsForLeagueQueryVariables>(ListSeasonsForLeagueDocument, options);
        }
export type ListSeasonsForLeagueQueryHookResult = ReturnType<typeof useListSeasonsForLeagueQuery>;
export type ListSeasonsForLeagueLazyQueryHookResult = ReturnType<typeof useListSeasonsForLeagueLazyQuery>;
export type ListSeasonsForLeagueQueryResult = Apollo.QueryResult<ListSeasonsForLeagueQuery, ListSeasonsForLeagueQueryVariables>;
export const DeleteSeasonDocument = gql`
    mutation deleteSeason($id: String!) {
  deleteSeason(input: {id: $id})
}
    `;
export type DeleteSeasonMutationFn = Apollo.MutationFunction<DeleteSeasonMutation, DeleteSeasonMutationVariables>;

/**
 * __useDeleteSeasonMutation__
 *
 * To run a mutation, you first call `useDeleteSeasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSeasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSeasonMutation, { data, loading, error }] = useDeleteSeasonMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSeasonMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSeasonMutation, DeleteSeasonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSeasonMutation, DeleteSeasonMutationVariables>(DeleteSeasonDocument, options);
      }
export type DeleteSeasonMutationHookResult = ReturnType<typeof useDeleteSeasonMutation>;
export type DeleteSeasonMutationResult = Apollo.MutationResult<DeleteSeasonMutation>;
export type DeleteSeasonMutationOptions = Apollo.BaseMutationOptions<DeleteSeasonMutation, DeleteSeasonMutationVariables>;
export const UpdateSeasonDocument = gql`
    mutation updateSeason($id: String!, $name: String!, $description: String, $startDate: Date, $endDate: Date) {
  updateSeason(
    input: {id: $id, name: $name, description: $description, startDate: $startDate, endDate: $endDate}
  ) {
    id
    leagueId
    leagueName
    leagueLogo
    teamKitEnabled
    competitionIntegrationEnabled
    mediaMembersEnabled
    name
    description
    status
    startDate
    endDate
    createdAt
    updatedAt
  }
}
    `;
export type UpdateSeasonMutationFn = Apollo.MutationFunction<UpdateSeasonMutation, UpdateSeasonMutationVariables>;

/**
 * __useUpdateSeasonMutation__
 *
 * To run a mutation, you first call `useUpdateSeasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSeasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSeasonMutation, { data, loading, error }] = useUpdateSeasonMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useUpdateSeasonMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSeasonMutation, UpdateSeasonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSeasonMutation, UpdateSeasonMutationVariables>(UpdateSeasonDocument, options);
      }
export type UpdateSeasonMutationHookResult = ReturnType<typeof useUpdateSeasonMutation>;
export type UpdateSeasonMutationResult = Apollo.MutationResult<UpdateSeasonMutation>;
export type UpdateSeasonMutationOptions = Apollo.BaseMutationOptions<UpdateSeasonMutation, UpdateSeasonMutationVariables>;
export const ActivateSeasonDocument = gql`
    mutation activateSeason($id: String!, $sendCoachEmails: Boolean!, $sendOfficialEmails: Boolean!) {
  activateSeason(
    input: {id: $id, sendCoachEmails: $sendCoachEmails, sendOfficialEmails: $sendOfficialEmails}
  ) {
    id
    leagueId
    leagueName
    leagueLogo
    teamKitEnabled
    competitionIntegrationEnabled
    mediaMembersEnabled
    name
    description
    status
    startDate
    endDate
    createdAt
    updatedAt
  }
}
    `;
export type ActivateSeasonMutationFn = Apollo.MutationFunction<ActivateSeasonMutation, ActivateSeasonMutationVariables>;

/**
 * __useActivateSeasonMutation__
 *
 * To run a mutation, you first call `useActivateSeasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateSeasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateSeasonMutation, { data, loading, error }] = useActivateSeasonMutation({
 *   variables: {
 *      id: // value for 'id'
 *      sendCoachEmails: // value for 'sendCoachEmails'
 *      sendOfficialEmails: // value for 'sendOfficialEmails'
 *   },
 * });
 */
export function useActivateSeasonMutation(baseOptions?: Apollo.MutationHookOptions<ActivateSeasonMutation, ActivateSeasonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateSeasonMutation, ActivateSeasonMutationVariables>(ActivateSeasonDocument, options);
      }
export type ActivateSeasonMutationHookResult = ReturnType<typeof useActivateSeasonMutation>;
export type ActivateSeasonMutationResult = Apollo.MutationResult<ActivateSeasonMutation>;
export type ActivateSeasonMutationOptions = Apollo.BaseMutationOptions<ActivateSeasonMutation, ActivateSeasonMutationVariables>;
export const DeactivateSeasonDocument = gql`
    mutation deactivateSeason($id: String!) {
  deactivateSeason(input: {id: $id})
}
    `;
export type DeactivateSeasonMutationFn = Apollo.MutationFunction<DeactivateSeasonMutation, DeactivateSeasonMutationVariables>;

/**
 * __useDeactivateSeasonMutation__
 *
 * To run a mutation, you first call `useDeactivateSeasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateSeasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateSeasonMutation, { data, loading, error }] = useDeactivateSeasonMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeactivateSeasonMutation(baseOptions?: Apollo.MutationHookOptions<DeactivateSeasonMutation, DeactivateSeasonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeactivateSeasonMutation, DeactivateSeasonMutationVariables>(DeactivateSeasonDocument, options);
      }
export type DeactivateSeasonMutationHookResult = ReturnType<typeof useDeactivateSeasonMutation>;
export type DeactivateSeasonMutationResult = Apollo.MutationResult<DeactivateSeasonMutation>;
export type DeactivateSeasonMutationOptions = Apollo.BaseMutationOptions<DeactivateSeasonMutation, DeactivateSeasonMutationVariables>;
export const GetSeasonSetupInfoDocument = gql`
    query getSeasonSetupInfo($seasonId: String!) {
  getSeasonSetupInfo(input: {seasonId: $seasonId}) {
    seasonId
    leagueId
    leagueName
    name
    status
    periodCount
    divisionCount
    divisionsWithoutTeamsCount
    teamsWithoutDivisionsCount
    competitionOfficialCount
    teamCount
    coachCount
    officialCount
    surveyCount
    competitionCount
    leagueMembershipCount
  }
}
    `;

/**
 * __useGetSeasonSetupInfoQuery__
 *
 * To run a query within a React component, call `useGetSeasonSetupInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSeasonSetupInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSeasonSetupInfoQuery({
 *   variables: {
 *      seasonId: // value for 'seasonId'
 *   },
 * });
 */
export function useGetSeasonSetupInfoQuery(baseOptions: Apollo.QueryHookOptions<GetSeasonSetupInfoQuery, GetSeasonSetupInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSeasonSetupInfoQuery, GetSeasonSetupInfoQueryVariables>(GetSeasonSetupInfoDocument, options);
      }
export function useGetSeasonSetupInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSeasonSetupInfoQuery, GetSeasonSetupInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSeasonSetupInfoQuery, GetSeasonSetupInfoQueryVariables>(GetSeasonSetupInfoDocument, options);
        }
export type GetSeasonSetupInfoQueryHookResult = ReturnType<typeof useGetSeasonSetupInfoQuery>;
export type GetSeasonSetupInfoLazyQueryHookResult = ReturnType<typeof useGetSeasonSetupInfoLazyQuery>;
export type GetSeasonSetupInfoQueryResult = Apollo.QueryResult<GetSeasonSetupInfoQuery, GetSeasonSetupInfoQueryVariables>;
export const ListTeamsForSeasonDocument = gql`
    query listTeamsForSeason($offset: Int!, $limit: Int!, $seasonId: String!, $search: String, $countryCodes: [CountryCodes!], $classifications: [TeamClassifications!], $ageGroup: String, $parentOrganizationName: String) {
  listTeamsForSeason(
    input: {seasonId: $seasonId, search: $search, countryCodes: $countryCodes, classifications: $classifications, ageGroup: $ageGroup, parentOrganizationName: $parentOrganizationName, pagination: {offset: $offset, limit: $limit}}
  ) {
    seasonId
    teams {
      id
      seasonId
      team {
        id
        name
        abbreviation
        sport
        city
        stateAbbreviation
        countryCode
        classification
        gender
        parentOrganizationName
        externalId
        ageGroup
        timeZone
        website
        logo
        createdAt
        updatedAt
      }
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListTeamsForSeasonQuery__
 *
 * To run a query within a React component, call `useListTeamsForSeasonQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTeamsForSeasonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTeamsForSeasonQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      seasonId: // value for 'seasonId'
 *      search: // value for 'search'
 *      countryCodes: // value for 'countryCodes'
 *      classifications: // value for 'classifications'
 *      ageGroup: // value for 'ageGroup'
 *      parentOrganizationName: // value for 'parentOrganizationName'
 *   },
 * });
 */
export function useListTeamsForSeasonQuery(baseOptions: Apollo.QueryHookOptions<ListTeamsForSeasonQuery, ListTeamsForSeasonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListTeamsForSeasonQuery, ListTeamsForSeasonQueryVariables>(ListTeamsForSeasonDocument, options);
      }
export function useListTeamsForSeasonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListTeamsForSeasonQuery, ListTeamsForSeasonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListTeamsForSeasonQuery, ListTeamsForSeasonQueryVariables>(ListTeamsForSeasonDocument, options);
        }
export type ListTeamsForSeasonQueryHookResult = ReturnType<typeof useListTeamsForSeasonQuery>;
export type ListTeamsForSeasonLazyQueryHookResult = ReturnType<typeof useListTeamsForSeasonLazyQuery>;
export type ListTeamsForSeasonQueryResult = Apollo.QueryResult<ListTeamsForSeasonQuery, ListTeamsForSeasonQueryVariables>;
export const RemoveTeamFromSeasonDocument = gql`
    mutation removeTeamFromSeason($seasonId: String!, $teamId: String!) {
  removeTeamFromSeason(input: {seasonId: $seasonId, teamId: $teamId})
}
    `;
export type RemoveTeamFromSeasonMutationFn = Apollo.MutationFunction<RemoveTeamFromSeasonMutation, RemoveTeamFromSeasonMutationVariables>;

/**
 * __useRemoveTeamFromSeasonMutation__
 *
 * To run a mutation, you first call `useRemoveTeamFromSeasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTeamFromSeasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTeamFromSeasonMutation, { data, loading, error }] = useRemoveTeamFromSeasonMutation({
 *   variables: {
 *      seasonId: // value for 'seasonId'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useRemoveTeamFromSeasonMutation(baseOptions?: Apollo.MutationHookOptions<RemoveTeamFromSeasonMutation, RemoveTeamFromSeasonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveTeamFromSeasonMutation, RemoveTeamFromSeasonMutationVariables>(RemoveTeamFromSeasonDocument, options);
      }
export type RemoveTeamFromSeasonMutationHookResult = ReturnType<typeof useRemoveTeamFromSeasonMutation>;
export type RemoveTeamFromSeasonMutationResult = Apollo.MutationResult<RemoveTeamFromSeasonMutation>;
export type RemoveTeamFromSeasonMutationOptions = Apollo.BaseMutationOptions<RemoveTeamFromSeasonMutation, RemoveTeamFromSeasonMutationVariables>;
export const ListCoachesForSeasonDocument = gql`
    query listCoachesForSeason($offset: Int!, $limit: Int!, $seasonId: String!, $search: String, $type: CoachTypes, $status: CoachStatuses, $role: String, $team: String, $userStatus: UserStatuses) {
  listCoachesForSeason(
    input: {seasonId: $seasonId, search: $search, type: $type, status: $status, role: $role, team: $team, userStatus: $userStatus, pagination: {offset: $offset, limit: $limit}}
  ) {
    seasonId
    coaches {
      id
      teamId
      teamName
      teamAbbreviation
      sport
      teamCity
      userId
      type
      role
      status
      email
      firstName
      lastName
      profilePic
      userStatus
      createdAt
      updatedAt
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListCoachesForSeasonQuery__
 *
 * To run a query within a React component, call `useListCoachesForSeasonQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCoachesForSeasonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCoachesForSeasonQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      seasonId: // value for 'seasonId'
 *      search: // value for 'search'
 *      type: // value for 'type'
 *      status: // value for 'status'
 *      role: // value for 'role'
 *      team: // value for 'team'
 *      userStatus: // value for 'userStatus'
 *   },
 * });
 */
export function useListCoachesForSeasonQuery(baseOptions: Apollo.QueryHookOptions<ListCoachesForSeasonQuery, ListCoachesForSeasonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListCoachesForSeasonQuery, ListCoachesForSeasonQueryVariables>(ListCoachesForSeasonDocument, options);
      }
export function useListCoachesForSeasonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListCoachesForSeasonQuery, ListCoachesForSeasonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListCoachesForSeasonQuery, ListCoachesForSeasonQueryVariables>(ListCoachesForSeasonDocument, options);
        }
export type ListCoachesForSeasonQueryHookResult = ReturnType<typeof useListCoachesForSeasonQuery>;
export type ListCoachesForSeasonLazyQueryHookResult = ReturnType<typeof useListCoachesForSeasonLazyQuery>;
export type ListCoachesForSeasonQueryResult = Apollo.QueryResult<ListCoachesForSeasonQuery, ListCoachesForSeasonQueryVariables>;
export const CreateNewSeasonCoachDocument = gql`
    mutation createNewSeasonCoach($seasonId: String!, $teamId: String!, $email: String!, $firstName: String!, $lastName: String!, $type: CoachTypes!, $status: CoachStatuses!, $addToOpenSurveys: Boolean!, $role: String) {
  createNewSeasonCoach(
    input: {seasonId: $seasonId, teamId: $teamId, email: $email, firstName: $firstName, lastName: $lastName, type: $type, status: $status, addToOpenSurveys: $addToOpenSurveys, role: $role}
  ) {
    id
    teamId
    teamName
    teamAbbreviation
    sport
    teamCity
    userId
    type
    role
    status
    email
    firstName
    lastName
    profilePic
    userStatus
    createdAt
    updatedAt
  }
}
    `;
export type CreateNewSeasonCoachMutationFn = Apollo.MutationFunction<CreateNewSeasonCoachMutation, CreateNewSeasonCoachMutationVariables>;

/**
 * __useCreateNewSeasonCoachMutation__
 *
 * To run a mutation, you first call `useCreateNewSeasonCoachMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewSeasonCoachMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewSeasonCoachMutation, { data, loading, error }] = useCreateNewSeasonCoachMutation({
 *   variables: {
 *      seasonId: // value for 'seasonId'
 *      teamId: // value for 'teamId'
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      type: // value for 'type'
 *      status: // value for 'status'
 *      addToOpenSurveys: // value for 'addToOpenSurveys'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useCreateNewSeasonCoachMutation(baseOptions?: Apollo.MutationHookOptions<CreateNewSeasonCoachMutation, CreateNewSeasonCoachMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNewSeasonCoachMutation, CreateNewSeasonCoachMutationVariables>(CreateNewSeasonCoachDocument, options);
      }
export type CreateNewSeasonCoachMutationHookResult = ReturnType<typeof useCreateNewSeasonCoachMutation>;
export type CreateNewSeasonCoachMutationResult = Apollo.MutationResult<CreateNewSeasonCoachMutation>;
export type CreateNewSeasonCoachMutationOptions = Apollo.BaseMutationOptions<CreateNewSeasonCoachMutation, CreateNewSeasonCoachMutationVariables>;
export const ListOfficialsForSeasonDocument = gql`
    query listOfficialsForSeason($offset: Int!, $limit: Int!, $seasonId: String!, $nameOrEmail: String, $status: [OfficialStatuses!], $userStatus: [UserStatuses!], $externalId: String) {
  listOfficialsForSeason(
    input: {seasonId: $seasonId, nameOrEmail: $nameOrEmail, status: $status, userStatus: $userStatus, externalId: $externalId, pagination: {offset: $offset, limit: $limit}}
  ) {
    seasonId
    officials {
      id
      seasonId
      official {
        id
        sport
        userId
        status
        userStatus
        email
        firstName
        lastName
        externalIds {
          id
          organizationId
          externalId
        }
        profilePic
        createdAt
        updatedAt
      }
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListOfficialsForSeasonQuery__
 *
 * To run a query within a React component, call `useListOfficialsForSeasonQuery` and pass it any options that fit your needs.
 * When your component renders, `useListOfficialsForSeasonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListOfficialsForSeasonQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      seasonId: // value for 'seasonId'
 *      nameOrEmail: // value for 'nameOrEmail'
 *      status: // value for 'status'
 *      userStatus: // value for 'userStatus'
 *      externalId: // value for 'externalId'
 *   },
 * });
 */
export function useListOfficialsForSeasonQuery(baseOptions: Apollo.QueryHookOptions<ListOfficialsForSeasonQuery, ListOfficialsForSeasonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListOfficialsForSeasonQuery, ListOfficialsForSeasonQueryVariables>(ListOfficialsForSeasonDocument, options);
      }
export function useListOfficialsForSeasonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListOfficialsForSeasonQuery, ListOfficialsForSeasonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListOfficialsForSeasonQuery, ListOfficialsForSeasonQueryVariables>(ListOfficialsForSeasonDocument, options);
        }
export type ListOfficialsForSeasonQueryHookResult = ReturnType<typeof useListOfficialsForSeasonQuery>;
export type ListOfficialsForSeasonLazyQueryHookResult = ReturnType<typeof useListOfficialsForSeasonLazyQuery>;
export type ListOfficialsForSeasonQueryResult = Apollo.QueryResult<ListOfficialsForSeasonQuery, ListOfficialsForSeasonQueryVariables>;
export const CreateNewOfficialForSeasonDocument = gql`
    mutation createNewOfficialForSeason($seasonId: String!, $email: String, $firstName: String!, $lastName: String!, $sendEmail: Boolean!, $externalId: String) {
  createNewOfficialForSeason(
    input: {seasonId: $seasonId, email: $email, firstName: $firstName, lastName: $lastName, sendEmail: $sendEmail, externalId: $externalId}
  ) {
    seasonOfficial {
      id
      seasonId
      official {
        id
        sport
        userId
        status
        email
        firstName
        lastName
        externalIds {
          id
          organizationId
          externalId
        }
        profilePic
        createdAt
        updatedAt
      }
    }
    emailSent
    newUser
  }
}
    `;
export type CreateNewOfficialForSeasonMutationFn = Apollo.MutationFunction<CreateNewOfficialForSeasonMutation, CreateNewOfficialForSeasonMutationVariables>;

/**
 * __useCreateNewOfficialForSeasonMutation__
 *
 * To run a mutation, you first call `useCreateNewOfficialForSeasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewOfficialForSeasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewOfficialForSeasonMutation, { data, loading, error }] = useCreateNewOfficialForSeasonMutation({
 *   variables: {
 *      seasonId: // value for 'seasonId'
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      sendEmail: // value for 'sendEmail'
 *      externalId: // value for 'externalId'
 *   },
 * });
 */
export function useCreateNewOfficialForSeasonMutation(baseOptions?: Apollo.MutationHookOptions<CreateNewOfficialForSeasonMutation, CreateNewOfficialForSeasonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNewOfficialForSeasonMutation, CreateNewOfficialForSeasonMutationVariables>(CreateNewOfficialForSeasonDocument, options);
      }
export type CreateNewOfficialForSeasonMutationHookResult = ReturnType<typeof useCreateNewOfficialForSeasonMutation>;
export type CreateNewOfficialForSeasonMutationResult = Apollo.MutationResult<CreateNewOfficialForSeasonMutation>;
export type CreateNewOfficialForSeasonMutationOptions = Apollo.BaseMutationOptions<CreateNewOfficialForSeasonMutation, CreateNewOfficialForSeasonMutationVariables>;
export const AddOfficialToSeasonDocument = gql`
    mutation addOfficialToSeason($seasonId: String!, $officialId: String!, $sendEmail: Boolean!) {
  addOfficialToSeason(
    input: {seasonId: $seasonId, officialId: $officialId, sendEmail: $sendEmail}
  ) {
    id
    seasonId
    official {
      id
      sport
      userId
      status
      email
      firstName
      lastName
      externalIds {
        id
        organizationId
        externalId
      }
      profilePic
      createdAt
      updatedAt
    }
  }
}
    `;
export type AddOfficialToSeasonMutationFn = Apollo.MutationFunction<AddOfficialToSeasonMutation, AddOfficialToSeasonMutationVariables>;

/**
 * __useAddOfficialToSeasonMutation__
 *
 * To run a mutation, you first call `useAddOfficialToSeasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOfficialToSeasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOfficialToSeasonMutation, { data, loading, error }] = useAddOfficialToSeasonMutation({
 *   variables: {
 *      seasonId: // value for 'seasonId'
 *      officialId: // value for 'officialId'
 *      sendEmail: // value for 'sendEmail'
 *   },
 * });
 */
export function useAddOfficialToSeasonMutation(baseOptions?: Apollo.MutationHookOptions<AddOfficialToSeasonMutation, AddOfficialToSeasonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddOfficialToSeasonMutation, AddOfficialToSeasonMutationVariables>(AddOfficialToSeasonDocument, options);
      }
export type AddOfficialToSeasonMutationHookResult = ReturnType<typeof useAddOfficialToSeasonMutation>;
export type AddOfficialToSeasonMutationResult = Apollo.MutationResult<AddOfficialToSeasonMutation>;
export type AddOfficialToSeasonMutationOptions = Apollo.BaseMutationOptions<AddOfficialToSeasonMutation, AddOfficialToSeasonMutationVariables>;
export const RemoveOfficialFromSeasonDocument = gql`
    mutation removeOfficialFromSeason($seasonId: String!, $officialId: String!) {
  removeOfficialFromSeason(input: {seasonId: $seasonId, officialId: $officialId})
}
    `;
export type RemoveOfficialFromSeasonMutationFn = Apollo.MutationFunction<RemoveOfficialFromSeasonMutation, RemoveOfficialFromSeasonMutationVariables>;

/**
 * __useRemoveOfficialFromSeasonMutation__
 *
 * To run a mutation, you first call `useRemoveOfficialFromSeasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOfficialFromSeasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeOfficialFromSeasonMutation, { data, loading, error }] = useRemoveOfficialFromSeasonMutation({
 *   variables: {
 *      seasonId: // value for 'seasonId'
 *      officialId: // value for 'officialId'
 *   },
 * });
 */
export function useRemoveOfficialFromSeasonMutation(baseOptions?: Apollo.MutationHookOptions<RemoveOfficialFromSeasonMutation, RemoveOfficialFromSeasonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveOfficialFromSeasonMutation, RemoveOfficialFromSeasonMutationVariables>(RemoveOfficialFromSeasonDocument, options);
      }
export type RemoveOfficialFromSeasonMutationHookResult = ReturnType<typeof useRemoveOfficialFromSeasonMutation>;
export type RemoveOfficialFromSeasonMutationResult = Apollo.MutationResult<RemoveOfficialFromSeasonMutation>;
export type RemoveOfficialFromSeasonMutationOptions = Apollo.BaseMutationOptions<RemoveOfficialFromSeasonMutation, RemoveOfficialFromSeasonMutationVariables>;
export const InviteSeasonOfficialUserDocument = gql`
    mutation inviteSeasonOfficialUser($seasonId: String!, $officialId: String!) {
  inviteSeasonOfficialUser(input: {seasonId: $seasonId, officialId: $officialId})
}
    `;
export type InviteSeasonOfficialUserMutationFn = Apollo.MutationFunction<InviteSeasonOfficialUserMutation, InviteSeasonOfficialUserMutationVariables>;

/**
 * __useInviteSeasonOfficialUserMutation__
 *
 * To run a mutation, you first call `useInviteSeasonOfficialUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteSeasonOfficialUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteSeasonOfficialUserMutation, { data, loading, error }] = useInviteSeasonOfficialUserMutation({
 *   variables: {
 *      seasonId: // value for 'seasonId'
 *      officialId: // value for 'officialId'
 *   },
 * });
 */
export function useInviteSeasonOfficialUserMutation(baseOptions?: Apollo.MutationHookOptions<InviteSeasonOfficialUserMutation, InviteSeasonOfficialUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InviteSeasonOfficialUserMutation, InviteSeasonOfficialUserMutationVariables>(InviteSeasonOfficialUserDocument, options);
      }
export type InviteSeasonOfficialUserMutationHookResult = ReturnType<typeof useInviteSeasonOfficialUserMutation>;
export type InviteSeasonOfficialUserMutationResult = Apollo.MutationResult<InviteSeasonOfficialUserMutation>;
export type InviteSeasonOfficialUserMutationOptions = Apollo.BaseMutationOptions<InviteSeasonOfficialUserMutation, InviteSeasonOfficialUserMutationVariables>;
export const AddOfficialAssessmentObjectToSeasonDocument = gql`
    mutation addOfficialAssessmentObjectToSeason($seasonId: String!, $assessmentObjectId: String!, $order: Int!, $isOptional: Boolean!) {
  addOfficialAssessmentObjectToSeason(
    input: {seasonId: $seasonId, assessmentObjectId: $assessmentObjectId, order: $order, isOptional: $isOptional}
  ) {
    id
    seasonId
    order
    isOptional
    assessmentObject {
      id
      sport
      type
      classification
      name
      createdAt
      updatedAt
    }
  }
}
    `;
export type AddOfficialAssessmentObjectToSeasonMutationFn = Apollo.MutationFunction<AddOfficialAssessmentObjectToSeasonMutation, AddOfficialAssessmentObjectToSeasonMutationVariables>;

/**
 * __useAddOfficialAssessmentObjectToSeasonMutation__
 *
 * To run a mutation, you first call `useAddOfficialAssessmentObjectToSeasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOfficialAssessmentObjectToSeasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOfficialAssessmentObjectToSeasonMutation, { data, loading, error }] = useAddOfficialAssessmentObjectToSeasonMutation({
 *   variables: {
 *      seasonId: // value for 'seasonId'
 *      assessmentObjectId: // value for 'assessmentObjectId'
 *      order: // value for 'order'
 *      isOptional: // value for 'isOptional'
 *   },
 * });
 */
export function useAddOfficialAssessmentObjectToSeasonMutation(baseOptions?: Apollo.MutationHookOptions<AddOfficialAssessmentObjectToSeasonMutation, AddOfficialAssessmentObjectToSeasonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddOfficialAssessmentObjectToSeasonMutation, AddOfficialAssessmentObjectToSeasonMutationVariables>(AddOfficialAssessmentObjectToSeasonDocument, options);
      }
export type AddOfficialAssessmentObjectToSeasonMutationHookResult = ReturnType<typeof useAddOfficialAssessmentObjectToSeasonMutation>;
export type AddOfficialAssessmentObjectToSeasonMutationResult = Apollo.MutationResult<AddOfficialAssessmentObjectToSeasonMutation>;
export type AddOfficialAssessmentObjectToSeasonMutationOptions = Apollo.BaseMutationOptions<AddOfficialAssessmentObjectToSeasonMutation, AddOfficialAssessmentObjectToSeasonMutationVariables>;
export const SetIsOptionalForSeasonOfficialAssessmentObjectDocument = gql`
    mutation setIsOptionalForSeasonOfficialAssessmentObject($id: String!, $isOptional: Boolean!) {
  setIsOptionalForSeasonOfficialAssessmentObject(
    input: {id: $id, isOptional: $isOptional}
  ) {
    id
    seasonId
    order
    isOptional
    assessmentObject {
      id
      sport
      type
      classification
      name
      createdAt
      updatedAt
    }
  }
}
    `;
export type SetIsOptionalForSeasonOfficialAssessmentObjectMutationFn = Apollo.MutationFunction<SetIsOptionalForSeasonOfficialAssessmentObjectMutation, SetIsOptionalForSeasonOfficialAssessmentObjectMutationVariables>;

/**
 * __useSetIsOptionalForSeasonOfficialAssessmentObjectMutation__
 *
 * To run a mutation, you first call `useSetIsOptionalForSeasonOfficialAssessmentObjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetIsOptionalForSeasonOfficialAssessmentObjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setIsOptionalForSeasonOfficialAssessmentObjectMutation, { data, loading, error }] = useSetIsOptionalForSeasonOfficialAssessmentObjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isOptional: // value for 'isOptional'
 *   },
 * });
 */
export function useSetIsOptionalForSeasonOfficialAssessmentObjectMutation(baseOptions?: Apollo.MutationHookOptions<SetIsOptionalForSeasonOfficialAssessmentObjectMutation, SetIsOptionalForSeasonOfficialAssessmentObjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetIsOptionalForSeasonOfficialAssessmentObjectMutation, SetIsOptionalForSeasonOfficialAssessmentObjectMutationVariables>(SetIsOptionalForSeasonOfficialAssessmentObjectDocument, options);
      }
export type SetIsOptionalForSeasonOfficialAssessmentObjectMutationHookResult = ReturnType<typeof useSetIsOptionalForSeasonOfficialAssessmentObjectMutation>;
export type SetIsOptionalForSeasonOfficialAssessmentObjectMutationResult = Apollo.MutationResult<SetIsOptionalForSeasonOfficialAssessmentObjectMutation>;
export type SetIsOptionalForSeasonOfficialAssessmentObjectMutationOptions = Apollo.BaseMutationOptions<SetIsOptionalForSeasonOfficialAssessmentObjectMutation, SetIsOptionalForSeasonOfficialAssessmentObjectMutationVariables>;
export const ListOfficialAssessmentObjectsForSeasonDocument = gql`
    query listOfficialAssessmentObjectsForSeason($offset: Int!, $limit: Int!, $seasonId: String!, $name: String) {
  listOfficialAssessmentObjectsForSeason(
    input: {seasonId: $seasonId, name: $name, pagination: {offset: $offset, limit: $limit}}
  ) {
    seasonId
    assessmentObjects {
      id
      seasonId
      order
      isOptional
      assessmentObject {
        id
        sport
        type
        classification
        name
        createdAt
        updatedAt
      }
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListOfficialAssessmentObjectsForSeasonQuery__
 *
 * To run a query within a React component, call `useListOfficialAssessmentObjectsForSeasonQuery` and pass it any options that fit your needs.
 * When your component renders, `useListOfficialAssessmentObjectsForSeasonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListOfficialAssessmentObjectsForSeasonQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      seasonId: // value for 'seasonId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useListOfficialAssessmentObjectsForSeasonQuery(baseOptions: Apollo.QueryHookOptions<ListOfficialAssessmentObjectsForSeasonQuery, ListOfficialAssessmentObjectsForSeasonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListOfficialAssessmentObjectsForSeasonQuery, ListOfficialAssessmentObjectsForSeasonQueryVariables>(ListOfficialAssessmentObjectsForSeasonDocument, options);
      }
export function useListOfficialAssessmentObjectsForSeasonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListOfficialAssessmentObjectsForSeasonQuery, ListOfficialAssessmentObjectsForSeasonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListOfficialAssessmentObjectsForSeasonQuery, ListOfficialAssessmentObjectsForSeasonQueryVariables>(ListOfficialAssessmentObjectsForSeasonDocument, options);
        }
export type ListOfficialAssessmentObjectsForSeasonQueryHookResult = ReturnType<typeof useListOfficialAssessmentObjectsForSeasonQuery>;
export type ListOfficialAssessmentObjectsForSeasonLazyQueryHookResult = ReturnType<typeof useListOfficialAssessmentObjectsForSeasonLazyQuery>;
export type ListOfficialAssessmentObjectsForSeasonQueryResult = Apollo.QueryResult<ListOfficialAssessmentObjectsForSeasonQuery, ListOfficialAssessmentObjectsForSeasonQueryVariables>;
export const RemoveOfficialAssessmentObjectFromSeasonDocument = gql`
    mutation removeOfficialAssessmentObjectFromSeason($id: String!) {
  removeOfficialAssessmentObjectFromSeason(input: {id: $id})
}
    `;
export type RemoveOfficialAssessmentObjectFromSeasonMutationFn = Apollo.MutationFunction<RemoveOfficialAssessmentObjectFromSeasonMutation, RemoveOfficialAssessmentObjectFromSeasonMutationVariables>;

/**
 * __useRemoveOfficialAssessmentObjectFromSeasonMutation__
 *
 * To run a mutation, you first call `useRemoveOfficialAssessmentObjectFromSeasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOfficialAssessmentObjectFromSeasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeOfficialAssessmentObjectFromSeasonMutation, { data, loading, error }] = useRemoveOfficialAssessmentObjectFromSeasonMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveOfficialAssessmentObjectFromSeasonMutation(baseOptions?: Apollo.MutationHookOptions<RemoveOfficialAssessmentObjectFromSeasonMutation, RemoveOfficialAssessmentObjectFromSeasonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveOfficialAssessmentObjectFromSeasonMutation, RemoveOfficialAssessmentObjectFromSeasonMutationVariables>(RemoveOfficialAssessmentObjectFromSeasonDocument, options);
      }
export type RemoveOfficialAssessmentObjectFromSeasonMutationHookResult = ReturnType<typeof useRemoveOfficialAssessmentObjectFromSeasonMutation>;
export type RemoveOfficialAssessmentObjectFromSeasonMutationResult = Apollo.MutationResult<RemoveOfficialAssessmentObjectFromSeasonMutation>;
export type RemoveOfficialAssessmentObjectFromSeasonMutationOptions = Apollo.BaseMutationOptions<RemoveOfficialAssessmentObjectFromSeasonMutation, RemoveOfficialAssessmentObjectFromSeasonMutationVariables>;
export const ListAvailableAssessmentObjectsForSeasonDocument = gql`
    query listAvailableAssessmentObjectsForSeason($seasonId: String!) {
  listAvailableAssessmentObjectsForSeason(input: {seasonId: $seasonId}) {
    seasonId
    assessmentObjects {
      id
      sport
      type
      classification
      name
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useListAvailableAssessmentObjectsForSeasonQuery__
 *
 * To run a query within a React component, call `useListAvailableAssessmentObjectsForSeasonQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAvailableAssessmentObjectsForSeasonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAvailableAssessmentObjectsForSeasonQuery({
 *   variables: {
 *      seasonId: // value for 'seasonId'
 *   },
 * });
 */
export function useListAvailableAssessmentObjectsForSeasonQuery(baseOptions: Apollo.QueryHookOptions<ListAvailableAssessmentObjectsForSeasonQuery, ListAvailableAssessmentObjectsForSeasonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAvailableAssessmentObjectsForSeasonQuery, ListAvailableAssessmentObjectsForSeasonQueryVariables>(ListAvailableAssessmentObjectsForSeasonDocument, options);
      }
export function useListAvailableAssessmentObjectsForSeasonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAvailableAssessmentObjectsForSeasonQuery, ListAvailableAssessmentObjectsForSeasonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAvailableAssessmentObjectsForSeasonQuery, ListAvailableAssessmentObjectsForSeasonQueryVariables>(ListAvailableAssessmentObjectsForSeasonDocument, options);
        }
export type ListAvailableAssessmentObjectsForSeasonQueryHookResult = ReturnType<typeof useListAvailableAssessmentObjectsForSeasonQuery>;
export type ListAvailableAssessmentObjectsForSeasonLazyQueryHookResult = ReturnType<typeof useListAvailableAssessmentObjectsForSeasonLazyQuery>;
export type ListAvailableAssessmentObjectsForSeasonQueryResult = Apollo.QueryResult<ListAvailableAssessmentObjectsForSeasonQuery, ListAvailableAssessmentObjectsForSeasonQueryVariables>;
export const AddPeriodToSeasonDocument = gql`
    mutation addPeriodToSeason($seasonId: String!, $periodId: String!, $order: Int!) {
  addPeriodToSeason(
    input: {seasonId: $seasonId, periodId: $periodId, order: $order}
  ) {
    id
    seasonId
    order
    period {
      id
      sport
      name
      createdAt
      updatedAt
    }
  }
}
    `;
export type AddPeriodToSeasonMutationFn = Apollo.MutationFunction<AddPeriodToSeasonMutation, AddPeriodToSeasonMutationVariables>;

/**
 * __useAddPeriodToSeasonMutation__
 *
 * To run a mutation, you first call `useAddPeriodToSeasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPeriodToSeasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPeriodToSeasonMutation, { data, loading, error }] = useAddPeriodToSeasonMutation({
 *   variables: {
 *      seasonId: // value for 'seasonId'
 *      periodId: // value for 'periodId'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useAddPeriodToSeasonMutation(baseOptions?: Apollo.MutationHookOptions<AddPeriodToSeasonMutation, AddPeriodToSeasonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPeriodToSeasonMutation, AddPeriodToSeasonMutationVariables>(AddPeriodToSeasonDocument, options);
      }
export type AddPeriodToSeasonMutationHookResult = ReturnType<typeof useAddPeriodToSeasonMutation>;
export type AddPeriodToSeasonMutationResult = Apollo.MutationResult<AddPeriodToSeasonMutation>;
export type AddPeriodToSeasonMutationOptions = Apollo.BaseMutationOptions<AddPeriodToSeasonMutation, AddPeriodToSeasonMutationVariables>;
export const ListSeasonPeriodsForSeasonDocument = gql`
    query listSeasonPeriodsForSeason($offset: Int!, $limit: Int!, $seasonId: String!, $name: String) {
  listSeasonPeriodsForSeason(
    input: {seasonId: $seasonId, name: $name, pagination: {offset: $offset, limit: $limit}}
  ) {
    seasonId
    periods {
      id
      seasonId
      order
      period {
        id
        sport
        name
        createdAt
        updatedAt
      }
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListSeasonPeriodsForSeasonQuery__
 *
 * To run a query within a React component, call `useListSeasonPeriodsForSeasonQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSeasonPeriodsForSeasonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSeasonPeriodsForSeasonQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      seasonId: // value for 'seasonId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useListSeasonPeriodsForSeasonQuery(baseOptions: Apollo.QueryHookOptions<ListSeasonPeriodsForSeasonQuery, ListSeasonPeriodsForSeasonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListSeasonPeriodsForSeasonQuery, ListSeasonPeriodsForSeasonQueryVariables>(ListSeasonPeriodsForSeasonDocument, options);
      }
export function useListSeasonPeriodsForSeasonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListSeasonPeriodsForSeasonQuery, ListSeasonPeriodsForSeasonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListSeasonPeriodsForSeasonQuery, ListSeasonPeriodsForSeasonQueryVariables>(ListSeasonPeriodsForSeasonDocument, options);
        }
export type ListSeasonPeriodsForSeasonQueryHookResult = ReturnType<typeof useListSeasonPeriodsForSeasonQuery>;
export type ListSeasonPeriodsForSeasonLazyQueryHookResult = ReturnType<typeof useListSeasonPeriodsForSeasonLazyQuery>;
export type ListSeasonPeriodsForSeasonQueryResult = Apollo.QueryResult<ListSeasonPeriodsForSeasonQuery, ListSeasonPeriodsForSeasonQueryVariables>;
export const RemoveSeasonPeriodFromSeasonDocument = gql`
    mutation removeSeasonPeriodFromSeason($id: String!) {
  removeSeasonPeriodFromSeason(input: {id: $id})
}
    `;
export type RemoveSeasonPeriodFromSeasonMutationFn = Apollo.MutationFunction<RemoveSeasonPeriodFromSeasonMutation, RemoveSeasonPeriodFromSeasonMutationVariables>;

/**
 * __useRemoveSeasonPeriodFromSeasonMutation__
 *
 * To run a mutation, you first call `useRemoveSeasonPeriodFromSeasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSeasonPeriodFromSeasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSeasonPeriodFromSeasonMutation, { data, loading, error }] = useRemoveSeasonPeriodFromSeasonMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveSeasonPeriodFromSeasonMutation(baseOptions?: Apollo.MutationHookOptions<RemoveSeasonPeriodFromSeasonMutation, RemoveSeasonPeriodFromSeasonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveSeasonPeriodFromSeasonMutation, RemoveSeasonPeriodFromSeasonMutationVariables>(RemoveSeasonPeriodFromSeasonDocument, options);
      }
export type RemoveSeasonPeriodFromSeasonMutationHookResult = ReturnType<typeof useRemoveSeasonPeriodFromSeasonMutation>;
export type RemoveSeasonPeriodFromSeasonMutationResult = Apollo.MutationResult<RemoveSeasonPeriodFromSeasonMutation>;
export type RemoveSeasonPeriodFromSeasonMutationOptions = Apollo.BaseMutationOptions<RemoveSeasonPeriodFromSeasonMutation, RemoveSeasonPeriodFromSeasonMutationVariables>;
export const ListAvailablePeriodsForSeasonDocument = gql`
    query listAvailablePeriodsForSeason($seasonId: String!) {
  listAvailablePeriodsForSeason(input: {seasonId: $seasonId}) {
    seasonId
    periods {
      id
      sport
      name
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useListAvailablePeriodsForSeasonQuery__
 *
 * To run a query within a React component, call `useListAvailablePeriodsForSeasonQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAvailablePeriodsForSeasonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAvailablePeriodsForSeasonQuery({
 *   variables: {
 *      seasonId: // value for 'seasonId'
 *   },
 * });
 */
export function useListAvailablePeriodsForSeasonQuery(baseOptions: Apollo.QueryHookOptions<ListAvailablePeriodsForSeasonQuery, ListAvailablePeriodsForSeasonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAvailablePeriodsForSeasonQuery, ListAvailablePeriodsForSeasonQueryVariables>(ListAvailablePeriodsForSeasonDocument, options);
      }
export function useListAvailablePeriodsForSeasonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAvailablePeriodsForSeasonQuery, ListAvailablePeriodsForSeasonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAvailablePeriodsForSeasonQuery, ListAvailablePeriodsForSeasonQueryVariables>(ListAvailablePeriodsForSeasonDocument, options);
        }
export type ListAvailablePeriodsForSeasonQueryHookResult = ReturnType<typeof useListAvailablePeriodsForSeasonQuery>;
export type ListAvailablePeriodsForSeasonLazyQueryHookResult = ReturnType<typeof useListAvailablePeriodsForSeasonLazyQuery>;
export type ListAvailablePeriodsForSeasonQueryResult = Apollo.QueryResult<ListAvailablePeriodsForSeasonQuery, ListAvailablePeriodsForSeasonQueryVariables>;
export const CreateSeasonDivisionDocument = gql`
    mutation createSeasonDivision($seasonId: String!, $name: String!) {
  createSeasonDivision(input: {seasonId: $seasonId, name: $name}) {
    id
    seasonId
    name
    aliases {
      id
      seasonDivisionId
      alias
    }
  }
}
    `;
export type CreateSeasonDivisionMutationFn = Apollo.MutationFunction<CreateSeasonDivisionMutation, CreateSeasonDivisionMutationVariables>;

/**
 * __useCreateSeasonDivisionMutation__
 *
 * To run a mutation, you first call `useCreateSeasonDivisionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSeasonDivisionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSeasonDivisionMutation, { data, loading, error }] = useCreateSeasonDivisionMutation({
 *   variables: {
 *      seasonId: // value for 'seasonId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateSeasonDivisionMutation(baseOptions?: Apollo.MutationHookOptions<CreateSeasonDivisionMutation, CreateSeasonDivisionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSeasonDivisionMutation, CreateSeasonDivisionMutationVariables>(CreateSeasonDivisionDocument, options);
      }
export type CreateSeasonDivisionMutationHookResult = ReturnType<typeof useCreateSeasonDivisionMutation>;
export type CreateSeasonDivisionMutationResult = Apollo.MutationResult<CreateSeasonDivisionMutation>;
export type CreateSeasonDivisionMutationOptions = Apollo.BaseMutationOptions<CreateSeasonDivisionMutation, CreateSeasonDivisionMutationVariables>;
export const ListSeasonDivisionsForSeasonDocument = gql`
    query listSeasonDivisionsForSeason($offset: Int!, $limit: Int!, $seasonId: String!, $name: String) {
  listSeasonDivisionsForSeason(
    input: {seasonId: $seasonId, name: $name, pagination: {offset: $offset, limit: $limit}}
  ) {
    seasonId
    seasonDivisions {
      id
      seasonId
      name
      aliases {
        id
        seasonDivisionId
        alias
      }
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListSeasonDivisionsForSeasonQuery__
 *
 * To run a query within a React component, call `useListSeasonDivisionsForSeasonQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSeasonDivisionsForSeasonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSeasonDivisionsForSeasonQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      seasonId: // value for 'seasonId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useListSeasonDivisionsForSeasonQuery(baseOptions: Apollo.QueryHookOptions<ListSeasonDivisionsForSeasonQuery, ListSeasonDivisionsForSeasonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListSeasonDivisionsForSeasonQuery, ListSeasonDivisionsForSeasonQueryVariables>(ListSeasonDivisionsForSeasonDocument, options);
      }
export function useListSeasonDivisionsForSeasonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListSeasonDivisionsForSeasonQuery, ListSeasonDivisionsForSeasonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListSeasonDivisionsForSeasonQuery, ListSeasonDivisionsForSeasonQueryVariables>(ListSeasonDivisionsForSeasonDocument, options);
        }
export type ListSeasonDivisionsForSeasonQueryHookResult = ReturnType<typeof useListSeasonDivisionsForSeasonQuery>;
export type ListSeasonDivisionsForSeasonLazyQueryHookResult = ReturnType<typeof useListSeasonDivisionsForSeasonLazyQuery>;
export type ListSeasonDivisionsForSeasonQueryResult = Apollo.QueryResult<ListSeasonDivisionsForSeasonQuery, ListSeasonDivisionsForSeasonQueryVariables>;
export const GetSeasonDivisionByIdDocument = gql`
    query getSeasonDivisionById($id: String!) {
  getSeasonDivisionById(input: {id: $id}) {
    id
    seasonId
    name
    aliases {
      id
      seasonDivisionId
      alias
    }
  }
}
    `;

/**
 * __useGetSeasonDivisionByIdQuery__
 *
 * To run a query within a React component, call `useGetSeasonDivisionByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSeasonDivisionByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSeasonDivisionByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSeasonDivisionByIdQuery(baseOptions: Apollo.QueryHookOptions<GetSeasonDivisionByIdQuery, GetSeasonDivisionByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSeasonDivisionByIdQuery, GetSeasonDivisionByIdQueryVariables>(GetSeasonDivisionByIdDocument, options);
      }
export function useGetSeasonDivisionByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSeasonDivisionByIdQuery, GetSeasonDivisionByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSeasonDivisionByIdQuery, GetSeasonDivisionByIdQueryVariables>(GetSeasonDivisionByIdDocument, options);
        }
export type GetSeasonDivisionByIdQueryHookResult = ReturnType<typeof useGetSeasonDivisionByIdQuery>;
export type GetSeasonDivisionByIdLazyQueryHookResult = ReturnType<typeof useGetSeasonDivisionByIdLazyQuery>;
export type GetSeasonDivisionByIdQueryResult = Apollo.QueryResult<GetSeasonDivisionByIdQuery, GetSeasonDivisionByIdQueryVariables>;
export const UpdateSeasonDivisionDocument = gql`
    mutation updateSeasonDivision($id: String!, $name: String!) {
  updateSeasonDivision(input: {id: $id, name: $name}) {
    id
    seasonId
    name
    aliases {
      id
      seasonDivisionId
      alias
    }
  }
}
    `;
export type UpdateSeasonDivisionMutationFn = Apollo.MutationFunction<UpdateSeasonDivisionMutation, UpdateSeasonDivisionMutationVariables>;

/**
 * __useUpdateSeasonDivisionMutation__
 *
 * To run a mutation, you first call `useUpdateSeasonDivisionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSeasonDivisionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSeasonDivisionMutation, { data, loading, error }] = useUpdateSeasonDivisionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateSeasonDivisionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSeasonDivisionMutation, UpdateSeasonDivisionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSeasonDivisionMutation, UpdateSeasonDivisionMutationVariables>(UpdateSeasonDivisionDocument, options);
      }
export type UpdateSeasonDivisionMutationHookResult = ReturnType<typeof useUpdateSeasonDivisionMutation>;
export type UpdateSeasonDivisionMutationResult = Apollo.MutationResult<UpdateSeasonDivisionMutation>;
export type UpdateSeasonDivisionMutationOptions = Apollo.BaseMutationOptions<UpdateSeasonDivisionMutation, UpdateSeasonDivisionMutationVariables>;
export const DeleteSeasonDivisionDocument = gql`
    mutation deleteSeasonDivision($id: String!) {
  deleteSeasonDivision(input: {id: $id})
}
    `;
export type DeleteSeasonDivisionMutationFn = Apollo.MutationFunction<DeleteSeasonDivisionMutation, DeleteSeasonDivisionMutationVariables>;

/**
 * __useDeleteSeasonDivisionMutation__
 *
 * To run a mutation, you first call `useDeleteSeasonDivisionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSeasonDivisionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSeasonDivisionMutation, { data, loading, error }] = useDeleteSeasonDivisionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSeasonDivisionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSeasonDivisionMutation, DeleteSeasonDivisionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSeasonDivisionMutation, DeleteSeasonDivisionMutationVariables>(DeleteSeasonDivisionDocument, options);
      }
export type DeleteSeasonDivisionMutationHookResult = ReturnType<typeof useDeleteSeasonDivisionMutation>;
export type DeleteSeasonDivisionMutationResult = Apollo.MutationResult<DeleteSeasonDivisionMutation>;
export type DeleteSeasonDivisionMutationOptions = Apollo.BaseMutationOptions<DeleteSeasonDivisionMutation, DeleteSeasonDivisionMutationVariables>;
export const CreateSeasonDivisionAliasDocument = gql`
    mutation createSeasonDivisionAlias($seasonDivisionId: String!, $alias: String!) {
  createSeasonDivisionAlias(
    input: {seasonDivisionId: $seasonDivisionId, alias: $alias}
  ) {
    id
    seasonDivisionId
    alias
  }
}
    `;
export type CreateSeasonDivisionAliasMutationFn = Apollo.MutationFunction<CreateSeasonDivisionAliasMutation, CreateSeasonDivisionAliasMutationVariables>;

/**
 * __useCreateSeasonDivisionAliasMutation__
 *
 * To run a mutation, you first call `useCreateSeasonDivisionAliasMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSeasonDivisionAliasMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSeasonDivisionAliasMutation, { data, loading, error }] = useCreateSeasonDivisionAliasMutation({
 *   variables: {
 *      seasonDivisionId: // value for 'seasonDivisionId'
 *      alias: // value for 'alias'
 *   },
 * });
 */
export function useCreateSeasonDivisionAliasMutation(baseOptions?: Apollo.MutationHookOptions<CreateSeasonDivisionAliasMutation, CreateSeasonDivisionAliasMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSeasonDivisionAliasMutation, CreateSeasonDivisionAliasMutationVariables>(CreateSeasonDivisionAliasDocument, options);
      }
export type CreateSeasonDivisionAliasMutationHookResult = ReturnType<typeof useCreateSeasonDivisionAliasMutation>;
export type CreateSeasonDivisionAliasMutationResult = Apollo.MutationResult<CreateSeasonDivisionAliasMutation>;
export type CreateSeasonDivisionAliasMutationOptions = Apollo.BaseMutationOptions<CreateSeasonDivisionAliasMutation, CreateSeasonDivisionAliasMutationVariables>;
export const ListSeasonDivisionAliasesDocument = gql`
    query listSeasonDivisionAliases($offset: Int!, $limit: Int!, $seasonDivisionId: String!, $alias: String) {
  listSeasonDivisionAliases(
    input: {seasonDivisionId: $seasonDivisionId, alias: $alias, pagination: {offset: $offset, limit: $limit}}
  ) {
    seasonDivisionId
    aliases {
      id
      seasonDivisionId
      alias
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListSeasonDivisionAliasesQuery__
 *
 * To run a query within a React component, call `useListSeasonDivisionAliasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSeasonDivisionAliasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSeasonDivisionAliasesQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      seasonDivisionId: // value for 'seasonDivisionId'
 *      alias: // value for 'alias'
 *   },
 * });
 */
export function useListSeasonDivisionAliasesQuery(baseOptions: Apollo.QueryHookOptions<ListSeasonDivisionAliasesQuery, ListSeasonDivisionAliasesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListSeasonDivisionAliasesQuery, ListSeasonDivisionAliasesQueryVariables>(ListSeasonDivisionAliasesDocument, options);
      }
export function useListSeasonDivisionAliasesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListSeasonDivisionAliasesQuery, ListSeasonDivisionAliasesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListSeasonDivisionAliasesQuery, ListSeasonDivisionAliasesQueryVariables>(ListSeasonDivisionAliasesDocument, options);
        }
export type ListSeasonDivisionAliasesQueryHookResult = ReturnType<typeof useListSeasonDivisionAliasesQuery>;
export type ListSeasonDivisionAliasesLazyQueryHookResult = ReturnType<typeof useListSeasonDivisionAliasesLazyQuery>;
export type ListSeasonDivisionAliasesQueryResult = Apollo.QueryResult<ListSeasonDivisionAliasesQuery, ListSeasonDivisionAliasesQueryVariables>;
export const UpdateSeasonDivisionAliasDocument = gql`
    mutation updateSeasonDivisionAlias($id: String!, $alias: String!) {
  updateSeasonDivisionAlias(input: {id: $id, alias: $alias}) {
    id
    seasonDivisionId
    alias
  }
}
    `;
export type UpdateSeasonDivisionAliasMutationFn = Apollo.MutationFunction<UpdateSeasonDivisionAliasMutation, UpdateSeasonDivisionAliasMutationVariables>;

/**
 * __useUpdateSeasonDivisionAliasMutation__
 *
 * To run a mutation, you first call `useUpdateSeasonDivisionAliasMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSeasonDivisionAliasMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSeasonDivisionAliasMutation, { data, loading, error }] = useUpdateSeasonDivisionAliasMutation({
 *   variables: {
 *      id: // value for 'id'
 *      alias: // value for 'alias'
 *   },
 * });
 */
export function useUpdateSeasonDivisionAliasMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSeasonDivisionAliasMutation, UpdateSeasonDivisionAliasMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSeasonDivisionAliasMutation, UpdateSeasonDivisionAliasMutationVariables>(UpdateSeasonDivisionAliasDocument, options);
      }
export type UpdateSeasonDivisionAliasMutationHookResult = ReturnType<typeof useUpdateSeasonDivisionAliasMutation>;
export type UpdateSeasonDivisionAliasMutationResult = Apollo.MutationResult<UpdateSeasonDivisionAliasMutation>;
export type UpdateSeasonDivisionAliasMutationOptions = Apollo.BaseMutationOptions<UpdateSeasonDivisionAliasMutation, UpdateSeasonDivisionAliasMutationVariables>;
export const DeleteSeasonDivisionAliasDocument = gql`
    mutation deleteSeasonDivisionAlias($id: String!) {
  deleteSeasonDivisionAlias(input: {id: $id})
}
    `;
export type DeleteSeasonDivisionAliasMutationFn = Apollo.MutationFunction<DeleteSeasonDivisionAliasMutation, DeleteSeasonDivisionAliasMutationVariables>;

/**
 * __useDeleteSeasonDivisionAliasMutation__
 *
 * To run a mutation, you first call `useDeleteSeasonDivisionAliasMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSeasonDivisionAliasMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSeasonDivisionAliasMutation, { data, loading, error }] = useDeleteSeasonDivisionAliasMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSeasonDivisionAliasMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSeasonDivisionAliasMutation, DeleteSeasonDivisionAliasMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSeasonDivisionAliasMutation, DeleteSeasonDivisionAliasMutationVariables>(DeleteSeasonDivisionAliasDocument, options);
      }
export type DeleteSeasonDivisionAliasMutationHookResult = ReturnType<typeof useDeleteSeasonDivisionAliasMutation>;
export type DeleteSeasonDivisionAliasMutationResult = Apollo.MutationResult<DeleteSeasonDivisionAliasMutation>;
export type DeleteSeasonDivisionAliasMutationOptions = Apollo.BaseMutationOptions<DeleteSeasonDivisionAliasMutation, DeleteSeasonDivisionAliasMutationVariables>;
export const ListSeasonTeamsForSeasonDivisionDocument = gql`
    query listSeasonTeamsForSeasonDivision($offset: Int!, $limit: Int!, $seasonId: String!, $seasonDivisionId: String, $search: String, $countryCodes: [CountryCodes!], $classifications: [TeamClassifications!], $ageGroup: String, $parentOrganizationName: String) {
  listSeasonTeamsForSeasonDivision(
    input: {seasonId: $seasonId, seasonDivisionId: $seasonDivisionId, search: $search, countryCodes: $countryCodes, classifications: $classifications, ageGroup: $ageGroup, parentOrganizationName: $parentOrganizationName, pagination: {offset: $offset, limit: $limit}}
  ) {
    seasonId
    seasonDivisionTeams {
      id
      seasonId
      seasonDivision {
        id
        seasonId
        name
        aliases {
          id
          seasonDivisionId
          alias
        }
      }
      seasonTeam {
        id
        seasonId
        team {
          id
          name
          abbreviation
          sport
          city
          stateAbbreviation
          countryCode
          timeZone
          website
          logo
          classification
          gender
          parentOrganizationName
          externalId
          ageGroup
          createdAt
          updatedAt
        }
      }
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListSeasonTeamsForSeasonDivisionQuery__
 *
 * To run a query within a React component, call `useListSeasonTeamsForSeasonDivisionQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSeasonTeamsForSeasonDivisionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSeasonTeamsForSeasonDivisionQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      seasonId: // value for 'seasonId'
 *      seasonDivisionId: // value for 'seasonDivisionId'
 *      search: // value for 'search'
 *      countryCodes: // value for 'countryCodes'
 *      classifications: // value for 'classifications'
 *      ageGroup: // value for 'ageGroup'
 *      parentOrganizationName: // value for 'parentOrganizationName'
 *   },
 * });
 */
export function useListSeasonTeamsForSeasonDivisionQuery(baseOptions: Apollo.QueryHookOptions<ListSeasonTeamsForSeasonDivisionQuery, ListSeasonTeamsForSeasonDivisionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListSeasonTeamsForSeasonDivisionQuery, ListSeasonTeamsForSeasonDivisionQueryVariables>(ListSeasonTeamsForSeasonDivisionDocument, options);
      }
export function useListSeasonTeamsForSeasonDivisionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListSeasonTeamsForSeasonDivisionQuery, ListSeasonTeamsForSeasonDivisionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListSeasonTeamsForSeasonDivisionQuery, ListSeasonTeamsForSeasonDivisionQueryVariables>(ListSeasonTeamsForSeasonDivisionDocument, options);
        }
export type ListSeasonTeamsForSeasonDivisionQueryHookResult = ReturnType<typeof useListSeasonTeamsForSeasonDivisionQuery>;
export type ListSeasonTeamsForSeasonDivisionLazyQueryHookResult = ReturnType<typeof useListSeasonTeamsForSeasonDivisionLazyQuery>;
export type ListSeasonTeamsForSeasonDivisionQueryResult = Apollo.QueryResult<ListSeasonTeamsForSeasonDivisionQuery, ListSeasonTeamsForSeasonDivisionQueryVariables>;
export const ListSeasonTeamsAvailableForSeasonDivisionDocument = gql`
    query listSeasonTeamsAvailableForSeasonDivision($offset: Int!, $limit: Int!, $seasonId: String!, $seasonDivisionId: String!, $search: String) {
  listSeasonTeamsAvailableForSeasonDivision(
    input: {seasonId: $seasonId, seasonDivisionId: $seasonDivisionId, search: $search, pagination: {offset: $offset, limit: $limit}}
  ) {
    seasonId
    teams {
      id
      seasonId
      team {
        id
        name
        abbreviation
        sport
        city
        stateAbbreviation
        countryCode
        timeZone
        website
        logo
        classification
        gender
        parentOrganizationName
        externalId
        ageGroup
        createdAt
        updatedAt
      }
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListSeasonTeamsAvailableForSeasonDivisionQuery__
 *
 * To run a query within a React component, call `useListSeasonTeamsAvailableForSeasonDivisionQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSeasonTeamsAvailableForSeasonDivisionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSeasonTeamsAvailableForSeasonDivisionQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      seasonId: // value for 'seasonId'
 *      seasonDivisionId: // value for 'seasonDivisionId'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useListSeasonTeamsAvailableForSeasonDivisionQuery(baseOptions: Apollo.QueryHookOptions<ListSeasonTeamsAvailableForSeasonDivisionQuery, ListSeasonTeamsAvailableForSeasonDivisionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListSeasonTeamsAvailableForSeasonDivisionQuery, ListSeasonTeamsAvailableForSeasonDivisionQueryVariables>(ListSeasonTeamsAvailableForSeasonDivisionDocument, options);
      }
export function useListSeasonTeamsAvailableForSeasonDivisionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListSeasonTeamsAvailableForSeasonDivisionQuery, ListSeasonTeamsAvailableForSeasonDivisionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListSeasonTeamsAvailableForSeasonDivisionQuery, ListSeasonTeamsAvailableForSeasonDivisionQueryVariables>(ListSeasonTeamsAvailableForSeasonDivisionDocument, options);
        }
export type ListSeasonTeamsAvailableForSeasonDivisionQueryHookResult = ReturnType<typeof useListSeasonTeamsAvailableForSeasonDivisionQuery>;
export type ListSeasonTeamsAvailableForSeasonDivisionLazyQueryHookResult = ReturnType<typeof useListSeasonTeamsAvailableForSeasonDivisionLazyQuery>;
export type ListSeasonTeamsAvailableForSeasonDivisionQueryResult = Apollo.QueryResult<ListSeasonTeamsAvailableForSeasonDivisionQuery, ListSeasonTeamsAvailableForSeasonDivisionQueryVariables>;
export const AddSeasonTeamToSeasonDivisionDocument = gql`
    mutation addSeasonTeamToSeasonDivision($seasonId: String!, $seasonDivisionId: String!, $seasonTeamId: String!) {
  addSeasonTeamToSeasonDivision(
    input: {seasonId: $seasonId, seasonDivisionId: $seasonDivisionId, seasonTeamId: $seasonTeamId}
  ) {
    id
    seasonId
    seasonDivision {
      id
      seasonId
      name
      aliases {
        id
        seasonDivisionId
        alias
      }
    }
    seasonTeam {
      id
      seasonId
      team {
        id
        name
        abbreviation
        sport
        city
        stateAbbreviation
        countryCode
        timeZone
        website
        logo
        classification
        gender
        parentOrganizationName
        externalId
        ageGroup
        createdAt
        updatedAt
      }
    }
  }
}
    `;
export type AddSeasonTeamToSeasonDivisionMutationFn = Apollo.MutationFunction<AddSeasonTeamToSeasonDivisionMutation, AddSeasonTeamToSeasonDivisionMutationVariables>;

/**
 * __useAddSeasonTeamToSeasonDivisionMutation__
 *
 * To run a mutation, you first call `useAddSeasonTeamToSeasonDivisionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSeasonTeamToSeasonDivisionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSeasonTeamToSeasonDivisionMutation, { data, loading, error }] = useAddSeasonTeamToSeasonDivisionMutation({
 *   variables: {
 *      seasonId: // value for 'seasonId'
 *      seasonDivisionId: // value for 'seasonDivisionId'
 *      seasonTeamId: // value for 'seasonTeamId'
 *   },
 * });
 */
export function useAddSeasonTeamToSeasonDivisionMutation(baseOptions?: Apollo.MutationHookOptions<AddSeasonTeamToSeasonDivisionMutation, AddSeasonTeamToSeasonDivisionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddSeasonTeamToSeasonDivisionMutation, AddSeasonTeamToSeasonDivisionMutationVariables>(AddSeasonTeamToSeasonDivisionDocument, options);
      }
export type AddSeasonTeamToSeasonDivisionMutationHookResult = ReturnType<typeof useAddSeasonTeamToSeasonDivisionMutation>;
export type AddSeasonTeamToSeasonDivisionMutationResult = Apollo.MutationResult<AddSeasonTeamToSeasonDivisionMutation>;
export type AddSeasonTeamToSeasonDivisionMutationOptions = Apollo.BaseMutationOptions<AddSeasonTeamToSeasonDivisionMutation, AddSeasonTeamToSeasonDivisionMutationVariables>;
export const DeleteSeasonDivisionTeamDocument = gql`
    mutation deleteSeasonDivisionTeam($seasonId: String!, $seasonDivisionTeamId: String!) {
  deleteSeasonDivisionTeam(
    input: {seasonId: $seasonId, seasonDivisionTeamId: $seasonDivisionTeamId}
  )
}
    `;
export type DeleteSeasonDivisionTeamMutationFn = Apollo.MutationFunction<DeleteSeasonDivisionTeamMutation, DeleteSeasonDivisionTeamMutationVariables>;

/**
 * __useDeleteSeasonDivisionTeamMutation__
 *
 * To run a mutation, you first call `useDeleteSeasonDivisionTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSeasonDivisionTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSeasonDivisionTeamMutation, { data, loading, error }] = useDeleteSeasonDivisionTeamMutation({
 *   variables: {
 *      seasonId: // value for 'seasonId'
 *      seasonDivisionTeamId: // value for 'seasonDivisionTeamId'
 *   },
 * });
 */
export function useDeleteSeasonDivisionTeamMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSeasonDivisionTeamMutation, DeleteSeasonDivisionTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSeasonDivisionTeamMutation, DeleteSeasonDivisionTeamMutationVariables>(DeleteSeasonDivisionTeamDocument, options);
      }
export type DeleteSeasonDivisionTeamMutationHookResult = ReturnType<typeof useDeleteSeasonDivisionTeamMutation>;
export type DeleteSeasonDivisionTeamMutationResult = Apollo.MutationResult<DeleteSeasonDivisionTeamMutation>;
export type DeleteSeasonDivisionTeamMutationOptions = Apollo.BaseMutationOptions<DeleteSeasonDivisionTeamMutation, DeleteSeasonDivisionTeamMutationVariables>;
export const UpdateTeamKitEnabledForSeasonDocument = gql`
    mutation updateTeamKitEnabledForSeason($seasonId: String!, $enabled: Boolean!) {
  updateTeamKitEnabledForSeason(input: {seasonId: $seasonId, enabled: $enabled})
}
    `;
export type UpdateTeamKitEnabledForSeasonMutationFn = Apollo.MutationFunction<UpdateTeamKitEnabledForSeasonMutation, UpdateTeamKitEnabledForSeasonMutationVariables>;

/**
 * __useUpdateTeamKitEnabledForSeasonMutation__
 *
 * To run a mutation, you first call `useUpdateTeamKitEnabledForSeasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamKitEnabledForSeasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeamKitEnabledForSeasonMutation, { data, loading, error }] = useUpdateTeamKitEnabledForSeasonMutation({
 *   variables: {
 *      seasonId: // value for 'seasonId'
 *      enabled: // value for 'enabled'
 *   },
 * });
 */
export function useUpdateTeamKitEnabledForSeasonMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTeamKitEnabledForSeasonMutation, UpdateTeamKitEnabledForSeasonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTeamKitEnabledForSeasonMutation, UpdateTeamKitEnabledForSeasonMutationVariables>(UpdateTeamKitEnabledForSeasonDocument, options);
      }
export type UpdateTeamKitEnabledForSeasonMutationHookResult = ReturnType<typeof useUpdateTeamKitEnabledForSeasonMutation>;
export type UpdateTeamKitEnabledForSeasonMutationResult = Apollo.MutationResult<UpdateTeamKitEnabledForSeasonMutation>;
export type UpdateTeamKitEnabledForSeasonMutationOptions = Apollo.BaseMutationOptions<UpdateTeamKitEnabledForSeasonMutation, UpdateTeamKitEnabledForSeasonMutationVariables>;
export const FindSeasonTeamKitForSeasonDocument = gql`
    query findSeasonTeamKitForSeason($orgId: String!, $seasonId: String!) {
  findSeasonTeamKitForSeason(input: {orgId: $orgId, seasonId: $seasonId}) {
    orgId
    seasonId
    id
    status
    sendPreCompetitionDays
    sendReminders
    sendReminderDays
    sendReminderCutoffDays
    activatedAt
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useFindSeasonTeamKitForSeasonQuery__
 *
 * To run a query within a React component, call `useFindSeasonTeamKitForSeasonQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindSeasonTeamKitForSeasonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindSeasonTeamKitForSeasonQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      seasonId: // value for 'seasonId'
 *   },
 * });
 */
export function useFindSeasonTeamKitForSeasonQuery(baseOptions: Apollo.QueryHookOptions<FindSeasonTeamKitForSeasonQuery, FindSeasonTeamKitForSeasonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindSeasonTeamKitForSeasonQuery, FindSeasonTeamKitForSeasonQueryVariables>(FindSeasonTeamKitForSeasonDocument, options);
      }
export function useFindSeasonTeamKitForSeasonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindSeasonTeamKitForSeasonQuery, FindSeasonTeamKitForSeasonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindSeasonTeamKitForSeasonQuery, FindSeasonTeamKitForSeasonQueryVariables>(FindSeasonTeamKitForSeasonDocument, options);
        }
export type FindSeasonTeamKitForSeasonQueryHookResult = ReturnType<typeof useFindSeasonTeamKitForSeasonQuery>;
export type FindSeasonTeamKitForSeasonLazyQueryHookResult = ReturnType<typeof useFindSeasonTeamKitForSeasonLazyQuery>;
export type FindSeasonTeamKitForSeasonQueryResult = Apollo.QueryResult<FindSeasonTeamKitForSeasonQuery, FindSeasonTeamKitForSeasonQueryVariables>;
export const GetSeasonTeamKitByIdDocument = gql`
    query getSeasonTeamKitById($seasonTeamKitId: String!) {
  getSeasonTeamKitById(input: {seasonTeamKitId: $seasonTeamKitId}) {
    orgId
    seasonId
    id
    status
    sendPreCompetitionDays
    sendReminders
    sendReminderDays
    sendReminderCutoffDays
    activatedAt
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetSeasonTeamKitByIdQuery__
 *
 * To run a query within a React component, call `useGetSeasonTeamKitByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSeasonTeamKitByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSeasonTeamKitByIdQuery({
 *   variables: {
 *      seasonTeamKitId: // value for 'seasonTeamKitId'
 *   },
 * });
 */
export function useGetSeasonTeamKitByIdQuery(baseOptions: Apollo.QueryHookOptions<GetSeasonTeamKitByIdQuery, GetSeasonTeamKitByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSeasonTeamKitByIdQuery, GetSeasonTeamKitByIdQueryVariables>(GetSeasonTeamKitByIdDocument, options);
      }
export function useGetSeasonTeamKitByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSeasonTeamKitByIdQuery, GetSeasonTeamKitByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSeasonTeamKitByIdQuery, GetSeasonTeamKitByIdQueryVariables>(GetSeasonTeamKitByIdDocument, options);
        }
export type GetSeasonTeamKitByIdQueryHookResult = ReturnType<typeof useGetSeasonTeamKitByIdQuery>;
export type GetSeasonTeamKitByIdLazyQueryHookResult = ReturnType<typeof useGetSeasonTeamKitByIdLazyQuery>;
export type GetSeasonTeamKitByIdQueryResult = Apollo.QueryResult<GetSeasonTeamKitByIdQuery, GetSeasonTeamKitByIdQueryVariables>;
export const CreateSeasonTeamKitDocument = gql`
    mutation createSeasonTeamKit($orgId: String!, $seasonId: String!, $sendPreCompetitionDays: Int!, $sendReminders: Boolean!, $sendReminderDays: Int!, $sendReminderCutoffDays: Int!) {
  createSeasonTeamKit(
    input: {orgId: $orgId, seasonId: $seasonId, sendPreCompetitionDays: $sendPreCompetitionDays, sendReminders: $sendReminders, sendReminderDays: $sendReminderDays, sendReminderCutoffDays: $sendReminderCutoffDays}
  ) {
    orgId
    seasonId
    id
    status
    sendPreCompetitionDays
    sendReminders
    sendReminderDays
    sendReminderCutoffDays
    activatedAt
    createdAt
    updatedAt
  }
}
    `;
export type CreateSeasonTeamKitMutationFn = Apollo.MutationFunction<CreateSeasonTeamKitMutation, CreateSeasonTeamKitMutationVariables>;

/**
 * __useCreateSeasonTeamKitMutation__
 *
 * To run a mutation, you first call `useCreateSeasonTeamKitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSeasonTeamKitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSeasonTeamKitMutation, { data, loading, error }] = useCreateSeasonTeamKitMutation({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      seasonId: // value for 'seasonId'
 *      sendPreCompetitionDays: // value for 'sendPreCompetitionDays'
 *      sendReminders: // value for 'sendReminders'
 *      sendReminderDays: // value for 'sendReminderDays'
 *      sendReminderCutoffDays: // value for 'sendReminderCutoffDays'
 *   },
 * });
 */
export function useCreateSeasonTeamKitMutation(baseOptions?: Apollo.MutationHookOptions<CreateSeasonTeamKitMutation, CreateSeasonTeamKitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSeasonTeamKitMutation, CreateSeasonTeamKitMutationVariables>(CreateSeasonTeamKitDocument, options);
      }
export type CreateSeasonTeamKitMutationHookResult = ReturnType<typeof useCreateSeasonTeamKitMutation>;
export type CreateSeasonTeamKitMutationResult = Apollo.MutationResult<CreateSeasonTeamKitMutation>;
export type CreateSeasonTeamKitMutationOptions = Apollo.BaseMutationOptions<CreateSeasonTeamKitMutation, CreateSeasonTeamKitMutationVariables>;
export const UpdateSeasonTeamKitDocument = gql`
    mutation updateSeasonTeamKit($orgId: String!, $seasonId: String!, $seasonTeamKitId: String!, $sendPreCompetitionDays: Int!, $sendReminders: Boolean!, $sendReminderDays: Int!, $sendReminderCutoffDays: Int!) {
  updateSeasonTeamKit(
    input: {orgId: $orgId, seasonId: $seasonId, seasonTeamKitId: $seasonTeamKitId, sendPreCompetitionDays: $sendPreCompetitionDays, sendReminders: $sendReminders, sendReminderDays: $sendReminderDays, sendReminderCutoffDays: $sendReminderCutoffDays}
  ) {
    orgId
    seasonId
    id
    status
    sendPreCompetitionDays
    sendReminders
    sendReminderDays
    sendReminderCutoffDays
    activatedAt
    createdAt
    updatedAt
  }
}
    `;
export type UpdateSeasonTeamKitMutationFn = Apollo.MutationFunction<UpdateSeasonTeamKitMutation, UpdateSeasonTeamKitMutationVariables>;

/**
 * __useUpdateSeasonTeamKitMutation__
 *
 * To run a mutation, you first call `useUpdateSeasonTeamKitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSeasonTeamKitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSeasonTeamKitMutation, { data, loading, error }] = useUpdateSeasonTeamKitMutation({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      seasonId: // value for 'seasonId'
 *      seasonTeamKitId: // value for 'seasonTeamKitId'
 *      sendPreCompetitionDays: // value for 'sendPreCompetitionDays'
 *      sendReminders: // value for 'sendReminders'
 *      sendReminderDays: // value for 'sendReminderDays'
 *      sendReminderCutoffDays: // value for 'sendReminderCutoffDays'
 *   },
 * });
 */
export function useUpdateSeasonTeamKitMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSeasonTeamKitMutation, UpdateSeasonTeamKitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSeasonTeamKitMutation, UpdateSeasonTeamKitMutationVariables>(UpdateSeasonTeamKitDocument, options);
      }
export type UpdateSeasonTeamKitMutationHookResult = ReturnType<typeof useUpdateSeasonTeamKitMutation>;
export type UpdateSeasonTeamKitMutationResult = Apollo.MutationResult<UpdateSeasonTeamKitMutation>;
export type UpdateSeasonTeamKitMutationOptions = Apollo.BaseMutationOptions<UpdateSeasonTeamKitMutation, UpdateSeasonTeamKitMutationVariables>;
export const ListSeasonTeamKitRolesDocument = gql`
    query listSeasonTeamKitRoles($offset: Int!, $limit: Int!, $seasonId: String!, $type: TeamKitRoleType) {
  listSeasonTeamKitRoles(
    input: {seasonId: $seasonId, type: $type, pagination: {offset: $offset, limit: $limit}}
  ) {
    seasonId
    kitRoles {
      id
      value
      type
      order
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListSeasonTeamKitRolesQuery__
 *
 * To run a query within a React component, call `useListSeasonTeamKitRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSeasonTeamKitRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSeasonTeamKitRolesQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      seasonId: // value for 'seasonId'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useListSeasonTeamKitRolesQuery(baseOptions: Apollo.QueryHookOptions<ListSeasonTeamKitRolesQuery, ListSeasonTeamKitRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListSeasonTeamKitRolesQuery, ListSeasonTeamKitRolesQueryVariables>(ListSeasonTeamKitRolesDocument, options);
      }
export function useListSeasonTeamKitRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListSeasonTeamKitRolesQuery, ListSeasonTeamKitRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListSeasonTeamKitRolesQuery, ListSeasonTeamKitRolesQueryVariables>(ListSeasonTeamKitRolesDocument, options);
        }
export type ListSeasonTeamKitRolesQueryHookResult = ReturnType<typeof useListSeasonTeamKitRolesQuery>;
export type ListSeasonTeamKitRolesLazyQueryHookResult = ReturnType<typeof useListSeasonTeamKitRolesLazyQuery>;
export type ListSeasonTeamKitRolesQueryResult = Apollo.QueryResult<ListSeasonTeamKitRolesQuery, ListSeasonTeamKitRolesQueryVariables>;
export const CreateSeasonTeamKitRoleDocument = gql`
    mutation createSeasonTeamKitRole($seasonId: String!, $value: String!, $type: TeamKitRoleType!) {
  createSeasonTeamKitRole(
    input: {seasonId: $seasonId, type: $type, value: $value}
  ) {
    id
    value
    type
    order
  }
}
    `;
export type CreateSeasonTeamKitRoleMutationFn = Apollo.MutationFunction<CreateSeasonTeamKitRoleMutation, CreateSeasonTeamKitRoleMutationVariables>;

/**
 * __useCreateSeasonTeamKitRoleMutation__
 *
 * To run a mutation, you first call `useCreateSeasonTeamKitRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSeasonTeamKitRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSeasonTeamKitRoleMutation, { data, loading, error }] = useCreateSeasonTeamKitRoleMutation({
 *   variables: {
 *      seasonId: // value for 'seasonId'
 *      value: // value for 'value'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useCreateSeasonTeamKitRoleMutation(baseOptions?: Apollo.MutationHookOptions<CreateSeasonTeamKitRoleMutation, CreateSeasonTeamKitRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSeasonTeamKitRoleMutation, CreateSeasonTeamKitRoleMutationVariables>(CreateSeasonTeamKitRoleDocument, options);
      }
export type CreateSeasonTeamKitRoleMutationHookResult = ReturnType<typeof useCreateSeasonTeamKitRoleMutation>;
export type CreateSeasonTeamKitRoleMutationResult = Apollo.MutationResult<CreateSeasonTeamKitRoleMutation>;
export type CreateSeasonTeamKitRoleMutationOptions = Apollo.BaseMutationOptions<CreateSeasonTeamKitRoleMutation, CreateSeasonTeamKitRoleMutationVariables>;
export const UpdateSeasonTeamKitRoleDocument = gql`
    mutation updateSeasonTeamKitRole($seasonId: String!, $kitRoleId: String!, $value: String!, $type: TeamKitRoleType!) {
  updateSeasonTeamKitRole(
    input: {seasonId: $seasonId, kitRoleId: $kitRoleId, type: $type, value: $value}
  ) {
    id
    value
    type
    order
  }
}
    `;
export type UpdateSeasonTeamKitRoleMutationFn = Apollo.MutationFunction<UpdateSeasonTeamKitRoleMutation, UpdateSeasonTeamKitRoleMutationVariables>;

/**
 * __useUpdateSeasonTeamKitRoleMutation__
 *
 * To run a mutation, you first call `useUpdateSeasonTeamKitRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSeasonTeamKitRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSeasonTeamKitRoleMutation, { data, loading, error }] = useUpdateSeasonTeamKitRoleMutation({
 *   variables: {
 *      seasonId: // value for 'seasonId'
 *      kitRoleId: // value for 'kitRoleId'
 *      value: // value for 'value'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useUpdateSeasonTeamKitRoleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSeasonTeamKitRoleMutation, UpdateSeasonTeamKitRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSeasonTeamKitRoleMutation, UpdateSeasonTeamKitRoleMutationVariables>(UpdateSeasonTeamKitRoleDocument, options);
      }
export type UpdateSeasonTeamKitRoleMutationHookResult = ReturnType<typeof useUpdateSeasonTeamKitRoleMutation>;
export type UpdateSeasonTeamKitRoleMutationResult = Apollo.MutationResult<UpdateSeasonTeamKitRoleMutation>;
export type UpdateSeasonTeamKitRoleMutationOptions = Apollo.BaseMutationOptions<UpdateSeasonTeamKitRoleMutation, UpdateSeasonTeamKitRoleMutationVariables>;
export const DeleteSeasonTeamKitRoleDocument = gql`
    mutation deleteSeasonTeamKitRole($seasonId: String!, $kitRoleId: String!) {
  deleteSeasonTeamKitRole(input: {seasonId: $seasonId, kitRoleId: $kitRoleId})
}
    `;
export type DeleteSeasonTeamKitRoleMutationFn = Apollo.MutationFunction<DeleteSeasonTeamKitRoleMutation, DeleteSeasonTeamKitRoleMutationVariables>;

/**
 * __useDeleteSeasonTeamKitRoleMutation__
 *
 * To run a mutation, you first call `useDeleteSeasonTeamKitRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSeasonTeamKitRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSeasonTeamKitRoleMutation, { data, loading, error }] = useDeleteSeasonTeamKitRoleMutation({
 *   variables: {
 *      seasonId: // value for 'seasonId'
 *      kitRoleId: // value for 'kitRoleId'
 *   },
 * });
 */
export function useDeleteSeasonTeamKitRoleMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSeasonTeamKitRoleMutation, DeleteSeasonTeamKitRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSeasonTeamKitRoleMutation, DeleteSeasonTeamKitRoleMutationVariables>(DeleteSeasonTeamKitRoleDocument, options);
      }
export type DeleteSeasonTeamKitRoleMutationHookResult = ReturnType<typeof useDeleteSeasonTeamKitRoleMutation>;
export type DeleteSeasonTeamKitRoleMutationResult = Apollo.MutationResult<DeleteSeasonTeamKitRoleMutation>;
export type DeleteSeasonTeamKitRoleMutationOptions = Apollo.BaseMutationOptions<DeleteSeasonTeamKitRoleMutation, DeleteSeasonTeamKitRoleMutationVariables>;
export const ListSeasonTeamKitApparelDefinitionsDocument = gql`
    query listSeasonTeamKitApparelDefinitions($offset: Int!, $limit: Int!, $seasonId: String!) {
  listSeasonTeamKitApparelDefinitions(
    input: {seasonId: $seasonId, pagination: {offset: $offset, limit: $limit}}
  ) {
    seasonId
    definitions {
      id
      value
      order
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListSeasonTeamKitApparelDefinitionsQuery__
 *
 * To run a query within a React component, call `useListSeasonTeamKitApparelDefinitionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSeasonTeamKitApparelDefinitionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSeasonTeamKitApparelDefinitionsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      seasonId: // value for 'seasonId'
 *   },
 * });
 */
export function useListSeasonTeamKitApparelDefinitionsQuery(baseOptions: Apollo.QueryHookOptions<ListSeasonTeamKitApparelDefinitionsQuery, ListSeasonTeamKitApparelDefinitionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListSeasonTeamKitApparelDefinitionsQuery, ListSeasonTeamKitApparelDefinitionsQueryVariables>(ListSeasonTeamKitApparelDefinitionsDocument, options);
      }
export function useListSeasonTeamKitApparelDefinitionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListSeasonTeamKitApparelDefinitionsQuery, ListSeasonTeamKitApparelDefinitionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListSeasonTeamKitApparelDefinitionsQuery, ListSeasonTeamKitApparelDefinitionsQueryVariables>(ListSeasonTeamKitApparelDefinitionsDocument, options);
        }
export type ListSeasonTeamKitApparelDefinitionsQueryHookResult = ReturnType<typeof useListSeasonTeamKitApparelDefinitionsQuery>;
export type ListSeasonTeamKitApparelDefinitionsLazyQueryHookResult = ReturnType<typeof useListSeasonTeamKitApparelDefinitionsLazyQuery>;
export type ListSeasonTeamKitApparelDefinitionsQueryResult = Apollo.QueryResult<ListSeasonTeamKitApparelDefinitionsQuery, ListSeasonTeamKitApparelDefinitionsQueryVariables>;
export const CreateSeasonTeamKitApparelDefinitionDocument = gql`
    mutation createSeasonTeamKitApparelDefinition($seasonId: String!, $value: String!) {
  createSeasonTeamKitApparelDefinition(
    input: {seasonId: $seasonId, value: $value}
  ) {
    id
    value
    order
  }
}
    `;
export type CreateSeasonTeamKitApparelDefinitionMutationFn = Apollo.MutationFunction<CreateSeasonTeamKitApparelDefinitionMutation, CreateSeasonTeamKitApparelDefinitionMutationVariables>;

/**
 * __useCreateSeasonTeamKitApparelDefinitionMutation__
 *
 * To run a mutation, you first call `useCreateSeasonTeamKitApparelDefinitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSeasonTeamKitApparelDefinitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSeasonTeamKitApparelDefinitionMutation, { data, loading, error }] = useCreateSeasonTeamKitApparelDefinitionMutation({
 *   variables: {
 *      seasonId: // value for 'seasonId'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useCreateSeasonTeamKitApparelDefinitionMutation(baseOptions?: Apollo.MutationHookOptions<CreateSeasonTeamKitApparelDefinitionMutation, CreateSeasonTeamKitApparelDefinitionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSeasonTeamKitApparelDefinitionMutation, CreateSeasonTeamKitApparelDefinitionMutationVariables>(CreateSeasonTeamKitApparelDefinitionDocument, options);
      }
export type CreateSeasonTeamKitApparelDefinitionMutationHookResult = ReturnType<typeof useCreateSeasonTeamKitApparelDefinitionMutation>;
export type CreateSeasonTeamKitApparelDefinitionMutationResult = Apollo.MutationResult<CreateSeasonTeamKitApparelDefinitionMutation>;
export type CreateSeasonTeamKitApparelDefinitionMutationOptions = Apollo.BaseMutationOptions<CreateSeasonTeamKitApparelDefinitionMutation, CreateSeasonTeamKitApparelDefinitionMutationVariables>;
export const UpdateSeasonTeamKitApparelDefinitionDocument = gql`
    mutation updateSeasonTeamKitApparelDefinition($seasonId: String!, $definitionId: String!, $value: String!) {
  updateSeasonTeamKitApparelDefinition(
    input: {seasonId: $seasonId, definitionId: $definitionId, value: $value}
  ) {
    id
    value
    order
  }
}
    `;
export type UpdateSeasonTeamKitApparelDefinitionMutationFn = Apollo.MutationFunction<UpdateSeasonTeamKitApparelDefinitionMutation, UpdateSeasonTeamKitApparelDefinitionMutationVariables>;

/**
 * __useUpdateSeasonTeamKitApparelDefinitionMutation__
 *
 * To run a mutation, you first call `useUpdateSeasonTeamKitApparelDefinitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSeasonTeamKitApparelDefinitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSeasonTeamKitApparelDefinitionMutation, { data, loading, error }] = useUpdateSeasonTeamKitApparelDefinitionMutation({
 *   variables: {
 *      seasonId: // value for 'seasonId'
 *      definitionId: // value for 'definitionId'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useUpdateSeasonTeamKitApparelDefinitionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSeasonTeamKitApparelDefinitionMutation, UpdateSeasonTeamKitApparelDefinitionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSeasonTeamKitApparelDefinitionMutation, UpdateSeasonTeamKitApparelDefinitionMutationVariables>(UpdateSeasonTeamKitApparelDefinitionDocument, options);
      }
export type UpdateSeasonTeamKitApparelDefinitionMutationHookResult = ReturnType<typeof useUpdateSeasonTeamKitApparelDefinitionMutation>;
export type UpdateSeasonTeamKitApparelDefinitionMutationResult = Apollo.MutationResult<UpdateSeasonTeamKitApparelDefinitionMutation>;
export type UpdateSeasonTeamKitApparelDefinitionMutationOptions = Apollo.BaseMutationOptions<UpdateSeasonTeamKitApparelDefinitionMutation, UpdateSeasonTeamKitApparelDefinitionMutationVariables>;
export const DeleteSeasonTeamKitApparelDefinitionDocument = gql`
    mutation deleteSeasonTeamKitApparelDefinition($seasonId: String!, $definitionId: String!) {
  deleteSeasonTeamKitApparelDefinition(
    input: {seasonId: $seasonId, definitionId: $definitionId}
  )
}
    `;
export type DeleteSeasonTeamKitApparelDefinitionMutationFn = Apollo.MutationFunction<DeleteSeasonTeamKitApparelDefinitionMutation, DeleteSeasonTeamKitApparelDefinitionMutationVariables>;

/**
 * __useDeleteSeasonTeamKitApparelDefinitionMutation__
 *
 * To run a mutation, you first call `useDeleteSeasonTeamKitApparelDefinitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSeasonTeamKitApparelDefinitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSeasonTeamKitApparelDefinitionMutation, { data, loading, error }] = useDeleteSeasonTeamKitApparelDefinitionMutation({
 *   variables: {
 *      seasonId: // value for 'seasonId'
 *      definitionId: // value for 'definitionId'
 *   },
 * });
 */
export function useDeleteSeasonTeamKitApparelDefinitionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSeasonTeamKitApparelDefinitionMutation, DeleteSeasonTeamKitApparelDefinitionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSeasonTeamKitApparelDefinitionMutation, DeleteSeasonTeamKitApparelDefinitionMutationVariables>(DeleteSeasonTeamKitApparelDefinitionDocument, options);
      }
export type DeleteSeasonTeamKitApparelDefinitionMutationHookResult = ReturnType<typeof useDeleteSeasonTeamKitApparelDefinitionMutation>;
export type DeleteSeasonTeamKitApparelDefinitionMutationResult = Apollo.MutationResult<DeleteSeasonTeamKitApparelDefinitionMutation>;
export type DeleteSeasonTeamKitApparelDefinitionMutationOptions = Apollo.BaseMutationOptions<DeleteSeasonTeamKitApparelDefinitionMutation, DeleteSeasonTeamKitApparelDefinitionMutationVariables>;
export const ListSeasonTeamKitContactDefinitionsDocument = gql`
    query listSeasonTeamKitContactDefinitions($offset: Int!, $limit: Int!, $seasonId: String!, $type: TeamKitContactType) {
  listSeasonTeamKitContactDefinitions(
    input: {seasonId: $seasonId, type: $type, pagination: {offset: $offset, limit: $limit}}
  ) {
    seasonId
    definitions {
      id
      value
      order
      type
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListSeasonTeamKitContactDefinitionsQuery__
 *
 * To run a query within a React component, call `useListSeasonTeamKitContactDefinitionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSeasonTeamKitContactDefinitionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSeasonTeamKitContactDefinitionsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      seasonId: // value for 'seasonId'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useListSeasonTeamKitContactDefinitionsQuery(baseOptions: Apollo.QueryHookOptions<ListSeasonTeamKitContactDefinitionsQuery, ListSeasonTeamKitContactDefinitionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListSeasonTeamKitContactDefinitionsQuery, ListSeasonTeamKitContactDefinitionsQueryVariables>(ListSeasonTeamKitContactDefinitionsDocument, options);
      }
export function useListSeasonTeamKitContactDefinitionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListSeasonTeamKitContactDefinitionsQuery, ListSeasonTeamKitContactDefinitionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListSeasonTeamKitContactDefinitionsQuery, ListSeasonTeamKitContactDefinitionsQueryVariables>(ListSeasonTeamKitContactDefinitionsDocument, options);
        }
export type ListSeasonTeamKitContactDefinitionsQueryHookResult = ReturnType<typeof useListSeasonTeamKitContactDefinitionsQuery>;
export type ListSeasonTeamKitContactDefinitionsLazyQueryHookResult = ReturnType<typeof useListSeasonTeamKitContactDefinitionsLazyQuery>;
export type ListSeasonTeamKitContactDefinitionsQueryResult = Apollo.QueryResult<ListSeasonTeamKitContactDefinitionsQuery, ListSeasonTeamKitContactDefinitionsQueryVariables>;
export const CreateSeasonTeamKitContactDefinitionDocument = gql`
    mutation createSeasonTeamKitContactDefinition($seasonId: String!, $value: String!, $type: TeamKitContactType!) {
  createSeasonTeamKitContactDefinition(
    input: {seasonId: $seasonId, value: $value, type: $type}
  ) {
    id
    value
    order
    type
  }
}
    `;
export type CreateSeasonTeamKitContactDefinitionMutationFn = Apollo.MutationFunction<CreateSeasonTeamKitContactDefinitionMutation, CreateSeasonTeamKitContactDefinitionMutationVariables>;

/**
 * __useCreateSeasonTeamKitContactDefinitionMutation__
 *
 * To run a mutation, you first call `useCreateSeasonTeamKitContactDefinitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSeasonTeamKitContactDefinitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSeasonTeamKitContactDefinitionMutation, { data, loading, error }] = useCreateSeasonTeamKitContactDefinitionMutation({
 *   variables: {
 *      seasonId: // value for 'seasonId'
 *      value: // value for 'value'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useCreateSeasonTeamKitContactDefinitionMutation(baseOptions?: Apollo.MutationHookOptions<CreateSeasonTeamKitContactDefinitionMutation, CreateSeasonTeamKitContactDefinitionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSeasonTeamKitContactDefinitionMutation, CreateSeasonTeamKitContactDefinitionMutationVariables>(CreateSeasonTeamKitContactDefinitionDocument, options);
      }
export type CreateSeasonTeamKitContactDefinitionMutationHookResult = ReturnType<typeof useCreateSeasonTeamKitContactDefinitionMutation>;
export type CreateSeasonTeamKitContactDefinitionMutationResult = Apollo.MutationResult<CreateSeasonTeamKitContactDefinitionMutation>;
export type CreateSeasonTeamKitContactDefinitionMutationOptions = Apollo.BaseMutationOptions<CreateSeasonTeamKitContactDefinitionMutation, CreateSeasonTeamKitContactDefinitionMutationVariables>;
export const UpdateSeasonTeamKitContactDefinitionDocument = gql`
    mutation updateSeasonTeamKitContactDefinition($seasonId: String!, $contactDefinitionId: String!, $value: String!, $type: TeamKitContactType!) {
  updateSeasonTeamKitContactDefinition(
    input: {seasonId: $seasonId, contactDefinitionId: $contactDefinitionId, value: $value, type: $type}
  ) {
    id
    value
    order
    type
  }
}
    `;
export type UpdateSeasonTeamKitContactDefinitionMutationFn = Apollo.MutationFunction<UpdateSeasonTeamKitContactDefinitionMutation, UpdateSeasonTeamKitContactDefinitionMutationVariables>;

/**
 * __useUpdateSeasonTeamKitContactDefinitionMutation__
 *
 * To run a mutation, you first call `useUpdateSeasonTeamKitContactDefinitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSeasonTeamKitContactDefinitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSeasonTeamKitContactDefinitionMutation, { data, loading, error }] = useUpdateSeasonTeamKitContactDefinitionMutation({
 *   variables: {
 *      seasonId: // value for 'seasonId'
 *      contactDefinitionId: // value for 'contactDefinitionId'
 *      value: // value for 'value'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useUpdateSeasonTeamKitContactDefinitionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSeasonTeamKitContactDefinitionMutation, UpdateSeasonTeamKitContactDefinitionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSeasonTeamKitContactDefinitionMutation, UpdateSeasonTeamKitContactDefinitionMutationVariables>(UpdateSeasonTeamKitContactDefinitionDocument, options);
      }
export type UpdateSeasonTeamKitContactDefinitionMutationHookResult = ReturnType<typeof useUpdateSeasonTeamKitContactDefinitionMutation>;
export type UpdateSeasonTeamKitContactDefinitionMutationResult = Apollo.MutationResult<UpdateSeasonTeamKitContactDefinitionMutation>;
export type UpdateSeasonTeamKitContactDefinitionMutationOptions = Apollo.BaseMutationOptions<UpdateSeasonTeamKitContactDefinitionMutation, UpdateSeasonTeamKitContactDefinitionMutationVariables>;
export const DeleteSeasonTeamKitContactDefinitionDocument = gql`
    mutation deleteSeasonTeamKitContactDefinition($seasonId: String!, $contactDefinitionId: String!) {
  deleteSeasonTeamKitContactDefinition(
    input: {seasonId: $seasonId, contactDefinitionId: $contactDefinitionId}
  )
}
    `;
export type DeleteSeasonTeamKitContactDefinitionMutationFn = Apollo.MutationFunction<DeleteSeasonTeamKitContactDefinitionMutation, DeleteSeasonTeamKitContactDefinitionMutationVariables>;

/**
 * __useDeleteSeasonTeamKitContactDefinitionMutation__
 *
 * To run a mutation, you first call `useDeleteSeasonTeamKitContactDefinitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSeasonTeamKitContactDefinitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSeasonTeamKitContactDefinitionMutation, { data, loading, error }] = useDeleteSeasonTeamKitContactDefinitionMutation({
 *   variables: {
 *      seasonId: // value for 'seasonId'
 *      contactDefinitionId: // value for 'contactDefinitionId'
 *   },
 * });
 */
export function useDeleteSeasonTeamKitContactDefinitionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSeasonTeamKitContactDefinitionMutation, DeleteSeasonTeamKitContactDefinitionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSeasonTeamKitContactDefinitionMutation, DeleteSeasonTeamKitContactDefinitionMutationVariables>(DeleteSeasonTeamKitContactDefinitionDocument, options);
      }
export type DeleteSeasonTeamKitContactDefinitionMutationHookResult = ReturnType<typeof useDeleteSeasonTeamKitContactDefinitionMutation>;
export type DeleteSeasonTeamKitContactDefinitionMutationResult = Apollo.MutationResult<DeleteSeasonTeamKitContactDefinitionMutation>;
export type DeleteSeasonTeamKitContactDefinitionMutationOptions = Apollo.BaseMutationOptions<DeleteSeasonTeamKitContactDefinitionMutation, DeleteSeasonTeamKitContactDefinitionMutationVariables>;
export const ListSeasonTeamKitContactOptionsDocument = gql`
    query listSeasonTeamKitContactOptions($offset: Int!, $limit: Int!, $seasonId: String!, $contactDefinitionId: String!, $teamId: String) {
  listSeasonTeamKitContactOptions(
    input: {seasonId: $seasonId, contactDefinitionId: $contactDefinitionId, teamId: $teamId, pagination: {offset: $offset, limit: $limit}}
  ) {
    seasonId
    options {
      id
      seasonId
      definitionId
      orgContactId
      teamContactId
      isDefault
      contact
      email
      phone
      firstName
      lastName
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListSeasonTeamKitContactOptionsQuery__
 *
 * To run a query within a React component, call `useListSeasonTeamKitContactOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSeasonTeamKitContactOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSeasonTeamKitContactOptionsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      seasonId: // value for 'seasonId'
 *      contactDefinitionId: // value for 'contactDefinitionId'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useListSeasonTeamKitContactOptionsQuery(baseOptions: Apollo.QueryHookOptions<ListSeasonTeamKitContactOptionsQuery, ListSeasonTeamKitContactOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListSeasonTeamKitContactOptionsQuery, ListSeasonTeamKitContactOptionsQueryVariables>(ListSeasonTeamKitContactOptionsDocument, options);
      }
export function useListSeasonTeamKitContactOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListSeasonTeamKitContactOptionsQuery, ListSeasonTeamKitContactOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListSeasonTeamKitContactOptionsQuery, ListSeasonTeamKitContactOptionsQueryVariables>(ListSeasonTeamKitContactOptionsDocument, options);
        }
export type ListSeasonTeamKitContactOptionsQueryHookResult = ReturnType<typeof useListSeasonTeamKitContactOptionsQuery>;
export type ListSeasonTeamKitContactOptionsLazyQueryHookResult = ReturnType<typeof useListSeasonTeamKitContactOptionsLazyQuery>;
export type ListSeasonTeamKitContactOptionsQueryResult = Apollo.QueryResult<ListSeasonTeamKitContactOptionsQuery, ListSeasonTeamKitContactOptionsQueryVariables>;
export const CreateSeasonTeamKitContactOptionDocument = gql`
    mutation createSeasonTeamKitContactOption($seasonId: String!, $contactDefinitionId: String!, $isDefault: Boolean!, $orgContactId: String, $teamContactId: String, $teamId: String) {
  createSeasonTeamKitContactOption(
    input: {seasonId: $seasonId, contactDefinitionId: $contactDefinitionId, orgContactId: $orgContactId, teamId: $teamId, teamContactId: $teamContactId, isDefault: $isDefault}
  ) {
    id
    seasonId
    definitionId
    orgContactId
    teamContactId
    isDefault
    contact
    email
    phone
    firstName
    lastName
  }
}
    `;
export type CreateSeasonTeamKitContactOptionMutationFn = Apollo.MutationFunction<CreateSeasonTeamKitContactOptionMutation, CreateSeasonTeamKitContactOptionMutationVariables>;

/**
 * __useCreateSeasonTeamKitContactOptionMutation__
 *
 * To run a mutation, you first call `useCreateSeasonTeamKitContactOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSeasonTeamKitContactOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSeasonTeamKitContactOptionMutation, { data, loading, error }] = useCreateSeasonTeamKitContactOptionMutation({
 *   variables: {
 *      seasonId: // value for 'seasonId'
 *      contactDefinitionId: // value for 'contactDefinitionId'
 *      isDefault: // value for 'isDefault'
 *      orgContactId: // value for 'orgContactId'
 *      teamContactId: // value for 'teamContactId'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useCreateSeasonTeamKitContactOptionMutation(baseOptions?: Apollo.MutationHookOptions<CreateSeasonTeamKitContactOptionMutation, CreateSeasonTeamKitContactOptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSeasonTeamKitContactOptionMutation, CreateSeasonTeamKitContactOptionMutationVariables>(CreateSeasonTeamKitContactOptionDocument, options);
      }
export type CreateSeasonTeamKitContactOptionMutationHookResult = ReturnType<typeof useCreateSeasonTeamKitContactOptionMutation>;
export type CreateSeasonTeamKitContactOptionMutationResult = Apollo.MutationResult<CreateSeasonTeamKitContactOptionMutation>;
export type CreateSeasonTeamKitContactOptionMutationOptions = Apollo.BaseMutationOptions<CreateSeasonTeamKitContactOptionMutation, CreateSeasonTeamKitContactOptionMutationVariables>;
export const SetDefaultSeasonTeamKitContactOptionDocument = gql`
    mutation setDefaultSeasonTeamKitContactOption($seasonId: String!, $contactOptionId: String!, $isDefault: Boolean!, $teamId: String) {
  setDefaultSeasonTeamKitContactOption(
    input: {seasonId: $seasonId, contactOptionId: $contactOptionId, isDefault: $isDefault, teamId: $teamId}
  ) {
    id
    seasonId
    definitionId
    orgContactId
    teamContactId
    isDefault
    contact
    email
    phone
    firstName
    lastName
  }
}
    `;
export type SetDefaultSeasonTeamKitContactOptionMutationFn = Apollo.MutationFunction<SetDefaultSeasonTeamKitContactOptionMutation, SetDefaultSeasonTeamKitContactOptionMutationVariables>;

/**
 * __useSetDefaultSeasonTeamKitContactOptionMutation__
 *
 * To run a mutation, you first call `useSetDefaultSeasonTeamKitContactOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDefaultSeasonTeamKitContactOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDefaultSeasonTeamKitContactOptionMutation, { data, loading, error }] = useSetDefaultSeasonTeamKitContactOptionMutation({
 *   variables: {
 *      seasonId: // value for 'seasonId'
 *      contactOptionId: // value for 'contactOptionId'
 *      isDefault: // value for 'isDefault'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useSetDefaultSeasonTeamKitContactOptionMutation(baseOptions?: Apollo.MutationHookOptions<SetDefaultSeasonTeamKitContactOptionMutation, SetDefaultSeasonTeamKitContactOptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetDefaultSeasonTeamKitContactOptionMutation, SetDefaultSeasonTeamKitContactOptionMutationVariables>(SetDefaultSeasonTeamKitContactOptionDocument, options);
      }
export type SetDefaultSeasonTeamKitContactOptionMutationHookResult = ReturnType<typeof useSetDefaultSeasonTeamKitContactOptionMutation>;
export type SetDefaultSeasonTeamKitContactOptionMutationResult = Apollo.MutationResult<SetDefaultSeasonTeamKitContactOptionMutation>;
export type SetDefaultSeasonTeamKitContactOptionMutationOptions = Apollo.BaseMutationOptions<SetDefaultSeasonTeamKitContactOptionMutation, SetDefaultSeasonTeamKitContactOptionMutationVariables>;
export const DeleteSeasonTeamKitContactOptionDocument = gql`
    mutation deleteSeasonTeamKitContactOption($seasonId: String!, $contactOptionId: String!, $teamId: String) {
  deleteSeasonTeamKitContactOption(
    input: {seasonId: $seasonId, contactOptionId: $contactOptionId, teamId: $teamId}
  )
}
    `;
export type DeleteSeasonTeamKitContactOptionMutationFn = Apollo.MutationFunction<DeleteSeasonTeamKitContactOptionMutation, DeleteSeasonTeamKitContactOptionMutationVariables>;

/**
 * __useDeleteSeasonTeamKitContactOptionMutation__
 *
 * To run a mutation, you first call `useDeleteSeasonTeamKitContactOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSeasonTeamKitContactOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSeasonTeamKitContactOptionMutation, { data, loading, error }] = useDeleteSeasonTeamKitContactOptionMutation({
 *   variables: {
 *      seasonId: // value for 'seasonId'
 *      contactOptionId: // value for 'contactOptionId'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useDeleteSeasonTeamKitContactOptionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSeasonTeamKitContactOptionMutation, DeleteSeasonTeamKitContactOptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSeasonTeamKitContactOptionMutation, DeleteSeasonTeamKitContactOptionMutationVariables>(DeleteSeasonTeamKitContactOptionDocument, options);
      }
export type DeleteSeasonTeamKitContactOptionMutationHookResult = ReturnType<typeof useDeleteSeasonTeamKitContactOptionMutation>;
export type DeleteSeasonTeamKitContactOptionMutationResult = Apollo.MutationResult<DeleteSeasonTeamKitContactOptionMutation>;
export type DeleteSeasonTeamKitContactOptionMutationOptions = Apollo.BaseMutationOptions<DeleteSeasonTeamKitContactOptionMutation, DeleteSeasonTeamKitContactOptionMutationVariables>;
export const ListTeamKitBroadcastOptionsForSeasonDocument = gql`
    query listTeamKitBroadcastOptionsForSeason($offset: Int!, $limit: Int!, $seasonId: String!, $teamId: String, $search: String) {
  listTeamKitBroadcastOptionsForSeason(
    input: {seasonId: $seasonId, teamId: $teamId, search: $search, pagination: {offset: $offset, limit: $limit}}
  ) {
    seasonId
    broadcastOptions {
      id
      seasonId
      teamId
      teamName
      value
      isActive
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListTeamKitBroadcastOptionsForSeasonQuery__
 *
 * To run a query within a React component, call `useListTeamKitBroadcastOptionsForSeasonQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTeamKitBroadcastOptionsForSeasonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTeamKitBroadcastOptionsForSeasonQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      seasonId: // value for 'seasonId'
 *      teamId: // value for 'teamId'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useListTeamKitBroadcastOptionsForSeasonQuery(baseOptions: Apollo.QueryHookOptions<ListTeamKitBroadcastOptionsForSeasonQuery, ListTeamKitBroadcastOptionsForSeasonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListTeamKitBroadcastOptionsForSeasonQuery, ListTeamKitBroadcastOptionsForSeasonQueryVariables>(ListTeamKitBroadcastOptionsForSeasonDocument, options);
      }
export function useListTeamKitBroadcastOptionsForSeasonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListTeamKitBroadcastOptionsForSeasonQuery, ListTeamKitBroadcastOptionsForSeasonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListTeamKitBroadcastOptionsForSeasonQuery, ListTeamKitBroadcastOptionsForSeasonQueryVariables>(ListTeamKitBroadcastOptionsForSeasonDocument, options);
        }
export type ListTeamKitBroadcastOptionsForSeasonQueryHookResult = ReturnType<typeof useListTeamKitBroadcastOptionsForSeasonQuery>;
export type ListTeamKitBroadcastOptionsForSeasonLazyQueryHookResult = ReturnType<typeof useListTeamKitBroadcastOptionsForSeasonLazyQuery>;
export type ListTeamKitBroadcastOptionsForSeasonQueryResult = Apollo.QueryResult<ListTeamKitBroadcastOptionsForSeasonQuery, ListTeamKitBroadcastOptionsForSeasonQueryVariables>;
export const CreateTeamKitBroadcastOptionForSeasonDocument = gql`
    mutation createTeamKitBroadcastOptionForSeason($seasonId: String!, $teamId: String, $value: String!, $isActive: Boolean!) {
  createTeamKitBroadcastOptionForSeason(
    input: {seasonId: $seasonId, teamId: $teamId, value: $value, isActive: $isActive}
  ) {
    id
    seasonId
    teamId
    teamName
    value
    isActive
  }
}
    `;
export type CreateTeamKitBroadcastOptionForSeasonMutationFn = Apollo.MutationFunction<CreateTeamKitBroadcastOptionForSeasonMutation, CreateTeamKitBroadcastOptionForSeasonMutationVariables>;

/**
 * __useCreateTeamKitBroadcastOptionForSeasonMutation__
 *
 * To run a mutation, you first call `useCreateTeamKitBroadcastOptionForSeasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTeamKitBroadcastOptionForSeasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTeamKitBroadcastOptionForSeasonMutation, { data, loading, error }] = useCreateTeamKitBroadcastOptionForSeasonMutation({
 *   variables: {
 *      seasonId: // value for 'seasonId'
 *      teamId: // value for 'teamId'
 *      value: // value for 'value'
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useCreateTeamKitBroadcastOptionForSeasonMutation(baseOptions?: Apollo.MutationHookOptions<CreateTeamKitBroadcastOptionForSeasonMutation, CreateTeamKitBroadcastOptionForSeasonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTeamKitBroadcastOptionForSeasonMutation, CreateTeamKitBroadcastOptionForSeasonMutationVariables>(CreateTeamKitBroadcastOptionForSeasonDocument, options);
      }
export type CreateTeamKitBroadcastOptionForSeasonMutationHookResult = ReturnType<typeof useCreateTeamKitBroadcastOptionForSeasonMutation>;
export type CreateTeamKitBroadcastOptionForSeasonMutationResult = Apollo.MutationResult<CreateTeamKitBroadcastOptionForSeasonMutation>;
export type CreateTeamKitBroadcastOptionForSeasonMutationOptions = Apollo.BaseMutationOptions<CreateTeamKitBroadcastOptionForSeasonMutation, CreateTeamKitBroadcastOptionForSeasonMutationVariables>;
export const UpdateTeamKitBroadcastOptionForSeasonDocument = gql`
    mutation updateTeamKitBroadcastOptionForSeason($seasonId: String!, $teamId: String, $optionId: String!, $value: String!, $isActive: Boolean!) {
  updateTeamKitBroadcastOptionForSeason(
    input: {seasonId: $seasonId, teamId: $teamId, optionId: $optionId, value: $value, isActive: $isActive}
  ) {
    id
    seasonId
    teamId
    teamName
    value
    isActive
  }
}
    `;
export type UpdateTeamKitBroadcastOptionForSeasonMutationFn = Apollo.MutationFunction<UpdateTeamKitBroadcastOptionForSeasonMutation, UpdateTeamKitBroadcastOptionForSeasonMutationVariables>;

/**
 * __useUpdateTeamKitBroadcastOptionForSeasonMutation__
 *
 * To run a mutation, you first call `useUpdateTeamKitBroadcastOptionForSeasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamKitBroadcastOptionForSeasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeamKitBroadcastOptionForSeasonMutation, { data, loading, error }] = useUpdateTeamKitBroadcastOptionForSeasonMutation({
 *   variables: {
 *      seasonId: // value for 'seasonId'
 *      teamId: // value for 'teamId'
 *      optionId: // value for 'optionId'
 *      value: // value for 'value'
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useUpdateTeamKitBroadcastOptionForSeasonMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTeamKitBroadcastOptionForSeasonMutation, UpdateTeamKitBroadcastOptionForSeasonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTeamKitBroadcastOptionForSeasonMutation, UpdateTeamKitBroadcastOptionForSeasonMutationVariables>(UpdateTeamKitBroadcastOptionForSeasonDocument, options);
      }
export type UpdateTeamKitBroadcastOptionForSeasonMutationHookResult = ReturnType<typeof useUpdateTeamKitBroadcastOptionForSeasonMutation>;
export type UpdateTeamKitBroadcastOptionForSeasonMutationResult = Apollo.MutationResult<UpdateTeamKitBroadcastOptionForSeasonMutation>;
export type UpdateTeamKitBroadcastOptionForSeasonMutationOptions = Apollo.BaseMutationOptions<UpdateTeamKitBroadcastOptionForSeasonMutation, UpdateTeamKitBroadcastOptionForSeasonMutationVariables>;
export const DeleteTeamKitBroadcastOptionForSeasonDocument = gql`
    mutation deleteTeamKitBroadcastOptionForSeason($seasonId: String!, $teamId: String, $optionId: String!) {
  deleteTeamKitBroadcastOptionForSeason(
    input: {seasonId: $seasonId, teamId: $teamId, optionId: $optionId}
  )
}
    `;
export type DeleteTeamKitBroadcastOptionForSeasonMutationFn = Apollo.MutationFunction<DeleteTeamKitBroadcastOptionForSeasonMutation, DeleteTeamKitBroadcastOptionForSeasonMutationVariables>;

/**
 * __useDeleteTeamKitBroadcastOptionForSeasonMutation__
 *
 * To run a mutation, you first call `useDeleteTeamKitBroadcastOptionForSeasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTeamKitBroadcastOptionForSeasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTeamKitBroadcastOptionForSeasonMutation, { data, loading, error }] = useDeleteTeamKitBroadcastOptionForSeasonMutation({
 *   variables: {
 *      seasonId: // value for 'seasonId'
 *      teamId: // value for 'teamId'
 *      optionId: // value for 'optionId'
 *   },
 * });
 */
export function useDeleteTeamKitBroadcastOptionForSeasonMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTeamKitBroadcastOptionForSeasonMutation, DeleteTeamKitBroadcastOptionForSeasonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTeamKitBroadcastOptionForSeasonMutation, DeleteTeamKitBroadcastOptionForSeasonMutationVariables>(DeleteTeamKitBroadcastOptionForSeasonDocument, options);
      }
export type DeleteTeamKitBroadcastOptionForSeasonMutationHookResult = ReturnType<typeof useDeleteTeamKitBroadcastOptionForSeasonMutation>;
export type DeleteTeamKitBroadcastOptionForSeasonMutationResult = Apollo.MutationResult<DeleteTeamKitBroadcastOptionForSeasonMutation>;
export type DeleteTeamKitBroadcastOptionForSeasonMutationOptions = Apollo.BaseMutationOptions<DeleteTeamKitBroadcastOptionForSeasonMutation, DeleteTeamKitBroadcastOptionForSeasonMutationVariables>;
export const ListTeamKitSeasonApparelsDocument = gql`
    query listTeamKitSeasonApparels($offset: Int!, $limit: Int!, $seasonId: String!, $kitRoleId: String!, $kitApparelId: String!, $teamId: String, $search: String) {
  listTeamKitSeasonApparels(
    input: {seasonId: $seasonId, kitRoleId: $kitRoleId, kitApparelId: $kitApparelId, teamId: $teamId, search: $search, pagination: {offset: $offset, limit: $limit}}
  ) {
    seasonId
    teamId
    apparels {
      seasonId
      teamId
      teamName
      id
      isActive
      name
      color
      imageUrl
      kitApparel {
        id
        value
        order
      }
      kitRole {
        id
        value
        type
        order
      }
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListTeamKitSeasonApparelsQuery__
 *
 * To run a query within a React component, call `useListTeamKitSeasonApparelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTeamKitSeasonApparelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTeamKitSeasonApparelsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      seasonId: // value for 'seasonId'
 *      kitRoleId: // value for 'kitRoleId'
 *      kitApparelId: // value for 'kitApparelId'
 *      teamId: // value for 'teamId'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useListTeamKitSeasonApparelsQuery(baseOptions: Apollo.QueryHookOptions<ListTeamKitSeasonApparelsQuery, ListTeamKitSeasonApparelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListTeamKitSeasonApparelsQuery, ListTeamKitSeasonApparelsQueryVariables>(ListTeamKitSeasonApparelsDocument, options);
      }
export function useListTeamKitSeasonApparelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListTeamKitSeasonApparelsQuery, ListTeamKitSeasonApparelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListTeamKitSeasonApparelsQuery, ListTeamKitSeasonApparelsQueryVariables>(ListTeamKitSeasonApparelsDocument, options);
        }
export type ListTeamKitSeasonApparelsQueryHookResult = ReturnType<typeof useListTeamKitSeasonApparelsQuery>;
export type ListTeamKitSeasonApparelsLazyQueryHookResult = ReturnType<typeof useListTeamKitSeasonApparelsLazyQuery>;
export type ListTeamKitSeasonApparelsQueryResult = Apollo.QueryResult<ListTeamKitSeasonApparelsQuery, ListTeamKitSeasonApparelsQueryVariables>;
export const UpdateTeamKitSeasonApparelDocument = gql`
    mutation updateTeamKitSeasonApparel($seasonId: String!, $kitRoleId: String!, $kitApparelId: String!, $teamId: String, $seasonApparelId: String!, $name: String!, $color: String!, $isActive: Boolean!) {
  updateTeamKitSeasonApparel(
    input: {seasonId: $seasonId, kitRoleId: $kitRoleId, kitApparelId: $kitApparelId, seasonApparelId: $seasonApparelId, teamId: $teamId, name: $name, color: $color, isActive: $isActive}
  ) {
    seasonId
    teamId
    teamName
    id
    isActive
    name
    color
    imageUrl
    kitApparel {
      id
      value
      order
    }
    kitRole {
      id
      value
      type
      order
    }
  }
}
    `;
export type UpdateTeamKitSeasonApparelMutationFn = Apollo.MutationFunction<UpdateTeamKitSeasonApparelMutation, UpdateTeamKitSeasonApparelMutationVariables>;

/**
 * __useUpdateTeamKitSeasonApparelMutation__
 *
 * To run a mutation, you first call `useUpdateTeamKitSeasonApparelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamKitSeasonApparelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeamKitSeasonApparelMutation, { data, loading, error }] = useUpdateTeamKitSeasonApparelMutation({
 *   variables: {
 *      seasonId: // value for 'seasonId'
 *      kitRoleId: // value for 'kitRoleId'
 *      kitApparelId: // value for 'kitApparelId'
 *      teamId: // value for 'teamId'
 *      seasonApparelId: // value for 'seasonApparelId'
 *      name: // value for 'name'
 *      color: // value for 'color'
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useUpdateTeamKitSeasonApparelMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTeamKitSeasonApparelMutation, UpdateTeamKitSeasonApparelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTeamKitSeasonApparelMutation, UpdateTeamKitSeasonApparelMutationVariables>(UpdateTeamKitSeasonApparelDocument, options);
      }
export type UpdateTeamKitSeasonApparelMutationHookResult = ReturnType<typeof useUpdateTeamKitSeasonApparelMutation>;
export type UpdateTeamKitSeasonApparelMutationResult = Apollo.MutationResult<UpdateTeamKitSeasonApparelMutation>;
export type UpdateTeamKitSeasonApparelMutationOptions = Apollo.BaseMutationOptions<UpdateTeamKitSeasonApparelMutation, UpdateTeamKitSeasonApparelMutationVariables>;
export const DeleteTeamKitSeasonApparelDocument = gql`
    mutation deleteTeamKitSeasonApparel($seasonId: String!, $kitRoleId: String!, $kitApparelId: String!, $seasonApparelId: String!, $teamId: String) {
  deleteTeamKitSeasonApparel(
    input: {seasonId: $seasonId, kitRoleId: $kitRoleId, kitApparelId: $kitApparelId, seasonApparelId: $seasonApparelId, teamId: $teamId}
  )
}
    `;
export type DeleteTeamKitSeasonApparelMutationFn = Apollo.MutationFunction<DeleteTeamKitSeasonApparelMutation, DeleteTeamKitSeasonApparelMutationVariables>;

/**
 * __useDeleteTeamKitSeasonApparelMutation__
 *
 * To run a mutation, you first call `useDeleteTeamKitSeasonApparelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTeamKitSeasonApparelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTeamKitSeasonApparelMutation, { data, loading, error }] = useDeleteTeamKitSeasonApparelMutation({
 *   variables: {
 *      seasonId: // value for 'seasonId'
 *      kitRoleId: // value for 'kitRoleId'
 *      kitApparelId: // value for 'kitApparelId'
 *      seasonApparelId: // value for 'seasonApparelId'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useDeleteTeamKitSeasonApparelMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTeamKitSeasonApparelMutation, DeleteTeamKitSeasonApparelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTeamKitSeasonApparelMutation, DeleteTeamKitSeasonApparelMutationVariables>(DeleteTeamKitSeasonApparelDocument, options);
      }
export type DeleteTeamKitSeasonApparelMutationHookResult = ReturnType<typeof useDeleteTeamKitSeasonApparelMutation>;
export type DeleteTeamKitSeasonApparelMutationResult = Apollo.MutationResult<DeleteTeamKitSeasonApparelMutation>;
export type DeleteTeamKitSeasonApparelMutationOptions = Apollo.BaseMutationOptions<DeleteTeamKitSeasonApparelMutation, DeleteTeamKitSeasonApparelMutationVariables>;
export const ListTeamKitSeasonKitsDocument = gql`
    query listTeamKitSeasonKits($offset: Int!, $limit: Int!, $seasonId: String!, $kitRoleId: String!, $type: TeamKitType, $teamId: String, $search: String) {
  listTeamKitSeasonKits(
    input: {seasonId: $seasonId, kitRoleId: $kitRoleId, type: $type, search: $search, teamId: $teamId, pagination: {offset: $offset, limit: $limit}}
  ) {
    seasonId
    kits {
      seasonId
      teamId
      teamName
      roleId
      role
      definitionId
      definition
      id
      type
      apparel {
        seasonId
        teamId
        teamName
        id
        isActive
        name
        color
        imageUrl
      }
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListTeamKitSeasonKitsQuery__
 *
 * To run a query within a React component, call `useListTeamKitSeasonKitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTeamKitSeasonKitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTeamKitSeasonKitsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      seasonId: // value for 'seasonId'
 *      kitRoleId: // value for 'kitRoleId'
 *      type: // value for 'type'
 *      teamId: // value for 'teamId'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useListTeamKitSeasonKitsQuery(baseOptions: Apollo.QueryHookOptions<ListTeamKitSeasonKitsQuery, ListTeamKitSeasonKitsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListTeamKitSeasonKitsQuery, ListTeamKitSeasonKitsQueryVariables>(ListTeamKitSeasonKitsDocument, options);
      }
export function useListTeamKitSeasonKitsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListTeamKitSeasonKitsQuery, ListTeamKitSeasonKitsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListTeamKitSeasonKitsQuery, ListTeamKitSeasonKitsQueryVariables>(ListTeamKitSeasonKitsDocument, options);
        }
export type ListTeamKitSeasonKitsQueryHookResult = ReturnType<typeof useListTeamKitSeasonKitsQuery>;
export type ListTeamKitSeasonKitsLazyQueryHookResult = ReturnType<typeof useListTeamKitSeasonKitsLazyQuery>;
export type ListTeamKitSeasonKitsQueryResult = Apollo.QueryResult<ListTeamKitSeasonKitsQuery, ListTeamKitSeasonKitsQueryVariables>;
export const CreateTeamKitSeasonKitDocument = gql`
    mutation createTeamKitSeasonKit($seasonId: String!, $kitRoleId: String!, $definitionId: String!, $teamId: String, $apparelId: String!, $type: TeamKitType!) {
  createTeamKitSeasonKit(
    input: {seasonId: $seasonId, kitRoleId: $kitRoleId, definitionId: $definitionId, apparelId: $apparelId, teamId: $teamId, type: $type}
  ) {
    seasonId
    teamId
    teamName
    roleId
    role
    definitionId
    definition
    id
    type
    apparel {
      seasonId
      teamId
      teamName
      id
      isActive
      name
      color
      imageUrl
    }
  }
}
    `;
export type CreateTeamKitSeasonKitMutationFn = Apollo.MutationFunction<CreateTeamKitSeasonKitMutation, CreateTeamKitSeasonKitMutationVariables>;

/**
 * __useCreateTeamKitSeasonKitMutation__
 *
 * To run a mutation, you first call `useCreateTeamKitSeasonKitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTeamKitSeasonKitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTeamKitSeasonKitMutation, { data, loading, error }] = useCreateTeamKitSeasonKitMutation({
 *   variables: {
 *      seasonId: // value for 'seasonId'
 *      kitRoleId: // value for 'kitRoleId'
 *      definitionId: // value for 'definitionId'
 *      teamId: // value for 'teamId'
 *      apparelId: // value for 'apparelId'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useCreateTeamKitSeasonKitMutation(baseOptions?: Apollo.MutationHookOptions<CreateTeamKitSeasonKitMutation, CreateTeamKitSeasonKitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTeamKitSeasonKitMutation, CreateTeamKitSeasonKitMutationVariables>(CreateTeamKitSeasonKitDocument, options);
      }
export type CreateTeamKitSeasonKitMutationHookResult = ReturnType<typeof useCreateTeamKitSeasonKitMutation>;
export type CreateTeamKitSeasonKitMutationResult = Apollo.MutationResult<CreateTeamKitSeasonKitMutation>;
export type CreateTeamKitSeasonKitMutationOptions = Apollo.BaseMutationOptions<CreateTeamKitSeasonKitMutation, CreateTeamKitSeasonKitMutationVariables>;
export const UpdateTeamKitSeasonKitDocument = gql`
    mutation updateTeamKitSeasonKit($seasonId: String!, $kitRoleId: String!, $definitionId: String!, $teamId: String, $apparelId: String!, $seasonKitId: String!, $type: TeamKitType!) {
  updateTeamKitSeasonKit(
    input: {seasonId: $seasonId, kitRoleId: $kitRoleId, definitionId: $definitionId, seasonKitId: $seasonKitId, apparelId: $apparelId, teamId: $teamId, type: $type}
  ) {
    seasonId
    teamId
    teamName
    roleId
    role
    definitionId
    definition
    id
    type
    apparel {
      seasonId
      teamId
      teamName
      id
      isActive
      name
      color
      imageUrl
    }
  }
}
    `;
export type UpdateTeamKitSeasonKitMutationFn = Apollo.MutationFunction<UpdateTeamKitSeasonKitMutation, UpdateTeamKitSeasonKitMutationVariables>;

/**
 * __useUpdateTeamKitSeasonKitMutation__
 *
 * To run a mutation, you first call `useUpdateTeamKitSeasonKitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamKitSeasonKitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeamKitSeasonKitMutation, { data, loading, error }] = useUpdateTeamKitSeasonKitMutation({
 *   variables: {
 *      seasonId: // value for 'seasonId'
 *      kitRoleId: // value for 'kitRoleId'
 *      definitionId: // value for 'definitionId'
 *      teamId: // value for 'teamId'
 *      apparelId: // value for 'apparelId'
 *      seasonKitId: // value for 'seasonKitId'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useUpdateTeamKitSeasonKitMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTeamKitSeasonKitMutation, UpdateTeamKitSeasonKitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTeamKitSeasonKitMutation, UpdateTeamKitSeasonKitMutationVariables>(UpdateTeamKitSeasonKitDocument, options);
      }
export type UpdateTeamKitSeasonKitMutationHookResult = ReturnType<typeof useUpdateTeamKitSeasonKitMutation>;
export type UpdateTeamKitSeasonKitMutationResult = Apollo.MutationResult<UpdateTeamKitSeasonKitMutation>;
export type UpdateTeamKitSeasonKitMutationOptions = Apollo.BaseMutationOptions<UpdateTeamKitSeasonKitMutation, UpdateTeamKitSeasonKitMutationVariables>;
export const DeleteTeamKitSeasonKitDocument = gql`
    mutation deleteTeamKitSeasonKit($seasonId: String!, $seasonKitId: String!, $teamId: String) {
  deleteTeamKitSeasonKit(
    input: {seasonId: $seasonId, seasonKitId: $seasonKitId, teamId: $teamId}
  )
}
    `;
export type DeleteTeamKitSeasonKitMutationFn = Apollo.MutationFunction<DeleteTeamKitSeasonKitMutation, DeleteTeamKitSeasonKitMutationVariables>;

/**
 * __useDeleteTeamKitSeasonKitMutation__
 *
 * To run a mutation, you first call `useDeleteTeamKitSeasonKitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTeamKitSeasonKitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTeamKitSeasonKitMutation, { data, loading, error }] = useDeleteTeamKitSeasonKitMutation({
 *   variables: {
 *      seasonId: // value for 'seasonId'
 *      seasonKitId: // value for 'seasonKitId'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useDeleteTeamKitSeasonKitMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTeamKitSeasonKitMutation, DeleteTeamKitSeasonKitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTeamKitSeasonKitMutation, DeleteTeamKitSeasonKitMutationVariables>(DeleteTeamKitSeasonKitDocument, options);
      }
export type DeleteTeamKitSeasonKitMutationHookResult = ReturnType<typeof useDeleteTeamKitSeasonKitMutation>;
export type DeleteTeamKitSeasonKitMutationResult = Apollo.MutationResult<DeleteTeamKitSeasonKitMutation>;
export type DeleteTeamKitSeasonKitMutationOptions = Apollo.BaseMutationOptions<DeleteTeamKitSeasonKitMutation, DeleteTeamKitSeasonKitMutationVariables>;
export const ListSeasonTeamKitOrgRecipientsDocument = gql`
    query listSeasonTeamKitOrgRecipients($offset: Int!, $limit: Int!, $orgId: String!, $seasonId: String!) {
  listSeasonTeamKitOrgRecipients(
    input: {seasonId: $seasonId, orgId: $orgId, pagination: {offset: $offset, limit: $limit}}
  ) {
    seasonId
    recipients {
      id
      SeasonId
      orgMembershipId
      orgId
      orgName
      orgAbbreviation
      orgLogo
      isActive
      title
      userId
      email
      firstName
      lastName
      profilePic
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListSeasonTeamKitOrgRecipientsQuery__
 *
 * To run a query within a React component, call `useListSeasonTeamKitOrgRecipientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSeasonTeamKitOrgRecipientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSeasonTeamKitOrgRecipientsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      orgId: // value for 'orgId'
 *      seasonId: // value for 'seasonId'
 *   },
 * });
 */
export function useListSeasonTeamKitOrgRecipientsQuery(baseOptions: Apollo.QueryHookOptions<ListSeasonTeamKitOrgRecipientsQuery, ListSeasonTeamKitOrgRecipientsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListSeasonTeamKitOrgRecipientsQuery, ListSeasonTeamKitOrgRecipientsQueryVariables>(ListSeasonTeamKitOrgRecipientsDocument, options);
      }
export function useListSeasonTeamKitOrgRecipientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListSeasonTeamKitOrgRecipientsQuery, ListSeasonTeamKitOrgRecipientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListSeasonTeamKitOrgRecipientsQuery, ListSeasonTeamKitOrgRecipientsQueryVariables>(ListSeasonTeamKitOrgRecipientsDocument, options);
        }
export type ListSeasonTeamKitOrgRecipientsQueryHookResult = ReturnType<typeof useListSeasonTeamKitOrgRecipientsQuery>;
export type ListSeasonTeamKitOrgRecipientsLazyQueryHookResult = ReturnType<typeof useListSeasonTeamKitOrgRecipientsLazyQuery>;
export type ListSeasonTeamKitOrgRecipientsQueryResult = Apollo.QueryResult<ListSeasonTeamKitOrgRecipientsQuery, ListSeasonTeamKitOrgRecipientsQueryVariables>;
export const AddSeasonTeamKitOrgRecipientDocument = gql`
    mutation addSeasonTeamKitOrgRecipient($orgId: String!, $seasonId: String!, $orgMembershipId: String!) {
  addSeasonTeamKitOrgRecipient(
    input: {orgId: $orgId, seasonId: $seasonId, orgMembershipId: $orgMembershipId}
  ) {
    id
    SeasonId
    orgMembershipId
    orgId
    orgName
    orgAbbreviation
    orgLogo
    isActive
    title
    userId
    email
    firstName
    lastName
    profilePic
  }
}
    `;
export type AddSeasonTeamKitOrgRecipientMutationFn = Apollo.MutationFunction<AddSeasonTeamKitOrgRecipientMutation, AddSeasonTeamKitOrgRecipientMutationVariables>;

/**
 * __useAddSeasonTeamKitOrgRecipientMutation__
 *
 * To run a mutation, you first call `useAddSeasonTeamKitOrgRecipientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSeasonTeamKitOrgRecipientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSeasonTeamKitOrgRecipientMutation, { data, loading, error }] = useAddSeasonTeamKitOrgRecipientMutation({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      seasonId: // value for 'seasonId'
 *      orgMembershipId: // value for 'orgMembershipId'
 *   },
 * });
 */
export function useAddSeasonTeamKitOrgRecipientMutation(baseOptions?: Apollo.MutationHookOptions<AddSeasonTeamKitOrgRecipientMutation, AddSeasonTeamKitOrgRecipientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddSeasonTeamKitOrgRecipientMutation, AddSeasonTeamKitOrgRecipientMutationVariables>(AddSeasonTeamKitOrgRecipientDocument, options);
      }
export type AddSeasonTeamKitOrgRecipientMutationHookResult = ReturnType<typeof useAddSeasonTeamKitOrgRecipientMutation>;
export type AddSeasonTeamKitOrgRecipientMutationResult = Apollo.MutationResult<AddSeasonTeamKitOrgRecipientMutation>;
export type AddSeasonTeamKitOrgRecipientMutationOptions = Apollo.BaseMutationOptions<AddSeasonTeamKitOrgRecipientMutation, AddSeasonTeamKitOrgRecipientMutationVariables>;
export const ActivateSeasonTeamKitOrgRecipientDocument = gql`
    mutation activateSeasonTeamKitOrgRecipient($orgId: String!, $seasonId: String!, $orgRecipientId: String!, $isActive: Boolean!) {
  activateSeasonTeamKitOrgRecipient(
    input: {orgId: $orgId, seasonId: $seasonId, orgRecipientId: $orgRecipientId, isActive: $isActive}
  ) {
    id
    SeasonId
    orgMembershipId
    orgId
    orgName
    orgAbbreviation
    orgLogo
    isActive
    title
    userId
    email
    firstName
    lastName
    profilePic
  }
}
    `;
export type ActivateSeasonTeamKitOrgRecipientMutationFn = Apollo.MutationFunction<ActivateSeasonTeamKitOrgRecipientMutation, ActivateSeasonTeamKitOrgRecipientMutationVariables>;

/**
 * __useActivateSeasonTeamKitOrgRecipientMutation__
 *
 * To run a mutation, you first call `useActivateSeasonTeamKitOrgRecipientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateSeasonTeamKitOrgRecipientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateSeasonTeamKitOrgRecipientMutation, { data, loading, error }] = useActivateSeasonTeamKitOrgRecipientMutation({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      seasonId: // value for 'seasonId'
 *      orgRecipientId: // value for 'orgRecipientId'
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useActivateSeasonTeamKitOrgRecipientMutation(baseOptions?: Apollo.MutationHookOptions<ActivateSeasonTeamKitOrgRecipientMutation, ActivateSeasonTeamKitOrgRecipientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateSeasonTeamKitOrgRecipientMutation, ActivateSeasonTeamKitOrgRecipientMutationVariables>(ActivateSeasonTeamKitOrgRecipientDocument, options);
      }
export type ActivateSeasonTeamKitOrgRecipientMutationHookResult = ReturnType<typeof useActivateSeasonTeamKitOrgRecipientMutation>;
export type ActivateSeasonTeamKitOrgRecipientMutationResult = Apollo.MutationResult<ActivateSeasonTeamKitOrgRecipientMutation>;
export type ActivateSeasonTeamKitOrgRecipientMutationOptions = Apollo.BaseMutationOptions<ActivateSeasonTeamKitOrgRecipientMutation, ActivateSeasonTeamKitOrgRecipientMutationVariables>;
export const DeleteSeasonTeamKitOrgRecipientDocument = gql`
    mutation deleteSeasonTeamKitOrgRecipient($orgId: String!, $seasonId: String!, $orgRecipientId: String!) {
  deleteSeasonTeamKitOrgRecipient(
    input: {orgId: $orgId, seasonId: $seasonId, orgRecipientId: $orgRecipientId}
  )
}
    `;
export type DeleteSeasonTeamKitOrgRecipientMutationFn = Apollo.MutationFunction<DeleteSeasonTeamKitOrgRecipientMutation, DeleteSeasonTeamKitOrgRecipientMutationVariables>;

/**
 * __useDeleteSeasonTeamKitOrgRecipientMutation__
 *
 * To run a mutation, you first call `useDeleteSeasonTeamKitOrgRecipientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSeasonTeamKitOrgRecipientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSeasonTeamKitOrgRecipientMutation, { data, loading, error }] = useDeleteSeasonTeamKitOrgRecipientMutation({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      seasonId: // value for 'seasonId'
 *      orgRecipientId: // value for 'orgRecipientId'
 *   },
 * });
 */
export function useDeleteSeasonTeamKitOrgRecipientMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSeasonTeamKitOrgRecipientMutation, DeleteSeasonTeamKitOrgRecipientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSeasonTeamKitOrgRecipientMutation, DeleteSeasonTeamKitOrgRecipientMutationVariables>(DeleteSeasonTeamKitOrgRecipientDocument, options);
      }
export type DeleteSeasonTeamKitOrgRecipientMutationHookResult = ReturnType<typeof useDeleteSeasonTeamKitOrgRecipientMutation>;
export type DeleteSeasonTeamKitOrgRecipientMutationResult = Apollo.MutationResult<DeleteSeasonTeamKitOrgRecipientMutation>;
export type DeleteSeasonTeamKitOrgRecipientMutationOptions = Apollo.BaseMutationOptions<DeleteSeasonTeamKitOrgRecipientMutation, DeleteSeasonTeamKitOrgRecipientMutationVariables>;
export const GetTeamKitOrgSetupSummaryForSeasonDocument = gql`
    query getTeamKitOrgSetupSummaryForSeason($seasonId: String!) {
  getTeamKitOrgSetupSummaryForSeason(input: {seasonId: $seasonId}) {
    orgId
    orgName
    orgLogo
    leagueId
    leagueName
    seasonId
    seasonName
    orgRecipientsCount
    orgContactsCount
    orgContactOptionsCount
    orgContactOptionsTarget
    orgApparelSetCount
    orgTotalApparelSetTarget
    apparelsForRoles {
      seasonId
      seasonName
      roleId
      role
      roleOrder
      definitionId
      definition
      definitionOrder
      apparelCount
    }
  }
}
    `;

/**
 * __useGetTeamKitOrgSetupSummaryForSeasonQuery__
 *
 * To run a query within a React component, call `useGetTeamKitOrgSetupSummaryForSeasonQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamKitOrgSetupSummaryForSeasonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamKitOrgSetupSummaryForSeasonQuery({
 *   variables: {
 *      seasonId: // value for 'seasonId'
 *   },
 * });
 */
export function useGetTeamKitOrgSetupSummaryForSeasonQuery(baseOptions: Apollo.QueryHookOptions<GetTeamKitOrgSetupSummaryForSeasonQuery, GetTeamKitOrgSetupSummaryForSeasonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTeamKitOrgSetupSummaryForSeasonQuery, GetTeamKitOrgSetupSummaryForSeasonQueryVariables>(GetTeamKitOrgSetupSummaryForSeasonDocument, options);
      }
export function useGetTeamKitOrgSetupSummaryForSeasonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTeamKitOrgSetupSummaryForSeasonQuery, GetTeamKitOrgSetupSummaryForSeasonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTeamKitOrgSetupSummaryForSeasonQuery, GetTeamKitOrgSetupSummaryForSeasonQueryVariables>(GetTeamKitOrgSetupSummaryForSeasonDocument, options);
        }
export type GetTeamKitOrgSetupSummaryForSeasonQueryHookResult = ReturnType<typeof useGetTeamKitOrgSetupSummaryForSeasonQuery>;
export type GetTeamKitOrgSetupSummaryForSeasonLazyQueryHookResult = ReturnType<typeof useGetTeamKitOrgSetupSummaryForSeasonLazyQuery>;
export type GetTeamKitOrgSetupSummaryForSeasonQueryResult = Apollo.QueryResult<GetTeamKitOrgSetupSummaryForSeasonQuery, GetTeamKitOrgSetupSummaryForSeasonQueryVariables>;
export const ActivateTeamKitForSeasonDocument = gql`
    mutation activateTeamKitForSeason($orgId: String!, $seasonId: String!, $seasonTeamKitId: String!, $sendTeamEmails: Boolean!) {
  activateTeamKitForSeason(
    input: {orgId: $orgId, seasonId: $seasonId, seasonTeamKitId: $seasonTeamKitId, sendTeamEmails: $sendTeamEmails}
  ) {
    seasonId
    errors
    teamKit {
      orgId
      seasonId
      id
      status
      sendPreCompetitionDays
      sendReminders
      sendReminderDays
      sendReminderCutoffDays
      activatedAt
      createdAt
      updatedAt
    }
  }
}
    `;
export type ActivateTeamKitForSeasonMutationFn = Apollo.MutationFunction<ActivateTeamKitForSeasonMutation, ActivateTeamKitForSeasonMutationVariables>;

/**
 * __useActivateTeamKitForSeasonMutation__
 *
 * To run a mutation, you first call `useActivateTeamKitForSeasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateTeamKitForSeasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateTeamKitForSeasonMutation, { data, loading, error }] = useActivateTeamKitForSeasonMutation({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      seasonId: // value for 'seasonId'
 *      seasonTeamKitId: // value for 'seasonTeamKitId'
 *      sendTeamEmails: // value for 'sendTeamEmails'
 *   },
 * });
 */
export function useActivateTeamKitForSeasonMutation(baseOptions?: Apollo.MutationHookOptions<ActivateTeamKitForSeasonMutation, ActivateTeamKitForSeasonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateTeamKitForSeasonMutation, ActivateTeamKitForSeasonMutationVariables>(ActivateTeamKitForSeasonDocument, options);
      }
export type ActivateTeamKitForSeasonMutationHookResult = ReturnType<typeof useActivateTeamKitForSeasonMutation>;
export type ActivateTeamKitForSeasonMutationResult = Apollo.MutationResult<ActivateTeamKitForSeasonMutation>;
export type ActivateTeamKitForSeasonMutationOptions = Apollo.BaseMutationOptions<ActivateTeamKitForSeasonMutation, ActivateTeamKitForSeasonMutationVariables>;
export const DeactivateTeamKitForSeasonDocument = gql`
    mutation deactivateTeamKitForSeason($orgId: String!, $seasonId: String!, $seasonTeamKitId: String!) {
  deactivateTeamKitForSeason(
    input: {orgId: $orgId, seasonId: $seasonId, seasonTeamKitId: $seasonTeamKitId}
  ) {
    orgId
    seasonId
    id
    status
    sendPreCompetitionDays
    sendReminders
    sendReminderDays
    sendReminderCutoffDays
    activatedAt
    createdAt
    updatedAt
  }
}
    `;
export type DeactivateTeamKitForSeasonMutationFn = Apollo.MutationFunction<DeactivateTeamKitForSeasonMutation, DeactivateTeamKitForSeasonMutationVariables>;

/**
 * __useDeactivateTeamKitForSeasonMutation__
 *
 * To run a mutation, you first call `useDeactivateTeamKitForSeasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateTeamKitForSeasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateTeamKitForSeasonMutation, { data, loading, error }] = useDeactivateTeamKitForSeasonMutation({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      seasonId: // value for 'seasonId'
 *      seasonTeamKitId: // value for 'seasonTeamKitId'
 *   },
 * });
 */
export function useDeactivateTeamKitForSeasonMutation(baseOptions?: Apollo.MutationHookOptions<DeactivateTeamKitForSeasonMutation, DeactivateTeamKitForSeasonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeactivateTeamKitForSeasonMutation, DeactivateTeamKitForSeasonMutationVariables>(DeactivateTeamKitForSeasonDocument, options);
      }
export type DeactivateTeamKitForSeasonMutationHookResult = ReturnType<typeof useDeactivateTeamKitForSeasonMutation>;
export type DeactivateTeamKitForSeasonMutationResult = Apollo.MutationResult<DeactivateTeamKitForSeasonMutation>;
export type DeactivateTeamKitForSeasonMutationOptions = Apollo.BaseMutationOptions<DeactivateTeamKitForSeasonMutation, DeactivateTeamKitForSeasonMutationVariables>;
export const ListAvailableCompetitionIntegrationsForSeasonInputDocument = gql`
    query listAvailableCompetitionIntegrationsForSeasonInput($offset: Int!, $limit: Int!, $orgId: String!, $seasonId: String!, $nameSearch: String) {
  listAvailableCompetitionIntegrationsForSeasonInput(
    input: {orgId: $orgId, seasonId: $seasonId, nameSearch: $nameSearch, pagination: {offset: $offset, limit: $limit}}
  ) {
    integrations {
      id
      orgId
      orgName
      orgAbbreviation
      name
      description
      status
      queueName
      additionalOptions {
        id
        name
        isRequired
      }
      createdAt
      updatedAt
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListAvailableCompetitionIntegrationsForSeasonInputQuery__
 *
 * To run a query within a React component, call `useListAvailableCompetitionIntegrationsForSeasonInputQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAvailableCompetitionIntegrationsForSeasonInputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAvailableCompetitionIntegrationsForSeasonInputQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      orgId: // value for 'orgId'
 *      seasonId: // value for 'seasonId'
 *      nameSearch: // value for 'nameSearch'
 *   },
 * });
 */
export function useListAvailableCompetitionIntegrationsForSeasonInputQuery(baseOptions: Apollo.QueryHookOptions<ListAvailableCompetitionIntegrationsForSeasonInputQuery, ListAvailableCompetitionIntegrationsForSeasonInputQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAvailableCompetitionIntegrationsForSeasonInputQuery, ListAvailableCompetitionIntegrationsForSeasonInputQueryVariables>(ListAvailableCompetitionIntegrationsForSeasonInputDocument, options);
      }
export function useListAvailableCompetitionIntegrationsForSeasonInputLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAvailableCompetitionIntegrationsForSeasonInputQuery, ListAvailableCompetitionIntegrationsForSeasonInputQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAvailableCompetitionIntegrationsForSeasonInputQuery, ListAvailableCompetitionIntegrationsForSeasonInputQueryVariables>(ListAvailableCompetitionIntegrationsForSeasonInputDocument, options);
        }
export type ListAvailableCompetitionIntegrationsForSeasonInputQueryHookResult = ReturnType<typeof useListAvailableCompetitionIntegrationsForSeasonInputQuery>;
export type ListAvailableCompetitionIntegrationsForSeasonInputLazyQueryHookResult = ReturnType<typeof useListAvailableCompetitionIntegrationsForSeasonInputLazyQuery>;
export type ListAvailableCompetitionIntegrationsForSeasonInputQueryResult = Apollo.QueryResult<ListAvailableCompetitionIntegrationsForSeasonInputQuery, ListAvailableCompetitionIntegrationsForSeasonInputQueryVariables>;
export const ListSeasonCompetitionIntegrationsForSeasonInputDocument = gql`
    query listSeasonCompetitionIntegrationsForSeasonInput($offset: Int!, $limit: Int!, $orgId: String!, $seasonId: String!) {
  listSeasonCompetitionIntegrationsForSeasonInput(
    input: {orgId: $orgId, seasonId: $seasonId, pagination: {offset: $offset, limit: $limit}}
  ) {
    seasonIntegrations {
      id
      seasonId
      seasonName
      status
      externalIdOrg
      lastRan
      fromDateMinutes
      toDateMinutes
      integration {
        id
        orgId
        orgName
        orgAbbreviation
        name
        description
        status
        queueName
        additionalOptions {
          id
          name
          isRequired
        }
        createdAt
        updatedAt
      }
      seasonOptions {
        id
        name
        value
        isRequired
      }
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListSeasonCompetitionIntegrationsForSeasonInputQuery__
 *
 * To run a query within a React component, call `useListSeasonCompetitionIntegrationsForSeasonInputQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSeasonCompetitionIntegrationsForSeasonInputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSeasonCompetitionIntegrationsForSeasonInputQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      orgId: // value for 'orgId'
 *      seasonId: // value for 'seasonId'
 *   },
 * });
 */
export function useListSeasonCompetitionIntegrationsForSeasonInputQuery(baseOptions: Apollo.QueryHookOptions<ListSeasonCompetitionIntegrationsForSeasonInputQuery, ListSeasonCompetitionIntegrationsForSeasonInputQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListSeasonCompetitionIntegrationsForSeasonInputQuery, ListSeasonCompetitionIntegrationsForSeasonInputQueryVariables>(ListSeasonCompetitionIntegrationsForSeasonInputDocument, options);
      }
export function useListSeasonCompetitionIntegrationsForSeasonInputLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListSeasonCompetitionIntegrationsForSeasonInputQuery, ListSeasonCompetitionIntegrationsForSeasonInputQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListSeasonCompetitionIntegrationsForSeasonInputQuery, ListSeasonCompetitionIntegrationsForSeasonInputQueryVariables>(ListSeasonCompetitionIntegrationsForSeasonInputDocument, options);
        }
export type ListSeasonCompetitionIntegrationsForSeasonInputQueryHookResult = ReturnType<typeof useListSeasonCompetitionIntegrationsForSeasonInputQuery>;
export type ListSeasonCompetitionIntegrationsForSeasonInputLazyQueryHookResult = ReturnType<typeof useListSeasonCompetitionIntegrationsForSeasonInputLazyQuery>;
export type ListSeasonCompetitionIntegrationsForSeasonInputQueryResult = Apollo.QueryResult<ListSeasonCompetitionIntegrationsForSeasonInputQuery, ListSeasonCompetitionIntegrationsForSeasonInputQueryVariables>;
export const UpdateCompetitionIntegrationEnabledForSeasonDocument = gql`
    mutation updateCompetitionIntegrationEnabledForSeason($seasonId: String!, $enabled: Boolean!) {
  updateCompetitionIntegrationEnabledForSeason(
    input: {seasonId: $seasonId, enabled: $enabled}
  )
}
    `;
export type UpdateCompetitionIntegrationEnabledForSeasonMutationFn = Apollo.MutationFunction<UpdateCompetitionIntegrationEnabledForSeasonMutation, UpdateCompetitionIntegrationEnabledForSeasonMutationVariables>;

/**
 * __useUpdateCompetitionIntegrationEnabledForSeasonMutation__
 *
 * To run a mutation, you first call `useUpdateCompetitionIntegrationEnabledForSeasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompetitionIntegrationEnabledForSeasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompetitionIntegrationEnabledForSeasonMutation, { data, loading, error }] = useUpdateCompetitionIntegrationEnabledForSeasonMutation({
 *   variables: {
 *      seasonId: // value for 'seasonId'
 *      enabled: // value for 'enabled'
 *   },
 * });
 */
export function useUpdateCompetitionIntegrationEnabledForSeasonMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompetitionIntegrationEnabledForSeasonMutation, UpdateCompetitionIntegrationEnabledForSeasonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompetitionIntegrationEnabledForSeasonMutation, UpdateCompetitionIntegrationEnabledForSeasonMutationVariables>(UpdateCompetitionIntegrationEnabledForSeasonDocument, options);
      }
export type UpdateCompetitionIntegrationEnabledForSeasonMutationHookResult = ReturnType<typeof useUpdateCompetitionIntegrationEnabledForSeasonMutation>;
export type UpdateCompetitionIntegrationEnabledForSeasonMutationResult = Apollo.MutationResult<UpdateCompetitionIntegrationEnabledForSeasonMutation>;
export type UpdateCompetitionIntegrationEnabledForSeasonMutationOptions = Apollo.BaseMutationOptions<UpdateCompetitionIntegrationEnabledForSeasonMutation, UpdateCompetitionIntegrationEnabledForSeasonMutationVariables>;
export const CreateSeasonCompetitionIntegrationDocument = gql`
    mutation createSeasonCompetitionIntegration($orgId: String!, $seasonId: String!, $integrationId: String!) {
  createSeasonCompetitionIntegration(
    input: {orgId: $orgId, seasonId: $seasonId, integrationId: $integrationId}
  ) {
    id
    seasonId
    seasonName
    status
    externalIdOrg
    lastRan
    fromDateMinutes
    toDateMinutes
    integration {
      id
      orgId
      orgName
      orgAbbreviation
      name
      description
      status
      queueName
      additionalOptions {
        id
        name
        isRequired
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
    seasonOptions {
      id
      name
      value
      isRequired
    }
  }
}
    `;
export type CreateSeasonCompetitionIntegrationMutationFn = Apollo.MutationFunction<CreateSeasonCompetitionIntegrationMutation, CreateSeasonCompetitionIntegrationMutationVariables>;

/**
 * __useCreateSeasonCompetitionIntegrationMutation__
 *
 * To run a mutation, you first call `useCreateSeasonCompetitionIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSeasonCompetitionIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSeasonCompetitionIntegrationMutation, { data, loading, error }] = useCreateSeasonCompetitionIntegrationMutation({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      seasonId: // value for 'seasonId'
 *      integrationId: // value for 'integrationId'
 *   },
 * });
 */
export function useCreateSeasonCompetitionIntegrationMutation(baseOptions?: Apollo.MutationHookOptions<CreateSeasonCompetitionIntegrationMutation, CreateSeasonCompetitionIntegrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSeasonCompetitionIntegrationMutation, CreateSeasonCompetitionIntegrationMutationVariables>(CreateSeasonCompetitionIntegrationDocument, options);
      }
export type CreateSeasonCompetitionIntegrationMutationHookResult = ReturnType<typeof useCreateSeasonCompetitionIntegrationMutation>;
export type CreateSeasonCompetitionIntegrationMutationResult = Apollo.MutationResult<CreateSeasonCompetitionIntegrationMutation>;
export type CreateSeasonCompetitionIntegrationMutationOptions = Apollo.BaseMutationOptions<CreateSeasonCompetitionIntegrationMutation, CreateSeasonCompetitionIntegrationMutationVariables>;
export const GetSeasonCompetitionIntegrationByIdDocument = gql`
    query getSeasonCompetitionIntegrationById($integrationId: String!) {
  getSeasonCompetitionIntegrationById(input: {integrationId: $integrationId}) {
    id
    seasonId
    seasonName
    status
    externalIdOrg
    lastRan
    fromDateMinutes
    toDateMinutes
    integration {
      id
      orgId
      orgName
      orgAbbreviation
      name
      description
      status
      queueName
      additionalOptions {
        id
        name
        isRequired
      }
      createdAt
      updatedAt
    }
    seasonOptions {
      id
      name
      value
      isRequired
    }
  }
}
    `;

/**
 * __useGetSeasonCompetitionIntegrationByIdQuery__
 *
 * To run a query within a React component, call `useGetSeasonCompetitionIntegrationByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSeasonCompetitionIntegrationByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSeasonCompetitionIntegrationByIdQuery({
 *   variables: {
 *      integrationId: // value for 'integrationId'
 *   },
 * });
 */
export function useGetSeasonCompetitionIntegrationByIdQuery(baseOptions: Apollo.QueryHookOptions<GetSeasonCompetitionIntegrationByIdQuery, GetSeasonCompetitionIntegrationByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSeasonCompetitionIntegrationByIdQuery, GetSeasonCompetitionIntegrationByIdQueryVariables>(GetSeasonCompetitionIntegrationByIdDocument, options);
      }
export function useGetSeasonCompetitionIntegrationByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSeasonCompetitionIntegrationByIdQuery, GetSeasonCompetitionIntegrationByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSeasonCompetitionIntegrationByIdQuery, GetSeasonCompetitionIntegrationByIdQueryVariables>(GetSeasonCompetitionIntegrationByIdDocument, options);
        }
export type GetSeasonCompetitionIntegrationByIdQueryHookResult = ReturnType<typeof useGetSeasonCompetitionIntegrationByIdQuery>;
export type GetSeasonCompetitionIntegrationByIdLazyQueryHookResult = ReturnType<typeof useGetSeasonCompetitionIntegrationByIdLazyQuery>;
export type GetSeasonCompetitionIntegrationByIdQueryResult = Apollo.QueryResult<GetSeasonCompetitionIntegrationByIdQuery, GetSeasonCompetitionIntegrationByIdQueryVariables>;
export const ListOptionsForSeasonCompetitionIntegrationDocument = gql`
    query listOptionsForSeasonCompetitionIntegration($seasonIntegrationId: String!) {
  listOptionsForSeasonCompetitionIntegration(
    input: {seasonIntegrationId: $seasonIntegrationId}
  ) {
    id
    name
    value
    isRequired
  }
}
    `;

/**
 * __useListOptionsForSeasonCompetitionIntegrationQuery__
 *
 * To run a query within a React component, call `useListOptionsForSeasonCompetitionIntegrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useListOptionsForSeasonCompetitionIntegrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListOptionsForSeasonCompetitionIntegrationQuery({
 *   variables: {
 *      seasonIntegrationId: // value for 'seasonIntegrationId'
 *   },
 * });
 */
export function useListOptionsForSeasonCompetitionIntegrationQuery(baseOptions: Apollo.QueryHookOptions<ListOptionsForSeasonCompetitionIntegrationQuery, ListOptionsForSeasonCompetitionIntegrationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListOptionsForSeasonCompetitionIntegrationQuery, ListOptionsForSeasonCompetitionIntegrationQueryVariables>(ListOptionsForSeasonCompetitionIntegrationDocument, options);
      }
export function useListOptionsForSeasonCompetitionIntegrationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListOptionsForSeasonCompetitionIntegrationQuery, ListOptionsForSeasonCompetitionIntegrationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListOptionsForSeasonCompetitionIntegrationQuery, ListOptionsForSeasonCompetitionIntegrationQueryVariables>(ListOptionsForSeasonCompetitionIntegrationDocument, options);
        }
export type ListOptionsForSeasonCompetitionIntegrationQueryHookResult = ReturnType<typeof useListOptionsForSeasonCompetitionIntegrationQuery>;
export type ListOptionsForSeasonCompetitionIntegrationLazyQueryHookResult = ReturnType<typeof useListOptionsForSeasonCompetitionIntegrationLazyQuery>;
export type ListOptionsForSeasonCompetitionIntegrationQueryResult = Apollo.QueryResult<ListOptionsForSeasonCompetitionIntegrationQuery, ListOptionsForSeasonCompetitionIntegrationQueryVariables>;
export const DeleteSeasonCompetitionIntegrationDocument = gql`
    mutation deleteSeasonCompetitionIntegration($seasonIntegrationId: String!) {
  deleteSeasonCompetitionIntegration(
    input: {seasonIntegrationId: $seasonIntegrationId}
  )
}
    `;
export type DeleteSeasonCompetitionIntegrationMutationFn = Apollo.MutationFunction<DeleteSeasonCompetitionIntegrationMutation, DeleteSeasonCompetitionIntegrationMutationVariables>;

/**
 * __useDeleteSeasonCompetitionIntegrationMutation__
 *
 * To run a mutation, you first call `useDeleteSeasonCompetitionIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSeasonCompetitionIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSeasonCompetitionIntegrationMutation, { data, loading, error }] = useDeleteSeasonCompetitionIntegrationMutation({
 *   variables: {
 *      seasonIntegrationId: // value for 'seasonIntegrationId'
 *   },
 * });
 */
export function useDeleteSeasonCompetitionIntegrationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSeasonCompetitionIntegrationMutation, DeleteSeasonCompetitionIntegrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSeasonCompetitionIntegrationMutation, DeleteSeasonCompetitionIntegrationMutationVariables>(DeleteSeasonCompetitionIntegrationDocument, options);
      }
export type DeleteSeasonCompetitionIntegrationMutationHookResult = ReturnType<typeof useDeleteSeasonCompetitionIntegrationMutation>;
export type DeleteSeasonCompetitionIntegrationMutationResult = Apollo.MutationResult<DeleteSeasonCompetitionIntegrationMutation>;
export type DeleteSeasonCompetitionIntegrationMutationOptions = Apollo.BaseMutationOptions<DeleteSeasonCompetitionIntegrationMutation, DeleteSeasonCompetitionIntegrationMutationVariables>;
export const UpdateSeasonCompetitionIntegrationDocument = gql`
    mutation updateSeasonCompetitionIntegration($seasonIntegrationId: String!, $fromDateMinutes: Int, $toDateMinutes: Int, $externalIdOrg: String) {
  updateSeasonCompetitionIntegration(
    input: {seasonIntegrationId: $seasonIntegrationId, fromDateMinutes: $fromDateMinutes, toDateMinutes: $toDateMinutes, externalIdOrg: $externalIdOrg}
  ) {
    id
    id
    seasonId
    seasonName
    status
    externalIdOrg
    lastRan
    fromDateMinutes
    toDateMinutes
    integration {
      id
      orgId
      orgName
      orgAbbreviation
      name
      description
      status
      queueName
      additionalOptions {
        id
        name
        isRequired
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
    seasonOptions {
      id
      name
      value
      isRequired
    }
  }
}
    `;
export type UpdateSeasonCompetitionIntegrationMutationFn = Apollo.MutationFunction<UpdateSeasonCompetitionIntegrationMutation, UpdateSeasonCompetitionIntegrationMutationVariables>;

/**
 * __useUpdateSeasonCompetitionIntegrationMutation__
 *
 * To run a mutation, you first call `useUpdateSeasonCompetitionIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSeasonCompetitionIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSeasonCompetitionIntegrationMutation, { data, loading, error }] = useUpdateSeasonCompetitionIntegrationMutation({
 *   variables: {
 *      seasonIntegrationId: // value for 'seasonIntegrationId'
 *      fromDateMinutes: // value for 'fromDateMinutes'
 *      toDateMinutes: // value for 'toDateMinutes'
 *      externalIdOrg: // value for 'externalIdOrg'
 *   },
 * });
 */
export function useUpdateSeasonCompetitionIntegrationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSeasonCompetitionIntegrationMutation, UpdateSeasonCompetitionIntegrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSeasonCompetitionIntegrationMutation, UpdateSeasonCompetitionIntegrationMutationVariables>(UpdateSeasonCompetitionIntegrationDocument, options);
      }
export type UpdateSeasonCompetitionIntegrationMutationHookResult = ReturnType<typeof useUpdateSeasonCompetitionIntegrationMutation>;
export type UpdateSeasonCompetitionIntegrationMutationResult = Apollo.MutationResult<UpdateSeasonCompetitionIntegrationMutation>;
export type UpdateSeasonCompetitionIntegrationMutationOptions = Apollo.BaseMutationOptions<UpdateSeasonCompetitionIntegrationMutation, UpdateSeasonCompetitionIntegrationMutationVariables>;
export const UpdateSeasonCompetitionIntegrationOptionDocument = gql`
    mutation updateSeasonCompetitionIntegrationOption($optionId: String!, $value: String) {
  updateSeasonCompetitionIntegrationOption(
    input: {optionId: $optionId, value: $value}
  ) {
    id
    name
    value
    isRequired
  }
}
    `;
export type UpdateSeasonCompetitionIntegrationOptionMutationFn = Apollo.MutationFunction<UpdateSeasonCompetitionIntegrationOptionMutation, UpdateSeasonCompetitionIntegrationOptionMutationVariables>;

/**
 * __useUpdateSeasonCompetitionIntegrationOptionMutation__
 *
 * To run a mutation, you first call `useUpdateSeasonCompetitionIntegrationOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSeasonCompetitionIntegrationOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSeasonCompetitionIntegrationOptionMutation, { data, loading, error }] = useUpdateSeasonCompetitionIntegrationOptionMutation({
 *   variables: {
 *      optionId: // value for 'optionId'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useUpdateSeasonCompetitionIntegrationOptionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSeasonCompetitionIntegrationOptionMutation, UpdateSeasonCompetitionIntegrationOptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSeasonCompetitionIntegrationOptionMutation, UpdateSeasonCompetitionIntegrationOptionMutationVariables>(UpdateSeasonCompetitionIntegrationOptionDocument, options);
      }
export type UpdateSeasonCompetitionIntegrationOptionMutationHookResult = ReturnType<typeof useUpdateSeasonCompetitionIntegrationOptionMutation>;
export type UpdateSeasonCompetitionIntegrationOptionMutationResult = Apollo.MutationResult<UpdateSeasonCompetitionIntegrationOptionMutation>;
export type UpdateSeasonCompetitionIntegrationOptionMutationOptions = Apollo.BaseMutationOptions<UpdateSeasonCompetitionIntegrationOptionMutation, UpdateSeasonCompetitionIntegrationOptionMutationVariables>;
export const ActivateSeasonCompetitionIntegrationDocument = gql`
    mutation activateSeasonCompetitionIntegration($integrationId: String!) {
  activateSeasonCompetitionIntegration(input: {integrationId: $integrationId}) {
    id
    seasonId
    seasonName
    status
    externalIdOrg
    lastRan
    fromDateMinutes
    toDateMinutes
    integration {
      id
      orgId
      orgName
      orgAbbreviation
      name
      description
      status
      queueName
      additionalOptions {
        id
        name
        isRequired
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
    seasonOptions {
      id
      name
      value
      isRequired
    }
  }
}
    `;
export type ActivateSeasonCompetitionIntegrationMutationFn = Apollo.MutationFunction<ActivateSeasonCompetitionIntegrationMutation, ActivateSeasonCompetitionIntegrationMutationVariables>;

/**
 * __useActivateSeasonCompetitionIntegrationMutation__
 *
 * To run a mutation, you first call `useActivateSeasonCompetitionIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateSeasonCompetitionIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateSeasonCompetitionIntegrationMutation, { data, loading, error }] = useActivateSeasonCompetitionIntegrationMutation({
 *   variables: {
 *      integrationId: // value for 'integrationId'
 *   },
 * });
 */
export function useActivateSeasonCompetitionIntegrationMutation(baseOptions?: Apollo.MutationHookOptions<ActivateSeasonCompetitionIntegrationMutation, ActivateSeasonCompetitionIntegrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateSeasonCompetitionIntegrationMutation, ActivateSeasonCompetitionIntegrationMutationVariables>(ActivateSeasonCompetitionIntegrationDocument, options);
      }
export type ActivateSeasonCompetitionIntegrationMutationHookResult = ReturnType<typeof useActivateSeasonCompetitionIntegrationMutation>;
export type ActivateSeasonCompetitionIntegrationMutationResult = Apollo.MutationResult<ActivateSeasonCompetitionIntegrationMutation>;
export type ActivateSeasonCompetitionIntegrationMutationOptions = Apollo.BaseMutationOptions<ActivateSeasonCompetitionIntegrationMutation, ActivateSeasonCompetitionIntegrationMutationVariables>;
export const DisableSeasonCompetitionIntegrationDocument = gql`
    mutation disableSeasonCompetitionIntegration($integrationId: String!) {
  disableSeasonCompetitionIntegration(input: {integrationId: $integrationId}) {
    id
    seasonId
    seasonName
    status
    externalIdOrg
    lastRan
    fromDateMinutes
    toDateMinutes
    integration {
      id
      orgId
      orgName
      orgAbbreviation
      name
      description
      status
      queueName
      additionalOptions {
        id
        name
        isRequired
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
    seasonOptions {
      id
      name
      value
      isRequired
    }
  }
}
    `;
export type DisableSeasonCompetitionIntegrationMutationFn = Apollo.MutationFunction<DisableSeasonCompetitionIntegrationMutation, DisableSeasonCompetitionIntegrationMutationVariables>;

/**
 * __useDisableSeasonCompetitionIntegrationMutation__
 *
 * To run a mutation, you first call `useDisableSeasonCompetitionIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableSeasonCompetitionIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableSeasonCompetitionIntegrationMutation, { data, loading, error }] = useDisableSeasonCompetitionIntegrationMutation({
 *   variables: {
 *      integrationId: // value for 'integrationId'
 *   },
 * });
 */
export function useDisableSeasonCompetitionIntegrationMutation(baseOptions?: Apollo.MutationHookOptions<DisableSeasonCompetitionIntegrationMutation, DisableSeasonCompetitionIntegrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DisableSeasonCompetitionIntegrationMutation, DisableSeasonCompetitionIntegrationMutationVariables>(DisableSeasonCompetitionIntegrationDocument, options);
      }
export type DisableSeasonCompetitionIntegrationMutationHookResult = ReturnType<typeof useDisableSeasonCompetitionIntegrationMutation>;
export type DisableSeasonCompetitionIntegrationMutationResult = Apollo.MutationResult<DisableSeasonCompetitionIntegrationMutation>;
export type DisableSeasonCompetitionIntegrationMutationOptions = Apollo.BaseMutationOptions<DisableSeasonCompetitionIntegrationMutation, DisableSeasonCompetitionIntegrationMutationVariables>;
export const RunSeasonCompetitionIntegrationDocument = gql`
    mutation runSeasonCompetitionIntegration($seasonIntegrationId: String!) {
  runSeasonCompetitionIntegration(
    input: {seasonIntegrationId: $seasonIntegrationId}
  )
}
    `;
export type RunSeasonCompetitionIntegrationMutationFn = Apollo.MutationFunction<RunSeasonCompetitionIntegrationMutation, RunSeasonCompetitionIntegrationMutationVariables>;

/**
 * __useRunSeasonCompetitionIntegrationMutation__
 *
 * To run a mutation, you first call `useRunSeasonCompetitionIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRunSeasonCompetitionIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [runSeasonCompetitionIntegrationMutation, { data, loading, error }] = useRunSeasonCompetitionIntegrationMutation({
 *   variables: {
 *      seasonIntegrationId: // value for 'seasonIntegrationId'
 *   },
 * });
 */
export function useRunSeasonCompetitionIntegrationMutation(baseOptions?: Apollo.MutationHookOptions<RunSeasonCompetitionIntegrationMutation, RunSeasonCompetitionIntegrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RunSeasonCompetitionIntegrationMutation, RunSeasonCompetitionIntegrationMutationVariables>(RunSeasonCompetitionIntegrationDocument, options);
      }
export type RunSeasonCompetitionIntegrationMutationHookResult = ReturnType<typeof useRunSeasonCompetitionIntegrationMutation>;
export type RunSeasonCompetitionIntegrationMutationResult = Apollo.MutationResult<RunSeasonCompetitionIntegrationMutation>;
export type RunSeasonCompetitionIntegrationMutationOptions = Apollo.BaseMutationOptions<RunSeasonCompetitionIntegrationMutation, RunSeasonCompetitionIntegrationMutationVariables>;
export const UpdateMediaMembersEnabledForSeasonDocument = gql`
    mutation updateMediaMembersEnabledForSeason($seasonId: String!, $enabled: Boolean!) {
  updateMediaMembersEnabledForSeason(
    input: {seasonId: $seasonId, enabled: $enabled}
  )
}
    `;
export type UpdateMediaMembersEnabledForSeasonMutationFn = Apollo.MutationFunction<UpdateMediaMembersEnabledForSeasonMutation, UpdateMediaMembersEnabledForSeasonMutationVariables>;

/**
 * __useUpdateMediaMembersEnabledForSeasonMutation__
 *
 * To run a mutation, you first call `useUpdateMediaMembersEnabledForSeasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMediaMembersEnabledForSeasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMediaMembersEnabledForSeasonMutation, { data, loading, error }] = useUpdateMediaMembersEnabledForSeasonMutation({
 *   variables: {
 *      seasonId: // value for 'seasonId'
 *      enabled: // value for 'enabled'
 *   },
 * });
 */
export function useUpdateMediaMembersEnabledForSeasonMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMediaMembersEnabledForSeasonMutation, UpdateMediaMembersEnabledForSeasonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMediaMembersEnabledForSeasonMutation, UpdateMediaMembersEnabledForSeasonMutationVariables>(UpdateMediaMembersEnabledForSeasonDocument, options);
      }
export type UpdateMediaMembersEnabledForSeasonMutationHookResult = ReturnType<typeof useUpdateMediaMembersEnabledForSeasonMutation>;
export type UpdateMediaMembersEnabledForSeasonMutationResult = Apollo.MutationResult<UpdateMediaMembersEnabledForSeasonMutation>;
export type UpdateMediaMembersEnabledForSeasonMutationOptions = Apollo.BaseMutationOptions<UpdateMediaMembersEnabledForSeasonMutation, UpdateMediaMembersEnabledForSeasonMutationVariables>;
export const GetSeasonMediaMembersConfigByIdDocument = gql`
    query getSeasonMediaMembersConfigById($seasonMediaMembersConfigId: String!) {
  getSeasonMediaMembersConfigById(
    input: {seasonMediaMembersConfigId: $seasonMediaMembersConfigId}
  ) {
    orgId
    seasonId
    id
    status
    allowIncidentReports
    allowIncidentReportsCutoffDays
    allowIncidentReportsDelayMinutes
    activatedAt
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetSeasonMediaMembersConfigByIdQuery__
 *
 * To run a query within a React component, call `useGetSeasonMediaMembersConfigByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSeasonMediaMembersConfigByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSeasonMediaMembersConfigByIdQuery({
 *   variables: {
 *      seasonMediaMembersConfigId: // value for 'seasonMediaMembersConfigId'
 *   },
 * });
 */
export function useGetSeasonMediaMembersConfigByIdQuery(baseOptions: Apollo.QueryHookOptions<GetSeasonMediaMembersConfigByIdQuery, GetSeasonMediaMembersConfigByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSeasonMediaMembersConfigByIdQuery, GetSeasonMediaMembersConfigByIdQueryVariables>(GetSeasonMediaMembersConfigByIdDocument, options);
      }
export function useGetSeasonMediaMembersConfigByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSeasonMediaMembersConfigByIdQuery, GetSeasonMediaMembersConfigByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSeasonMediaMembersConfigByIdQuery, GetSeasonMediaMembersConfigByIdQueryVariables>(GetSeasonMediaMembersConfigByIdDocument, options);
        }
export type GetSeasonMediaMembersConfigByIdQueryHookResult = ReturnType<typeof useGetSeasonMediaMembersConfigByIdQuery>;
export type GetSeasonMediaMembersConfigByIdLazyQueryHookResult = ReturnType<typeof useGetSeasonMediaMembersConfigByIdLazyQuery>;
export type GetSeasonMediaMembersConfigByIdQueryResult = Apollo.QueryResult<GetSeasonMediaMembersConfigByIdQuery, GetSeasonMediaMembersConfigByIdQueryVariables>;
export const FindSeasonMediaMembersConfigForSeasonDocument = gql`
    query findSeasonMediaMembersConfigForSeason($orgId: String!, $seasonId: String!) {
  findSeasonMediaMembersConfigForSeason(
    input: {orgId: $orgId, seasonId: $seasonId}
  ) {
    orgId
    seasonId
    id
    status
    allowIncidentReports
    allowIncidentReportsCutoffDays
    allowIncidentReportsDelayMinutes
    activatedAt
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useFindSeasonMediaMembersConfigForSeasonQuery__
 *
 * To run a query within a React component, call `useFindSeasonMediaMembersConfigForSeasonQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindSeasonMediaMembersConfigForSeasonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindSeasonMediaMembersConfigForSeasonQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      seasonId: // value for 'seasonId'
 *   },
 * });
 */
export function useFindSeasonMediaMembersConfigForSeasonQuery(baseOptions: Apollo.QueryHookOptions<FindSeasonMediaMembersConfigForSeasonQuery, FindSeasonMediaMembersConfigForSeasonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindSeasonMediaMembersConfigForSeasonQuery, FindSeasonMediaMembersConfigForSeasonQueryVariables>(FindSeasonMediaMembersConfigForSeasonDocument, options);
      }
export function useFindSeasonMediaMembersConfigForSeasonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindSeasonMediaMembersConfigForSeasonQuery, FindSeasonMediaMembersConfigForSeasonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindSeasonMediaMembersConfigForSeasonQuery, FindSeasonMediaMembersConfigForSeasonQueryVariables>(FindSeasonMediaMembersConfigForSeasonDocument, options);
        }
export type FindSeasonMediaMembersConfigForSeasonQueryHookResult = ReturnType<typeof useFindSeasonMediaMembersConfigForSeasonQuery>;
export type FindSeasonMediaMembersConfigForSeasonLazyQueryHookResult = ReturnType<typeof useFindSeasonMediaMembersConfigForSeasonLazyQuery>;
export type FindSeasonMediaMembersConfigForSeasonQueryResult = Apollo.QueryResult<FindSeasonMediaMembersConfigForSeasonQuery, FindSeasonMediaMembersConfigForSeasonQueryVariables>;
export const CreateSeasonMediaMembersConfigDocument = gql`
    mutation createSeasonMediaMembersConfig($orgId: String!, $seasonId: String!, $allowIncidentReports: Boolean!, $allowIncidentReportsDelayMinutes: Int, $allowIncidentReportsCutoffDays: Int) {
  createSeasonMediaMembersConfig(
    input: {orgId: $orgId, seasonId: $seasonId, allowIncidentReports: $allowIncidentReports, allowIncidentReportsDelayMinutes: $allowIncidentReportsDelayMinutes, allowIncidentReportsCutoffDays: $allowIncidentReportsCutoffDays}
  ) {
    orgId
    seasonId
    id
    status
    allowIncidentReports
    allowIncidentReportsCutoffDays
    allowIncidentReportsDelayMinutes
    activatedAt
    createdAt
    updatedAt
  }
}
    `;
export type CreateSeasonMediaMembersConfigMutationFn = Apollo.MutationFunction<CreateSeasonMediaMembersConfigMutation, CreateSeasonMediaMembersConfigMutationVariables>;

/**
 * __useCreateSeasonMediaMembersConfigMutation__
 *
 * To run a mutation, you first call `useCreateSeasonMediaMembersConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSeasonMediaMembersConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSeasonMediaMembersConfigMutation, { data, loading, error }] = useCreateSeasonMediaMembersConfigMutation({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      seasonId: // value for 'seasonId'
 *      allowIncidentReports: // value for 'allowIncidentReports'
 *      allowIncidentReportsDelayMinutes: // value for 'allowIncidentReportsDelayMinutes'
 *      allowIncidentReportsCutoffDays: // value for 'allowIncidentReportsCutoffDays'
 *   },
 * });
 */
export function useCreateSeasonMediaMembersConfigMutation(baseOptions?: Apollo.MutationHookOptions<CreateSeasonMediaMembersConfigMutation, CreateSeasonMediaMembersConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSeasonMediaMembersConfigMutation, CreateSeasonMediaMembersConfigMutationVariables>(CreateSeasonMediaMembersConfigDocument, options);
      }
export type CreateSeasonMediaMembersConfigMutationHookResult = ReturnType<typeof useCreateSeasonMediaMembersConfigMutation>;
export type CreateSeasonMediaMembersConfigMutationResult = Apollo.MutationResult<CreateSeasonMediaMembersConfigMutation>;
export type CreateSeasonMediaMembersConfigMutationOptions = Apollo.BaseMutationOptions<CreateSeasonMediaMembersConfigMutation, CreateSeasonMediaMembersConfigMutationVariables>;
export const UpdateSeasonMediaMembersConfigDocument = gql`
    mutation updateSeasonMediaMembersConfig($orgId: String!, $seasonId: String!, $seasonMediaMembersConfigId: String!, $allowIncidentReports: Boolean!, $allowIncidentReportsCutoffDays: Int, $allowIncidentReportsDelayMinutes: Int) {
  updateSeasonMediaMembersConfig(
    input: {orgId: $orgId, seasonId: $seasonId, seasonMediaMembersConfigId: $seasonMediaMembersConfigId, allowIncidentReports: $allowIncidentReports, allowIncidentReportsCutoffDays: $allowIncidentReportsCutoffDays, allowIncidentReportsDelayMinutes: $allowIncidentReportsDelayMinutes}
  ) {
    orgId
    seasonId
    id
    status
    allowIncidentReports
    allowIncidentReportsCutoffDays
    allowIncidentReportsDelayMinutes
    activatedAt
    createdAt
    updatedAt
  }
}
    `;
export type UpdateSeasonMediaMembersConfigMutationFn = Apollo.MutationFunction<UpdateSeasonMediaMembersConfigMutation, UpdateSeasonMediaMembersConfigMutationVariables>;

/**
 * __useUpdateSeasonMediaMembersConfigMutation__
 *
 * To run a mutation, you first call `useUpdateSeasonMediaMembersConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSeasonMediaMembersConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSeasonMediaMembersConfigMutation, { data, loading, error }] = useUpdateSeasonMediaMembersConfigMutation({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      seasonId: // value for 'seasonId'
 *      seasonMediaMembersConfigId: // value for 'seasonMediaMembersConfigId'
 *      allowIncidentReports: // value for 'allowIncidentReports'
 *      allowIncidentReportsCutoffDays: // value for 'allowIncidentReportsCutoffDays'
 *      allowIncidentReportsDelayMinutes: // value for 'allowIncidentReportsDelayMinutes'
 *   },
 * });
 */
export function useUpdateSeasonMediaMembersConfigMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSeasonMediaMembersConfigMutation, UpdateSeasonMediaMembersConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSeasonMediaMembersConfigMutation, UpdateSeasonMediaMembersConfigMutationVariables>(UpdateSeasonMediaMembersConfigDocument, options);
      }
export type UpdateSeasonMediaMembersConfigMutationHookResult = ReturnType<typeof useUpdateSeasonMediaMembersConfigMutation>;
export type UpdateSeasonMediaMembersConfigMutationResult = Apollo.MutationResult<UpdateSeasonMediaMembersConfigMutation>;
export type UpdateSeasonMediaMembersConfigMutationOptions = Apollo.BaseMutationOptions<UpdateSeasonMediaMembersConfigMutation, UpdateSeasonMediaMembersConfigMutationVariables>;
export const GetMediaMembersOrgSetupSummaryForSeasonDocument = gql`
    query getMediaMembersOrgSetupSummaryForSeason($seasonId: String!) {
  getMediaMembersOrgSetupSummaryForSeason(input: {seasonId: $seasonId}) {
    orgId
    orgName
    orgLogo
    leagueId
    leagueName
    seasonId
    seasonName
    kiEnabled
    kiRecipientsCount
    mediaMembersCount
  }
}
    `;

/**
 * __useGetMediaMembersOrgSetupSummaryForSeasonQuery__
 *
 * To run a query within a React component, call `useGetMediaMembersOrgSetupSummaryForSeasonQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMediaMembersOrgSetupSummaryForSeasonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMediaMembersOrgSetupSummaryForSeasonQuery({
 *   variables: {
 *      seasonId: // value for 'seasonId'
 *   },
 * });
 */
export function useGetMediaMembersOrgSetupSummaryForSeasonQuery(baseOptions: Apollo.QueryHookOptions<GetMediaMembersOrgSetupSummaryForSeasonQuery, GetMediaMembersOrgSetupSummaryForSeasonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMediaMembersOrgSetupSummaryForSeasonQuery, GetMediaMembersOrgSetupSummaryForSeasonQueryVariables>(GetMediaMembersOrgSetupSummaryForSeasonDocument, options);
      }
export function useGetMediaMembersOrgSetupSummaryForSeasonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMediaMembersOrgSetupSummaryForSeasonQuery, GetMediaMembersOrgSetupSummaryForSeasonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMediaMembersOrgSetupSummaryForSeasonQuery, GetMediaMembersOrgSetupSummaryForSeasonQueryVariables>(GetMediaMembersOrgSetupSummaryForSeasonDocument, options);
        }
export type GetMediaMembersOrgSetupSummaryForSeasonQueryHookResult = ReturnType<typeof useGetMediaMembersOrgSetupSummaryForSeasonQuery>;
export type GetMediaMembersOrgSetupSummaryForSeasonLazyQueryHookResult = ReturnType<typeof useGetMediaMembersOrgSetupSummaryForSeasonLazyQuery>;
export type GetMediaMembersOrgSetupSummaryForSeasonQueryResult = Apollo.QueryResult<GetMediaMembersOrgSetupSummaryForSeasonQuery, GetMediaMembersOrgSetupSummaryForSeasonQueryVariables>;
export const DeactivateMediaMembersConfigForSeasonDocument = gql`
    mutation deactivateMediaMembersConfigForSeason($orgId: String!, $seasonId: String!, $seasonMediaMembersConfigId: String!) {
  deactivateMediaMembersConfigForSeason(
    input: {orgId: $orgId, seasonId: $seasonId, seasonMediaMembersConfigId: $seasonMediaMembersConfigId}
  ) {
    orgId
    seasonId
    id
    status
    allowIncidentReports
    allowIncidentReportsCutoffDays
    allowIncidentReportsDelayMinutes
    activatedAt
    createdAt
    updatedAt
  }
}
    `;
export type DeactivateMediaMembersConfigForSeasonMutationFn = Apollo.MutationFunction<DeactivateMediaMembersConfigForSeasonMutation, DeactivateMediaMembersConfigForSeasonMutationVariables>;

/**
 * __useDeactivateMediaMembersConfigForSeasonMutation__
 *
 * To run a mutation, you first call `useDeactivateMediaMembersConfigForSeasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateMediaMembersConfigForSeasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateMediaMembersConfigForSeasonMutation, { data, loading, error }] = useDeactivateMediaMembersConfigForSeasonMutation({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      seasonId: // value for 'seasonId'
 *      seasonMediaMembersConfigId: // value for 'seasonMediaMembersConfigId'
 *   },
 * });
 */
export function useDeactivateMediaMembersConfigForSeasonMutation(baseOptions?: Apollo.MutationHookOptions<DeactivateMediaMembersConfigForSeasonMutation, DeactivateMediaMembersConfigForSeasonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeactivateMediaMembersConfigForSeasonMutation, DeactivateMediaMembersConfigForSeasonMutationVariables>(DeactivateMediaMembersConfigForSeasonDocument, options);
      }
export type DeactivateMediaMembersConfigForSeasonMutationHookResult = ReturnType<typeof useDeactivateMediaMembersConfigForSeasonMutation>;
export type DeactivateMediaMembersConfigForSeasonMutationResult = Apollo.MutationResult<DeactivateMediaMembersConfigForSeasonMutation>;
export type DeactivateMediaMembersConfigForSeasonMutationOptions = Apollo.BaseMutationOptions<DeactivateMediaMembersConfigForSeasonMutation, DeactivateMediaMembersConfigForSeasonMutationVariables>;
export const ListSeasonMediaMembersOrgRecipientsDocument = gql`
    query listSeasonMediaMembersOrgRecipients($offset: Int!, $limit: Int!, $orgId: String!, $seasonId: String!) {
  listSeasonMediaMembersOrgRecipients(
    input: {seasonId: $seasonId, orgId: $orgId, pagination: {offset: $offset, limit: $limit}}
  ) {
    seasonId
    recipients {
      id
      SeasonId
      orgMembershipId
      orgId
      orgName
      orgAbbreviation
      orgLogo
      isActive
      isKIRecipient
      title
      userId
      email
      firstName
      lastName
      profilePic
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListSeasonMediaMembersOrgRecipientsQuery__
 *
 * To run a query within a React component, call `useListSeasonMediaMembersOrgRecipientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSeasonMediaMembersOrgRecipientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSeasonMediaMembersOrgRecipientsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      orgId: // value for 'orgId'
 *      seasonId: // value for 'seasonId'
 *   },
 * });
 */
export function useListSeasonMediaMembersOrgRecipientsQuery(baseOptions: Apollo.QueryHookOptions<ListSeasonMediaMembersOrgRecipientsQuery, ListSeasonMediaMembersOrgRecipientsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListSeasonMediaMembersOrgRecipientsQuery, ListSeasonMediaMembersOrgRecipientsQueryVariables>(ListSeasonMediaMembersOrgRecipientsDocument, options);
      }
export function useListSeasonMediaMembersOrgRecipientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListSeasonMediaMembersOrgRecipientsQuery, ListSeasonMediaMembersOrgRecipientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListSeasonMediaMembersOrgRecipientsQuery, ListSeasonMediaMembersOrgRecipientsQueryVariables>(ListSeasonMediaMembersOrgRecipientsDocument, options);
        }
export type ListSeasonMediaMembersOrgRecipientsQueryHookResult = ReturnType<typeof useListSeasonMediaMembersOrgRecipientsQuery>;
export type ListSeasonMediaMembersOrgRecipientsLazyQueryHookResult = ReturnType<typeof useListSeasonMediaMembersOrgRecipientsLazyQuery>;
export type ListSeasonMediaMembersOrgRecipientsQueryResult = Apollo.QueryResult<ListSeasonMediaMembersOrgRecipientsQuery, ListSeasonMediaMembersOrgRecipientsQueryVariables>;
export const AddSeasonMediaMembersOrgRecipientDocument = gql`
    mutation addSeasonMediaMembersOrgRecipient($orgId: String!, $seasonId: String!, $orgMembershipId: String!, $isActive: Boolean!, $isKIRecipient: Boolean!) {
  addSeasonMediaMembersOrgRecipient(
    input: {orgId: $orgId, seasonId: $seasonId, orgMembershipId: $orgMembershipId, isActive: $isActive, isKIRecipient: $isKIRecipient}
  ) {
    id
    SeasonId
    orgMembershipId
    orgId
    orgName
    orgAbbreviation
    orgLogo
    isActive
    isKIRecipient
    title
    userId
    email
    firstName
    lastName
    profilePic
  }
}
    `;
export type AddSeasonMediaMembersOrgRecipientMutationFn = Apollo.MutationFunction<AddSeasonMediaMembersOrgRecipientMutation, AddSeasonMediaMembersOrgRecipientMutationVariables>;

/**
 * __useAddSeasonMediaMembersOrgRecipientMutation__
 *
 * To run a mutation, you first call `useAddSeasonMediaMembersOrgRecipientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSeasonMediaMembersOrgRecipientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSeasonMediaMembersOrgRecipientMutation, { data, loading, error }] = useAddSeasonMediaMembersOrgRecipientMutation({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      seasonId: // value for 'seasonId'
 *      orgMembershipId: // value for 'orgMembershipId'
 *      isActive: // value for 'isActive'
 *      isKIRecipient: // value for 'isKIRecipient'
 *   },
 * });
 */
export function useAddSeasonMediaMembersOrgRecipientMutation(baseOptions?: Apollo.MutationHookOptions<AddSeasonMediaMembersOrgRecipientMutation, AddSeasonMediaMembersOrgRecipientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddSeasonMediaMembersOrgRecipientMutation, AddSeasonMediaMembersOrgRecipientMutationVariables>(AddSeasonMediaMembersOrgRecipientDocument, options);
      }
export type AddSeasonMediaMembersOrgRecipientMutationHookResult = ReturnType<typeof useAddSeasonMediaMembersOrgRecipientMutation>;
export type AddSeasonMediaMembersOrgRecipientMutationResult = Apollo.MutationResult<AddSeasonMediaMembersOrgRecipientMutation>;
export type AddSeasonMediaMembersOrgRecipientMutationOptions = Apollo.BaseMutationOptions<AddSeasonMediaMembersOrgRecipientMutation, AddSeasonMediaMembersOrgRecipientMutationVariables>;
export const DeleteSeasonMediaMembersOrgRecipientDocument = gql`
    mutation deleteSeasonMediaMembersOrgRecipient($orgId: String!, $seasonId: String!, $orgRecipientId: String!) {
  deleteSeasonMediaMembersOrgRecipient(
    input: {orgId: $orgId, seasonId: $seasonId, orgRecipientId: $orgRecipientId}
  )
}
    `;
export type DeleteSeasonMediaMembersOrgRecipientMutationFn = Apollo.MutationFunction<DeleteSeasonMediaMembersOrgRecipientMutation, DeleteSeasonMediaMembersOrgRecipientMutationVariables>;

/**
 * __useDeleteSeasonMediaMembersOrgRecipientMutation__
 *
 * To run a mutation, you first call `useDeleteSeasonMediaMembersOrgRecipientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSeasonMediaMembersOrgRecipientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSeasonMediaMembersOrgRecipientMutation, { data, loading, error }] = useDeleteSeasonMediaMembersOrgRecipientMutation({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      seasonId: // value for 'seasonId'
 *      orgRecipientId: // value for 'orgRecipientId'
 *   },
 * });
 */
export function useDeleteSeasonMediaMembersOrgRecipientMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSeasonMediaMembersOrgRecipientMutation, DeleteSeasonMediaMembersOrgRecipientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSeasonMediaMembersOrgRecipientMutation, DeleteSeasonMediaMembersOrgRecipientMutationVariables>(DeleteSeasonMediaMembersOrgRecipientDocument, options);
      }
export type DeleteSeasonMediaMembersOrgRecipientMutationHookResult = ReturnType<typeof useDeleteSeasonMediaMembersOrgRecipientMutation>;
export type DeleteSeasonMediaMembersOrgRecipientMutationResult = Apollo.MutationResult<DeleteSeasonMediaMembersOrgRecipientMutation>;
export type DeleteSeasonMediaMembersOrgRecipientMutationOptions = Apollo.BaseMutationOptions<DeleteSeasonMediaMembersOrgRecipientMutation, DeleteSeasonMediaMembersOrgRecipientMutationVariables>;
export const ActivateSeasonMediaMembersOrgRecipientDocument = gql`
    mutation activateSeasonMediaMembersOrgRecipient($orgId: String!, $seasonId: String!, $orgRecipientId: String!, $isActive: Boolean!) {
  activateSeasonMediaMembersOrgRecipient(
    input: {orgId: $orgId, seasonId: $seasonId, orgRecipientId: $orgRecipientId, isActive: $isActive}
  ) {
    id
    SeasonId
    orgMembershipId
    orgId
    orgName
    orgAbbreviation
    orgLogo
    isActive
    isKIRecipient
    title
    userId
    email
    firstName
    lastName
    profilePic
  }
}
    `;
export type ActivateSeasonMediaMembersOrgRecipientMutationFn = Apollo.MutationFunction<ActivateSeasonMediaMembersOrgRecipientMutation, ActivateSeasonMediaMembersOrgRecipientMutationVariables>;

/**
 * __useActivateSeasonMediaMembersOrgRecipientMutation__
 *
 * To run a mutation, you first call `useActivateSeasonMediaMembersOrgRecipientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateSeasonMediaMembersOrgRecipientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateSeasonMediaMembersOrgRecipientMutation, { data, loading, error }] = useActivateSeasonMediaMembersOrgRecipientMutation({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      seasonId: // value for 'seasonId'
 *      orgRecipientId: // value for 'orgRecipientId'
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useActivateSeasonMediaMembersOrgRecipientMutation(baseOptions?: Apollo.MutationHookOptions<ActivateSeasonMediaMembersOrgRecipientMutation, ActivateSeasonMediaMembersOrgRecipientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateSeasonMediaMembersOrgRecipientMutation, ActivateSeasonMediaMembersOrgRecipientMutationVariables>(ActivateSeasonMediaMembersOrgRecipientDocument, options);
      }
export type ActivateSeasonMediaMembersOrgRecipientMutationHookResult = ReturnType<typeof useActivateSeasonMediaMembersOrgRecipientMutation>;
export type ActivateSeasonMediaMembersOrgRecipientMutationResult = Apollo.MutationResult<ActivateSeasonMediaMembersOrgRecipientMutation>;
export type ActivateSeasonMediaMembersOrgRecipientMutationOptions = Apollo.BaseMutationOptions<ActivateSeasonMediaMembersOrgRecipientMutation, ActivateSeasonMediaMembersOrgRecipientMutationVariables>;
export const SetIsKiRecipientForSeasonMediaMembersOrgRecipientDocument = gql`
    mutation setIsKIRecipientForSeasonMediaMembersOrgRecipient($orgId: String!, $seasonId: String!, $orgRecipientId: String!, $isKIRecipient: Boolean!) {
  setIsKIRecipientForSeasonMediaMembersOrgRecipient(
    input: {orgId: $orgId, seasonId: $seasonId, orgRecipientId: $orgRecipientId, isKIRecipient: $isKIRecipient}
  ) {
    id
    SeasonId
    orgMembershipId
    orgId
    orgName
    orgAbbreviation
    orgLogo
    isActive
    isKIRecipient
    title
    userId
    email
    firstName
    lastName
    profilePic
  }
}
    `;
export type SetIsKiRecipientForSeasonMediaMembersOrgRecipientMutationFn = Apollo.MutationFunction<SetIsKiRecipientForSeasonMediaMembersOrgRecipientMutation, SetIsKiRecipientForSeasonMediaMembersOrgRecipientMutationVariables>;

/**
 * __useSetIsKiRecipientForSeasonMediaMembersOrgRecipientMutation__
 *
 * To run a mutation, you first call `useSetIsKiRecipientForSeasonMediaMembersOrgRecipientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetIsKiRecipientForSeasonMediaMembersOrgRecipientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setIsKiRecipientForSeasonMediaMembersOrgRecipientMutation, { data, loading, error }] = useSetIsKiRecipientForSeasonMediaMembersOrgRecipientMutation({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      seasonId: // value for 'seasonId'
 *      orgRecipientId: // value for 'orgRecipientId'
 *      isKIRecipient: // value for 'isKIRecipient'
 *   },
 * });
 */
export function useSetIsKiRecipientForSeasonMediaMembersOrgRecipientMutation(baseOptions?: Apollo.MutationHookOptions<SetIsKiRecipientForSeasonMediaMembersOrgRecipientMutation, SetIsKiRecipientForSeasonMediaMembersOrgRecipientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetIsKiRecipientForSeasonMediaMembersOrgRecipientMutation, SetIsKiRecipientForSeasonMediaMembersOrgRecipientMutationVariables>(SetIsKiRecipientForSeasonMediaMembersOrgRecipientDocument, options);
      }
export type SetIsKiRecipientForSeasonMediaMembersOrgRecipientMutationHookResult = ReturnType<typeof useSetIsKiRecipientForSeasonMediaMembersOrgRecipientMutation>;
export type SetIsKiRecipientForSeasonMediaMembersOrgRecipientMutationResult = Apollo.MutationResult<SetIsKiRecipientForSeasonMediaMembersOrgRecipientMutation>;
export type SetIsKiRecipientForSeasonMediaMembersOrgRecipientMutationOptions = Apollo.BaseMutationOptions<SetIsKiRecipientForSeasonMediaMembersOrgRecipientMutation, SetIsKiRecipientForSeasonMediaMembersOrgRecipientMutationVariables>;
export const ListSportsDocument = gql`
    query listSports {
  listSports {
    sport
  }
}
    `;

/**
 * __useListSportsQuery__
 *
 * To run a query within a React component, call `useListSportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSportsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListSportsQuery(baseOptions?: Apollo.QueryHookOptions<ListSportsQuery, ListSportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListSportsQuery, ListSportsQueryVariables>(ListSportsDocument, options);
      }
export function useListSportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListSportsQuery, ListSportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListSportsQuery, ListSportsQueryVariables>(ListSportsDocument, options);
        }
export type ListSportsQueryHookResult = ReturnType<typeof useListSportsQuery>;
export type ListSportsLazyQueryHookResult = ReturnType<typeof useListSportsLazyQuery>;
export type ListSportsQueryResult = Apollo.QueryResult<ListSportsQuery, ListSportsQueryVariables>;
export const ListAssessmentObjectsDocument = gql`
    query listAssessmentObjects($offset: Int!, $limit: Int!, $sport: Sports, $type: AssessmentObjectTypes, $classification: AssessmentObjectClassifications) {
  listAssessmentObjects(
    input: {sport: $sport, type: $type, classification: $classification, pagination: {offset: $offset, limit: $limit}}
  ) {
    assessmentObjects {
      id
      sport
      type
      classification
      name
      createdAt
      updatedAt
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListAssessmentObjectsQuery__
 *
 * To run a query within a React component, call `useListAssessmentObjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAssessmentObjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAssessmentObjectsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      sport: // value for 'sport'
 *      type: // value for 'type'
 *      classification: // value for 'classification'
 *   },
 * });
 */
export function useListAssessmentObjectsQuery(baseOptions: Apollo.QueryHookOptions<ListAssessmentObjectsQuery, ListAssessmentObjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAssessmentObjectsQuery, ListAssessmentObjectsQueryVariables>(ListAssessmentObjectsDocument, options);
      }
export function useListAssessmentObjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAssessmentObjectsQuery, ListAssessmentObjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAssessmentObjectsQuery, ListAssessmentObjectsQueryVariables>(ListAssessmentObjectsDocument, options);
        }
export type ListAssessmentObjectsQueryHookResult = ReturnType<typeof useListAssessmentObjectsQuery>;
export type ListAssessmentObjectsLazyQueryHookResult = ReturnType<typeof useListAssessmentObjectsLazyQuery>;
export type ListAssessmentObjectsQueryResult = Apollo.QueryResult<ListAssessmentObjectsQuery, ListAssessmentObjectsQueryVariables>;
export const ListPeriodsDocument = gql`
    query listPeriods($offset: Int!, $limit: Int!, $sport: Sports, $name: String) {
  listPeriods(
    input: {sport: $sport, name: $name, pagination: {offset: $offset, limit: $limit}}
  ) {
    periods {
      id
      sport
      name
      createdAt
      updatedAt
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListPeriodsQuery__
 *
 * To run a query within a React component, call `useListPeriodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPeriodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPeriodsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      sport: // value for 'sport'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useListPeriodsQuery(baseOptions: Apollo.QueryHookOptions<ListPeriodsQuery, ListPeriodsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListPeriodsQuery, ListPeriodsQueryVariables>(ListPeriodsDocument, options);
      }
export function useListPeriodsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListPeriodsQuery, ListPeriodsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListPeriodsQuery, ListPeriodsQueryVariables>(ListPeriodsDocument, options);
        }
export type ListPeriodsQueryHookResult = ReturnType<typeof useListPeriodsQuery>;
export type ListPeriodsLazyQueryHookResult = ReturnType<typeof useListPeriodsLazyQuery>;
export type ListPeriodsQueryResult = Apollo.QueryResult<ListPeriodsQuery, ListPeriodsQueryVariables>;
export const ListSupplementalReportsForOrgDocument = gql`
    query listSupplementalReportsForOrg($offset: Int!, $limit: Int!, $orgId: String!, $leagueId: String, $seasonId: String, $teamSearch: String, $type: SupplementalReportTypes, $status: SupplementalReportStatus, $submittedBySearch: String, $competitionDate: String) {
  listSupplementalReportsForOrg(
    input: {orgId: $orgId, filters: {leagueId: $leagueId, seasonId: $seasonId, teamSearch: $teamSearch, type: $type, status: $status, submittedBySearch: $submittedBySearch, competitionDate: $competitionDate}, pagination: {offset: $offset, limit: $limit}}
  ) {
    supplementalReports {
      id
      competitionSurveyId
      dynamicCompetitionSurveyId
      submittedBy {
        userId
        email
        firstName
        lastName
        profilePic
        teamId
        teamName
        teamLogo
        officialId
        assessmentObjectId
        assessmentObjectName
      }
      approvedBy {
        userId
        email
        firstName
        lastName
        profilePic
        title
        organizationName
        organizationAbbreviation
      }
      competitionInfo {
        competitionId
        externalId
        sport
        homeTeamId
        homeTeamName
        homeTeamScore
        homeTeamTieBreakerScore
        awayTeamId
        awayTeamName
        awayTeamScore
        awayTeamTieBreakerScore
        startTime
        timeZone
        location
        outcome
        verificationStatus
      }
      assessmentObject {
        id
        sport
        type
        classification
        name
        createdAt
        updatedAt
      }
      official {
        id
        sport
        userId
        status
        email
        firstName
        lastName
        profilePic
        createdAt
        updatedAt
      }
      team {
        id
        name
        abbreviation
        sport
        city
        stateAbbreviation
        countryCode
        classification
        gender
        parentOrganizationName
        externalId
        ageGroup
        timeZone
        website
        logo
        createdAt
        updatedAt
      }
      organizationId
      organizationName
      leagueId
      leagueName
      seasonId
      seasonName
      competitionSurveySectionQuestionId
      competitionSurveyFeedbackId
      dynamicCompetitionSurveySectionQuestionId
      dynamicCompetitionSurveyFeedbackId
      dynamicCompetitionSurveySubFeedbackId
      type
      status
      title
      report
      reportVersion
      time
      approvedDate
      reopenedDate
      submittedAt
      createdAt
      updatedAt
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListSupplementalReportsForOrgQuery__
 *
 * To run a query within a React component, call `useListSupplementalReportsForOrgQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSupplementalReportsForOrgQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSupplementalReportsForOrgQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      orgId: // value for 'orgId'
 *      leagueId: // value for 'leagueId'
 *      seasonId: // value for 'seasonId'
 *      teamSearch: // value for 'teamSearch'
 *      type: // value for 'type'
 *      status: // value for 'status'
 *      submittedBySearch: // value for 'submittedBySearch'
 *      competitionDate: // value for 'competitionDate'
 *   },
 * });
 */
export function useListSupplementalReportsForOrgQuery(baseOptions: Apollo.QueryHookOptions<ListSupplementalReportsForOrgQuery, ListSupplementalReportsForOrgQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListSupplementalReportsForOrgQuery, ListSupplementalReportsForOrgQueryVariables>(ListSupplementalReportsForOrgDocument, options);
      }
export function useListSupplementalReportsForOrgLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListSupplementalReportsForOrgQuery, ListSupplementalReportsForOrgQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListSupplementalReportsForOrgQuery, ListSupplementalReportsForOrgQueryVariables>(ListSupplementalReportsForOrgDocument, options);
        }
export type ListSupplementalReportsForOrgQueryHookResult = ReturnType<typeof useListSupplementalReportsForOrgQuery>;
export type ListSupplementalReportsForOrgLazyQueryHookResult = ReturnType<typeof useListSupplementalReportsForOrgLazyQuery>;
export type ListSupplementalReportsForOrgQueryResult = Apollo.QueryResult<ListSupplementalReportsForOrgQuery, ListSupplementalReportsForOrgQueryVariables>;
export const ListSupplementalReportsForUserDocument = gql`
    query listSupplementalReportsForUser($offset: Int!, $limit: Int!, $leagueId: String, $seasonId: String, $teamSearch: String, $type: SupplementalReportTypes, $status: SupplementalReportStatus, $submittedBySearch: String, $competitionDate: String) {
  listSupplementalReportsForUser(
    input: {filters: {leagueId: $leagueId, seasonId: $seasonId, teamSearch: $teamSearch, type: $type, status: $status, submittedBySearch: $submittedBySearch, competitionDate: $competitionDate}, pagination: {offset: $offset, limit: $limit}}
  ) {
    supplementalReports {
      id
      competitionSurveyId
      dynamicCompetitionSurveyId
      submittedBy {
        userId
        email
        firstName
        lastName
        profilePic
        teamId
        teamName
        teamLogo
        officialId
        assessmentObjectId
        assessmentObjectName
      }
      approvedBy {
        userId
        email
        firstName
        lastName
        profilePic
        title
        organizationName
        organizationAbbreviation
      }
      competitionInfo {
        competitionId
        externalId
        sport
        homeTeamId
        homeTeamName
        homeTeamScore
        homeTeamTieBreakerScore
        awayTeamId
        awayTeamName
        awayTeamScore
        awayTeamTieBreakerScore
        startTime
        timeZone
        location
        outcome
        verificationStatus
      }
      assessmentObject {
        id
        sport
        type
        classification
        name
        createdAt
        updatedAt
      }
      official {
        id
        sport
        userId
        status
        email
        firstName
        lastName
        profilePic
        createdAt
        updatedAt
      }
      team {
        id
        name
        abbreviation
        sport
        city
        stateAbbreviation
        countryCode
        classification
        gender
        parentOrganizationName
        externalId
        ageGroup
        timeZone
        website
        logo
        createdAt
        updatedAt
      }
      organizationId
      organizationName
      leagueId
      leagueName
      seasonId
      seasonName
      competitionSurveySectionQuestionId
      competitionSurveyFeedbackId
      dynamicCompetitionSurveySectionQuestionId
      dynamicCompetitionSurveyFeedbackId
      dynamicCompetitionSurveySubFeedbackId
      type
      status
      title
      report
      reportVersion
      time
      approvedDate
      reopenedDate
      submittedAt
      createdAt
      updatedAt
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListSupplementalReportsForUserQuery__
 *
 * To run a query within a React component, call `useListSupplementalReportsForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSupplementalReportsForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSupplementalReportsForUserQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      leagueId: // value for 'leagueId'
 *      seasonId: // value for 'seasonId'
 *      teamSearch: // value for 'teamSearch'
 *      type: // value for 'type'
 *      status: // value for 'status'
 *      submittedBySearch: // value for 'submittedBySearch'
 *      competitionDate: // value for 'competitionDate'
 *   },
 * });
 */
export function useListSupplementalReportsForUserQuery(baseOptions: Apollo.QueryHookOptions<ListSupplementalReportsForUserQuery, ListSupplementalReportsForUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListSupplementalReportsForUserQuery, ListSupplementalReportsForUserQueryVariables>(ListSupplementalReportsForUserDocument, options);
      }
export function useListSupplementalReportsForUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListSupplementalReportsForUserQuery, ListSupplementalReportsForUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListSupplementalReportsForUserQuery, ListSupplementalReportsForUserQueryVariables>(ListSupplementalReportsForUserDocument, options);
        }
export type ListSupplementalReportsForUserQueryHookResult = ReturnType<typeof useListSupplementalReportsForUserQuery>;
export type ListSupplementalReportsForUserLazyQueryHookResult = ReturnType<typeof useListSupplementalReportsForUserLazyQuery>;
export type ListSupplementalReportsForUserQueryResult = Apollo.QueryResult<ListSupplementalReportsForUserQuery, ListSupplementalReportsForUserQueryVariables>;
export const ListOrgSupplementalReportsForCompetitionDocument = gql`
    query listOrgSupplementalReportsForCompetition($offset: Int!, $limit: Int!, $competitionId: String!, $teamSearch: String, $type: SupplementalReportTypes, $status: SupplementalReportStatus) {
  listOrgSupplementalReportsForCompetition(
    input: {competitionId: $competitionId, filters: {teamSearch: $teamSearch, type: $type, status: $status}, pagination: {offset: $offset, limit: $limit}}
  ) {
    supplementalReports {
      id
      competitionSurveyId
      dynamicCompetitionSurveyId
      submittedBy {
        userId
        email
        firstName
        lastName
        profilePic
        teamId
        teamName
        teamLogo
        officialId
        assessmentObjectId
        assessmentObjectName
      }
      approvedBy {
        userId
        email
        firstName
        lastName
        profilePic
        title
        organizationName
        organizationAbbreviation
      }
      competitionInfo {
        competitionId
        externalId
        sport
        homeTeamId
        homeTeamName
        homeTeamScore
        homeTeamTieBreakerScore
        awayTeamId
        awayTeamName
        awayTeamScore
        awayTeamTieBreakerScore
        startTime
        timeZone
        location
        outcome
        verificationStatus
      }
      assessmentObject {
        id
        sport
        type
        classification
        name
        createdAt
        updatedAt
      }
      official {
        id
        sport
        userId
        status
        email
        firstName
        lastName
        profilePic
        createdAt
        updatedAt
      }
      team {
        id
        name
        abbreviation
        sport
        city
        stateAbbreviation
        countryCode
        classification
        gender
        parentOrganizationName
        externalId
        ageGroup
        timeZone
        website
        logo
        createdAt
        updatedAt
      }
      organizationId
      organizationName
      leagueId
      leagueName
      seasonId
      seasonName
      competitionSurveySectionQuestionId
      competitionSurveyFeedbackId
      dynamicCompetitionSurveySectionQuestionId
      dynamicCompetitionSurveyFeedbackId
      dynamicCompetitionSurveySubFeedbackId
      type
      status
      title
      report
      reportVersion
      time
      approvedDate
      reopenedDate
      submittedAt
      createdAt
      updatedAt
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListOrgSupplementalReportsForCompetitionQuery__
 *
 * To run a query within a React component, call `useListOrgSupplementalReportsForCompetitionQuery` and pass it any options that fit your needs.
 * When your component renders, `useListOrgSupplementalReportsForCompetitionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListOrgSupplementalReportsForCompetitionQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      competitionId: // value for 'competitionId'
 *      teamSearch: // value for 'teamSearch'
 *      type: // value for 'type'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useListOrgSupplementalReportsForCompetitionQuery(baseOptions: Apollo.QueryHookOptions<ListOrgSupplementalReportsForCompetitionQuery, ListOrgSupplementalReportsForCompetitionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListOrgSupplementalReportsForCompetitionQuery, ListOrgSupplementalReportsForCompetitionQueryVariables>(ListOrgSupplementalReportsForCompetitionDocument, options);
      }
export function useListOrgSupplementalReportsForCompetitionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListOrgSupplementalReportsForCompetitionQuery, ListOrgSupplementalReportsForCompetitionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListOrgSupplementalReportsForCompetitionQuery, ListOrgSupplementalReportsForCompetitionQueryVariables>(ListOrgSupplementalReportsForCompetitionDocument, options);
        }
export type ListOrgSupplementalReportsForCompetitionQueryHookResult = ReturnType<typeof useListOrgSupplementalReportsForCompetitionQuery>;
export type ListOrgSupplementalReportsForCompetitionLazyQueryHookResult = ReturnType<typeof useListOrgSupplementalReportsForCompetitionLazyQuery>;
export type ListOrgSupplementalReportsForCompetitionQueryResult = Apollo.QueryResult<ListOrgSupplementalReportsForCompetitionQuery, ListOrgSupplementalReportsForCompetitionQueryVariables>;
export const ListUserSupplementalReportsForCompetitionDocument = gql`
    query listUserSupplementalReportsForCompetition($offset: Int!, $limit: Int!, $competitionId: String!, $teamSearch: String, $type: SupplementalReportTypes, $status: SupplementalReportStatus) {
  listUserSupplementalReportsForCompetition(
    input: {competitionId: $competitionId, filters: {teamSearch: $teamSearch, type: $type, status: $status}, pagination: {offset: $offset, limit: $limit}}
  ) {
    supplementalReports {
      id
      competitionSurveyId
      dynamicCompetitionSurveyId
      submittedBy {
        userId
        email
        firstName
        lastName
        profilePic
        teamId
        teamName
        teamLogo
        officialId
        assessmentObjectId
        assessmentObjectName
      }
      approvedBy {
        userId
        email
        firstName
        lastName
        profilePic
        title
        organizationName
        organizationAbbreviation
      }
      competitionInfo {
        competitionId
        externalId
        sport
        homeTeamId
        homeTeamName
        homeTeamScore
        homeTeamTieBreakerScore
        awayTeamId
        awayTeamName
        awayTeamScore
        awayTeamTieBreakerScore
        startTime
        timeZone
        location
        outcome
        verificationStatus
      }
      assessmentObject {
        id
        sport
        type
        classification
        name
        createdAt
        updatedAt
      }
      official {
        id
        sport
        userId
        status
        email
        firstName
        lastName
        profilePic
        createdAt
        updatedAt
      }
      team {
        id
        name
        abbreviation
        sport
        city
        stateAbbreviation
        countryCode
        classification
        gender
        parentOrganizationName
        externalId
        ageGroup
        timeZone
        website
        logo
        createdAt
        updatedAt
      }
      organizationId
      organizationName
      leagueId
      leagueName
      seasonId
      seasonName
      competitionSurveySectionQuestionId
      competitionSurveyFeedbackId
      dynamicCompetitionSurveySectionQuestionId
      dynamicCompetitionSurveyFeedbackId
      dynamicCompetitionSurveySubFeedbackId
      type
      status
      title
      report
      reportVersion
      time
      approvedDate
      reopenedDate
      submittedAt
      createdAt
      updatedAt
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListUserSupplementalReportsForCompetitionQuery__
 *
 * To run a query within a React component, call `useListUserSupplementalReportsForCompetitionQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUserSupplementalReportsForCompetitionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUserSupplementalReportsForCompetitionQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      competitionId: // value for 'competitionId'
 *      teamSearch: // value for 'teamSearch'
 *      type: // value for 'type'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useListUserSupplementalReportsForCompetitionQuery(baseOptions: Apollo.QueryHookOptions<ListUserSupplementalReportsForCompetitionQuery, ListUserSupplementalReportsForCompetitionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListUserSupplementalReportsForCompetitionQuery, ListUserSupplementalReportsForCompetitionQueryVariables>(ListUserSupplementalReportsForCompetitionDocument, options);
      }
export function useListUserSupplementalReportsForCompetitionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListUserSupplementalReportsForCompetitionQuery, ListUserSupplementalReportsForCompetitionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListUserSupplementalReportsForCompetitionQuery, ListUserSupplementalReportsForCompetitionQueryVariables>(ListUserSupplementalReportsForCompetitionDocument, options);
        }
export type ListUserSupplementalReportsForCompetitionQueryHookResult = ReturnType<typeof useListUserSupplementalReportsForCompetitionQuery>;
export type ListUserSupplementalReportsForCompetitionLazyQueryHookResult = ReturnType<typeof useListUserSupplementalReportsForCompetitionLazyQuery>;
export type ListUserSupplementalReportsForCompetitionQueryResult = Apollo.QueryResult<ListUserSupplementalReportsForCompetitionQuery, ListUserSupplementalReportsForCompetitionQueryVariables>;
export const GetSupplementalReportByIdDocument = gql`
    query getSupplementalReportById($supplementalReportId: String!) {
  getSupplementalReportById(input: {supplementalReportId: $supplementalReportId}) {
    id
    competitionSurveyId
    dynamicCompetitionSurveyId
    submittedBy {
      userId
      email
      firstName
      lastName
      profilePic
      teamId
      teamName
      teamLogo
      officialId
      assessmentObjectId
      assessmentObjectName
    }
    approvedBy {
      userId
      email
      firstName
      lastName
      profilePic
      title
      organizationName
      organizationAbbreviation
    }
    competitionInfo {
      competitionId
      externalId
      sport
      homeTeamId
      homeTeamName
      homeTeamScore
      homeTeamTieBreakerScore
      awayTeamId
      awayTeamName
      awayTeamScore
      awayTeamTieBreakerScore
      startTime
      timeZone
      location
      outcome
      verificationStatus
    }
    assessmentObject {
      id
      sport
      type
      classification
      name
      createdAt
      updatedAt
    }
    official {
      id
      sport
      userId
      status
      email
      firstName
      lastName
      profilePic
      createdAt
      updatedAt
    }
    team {
      id
      name
      abbreviation
      sport
      city
      stateAbbreviation
      countryCode
      classification
      gender
      parentOrganizationName
      externalId
      ageGroup
      timeZone
      website
      logo
      createdAt
      updatedAt
    }
    organizationId
    organizationName
    leagueId
    leagueName
    seasonId
    seasonName
    competitionSurveySectionQuestionId
    competitionSurveyFeedbackId
    dynamicCompetitionSurveySectionQuestionId
    dynamicCompetitionSurveyFeedbackId
    dynamicCompetitionSurveySubFeedbackId
    type
    status
    title
    report
    reportVersion
    time
    approvedDate
    reopenedDate
    submittedAt
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetSupplementalReportByIdQuery__
 *
 * To run a query within a React component, call `useGetSupplementalReportByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplementalReportByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplementalReportByIdQuery({
 *   variables: {
 *      supplementalReportId: // value for 'supplementalReportId'
 *   },
 * });
 */
export function useGetSupplementalReportByIdQuery(baseOptions: Apollo.QueryHookOptions<GetSupplementalReportByIdQuery, GetSupplementalReportByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSupplementalReportByIdQuery, GetSupplementalReportByIdQueryVariables>(GetSupplementalReportByIdDocument, options);
      }
export function useGetSupplementalReportByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSupplementalReportByIdQuery, GetSupplementalReportByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSupplementalReportByIdQuery, GetSupplementalReportByIdQueryVariables>(GetSupplementalReportByIdDocument, options);
        }
export type GetSupplementalReportByIdQueryHookResult = ReturnType<typeof useGetSupplementalReportByIdQuery>;
export type GetSupplementalReportByIdLazyQueryHookResult = ReturnType<typeof useGetSupplementalReportByIdLazyQuery>;
export type GetSupplementalReportByIdQueryResult = Apollo.QueryResult<GetSupplementalReportByIdQuery, GetSupplementalReportByIdQueryVariables>;
export const UpdateReportForSupplementalReportDocument = gql`
    mutation updateReportForSupplementalReport($supplementalReportId: String!, $report: String!) {
  updateReportForSupplementalReport(
    input: {supplementalReportId: $supplementalReportId, report: $report}
  ) {
    id
    competitionSurveyId
    dynamicCompetitionSurveyId
    submittedBy {
      userId
      email
      firstName
      lastName
      profilePic
      teamId
      teamName
      teamLogo
      officialId
      assessmentObjectId
      assessmentObjectName
    }
    approvedBy {
      userId
      email
      firstName
      lastName
      profilePic
      title
      organizationName
      organizationAbbreviation
    }
    competitionInfo {
      competitionId
      externalId
      sport
      homeTeamId
      homeTeamName
      homeTeamScore
      homeTeamTieBreakerScore
      awayTeamId
      awayTeamName
      awayTeamScore
      awayTeamTieBreakerScore
      startTime
      timeZone
      location
      outcome
      verificationStatus
    }
    assessmentObject {
      id
      sport
      type
      classification
      name
      createdAt
      updatedAt
    }
    official {
      id
      sport
      userId
      status
      email
      firstName
      lastName
      profilePic
      createdAt
      updatedAt
    }
    team {
      id
      name
      abbreviation
      sport
      city
      stateAbbreviation
      countryCode
      classification
      gender
      parentOrganizationName
      externalId
      ageGroup
      timeZone
      website
      logo
      createdAt
      updatedAt
    }
    organizationId
    organizationName
    leagueId
    leagueName
    seasonId
    seasonName
    competitionSurveySectionQuestionId
    competitionSurveyFeedbackId
    dynamicCompetitionSurveySectionQuestionId
    dynamicCompetitionSurveyFeedbackId
    dynamicCompetitionSurveySubFeedbackId
    type
    status
    title
    report
    reportVersion
    time
    approvedDate
    reopenedDate
    submittedAt
    createdAt
    updatedAt
  }
}
    `;
export type UpdateReportForSupplementalReportMutationFn = Apollo.MutationFunction<UpdateReportForSupplementalReportMutation, UpdateReportForSupplementalReportMutationVariables>;

/**
 * __useUpdateReportForSupplementalReportMutation__
 *
 * To run a mutation, you first call `useUpdateReportForSupplementalReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReportForSupplementalReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReportForSupplementalReportMutation, { data, loading, error }] = useUpdateReportForSupplementalReportMutation({
 *   variables: {
 *      supplementalReportId: // value for 'supplementalReportId'
 *      report: // value for 'report'
 *   },
 * });
 */
export function useUpdateReportForSupplementalReportMutation(baseOptions?: Apollo.MutationHookOptions<UpdateReportForSupplementalReportMutation, UpdateReportForSupplementalReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateReportForSupplementalReportMutation, UpdateReportForSupplementalReportMutationVariables>(UpdateReportForSupplementalReportDocument, options);
      }
export type UpdateReportForSupplementalReportMutationHookResult = ReturnType<typeof useUpdateReportForSupplementalReportMutation>;
export type UpdateReportForSupplementalReportMutationResult = Apollo.MutationResult<UpdateReportForSupplementalReportMutation>;
export type UpdateReportForSupplementalReportMutationOptions = Apollo.BaseMutationOptions<UpdateReportForSupplementalReportMutation, UpdateReportForSupplementalReportMutationVariables>;
export const RequestChangeForSupplementalReportDocument = gql`
    mutation requestChangeForSupplementalReport($supplementalReportId: String!, $message: String!) {
  requestChangeForSupplementalReport(
    input: {supplementalReportId: $supplementalReportId, message: $message}
  ) {
    id
    competitionSurveyId
    dynamicCompetitionSurveyId
    submittedBy {
      userId
      email
      firstName
      lastName
      profilePic
      teamId
      teamName
      teamLogo
      officialId
      assessmentObjectId
      assessmentObjectName
    }
    approvedBy {
      userId
      email
      firstName
      lastName
      profilePic
      title
      organizationName
      organizationAbbreviation
    }
    competitionInfo {
      competitionId
      externalId
      sport
      homeTeamId
      homeTeamName
      homeTeamScore
      homeTeamTieBreakerScore
      awayTeamId
      awayTeamName
      awayTeamScore
      awayTeamTieBreakerScore
      startTime
      timeZone
      location
      outcome
      verificationStatus
    }
    assessmentObject {
      id
      sport
      type
      classification
      name
      createdAt
      updatedAt
    }
    official {
      id
      sport
      userId
      status
      email
      firstName
      lastName
      profilePic
      createdAt
      updatedAt
    }
    team {
      id
      name
      abbreviation
      sport
      city
      stateAbbreviation
      countryCode
      classification
      gender
      parentOrganizationName
      externalId
      ageGroup
      timeZone
      website
      logo
      createdAt
      updatedAt
    }
    organizationId
    organizationName
    leagueId
    leagueName
    seasonId
    seasonName
    competitionSurveySectionQuestionId
    competitionSurveyFeedbackId
    dynamicCompetitionSurveySectionQuestionId
    dynamicCompetitionSurveyFeedbackId
    dynamicCompetitionSurveySubFeedbackId
    type
    status
    title
    report
    reportVersion
    time
    approvedDate
    reopenedDate
    submittedAt
    createdAt
    updatedAt
  }
}
    `;
export type RequestChangeForSupplementalReportMutationFn = Apollo.MutationFunction<RequestChangeForSupplementalReportMutation, RequestChangeForSupplementalReportMutationVariables>;

/**
 * __useRequestChangeForSupplementalReportMutation__
 *
 * To run a mutation, you first call `useRequestChangeForSupplementalReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestChangeForSupplementalReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestChangeForSupplementalReportMutation, { data, loading, error }] = useRequestChangeForSupplementalReportMutation({
 *   variables: {
 *      supplementalReportId: // value for 'supplementalReportId'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useRequestChangeForSupplementalReportMutation(baseOptions?: Apollo.MutationHookOptions<RequestChangeForSupplementalReportMutation, RequestChangeForSupplementalReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestChangeForSupplementalReportMutation, RequestChangeForSupplementalReportMutationVariables>(RequestChangeForSupplementalReportDocument, options);
      }
export type RequestChangeForSupplementalReportMutationHookResult = ReturnType<typeof useRequestChangeForSupplementalReportMutation>;
export type RequestChangeForSupplementalReportMutationResult = Apollo.MutationResult<RequestChangeForSupplementalReportMutation>;
export type RequestChangeForSupplementalReportMutationOptions = Apollo.BaseMutationOptions<RequestChangeForSupplementalReportMutation, RequestChangeForSupplementalReportMutationVariables>;
export const ApproveSupplementalReportDocument = gql`
    mutation approveSupplementalReport($supplementalReportId: String!) {
  approveSupplementalReport(input: {supplementalReportId: $supplementalReportId}) {
    id
    competitionSurveyId
    dynamicCompetitionSurveyId
    submittedBy {
      userId
      email
      firstName
      lastName
      profilePic
      teamId
      teamName
      teamLogo
      officialId
      assessmentObjectId
      assessmentObjectName
    }
    approvedBy {
      userId
      email
      firstName
      lastName
      profilePic
      title
      organizationName
      organizationAbbreviation
    }
    competitionInfo {
      competitionId
      externalId
      sport
      homeTeamId
      homeTeamName
      homeTeamScore
      homeTeamTieBreakerScore
      awayTeamId
      awayTeamName
      awayTeamScore
      awayTeamTieBreakerScore
      startTime
      timeZone
      location
      outcome
      verificationStatus
    }
    assessmentObject {
      id
      sport
      type
      classification
      name
      createdAt
      updatedAt
    }
    official {
      id
      sport
      userId
      status
      email
      firstName
      lastName
      profilePic
      createdAt
      updatedAt
    }
    team {
      id
      name
      abbreviation
      sport
      city
      stateAbbreviation
      countryCode
      classification
      gender
      parentOrganizationName
      externalId
      ageGroup
      timeZone
      website
      logo
      createdAt
      updatedAt
    }
    organizationId
    organizationName
    leagueId
    leagueName
    seasonId
    seasonName
    competitionSurveySectionQuestionId
    competitionSurveyFeedbackId
    dynamicCompetitionSurveySectionQuestionId
    dynamicCompetitionSurveyFeedbackId
    dynamicCompetitionSurveySubFeedbackId
    type
    status
    title
    report
    reportVersion
    time
    approvedDate
    reopenedDate
    submittedAt
    createdAt
    updatedAt
  }
}
    `;
export type ApproveSupplementalReportMutationFn = Apollo.MutationFunction<ApproveSupplementalReportMutation, ApproveSupplementalReportMutationVariables>;

/**
 * __useApproveSupplementalReportMutation__
 *
 * To run a mutation, you first call `useApproveSupplementalReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveSupplementalReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveSupplementalReportMutation, { data, loading, error }] = useApproveSupplementalReportMutation({
 *   variables: {
 *      supplementalReportId: // value for 'supplementalReportId'
 *   },
 * });
 */
export function useApproveSupplementalReportMutation(baseOptions?: Apollo.MutationHookOptions<ApproveSupplementalReportMutation, ApproveSupplementalReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApproveSupplementalReportMutation, ApproveSupplementalReportMutationVariables>(ApproveSupplementalReportDocument, options);
      }
export type ApproveSupplementalReportMutationHookResult = ReturnType<typeof useApproveSupplementalReportMutation>;
export type ApproveSupplementalReportMutationResult = Apollo.MutationResult<ApproveSupplementalReportMutation>;
export type ApproveSupplementalReportMutationOptions = Apollo.BaseMutationOptions<ApproveSupplementalReportMutation, ApproveSupplementalReportMutationVariables>;
export const ListCommentsForSupplementalReportDocument = gql`
    query listCommentsForSupplementalReport($offset: Int!, $limit: Int!, $supplementalReportId: String!) {
  listCommentsForSupplementalReport(
    input: {supplementalReportId: $supplementalReportId, pagination: {offset: $offset, limit: $limit}}
  ) {
    messages {
      id
      supplementalReportId
      author {
        id
        userId
        email
        firstName
        lastName
        profilePic
        authorType
        teamId
        teamName
        teamLogo
        title
        organizationName
        organizationAbbreviation
        assessmentObjectId
        assessmentObjectName
      }
      message
      createdAt
      updatedAt
      editedAt
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListCommentsForSupplementalReportQuery__
 *
 * To run a query within a React component, call `useListCommentsForSupplementalReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCommentsForSupplementalReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCommentsForSupplementalReportQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      supplementalReportId: // value for 'supplementalReportId'
 *   },
 * });
 */
export function useListCommentsForSupplementalReportQuery(baseOptions: Apollo.QueryHookOptions<ListCommentsForSupplementalReportQuery, ListCommentsForSupplementalReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListCommentsForSupplementalReportQuery, ListCommentsForSupplementalReportQueryVariables>(ListCommentsForSupplementalReportDocument, options);
      }
export function useListCommentsForSupplementalReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListCommentsForSupplementalReportQuery, ListCommentsForSupplementalReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListCommentsForSupplementalReportQuery, ListCommentsForSupplementalReportQueryVariables>(ListCommentsForSupplementalReportDocument, options);
        }
export type ListCommentsForSupplementalReportQueryHookResult = ReturnType<typeof useListCommentsForSupplementalReportQuery>;
export type ListCommentsForSupplementalReportLazyQueryHookResult = ReturnType<typeof useListCommentsForSupplementalReportLazyQuery>;
export type ListCommentsForSupplementalReportQueryResult = Apollo.QueryResult<ListCommentsForSupplementalReportQuery, ListCommentsForSupplementalReportQueryVariables>;
export const AddCommentToSupplementalReportDocument = gql`
    mutation addCommentToSupplementalReport($supplementalReportId: String!, $comment: String!) {
  addCommentToSupplementalReport(
    input: {supplementalReportId: $supplementalReportId, comment: $comment}
  ) {
    id
    supplementalReportId
    author {
      id
      userId
      email
      firstName
      lastName
      profilePic
      authorType
      teamId
      teamName
      teamLogo
      title
      organizationName
      organizationAbbreviation
      assessmentObjectId
      assessmentObjectName
    }
    message
    createdAt
    updatedAt
    editedAt
  }
}
    `;
export type AddCommentToSupplementalReportMutationFn = Apollo.MutationFunction<AddCommentToSupplementalReportMutation, AddCommentToSupplementalReportMutationVariables>;

/**
 * __useAddCommentToSupplementalReportMutation__
 *
 * To run a mutation, you first call `useAddCommentToSupplementalReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCommentToSupplementalReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCommentToSupplementalReportMutation, { data, loading, error }] = useAddCommentToSupplementalReportMutation({
 *   variables: {
 *      supplementalReportId: // value for 'supplementalReportId'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useAddCommentToSupplementalReportMutation(baseOptions?: Apollo.MutationHookOptions<AddCommentToSupplementalReportMutation, AddCommentToSupplementalReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCommentToSupplementalReportMutation, AddCommentToSupplementalReportMutationVariables>(AddCommentToSupplementalReportDocument, options);
      }
export type AddCommentToSupplementalReportMutationHookResult = ReturnType<typeof useAddCommentToSupplementalReportMutation>;
export type AddCommentToSupplementalReportMutationResult = Apollo.MutationResult<AddCommentToSupplementalReportMutation>;
export type AddCommentToSupplementalReportMutationOptions = Apollo.BaseMutationOptions<AddCommentToSupplementalReportMutation, AddCommentToSupplementalReportMutationVariables>;
export const GetSurveySummaryRecipientNotificationSettingByTokenDocument = gql`
    query getSurveySummaryRecipientNotificationSettingByToken($summaryNotificationSettingsToken: String!) {
  getSurveySummaryRecipientNotificationSettingByToken(
    input: {summaryNotificationSettingsToken: $summaryNotificationSettingsToken}
  ) {
    id
    email
    firstName
    lastName
    surveyName
    isActive
  }
}
    `;

/**
 * __useGetSurveySummaryRecipientNotificationSettingByTokenQuery__
 *
 * To run a query within a React component, call `useGetSurveySummaryRecipientNotificationSettingByTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSurveySummaryRecipientNotificationSettingByTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSurveySummaryRecipientNotificationSettingByTokenQuery({
 *   variables: {
 *      summaryNotificationSettingsToken: // value for 'summaryNotificationSettingsToken'
 *   },
 * });
 */
export function useGetSurveySummaryRecipientNotificationSettingByTokenQuery(baseOptions: Apollo.QueryHookOptions<GetSurveySummaryRecipientNotificationSettingByTokenQuery, GetSurveySummaryRecipientNotificationSettingByTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSurveySummaryRecipientNotificationSettingByTokenQuery, GetSurveySummaryRecipientNotificationSettingByTokenQueryVariables>(GetSurveySummaryRecipientNotificationSettingByTokenDocument, options);
      }
export function useGetSurveySummaryRecipientNotificationSettingByTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSurveySummaryRecipientNotificationSettingByTokenQuery, GetSurveySummaryRecipientNotificationSettingByTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSurveySummaryRecipientNotificationSettingByTokenQuery, GetSurveySummaryRecipientNotificationSettingByTokenQueryVariables>(GetSurveySummaryRecipientNotificationSettingByTokenDocument, options);
        }
export type GetSurveySummaryRecipientNotificationSettingByTokenQueryHookResult = ReturnType<typeof useGetSurveySummaryRecipientNotificationSettingByTokenQuery>;
export type GetSurveySummaryRecipientNotificationSettingByTokenLazyQueryHookResult = ReturnType<typeof useGetSurveySummaryRecipientNotificationSettingByTokenLazyQuery>;
export type GetSurveySummaryRecipientNotificationSettingByTokenQueryResult = Apollo.QueryResult<GetSurveySummaryRecipientNotificationSettingByTokenQuery, GetSurveySummaryRecipientNotificationSettingByTokenQueryVariables>;
export const UpdateSurveySummaryRecipientNotificationSettingByTokenDocument = gql`
    mutation updateSurveySummaryRecipientNotificationSettingByToken($summaryNotificationSettingsToken: String!, $isActive: Boolean!) {
  updateSurveySummaryRecipientNotificationSettingByToken(
    input: {summaryNotificationSettingsToken: $summaryNotificationSettingsToken, isActive: $isActive}
  )
}
    `;
export type UpdateSurveySummaryRecipientNotificationSettingByTokenMutationFn = Apollo.MutationFunction<UpdateSurveySummaryRecipientNotificationSettingByTokenMutation, UpdateSurveySummaryRecipientNotificationSettingByTokenMutationVariables>;

/**
 * __useUpdateSurveySummaryRecipientNotificationSettingByTokenMutation__
 *
 * To run a mutation, you first call `useUpdateSurveySummaryRecipientNotificationSettingByTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSurveySummaryRecipientNotificationSettingByTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSurveySummaryRecipientNotificationSettingByTokenMutation, { data, loading, error }] = useUpdateSurveySummaryRecipientNotificationSettingByTokenMutation({
 *   variables: {
 *      summaryNotificationSettingsToken: // value for 'summaryNotificationSettingsToken'
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useUpdateSurveySummaryRecipientNotificationSettingByTokenMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSurveySummaryRecipientNotificationSettingByTokenMutation, UpdateSurveySummaryRecipientNotificationSettingByTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSurveySummaryRecipientNotificationSettingByTokenMutation, UpdateSurveySummaryRecipientNotificationSettingByTokenMutationVariables>(UpdateSurveySummaryRecipientNotificationSettingByTokenDocument, options);
      }
export type UpdateSurveySummaryRecipientNotificationSettingByTokenMutationHookResult = ReturnType<typeof useUpdateSurveySummaryRecipientNotificationSettingByTokenMutation>;
export type UpdateSurveySummaryRecipientNotificationSettingByTokenMutationResult = Apollo.MutationResult<UpdateSurveySummaryRecipientNotificationSettingByTokenMutation>;
export type UpdateSurveySummaryRecipientNotificationSettingByTokenMutationOptions = Apollo.BaseMutationOptions<UpdateSurveySummaryRecipientNotificationSettingByTokenMutation, UpdateSurveySummaryRecipientNotificationSettingByTokenMutationVariables>;
export const ListMySurveySummaryRecipientNotificationSettingsDocument = gql`
    query listMySurveySummaryRecipientNotificationSettings($offset: Int!, $limit: Int!, $surveyName: String) {
  listMySurveySummaryRecipientNotificationSettings(
    input: {surveyName: $surveyName, pagination: {offset: $offset, limit: $limit}}
  ) {
    settings {
      id
      email
      firstName
      lastName
      surveyName
      isActive
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListMySurveySummaryRecipientNotificationSettingsQuery__
 *
 * To run a query within a React component, call `useListMySurveySummaryRecipientNotificationSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListMySurveySummaryRecipientNotificationSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListMySurveySummaryRecipientNotificationSettingsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      surveyName: // value for 'surveyName'
 *   },
 * });
 */
export function useListMySurveySummaryRecipientNotificationSettingsQuery(baseOptions: Apollo.QueryHookOptions<ListMySurveySummaryRecipientNotificationSettingsQuery, ListMySurveySummaryRecipientNotificationSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListMySurveySummaryRecipientNotificationSettingsQuery, ListMySurveySummaryRecipientNotificationSettingsQueryVariables>(ListMySurveySummaryRecipientNotificationSettingsDocument, options);
      }
export function useListMySurveySummaryRecipientNotificationSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListMySurveySummaryRecipientNotificationSettingsQuery, ListMySurveySummaryRecipientNotificationSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListMySurveySummaryRecipientNotificationSettingsQuery, ListMySurveySummaryRecipientNotificationSettingsQueryVariables>(ListMySurveySummaryRecipientNotificationSettingsDocument, options);
        }
export type ListMySurveySummaryRecipientNotificationSettingsQueryHookResult = ReturnType<typeof useListMySurveySummaryRecipientNotificationSettingsQuery>;
export type ListMySurveySummaryRecipientNotificationSettingsLazyQueryHookResult = ReturnType<typeof useListMySurveySummaryRecipientNotificationSettingsLazyQuery>;
export type ListMySurveySummaryRecipientNotificationSettingsQueryResult = Apollo.QueryResult<ListMySurveySummaryRecipientNotificationSettingsQuery, ListMySurveySummaryRecipientNotificationSettingsQueryVariables>;
export const UpdateMySurveySummaryRecipientNotificationSettingDocument = gql`
    mutation updateMySurveySummaryRecipientNotificationSetting($recipientId: String!, $isActive: Boolean!) {
  updateMySurveySummaryRecipientNotificationSetting(
    input: {recipientId: $recipientId, isActive: $isActive}
  )
}
    `;
export type UpdateMySurveySummaryRecipientNotificationSettingMutationFn = Apollo.MutationFunction<UpdateMySurveySummaryRecipientNotificationSettingMutation, UpdateMySurveySummaryRecipientNotificationSettingMutationVariables>;

/**
 * __useUpdateMySurveySummaryRecipientNotificationSettingMutation__
 *
 * To run a mutation, you first call `useUpdateMySurveySummaryRecipientNotificationSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMySurveySummaryRecipientNotificationSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMySurveySummaryRecipientNotificationSettingMutation, { data, loading, error }] = useUpdateMySurveySummaryRecipientNotificationSettingMutation({
 *   variables: {
 *      recipientId: // value for 'recipientId'
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useUpdateMySurveySummaryRecipientNotificationSettingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMySurveySummaryRecipientNotificationSettingMutation, UpdateMySurveySummaryRecipientNotificationSettingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMySurveySummaryRecipientNotificationSettingMutation, UpdateMySurveySummaryRecipientNotificationSettingMutationVariables>(UpdateMySurveySummaryRecipientNotificationSettingDocument, options);
      }
export type UpdateMySurveySummaryRecipientNotificationSettingMutationHookResult = ReturnType<typeof useUpdateMySurveySummaryRecipientNotificationSettingMutation>;
export type UpdateMySurveySummaryRecipientNotificationSettingMutationResult = Apollo.MutationResult<UpdateMySurveySummaryRecipientNotificationSettingMutation>;
export type UpdateMySurveySummaryRecipientNotificationSettingMutationOptions = Apollo.BaseMutationOptions<UpdateMySurveySummaryRecipientNotificationSettingMutation, UpdateMySurveySummaryRecipientNotificationSettingMutationVariables>;
export const ListAdminSurveyTemplatesDocument = gql`
    query listAdminSurveyTemplates($offset: Int!, $limit: Int!, $sport: Sports, $status: SurveyStatuses, $name: String) {
  listAdminSurveyTemplates(
    input: {sport: $sport, status: $status, name: $name, pagination: {offset: $offset, limit: $limit}}
  ) {
    templates {
      id
      sport
      status
      orgId
      hasIncidentReport
      createdAt
      updatedAt
      templateInfo {
        name
        description
        audience
        sendDelayMinutes
      }
      sections {
        id
        name
        description
        order
        questions {
          id
          name
          description
          order
          isRequired
          canAddToKeyIncidents
          questionType
          options {
            id
            value
            order
          }
        }
        assessmentObject {
          id
          sport
          type
          classification
          name
        }
        createdAt
        updatedAt
      }
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListAdminSurveyTemplatesQuery__
 *
 * To run a query within a React component, call `useListAdminSurveyTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAdminSurveyTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAdminSurveyTemplatesQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      sport: // value for 'sport'
 *      status: // value for 'status'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useListAdminSurveyTemplatesQuery(baseOptions: Apollo.QueryHookOptions<ListAdminSurveyTemplatesQuery, ListAdminSurveyTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAdminSurveyTemplatesQuery, ListAdminSurveyTemplatesQueryVariables>(ListAdminSurveyTemplatesDocument, options);
      }
export function useListAdminSurveyTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAdminSurveyTemplatesQuery, ListAdminSurveyTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAdminSurveyTemplatesQuery, ListAdminSurveyTemplatesQueryVariables>(ListAdminSurveyTemplatesDocument, options);
        }
export type ListAdminSurveyTemplatesQueryHookResult = ReturnType<typeof useListAdminSurveyTemplatesQuery>;
export type ListAdminSurveyTemplatesLazyQueryHookResult = ReturnType<typeof useListAdminSurveyTemplatesLazyQuery>;
export type ListAdminSurveyTemplatesQueryResult = Apollo.QueryResult<ListAdminSurveyTemplatesQuery, ListAdminSurveyTemplatesQueryVariables>;
export const ListSurveyTemplatesForOrganizationDocument = gql`
    query listSurveyTemplatesForOrganization($offset: Int!, $limit: Int!, $orgId: String!, $adminFilter: SurveyTemplateAdminFilters!, $name: String, $status: SurveyStatuses) {
  listSurveyTemplatesForOrganization(
    input: {orgId: $orgId, adminFilter: $adminFilter, status: $status, name: $name, pagination: {offset: $offset, limit: $limit}}
  ) {
    templates {
      id
      sport
      status
      orgId
      hasIncidentReport
      createdAt
      updatedAt
      templateInfo {
        name
        description
        audience
        sendDelayMinutes
      }
      sections {
        id
        name
        description
        order
        questions {
          id
          name
          description
          order
          isRequired
          canAddToKeyIncidents
          questionType
          options {
            id
            value
            order
          }
        }
        assessmentObject {
          id
          sport
          type
          classification
          name
        }
        createdAt
        updatedAt
      }
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListSurveyTemplatesForOrganizationQuery__
 *
 * To run a query within a React component, call `useListSurveyTemplatesForOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSurveyTemplatesForOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSurveyTemplatesForOrganizationQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      orgId: // value for 'orgId'
 *      adminFilter: // value for 'adminFilter'
 *      name: // value for 'name'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useListSurveyTemplatesForOrganizationQuery(baseOptions: Apollo.QueryHookOptions<ListSurveyTemplatesForOrganizationQuery, ListSurveyTemplatesForOrganizationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListSurveyTemplatesForOrganizationQuery, ListSurveyTemplatesForOrganizationQueryVariables>(ListSurveyTemplatesForOrganizationDocument, options);
      }
export function useListSurveyTemplatesForOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListSurveyTemplatesForOrganizationQuery, ListSurveyTemplatesForOrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListSurveyTemplatesForOrganizationQuery, ListSurveyTemplatesForOrganizationQueryVariables>(ListSurveyTemplatesForOrganizationDocument, options);
        }
export type ListSurveyTemplatesForOrganizationQueryHookResult = ReturnType<typeof useListSurveyTemplatesForOrganizationQuery>;
export type ListSurveyTemplatesForOrganizationLazyQueryHookResult = ReturnType<typeof useListSurveyTemplatesForOrganizationLazyQuery>;
export type ListSurveyTemplatesForOrganizationQueryResult = Apollo.QueryResult<ListSurveyTemplatesForOrganizationQuery, ListSurveyTemplatesForOrganizationQueryVariables>;
export const CreateAdminSurveyTemplateDocument = gql`
    mutation createAdminSurveyTemplate($sport: Sports!, $name: String!, $description: String!, $audience: SurveyAudiences!, $sendDelayMinutes: Int!) {
  createAdminSurveyTemplate(
    input: {sport: $sport, name: $name, description: $description, audience: $audience, sendDelayMinutes: $sendDelayMinutes}
  ) {
    id
    sport
    status
    orgId
    hasIncidentReport
    createdAt
    updatedAt
    templateInfo {
      name
      description
      audience
      sendDelayMinutes
    }
    sections {
      id
      name
      description
      order
      questions {
        id
        name
        description
        order
        isRequired
        canAddToKeyIncidents
        questionType
        options {
          id
          value
          order
        }
      }
      assessmentObject {
        id
        sport
        type
        classification
        name
      }
      createdAt
      updatedAt
    }
  }
}
    `;
export type CreateAdminSurveyTemplateMutationFn = Apollo.MutationFunction<CreateAdminSurveyTemplateMutation, CreateAdminSurveyTemplateMutationVariables>;

/**
 * __useCreateAdminSurveyTemplateMutation__
 *
 * To run a mutation, you first call `useCreateAdminSurveyTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAdminSurveyTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAdminSurveyTemplateMutation, { data, loading, error }] = useCreateAdminSurveyTemplateMutation({
 *   variables: {
 *      sport: // value for 'sport'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      audience: // value for 'audience'
 *      sendDelayMinutes: // value for 'sendDelayMinutes'
 *   },
 * });
 */
export function useCreateAdminSurveyTemplateMutation(baseOptions?: Apollo.MutationHookOptions<CreateAdminSurveyTemplateMutation, CreateAdminSurveyTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAdminSurveyTemplateMutation, CreateAdminSurveyTemplateMutationVariables>(CreateAdminSurveyTemplateDocument, options);
      }
export type CreateAdminSurveyTemplateMutationHookResult = ReturnType<typeof useCreateAdminSurveyTemplateMutation>;
export type CreateAdminSurveyTemplateMutationResult = Apollo.MutationResult<CreateAdminSurveyTemplateMutation>;
export type CreateAdminSurveyTemplateMutationOptions = Apollo.BaseMutationOptions<CreateAdminSurveyTemplateMutation, CreateAdminSurveyTemplateMutationVariables>;
export const CreateOrganizationSurveyTemplateDocument = gql`
    mutation createOrganizationSurveyTemplate($orgId: String!, $name: String!, $description: String!, $audience: SurveyAudiences!, $sendDelayMinutes: Int!) {
  createOrganizationSurveyTemplate(
    input: {orgId: $orgId, name: $name, description: $description, audience: $audience, sendDelayMinutes: $sendDelayMinutes}
  ) {
    id
    sport
    status
    orgId
    hasIncidentReport
    createdAt
    updatedAt
    templateInfo {
      name
      description
      audience
      sendDelayMinutes
    }
    sections {
      id
      name
      description
      order
      questions {
        id
        name
        description
        order
        isRequired
        canAddToKeyIncidents
        questionType
        options {
          id
          value
          order
        }
      }
      assessmentObject {
        id
        sport
        type
        classification
        name
      }
      createdAt
      updatedAt
    }
  }
}
    `;
export type CreateOrganizationSurveyTemplateMutationFn = Apollo.MutationFunction<CreateOrganizationSurveyTemplateMutation, CreateOrganizationSurveyTemplateMutationVariables>;

/**
 * __useCreateOrganizationSurveyTemplateMutation__
 *
 * To run a mutation, you first call `useCreateOrganizationSurveyTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganizationSurveyTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganizationSurveyTemplateMutation, { data, loading, error }] = useCreateOrganizationSurveyTemplateMutation({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      audience: // value for 'audience'
 *      sendDelayMinutes: // value for 'sendDelayMinutes'
 *   },
 * });
 */
export function useCreateOrganizationSurveyTemplateMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrganizationSurveyTemplateMutation, CreateOrganizationSurveyTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrganizationSurveyTemplateMutation, CreateOrganizationSurveyTemplateMutationVariables>(CreateOrganizationSurveyTemplateDocument, options);
      }
export type CreateOrganizationSurveyTemplateMutationHookResult = ReturnType<typeof useCreateOrganizationSurveyTemplateMutation>;
export type CreateOrganizationSurveyTemplateMutationResult = Apollo.MutationResult<CreateOrganizationSurveyTemplateMutation>;
export type CreateOrganizationSurveyTemplateMutationOptions = Apollo.BaseMutationOptions<CreateOrganizationSurveyTemplateMutation, CreateOrganizationSurveyTemplateMutationVariables>;
export const GetSurveyTemplateByIdDocument = gql`
    query getSurveyTemplateById($id: String!) {
  getSurveyTemplateById(input: {id: $id}) {
    id
    sport
    status
    orgId
    hasIncidentReport
    createdAt
    updatedAt
    templateInfo {
      name
      description
      audience
      sendDelayMinutes
    }
    sections {
      id
      name
      description
      order
      questions {
        id
        name
        description
        order
        isRequired
        canAddToKeyIncidents
        questionType
        options {
          id
          value
          order
        }
      }
      assessmentObject {
        id
        sport
        type
        classification
        name
      }
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useGetSurveyTemplateByIdQuery__
 *
 * To run a query within a React component, call `useGetSurveyTemplateByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSurveyTemplateByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSurveyTemplateByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSurveyTemplateByIdQuery(baseOptions: Apollo.QueryHookOptions<GetSurveyTemplateByIdQuery, GetSurveyTemplateByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSurveyTemplateByIdQuery, GetSurveyTemplateByIdQueryVariables>(GetSurveyTemplateByIdDocument, options);
      }
export function useGetSurveyTemplateByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSurveyTemplateByIdQuery, GetSurveyTemplateByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSurveyTemplateByIdQuery, GetSurveyTemplateByIdQueryVariables>(GetSurveyTemplateByIdDocument, options);
        }
export type GetSurveyTemplateByIdQueryHookResult = ReturnType<typeof useGetSurveyTemplateByIdQuery>;
export type GetSurveyTemplateByIdLazyQueryHookResult = ReturnType<typeof useGetSurveyTemplateByIdLazyQuery>;
export type GetSurveyTemplateByIdQueryResult = Apollo.QueryResult<GetSurveyTemplateByIdQuery, GetSurveyTemplateByIdQueryVariables>;
export const DeleteSurveyTemplateDocument = gql`
    mutation deleteSurveyTemplate($id: String!) {
  deleteSurveyTemplate(input: {id: $id})
}
    `;
export type DeleteSurveyTemplateMutationFn = Apollo.MutationFunction<DeleteSurveyTemplateMutation, DeleteSurveyTemplateMutationVariables>;

/**
 * __useDeleteSurveyTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteSurveyTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSurveyTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSurveyTemplateMutation, { data, loading, error }] = useDeleteSurveyTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSurveyTemplateMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSurveyTemplateMutation, DeleteSurveyTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSurveyTemplateMutation, DeleteSurveyTemplateMutationVariables>(DeleteSurveyTemplateDocument, options);
      }
export type DeleteSurveyTemplateMutationHookResult = ReturnType<typeof useDeleteSurveyTemplateMutation>;
export type DeleteSurveyTemplateMutationResult = Apollo.MutationResult<DeleteSurveyTemplateMutation>;
export type DeleteSurveyTemplateMutationOptions = Apollo.BaseMutationOptions<DeleteSurveyTemplateMutation, DeleteSurveyTemplateMutationVariables>;
export const UpdateSurveyTemplateInfoDocument = gql`
    mutation updateSurveyTemplateInfo($id: String!, $name: String!, $description: String!, $audience: SurveyAudiences!, $sendDelayMinutes: Int!) {
  updateSurveyTemplateInfo(
    input: {id: $id, name: $name, description: $description, audience: $audience, sendDelayMinutes: $sendDelayMinutes}
  ) {
    name
    description
    audience
    sendDelayMinutes
  }
}
    `;
export type UpdateSurveyTemplateInfoMutationFn = Apollo.MutationFunction<UpdateSurveyTemplateInfoMutation, UpdateSurveyTemplateInfoMutationVariables>;

/**
 * __useUpdateSurveyTemplateInfoMutation__
 *
 * To run a mutation, you first call `useUpdateSurveyTemplateInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSurveyTemplateInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSurveyTemplateInfoMutation, { data, loading, error }] = useUpdateSurveyTemplateInfoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      audience: // value for 'audience'
 *      sendDelayMinutes: // value for 'sendDelayMinutes'
 *   },
 * });
 */
export function useUpdateSurveyTemplateInfoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSurveyTemplateInfoMutation, UpdateSurveyTemplateInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSurveyTemplateInfoMutation, UpdateSurveyTemplateInfoMutationVariables>(UpdateSurveyTemplateInfoDocument, options);
      }
export type UpdateSurveyTemplateInfoMutationHookResult = ReturnType<typeof useUpdateSurveyTemplateInfoMutation>;
export type UpdateSurveyTemplateInfoMutationResult = Apollo.MutationResult<UpdateSurveyTemplateInfoMutation>;
export type UpdateSurveyTemplateInfoMutationOptions = Apollo.BaseMutationOptions<UpdateSurveyTemplateInfoMutation, UpdateSurveyTemplateInfoMutationVariables>;
export const AddSurveyTemplateIncidentReportDocument = gql`
    mutation addSurveyTemplateIncidentReport($templateId: String!) {
  addSurveyTemplateIncidentReport(input: {templateId: $templateId})
}
    `;
export type AddSurveyTemplateIncidentReportMutationFn = Apollo.MutationFunction<AddSurveyTemplateIncidentReportMutation, AddSurveyTemplateIncidentReportMutationVariables>;

/**
 * __useAddSurveyTemplateIncidentReportMutation__
 *
 * To run a mutation, you first call `useAddSurveyTemplateIncidentReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSurveyTemplateIncidentReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSurveyTemplateIncidentReportMutation, { data, loading, error }] = useAddSurveyTemplateIncidentReportMutation({
 *   variables: {
 *      templateId: // value for 'templateId'
 *   },
 * });
 */
export function useAddSurveyTemplateIncidentReportMutation(baseOptions?: Apollo.MutationHookOptions<AddSurveyTemplateIncidentReportMutation, AddSurveyTemplateIncidentReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddSurveyTemplateIncidentReportMutation, AddSurveyTemplateIncidentReportMutationVariables>(AddSurveyTemplateIncidentReportDocument, options);
      }
export type AddSurveyTemplateIncidentReportMutationHookResult = ReturnType<typeof useAddSurveyTemplateIncidentReportMutation>;
export type AddSurveyTemplateIncidentReportMutationResult = Apollo.MutationResult<AddSurveyTemplateIncidentReportMutation>;
export type AddSurveyTemplateIncidentReportMutationOptions = Apollo.BaseMutationOptions<AddSurveyTemplateIncidentReportMutation, AddSurveyTemplateIncidentReportMutationVariables>;
export const DeleteSurveyTemplateIncidentReportDocument = gql`
    mutation deleteSurveyTemplateIncidentReport($templateId: String!) {
  deleteSurveyTemplateIncidentReport(input: {templateId: $templateId})
}
    `;
export type DeleteSurveyTemplateIncidentReportMutationFn = Apollo.MutationFunction<DeleteSurveyTemplateIncidentReportMutation, DeleteSurveyTemplateIncidentReportMutationVariables>;

/**
 * __useDeleteSurveyTemplateIncidentReportMutation__
 *
 * To run a mutation, you first call `useDeleteSurveyTemplateIncidentReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSurveyTemplateIncidentReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSurveyTemplateIncidentReportMutation, { data, loading, error }] = useDeleteSurveyTemplateIncidentReportMutation({
 *   variables: {
 *      templateId: // value for 'templateId'
 *   },
 * });
 */
export function useDeleteSurveyTemplateIncidentReportMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSurveyTemplateIncidentReportMutation, DeleteSurveyTemplateIncidentReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSurveyTemplateIncidentReportMutation, DeleteSurveyTemplateIncidentReportMutationVariables>(DeleteSurveyTemplateIncidentReportDocument, options);
      }
export type DeleteSurveyTemplateIncidentReportMutationHookResult = ReturnType<typeof useDeleteSurveyTemplateIncidentReportMutation>;
export type DeleteSurveyTemplateIncidentReportMutationResult = Apollo.MutationResult<DeleteSurveyTemplateIncidentReportMutation>;
export type DeleteSurveyTemplateIncidentReportMutationOptions = Apollo.BaseMutationOptions<DeleteSurveyTemplateIncidentReportMutation, DeleteSurveyTemplateIncidentReportMutationVariables>;
export const AddSurveyTemplateSectionDocument = gql`
    mutation addSurveyTemplateSection($templateId: String!, $assessmentObjectId: String!, $name: String!, $description: String!) {
  addSurveyTemplateSection(
    input: {id: $templateId, assessmentObjectId: $assessmentObjectId, name: $name, description: $description}
  ) {
    id
    name
    description
    order
    questions {
      id
      name
      description
      order
      isRequired
      canAddToKeyIncidents
      questionType
      options {
        id
        value
        order
      }
    }
    assessmentObject {
      id
      sport
      type
      classification
      name
    }
    createdAt
    updatedAt
  }
}
    `;
export type AddSurveyTemplateSectionMutationFn = Apollo.MutationFunction<AddSurveyTemplateSectionMutation, AddSurveyTemplateSectionMutationVariables>;

/**
 * __useAddSurveyTemplateSectionMutation__
 *
 * To run a mutation, you first call `useAddSurveyTemplateSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSurveyTemplateSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSurveyTemplateSectionMutation, { data, loading, error }] = useAddSurveyTemplateSectionMutation({
 *   variables: {
 *      templateId: // value for 'templateId'
 *      assessmentObjectId: // value for 'assessmentObjectId'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useAddSurveyTemplateSectionMutation(baseOptions?: Apollo.MutationHookOptions<AddSurveyTemplateSectionMutation, AddSurveyTemplateSectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddSurveyTemplateSectionMutation, AddSurveyTemplateSectionMutationVariables>(AddSurveyTemplateSectionDocument, options);
      }
export type AddSurveyTemplateSectionMutationHookResult = ReturnType<typeof useAddSurveyTemplateSectionMutation>;
export type AddSurveyTemplateSectionMutationResult = Apollo.MutationResult<AddSurveyTemplateSectionMutation>;
export type AddSurveyTemplateSectionMutationOptions = Apollo.BaseMutationOptions<AddSurveyTemplateSectionMutation, AddSurveyTemplateSectionMutationVariables>;
export const UpdateSurveyTemplateSectionDocument = gql`
    mutation updateSurveyTemplateSection($sectionId: String!, $assessmentObjectId: String!, $name: String!, $description: String!, $order: Int!) {
  updateSurveyTemplateSection(
    input: {sectionId: $sectionId, assessmentObjectId: $assessmentObjectId, name: $name, description: $description, order: $order}
  ) {
    id
    name
    description
    order
    questions {
      id
      name
      description
      order
      isRequired
      canAddToKeyIncidents
      questionType
      options {
        id
        value
        order
      }
    }
    assessmentObject {
      id
      sport
      type
      classification
      name
    }
    createdAt
    updatedAt
  }
}
    `;
export type UpdateSurveyTemplateSectionMutationFn = Apollo.MutationFunction<UpdateSurveyTemplateSectionMutation, UpdateSurveyTemplateSectionMutationVariables>;

/**
 * __useUpdateSurveyTemplateSectionMutation__
 *
 * To run a mutation, you first call `useUpdateSurveyTemplateSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSurveyTemplateSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSurveyTemplateSectionMutation, { data, loading, error }] = useUpdateSurveyTemplateSectionMutation({
 *   variables: {
 *      sectionId: // value for 'sectionId'
 *      assessmentObjectId: // value for 'assessmentObjectId'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useUpdateSurveyTemplateSectionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSurveyTemplateSectionMutation, UpdateSurveyTemplateSectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSurveyTemplateSectionMutation, UpdateSurveyTemplateSectionMutationVariables>(UpdateSurveyTemplateSectionDocument, options);
      }
export type UpdateSurveyTemplateSectionMutationHookResult = ReturnType<typeof useUpdateSurveyTemplateSectionMutation>;
export type UpdateSurveyTemplateSectionMutationResult = Apollo.MutationResult<UpdateSurveyTemplateSectionMutation>;
export type UpdateSurveyTemplateSectionMutationOptions = Apollo.BaseMutationOptions<UpdateSurveyTemplateSectionMutation, UpdateSurveyTemplateSectionMutationVariables>;
export const DeleteSurveyTemplateSectionDocument = gql`
    mutation deleteSurveyTemplateSection($sectionId: String!) {
  deleteSurveyTemplateSection(input: {sectionId: $sectionId})
}
    `;
export type DeleteSurveyTemplateSectionMutationFn = Apollo.MutationFunction<DeleteSurveyTemplateSectionMutation, DeleteSurveyTemplateSectionMutationVariables>;

/**
 * __useDeleteSurveyTemplateSectionMutation__
 *
 * To run a mutation, you first call `useDeleteSurveyTemplateSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSurveyTemplateSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSurveyTemplateSectionMutation, { data, loading, error }] = useDeleteSurveyTemplateSectionMutation({
 *   variables: {
 *      sectionId: // value for 'sectionId'
 *   },
 * });
 */
export function useDeleteSurveyTemplateSectionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSurveyTemplateSectionMutation, DeleteSurveyTemplateSectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSurveyTemplateSectionMutation, DeleteSurveyTemplateSectionMutationVariables>(DeleteSurveyTemplateSectionDocument, options);
      }
export type DeleteSurveyTemplateSectionMutationHookResult = ReturnType<typeof useDeleteSurveyTemplateSectionMutation>;
export type DeleteSurveyTemplateSectionMutationResult = Apollo.MutationResult<DeleteSurveyTemplateSectionMutation>;
export type DeleteSurveyTemplateSectionMutationOptions = Apollo.BaseMutationOptions<DeleteSurveyTemplateSectionMutation, DeleteSurveyTemplateSectionMutationVariables>;
export const AddSurveyTemplateSectionQuestionDocument = gql`
    mutation addSurveyTemplateSectionQuestion($sectionId: String!, $name: String!, $description: String!, $isRequired: Boolean!, $questionType: SurveyQuestionTypes!) {
  addSurveyTemplateSectionQuestion(
    input: {sectionId: $sectionId, name: $name, description: $description, isRequired: $isRequired, questionType: $questionType}
  ) {
    id
    name
    description
    order
    isRequired
    canAddToKeyIncidents
    questionType
    options {
      id
      value
      order
    }
    createdAt
    updatedAt
  }
}
    `;
export type AddSurveyTemplateSectionQuestionMutationFn = Apollo.MutationFunction<AddSurveyTemplateSectionQuestionMutation, AddSurveyTemplateSectionQuestionMutationVariables>;

/**
 * __useAddSurveyTemplateSectionQuestionMutation__
 *
 * To run a mutation, you first call `useAddSurveyTemplateSectionQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSurveyTemplateSectionQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSurveyTemplateSectionQuestionMutation, { data, loading, error }] = useAddSurveyTemplateSectionQuestionMutation({
 *   variables: {
 *      sectionId: // value for 'sectionId'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      isRequired: // value for 'isRequired'
 *      questionType: // value for 'questionType'
 *   },
 * });
 */
export function useAddSurveyTemplateSectionQuestionMutation(baseOptions?: Apollo.MutationHookOptions<AddSurveyTemplateSectionQuestionMutation, AddSurveyTemplateSectionQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddSurveyTemplateSectionQuestionMutation, AddSurveyTemplateSectionQuestionMutationVariables>(AddSurveyTemplateSectionQuestionDocument, options);
      }
export type AddSurveyTemplateSectionQuestionMutationHookResult = ReturnType<typeof useAddSurveyTemplateSectionQuestionMutation>;
export type AddSurveyTemplateSectionQuestionMutationResult = Apollo.MutationResult<AddSurveyTemplateSectionQuestionMutation>;
export type AddSurveyTemplateSectionQuestionMutationOptions = Apollo.BaseMutationOptions<AddSurveyTemplateSectionQuestionMutation, AddSurveyTemplateSectionQuestionMutationVariables>;
export const UpdateSurveyTemplateSectionQuestionDocument = gql`
    mutation updateSurveyTemplateSectionQuestion($questionId: String!, $name: String!, $description: String!, $isRequired: Boolean!, $order: Int!) {
  updateSurveyTemplateSectionQuestion(
    input: {questionId: $questionId, name: $name, description: $description, isRequired: $isRequired, order: $order}
  ) {
    id
    name
    description
    order
    isRequired
    canAddToKeyIncidents
    questionType
    options {
      id
      value
      order
    }
    createdAt
    updatedAt
  }
}
    `;
export type UpdateSurveyTemplateSectionQuestionMutationFn = Apollo.MutationFunction<UpdateSurveyTemplateSectionQuestionMutation, UpdateSurveyTemplateSectionQuestionMutationVariables>;

/**
 * __useUpdateSurveyTemplateSectionQuestionMutation__
 *
 * To run a mutation, you first call `useUpdateSurveyTemplateSectionQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSurveyTemplateSectionQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSurveyTemplateSectionQuestionMutation, { data, loading, error }] = useUpdateSurveyTemplateSectionQuestionMutation({
 *   variables: {
 *      questionId: // value for 'questionId'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      isRequired: // value for 'isRequired'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useUpdateSurveyTemplateSectionQuestionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSurveyTemplateSectionQuestionMutation, UpdateSurveyTemplateSectionQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSurveyTemplateSectionQuestionMutation, UpdateSurveyTemplateSectionQuestionMutationVariables>(UpdateSurveyTemplateSectionQuestionDocument, options);
      }
export type UpdateSurveyTemplateSectionQuestionMutationHookResult = ReturnType<typeof useUpdateSurveyTemplateSectionQuestionMutation>;
export type UpdateSurveyTemplateSectionQuestionMutationResult = Apollo.MutationResult<UpdateSurveyTemplateSectionQuestionMutation>;
export type UpdateSurveyTemplateSectionQuestionMutationOptions = Apollo.BaseMutationOptions<UpdateSurveyTemplateSectionQuestionMutation, UpdateSurveyTemplateSectionQuestionMutationVariables>;
export const SetSurveyTemplateSectionQuestionCanAddToKeyIncidentsDocument = gql`
    mutation setSurveyTemplateSectionQuestionCanAddToKeyIncidents($questionId: String!, $canAddToKeyIncidents: Boolean!) {
  setSurveyTemplateSectionQuestionCanAddToKeyIncidents(
    input: {questionId: $questionId, canAddToKeyIncidents: $canAddToKeyIncidents}
  ) {
    id
    name
    description
    order
    isRequired
    canAddToKeyIncidents
    questionType
    options {
      id
      value
      order
    }
    createdAt
    updatedAt
  }
}
    `;
export type SetSurveyTemplateSectionQuestionCanAddToKeyIncidentsMutationFn = Apollo.MutationFunction<SetSurveyTemplateSectionQuestionCanAddToKeyIncidentsMutation, SetSurveyTemplateSectionQuestionCanAddToKeyIncidentsMutationVariables>;

/**
 * __useSetSurveyTemplateSectionQuestionCanAddToKeyIncidentsMutation__
 *
 * To run a mutation, you first call `useSetSurveyTemplateSectionQuestionCanAddToKeyIncidentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetSurveyTemplateSectionQuestionCanAddToKeyIncidentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setSurveyTemplateSectionQuestionCanAddToKeyIncidentsMutation, { data, loading, error }] = useSetSurveyTemplateSectionQuestionCanAddToKeyIncidentsMutation({
 *   variables: {
 *      questionId: // value for 'questionId'
 *      canAddToKeyIncidents: // value for 'canAddToKeyIncidents'
 *   },
 * });
 */
export function useSetSurveyTemplateSectionQuestionCanAddToKeyIncidentsMutation(baseOptions?: Apollo.MutationHookOptions<SetSurveyTemplateSectionQuestionCanAddToKeyIncidentsMutation, SetSurveyTemplateSectionQuestionCanAddToKeyIncidentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetSurveyTemplateSectionQuestionCanAddToKeyIncidentsMutation, SetSurveyTemplateSectionQuestionCanAddToKeyIncidentsMutationVariables>(SetSurveyTemplateSectionQuestionCanAddToKeyIncidentsDocument, options);
      }
export type SetSurveyTemplateSectionQuestionCanAddToKeyIncidentsMutationHookResult = ReturnType<typeof useSetSurveyTemplateSectionQuestionCanAddToKeyIncidentsMutation>;
export type SetSurveyTemplateSectionQuestionCanAddToKeyIncidentsMutationResult = Apollo.MutationResult<SetSurveyTemplateSectionQuestionCanAddToKeyIncidentsMutation>;
export type SetSurveyTemplateSectionQuestionCanAddToKeyIncidentsMutationOptions = Apollo.BaseMutationOptions<SetSurveyTemplateSectionQuestionCanAddToKeyIncidentsMutation, SetSurveyTemplateSectionQuestionCanAddToKeyIncidentsMutationVariables>;
export const DeleteSurveyTemplateSectionQuestionDocument = gql`
    mutation deleteSurveyTemplateSectionQuestion($questionId: String!) {
  deleteSurveyTemplateSectionQuestion(input: {questionId: $questionId})
}
    `;
export type DeleteSurveyTemplateSectionQuestionMutationFn = Apollo.MutationFunction<DeleteSurveyTemplateSectionQuestionMutation, DeleteSurveyTemplateSectionQuestionMutationVariables>;

/**
 * __useDeleteSurveyTemplateSectionQuestionMutation__
 *
 * To run a mutation, you first call `useDeleteSurveyTemplateSectionQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSurveyTemplateSectionQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSurveyTemplateSectionQuestionMutation, { data, loading, error }] = useDeleteSurveyTemplateSectionQuestionMutation({
 *   variables: {
 *      questionId: // value for 'questionId'
 *   },
 * });
 */
export function useDeleteSurveyTemplateSectionQuestionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSurveyTemplateSectionQuestionMutation, DeleteSurveyTemplateSectionQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSurveyTemplateSectionQuestionMutation, DeleteSurveyTemplateSectionQuestionMutationVariables>(DeleteSurveyTemplateSectionQuestionDocument, options);
      }
export type DeleteSurveyTemplateSectionQuestionMutationHookResult = ReturnType<typeof useDeleteSurveyTemplateSectionQuestionMutation>;
export type DeleteSurveyTemplateSectionQuestionMutationResult = Apollo.MutationResult<DeleteSurveyTemplateSectionQuestionMutation>;
export type DeleteSurveyTemplateSectionQuestionMutationOptions = Apollo.BaseMutationOptions<DeleteSurveyTemplateSectionQuestionMutation, DeleteSurveyTemplateSectionQuestionMutationVariables>;
export const AddSurveyTemplateSectionQuestionOptionDocument = gql`
    mutation addSurveyTemplateSectionQuestionOption($questionId: String!, $value: String!) {
  addSurveyTemplateSectionQuestionOption(
    input: {questionId: $questionId, value: $value}
  ) {
    id
    value
    order
  }
}
    `;
export type AddSurveyTemplateSectionQuestionOptionMutationFn = Apollo.MutationFunction<AddSurveyTemplateSectionQuestionOptionMutation, AddSurveyTemplateSectionQuestionOptionMutationVariables>;

/**
 * __useAddSurveyTemplateSectionQuestionOptionMutation__
 *
 * To run a mutation, you first call `useAddSurveyTemplateSectionQuestionOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSurveyTemplateSectionQuestionOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSurveyTemplateSectionQuestionOptionMutation, { data, loading, error }] = useAddSurveyTemplateSectionQuestionOptionMutation({
 *   variables: {
 *      questionId: // value for 'questionId'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useAddSurveyTemplateSectionQuestionOptionMutation(baseOptions?: Apollo.MutationHookOptions<AddSurveyTemplateSectionQuestionOptionMutation, AddSurveyTemplateSectionQuestionOptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddSurveyTemplateSectionQuestionOptionMutation, AddSurveyTemplateSectionQuestionOptionMutationVariables>(AddSurveyTemplateSectionQuestionOptionDocument, options);
      }
export type AddSurveyTemplateSectionQuestionOptionMutationHookResult = ReturnType<typeof useAddSurveyTemplateSectionQuestionOptionMutation>;
export type AddSurveyTemplateSectionQuestionOptionMutationResult = Apollo.MutationResult<AddSurveyTemplateSectionQuestionOptionMutation>;
export type AddSurveyTemplateSectionQuestionOptionMutationOptions = Apollo.BaseMutationOptions<AddSurveyTemplateSectionQuestionOptionMutation, AddSurveyTemplateSectionQuestionOptionMutationVariables>;
export const DeleteSurveyTemplateSectionQuestionOptionDocument = gql`
    mutation deleteSurveyTemplateSectionQuestionOption($optionId: String!) {
  deleteSurveyTemplateSectionQuestionOption(input: {optionId: $optionId})
}
    `;
export type DeleteSurveyTemplateSectionQuestionOptionMutationFn = Apollo.MutationFunction<DeleteSurveyTemplateSectionQuestionOptionMutation, DeleteSurveyTemplateSectionQuestionOptionMutationVariables>;

/**
 * __useDeleteSurveyTemplateSectionQuestionOptionMutation__
 *
 * To run a mutation, you first call `useDeleteSurveyTemplateSectionQuestionOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSurveyTemplateSectionQuestionOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSurveyTemplateSectionQuestionOptionMutation, { data, loading, error }] = useDeleteSurveyTemplateSectionQuestionOptionMutation({
 *   variables: {
 *      optionId: // value for 'optionId'
 *   },
 * });
 */
export function useDeleteSurveyTemplateSectionQuestionOptionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSurveyTemplateSectionQuestionOptionMutation, DeleteSurveyTemplateSectionQuestionOptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSurveyTemplateSectionQuestionOptionMutation, DeleteSurveyTemplateSectionQuestionOptionMutationVariables>(DeleteSurveyTemplateSectionQuestionOptionDocument, options);
      }
export type DeleteSurveyTemplateSectionQuestionOptionMutationHookResult = ReturnType<typeof useDeleteSurveyTemplateSectionQuestionOptionMutation>;
export type DeleteSurveyTemplateSectionQuestionOptionMutationResult = Apollo.MutationResult<DeleteSurveyTemplateSectionQuestionOptionMutation>;
export type DeleteSurveyTemplateSectionQuestionOptionMutationOptions = Apollo.BaseMutationOptions<DeleteSurveyTemplateSectionQuestionOptionMutation, DeleteSurveyTemplateSectionQuestionOptionMutationVariables>;
export const UpdateSurveyTemplateSectionQuestionOptionDocument = gql`
    mutation updateSurveyTemplateSectionQuestionOption($optionId: String!, $value: String!, $order: Int!) {
  updateSurveyTemplateSectionQuestionOption(
    input: {optionId: $optionId, value: $value, order: $order}
  ) {
    id
    value
    order
  }
}
    `;
export type UpdateSurveyTemplateSectionQuestionOptionMutationFn = Apollo.MutationFunction<UpdateSurveyTemplateSectionQuestionOptionMutation, UpdateSurveyTemplateSectionQuestionOptionMutationVariables>;

/**
 * __useUpdateSurveyTemplateSectionQuestionOptionMutation__
 *
 * To run a mutation, you first call `useUpdateSurveyTemplateSectionQuestionOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSurveyTemplateSectionQuestionOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSurveyTemplateSectionQuestionOptionMutation, { data, loading, error }] = useUpdateSurveyTemplateSectionQuestionOptionMutation({
 *   variables: {
 *      optionId: // value for 'optionId'
 *      value: // value for 'value'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useUpdateSurveyTemplateSectionQuestionOptionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSurveyTemplateSectionQuestionOptionMutation, UpdateSurveyTemplateSectionQuestionOptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSurveyTemplateSectionQuestionOptionMutation, UpdateSurveyTemplateSectionQuestionOptionMutationVariables>(UpdateSurveyTemplateSectionQuestionOptionDocument, options);
      }
export type UpdateSurveyTemplateSectionQuestionOptionMutationHookResult = ReturnType<typeof useUpdateSurveyTemplateSectionQuestionOptionMutation>;
export type UpdateSurveyTemplateSectionQuestionOptionMutationResult = Apollo.MutationResult<UpdateSurveyTemplateSectionQuestionOptionMutation>;
export type UpdateSurveyTemplateSectionQuestionOptionMutationOptions = Apollo.BaseMutationOptions<UpdateSurveyTemplateSectionQuestionOptionMutation, UpdateSurveyTemplateSectionQuestionOptionMutationVariables>;
export const CreateSeasonSurveyDocument = gql`
    mutation createSeasonSurvey($orgId: String!, $seasonId: String!, $name: String!, $description: String!, $audience: SurveyAudiences!, $sendDelayMinutes: Int!, $reminderType: SurveyReminderTypes!, $dailyReminders: SurveyDailyRemindersInput, $levelReminders: SurveyLevelRemindersInput, $recipientAssessmentObjectId: String) {
  createSeasonSurvey(
    input: {orgId: $orgId, seasonId: $seasonId, name: $name, description: $description, audience: $audience, sendDelayMinutes: $sendDelayMinutes, reminderType: $reminderType, dailyReminders: $dailyReminders, levelReminders: $levelReminders, recipientAssessmentObjectId: $recipientAssessmentObjectId}
  ) {
    id
    sport
    status
    activatedAt
    createdAt
    updatedAt
    orgId
    seasonId
    hasCompetitionVerification
    hasIncidentReport
    additionsConfig {
      allowAdditionalIncidentReports
      allowAdditionalIncidentReportsCutoffDays
    }
    kiVideoIntegration {
      id
      orgId
      orgName
      orgAbbreviation
      name
      description
      status
      queueName
      createdAt
      updatedAt
    }
    recipients {
      id
      dynamicSurveyId
      surveyId
      assessmentObject {
        id
        sport
        type
        classification
        name
      }
    }
    keyIncidentRecipients {
      id
      orgMembershipId
      orgId
      orgName
      orgAbbreviation
      orgLogo
      title
      userId
      email
      firstName
      lastName
      profilePic
    }
    info {
      name
      description
      audience
      sendDelayMinutes
    }
    reminders {
      type
      dailyReminders {
        sendReminders
        sendReminderDays
        sendReminderCutoffDays
      }
      levelReminders {
        sendRemindersLevel1
        sendReminderDaysLevel1
        sendRemindersLevel2
        sendReminderDaysLevel2
        sendRemindersLevel3
        sendReminderDaysLevel3
      }
    }
    sections {
      id
      name
      description
      order
      questions {
        id
        name
        description
        order
        isRequired
        canAddToKeyIncidents
        questionType
        options {
          id
          value
          order
        }
      }
      createdAt
      updatedAt
      assessmentObject {
        id
        sport
        type
        classification
        name
      }
    }
  }
}
    `;
export type CreateSeasonSurveyMutationFn = Apollo.MutationFunction<CreateSeasonSurveyMutation, CreateSeasonSurveyMutationVariables>;

/**
 * __useCreateSeasonSurveyMutation__
 *
 * To run a mutation, you first call `useCreateSeasonSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSeasonSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSeasonSurveyMutation, { data, loading, error }] = useCreateSeasonSurveyMutation({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      seasonId: // value for 'seasonId'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      audience: // value for 'audience'
 *      sendDelayMinutes: // value for 'sendDelayMinutes'
 *      reminderType: // value for 'reminderType'
 *      dailyReminders: // value for 'dailyReminders'
 *      levelReminders: // value for 'levelReminders'
 *      recipientAssessmentObjectId: // value for 'recipientAssessmentObjectId'
 *   },
 * });
 */
export function useCreateSeasonSurveyMutation(baseOptions?: Apollo.MutationHookOptions<CreateSeasonSurveyMutation, CreateSeasonSurveyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSeasonSurveyMutation, CreateSeasonSurveyMutationVariables>(CreateSeasonSurveyDocument, options);
      }
export type CreateSeasonSurveyMutationHookResult = ReturnType<typeof useCreateSeasonSurveyMutation>;
export type CreateSeasonSurveyMutationResult = Apollo.MutationResult<CreateSeasonSurveyMutation>;
export type CreateSeasonSurveyMutationOptions = Apollo.BaseMutationOptions<CreateSeasonSurveyMutation, CreateSeasonSurveyMutationVariables>;
export const CreateSeasonSurveyFromTemplateDocument = gql`
    mutation createSeasonSurveyFromTemplate($templateId: String!, $orgId: String!, $seasonId: String!, $name: String!, $description: String!, $sendDelayMinutes: Int!, $reminderType: SurveyReminderTypes!, $dailyReminders: SurveyDailyRemindersInput, $levelReminders: SurveyLevelRemindersInput, $orgMembershipId: String, $recipientAssessmentObjectId: String) {
  createSeasonSurveyFromTemplate(
    input: {templateId: $templateId, orgId: $orgId, seasonId: $seasonId, name: $name, description: $description, sendDelayMinutes: $sendDelayMinutes, reminderType: $reminderType, dailyReminders: $dailyReminders, levelReminders: $levelReminders, orgMembershipId: $orgMembershipId, recipientAssessmentObjectId: $recipientAssessmentObjectId}
  ) {
    id
    sport
    status
    activatedAt
    createdAt
    updatedAt
    orgId
    seasonId
    hasCompetitionVerification
    hasIncidentReport
    additionsConfig {
      allowAdditionalIncidentReports
      allowAdditionalIncidentReportsCutoffDays
    }
    kiVideoIntegration {
      id
      orgId
      orgName
      orgAbbreviation
      name
      description
      status
      queueName
      createdAt
      updatedAt
    }
    recipients {
      id
      dynamicSurveyId
      surveyId
      assessmentObject {
        id
        sport
        type
        classification
        name
      }
    }
    keyIncidentRecipients {
      id
      orgMembershipId
      orgId
      orgName
      orgAbbreviation
      orgLogo
      title
      userId
      email
      firstName
      lastName
      profilePic
    }
    info {
      name
      description
      audience
      sendDelayMinutes
    }
    reminders {
      type
      dailyReminders {
        sendReminders
        sendReminderDays
        sendReminderCutoffDays
      }
      levelReminders {
        sendRemindersLevel1
        sendReminderDaysLevel1
        sendRemindersLevel2
        sendReminderDaysLevel2
        sendRemindersLevel3
        sendReminderDaysLevel3
      }
    }
    sections {
      id
      name
      description
      order
      questions {
        id
        name
        description
        order
        isRequired
        canAddToKeyIncidents
        questionType
        options {
          id
          value
          order
        }
      }
      createdAt
      updatedAt
      assessmentObject {
        id
        sport
        type
        classification
        name
      }
    }
  }
}
    `;
export type CreateSeasonSurveyFromTemplateMutationFn = Apollo.MutationFunction<CreateSeasonSurveyFromTemplateMutation, CreateSeasonSurveyFromTemplateMutationVariables>;

/**
 * __useCreateSeasonSurveyFromTemplateMutation__
 *
 * To run a mutation, you first call `useCreateSeasonSurveyFromTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSeasonSurveyFromTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSeasonSurveyFromTemplateMutation, { data, loading, error }] = useCreateSeasonSurveyFromTemplateMutation({
 *   variables: {
 *      templateId: // value for 'templateId'
 *      orgId: // value for 'orgId'
 *      seasonId: // value for 'seasonId'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      sendDelayMinutes: // value for 'sendDelayMinutes'
 *      reminderType: // value for 'reminderType'
 *      dailyReminders: // value for 'dailyReminders'
 *      levelReminders: // value for 'levelReminders'
 *      orgMembershipId: // value for 'orgMembershipId'
 *      recipientAssessmentObjectId: // value for 'recipientAssessmentObjectId'
 *   },
 * });
 */
export function useCreateSeasonSurveyFromTemplateMutation(baseOptions?: Apollo.MutationHookOptions<CreateSeasonSurveyFromTemplateMutation, CreateSeasonSurveyFromTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSeasonSurveyFromTemplateMutation, CreateSeasonSurveyFromTemplateMutationVariables>(CreateSeasonSurveyFromTemplateDocument, options);
      }
export type CreateSeasonSurveyFromTemplateMutationHookResult = ReturnType<typeof useCreateSeasonSurveyFromTemplateMutation>;
export type CreateSeasonSurveyFromTemplateMutationResult = Apollo.MutationResult<CreateSeasonSurveyFromTemplateMutation>;
export type CreateSeasonSurveyFromTemplateMutationOptions = Apollo.BaseMutationOptions<CreateSeasonSurveyFromTemplateMutation, CreateSeasonSurveyFromTemplateMutationVariables>;
export const GetSurveyByIdDocument = gql`
    query getSurveyById($id: String!) {
  getSurveyById(input: {id: $id}) {
    id
    sport
    status
    activatedAt
    createdAt
    updatedAt
    orgId
    seasonId
    hasCompetitionVerification
    hasIncidentReport
    additionsConfig {
      allowAdditionalIncidentReports
      allowAdditionalIncidentReportsCutoffDays
    }
    kiVideoIntegration {
      id
      orgId
      orgName
      orgAbbreviation
      name
      description
      status
      queueName
      createdAt
      updatedAt
    }
    recipients {
      id
      dynamicSurveyId
      surveyId
      assessmentObject {
        id
        sport
        type
        classification
        name
      }
    }
    keyIncidentRecipients {
      id
      orgMembershipId
      orgId
      orgName
      orgAbbreviation
      orgLogo
      title
      userId
      email
      firstName
      lastName
      profilePic
    }
    info {
      name
      description
      audience
      sendDelayMinutes
    }
    reminders {
      type
      dailyReminders {
        sendReminders
        sendReminderDays
        sendReminderCutoffDays
      }
      levelReminders {
        sendRemindersLevel1
        sendReminderDaysLevel1
        sendRemindersLevel2
        sendReminderDaysLevel2
        sendRemindersLevel3
        sendReminderDaysLevel3
      }
    }
    sections {
      id
      name
      description
      order
      questions {
        id
        name
        description
        order
        isRequired
        canAddToKeyIncidents
        questionType
        options {
          id
          value
          order
        }
      }
      createdAt
      updatedAt
      assessmentObject {
        id
        sport
        type
        classification
        name
      }
    }
  }
}
    `;

/**
 * __useGetSurveyByIdQuery__
 *
 * To run a query within a React component, call `useGetSurveyByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSurveyByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSurveyByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSurveyByIdQuery(baseOptions: Apollo.QueryHookOptions<GetSurveyByIdQuery, GetSurveyByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSurveyByIdQuery, GetSurveyByIdQueryVariables>(GetSurveyByIdDocument, options);
      }
export function useGetSurveyByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSurveyByIdQuery, GetSurveyByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSurveyByIdQuery, GetSurveyByIdQueryVariables>(GetSurveyByIdDocument, options);
        }
export type GetSurveyByIdQueryHookResult = ReturnType<typeof useGetSurveyByIdQuery>;
export type GetSurveyByIdLazyQueryHookResult = ReturnType<typeof useGetSurveyByIdLazyQuery>;
export type GetSurveyByIdQueryResult = Apollo.QueryResult<GetSurveyByIdQuery, GetSurveyByIdQueryVariables>;
export const ListSurveysForOrganizationDocument = gql`
    query listSurveysForOrganization($offset: Int!, $limit: Int!, $orgId: String!, $seasonId: String, $name: String, $status: SurveyStatuses, $audience: SurveyAudiences) {
  listSurveysForOrganization(
    input: {orgId: $orgId, seasonId: $seasonId, status: $status, name: $name, audience: $audience, pagination: {offset: $offset, limit: $limit}}
  ) {
    surveys {
      id
      sport
      status
      orgId
      hasCompetitionVerification
      hasIncidentReport
      additionsConfig {
        allowAdditionalIncidentReports
        allowAdditionalIncidentReportsCutoffDays
      }
      kiVideoIntegration {
        id
        orgId
        orgName
        orgAbbreviation
        name
        description
        status
        queueName
        createdAt
        updatedAt
      }
      recipients {
        id
        dynamicSurveyId
        surveyId
        assessmentObject {
          id
          sport
          type
          classification
          name
        }
      }
      keyIncidentRecipients {
        id
        orgMembershipId
        orgId
        orgName
        orgAbbreviation
        orgLogo
        title
        userId
        email
        firstName
        lastName
        profilePic
      }
      activatedAt
      createdAt
      updatedAt
      info {
        name
        description
        audience
        sendDelayMinutes
      }
      reminders {
        type
        dailyReminders {
          sendReminders
          sendReminderDays
          sendReminderCutoffDays
        }
        levelReminders {
          sendRemindersLevel1
          sendReminderDaysLevel1
          sendRemindersLevel2
          sendReminderDaysLevel2
          sendRemindersLevel3
          sendReminderDaysLevel3
        }
      }
      sections {
        id
        name
        description
        order
        questions {
          id
          name
          description
          order
          isRequired
          canAddToKeyIncidents
          questionType
          options {
            id
            value
            order
          }
        }
        createdAt
        updatedAt
        assessmentObject {
          id
          sport
          type
          classification
          name
        }
      }
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListSurveysForOrganizationQuery__
 *
 * To run a query within a React component, call `useListSurveysForOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSurveysForOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSurveysForOrganizationQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      orgId: // value for 'orgId'
 *      seasonId: // value for 'seasonId'
 *      name: // value for 'name'
 *      status: // value for 'status'
 *      audience: // value for 'audience'
 *   },
 * });
 */
export function useListSurveysForOrganizationQuery(baseOptions: Apollo.QueryHookOptions<ListSurveysForOrganizationQuery, ListSurveysForOrganizationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListSurveysForOrganizationQuery, ListSurveysForOrganizationQueryVariables>(ListSurveysForOrganizationDocument, options);
      }
export function useListSurveysForOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListSurveysForOrganizationQuery, ListSurveysForOrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListSurveysForOrganizationQuery, ListSurveysForOrganizationQueryVariables>(ListSurveysForOrganizationDocument, options);
        }
export type ListSurveysForOrganizationQueryHookResult = ReturnType<typeof useListSurveysForOrganizationQuery>;
export type ListSurveysForOrganizationLazyQueryHookResult = ReturnType<typeof useListSurveysForOrganizationLazyQuery>;
export type ListSurveysForOrganizationQueryResult = Apollo.QueryResult<ListSurveysForOrganizationQuery, ListSurveysForOrganizationQueryVariables>;
export const DeleteSurveyDocument = gql`
    mutation deleteSurvey($id: String!) {
  deleteSurvey(input: {id: $id})
}
    `;
export type DeleteSurveyMutationFn = Apollo.MutationFunction<DeleteSurveyMutation, DeleteSurveyMutationVariables>;

/**
 * __useDeleteSurveyMutation__
 *
 * To run a mutation, you first call `useDeleteSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSurveyMutation, { data, loading, error }] = useDeleteSurveyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSurveyMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSurveyMutation, DeleteSurveyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSurveyMutation, DeleteSurveyMutationVariables>(DeleteSurveyDocument, options);
      }
export type DeleteSurveyMutationHookResult = ReturnType<typeof useDeleteSurveyMutation>;
export type DeleteSurveyMutationResult = Apollo.MutationResult<DeleteSurveyMutation>;
export type DeleteSurveyMutationOptions = Apollo.BaseMutationOptions<DeleteSurveyMutation, DeleteSurveyMutationVariables>;
export const ActivateSurveyDocument = gql`
    mutation activateSurvey($id: String!) {
  activateSurvey(input: {id: $id})
}
    `;
export type ActivateSurveyMutationFn = Apollo.MutationFunction<ActivateSurveyMutation, ActivateSurveyMutationVariables>;

/**
 * __useActivateSurveyMutation__
 *
 * To run a mutation, you first call `useActivateSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateSurveyMutation, { data, loading, error }] = useActivateSurveyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useActivateSurveyMutation(baseOptions?: Apollo.MutationHookOptions<ActivateSurveyMutation, ActivateSurveyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateSurveyMutation, ActivateSurveyMutationVariables>(ActivateSurveyDocument, options);
      }
export type ActivateSurveyMutationHookResult = ReturnType<typeof useActivateSurveyMutation>;
export type ActivateSurveyMutationResult = Apollo.MutationResult<ActivateSurveyMutation>;
export type ActivateSurveyMutationOptions = Apollo.BaseMutationOptions<ActivateSurveyMutation, ActivateSurveyMutationVariables>;
export const DeactivateSurveyDocument = gql`
    mutation deactivateSurvey($id: String!) {
  deactivateSurvey(input: {id: $id})
}
    `;
export type DeactivateSurveyMutationFn = Apollo.MutationFunction<DeactivateSurveyMutation, DeactivateSurveyMutationVariables>;

/**
 * __useDeactivateSurveyMutation__
 *
 * To run a mutation, you first call `useDeactivateSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateSurveyMutation, { data, loading, error }] = useDeactivateSurveyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeactivateSurveyMutation(baseOptions?: Apollo.MutationHookOptions<DeactivateSurveyMutation, DeactivateSurveyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeactivateSurveyMutation, DeactivateSurveyMutationVariables>(DeactivateSurveyDocument, options);
      }
export type DeactivateSurveyMutationHookResult = ReturnType<typeof useDeactivateSurveyMutation>;
export type DeactivateSurveyMutationResult = Apollo.MutationResult<DeactivateSurveyMutation>;
export type DeactivateSurveyMutationOptions = Apollo.BaseMutationOptions<DeactivateSurveyMutation, DeactivateSurveyMutationVariables>;
export const UpdateSurveyInfoDocument = gql`
    mutation updateSurveyInfo($surveyId: String!, $name: String!, $description: String!, $audience: SurveyAudiences!, $sendDelayMinutes: Int!) {
  updateSurveyInfo(
    input: {surveyId: $surveyId, name: $name, description: $description, audience: $audience, sendDelayMinutes: $sendDelayMinutes}
  ) {
    name
    description
    audience
    sendDelayMinutes
  }
}
    `;
export type UpdateSurveyInfoMutationFn = Apollo.MutationFunction<UpdateSurveyInfoMutation, UpdateSurveyInfoMutationVariables>;

/**
 * __useUpdateSurveyInfoMutation__
 *
 * To run a mutation, you first call `useUpdateSurveyInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSurveyInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSurveyInfoMutation, { data, loading, error }] = useUpdateSurveyInfoMutation({
 *   variables: {
 *      surveyId: // value for 'surveyId'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      audience: // value for 'audience'
 *      sendDelayMinutes: // value for 'sendDelayMinutes'
 *   },
 * });
 */
export function useUpdateSurveyInfoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSurveyInfoMutation, UpdateSurveyInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSurveyInfoMutation, UpdateSurveyInfoMutationVariables>(UpdateSurveyInfoDocument, options);
      }
export type UpdateSurveyInfoMutationHookResult = ReturnType<typeof useUpdateSurveyInfoMutation>;
export type UpdateSurveyInfoMutationResult = Apollo.MutationResult<UpdateSurveyInfoMutation>;
export type UpdateSurveyInfoMutationOptions = Apollo.BaseMutationOptions<UpdateSurveyInfoMutation, UpdateSurveyInfoMutationVariables>;
export const SetSurveyCompetitionVerificationDocument = gql`
    mutation setSurveyCompetitionVerification($surveyId: String!, $enabled: Boolean!) {
  setSurveyCompetitionVerification(
    input: {surveyId: $surveyId, enabled: $enabled}
  ) {
    enabled
  }
}
    `;
export type SetSurveyCompetitionVerificationMutationFn = Apollo.MutationFunction<SetSurveyCompetitionVerificationMutation, SetSurveyCompetitionVerificationMutationVariables>;

/**
 * __useSetSurveyCompetitionVerificationMutation__
 *
 * To run a mutation, you first call `useSetSurveyCompetitionVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetSurveyCompetitionVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setSurveyCompetitionVerificationMutation, { data, loading, error }] = useSetSurveyCompetitionVerificationMutation({
 *   variables: {
 *      surveyId: // value for 'surveyId'
 *      enabled: // value for 'enabled'
 *   },
 * });
 */
export function useSetSurveyCompetitionVerificationMutation(baseOptions?: Apollo.MutationHookOptions<SetSurveyCompetitionVerificationMutation, SetSurveyCompetitionVerificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetSurveyCompetitionVerificationMutation, SetSurveyCompetitionVerificationMutationVariables>(SetSurveyCompetitionVerificationDocument, options);
      }
export type SetSurveyCompetitionVerificationMutationHookResult = ReturnType<typeof useSetSurveyCompetitionVerificationMutation>;
export type SetSurveyCompetitionVerificationMutationResult = Apollo.MutationResult<SetSurveyCompetitionVerificationMutation>;
export type SetSurveyCompetitionVerificationMutationOptions = Apollo.BaseMutationOptions<SetSurveyCompetitionVerificationMutation, SetSurveyCompetitionVerificationMutationVariables>;
export const UpdateSurveyRemindersDocument = gql`
    mutation updateSurveyReminders($surveyId: String!, $type: SurveyReminderTypes!, $dailyReminders: SurveyDailyRemindersInput, $levelReminders: SurveyLevelRemindersInput) {
  updateSurveyReminders(
    input: {surveyId: $surveyId, type: $type, dailyReminders: $dailyReminders, levelReminders: $levelReminders}
  ) {
    type
    dailyReminders {
      sendReminders
      sendReminderDays
      sendReminderCutoffDays
    }
    levelReminders {
      sendRemindersLevel1
      sendReminderDaysLevel1
      sendRemindersLevel2
      sendReminderDaysLevel2
      sendRemindersLevel3
      sendReminderDaysLevel3
    }
  }
}
    `;
export type UpdateSurveyRemindersMutationFn = Apollo.MutationFunction<UpdateSurveyRemindersMutation, UpdateSurveyRemindersMutationVariables>;

/**
 * __useUpdateSurveyRemindersMutation__
 *
 * To run a mutation, you first call `useUpdateSurveyRemindersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSurveyRemindersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSurveyRemindersMutation, { data, loading, error }] = useUpdateSurveyRemindersMutation({
 *   variables: {
 *      surveyId: // value for 'surveyId'
 *      type: // value for 'type'
 *      dailyReminders: // value for 'dailyReminders'
 *      levelReminders: // value for 'levelReminders'
 *   },
 * });
 */
export function useUpdateSurveyRemindersMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSurveyRemindersMutation, UpdateSurveyRemindersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSurveyRemindersMutation, UpdateSurveyRemindersMutationVariables>(UpdateSurveyRemindersDocument, options);
      }
export type UpdateSurveyRemindersMutationHookResult = ReturnType<typeof useUpdateSurveyRemindersMutation>;
export type UpdateSurveyRemindersMutationResult = Apollo.MutationResult<UpdateSurveyRemindersMutation>;
export type UpdateSurveyRemindersMutationOptions = Apollo.BaseMutationOptions<UpdateSurveyRemindersMutation, UpdateSurveyRemindersMutationVariables>;
export const ListReminderLevelRecipientsForSurveyDocument = gql`
    query listReminderLevelRecipientsForSurvey($offset: Int!, $limit: Int!, $surveyId: String!) {
  listReminderLevelRecipientsForSurvey(
    input: {surveyId: $surveyId, pagination: {offset: $offset, limit: $limit}}
  ) {
    recipients {
      id
      orgMembershipId
      orgId
      orgName
      orgAbbreviation
      orgLogo
      title
      userId
      email
      firstName
      lastName
      profilePic
      level1Recipient
      level2Recipient
      level3Recipient
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListReminderLevelRecipientsForSurveyQuery__
 *
 * To run a query within a React component, call `useListReminderLevelRecipientsForSurveyQuery` and pass it any options that fit your needs.
 * When your component renders, `useListReminderLevelRecipientsForSurveyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListReminderLevelRecipientsForSurveyQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      surveyId: // value for 'surveyId'
 *   },
 * });
 */
export function useListReminderLevelRecipientsForSurveyQuery(baseOptions: Apollo.QueryHookOptions<ListReminderLevelRecipientsForSurveyQuery, ListReminderLevelRecipientsForSurveyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListReminderLevelRecipientsForSurveyQuery, ListReminderLevelRecipientsForSurveyQueryVariables>(ListReminderLevelRecipientsForSurveyDocument, options);
      }
export function useListReminderLevelRecipientsForSurveyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListReminderLevelRecipientsForSurveyQuery, ListReminderLevelRecipientsForSurveyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListReminderLevelRecipientsForSurveyQuery, ListReminderLevelRecipientsForSurveyQueryVariables>(ListReminderLevelRecipientsForSurveyDocument, options);
        }
export type ListReminderLevelRecipientsForSurveyQueryHookResult = ReturnType<typeof useListReminderLevelRecipientsForSurveyQuery>;
export type ListReminderLevelRecipientsForSurveyLazyQueryHookResult = ReturnType<typeof useListReminderLevelRecipientsForSurveyLazyQuery>;
export type ListReminderLevelRecipientsForSurveyQueryResult = Apollo.QueryResult<ListReminderLevelRecipientsForSurveyQuery, ListReminderLevelRecipientsForSurveyQueryVariables>;
export const AddSurveyReminderLevelReportRecipientDocument = gql`
    mutation addSurveyReminderLevelReportRecipient($surveyId: String!, $orgMembershipId: String!, $level1Recipient: Boolean!, $level2Recipient: Boolean!, $level3Recipient: Boolean!) {
  addSurveyReminderLevelReportRecipient(
    input: {surveyId: $surveyId, orgMembershipId: $orgMembershipId, level1Recipient: $level1Recipient, level2Recipient: $level2Recipient, level3Recipient: $level3Recipient}
  ) {
    id
    orgMembershipId
    orgId
    orgName
    orgAbbreviation
    orgLogo
    title
    userId
    email
    firstName
    lastName
    profilePic
    level1Recipient
    level2Recipient
    level3Recipient
  }
}
    `;
export type AddSurveyReminderLevelReportRecipientMutationFn = Apollo.MutationFunction<AddSurveyReminderLevelReportRecipientMutation, AddSurveyReminderLevelReportRecipientMutationVariables>;

/**
 * __useAddSurveyReminderLevelReportRecipientMutation__
 *
 * To run a mutation, you first call `useAddSurveyReminderLevelReportRecipientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSurveyReminderLevelReportRecipientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSurveyReminderLevelReportRecipientMutation, { data, loading, error }] = useAddSurveyReminderLevelReportRecipientMutation({
 *   variables: {
 *      surveyId: // value for 'surveyId'
 *      orgMembershipId: // value for 'orgMembershipId'
 *      level1Recipient: // value for 'level1Recipient'
 *      level2Recipient: // value for 'level2Recipient'
 *      level3Recipient: // value for 'level3Recipient'
 *   },
 * });
 */
export function useAddSurveyReminderLevelReportRecipientMutation(baseOptions?: Apollo.MutationHookOptions<AddSurveyReminderLevelReportRecipientMutation, AddSurveyReminderLevelReportRecipientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddSurveyReminderLevelReportRecipientMutation, AddSurveyReminderLevelReportRecipientMutationVariables>(AddSurveyReminderLevelReportRecipientDocument, options);
      }
export type AddSurveyReminderLevelReportRecipientMutationHookResult = ReturnType<typeof useAddSurveyReminderLevelReportRecipientMutation>;
export type AddSurveyReminderLevelReportRecipientMutationResult = Apollo.MutationResult<AddSurveyReminderLevelReportRecipientMutation>;
export type AddSurveyReminderLevelReportRecipientMutationOptions = Apollo.BaseMutationOptions<AddSurveyReminderLevelReportRecipientMutation, AddSurveyReminderLevelReportRecipientMutationVariables>;
export const UpdateSurveyReminderLevelRecipientDocument = gql`
    mutation updateSurveyReminderLevelRecipient($recipientId: String!, $level1Recipient: Boolean!, $level2Recipient: Boolean!, $level3Recipient: Boolean!) {
  updateSurveyReminderLevelRecipient(
    input: {recipientId: $recipientId, level1Recipient: $level1Recipient, level2Recipient: $level2Recipient, level3Recipient: $level3Recipient}
  ) {
    id
    orgMembershipId
    orgId
    orgName
    orgAbbreviation
    orgLogo
    title
    userId
    email
    firstName
    lastName
    profilePic
    level1Recipient
    level2Recipient
    level3Recipient
  }
}
    `;
export type UpdateSurveyReminderLevelRecipientMutationFn = Apollo.MutationFunction<UpdateSurveyReminderLevelRecipientMutation, UpdateSurveyReminderLevelRecipientMutationVariables>;

/**
 * __useUpdateSurveyReminderLevelRecipientMutation__
 *
 * To run a mutation, you first call `useUpdateSurveyReminderLevelRecipientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSurveyReminderLevelRecipientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSurveyReminderLevelRecipientMutation, { data, loading, error }] = useUpdateSurveyReminderLevelRecipientMutation({
 *   variables: {
 *      recipientId: // value for 'recipientId'
 *      level1Recipient: // value for 'level1Recipient'
 *      level2Recipient: // value for 'level2Recipient'
 *      level3Recipient: // value for 'level3Recipient'
 *   },
 * });
 */
export function useUpdateSurveyReminderLevelRecipientMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSurveyReminderLevelRecipientMutation, UpdateSurveyReminderLevelRecipientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSurveyReminderLevelRecipientMutation, UpdateSurveyReminderLevelRecipientMutationVariables>(UpdateSurveyReminderLevelRecipientDocument, options);
      }
export type UpdateSurveyReminderLevelRecipientMutationHookResult = ReturnType<typeof useUpdateSurveyReminderLevelRecipientMutation>;
export type UpdateSurveyReminderLevelRecipientMutationResult = Apollo.MutationResult<UpdateSurveyReminderLevelRecipientMutation>;
export type UpdateSurveyReminderLevelRecipientMutationOptions = Apollo.BaseMutationOptions<UpdateSurveyReminderLevelRecipientMutation, UpdateSurveyReminderLevelRecipientMutationVariables>;
export const DeleteSurveyReminderLevelRecipientDocument = gql`
    mutation deleteSurveyReminderLevelRecipient($recipientId: String!) {
  deleteSurveyReminderLevelRecipient(input: {recipientId: $recipientId})
}
    `;
export type DeleteSurveyReminderLevelRecipientMutationFn = Apollo.MutationFunction<DeleteSurveyReminderLevelRecipientMutation, DeleteSurveyReminderLevelRecipientMutationVariables>;

/**
 * __useDeleteSurveyReminderLevelRecipientMutation__
 *
 * To run a mutation, you first call `useDeleteSurveyReminderLevelRecipientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSurveyReminderLevelRecipientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSurveyReminderLevelRecipientMutation, { data, loading, error }] = useDeleteSurveyReminderLevelRecipientMutation({
 *   variables: {
 *      recipientId: // value for 'recipientId'
 *   },
 * });
 */
export function useDeleteSurveyReminderLevelRecipientMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSurveyReminderLevelRecipientMutation, DeleteSurveyReminderLevelRecipientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSurveyReminderLevelRecipientMutation, DeleteSurveyReminderLevelRecipientMutationVariables>(DeleteSurveyReminderLevelRecipientDocument, options);
      }
export type DeleteSurveyReminderLevelRecipientMutationHookResult = ReturnType<typeof useDeleteSurveyReminderLevelRecipientMutation>;
export type DeleteSurveyReminderLevelRecipientMutationResult = Apollo.MutationResult<DeleteSurveyReminderLevelRecipientMutation>;
export type DeleteSurveyReminderLevelRecipientMutationOptions = Apollo.BaseMutationOptions<DeleteSurveyReminderLevelRecipientMutation, DeleteSurveyReminderLevelRecipientMutationVariables>;
export const AddSurveyAssessmentObjectRecipientDocument = gql`
    mutation addSurveyAssessmentObjectRecipient($surveyId: String!, $assessmentObjectId: String!) {
  addSurveyAssessmentObjectRecipient(
    input: {surveyId: $surveyId, assessmentObjectId: $assessmentObjectId}
  ) {
    id
    dynamicSurveyId
    surveyId
    assessmentObject {
      id
      sport
      type
      classification
      name
    }
  }
}
    `;
export type AddSurveyAssessmentObjectRecipientMutationFn = Apollo.MutationFunction<AddSurveyAssessmentObjectRecipientMutation, AddSurveyAssessmentObjectRecipientMutationVariables>;

/**
 * __useAddSurveyAssessmentObjectRecipientMutation__
 *
 * To run a mutation, you first call `useAddSurveyAssessmentObjectRecipientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSurveyAssessmentObjectRecipientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSurveyAssessmentObjectRecipientMutation, { data, loading, error }] = useAddSurveyAssessmentObjectRecipientMutation({
 *   variables: {
 *      surveyId: // value for 'surveyId'
 *      assessmentObjectId: // value for 'assessmentObjectId'
 *   },
 * });
 */
export function useAddSurveyAssessmentObjectRecipientMutation(baseOptions?: Apollo.MutationHookOptions<AddSurveyAssessmentObjectRecipientMutation, AddSurveyAssessmentObjectRecipientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddSurveyAssessmentObjectRecipientMutation, AddSurveyAssessmentObjectRecipientMutationVariables>(AddSurveyAssessmentObjectRecipientDocument, options);
      }
export type AddSurveyAssessmentObjectRecipientMutationHookResult = ReturnType<typeof useAddSurveyAssessmentObjectRecipientMutation>;
export type AddSurveyAssessmentObjectRecipientMutationResult = Apollo.MutationResult<AddSurveyAssessmentObjectRecipientMutation>;
export type AddSurveyAssessmentObjectRecipientMutationOptions = Apollo.BaseMutationOptions<AddSurveyAssessmentObjectRecipientMutation, AddSurveyAssessmentObjectRecipientMutationVariables>;
export const DeleteSurveyAssessmentObjectRecipientDocument = gql`
    mutation deleteSurveyAssessmentObjectRecipient($recipientId: String!) {
  deleteSurveyAssessmentObjectRecipient(input: {recipientId: $recipientId})
}
    `;
export type DeleteSurveyAssessmentObjectRecipientMutationFn = Apollo.MutationFunction<DeleteSurveyAssessmentObjectRecipientMutation, DeleteSurveyAssessmentObjectRecipientMutationVariables>;

/**
 * __useDeleteSurveyAssessmentObjectRecipientMutation__
 *
 * To run a mutation, you first call `useDeleteSurveyAssessmentObjectRecipientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSurveyAssessmentObjectRecipientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSurveyAssessmentObjectRecipientMutation, { data, loading, error }] = useDeleteSurveyAssessmentObjectRecipientMutation({
 *   variables: {
 *      recipientId: // value for 'recipientId'
 *   },
 * });
 */
export function useDeleteSurveyAssessmentObjectRecipientMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSurveyAssessmentObjectRecipientMutation, DeleteSurveyAssessmentObjectRecipientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSurveyAssessmentObjectRecipientMutation, DeleteSurveyAssessmentObjectRecipientMutationVariables>(DeleteSurveyAssessmentObjectRecipientDocument, options);
      }
export type DeleteSurveyAssessmentObjectRecipientMutationHookResult = ReturnType<typeof useDeleteSurveyAssessmentObjectRecipientMutation>;
export type DeleteSurveyAssessmentObjectRecipientMutationResult = Apollo.MutationResult<DeleteSurveyAssessmentObjectRecipientMutation>;
export type DeleteSurveyAssessmentObjectRecipientMutationOptions = Apollo.BaseMutationOptions<DeleteSurveyAssessmentObjectRecipientMutation, DeleteSurveyAssessmentObjectRecipientMutationVariables>;
export const ListAvailableAssessmentObjectsForSurveysRecipientsDocument = gql`
    query listAvailableAssessmentObjectsForSurveysRecipients($offset: Int!, $limit: Int!, $surveyId: String!) {
  listAvailableAssessmentObjectsForSurveysRecipients(
    input: {surveyId: $surveyId, pagination: {offset: $offset, limit: $limit}}
  ) {
    assessmentObjects {
      id
      sport
      type
      classification
      name
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListAvailableAssessmentObjectsForSurveysRecipientsQuery__
 *
 * To run a query within a React component, call `useListAvailableAssessmentObjectsForSurveysRecipientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAvailableAssessmentObjectsForSurveysRecipientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAvailableAssessmentObjectsForSurveysRecipientsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      surveyId: // value for 'surveyId'
 *   },
 * });
 */
export function useListAvailableAssessmentObjectsForSurveysRecipientsQuery(baseOptions: Apollo.QueryHookOptions<ListAvailableAssessmentObjectsForSurveysRecipientsQuery, ListAvailableAssessmentObjectsForSurveysRecipientsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAvailableAssessmentObjectsForSurveysRecipientsQuery, ListAvailableAssessmentObjectsForSurveysRecipientsQueryVariables>(ListAvailableAssessmentObjectsForSurveysRecipientsDocument, options);
      }
export function useListAvailableAssessmentObjectsForSurveysRecipientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAvailableAssessmentObjectsForSurveysRecipientsQuery, ListAvailableAssessmentObjectsForSurveysRecipientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAvailableAssessmentObjectsForSurveysRecipientsQuery, ListAvailableAssessmentObjectsForSurveysRecipientsQueryVariables>(ListAvailableAssessmentObjectsForSurveysRecipientsDocument, options);
        }
export type ListAvailableAssessmentObjectsForSurveysRecipientsQueryHookResult = ReturnType<typeof useListAvailableAssessmentObjectsForSurveysRecipientsQuery>;
export type ListAvailableAssessmentObjectsForSurveysRecipientsLazyQueryHookResult = ReturnType<typeof useListAvailableAssessmentObjectsForSurveysRecipientsLazyQuery>;
export type ListAvailableAssessmentObjectsForSurveysRecipientsQueryResult = Apollo.QueryResult<ListAvailableAssessmentObjectsForSurveysRecipientsQuery, ListAvailableAssessmentObjectsForSurveysRecipientsQueryVariables>;
export const AddSurveyIncidentReportDocument = gql`
    mutation addSurveyIncidentReport($surveyId: String!, $orgMembershipId: String!) {
  addSurveyIncidentReport(
    input: {surveyId: $surveyId, orgMembershipId: $orgMembershipId}
  ) {
    id
    orgMembershipId
    orgId
    orgName
    orgAbbreviation
    orgLogo
    title
    userId
    email
    firstName
    lastName
    profilePic
  }
}
    `;
export type AddSurveyIncidentReportMutationFn = Apollo.MutationFunction<AddSurveyIncidentReportMutation, AddSurveyIncidentReportMutationVariables>;

/**
 * __useAddSurveyIncidentReportMutation__
 *
 * To run a mutation, you first call `useAddSurveyIncidentReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSurveyIncidentReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSurveyIncidentReportMutation, { data, loading, error }] = useAddSurveyIncidentReportMutation({
 *   variables: {
 *      surveyId: // value for 'surveyId'
 *      orgMembershipId: // value for 'orgMembershipId'
 *   },
 * });
 */
export function useAddSurveyIncidentReportMutation(baseOptions?: Apollo.MutationHookOptions<AddSurveyIncidentReportMutation, AddSurveyIncidentReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddSurveyIncidentReportMutation, AddSurveyIncidentReportMutationVariables>(AddSurveyIncidentReportDocument, options);
      }
export type AddSurveyIncidentReportMutationHookResult = ReturnType<typeof useAddSurveyIncidentReportMutation>;
export type AddSurveyIncidentReportMutationResult = Apollo.MutationResult<AddSurveyIncidentReportMutation>;
export type AddSurveyIncidentReportMutationOptions = Apollo.BaseMutationOptions<AddSurveyIncidentReportMutation, AddSurveyIncidentReportMutationVariables>;
export const DeleteSurveyIncidentReportDocument = gql`
    mutation deleteSurveyIncidentReport($surveyId: String!) {
  deleteSurveyIncidentReport(input: {surveyId: $surveyId})
}
    `;
export type DeleteSurveyIncidentReportMutationFn = Apollo.MutationFunction<DeleteSurveyIncidentReportMutation, DeleteSurveyIncidentReportMutationVariables>;

/**
 * __useDeleteSurveyIncidentReportMutation__
 *
 * To run a mutation, you first call `useDeleteSurveyIncidentReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSurveyIncidentReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSurveyIncidentReportMutation, { data, loading, error }] = useDeleteSurveyIncidentReportMutation({
 *   variables: {
 *      surveyId: // value for 'surveyId'
 *   },
 * });
 */
export function useDeleteSurveyIncidentReportMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSurveyIncidentReportMutation, DeleteSurveyIncidentReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSurveyIncidentReportMutation, DeleteSurveyIncidentReportMutationVariables>(DeleteSurveyIncidentReportDocument, options);
      }
export type DeleteSurveyIncidentReportMutationHookResult = ReturnType<typeof useDeleteSurveyIncidentReportMutation>;
export type DeleteSurveyIncidentReportMutationResult = Apollo.MutationResult<DeleteSurveyIncidentReportMutation>;
export type DeleteSurveyIncidentReportMutationOptions = Apollo.BaseMutationOptions<DeleteSurveyIncidentReportMutation, DeleteSurveyIncidentReportMutationVariables>;
export const AddSurveyIncidentReportRecipientDocument = gql`
    mutation addSurveyIncidentReportRecipient($surveyId: String!, $orgMembershipId: String!) {
  addSurveyIncidentReportRecipient(
    input: {surveyId: $surveyId, orgMembershipId: $orgMembershipId}
  ) {
    id
    orgMembershipId
    orgId
    orgName
    orgAbbreviation
    orgLogo
    title
    userId
    email
    firstName
    lastName
    profilePic
  }
}
    `;
export type AddSurveyIncidentReportRecipientMutationFn = Apollo.MutationFunction<AddSurveyIncidentReportRecipientMutation, AddSurveyIncidentReportRecipientMutationVariables>;

/**
 * __useAddSurveyIncidentReportRecipientMutation__
 *
 * To run a mutation, you first call `useAddSurveyIncidentReportRecipientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSurveyIncidentReportRecipientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSurveyIncidentReportRecipientMutation, { data, loading, error }] = useAddSurveyIncidentReportRecipientMutation({
 *   variables: {
 *      surveyId: // value for 'surveyId'
 *      orgMembershipId: // value for 'orgMembershipId'
 *   },
 * });
 */
export function useAddSurveyIncidentReportRecipientMutation(baseOptions?: Apollo.MutationHookOptions<AddSurveyIncidentReportRecipientMutation, AddSurveyIncidentReportRecipientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddSurveyIncidentReportRecipientMutation, AddSurveyIncidentReportRecipientMutationVariables>(AddSurveyIncidentReportRecipientDocument, options);
      }
export type AddSurveyIncidentReportRecipientMutationHookResult = ReturnType<typeof useAddSurveyIncidentReportRecipientMutation>;
export type AddSurveyIncidentReportRecipientMutationResult = Apollo.MutationResult<AddSurveyIncidentReportRecipientMutation>;
export type AddSurveyIncidentReportRecipientMutationOptions = Apollo.BaseMutationOptions<AddSurveyIncidentReportRecipientMutation, AddSurveyIncidentReportRecipientMutationVariables>;
export const DeleteSurveyIncidentReportRecipientDocument = gql`
    mutation deleteSurveyIncidentReportRecipient($recipientId: String!) {
  deleteSurveyIncidentReportRecipient(input: {recipientId: $recipientId})
}
    `;
export type DeleteSurveyIncidentReportRecipientMutationFn = Apollo.MutationFunction<DeleteSurveyIncidentReportRecipientMutation, DeleteSurveyIncidentReportRecipientMutationVariables>;

/**
 * __useDeleteSurveyIncidentReportRecipientMutation__
 *
 * To run a mutation, you first call `useDeleteSurveyIncidentReportRecipientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSurveyIncidentReportRecipientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSurveyIncidentReportRecipientMutation, { data, loading, error }] = useDeleteSurveyIncidentReportRecipientMutation({
 *   variables: {
 *      recipientId: // value for 'recipientId'
 *   },
 * });
 */
export function useDeleteSurveyIncidentReportRecipientMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSurveyIncidentReportRecipientMutation, DeleteSurveyIncidentReportRecipientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSurveyIncidentReportRecipientMutation, DeleteSurveyIncidentReportRecipientMutationVariables>(DeleteSurveyIncidentReportRecipientDocument, options);
      }
export type DeleteSurveyIncidentReportRecipientMutationHookResult = ReturnType<typeof useDeleteSurveyIncidentReportRecipientMutation>;
export type DeleteSurveyIncidentReportRecipientMutationResult = Apollo.MutationResult<DeleteSurveyIncidentReportRecipientMutation>;
export type DeleteSurveyIncidentReportRecipientMutationOptions = Apollo.BaseMutationOptions<DeleteSurveyIncidentReportRecipientMutation, DeleteSurveyIncidentReportRecipientMutationVariables>;
export const AddKeyIncidentVideoIntegrationToSurveyDocument = gql`
    mutation addKeyIncidentVideoIntegrationToSurvey($surveyId: String!, $integrationId: String!) {
  addKeyIncidentVideoIntegrationToSurvey(
    input: {surveyId: $surveyId, integrationId: $integrationId}
  ) {
    id
    orgId
    orgName
    orgAbbreviation
    name
    description
    status
    queueName
    createdAt
    updatedAt
  }
}
    `;
export type AddKeyIncidentVideoIntegrationToSurveyMutationFn = Apollo.MutationFunction<AddKeyIncidentVideoIntegrationToSurveyMutation, AddKeyIncidentVideoIntegrationToSurveyMutationVariables>;

/**
 * __useAddKeyIncidentVideoIntegrationToSurveyMutation__
 *
 * To run a mutation, you first call `useAddKeyIncidentVideoIntegrationToSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddKeyIncidentVideoIntegrationToSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addKeyIncidentVideoIntegrationToSurveyMutation, { data, loading, error }] = useAddKeyIncidentVideoIntegrationToSurveyMutation({
 *   variables: {
 *      surveyId: // value for 'surveyId'
 *      integrationId: // value for 'integrationId'
 *   },
 * });
 */
export function useAddKeyIncidentVideoIntegrationToSurveyMutation(baseOptions?: Apollo.MutationHookOptions<AddKeyIncidentVideoIntegrationToSurveyMutation, AddKeyIncidentVideoIntegrationToSurveyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddKeyIncidentVideoIntegrationToSurveyMutation, AddKeyIncidentVideoIntegrationToSurveyMutationVariables>(AddKeyIncidentVideoIntegrationToSurveyDocument, options);
      }
export type AddKeyIncidentVideoIntegrationToSurveyMutationHookResult = ReturnType<typeof useAddKeyIncidentVideoIntegrationToSurveyMutation>;
export type AddKeyIncidentVideoIntegrationToSurveyMutationResult = Apollo.MutationResult<AddKeyIncidentVideoIntegrationToSurveyMutation>;
export type AddKeyIncidentVideoIntegrationToSurveyMutationOptions = Apollo.BaseMutationOptions<AddKeyIncidentVideoIntegrationToSurveyMutation, AddKeyIncidentVideoIntegrationToSurveyMutationVariables>;
export const RemoveKeyIncidentVideoIntegrationFromSurveyDocument = gql`
    mutation removeKeyIncidentVideoIntegrationFromSurvey($surveyId: String!) {
  removeKeyIncidentVideoIntegrationFromSurvey(input: {surveyId: $surveyId})
}
    `;
export type RemoveKeyIncidentVideoIntegrationFromSurveyMutationFn = Apollo.MutationFunction<RemoveKeyIncidentVideoIntegrationFromSurveyMutation, RemoveKeyIncidentVideoIntegrationFromSurveyMutationVariables>;

/**
 * __useRemoveKeyIncidentVideoIntegrationFromSurveyMutation__
 *
 * To run a mutation, you first call `useRemoveKeyIncidentVideoIntegrationFromSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveKeyIncidentVideoIntegrationFromSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeKeyIncidentVideoIntegrationFromSurveyMutation, { data, loading, error }] = useRemoveKeyIncidentVideoIntegrationFromSurveyMutation({
 *   variables: {
 *      surveyId: // value for 'surveyId'
 *   },
 * });
 */
export function useRemoveKeyIncidentVideoIntegrationFromSurveyMutation(baseOptions?: Apollo.MutationHookOptions<RemoveKeyIncidentVideoIntegrationFromSurveyMutation, RemoveKeyIncidentVideoIntegrationFromSurveyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveKeyIncidentVideoIntegrationFromSurveyMutation, RemoveKeyIncidentVideoIntegrationFromSurveyMutationVariables>(RemoveKeyIncidentVideoIntegrationFromSurveyDocument, options);
      }
export type RemoveKeyIncidentVideoIntegrationFromSurveyMutationHookResult = ReturnType<typeof useRemoveKeyIncidentVideoIntegrationFromSurveyMutation>;
export type RemoveKeyIncidentVideoIntegrationFromSurveyMutationResult = Apollo.MutationResult<RemoveKeyIncidentVideoIntegrationFromSurveyMutation>;
export type RemoveKeyIncidentVideoIntegrationFromSurveyMutationOptions = Apollo.BaseMutationOptions<RemoveKeyIncidentVideoIntegrationFromSurveyMutation, RemoveKeyIncidentVideoIntegrationFromSurveyMutationVariables>;
export const SetSurveyAllowAdditionalIncidentReportsDocument = gql`
    mutation setSurveyAllowAdditionalIncidentReports($surveyId: String!, $allowAdditions: Boolean!) {
  setSurveyAllowAdditionalIncidentReports(
    input: {surveyId: $surveyId, allowAdditions: $allowAdditions}
  ) {
    allowAdditionalIncidentReports
    allowAdditionalIncidentReportsCutoffDays
  }
}
    `;
export type SetSurveyAllowAdditionalIncidentReportsMutationFn = Apollo.MutationFunction<SetSurveyAllowAdditionalIncidentReportsMutation, SetSurveyAllowAdditionalIncidentReportsMutationVariables>;

/**
 * __useSetSurveyAllowAdditionalIncidentReportsMutation__
 *
 * To run a mutation, you first call `useSetSurveyAllowAdditionalIncidentReportsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetSurveyAllowAdditionalIncidentReportsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setSurveyAllowAdditionalIncidentReportsMutation, { data, loading, error }] = useSetSurveyAllowAdditionalIncidentReportsMutation({
 *   variables: {
 *      surveyId: // value for 'surveyId'
 *      allowAdditions: // value for 'allowAdditions'
 *   },
 * });
 */
export function useSetSurveyAllowAdditionalIncidentReportsMutation(baseOptions?: Apollo.MutationHookOptions<SetSurveyAllowAdditionalIncidentReportsMutation, SetSurveyAllowAdditionalIncidentReportsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetSurveyAllowAdditionalIncidentReportsMutation, SetSurveyAllowAdditionalIncidentReportsMutationVariables>(SetSurveyAllowAdditionalIncidentReportsDocument, options);
      }
export type SetSurveyAllowAdditionalIncidentReportsMutationHookResult = ReturnType<typeof useSetSurveyAllowAdditionalIncidentReportsMutation>;
export type SetSurveyAllowAdditionalIncidentReportsMutationResult = Apollo.MutationResult<SetSurveyAllowAdditionalIncidentReportsMutation>;
export type SetSurveyAllowAdditionalIncidentReportsMutationOptions = Apollo.BaseMutationOptions<SetSurveyAllowAdditionalIncidentReportsMutation, SetSurveyAllowAdditionalIncidentReportsMutationVariables>;
export const SetSurveyAllowAdditionalIncidentReportsCutoffDaysDocument = gql`
    mutation setSurveyAllowAdditionalIncidentReportsCutoffDays($surveyId: String!, $allowAdditionsCutoffDays: Int) {
  setSurveyAllowAdditionalIncidentReportsCutoffDays(
    input: {surveyId: $surveyId, allowAdditionsCutoffDays: $allowAdditionsCutoffDays}
  ) {
    allowAdditionalIncidentReports
    allowAdditionalIncidentReportsCutoffDays
  }
}
    `;
export type SetSurveyAllowAdditionalIncidentReportsCutoffDaysMutationFn = Apollo.MutationFunction<SetSurveyAllowAdditionalIncidentReportsCutoffDaysMutation, SetSurveyAllowAdditionalIncidentReportsCutoffDaysMutationVariables>;

/**
 * __useSetSurveyAllowAdditionalIncidentReportsCutoffDaysMutation__
 *
 * To run a mutation, you first call `useSetSurveyAllowAdditionalIncidentReportsCutoffDaysMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetSurveyAllowAdditionalIncidentReportsCutoffDaysMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setSurveyAllowAdditionalIncidentReportsCutoffDaysMutation, { data, loading, error }] = useSetSurveyAllowAdditionalIncidentReportsCutoffDaysMutation({
 *   variables: {
 *      surveyId: // value for 'surveyId'
 *      allowAdditionsCutoffDays: // value for 'allowAdditionsCutoffDays'
 *   },
 * });
 */
export function useSetSurveyAllowAdditionalIncidentReportsCutoffDaysMutation(baseOptions?: Apollo.MutationHookOptions<SetSurveyAllowAdditionalIncidentReportsCutoffDaysMutation, SetSurveyAllowAdditionalIncidentReportsCutoffDaysMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetSurveyAllowAdditionalIncidentReportsCutoffDaysMutation, SetSurveyAllowAdditionalIncidentReportsCutoffDaysMutationVariables>(SetSurveyAllowAdditionalIncidentReportsCutoffDaysDocument, options);
      }
export type SetSurveyAllowAdditionalIncidentReportsCutoffDaysMutationHookResult = ReturnType<typeof useSetSurveyAllowAdditionalIncidentReportsCutoffDaysMutation>;
export type SetSurveyAllowAdditionalIncidentReportsCutoffDaysMutationResult = Apollo.MutationResult<SetSurveyAllowAdditionalIncidentReportsCutoffDaysMutation>;
export type SetSurveyAllowAdditionalIncidentReportsCutoffDaysMutationOptions = Apollo.BaseMutationOptions<SetSurveyAllowAdditionalIncidentReportsCutoffDaysMutation, SetSurveyAllowAdditionalIncidentReportsCutoffDaysMutationVariables>;
export const AddSurveySectionDocument = gql`
    mutation addSurveySection($surveyId: String!, $assessmentObjectId: String!, $name: String!, $description: String!) {
  addSurveySection(
    input: {surveyId: $surveyId, assessmentObjectId: $assessmentObjectId, name: $name, description: $description}
  ) {
    id
    name
    description
    order
    questions {
      id
      name
      description
      order
      isRequired
      canAddToKeyIncidents
      questionType
      options {
        id
        value
        order
      }
    }
    createdAt
    updatedAt
    assessmentObject {
      id
      sport
      type
      classification
      name
    }
  }
}
    `;
export type AddSurveySectionMutationFn = Apollo.MutationFunction<AddSurveySectionMutation, AddSurveySectionMutationVariables>;

/**
 * __useAddSurveySectionMutation__
 *
 * To run a mutation, you first call `useAddSurveySectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSurveySectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSurveySectionMutation, { data, loading, error }] = useAddSurveySectionMutation({
 *   variables: {
 *      surveyId: // value for 'surveyId'
 *      assessmentObjectId: // value for 'assessmentObjectId'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useAddSurveySectionMutation(baseOptions?: Apollo.MutationHookOptions<AddSurveySectionMutation, AddSurveySectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddSurveySectionMutation, AddSurveySectionMutationVariables>(AddSurveySectionDocument, options);
      }
export type AddSurveySectionMutationHookResult = ReturnType<typeof useAddSurveySectionMutation>;
export type AddSurveySectionMutationResult = Apollo.MutationResult<AddSurveySectionMutation>;
export type AddSurveySectionMutationOptions = Apollo.BaseMutationOptions<AddSurveySectionMutation, AddSurveySectionMutationVariables>;
export const UpdateSurveySectionDocument = gql`
    mutation updateSurveySection($sectionId: String!, $assessmentObjectId: String!, $name: String!, $description: String!, $order: Int!) {
  updateSurveySection(
    input: {sectionId: $sectionId, assessmentObjectId: $assessmentObjectId, name: $name, description: $description, order: $order}
  ) {
    id
    name
    description
    order
    questions {
      id
      name
      description
      order
      isRequired
      canAddToKeyIncidents
      questionType
      options {
        id
        value
        order
      }
    }
    createdAt
    updatedAt
    assessmentObject {
      id
      sport
      type
      classification
      name
    }
  }
}
    `;
export type UpdateSurveySectionMutationFn = Apollo.MutationFunction<UpdateSurveySectionMutation, UpdateSurveySectionMutationVariables>;

/**
 * __useUpdateSurveySectionMutation__
 *
 * To run a mutation, you first call `useUpdateSurveySectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSurveySectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSurveySectionMutation, { data, loading, error }] = useUpdateSurveySectionMutation({
 *   variables: {
 *      sectionId: // value for 'sectionId'
 *      assessmentObjectId: // value for 'assessmentObjectId'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useUpdateSurveySectionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSurveySectionMutation, UpdateSurveySectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSurveySectionMutation, UpdateSurveySectionMutationVariables>(UpdateSurveySectionDocument, options);
      }
export type UpdateSurveySectionMutationHookResult = ReturnType<typeof useUpdateSurveySectionMutation>;
export type UpdateSurveySectionMutationResult = Apollo.MutationResult<UpdateSurveySectionMutation>;
export type UpdateSurveySectionMutationOptions = Apollo.BaseMutationOptions<UpdateSurveySectionMutation, UpdateSurveySectionMutationVariables>;
export const DeleteSurveySectionDocument = gql`
    mutation deleteSurveySection($sectionId: String!) {
  deleteSurveySection(input: {sectionId: $sectionId})
}
    `;
export type DeleteSurveySectionMutationFn = Apollo.MutationFunction<DeleteSurveySectionMutation, DeleteSurveySectionMutationVariables>;

/**
 * __useDeleteSurveySectionMutation__
 *
 * To run a mutation, you first call `useDeleteSurveySectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSurveySectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSurveySectionMutation, { data, loading, error }] = useDeleteSurveySectionMutation({
 *   variables: {
 *      sectionId: // value for 'sectionId'
 *   },
 * });
 */
export function useDeleteSurveySectionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSurveySectionMutation, DeleteSurveySectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSurveySectionMutation, DeleteSurveySectionMutationVariables>(DeleteSurveySectionDocument, options);
      }
export type DeleteSurveySectionMutationHookResult = ReturnType<typeof useDeleteSurveySectionMutation>;
export type DeleteSurveySectionMutationResult = Apollo.MutationResult<DeleteSurveySectionMutation>;
export type DeleteSurveySectionMutationOptions = Apollo.BaseMutationOptions<DeleteSurveySectionMutation, DeleteSurveySectionMutationVariables>;
export const AddSurveySectionQuestionDocument = gql`
    mutation addSurveySectionQuestion($sectionId: String!, $name: String!, $description: String!, $isRequired: Boolean!, $questionType: SurveyQuestionTypes!) {
  addSurveySectionQuestion(
    input: {sectionId: $sectionId, name: $name, description: $description, isRequired: $isRequired, questionType: $questionType}
  ) {
    id
    name
    description
    order
    isRequired
    canAddToKeyIncidents
    questionType
    options {
      id
      value
      order
    }
    createdAt
    updatedAt
  }
}
    `;
export type AddSurveySectionQuestionMutationFn = Apollo.MutationFunction<AddSurveySectionQuestionMutation, AddSurveySectionQuestionMutationVariables>;

/**
 * __useAddSurveySectionQuestionMutation__
 *
 * To run a mutation, you first call `useAddSurveySectionQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSurveySectionQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSurveySectionQuestionMutation, { data, loading, error }] = useAddSurveySectionQuestionMutation({
 *   variables: {
 *      sectionId: // value for 'sectionId'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      isRequired: // value for 'isRequired'
 *      questionType: // value for 'questionType'
 *   },
 * });
 */
export function useAddSurveySectionQuestionMutation(baseOptions?: Apollo.MutationHookOptions<AddSurveySectionQuestionMutation, AddSurveySectionQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddSurveySectionQuestionMutation, AddSurveySectionQuestionMutationVariables>(AddSurveySectionQuestionDocument, options);
      }
export type AddSurveySectionQuestionMutationHookResult = ReturnType<typeof useAddSurveySectionQuestionMutation>;
export type AddSurveySectionQuestionMutationResult = Apollo.MutationResult<AddSurveySectionQuestionMutation>;
export type AddSurveySectionQuestionMutationOptions = Apollo.BaseMutationOptions<AddSurveySectionQuestionMutation, AddSurveySectionQuestionMutationVariables>;
export const UpdateSurveySectionQuestionDocument = gql`
    mutation updateSurveySectionQuestion($questionId: String!, $name: String!, $description: String!, $isRequired: Boolean!, $order: Int!) {
  updateSurveySectionQuestion(
    input: {questionId: $questionId, name: $name, description: $description, isRequired: $isRequired, order: $order}
  ) {
    id
    name
    description
    order
    isRequired
    canAddToKeyIncidents
    questionType
    options {
      id
      value
      order
    }
    createdAt
    updatedAt
  }
}
    `;
export type UpdateSurveySectionQuestionMutationFn = Apollo.MutationFunction<UpdateSurveySectionQuestionMutation, UpdateSurveySectionQuestionMutationVariables>;

/**
 * __useUpdateSurveySectionQuestionMutation__
 *
 * To run a mutation, you first call `useUpdateSurveySectionQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSurveySectionQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSurveySectionQuestionMutation, { data, loading, error }] = useUpdateSurveySectionQuestionMutation({
 *   variables: {
 *      questionId: // value for 'questionId'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      isRequired: // value for 'isRequired'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useUpdateSurveySectionQuestionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSurveySectionQuestionMutation, UpdateSurveySectionQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSurveySectionQuestionMutation, UpdateSurveySectionQuestionMutationVariables>(UpdateSurveySectionQuestionDocument, options);
      }
export type UpdateSurveySectionQuestionMutationHookResult = ReturnType<typeof useUpdateSurveySectionQuestionMutation>;
export type UpdateSurveySectionQuestionMutationResult = Apollo.MutationResult<UpdateSurveySectionQuestionMutation>;
export type UpdateSurveySectionQuestionMutationOptions = Apollo.BaseMutationOptions<UpdateSurveySectionQuestionMutation, UpdateSurveySectionQuestionMutationVariables>;
export const DeleteSurveySectionQuestionDocument = gql`
    mutation deleteSurveySectionQuestion($questionId: String!) {
  deleteSurveySectionQuestion(input: {questionId: $questionId})
}
    `;
export type DeleteSurveySectionQuestionMutationFn = Apollo.MutationFunction<DeleteSurveySectionQuestionMutation, DeleteSurveySectionQuestionMutationVariables>;

/**
 * __useDeleteSurveySectionQuestionMutation__
 *
 * To run a mutation, you first call `useDeleteSurveySectionQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSurveySectionQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSurveySectionQuestionMutation, { data, loading, error }] = useDeleteSurveySectionQuestionMutation({
 *   variables: {
 *      questionId: // value for 'questionId'
 *   },
 * });
 */
export function useDeleteSurveySectionQuestionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSurveySectionQuestionMutation, DeleteSurveySectionQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSurveySectionQuestionMutation, DeleteSurveySectionQuestionMutationVariables>(DeleteSurveySectionQuestionDocument, options);
      }
export type DeleteSurveySectionQuestionMutationHookResult = ReturnType<typeof useDeleteSurveySectionQuestionMutation>;
export type DeleteSurveySectionQuestionMutationResult = Apollo.MutationResult<DeleteSurveySectionQuestionMutation>;
export type DeleteSurveySectionQuestionMutationOptions = Apollo.BaseMutationOptions<DeleteSurveySectionQuestionMutation, DeleteSurveySectionQuestionMutationVariables>;
export const SetSurveySectionQuestionCanAddToKeyIncidentsDocument = gql`
    mutation setSurveySectionQuestionCanAddToKeyIncidents($questionId: String!, $canAddToKeyIncidents: Boolean!) {
  setSurveySectionQuestionCanAddToKeyIncidents(
    input: {questionId: $questionId, canAddToKeyIncidents: $canAddToKeyIncidents}
  ) {
    id
    name
    description
    order
    isRequired
    canAddToKeyIncidents
    questionType
    options {
      id
      value
      order
    }
    createdAt
    updatedAt
  }
}
    `;
export type SetSurveySectionQuestionCanAddToKeyIncidentsMutationFn = Apollo.MutationFunction<SetSurveySectionQuestionCanAddToKeyIncidentsMutation, SetSurveySectionQuestionCanAddToKeyIncidentsMutationVariables>;

/**
 * __useSetSurveySectionQuestionCanAddToKeyIncidentsMutation__
 *
 * To run a mutation, you first call `useSetSurveySectionQuestionCanAddToKeyIncidentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetSurveySectionQuestionCanAddToKeyIncidentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setSurveySectionQuestionCanAddToKeyIncidentsMutation, { data, loading, error }] = useSetSurveySectionQuestionCanAddToKeyIncidentsMutation({
 *   variables: {
 *      questionId: // value for 'questionId'
 *      canAddToKeyIncidents: // value for 'canAddToKeyIncidents'
 *   },
 * });
 */
export function useSetSurveySectionQuestionCanAddToKeyIncidentsMutation(baseOptions?: Apollo.MutationHookOptions<SetSurveySectionQuestionCanAddToKeyIncidentsMutation, SetSurveySectionQuestionCanAddToKeyIncidentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetSurveySectionQuestionCanAddToKeyIncidentsMutation, SetSurveySectionQuestionCanAddToKeyIncidentsMutationVariables>(SetSurveySectionQuestionCanAddToKeyIncidentsDocument, options);
      }
export type SetSurveySectionQuestionCanAddToKeyIncidentsMutationHookResult = ReturnType<typeof useSetSurveySectionQuestionCanAddToKeyIncidentsMutation>;
export type SetSurveySectionQuestionCanAddToKeyIncidentsMutationResult = Apollo.MutationResult<SetSurveySectionQuestionCanAddToKeyIncidentsMutation>;
export type SetSurveySectionQuestionCanAddToKeyIncidentsMutationOptions = Apollo.BaseMutationOptions<SetSurveySectionQuestionCanAddToKeyIncidentsMutation, SetSurveySectionQuestionCanAddToKeyIncidentsMutationVariables>;
export const AddSurveySectionQuestionOptionDocument = gql`
    mutation addSurveySectionQuestionOption($questionId: String!, $value: String!) {
  addSurveySectionQuestionOption(input: {questionId: $questionId, value: $value}) {
    id
    value
    order
  }
}
    `;
export type AddSurveySectionQuestionOptionMutationFn = Apollo.MutationFunction<AddSurveySectionQuestionOptionMutation, AddSurveySectionQuestionOptionMutationVariables>;

/**
 * __useAddSurveySectionQuestionOptionMutation__
 *
 * To run a mutation, you first call `useAddSurveySectionQuestionOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSurveySectionQuestionOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSurveySectionQuestionOptionMutation, { data, loading, error }] = useAddSurveySectionQuestionOptionMutation({
 *   variables: {
 *      questionId: // value for 'questionId'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useAddSurveySectionQuestionOptionMutation(baseOptions?: Apollo.MutationHookOptions<AddSurveySectionQuestionOptionMutation, AddSurveySectionQuestionOptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddSurveySectionQuestionOptionMutation, AddSurveySectionQuestionOptionMutationVariables>(AddSurveySectionQuestionOptionDocument, options);
      }
export type AddSurveySectionQuestionOptionMutationHookResult = ReturnType<typeof useAddSurveySectionQuestionOptionMutation>;
export type AddSurveySectionQuestionOptionMutationResult = Apollo.MutationResult<AddSurveySectionQuestionOptionMutation>;
export type AddSurveySectionQuestionOptionMutationOptions = Apollo.BaseMutationOptions<AddSurveySectionQuestionOptionMutation, AddSurveySectionQuestionOptionMutationVariables>;
export const DeleteSurveySectionQuestionOptionDocument = gql`
    mutation deleteSurveySectionQuestionOption($optionId: String!) {
  deleteSurveySectionQuestionOption(input: {optionId: $optionId})
}
    `;
export type DeleteSurveySectionQuestionOptionMutationFn = Apollo.MutationFunction<DeleteSurveySectionQuestionOptionMutation, DeleteSurveySectionQuestionOptionMutationVariables>;

/**
 * __useDeleteSurveySectionQuestionOptionMutation__
 *
 * To run a mutation, you first call `useDeleteSurveySectionQuestionOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSurveySectionQuestionOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSurveySectionQuestionOptionMutation, { data, loading, error }] = useDeleteSurveySectionQuestionOptionMutation({
 *   variables: {
 *      optionId: // value for 'optionId'
 *   },
 * });
 */
export function useDeleteSurveySectionQuestionOptionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSurveySectionQuestionOptionMutation, DeleteSurveySectionQuestionOptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSurveySectionQuestionOptionMutation, DeleteSurveySectionQuestionOptionMutationVariables>(DeleteSurveySectionQuestionOptionDocument, options);
      }
export type DeleteSurveySectionQuestionOptionMutationHookResult = ReturnType<typeof useDeleteSurveySectionQuestionOptionMutation>;
export type DeleteSurveySectionQuestionOptionMutationResult = Apollo.MutationResult<DeleteSurveySectionQuestionOptionMutation>;
export type DeleteSurveySectionQuestionOptionMutationOptions = Apollo.BaseMutationOptions<DeleteSurveySectionQuestionOptionMutation, DeleteSurveySectionQuestionOptionMutationVariables>;
export const UpdateSurveySectionQuestionOptionDocument = gql`
    mutation updateSurveySectionQuestionOption($optionId: String!, $value: String!, $order: Int!) {
  updateSurveySectionQuestionOption(
    input: {optionId: $optionId, value: $value, order: $order}
  ) {
    id
    value
    order
  }
}
    `;
export type UpdateSurveySectionQuestionOptionMutationFn = Apollo.MutationFunction<UpdateSurveySectionQuestionOptionMutation, UpdateSurveySectionQuestionOptionMutationVariables>;

/**
 * __useUpdateSurveySectionQuestionOptionMutation__
 *
 * To run a mutation, you first call `useUpdateSurveySectionQuestionOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSurveySectionQuestionOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSurveySectionQuestionOptionMutation, { data, loading, error }] = useUpdateSurveySectionQuestionOptionMutation({
 *   variables: {
 *      optionId: // value for 'optionId'
 *      value: // value for 'value'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useUpdateSurveySectionQuestionOptionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSurveySectionQuestionOptionMutation, UpdateSurveySectionQuestionOptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSurveySectionQuestionOptionMutation, UpdateSurveySectionQuestionOptionMutationVariables>(UpdateSurveySectionQuestionOptionDocument, options);
      }
export type UpdateSurveySectionQuestionOptionMutationHookResult = ReturnType<typeof useUpdateSurveySectionQuestionOptionMutation>;
export type UpdateSurveySectionQuestionOptionMutationResult = Apollo.MutationResult<UpdateSurveySectionQuestionOptionMutation>;
export type UpdateSurveySectionQuestionOptionMutationOptions = Apollo.BaseMutationOptions<UpdateSurveySectionQuestionOptionMutation, UpdateSurveySectionQuestionOptionMutationVariables>;
export const GetTeamByIdDocument = gql`
    query getTeamById($id: String!) {
  getTeamById(input: {id: $id}) {
    id
    name
    abbreviation
    sport
    city
    stateAbbreviation
    countryCode
    classification
    gender
    parentOrganizationName
    externalId
    ageGroup
    timeZone
    website
    logo
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetTeamByIdQuery__
 *
 * To run a query within a React component, call `useGetTeamByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTeamByIdQuery(baseOptions: Apollo.QueryHookOptions<GetTeamByIdQuery, GetTeamByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTeamByIdQuery, GetTeamByIdQueryVariables>(GetTeamByIdDocument, options);
      }
export function useGetTeamByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTeamByIdQuery, GetTeamByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTeamByIdQuery, GetTeamByIdQueryVariables>(GetTeamByIdDocument, options);
        }
export type GetTeamByIdQueryHookResult = ReturnType<typeof useGetTeamByIdQuery>;
export type GetTeamByIdLazyQueryHookResult = ReturnType<typeof useGetTeamByIdLazyQuery>;
export type GetTeamByIdQueryResult = Apollo.QueryResult<GetTeamByIdQuery, GetTeamByIdQueryVariables>;
export const UpdateTeamForSeasonDocument = gql`
    mutation updateTeamForSeason($organizationId: String!, $seasonId: String!, $teamId: String!, $name: String!, $abbreviation: String, $website: String, $logo: String, $city: String!, $stateAbbreviation: String, $countryCode: CountryCodes!, $timeZone: String!, $classification: TeamClassifications!, $gender: TeamGenders!, $parentOrganizationName: String, $externalId: String, $ageGroup: String) {
  updateTeamForSeason(
    input: {organizationId: $organizationId, seasonId: $seasonId, teamId: $teamId, name: $name, abbreviation: $abbreviation, website: $website, logo: $logo, city: $city, stateAbbreviation: $stateAbbreviation, countryCode: $countryCode, timeZone: $timeZone, classification: $classification, gender: $gender, parentOrganizationName: $parentOrganizationName, externalId: $externalId, ageGroup: $ageGroup}
  ) {
    id
    name
    abbreviation
    sport
    city
    stateAbbreviation
    countryCode
    timeZone
    website
    logo
    classification
    gender
    parentOrganizationName
    externalId
    ageGroup
    createdAt
    updatedAt
  }
}
    `;
export type UpdateTeamForSeasonMutationFn = Apollo.MutationFunction<UpdateTeamForSeasonMutation, UpdateTeamForSeasonMutationVariables>;

/**
 * __useUpdateTeamForSeasonMutation__
 *
 * To run a mutation, you first call `useUpdateTeamForSeasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamForSeasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeamForSeasonMutation, { data, loading, error }] = useUpdateTeamForSeasonMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      seasonId: // value for 'seasonId'
 *      teamId: // value for 'teamId'
 *      name: // value for 'name'
 *      abbreviation: // value for 'abbreviation'
 *      website: // value for 'website'
 *      logo: // value for 'logo'
 *      city: // value for 'city'
 *      stateAbbreviation: // value for 'stateAbbreviation'
 *      countryCode: // value for 'countryCode'
 *      timeZone: // value for 'timeZone'
 *      classification: // value for 'classification'
 *      gender: // value for 'gender'
 *      parentOrganizationName: // value for 'parentOrganizationName'
 *      externalId: // value for 'externalId'
 *      ageGroup: // value for 'ageGroup'
 *   },
 * });
 */
export function useUpdateTeamForSeasonMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTeamForSeasonMutation, UpdateTeamForSeasonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTeamForSeasonMutation, UpdateTeamForSeasonMutationVariables>(UpdateTeamForSeasonDocument, options);
      }
export type UpdateTeamForSeasonMutationHookResult = ReturnType<typeof useUpdateTeamForSeasonMutation>;
export type UpdateTeamForSeasonMutationResult = Apollo.MutationResult<UpdateTeamForSeasonMutation>;
export type UpdateTeamForSeasonMutationOptions = Apollo.BaseMutationOptions<UpdateTeamForSeasonMutation, UpdateTeamForSeasonMutationVariables>;
export const ListTeamCoachesDocument = gql`
    query listTeamCoaches($offset: Int!, $limit: Int!, $teamId: String!, $search: String, $type: CoachTypes, $status: CoachStatuses, $role: String, $userStatus: UserStatuses) {
  listTeamCoaches(
    input: {teamId: $teamId, search: $search, type: $type, status: $status, role: $role, userStatus: $userStatus, pagination: {offset: $offset, limit: $limit}}
  ) {
    teamId
    coaches {
      id
      teamId
      teamName
      teamAbbreviation
      sport
      teamCity
      userId
      type
      role
      status
      email
      firstName
      lastName
      profilePic
      userStatus
      createdAt
      updatedAt
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListTeamCoachesQuery__
 *
 * To run a query within a React component, call `useListTeamCoachesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTeamCoachesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTeamCoachesQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      teamId: // value for 'teamId'
 *      search: // value for 'search'
 *      type: // value for 'type'
 *      status: // value for 'status'
 *      role: // value for 'role'
 *      userStatus: // value for 'userStatus'
 *   },
 * });
 */
export function useListTeamCoachesQuery(baseOptions: Apollo.QueryHookOptions<ListTeamCoachesQuery, ListTeamCoachesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListTeamCoachesQuery, ListTeamCoachesQueryVariables>(ListTeamCoachesDocument, options);
      }
export function useListTeamCoachesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListTeamCoachesQuery, ListTeamCoachesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListTeamCoachesQuery, ListTeamCoachesQueryVariables>(ListTeamCoachesDocument, options);
        }
export type ListTeamCoachesQueryHookResult = ReturnType<typeof useListTeamCoachesQuery>;
export type ListTeamCoachesLazyQueryHookResult = ReturnType<typeof useListTeamCoachesLazyQuery>;
export type ListTeamCoachesQueryResult = Apollo.QueryResult<ListTeamCoachesQuery, ListTeamCoachesQueryVariables>;
export const CreateTeamAliasDocument = gql`
    mutation createTeamAlias($teamId: String!, $alias: String!) {
  createTeamAlias(input: {teamId: $teamId, alias: $alias}) {
    id
    teamId
    alias
  }
}
    `;
export type CreateTeamAliasMutationFn = Apollo.MutationFunction<CreateTeamAliasMutation, CreateTeamAliasMutationVariables>;

/**
 * __useCreateTeamAliasMutation__
 *
 * To run a mutation, you first call `useCreateTeamAliasMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTeamAliasMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTeamAliasMutation, { data, loading, error }] = useCreateTeamAliasMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      alias: // value for 'alias'
 *   },
 * });
 */
export function useCreateTeamAliasMutation(baseOptions?: Apollo.MutationHookOptions<CreateTeamAliasMutation, CreateTeamAliasMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTeamAliasMutation, CreateTeamAliasMutationVariables>(CreateTeamAliasDocument, options);
      }
export type CreateTeamAliasMutationHookResult = ReturnType<typeof useCreateTeamAliasMutation>;
export type CreateTeamAliasMutationResult = Apollo.MutationResult<CreateTeamAliasMutation>;
export type CreateTeamAliasMutationOptions = Apollo.BaseMutationOptions<CreateTeamAliasMutation, CreateTeamAliasMutationVariables>;
export const ListTeamAliasesDocument = gql`
    query listTeamAliases($offset: Int!, $limit: Int!, $teamId: String!, $alias: String) {
  listTeamAliases(
    input: {teamId: $teamId, alias: $alias, pagination: {offset: $offset, limit: $limit}}
  ) {
    teamId
    aliases {
      id
      teamId
      alias
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListTeamAliasesQuery__
 *
 * To run a query within a React component, call `useListTeamAliasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTeamAliasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTeamAliasesQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      teamId: // value for 'teamId'
 *      alias: // value for 'alias'
 *   },
 * });
 */
export function useListTeamAliasesQuery(baseOptions: Apollo.QueryHookOptions<ListTeamAliasesQuery, ListTeamAliasesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListTeamAliasesQuery, ListTeamAliasesQueryVariables>(ListTeamAliasesDocument, options);
      }
export function useListTeamAliasesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListTeamAliasesQuery, ListTeamAliasesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListTeamAliasesQuery, ListTeamAliasesQueryVariables>(ListTeamAliasesDocument, options);
        }
export type ListTeamAliasesQueryHookResult = ReturnType<typeof useListTeamAliasesQuery>;
export type ListTeamAliasesLazyQueryHookResult = ReturnType<typeof useListTeamAliasesLazyQuery>;
export type ListTeamAliasesQueryResult = Apollo.QueryResult<ListTeamAliasesQuery, ListTeamAliasesQueryVariables>;
export const UpdateTeamAliasDocument = gql`
    mutation updateTeamAlias($id: String!, $alias: String!) {
  updateTeamAlias(input: {id: $id, alias: $alias}) {
    id
    teamId
    alias
  }
}
    `;
export type UpdateTeamAliasMutationFn = Apollo.MutationFunction<UpdateTeamAliasMutation, UpdateTeamAliasMutationVariables>;

/**
 * __useUpdateTeamAliasMutation__
 *
 * To run a mutation, you first call `useUpdateTeamAliasMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamAliasMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeamAliasMutation, { data, loading, error }] = useUpdateTeamAliasMutation({
 *   variables: {
 *      id: // value for 'id'
 *      alias: // value for 'alias'
 *   },
 * });
 */
export function useUpdateTeamAliasMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTeamAliasMutation, UpdateTeamAliasMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTeamAliasMutation, UpdateTeamAliasMutationVariables>(UpdateTeamAliasDocument, options);
      }
export type UpdateTeamAliasMutationHookResult = ReturnType<typeof useUpdateTeamAliasMutation>;
export type UpdateTeamAliasMutationResult = Apollo.MutationResult<UpdateTeamAliasMutation>;
export type UpdateTeamAliasMutationOptions = Apollo.BaseMutationOptions<UpdateTeamAliasMutation, UpdateTeamAliasMutationVariables>;
export const DeleteTeamAliasDocument = gql`
    mutation deleteTeamAlias($id: String!) {
  deleteTeamAlias(input: {id: $id})
}
    `;
export type DeleteTeamAliasMutationFn = Apollo.MutationFunction<DeleteTeamAliasMutation, DeleteTeamAliasMutationVariables>;

/**
 * __useDeleteTeamAliasMutation__
 *
 * To run a mutation, you first call `useDeleteTeamAliasMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTeamAliasMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTeamAliasMutation, { data, loading, error }] = useDeleteTeamAliasMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTeamAliasMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTeamAliasMutation, DeleteTeamAliasMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTeamAliasMutation, DeleteTeamAliasMutationVariables>(DeleteTeamAliasDocument, options);
      }
export type DeleteTeamAliasMutationHookResult = ReturnType<typeof useDeleteTeamAliasMutation>;
export type DeleteTeamAliasMutationResult = Apollo.MutationResult<DeleteTeamAliasMutation>;
export type DeleteTeamAliasMutationOptions = Apollo.BaseMutationOptions<DeleteTeamAliasMutation, DeleteTeamAliasMutationVariables>;
export const ListContactsForTeamForSeasonDocument = gql`
    query listContactsForTeamForSeason($offset: Int!, $limit: Int!, $organizationId: String!, $seasonId: String!, $teamId: String!, $search: String, $activeOnly: Boolean) {
  listContactsForTeamForSeason(
    input: {organizationId: $organizationId, seasonId: $seasonId, teamId: $teamId, search: $search, activeOnly: $activeOnly, pagination: {offset: $offset, limit: $limit}}
  ) {
    teamId
    teamName
    contacts {
      id
      firstName
      lastName
      isActive
      email
      phone
      createdAt
      updatedAt
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListContactsForTeamForSeasonQuery__
 *
 * To run a query within a React component, call `useListContactsForTeamForSeasonQuery` and pass it any options that fit your needs.
 * When your component renders, `useListContactsForTeamForSeasonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListContactsForTeamForSeasonQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      organizationId: // value for 'organizationId'
 *      seasonId: // value for 'seasonId'
 *      teamId: // value for 'teamId'
 *      search: // value for 'search'
 *      activeOnly: // value for 'activeOnly'
 *   },
 * });
 */
export function useListContactsForTeamForSeasonQuery(baseOptions: Apollo.QueryHookOptions<ListContactsForTeamForSeasonQuery, ListContactsForTeamForSeasonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListContactsForTeamForSeasonQuery, ListContactsForTeamForSeasonQueryVariables>(ListContactsForTeamForSeasonDocument, options);
      }
export function useListContactsForTeamForSeasonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListContactsForTeamForSeasonQuery, ListContactsForTeamForSeasonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListContactsForTeamForSeasonQuery, ListContactsForTeamForSeasonQueryVariables>(ListContactsForTeamForSeasonDocument, options);
        }
export type ListContactsForTeamForSeasonQueryHookResult = ReturnType<typeof useListContactsForTeamForSeasonQuery>;
export type ListContactsForTeamForSeasonLazyQueryHookResult = ReturnType<typeof useListContactsForTeamForSeasonLazyQuery>;
export type ListContactsForTeamForSeasonQueryResult = Apollo.QueryResult<ListContactsForTeamForSeasonQuery, ListContactsForTeamForSeasonQueryVariables>;
export const CreateTeamContactForSeasonDocument = gql`
    mutation createTeamContactForSeason($organizationId: String!, $seasonId: String!, $teamId: String!, $firstName: String!, $lastName: String!, $email: String, $phone: String) {
  createTeamContactForSeason(
    input: {organizationId: $organizationId, seasonId: $seasonId, teamId: $teamId, firstName: $firstName, lastName: $lastName, email: $email, phone: $phone}
  ) {
    id
    firstName
    lastName
    isActive
    email
    phone
    createdAt
    updatedAt
  }
}
    `;
export type CreateTeamContactForSeasonMutationFn = Apollo.MutationFunction<CreateTeamContactForSeasonMutation, CreateTeamContactForSeasonMutationVariables>;

/**
 * __useCreateTeamContactForSeasonMutation__
 *
 * To run a mutation, you first call `useCreateTeamContactForSeasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTeamContactForSeasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTeamContactForSeasonMutation, { data, loading, error }] = useCreateTeamContactForSeasonMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      seasonId: // value for 'seasonId'
 *      teamId: // value for 'teamId'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function useCreateTeamContactForSeasonMutation(baseOptions?: Apollo.MutationHookOptions<CreateTeamContactForSeasonMutation, CreateTeamContactForSeasonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTeamContactForSeasonMutation, CreateTeamContactForSeasonMutationVariables>(CreateTeamContactForSeasonDocument, options);
      }
export type CreateTeamContactForSeasonMutationHookResult = ReturnType<typeof useCreateTeamContactForSeasonMutation>;
export type CreateTeamContactForSeasonMutationResult = Apollo.MutationResult<CreateTeamContactForSeasonMutation>;
export type CreateTeamContactForSeasonMutationOptions = Apollo.BaseMutationOptions<CreateTeamContactForSeasonMutation, CreateTeamContactForSeasonMutationVariables>;
export const UpdateTeamContactForSeasonDocument = gql`
    mutation updateTeamContactForSeason($organizationId: String!, $seasonId: String!, $teamId: String!, $contactId: String!, $firstName: String!, $lastName: String!, $email: String, $phone: String, $isActive: Boolean!) {
  updateTeamContactForSeason(
    input: {organizationId: $organizationId, seasonId: $seasonId, teamId: $teamId, contactId: $contactId, firstName: $firstName, lastName: $lastName, email: $email, phone: $phone, isActive: $isActive}
  ) {
    id
    firstName
    lastName
    isActive
    email
    phone
    createdAt
    updatedAt
  }
}
    `;
export type UpdateTeamContactForSeasonMutationFn = Apollo.MutationFunction<UpdateTeamContactForSeasonMutation, UpdateTeamContactForSeasonMutationVariables>;

/**
 * __useUpdateTeamContactForSeasonMutation__
 *
 * To run a mutation, you first call `useUpdateTeamContactForSeasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamContactForSeasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeamContactForSeasonMutation, { data, loading, error }] = useUpdateTeamContactForSeasonMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      seasonId: // value for 'seasonId'
 *      teamId: // value for 'teamId'
 *      contactId: // value for 'contactId'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      phone: // value for 'phone'
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useUpdateTeamContactForSeasonMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTeamContactForSeasonMutation, UpdateTeamContactForSeasonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTeamContactForSeasonMutation, UpdateTeamContactForSeasonMutationVariables>(UpdateTeamContactForSeasonDocument, options);
      }
export type UpdateTeamContactForSeasonMutationHookResult = ReturnType<typeof useUpdateTeamContactForSeasonMutation>;
export type UpdateTeamContactForSeasonMutationResult = Apollo.MutationResult<UpdateTeamContactForSeasonMutation>;
export type UpdateTeamContactForSeasonMutationOptions = Apollo.BaseMutationOptions<UpdateTeamContactForSeasonMutation, UpdateTeamContactForSeasonMutationVariables>;
export const DeleteTeamContactForSeasonDocument = gql`
    mutation deleteTeamContactForSeason($organizationId: String!, $seasonId: String!, $teamId: String!, $contactId: String!) {
  deleteTeamContactForSeason(
    input: {organizationId: $organizationId, seasonId: $seasonId, teamId: $teamId, contactId: $contactId}
  )
}
    `;
export type DeleteTeamContactForSeasonMutationFn = Apollo.MutationFunction<DeleteTeamContactForSeasonMutation, DeleteTeamContactForSeasonMutationVariables>;

/**
 * __useDeleteTeamContactForSeasonMutation__
 *
 * To run a mutation, you first call `useDeleteTeamContactForSeasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTeamContactForSeasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTeamContactForSeasonMutation, { data, loading, error }] = useDeleteTeamContactForSeasonMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      seasonId: // value for 'seasonId'
 *      teamId: // value for 'teamId'
 *      contactId: // value for 'contactId'
 *   },
 * });
 */
export function useDeleteTeamContactForSeasonMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTeamContactForSeasonMutation, DeleteTeamContactForSeasonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTeamContactForSeasonMutation, DeleteTeamContactForSeasonMutationVariables>(DeleteTeamContactForSeasonDocument, options);
      }
export type DeleteTeamContactForSeasonMutationHookResult = ReturnType<typeof useDeleteTeamContactForSeasonMutation>;
export type DeleteTeamContactForSeasonMutationResult = Apollo.MutationResult<DeleteTeamContactForSeasonMutation>;
export type DeleteTeamContactForSeasonMutationOptions = Apollo.BaseMutationOptions<DeleteTeamContactForSeasonMutation, DeleteTeamContactForSeasonMutationVariables>;
export const ListTeamKitRecipientsForSeasonTeamDocument = gql`
    query listTeamKitRecipientsForSeasonTeam($offset: Int!, $limit: Int!, $organizationId: String!, $seasonId: String!, $teamId: String!, $search: String, $activeOnly: Boolean) {
  listTeamKitRecipientsForSeasonTeam(
    input: {organizationId: $organizationId, seasonId: $seasonId, teamId: $teamId, search: $search, activeOnly: $activeOnly, pagination: {offset: $offset, limit: $limit}}
  ) {
    teamId
    recipients {
      id
      teamId
      teamName
      teamAbbreviation
      teamLogo
      teamCity
      role
      userId
      firstName
      lastName
      isActive
      email
      profilePic
      userStatus
      createdAt
      updatedAt
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListTeamKitRecipientsForSeasonTeamQuery__
 *
 * To run a query within a React component, call `useListTeamKitRecipientsForSeasonTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTeamKitRecipientsForSeasonTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTeamKitRecipientsForSeasonTeamQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      organizationId: // value for 'organizationId'
 *      seasonId: // value for 'seasonId'
 *      teamId: // value for 'teamId'
 *      search: // value for 'search'
 *      activeOnly: // value for 'activeOnly'
 *   },
 * });
 */
export function useListTeamKitRecipientsForSeasonTeamQuery(baseOptions: Apollo.QueryHookOptions<ListTeamKitRecipientsForSeasonTeamQuery, ListTeamKitRecipientsForSeasonTeamQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListTeamKitRecipientsForSeasonTeamQuery, ListTeamKitRecipientsForSeasonTeamQueryVariables>(ListTeamKitRecipientsForSeasonTeamDocument, options);
      }
export function useListTeamKitRecipientsForSeasonTeamLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListTeamKitRecipientsForSeasonTeamQuery, ListTeamKitRecipientsForSeasonTeamQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListTeamKitRecipientsForSeasonTeamQuery, ListTeamKitRecipientsForSeasonTeamQueryVariables>(ListTeamKitRecipientsForSeasonTeamDocument, options);
        }
export type ListTeamKitRecipientsForSeasonTeamQueryHookResult = ReturnType<typeof useListTeamKitRecipientsForSeasonTeamQuery>;
export type ListTeamKitRecipientsForSeasonTeamLazyQueryHookResult = ReturnType<typeof useListTeamKitRecipientsForSeasonTeamLazyQuery>;
export type ListTeamKitRecipientsForSeasonTeamQueryResult = Apollo.QueryResult<ListTeamKitRecipientsForSeasonTeamQuery, ListTeamKitRecipientsForSeasonTeamQueryVariables>;
export const CreateTeamKitTeamRecipientForSeasonDocument = gql`
    mutation createTeamKitTeamRecipientForSeason($organizationId: String!, $seasonId: String!, $teamId: String!, $firstName: String!, $lastName: String!, $email: String!, $addToExistingCompetitions: Boolean!, $role: String) {
  createTeamKitTeamRecipientForSeason(
    input: {organizationId: $organizationId, seasonId: $seasonId, teamId: $teamId, firstName: $firstName, lastName: $lastName, email: $email, addToExistingCompetitions: $addToExistingCompetitions, role: $role}
  ) {
    id
    teamId
    teamName
    teamAbbreviation
    teamLogo
    teamCity
    role
    userId
    firstName
    lastName
    isActive
    email
    profilePic
    userStatus
    createdAt
    updatedAt
  }
}
    `;
export type CreateTeamKitTeamRecipientForSeasonMutationFn = Apollo.MutationFunction<CreateTeamKitTeamRecipientForSeasonMutation, CreateTeamKitTeamRecipientForSeasonMutationVariables>;

/**
 * __useCreateTeamKitTeamRecipientForSeasonMutation__
 *
 * To run a mutation, you first call `useCreateTeamKitTeamRecipientForSeasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTeamKitTeamRecipientForSeasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTeamKitTeamRecipientForSeasonMutation, { data, loading, error }] = useCreateTeamKitTeamRecipientForSeasonMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      seasonId: // value for 'seasonId'
 *      teamId: // value for 'teamId'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      addToExistingCompetitions: // value for 'addToExistingCompetitions'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useCreateTeamKitTeamRecipientForSeasonMutation(baseOptions?: Apollo.MutationHookOptions<CreateTeamKitTeamRecipientForSeasonMutation, CreateTeamKitTeamRecipientForSeasonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTeamKitTeamRecipientForSeasonMutation, CreateTeamKitTeamRecipientForSeasonMutationVariables>(CreateTeamKitTeamRecipientForSeasonDocument, options);
      }
export type CreateTeamKitTeamRecipientForSeasonMutationHookResult = ReturnType<typeof useCreateTeamKitTeamRecipientForSeasonMutation>;
export type CreateTeamKitTeamRecipientForSeasonMutationResult = Apollo.MutationResult<CreateTeamKitTeamRecipientForSeasonMutation>;
export type CreateTeamKitTeamRecipientForSeasonMutationOptions = Apollo.BaseMutationOptions<CreateTeamKitTeamRecipientForSeasonMutation, CreateTeamKitTeamRecipientForSeasonMutationVariables>;
export const UpdateTeamKitTeamRecipientForSeasonDocument = gql`
    mutation updateTeamKitTeamRecipientForSeason($organizationId: String!, $seasonId: String!, $teamId: String!, $recipientId: String!, $role: String, $isActive: Boolean!) {
  updateTeamKitTeamRecipientForSeason(
    input: {organizationId: $organizationId, seasonId: $seasonId, teamId: $teamId, recipientId: $recipientId, role: $role, isActive: $isActive}
  ) {
    id
    teamId
    teamName
    teamAbbreviation
    teamLogo
    teamCity
    role
    userId
    firstName
    lastName
    isActive
    email
    profilePic
    userStatus
    createdAt
    updatedAt
  }
}
    `;
export type UpdateTeamKitTeamRecipientForSeasonMutationFn = Apollo.MutationFunction<UpdateTeamKitTeamRecipientForSeasonMutation, UpdateTeamKitTeamRecipientForSeasonMutationVariables>;

/**
 * __useUpdateTeamKitTeamRecipientForSeasonMutation__
 *
 * To run a mutation, you first call `useUpdateTeamKitTeamRecipientForSeasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamKitTeamRecipientForSeasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeamKitTeamRecipientForSeasonMutation, { data, loading, error }] = useUpdateTeamKitTeamRecipientForSeasonMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      seasonId: // value for 'seasonId'
 *      teamId: // value for 'teamId'
 *      recipientId: // value for 'recipientId'
 *      role: // value for 'role'
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useUpdateTeamKitTeamRecipientForSeasonMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTeamKitTeamRecipientForSeasonMutation, UpdateTeamKitTeamRecipientForSeasonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTeamKitTeamRecipientForSeasonMutation, UpdateTeamKitTeamRecipientForSeasonMutationVariables>(UpdateTeamKitTeamRecipientForSeasonDocument, options);
      }
export type UpdateTeamKitTeamRecipientForSeasonMutationHookResult = ReturnType<typeof useUpdateTeamKitTeamRecipientForSeasonMutation>;
export type UpdateTeamKitTeamRecipientForSeasonMutationResult = Apollo.MutationResult<UpdateTeamKitTeamRecipientForSeasonMutation>;
export type UpdateTeamKitTeamRecipientForSeasonMutationOptions = Apollo.BaseMutationOptions<UpdateTeamKitTeamRecipientForSeasonMutation, UpdateTeamKitTeamRecipientForSeasonMutationVariables>;
export const ActivateSeasonTeamKitTeamRecipientDocument = gql`
    mutation activateSeasonTeamKitTeamRecipient($organizationId: String!, $seasonId: String!, $teamId: String!, $recipientId: String!, $isActive: Boolean!) {
  activateSeasonTeamKitTeamRecipient(
    input: {organizationId: $organizationId, seasonId: $seasonId, teamId: $teamId, recipientId: $recipientId, isActive: $isActive}
  ) {
    id
    teamId
    teamName
    teamAbbreviation
    teamLogo
    teamCity
    role
    userId
    firstName
    lastName
    isActive
    email
    profilePic
    userStatus
    createdAt
    updatedAt
  }
}
    `;
export type ActivateSeasonTeamKitTeamRecipientMutationFn = Apollo.MutationFunction<ActivateSeasonTeamKitTeamRecipientMutation, ActivateSeasonTeamKitTeamRecipientMutationVariables>;

/**
 * __useActivateSeasonTeamKitTeamRecipientMutation__
 *
 * To run a mutation, you first call `useActivateSeasonTeamKitTeamRecipientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateSeasonTeamKitTeamRecipientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateSeasonTeamKitTeamRecipientMutation, { data, loading, error }] = useActivateSeasonTeamKitTeamRecipientMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      seasonId: // value for 'seasonId'
 *      teamId: // value for 'teamId'
 *      recipientId: // value for 'recipientId'
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useActivateSeasonTeamKitTeamRecipientMutation(baseOptions?: Apollo.MutationHookOptions<ActivateSeasonTeamKitTeamRecipientMutation, ActivateSeasonTeamKitTeamRecipientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateSeasonTeamKitTeamRecipientMutation, ActivateSeasonTeamKitTeamRecipientMutationVariables>(ActivateSeasonTeamKitTeamRecipientDocument, options);
      }
export type ActivateSeasonTeamKitTeamRecipientMutationHookResult = ReturnType<typeof useActivateSeasonTeamKitTeamRecipientMutation>;
export type ActivateSeasonTeamKitTeamRecipientMutationResult = Apollo.MutationResult<ActivateSeasonTeamKitTeamRecipientMutation>;
export type ActivateSeasonTeamKitTeamRecipientMutationOptions = Apollo.BaseMutationOptions<ActivateSeasonTeamKitTeamRecipientMutation, ActivateSeasonTeamKitTeamRecipientMutationVariables>;
export const ListTeamKitTeamSetupSummariesForSeasonDocument = gql`
    query listTeamKitTeamSetupSummariesForSeason($offset: Int!, $limit: Int!, $seasonId: String!, $search: String) {
  listTeamKitTeamSetupSummariesForSeason(
    input: {seasonId: $seasonId, search: $search, pagination: {offset: $offset, limit: $limit}}
  ) {
    seasonId
    summaries {
      seasonId
      seasonName
      teamId
      teamName
      teamLogo
      teamRecipientsCount
      teamContactsCount
      teamHomeContactOptionsCount
      teamHomeContactOptionsTarget
      teamAwayContactOptionsCount
      teamAwayContactOptionsTarget
      teamBroadcastOptionsCount
      teamApparelSetCount
      teamTotalApparelSetTarget
    }
    pagination {
      total
      size
      offset
      limit
      page
      totalPages
    }
  }
}
    `;

/**
 * __useListTeamKitTeamSetupSummariesForSeasonQuery__
 *
 * To run a query within a React component, call `useListTeamKitTeamSetupSummariesForSeasonQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTeamKitTeamSetupSummariesForSeasonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTeamKitTeamSetupSummariesForSeasonQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      seasonId: // value for 'seasonId'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useListTeamKitTeamSetupSummariesForSeasonQuery(baseOptions: Apollo.QueryHookOptions<ListTeamKitTeamSetupSummariesForSeasonQuery, ListTeamKitTeamSetupSummariesForSeasonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListTeamKitTeamSetupSummariesForSeasonQuery, ListTeamKitTeamSetupSummariesForSeasonQueryVariables>(ListTeamKitTeamSetupSummariesForSeasonDocument, options);
      }
export function useListTeamKitTeamSetupSummariesForSeasonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListTeamKitTeamSetupSummariesForSeasonQuery, ListTeamKitTeamSetupSummariesForSeasonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListTeamKitTeamSetupSummariesForSeasonQuery, ListTeamKitTeamSetupSummariesForSeasonQueryVariables>(ListTeamKitTeamSetupSummariesForSeasonDocument, options);
        }
export type ListTeamKitTeamSetupSummariesForSeasonQueryHookResult = ReturnType<typeof useListTeamKitTeamSetupSummariesForSeasonQuery>;
export type ListTeamKitTeamSetupSummariesForSeasonLazyQueryHookResult = ReturnType<typeof useListTeamKitTeamSetupSummariesForSeasonLazyQuery>;
export type ListTeamKitTeamSetupSummariesForSeasonQueryResult = Apollo.QueryResult<ListTeamKitTeamSetupSummariesForSeasonQuery, ListTeamKitTeamSetupSummariesForSeasonQueryVariables>;
export const GetTeamKitTeamSetupSummaryForSeasonTeamDocument = gql`
    query getTeamKitTeamSetupSummaryForSeasonTeam($seasonId: String!, $teamId: String!) {
  getTeamKitTeamSetupSummaryForSeasonTeam(
    input: {seasonId: $seasonId, teamId: $teamId}
  ) {
    seasonId
    seasonName
    teamId
    teamName
    teamLogo
    teamRecipientsCount
    teamHomeContactOptionsCount
    teamHomeContactOptionsTarget
    teamAwayContactOptionsCount
    teamAwayContactOptionsTarget
    teamContactsCount
    teamBroadcastOptionsCount
    teamApparelSetCount
    teamTotalApparelSetTarget
    apparelsForRoles {
      seasonId
      seasonName
      teamId
      teamName
      teamLogo
      roleId
      role
      roleOrder
      definitionId
      definition
      definitionOrder
      apparelCount
    }
  }
}
    `;

/**
 * __useGetTeamKitTeamSetupSummaryForSeasonTeamQuery__
 *
 * To run a query within a React component, call `useGetTeamKitTeamSetupSummaryForSeasonTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamKitTeamSetupSummaryForSeasonTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamKitTeamSetupSummaryForSeasonTeamQuery({
 *   variables: {
 *      seasonId: // value for 'seasonId'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useGetTeamKitTeamSetupSummaryForSeasonTeamQuery(baseOptions: Apollo.QueryHookOptions<GetTeamKitTeamSetupSummaryForSeasonTeamQuery, GetTeamKitTeamSetupSummaryForSeasonTeamQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTeamKitTeamSetupSummaryForSeasonTeamQuery, GetTeamKitTeamSetupSummaryForSeasonTeamQueryVariables>(GetTeamKitTeamSetupSummaryForSeasonTeamDocument, options);
      }
export function useGetTeamKitTeamSetupSummaryForSeasonTeamLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTeamKitTeamSetupSummaryForSeasonTeamQuery, GetTeamKitTeamSetupSummaryForSeasonTeamQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTeamKitTeamSetupSummaryForSeasonTeamQuery, GetTeamKitTeamSetupSummaryForSeasonTeamQueryVariables>(GetTeamKitTeamSetupSummaryForSeasonTeamDocument, options);
        }
export type GetTeamKitTeamSetupSummaryForSeasonTeamQueryHookResult = ReturnType<typeof useGetTeamKitTeamSetupSummaryForSeasonTeamQuery>;
export type GetTeamKitTeamSetupSummaryForSeasonTeamLazyQueryHookResult = ReturnType<typeof useGetTeamKitTeamSetupSummaryForSeasonTeamLazyQuery>;
export type GetTeamKitTeamSetupSummaryForSeasonTeamQueryResult = Apollo.QueryResult<GetTeamKitTeamSetupSummaryForSeasonTeamQuery, GetTeamKitTeamSetupSummaryForSeasonTeamQueryVariables>;
export const UploadUserProfilePicDocument = gql`
    mutation uploadUserProfilePic($file: Upload!) {
  uploadUserProfilePicture(file: $file)
}
    `;
export type UploadUserProfilePicMutationFn = Apollo.MutationFunction<UploadUserProfilePicMutation, UploadUserProfilePicMutationVariables>;

/**
 * __useUploadUserProfilePicMutation__
 *
 * To run a mutation, you first call `useUploadUserProfilePicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadUserProfilePicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadUserProfilePicMutation, { data, loading, error }] = useUploadUserProfilePicMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadUserProfilePicMutation(baseOptions?: Apollo.MutationHookOptions<UploadUserProfilePicMutation, UploadUserProfilePicMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadUserProfilePicMutation, UploadUserProfilePicMutationVariables>(UploadUserProfilePicDocument, options);
      }
export type UploadUserProfilePicMutationHookResult = ReturnType<typeof useUploadUserProfilePicMutation>;
export type UploadUserProfilePicMutationResult = Apollo.MutationResult<UploadUserProfilePicMutation>;
export type UploadUserProfilePicMutationOptions = Apollo.BaseMutationOptions<UploadUserProfilePicMutation, UploadUserProfilePicMutationVariables>;
export const UpdateProfileDocument = gql`
    mutation updateProfile($firstName: String!, $lastName: String!) {
  updateMyProfile(input: {firstName: $firstName, lastName: $lastName}) {
    id
    firstName
    lastName
  }
}
    `;
export type UpdateProfileMutationFn = Apollo.MutationFunction<UpdateProfileMutation, UpdateProfileMutationVariables>;

/**
 * __useUpdateProfileMutation__
 *
 * To run a mutation, you first call `useUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileMutation, { data, loading, error }] = useUpdateProfileMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *   },
 * });
 */
export function useUpdateProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProfileMutation, UpdateProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProfileMutation, UpdateProfileMutationVariables>(UpdateProfileDocument, options);
      }
export type UpdateProfileMutationHookResult = ReturnType<typeof useUpdateProfileMutation>;
export type UpdateProfileMutationResult = Apollo.MutationResult<UpdateProfileMutation>;
export type UpdateProfileMutationOptions = Apollo.BaseMutationOptions<UpdateProfileMutation, UpdateProfileMutationVariables>;
export const ListUserNotificationSettingsDocument = gql`
    query listUserNotificationSettings($notificationSettingsToken: String!) {
  listUserNotificationSettings(
    input: {notificationSettingsToken: $notificationSettingsToken}
  ) {
    userId
    settings {
      id
      setting
      enabled
    }
  }
}
    `;

/**
 * __useListUserNotificationSettingsQuery__
 *
 * To run a query within a React component, call `useListUserNotificationSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUserNotificationSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUserNotificationSettingsQuery({
 *   variables: {
 *      notificationSettingsToken: // value for 'notificationSettingsToken'
 *   },
 * });
 */
export function useListUserNotificationSettingsQuery(baseOptions: Apollo.QueryHookOptions<ListUserNotificationSettingsQuery, ListUserNotificationSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListUserNotificationSettingsQuery, ListUserNotificationSettingsQueryVariables>(ListUserNotificationSettingsDocument, options);
      }
export function useListUserNotificationSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListUserNotificationSettingsQuery, ListUserNotificationSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListUserNotificationSettingsQuery, ListUserNotificationSettingsQueryVariables>(ListUserNotificationSettingsDocument, options);
        }
export type ListUserNotificationSettingsQueryHookResult = ReturnType<typeof useListUserNotificationSettingsQuery>;
export type ListUserNotificationSettingsLazyQueryHookResult = ReturnType<typeof useListUserNotificationSettingsLazyQuery>;
export type ListUserNotificationSettingsQueryResult = Apollo.QueryResult<ListUserNotificationSettingsQuery, ListUserNotificationSettingsQueryVariables>;
export const MyNotificationSettingsDocument = gql`
    query myNotificationSettings {
  myNotificationSettings {
    userId
    settings {
      id
      setting
      enabled
    }
  }
}
    `;

/**
 * __useMyNotificationSettingsQuery__
 *
 * To run a query within a React component, call `useMyNotificationSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyNotificationSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyNotificationSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyNotificationSettingsQuery(baseOptions?: Apollo.QueryHookOptions<MyNotificationSettingsQuery, MyNotificationSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyNotificationSettingsQuery, MyNotificationSettingsQueryVariables>(MyNotificationSettingsDocument, options);
      }
export function useMyNotificationSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyNotificationSettingsQuery, MyNotificationSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyNotificationSettingsQuery, MyNotificationSettingsQueryVariables>(MyNotificationSettingsDocument, options);
        }
export type MyNotificationSettingsQueryHookResult = ReturnType<typeof useMyNotificationSettingsQuery>;
export type MyNotificationSettingsLazyQueryHookResult = ReturnType<typeof useMyNotificationSettingsLazyQuery>;
export type MyNotificationSettingsQueryResult = Apollo.QueryResult<MyNotificationSettingsQuery, MyNotificationSettingsQueryVariables>;
export const UpdateUserNotificationSettingsDocument = gql`
    mutation updateUserNotificationSettings($notificationSettingsToken: String!, $enableSurveyNotifications: Boolean!, $enableIncidentReports: Boolean!, $enableSupplementalReports: Boolean!, $enableTeamKits: Boolean!) {
  updateUserNotificationSettings(
    input: {notificationSettingsToken: $notificationSettingsToken, enableSurveyNotifications: $enableSurveyNotifications, enableIncidentReports: $enableIncidentReports, enableSupplementalReports: $enableSupplementalReports, enableTeamKits: $enableTeamKits}
  )
}
    `;
export type UpdateUserNotificationSettingsMutationFn = Apollo.MutationFunction<UpdateUserNotificationSettingsMutation, UpdateUserNotificationSettingsMutationVariables>;

/**
 * __useUpdateUserNotificationSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateUserNotificationSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserNotificationSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserNotificationSettingsMutation, { data, loading, error }] = useUpdateUserNotificationSettingsMutation({
 *   variables: {
 *      notificationSettingsToken: // value for 'notificationSettingsToken'
 *      enableSurveyNotifications: // value for 'enableSurveyNotifications'
 *      enableIncidentReports: // value for 'enableIncidentReports'
 *      enableSupplementalReports: // value for 'enableSupplementalReports'
 *      enableTeamKits: // value for 'enableTeamKits'
 *   },
 * });
 */
export function useUpdateUserNotificationSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserNotificationSettingsMutation, UpdateUserNotificationSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserNotificationSettingsMutation, UpdateUserNotificationSettingsMutationVariables>(UpdateUserNotificationSettingsDocument, options);
      }
export type UpdateUserNotificationSettingsMutationHookResult = ReturnType<typeof useUpdateUserNotificationSettingsMutation>;
export type UpdateUserNotificationSettingsMutationResult = Apollo.MutationResult<UpdateUserNotificationSettingsMutation>;
export type UpdateUserNotificationSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateUserNotificationSettingsMutation, UpdateUserNotificationSettingsMutationVariables>;
export const UpdateMyNotificationSettingsDocument = gql`
    mutation updateMyNotificationSettings($enableSurveyNotifications: Boolean!, $enableIncidentReports: Boolean!, $enableSupplementalReports: Boolean!, $enableTeamKits: Boolean!) {
  updateMyNotificationSettings(
    input: {enableSurveyNotifications: $enableSurveyNotifications, enableIncidentReports: $enableIncidentReports, enableSupplementalReports: $enableSupplementalReports, enableTeamKits: $enableTeamKits}
  )
}
    `;
export type UpdateMyNotificationSettingsMutationFn = Apollo.MutationFunction<UpdateMyNotificationSettingsMutation, UpdateMyNotificationSettingsMutationVariables>;

/**
 * __useUpdateMyNotificationSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateMyNotificationSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMyNotificationSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMyNotificationSettingsMutation, { data, loading, error }] = useUpdateMyNotificationSettingsMutation({
 *   variables: {
 *      enableSurveyNotifications: // value for 'enableSurveyNotifications'
 *      enableIncidentReports: // value for 'enableIncidentReports'
 *      enableSupplementalReports: // value for 'enableSupplementalReports'
 *      enableTeamKits: // value for 'enableTeamKits'
 *   },
 * });
 */
export function useUpdateMyNotificationSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMyNotificationSettingsMutation, UpdateMyNotificationSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMyNotificationSettingsMutation, UpdateMyNotificationSettingsMutationVariables>(UpdateMyNotificationSettingsDocument, options);
      }
export type UpdateMyNotificationSettingsMutationHookResult = ReturnType<typeof useUpdateMyNotificationSettingsMutation>;
export type UpdateMyNotificationSettingsMutationResult = Apollo.MutationResult<UpdateMyNotificationSettingsMutation>;
export type UpdateMyNotificationSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateMyNotificationSettingsMutation, UpdateMyNotificationSettingsMutationVariables>;